import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import {
  clearDiactiveStaffOfAPIRes,
  diactiveStaffOfOrgApiCall,
} from "redux/DiactiveStaffOfOrganization/API";
import { DiactiveStaffOfOrgBody } from "redux/DiactiveStaffOfOrganization/Model";
import MWSnackbar from "component/MWSnackbar";
import AddOrganizationStaffDialog from "./../AddOrganizationStaffDialog";
import {
  GetActiveListByOrgIdBody,
  StaffList,
} from "redux/ViewOrganizationStaffList/ActiveListByOrganizationId/Model";
import { getActiveListByOrgId } from "redux/ViewOrganizationStaffList/ActiveListByOrganizationId/API";

type Props = {
  viewOrgStaffListloader?: boolean | any;
  activeListCount: (value: string) => void;
};

export default function ActiveStaffList({ viewOrgStaffListloader, activeListCount }: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const [addOrganizationStaffDialog, setAddOrganizationStaffDialog] = React.useState(false);
  const updateAddOrganizationStaffDialogValFromChild = (passedVal: boolean) => {
    setAddOrganizationStaffDialog(passedVal);
  };

  useEffect(() => {
    const activeListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 1,
    } as GetActiveListByOrgIdBody;
    dispatch(getActiveListByOrgId(activeListBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const viewOrgAdminList = useSelector((state: AppState) => state.getWiewOrgStaffListValue);
  const diactiveStaffResponseValue = useSelector((state: AppState) => state.diactiveStaffValue);
  const addStaffOrgValue = useSelector((state: AppState) => state.addStaffOfOrgValue);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const deactiveStaff = (staffId: string) => {
    let diactiveBody = {
      organizationId: organizationid,
      staffId: staffId,
    } as DiactiveStaffOfOrgBody;
    dispatch(diactiveStaffOfOrgApiCall(diactiveBody));
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  useEffect(() => {
    viewOrgAdminList?.getActiveListByOrgIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(viewOrgAdminList?.getActiveListByOrgIdRes?.numberOfPages))
      : setPageListCount("0");
    viewOrgAdminList?.getActiveListByOrgIdRes?.count !== undefined
      ? activeListCount(String(viewOrgAdminList?.getActiveListByOrgIdRes?.count))
      : activeListCount("0");
    if (viewOrgAdminList?.getActiveListByOrgIdRes?.StaffList !== undefined) {
      let dataPointArr = viewOrgAdminList?.getActiveListByOrgIdRes?.StaffList.map(
        (element: StaffList) => ({
          id: element.id,
          staffName: element.email,
          firstName: element.first_name,
          lastName: element.last_name,
          userId: element.userId,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    if (viewOrgAdminList?.errorMsg !== "") {
      setNotAdministratorMsg(viewOrgAdminList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOrgAdminList]);

  useEffect(() => {
    if (diactiveStaffResponseValue?.DiactiveStaffOfOrgRes?.message !== undefined) {
      setDiactiveMsg(diactiveStaffResponseValue?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const activeListBody = {
        organizationId: organizationid,
        isActiveOrAdmin: 1,
      } as GetActiveListByOrgIdBody;
      dispatch(getActiveListByOrgId(activeListBody, page));
      dispatch(clearDiactiveStaffOfAPIRes());
    }
    if (diactiveStaffResponseValue?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(diactiveStaffResponseValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diactiveStaffResponseValue]);

  useEffect(() => {
    if (addStaffOrgValue?.AddStaffOfOrgRes?.message !== undefined) {
      setDiactiveMsg(addStaffOrgValue?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const activeListBody = {
        organizationId: organizationid,
        isActiveOrAdmin: 1,
      } as GetActiveListByOrgIdBody;
      dispatch(getActiveListByOrgId(activeListBody, page));
    }
    if (addStaffOrgValue?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(addStaffOrgValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStaffOrgValue]);

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
    }
  };

  const addOrganizationStaff = () => {
    setAddOrganizationStaffDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "staffName",
      headerName: "User Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Action",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deactiveStaff(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Deactive Staff
        </Button>
      ),
    },
  ];

  const DeactiveStaffListOfOrganization = (
    <Box>
      {viewOrgAdminList?.getActiveListByOrgIdRes?.StaffList !== undefined ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item></Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={() => addOrganizationStaff()}
                >
                  Add Organization Staff
                </Button>
              </Grid>
              <Grid item>
                <Typography>Page: {page}</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={Number(pageListCount) || 0}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid mt={2}>
        {notAdministratorMsg !== "" ? (
          <Alert severity="error">{notAdministratorMsg}</Alert>
        ) : (
          <Box>
            {viewOrgAdminList?.getActiveListByOrgIdRes?.StaffList &&
            viewOrgAdminList?.getActiveListByOrgIdRes?.StaffList.length ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </Grid>
    </Box>
  );

  return (
    <Box>
      {viewOrgAdminList?.loading === true
        ? viewOrgStaffListloader(true)
        : viewOrgStaffListloader(false)}
      {DeactiveStaffListOfOrganization}
      {addOrganizationStaffDialog ? (
        <AddOrganizationStaffDialog
          itemId={organizationid}
          addOrganizationStaffDialogEnable={addOrganizationStaffDialog}
          addOrganizationStaffDialogUpdateState={updateAddOrganizationStaffDialogValFromChild}
        />
      ) : null}
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
