import Button from "@mui/material/Button";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addExecutiveSignupRequestApi } from "redux/Executive/AddExecutiveSignupReqApi/API";
import { AddExecutiveSignupRequestBody } from "redux/Executive/AddExecutiveSignupReqApi/Model";

type Props = {
  executiveSignupEmail: boolean;
};

export default function AddExecutiveSignupRequest({ executiveSignupEmail }: Props) {
  const dispatch = useDispatch();
  const [executiveEmail, setExecutiveEmail] = useState("");

  useEffect(() => {
    if (!executiveSignupEmail) {
      setExecutiveEmail("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveSignupEmail]);

  const addExecutiveSignupReq = () => {
    dispatch(
      addExecutiveSignupRequestApi({
        email: executiveEmail,
      } as AddExecutiveSignupRequestBody),
    );
  };

  return (
    <Box>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} my={1}>
          <Typography variant="h6">Add Executive Signup Request</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            label="Email"
            placeholder="Enter email"
            value={executiveEmail}
            onChange={(e) => {
              setExecutiveEmail(e.target.value);
            }}
            id="executiveEmail"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
          mt={1}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={addExecutiveSignupReq}
              disabled={executiveEmail !== "" ? false : true}
            >
              Signup Request
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
