import {
  GET_PATIENT_CASE_DETAILS_BY_ID_FAIL,
  GET_PATIENT_CASE_DETAILS_BY_ID_LOADING,
  GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS,
  GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG,
  GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES,
  GetPatientCaseDetailsByIdActionTypes,
} from "./ActionTypes";
import { GetPatientCaseDetailsByIdRes } from "./Model";

export const getPatientCaseDetailsByIdLoadingAction = (
  loading: boolean,
): GetPatientCaseDetailsByIdActionTypes => {
  return {
    type: GET_PATIENT_CASE_DETAILS_BY_ID_LOADING,
    loading: loading,
  };
};

export const getPatientCaseDetailsByIdSuccessAction = (
  getPatientCaseDetailsByIdResponse: GetPatientCaseDetailsByIdRes,
): GetPatientCaseDetailsByIdActionTypes => {
  return {
    type: GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS,
    payload: getPatientCaseDetailsByIdResponse,
  };
};

export const getPatientCaseDetailsByIdErrorAction = (
  getPatientCaseDetailsByIdResponse: GetPatientCaseDetailsByIdRes,
  errMsg: string,
  status: number,
): GetPatientCaseDetailsByIdActionTypes => {
  return {
    type: GET_PATIENT_CASE_DETAILS_BY_ID_FAIL,
    payload: getPatientCaseDetailsByIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientCaseDetailsByIdUpdateAPIMsgAction = (
  getPatientCaseDetailsByIdResponse: GetPatientCaseDetailsByIdRes,
  errMsg: string,
  status: number,
): GetPatientCaseDetailsByIdActionTypes => {
  return {
    type: GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG,
    payload: getPatientCaseDetailsByIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientCaseDetailsByIdAPIResClearAction = (
  getPatientCaseDetailsByIdResponse: GetPatientCaseDetailsByIdRes,
  _successMsg: string,
  errMsg: string,
  status: number,
): GetPatientCaseDetailsByIdActionTypes => {
  return {
    type: GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES,
    payload: getPatientCaseDetailsByIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
