import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetSessionPatientDetailsListActionTypes } from "./ActionTypes";
import { GetSessionPatientDetailsListBody, GetSessionPatientDetailsListRes } from "./Model";
import {
  getSessionPatientDetailsListAPIResClearAction,
  getSessionPatientDetailsListErrorAction,
  getSessionPatientDetailsListLoadingAction,
  getSessionPatientDetailsListSuccessAction,
  getSessionPatientDetailsListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetSessionPatientDetailsListRes;
export const getSessionPatientDetailsListApi = (
  payload: GetSessionPatientDetailsListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetSessionPatientDetailsListActionTypes>) {
    dispatch(getSessionPatientDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthCampSessionPatientDetails/get_patient_detailsList_of_session?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getSessionPatientDetailsListLoadingAction(false));
        dispatch(
          getSessionPatientDetailsListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetSessionPatientDetailsListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getSessionPatientDetailsListLoadingAction(false));
        dispatch(
          getSessionPatientDetailsListErrorAction(
            {} as GetSessionPatientDetailsListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetSessionPatientDetailsListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetSessionPatientDetailsListActionTypes>) {
    dispatch(
      getSessionPatientDetailsListUpdateAPIMsgAction(
        apiRes as GetSessionPatientDetailsListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetSessionPatientDetailsListAPIRes = () => {
  return function (dispatch: Dispatch<GetSessionPatientDetailsListActionTypes>) {
    dispatch(
      getSessionPatientDetailsListAPIResClearAction({} as GetSessionPatientDetailsListRes, "", "", 0),
    );
  };
};
