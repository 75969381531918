import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import { Alert, Box, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import MWTextField from "../../../component/MWTextField";
import { addPatientTeamByAdmin } from "redux/PatientTeam/addPatientTeamByAdmin/API";
import { AddPatientTeamByAdminBody } from "redux/PatientTeam/addPatientTeamByAdmin/Model";
import AdminLoader from "pages/AdminLoader";

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  addPatientTeamDialogEnable?: boolean | any;
  addPatientTeamDialogUpdateState?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((_theme: Theme) => ({
  root: {
    margin: 0,
    padding: "8px",
  },
}))(MuiDialogActions);

export default function AddPatientTeamDialog({
  addPatientTeamDialogEnable,
  addPatientTeamDialogUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const closeSummaryBanner = () => {
    setAddHealthCampPatientSuccess("");
    setDialogState(false);
  };
  const [addHealthCampPatientSuccess, setAddHealthCampPatientSuccess] = React.useState("");
  const addHealthCampPatientSuccessDiv = addHealthCampPatientSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{addHealthCampPatientSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [dialogState, setDialogState] = React.useState(false);
  useEffect(() => {
    setDialogState(addPatientTeamDialogEnable);
  }, [addPatientTeamDialogEnable]);

  const handleDialogClose = () => {
    setDialogState(false);
    addPatientTeamDialogUpdateState(false);
  };
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // call Store
  const addPatientTeamRes = useSelector((state: AppState) => state.addPatientTeamByAdminRes);

  const [emailError, setEmailError] = useState("");
  const emailErrordiv = emailError ? <Typography variant="body1">{emailError}</Typography> : "";
  const [firstNameError, setFirstNameError] = useState("");
  const firstNameErrordiv = firstNameError ? (
    <Typography variant="body1">{firstNameError}</Typography>
  ) : (
    ""
  );
  const [lastNameError, setLastNameError] = useState("");
  const lastNameErrordiv = lastNameError ? (
    <Typography variant="body1">{lastNameError}</Typography>
  ) : (
    ""
  );

  // Add patient team body
  const addPatientTeamBody = {
    email: email.toLowerCase(),
    first_name: firstName,
    last_name: lastName,
    phoneNumber: phoneNumber,
  } as AddPatientTeamByAdminBody;
  // Add patient eam API
  const addUser = () => {
    if (email === "" && firstName === "" && lastName === "") {
      setEmailError("Please enter email");
      setFirstNameError("Please enter first name");
      setLastNameError("Please enter last name");
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase()) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setEmailError("Please enter valid email");
    } else if (email === "" && firstName === "" && lastName !== "") {
      setEmailError("Please enter email");
      setFirstNameError("Please enter first name");
      setLastNameError("");
    } else if (email === "" && firstName !== "" && lastName === "") {
      setEmailError("Please enter email");
      setFirstNameError("");
      setLastNameError("Please enter last name");
    } else if (email === "" && firstName !== "" && lastName !== "") {
      setEmailError("Please enter email");
      setFirstNameError("");
      setLastNameError("");
    } else if (email !== "" && firstName === "" && lastName === "") {
      setEmailError("");
      setFirstNameError("Please enter first name");
      setLastNameError("Please enter last name");
    } else if (email !== "" && firstName === "" && lastName !== "") {
      setEmailError("");
      setFirstNameError("Please enter first name");
      setLastNameError("");
    } else if (email !== "" && firstName !== "" && lastName === "") {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("Please enter last name");
    } else {
      dispatch(addPatientTeamByAdmin(addPatientTeamBody));
    }
  };
  //addPatientTeamByAdminRes
  useEffect(() => {
    if (addPatientTeamRes?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientTeamRes]);

  return (
    <Dialog
      onClose={handleDialogClose}
      open={dialogState}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
    >
      {addPatientTeamRes?.loading && <AdminLoader />}
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h5">Add Patient Team</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <MWTextField
              label="Email"
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              placeholder="Please email number"
              id="emailPhoneID"
              fullWidth
              size="small"
            />
            <Typography mt={1}>{emailErrordiv}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MWTextField
              label="First Name"
              value={firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(event.target.value);
              }}
              placeholder="Please First Name"
              id="firstNameId"
              fullWidth
              size="small"
            />
            <Typography mt={1}>{firstNameErrordiv}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MWTextField
              label="Last Name"
              value={lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(event.target.value);
              }}
              placeholder="Please Last Name"
              id="lastNameId"
              fullWidth
              size="small"
            />
            <Typography mt={1}>{lastNameErrordiv}</Typography>
          </Grid>
          <Grid item xs={12}>
            <MWTextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPhoneNumber(event.target.value);
              }}
              placeholder="Please phone number"
              id="emailPhoneID"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Box pt={2}>{addHealthCampPatientSuccessDiv}</Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={addUser}
          variant="contained"
          color="primary"
          style={{ fontSize: "12px" }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
