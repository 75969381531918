import {
  GetPatientDetailsListByCompetitionIdActionTypes,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_FAIL,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_LOADING,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_SUCCESS,
  PatientDetailsListOfCompetitionIdState,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES,
} from "./ActionTypes";
import { GetPatientDetailsListByCompetitionIdDetails } from "./Model";

const initialStateGetPosts: PatientDetailsListOfCompetitionIdState = {
  loading: false,
  patientDetailsListOfCompetitionIdRes: {} as GetPatientDetailsListByCompetitionIdDetails,
  message: "",
  status: 0,
};
export const getPatientDetailsByCompetitionIdReducer = (
  state = initialStateGetPosts,
  action: GetPatientDetailsListByCompetitionIdActionTypes,
): PatientDetailsListOfCompetitionIdState => {
  switch (action.type) {
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_LOADING:
      return {
        ...state,
        loading: true,
        patientDetailsListOfCompetitionIdRes: {} as GetPatientDetailsListByCompetitionIdDetails,
      };
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: action.payload,
      };
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_FAIL:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: {} as GetPatientDetailsListByCompetitionIdDetails,
        message: action.message,
        status: action.status,
      };
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES:
      return {
        ...state,
        loading: false,
        patientDetailsListOfCompetitionIdRes: {} as GetPatientDetailsListByCompetitionIdDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
