import React, { useEffect, useState } from "react";
import WisdomLogo from "../../image/logo/appLogo.png";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import AdminLoader from "./../AdminLoader";
import OnboardingLayout from "./../Layout/OnboardingLayout";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { clearLoginAPIRes, jwtTokenApiCall } from "../../redux/jwtAdminLogIn/API";
import { JwtTokenAuthBody } from "../../redux/jwtAdminLogIn/Model";
import {
  adminLoginDetailsApi,
  clearGetAdminDetailsAPIRes,
} from "../../redux/jwtAdminLogIn/adminLoginDetails/API";
import { LoginBody } from "../../redux/jwtAdminLogIn/adminLoginDetails/Model";
import url from "../../ApiConfig";
import { checkReturnValue } from "component/Utility";
import AddExecutiveSignupRequest from "./AddExecutiveSignupRequestDialog/inedx";
import MWSnackbar from "component/MWSnackbar";
import { clearAddExecutiveSignupRequestAPIRes } from "redux/Executive/AddExecutiveSignupReqApi/API";

export default function Login() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const apiAuthTokenValue = useSelector((state: AppState) => state.apiAuthToken);
  const adminLoginDetailsResponse = useSelector((state: AppState) => state.adminLoginDetailsRes);
  const addExecutiveSignupRequestRes = useSelector(
    (state: AppState) => state.addExecutiveSignupRequestVlaue,
  );
  const closeBanner = () => {
    setError("");
    setErrorTokenMsg("");
    dispatch(clearLoginAPIRes());
  };
  const [error, setError] = useState("");
  const [errorTokenMsg, setErrorTokenMsg] = useState("");

  const errorErrorDiv = error ? (
    <Alert onClose={closeBanner} severity="error">
      <Typography>{error}</Typography>
    </Alert>
  ) : (
    ""
  );
  const errorTokenDiv = errorTokenMsg ? (
    <Alert variant="standard" severity="error" onClose={() => closeBanner()}>
      <Box sx={{ mb: 2 }}>{errorTokenMsg}</Box>
    </Alert>
  ) : (
    ""
  );

  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const [email, setEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = useState("");
  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPassword] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [errorpassword, setErrorPassword] = useState("");
  const errorPassword = errorpassword ? (
    <Typography color="#de5555">{errorpassword}</Typography>
  ) : (
    ""
  );

  const authApiBody = {
    username: email.toLowerCase(),
    password: password.password,
  } as JwtTokenAuthBody;

  // initiate Login API
  const handleLogin = () => {
    if (email === "") {
      setErrorEmail("Email is empty");
      setErrorPassword("Password is empty");
    } else if (email === "" && password.password !== "") {
      setErrorEmail("Email is empty");
      setErrorPassword("");
    } else if (email !== "" && password.password === "") {
      setErrorEmail("");
      setErrorPassword("Password is empty");
    } else {
      setError("");
      setErrorEmail("");
      setErrorPassword("");
      dispatch(jwtTokenApiCall(authApiBody));
    }
  };

  const [access, setToken] = useState("");
  useEffect(() => {
    if (apiAuthTokenValue?.loginRes?.access !== undefined) {
      setToken(apiAuthTokenValue?.loginRes?.access);
    } else {
      setErrorTokenMsg(apiAuthTokenValue.errorMsg);
    }
  }, [apiAuthTokenValue]);

  useEffect(() => {
    if (access !== "") {
      dispatch(
        adminLoginDetailsApi({
          access: access,
        } as LoginBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  useEffect(() => {
    setIsLoading(adminLoginDetailsResponse?.loading);
    if (
      checkReturnValue(adminLoginDetailsResponse?.getAdminLoginDetailsRes?.user?.id) !== "Not Set"
    ) {
      dispatch(clearLoginAPIRes());
      dispatch(clearGetAdminDetailsAPIRes());
      window.location.reload();
      // console.log("CONSOLE", "RELOAD");
    }
    if (adminLoginDetailsResponse?.errorMsg !== "") {
      // history.push("/signin");
      setError(adminLoginDetailsResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminLoginDetailsResponse]);

  const [checked, setChecked] = React.useState(false);
  const showExeSignupSection = () => {
    setChecked((prev) => !prev);
  };
  const [addExecutiveSignupRqstMsg, setAddExecutiveSignupRqstMsg] = useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    if (addExecutiveSignupRequestRes?.successMsg !== "") {
      setAddExecutiveSignupRqstMsg(addExecutiveSignupRequestRes?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      setChecked(false);
    }
    if (addExecutiveSignupRequestRes?.errorMsg !== "") {
      setAddExecutiveSignupRqstMsg(addExecutiveSignupRequestRes?.errorMsg);
      setLinkImageToCompetitionMsgType("error");
      setChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExecutiveSignupRequestRes]);

  const actualPageMarkup = (
    <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
      <Grid item>
        <Box>
          {loadingMarkup}
          <Card>
            <CardContent>
              <Box sx={{ m: 2 }} onKeyDown={keyDownEvent}>
                <Link underline="none" href={`${url.redirectUrl}`} target="_blank" rel="noreferrer">
                  <img src={WisdomLogo} alt="Logo" height="60" />
                </Link>
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Log into admin dashboard
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  sx={{ my: 2 }}
                  size="small"
                  value={email}
                  error={errorEmail === "" ? false : true}
                  label="Email or Phone Number"
                  placeholder="Please enter email or phone number"
                  id="emailFieldID"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                    setErrorEmail("");
                  }}
                  helperText={
                    errorEmail === "" ? "Please provide email or phone number" : errorEmail
                  }
                  InputLabelProps={{ shrink: true }}
                  disabled={checked}
                />
                <FormControl fullWidth variant="outlined">
                  <InputLabel size="small" htmlFor="outlined-adornment-password" shrink>
                    Enter your password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    notched
                    size="small"
                    error={errorPassword === "" ? false : true}
                    placeholder="Enter your password"
                    label="Enter your password"
                    type={password.showPassword ? "text" : "Password"}
                    value={password.password}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="small"
                        >
                          {password.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    disabled={checked}
                  />
                  <FormHelperText>
                    <Grid container item direction="column" justifyContent="center" xs={12}>
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Box>{errorPassword === "" ? "Please enter password" : errorPassword}</Box>
                      </Grid>
                    </Grid>
                  </FormHelperText>
                </FormControl>
                <Box>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        sx={{ my: 2 }}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        disabled={checked}
                      >
                        Sign In
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        pb={1}
                      >
                        <Grid item>
                          <Button
                            variant="text"
                            fullWidth
                            sx={{
                              textTransform: "none",
                            }}
                            onClick={showExeSignupSection}
                          >
                            {checked === false ? "Executive Signup Request" : "Cancel"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Collapse in={checked}>
                        <Divider />
                        <AddExecutiveSignupRequest executiveSignupEmail={checked} />
                      </Collapse>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {errorErrorDiv}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {errorTokenDiv}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
  const closeMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddExecutiveSignupRequestAPIRes());
      setAddExecutiveSignupRqstMsg("");
    }
  };
  return (
    <Container maxWidth="xl">
      <OnboardingLayout>{actualPageMarkup}</OnboardingLayout>
      {addExecutiveSignupRqstMsg !== "" ? (
        <MWSnackbar
          msg={addExecutiveSignupRqstMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={closeMsg}
        />
      ) : null}
    </Container>
  );
}
