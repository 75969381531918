import {
  GET_VITAL_COLLECTION_ACTIVE_FAIL,
  GET_VITAL_COLLECTION_ACTIVE_LOADING,
  GET_VITAL_COLLECTION_ACTIVE_SUCCESS,
  GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_MSG,
  GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_RES,
  GetVitalCollectionActiveActionTypes,
} from "./ActionTypes";
import { GetVitalCollectionActiveRes } from "./Model";

export const getVitalCollectionActiveLoadingAction = (
  loading: boolean,
): GetVitalCollectionActiveActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_ACTIVE_LOADING,
    loading: loading,
  };
};

export const getVitalCollectionActiveSuccessAction = (
  getVitalCollectionActiveResponse: GetVitalCollectionActiveRes,
  successMsg: string,
): GetVitalCollectionActiveActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_ACTIVE_SUCCESS,
    payload: getVitalCollectionActiveResponse,
    successMsg: successMsg,
  };
};

export const getVitalCollectionActiveErrorAction = (
  getVitalCollectionActiveResponse: GetVitalCollectionActiveRes,
  errMsg: string,
  status: number,
): GetVitalCollectionActiveActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_ACTIVE_FAIL,
    payload: getVitalCollectionActiveResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getVitalCollectionActiveUpdateAPIMsgAction = (
  getVitalCollectionActiveResponse: GetVitalCollectionActiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetVitalCollectionActiveActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_MSG,
    payload: getVitalCollectionActiveResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getVitalCollectionActiveAPIResClearAction = (
  getVitalCollectionActiveResponse: GetVitalCollectionActiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetVitalCollectionActiveActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_RES,
    payload: getVitalCollectionActiveResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
