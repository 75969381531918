import {
  GetCompetitionPatientListActionTypes,
  PATIENT_LIST_BY_COMPETITION_ID_FAIL,
  PATIENT_LIST_BY_COMPETITION_ID_LOADING,
  PATIENT_LIST_BY_COMPETITION_ID_SUCCESS,
  PatientListOfCompetitionIdDetailsState,
  COMPETITION_PATIENT_LIST_API_MSG,
  COMPETITION_PATIENT_LIST_API_RES,
} from "./ActionTypes";
import { GetCompetitionPatientListDetails } from "./Model";

const initialStateGetPosts: PatientListOfCompetitionIdDetailsState = {
  loading: false,
  PatientListOfCompetitionIdRes: {} as GetCompetitionPatientListDetails,
  message: "",
  status: 0,
};
export const getCompetitionPatientReducer = (
  state = initialStateGetPosts,
  action: GetCompetitionPatientListActionTypes,
): PatientListOfCompetitionIdDetailsState => {
  switch (action.type) {
    case PATIENT_LIST_BY_COMPETITION_ID_LOADING:
      return {
        ...state,
        loading: true,
        PatientListOfCompetitionIdRes: {} as GetCompetitionPatientListDetails,
      };
    case PATIENT_LIST_BY_COMPETITION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionIdRes: action.payload,
      };
    case PATIENT_LIST_BY_COMPETITION_ID_FAIL:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionIdRes: {} as GetCompetitionPatientListDetails,
        message: action.message,
        status: action.status,
      };
    case COMPETITION_PATIENT_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionIdRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case COMPETITION_PATIENT_LIST_API_RES:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionIdRes: {} as GetCompetitionPatientListDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
