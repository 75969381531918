import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import MWExceptionList from "../../component/MWExceptionList";
import { useHistory } from "react-router-dom";
import { getPatientListValue } from "../../redux/effects/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function PatientListAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const gotoSearchPatient = () => {
    history.push(`/searchpatient`);
  };

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientListValue(value));
  };

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  // Call Patient List API on Page loading
  useEffect(() => {
    dispatch(getPatientListValue(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.patientValue);
  const pageCount = useSelector((state: AppState) => state.patientValue.patientPageCount);
  const patientListRes = AdminPatientList.patientValue;
  const [patientList, setPatientList] = useState([] as any[]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminPatientList?.loading));
    if (patientListRes !== undefined) {
      let ptList = patientListRes.map((element: any) => ({
        id: element.id,
        firstName: element?.firstname !== undefined ? element?.firstname : "",
        lastName: element?.lastname !== undefined ? element?.lastname : "",
        dob: dayjs(element.dob).format("MMM D, YYYY"),
        patientRelationshipWithOwner:
          element.patientRelationshipWithOwner?.displayValue !== undefined
            ? element.patientRelationshipWithOwner?.displayValue
            : "",
        gender: element?.gender.label !== undefined ? element?.gender?.label : "",
        bloodGroup: element?.bloodGroup !== undefined ? element.bloodGroup?.label : "",
        description: element?.description !== undefined ? element.description : "",
      }));
      setPatientList(ptList);
    } else setPatientList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);
  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      type: "string",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      minWidth: 150,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      minWidth: 150,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      type: "string",
      minWidth: 150,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "patientRelationshipWithOwner",
      headerName: "Relationship",
      type: "string",
      minWidth: 150,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      type: "string",
      minWidth: 100,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "bloodGroup",
      headerName: "Blood Group",
      type: "string",
      minWidth: 130,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      minWidth: 150,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant={"contained"}
                  onClick={() => gotoSearchPatient()}
                  sx={{ textTransform: "none" }}
                >
                  Search Patient
                </Button>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                  pt={2}
                >
                  {!fullScreen && (
                    <Grid item>
                      <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Pagination
                      color="primary"
                      count={Number(pageCount)}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {patientList && patientList.length ? (
            <DataGrid
              rows={patientList}
              columns={columns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
