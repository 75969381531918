import { AddRankForFinalRoundDetailsRes } from "./Model";
export const ADD_RANK_FOR_FINAL_ROUND_LOADING = "ADD_RANK_FOR_FINAL_ROUND_LOADING";
export const ADD_RANK_FOR_FINAL_ROUND_SUCCESS = "ADD_RANK_FOR_FINAL_ROUND_SUCCESS";
export const ADD_RANK_FOR_FINAL_ROUND_FAIL = "ADD_RANK_FOR_FINAL_ROUND_FAIL";
export const ADD_RANK_FOR_FINAL_ROUND_DATA = "ADD_RANK_FOR_FINAL_ROUND_DATA";
export const ADD_RANK_FOR_FINAL_ROUND_API_MSG = "ADD_RANK_FOR_FINAL_ROUND_API_MSG";
export const ADD_RANK_FOR_FINAL_ROUND_API_RES = "ADD_RANK_FOR_FINAL_ROUND_API_RES";

export interface AddRankForFinalRoundState {
  addRankForFinalRoundRes: AddRankForFinalRoundDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface AddRankForFinalRoundLoading {
  type: typeof ADD_RANK_FOR_FINAL_ROUND_LOADING;
  loading: boolean;
}
export interface AddRankForFinalRoundSuccess {
  type: typeof ADD_RANK_FOR_FINAL_ROUND_SUCCESS;
  payload: AddRankForFinalRoundDetailsRes;
}
export interface AddRankForFinalRoundFail {
  type: typeof ADD_RANK_FOR_FINAL_ROUND_FAIL;
  payload: AddRankForFinalRoundDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddRankForFinalRoundAPIMsg {
  type: typeof ADD_RANK_FOR_FINAL_ROUND_API_MSG;
  payload: AddRankForFinalRoundDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddRankForFinalRoundAPIRes {
  type: typeof ADD_RANK_FOR_FINAL_ROUND_API_RES;
  payload: AddRankForFinalRoundDetailsRes;
  errorMsg: string;
  status: number;
}

export type AddRankForFinalRoundDetailsActionTypes =
  | AddRankForFinalRoundLoading
  | AddRankForFinalRoundSuccess
  | AddRankForFinalRoundFail
  | AddRankForFinalRoundAPIMsg
  | AddRankForFinalRoundAPIRes;
