import {
  AddPatientTeamByAdminActionTypes,
  ADD_PATIENT_TEAM_BY_ADMIN_FAIL,
  ADD_PATIENT_TEAM_BY_ADMIN_LOADING,
  ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS,
  AddPatientTeamByAdminState,
  ADD_PATIENT_TEAM_BY_ADMIN_API_MSG,
  ADD_PATIENT_TEAM_BY_ADMIN_API_RES,
} from "./ActionTypes";
import { AddPatientTeamByAdminRes } from "./Model";

const initialStateEditPosts: AddPatientTeamByAdminState = {
  loading: false,
  addPatientTeamByAdminRes: {} as AddPatientTeamByAdminRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addPatientTeamByAdminReducer = (
  state = initialStateEditPosts,
  action: AddPatientTeamByAdminActionTypes
): AddPatientTeamByAdminState => {
  switch (action.type) {
    case ADD_PATIENT_TEAM_BY_ADMIN_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS:
      return {
        ...state,
        addPatientTeamByAdminRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_TEAM_BY_ADMIN_FAIL:
      return {
        ...state,
        addPatientTeamByAdminRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_TEAM_BY_ADMIN_API_MSG:
      return {
        ...state,
        addPatientTeamByAdminRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_TEAM_BY_ADMIN_API_RES:
      return {
        ...state,
        addPatientTeamByAdminRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
