import { AssignExecutiveResponse } from "./Model";

export const ASSIGN_EXECUTIVE_LOADING = "ASSIGN_EXECUTIVE_LOADING";
export const ASSIGN_EXECUTIVE_SUCCESS = "ASSIGN_EXECUTIVE_SUCCESS";
export const ASSIGN_EXECUTIVE_FAIL = "ASSIGN_EXECUTIVE_FAIL";
export const ASSIGN_EXECUTIVE_API_MSG = "ASSIGN_EXECUTIVE_API_MSG";
export const ASSIGN_EXECUTIVE_CLEAR_API_RES = "ASSIGN_EXECUTIVE_CLEAR_API_RES";

export interface AssignExecutiveDataState {
  assignExecutiveRes: AssignExecutiveResponse;
  loading: boolean;
  message: string;
  status: number;
}

export interface AssignExecutiveListLoading {
  type: typeof ASSIGN_EXECUTIVE_LOADING;
  loading: boolean;
}
export interface AssignExecutiveListSuccess {
  type: typeof ASSIGN_EXECUTIVE_SUCCESS;
  payload: AssignExecutiveResponse;
}
export interface AssignExecutiveListFail {
  type: typeof ASSIGN_EXECUTIVE_FAIL;
  payload: AssignExecutiveResponse;
  message: string;
  status: number;
}
export interface AssignExecutiveAPIMsg {
  type: typeof ASSIGN_EXECUTIVE_API_MSG;
  payload: AssignExecutiveResponse;
  message: string;
  status: number;
}
export interface AssignExecutiveAPIRes {
  type: typeof ASSIGN_EXECUTIVE_CLEAR_API_RES;
  payload: AssignExecutiveResponse;
  message: string;
  status: number;
}

export type AssignExecutiveListActionTypes =
  | AssignExecutiveListLoading
  | AssignExecutiveListSuccess
  | AssignExecutiveListFail
  | AssignExecutiveAPIMsg
  | AssignExecutiveAPIRes;
