import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/store/Store";
import { Alert, Box, Grid } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../component/MWExceptionList";
import { getObservationTypeDetails } from "redux/GetObservationTypeList/API";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import { ModifiedReportList } from "./Model";

type Props = {
  reportingLoader: (value: boolean) => void;
  count: (value: string) => void;
};

export default function Reporting({ reportingLoader, count }: Props) {
  const dispatch = useDispatch();
  // call Store
  const getObservationTypeListRes = useSelector((state: AppState) => state.getObservationTypeValue);
  useEffect(() => {
    dispatch(getObservationTypeDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = useState([] as ModifiedReportList[]);
  const [notAdministratorMsg] = useState("");

  useEffect(() => {
    reportingLoader(getObservationTypeListRes?.loading);
    if (getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      count(
        String(getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList.length),
      );
      let reportListItem =
        getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList.map(
          (element: ObservationTypeList) => ({
            id: element.id,
            category: element?.displayValue,
            status: element?.displayValue ? "Used" : "False",
            categoryVal: element?.id,
            acceptedPtCount: "30",
            ignoredPtCount: "20",
          }),
        );
      setDataGridArray(reportListItem);
    } else {
      setDataGridArray([] as any[]);
      count("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObservationTypeListRes]);

  const columns: GridColDef[] = [
    {
      field: "category",
      headerName: "Category",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      align: "center",
    },
    {
      field: "acceptedPtCount",
      headerName: "Intake Patient Count",
      minWidth: 150,
      align: "center",
    },
    {
      field: "ignoredPtCount",
      headerName: "Ignored Patient Count",
      minWidth: 150,
      align: "center",
    },
  ];

  return (
    <Box>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {notAdministratorMsg !== "" ? (
            <Alert severity="error">{notAdministratorMsg}</Alert>
          ) : (
            <Box>
              {dataGridArray.length > 0 ? (
                <DataGrid
                  rows={dataGridArray}
                  columns={columns}
                  disableSelectionOnClick
                  hideFooter
                  hideFooterPagination
                  experimentalFeatures={{ newEditingApi: true }}
                  autoHeight
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  getRowHeight={() => "auto"}
                />
              ) : (
                <MWExceptionList />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
