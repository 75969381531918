import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import MWSnackbar from "component/MWSnackbar";
import AddStaffDialog from "./../AddStaffDialog";
import { DiactiveStaffOfProviderOrgBody } from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/ToggleStaffOfOrganization/Model";
import {
  clearToggleStaffOfProviderOrgAPIRes,
  toggleProviderOrgStaffOfOrgApiCall,
} from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/ToggleStaffOfOrganization/API";
import { getStaffAdminListByProviderOrgId } from "redux/ProviderOrganizationDetails/ProviderOrganizationAdminStaffList/API";
import {
  GetProviderOrgStaffAdminListBody,
  StaffList,
} from "redux/ProviderOrganizationDetails/ProviderOrganizationAdminStaffList/Model";
import MWExceptionList from "component/MWExceptionList";

type Props = {
  viewOrgStaffListloader?: boolean | any;
};

export default function AdminList({ viewOrgStaffListloader }: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  // call Store
  const viewProviderOrgStaffList = useSelector((state: AppState) => state.staffAdminList);

  const deactivateHealthcampStaffValueList = useSelector(
    (state: AppState) => state.toggleProviderOrgStaffResponse,
  );
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const viewProviderOrgStaffListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 2,
    } as GetProviderOrgStaffAdminListBody;
    dispatch(getStaffAdminListByProviderOrgId(viewProviderOrgStaffListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const deactiveStaff = (id: string) => {
    let diactiveBody = {
      organizationId: organizationid,
      staffId: id,
    } as DiactiveStaffOfProviderOrgBody;
    dispatch(toggleProviderOrgStaffOfOrgApiCall(diactiveBody));
  };

  useEffect(() => {
    if (deactivateHealthcampStaffValueList?.DiactiveStaffOfProviderOrgRes?.message !== undefined) {
      setDiactiveMsg(deactivateHealthcampStaffValueList?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const viewProviderOrgStaffListBody = {
        organizationId: organizationid,
        isActiveOrAdmin: 2,
      } as GetProviderOrgStaffAdminListBody;
      dispatch(getStaffAdminListByProviderOrgId(viewProviderOrgStaffListBody, 1));
      dispatch(clearToggleStaffOfProviderOrgAPIRes());
    }
    if (deactivateHealthcampStaffValueList?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(deactivateHealthcampStaffValueList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateHealthcampStaffValueList]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  const [addProviderOrganizationStaffDialog, setAddHealthcampStaffDialog] = React.useState(false);
  const updateAddOrganizationStaffDialogValFromChild = (passedVal: boolean) => {
    setAddHealthcampStaffDialog(passedVal);
  };
  useEffect(() => {
    viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.numberOfPages !== undefined
      ? setPageListCount(
          String(viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.numberOfPages),
        )
      : setPageListCount("0");
    if (viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList !== undefined) {
      let dataPointArr = viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.map(
        (element: StaffList) => ({
          id: element.id,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
          phoneNumber: element.phoneNumber,
          userId: element.userId,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    if (viewProviderOrgStaffList?.errorMsg !== "") {
      setNotAdministratorMsg(viewProviderOrgStaffList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewProviderOrgStaffList]);

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Action",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deactiveStaff(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Deactive Staff
        </Button>
      ),
    },
  ];

  // const addHealthcampStaff = () => {
  //   setAddHealthcampStaffDialog(true);
  // };

  const StaffListOfProviderOrganization = (
    <>
      {viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList !== undefined ? (
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item></Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    {/* <Button
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={() => addHealthcampStaff()}
                    >
                      Add Organization Staff
                    </Button> */}
                  </Grid>
                  <Grid item>
                    <Typography>Page: {page}</Typography>
                  </Grid>
                  <Grid item>
                    <Pagination
                      color="primary"
                      count={Number(pageListCount) || 0}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      ) : null}

      <CardContent>
        {notAdministratorMsg !== "" ? (
          <Alert severity="error">{notAdministratorMsg}</Alert>
        ) : (
          <Box>
            {viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList &&
            viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length > 0 ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </CardContent>
    </>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
    }
  };

  return (
    <Box>
      {viewProviderOrgStaffList?.loading === true
        ? viewOrgStaffListloader(true)
        : viewOrgStaffListloader(false)}
      {StaffListOfProviderOrganization}
      {addProviderOrganizationStaffDialog ? (
        <AddStaffDialog
          itemId={organizationid}
          addOrganizationStaffDialogEnable={addProviderOrganizationStaffDialog}
          addOrganizationStaffDialogUpdateState={updateAddOrganizationStaffDialogValFromChild}
        />
      ) : null}
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
