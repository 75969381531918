import {
    APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL,
    APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING,
    APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS,
    APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG,
    APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES,
    AppoinmentListOfHealthcampActionTypes,
  } from "./ActionTypes";
  import { GetAppointmentListHealthcampRes } from "./Model";
  
  export const getAppoinmentListOfHealthcampLoadingAction = (
    loading: boolean,
  ): AppoinmentListOfHealthcampActionTypes => {
    return {
      type: APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING,
      loading: loading,
    };
  };
  
  export const getAppoinmentListOfHealthcampSuccessAction = (
    getAppoinmentListOfHealthcampResponse: GetAppointmentListHealthcampRes,
    successMsg: string,
  ): AppoinmentListOfHealthcampActionTypes => {
    return {
      type: APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS,
      payload: getAppoinmentListOfHealthcampResponse,
      successMsg: successMsg,
    };
  };
  
  export const getAppoinmentListOfHealthcampErrorAction = (
    getAppoinmentListOfHealthcampResponse: GetAppointmentListHealthcampRes,
    errMsg: string,
    status: number,
  ): AppoinmentListOfHealthcampActionTypes => {
    return {
      type: APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL,
      payload: getAppoinmentListOfHealthcampResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getAppoinmentListOfHealthcampUpdateAPIMsgAction = (
    getAppoinmentListOfHealthcampResponse: GetAppointmentListHealthcampRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): AppoinmentListOfHealthcampActionTypes => {
    return {
      type: APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG,
      payload: getAppoinmentListOfHealthcampResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getAppoinmentListOfHealthcampAPIResClearAction = (
    getAppoinmentListOfHealthcampResponse: GetAppointmentListHealthcampRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): AppoinmentListOfHealthcampActionTypes => {
    return {
      type: APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES,
      payload: getAppoinmentListOfHealthcampResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  