import {
  PatientDetailsActionTypes,
 PATIENT_DETAILS_FAIL,
 PATIENT_DETAILS_LOADING,
 PATIENT_DETAILS_SUCCESS,
 PatientDetailsState,
 PATIENT_DETAILS_UPDATE_API_MSG,
 PATIENT_DETAILS_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientDetailsById } from "./Model";

const initialStateGetPosts: PatientDetailsState = {
 loading: false,
 patientDetailsById: {} as PatientDetailsById,
 successMsg: "",
 errorMsg: "",
 status: 0,
};
export const  getPatientDetailsByIdReducer = (
 state = initialStateGetPosts,
 action:  PatientDetailsActionTypes,
): PatientDetailsState => {
 switch (action.type) {
   case PATIENT_DETAILS_LOADING:
     return {
       ...state,
       loading: action.loading,
     };
   case PATIENT_DETAILS_SUCCESS:
     return {
       ...state,
       patientDetailsById: action.payload,
       successMsg: action.successMsg,
     };
   case PATIENT_DETAILS_FAIL:
     return {
       ...state,
       patientDetailsById: action.payload,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   case PATIENT_DETAILS_UPDATE_API_MSG:
     return {
       ...state,
       patientDetailsById: action.payload,
       successMsg: action.successMsg,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   case PATIENT_DETAILS_UPDATE_API_RES:
     return {
       ...state,
       patientDetailsById: action.payload,
       successMsg: action.successMsg,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   default:
     return state;
 }
};

