import {
  GetJuryListByCompetitionActionTypes,
  JURY_LIST_BY_COMPETITION_ID_FAIL,
  JURY_LIST_BY_COMPETITION_ID_LOADING,
  JURY_LIST_BY_COMPETITION_ID_SUCCESS,
  GetJuryListByCompetitionState,
  COMPETITION_JURY_LIST_API_MSG,
  COMPETITION_JURY_LIST_API_RES,
} from "./ActionTypes";
import { GetJuryListByCompetitionDetails } from "./Model";

const initialStateGetPosts: GetJuryListByCompetitionState = {
  loading: false,
  getJuryListByCompetitionRes: {} as GetJuryListByCompetitionDetails,
  message: "",
  status: 0,
};
export const getJuryListByCompetitionReducer = (
  state = initialStateGetPosts,
  action: GetJuryListByCompetitionActionTypes,
): GetJuryListByCompetitionState => {
  switch (action.type) {
    case JURY_LIST_BY_COMPETITION_ID_LOADING:
      return {
        ...state,
        loading: true,
        getJuryListByCompetitionRes: {} as GetJuryListByCompetitionDetails,
      };
    case JURY_LIST_BY_COMPETITION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getJuryListByCompetitionRes: action.payload,
      };
    case JURY_LIST_BY_COMPETITION_ID_FAIL:
      return {
        ...state,
        loading: false,
        getJuryListByCompetitionRes: {} as GetJuryListByCompetitionDetails,
        message: action.message,
        status: action.status,
      };
    case COMPETITION_JURY_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        getJuryListByCompetitionRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case COMPETITION_JURY_LIST_API_RES:
      return {
        ...state,
        loading: false,
        getJuryListByCompetitionRes: {} as GetJuryListByCompetitionDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
