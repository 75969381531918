import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddRankForFinalRoundDetailsActionTypes } from "./ActionTypes";
import { AddRankForFinalRoundDetailsBody, AddRankForFinalRoundDetailsRes } from "./Model";
import {
  addRankForFinalRoundDetailsAPIResClearAction,
  addRankForFinalRoundDetailsErrorAction,
  addRankForFinalRoundDetailsLoadingAction,
  addRankForFinalRoundDetailsSuccessAction,
  addRankForFinalRoundDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as AddRankForFinalRoundDetailsRes;
export const addRankForFinalRoundApi = (
  payload: AddRankForFinalRoundDetailsBody,
) => {
  return function (dispatch: Dispatch<AddRankForFinalRoundDetailsActionTypes>) {
    dispatch(addRankForFinalRoundDetailsLoadingAction(true));
    axios
      .post(Url.baseUrl + `/competitionapp/api/competitionFinalRound/addRankForCompetitionFinalRound`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addRankForFinalRoundDetailsLoadingAction(false));
        dispatch(addRankForFinalRoundDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(addRankForFinalRoundDetailsLoadingAction(false));
        dispatch(
          addRankForFinalRoundDetailsErrorAction(
            {} as AddRankForFinalRoundDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const addRankForFinalRoundAPIResMsg = () => {
  return function (dispatch: Dispatch<AddRankForFinalRoundDetailsActionTypes>) {
    dispatch(
      addRankForFinalRoundDetailsAPIMsgAction(
        apiRes as AddRankForFinalRoundDetailsRes,
        "",
        0,
      ),
    );
  };
};

export const clearAddRakForFinalRoundAPIRes = () => {
  return function (dispatch: Dispatch<AddRankForFinalRoundDetailsActionTypes>) {
    dispatch(
      addRankForFinalRoundDetailsAPIResClearAction(
        {} as AddRankForFinalRoundDetailsRes,
        "",
        0,
      ),
    );
  };
};
