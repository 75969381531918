import {
  PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL,
  PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_LOADING,
  PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_SUCCESS,
  GetProviderOrganizationPatientListActionTypes,
} from "./ActionTypes";
import { GetProviderOrganizationPatientListDetails } from "./Model";


export const GetProviderOrganizationPatientListLoadingAction = (
  loading: boolean,
): GetProviderOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_LOADING,
    loading: loading,
  };
};

export const GetProviderOrganizationPatientListSuccessAction = (
  providerOrganizationPatientListResponse: GetProviderOrganizationPatientListDetails,
): GetProviderOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_SUCCESS,
    payload: providerOrganizationPatientListResponse,
  };
};

export const GetProviderOrganizationPatientListErrorAction = (
  providerOrganizationPatientListResponse: GetProviderOrganizationPatientListDetails,
  errMsg: string,
  status: number,
): GetProviderOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL,
    payload: providerOrganizationPatientListResponse,
    message: errMsg,
    status: status,
  };
};
export const GetProviderOrganizationPatientListAPIMsgAction = (
  providerOrganizationPatientListResponse: GetProviderOrganizationPatientListDetails,
  errMsg: string,
  status: number,
): GetProviderOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL,
    payload: providerOrganizationPatientListResponse,
    message: errMsg,
    status: status,
  };
};

export const GetProviderOrganizationPatientListAPIResClearAction = (
  providerOrganizationPatientListResponse: GetProviderOrganizationPatientListDetails,
  errMsg: string,
  status: number,
): GetProviderOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL,
    payload: providerOrganizationPatientListResponse,
    message: errMsg,
    status: status,
  };
};
