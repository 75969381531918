import {
  IMPORT_DATA_FROM_RECENT_CALL_FAIL,
  IMPORT_DATA_FROM_RECENT_CALL_LOADING,
  IMPORT_DATA_FROM_RECENT_CALL_SUCCESS,
  IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG,
  IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES,
  ImportDataFromRecentCallActionTypes,
} from "./ActionTypes";
import { ImportDataFromRecentCallRes } from "./Model";

export const importDataFromRecentCallLoadingAction = (
  loading: boolean,
): ImportDataFromRecentCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_RECENT_CALL_LOADING,
    loading: loading,
  };
};

export const importDataFromRecentCallSuccessAction = (
  importDataFromRecentCallRes: ImportDataFromRecentCallRes,
  successMsg: string,
): ImportDataFromRecentCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_RECENT_CALL_SUCCESS,
    payload: importDataFromRecentCallRes,
    successMsg: successMsg,
  };
};

export const importDataFromRecentCallErrorAction = (
  importDataFromRecentCallRes: ImportDataFromRecentCallRes,
  errMsg: string,
  status: number,
): ImportDataFromRecentCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_RECENT_CALL_FAIL,
    payload: importDataFromRecentCallRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const importDataFromRecentCallUpdateAPIMsgAction = (
  importDataFromRecentCallRes: ImportDataFromRecentCallRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ImportDataFromRecentCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG,
    payload: importDataFromRecentCallRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const importDataFromRecentCallAPIResClearAction = (
  importDataFromRecentCallRes: ImportDataFromRecentCallRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ImportDataFromRecentCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES,
    payload: importDataFromRecentCallRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
