import React, { useEffect, useState } from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getCorporatePackageList } from "../../redux/CorporatePackage/Package/ApiCall";
import { getCorporatePackageToggleStatus } from "../../redux/CorporatePackage/TogglePackageStatus/ApiCall";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, CardContent, IconButton, Snackbar } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CorporatePackageList } from "../../redux/CorporatePackage/Package/Model";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../component/MWExceptionList";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function CorporatePackageActive() {
  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const gotoDetails = (id: any) => {
    history.push(`/activecorporatepackagesdetails/${id}`);
  };
  // call Store
  const corporatepackageToggleLoadingValue = useSelector(
    (state: AppState) => state.corporatePackageTogglevalue.loading,
  );
  const corporatepackageToggleStatus = useSelector(
    (state: AppState) => state.corporatePackageTogglevalue.corporatePackageToggleValue.status,
  );

  // Change Corporate package Status Toggle success toast
  const [
    sucessCorporatePackageStatusToggleToastActive,
    setSucessCorporatePackageStatusToggleToastActive,
  ] = useState(false);
  const toggleSuccessCorporatePackageStatusToggleActive = () => {
    setSucessCorporatePackageStatusToggleToastActive(
      (sucessCorporatePackageStatusToggleToastActive) =>
        !sucessCorporatePackageStatusToggleToastActive,
    );
    dispatch(getCorporatePackageList(corporatePackageBody));
  };

  const toastSucessCorporatePackageStatusToggleMarkup =
    sucessCorporatePackageStatusToggleToastActive ? (
      <Snackbar
        autoHideDuration={1000}
        onClose={toggleSuccessCorporatePackageStatusToggleActive}
        message="Selected package status changes successfully"
        action={action}
      />
    ) : null;

  // Change Corporate package Status Toggle error toast
  const [
    errorCorporatePackageStatusToggleToastActive,
    setErrorCorporatePackageStatusToggleToastActive,
  ] = useState(false);
  const toggleErrorCorporatePackageStatusToggleActive = () => {
    setErrorCorporatePackageStatusToggleToastActive(
      (errorCorporatePackageStatusToggleToastActive) =>
        !errorCorporatePackageStatusToggleToastActive,
    );
  };
  const toastErrorCorporatePackageStatusToggleMarkup =
    errorCorporatePackageStatusToggleToastActive ? (
      <Snackbar
        autoHideDuration={1000}
        onClose={toggleErrorCorporatePackageStatusToggleActive}
        message="Something went wrong"
        action={action}
      />
    ) : null;

  // Change Corporate package status toggle api
  function changePackageStatus(packageId: any) {
    dispatch(getCorporatePackageToggleStatus(packageId));
    setSucessCorporatePackageStatusToggleToastActive(
      (sucessCorporatePackageStatusToggleToastActive) =>
        !sucessCorporatePackageStatusToggleToastActive,
    );
    setErrorCorporatePackageStatusToggleToastActive(
      (errorCorporatePackageStatusToggleToastActive) =>
        !errorCorporatePackageStatusToggleToastActive,
    );
  }
  // get corporate package list body
  const corporatePackageBody = {
    active: Number(1),
  };
  useEffect(() => {
    dispatch(getCorporatePackageList(corporatePackageBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const activeCorporatePackageList = useSelector((state: AppState) => state.corporatePackageValue);
  const activeCorporateListValues = activeCorporatePackageList.corporatePackageListValue;
  useEffect(() => {
    if (activeCorporateListValues !== undefined) {
      let dataPointArr = activeCorporateListValues.map((element: CorporatePackageList) => ({
        id: element.id,
        name: element.name,
        discountFactor: element.discountFactor,
        action: element.id,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCorporatePackageList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View Details",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    { field: "name", headerName: "Package Name", minWidth: 250, flex: 1 },
    { field: "discountFactor", headerName: "Discount Factor", minWidth: 120, align: "right" },
    {
      field: "action",
      headerName: "Change Status",
      minWidth: 110,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <IconButton
          color="primary"
          onClick={() => {
            changePackageStatus(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          <CurrencyExchangeIcon />
        </IconButton>
      ),
    },
    { field: "", headerName: "Total No. of Patient Team", minWidth: 170, align: "right" },
  ];

  const actualPageMarkup = (
    <Card>
      <CardContent>
        {activeCorporateListValues && activeCorporateListValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(activeCorporatePackageList.loading || corporatepackageToggleLoadingValue),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      {actualPageMarkup}
      {Number(corporatepackageToggleStatus)! === 1
        ? toastSucessCorporatePackageStatusToggleMarkup
        : ""}
      {Number(corporatepackageToggleStatus)! !== 1
        ? toastErrorCorporatePackageStatusToggleMarkup
        : ""}
    </Box>
  );
}
