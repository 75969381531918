import { PageCount, GetPhotoJudgingDetailsListDetails } from "./Model";

// Patient Demographics Loading State
export const GET_PHOTO_JUDGING_LIST_ID_LOADING = "GET_PHOTO_JUDGING_LIST_ID_LOADING";
export const GET_PHOTO_JUDGING_LIST_ID_SUCCESS = "GET_PHOTO_JUDGING_LIST_ID_SUCCESS";
export const GET_PHOTO_JUDGING_LIST_ID_FAIL = "GET_PHOTO_JUDGING_LIST_ID_FAIL";
export const GET_PHOTO_JUDGING_LIST_ID_DATA = "GET_PHOTO_JUDGING_LIST_ID_DATA";
export const GET_PHOTO_JUDGING_LIST_ID_API_MSG = "GET_PHOTO_JUDGING_LIST_ID_API_MSG";
export const GET_PHOTO_JUDGING_LIST_ID_API_RES = "GET_PHOTO_JUDGING_LIST_ID_API_RES";

export interface GetPhotoJudgingDetailsListState {
  getPhotoJudgingDetailsListRes: GetPhotoJudgingDetailsListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface GetPhotoJudgingDetailsListLoading {
  type: typeof GET_PHOTO_JUDGING_LIST_ID_LOADING;
  loading: boolean;
}
export interface GetPhotoJudgingDetailsListSuccess {
  type: typeof GET_PHOTO_JUDGING_LIST_ID_SUCCESS;
  payload: GetPhotoJudgingDetailsListDetails;
}
export interface GetPhotoJudgingDetailsListFail {
  type: typeof GET_PHOTO_JUDGING_LIST_ID_FAIL;
  payload: GetPhotoJudgingDetailsListDetails;
  message: string;
  status: number;
}
export interface GetPhotoJudgingDetailsListAPIMsg {
  type: typeof GET_PHOTO_JUDGING_LIST_ID_API_MSG;
  payload: GetPhotoJudgingDetailsListDetails;
  message: string;
  status: number;
}
export interface GetPhotoJudgingDetailsListRes {
  type: typeof GET_PHOTO_JUDGING_LIST_ID_API_RES;
  payload: GetPhotoJudgingDetailsListDetails;
  message: string;
  status: number;
}
interface GetPhotoJudgingDetailsListDataAction {
  type: typeof GET_PHOTO_JUDGING_LIST_ID_DATA;
  payload: GetPhotoJudgingDetailsListDetails;
  pageCount: PageCount;
}

export type GetPhotoJudgingDetailsListActionTypes =
  | GetPhotoJudgingDetailsListDataAction
  | GetPhotoJudgingDetailsListLoading
  | GetPhotoJudgingDetailsListSuccess
  | GetPhotoJudgingDetailsListFail
  | GetPhotoJudgingDetailsListAPIMsg
  | GetPhotoJudgingDetailsListRes;
