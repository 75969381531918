import {
  PATIENT_QURIES_LIST_API_MSG,
  PATIENT_QURIES_LIST_CLEAR_API_RES,
  PATIENT_QURIES_LIST_FAIL,
  PATIENT_QURIES_LIST_LOADING,
  PATIENT_QURIES_LIST_SUCCESS,
  PatientQuriesListDataState,
  PatientQuriesListActionTypes,
} from "./ActionTypes";
import { PatientQuriesListResponse } from "./Model";

const initialStateGetPosts: PatientQuriesListDataState = {
  loading: false,
  patientQueriesRes: {} as PatientQuriesListResponse,
  message: "",
  status: 0,
};

export const patientQuriesListReducer = (
  state = initialStateGetPosts,
  action: PatientQuriesListActionTypes,
): PatientQuriesListDataState => {
  switch (action.type) {
    case PATIENT_QURIES_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PATIENT_QURIES_LIST_SUCCESS:
      return {
        ...state,
        patientQueriesRes: action.payload,
      };
    case PATIENT_QURIES_LIST_FAIL:
      return {
        ...state,
        patientQueriesRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_QURIES_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        patientQueriesRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_QURIES_LIST_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        patientQueriesRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
