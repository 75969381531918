import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { CompetitionListByPatientIdActionTypes } from "./ActionTypes";
import { CompetitionListByPatientIdBody, CompetitionListByPatientIdDetails } from "./Model";
import {
  CompetitionListByPatientIdAPIResClearAction,
  CompetitionListByPatientIdErrorAction,
  CompetitionListByPatientIdLoadingAction,
  CompetitionListByPatientIdSuccessAction,
  CompetitionListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as CompetitionListByPatientIdDetails;
export const competitionListByPatientIdApi = (
  patientid: CompetitionListByPatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<CompetitionListByPatientIdActionTypes>) {
    dispatch(CompetitionListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionListAsStaff/competition_list_by_patient_as_staff?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(CompetitionListByPatientIdLoadingAction(false));
        dispatch(
          CompetitionListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as CompetitionListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient competition list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(CompetitionListByPatientIdLoadingAction(false));
        dispatch(
          CompetitionListByPatientIdErrorAction(
            {} as CompetitionListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateCompetitionListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<CompetitionListByPatientIdActionTypes>) {
    dispatch(
      CompetitionListByPatientIdAPIMsgAction(apiRes as CompetitionListByPatientIdDetails, "", "", 0),
    );
  };
};

export const clearCompetitionListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<CompetitionListByPatientIdActionTypes>) {
    dispatch(
      CompetitionListByPatientIdAPIResClearAction({} as CompetitionListByPatientIdDetails, "", "", 0),
    );
  };
};
