import {
    CorporatePackagesTypes, CORPORATE_LIST_FAIL,
    CORPORATE_LIST_LOADING, CORPORATE_LIST_SUCCESS,
    GetCorporatePackageListStateType
} from "./ActionTypes";

const initialStateGetActiveCorporateList: GetCorporatePackageListStateType = { loading: false, corporatePackageListValue: [] };

export const getCorporatePackageListReducer = (state = initialStateGetActiveCorporateList, action: CorporatePackagesTypes): GetCorporatePackageListStateType => {
    switch (action.type) {
        case CORPORATE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                corporatePackageListValue: action.payload
            };
        case CORPORATE_LIST_LOADING:
            return {
                ...state,
                loading: true,
                corporatePackageListValue: []
            };
        case CORPORATE_LIST_FAIL:
            return {
                ...state,
                corporatePackageListValue: [],
                loading: false
            };
        default:
            return state;
    }
};