import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import AdminLoader from "pages/AdminLoader";
import { getObservationTypeDetails } from "redux/GetObservationTypeList/API";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { clearGetVitalCollectionActiveAPIRes } from "redux/VitalCollectionStatus/VitalActiveStatus/API";
import { clearSessionPatientAvailableByObservationTypeAPIRes } from "redux/HealthcampSessionList/AvailablePatientObservationType/API";
import { clearGetPatientListBySessionIdAPIRes } from "redux/HealthcampSessionList/PatientListBySessionId/API";
import {
  clearPatientDataRangeBySessionIdTypeAPIRes,
  updatePatientDataRangeBySessionIdTypeAPIResMsg,
} from "redux/HealthcampSessionList/PatientDataRangeBySessionId/API";
import {
  filterPatientDetailsObservation,
  updateFilterPatientDetailsByObservationAPIResMsg,
} from "redux/HealthcampSessionList/FilterPatientDetails/API";
import { FilterPatientDetailsByObservationBody } from "redux/HealthcampSessionList/FilterPatientDetails/Model";
import { clearGetSessionPatientDetailsListAPIRes } from "redux/HealthcampSessionList/SessionPatientDetailsList/API";
import { useHistory } from "react-router-dom";

type Props = {
  filterDialogEnable?: boolean;
  filterDialogUpdateState: (value: boolean) => void;
  sessionId: string | any;
  obType: string | any;
  date: string | any;
  operationType: string | any;
  parameters: any;
};

export default function FilterPatientDetailsDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  sessionId,
  obType,
  date,
  operationType,
  parameters,
}: Props) {
  const operationTypeValue = [
    {
      id: 1,
      displayValue: "With in Range",
      name: "withInRange",
      sequence: 1,
    },
    {
      id: 2,
      displayValue: "Greater",
      name: "greater",
      sequence: 2,
    },
    {
      id: 3,
      displayValue: "Less Than",
      name: "lessThan",
      sequence: 3,
    },
    {
      id: 4,
      displayValue: "Equal",
      name: "equal",
      sequence: 4,
    },
    {
      id: 4,
      displayValue: "Not Equal",
      name: "notEqual",
      sequence: 4,
    },
    {
      id: 5,
      displayValue: "Out Of Range",
      name: "outOfRange",
      sequence: 5,
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  // store call
  const filterpatientDetailsByObservationList = useSelector(
    (state: AppState) => state.filterPatientDetailsByObservation,
  );
  const observationTypeList = useSelector((state: AppState) => state.getObservationTypeValue);
  const vitalCollectionActiveStatusList = useSelector(
    (state: AppState) => state.getVitalCollectionActiveList,
  );
  const availableDataByObservationType = useSelector(
    (state: AppState) => state.avaliableObservationResponse,
  );
  const patientDataRangeValues = useSelector(
    (state: AppState) => state.patientDataRangBySessionIdResult,
  );
  const [filterDialogState, setFilterDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setFilterDialogState(false);
    filterDialogUpdateState(false);
    // dispatch(updateFilterPatientDetailsByObservationAPIResMsg());
    dispatch(updatePatientDataRangeBySessionIdTypeAPIResMsg());
  };

  const [patientObservationTypeList, setPatientObservationTypeList] = useState([] as any[]);
  const [selectedPatientObservatonType, setSelectedPatientObservatonType] =
    useState<ObservationTypeList | null>(null);
  //   const [patientObservatonType, setPatientObservatonType] = useState<ObservationTypeList | null>(
  //     null,
  //   );
  const [startDate, setStartDate] = React.useState<Dayjs | null | string>(
    dayjs(new Date()).format("YYYY-MM-DD")!,
  );
  const [selectedPatientOperationType, setSelectedPatientOperationType] = useState("");
  const handleDateChange = (filterDateValue: Dayjs | null | string) => {
    setStartDate(dayjs(filterDateValue));
  };
  const [parameterName, setParameterName] = useState("");
  let str1 = parameterName;
  const parametersValue = str1?.split(",");

  const patientObservationTypeChange = (
    _selectedObservationVal: any,
    newvalue: ObservationTypeList,
  ) => {
    setSelectedPatientObservatonType(newvalue);
  };
  const patientOperationTypeChange = (operationType: any) => {
    setSelectedPatientOperationType(operationType.name);
  };
  useEffect(() => {
    dispatch(getObservationTypeDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (obType !== null && date !== null && operationType !== null && parameters !== null) {
      const words = date?.split("T");
      if (observationTypeList.GetObservationTypeResponse.observationTypeList !== undefined) {
        let result = observationTypeList.GetObservationTypeResponse.observationTypeList.find(
          (obj: any) => {
            return obj.name === obType;
          },
        );
        setSelectedPatientObservatonType(result!);
      }
      setStartDate(words[0]);
    }
    setSelectedPatientOperationType(operationType);
    setParameterName(parameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obType, date, operationType, parameters]);

  useEffect(() => {
    if (observationTypeList?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      setPatientObservationTypeList(
        observationTypeList?.GetObservationTypeResponse?.observationTypeList,
      );
    } else setPatientObservationTypeList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationTypeList]);

  useEffect(() => {
    if (filterpatientDetailsByObservationList?.successMsg !== "") {
      // handleDialogClose();
      filterDialogUpdateState(false);
      dispatch(updateFilterPatientDetailsByObservationAPIResMsg());
      dispatch(clearGetSessionPatientDetailsListAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterpatientDetailsByObservationList]);

  useEffect(() => {
    if (vitalCollectionActiveStatusList?.successMsg !== "") {
      handleDialogClose();
      dispatch(clearSessionPatientAvailableByObservationTypeAPIRes());
      dispatch(clearGetPatientListBySessionIdAPIRes());
      dispatch(clearPatientDataRangeBySessionIdTypeAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalCollectionActiveStatusList]);

  useEffect(() => {
    if (patientDataRangeValues?.successMsg !== "") {
      handleDialogClose();
      dispatch(clearSessionPatientAvailableByObservationTypeAPIRes());
      dispatch(clearGetPatientListBySessionIdAPIRes());
      dispatch(clearGetVitalCollectionActiveAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataRangeValues]);

  React.useEffect(() => {
    setFilterDialogState(filterDialogEnable!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const searchObservation = () => {
    history.push(
      `${
        window.location.pathname +
        "?obType=" +
        selectedPatientObservatonType?.name +
        "&date=" +
        dayjs(decodeURIComponent(`${startDate}`)).format("YYYY-MM-DD") +
        "&operationType=" +
        selectedPatientOperationType +
        "&parameters=" +
        parametersValue
      }`,
    );
    if (selectedPatientObservatonType?.name !== undefined) {
      const PatientSearchByObservationBody = {
        patientObservationType: String(selectedPatientObservatonType?.name),
        healthcampSessionId: sessionId,
        startDate: startDate,
        parameters: parametersValue,
        operationType: selectedPatientOperationType,
      } as FilterPatientDetailsByObservationBody;
      dispatch(filterPatientDetailsObservation(PatientSearchByObservationBody, 1));
    } else {
      console.log("Show error");
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={filterDialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {availableDataByObservationType?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" fontWeight={"bold"}>
                Filter Patient Details by Observations Value
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                value={selectedPatientObservatonType}
                id="templateId"
                options={patientObservationTypeList}
                onChange={(event: any, newValue: any) => {
                  patientObservationTypeChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Filter by observation type"
                    size="small"
                  />
                )}
                getOptionLabel={(option) => `${option.name}`}
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  label=" Start Date"
                  onChange={(date) => handleDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            mt={1}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                value={
                  operationTypeValue.find(
                    (element: any) => element.name === selectedPatientOperationType,
                  ) || null
                }
                id="operationId"
                options={operationTypeValue}
                onChange={(_event: any, newValue: any) => {
                  patientOperationTypeChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Filter by operation type" size="small" />
                )}
                getOptionLabel={(option) => `${option.name}`}
                fullWidth
                // disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label="Parameter"
                id="fullWidth"
                size="small"
                placeholder="Enter parameter"
                value={parameterName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setParameterName(event.target.value);
                }}
                disabled={selectedPatientOperationType !== "" ? false : true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={searchObservation}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
