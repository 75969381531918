import { AssignPatientDetailsTypeRes } from "./Model";

export const ADD_PATIENT_DETAILS_TYPE_LOADING = "ADD_PATIENT_DETAILS_TYPE_LOADING";
export const ADD_PATIENT_DETAILS_TYPE_SUCCESS = "Add_PATIENT_DETAILS_TYPE_SUCCESS";
export const ADD_PATIENT_DETAILS_TYPE_FAIL = "ADD_PATIENT_DETAILS_TYPE_FAIL";
export const ADD_PATIENT_DETAILS_TYPE_DATA = "ADD_PATIENT_DETAILS_TYPE_DATA";
export const ADD_PATIENT_DETAILS_TYPE_API_MSG = "ADD_PATIENT_DETAILS_TYPE_API_MSG";
export const ADD_PATIENT_DETAILS_TYPE_API_RES = "ADD_PATIENT_DETAILS_TYPE_API_RES";
export const ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM = "ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM";

export interface AssignPatientDetailsTypeState {
  AssignPatientDetailsTypeRes: AssignPatientDetailsTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
  selectedItem: any[];
}
export interface AssignPatientDetailsTypeLoading {
  type: typeof ADD_PATIENT_DETAILS_TYPE_LOADING;
  loading: boolean;
}
export interface AssignPatientDetailsTypeSuccess {
  type: typeof ADD_PATIENT_DETAILS_TYPE_SUCCESS;
  payload: AssignPatientDetailsTypeRes;
  successMsg: string;
}
export interface AssignPatientDetailsTypeFail {
  type: typeof ADD_PATIENT_DETAILS_TYPE_FAIL;
  payload: AssignPatientDetailsTypeRes;
  errorMsg: string;
  status: number;
}
export interface AssignPatientDetailsTypeAPIMsg {
  type: typeof ADD_PATIENT_DETAILS_TYPE_API_MSG;
  payload: AssignPatientDetailsTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AssignPatientDetailsTypeAPIRes {
  type: typeof ADD_PATIENT_DETAILS_TYPE_API_RES;
  payload: AssignPatientDetailsTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AssignPatientDetailsTypeSelectedItem {
  type: typeof ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM;
  selectedItem: any[];
}

export type AssignPatientDetailsTypeActionTypes =
  | AssignPatientDetailsTypeLoading
  | AssignPatientDetailsTypeSuccess
  | AssignPatientDetailsTypeFail
  | AssignPatientDetailsTypeAPIMsg
  | AssignPatientDetailsTypeAPIRes
  | AssignPatientDetailsTypeSelectedItem;
