import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import MWPageTitle from "component/MWPageTitle";
import MWExceptionList from "component/MWExceptionList";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { getPatientCaseListByPatientIdApi } from "redux/GetPatientCaseListByPatientId/API";
import {
  GetPatientCaseListByPatientIdBody,
  PatientCaseList,
} from "redux/GetPatientCaseListByPatientId/Model";
import { addExistingPatientCaseToSessionApiCall, clearAddExistingPatientCaseToSessionAPIRes } from "redux/HealthcampSessionList/AddExistingPatientCase/API";
import { getPatientCaseListBySessionIdApi } from "redux/HealthcampSessionList/GetPatientCaseListBySessionId/API";
import { GetPatientCaseListBySessionIdBody } from "redux/HealthcampSessionList/GetPatientCaseListBySessionId/Model";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  healthcampSessionId?: string | any;
  patientid?: string | any;
};

export default function AddExistingPatientCaseDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  healthcampSessionId,
  patientid,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearAddExistingPatientCaseToSessionAPIRes());
  };
  const addExistingPatientResponse = useSelector(
    (state: AppState) => state.addExistingPatientToSessionResponse,
  );

  const caseHistory = useSelector((state: AppState) => state.getPatientCaseListResult);
  useEffect(() => {
    if (addExistingPatientResponse?.successMsg !== "") {
      handleDialogClose();
      dispatch(
        getPatientCaseListBySessionIdApi({
          healthcampSessionId: healthcampSessionId,
        } as GetPatientCaseListBySessionIdBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExistingPatientResponse]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    dispatch(
      getPatientCaseListByPatientIdApi({
        patientId: patientid,
      } as GetPatientCaseListByPatientIdBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const addExistingPatientCase = (patientcaseId: number) => {
    if (patientcaseId !== null) {
      let apiBody = {
        healthcampSessionId: healthcampSessionId,
        patientCaseId: String(patientcaseId)!,
      } as any;
      dispatch(addExistingPatientCaseToSessionApiCall(apiBody));
    }
  };

  const caseListPage = (
    <Box py={2}>
      {caseHistory?.getPatientCaseListByPatientIdRes?.patientCaseList !== undefined ? (
        caseHistory?.getPatientCaseListByPatientIdRes?.patientCaseList.length > 0 ? (
          caseHistory?.getPatientCaseListByPatientIdRes?.patientCaseList.map(
            (element: PatientCaseList) => (
              <Box key={element.caseId} style={{ textDecoration: "none", textTransform: "none" }}>
                <List disablePadding dense>
                  <ListItem>
                    <ListItemIcon>
                      <VisibilityOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontWeight="bold">
                          {element?.whyWant2ndOpinion !== undefined
                            ? element?.whyWant2ndOpinion !== ""
                              ? element?.whyWant2ndOpinion
                              : null
                            : null}
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography variant="subtitle2">
                            {element?.secondOpinionReasons
                              ? element?.secondOpinionReasons !== undefined
                                ? element?.secondOpinionReasons !== ""
                                  ? element?.secondOpinionReasons
                                  : null
                                : null
                              : null}
                          </Typography>
                          {element?.consultationType ? (
                            <Chip
                              size="small"
                              color="primary"
                              label={
                                element?.consultationType !== undefined
                                  ? element?.consultationType !== ""
                                    ? element?.consultationType
                                    : null
                                  : null
                              }
                            />
                          ) : null}
                        </Box>
                      }
                    />
                    <Button
                      onClick={() => addExistingPatientCase(element?.caseId)}
                      variant="outlined"
                    >
                      Add Existing Patient Case
                    </Button>
                  </ListItem>
                  <Divider />
                </List>
              </Box>
            ),
          )
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        {addExistingPatientResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Patient Case List</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Card>
            <CardContent>
              <Box sx={{ mb: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  my={1}
                >
                  <Grid item>
                    <MWPageTitle title={"Patient Case List"} enableCount={true} />
                  </Grid>
                  <Grid item></Grid>
                </Grid>
                <Divider />
              </Box>
              <Box>{caseListPage}</Box>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
