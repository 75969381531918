import {
  PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL,
  PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING,
  PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS,
  PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG,
  PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES,
  GetProviderOrgStaffAdminListActionTypes,
} from "./ActionTypes";
import { ProviderOrgStaffAdminRes } from "./Model";

export const getProviderOrgStaffAdminListLoadingAction = (
  loading: boolean,
): GetProviderOrgStaffAdminListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING,
    loading: loading,
  };
};

export const getProviderOrgStaffAdminListSuccessAction = (
  getProviderOrgStaffAdminListresponse: ProviderOrgStaffAdminRes,
  successMsg: string,
): GetProviderOrgStaffAdminListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS,
    payload: getProviderOrgStaffAdminListresponse,
    successMsg: successMsg,
  };
};

export const getProviderOrgStaffAdminListErrorAction = (
  getProviderOrgStaffAdminListresponse: ProviderOrgStaffAdminRes,
  errMsg: string,
  status: number,
): GetProviderOrgStaffAdminListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL,
    payload: getProviderOrgStaffAdminListresponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getProviderOrgStaffAdminListUpdateAPIMsgAction = (
  getProviderOrgStaffAdminListresponse: ProviderOrgStaffAdminRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetProviderOrgStaffAdminListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG,
    payload: getProviderOrgStaffAdminListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getProviderOrgStaffAdminListAPIResClearAction = (
  getProviderOrgStaffAdminListresponse: ProviderOrgStaffAdminRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetProviderOrgStaffAdminListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES,
    payload: getProviderOrgStaffAdminListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
