import {
    AddPatientDetailsTypeToSessionListActionTypes,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS,
    AddPatientDetailsTypeToSessionListState,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES,
  } from "./ActionTypes";
  import { AddPatientDetailsTypeToSessionRes } from "./Model";
  
  const initialStateGetPosts: AddPatientDetailsTypeToSessionListState = {
    loading: false,
    addPatientDetailsTypeToSessionRes: {} as AddPatientDetailsTypeToSessionRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const addPatientDetailsTypeToSessionReducer = (
    state = initialStateGetPosts,
    action: AddPatientDetailsTypeToSessionListActionTypes,
  ): AddPatientDetailsTypeToSessionListState => {
    switch (action.type) {
      case ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS:
        return {
          ...state,
          addPatientDetailsTypeToSessionRes: action.payload,
          successMsg: action.successMsg,
        };
      case ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL:
        return {
          ...state,
          addPatientDetailsTypeToSessionRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG:
        return {
          ...state,
          addPatientDetailsTypeToSessionRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES:
        return {
          ...state,
          addPatientDetailsTypeToSessionRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  