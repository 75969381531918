import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorOnboardingIncompleteList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { DocotorOnboardIncompleteBody } from "../../redux/doctorsOnboardingImcomplete/Model";
import { onBoardDoctorIncomplete } from "../../redux/doctorsOnboardingImcomplete/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function DoctorsOnboardingImcomplete() {
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [listCount, setListCount] = React.useState("");
  // approve reject api call........
  const doctorOnBoardingApproveOrReject = (doctorId: any, approveOrreject: any) => {
    dispatch(
      onBoardDoctorIncomplete({
        isApproved: approveOrreject,
        onboardReqId: doctorId,
      } as DocotorOnboardIncompleteBody),
    );
  };

  useEffect(() => {
    dispatch(getDoctorOnboardingIncompleteList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const doctorOnboardingIncompleteListApi = () => {
    dispatch(getDoctorOnboardingIncompleteList());
  };

  // Api Call from Store....
  const docotorList = useSelector((state: AppState) => state.doctorIncompleteValue);
  const docIncompleteList = docotorList.doctorIncompleteValue;
  useEffect(() => {
    dispatch(toggleAppBarLoading(docotorList?.loading));
    docIncompleteList?.length !== undefined
      ? setListCount(String(docIncompleteList?.length))
      : setListCount("0");
    if (docIncompleteList !== undefined) {
      let dataPointArr = docIncompleteList.map((element: any) => ({
        id: element.id,
        doctorId: element.id,
        name: element.fullName,
        qualification: element.qualification,
        specialty: element.specialty,
        yearsOfExperience: element.yearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docotorList]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      editable: true,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "qualification",
      headerName: "Qualification",
      editable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "specialty",
      headerName: "Speciality",
      type: "string",
      minWidth: 250,
      align: "left",
      flex: 1,
    },
    {
      field: "yearsOfExperience",
      headerName: "Years of Experience",
      sortable: false,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "doctorId",
      headerName: "Approve",
      minWidth: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<CheckIcon />}
          onClick={() => {
            doctorOnBoardingApproveOrReject(params.row.doctorId, "True");
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          Approve
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Reject",
      minWidth: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<CloseIcon />}
          onClick={() => {
            doctorOnBoardingApproveOrReject(params.row.doctorId, "False");
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          Reject
        </Button>
      ),
    },
  ];

  const doctorOnBoardingIncompleteList = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                title="Doctor's On-Boarding Incomplete"
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={doctorOnboardingIncompleteListApi}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {docIncompleteList && docIncompleteList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return <Box>{doctorOnBoardingIncompleteList}</Box>;
}
