import {
  SESSION_LIST_FAIL,
  SESSION_LIST_LOADING,
  SESSION_LIST_SUCCESS,
  SESSION_LIST_UPDATE_API_MSG,
  SESSION_LIST_UPDATE_API_RES,
  GetSessionListActionTypes,
} from "./ActionTypes";
import { GetSessionListRes } from "./Model";

export const getSessionListLoadingAction = (
  loading: boolean,
): GetSessionListActionTypes => {
  return {
    type: SESSION_LIST_LOADING,
    loading: loading,
  };
};

export const getSessionListSuccessAction = (
  getSessionListResponse: GetSessionListRes,
  successMsg: string,
): GetSessionListActionTypes => {
  return {
    type: SESSION_LIST_SUCCESS,
    payload: getSessionListResponse,
    successMsg: successMsg,
  };
};

export const getSessionListErrorAction = (
  getSessionListResponse: GetSessionListRes,
  errMsg: string,
  status: number,
): GetSessionListActionTypes => {
  return {
    type: SESSION_LIST_FAIL,
    payload: getSessionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getSessionListUpdateAPIMsgAction = (
  getSessionListResponse: GetSessionListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetSessionListActionTypes => {
  return {
    type: SESSION_LIST_UPDATE_API_MSG,
    payload: getSessionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getSessionListAPIResClearAction = (
  getSessionListResponse: GetSessionListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetSessionListActionTypes => {
  return {
    type: SESSION_LIST_UPDATE_API_RES,
    payload: getSessionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
