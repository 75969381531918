import { GetProviderOrgStaffListRes } from "./Model";
export const PROVIDER_ORG_STAFF_LIST_LOADING = "PROVIDER_ORG_STAFF_LIST_LOADING";
export const PROVIDER_ORG_STAFF_LIST_SUCCESS = "PROVIDER_ORG_STAFF_LIST_SUCCESS";
export const PROVIDER_ORG_STAFF_LIST_FAIL = "PROVIDER_ORG_STAFF_LIST_FAIL";
export const PROVIDER_ORG_STAFF_LIST_UPDATE_API_MSG = "PROVIDER_ORG_STAFF_LIST_UPDATE_API_MSG";
export const PROVIDER_ORG_STAFF_LIST_UPDATE_API_RES = "PROVIDER_ORG_STAFF_LIST_UPDATE_API_RES";

export interface GetProviderOrgStaffListState {
  getProviderOrgStaffList: GetProviderOrgStaffListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgStaffListLoading {
  type: typeof PROVIDER_ORG_STAFF_LIST_LOADING;
  loading: boolean;
}
export interface GetProviderOrgStaffListSuccess {
  type: typeof PROVIDER_ORG_STAFF_LIST_SUCCESS;
  payload: GetProviderOrgStaffListRes;
  successMsg: string;
}
export interface GetProviderOrgStaffListFail {
  type: typeof PROVIDER_ORG_STAFF_LIST_FAIL;
  payload: GetProviderOrgStaffListRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgStaffListUpdateAPIMsg {
  type: typeof PROVIDER_ORG_STAFF_LIST_UPDATE_API_MSG;
  payload: GetProviderOrgStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgStaffListUpdateAPIRes {
  type: typeof PROVIDER_ORG_STAFF_LIST_UPDATE_API_RES;
  payload: GetProviderOrgStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetProviderOrgStaffListActionTypes =
  | GetProviderOrgStaffListLoading
  | GetProviderOrgStaffListSuccess
  | GetProviderOrgStaffListFail
  | GetProviderOrgStaffListUpdateAPIMsg
  | GetProviderOrgStaffListUpdateAPIRes;
