import {
  PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL,
  PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING,
  PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
  PatientSearchListByObservationTypeActionTypes,
} from "./ActionTypes";
import { PatientSearchListByObservationTypeDetails } from "./Model";

export const PatientSearchListByObservationTypeLoadingAction = (
  loading: boolean,
): PatientSearchListByObservationTypeActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const PatientSearchListByObservationTypeSuccessAction = (
  patientSearchObservationTypeResponse: PatientSearchListByObservationTypeDetails,
  successMsg: string,
): PatientSearchListByObservationTypeActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS,
    payload: patientSearchObservationTypeResponse,
    successMsg: successMsg,
  };
};

export const PatientSearchListByObservationTypeErrorAction = (
  patientSearchObservationTypeResponse: PatientSearchListByObservationTypeDetails,
  errMsg: string,
  status: number,
): PatientSearchListByObservationTypeActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL,
    payload: patientSearchObservationTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const PatientSearchListByObservationTypeAPIMsgAction = (
  patientSearchObservationTypeResponse: PatientSearchListByObservationTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): PatientSearchListByObservationTypeActionTypes => {
  return {
    type: PATIENT_SEARCH_UPDATE_API_MSG,
    payload: patientSearchObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const PatientSearchListByObservationTypeAPIResClearAction = (
  patientSearchObservationTypeResponse: PatientSearchListByObservationTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): PatientSearchListByObservationTypeActionTypes => {
  return {
    type: PATIENT_SEARCH_UPDATE_API_RES,
    payload: patientSearchObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
