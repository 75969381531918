import {
  PATIENT_SEARCH_BY_INSTANCE_ID_FAIL,
  PATIENT_SEARCH_BY_INSTANCE_ID_LOADING,
  PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS,
  PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG,
  PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES,
  PatientSearchByInstanceIdActionTypes,
} from "./ActionTypes";
import { PatientSearchByInstanceIdDetails } from "./Model";

export const PatientSearchByInstanceIdLoadingAction = (
  loading: boolean,
): PatientSearchByInstanceIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_INSTANCE_ID_LOADING,
    loading: loading,
  };
};

export const PatientSearchByInstanceIdSuccessAction = (
  patientSearchByInstanceIdResponse: PatientSearchByInstanceIdDetails,
): PatientSearchByInstanceIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS,
    payload: patientSearchByInstanceIdResponse,
  };
};

export const PatientSearchByInstanceIdErrorAction = (
  patientSearchByInstanceIdResponse: PatientSearchByInstanceIdDetails,
  errMsg: string,
  status: number,
): PatientSearchByInstanceIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_INSTANCE_ID_FAIL,
    payload: patientSearchByInstanceIdResponse,
    message: errMsg,
    status: status,
  };
};
export const PatientSearchByInstanceIdAPIMsgAction = (
  patientSearchByInstanceIdResponse: PatientSearchByInstanceIdDetails,
  errMsg: string,
  status: number,
): PatientSearchByInstanceIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG,
    payload: patientSearchByInstanceIdResponse,
    message: errMsg,
    status: status,
  };
};

export const PatientSearchByInstanceIdAPIResClearAction = (
  patientSearchByInstanceIdResponse: PatientSearchByInstanceIdDetails,
  errMsg: string,
  status: number,
): PatientSearchByInstanceIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES,
    payload: patientSearchByInstanceIdResponse,
    message: errMsg,
    status: status,
  };
};
