import { OrganizationRegistrationRequestListRes } from "./Model";
export const ORGANIZATION_REGISTRATION_LIST_LOADING = "ORGANIZATION_REGISTRATION_LIST_LOADING";
export const ORGANIZATION_REGISTRATION_LIST_SUCCESS = "ORGANIZATION_REGISTRATION_LIST_SUCCESS";
export const ORGANIZATION_REGISTRATION_LIST_FAIL = "ORGANIZATION_REGISTRATION_LIST_FAIL";
export const ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG = "ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG";
export const ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES = "ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES";

export interface GetOrganizationRegistrationListState {
  patientOrgnizationRegistrationRequestList: OrganizationRegistrationRequestListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationRegistrationListLoading {
  type: typeof ORGANIZATION_REGISTRATION_LIST_LOADING;
  loading: boolean;
}
export interface GetOrganizationRegistrationListSuccess {
  type: typeof ORGANIZATION_REGISTRATION_LIST_SUCCESS;
  payload: OrganizationRegistrationRequestListRes;
  successMsg: string;
}
export interface GetOrganizationRegistrationListFail {
  type: typeof ORGANIZATION_REGISTRATION_LIST_FAIL;
  payload: OrganizationRegistrationRequestListRes;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationRegistrationListUpdateAPIMsg {
  type: typeof ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG;
  payload: OrganizationRegistrationRequestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationRegistrationListUpdateAPIRes {
  type: typeof ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES;
  payload: OrganizationRegistrationRequestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetOrganizationRegistrationListActionTypes =
  | GetOrganizationRegistrationListLoading
  | GetOrganizationRegistrationListSuccess
  | GetOrganizationRegistrationListFail
  | GetOrganizationRegistrationListUpdateAPIMsg
  | GetOrganizationRegistrationListUpdateAPIRes;
