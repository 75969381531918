import {
  HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL,
  HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING,
  HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS,
  HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG,
  HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES,
  GetHealthcampStaffInactiveListActionTypes,
} from "./ActionTypes";
import { GetHealthcampStaffInactiveListRes } from "./Model";

export const getHealthcampStaffInactiveListLoadingAction = (
  loading: boolean,
): GetHealthcampStaffInactiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING,
    loading: loading,
  };
};

export const getHealthcampStaffInactiveListSuccessAction = (
  getHealthcampStaffInactiveListResponse: GetHealthcampStaffInactiveListRes,
  successMsg: string,
  status: number,
): GetHealthcampStaffInactiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS,
    payload: getHealthcampStaffInactiveListResponse,
    successMsg: successMsg,
    status: status,
  };
};

export const getHealthcampStaffInactiveListErrorAction = (
  getHealthcampStaffInactiveListResponse: GetHealthcampStaffInactiveListRes,
  errMsg: string,
  status: number,
): GetHealthcampStaffInactiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL,
    payload: getHealthcampStaffInactiveListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthcampStaffInactiveListUpdateAPIMsgAction = (
  getHealthcampStaffInactiveListResponse: GetHealthcampStaffInactiveListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthcampStaffInactiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG,
    payload: getHealthcampStaffInactiveListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthcampStaffInactiveListAPIResClearAction = (
  getHealthcampStaffInactiveListResponse: GetHealthcampStaffInactiveListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthcampStaffInactiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES,
    payload: getHealthcampStaffInactiveListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
