import {
  GET_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL,
  GET_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING,
  GET_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS,
  GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG,
  GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES,
  GetHealthCampByOrganizationListActionTypes,
} from "./ActionTypes";
import { GetHealthCampByOrganizationRes } from "./Model";

export const getHealthCampByOrganizationLoadingAction = (
  loading: boolean,
): GetHealthCampByOrganizationListActionTypes => {
  return {
    type: GET_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING,
    loading: loading,
  };
};

export const getHealthCampByOrganizationListSuccessAction = (
  getHealthCampByOrganizationListResponse: GetHealthCampByOrganizationRes,
  successMsg: string,
): GetHealthCampByOrganizationListActionTypes => {
  return {
    type: GET_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS,
    payload: getHealthCampByOrganizationListResponse,
    successMsg: successMsg,
  };
};

export const getHealthCampByOrganizationListErrorAction = (
  getHealthCampByOrganizationListResponse: GetHealthCampByOrganizationRes,
  errMsg: string,
  status: number,
): GetHealthCampByOrganizationListActionTypes => {
  return {
    type: GET_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL,
    payload: getHealthCampByOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthCampByOrganizationListUpdateAPIMsgAction = (
  getHealthCampByOrganizationListResponse: GetHealthCampByOrganizationRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthCampByOrganizationListActionTypes => {
  return {
    type: GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG,
    payload: getHealthCampByOrganizationListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthCampByOrganizationAPIResClearAction = (
  getHealthCampByOrganizationListResponse: GetHealthCampByOrganizationRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthCampByOrganizationListActionTypes => {
  return {
    type: GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES,
    payload: getHealthCampByOrganizationListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
