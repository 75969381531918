import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetSessionListActionTypes } from "./ActionTypes";
import { GetSessionListBody, GetSessionListRes } from "./Model";
import {
  getSessionListAPIResClearAction,
  getSessionListErrorAction,
  getSessionListLoadingAction,
  getSessionListSuccessAction,
  getSessionListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetSessionListRes;
export const getSessionListByHealthCampId = (payload: GetSessionListBody) => {
  return function (dispatch: Dispatch<GetSessionListActionTypes>) {
    dispatch(getSessionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsession/session_list_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getSessionListLoadingAction(false));
        dispatch(
          getSessionListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetSessionListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getSessionListLoadingAction(false));
        dispatch(
          getSessionListErrorAction(
            {} as GetSessionListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateSessionListByHealthCampIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetSessionListActionTypes>) {
    dispatch(
      getSessionListUpdateAPIMsgAction(apiRes as GetSessionListRes, "", "", 0),
    );
  };
};

export const clearSessionListByHealthCampIdAPIRes = () => {
  return function (dispatch: Dispatch<GetSessionListActionTypes>) {
    dispatch(getSessionListAPIResClearAction({} as GetSessionListRes, "", "", 0));
  };
};
