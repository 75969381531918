import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { PatientAddPointDataActionTypes } from "./ActionTypes";
import { AddPatientRes, PatientDataBody } from "./Model";
import {
  registerPatientAPIResClearAction,
  registerPatientErrorAction,
  registerPatientLoadingAction,
  registerPatientSuccessAction,
  registerPatientUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as AddPatientRes;
export const addHealthcampPatient = (payload: PatientDataBody) => {
  return function (dispatch: Dispatch<PatientAddPointDataActionTypes>) {
    dispatch(registerPatientLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/caregiverapp/api/healthcamppatient/add_patient_for_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        dispatch(registerPatientLoadingAction(false));
        dispatch(
          registerPatientSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "New schedule has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(registerPatientLoadingAction(false));
        dispatch(
          registerPatientErrorAction(
            {} as AddPatientRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const addPatientAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientAddPointDataActionTypes>) {
    dispatch(registerPatientUpdateAPIMsgAction(apiRes as AddPatientRes, "", "", 0));
  };
};

export const clearAddPatientAPIRes = () => {
  return function (dispatch: Dispatch<PatientAddPointDataActionTypes>) {
    dispatch(registerPatientAPIResClearAction({} as AddPatientRes, "", "", 0));
  };
};
