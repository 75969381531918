import { DuplicatePatientDemographicsDetailsRes } from "./Model";
export const DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING = "DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING";
export const DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS = "DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS";
export const DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL = "DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL";
export const DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG = "DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG";
export const DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES = "DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES";

export interface GetDuplicateDemographicsDetailsState {
  getDuplicateDemographicsByPatientIdRes: DuplicatePatientDemographicsDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDuplicateDemographicsDetailsLoading {
  type: typeof DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDuplicateDemographicsDetailsSuccess {
  type: typeof DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS;
  payload: DuplicatePatientDemographicsDetailsRes;
  successMsg: string;
}
export interface GetDuplicateDemographicsDetailsFail {
  type: typeof DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL;
  payload: DuplicatePatientDemographicsDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDuplicateDemographicsDetailsUpdateAPIMsg {
  type: typeof DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG;
  payload: DuplicatePatientDemographicsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDuplicateDemographicsDetailsUpdateAPIRes {
  type: typeof DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES;
  payload: DuplicatePatientDemographicsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DuplicatePatientDemographicsDetailsActionTypes =
  | GetDuplicateDemographicsDetailsLoading
  | GetDuplicateDemographicsDetailsSuccess
  | GetDuplicateDemographicsDetailsFail
  | GetDuplicateDemographicsDetailsUpdateAPIMsg
  | GetDuplicateDemographicsDetailsUpdateAPIRes;
