import { AddDoctorInHealthCampRes } from "./Model";
export const ADD_DOCTOR_IN_HEALTH_CAMP_LOADING = "ADD_DOCTOR_IN_HEALTH_CAMP_LOADING";
export const ADD_DOCTOR_IN_HEALTH_CAMP_SUCCESS = "ADD_DOCTOR_IN_HEALTH_CAMP_SUCCESS";
export const ADD_DOCTOR_IN_HEALTH_CAMP_FAIL = "ADD_DOCTOR_IN_HEALTH_CAMP_FAIL";
export const ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_MSG = "ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_MSG";
export const ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_RES = "ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_RES";

export interface GetHealthCampStaffListState {
  addDoctorInHealthCampRes: AddDoctorInHealthCampRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampStaffListLoading {
  type: typeof ADD_DOCTOR_IN_HEALTH_CAMP_LOADING;
  loading: boolean;
}
export interface GetHealthCampStaffListSuccess {
  type: typeof ADD_DOCTOR_IN_HEALTH_CAMP_SUCCESS;
  payload: AddDoctorInHealthCampRes;
  successMsg: string;
}
export interface GetHealthCampStaffListFail {
  type: typeof ADD_DOCTOR_IN_HEALTH_CAMP_FAIL;
  payload: AddDoctorInHealthCampRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampStaffListUpdateAPIMsg {
  type: typeof ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_MSG;
  payload: AddDoctorInHealthCampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampStaffListUpdateAPIRes {
  type: typeof ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_RES;
  payload: AddDoctorInHealthCampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDoctorInHealthCampActionTypes =
  | GetHealthCampStaffListLoading
  | GetHealthCampStaffListSuccess
  | GetHealthCampStaffListFail
  | GetHealthCampStaffListUpdateAPIMsg
  | GetHealthCampStaffListUpdateAPIRes;
