import {
  EditPatientImportConfigDetails,
  editPatientImportConfigActionTypes,
  EDIT_PATIENT_IMPORT_CONFIG_FAIL,
  EDIT_PATIENT_IMPORT_CONFIG_LOADING,
  EDIT_PATIENT_IMPORT_CONFIG_SUCCESS,
} from "./ActionTypes";
import { EditPatientImportConfigValue } from "./Model";

const initialStateGetPosts: EditPatientImportConfigDetails = {
  loading: false,
  editPatientImportConfigRes: {} as EditPatientImportConfigValue,
  errRes: "",
};
export const editPatientImportConfigReducer = (
  state = initialStateGetPosts,
  action: editPatientImportConfigActionTypes
): EditPatientImportConfigDetails => {
  switch (action.type) {
    case EDIT_PATIENT_IMPORT_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        editPatientImportConfigRes: action.payload,
        errRes: "",
      };
    case EDIT_PATIENT_IMPORT_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
        editPatientImportConfigRes: {} as EditPatientImportConfigValue,
      };
    case EDIT_PATIENT_IMPORT_CONFIG_FAIL:
      return {
        ...state,
        editPatientImportConfigRes: {} as EditPatientImportConfigValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
