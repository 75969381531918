import {
     GetOrganizationRegistrationListActionTypes,
    ORGANIZATION_REGISTRATION_LIST_FAIL,
    ORGANIZATION_REGISTRATION_LIST_LOADING,
    ORGANIZATION_REGISTRATION_LIST_SUCCESS,
    GetOrganizationRegistrationListState,
    ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG,
    ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { OrganizationRegistrationRequestListRes } from "./Model";
  
  const initialStateGetPosts: GetOrganizationRegistrationListState = {
    loading: false,
    patientOrgnizationRegistrationRequestList: {} as OrganizationRegistrationRequestListRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const  getOrganizationRegistrationRequestListReducer = (
    state = initialStateGetPosts,
    action:  GetOrganizationRegistrationListActionTypes,
  ): GetOrganizationRegistrationListState => {
    switch (action.type) {
      case ORGANIZATION_REGISTRATION_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case ORGANIZATION_REGISTRATION_LIST_SUCCESS:
        return {
          ...state,
          patientOrgnizationRegistrationRequestList: action.payload,
          successMsg: action.successMsg,
        };
      case ORGANIZATION_REGISTRATION_LIST_FAIL:
        return {
          ...state,
          patientOrgnizationRegistrationRequestList: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG:
        return {
          ...state,
          patientOrgnizationRegistrationRequestList: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES:
        return {
          ...state,
          patientOrgnizationRegistrationRequestList: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  