import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPatientDetailsListBySessionIdActionTypes } from "./ActionTypes";
import { GetPatientDetailsListBySessionIdBody, GetPatientDetailsListBySessionIdRes } from "./Model";
import {
  getPatientDetailsListBySessionIdAPIResClearAction,
  getPatientDetailsListBySessionIdErrorAction,
  getPatientDetailsListBySessionIdLoadingAction,
  getPatientDetailsListBySessionIdSuccessAction,
  getPatientDetailsListBySessionIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetPatientDetailsListBySessionIdRes;
export const getPatientDetailsListBySessionIdApi = (
  payload: GetPatientDetailsListBySessionIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetPatientDetailsListBySessionIdActionTypes>) {
    dispatch(getPatientDetailsListBySessionIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsessionpatientdetails/get_patientDetailsList_by_session?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDetailsListBySessionIdLoadingAction(false));
        dispatch(
          getPatientDetailsListBySessionIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientDetailsListBySessionIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientDetailsListBySessionIdLoadingAction(false));
        dispatch(
          getPatientDetailsListBySessionIdErrorAction(
            {} as GetPatientDetailsListBySessionIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientDetailsListBySessionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsListBySessionIdActionTypes>) {
    dispatch(
      getPatientDetailsListBySessionIdUpdateAPIMsgAction(
        apiRes as GetPatientDetailsListBySessionIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetPatientDetailsListBySessionIdAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsListBySessionIdActionTypes>) {
    dispatch(
      getPatientDetailsListBySessionIdAPIResClearAction({} as GetPatientDetailsListBySessionIdRes, "", "", 0),
    );
  };
};
