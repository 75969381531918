import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  ToggleActiveOrgRegistrationRequestActionTypes } from "./ActionTypes";
import { ToggleActiveOrgRegReqBody, ToggleActiveOrgRegReqRes } from "./Model";
import {
   toggleActiveOrgRegistrationRequestAPIResClearAction,
   toggleActiveOrgRegistrationRequestErrorAction,
   toggleActiveOrgRegistrationRequestLoadingAction,
   toggleActiveOrgRegistrationRequestSuccessAction,
   toggleActiveOrgRegistrationRequestUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as ToggleActiveOrgRegReqRes;
export const  toggleActiveOrganizationRegistrationRequest = (payload: ToggleActiveOrgRegReqBody) => {
  return function (dispatch: Dispatch< ToggleActiveOrgRegistrationRequestActionTypes>) {
    dispatch( toggleActiveOrgRegistrationRequestLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientOrganizationRegistrationRequest/toggle_active_registration_request_patientOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( toggleActiveOrgRegistrationRequestLoadingAction(false));
        dispatch(
           toggleActiveOrgRegistrationRequestSuccessAction(
            res.data !== undefined ? res.data : ({} as ToggleActiveOrgRegReqRes),
            res.data.message !== undefined
              ? res.data.message
              : "patientOrganizationRegistrationRequest status of is now False",
          ),
        );
      })
      .catch((error) => {
        dispatch( toggleActiveOrgRegistrationRequestLoadingAction(false));
        dispatch(
           toggleActiveOrgRegistrationRequestErrorAction(
            {} as ToggleActiveOrgRegReqRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateToggleActiveOrgRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch< ToggleActiveOrgRegistrationRequestActionTypes>) {
    dispatch(
       toggleActiveOrgRegistrationRequestUpdateAPIMsgAction(apiRes as ToggleActiveOrgRegReqRes, "", "", 0),
    );
  };
};

export const clearToggleActiveOrgRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch< ToggleActiveOrgRegistrationRequestActionTypes>) {
    dispatch( toggleActiveOrgRegistrationRequestAPIResClearAction({} as ToggleActiveOrgRegReqRes, "", "", 0));
  };
};
