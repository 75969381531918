import { GetHealthcampStaffInactiveListRes } from "./Model";
export const HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING = "HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING";
export const HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS = "HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS";
export const HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL = "HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL";
export const HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG =
  "HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG";
export const HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES =
  "HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES";

export interface GetHealthCampDeactiveStaffListState {
  getHealthcampStaffInactiveList: GetHealthcampStaffInactiveListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListLoading {
  type: typeof HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthCampDeactiveStaffListSuccess {
  type: typeof HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS;
  payload: GetHealthcampStaffInactiveListRes;
  successMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListFail {
  type: typeof HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL;
  payload: GetHealthcampStaffInactiveListRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListUpdateAPIMsg {
  type: typeof HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG;
  payload: GetHealthcampStaffInactiveListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListUpdateAPIRes {
  type: typeof HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES;
  payload: GetHealthcampStaffInactiveListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthcampStaffInactiveListActionTypes =
  | GetHealthCampDeactiveStaffListLoading
  | GetHealthCampDeactiveStaffListSuccess
  | GetHealthCampDeactiveStaffListFail
  | GetHealthCampDeactiveStaffListUpdateAPIMsg
  | GetHealthCampDeactiveStaffListUpdateAPIRes;
