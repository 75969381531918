import {
  GetHealthcampStaffInactiveListActionTypes,
  HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL,
  HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING,
  HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS,
  GetHealthCampDeactiveStaffListState,
  HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG,
  HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetHealthcampStaffInactiveListRes } from "./Model";

const initialStateGetPosts: GetHealthCampDeactiveStaffListState = {
  loading: false,
  getHealthcampStaffInactiveList: {} as GetHealthcampStaffInactiveListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getInactiveListByHealthCampIdReducer = (
  state = initialStateGetPosts,
  action: GetHealthcampStaffInactiveListActionTypes,
): GetHealthCampDeactiveStaffListState => {
  switch (action.type) {
    case HEALTHCAMP_STAFF_INACTIVE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case HEALTHCAMP_STAFF_INACTIVE_LIST_SUCCESS:
      return {
        ...state,
        getHealthcampStaffInactiveList: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case HEALTHCAMP_STAFF_INACTIVE_LIST_FAIL:
      return {
        ...state,
        getHealthcampStaffInactiveList: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getHealthcampStaffInactiveList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HEALTHCAMP_STAFF_INACTIVE_LIST_UPDATE_API_RES:
      return {
        ...state,
        getHealthcampStaffInactiveList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
