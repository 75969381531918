import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { changeNotesForDoctorStatus, getDoctorNoteListForDoctor } from "../../redux/Notes/ApiCall";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";

type Props = {
  noteListLoader?: boolean | any;
};

export default function InActiveNotes({noteListLoader}:Props) {
  const dispatch = useDispatch();
  const [inactiveNoteListValue, setInactiveNoteListValue] = useState([] as any[]);
  const activeNotesList: GridColDef[] = [
    {
      field: "note",
      headerName: "Note",
      width: 150,
    },
    {
      field: "createdDate",
      headerName: "Create Date",
      width: 120,
      flex: 1,
    },
    { field: "createBy", headerName: "Create By", width: 180,flex: 1},
    {
      field: "modifyBy",
      headerName: "Modify By",
      width: 180,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 180,
    },
    {
      field: "id",
      headerName: "Request Status",
      sortable: true,
      hideable: false,
      minWidth: 180,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const doctorId = localStorage.getItem("doctorID");

  // Call Note List for Dr Store
  const inactiveNoteListFromAPIStoreValue = useSelector((state: AppState) => state.notesForDrvalues);

  // call Change Dr. Notes Status Store
  const changeDrNoteStatusLoadingvalue = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.loading,
  );
  const changeDrNoteStatusStatus = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.status,
  );
  const changeDrNoteStatusMessage = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.message,
  );

  // Change Dr notes status Success toast
  const [successChangeDrNoteStatusToastActive, setSuccessChangeDrNoteStatusToastActive] =
    useState(false);
  const toggleSuccessChangeDrNoteStatusActive = () => {
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) => !successChangeDrNoteStatusToastActive,
    );
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
  };

  const toastChangeDrNoteStatusSuccessMarkup = successChangeDrNoteStatusToastActive ? (
    // <Toast
    //   content={changeDrNoteStatusMessage}
    //   onDismiss={toggleSuccessChangeDrNoteStatusActive}
    //   duration={1000}
    // />
    <Snackbar
      open={successChangeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessChangeDrNoteStatusActive}
    >
      <Alert
        severity="success"
        onClose={toggleSuccessChangeDrNoteStatusActive}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Change Dr notes status Error toast
  const [changeDrNoteStatusToastActive, setErrorChangeDrNoteStatusToastActive] = useState(false);
  const handleErrorChangeDrNoteStatusToastChange = () => {
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive,
    );
  };
  const toastChangeDrNoteStatusErrorMarkup = changeDrNoteStatusToastActive ? (
    // <Toast
    //   content={changeDrNoteStatusMessage}
    //   error
    //   onDismiss={handleErrorChangeDrNoteStatusToastChange}
    // />
    <Snackbar
      open={changeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={handleErrorChangeDrNoteStatusToastChange}
    >
      <Alert
        severity="success"
        onClose={handleErrorChangeDrNoteStatusToastChange}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Get Doctor Note List Body
  const getDrNoteListBody = {
    doctorId: doctorId,
    isActive: false,
  };


  // Notes Status change API
  function toggleNoteStatus(response: any) {
    dispatch(changeNotesForDoctorStatus(response));
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) => !successChangeDrNoteStatusToastActive,
    );
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive,
    );
  }

  useEffect(() => {
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    noteListLoader(inactiveNoteListFromAPIStoreValue?.loading);
    if (inactiveNoteListFromAPIStoreValue?.noteList !== undefined) {
      let regRequestList = inactiveNoteListFromAPIStoreValue?.noteList.map((element: any) => ({
        id: element.id,
        note: element?.note !== "" ? element?.note : "",
        createdDate: dayjs(element.createdDate).format("ddd, MMM D, YYYY"),
        createBy:
          element.createdBy === null
            ? ""
            : element.createdBy.user.first_name + " " + element.createdBy.user.last_name,
        modifyBy:
          element.modifiedBy === null
            ? ""
            : element.modifiedBy.user.first_name + " " + element.modifiedBy.user.last_name,
        modifiedDate: dayjs(element.modifiedDate).format("ddd, MMM D, YYYY"),
      }));
      setInactiveNoteListValue(regRequestList);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveNoteListFromAPIStoreValue]);

  // Active Notes List
  const getInActiveNoteList = (
    <Card>
    <CardHeader
      title={
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6"> Inactive Notes List</Typography>
          </Grid>
        </Grid>
      }
    />
    <CardContent>
      {inactiveNoteListValue && inactiveNoteListValue.length ? (
        <DataGrid
          rows={inactiveNoteListValue}
          columns={activeNotesList}
          getRowId={(row) => row.id}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      ) : (
        <MWExceptionList />
      )}
    </CardContent>
  </Card>
  );

  return (
    <Box>
      {inactiveNoteListFromAPIStoreValue.loading === true || changeDrNoteStatusLoadingvalue === true
         ? noteListLoader(true)
        : noteListLoader(false)}
      <Container maxWidth="xl">
        {getInActiveNoteList}
        {changeDrNoteStatusStatus! === 1 ? toastChangeDrNoteStatusSuccessMarkup : ""}
        {changeDrNoteStatusStatus! === 0 ? toastChangeDrNoteStatusErrorMarkup : ""}
      </Container>
    </Box>
  );
}
