import {
  PROV_ORG_PT_DETAILS_FAIL,
  PROV_ORG_PT_DETAILS_LOADING,
  PROV_ORG_PT_DETAILS_SUCCESS,
  PROV_ORG_PT_DETAILS_API_MSG,
  PROV_ORG_PT_DETAILS_API_RES,
  ProviderOrgPtDetailsActionTypes,
} from "./ActionTypes";
import { ProviderOrgPatientDetailsRes } from "./Model";

export const proOrgPtDetailsLoadingAction = (
  loading: boolean,
): ProviderOrgPtDetailsActionTypes => {
  return {
    type: PROV_ORG_PT_DETAILS_LOADING,
    loading: loading,
  };
};

export const proOrgPtDetailsSuccessAction = (
  providerOrgPtDtRes: ProviderOrgPatientDetailsRes,
): ProviderOrgPtDetailsActionTypes => {
  return {
    type: PROV_ORG_PT_DETAILS_SUCCESS,
    payload: providerOrgPtDtRes,
  };
};

export const proOrgPtDetailsErrorAction = (
  providerOrgPtDtRes: ProviderOrgPatientDetailsRes,
  errMsg: string,
  status: number,
): ProviderOrgPtDetailsActionTypes => {
  return {
    type: PROV_ORG_PT_DETAILS_FAIL,
    payload: providerOrgPtDtRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const proOrgPtDetailsAPIMsgAction = (
  providerOrgPtDtRes: ProviderOrgPatientDetailsRes,
  errMsg: string,
  status: number,
): ProviderOrgPtDetailsActionTypes => {
  return {
    type: PROV_ORG_PT_DETAILS_API_MSG,
    payload: providerOrgPtDtRes,
    errorMsg: errMsg,
    status: status,
  };
};

export const proOrgPtDetailsAPIResClearAction = (
  providerOrgPtDtRes: ProviderOrgPatientDetailsRes,
  errMsg: string,
  status: number,
): ProviderOrgPtDetailsActionTypes => {
  return {
    type: PROV_ORG_PT_DETAILS_API_RES,
    payload: providerOrgPtDtRes,
    errorMsg: errMsg,
    status: status,
  };
};
