import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetPhotoJudgingDetailsListActionTypes } from "./ActionTypes";
import { GetPhotoJudgingDetailsListBody, GetPhotoJudgingDetailsListDetails } from "./Model";
import {
  getPhotoJudgingDetailsListAPIResClearAction,
  getPhotoJudgingDetailsListErrorAction,
  getPhotoJudgingDetailsListLoadingAction,
  getPhotoJudgingDetailsListSuccessAction,
  getPhotoJudgingDetailsListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetPhotoJudgingDetailsListDetails;
export const getPhotoJudgingDetailsList = (
  competionId: GetPhotoJudgingDetailsListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetPhotoJudgingDetailsListActionTypes>) {
    dispatch(getPhotoJudgingDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionJudgingResultAsStaff/getJudgingDetailsList?page=${Number(
            pageNo,
          )}&size=25`,
          competionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPhotoJudgingDetailsListLoadingAction(false));
        dispatch(getPhotoJudgingDetailsListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getPhotoJudgingDetailsListLoadingAction(false));
        dispatch(
          getPhotoJudgingDetailsListErrorAction(
            {} as GetPhotoJudgingDetailsListDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getPhotoJudgingDetailsListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPhotoJudgingDetailsListActionTypes>) {
    dispatch(
      getPhotoJudgingDetailsListAPIMsgAction(apiRes as GetPhotoJudgingDetailsListDetails, "", 0),
    );
  };
};

export const clearGetPhotoJudgingDetailsListAPIRes = () => {
  return function (dispatch: Dispatch<GetPhotoJudgingDetailsListActionTypes>) {
    dispatch(
      getPhotoJudgingDetailsListAPIResClearAction({} as GetPhotoJudgingDetailsListDetails, "", 0),
    );
  };
};
