import { PatientDataRangeBySessionIdTypeDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING = "PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING";
export const PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS = "PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS";
export const PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL = "PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL";
export const PATIENT_DATA_RANGE_BY_SESSION_ID_DATA = "PATIENT_DATA_RANGE_BY_SESSION_ID_DATA";
export const PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG = "PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG";
export const PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES = "PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES";

export interface PatientDataRangeBySessionIdTypeDetailsState {
  patientDataRangeBySessionIdTypeRes: PatientDataRangeBySessionIdTypeDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface PatientDataRangeBySessionIdTypeLoading {
  type: typeof PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface PatientDataRangeBySessionIdTypeSuccess {
  type: typeof PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS;
  payload: PatientDataRangeBySessionIdTypeDetails;
  successMsg: string;
}
export interface PatientDataRangeBySessionIdTypeFail {
  type: typeof PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL;
  payload: PatientDataRangeBySessionIdTypeDetails;
  errorMsg: string;
  status: number;
}
export interface PatientDataRangeBySessionIdTypeAPIMsg {
  type: typeof PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG;
  payload: PatientDataRangeBySessionIdTypeDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES;
  payload: PatientDataRangeBySessionIdTypeDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type PatientDataRangeBySessionIdTypeActionTypes =
  | PatientDataRangeBySessionIdTypeLoading
  | PatientDataRangeBySessionIdTypeSuccess
  | PatientDataRangeBySessionIdTypeFail
  | PatientDataRangeBySessionIdTypeAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
