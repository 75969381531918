import {
  EDIT_SESSION_FAIL,
  EDIT_SESSION_LOADING,
  EDIT_SESSION_SUCCESS,
  EDIT_SESSION_API_MSG,
  EDIT_SESSION_API_RES,
  EditSessionActionTypes,
} from "./ActionTypes";
import { EditSessionDetails } from "./Model";

export const editSessionLoadingAction = (
  loading: boolean,
): EditSessionActionTypes => {
  return {
    type: EDIT_SESSION_LOADING,
    loading: loading,
  };
};

export const editSessionSuccessAction = (
  editSessionResponse: EditSessionDetails,
  successMsg: string,
): EditSessionActionTypes => {
  return {
    type: EDIT_SESSION_SUCCESS,
    payload: editSessionResponse,
    successMsg: successMsg,
  };
};

export const editSessionErrorAction = (
  editSessionResponse: EditSessionDetails,
  errMsg: string,
  status: number,
): EditSessionActionTypes => {
  return {
    type: EDIT_SESSION_FAIL,
    payload: editSessionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editSessionAPIMsgAction = (
  editSessionResponse: EditSessionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): EditSessionActionTypes => {
  return {
    type: EDIT_SESSION_API_MSG,
    payload: editSessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editSessionAPIResClearAction = (
  editSessionResponse: EditSessionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): EditSessionActionTypes => {
  return {
    type: EDIT_SESSION_API_RES,
    payload: editSessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
