import {
  DIACTIVE_STAFF_OF_COMPETITION_DATA,
  DIACTIVE_STAFF_OF_COMPETITION_FAIL,
  DIACTIVE_STAFF_OF_COMPETITION_LOADING,
  DIACTIVE_STAFF_OF_COMPETITION_SUCCESS,
  DIACTIVE_STAFF_OF_COMPETITION_API_MSG,
  DIACTIVE_STAFF_OF_COMPETITION_API_RES,
  DiactiveStaffOfCompetitionActionTypes,
} from "./ActionTypes";
import { PageCount, DiactiveStaffOfCompetitionDetails } from "./Model";

export const diactiveStaffOrganizationIdAction = (
  DiactiveStaffOfCompetitionDetails: DiactiveStaffOfCompetitionDetails,
  pageCount: PageCount,
): DiactiveStaffOfCompetitionActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_COMPETITION_DATA,
    payload: DiactiveStaffOfCompetitionDetails,
    pageCount: pageCount,
  };
};

export const DiactiveStaffOfCompetitionLoadingAction = (
  loading: boolean,
): DiactiveStaffOfCompetitionActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_COMPETITION_LOADING,
    loading: loading,
  };
};

export const DiactiveStaffOfCompetitionSuccessAction = (
  diactiveStaffResponse: DiactiveStaffOfCompetitionDetails,
  successMsg: string,
): DiactiveStaffOfCompetitionActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_COMPETITION_SUCCESS,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
  };
};

export const DiactiveStaffOfCompetitionErrorAction = (
  diactiveStaffResponse: DiactiveStaffOfCompetitionDetails,
  errMsg: string,
  status: number,
): DiactiveStaffOfCompetitionActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_COMPETITION_FAIL,
    payload: diactiveStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DiactiveStaffOfCompetitionAPIMsgAction = (
  diactiveStaffResponse: DiactiveStaffOfCompetitionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DiactiveStaffOfCompetitionActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_COMPETITION_API_MSG,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const DiactiveStaffOfCompetitionAPIResClearAction = (
  diactiveStaffResponse: DiactiveStaffOfCompetitionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DiactiveStaffOfCompetitionActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_COMPETITION_API_RES,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
