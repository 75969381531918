import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  AlertColor,
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormHelperText,
  Grid,
  InputBase,
  Pagination,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import { PatientSearchByHealthCampIdBody } from "redux/PatientSearchByHealcampId/Model";
import { clearAPIRes, patientSearchByHealthCampIdApi } from "redux/PatientSearchByHealcampId/API";
import MWPageTitle from "component/MWPageTitle";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "component/MWExceptionList";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddPatientBySessionIdBody } from "redux/HealthcampSessionList/AddPatientBySessionId/Model";
import {
  addPatientBySessionIdApiCall,
  clearAddPatientBySessionIdAPIRes,
} from "redux/HealthcampSessionList/AddPatientBySessionId/API";
import { useHistory, useParams } from "react-router-dom";
import MWSnackbar from "component/MWSnackbar";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridView from "./GridView";
import { PatientSearchByInstanceIdBody } from "redux/HealthcampSessionList/PatientSearchByInstanceId/Model";
import {
  clearSearchAppointmentAPIRes,
  patientSearchByInstanceIdApi,
} from "redux/HealthcampSessionList/PatientSearchByInstanceId/API";

export default function CheckInPatientComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const searchValueList = useSelector((state: AppState) => state.searchPatientByHealthCampIdValue);
  const patientSearchByHealthCampIdRes = searchValueList.PatientSearchByHealthcampIdRes.patientList;
  const searchListByInstanceIdValues = useSelector(
    (state: AppState) => state.searchPatientByHealthcampInstanceId,
  );
  const addPatientResponse = useSelector((state: AppState) => state.addPatientBySessionIdValues);
  const [medicalWisdomDetails, setMedicalWisdomDetails] = useState<any[]>([]);
  const [patientDetails, setPatientDetails] = useState<any[]>([]);
  const [addCheckInMsg, setAddCheckInMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const [inputValue, setInputValue] = React.useState("" as any);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("0");
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [alignment, setAlignment] = useState<string | null>("1");
  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (addPatientResponse?.successMsg !== "") {
      dispatch(clearAPIRes());
      history.push(
        `/healthcampinstance_session/${healthcampid}/${instanceHealthcampId}/${sessionId}`,
      );
    }
    if (addPatientResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setAddCheckInMsg(addPatientResponse?.errorMsg);
      dispatch(clearAddPatientBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientResponse]);

  const patientSearchByHealthcampBody = {
    searchKey: inputValue,
    healthCampId: healthcampid,
  } as PatientSearchByHealthCampIdBody;

  const patientSearchByAppointmentBody = {
    searchKey: inputValue,
    healthcampInstanceId: instanceHealthcampId,
  } as PatientSearchByInstanceIdBody;

  const patientSearchInCamp = () => {
    if (inputValue !== "") {
      dispatch(patientSearchByHealthCampIdApi(patientSearchByHealthcampBody, 1));
      dispatch(clearSearchAppointmentAPIRes());
      setPage(1);
      setIsFormInvalid(false);
    } else {
      setIsFormInvalid(true);
    }
  };

  const patientSearchInAppointment = () => {
    if (inputValue !== "") {
      dispatch(patientSearchByInstanceIdApi(patientSearchByAppointmentBody, 1));
      dispatch(clearAPIRes());
      setPage(1);
      setIsFormInvalid(false);
    } else {
      setIsFormInvalid(true);
    }
  };

  useEffect(() => {
    searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages))
      : setPageListCount("0");
    if (searchValueList?.PatientSearchByHealthcampIdRes?.count !== undefined) {
      setListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.count));
    }
    if (patientSearchByHealthCampIdRes !== undefined) {
      let dataPointArr = patientSearchByHealthCampIdRes.map((element: any) => ({
        id: element.patientId,
        patientId: element.patientId,
        givenName: element.givenName,
        familyName: element.familyName,
        userName: element.userName,
        email: element.email,
        dob: dayjs(element.dob).format("ddd, MMM D, YYYY"),
        phone: element.phone,
        profilePhotoId: element.profilePhotoId,
        ptDetails: element.patientId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueList]);

  useEffect(() => {
    searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.count !== undefined) {
      setListCount(String(searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.count));
    }
    if (searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList !== undefined) {
      let dataPointArr = searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList.map((element: any) => ({
        id: element.patientId,
        patientId: element.patientId,
        givenName: element.givenName,
        familyName: element.familyName,
        userName: element.userName,
        email: element.email,
        dob: dayjs(element.dob).format("ddd, MMM D, YYYY"),
        phone: element.phone,
        profilePhotoId: element.profilePhotoId,
        ptDetails: element.patientId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(
        String(searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.numberOfPages),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchListByInstanceIdValues]);

  const gotoDetails = (patientId: string) => {
    history.push(`/patientdetails/${patientId}`);
  };

  useEffect(() => {
    if (alignment === "1") {
      setMedicalWisdomDetails(
        searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList,
      );
    } else {
      setPatientDetails(patientSearchByHealthCampIdRes);
    }
  }, [
    alignment,
    searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList,
    patientSearchByHealthCampIdRes,
  ]);

  const columns: GridColDef[] = [
    {
      field: "ptDetails",
      headerName: "View",
      width: 100,
      align: "left",
      hide: patientSearchByHealthCampIdRes !== undefined ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },

    {
      field: "id",
      headerName: "Patient Id",
      width: 100,
      align: "left",
    },
    {
      field: "givenName",
      headerName: "First Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "familyName",
      headerName: "Last Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "patientId",
      headerName: "Action",
      width: 50,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            addPatientSubmit(params.row.patientId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Check-In Patient
          </span>
        </Button>
      ),
    },
  ];

  const addPatientSubmit = (element: string) => {
    const addExistingPatientBody = {
      healthcampSessionId: sessionId!,
      patientId: element,
    } as AddPatientBySessionIdBody;
    dispatch(addPatientBySessionIdApiCall(addExistingPatientBody));
  };

  const addCheckInMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setAddCheckInMsg("");
    }
  };

  const searchTitle =
    medicalWisdomDetails !== undefined
      ? `Search result in appointment for "${inputValue}"`
      : "" || patientDetails !== undefined
      ? `Search result in camp for "${inputValue}"`
      : "" || searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList !== undefined
      ? `Search result in appointment for "${inputValue}"`
      : "" || patientSearchByHealthCampIdRes !== undefined
      ? `Search result in camp for "${inputValue}"`
      : "";

  const PatientCheckInPage = (
    <Card>
      <CardHeader
        title={
          <Box>
            <Grid container direction="row" justifyContent="space-between">
              <Paper
                variant="outlined"
                component="form"
                sx={{ display: "flex", alignItems: "center", width: 700 }}
              >
                <InputBase
                  value={inputValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInputValue(event.target.value);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      dispatch(patientSearchByHealthCampIdApi(patientSearchByHealthcampBody, page));
                      ev.preventDefault();
                    }
                  }}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Patient"
                  inputProps={{ "aria-label": "search patient" }}
                />
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                  <Button sx={{ borderRadius: 0 }} onClick={patientSearchInCamp}>
                    In Camp
                  </Button>
                  <Button sx={{ borderRadius: 0 }} onClick={patientSearchInAppointment}>
                    In Appointment
                  </Button>
                </ButtonGroup>
              </Paper>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                size="small"
              >
                <ToggleButton value="1" aria-label="centered">
                  <GridViewIcon />
                </ToggleButton>
                <ToggleButton value="2" aria-label="centered">
                  <FormatListBulletedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {isFormInvalid && (
              <FormHelperText error id="my-helper-text">
                Please enter patient name
              </FormHelperText>
            )}
          </Box>
        }
      />
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            my={1}
          >
            <Grid item>
              <MWPageTitle title={searchTitle} enableCount={true} count={listCount} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 0}
                    page={page}
                    //onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        {alignment === "1" ? (
          <Box>
            <GridView
              patientDetails={patientSearchByHealthCampIdRes}
              medicalWisdomDetails={medicalWisdomDetails}
              sessionId={sessionId}
              instanceHealthcampId={instanceHealthcampId}
            />
          </Box>
        ) : (
          <Box>
            {(patientSearchByHealthCampIdRes && patientSearchByHealthCampIdRes.length) ||
            (searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList &&
              searchListByInstanceIdValues?.patientSearchByInstanceIdRes?.patientList.length) ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {addPatientResponse?.loading ? <AdminLoader /> : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <MWPageTitle title={"Search Patient in Session for Patient Check In"} backButton={true} />
          {PatientCheckInPage}
          {addCheckInMsg !== "" ? (
            <MWSnackbar
              msg={addCheckInMsg}
              type={linkImageToCompetitionMsgType}
              alertClose={addCheckInMsgClose}
            />
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
