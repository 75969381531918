import {
  GetAdminDetailsActionTypes,
  GET_ADMIN_LOGIN_DETAILS_FAIL,
  GET_ADMIN_LOGIN_DETAILS_LOADING,
  GET_ADMIN_LOGIN_DETAILS_SUCCESS,
  GetAdminLoginDetailsState,
  GET_ADMIN_LOGIN_DETAILS_API_MSG,
  GET_ADMIN_LOGIN_DETAILS_API_RES,
} from "./ActionTypes";
import { GetAdminDetailsRes } from "./Model";

const initialStateUpdatePosts: GetAdminLoginDetailsState = {
  loading: false,
  getAdminLoginDetailsRes: {} as GetAdminDetailsRes,
  errorMsg: "",
  status: 0,
};
export const adminLoginDetailsReducer = (
  state = initialStateUpdatePosts,
  action: GetAdminDetailsActionTypes,
): GetAdminLoginDetailsState => {
  switch (action.type) {
    case GET_ADMIN_LOGIN_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_ADMIN_LOGIN_DETAILS_SUCCESS:
      return {
        ...state,
        getAdminLoginDetailsRes: action.payload,
      };
    case GET_ADMIN_LOGIN_DETAILS_FAIL:
      return {
        ...state,
        getAdminLoginDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_ADMIN_LOGIN_DETAILS_API_MSG:
      return {
        ...state,
        getAdminLoginDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_ADMIN_LOGIN_DETAILS_API_RES:
      return {
        ...state,
        getAdminLoginDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
