import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetSubscriptionListByIdActionTypes } from "./ActionTypes";
import { GetSubscriptionResponse } from "./Model";
import {
  getSubscriptionAPIResClearAction,
  getSubscriptionErrorAction,
  getSubscriptionLoadingAction,
  getSubscriptionSuccessAction,
  getSubscriptionUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetSubscriptionResponse;
export const getSubscriptionListById = () => {
  return function (dispatch: Dispatch<GetSubscriptionListByIdActionTypes>) {
    dispatch(getSubscriptionLoadingAction(true));
    axios
      .get(Url.baseUrl + `/subscriptionapp/api/medicalWisdomSubscription/get_subscription_dropdown_list`, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(getSubscriptionLoadingAction(false));
        dispatch(getSubscriptionSuccessAction(res.data, "Preferences has been fetched successfully"));
      })
      .catch((error) => {
        dispatch(getSubscriptionLoadingAction(false));
        dispatch(
          getSubscriptionErrorAction(
            {} as GetSubscriptionResponse,
            error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSubscriptionAPIResMsg = () => {
  return function (dispatch: Dispatch<GetSubscriptionListByIdActionTypes>) {
    dispatch(getSubscriptionUpdateAPIMsgAction(apiRes as GetSubscriptionResponse, "", "", 0));
  };
};

export const clearSubscriptionAPIRes = () => {
  return function (dispatch: Dispatch<GetSubscriptionListByIdActionTypes>) {
    dispatch(getSubscriptionAPIResClearAction({} as GetSubscriptionResponse, "", "", 0));
  };
};
