import { Divider, Stack, Typography } from "@mui/material";
import { GetAdminDetailsRes } from "../redux/jwtAdminLogIn/adminLoginDetails/Model";
import SecureLS from "secure-ls";

export default function AdminDetails() {
  var ls = new SecureLS({ encodingType: "aes" });
  const adminDetails = ls.get("loginDetails");
  const userDetails = adminDetails.loginDetails as GetAdminDetailsRes;
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} m={2}>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Admin Details:
        </Typography>
        <Divider />
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          First Name:
        </Typography>
        <Typography variant="subtitle2">
          {userDetails?.user?.first_name !== undefined ? userDetails?.user?.first_name : null}
        </Typography>
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Last Name:
        </Typography>
        <Typography variant="subtitle2">
          {userDetails?.user?.last_name !== undefined ? userDetails?.user?.last_name : null}
        </Typography>
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Email:
        </Typography>
        <Typography variant="subtitle2">
          {userDetails?.user?.email !== undefined ? userDetails?.user?.email : null}
        </Typography>
      </Stack>
    </Stack>
  );
}
