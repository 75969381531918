import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AdminSetTaskStatusActionTypes } from "./ActionTypes";
import { AdminSetTaskStatusBody, AdminSetTaskStatusDetails } from "./Model";
import {
  adminSetTaskStatusAPIResClearAction,
  adminSetTaskStatusErrorAction,
  adminSetTaskStatusLoadingAction,
  adminSetTaskStatusSuccessAction,
  adminSetTaskStatusAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AdminSetTaskStatusDetails;
export const adminSetTaskStatusApiCall = (payload: AdminSetTaskStatusBody) => {
  return function (dispatch: Dispatch<AdminSetTaskStatusActionTypes>) {
    dispatch(adminSetTaskStatusLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/adminActions/admin_set_task_status`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(adminSetTaskStatusLoadingAction(false));
        dispatch(
          adminSetTaskStatusSuccessAction(
            res.data !== undefined ? res.data : ({} as AdminSetTaskStatusDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Admin task status fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(adminSetTaskStatusLoadingAction(false));
        dispatch(
          adminSetTaskStatusErrorAction(
            {} as AdminSetTaskStatusDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAdminSetTaskStatusAPIResMsg = () => {
  return function (dispatch: Dispatch<AdminSetTaskStatusActionTypes>) {
    dispatch(adminSetTaskStatusAPIMsgAction(apiRes as AdminSetTaskStatusDetails, "", "", 0));
  };
};

export const clearAdminSetTaskStatusAPIRes = () => {
  return function (dispatch: Dispatch<AdminSetTaskStatusActionTypes>) {
    dispatch(adminSetTaskStatusAPIResClearAction({} as AdminSetTaskStatusDetails, "", "", 0));
  };
};
