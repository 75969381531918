import { AlertColor, Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import MWExceptionList from "../../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import {
  GetJudgingSecondRoundDetailsListBody,
  PatientDetailsList,
} from "redux/JudgingSecondRoundDetailsList/Model";
import { getJudgingSecondRoundDetailsList } from "redux/JudgingSecondRoundDetailsList/API";
import AddFinalRoundDialog from "./AddFinalRoundDialog";
import MWSnackbar from "component/MWSnackbar";
import { clearAddRakForFinalRoundAPIRes } from "redux/AddFinalRank/API";

type Props = {
  judgementSecondRoundViewLoader?: boolean | any;
};

export default function JudgementSecondRoundView({ judgementSecondRoundViewLoader }: Props) {
  const dispatch = useDispatch();
  const patientPointDataList = useSelector((state: AppState) => state.getJudgingScoundRoundValue);
  const addFinalRoundResValues = useSelector(
    (state: AppState) => state.addRankForFinalRoundvalue,
  );
  const patientDetailsValue =
    patientPointDataList?.getJudgingSecondRoundDetailsListRes?.patientDetailsList;
  const history = useHistory();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  function photoDetails(patientId: number) {
    history.push(`/judgingview/${patientId}`);
  }
  const [competitionMapId, setCompetitionMapId] = useState("");
  const [showFinalRoundDialog, setShowFinalRoundDialog] = React.useState(false);
  const updateAddFinalRoundDialogValFromChild = (passedVal: boolean) => {
    setShowFinalRoundDialog(passedVal);
  };

  const [addFinalRankMsg, setAddFinalRankMsg] = useState("");
  const [addFinalRankMsgType, setAddFinalRankMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addFinalRoundResValues?.addRankForFinalRoundRes?.message !== undefined) {
      setAddFinalRankMsgType("success");
      setAddFinalRankMsg(addFinalRoundResValues?.addRankForFinalRoundRes?.message);
    }
    if (addFinalRoundResValues?.errorMsg !== "") {
      setAddFinalRankMsgType("error");
      setAddFinalRankMsg(addFinalRoundResValues?.errorMsg);
    }
  }, [addFinalRoundResValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const patientListBody = {
      competitionId: competitionid,
    } as GetJudgingSecondRoundDetailsListBody;
    dispatch(getJudgingSecondRoundDetailsList(patientListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const addRank = (photoId: number) => {
    setShowFinalRoundDialog(true);
    setCompetitionMapId(String(photoId));
  };

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddRakForFinalRoundAPIRes());
      setAddFinalRankMsg("");
    }
  };

  return (
    <Container maxWidth="xl">
      {patientPointDataList?.loading
        ? judgementSecondRoundViewLoader(true)
        : judgementSecondRoundViewLoader(false)}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        py={1}
      >
        {patientDetailsValue !== undefined && patientDetailsValue.length ? (
          <React.Fragment>
            {patientDetailsValue?.map((photoItem: PatientDetailsList, index: number) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index}>
                {photoItem?.id && (
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image={
                        photoItem?.patientDocument?.document !== undefined
                          ? photoItem?.patientDocument?.document
                          : ""
                      }
                      alt="image"
                    />
                    <CardActions>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: "none",
                              borderColor: "#c4c4c4",
                              "&:hover": {
                                borderColor: "#c4c4c4",
                              },
                            }}
                            onClick={() => {
                              addRank(photoItem?.id);
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight="bold">
                              Add Rank
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: "none",
                              borderColor: "#c4c4c4",
                              "&:hover": {
                                borderColor: "#c4c4c4",
                              },
                            }}
                            onClick={() => {
                              photoDetails(photoItem?.id);
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight="bold">
                              Details
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                )}
              </Grid>
            ))}
          </React.Fragment>
        ) : (
          <Box p={3}>
            <MWExceptionList />
          </Box>
        )}
      </Grid>
      {showFinalRoundDialog ? (
        <AddFinalRoundDialog
          addFinalRoundDialogEnable={showFinalRoundDialog}
          addFinalRoundDialogUpdateState={updateAddFinalRoundDialogValFromChild}
          competitionid={competitionid}
          competitionMapId={competitionMapId}
        />
      ) : null}
      {addFinalRankMsg !== "" ? (
            <MWSnackbar
              msg={addFinalRankMsg}
              type={addFinalRankMsgType}
              alertClose={addImgtoComp}
            />
          ) : null}
    </Container>
  );
}
