import {
  DiactiveStaffOfProviderOrgActionTypes,
  TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL,
  TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING,
  TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS,
  DiactiveStaffOfProviderOrgState,
  TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG,
  TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES,
} from "./ActionTypes";
import { DiactiveStaffOfProviderOrgDetails } from "./Model";

const initialStateGetPosts: DiactiveStaffOfProviderOrgState = {
  loading: false,
  DiactiveStaffOfProviderOrgRes: {} as DiactiveStaffOfProviderOrgDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const toggleStaffOfProviderOrgReducer = (
  state = initialStateGetPosts,
  action: DiactiveStaffOfProviderOrgActionTypes,
): DiactiveStaffOfProviderOrgState => {
  switch (action.type) {
    case TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS:
      return {
        ...state,
        DiactiveStaffOfProviderOrgRes: action.payload,
        successMsg: action.successMsg,
      };
    case TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL:
      return {
        ...state,
        DiactiveStaffOfProviderOrgRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG:
      return {
        ...state,
        DiactiveStaffOfProviderOrgRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES:
      return {
        ...state,
        DiactiveStaffOfProviderOrgRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
