import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { CompetitionListByDuplicatePatientIdActionTypes } from "./ActionTypes";
import { CompetitionListByDuplicatePatientIdBody, CompetitionListByDuplicatePatientIdDetails } from "./Model";
import {
  CompetitionListByDuplicatePatientIdAPIResClearAction,
  CompetitionListByDuplicatePatientIdErrorAction,
  CompetitionListByDuplicatePatientIdLoadingAction,
  CompetitionListByDuplicatePatientIdSuccessAction,
  CompetitionListByDuplicatePatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as CompetitionListByDuplicatePatientIdDetails;
export const competitionListByDuplicatePatientIdApi = (
  patientid: CompetitionListByDuplicatePatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<CompetitionListByDuplicatePatientIdActionTypes>) {
    dispatch(CompetitionListByDuplicatePatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionListAsStaff/competition_list_by_patient_as_staff?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(CompetitionListByDuplicatePatientIdLoadingAction(false));
        dispatch(
          CompetitionListByDuplicatePatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as CompetitionListByDuplicatePatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Duplicate Patient competition list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(CompetitionListByDuplicatePatientIdLoadingAction(false));
        dispatch(
          CompetitionListByDuplicatePatientIdErrorAction(
            {} as CompetitionListByDuplicatePatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateCompetitionListByDuplicatePatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<CompetitionListByDuplicatePatientIdActionTypes>) {
    dispatch(
      CompetitionListByDuplicatePatientIdAPIMsgAction(apiRes as CompetitionListByDuplicatePatientIdDetails, "", "", 0),
    );
  };
};

export const clearCompetitionListByDuplicatePatientIdAPIRes = () => {
  return function (dispatch: Dispatch<CompetitionListByDuplicatePatientIdActionTypes>) {
    dispatch(
      CompetitionListByDuplicatePatientIdAPIResClearAction({} as CompetitionListByDuplicatePatientIdDetails, "", "", 0),
    );
  };
};
