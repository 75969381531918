import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetActiveListByOrgIdActionTypes } from "./ActionTypes";
import { GetActiveListByOrgIdBody, GetActiveListByOrgIdDetails } from "./Model";
import {
  GetActiveListByOrgIdAPIResClearAction,
  GetActiveListByOrgIdErrorAction,
  GetActiveListByOrgIdLoadingAction,
  GetActiveListByOrgIdSuccessAction,
  GetActiveListByOrgIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetActiveListByOrgIdDetails;
export const getActiveListByOrgId = (
  organizationId: GetActiveListByOrgIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetActiveListByOrgIdActionTypes>) {
    dispatch(GetActiveListByOrgIdLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientorganizationstafflist/staff_list_patient_organization?page=${Number(
            pageNo,
          )}&size=50`,
        organizationId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(GetActiveListByOrgIdLoadingAction(false));
        dispatch(
          GetActiveListByOrgIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetActiveListByOrgIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(GetActiveListByOrgIdLoadingAction(false));
        dispatch(
          GetActiveListByOrgIdErrorAction(
            {} as GetActiveListByOrgIdDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const viewOrganizationStaffListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetActiveListByOrgIdActionTypes>) {
    dispatch(
      GetActiveListByOrgIdAPIMsgAction(
        apiRes as GetActiveListByOrgIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearViewOrgStaffListAPIRes = () => {
  return function (dispatch: Dispatch<GetActiveListByOrgIdActionTypes>) {
    dispatch(
      GetActiveListByOrgIdAPIResClearAction(
        {} as GetActiveListByOrgIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};
