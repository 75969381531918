import {
  ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL,
  ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING,
  ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS,
  ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG,
  ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES,
  GetOrganizationDeactiveStaffListActionTypes,
} from "./ActionTypes";
import { GetOrganizationDeactiveStaffListRes } from "./Model";

export const getOrganizationDeactiveStaffListLoadingAction = (
  loading: boolean,
): GetOrganizationDeactiveStaffListActionTypes => {
  return {
    type: ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING,
    loading: loading,
  };
};

export const getOrganizationDeactiveStaffListSuccessAction = (
  getOrganizationDeactiveStaffListResponse: GetOrganizationDeactiveStaffListRes,
  successMsg: string,
): GetOrganizationDeactiveStaffListActionTypes => {
  return {
    type: ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS,
    payload: getOrganizationDeactiveStaffListResponse,
    successMsg: successMsg,
  };
};

export const getOrganizationDeactiveStaffListErrorAction = (
  getOrganizationDeactiveStaffListResponse: GetOrganizationDeactiveStaffListRes,
  errMsg: string,
  status: number,
): GetOrganizationDeactiveStaffListActionTypes => {
  return {
    type: ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL,
    payload: getOrganizationDeactiveStaffListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getOrganizationDeactiveStaffListUpdateAPIMsgAction = (
  getOrganizationDeactiveStaffListResponse: GetOrganizationDeactiveStaffListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetOrganizationDeactiveStaffListActionTypes => {
  return {
    type: ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG,
    payload: getOrganizationDeactiveStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getOrganizationDeactiveStaffListAPIResClearAction = (
  getOrganizationDeactiveStaffListResponse: GetOrganizationDeactiveStaffListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetOrganizationDeactiveStaffListActionTypes => {
  return {
    type: ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES,
    payload: getOrganizationDeactiveStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
