import { GetProviderOrganizationDeactiveStaffListRes } from "./Model";
export const PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING = "PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING";
export const PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS = "PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS";
export const PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL = "PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL";
export const PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG = "PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG";
export const PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES = "PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES";

export interface GetProviderOrganizationDeactiveStaffListState {
  getProviderOrganizationDeactiveStaffList: GetProviderOrganizationDeactiveStaffListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrganizationDeactiveStaffListLoading {
  type: typeof PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING;
  loading: boolean;
}
export interface GetProviderOrganizationDeactiveStaffListSuccess {
  type: typeof PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS;
  payload: GetProviderOrganizationDeactiveStaffListRes;
  successMsg: string;
}
export interface GetProviderOrganizationDeactiveStaffListFail {
  type: typeof PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL;
  payload: GetProviderOrganizationDeactiveStaffListRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrganizationDeactiveStaffListUpdateAPIMsg {
  type: typeof PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG;
  payload: GetProviderOrganizationDeactiveStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrganizationDeactiveStaffListUpdateAPIRes {
  type: typeof PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES;
  payload: GetProviderOrganizationDeactiveStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetProviderOrganizationDeactiveStaffListActionTypes =
  | GetProviderOrganizationDeactiveStaffListLoading
  | GetProviderOrganizationDeactiveStaffListSuccess
  | GetProviderOrganizationDeactiveStaffListFail
  | GetProviderOrganizationDeactiveStaffListUpdateAPIMsg
  | GetProviderOrganizationDeactiveStaffListUpdateAPIRes;
