import {
  EXECUTIVE_LIST_FAIL,
  EXECUTIVE_LIST_LOADING,
  EXECUTIVE_LIST_SUCCESS,
  EXECUTIVE_LIST_UPDATE_API_MSG,
  EXECUTIVE_LIST_UPDATE_API_RES,
  ExecutiveListActionTypes,
} from "./ActionTypes";
import { ExecutiveListRes } from "./Model";

export const executiveListLoadingAction = (loading: boolean): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_LOADING,
    loading: loading,
  };
};

export const executiveListSuccessAction = (
  executiveListRes: ExecutiveListRes,
  successMsg: string,
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_SUCCESS,
    payload: executiveListRes,
    successMsg: successMsg,
  };
};

export const executiveListErrorAction = (
  executiveListRes: ExecutiveListRes,
  errMsg: string,
  status: number,
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_FAIL,
    payload: executiveListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const executiveListUpdateAPIMsgAction = (
  executiveListRes: ExecutiveListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_UPDATE_API_MSG,
    payload: executiveListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const executiveListAPIResClearAction = (
  executiveListRes: ExecutiveListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_UPDATE_API_RES,
    payload: executiveListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
