import {
  INSTANCE_LIST_BY_PATIENT_ID_FAIL,
  INSTANCE_LIST_BY_PATIENT_ID_LOADING,
  INSTANCE_LIST_BY_PATIENT_ID_SUCCESS,
  INSTANCE_LIST_BY_PATIENT_ID_API_MSG,
  INSTANCE_LIST_BY_PATIENT_ID_API_RES,
  InstanceListByPatientIdActionTypes,
} from "./ActionTypes";
import { InstanceListByPatientIdDetails } from "./Model";

export const InstanceListByPatientIdLoadingAction = (
  loading: boolean,
): InstanceListByPatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const InstanceListByPatientIdSuccessAction = (
  instanceListByPatientIdResponse: InstanceListByPatientIdDetails,
  successMsg: string,
): InstanceListByPatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_PATIENT_ID_SUCCESS,
    payload: instanceListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const InstanceListByPatientIdErrorAction = (
  instanceListByPatientIdResponse: InstanceListByPatientIdDetails,
  errMsg: string,
  status: number,
): InstanceListByPatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_PATIENT_ID_FAIL,
    payload: instanceListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const InstanceListByPatientIdAPIMsgAction = (
  instanceListByPatientIdResponse: InstanceListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): InstanceListByPatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_PATIENT_ID_API_MSG,
    payload: instanceListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const InstanceListByPatientIdAPIResClearAction = (
  instanceListByPatientIdResponse: InstanceListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): InstanceListByPatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_PATIENT_ID_API_RES,
    payload: instanceListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
