import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetCompetitionDeactiveStaffListActionTypes } from "./ActionTypes";
import { GetCompetitionDeactiveStaffListBody, GetCompetitionDeactiveStaffListRes } from "./Model";
import {
  getCompetitionDeactiveStaffListAPIResClearAction,
  getCompetitionDeactiveStaffListErrorAction,
  getCompetitionDeactiveStaffListLoadingAction,
  getCompetitionDeactiveStaffListSuccessAction,
  getCompetitionDeactiveStaffListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetCompetitionDeactiveStaffListRes;
export const getDeactiveStaffListByCompetitionId = (
  payload: GetCompetitionDeactiveStaffListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetCompetitionDeactiveStaffListActionTypes>) {
    dispatch(getCompetitionDeactiveStaffListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionStaffList/deactive_staff_list_competition?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getCompetitionDeactiveStaffListLoadingAction(false));
        dispatch(
          getCompetitionDeactiveStaffListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetCompetitionDeactiveStaffListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient organization staff list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getCompetitionDeactiveStaffListLoadingAction(false));
        dispatch(
          getCompetitionDeactiveStaffListErrorAction(
            {} as GetCompetitionDeactiveStaffListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDeactiveStaffListByCompetitionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetCompetitionDeactiveStaffListActionTypes>) {
    dispatch(
      getCompetitionDeactiveStaffListUpdateAPIMsgAction(
        apiRes as GetCompetitionDeactiveStaffListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearDeactiveStaffListByCompetitionIdAPIRes = () => {
  return function (dispatch: Dispatch<GetCompetitionDeactiveStaffListActionTypes>) {
    dispatch(
      getCompetitionDeactiveStaffListAPIResClearAction(
        {} as GetCompetitionDeactiveStaffListRes,
        "",
        "",
        0,
      ),
    );
  };
};
