import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { OrganizationToggleActiveRegistrationRequestDetailsActionTypes } from "./ActionTypes";
import {
  OrganizationToggleActiveRegReqDetailsBody,
  OrganizationToggleActiveRegReqDetailsRes,
} from "./Model";
import {
  organizationToggleActiveRegistrationRequestDetailsAPIResClearAction,
  organizationToggleActiveRegistrationRequestDetailsErrorAction,
  organizationToggleActiveRegistrationRequestDetailsLoadingAction,
  organizationToggleActiveRegistrationRequestDetailsSuccessAction,
  organizationToggleActiveRegistrationRequestDetailsUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as OrganizationToggleActiveRegReqDetailsRes;
export const organizationToggleActiveRegistrationRequestDetails = (
  payload: OrganizationToggleActiveRegReqDetailsBody,
) => {
  return function (
    dispatch: Dispatch<OrganizationToggleActiveRegistrationRequestDetailsActionTypes>,
  ) {
    dispatch(organizationToggleActiveRegistrationRequestDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientOrganizationRegistrationDetail/toggle_active_registration_request_detail_patientOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(organizationToggleActiveRegistrationRequestDetailsLoadingAction(false));
        dispatch(
          organizationToggleActiveRegistrationRequestDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as OrganizationToggleActiveRegReqDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "PatientOrganizationRegistrationRequestDetail status of is now False",
          ),
        );
      })
      .catch((error) => {
        dispatch(organizationToggleActiveRegistrationRequestDetailsLoadingAction(false));
        dispatch(
          organizationToggleActiveRegistrationRequestDetailsErrorAction(
            {} as OrganizationToggleActiveRegReqDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateOrganizationToggleActiveRegistrationRequestDetailsAPIResMsg = () => {
  return function (
    dispatch: Dispatch<OrganizationToggleActiveRegistrationRequestDetailsActionTypes>,
  ) {
    dispatch(
      organizationToggleActiveRegistrationRequestDetailsUpdateAPIMsgAction(
        apiRes as OrganizationToggleActiveRegReqDetailsRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearOrganizationToggleActiveRegistrationRequestDetailsAPIRes = () => {
  return function (
    dispatch: Dispatch<OrganizationToggleActiveRegistrationRequestDetailsActionTypes>,
  ) {
    dispatch(
      organizationToggleActiveRegistrationRequestDetailsAPIResClearAction(
        {} as OrganizationToggleActiveRegReqDetailsRes,
        "",
        "",
        0,
      ),
    );
  };
};
