import {
  CheckAppointmentPaymentStatusActionTypes,
  GET_APPOINTMENT_PAYMENT_STATUS_FAIL,
  GET_APPOINTMENT_PAYMENT_STATUS_LOADING,
  GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS,
  CheckAppointmentPaymentStatusState,
  GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG,
  GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES,
} from "./ActionTypes";
import { CheckAppointmentPaymentStatusRes } from "./Model";

const initialStateCheckPosts: CheckAppointmentPaymentStatusState = {
  loading: false,
  checkAppointmentPaymentStatusRes: {} as CheckAppointmentPaymentStatusRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const checkAppointmentPaymentStatusReducer = (
  state = initialStateCheckPosts,
  action: CheckAppointmentPaymentStatusActionTypes,
): CheckAppointmentPaymentStatusState => {
  switch (action.type) {
    case GET_APPOINTMENT_PAYMENT_STATUS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        checkAppointmentPaymentStatusRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_APPOINTMENT_PAYMENT_STATUS_FAIL:
      return {
        ...state,
        checkAppointmentPaymentStatusRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG:
      return {
        ...state,
        checkAppointmentPaymentStatusRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES:
      return {
        ...state,
        checkAppointmentPaymentStatusRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
