import {
  GET_ORGANIZATION_ADMIN_LIST_FAIL,
  GET_ORGANIZATION_ADMIN_LIST_LOADING,
  GET_ORGANIZATION_ADMIN_LIST_SUCCESS,
  GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_MSG,
  GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_RES,
  GetOrganizationAdminListActionTypes,
} from "./ActionTypes";
import { GetOrganizationAdminListRes } from "./Model";

export const getOrganizationAdminListLoadingAction = (
  loading: boolean,
): GetOrganizationAdminListActionTypes => {
  return {
    type: GET_ORGANIZATION_ADMIN_LIST_LOADING,
    loading: loading,
  };
};

export const getOrganizationAdminListSuccessAction = (
  getOrganizationAdminListResponse: GetOrganizationAdminListRes,
  successMsg: string,
): GetOrganizationAdminListActionTypes => {
  return {
    type: GET_ORGANIZATION_ADMIN_LIST_SUCCESS,
    payload: getOrganizationAdminListResponse,
    successMsg: successMsg,
  };
};

export const getOrganizationAdminListErrorAction = (
  getOrganizationAdminListResponse: GetOrganizationAdminListRes,
  errMsg: string,
  status: number,
): GetOrganizationAdminListActionTypes => {
  return {
    type: GET_ORGANIZATION_ADMIN_LIST_FAIL,
    payload: getOrganizationAdminListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getOrganizationAdminListUpdateAPIMsgAction = (
  getOrganizationAdminListResponse: GetOrganizationAdminListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetOrganizationAdminListActionTypes => {
  return {
    type: GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_MSG,
    payload: getOrganizationAdminListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getOrganizationAdminListAPIResClearAction = (
  getOrganizationAdminListResponse: GetOrganizationAdminListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetOrganizationAdminListActionTypes => {
  return {
    type: GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_RES,
    payload: getOrganizationAdminListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
