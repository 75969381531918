import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AdminTaskStatusListActionTypes } from "./ActionTypes";
import { AdminTaskStatusListRes } from "./Model";
import {
  adminTaskStatusListAPIResClearAction,
  adminTaskStatusListErrorAction,
  adminTaskStatusListLoadingAction,
  adminTaskStatusListSuccessAction,
  adminTaskStatusListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as AdminTaskStatusListRes;
export const adminTaskStatusListApi = () => {
  return function (dispatch: Dispatch<AdminTaskStatusListActionTypes>) {
    dispatch(adminTaskStatusListLoadingAction(true));
    axios
      .get(Url.baseUrl + `/adminapp/api/adminActions/task_status_list_dropdown`, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(adminTaskStatusListLoadingAction(false));
        dispatch(
          adminTaskStatusListSuccessAction(
            res.data !== undefined ? res.data : ({} as AdminTaskStatusListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Note status has been changed successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(adminTaskStatusListLoadingAction(false));
        dispatch(
          adminTaskStatusListErrorAction(
            {} as AdminTaskStatusListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAdminTaskStatusListAPIResMsg = () => {
  return function (dispatch: Dispatch<AdminTaskStatusListActionTypes>) {
    dispatch(
      adminTaskStatusListUpdateAPIMsgAction(apiRes as AdminTaskStatusListRes, "", "", 0),
    );
  };
};

export const clearAdminTaskStatusListAPIRes = () => {
  return function (dispatch: Dispatch<AdminTaskStatusListActionTypes>) {
    dispatch(
      adminTaskStatusListAPIResClearAction({} as AdminTaskStatusListRes, "", "", 0),
    );
  };
};
