import { PageCount, GetJudgingSecondRoundDetailsListDetails } from "./Model";

// Patient Demographics Loading State
export const GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING = "GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING";
export const GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS = "GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS";
export const GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL = "GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL";
export const GET_JUDGING_SECOND_ROUND_LIST_ID_DATA = "GET_JUDGING_SECOND_ROUND_LIST_ID_DATA";
export const GET_JUDGING_SECOND_ROUND_LIST_ID_API_MSG = "GET_JUDGING_SECOND_ROUND_LIST_ID_API_MSG";
export const GET_JUDGING_LIST_ID_API_RES = "GET_JUDGING_LIST_ID_API_RES";

export interface GetJudgingSecondRoundDetailsListState {
  getJudgingSecondRoundDetailsListRes: GetJudgingSecondRoundDetailsListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface GetJudgingSecondRoundDetailsListLoading {
  type: typeof GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING;
  loading: boolean;
}
export interface GetJudgingSecondRoundDetailsListSuccess {
  type: typeof GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS;
  payload: GetJudgingSecondRoundDetailsListDetails;
}
export interface GetJudgingSecondRoundDetailsListFail {
  type: typeof GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL;
  payload: GetJudgingSecondRoundDetailsListDetails;
  message: string;
  status: number;
}
export interface GetJudgingSecondRoundDetailsListAPIMsg {
  type: typeof GET_JUDGING_SECOND_ROUND_LIST_ID_API_MSG;
  payload: GetJudgingSecondRoundDetailsListDetails;
  message: string;
  status: number;
}
export interface GetJudgingSecondRoundDetailsListRes {
  type: typeof GET_JUDGING_LIST_ID_API_RES;
  payload: GetJudgingSecondRoundDetailsListDetails;
  message: string;
  status: number;
}
interface GetJudgingSecondRoundDetailsListDataAction {
  type: typeof GET_JUDGING_SECOND_ROUND_LIST_ID_DATA;
  payload: GetJudgingSecondRoundDetailsListDetails;
  pageCount: PageCount;
}

export type GetJudgingSecondRoundDetailsListActionTypes =
  | GetJudgingSecondRoundDetailsListDataAction
  | GetJudgingSecondRoundDetailsListLoading
  | GetJudgingSecondRoundDetailsListSuccess
  | GetJudgingSecondRoundDetailsListFail
  | GetJudgingSecondRoundDetailsListAPIMsg
  | GetJudgingSecondRoundDetailsListRes;
