import {
  AddRegistrationRequestDetailsActionTypes,
  ADD_REGISTRATION_REQUEST_DETAILS_FAIL,
  ADD_REGISTRATION_REQUEST_DETAILS_LOADING,
  ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS,
  AddRegistrationRequestDetailsState,
  ADD_REGISTRATION_REQUEST_DETAILS_API_MSG,
  ADD_REGISTRATION_REQUEST_DETAILS_API_RES,
} from "./ActionTypes";
import { AddRegistrationRequestDetailsDetails } from "./Model";

const initialStateGetPosts: AddRegistrationRequestDetailsState = {
  loading: false,
  addRegistrationRequestDetailsRes: {} as AddRegistrationRequestDetailsDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addRegistrationRequestDetailsReducer = (
  state = initialStateGetPosts,
  action: AddRegistrationRequestDetailsActionTypes,
): AddRegistrationRequestDetailsState => {
  switch (action.type) {
    case ADD_REGISTRATION_REQUEST_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        addRegistrationRequestDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_REGISTRATION_REQUEST_DETAILS_FAIL:
      return {
        ...state,
        addRegistrationRequestDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_REGISTRATION_REQUEST_DETAILS_API_MSG:
      return {
        ...state,
        addRegistrationRequestDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_REGISTRATION_REQUEST_DETAILS_API_RES:
      return {
        ...state,
        addRegistrationRequestDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
