import { GET_LIST } from "../../types/ActionTypes";
import { AddPastMedicalResponse } from "./Model";

// Patient Demographics Loading State
export const ADD_PAST_MEDICAL_LOADING = "ADD_PAST_MEDICAL_LOADING";
export const ADD_PAST_MEDICAL_SUCCESS = "ADD_PAST_MEDICAL_SUCCESS";
export const ADD_PAST_MEDICAL_FAIL = "ADD_PAST_MEDICAL_FAIL";

export interface GetPatientDemographicsState {
  addPastMedical: AddPastMedicalResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof ADD_PAST_MEDICAL_LOADING;
}
export interface DoctorListSuccess {
  type: typeof ADD_PAST_MEDICAL_SUCCESS;
  payload: AddPastMedicalResponse;
}
export interface DoctorListFail {
  type: typeof ADD_PAST_MEDICAL_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: AddPastMedicalResponse;
}

export type AddPastMedicalActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
