import {
    GetOrganizationRegistrationReqDetailsListActionTypes,
   ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL,
   ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING,
   ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS,
   GetOrganizationRegistrationReqDetailsListState,
   ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG,
   ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES,
 } from "./ActionTypes";
 import { GetOrganizationRequestDetailsListRes } from "./Model";
 
 const initialStateGetPosts: GetOrganizationRegistrationReqDetailsListState = {
   loading: false,
   getOrganizationRegistrationReqDetailsListresponse: {} as GetOrganizationRequestDetailsListRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const  getOrganizationRegistrationRequestDetailsListReducer = (
   state = initialStateGetPosts,
   action:  GetOrganizationRegistrationReqDetailsListActionTypes,
 ): GetOrganizationRegistrationReqDetailsListState => {
   switch (action.type) {
     case ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS:
       return {
         ...state,
         getOrganizationRegistrationReqDetailsListresponse: action.payload,
         successMsg: action.successMsg,
       };
     case ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL:
       return {
         ...state,
         getOrganizationRegistrationReqDetailsListresponse: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG:
       return {
         ...state,
         getOrganizationRegistrationReqDetailsListresponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES:
       return {
         ...state,
         getOrganizationRegistrationReqDetailsListresponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 