import axios from "axios";
import { Dispatch } from "redux";
import url from "../../../ApiConfig";
import { AddPatientTeamByAdminActionTypes } from "./ActionTypes";
import { AddPatientTeamByAdminBody, AddPatientTeamByAdminRes } from "./Model";
import {
  addPatientTeamByAdminAPIResClearAction,
  addPatientTeamByAdminErrorAction,
  addPatientTeamByAdminLoadingAction,
  addPatientTeamByAdminSuccessAction,
  addPatientTeamByAdminAPIMsgAction,
} from "./Action";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "../../../redux/Authentication/Interceptors";

let apiRes = {} as AddPatientTeamByAdminRes;
export const addPatientTeamByAdmin = (payload: AddPatientTeamByAdminBody) => {
  return function (dispatch: Dispatch<AddPatientTeamByAdminActionTypes>) {
    dispatch(addPatientTeamByAdminLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(url.baseUrl + `/adminapp/api/adminActions/add_patient_user_account`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientTeamByAdminLoadingAction(false));
        dispatch(
          addPatientTeamByAdminSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient Team has been created successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addPatientTeamByAdminLoadingAction(false));
        dispatch(
          addPatientTeamByAdminErrorAction(
            {} as AddPatientTeamByAdminRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddPatientTeamByAdminAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientTeamByAdminActionTypes>) {
    dispatch(addPatientTeamByAdminAPIMsgAction(apiRes as AddPatientTeamByAdminRes, "", "", 0));
  };
};

export const clearAddPatientTeamByAdminAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientTeamByAdminActionTypes>) {
    dispatch(addPatientTeamByAdminAPIResClearAction({} as AddPatientTeamByAdminRes, "", "", 0));
  };
};
