import { GetPatientDetailsTypeOfSessionDetails } from "./Model";
export const GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING = "GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING";
export const GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS = "GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS";
export const GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL = "GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL";
export const GET_PATIENT_DETAILS_TYPE_OF_SESSION_DATA = "GET_PATIENT_DETAILS_TYPE_OF_SESSION_DATA";
export const GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG = "GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG";
export const GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES = "GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES";

export interface GetPatientDetailsTypeOfSessionListState {
  getPatientDetailsTypeofSessionListRes: GetPatientDetailsTypeOfSessionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsTypeOfSessionListLoading {
  type: typeof GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING;
  loading: boolean;
}
export interface GetPatientDetailsTypeOfSessionListSuccess {
  type: typeof GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS;
  payload: GetPatientDetailsTypeOfSessionDetails;
  successMsg: string;
}
export interface GetPatientDetailsTypeOfSessionListFail {
  type: typeof GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL;
  payload: GetPatientDetailsTypeOfSessionDetails;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsTypeOfSessionListAPIMsg {
  type: typeof GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG;
  payload: GetPatientDetailsTypeOfSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsTypeOfSessionListAPIRes {
  type: typeof GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES;
  payload: GetPatientDetailsTypeOfSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type GetPatientDetailsTypeOfSessionListActionTypes =
  | GetPatientDetailsTypeOfSessionListLoading
  | GetPatientDetailsTypeOfSessionListSuccess
  | GetPatientDetailsTypeOfSessionListFail
  | GetPatientDetailsTypeOfSessionListAPIMsg
  | GetPatientDetailsTypeOfSessionListAPIRes;
