import * as React from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { PatientObservationsList } from "../../../redux/patientDashboardDataListByObservationType/Model";
import { AppState } from "../../../redux/store/Store";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { formatTimeString } from "component/Utility";

export default function ObservationDetailsTable() {
  const { observationtype } = useParams() as {
    observationtype: string;
  };
  const dataListByObservationTypeResponse = useSelector(
    (state: AppState) => state.dataListByObservationTypeRes,
  );
  const sleepScoreObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepScoreObservationTypeValue,
  );
  const sleepTimeObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepTimeObservationTypeValue,
  );
  const [observationListByType, setObservationListByType] = React.useState([] as any[]);
  const [sleepScoreListByType, setSleepScoreListByType] = React.useState([] as any[]);
  const [sleepTimeListByType, setSleepTimeListByType] = React.useState([] as any[]);
  const [sleepDataListByType, setSleepDataListByType] = React.useState([] as any[]);
  const [sleepDataByType, setSleepDataByType] = React.useState([] as any[]);
  const sleepScheduleObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepScheduleTypeValue,
  );
  const [sleepScheduleObservationListByType, setSleepScheduleObservationListByType] =
    React.useState([] as any[]);

  const columns: GridColDef[] = [
    {
      field: "value",
      headerName: observationtype === "sleep" ? "Sleep Score" : "Reading",
      flex: 1,
      sortable: true,
    },

    {
      field: "sleepTime",
      headerName: "Time Asleep",
      flex: 1,
      sortable: true,
      hide: observationtype === "sleep" ? false : true,
    },

    {
      field: "sleepSchedule",
      headerName: "Sleep Schedule",
      flex: 1,
      sortable: true,
      hide: observationtype === "sleep" ? false : true,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 130,
      sortable: true,
    },

    {
      field: "modifiedDate",
      headerName: "Modified Date",
      minWidth: 130,
      sortable: true,
      hide: observationtype === "sleep" ? true : false,
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "number",
      minWidth: 30,
      sortable: true,
      hide: observationtype === "sleep" ? true : false,
    },
  ];

  React.useEffect(() => {
    if (observationtype === "sleep") {
      if (
        sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes
          ?.patientObservationsList !== undefined
      ) {
        let modifiedSleepObservationList =
          sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              id: element.id,
              name: element.patientObservationType.displayValue,
              value: element.value,
              startDate:
                element.startDate !== null
                  ? dayjs(element.startDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              createDate:
                element.createDate !== null
                  ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              modifiedDate:
                element.modifiedDate !== null
                  ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              endDate:
                element.endDate !== null ? dayjs(element.endDate).format("ddd, DD/MM/YYYY") : "N/A",
              isActive: element.isActive === true ? "Active" : "Inactive",
            }),
          );
        setSleepScoreListByType(modifiedSleepObservationList);
      }
    }
    if (
      sleepTimeObservationTypeValueList?.sleepTimeObservationTypeRes?.patientObservationsList !==
      undefined
    ) {
      let modifiedSleepTimeObservationList =
        sleepTimeObservationTypeValueList?.sleepTimeObservationTypeRes?.patientObservationsList.map(
          (element: PatientObservationsList) => ({
            id: element.id,
            name: element.patientObservationType.displayValue,
            sleepTime: formatTimeString(element.value),
            startDate:
              element.startDate !== null
                ? dayjs(element.startDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            createDate:
              element.createDate !== null
                ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            modifiedDate:
              element.modifiedDate !== null
                ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            endDate:
              element.endDate !== null ? dayjs(element.endDate).format("ddd, DD/MM/YYYY") : "N/A",
            isActive: element.isActive === true ? "Active" : "Inactive",
          }),
        );
      setSleepTimeListByType(modifiedSleepTimeObservationList);
    }
    if (
      sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes
        ?.patientObservationsList !== undefined
    ) {
      let modifiedSleepObservationList =
        sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes?.patientObservationsList.map(
          (element: PatientObservationsList) => ({
            id: element.id,
            name: element.patientObservationType.displayValue,
            sleepSchedule: element.value.replace(
              /(\d{1,2})-(\d{2})\s([AP]M)\s~\s(\d{1,2})-(\d{2})\s([AP]M)/,
              "$1:$2 $3 to $4:$5 $6",
            ),
            startDate:
              element.startDate !== null
                ? dayjs(element.startDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            createDate:
              element.createDate !== null
                ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            modifiedDate:
              element.modifiedDate !== null
                ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            endDate:
              element.endDate !== null ? dayjs(element.endDate).format("ddd, DD/MM/YYYY") : "N/A",
            isActive: element.isActive === true ? "Active" : "Inactive",
          }),
        );
      setSleepScheduleObservationListByType(modifiedSleepObservationList);
    } else {
      setSleepScheduleObservationListByType([] as any[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sleepScoreObservationTypeValueList,
    sleepTimeObservationTypeValueList,
    sleepScheduleObservationTypeValueList,
  ]);

  React.useEffect(() => {
    const resultArray = [];
    if (sleepScoreListByType !== ([] as any[]) && sleepTimeListByType !== ([] as any[])) {
      for (const objA of sleepTimeListByType) {
        const matchingObjB = sleepScoreListByType.find(
          (objB: any) => objB.startDate === objA.startDate,
        );
        if (matchingObjB) {
          const mergedObj = {
            createDate: objA.createDate,
            endDate: "N/A",
            isActive: objA.isActive,
            modifiedDate: objA.modifiedDate,
            sleepTime: objA?.sleepTime?.trim(),
            startDate: objA.startDate,
            value: matchingObjB?.value.trim(),
          };

          resultArray.push(mergedObj);
        }
      }
      //let sleepMergeArray = Array.prototype.push.apply(sleepScoreListByType, sleepTimeListByType);
    }
    const newData = resultArray.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setSleepDataListByType(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepScoreListByType, sleepTimeListByType]);

  React.useEffect(() => {
    const result2Array = [];
    if (
      sleepDataListByType !== ([] as any[]) &&
      sleepScheduleObservationListByType !== ([] as any[])
    ) {
      for (const objA of sleepDataListByType) {
        const matchingObjB = sleepScheduleObservationListByType.find(
          (objB: any) => objB.startDate === objA.startDate,
        );
        if (matchingObjB) {
          const mergedObj = {
            createDate: objA.createDate,
            endDate: "N/A",
            isActive: objA.isActive,
            modifiedDate: objA.modifiedDate,
            sleepTime: objA?.sleepTime?.trim(),
            sleepSchedule: matchingObjB?.sleepSchedule?.trim(),
            startDate: objA.startDate,
            value: objA?.value.trim(),
          };

          result2Array.push(mergedObj);
        }
      }
      //let sleepMergeArray = Array.prototype.push.apply(sleepScoreListByType, sleepTimeListByType);
    }
    const newData2 = result2Array.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setSleepDataByType(newData2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepDataListByType, sleepScheduleObservationListByType]);

  React.useEffect(() => {
    if (
      dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList !==
      undefined
    ) {
      if (
        dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList
          .length > 0
      ) {
        let modifiedObservationList =
          dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              id: element.id,
              name: element.patientObservationType.displayValue,
              value: element.value,
              startDate:
                element.startDate !== null
                  ? dayjs(element.startDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              createDate:
                element.createDate !== null
                  ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              modifiedDate:
                element.modifiedDate !== null
                  ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              endDate:
                element.endDate !== null ? dayjs(element.endDate).format("ddd, DD/MM/YYYY") : "N/A",
              isActive: element.isActive === true ? "Active" : "Inactive",
            }),
          );
        setObservationListByType(modifiedObservationList);
      } else {
        setObservationListByType([] as any[]);
      }
    } else {
      setObservationListByType([] as any[]);
    }
  }, [dataListByObservationTypeResponse]);
  return (
    <Paper>
      {observationtype === "sleep" ? (
        <DataGrid
          rows={sleepDataByType}
          columns={columns}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      ) : (
        <DataGrid
          rows={observationListByType}
          columns={columns}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      )}
    </Paper>
  );
}
