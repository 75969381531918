import {
  DarkTheme as DefaultDarkTheme
} from "./theme/index";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const DarkTheme = createTheme({
  ...DefaultDarkTheme,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "8px",
          minHeight: "40px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "left",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "white",
          "&.Mui-selected": {
            backgroundColor: "#5d5d5d",
            color: "#00ddff",
            borderLeft: "solid 2px",
            "&.Mui-focusVisible": { 
              background: "#00ddff"
            },
            "& .MuiListItemIcon-root": {
              color: "#00ddff"
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #2f2f2f inset',
            '-webkit-text-fill-color': '#fff'
          }
        }
      }
    }
  }
});

const mwTheme = responsiveFontSizes(DarkTheme);
export default mwTheme;