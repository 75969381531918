import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { GetPatientDetailsByIdActionTypes } from "./ActionTypes";
import { GetPatientTeamTeamRes, PatientTeamBody } from "./Model";
import {
  getPatientTeamByIdAPIResClearAction,
  getPatientTeamByIdErrorAction,
  getPatientTeamByIdLoadingAction,
  getPatientTeamByIdSuccessAction,
  getPatientTeamByIdUpdateAPIMsgAction,
} from "./Action";
import { headers } from "../../component/Utility";

let apiRes = {} as GetPatientTeamTeamRes;
export const getPatientTeamById = (editBody: PatientTeamBody) => {
  return function (dispatch: Dispatch<GetPatientDetailsByIdActionTypes>) {
    dispatch(getPatientTeamByIdLoadingAction(true));
    axios
      .post(
        baseUrl.baseUrl +
          `/adminapp/api/adminActions/get_patientTeam_by_patientId`,
        editBody,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientTeamByIdLoadingAction(false));
        dispatch(getPatientTeamByIdSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getPatientTeamByIdLoadingAction(false));
        dispatch(
          getPatientTeamByIdErrorAction(
            {} as GetPatientTeamTeamRes,
            error.response !== undefined ? error.response.data.message : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientTeamByIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsByIdActionTypes>) {
    dispatch(getPatientTeamByIdUpdateAPIMsgAction(apiRes as GetPatientTeamTeamRes, "", 0));
  };
};

export const clearGetPatientTeamByIdAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsByIdActionTypes>) {
    dispatch(getPatientTeamByIdAPIResClearAction({} as GetPatientTeamTeamRes, "", 0));
  };
};
