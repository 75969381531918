import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DuplicateObservationListActionTypes } from "./ActionTypes";
import { DuplicateObservationListBody, DuplicateObservationListDetails } from "./Model";
import {
  DuplicateObservationListAPIResClearAction,
  DuplicateObservationListErrorAction,
  DuplicateObservationListLoadingAction,
  DuplicateObservationListSuccessAction,
  DuplicateObservationListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as DuplicateObservationListDetails;
export const duplicateObservationListApi = (
  patientid: DuplicateObservationListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<DuplicateObservationListActionTypes>) {
    dispatch(DuplicateObservationListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientObservation/getListOfObservationsExtractedFromPatientDetails?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DuplicateObservationListLoadingAction(false));
        dispatch(
          DuplicateObservationListSuccessAction(
            res.data !== undefined ? res.data : ({} as DuplicateObservationListDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(DuplicateObservationListLoadingAction(false));
        dispatch(
          DuplicateObservationListErrorAction(
            {} as DuplicateObservationListDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDuplicateObservationListAPIResMsg = () => {
  return function (dispatch: Dispatch<DuplicateObservationListActionTypes>) {
    dispatch(
      DuplicateObservationListAPIMsgAction(apiRes as DuplicateObservationListDetails, "", "", 0),
    );
  };
};

export const clearDuplicateObservationListAPIRes = () => {
  return function (dispatch: Dispatch<DuplicateObservationListActionTypes>) {
    dispatch(
      DuplicateObservationListAPIResClearAction({} as DuplicateObservationListDetails, "", "", 0),
    );
  };
};
