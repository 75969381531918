import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Grid, Tab, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { HealthCampDoctor } from "./Doctor";
import HealthCampStaffList from "./Staff";

type Props = {
  healthCampStaffListLoader: boolean | any;
};
export default function ViewHealthCampStaffList({ healthCampStaffListLoader }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  return (
    <Box>
      <TabContext value={String(selected)}>
        <Box
          sx={{
            borderColor: "divider",
            width: fullScreen ? "calc(100vw - 10vw)" : "100%",
          }}
        >
          <TabList onChange={handleTabChange}>
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Staff</Typography>
                  </Grid>
                </Grid>
              }
              value="1"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Doctor</Typography>
                  </Grid>
                </Grid>
              }
              value="2"
            />
          </TabList>
        </Box>
        <Divider />
        {fullScreen ? (
          <Box pt={2}>
            {selected === "1" ? (
              <HealthCampStaffList healthCampStaffListLoader={healthCampStaffListLoader} />
            ) : (
              <HealthCampDoctor healthCampDoctorLoader={healthCampStaffListLoader} />
            )}
          </Box>
        ) : (
          <TabContext value={String(selected)}>
            <TabPanel value="1">
              <HealthCampStaffList healthCampStaffListLoader={healthCampStaffListLoader} />
            </TabPanel>
            <TabPanel value="2">
              <HealthCampDoctor healthCampDoctorLoader={healthCampStaffListLoader} />
            </TabPanel>
          </TabContext>
        )}
      </TabContext>
    </Box>
  );
}
