import axios from "axios";
import { Dispatch } from "react";
import Url from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { AdminTaskListResponse } from "./Model";
import { adminTaskErrorAction, adminTaskLoadingAction, adminTaskSuccessAction } from "./Actions";
import { AdminTaskListActionTypes } from "./ActionTypes";
import { headers } from "../../../component/Utility";

export const getAllTaskListValue = (pageNo: number, taskStatus: number) => {
  return function (dispatch: Dispatch<AdminTaskListActionTypes>) {
    dispatch(adminTaskLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/adminTasks/admin_task_list_by_status?page=${Number(pageNo)}&size=10`,
        { taskStatus: taskStatus },
        { headers: headers },
      )
      .then((res) => {
        dispatch(adminTaskLoadingAction(false));
        dispatch(adminTaskSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(adminTaskLoadingAction(false));
        dispatch(
          adminTaskErrorAction(
            {} as AdminTaskListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};
