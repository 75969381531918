import {
  EditCaseActionTypes,
  EDIT_CASE_FAIL,
  EDIT_CASE_LOADING,
  EDIT_CASE_SUCCESS,
  EditCaseState,
  EDIT_CASE_API_MSG,
  EDIT_CASE_API_RES,
} from "./ActionTypes";
import { EditCaseResponse } from "./Model";

const initialStateGetPosts: EditCaseState = {
  loading: false,
  editCaseRes: {} as EditCaseResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const EditCaseReducer = (
  state = initialStateGetPosts,
  action: EditCaseActionTypes
): EditCaseState => {
  switch (action.type) {
    case EDIT_CASE_LOADING:
      return {
        ...state,
        loading: true,
        editCaseRes: {} as EditCaseResponse,
      };
    case EDIT_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        editCaseRes: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case EDIT_CASE_FAIL:
      return {
        ...state,
        loading: false,
        editCaseRes: {} as EditCaseResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_CASE_API_MSG:
      return {
        ...state,
        loading: false,
        editCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_CASE_API_RES:
      return {
        ...state,
        loading: false,
        editCaseRes: {} as EditCaseResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
