import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import AdminLoader from "../../AdminLoader";
import {
  clearOrganizationListAPIRes,
  getOrganizationListValue,
} from "../../../redux/OrganizationList/API";
import {
  clearSetPreferenceAPIRes,
  setPreference,
  setPreferenceAPIResMsg,
} from "../../../redux/Preference/SetPreference/API";
import { SetPreferenceBody } from "../../../redux/Preference/SetPreference/Model";
import { GetSessionListBody, HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import {
  clearSessionListByHealthCampIdAPIRes,
  getSessionListByHealthCampId,
} from "redux/HealthcampSessionList/API";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import {
  clearInstanceListByHealthCampIdAPIRes,
  getInstanceListByHealthCampId,
} from "redux/InstanceListOfHealthcamp /API";
import { getPreferenceDetails } from "redux/Preference/GetPreference/API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import { PatientOrganizationList } from "redux/OrganizationList/Model";
import { HealthCampList } from "redux/healthcampList/Model";
import { ModifiedHealthCampList, ModifiedInstanceList, ModifiedSessionList } from "./Model";

type Props = {
  setPreferenceDialogEnable?: boolean | any;
  updateDialogState?: boolean | any;
};

export default function SetPreferenceDialog({
  setPreferenceDialogEnable,
  updateDialogState,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(false);
  useEffect(() => {
    setDialogState(setPreferenceDialogEnable);
  }, [setPreferenceDialogEnable]);
  const handleDialogClose = () => {
    setDialogState(false);
    updateDialogState(false);
    dispatch(clearOrganizationListAPIRes());
    dispatch(clearSessionListByHealthCampIdAPIRes());
    dispatch(clearInstanceListByHealthCampIdAPIRes());
  };

  const [organizationList, setOrganizationList] = React.useState([] as PatientOrganizationList[]);
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<PatientOrganizationList | null>(null);
  const [healthCampList, setHealthCampList] = React.useState([] as ModifiedHealthCampList[]);
  const [selectedHealthCamp, setSelectedHealthCamp] = React.useState<ModifiedHealthCampList | null>(
    null,
  );
  const changeSelectedOrganization = (value: PatientOrganizationList | null) => {
    setSelectedOrganization(value);
  };
  // Call Organization List Store
  const organizationListRes = useSelector((state: AppState) => state.organizationListValue);
  const allPreferenceValues = useSelector((state: AppState) => state.getPreferenceDetails);
  useEffect(() => {
    if (organizationListRes?.getOrganizationRes?.patientOrganizationList !== undefined) {
      let orgDropdownValue = organizationListRes?.getOrganizationRes?.patientOrganizationList.map(
        (element: PatientOrganizationList) => ({
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
        }),
      );
      setOrganizationList(orgDropdownValue);
    } else setOrganizationList([] as PatientOrganizationList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationListRes]);

  useEffect(() => {
    if (
      allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences !== undefined
    ) {
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.organizationId !== undefined
      ) {
        let organizationObj = {
          displayName:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.organizationName,
          id: allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.organizationId,
        };
        setSelectedOrganization(organizationObj);
      } else setSelectedOrganization(null);
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampId !== undefined
      ) {
        let healthcampObj = {
          label:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampName,
          value:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampId,
        };
        setSelectedHealthCamp(healthcampObj);
      } else setSelectedHealthCamp(null);
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampInstanceId !== undefined
      ) {
        let healthcampInstanceObj = {
          label:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampInstanceName,
          value:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampInstanceId,
        };
        setSelectedInstance(healthcampInstanceObj);
      } else setSelectedInstance(null);
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampSessionId !== null
      ) {
        let healthcampInstanceSessionObj = {
          label:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampSessionName,
          value:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampSessionId,
        };
        setSelectedSession(healthcampInstanceSessionObj);
      } else setSelectedSession(null);
    } else {
      setSelectedHealthCamp(null);
      setSelectedOrganization(null);
      setSelectedInstance(null);
      setSelectedSession(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPreferenceValues]);

  const changeSelectedHealthCamp = (passedVal: any | null) => {
    setSelectedHealthCamp(passedVal);
    if (passedVal !== null) {
      const viewHealthcampStaffListBody = {
        healthcampId: passedVal.value,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
  };
  // Call All HealthCamp List Store
  const adminAllHealthCampListRes = useSelector((state: AppState) => state.healthcampListValue);
  useEffect(() => {
    if (adminAllHealthCampListRes?.healthcampListValue?.HealthCampList !== undefined) {
      let healthCampDropdownValue =
        adminAllHealthCampListRes?.healthcampListValue?.HealthCampList.map(
          (element: HealthCampList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setHealthCampList(healthCampDropdownValue);
    } else setHealthCampList([] as ModifiedHealthCampList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAllHealthCampListRes]);

  const [instanceList, setInstanceList] = React.useState([] as ModifiedInstanceList[]);
  const [selectedInstance, setSelectedInstance] = React.useState<ModifiedInstanceList | null>(null);
  const changeSelectedInstance = (passedVal: ModifiedInstanceList | null) => {
    setSelectedInstance(passedVal);
    if (passedVal !== null) {
      const healthcampSessionListBody = {
        healthcampInstanceId: String(passedVal?.value),
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
  };
  // Call Instance List Store
  const getInstanceListRes = useSelector((state: AppState) => state.instanceListValue);
  useEffect(() => {
    if (getInstanceListRes?.getInstanceList?.healthcampInstanceList !== undefined) {
      let instanceListDropdownValue =
        getInstanceListRes?.getInstanceList?.healthcampInstanceList.map(
          (element: HealthcampInstanceList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setInstanceList(instanceListDropdownValue);
    } else setInstanceList([] as ModifiedInstanceList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstanceListRes]);

  const getSeasonListRes = useSelector((state: AppState) => state.sessionListValues);

  useEffect(() => {
    dispatch(getOrganizationListValue());
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [sessionList, setSessionList] = React.useState([] as ModifiedSessionList[]);
  const [selectedSession, setSelectedSession] = React.useState<ModifiedSessionList | null>(null);
  const changeSelectedSession = (passedVal: ModifiedSessionList | null) => {
    setSelectedSession(passedVal);
  };
  useEffect(() => {
    if (getSeasonListRes?.getSessionList?.healthcampSessionList !== undefined) {
      let healthCampSessionListDropdownValue =
        getSeasonListRes?.getSessionList?.healthcampSessionList.map(
          (element: HealthcampSessionList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setSessionList(healthCampSessionListDropdownValue);
    } else setSessionList([] as ModifiedSessionList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeasonListRes]);

  const OrganizationPatientBody = {
    organizationId:
      selectedOrganization !== null
        ? String(selectedOrganization?.id)
        : allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.organizationId !== undefined
        ? -1
        : null,
    healthCampId:
      selectedHealthCamp !== null
        ? String(selectedHealthCamp?.value)
        : allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.healthcampId
        ? -1
        : null,
    healthcampInstanceId: selectedInstance !== null ? String(selectedInstance?.value) : null,
    healthcampSessionId: selectedSession !== null ? String(selectedSession?.value) : null,
  } as SetPreferenceBody;

  const setPreferenceSubmit = () => {
    dispatch(setPreference(OrganizationPatientBody));
  };

  const [setPreferenceAPIMsgType, setSetPreferenceAPIMsgType] = useState<AlertColor>("success");
  const [setPreferenceAPIMsg, setSetPreferenceAPIMsg] = useState("");
  // Call Set Preference Store
  const setPreferenceValueRes = useSelector((state: AppState) => state.setPreferenceValue);
  useEffect(() => {
    if (setPreferenceValueRes?.successMsg !== "") {
      setSetPreferenceAPIMsgType("success");
      setSetPreferenceAPIMsg(setPreferenceValueRes?.successMsg);
      setDialogState(false);
      updateDialogState(false);
      dispatch(getPreferenceDetails());
      dispatch(clearSetPreferenceAPIRes());
    }
    if (setPreferenceValueRes?.errorMsg !== "") {
      setSetPreferenceAPIMsgType("error");
      setSetPreferenceAPIMsg(setPreferenceValueRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPreferenceValueRes]);

  return (
    <Box>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <Box>{setPreferenceValueRes?.loading ? <AdminLoader /> : null}</Box>
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>Set Preference</Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                fullWidth
                size="small"
                disabled={setPreferenceValueRes?.loading}
                value={selectedOrganization}
                options={organizationList}
                onChange={(_event: any, newValue: any) => {
                  changeSelectedOrganization(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Organization Name" variant="outlined" />
                )}
                getOptionLabel={(option) => `${option.displayName}`}
                isOptionEqualToValue={(option: any, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
                fullWidth
                size="small"
                disabled={setPreferenceValueRes?.loading}
                value={selectedHealthCamp}
                options={healthCampList}
                onChange={(_event: any, newValue: any) => {
                  changeSelectedHealthCamp(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Healthcamp Name" variant="outlined" />
                )}
                getOptionLabel={(option) => `${option.label}`}
                isOptionEqualToValue={(option: any, value) => option.id === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
                fullWidth
                id="controllable-states-demo"
                disabled={setPreferenceValueRes?.loading}
                value={selectedInstance}
                options={instanceList}
                onChange={(_event: any, newValue: any) => {
                  changeSelectedInstance(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Instance name" variant="outlined" />
                )}
                getOptionLabel={(option) => `${option.label}`}
                isOptionEqualToValue={(option: any, value) => option.id === value.value}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
                size="small"
                fullWidth
                disabled={setPreferenceValueRes?.loading}
                value={selectedSession}
                options={sessionList}
                onChange={(_event: any, newValue: any) => {
                  changeSelectedSession(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Session name" variant="outlined" />
                )}
                getOptionLabel={(option) => `${option.label}`}
                isOptionEqualToValue={(option: any, value) => option.id === value.value}
              />
            </Grid>
          </Grid>
          {setPreferenceAPIMsgType === "error" && setPreferenceAPIMsg !== "" ? (
            <Box py={2}>
              <Alert
                severity={setPreferenceAPIMsgType}
                onClose={() => {
                  setSetPreferenceAPIMsg("");
                  dispatch(setPreferenceAPIResMsg());
                }}
              >
                {setPreferenceAPIMsg}
              </Alert>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                autoFocus
                variant="outlined"
                disabled={setPreferenceValueRes?.loading}
                onClick={handleDialogClose}
                sx={{ textTransform: "none" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disabled={
                  setPreferenceValueRes?.loading ||
                  (selectedOrganization === null &&
                    selectedHealthCamp === null &&
                    selectedInstance === null &&
                    selectedSession === null)
                }
                onClick={setPreferenceSubmit}
                sx={{ textTransform: "none" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
