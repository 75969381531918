import { PageCount, AddExistingPatientDetailsBySessionIdDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING = "ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING";
export const ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL = "ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL";
export const ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_DATA = "ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_DATA";
export const ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG = "ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG";
export const ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES = "ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES";

export interface AddExistingPatientDetailsBySessionIdState {
  addExistingPatientDetailsBySessionIdRes: AddExistingPatientDetailsBySessionIdDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientDetailsBySessionIdLoading {
  type: typeof ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface AddExistingPatientDetailsBySessionIdSuccess {
  type: typeof ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS;
  payload: AddExistingPatientDetailsBySessionIdDetails;
  successMsg: string;
}
export interface AddExistingPatientDetailsBySessionIdFail {
  type: typeof ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL;
  payload: AddExistingPatientDetailsBySessionIdDetails;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientDetailsBySessionIdAPIMsg {
  type: typeof ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG;
  payload: AddExistingPatientDetailsBySessionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientDetailsBySessionIdAPIRes {
  type: typeof ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES;
  payload: AddExistingPatientDetailsBySessionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddExistingPatientDetailsBySessionIdDataAction {
  type: typeof ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_DATA;
  payload: AddExistingPatientDetailsBySessionIdDetails;
  pageCount: PageCount;
}

export type AddExistingPatientDetailsBySessionIdActionTypes =
  | AddExistingPatientDetailsBySessionIdDataAction
  | AddExistingPatientDetailsBySessionIdLoading
  | AddExistingPatientDetailsBySessionIdSuccess
  | AddExistingPatientDetailsBySessionIdFail
  | AddExistingPatientDetailsBySessionIdAPIMsg
  | AddExistingPatientDetailsBySessionIdAPIRes;
