import { AddExecutiveSignupRequestRes } from "./Model";
export const ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING = "ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING";
export const ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS = "ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS";
export const ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL = "ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL";
export const ADD_EXECUTIVE_SIGNUP_REQUEST_DATA = "ADD_EXECUTIVE_SIGNUP_REQUEST_DATA";
export const ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG = "ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG";
export const ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES = "ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES";

export interface AddProviderOrgAdministratorState {
  addExecutiveSignupRequestRes: AddExecutiveSignupRequestRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddProviderOrgAdministratorLoading {
  type: typeof ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING;
  loading: boolean;
}
export interface AddProviderOrgAdministratorSuccess {
  type: typeof ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS;
  payload: AddExecutiveSignupRequestRes;
  successMsg: string;
}
export interface AddProviderOrgAdministratorFail {
  type: typeof ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL;
  payload: AddExecutiveSignupRequestRes;
  errorMsg: string;
  status: number;
}
export interface AddProviderOrgAdministratorAPIMsg {
  type: typeof ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG;
  payload: AddExecutiveSignupRequestRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddProviderOrgAdministratorAPIRes {
  type: typeof ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES;
  payload: AddExecutiveSignupRequestRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddExecutiveSignupRequestActionTypes =
  | AddProviderOrgAdministratorLoading
  | AddProviderOrgAdministratorSuccess
  | AddProviderOrgAdministratorFail
  | AddProviderOrgAdministratorAPIMsg
  | AddProviderOrgAdministratorAPIRes;
