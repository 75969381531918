import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import { Alert, Box, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import { getPatientListValue } from "../../../redux/effects/ApiCall";
import MWTextField from "../../../component/MWTextField";
import { addDrOnBoarding } from "../../../redux/DoctorOnboarding/ApiCall";
const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  addDoctorDialogEnable?: boolean | any;
  addDoctorDialogUpdateState?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((_theme: Theme) => ({
  root: {
    margin: 0,
    padding: "8px",
  },
}))(MuiDialogActions);

export default function AddDoctorDialog({
  addDoctorDialogEnable,
  addDoctorDialogUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const closeSummaryBanner = () => {
    setAddHealthCampPatientSuccess("");
    setDialogState(false);
  };
  const [addHealthCampPatientSuccess, setAddHealthCampPatientSuccess] = React.useState("");
  const addHealthCampPatientSuccessDiv = addHealthCampPatientSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{addHealthCampPatientSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addDoctorDialogUpdateState(false);
  };
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [page, setPage] = useState(1);
  console.log(setPage);
  useEffect(() => {
    dispatch(getPatientListValue(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.patientValue);
  const [patientList, setPatientList] = useState([] as any[]);
  console.log("patientList", patientList);

  useEffect(() => {
    if (AdminPatientList.patientValue !== undefined) {
      let dataPointArr = AdminPatientList.patientValue.map((element: any) => ({
        value: element.id,
        label: element.firstname + " " + element.lastname,
      }));
      setPatientList(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  // useEffect(() => {
  //   if (addPatientValueRes?.addPatientRes?.message !== undefined) {
  //     //dispatch(getPatientListByHealthcamp(selectedItem, 1));
  //     setAddHealthCampPatientSuccess(
  //       addPatientValueRes?.addPatientRes?.message
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addPatientValueRes]);

  const [emailError, setEmailError] = useState("");
  const emailErrordiv = emailError ? <Typography variant="body1">{emailError}</Typography> : "";

  const [firstNameError, setFirstNameError] = useState("");
  const firstNameErrordiv = firstNameError ? (
    <Typography variant="body1">{firstNameError}</Typography>
  ) : (
    ""
  );
  const [lastNameError, setLastNameError] = useState("");
  const lastNameErrordiv = lastNameError ? (
    <Typography variant="body1">{lastNameError}</Typography>
  ) : (
    ""
  );
  // Add Doctor Body
  const addDoctorBody = {
    email: email.toLowerCase(),
    first_name: firstName,
    last_name: lastName,
  };

  // Add patient eam API
  const addUser = () => {
    if (email === "" && firstName === "" && lastName === "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("Please enter first name");
      setLastNameError("Please enter last name");
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase()) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setEmailError("Please enter valid email id or phone number");
    } else if (email === "" && firstName === "" && lastName !== "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("Please enter first name");
      setLastNameError("");
    } else if (email === "" && firstName !== "" && lastName === "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("");
      setLastNameError("Please enter last name");
    } else if (email === "" && firstName !== "" && lastName !== "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("");
      setLastNameError("");
    } else if (email !== "" && firstName === "" && lastName === "") {
      setEmailError("");
      setFirstNameError("Please enter first name");
      setLastNameError("Please enter last name");
    } else if (email !== "" && firstName === "" && lastName !== "") {
      setEmailError("");
      setFirstNameError("Please enter first name");
      setLastNameError("");
    } else if (email !== "" && firstName !== "" && lastName === "") {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("Please enter last name");
    } else {
      dispatch(addDrOnBoarding(addDoctorBody));
    }
  };

  React.useEffect(() => {
    setDialogState(addDoctorDialogEnable);
  }, [addDoctorDialogEnable]);

  return (
    <div>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h5">Add Doctor</Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <MWTextField
                label="Email / Phone Number"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                placeholder="Please email or phone number"
                id="emailPhoneID"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{emailErrordiv}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MWTextField
                label="First Name"
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                }}
                placeholder="Please First Name"
                id="firstNameId"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{firstNameErrordiv}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MWTextField
                label="Last Name"
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                }}
                placeholder="Please Last Name"
                id="lastNameId"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{lastNameErrordiv}</Typography>
            </Grid>
          </Grid>
          <Box pt={2}>{addHealthCampPatientSuccessDiv}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={addUser}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
