import {
  InstanceListByPatientIdActionTypes,
  INSTANCE_LIST_BY_PATIENT_ID_FAIL,
  INSTANCE_LIST_BY_PATIENT_ID_LOADING,
  INSTANCE_LIST_BY_PATIENT_ID_SUCCESS,
  InstanceListByPatientIdDetailsState,
  INSTANCE_LIST_BY_PATIENT_ID_API_MSG,
  INSTANCE_LIST_BY_PATIENT_ID_API_RES,
} from "./ActionTypes";
import { InstanceListByPatientIdDetails } from "./Model";

const initialStateGetPosts: InstanceListByPatientIdDetailsState = {
  loading: false,
  instanceListByPatientIdRes: {} as InstanceListByPatientIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const instanceListByPatientIdReducer = (
  state = initialStateGetPosts,
  action: InstanceListByPatientIdActionTypes,
): InstanceListByPatientIdDetailsState => {
  switch (action.type) {
    case INSTANCE_LIST_BY_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: true,
        instanceListByPatientIdRes: {} as InstanceListByPatientIdDetails,
      };
    case INSTANCE_LIST_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        instanceListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case INSTANCE_LIST_BY_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        instanceListByPatientIdRes: {} as InstanceListByPatientIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case INSTANCE_LIST_BY_PATIENT_ID_API_MSG:
      return {
        ...state,
        loading: false,
        instanceListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case INSTANCE_LIST_BY_PATIENT_ID_API_RES:
      return {
        ...state,
        loading: false,
        instanceListByPatientIdRes: {} as InstanceListByPatientIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
