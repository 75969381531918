import {
  GetViewCompetitionStaffListActionTypes,
  VIEW_COMPETITION_STAFF_LIST_FAIL,
  VIEW_COMPETITION_STAFF_LIST_LOADING,
  VIEW_COMPETITION_STAFF_LIST_SUCCESS,
  ViewCompetitionStaffListState,
  VIEW_COMPETITION_STAFF_LIST_API_MSG,
  VIEW_COMPETITION_STAFF_LIST_API_RES,
} from "./ActionTypes";
import { GetViewCompetitionStaffListDetails } from "./Model";

const initialStateGetPosts: ViewCompetitionStaffListState = {
  loading: false,
  viewCompetitionStaffListRes: {} as GetViewCompetitionStaffListDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getViewCompetitionStaffListReducer = (
  state = initialStateGetPosts,
  action: GetViewCompetitionStaffListActionTypes,
): ViewCompetitionStaffListState => {
  switch (action.type) {
    case VIEW_COMPETITION_STAFF_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case VIEW_COMPETITION_STAFF_LIST_SUCCESS:
      return {
        ...state,
        viewCompetitionStaffListRes: action.payload,
        successMsg: action.successMsg,
      };
    case VIEW_COMPETITION_STAFF_LIST_FAIL:
      return {
        ...state,
        viewCompetitionStaffListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case VIEW_COMPETITION_STAFF_LIST_API_MSG:
      return {
        ...state,
        viewCompetitionStaffListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case VIEW_COMPETITION_STAFF_LIST_API_RES:
      return {
        ...state,
        viewCompetitionStaffListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
