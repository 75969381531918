import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import { getJudgingDetailsList } from "redux/JudgingDetailsList/API";
import { GetJudgingDetailsListBody, JudgingDetailsList } from "redux/JudgingDetailsList/Model";
import AddSecondRoundDialog from "./AddSecondRoundDialog";
import MWSnackbar from "component/MWSnackbar";
import { clearAddSecondRoundAPIRes } from "redux/AddSecondRound/API";
import PreviewCutoffMarksDialog from "./PreviewCutoffMarksDialog";

type Props = {
  judgementViewLoader?: boolean | any;
};

export default function JudgementView({ judgementViewLoader }: Props) {
  /*  create a piece of state (toSignin)
      with a method to update that state (setToHome)
      and give it a default value of false */
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const [showSecondRoundDialog, setShowSecondRoundDialog] = React.useState(false);

  const [showPreviewCuttOffDialog, setShowPreviewCuttOffDialog] = React.useState(false);

  const updateAddSecondRoundDialogValFromChild = (passedVal: boolean) => {
    setSelectedItems([] as any[]);
    setShowSecondRoundDialog(passedVal);
  };

  const updatePreviewCutoffmarksDialogValFromChild = (passedVal: boolean) => {
    setShowPreviewCuttOffDialog(passedVal);
  };
  // call Store
  const getJuryListValues = useSelector((state: AppState) => state.getJudgingListValue);
  const juryListValues = getJuryListValues?.getJudgingDetailsListRes?.JudgingDetailsList;
  const addSecondRoundResValues = useSelector(
    (state: AppState) => state.addSecondRoundDetailsValue,
  );
  
  const addNextRoundResValues = useSelector(
    (state: AppState) => state.addCutoffMarksValue,
  );
  const [selectedItems, setSelectedItems] = React.useState([] as any[]);
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [competitionMapId, setCompetitionMapId] = useState("");
  const [linkImageToCompetitionMsg, setLinkImageToCompetitionMsg] = useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addSecondRoundResValues?.addSecondRoundRes?.message !== undefined) {
      setLinkImageToCompetitionMsgType("success");
      setLinkImageToCompetitionMsg(addSecondRoundResValues?.addSecondRoundRes?.message);
    }
    if (addSecondRoundResValues?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setLinkImageToCompetitionMsg(addSecondRoundResValues?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSecondRoundResValues]);

  useEffect(() => {
    if (addNextRoundResValues?.previewCutoffMarksRes?.message !== undefined) {
      setLinkImageToCompetitionMsgType("success");
      setLinkImageToCompetitionMsg(addNextRoundResValues?.previewCutoffMarksRes?.message);
    }
    if (addNextRoundResValues?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setLinkImageToCompetitionMsg(addNextRoundResValues?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNextRoundResValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const patientListBody = {
      competitionId: competitionid,
      patientObservationKey: "photograding",
    } as GetJudgingDetailsListBody;
    dispatch(getJudgingDetailsList(patientListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getJuryListValues?.getJudgingDetailsListRes?.numberOfPages !== undefined
      ? setPageListCount(String(getJuryListValues?.getJudgingDetailsListRes?.numberOfPages))
      : setPageListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJuryListValues]);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (juryListValues !== undefined) {
      const patientListBody = {
        competitionId: competitionid,
        patientObservationKey: "photograding",
      } as GetJudgingDetailsListBody;
      dispatch(getJudgingDetailsList(patientListBody, value));
    }
  };

  useEffect(() => {
    if (juryListValues !== undefined) {
      let dataPointArr = juryListValues.map((element: JudgingDetailsList) => ({
        id: element.PatientDetailId,
        patientId: element.PatientId,
        Jury1Rating: element.Jury1Rating,
        Jury2Rating: element.Jury2Rating,
        Jury3Rating: element.Jury3Rating,
        TotalRating: element.TotalRating,
        patientDetailCompetitionMappingId: element.patientDetailCompetitionMappingId,
        ranking: element.ranking,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJuryListValues]);

  const addNextRound = (patientDetailCompetitionMappingId: number) => {
    setCompetitionMapId(String(patientDetailCompetitionMappingId));
    setShowSecondRoundDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Patient Details Id",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Link to={`/judgingview/${params.value}`}>{params.value}</Link>,
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 150,
    },
    { field: "Jury1Rating", headerName: "Jury One", minWidth: 100 },
    { field: "Jury2Rating", headerName: "Jury Two", minWidth: 100 },
    { field: "Jury3Rating", headerName: "Jury Three", minWidth: 100 },
    { field: "TotalRating", headerName: "Total Rating", minWidth: 100 },
    { field: "ranking", headerName: "Ranking", minWidth: 100 },
    {
      field: "patientDetailCompetitionMappingId",
      headerName: "Action",
      minWidth: 180,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={params.row.ranking !== null ? true : false}
          onClick={() => {
            addNextRound(params.row.patientDetailCompetitionMappingId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add to Next Round
          </span>
        </Button>
      ),
    },
  ];

  const addTo2ndRound = () => {
    setShowSecondRoundDialog(true);
  };

  const previewCutoffMarks = () => {
    setShowPreviewCuttOffDialog(true);
  };

  const JuryListOfCompetition = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                variant="outlined"
                disabled={selectedItems.length === 0 ? true : false}
                onClick={() => addTo2ndRound()}
                sx={{ textTransform: "none", mr: 1 }}
              >
                Add Selected to Next Round
              </Button>
              <Button
                variant="outlined"
                onClick={() => previewCutoffMarks()}
                sx={{ textTransform: "none" }}
              >
                Preview Based On Cutoff Marks
              </Button>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
                pt={2}
              >
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                <Pagination
                  color="primary"
                  count={Number(pageListCount) || 0}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {juryListValues && juryListValues.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              checkboxSelection
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = dataGridArray.filter((row: any) => selectedIDs.has(row.id));
                const mi = selectedRows.map((item: any) => ({
                  patientDetailsId: item.id,
                  patientDetailCompetitionMappingId: item.patientDetailCompetitionMappingId,
                }));
                setSelectedItems(mi);
              }}
              isRowSelectable={(params: GridRowParams) =>
                params.row.ranking === null ? true : false
              }
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddSecondRoundAPIRes());
      setLinkImageToCompetitionMsg("");
    }
  };

  return (
    <Box>
      {getJuryListValues.loading === true ? judgementViewLoader(true) : judgementViewLoader(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {JuryListOfCompetition}
          {showSecondRoundDialog ? (
            <AddSecondRoundDialog
              addPatientTeamDialogEnable={showSecondRoundDialog}
              addPatientTeamDialogUpdateState={updateAddSecondRoundDialogValFromChild}
              competitionMapId={competitionMapId}
              competitionid={competitionid}
              secondRoundValue={selectedItems}
            />
          ) : null}
          {showPreviewCuttOffDialog ? (
            <PreviewCutoffMarksDialog
              previewCutoffMarksDialogEnable={showPreviewCuttOffDialog}
              previewCutoffMarksDialogUpdateState={updatePreviewCutoffmarksDialogValFromChild}
              competitionid={competitionid}
            />
          ) : null}
          {linkImageToCompetitionMsg !== "" ? (
            <MWSnackbar
              msg={linkImageToCompetitionMsg}
              type={linkImageToCompetitionMsgType}
              alertClose={addImgtoComp}
            />
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
