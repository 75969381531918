import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetHealthcampAdminListActionTypes } from "./ActionTypes";
import { GetHealthcampAdminListBody, GetHealthcampAdminListRes } from "./Model";
import {
  getHealthcampAdminListAPIResClearAction,
  getHealthcampAdminListErrorAction,
  getHealthcampAdminListLoadingAction,
  getHealthcampAdminListSuccessAction,
  getHealthcampAdminListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetHealthcampAdminListRes;
export const getHealthcampAdminListByHealthCampId = (payload: GetHealthcampAdminListBody, pageNo: any) => {
  return function (dispatch: Dispatch<GetHealthcampAdminListActionTypes>) {
    dispatch(getHealthcampAdminListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampstaff/staff_list_healthcamp?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getHealthcampAdminListLoadingAction(false));
        dispatch(
          getHealthcampAdminListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetHealthcampAdminListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Admin list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getHealthcampAdminListLoadingAction(false));
        dispatch(
          getHealthcampAdminListErrorAction(
            {} as GetHealthcampAdminListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetHealthcampAdminListByHealthCampIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetHealthcampAdminListActionTypes>) {
    dispatch(
      getHealthcampAdminListUpdateAPIMsgAction(apiRes as GetHealthcampAdminListRes, "", "", 0),
    );
  };
};

export const clearGetHealthcampAdminListByHealthCampIdAPIRes = () => {
  return function (dispatch: Dispatch<GetHealthcampAdminListActionTypes>) {
    dispatch(getHealthcampAdminListAPIResClearAction({} as GetHealthcampAdminListRes, "", "", 0));
  };
};
