import {
  AcceportRejectBookingTypes,
  ACCEPT_OR_REJECT_BOOKING_FAIL,
  ACCEPT_OR_REJECT_BOOKING_LOADING,
  ACCEPT_OR_REJECT_BOOKING_SUCCESS,
  GetAcceportRejectBookingStateType,
} from "./ActionTypes";

const initialStateGetAcceptorRejectBooking: GetAcceportRejectBookingStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const acceptorRejectBookingReducer = (
  state = initialStateGetAcceptorRejectBooking,
  action: AcceportRejectBookingTypes,
): GetAcceportRejectBookingStateType => {
  switch (action.type) {
    case ACCEPT_OR_REJECT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ACCEPT_OR_REJECT_BOOKING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ACCEPT_OR_REJECT_BOOKING_FAIL:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false,
      };
    default:
      return state;
  }
};
