import { DoctorQuriesListResponse } from "./Model";

export const DOCTOR_QURIES_LIST_LOADING = "DOCTOR_QURIES_LIST_LOADING";
export const DOCTOR_QURIES_LIST_SUCCESS = "DOCTOR_QURIES_LIST_SUCCESS";
export const DOCTOR_QURIES_LIST_FAIL = "DOCTOR_QURIES_LIST_FAIL";
export const DOCTOR_QURIES_LIST_API_MSG = "DOCTOR_QURIES_LIST_API_MSG";
export const DOCTOR_QURIES_LIST_CLEAR_API_RES = "DOCTOR_QURIES_LIST_CLEAR_API_RES";

export interface DoctorQuriesListDataState {
  doctorQueriesRes: DoctorQuriesListResponse;
  loading: boolean;
  message: string;
  status: number;
}

export interface DoctorQuriesListLoading {
  type: typeof DOCTOR_QURIES_LIST_LOADING;
  loading: boolean;
}
export interface DoctorQuriesListSuccess {
  type: typeof DOCTOR_QURIES_LIST_SUCCESS;
  payload: DoctorQuriesListResponse;
}
export interface DoctorQuriesListFail {
  type: typeof DOCTOR_QURIES_LIST_FAIL;
  payload: DoctorQuriesListResponse;
  message: string;
  status: number;
}
export interface DoctorQuriesAPIMsg {
  type: typeof DOCTOR_QURIES_LIST_API_MSG;
  payload: DoctorQuriesListResponse;
  message: string;
  status: number;
}
export interface DoctorQuriesAPIRes {
  type: typeof DOCTOR_QURIES_LIST_CLEAR_API_RES;
  payload: DoctorQuriesListResponse;
  message: string;
  status: number;
}

export type DoctorQuriesListActionTypes =
  | DoctorQuriesListLoading
  | DoctorQuriesListSuccess
  | DoctorQuriesListFail
  | DoctorQuriesAPIMsg
  | DoctorQuriesAPIRes;
