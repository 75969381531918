import {
  AddRankForFinalRoundDetailsActionTypes,
  ADD_RANK_FOR_FINAL_ROUND_FAIL,
  ADD_RANK_FOR_FINAL_ROUND_LOADING,
  ADD_RANK_FOR_FINAL_ROUND_SUCCESS,
  AddRankForFinalRoundState,
  ADD_RANK_FOR_FINAL_ROUND_API_MSG,
  ADD_RANK_FOR_FINAL_ROUND_API_RES,
} from "./ActionTypes";
import { AddRankForFinalRoundDetailsRes } from "./Model";

const initialStateUpdatePosts: AddRankForFinalRoundState = {
  loading: false,
  addRankForFinalRoundRes: {} as AddRankForFinalRoundDetailsRes,
  errorMsg: "",
  status: 0,
};
export const addRankForFinalRoundReducer = (
  state = initialStateUpdatePosts,
  action: AddRankForFinalRoundDetailsActionTypes,
): AddRankForFinalRoundState => {
  switch (action.type) {
    case ADD_RANK_FOR_FINAL_ROUND_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_RANK_FOR_FINAL_ROUND_SUCCESS:
      return {
        ...state,
        addRankForFinalRoundRes: action.payload,
      };
    case ADD_RANK_FOR_FINAL_ROUND_FAIL:
      return {
        ...state,
        addRankForFinalRoundRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_RANK_FOR_FINAL_ROUND_API_MSG:
      return {
        ...state,
        addRankForFinalRoundRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_RANK_FOR_FINAL_ROUND_API_RES:
      return {
        ...state,
        addRankForFinalRoundRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
