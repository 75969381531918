import {
  HCPATIENT_LIST_FAIL,
  HCPATIENT_LIST_LOADING,
  HCPATIENT_LIST_SUCCESS,
  HCPATIENT_LIST_UPDATE_API_MSG,
  HCPATIENT_LIST_UPDATE_API_RES,
  HealthcampPatientGetActionTypes,
} from "./ActionTypes";
import { HealthcampPatientList } from "./Model";

export const getPatientListByHealthcampLoadingAction = (
  loading: boolean,
): HealthcampPatientGetActionTypes => {
  return {
    type: HCPATIENT_LIST_LOADING,
    loading: loading,
  };
};

export const getPatientListByHealthcampListSuccessAction = (
  getPatientListByHealthcampListResponse: HealthcampPatientList,
  successMsg: string,
): HealthcampPatientGetActionTypes => {
  return {
    type: HCPATIENT_LIST_SUCCESS,
    payload: getPatientListByHealthcampListResponse,
    successMsg: successMsg,
  };
};

export const getPatientListByHealthcampListErrorAction = (
  getPatientListByHealthcampListResponse: HealthcampPatientList,
  errMsg: string,
  status: number,
): HealthcampPatientGetActionTypes => {
  return {
    type: HCPATIENT_LIST_FAIL,
    payload: getPatientListByHealthcampListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientListByHealthcampListUpdateAPIMsgAction = (
  getPatientListByHealthcampListResponse: HealthcampPatientList,
  successMsg: string,
  errMsg: string,
  status: number,
): HealthcampPatientGetActionTypes => {
  return {
    type: HCPATIENT_LIST_UPDATE_API_MSG,
    payload: getPatientListByHealthcampListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientListByHealthcampAPIResClearAction = (
  getPatientListByHealthcampListResponse: HealthcampPatientList,
  successMsg: string,
  errMsg: string,
  status: number,
): HealthcampPatientGetActionTypes => {
  return {
    type: HCPATIENT_LIST_UPDATE_API_RES,
    payload: getPatientListByHealthcampListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
