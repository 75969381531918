import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetUnprocessedPatientDetailsListActionTypes } from "./ActionTypes";
import { GetUnprocessedPatientDetailsListRes } from "./Model";
import {
  getUnprocessedPatientDetailsListAPIResClearAction,
  getUnprocessedPatientDetailsListErrorAction,
  getUnprocessedPatientDetailsListLoadingAction,
  getUnprocessedPatientDetailsListSuccessAction,
  getUnprocessedPatientDetailsListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetUnprocessedPatientDetailsListRes;
export const getUnprocessedPatientDetailsList = (pageNo: any) => {
  return function (dispatch: Dispatch<GetUnprocessedPatientDetailsListActionTypes>) {
    dispatch(getUnprocessedPatientDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/adminapp/api/patientDetailsFailedProcess/get_patientDetailsList_failed_processing_from_dashboard?page=${Number(
            pageNo,
          )}&size=50`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getUnprocessedPatientDetailsListLoadingAction(false));
        dispatch(getUnprocessedPatientDetailsListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getUnprocessedPatientDetailsListLoadingAction(false));
        dispatch(
          getUnprocessedPatientDetailsListErrorAction(
            {} as GetUnprocessedPatientDetailsListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getUnprocessedPatientDetailsListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetUnprocessedPatientDetailsListActionTypes>) {
    dispatch(getUnprocessedPatientDetailsListAPIMsgAction(apiRes as GetUnprocessedPatientDetailsListRes, "", 0));
  };
};

export const clearUnprocessedPatientDetailsListAPIRes = () => {
  return function (dispatch: Dispatch<GetUnprocessedPatientDetailsListActionTypes>) {
    dispatch(getUnprocessedPatientDetailsListAPIResClearAction({} as GetUnprocessedPatientDetailsListRes, "", 0));
  };
};
