import {
  CompetitionListByDuplicatePatientIdActionTypes,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
  CompetitionListByDuplicatePatientIdDetailsState,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
} from "./ActionTypes";
import { CompetitionListByDuplicatePatientIdDetails } from "./Model";

const initialStateGetPosts: CompetitionListByDuplicatePatientIdDetailsState = {
  loading: false,
  competitionListByDuplicatePatientIdRes: {} as CompetitionListByDuplicatePatientIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const competitionListByDuplicatePatientIdReducer = (
  state = initialStateGetPosts,
  action: CompetitionListByDuplicatePatientIdActionTypes,
): CompetitionListByDuplicatePatientIdDetailsState => {
  switch (action.type) {
    case COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: true,
        competitionListByDuplicatePatientIdRes: {} as CompetitionListByDuplicatePatientIdDetails,
      };
    case COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        competitionListByDuplicatePatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        competitionListByDuplicatePatientIdRes: {} as CompetitionListByDuplicatePatientIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG:
      return {
        ...state,
        loading: false,
        competitionListByDuplicatePatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES:
      return {
        ...state,
        loading: false,
        competitionListByDuplicatePatientIdRes: {} as CompetitionListByDuplicatePatientIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
