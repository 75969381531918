import { OwnerDetails, PatientTeam } from "./Model";

// Case Status
export const PATIENT_TEAM_LOADING = "PATIENT_TEAM_LOADING";
export const PATIENT_TEAM_SUCCESS = "PATIENT_TEAM_SUCCESS";
export const PATIENT_TEAM_FAIL = "PATIENT_TEAM_FAIL";
export const PATIENT_TEAM_TYPE = "PATIENT_TEAM_TYPE";

export interface PatientTeamStateType {
  loading: boolean;
  ownerDetails: OwnerDetails;
  patientTeam: PatientTeam[];
  patientCasePhone: string;
  message: string;
  status: number;
}
export interface PatientTeamLoading {
  type: typeof PATIENT_TEAM_LOADING;
}
export interface PatientTeamSuccess {
  type: typeof PATIENT_TEAM_SUCCESS;
  ownerDetails: OwnerDetails;
  patientTeam: PatientTeam[];
  patientCasePhone: string;
  message: string;
  status: number;
}
export interface PatientTeamFail {
  type: typeof PATIENT_TEAM_FAIL;
  message: string;
  status: number;
}

interface PatientTeamType {
  type: typeof PATIENT_TEAM_TYPE;
  ownerDetails: OwnerDetails;
  patientTeam: PatientTeam[];
  patientCasePhone: string;
  message: string;
  status: number;
}

export type PatientTeamTypes =
  | PatientTeamType
  | PatientTeamLoading
  | PatientTeamSuccess
  | PatientTeamFail;
// Case Status
export const ADD_PATIENT_TEAM_LOADING = "ADD_PATIENT_TEAM_LOADING";
export const ADD_PATIENT_TEAM_SUCCESS = "ADD_PATIENT_TEAM_SUCCESS";
export const ADD_PATIENT_TEAM_FAIL = "ADD_PATIENT_TEAM_FAIL";
export const ADD_PATIENT_TEAM_TYPE = "ADD_PATIENT_TEAM_TYPE";

export interface AddPatientTeamStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface AddPatientTeamLoading {
  type: typeof ADD_PATIENT_TEAM_LOADING;
}
export interface AddPatientTeamSuccess {
  type: typeof ADD_PATIENT_TEAM_SUCCESS;
  message: string;
  status: number;
}
export interface AddPatientTeamFail {
  type: typeof ADD_PATIENT_TEAM_FAIL;
  message: string;
  status: number;
}

interface AddPatientTeamType {
  type: typeof ADD_PATIENT_TEAM_TYPE;
  message: string;
  status: number;
}

export type AddPatientTeamTypes =
  | AddPatientTeamType
  | AddPatientTeamLoading
  | AddPatientTeamSuccess
  | AddPatientTeamFail;
