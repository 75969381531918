import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import { getHealthcampListValue } from "../../redux/healthcampList/API";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
  AlertColor,
} from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { HealthCampList } from "../../redux/healthcampList/Model";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearHealthCampEditAPIRes,
  editHealthcampName,
} from "redux/healthcampList/EditHealthcampName/API";
import { EditHealthcampNameBody } from "redux/healthcampList/EditHealthcampName/Model";
import MWSnackbar from "component/MWSnackbar";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
export default function HealthcampList() {
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const dispatch = useDispatch();
  const [listCount, setListCount] = React.useState("");
  const [healthcampDisplayName, setHealthcampDisplayName] = React.useState("");
  const handleHealthcareNameChange = React.useCallback((newValue: any) => {
    setHealthcampDisplayName(newValue);
  }, []);
  const [errorCampOwnerId, setErrorCampOwnerId] = React.useState("");
  const [edithealthcampNameMsg, setEdithealthcampNameMsg] = React.useState<AlertColor>("success");
  const [edithealthcampNameAPIMsg, setEdithealthcampNameAPIMsg] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);
  const [campOwnerProfileId, setCampOwnerProfileId] = React.useState("");
  const [selectedCampID, setSelectedCampID] = React.useState("");

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoHealthcampDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    { field: "displayName", headerName: "Health Camp Name", minWidth: 250, flex: 1 },
    { field: "dateCreated", headerName: "Create Date", minWidth: 170 },
    { field: "ownerFirstName", headerName: "Owner First Name", minWidth: 250 },
    { field: "ownerUserName", headerName: "Owner User Name", minWidth: 200, flex: 1 },
    {
      field: "healthcampEditId",
      headerName: "Edit",
      align: "center",
      valueGetter: ({ id }) => {
        const item = dataGridArray.find((item) => item.id === id);
        return item.id;
      },
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          onClick={() => {
            editHealtcampName(params.value);
            setHealthcampDisplayName(params.row.displayName);
            setCampOwnerProfileId(params.row.ownerProfileId);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          Edit
        </Button>
      ),
      minWidth: 90,
    },
  ];

  const handleDialogClose = () => {
    setDialogState(false);
    setHealthcampDisplayName("");
    setCampOwnerProfileId("");
    setErrorCampOwnerId("");
    dispatch(clearHealthCampEditAPIRes());
  };

  const editHealthcampNameSubmit = () => {
    if (
      //eslint-disable-next-line
      new RegExp(/^(0|[1-9][0-9]*)$/).test(campOwnerProfileId.toString()) === false
    ) {
      setErrorCampOwnerId("Please enter valid Camp OwnerId");
    } else {
      if (selectedCampID !== "") {
        setErrorCampOwnerId("");
        let editHealthcampBody = {
          healthcampId: selectedCampID,
          displayName: healthcampDisplayName,
          ownerId: campOwnerProfileId,
        } as EditHealthcampNameBody;
        dispatch(editHealthcampName(editHealthcampBody));
      }
    }
  };

  const history = useHistory();
  const gotoHealthcampDetails = (rowIndex: any) => {
    history.push(`/patientlistbyhealthcamp/${rowIndex}`);
  };
  const editHealtcampName = (rowIndex: any) => {
    setDialogState(true);
    setSelectedCampID(rowIndex);
  };

  const closePatientDetailsTypeToSessionMsg = () => {
    dispatch(clearHealthCampEditAPIRes());
    setEdithealthcampNameAPIMsg("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getHealthcampListValue());
  }, [dispatch]);

  function healthCampListApi() {
    dispatch(getHealthcampListValue());
  }

  // call Store
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const AdminEditHealthcampList = useSelector(
    (state: AppState) => state.editHealthcampNameReducerValue,
  );
  const healthcampValues = AdminAllHealthcampList.healthcampListValue?.HealthCampList;

  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminAllHealthcampList?.loading));
    AdminAllHealthcampList?.healthcampListValue.count !== undefined
      ? setListCount(String(AdminAllHealthcampList?.healthcampListValue.count))
      : setListCount("0");

    if (healthcampValues !== undefined) {
      let dataPointArr = healthcampValues.map((element: HealthCampList) => ({
        id: element.id,
        displayName: element.displayName,
        dateCreated: dayjs(element.dateCreated).format("dddd, MMM DD, YYYY"),
        ownerFirstName: element.ownerFirstName,
        ownerUserName: element.ownerUserName,
        ownerProfileId: element.ownerProfileId,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);

  useEffect(() => {
    if (AdminEditHealthcampList?.successMsg !== "") {
      setEdithealthcampNameMsg("success");
      setEdithealthcampNameAPIMsg(AdminEditHealthcampList?.successMsg);
      setDialogState(false);
      setHealthcampDisplayName("");
      setCampOwnerProfileId("");
      dispatch(getHealthcampListValue());
    }
    if (AdminEditHealthcampList?.errorMsg !== "") {
      setEdithealthcampNameMsg("error");
      setEdithealthcampNameAPIMsg(AdminEditHealthcampList?.errorMsg);
      setDialogState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminEditHealthcampList]);

  const campName = "Health Camp List";
  const DataGridMarkup = (
    <Card>
      <CardContent>
        {healthcampValues && healthcampValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  const EdithealthcampPopup = (
    <Dialog
      onClose={() => handleDialogClose()}
      aria-labelledby="customized-dialog-title"
      open={dialogState}
      disableEscapeKeyDown
      PaperProps={{
        style: {
          minHeight: "20vh",
          minWidth: "40vw",
          overflow: "hidden",
        },
      }}
    >
      {AdminEditHealthcampList?.loading ? <AdminLoader /> : null}
      <DialogTitle id="customized-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Edit Healthcamp Name</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              value={campOwnerProfileId}
              label="Profile ID"
              placeholder="Enter Profile ID"
              id="ProfileID"
              error={errorCampOwnerId === "" ? false : true}
              onChange={(event) => setCampOwnerProfileId(event.target.value)}
              fullWidth
              size="small"
              helperText={errorCampOwnerId === "" ? "" : errorCampOwnerId}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <TextField
              label="Add Healthcamp Name"
              placeholder="Please Enter Healthcamp Name"
              value={healthcampDisplayName}
              onChange={(event) => {
                handleHealthcareNameChange(event.target.value);
              }}
              id="descriptionID"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item></Grid>
          <Grid item>
            <Button
              autoFocus
              onClick={() => editHealthcampNameSubmit()}
              variant="contained"
              color="primary"
              style={{ fontSize: "12px" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                title={campName}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={healthCampListApi}
              />
            </Grid>
          </Grid>
          {DataGridMarkup}
        </Stack>
        {EdithealthcampPopup}
      </Container>
      {edithealthcampNameAPIMsg !== "" ? (
        <MWSnackbar
          msg={edithealthcampNameAPIMsg}
          type={edithealthcampNameMsg}
          alertClose={closePatientDetailsTypeToSessionMsg}
        />
      ) : null}
    </Box>
  );
}
