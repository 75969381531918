import { UpdateDoctorContactDetailsRes } from "./Model";
export const UPDATE_DOCTOR_CONTACT_DETAILS_LOADING =
  "UPDATE_DOCTOR_CONTACT_DETAILS_LOADING";
export const UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS =
  "UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS";
export const UPDATE_DOCTOR_CONTACT_DETAILS_FAIL =
  "UPDATE_DOCTOR_CONTACT_DETAILS_FAIL";
export const UPDATE_DOCTOR_CONTACT_DETAILS_DATA =
  "UPDATE_DOCTOR_CONTACT_DETAILS_DATA";
export const UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG =
  "UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG";
export const UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES =
  "UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES";

export interface UpdateDoctorContactDetailsState {
  updateNoteResRes: UpdateDoctorContactDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorContactDetailsLoading {
  type: typeof UPDATE_DOCTOR_CONTACT_DETAILS_LOADING;
  loading: boolean;
}
export interface UpdateDoctorContactDetailsSuccess {
  type: typeof UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS;
  payload: UpdateDoctorContactDetailsRes;
  successMsg: string;
}
export interface UpdateDoctorContactDetailsFail {
  type: typeof UPDATE_DOCTOR_CONTACT_DETAILS_FAIL;
  payload: UpdateDoctorContactDetailsRes;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorContactDetailsUpdateAPIMsg {
  type: typeof UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG;
  payload: UpdateDoctorContactDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorContactDetailsUpdateAPIRes {
  type: typeof UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES;
  payload: UpdateDoctorContactDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type UpdateDoctorContactDetailsActionTypes =
  | UpdateDoctorContactDetailsLoading
  | UpdateDoctorContactDetailsSuccess
  | UpdateDoctorContactDetailsFail
  | UpdateDoctorContactDetailsUpdateAPIMsg
  | UpdateDoctorContactDetailsUpdateAPIRes;
