import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  getPatientImportConfigListActionTypes,
  GET_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  GET_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
} from "./ActionTypes";
import { PatientImportConfigListDetails, PatientImportConfigListValue } from "./Model";
import { headers } from "../../component/Utility";

export const getPatientInfortConfigListApi = (body: PatientImportConfigListDetails) => {
  return function (dispatch: Dispatch<getPatientImportConfigListActionTypes>) {
    dispatch({
      type: GET_PATIENT_IMPORT_CONFIG_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/patientapp/api/dataimport/get_patient_import_config_list`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PATIENT_IMPORT_CONFIG_LIST_FAIL,
          payload: {} as PatientImportConfigListValue,
          errRes: error.response.data.message,
        });
      });
  };
};
