import { Card, Grid, Typography, Avatar, CardHeader, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { AgeRange, NameGenerate } from "../../../../../../component/Utility";
import { AppState } from "redux/store/Store";
import { useEffect } from "react";
import { getOriginalDemographicsDetailsByPatientId } from "redux/OriginalPatientDemographics/API";
import { OriginalPatientDemographicsDetailsBody } from "redux/OriginalPatientDemographics/Model";

type Props = {
  patientoriginalid?: any;
};

function OriginalDemographicsDetails({ patientoriginalid }: Props) {
  const dispatch = useDispatch();
  const originalPatientDemographicsDetails = useSelector(
    (state: AppState) => state.getOriginalDemographicsValue,
  );

  useEffect(() => {
    if (patientoriginalid) {
      dispatch(
        getOriginalDemographicsDetailsByPatientId({
          healthRecordId: patientoriginalid,
        } as OriginalPatientDemographicsDetailsBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Card style={{ borderRadius: 20 }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Demographics
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>Patient Id : {patientoriginalid}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
              ?.personalDetails?.profilePicture !== null ? (
              <img
                src={
                  originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.profilePicture?.document
                }
                srcSet={
                  originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.profilePicture?.document
                }
                alt=""
                loading="lazy"
                style={{
                  maxWidth: "180px",
                  maxHeight: "180px",
                  flex: 1,
                }}
              />
            ) : (
              <Avatar sx={{ width: 120, height: 120 }} />
            )}
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Image Id:
              {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                ?.personalDetails?.profilePicture !== null
                ? originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.profilePicture?.id
                : " Not Available"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {NameGenerate.changeName(
                    patientoriginalid,
                    originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                      ?.personalDetails?.firstname !== undefined &&
                      originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                        ?.personalDetails?.lastname !== undefined
                      ? originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.firstname! +
                          " " +
                          originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                            ?.personalDetails?.lastname!
                      : "",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Gender:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.gender?.label !== undefined
                    ? " " +
                      originalPatientDemographicsDetails.getOriginalDemographicsByPatientIdRes
                        ?.personalDetails?.gender?.label +
                      " "
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Age:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {originalPatientDemographicsDetails.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.dob !== null ||
                  originalPatientDemographicsDetails.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.dob !== ""
                    ? localStorage.getItem("togglename") === "true"
                      ? AgeRange.ageRangeCheck(
                          dayjs(new Date()).diff(
                            originalPatientDemographicsDetails.getOriginalDemographicsByPatientIdRes
                              ?.personalDetails?.dob,
                            "year",
                          ),
                        )
                      : dayjs(new Date()).diff(
                          originalPatientDemographicsDetails.getOriginalDemographicsByPatientIdRes
                            ?.personalDetails?.dob,
                          "year",
                        ) + " years old "
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Phone:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.phone !== ""
                    ? originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                        ?.personalDetails?.phone
                    : "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>City:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.city !== ""
                    ? originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                        ?.personalDetails?.city
                    : "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Pincode:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.pincode !== null
                    ? originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                        ?.personalDetails?.pincode
                    : "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Identity Card:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.idProof !== null ? (
                    <img
                      src={
                        originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.supportingDocuments?.document
                      }
                      srcSet={
                        originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.supportingDocuments?.document
                      }
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />
                  ) : (
                    "Not Available"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Identity Type:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.idProof !== null ? (
                    <img
                      src={
                        originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idType?.label
                      }
                      srcSet={
                        originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idType?.label
                      }
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />
                  ) : (
                    "Not Available"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Identity Number:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                    ?.personalDetails?.idProof !== null ? (
                    <img
                      src={
                        originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idNumber
                      }
                      srcSet={
                        originalPatientDemographicsDetails?.getOriginalDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idNumber
                      }
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />
                  ) : (
                    "Not Available"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default OriginalDemographicsDetails;
