import {
  GetFilteredHealthCampPatientListActionTypes,
  GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_FAIL,
  GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_LOADING,
  GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_SUCCESS,
  GetFilteredHealthCampPatientListState,
  GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_MSG,
  GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetFIlteredHealthCampPatientListRes } from "./Model";

const initialStateGetPosts: GetFilteredHealthCampPatientListState = {
  loading: false,
  getFilteredHealthCampPatientListRes: {} as GetFIlteredHealthCampPatientListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getFilteredHealthCampPatientListReducer = (
  state = initialStateGetPosts,
  action: GetFilteredHealthCampPatientListActionTypes,
): GetFilteredHealthCampPatientListState => {
  switch (action.type) {
    case GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        getFilteredHealthCampPatientListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_FAIL:
      return {
        ...state,
        getFilteredHealthCampPatientListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getFilteredHealthCampPatientListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_RES:
      return {
        ...state,
        getFilteredHealthCampPatientListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
