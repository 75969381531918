import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { Button, Container, Stack, Box, Grid, Typography, Pagination } from "@mui/material";
import dayjs from "dayjs";
import AdminLoader from "pages/AdminLoader";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { duplicateHealthcampListByPatientIdApi } from "redux/DuplicatePatientDetails/PatientHealthcampListById/API";
import { DuplicateHealthcampListByPatientIdBody, HealthCampList } from "redux/DuplicatePatientDetails/PatientHealthcampListById/Model";

type Props = {
  patientDuplicateId: any;
};

export default function HealthcampListByPatient({ patientDuplicateId }: Props) {
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  // call Store
  const healthcampListByDuplicatePatientId = useSelector(
    (state: AppState) => state.duplicatePatientHealthcampListResult,
  );
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoHealthcampDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    { field: "displayName", headerName: "Health Camp Name", flex: 1 },
    { field: "dateCreated", headerName: "Create Date", minWidth: 120 },
  ];
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      duplicateHealthcampListByPatientIdApi(
        { patientId: patientDuplicateId } as DuplicateHealthcampListByPatientIdBody,
        value,
      ),
    );
  };
  const history = useHistory();
  const gotoHealthcampDetails = (rowIndex: any) => {
    history.push(`/patientlistbyhealthcamp/${rowIndex}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      duplicateHealthcampListByPatientIdApi(
        { patientId: patientDuplicateId } as DuplicateHealthcampListByPatientIdBody,
        page,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (
      healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.HealthCampList !== undefined
    ) {
      let dataPointArr =
      healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.HealthCampList.map(
          (element: HealthCampList) => ({
            id: element.id,
            displayName: element.displayName,
            dateCreated: dayjs(element.dateCreated).format("DD/MM/YYYY"),
          }),
        );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampListByDuplicatePatientId]);
  const DataGridMarkup = (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.HealthCampList &&
        healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.HealthCampList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      {healthcampListByDuplicatePatientId.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {DataGridMarkup}
        </Stack>
      </Container>
    </Box>
  );
}
