import {
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES,
  AddExistingPatientDetailsBySessionIdActionTypes,
} from "./ActionTypes";
import { AddExistingPatientDetailsBySessionIdDetails } from "./Model";

export const addExistingPatientDetailsBySessionIdLoadingAction = (
  loading: boolean,
): AddExistingPatientDetailsBySessionIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const addExistingPatientDetailsBySessionIdSuccessAction = (
  addExistingPatientDetailsBySessionIdResponse: AddExistingPatientDetailsBySessionIdDetails,
  successMsg: string,
): AddExistingPatientDetailsBySessionIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS,
    payload: addExistingPatientDetailsBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const addExistingPatientDetailsBySessionIdErrorAction = (
  addExistingPatientDetailsBySessionIdResponse: AddExistingPatientDetailsBySessionIdDetails,
  errMsg: string,
  status: number,
): AddExistingPatientDetailsBySessionIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL,
    payload: addExistingPatientDetailsBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addExistingPatientDetailsBySessionIdAPIMsgAction = (
  addExistingPatientDetailsBySessionIdResponse: AddExistingPatientDetailsBySessionIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExistingPatientDetailsBySessionIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG,
    payload: addExistingPatientDetailsBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addExistingPatientDetailsBySessionIdAPIResClearAction = (
  addExistingPatientDetailsBySessionIdResponse: AddExistingPatientDetailsBySessionIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExistingPatientDetailsBySessionIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES,
    payload: addExistingPatientDetailsBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
