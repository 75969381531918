import {
  SessionListByDuplicatePatientIdActionTypes,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
  SessionListByDuplicatePatientIdDetailsState,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
} from "./ActionTypes";
import { SessionListByDuplicatePatientIdDetails } from "./Model";

const initialStateGetPosts: SessionListByDuplicatePatientIdDetailsState = {
  loading: false,
  sessionListByDuplicatePatientIdRes: {} as SessionListByDuplicatePatientIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const sessionListByDuplicatePatientIdReducer = (
  state = initialStateGetPosts,
  action: SessionListByDuplicatePatientIdActionTypes,
): SessionListByDuplicatePatientIdDetailsState => {
  switch (action.type) {
    case SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: true,
        sessionListByDuplicatePatientIdRes: {} as SessionListByDuplicatePatientIdDetails,
      };
    case SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionListByDuplicatePatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        sessionListByDuplicatePatientIdRes: {} as SessionListByDuplicatePatientIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG:
      return {
        ...state,
        loading: false,
        sessionListByDuplicatePatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES:
      return {
        ...state,
        loading: false,
        sessionListByDuplicatePatientIdRes: {} as SessionListByDuplicatePatientIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
