import {
  VITALREQUEST_LIST_FAIL,
  VITALREQUEST_LIST_LOADING,
  VITALREQUEST_LIST_SUCCESS,
  VITALREQUEST_LIST_UPDATE_API_MSG,
  VITALREQUEST_LIST_UPDATE_API_RES,
  VitalRequestListActionTypes,
} from "./ActionTypes";
import { VitalRequestResponse } from "./Model";

export const vitalRequestListLoadingAction = (
  loading: boolean,
): VitalRequestListActionTypes => {
  return {
    type: VITALREQUEST_LIST_LOADING,
    loading: loading,
  };
};

export const vitalRequestListSuccessAction = (
  vitalRequestListRes: VitalRequestResponse,
  successMsg: string,
): VitalRequestListActionTypes => {
  return {
    type: VITALREQUEST_LIST_SUCCESS,
    payload: vitalRequestListRes,
    successMsg: successMsg,
  };
};

export const vitalRequestListErrorAction = (
  vitalRequestListRes: VitalRequestResponse,
  errMsg: string,
  status: number,
): VitalRequestListActionTypes => {
  return {
    type: VITALREQUEST_LIST_FAIL,
    payload: vitalRequestListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const vitalRequestListUpdateAPIMsgAction = (
  vitalRequestListRes: VitalRequestResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): VitalRequestListActionTypes => {
  return {
    type: VITALREQUEST_LIST_UPDATE_API_MSG,
    payload: vitalRequestListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const vitalRequestListAPIResClearAction = (
  vitalRequestListRes: VitalRequestResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): VitalRequestListActionTypes => {
  return {
    type: VITALREQUEST_LIST_UPDATE_API_RES,
    payload: vitalRequestListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
