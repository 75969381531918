import { Box, CardContent, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MWExceptionList from "../../../../component/MWExceptionList";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { PatientDetailsList } from "redux/JudgingSecondRoundDetailsList/Model";
import { GetJudgingFinalRoundDetailsListBody } from "redux/FinalRoundDetailsList/Model";
import { getJudgingFinalRoundDetailsList } from "redux/FinalRoundDetailsList/API";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";

type Props = {
  judgementSecondRoundViewLoader?: boolean | any;
};

export default function WinnersView({ judgementSecondRoundViewLoader }: Props) {
  const dispatch = useDispatch();
  const patientPointDataList = useSelector((state: AppState) => state.getFinalRoundvalue);
  const patientDetailsValue =
    patientPointDataList?.getJudgingFinalRoundDetailsListRes?.patientDetailsList;
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const finalRoundListBody = {
      competitionId: competitionid,
    } as GetJudgingFinalRoundDetailsListBody;
    dispatch(getJudgingFinalRoundDetailsList(finalRoundListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      patientPointDataList?.getJudgingFinalRoundDetailsListRes?.patientDetailsList !== undefined
    ) {
      let dataPointArr = patientDetailsValue.map((element: PatientDetailsList) => ({
        id: element.id,
        description: element.description,
        startDate:
          element.startDate !== null ? dayjs(element.startDate).format("ddd, MMM D, YYYY") : "",
        patientDocument: element.patientDocument,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Patient Details Id",
      minWidth: 150,
      renderCell: (params) => <Link to={`/judgingview/${params.value}`}>{params.value}</Link>,
    },
    { field: "description", headerName: "Description", width: 500, flex: 1, },
    { field: "startDate", headerName: "Start Date", width: 200},
  ];

  const WinnerViewListOfCompetition = (
    <Card>
      <CardContent>
        <Box>
          {patientDetailsValue && patientDetailsValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {patientPointDataList?.loading
        ? judgementSecondRoundViewLoader(true)
        : judgementSecondRoundViewLoader(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {WinnerViewListOfCompetition}
        </Stack>
      </Container>
    </Box>
  );
}
