import {
  AddProviderOrganizationActionTypes,
  ADD_PROVIDER_ORGANIZATION_FAIL,
  ADD_PROVIDER_ORGANIZATION_LOADING,
  ADD_PROVIDER_ORGANIZATION_SUCCESS,
  AddProviderOrganizationDetailsState,
  ADD_PROVIDER_ORGANIZATION_API_MSG,
  ADD_PROVIDER_ORGANIZATION_API_RES,
} from "./ActionTypes";
import { AddProviderOrganizationDetails } from "./Model";

const initialStateGetPosts: AddProviderOrganizationDetailsState = {
  loading: false,
  AddProviderOrganizationRes: {} as AddProviderOrganizationDetails,
  message: "",
  status: 0,
};
export const addProviderOrganizationReducer = (
  state = initialStateGetPosts,
  action: AddProviderOrganizationActionTypes,
): AddProviderOrganizationDetailsState => {
  switch (action.type) {
    case ADD_PROVIDER_ORGANIZATION_LOADING:
      return {
        ...state,
        loading: true,
        AddProviderOrganizationRes: {} as AddProviderOrganizationDetails,
      };
    case ADD_PROVIDER_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        AddProviderOrganizationRes: action.payload,
      };
    case ADD_PROVIDER_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        AddProviderOrganizationRes: {} as AddProviderOrganizationDetails,
        message: action.message,
        status: action.status,
      };
    case ADD_PROVIDER_ORGANIZATION_API_MSG:
      return {
        ...state,
        loading: false,
        AddProviderOrganizationRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADD_PROVIDER_ORGANIZATION_API_RES:
      return {
        ...state,
        loading: false,
        AddProviderOrganizationRes: {} as AddProviderOrganizationDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
