import {
    EditHealthcampNameActionTypes,
   EDIT_HEALTHCAMP_NAME_FAIL,
   EDIT_HEALTHCAMP_NAME_LOADING,
   EDIT_HEALTHCAMP_NAME_SUCCESS,
   EditHealthcampNameState,
   EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG,
   EDIT_HEALTHCAMP_NAME_UPDATE_API_RES,
 } from "./ActionTypes";
 import { EditHealthcampNameRes } from "./Model";
 
 const initialStateGetPosts: EditHealthcampNameState = {
   loading: false,
   editHealthcampNameResponse: {} as EditHealthcampNameRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const  editHealthcampNameReducer = (
   state = initialStateGetPosts,
   action:  EditHealthcampNameActionTypes,
 ): EditHealthcampNameState => {
   switch (action.type) {
     case EDIT_HEALTHCAMP_NAME_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case EDIT_HEALTHCAMP_NAME_SUCCESS:
       return {
         ...state,
         editHealthcampNameResponse: action.payload,
         successMsg: action.successMsg,
       };
     case EDIT_HEALTHCAMP_NAME_FAIL:
       return {
         ...state,
         editHealthcampNameResponse: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG:
       return {
         ...state,
         editHealthcampNameResponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case EDIT_HEALTHCAMP_NAME_UPDATE_API_RES:
       return {
         ...state,
         editHealthcampNameResponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 