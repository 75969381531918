import { GetProviderOrgDetails } from "./Model";

// Patient Demographics Loading State
export const GET_PROVIDER_ORG_DETAILS_LOADING = "GET_PROVIDER_ORG_DETAILS_LOADING";
export const GET_PROVIDER_ORG_DETAILS_SUCCESS = "GET_PROVIDER_ORG_DETAILS_SUCCESS";
export const GET_PROVIDER_ORG_DETAILS_FAIL = "GET_PROVIDER_ORG_DETAILS_FAIL";
export const GET_PROVIDER_ORG_DETAILS_API_MSG = "GET_PROVIDER_ORG_DETAILS_API_MSG";
export const GET_PROVIDER_ORG_DETAILS_API_RES = "GET_PROVIDER_ORG_DETAILS_API_RES";

export interface GetProviderOrgDetailsState {
  getProviderOrgDetailsRes: GetProviderOrgDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgDetailsLoading {
  type: typeof GET_PROVIDER_ORG_DETAILS_LOADING;
  loading: boolean;
}
export interface GetProviderOrgDetailsSuccess {
  type: typeof GET_PROVIDER_ORG_DETAILS_SUCCESS;
  payload: GetProviderOrgDetails;
  successMsg: string;
}
export interface GetProviderOrgDetailsFail {
  type: typeof GET_PROVIDER_ORG_DETAILS_FAIL;
  payload: GetProviderOrgDetails;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgDetailsAPIMsg {
  type: typeof GET_PROVIDER_ORG_DETAILS_API_MSG;
  payload: GetProviderOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgDetailsAPIRes {
  type: typeof GET_PROVIDER_ORG_DETAILS_API_RES;
  payload: GetProviderOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetProviderOrgDetailsActionTypes =
  | GetProviderOrgDetailsLoading
  | GetProviderOrgDetailsSuccess
  | GetProviderOrgDetailsFail
  | GetProviderOrgDetailsAPIMsg
  | GetProviderOrgDetailsAPIRes;
