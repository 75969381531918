import { Autocomplete, Grid, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatientListByHealthcamp } from "redux/patientListOfHealthcamp/API";
import {
  GetPatientListByHealthCampBody,
  GetPatientListByHealthCampMainBody,
} from "redux/patientListOfHealthcamp/Model";
import { AppState } from "redux/store/Store";

export default function SortingComponent() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const AdminPatientList = useSelector((state: AppState) => state.healthcampPatientListValue);
  const filterList = [
    { label: "First Name (Asc)", value: "firstName" },
    { label: "First Name (Desc)", value: "-firstName" },
    { label: "Create Date (Asc)", value: "createDate" },
    { label: "Create Date (Desc)", value: "-createDate" },
    { label: "Modified Date (Asc)", value: "modifiedDate" },
    { label: "Modified Date (Desc)", value: "-modifiedDate" },
    { label: "Pin code (Asc)", value: "pincode" },
    { label: "Pin code (Desc)", value: "-pincode" },
  ];
  const changeFilter = (selectedFilterVal: any) => {
    setSelectedFilter(selectedFilterVal);
    if (selectedFilterVal !== null) {
      // Call API with "selectedFilterVal"
      const filterPatientListBody = {
        healthCampId: healthcampid,
        orderByParam: selectedFilterVal.value!,
      } as GetPatientListByHealthCampBody;
      // dispatch(getFilteredHealthCampPatientList(filterPatientListBody));
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: 1,
        } as GetPatientListByHealthCampMainBody),
      );
    }
  };
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" m={1}>
      {fullScreen ? (
        <Grid item xs={12} sm={12} md={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={filterList}
            value={selectedFilter}
            onChange={(_event: any, newValue: any) => changeFilter(newValue)}
            disabled={AdminPatientList?.loading}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField {...params} label="Sorting " size="small" fullWidth />
            )}
            sx={{ width: fullScreen ? "calc(100vw - 23vw)" : "100%" }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={2} xl={2} lg={2}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={filterList}
            value={selectedFilter}
            onChange={(_event: any, newValue: any) => changeFilter(newValue)}
            disabled={AdminPatientList?.loading}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField {...params} label="Sorting " size="small" fullWidth />
            )}
          />
        </Grid>
      )}
      {fullScreen ? (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <GridToolbarQuickFilter style={{ width: "calc(100vw - 23vw)", paddingTop: 10 }} />
        </Grid>
      ) : (
        <Grid item>
          <GridToolbarQuickFilter style={{ paddingRight: 14 }} />
        </Grid>
      )}
    </Grid>
  );
}
