import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DoctorSearchActionTypes } from "./ActionTypes";
import { DoctorSearchBody, DoctorSearchDetails } from "./Model";
import {
  DoctorSearchAPIResClearAction,
  DoctorSearchErrorAction,
  DoctorSearchLoadingAction,
  DoctorSearchSuccessAction,
  DoctorSearchUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as DoctorSearchDetails;
export const DoctorSearchApi = (searchKey: DoctorSearchBody, pageNo: any) => {
  return function (dispatch: Dispatch<DoctorSearchActionTypes>) {
    dispatch(DoctorSearchLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/searchapp/api/searchDoctor/search_doctor_list?page=${Number(pageNo)}&size=12`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DoctorSearchLoadingAction(false));
        dispatch(DoctorSearchSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(DoctorSearchLoadingAction(false));
        dispatch(
          DoctorSearchErrorAction(
            {} as DoctorSearchDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDoctorSearchAPIResMsg = () => {
  return function (dispatch: Dispatch<DoctorSearchActionTypes>) {
    dispatch(DoctorSearchUpdateAPIMsgAction(apiRes as DoctorSearchDetails, "", 0));
  };
};

export const clearDoctorSearchAPIRes = () => {
  return function (dispatch: Dispatch<DoctorSearchActionTypes>) {
    dispatch(DoctorSearchAPIResClearAction({} as DoctorSearchDetails, "", 0));
  };
};
