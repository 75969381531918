import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationEnglish from "./English/translation.json";
import translationBengali from "./Bengali/translation.json";

//Different namespaces
const resources = {
    en: {
        home: translationEnglish
    },
    ben: {
        home: translationBengali
    }
}
i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("lng") || "en", //set default language
    });


export default i18next;