import { GET_LIST } from "../types/ActionTypes";
import { DoctorOnboardIncompleteResponse } from "./Model";

// Patient Demographics Loading State
export const DOCTOR_ONBOARD_INCOMPLETE_LOADING = "DOCTOR_ONBOARD_INCOMPLETE_LOADING";
export const DOCTOR_ONBOARD_INCOMPLETE_SUCCESS = "DOCTOR_ONBOARD_INCOMPLETE_SUCCESS";
export const DOCTOR_ONBOARD_INCOMPLETE_FAIL = "DOCTOR_ONBOARD_INCOMPLETE_FAIL";

export interface GetPatientDemographicsState {
  patientDemographicsRes: DoctorOnboardIncompleteResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof DOCTOR_ONBOARD_INCOMPLETE_LOADING;
}
export interface DoctorListSuccess {
  type: typeof DOCTOR_ONBOARD_INCOMPLETE_SUCCESS;
  payload: DoctorOnboardIncompleteResponse;
}
export interface DoctorListFail {
  type: typeof DOCTOR_ONBOARD_INCOMPLETE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: DoctorOnboardIncompleteResponse;
}

export type PatientDemographicsActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
