import {
  GetVitalCollectionStatusActionTypes,
  GET_VITAL_COLLECTION_STATUS_FAIL,
  GET_VITAL_COLLECTION_STATUS_LOADING,
  GET_VITAL_COLLECTION_STATUS_SUCCESS,
  GetVitalCollectionStatusState,
  GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG,
  GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES,
} from "./ActionTypes";
import { GetVitalCollectionStatusRes } from "./Model";

const initialStateGetPosts: GetVitalCollectionStatusState = {
  loading: false,
  getVitalCollectionStatusRes: {} as GetVitalCollectionStatusRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getVitalCollectionStatusReducer = (
  state = initialStateGetPosts,
  action: GetVitalCollectionStatusActionTypes,
): GetVitalCollectionStatusState => {
  switch (action.type) {
    case GET_VITAL_COLLECTION_STATUS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_VITAL_COLLECTION_STATUS_SUCCESS:
      return {
        ...state,
        getVitalCollectionStatusRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_VITAL_COLLECTION_STATUS_FAIL:
      return {
        ...state,
        getVitalCollectionStatusRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG:
      return {
        ...state,
        getVitalCollectionStatusRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES:
      return {
        ...state,
        getVitalCollectionStatusRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
