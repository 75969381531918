import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddStaffOfOrgActionTypes } from "./ActionTypes";
import { AddStaffOfOrgBody, AddStaffOfOrgDetails } from "./Model";
import {
  AddStaffOfOrgAPIResClearAction,
  AddStaffOfOrgErrorAction,
  AddStaffOfOrgLoadingAction,
  AddStaffOfOrgSuccessAction,
  AddStaffOfOrgAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddStaffOfOrgDetails;
export const addStaffOfOrgApiCall = (payload: AddStaffOfOrgBody) => {
  return function (dispatch: Dispatch<AddStaffOfOrgActionTypes>) {
    dispatch(AddStaffOfOrgLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/patientorganization/add_staff_organization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(AddStaffOfOrgLoadingAction(false));
        dispatch(
          AddStaffOfOrgSuccessAction(
            res.data !== undefined ? res.data : ({} as AddStaffOfOrgDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(AddStaffOfOrgLoadingAction(false));
        dispatch(
          AddStaffOfOrgErrorAction(
            {} as AddStaffOfOrgDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const addStaffOfAPIResMsg = () => {
  return function (dispatch: Dispatch<AddStaffOfOrgActionTypes>) {
    dispatch(AddStaffOfOrgAPIMsgAction(apiRes as AddStaffOfOrgDetails, "", "", 0));
  };
};

export const clearAddStaffOfAPIRes = () => {
  return function (dispatch: Dispatch<AddStaffOfOrgActionTypes>) {
    dispatch(AddStaffOfOrgAPIResClearAction({} as AddStaffOfOrgDetails, "", "", 0));
  };
};
