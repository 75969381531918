import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ViewHistoryInstanceActionTypes } from "./ActionTypes";
import { ViewHistoryInstanceBody, ViewHistoryInstanceRes } from "./Model";
import {
  viewHistoryInstanceAPIResClearAction,
  viewHistoryInstanceErrorAction,
  viewHistoryInstanceLoadingAction,
  viewHistoryInstanceSuccessAction,
  viewHistoryInstanceUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as ViewHistoryInstanceRes;
export const viewHistoryInstanceByHealthCampId = (payload: ViewHistoryInstanceBody, pageNo: any) => {
  return function (dispatch: Dispatch<ViewHistoryInstanceActionTypes>) {
    dispatch(viewHistoryInstanceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampinstance/getinstancehealthcampHistory?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(viewHistoryInstanceLoadingAction(false));
        dispatch(
          viewHistoryInstanceSuccessAction(
            res.data !== undefined ? res.data : ({} as ViewHistoryInstanceRes),
            res.data.message !== undefined
              ? res.data.message
              : "View History fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(viewHistoryInstanceLoadingAction(false));
        dispatch(
          viewHistoryInstanceErrorAction(
            {} as ViewHistoryInstanceRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateViewHistoryOfInstanceIdAPIResMsg = () => {
  return function (dispatch: Dispatch<ViewHistoryInstanceActionTypes>) {
    dispatch(viewHistoryInstanceUpdateAPIMsgAction(apiRes as ViewHistoryInstanceRes, "", "", 0));
  };
};

export const clearViewHistoryOfInstanceIdAPIRes = () => {
  return function (dispatch: Dispatch<ViewHistoryInstanceActionTypes>) {
    dispatch(viewHistoryInstanceAPIResClearAction({} as ViewHistoryInstanceRes, "", "", 0));
  };
};
