import { GetSessionPatientDetailsListRes } from "./Model";
export const GET_SESSION_PATIENT_DETAILS_LIST_LOADING = "GET_SESSION_PATIENT_DETAILS_LIST_LOADING";
export const GET_SESSION_PATIENT_DETAILS_LIST_SUCCESS = "GET_SESSION_PATIENT_DETAILS_LIST_SUCCESS";
export const GET_SESSION_PATIENT_DETAILS_LIST_FAIL = "GET_SESSION_PATIENT_DETAILS_LIST_FAIL";
export const GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_MSG = "GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_MSG";
export const GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_RES = "GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_RES";

export interface GetSessionPatientDetailsListState {
  getSessionPatientDetailsListRes: GetSessionPatientDetailsListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSessionPatientDetailsListLoading {
  type: typeof GET_SESSION_PATIENT_DETAILS_LIST_LOADING;
  loading: boolean;
}
export interface GetSessionPatientDetailsListSuccess {
  type: typeof GET_SESSION_PATIENT_DETAILS_LIST_SUCCESS;
  payload: GetSessionPatientDetailsListRes;
  successMsg: string;
}
export interface GetSessionPatientDetailsListFail {
  type: typeof GET_SESSION_PATIENT_DETAILS_LIST_FAIL;
  payload: GetSessionPatientDetailsListRes;
  errorMsg: string;
  status: number;
}
export interface GetSessionPatientDetailsListUpdateAPIMsg {
  type: typeof GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_MSG;
  payload: GetSessionPatientDetailsListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSessionPatientDetailsListUpdateAPIRes {
  type: typeof GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_RES;
  payload: GetSessionPatientDetailsListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetSessionPatientDetailsListActionTypes =
  | GetSessionPatientDetailsListLoading
  | GetSessionPatientDetailsListSuccess
  | GetSessionPatientDetailsListFail
  | GetSessionPatientDetailsListUpdateAPIMsg
  | GetSessionPatientDetailsListUpdateAPIRes;
