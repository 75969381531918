import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetProviderOrganizationPatientListActionTypes } from "./ActionTypes";
import { GetProviderOrganizationPatientListBody, GetProviderOrganizationPatientListDetails } from "./Model";
import {
  GetProviderOrganizationPatientListAPIResClearAction,
  GetProviderOrganizationPatientListErrorAction,
  GetProviderOrganizationPatientListLoadingAction,
  GetProviderOrganizationPatientListSuccessAction,
  GetProviderOrganizationPatientListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetProviderOrganizationPatientListDetails;
export const getPatientListByProviderOrganization = (
  organizationId: GetProviderOrganizationPatientListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetProviderOrganizationPatientListActionTypes>) {
    dispatch(GetProviderOrganizationPatientListLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationPatientList/get_patientList_providerOrganization?page=${Number(
            pageNo,
          )}&size=50`,
        organizationId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(GetProviderOrganizationPatientListLoadingAction(false));
        dispatch(GetProviderOrganizationPatientListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(GetProviderOrganizationPatientListLoadingAction(false));
        dispatch(
          GetProviderOrganizationPatientListErrorAction(
            {} as GetProviderOrganizationPatientListDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const organizationPatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderOrganizationPatientListActionTypes>) {
    dispatch(
      GetProviderOrganizationPatientListAPIMsgAction(apiRes as GetProviderOrganizationPatientListDetails, "", 0),
    );
  };
};

export const clearOrgPatientListAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderOrganizationPatientListActionTypes>) {
    dispatch(
      GetProviderOrganizationPatientListAPIResClearAction({} as GetProviderOrganizationPatientListDetails, "", 0),
    );
  };
};
