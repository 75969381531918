import {
  HEALTH_CAMP_SESSION_REPORT_LIST_FAIL,
  HEALTH_CAMP_SESSION_REPORT_LIST_LOADING,
  HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS,
  HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG,
  HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES,
  GetHealthCampSessionReportActionTypes,
} from "./ActionTypes";
import { GetHealthCampSessionReportRes } from "./Model";

export const getHealthCampSessionReportLoadingAction = (
  loading: boolean,
): GetHealthCampSessionReportActionTypes => {
  return {
    type: HEALTH_CAMP_SESSION_REPORT_LIST_LOADING,
    loading: loading,
  };
};

export const getHealthCampSessionReportSuccessAction = (
  getHealthCampSessionReportResponse: GetHealthCampSessionReportRes,
  successMsg: string,
): GetHealthCampSessionReportActionTypes => {
  return {
    type: HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS,
    payload: getHealthCampSessionReportResponse,
    successMsg: successMsg,
  };
};

export const getHealthCampSessionReportErrorAction = (
  getHealthCampSessionReportResponse: GetHealthCampSessionReportRes,
  errMsg: string,
  status: number,
): GetHealthCampSessionReportActionTypes => {
  return {
    type: HEALTH_CAMP_SESSION_REPORT_LIST_FAIL,
    payload: getHealthCampSessionReportResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthCampSessionReportUpdateAPIMsgAction = (
  getHealthCampSessionReportResponse: GetHealthCampSessionReportRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthCampSessionReportActionTypes => {
  return {
    type: HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG,
    payload: getHealthCampSessionReportResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthCampSessionReportAPIResClearAction = (
  getHealthCampSessionReportResponse: GetHealthCampSessionReportRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthCampSessionReportActionTypes => {
  return {
    type: HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES,
    payload: getHealthCampSessionReportResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
