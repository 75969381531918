import { GET_LIST } from "../types/ActionTypes";
import { AssignExecutiveResponse } from "./Model";

// Patient Demographics Loading State
export const ASSIGN_EXECUTIVE_LOADING = "GET_EXECUTIVE_LOADING";
export const ASSIGN_EXECUTIVE_SUCCESS = "GET_EXECUTIVE_SUCCESS";
export const ASSIGN_EXECUTIVE_FAIL = "GET_EXECUTIVE_FAIL";

export interface GetPatientDemographicsState {
  assignExecutiveRes: AssignExecutiveResponse;
  loading: boolean;
}
export interface ExecutiveListLoading {
  type: typeof ASSIGN_EXECUTIVE_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof ASSIGN_EXECUTIVE_SUCCESS;
  payload: AssignExecutiveResponse;
}
export interface ExecutiveListFail {
  type: typeof ASSIGN_EXECUTIVE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: AssignExecutiveResponse;
}

export type ExecutiveListActionTypes =
  | GetDoctorAction
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail;
