import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { VitalRequestListActionTypes } from "./ActionTypes";
import { VitalRequestListBody, VitalRequestResponse } from "./Model";
import {
  vitalRequestListAPIResClearAction,
  vitalRequestListErrorAction,
  vitalRequestListLoadingAction,
  vitalRequestListSuccessAction,
  vitalRequestListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as VitalRequestResponse;
export const vitalRequestList = (payload: VitalRequestListBody) => {
  return function (dispatch: Dispatch<VitalRequestListActionTypes>) {
    dispatch(vitalRequestListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/vitalsCollectionRequestFromDashboard/vitals_collection_request_list_from_dashboard`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(vitalRequestListLoadingAction(false));
        dispatch(
          vitalRequestListSuccessAction(
            res.data !== undefined ? res.data : ({} as VitalRequestResponse),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(vitalRequestListLoadingAction(false));
        dispatch(
          vitalRequestListErrorAction(
            {} as VitalRequestResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const vitalRequestListAPIResMsg = () => {
  return function (dispatch: Dispatch<VitalRequestListActionTypes>) {
    dispatch(
      vitalRequestListUpdateAPIMsgAction(apiRes as VitalRequestResponse, "", "", 0),
    );
  };
};

export const clearVitalRequestListAPIRes = () => {
  return function (dispatch: Dispatch<VitalRequestListActionTypes>) {
    dispatch(vitalRequestListAPIResClearAction({} as VitalRequestResponse, "", "", 0));
  };
};
