import {
  ADD_PATIENT_BY_SESSION_ID_FAIL,
  ADD_PATIENT_BY_SESSION_ID_LOADING,
  ADD_PATIENT_BY_SESSION_ID_SUCCESS,
  ADD_PATIENT_BY_SESSION_ID_API_MSG,
  ADD_PATIENT_BY_SESSION_ID_API_RES,
  AddPatientBySessionIdActionTypes,
} from "./ActionTypes";
import { AddPatientBySessionIdDetails } from "./Model";

export const addPatientBySessionIdLoadingAction = (
  loading: boolean,
): AddPatientBySessionIdActionTypes => {
  return {
    type: ADD_PATIENT_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const addPatientBySessionIdSuccessAction = (
  addPatientBySessionIdResponse: AddPatientBySessionIdDetails,
  successMsg: string,
): AddPatientBySessionIdActionTypes => {
  return {
    type: ADD_PATIENT_BY_SESSION_ID_SUCCESS,
    payload: addPatientBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const addPatientBySessionIdErrorAction = (
  addPatientBySessionIdResponse: AddPatientBySessionIdDetails,
  errMsg: string,
  status: number,
): AddPatientBySessionIdActionTypes => {
  return {
    type: ADD_PATIENT_BY_SESSION_ID_FAIL,
    payload: addPatientBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientBySessionIdAPIMsgAction = (
  addPatientBySessionIdResponse: AddPatientBySessionIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddPatientBySessionIdActionTypes => {
  return {
    type: ADD_PATIENT_BY_SESSION_ID_API_MSG,
    payload: addPatientBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientBySessionIdAPIResClearAction = (
  addPatientBySessionIdResponse: AddPatientBySessionIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddPatientBySessionIdActionTypes => {
  return {
    type: ADD_PATIENT_BY_SESSION_ID_API_RES,
    payload: addPatientBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
