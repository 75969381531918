import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { OrganizationAddRegistrationRequestDetailsActionTypes } from "./ActionTypes";
import {
  OrganizationAddRegistrationRequestDetailsBody,
  OrganizationAddRegistrationRequestDetails,
} from "./Model";
import {
  organizationAddRegistrationRequestDetailsAPIResClearAction,
  organizationAddRegistrationRequestDetailsErrorAction,
  organizationAddRegistrationRequestDetailsLoadingAction,
  organizationAddRegistrationRequestDetailsSuccessAction,
  organizationAddRegistrationRequestDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as OrganizationAddRegistrationRequestDetails;
export const organizationAddRegistrationRequestDetailsApiCall = (
  payload: OrganizationAddRegistrationRequestDetailsBody,
) => {
  return function (dispatch: Dispatch<OrganizationAddRegistrationRequestDetailsActionTypes>) {
    dispatch(organizationAddRegistrationRequestDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientOrganizationRegistrationDetail/add_registration_request_detail_patientOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(organizationAddRegistrationRequestDetailsLoadingAction(false));
        dispatch(
          organizationAddRegistrationRequestDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as OrganizationAddRegistrationRequestDetails),
            res.data.message !== undefined
              ? res.data.message
              : "PatientOrganizationRegistrationRequestDetail is created.",
          ),
        );
      })
      .catch((error) => {
        dispatch(organizationAddRegistrationRequestDetailsLoadingAction(false));
        dispatch(
          organizationAddRegistrationRequestDetailsErrorAction(
            {} as OrganizationAddRegistrationRequestDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateOrganizationAddRegistrationRequestDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<OrganizationAddRegistrationRequestDetailsActionTypes>) {
    dispatch(
      organizationAddRegistrationRequestDetailsAPIMsgAction(
        apiRes as OrganizationAddRegistrationRequestDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearOrganizationAddRegistrationRequestDetailsAPIRes = () => {
  return function (dispatch: Dispatch<OrganizationAddRegistrationRequestDetailsActionTypes>) {
    dispatch(
      organizationAddRegistrationRequestDetailsAPIResClearAction(
        {} as OrganizationAddRegistrationRequestDetails,
        "",
        "",
        0,
      ),
    );
  };
};
