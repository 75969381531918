import { AlertColor, Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  GetPaymentListBody,
  AppointmentRequestList,
} from "../../../../redux/Patient/AppointmentPaymentList/Model";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MWExceptionList from "../../../../component/MWExceptionList";
import { useParams } from "react-router-dom";
import { getAppointmentPaymentList } from "../../../../redux/Patient/AppointmentPaymentList/API";
import { ModifiedAppointmentRequestList } from "./Model";
import { ConfirmAppointmentBody } from "../../../../redux/Patient/AppointmentPaymentList/CheckPaymentStatus copy/Model";
import {
  clearConfirmAppointmentAPIRes,
  confirmAppointment,
} from "../../../../redux/Patient/AppointmentPaymentList/CheckPaymentStatus copy/API";
import MWSnackbar from "../../../../component/MWSnackbar";

export default function ExistingAppointmentDetails() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const [appointmentRequestList, setAppointmentRequestList] = useState(
    [] as ModifiedAppointmentRequestList[],
  );

  let getAppointmentPaymentListRes = useSelector(
    (state: AppState) => state.getAppointmentPaymentListRes,
  );

  let confirmAppointmentRes = useSelector((state: AppState) => state.confirmAppointmentRes);

  useEffect(() => {
    if (
      getAppointmentPaymentListRes?.getAppointmentPaymentListRes?.appointmentRequestList !==
      undefined
    ) {
      if (
        getAppointmentPaymentListRes?.getAppointmentPaymentListRes?.appointmentRequestList.length >
        0
      ) {
        let appointmentRequestListItem =
          getAppointmentPaymentListRes?.getAppointmentPaymentListRes?.appointmentRequestList.map(
            (item: AppointmentRequestList) => {
              return {
                id: item.id,
                description: item?.name !== undefined ? (item?.name !== null ? item.name : "") : "",
                createDate:
                  item?.createDate !== undefined
                    ? item?.createDate !== null
                      ? dayjs(item?.createDate).format("ddd, MM/DD/YYYY")
                      : ""
                    : "",
                endDate:
                  item?.end !== undefined
                    ? item?.end !== null
                      ? dayjs(item?.end).format("ddd, MM/DD/YYYY")
                      : ""
                    : "",
                modifiedDate:
                  item?.modifiedDate !== undefined
                    ? item?.modifiedDate !== null
                      ? dayjs(item?.modifiedDate).format("ddd, MM/DD/YYYY")
                      : ""
                    : "",
                status:
                  item?.status !== undefined ? (item?.status !== null ? item.status : "") : "",
              } as ModifiedAppointmentRequestList;
            },
          );
        setAppointmentRequestList(appointmentRequestListItem);
      } else setAppointmentRequestList([] as ModifiedAppointmentRequestList[]);
    } else setAppointmentRequestList([] as ModifiedAppointmentRequestList[]);
  }, [getAppointmentPaymentListRes]);
  const columns: GridColDef[] = [
    { field: "description", headerName: "Description", flex: 1 },
    { field: "createDate", headerName: "Create Date", minWidth: 130 },
    { field: "endDate", headerName: "End Date", minWidth: 130 },
    { field: "modifiedDate", headerName: "Modified Date", minWidth: 130 },
    { field: "status", headerName: "Status", minWidth: 25 },
    {
      field: "id",
      headerName: "Confirm Appointment",
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<MobileFriendlyIcon />}
          variant="outlined"
          onClick={() => {
            dispatch(
              confirmAppointment({
                appointmentRequestId: String(params.row.id),
              } as ConfirmAppointmentBody),
            );
          }}
          sx={{ textTransform: "none" }}
          disabled={confirmAppointmentRes?.loading}
        >
          Confirm
        </Button>
      ),
    },
  ];
  const [checkPaymentStatusMsg, setCheckPaymentStatusMsg] = useState("");
  const [checkPaymentStatusMsgType, setCheckPaymentStatusMsgType] = useState<AlertColor>("success");
  useEffect(() => {
    if (confirmAppointmentRes?.successMsg !== "") {
      setCheckPaymentStatusMsgType("success");
      setCheckPaymentStatusMsg(confirmAppointmentRes?.successMsg);
    }
    if (confirmAppointmentRes?.errorMsg !== "") {
      setCheckPaymentStatusMsgType("error");
      setCheckPaymentStatusMsg(confirmAppointmentRes?.errorMsg);
    }
  }, [confirmAppointmentRes]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearConfirmAppointmentAPIRes());
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>Existing Appointment Details</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={getAppointmentPaymentListRes?.loading}
                  onClick={() =>
                    dispatch(
                      getAppointmentPaymentList({
                        caseId: caseid,
                      } as GetPaymentListBody),
                    )
                  }
                  sx={{ textTransform: "none" }}
                >
                  Reload Appointment List
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {appointmentRequestList.length > 0 ? (
            <DataGrid
              rows={appointmentRequestList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <MWSnackbar
        msg={checkPaymentStatusMsg}
        type={checkPaymentStatusMsgType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
