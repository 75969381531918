import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Box,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TabContext, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";
import { AppState } from "redux/store/Store";
import MWPageTitle from "component/MWPageTitle";
import PatientList from "./PatientList";
import { getPatientListValue } from "redux/effects/ApiCall";
import DuplicatePatientList from "./DuplicatePatientList/inedex";
import { useHistory } from "react-router-dom";
import { getDuplicatePatientList } from "redux/DuplicatePatientList/API";
import MWSnackbar from "component/MWSnackbar";
import {
  clearPatientDetailsFixedAPIRes,
  updatePatientDetailsFixedAPIResMsg,
} from "redux/MarkPatientDetails/API";
import PageLayout from "pages/Layout/PageLayout";

export default function PatientListComponent() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();
  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.patientValue);
  const getDuplicatePatientDataList = useSelector(
    (state: AppState) => state.getDuplicatePatientListValue,
  );
  const duplicatePatientDetails = useSelector((state: AppState) => state.addDuplicatePatientValue);

  const [listCount, setListCount] = useState("");
  const [duplicateCount, setDuplicateCount] = useState("");
  // const [tabValue, setTabValue] = useState("0");
  const [selected, setSelected] = React.useState("0");
  const [duplicateMsg, setDuplicateMsg] = React.useState("");
  const [duplicateMsgType, setDuplicateMsgType] = React.useState<AlertColor>("success");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: any) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === "0") {
      history.push(`${window.location.pathname + "?section=patientList"}`);
    } else if (newValue === "1") {
      history.push(`${window.location.pathname + "?section=duplicatepatientlist"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    AdminPatientList?.count !== undefined
      ? setListCount(String(AdminPatientList?.count))
      : setListCount("0");
  }, [AdminPatientList]);

  useEffect(() => {
    getDuplicatePatientDataList?.getDuplicatePatientListRes?.count !== undefined
      ? setDuplicateCount(String(getDuplicatePatientDataList?.getDuplicatePatientListRes?.count))
      : setDuplicateCount("0");
  }, [getDuplicatePatientDataList]);

  useEffect(() => {
    if (duplicatePatientDetails?.addDuplicatePatientRes?.message !== undefined) {
      setDuplicateMsg(duplicatePatientDetails?.successMsg);
      setDuplicateMsgType("success");
      dispatch(getDuplicatePatientList(1));
    } else {
      if (duplicatePatientDetails?.errorMsg !== undefined) {
        setDuplicateMsg(duplicatePatientDetails?.errorMsg);
        setDuplicateMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicatePatientDetails]);

  const reloadActionFromChild = () => {
    dispatch(getPatientListValue(1));
  };
  const duplicateList = () => {
    dispatch(getDuplicatePatientList(1));
  };
  const StaffListOfHealthCamp = (
    <Box>
      <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
        <Tabs
          value={selected}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Patient List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={listCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  reloadActionFromChild();
                }}
              />
            }
            iconPosition="end"
            value="0"
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Duplicate Patient List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={duplicateCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  duplicateList();
                }}
              />
            }
            iconPosition="end"
            value="1"
          />
        </Tabs>
      </Box>
      <Divider />
      {fullScreen ? (
        <Box pt={2}>{selected === "0" ? <PatientList /> : <DuplicatePatientList />}</Box>
      ) : (
        <TabContext value={String(selected)}>
          <TabPanel value="0">
            <PatientList />
          </TabPanel>
          <TabPanel value="1">
            <DuplicatePatientList />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );

  const closeMarkAlert = () => {
    setDuplicateMsg("");
    dispatch(clearPatientDetailsFixedAPIRes());
    dispatch(updatePatientDetailsFixedAPIResMsg());
  };

  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" mt={2}>
        <Grid item>
          <MWPageTitle title={"Patient List"} />
        </Grid>
      </Grid>
      {StaffListOfHealthCamp}
      {duplicateMsg !== "" ? (
        <MWSnackbar msg={duplicateMsg} type={duplicateMsgType} alertClose={closeMarkAlert} />
      ) : null}
    </PageLayout>
  );
}
