import { GET_LIST } from "../../types/ActionTypes";
import { AddIdentifyResponse } from "./Model";

// Patient Demographics Loading State
export const ADD_IDENTIFY_LOADING = "ADD_IDENTIFY_LOADING";
export const ADD_IDENTIFY_SUCCESS = "ADD_IDENTIFY_SUCCESS";
export const ADD_IDENTIFY_FAIL = "ADD_IDENTIFY_FAIL";

export interface GetPatientDemographicsState {
  addIdentify: AddIdentifyResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof ADD_IDENTIFY_LOADING;
}
export interface DoctorListSuccess {
  type: typeof ADD_IDENTIFY_SUCCESS;
  payload: AddIdentifyResponse;
}
export interface DoctorListFail {
  type: typeof ADD_IDENTIFY_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: AddIdentifyResponse;
}

export type AddIdentifyActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
