import { AddDrOnboardingRequestTypes, ADD_DR_ONBOARDING_REQUEST, DrApproveOrRejectTypes, DR_APPROVE_REJECT_REQUEST } from "./ActionTypes";

// Add Dr. API
export const addDrOnboardRequestActions = (message: any, status: any): AddDrOnboardingRequestTypes => {
    return {
        type: ADD_DR_ONBOARDING_REQUEST,
        message: message,
        status: status,
    };
};
// Dr Approve or Reject API
export const drApproveOrRejectActions = (message: any, status: any): DrApproveOrRejectTypes => {
    return {
        type: DR_APPROVE_REJECT_REQUEST,
        message: message,
        status: status,
    };
};