import {
  AddScoundRoundDetailsActionTypes,
  ADD_SECOND_ROUND_FAIL,
  ADD_SECOND_ROUND_LOADING,
  ADD_SECOND_ROUND_SUCCESS,
  AddSecondRoundState,
  ADD_SECOND_ROUND_API_MSG,
  ADD_SECOND_ROUND_API_RES,
} from "./ActionTypes";
import { AddScoundRoundDetailsRes } from "./Model";

const initialStateUpdatePosts: AddSecondRoundState = {
  loading: false,
  addSecondRoundRes: {} as AddScoundRoundDetailsRes,
  errorMsg: "",
  status: 0,
};
export const addSecoundRoundReducer = (
  state = initialStateUpdatePosts,
  action: AddScoundRoundDetailsActionTypes,
): AddSecondRoundState => {
  switch (action.type) {
    case ADD_SECOND_ROUND_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_SECOND_ROUND_SUCCESS:
      return {
        ...state,
        addSecondRoundRes: action.payload,
      };
    case ADD_SECOND_ROUND_FAIL:
      return {
        ...state,
        addSecondRoundRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_SECOND_ROUND_API_MSG:
      return {
        ...state,
        addSecondRoundRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_SECOND_ROUND_API_RES:
      return {
        ...state,
        addSecondRoundRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
