import { GET_LIST } from "../../types/ActionTypes";
import { DeleteIdentifyResponse } from "./Model";

// Patient Demographics Loading State
export const DELETE_IDENTIFY_LOADING = "DELETE_IDENTIFY_LOADING";
export const DELETE_IDENTIFY_SUCCESS = "DELETE_IDENTIFY_SUCCESS";
export const DELETE_IDENTIFY_FAIL = "DELETE_IDENTIFY_FAIL";

export interface GetPatientDemographicsState {
  deleteIdentify: DeleteIdentifyResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof DELETE_IDENTIFY_LOADING;
}
export interface DoctorListSuccess {
  type: typeof DELETE_IDENTIFY_SUCCESS;
  payload: DeleteIdentifyResponse;
}
export interface DoctorListFail {
  type: typeof DELETE_IDENTIFY_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: DeleteIdentifyResponse;
}

export type DeleteIdentifyActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
