import {
  DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL,
  DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING,
  DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS,
  DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG,
  DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES,
  DuplicateHealthcampListByPatientIdActionTypes,
} from "./ActionTypes";
import { DuplicateHealthcampListByPatientIdDetails } from "./Model";

export const DuplicateHealthcampListByPatientIdLoadingAction = (
  loading: boolean,
): DuplicateHealthcampListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const DuplicateHealthcampListByPatientIdSuccessAction = (
  duplicateHealthcampListByPatientIdResponse: DuplicateHealthcampListByPatientIdDetails,
  successMsg: string,
): DuplicateHealthcampListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS,
    payload: duplicateHealthcampListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const DuplicateHealthcampListByPatientIdErrorAction = (
  duplicateHealthcampListByPatientIdResponse: DuplicateHealthcampListByPatientIdDetails,
  errMsg: string,
  status: number,
): DuplicateHealthcampListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL,
    payload: duplicateHealthcampListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DuplicateHealthcampListByPatientIdAPIMsgAction = (
  duplicateHealthcampListByPatientIdResponse: DuplicateHealthcampListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): DuplicateHealthcampListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG,
    payload: duplicateHealthcampListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const DuplicateHealthcampListByPatientIdAPIResClearAction = (
  duplicateHealthcampListByPatientIdResponse: DuplicateHealthcampListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): DuplicateHealthcampListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES,
    payload: duplicateHealthcampListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
