import { AddExistingPatientByProviderOrgIdDetails } from "./Model";

export const ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING = "ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING";
export const ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS = "ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS";
export const ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL = "ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL";
export const ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG = "ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG";
export const ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES = "ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES";

export interface AddExistingPatientByProviderOrgIdState {
  addExistingPatientByProviderOrgIdRes: AddExistingPatientByProviderOrgIdDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientByProviderOrgIdLoading {
  type: typeof ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING;
  loading: boolean;
}
export interface AddExistingPatientByProviderOrgIdSuccess {
  type: typeof ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS;
  payload: AddExistingPatientByProviderOrgIdDetails;
  successMsg: string;
}
export interface AddExistingPatientByProviderOrgIdFail {
  type: typeof ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL;
  payload: AddExistingPatientByProviderOrgIdDetails;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientByProviderOrgIdAPIMsg {
  type: typeof ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG;
  payload: AddExistingPatientByProviderOrgIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientByProviderOrgIdAPIRes {
  type: typeof ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES;
  payload: AddExistingPatientByProviderOrgIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type AddExistingPatientByProviderOrgIdActionTypes =
  | AddExistingPatientByProviderOrgIdLoading
  | AddExistingPatientByProviderOrgIdSuccess
  | AddExistingPatientByProviderOrgIdFail
  | AddExistingPatientByProviderOrgIdAPIMsg
  | AddExistingPatientByProviderOrgIdAPIRes;
