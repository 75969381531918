import * as React from "react";
import { useEffect, useState } from "react";
import { getPatientCaseList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertColor,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import Demographics from "../PatientDashboard/Demographics";
import CaseList from "../PatientDashboard/CaseList/Index";
import CarePlan from "../PatientDashboard/CarePlan/Index";
import MedicationSummary from "../PatientDashboard/MedicationSummary/Index";
import HealthCondition from "../PatientDashboard/HealthCondition/Index";
import DataObservationList from "../PatientDashboard/DataObservationList/Index";
import { getpatientDetails } from "../../redux/Patient/DemographicsDetails/ApiCall";
import { PatientDemographicsReq } from "../../redux/Patient/DemographicsDetails/Model";
import { useDispatch, useSelector } from "react-redux";
import MWPageTitle from "../../component/MWPageTitle";
import PatientDataDetails from "../PatientDataDetails";
import CustomCard from "../../component/CardComponent";
import CarePlanList from "../PatientDashboard/CarePlan/CustomCardDataList";
import { NameGenerate } from "../../component/Utility";
import {
  LatestDataListBody,
  PatientObservationsList,
} from "../../redux/patientDashboardLatestDataList/Model";
import { getPatientDashBoardLatestDataList } from "../../redux/patientDashboardLatestDataList/ApiCall";
import RefreshIcon from "@mui/icons-material/Refresh";
import DefaultVital from "../../image/design-assets/medicalWISDOM-round-default.svg";
import { PatientVitalDetails } from "../PatientDashboard/CarePlan/Model";
import { ActivatePatientBody } from "redux/activatePatients/Model";
import { activatePatient, clearActivatePatientAPIRes } from "redux/activatePatients/API";
import MWSnackbar from "component/MWSnackbar";
import SleepSummary from "pages/PatientDashboard/SleepSummary/Index";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import PageLayout from "pages/Layout/PageLayout";
import PatientDetailsMenu from "./PatientDetailsMenu";

export interface MenuItemType {
  id: string;
  name: string;
  content: string;
  button: string;
  path: string;
  info: string;
  image: string;
}

const NameChangeSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function PatientDetails() {
  const menuItems = CarePlanList;
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { patientid } = useParams() as {
    patientid: string;
  };

  const handleViewNotesToggle = () => {
    history.push(`/patientnotes/${patientid}`);
  };

  const viewdemographic = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}?&tab=consultationType`);
  };
  const handleViewPtDataPoint = () => {
    history.push(`/patientdataobservationlist/${patientid}`);
  };
  const handlePtTeamList = () => {
    history.push(`/patientteamlistdetails/${patientid}`);
  };

  // Get Patient Demographics Details Body
  const getPatientDemographicsDetailsBody = {
    healthRecordId: patientid,
  } as PatientDemographicsReq;

  const patientDetailsValue = useSelector((state: AppState) => state.gethealthRecordvalue);
  const activatePatientRes = useSelector((state: AppState) => state.activatePatientRes);

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<AlertColor>("success");

  useEffect(() => {
    if (activatePatientRes?.successMsg !== "") {
      setMessage(activatePatientRes?.successMsg);
      setMessageType("success");
    }
    if (activatePatientRes?.errorMsg !== "") {
      setMessageType("error");
      setMessage(activatePatientRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatePatientRes]);

  const activePatientCloseAction = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearActivatePatientAPIRes());
    }
  };

  const [toggleState, setToggleState] = useState(false);
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      patientDetailsValue?.personalDetails?.firstname !== undefined &&
      patientDetailsValue?.personalDetails?.lastname !== undefined
    ) {
      localStorage.setItem("togglename", String(event.target.checked));
      setToggleState(event.target.checked);
      NameGenerate.changeName(
        String(patientDetailsValue?.personalDetails?.id),
        patientDetailsValue?.personalDetails?.firstname +
          " " +
          patientDetailsValue?.personalDetails?.lastname,
      );
    }
  };

  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    dispatch(getpatientDetails(getPatientDemographicsDetailsBody));
    dispatch(
      getPatientDashBoardLatestDataList({
        patientId: patientid,
      } as LatestDataListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      patientDetailsValue?.personalDetails?.firstname !== undefined &&
      patientDetailsValue?.personalDetails?.lastname !== undefined
    ) {
      if ("togglename" in localStorage) {
        NameGenerate.changeName(
          String(patientDetailsValue?.personalDetails?.id),
          patientDetailsValue?.personalDetails?.firstname +
            " " +
            patientDetailsValue?.personalDetails?.lastname,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState, patientDetailsValue]);

  useEffect(() => {
    if ("togglename" in localStorage) {
      setToggleState(localStorage.getItem("togglename") === "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPatientDashboardLatestDataListResponse = useSelector(
    (state: AppState) => state.getPatientDashboardLatestDataListRes,
  );
  const [observationList, setObservationList] = useState([] as PatientVitalDetails[]);
  const [sleepObservationList, setSleepObservationList] = useState([] as any[]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(getPatientDashboardLatestDataListResponse?.loading));
    if (
      getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
        ?.patientObservationsList !== undefined
    ) {
      if (
        getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
          ?.patientObservationsList.length > 0
      ) {
        // map array item with selected fields
        let ptList =
          getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              name: element.PatientDashboardUiComponent.displayValue,
              content: element.LatestObservation.value,
              isActive: element.PatientDashboardUiComponent.isActive,
              type: element.LatestObservation.patientObservationType.name,
            }),
          ) as PatientVitalDetails[];
        let values = ptList.filter(
          (person) =>
            person.type === "sleep" ||
            person.type === "sleepschedule" ||
            person.type === "sleephours" ||
            person.type === "sleeptracker" ||
            person.type === "sleepscore",
        );
        let obsTypeListValue = ptList.filter((a) => !values.map((b) => b.type).includes(a.type));
        // pickup matched items with API response array and static JSON
        const matchedItems = obsTypeListValue.map((v) => ({
          ...menuItems.find((sp) => sp.path === v.type),
          ...v,
        })) as PatientVitalDetails[];

        // pickup items which doesn't have any image
        let matchedItemWithOutImg = matchedItems.filter((o1: PatientVitalDetails) => o1.image);
        // pickup items which doesn't exists in matched items
        let unMatchedItemArr = matchedItems.filter(
          (o1: PatientVitalDetails) => !matchedItemWithOutImg.some((o2) => o1.id === o2.id),
        );
        // Add missing property in unmatched items
        let modifiedUnmatchedItemArr = unMatchedItemArr.map((element: PatientVitalDetails) => {
          return {
            name: element.name,
            content: element.content,
            isActive: element.isActive !== undefined ? element.isActive : false,
            type: element.type !== undefined ? element.type : "",
            button: "View All",
            image: DefaultVital,
            path: element.type !== undefined ? element.type : "",
          };
        });
        // merge matched & unmatched item array & add id property for all item objects
        var totalDataSet = [...matchedItemWithOutImg, ...modifiedUnmatchedItemArr].map(
          (item, index) => ({ ...item, id: index + 1 }),
        );
        const finalDataSet = totalDataSet.filter((item) => item.isActive === true);
        setObservationList(finalDataSet);
      } else setObservationList([] as PatientVitalDetails[]);
    } else setObservationList([] as PatientVitalDetails[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDashboardLatestDataListResponse]);
  //check sleep type existance
  const keyToCheck = "type";
  const keyExists = sleepObservationList.some((obj) => typeof obj[keyToCheck] !== "undefined");
  useEffect(() => {
    if (
      getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
        ?.patientObservationsList !== undefined
    ) {
      if (
        getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
          ?.patientObservationsList.length > 0
      ) {
        // map array item with selected fields
        let ptList =
          getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              name: element.LatestObservation.patientObservationType.displayValue,
              value: element.LatestObservation.value,
              type: element.LatestObservation.patientObservationType.name,
            }),
          );
        let values = ptList.filter(
          (person) =>
            person.type === "sleep" ||
            person.type === "sleepschedule" ||
            person.type === "sleephours" ||
            person.type === "sleeptracker" ||
            person.type === "sleepscore",
        );
        setSleepObservationList(values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDashboardLatestDataListResponse]);

  const pageBody = (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Demographics />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CaseList />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Button
          variant="outlined"
          size="small"
          disabled={getPatientDashboardLatestDataListResponse?.loading}
          onClick={() =>
            dispatch(
              getPatientDashBoardLatestDataList({
                patientId: patientid,
              } as LatestDataListBody),
            )
          }
          startIcon={<RefreshIcon />}
          sx={{ textTransform: "none" }}
        >
          Refresh Vitals
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              {observationList.map((element: PatientVitalDetails) => (
                <Grid item key={element.name} xs={6} sm={6} md={3} lg={3}>
                  <CustomCard
                    id={element.id}
                    heading={element.name}
                    subHeading={element.content}
                    buttonText={element.button}
                    onButtonClick={element?.path}
                    image={element.image}
                  />
                </Grid>
              ))}
              {keyExists === true ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SleepSummary />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CarePlan />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MedicationSummary />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <HealthCondition />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
          <Tabs
            value={selected}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient Details List</Typography>
                  </Grid>
                </Grid>
              }
              value="1"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient Observation List</Typography>
                  </Grid>
                </Grid>
              }
              value="2"
            />
          </Tabs>
        </Box>
        <Divider />
        {fullScreen ? (
          <Box pt={1}>
            {selected === "1" ? (
              <PatientDataDetails toggleState={toggleState} />
            ) : (
              <DataObservationList toggleState={toggleState} />
            )}
          </Box>
        ) : (
          <TabContext value={String(selected)}>
            <TabPanel value="1">
              <PatientDataDetails toggleState={toggleState} />
            </TabPanel>
            <TabPanel value="2">
              <DataObservationList toggleState={toggleState} />
            </TabPanel>
          </TabContext>
        )}
      </Grid>
    </Grid>
  );
  useEffect(() => {
    dispatch(toggleAppBarLoading(patientDetailsValue?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsValue]);
  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={2}>
        {fullScreen ? (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <MWPageTitle
                  title={`Patient Details of ${NameGenerate.changeName(
                    patientid,
                    patientDetailsValue?.personalDetails?.firstname !== undefined &&
                      patientDetailsValue?.personalDetails?.lastname !== undefined
                      ? patientDetailsValue?.personalDetails?.firstname +
                          " " +
                          patientDetailsValue?.personalDetails?.lastname
                      : "",
                  )} `}
                  backButton={true}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <MWPageTitle
                    title={`Patient Details of ${NameGenerate.changeName(
                      patientid,
                      patientDetailsValue?.personalDetails?.firstname !== undefined &&
                        patientDetailsValue?.personalDetails?.lastname !== undefined
                        ? patientDetailsValue?.personalDetails?.firstname +
                            " " +
                            patientDetailsValue?.personalDetails?.lastname
                        : "",
                    )} `}
                    backButton={true}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <NameChangeSwitch
                        checked={toggleState}
                        onChange={handleNameChange}
                        name="Mode"
                      />
                    }
                    label={"Strict Mode"}
                    labelPlacement={"start"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {fullScreen ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <NameChangeSwitch checked={toggleState} onChange={handleNameChange} name="Mode" />
                }
                label={"Strict Mode"}
                labelPlacement={"start"}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <PatientDetailsMenu />
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() =>
                          dispatch(
                            activatePatient({
                              patientId: Number(patientid),
                              isActive: 1,
                            } as ActivatePatientBody),
                          )
                        }
                        sx={{ textTransform: "none" }}
                      >
                        Activate
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => handlePtTeamList()}
                        sx={{ textTransform: "none" }}
                      >
                        Membership
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => handleViewPtDataPoint()}
                        sx={{ textTransform: "none" }}
                      >
                        View Patient Observation
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={() => handleViewNotesToggle()}
                        sx={{ textTransform: "none" }}
                      >
                        View Note
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={() => viewdemographic()}
                        sx={{ textTransform: "none" }}
                      >
                        Demographic Details
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => handleAddEncounterToggle()}
                        sx={{ textTransform: "none" }}
                      >
                        Add Case
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {pageBody}
      <MWSnackbar type={messageType} msg={message} alertClose={activePatientCloseAction} />
    </PageLayout>
  );
}
