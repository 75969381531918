import { PageCount, EditSessionDetails } from "./Model";

// Patient Demographics Loading State
export const EDIT_SESSION_LOADING = "EDIT_SESSION_LOADING";
export const EDIT_SESSION_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const EDIT_SESSION_FAIL = "EDIT_SESSION_FAIL";
export const EDIT_SESSION_DATA = "EDIT_SESSION_DATA";
export const EDIT_SESSION_API_MSG = "EDIT_SESSION_API_MSG";
export const EDIT_SESSION_API_RES = "EDIT_SESSION_API_RES";

export interface EditSessionState {
  editSessionRes: EditSessionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditSessionLoading {
  type: typeof EDIT_SESSION_LOADING;
  loading: boolean;
}
export interface EditSessionSuccess {
  type: typeof EDIT_SESSION_SUCCESS;
  payload: EditSessionDetails;
  successMsg: string;
}
export interface EditSessionFail {
  type: typeof EDIT_SESSION_FAIL;
  payload: EditSessionDetails;
  errorMsg: string;
  status: number;
}
export interface EditSessionAPIMsg {
  type: typeof EDIT_SESSION_API_MSG;
  payload: EditSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditSessionAPIRes {
  type: typeof EDIT_SESSION_API_RES;
  payload: EditSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface EditSessionDataAction {
  type: typeof EDIT_SESSION_DATA;
  payload: EditSessionDetails;
  pageCount: PageCount;
}

export type EditSessionActionTypes =
  | EditSessionDataAction
  | EditSessionLoading
  | EditSessionSuccess
  | EditSessionFail
  | EditSessionAPIMsg
  | EditSessionAPIRes;
