import {
  ExecutiveSignupRequestListActionTypes,
  EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL,
  EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING,
  EXECUTIVE_SIGHUP_REQUEST_LIST_SUCCESS,
  ExecutiveSignupRequestListState,
  EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG,
  EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES,
} from "./ActionTypes";
import { ExecutiveSignupRequestListDetails } from "./Model";

const initialStateGetPosts: ExecutiveSignupRequestListState = {
  loading: false,
  executiveSignupRequestListRes: {} as ExecutiveSignupRequestListDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const executiveSignupRequestListReducer = (
  state = initialStateGetPosts,
  action: ExecutiveSignupRequestListActionTypes,
): ExecutiveSignupRequestListState => {
  switch (action.type) {
    case EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EXECUTIVE_SIGHUP_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        executiveSignupRequestListRes: action.payload,
        successMsg: action.successMsg,
      };
    case EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL:
      return {
        ...state,
        executiveSignupRequestListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG:
      return {
        ...state,
        executiveSignupRequestListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES:
      return {
        ...state,
        executiveSignupRequestListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
