import {
  ExecutiveSearchActionTypes,
  EXECUTIVE_SEARCH_FAIL,
  EXECUTIVE_SEARCH_LOADING,
  EXECUTIVE_SEARCH_SUCCESS,
  ExecutiveSearchDetailsState,
  EXECUTIVE_SEARCH_UPDATE_API_MSG,
  EXECUTIVE_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { ExecutiveSearchDetails } from "./Model";

const initialStateGetPosts: ExecutiveSearchDetailsState = {
  loading: false,
  executiveSearchRes: {} as ExecutiveSearchDetails,
  message: "",
  status: 0,
};
export const executiveSearchReducer = (
  state = initialStateGetPosts,
  action: ExecutiveSearchActionTypes,
): ExecutiveSearchDetailsState => {
  switch (action.type) {
    case EXECUTIVE_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        executiveSearchRes: {} as ExecutiveSearchDetails,
      };
    case EXECUTIVE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        executiveSearchRes: action.payload,
      };
    case EXECUTIVE_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        executiveSearchRes: {} as ExecutiveSearchDetails,
        message: action.message,
        status: action.status,
      };
    case EXECUTIVE_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        executiveSearchRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case EXECUTIVE_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        executiveSearchRes: {} as ExecutiveSearchDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
