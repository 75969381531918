import {
  GetJudgingSecondRoundDetailsListActionTypes,
  GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL,
  GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING,
  GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS,
  GetJudgingSecondRoundDetailsListState,
  GET_JUDGING_SECOND_ROUND_LIST_ID_API_MSG,
  GET_JUDGING_LIST_ID_API_RES,
} from "./ActionTypes";
import { GetJudgingSecondRoundDetailsListDetails } from "./Model";

const initialStateGetPosts: GetJudgingSecondRoundDetailsListState = {
  loading: false,
  getJudgingSecondRoundDetailsListRes: {} as GetJudgingSecondRoundDetailsListDetails,
  message: "",
  status: 0,
};
export const getJudgingSecondRoundDetailsListReducer = (
  state = initialStateGetPosts,
  action: GetJudgingSecondRoundDetailsListActionTypes,
): GetJudgingSecondRoundDetailsListState => {
  switch (action.type) {
    case GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING:
      return {
        ...state,
        loading: true,
        getJudgingSecondRoundDetailsListRes: {} as GetJudgingSecondRoundDetailsListDetails,
      };
    case GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getJudgingSecondRoundDetailsListRes: action.payload,
      };
    case GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL:
      return {
        ...state,
        loading: false,
        getJudgingSecondRoundDetailsListRes: {} as GetJudgingSecondRoundDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    case GET_JUDGING_SECOND_ROUND_LIST_ID_API_MSG:
      return {
        ...state,
        loading: false,
        getJudgingSecondRoundDetailsListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case GET_JUDGING_LIST_ID_API_RES:
      return {
        ...state,
        loading: false,
        getJudgingSecondRoundDetailsListRes: {} as GetJudgingSecondRoundDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
