import {
  AddCutoffMarksDetailsActionTypes,
  ADD_CUTOFF_MARKS_FAIL,
  ADD_CUTOFF_MARKS_LOADING,
  ADD_CUTOFF_MARKS_SUCCESS,
  AddCutoffMarksState,
  ADD_CUTOFF_MARKS_API_MSG,
  ADD_CUTOFF_MARKS_API_RES,
} from "./ActionTypes";
import { AddCutoffMarksDetailsRes } from "./Model";

const initialStateUpdatePosts: AddCutoffMarksState = {
  loading: false,
  previewCutoffMarksRes: {} as AddCutoffMarksDetailsRes,
  errorMsg: "",
  status: 0,
};
export const addCutoffMarksReducer = (
  state = initialStateUpdatePosts,
  action: AddCutoffMarksDetailsActionTypes,
): AddCutoffMarksState => {
  switch (action.type) {
    case ADD_CUTOFF_MARKS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_CUTOFF_MARKS_SUCCESS:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
      };
    case ADD_CUTOFF_MARKS_FAIL:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_CUTOFF_MARKS_API_MSG:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_CUTOFF_MARKS_API_RES:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
