import { EditInstanceDetails } from "./Model";

export const EDIT_INSTANCE_LOADING = "EDIT_INSTANCE_LOADING";
export const EDIT_INSTANCE_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const EDIT_INSTANCE_FAIL = "EDIT_INSTANCE_FAIL";
export const EDIT_INSTANCE_DATA = "EDIT_INSTANCE_DATA";
export const EDIT_INSTANCE_API_MSG = "EDIT_INSTANCE_API_MSG";
export const EDIT_INSTANCE_API_RES = "EDIT_INSTANCE_API_RES";

export interface EditInstanceState {
  editInstanceRes: EditInstanceDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditInstanceLoading {
  type: typeof EDIT_INSTANCE_LOADING;
  loading: boolean;
}
export interface EditInstanceSuccess {
  type: typeof EDIT_INSTANCE_SUCCESS;
  payload: EditInstanceDetails;
  successMsg: string;
}
export interface EditInstanceFail {
  type: typeof EDIT_INSTANCE_FAIL;
  payload: EditInstanceDetails;
  errorMsg: string;
  status: number;
}
export interface EditInstanceAPIMsg {
  type: typeof EDIT_INSTANCE_API_MSG;
  payload: EditInstanceDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditInstanceAPIRes {
  type: typeof EDIT_INSTANCE_API_RES;
  payload: EditInstanceDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditInstanceActionTypes =
  | EditInstanceLoading
  | EditInstanceSuccess
  | EditInstanceFail
  | EditInstanceAPIMsg
  | EditInstanceAPIRes;
