import { AdminTaskListResponse } from "./Model";

export const ADMIN_TASK_LIST_LOADING = "ADMIN_TASK_LIST_LOADING";
export const ADMIN_TASK_LIST_SUCCESS = "ADMIN_TASK_LIST_SUCCESS";
export const ADMIN_TASK_LIST_FAIL = "ADMIN_TASK_LIST_FAIL";
export const ADMIN_TASK_LIST_API_MSG = "ADMIN_TASK_LIST_API_MSG";
export const ADMIN_TASK_LIST_CLEAR_API_RES = "ADMIN_TASK_LIST_CLEAR_API_RES";

export interface AdminTaskDataState {
  adminTaskRes: AdminTaskListResponse;
  loading: boolean;
  message: string;
  status: number;
}

export interface AdminTaskListLoading {
  type: typeof ADMIN_TASK_LIST_LOADING;
  loading: boolean;
}
export interface AdminTaskListSuccess {
  type: typeof ADMIN_TASK_LIST_SUCCESS;
  payload: AdminTaskListResponse;
}
export interface AdminTaskListFail {
  type: typeof ADMIN_TASK_LIST_FAIL;
  payload: AdminTaskListResponse;
  message: string;
  status: number;
}
export interface AdminTaskAPIMsg {
  type: typeof ADMIN_TASK_LIST_API_MSG;
  payload: AdminTaskListResponse;
  message: string;
  status: number;
}
export interface AdminTaskAPIRes {
  type: typeof ADMIN_TASK_LIST_CLEAR_API_RES;
  payload: AdminTaskListResponse;
  message: string;
  status: number;
}

export type AdminTaskListActionTypes =
  | AdminTaskListLoading
  | AdminTaskListSuccess
  | AdminTaskListFail
  | AdminTaskAPIMsg
  | AdminTaskAPIRes;
