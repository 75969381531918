import { ToggleActiveRegReqRes } from "./Model";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_LOADING = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_LOADING";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_SUCCESS = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_SUCCESS";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_FAIL = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_FAIL";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_MSG = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_MSG";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_RES = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_RES";

export interface ToggleActiveRegistrationRequestState {
  toggleActiveRegReqList: ToggleActiveRegReqRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveRegistrationRequestLoading {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_LOADING;
  loading: boolean;
}
export interface ToggleActiveRegistrationRequestSuccess {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_SUCCESS;
  payload: ToggleActiveRegReqRes;
  successMsg: string;
}
export interface ToggleActiveRegistrationRequestFail {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_FAIL;
  payload: ToggleActiveRegReqRes;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveRegistrationRequestUpdateAPIMsg {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_MSG;
  payload: ToggleActiveRegReqRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveRegistrationRequestUpdateAPIRes {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_RES;
  payload: ToggleActiveRegReqRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ToggleActiveRegistrationRequestActionTypes =
  | ToggleActiveRegistrationRequestLoading
  | ToggleActiveRegistrationRequestSuccess
  | ToggleActiveRegistrationRequestFail
  | ToggleActiveRegistrationRequestUpdateAPIMsg
  | ToggleActiveRegistrationRequestUpdateAPIRes;
