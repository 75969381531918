import {
  REGISTER_PATIENT_FAIL,
  REGISTER_PATIENT_LOADING,
  REGISTER_PATIENT_SUCCESS,
  REGISTER_PATIENT_API_MSG,
  REGISTER_PATIENT_CLEAR_API_RES,
  PatientAddPointDataActionTypes,
} from "./ActionTypes";
import { AddPatientRes } from "./Model";

export const registerPatientLoadingAction = (loading: boolean): PatientAddPointDataActionTypes => {
  return {
    type: REGISTER_PATIENT_LOADING,
    loading: loading,
  };
};

export const registerPatientSuccessAction = (
  addScheduleResponse: AddPatientRes,
  successMsg: string,
): PatientAddPointDataActionTypes => {
  return {
    type: REGISTER_PATIENT_SUCCESS,
    payload: addScheduleResponse,
    successMsg: successMsg,
  };
};

export const registerPatientErrorAction = (
  addScheduleResponse: AddPatientRes,
  errMsg: string,
  status: number,
): PatientAddPointDataActionTypes => {
  return {
    type: REGISTER_PATIENT_FAIL,
    payload: addScheduleResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const registerPatientUpdateAPIMsgAction = (
  addScheduleResponse: AddPatientRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientAddPointDataActionTypes => {
  return {
    type: REGISTER_PATIENT_API_MSG,
    payload: addScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const registerPatientAPIResClearAction = (
  addScheduleResponse: AddPatientRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientAddPointDataActionTypes => {
  return {
    type: REGISTER_PATIENT_CLEAR_API_RES,
    payload: addScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
