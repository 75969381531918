import AppFrame from "component/AppFrame";
import { Box, Toolbar } from "@mui/material";
import SecureLS from "secure-ls";
import ComponentRouter from "./Routes";
function App() {
  var ls = new SecureLS({ encodingType: "aes" });
  const Token = ls.get("access");
  return (
    <Box sx={{ display: "flex" }}>
      <AppFrame />
      <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
        {Token ? <Toolbar /> : null}
        <ComponentRouter />
      </Box>
    </Box>
  );
}

export default App;
