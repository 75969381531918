import { GetPatientCaseDetailsByIdRes } from "./Model";
export const GET_PATIENT_CASE_DETAILS_BY_ID_LOADING = "GET_PATIENT_CASE_DETAILS_BY_ID_LOADING";
export const GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS = "GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS";
export const GET_PATIENT_CASE_DETAILS_BY_ID_FAIL = "GET_PATIENT_CASE_DETAILS_BY_ID_FAIL";
export const GET_PATIENT_CASE_DETAILS_BY_ID_DATA = "GET_PATIENT_CASE_DETAILS_BY_ID_DATA";
export const GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG =
  "GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG";
export const GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES =
  "GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES";

export interface GetPatientCaseDetailsByIdState {
  getPatientCaseDetailsByIdRes: GetPatientCaseDetailsByIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseDetailsByIdLoading {
  type: typeof GET_PATIENT_CASE_DETAILS_BY_ID_LOADING;
  loading: boolean;
}
export interface GetPatientCaseDetailsByIdSuccess {
  type: typeof GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS;
  payload: GetPatientCaseDetailsByIdRes;
}
export interface GetPatientCaseDetailsByIdFail {
  type: typeof GET_PATIENT_CASE_DETAILS_BY_ID_FAIL;
  payload: GetPatientCaseDetailsByIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseDetailsByIdUpdateAPIMsg {
  type: typeof GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG;
  payload: GetPatientCaseDetailsByIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseDetailsByIdUpdateAPIRes {
  type: typeof GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES;
  payload: GetPatientCaseDetailsByIdRes;
  errorMsg: string;
  status: number;
}

export type GetPatientCaseDetailsByIdActionTypes =
  | GetPatientCaseDetailsByIdLoading
  | GetPatientCaseDetailsByIdSuccess
  | GetPatientCaseDetailsByIdFail
  | GetPatientCaseDetailsByIdUpdateAPIMsg
  | GetPatientCaseDetailsByIdUpdateAPIRes;
