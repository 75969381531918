import {
  GET_PROVIDER_ORG_DETAILS_FAIL,
  GET_PROVIDER_ORG_DETAILS_LOADING,
  GET_PROVIDER_ORG_DETAILS_SUCCESS,
  GET_PROVIDER_ORG_DETAILS_API_MSG,
  GET_PROVIDER_ORG_DETAILS_API_RES,
  GetProviderOrgDetailsActionTypes,
} from "./ActionTypes";
import { GetProviderOrgDetails } from "./Model";

export const getProviderOrgDetailsLoadingAction = (
  loading: boolean,
): GetProviderOrgDetailsActionTypes => {
  return {
    type: GET_PROVIDER_ORG_DETAILS_LOADING,
    loading: loading,
  };
};

export const getProviderOrgDetailsSuccessAction = (
  getProviderOrgDetailsResponse: GetProviderOrgDetails,
  successMsg: string,
): GetProviderOrgDetailsActionTypes => {
  return {
    type: GET_PROVIDER_ORG_DETAILS_SUCCESS,
    payload: getProviderOrgDetailsResponse,
    successMsg: successMsg,
  };
};

export const getProviderOrgDetailsErrorAction = (
  getProviderOrgDetailsResponse: GetProviderOrgDetails,
  errMsg: string,
  status: number,
): GetProviderOrgDetailsActionTypes => {
  return {
    type: GET_PROVIDER_ORG_DETAILS_FAIL,
    payload: getProviderOrgDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getProviderOrgDetailsAPIMsgAction = (
  getProviderOrgDetailsResponse: GetProviderOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): GetProviderOrgDetailsActionTypes => {
  return {
    type: GET_PROVIDER_ORG_DETAILS_API_MSG,
    payload: getProviderOrgDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getProviderOrgDetailsAPIResClearAction = (
  getProviderOrgDetailsResponse: GetProviderOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): GetProviderOrgDetailsActionTypes => {
  return {
    type: GET_PROVIDER_ORG_DETAILS_API_RES,
    payload: getProviderOrgDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
