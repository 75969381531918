import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetCompetitionPatientListActionTypes } from "./ActionTypes";
import { GetCompetitionPatientListBody, GetCompetitionPatientListDetails } from "./Model";
import {
  GetCompitionPatientListAPIResClearAction,
  GetCompitionPatientListErrorAction,
  GetCompitionPatientListLoadingAction,
  GetCompitionPatientListSuccessAction,
  GetCompitionPatientListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetCompetitionPatientListDetails;
export const getPatientListByCompition = (
  compitionId: GetCompetitionPatientListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetCompetitionPatientListActionTypes>) {
    dispatch(GetCompitionPatientListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionAsStaff/competition_patient_list?page=${Number(
            pageNo,
          )}&size=50`,
        compitionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(GetCompitionPatientListLoadingAction(false));
        dispatch(GetCompitionPatientListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(GetCompitionPatientListLoadingAction(false));
        dispatch(
          GetCompitionPatientListErrorAction(
            {} as GetCompetitionPatientListDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const compitionPatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetCompetitionPatientListActionTypes>) {
    dispatch(
      GetCompitionPatientListAPIMsgAction(apiRes as GetCompetitionPatientListDetails, "", 0),
    );
  };
};

export const clearCompetitionPatientListAPIRes = () => {
  return function (dispatch: Dispatch<GetCompetitionPatientListActionTypes>) {
    dispatch(
      GetCompitionPatientListAPIResClearAction({} as GetCompetitionPatientListDetails, "", 0),
    );
  };
};
