import { PaymentList } from "./Model";

export const CORPORATE_PACKAGE_PAYMENT_LIST = 'CORPORATE_PACKAGE_PAYMENT_LIST';
export const CORPORATE_PACKAGE_PAYMENT_LIST_LOADING = "CORPORATE_PACKAGE_PAYMENT_LIST_LOADING";
export const CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS = "CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS";
export const CORPORATE_PACKAGE_PAYMENT_LIST_FAIL = "CORPORATE_PACKAGE_PAYMENT_LIST_FAIL";

// Get Patient List Under Patient Team
export interface GetCorporatePackagePaymentListStateType {
    paymentListValue: PaymentList[];
    loading: boolean;
    status: String;
}
interface CorporatePackagePaymentListActionType {
    type: typeof CORPORATE_PACKAGE_PAYMENT_LIST;
    payload: PaymentList[];
    status: String;
}
export interface CorporatePackagePaymentListLoading {
    type: typeof CORPORATE_PACKAGE_PAYMENT_LIST_LOADING;
}
export interface CorporatePackagePaymentListSuccess {
    type: typeof CORPORATE_PACKAGE_PAYMENT_LIST_SUCCESS;
    payload: PaymentList[];
    status: String;
}
export interface CorporatePackagePaymentListFail {
    type: typeof CORPORATE_PACKAGE_PAYMENT_LIST_FAIL;
    status: String;
}
export type CorporatePackagePaymentListTypes = CorporatePackagePaymentListActionType | CorporatePackagePaymentListLoading | CorporatePackagePaymentListSuccess | CorporatePackagePaymentListFail;