import {
  OrganizationListByHealthCampIdActionTypes,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS,
  OrganizationListByHealthCampIdDetailsState,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { OrganizationListMappedWithHealthCampDetails } from "./Model";

const initialStateGetPosts: OrganizationListByHealthCampIdDetailsState = {
  loading: false,
  patientSearchRes: {} as OrganizationListMappedWithHealthCampDetails,
  message: "",
  status: 0,
};
export const organizationListMappedWithHealthCampReducer = (
  state = initialStateGetPosts,
  action: OrganizationListByHealthCampIdActionTypes,
): OrganizationListByHealthCampIdDetailsState => {
  switch (action.type) {
    case ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING:
      return {
        ...state,
        loading: true,
        patientSearchRes: {} as OrganizationListMappedWithHealthCampDetails,
      };
    case ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        patientSearchRes: action.payload,
      };
    case ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL:
      return {
        ...state,
        loading: false,
        patientSearchRes: {} as OrganizationListMappedWithHealthCampDetails,
        message: action.message,
        status: action.status,
      };
    case ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        patientSearchRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        patientSearchRes: {} as OrganizationListMappedWithHealthCampDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
