import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { RegistrationRequestDetailsListActionTypes } from "./ActionTypes";
import { RegistrationRequestDetailsListBody, RegistrationRequestDetailsListDetails } from "./Model";
import {
  registrationRequestDetailsListAPIResClearAction,
  registrationRequestDetailsListFailAction,
  registrationRequestDetailsListLoadingAction,
  registrationRequestDetailsListSuccessAction,
  registrationRequestDetailsListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as RegistrationRequestDetailsListDetails;
export const registrationRequestDetailsListApiCall = (payload: RegistrationRequestDetailsListBody) => {
  return function (dispatch: Dispatch<RegistrationRequestDetailsListActionTypes>) {
    dispatch(registrationRequestDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampRegistrationDetail/registration_request_detail_list_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(registrationRequestDetailsListLoadingAction(false));
        dispatch(
          registrationRequestDetailsListSuccessAction(
            res.data !== undefined ? res.data : ({} as RegistrationRequestDetailsListDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Registration text list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(registrationRequestDetailsListLoadingAction(false));
        dispatch(
          registrationRequestDetailsListFailAction(
            {} as RegistrationRequestDetailsListDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateRegistrationRequestDetailsListAPIResMsg = () => {
  return function (dispatch: Dispatch<RegistrationRequestDetailsListActionTypes>) {
    dispatch(registrationRequestDetailsListAPIMsgAction(apiRes as RegistrationRequestDetailsListDetails, "", "", 0));
  };
};

export const clearRegistrationRequestDetailsListAPIRes = () => {
  return function (dispatch: Dispatch<RegistrationRequestDetailsListActionTypes>) {
    dispatch(registrationRequestDetailsListAPIResClearAction({} as RegistrationRequestDetailsListDetails, "", "", 0));
  };
};
