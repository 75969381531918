import {
  GET_DOCTOR_SCHEDULE_LIST_FAIL,
  GET_DOCTOR_SCHEDULE_LIST_LOADING,
  GET_DOCTOR_SCHEDULE_LIST_SUCCESS,
  GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_MSG,
  GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_RES,
  GetDoctorWorkScheduleListActionTypes,
} from "./ActionTypes";
import { GetDoctorWorkScheduleListRes } from "./Model";

export const getDoctorWorkScheduleListLoadingAction = (
  loading: boolean
): GetDoctorWorkScheduleListActionTypes => {
  return {
    type: GET_DOCTOR_SCHEDULE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorWorkScheduleListSuccessAction = (
  getDoctorWorkScheduleListResponse: GetDoctorWorkScheduleListRes,
  successMsg: string
): GetDoctorWorkScheduleListActionTypes => {
  return {
    type: GET_DOCTOR_SCHEDULE_LIST_SUCCESS,
    payload: getDoctorWorkScheduleListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorWorkScheduleListErrorAction = (
  getDoctorWorkScheduleListResponse: GetDoctorWorkScheduleListRes,
  errMsg: string,
  status: number
): GetDoctorWorkScheduleListActionTypes => {
  return {
    type: GET_DOCTOR_SCHEDULE_LIST_FAIL,
    payload: getDoctorWorkScheduleListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorWorkScheduleListUpdateAPIMsgAction = (
  getDoctorWorkScheduleListResponse: GetDoctorWorkScheduleListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorWorkScheduleListActionTypes => {
  return {
    type: GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_MSG,
    payload: getDoctorWorkScheduleListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorWorkScheduleListAPIResClearAction = (
  getDoctorWorkScheduleListResponse: GetDoctorWorkScheduleListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorWorkScheduleListActionTypes => {
  return {
    type: GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_RES,
    payload: getDoctorWorkScheduleListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
