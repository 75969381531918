import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetDataCurationProblemListActionTypes } from "./ActionTypes";
import { GetDataCurationProblemListBody, GetDataCurationProblemListRes } from "./Model";
import {
  getDataCurationProblemListAPIResClearAction,
  getDataCurationProblemListErrorAction,
  getDataCurationProblemListLoadingAction,
  getDataCurationProblemListSuccessAction,
  getDataCurationProblemListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDataCurationProblemListRes;
export const getDataCurationProblemListApi = (
  payload: GetDataCurationProblemListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetDataCurationProblemListActionTypes>) {
    dispatch(getDataCurationProblemListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/curatorapp/api/dataCurationProblem/data_Curation_Problem_list?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDataCurationProblemListLoadingAction(false));
        dispatch(
          getDataCurationProblemListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetDataCurationProblemListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDataCurationProblemListLoadingAction(false));
        dispatch(
          getDataCurationProblemListErrorAction(
            {} as GetDataCurationProblemListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetDataCurationProblemListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDataCurationProblemListActionTypes>) {
    dispatch(
      getDataCurationProblemListUpdateAPIMsgAction(
        apiRes as GetDataCurationProblemListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetDataCurationProblemListAPIRes = () => {
  return function (dispatch: Dispatch<GetDataCurationProblemListActionTypes>) {
    dispatch(
      getDataCurationProblemListAPIResClearAction({} as GetDataCurationProblemListRes, "", "", 0),
    );
  };
};
