import {
  DeactiveStaffOfHealthCampActionTypes,
  DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL,
  DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING,
  DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS,
  DeactiveStaffOfHealthcampState,
  DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG,
  DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES,
} from "./ActionTypes";
import { DeactiveStaffOfHealthCampDetails } from "./Model";

const initialStateGetPosts: DeactiveStaffOfHealthcampState = {
  loading: false,
  deactiveStaffOfHealthcampRes: {} as DeactiveStaffOfHealthCampDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const deactiveStaffOfHealthcampReducer = (
  state = initialStateGetPosts,
  action: DeactiveStaffOfHealthCampActionTypes,
): DeactiveStaffOfHealthcampState => {
  switch (action.type) {
    case DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS:
      return {
        ...state,
        deactiveStaffOfHealthcampRes: action.payload,
        successMsg: action.successMsg,
      };
    case DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL:
      return {
        ...state,
        deactiveStaffOfHealthcampRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG:
      return {
        ...state,
        deactiveStaffOfHealthcampRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES:
      return {
        ...state,
        deactiveStaffOfHealthcampRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
