import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddExistingPatientCaseToSessionActionTypes } from "./ActionTypes";
import { AddExistingPatientCaseToSessionBody, AddExistingPatientCaseToSessionDetails } from "./Model";
import {
  addExistingPatientCaseToSessionAPIResClearAction,
  addExistingPatientCaseToSessionErrorAction,
  addExistingPatientCaseToSessionLoadingAction,
  addExistingPatientCaseToSessionSuccessAction,
  addExistingPatientCaseToSessionAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddExistingPatientCaseToSessionDetails;
export const addExistingPatientCaseToSessionApiCall = (payload: AddExistingPatientCaseToSessionBody) => {
  return function (dispatch: Dispatch<AddExistingPatientCaseToSessionActionTypes>) {
    dispatch(addExistingPatientCaseToSessionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampsessionpatientdetails/add_existing_patientCase_to_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addExistingPatientCaseToSessionLoadingAction(false));
        dispatch(
          addExistingPatientCaseToSessionSuccessAction(
            res.data !== undefined ? res.data : ({} as AddExistingPatientCaseToSessionDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addExistingPatientCaseToSessionLoadingAction(false));
        dispatch(
          addExistingPatientCaseToSessionErrorAction(
            {} as AddExistingPatientCaseToSessionDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddExistingPatientCaseToSessionAPIResMsg = () => {
  return function (dispatch: Dispatch<AddExistingPatientCaseToSessionActionTypes>) {
    dispatch(addExistingPatientCaseToSessionAPIMsgAction(apiRes as AddExistingPatientCaseToSessionDetails, "", "", 0));
  };
};

export const clearAddExistingPatientCaseToSessionAPIRes = () => {
  return function (dispatch: Dispatch<AddExistingPatientCaseToSessionActionTypes>) {
    dispatch(addExistingPatientCaseToSessionAPIResClearAction({} as AddExistingPatientCaseToSessionDetails, "", "", 0));
  };
};
