import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleIcon from "@mui/icons-material/People";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import SettingsIcon from "@mui/icons-material/Settings";

export const DrawerList = [
  {
    name: "Back to Home",
    path: "/dashboard",
    icon: <KeyboardBackspaceIcon />,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    name: "Patient Organization List",
    path: "/organizationlist",
    icon: <AddHomeWorkIcon />,
  },
  {
    name: "Healthcamp List",
    path: "/healthcamplist",
    icon: <FavoriteIcon />,
  },
  {
    name: "Competition List",
    path: "/competitionlist",
    icon: <Diversity3Icon />,
  },
  {
    name: "Corporate Packages",
    path: "/corporatepackages",
    icon: <AccountBalanceIcon />,
  },
  {
    name: "Doctor List",
    path: "/doctors",
    icon: <PersonAddIcon />,
  },
  {
    name: "Patient Team List",
    path: "/patientteam",
    icon: <GroupAddIcon />,
  },
  {
    name: "Patient List",
    path: "/patients",
    icon: <PeopleAltIcon />,
  },
  {
    name: "Patient Case List",
    path: "/allcaselist",
    icon: <PeopleIcon />,
  },
  {
    name: "Doctor On-Boarding Requests",
    path: "/doctoronboardingrequests",
    icon: <VisibilityIcon />,
  },
  {
    name: "Executive List",
    path: "/executive",
    icon: <SupervisorAccountIcon />,
  },
  {
    name: "Provider Organization List",
    path: "/providerorganizationlist",
    icon: <ApartmentIcon />,
  },
  {
    name: "Appointment List",
    path: "/appointmentlist",
    icon: <DisplaySettingsIcon />,
  },
  {
    name: "Patient Queries",
    path: "/patient-queries",
    icon: <PersonSearchIcon />,
  },
  {
    name: "Doctor Queries",
    path: "/doctor-queries",
    icon: <ZoomInIcon />,
  },
  {
    name: "Payment",
    path: "/payment",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    name: "Patient Details",
    path: "/unprocessesedpatientdetails",
    icon: <SyncProblemIcon />,
  },
  {
    name: "Data Management",
    path: "/data-management",
    icon: <ImportExportIcon />,
  },
  {
    name: "Task",
    path: "/task",
    icon: <NoteAltIcon />,
  },
  {
    name: "Question & Answer",
    path: "/question-answer",
    icon: <QuestionAnswerIcon />,
  },
  {
    name: "Telehealth",
    path: "/telehealth",
    icon: <PhoneIcon />,
  },
  {
    name: "Preference",
    path: "/preference",
    icon: <RoomPreferencesIcon />,
  },
  {
    name: "User Profile",
    path: "/admindetails",
    icon: <AccountCircleIcon />,
  },
  {
    name: "Settings",
    path: "/admindetails",
    icon: <SettingsIcon />,
  },
  {
    name: "Sign out",
    path: "/dashboard",
    icon: <LogoutIcon />,
  },
];
