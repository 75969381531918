import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddDataCurationProblemActionTypes } from "./ActionTypes";
import { AddDataCurationProblemBody, AddDataCurationProblemDetails } from "./Model";
import {
  addDataCurationProblemAPIResClearAction,
  addDataCurationProblemErrorAction,
  addDataCurationProblemLoadingAction,
  addDataCurationProblemSuccessAction,
  addDataCurationProblemAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddDataCurationProblemDetails;
export const addDataCurationProblemApiCall = (payload: AddDataCurationProblemBody) => {
  return function (dispatch: Dispatch<AddDataCurationProblemActionTypes>) {
    dispatch(addDataCurationProblemLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/curatorapp/api/dataCurationProblem/add_data_Curation_Problem`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDataCurationProblemLoadingAction(false));
        dispatch(
          addDataCurationProblemSuccessAction(
            res.data !== undefined ? res.data : ({} as AddDataCurationProblemDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addDataCurationProblemLoadingAction(false));
        dispatch(
          addDataCurationProblemErrorAction(
            {} as AddDataCurationProblemDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddDataCurationProblemAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDataCurationProblemActionTypes>) {
    dispatch(addDataCurationProblemAPIMsgAction(apiRes as AddDataCurationProblemDetails, "", "", 0));
  };
};

export const clearAddDataCurationProblemAPIRes = () => {
  return function (dispatch: Dispatch<AddDataCurationProblemActionTypes>) {
    dispatch(addDataCurationProblemAPIResClearAction({} as AddDataCurationProblemDetails, "", "", 0));
  };
};
