import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddCutoffMarksDetailsActionTypes } from "./ActionTypes";
import { AddCutoffMarksDetailsBody, AddCutoffMarksDetailsRes } from "./Model";
import {
  addCutoffMarksDetailsAPIResClearAction,
  addCutoffMarksDetailsErrorAction,
  addCutoffMarksDetailsLoadingAction,
  addCutoffMarksDetailsSuccessAction,
  addCutoffMarksDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddCutoffMarksDetailsRes;
export const addCutoffMarksApi = (
  payload: AddCutoffMarksDetailsBody,
) => {
  return function (dispatch: Dispatch<AddCutoffMarksDetailsActionTypes>) {
    dispatch(addCutoffMarksDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/competitionapp/api/competitionSecondRound/addPatientDetailsToNextRoundByCutoff`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addCutoffMarksDetailsLoadingAction(false));
        dispatch(addCutoffMarksDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(addCutoffMarksDetailsLoadingAction(false));
        dispatch(
          addCutoffMarksDetailsErrorAction(
            {} as AddCutoffMarksDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const addCutoffMarksAPIResMsg = () => {
  return function (dispatch: Dispatch<AddCutoffMarksDetailsActionTypes>) {
    dispatch(
      addCutoffMarksDetailsAPIMsgAction(
        apiRes as AddCutoffMarksDetailsRes,
        "",
        0,
      ),
    );
  };
};

export const clearAddCutoffMarksAPIRes = () => {
  return function (dispatch: Dispatch<AddCutoffMarksDetailsActionTypes>) {
    dispatch(
      addCutoffMarksDetailsAPIResClearAction(
        {} as AddCutoffMarksDetailsRes,
        "",
        0,
      ),
    );
  };
};
