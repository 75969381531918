import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useHistory, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWSnackbar from "component/MWSnackbar";
import MWExceptionList from "component/MWExceptionList";
import PageLayout from "pages/Layout/PageLayout";
import MWPageTitle from "component/MWPageTitle";
import { getOrganizationRegistrationRequestList } from "redux/OrganizationRegistrationRequestList/API";
import { GetOrganizationRegistrationRequestListBody } from "redux/OrganizationRegistrationRequestList/Model";
import { AppState } from "redux/store/Store";
import dayjs from "dayjs";
import { PatientOrganizationList } from "redux/OrganizationList/Model";
import { getOrganizationListValue } from "redux/OrganizationList/API";
import _ from "lodash";
import AdminLoader from "pages/AdminLoader";
import {
  clearToggleActiveOrgRegistrationRequestAPIRes,
  toggleActiveOrganizationRegistrationRequest,
} from "redux/ToggleActiveOrganizationRegistrationRequest/API";
import { ToggleActiveOrgRegReqBody } from "redux/ToggleActiveOrganizationRegistrationRequest/Model";

export default function OrganizationRegistrationRequestList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const OrganizationRegistrationReqList = useSelector(
    (state: AppState) => state.getOrganizationRegistrationRequestListValues,
  );
  const ToggleActiveOrgRegistrationRequestStoreVal = useSelector(
    (state: AppState) => state.toggleActiveOrgRegistrationRequestValue,
  );
  const { organizationid } = useParams() as {
    organizationid: string;
  };

  const registrationReqDetailsList = (rowIndex: any) => {
    history.push(`/organizationregistrationrequestdetails/${organizationid}/${rowIndex}`);
  };
  const [organizationReqListValues, setOrganizationReqListValues] = useState([] as any[]);

  useEffect(() => {
    if (
      OrganizationRegistrationReqList?.patientOrgnizationRegistrationRequestList
        ?.patientOrgnizationRegistrationRequestList !== undefined
    ) {
      let regRequestList =
        OrganizationRegistrationReqList?.patientOrgnizationRegistrationRequestList?.patientOrgnizationRegistrationRequestList.map(
          (element: any) => ({
            id: element.id,
            itemId: element.id,
            displayName: element?.displayName !== "" ? element?.displayName : "",
            dateCreated: dayjs(element.dateCreated).format("ddd, MMM D, YYYY"),
            ownerFirstName: element?.ownerFirstName !== "" ? element?.ownerFirstName : "",
            patientOrganizationId:
              element?.patientOrganizationId !== null ? element?.patientOrganizationId : "",
            patientOrganizationDisplayName:
              element?.patientOrganizationDisplayName !== ""
                ? element?.patientOrganizationDisplayName
                : "",
          }),
        );
      setOrganizationReqListValues(regRequestList);
    }
  }, [OrganizationRegistrationReqList]);

  const OrganizationList = useSelector((state: AppState) => state.organizationListValue);
  const organizationValues: PatientOrganizationList | undefined = _.find(
    OrganizationList?.getOrganizationRes?.patientOrganizationList,
    ["id", Number(organizationid)],
  );

  useEffect(() => {
    if (organizationValues === undefined) {
      dispatch(getOrganizationListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationValues]);

  useEffect(() => {
    if (ToggleActiveOrgRegistrationRequestStoreVal?.successMsg !== undefined) {
      setToggleActiveRegReqMsgType("success");
      setToggleActiveRegReqMsg(ToggleActiveOrgRegistrationRequestStoreVal?.successMsg);
      dispatch(
        getOrganizationRegistrationRequestList({
          organizationId: organizationid,
        } as GetOrganizationRegistrationRequestListBody),
      );
    }
    if (ToggleActiveOrgRegistrationRequestStoreVal?.errorMsg !== "") {
      setToggleActiveRegReqMsgType("error");
      setToggleActiveRegReqMsg(ToggleActiveOrgRegistrationRequestStoreVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ToggleActiveOrgRegistrationRequestStoreVal]);

  const requestList: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            registrationReqDetailsList(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "itemId",
      headerName: "ID",
      valueGetter: ({ id }) => {
        const item = organizationReqListValues.find((item) => item.id === id);
        return item.id;
      },
      width: 120,
    },
    {
      field: "displayName",
      headerName: "Display Name",
      width: 420,
      flex: 1,
    },
    { field: "ownerFirstName", headerName: "Owner First Name", width: 150 },
    {
      field: "dateCreated",
      headerName: "Create Date",
      width: 150,
    },
    {
      field: "status",
      headerName: "Request Status",
      sortable: true,
      hideable: false,
      minWidth: 200,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(
              toggleActiveOrganizationRegistrationRequest({
                patientOrgRegistrationRequestId: params.row.id,
              } as ToggleActiveOrgRegReqBody),
            );
          }}
        >
          Deactive
        </Button>
      ),
    },
  ];

  const toggleActiveClose = () => {
    dispatch(clearToggleActiveOrgRegistrationRequestAPIRes());
    setToggleActiveRegReqMsg("");
  };
  const [toggleActiveRegReqMsg, setToggleActiveRegReqMsg] = useState("");
  const [toggleActiveRegReqMsgType, setToggleActiveRegReqMsgType] = useState<AlertColor>("success");

  const reloadRegReqList = () => {
    dispatch(
      getOrganizationRegistrationRequestList({
        organizationId: organizationid,
      } as GetOrganizationRegistrationRequestListBody),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getOrganizationRegistrationRequestList({
        organizationId: organizationid,
      } as GetOrganizationRegistrationRequestListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6"> Request List</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={
                      OrganizationRegistrationReqList?.patientOrgnizationRegistrationRequestList
                        ?.patientOrgnizationRegistrationRequestList !== undefined
                        ? String(
                            OrganizationRegistrationReqList
                              ?.patientOrgnizationRegistrationRequestList
                              ?.patientOrgnizationRegistrationRequestList.length,
                          )
                        : "0"
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item mt={1}>
                  <RefreshIcon
                    onClick={() => {
                      reloadRegReqList();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {organizationReqListValues && organizationReqListValues.length ? (
          <DataGrid
            rows={organizationReqListValues}
            columns={requestList}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {OrganizationRegistrationReqList.loading ? <AdminLoader /> : null}
      <PageLayout>
        <MWPageTitle
          title={
            organizationValues?.displayName !== undefined
              ? organizationValues?.displayName + " Registration Request List"
              : ""
          }
          backButton={false}
          customRoute={`/patientregistration/${organizationid}`}
        />
        {actualPageMarkup}
      </PageLayout>
      {toggleActiveRegReqMsg !== "" ? (
        <MWSnackbar
          msg={toggleActiveRegReqMsg}
          type={toggleActiveRegReqMsgType}
          alertClose={toggleActiveClose}
        />
      ) : null}
    </Box>
  );
}
