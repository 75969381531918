import {
    GetPatientDetailsTypeOfSessionListActionTypes,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS,
    GetPatientDetailsTypeOfSessionListState,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES,
  } from "./ActionTypes";
  import { GetPatientDetailsTypeOfSessionDetails } from "./Model";
  
  const initialStateGetPosts: GetPatientDetailsTypeOfSessionListState = {
    loading: false,
    getPatientDetailsTypeofSessionListRes: {} as GetPatientDetailsTypeOfSessionDetails,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const getPatientDetailsTypeOfSessionListReducer = (
    state = initialStateGetPosts,
    action: GetPatientDetailsTypeOfSessionListActionTypes,
  ): GetPatientDetailsTypeOfSessionListState => {
    switch (action.type) {
      case GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS:
        return {
          ...state,
          getPatientDetailsTypeofSessionListRes: action.payload,
          successMsg: action.successMsg,
        };
      case GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL:
        return {
          ...state,
          getPatientDetailsTypeofSessionListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG:
        return {
          ...state,
          getPatientDetailsTypeofSessionListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES:
        return {
          ...state,
          getPatientDetailsTypeofSessionListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  