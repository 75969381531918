import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect, useState } from "react";
import PatientListBySessionId from "./PatientListBySessionId";
import { GetPatientListBySessionIdBody } from "redux/HealthcampSessionList/PatientListBySessionId/Model";
import { getPatientListBySessionIdApi } from "redux/HealthcampSessionList/PatientListBySessionId/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { GetSessionListBody } from "redux/HealthcampSessionList/Model";
import _ from "lodash";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { HealthCampList } from "redux/healthcampList/Model";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import AdminLoader from "pages/AdminLoader";
import MWPageTitle from "component/MWPageTitle";
import SessionManagement from "./SessionManagement";
import { GetPatientDetailsTypeOfSessionBody } from "redux/GetPatientDetailsTypeOfSession/Model";
import { getPatientDetailsTypeOfSessionList } from "redux/GetPatientDetailsTypeOfSession/API";
import SessionReport from "./SessionReport";
import { InactiveVitalCollectionListBody } from "redux/InactiveVitalCollectionStatus/Model";
import { inactiveVitalCollectionList } from "redux/InactiveVitalCollectionStatus/API";
import PatientDetailsListBySessionId from "./PatientDetailsListBySessionId";
import { GetSessionPatientDetailsListBody } from "redux/HealthcampSessionList/SessionPatientDetailsList/Model";
import { getSessionPatientDetailsListApi } from "redux/HealthcampSessionList/SessionPatientDetailsList/API";
import { clearAPIRes } from "redux/PatientSearchByHealcampId/API";
import { clearSearchAPIRes } from "redux/PatientSearchApi/API";
import { GetHealthCampSessionReportBody } from "redux/HealthCampSessionReportList/Model";
import { getReportByHealthCampSession } from "redux/HealthCampSessionReportList/API";
import PatientObservationListBySessionId from "./PatientObservationListBySessionId/Index";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function InstanceSessionDetails() {
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  // call Store
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const GetPatientDetailsTypeSessionListValue = useSelector(
    (state: AppState) => state.getPatientDetailsTypeOfSessionList,
  );
  const [patientListSessionIdLoader, setPatientListSessionIdLoader] = useState(false);
  const [patientListCount, setPatientListCount] = useState("");
  const changePtCount = (value: string) => {
    setPatientListCount(value);
  };
  const [sessionManagementListCount, setSessionManagementListCount] = useState("");
  const [selected, setSelected] = React.useState(0);
  const [sessionManagementListLoader, setSessionManagementListLoader] = useState(false);
  const sessionValues: any | undefined = _.find(
    sessionListResponseValue?.getSessionList?.healthcampSessionList,
    ["id", Number(sessionId)],
  );

  const healthcampValues: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );
  const instanceValues: HealthcampInstanceList | undefined = _.find(
    instanceListResponseValue.getInstanceList?.healthcampInstanceList,
    ["id", Number(instanceHealthcampId)],
  );
  useEffect(() => {
    GetPatientDetailsTypeSessionListValue?.getPatientDetailsTypeofSessionListRes
      ?.mandatoryPatientDeatilTypeList !== undefined
      ? setSessionManagementListCount(
          String(
            GetPatientDetailsTypeSessionListValue?.getPatientDetailsTypeofSessionListRes
              ?.mandatoryPatientDeatilTypeList.length,
          ),
        )
      : setSessionManagementListCount("0");
  }, [GetPatientDetailsTypeSessionListValue]);
  useEffect(() => {
    if (sessionValues === undefined) {
      const healthcampSessionListBody = {
        healthcampInstanceId: instanceHealthcampId,
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionValues]);

  useEffect(() => {
    if (instanceValues === undefined) {
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceValues]);

  useEffect(() => {
    if (healthcampValues === undefined) {
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampValues]);
  const sessionListApi = () => {
    let apiBody = {
      healthcampSessionId: sessionId,
    } as GetPatientListBySessionIdBody;
    dispatch(getPatientListBySessionIdApi(apiBody, 1));
  };
  const sessionManagementListApi = () => {
    dispatch(
      getPatientDetailsTypeOfSessionList({
        healthcampSessionId: sessionId,
      } as GetPatientDetailsTypeOfSessionBody),
    );
  };
  const [tabValue, setTabValue] = useState(0);
  const [SessionReportListLoading, setSessionReportListLoading] = useState(false);
  const changeSessionReportListLoadingVal = (value: boolean) => {
    setSessionReportListLoading(value);
  };

  const [sessionPatientDetailsListLoading, setSessionPatientDetailsListLoading] = useState(false);
  const changeSessionPatientDetailsListLoadingVal = (value: boolean) => {
    setSessionPatientDetailsListLoading(value);
  };

  const [sessionPatientDetailsListCount, setViewSessionPaientDetailsListCount] = React.useState("");
  const changeSessionPatientDetailsListCount = (value: string) => {
    setViewSessionPaientDetailsListCount(value);
  };

  const [sessionObservationListLoading, setSessionObservationListLoading] = useState(false);
  const changeSessionObservationListLoadingVal = (value: boolean) => {
    setSessionObservationListLoading(value);
  };

  const [sessionObservationListCount, setSessionObservationListCount] = React.useState("");
  const changeSessionObservationListCount = (value: string) => {
    setSessionObservationListCount(value);
  };

  const sessionPatientDetailsListApi = () => {
    let apiBody = {
      healthcampSessionId: sessionId,
    } as GetSessionPatientDetailsListBody;
    dispatch(getSessionPatientDetailsListApi(apiBody, 1));
  };
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=patientList"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=session-management"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=report"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=patient-details-list"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=patient-observation-list"}`);
    }
  };

  const handleClick = () => {
    history.push(`/patientregistration/${healthcampid}`);
    dispatch(clearAPIRes());
    dispatch(clearSearchAPIRes());
  };

  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "patientList") {
      setTabValue(0);
    } else if (tabId === "session-management") {
      setTabValue(1);
    } else if (tabId === "report") {
      setTabValue(2);
    } else if (tabId === "patient-details-list") {
      setTabValue(3);
    } else if (tabId === "patient-observation-list") {
      setTabValue(4);
    }
    // eslint-disable-next-line
  }, [tabId]);

  const StaffListOfHealthCamp = (
    <Card>
      <CardContent>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient List of Session</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={patientListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    sessionListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Session Management</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={sessionManagementListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    sessionManagementListApi();
                    dispatch(
                      inactiveVitalCollectionList({
                        healthcampSessionId: Number(sessionId),
                      } as InactiveVitalCollectionListBody),
                    );
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Report</Typography>
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    dispatch(
                      getReportByHealthCampSession({
                        healthcampSessionId: sessionId,
                      } as GetHealthCampSessionReportBody),
                    );
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient Details List of Session</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={sessionPatientDetailsListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    sessionPatientDetailsListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(3)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Observation List Of Session</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={sessionObservationListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    sessionPatientDetailsListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(4)}
            />
          </Tabs>
          <Divider />
          <TabPanel value={tabValue} index={0}>
            <PatientListBySessionId
              patientListCount={changePtCount}
              patientListLoader={setPatientListSessionIdLoader}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SessionManagement sessionManagementListLoader={setSessionManagementListLoader} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <SessionReport reportingLoader={changeSessionReportListLoadingVal} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <PatientDetailsListBySessionId
              patientDetailsLoader={changeSessionPatientDetailsListLoadingVal}
              count={changeSessionPatientDetailsListCount}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <PatientObservationListBySessionId
              observationDetailsLoader={changeSessionObservationListLoadingVal}
              count={changeSessionObservationListCount}
            />
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {patientListSessionIdLoader ||
      sessionManagementListLoader ||
      SessionReportListLoading ||
      sessionPatientDetailsListLoading ||
      sessionObservationListLoading ? (
        <AdminLoader />
      ) : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <MWPageTitle backButton={true} />
                </Grid>
                <Grid item>
                  <Breadcrumbs aria-label="breadcrumb">
                    {healthcampValues?.displayName !== undefined ? (
                      <Link
                        color="inherit"
                        to={`/patientlistbyhealthcamp/${healthcampid}?section=instanceList`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography>
                          {healthcampValues?.displayName!.slice(0, 26) +
                            (healthcampValues?.displayName.length > 26
                              ? "... " + healthcampValues?.displayName!.slice(-10)
                              : "")}
                        </Typography>
                      </Link>
                    ) : null}

                    {instanceValues?.displayName !== undefined ? (
                      <Link
                        color="inherit"
                        to={`/healthcampinstance/${healthcampid}/${instanceHealthcampId}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography>
                          {instanceValues?.displayName!.slice(0, 26) +
                            (instanceValues?.displayName.length > 26
                              ? "... " + instanceValues?.displayName!.slice(-10)
                              : "")}
                        </Typography>
                      </Link>
                    ) : null}
                    {sessionValues?.displayName !== undefined ? (
                      <Link
                        color="inherit"
                        to={`/healthcampinstance_session/${healthcampid}/${instanceHealthcampId}/${sessionId}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography color="text.primary">
                          {sessionValues?.displayName!.slice(0, 26) +
                            (sessionValues?.displayName.length > 26
                              ? "... " + sessionValues?.displayName!.slice(-10)
                              : "")}
                        </Typography>
                      </Link>
                    ) : null}
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button onClick={handleClick} variant="contained">
                Registration
              </Button>
            </Grid>
          </Grid>
          {StaffListOfHealthCamp}
        </Stack>
      </Container>
    </Box>
  );
}
