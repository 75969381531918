import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { Box } from "@mui/material";
import { TempData } from "../Model";

type Props = {
  data?: TempData | any;
  unit?: string | any;
};

export default function LeanBodyMassTrends({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as TempData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);
  useEffect(() => {
    let root = am5.Root.new("leanBodyMasschart");
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
      }),
    );

    // Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        min: 0,
        max: 100,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30,
        }),
      }),
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: unitt, count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.2,
          cellEndLocation: 0.8,
        }),
      }),
    );

    // Create series
    function createSeries(name: any, field: any) {
      var series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {}),
          //clustered: true
        }),
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
      });

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      series
        .get("tooltip")!
        .label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: [bold]{valueY}[/]");
      series.data.setAll(chartData);

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 6,
            fill: am5.color("#1fbef2"),
            stroke: am5.color("#1fbef2"),
            strokeWidth: 2,
          }),
        });
      });

      return series;
    }

    var series1 = createSeries("Lean Body Mass", "value");

    // Create axis ranges
    function createRange(series: any, value: any, endValue: any, color: any, dashed: any) {
      var rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue,
      });

      var range = series.createAxisRange(rangeDataItem);

      range.strokes.template.setAll({
        stroke: color,
        strokeWidth: 3,
      });

      range.fills.template.setAll({
        fill: color,
        fillOpacity: 0.8,
        visible: true,
      });

      if (endValue) {
        rangeDataItem.get("axisFill")!.setAll({
          fill: color,
          fillOpacity: 0.2,
          visible: true,
        });
      } else {
        // rangeDataItem.get("grid")!.setAll({
        //   stroke: color,
        //   strokeOpacity: 1,
        //   strokeWidth: 2,
        //   location: 1,
        // });

        if (dashed) {
          rangeDataItem.get("grid")!.set("strokeDasharray", [5, 3]);
        }
      }
    }

    createRange(series1, 26, 44, am5.color("#55943a"), true);
    createRange(series1, 20, 26, am5.color("#f2ac33"), false);
    createRange(series1, 44, 100, am5.color("#f24d1f"), false);
    createRange(series1, 0, 20, am5.color("#303030"), false);

    // Add cursor
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
        xAxis: xAxis,
      }),
    );

    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      }),
    );

    yAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      }),
    );
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    //series.appear(1000, 100);
    chart.appear(1000, 100);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unitt]);

  return (
    <Box id="leanBodyMasschart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />
  );
}
