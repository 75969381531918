import axios from "axios";
import { Dispatch } from "react";
import Url from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { getPatientTeamListAction } from "./Actions";
import {
  AdminGetPatientTeamListActionTypes,
  ALL_PATIENT_TEAM_FAIL,
  ALL_PATIENT_TEAM_LOADING,
  ALL_PATIENT_TEAM_SUCCESS,
} from "./ActionTypes";
import { headers } from "../../component/Utility";

// Admin get Patient Team List API
export const getPatientTeamList = (pageNo: any) => {
  return function (dispatch: Dispatch<AdminGetPatientTeamListActionTypes>) {
    dispatch({
      type: ALL_PATIENT_TEAM_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/adminapp/api/patientTeam/patient_team_list_all?page=${Number(pageNo)}&size=50`,
        { headers: headers },
      )
      .then((res) => {
        let patientTeamList = res.data;
        let patientTeamPageCount = res.data.numberOfPages;
        let patientTeamCurrentPageCount = res.data.current_page_no;
        dispatch(getPatientTeamListAction(patientTeamList, patientTeamPageCount));
        dispatch({
          type: ALL_PATIENT_TEAM_SUCCESS,
          payload: patientTeamList,
          patientListPageCount: patientTeamPageCount,
          patientTeamCurrentPageCount: patientTeamCurrentPageCount,
        });
      })
      .catch((_error) => {
        dispatch({
          type: ALL_PATIENT_TEAM_FAIL,
        });
      });
  };
};
