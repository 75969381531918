import { ResponseTimeTypeResponse } from "./Model";
export const RESPONSE_TIME_TYPE_LOADING = "RESPONSE_TIME_TYPE_LOADING";
export const RESPONSE_TIME_TYPE_SUCCESS = "RESPONSE_TIME_TYPE_SUCCESS";
export const RESPONSE_TIME_TYPE_FAIL = "RESPONSE_TIME_TYPE_FAIL";
export const RESPONSE_TIME_TYPE_DATA = "RESPONSE_TIME_TYPE_DATA";
export const RESPONSE_TIME_TYPE_API_MSG = "RESPONSE_TIME_TYPE_API_MSG";
export const RESPONSE_TIME_TYPE_API_RES = "RESPONSE_TIME_TYPE_API_RES";

export interface ResponseTimeTypeState {
  responseTimeTypeRes: ResponseTimeTypeResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ResponseTimeTypeLoading {
  type: typeof RESPONSE_TIME_TYPE_LOADING;
  loading: boolean;
}
export interface ResponseTimeTypeSuccess {
  type: typeof RESPONSE_TIME_TYPE_SUCCESS;
  payload: ResponseTimeTypeResponse;
  successMsg: string;
  status: number;
}
export interface ResponseTimeTypeFail {
  type: typeof RESPONSE_TIME_TYPE_FAIL;
  payload: ResponseTimeTypeResponse;
  errorMsg: string;
  status: number;
}
export interface ResponseTimeTypeUpdateAPIMsg {
  type: typeof RESPONSE_TIME_TYPE_API_MSG;
  payload: ResponseTimeTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ResponseTimeTypeUpdateAPIRes {
  type: typeof RESPONSE_TIME_TYPE_API_RES;
  payload: ResponseTimeTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface ResponseTimeTypeAction {
  type: typeof RESPONSE_TIME_TYPE_DATA;
  payload: ResponseTimeTypeResponse;
}

export type ResponseTimeTypeActionTypes =
  | ResponseTimeTypeAction
  | ResponseTimeTypeLoading
  | ResponseTimeTypeSuccess
  | ResponseTimeTypeFail
  | ResponseTimeTypeUpdateAPIMsg
  | ResponseTimeTypeUpdateAPIRes;
