import {
  PATIENT_DETAILS_FAIL,
  PATIENT_DETAILS_LOADING,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_DETAILS_UPDATE_API_MSG,
  PATIENT_DETAILS_UPDATE_API_RES,
  PatientDetailsActionTypes,
} from "./ActionTypes";
import { PatientDetailsById } from "./Model";

export const PatientDetailsLoadingAction = (
  loading: boolean,
): PatientDetailsActionTypes => {
  return {
    type: PATIENT_DETAILS_LOADING,
    loading: loading,
  };
};

export const PatientDetailsSuccessAction = (
  PatientDetailsresponse: PatientDetailsById,
  successMsg: string,
): PatientDetailsActionTypes => {
  return {
    type: PATIENT_DETAILS_SUCCESS,
    payload: PatientDetailsresponse,
    successMsg: successMsg,
  };
};

export const PatientDetailsErrorAction = (
  PatientDetailsresponse: PatientDetailsById,
  errMsg: string,
  status: number,
): PatientDetailsActionTypes => {
  return {
    type: PATIENT_DETAILS_FAIL,
    payload: PatientDetailsresponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const PatientDetailsUpdateAPIMsgAction = (
  PatientDetailsresponse: PatientDetailsById,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientDetailsActionTypes => {
  return {
    type: PATIENT_DETAILS_UPDATE_API_MSG,
    payload: PatientDetailsresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const PatientDetailsAPIResClearAction = (
  PatientDetailsresponse: PatientDetailsById,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientDetailsActionTypes => {
  return {
    type: PATIENT_DETAILS_UPDATE_API_RES,
    payload: PatientDetailsresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
