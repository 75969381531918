import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/store/Store";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import MWExceptionList from "../../../../../../component/MWExceptionList";
import {
  GetHealthcampStaffActiveListBody,
  StaffList,
} from "../../../../../../redux/HealthcampStaffList/Model";
import { useParams } from "react-router-dom";
import { getGetHealthcampStaffActiveList } from "redux/HealthcampStaffList/API";
import AddHealthcampStaffDialog from "../AddHealthcampStaffDialog";
import { DeactiveStaffOfHealthCampBody } from "redux/HealthcampStaffList/DeactiveStaffOfHealthcamp/Model";
import {
  clearDiactiveStaffOfHealthcampAPIRes,
  deactiveStaffOfHealthcampApiCall,
} from "redux/HealthcampStaffList/DeactiveStaffOfHealthcamp/API";
import MWSnackbar from "component/MWSnackbar";

type Props = {
  viewHealthcampStaffListLoader?: boolean | any;
};

export default function ActiveList({ viewHealthcampStaffListLoader }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  // call Store
  const viewHealthCampStaffList = useSelector(
    (state: AppState) => state.getViewHealthcampStaffListValue,
  );

  const deactivateHealthcampStaffValueList = useSelector(
    (state: AppState) => state.deactivateHealthcampStaffValue,
  );
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const viewHealthcampStaffListBody = {
      healthcampId: healthcampid,
      isActiveOrAdmin: 1,
    } as GetHealthcampStaffActiveListBody;
    dispatch(getGetHealthcampStaffActiveList(viewHealthcampStaffListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const deactiveStaff = (id: string) => {
    let diactiveBody = {
      healthcampId: healthcampid,
      staffId: id,
    } as DeactiveStaffOfHealthCampBody;
    dispatch(deactiveStaffOfHealthcampApiCall(diactiveBody));
  };

  useEffect(() => {
    if (deactivateHealthcampStaffValueList?.deactiveStaffOfHealthcampRes?.message !== undefined) {
      setDiactiveMsg(deactivateHealthcampStaffValueList?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
        isActiveOrAdmin: 1,
      } as GetHealthcampStaffActiveListBody;
      dispatch(getGetHealthcampStaffActiveList(viewHealthcampStaffListBody, 1));
      dispatch(clearDiactiveStaffOfHealthcampAPIRes());
    }
    if (deactivateHealthcampStaffValueList?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(deactivateHealthcampStaffValueList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateHealthcampStaffValueList]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  const [addHealthcampStaffDialog, setAddHealthcampStaffDialog] = React.useState(false);
  const updateAddOrganizationStaffDialogValFromChild = (passedVal: boolean) => {
    setAddHealthcampStaffDialog(passedVal);
  };
  useEffect(() => {
    viewHealthCampStaffList?.getHealthcampStaffActiveList.numberOfPages !== undefined
      ? setPageListCount(
          String(viewHealthCampStaffList?.getHealthcampStaffActiveList?.numberOfPages),
        )
      : setPageListCount("0");
    if (viewHealthCampStaffList?.getHealthcampStaffActiveList?.StaffList !== undefined) {
      if (viewHealthCampStaffList?.getHealthcampStaffActiveList?.StaffList.length > 0) {
        let dataPointArr = viewHealthCampStaffList?.getHealthcampStaffActiveList?.StaffList.map(
          (element: StaffList) => ({
            id: element.id,
            first_name: element.first_name,
            last_name: element.last_name,
            email: element.email,
            phoneNumber: element.phoneNumber,
            userId: element.userId,
          }),
        );
        setDataGridArray(dataPointArr);
      } else {
        setDataGridArray([] as any[]);
      }
    } else {
      setDataGridArray([] as any[]);
    }
    if (viewHealthCampStaffList?.errorMsg !== "") {
      setNotAdministratorMsg(viewHealthCampStaffList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewHealthCampStaffList]);

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 220,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Action",
      minWidth: 153,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deactiveStaff(params.value);
          }}
          startIcon={<ToggleOffIcon />}
          sx={{ textTransform: "none" }}
        >
          Deactivate Staff
        </Button>
      ),
    },
  ];

  const StaffListOfHealthCamp = (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      {viewHealthCampStaffList?.status === -1 && (
        <Grid item>
          <Alert severity="error">{notAdministratorMsg}</Alert>
        </Grid>
      )}
      {viewHealthCampStaffList?.status !== -1 && (
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={() => setAddHealthcampStaffDialog(true)}
              >
                Add Healthcamp Staff
              </Button>
            </Grid>
            {!fullScreen && (
              <Grid item>
                <Typography>Page: {page}</Typography>
              </Grid>
            )}
            <Grid item>
              <Pagination
                color="primary"
                count={Number(pageListCount) || 0}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {viewHealthCampStaffList?.status !== -1 && (
        <Grid item>
          <Box>
            {dataGridArray.length > 0 ? (
              <Box>
                {fullScreen ? (
                  <Box sx={{ height: "calc(100vh - 54vh)" }}>
                    <DataGrid
                      rows={dataGridArray}
                      columns={columns}
                      disableSelectionOnClick
                      hideFooter
                      hideFooterPagination
                      experimentalFeatures={{ newEditingApi: true }}
                      components={{ Toolbar: GridToolbar }}
                      componentsProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: true },
                          printOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 250 },
                        },
                      }}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                    />
                  </Box>
                ) : (
                  <DataGrid
                    rows={dataGridArray}
                    columns={columns}
                    disableSelectionOnClick
                    autoHeight
                    hideFooter
                    hideFooterPagination
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                )}
              </Box>
            ) : (
              <MWExceptionList />
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
    }
  };

  return (
    <Box>
      {viewHealthCampStaffList?.loading === true
        ? viewHealthcampStaffListLoader(true)
        : viewHealthcampStaffListLoader(false)}
      {StaffListOfHealthCamp}
      {addHealthcampStaffDialog ? (
        <AddHealthcampStaffDialog
          itemId={healthcampid}
          addOrganizationStaffDialogEnable={addHealthcampStaffDialog}
          addOrganizationStaffDialogUpdateState={updateAddOrganizationStaffDialogValFromChild}
        />
      ) : null}
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
