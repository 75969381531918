import { GetHealthcampRequestListRes } from "./Model";
export const HEALTHCAMP_REGISTRATION_LIST_LOADING = "HEALTHCAMP_REGISTRATION_LIST_LOADING";
export const HEALTHCAMP_REGISTRATION_LIST_SUCCESS = "HEALTHCAMP_REGISTRATION_LIST_SUCCESS";
export const HEALTHCAMP_REGISTRATION_LIST_FAIL = "HEALTHCAMP_REGISTRATION_LIST_FAIL";
export const HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG = "HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG";
export const HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES = "HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES";

export interface GetHealthCampRegistrationListState {
  getHealthCampRegistrationRequestList: GetHealthcampRequestListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampRegistrationListLoading {
  type: typeof HEALTHCAMP_REGISTRATION_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthCampRegistrationListSuccess {
  type: typeof HEALTHCAMP_REGISTRATION_LIST_SUCCESS;
  payload: GetHealthcampRequestListRes;
  successMsg: string;
}
export interface GetHealthCampRegistrationListFail {
  type: typeof HEALTHCAMP_REGISTRATION_LIST_FAIL;
  payload: GetHealthcampRequestListRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampRegistrationListUpdateAPIMsg {
  type: typeof HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG;
  payload: GetHealthcampRequestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampRegistrationListUpdateAPIRes {
  type: typeof HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES;
  payload: GetHealthcampRequestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthcampRegistrationListActionTypes =
  | GetHealthCampRegistrationListLoading
  | GetHealthCampRegistrationListSuccess
  | GetHealthCampRegistrationListFail
  | GetHealthCampRegistrationListUpdateAPIMsg
  | GetHealthCampRegistrationListUpdateAPIRes;
