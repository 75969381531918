import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./routes/PublicRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import AdminDashboard from "./pages/AdminDashboard/index";
import DoctorList from "./pages/DoctorList";
import PatientDetails from "./pages/PatientDetails/PatientDetails";
import NotFound from "./pages/NotFound";
import DoctorsOnboarding from "./pages/DoctorOnboarding/index";
import AppointmenList from "./pages/AppointmentList";
import PatientQueries from "./pages/PatientQueries";
import DoctorQueries from "./pages/DoctorQueries";
import Payment from "./pages/Payment";
import PatientQueriesDetails from "./pages/PatientQueriesDetails";
import DoctorQueriesDetails from "./pages/DoctorQueriesDetails";
import Task from "./pages/Task/Task";
import TaskDetails from "./pages/Task/TaskDetails";
import SelfTaskDetails from "./pages/SelfTaskDetails";
import PatientListByHealthcamp from "./pages/HealthCamp/HealthCampDetails/index";
import QuestionAnswer from "./pages/QuestionAnswer";
import CaseDetails from "./pages/Patient/CaseDetails";
import AddHealthRecord from "./pages/Patient/FilePreparation";
import DemographicDetails from "./pages/Patient/FilePreparation/DemographicDetails";
import ExecutiveList from "./pages/ExecutiveList/index";
import DoctorAppointments from "./pages/DoctorAppointments";
import AllCaseList from "./pages/CaseList/AllCaseList";
import ExecutiveTaskDetails from "./pages/ExecutiveTaskDetails";
import DoctorDetails from "./pages/DoctorDetails";
import PatientTeam from "./pages/PatientTeam/index";
import TeleHealth from "./pages/TeleHealth/TeleHealth";
import CorporatePackage from "./pages/CorporatePackage/CorporatePackage";
import ActiveCorporatePackageDetails from "./pages/CorporatePackage/ActiveCorporatePackageDetails";
import CorporatePackageInactivedetails from "./pages/CorporatePackage/CorporatePackageInactivedetails";
import ActivePatientListDetails from "./pages/CorporatePackage/CorporatePatientList/ActivepatientTeamDetails/ActivePatientListDetails";
import InactivePatientDetails from "./pages/CorporatePackage/CorporatePatientList/InActivePatientTeamDetails/InactivePatientDetails";
import PatientNotes from "./pages/Notes/PatientNote/PatientNotes";
import NotesDetails from "./pages/Notes/PatientCaseNotesForDoctor/NotesDetails";
import OrganizationList from "./pages/OrganizationList/index";
import PatientListByOrganization from "./pages/PatientListByOrganization/index";
import HealthcampList from "./pages/HealthCamp/index";
import SearchPatient from "./pages/SearchPatient";
import CaseListExpanded from "./pages/PatientDashboard/CaseList/CaseListExpanded";
import PatientRegistration from "./pages/PatientListByHealthCamp/PatientRegistration";
import PatientOrganizationListMapping from "./pages/HealthCamp/HealthCampDetails/OrganizationListMappedWithHealthCampId/index";
import HealthcampListByOrganization from "./pages/PatientListByOrganization/HealthCampListMappedWithOrganization/index";
import ProviderOrganizationList from "./pages/ProviderOrganizationList";
import AddProviderOrganization from "./pages/ProviderOrganizationList/AddOrganizationProvider";
import ProviderOrganizationAdministratorDetails from "./pages/ProviderOrganizationAdministratorList/ProviderOrganizationAdministratorDetails";
import ProviderOrganizationAdministratorList from "./pages/ProviderOrganizationAdministratorList/index";
import ObservationDetails from "./pages/PatientDetails/ObservationDetails";
import Preference from "./pages/Preference";
import ProviderOrganizationDetails from "./pages/ProviderOrganizationList/ProviderOrganizationDetails";
import DataObservationList from "pages/PatientDashboard/DataObservationList/Index";
import CompetitionListComponent from "pages/CompetitionListComponent/index";
import PatientDetailsListByCompetition from "pages/PatientDetailsListByCompetition";
import CompetitionDetails from "pages/CompetitionListComponent/CompetitionDetails";
import ImageViewerComponent from "pages/CompetitionListComponent/ImageViwerComponent";
import HealthCampSessionList from "pages/HealthCamp/HealthCampDetails/HealthCampInstance/HealthCampInstanceDetails/HealthCampSessionList";
import InstanceSessionDetails from "pages/HealthCamp/HealthCampDetails/HealthCampInstance/HealthCampInstanceDetails/HealthCampSessionList/SessionList/InstanceSessionDetails";
import PatientDetailsListBySessionId from "pages/HealthCamp/HealthCampDetails/HealthCampInstance/HealthCampInstanceDetails/HealthCampSessionList/SessionList/InstanceSessionDetails/PatientListBySessionId/PatientDetailsListBySessionId";
import CheckInPatientComponent from "pages/HealthCamp/HealthCampDetails/HealthCampInstance/HealthCampInstanceDetails/HealthCampSessionList/SessionList/InstanceSessionDetails/PatientListBySessionId/CheckInPatientComponent";
import UnprocessedPatientDetails from "pages/UnprocessedPatientDetails/inedex";
import PatientListComponent from "pages/PatientListComponent";
import DuplicatePatientDetailsView from "pages/PatientListComponent/DuplicatePatientList/DuplicatePatientDetailsView";
import HealthCampRegistrationReqList from "pages/PatientListByHealthCamp/PatientRegistration/HealthCampRegistrationReqList";
import HealthCampRegReqDetailsList from "pages/PatientListByHealthCamp/PatientRegistration/HealthCampRegReqDetailsList";
import SearchpatientInCamp from "pages/HealthCamp/HealthCampDetails/HealthCampInstance/HealthCampInstanceDetails/AppointmentList/SearchPatientInHealthcampcamp";
import PatientTeamDetails from "pages/PatientTeam/PatientTeamDetails";
import PatientTeamListDetailsByProfileId from "pages/PatientTeam/PatientTeamListDetailsByProfileId";
import OrganizationRegistrationRequestList from "pages/OrganizationRequestList";
import OrganizationRequestListDeatils from "pages/OrganizationRequestListDetails";
import DataManagement from "pages/DataManagement";
import SearchDoctorComponent from "pages/ProviderOrganizationList/DoctorListComonent/SearchDoctorComponent";
import Login from "pages/Login";
import DeleteAccountRequestList from "pages/DeleteAccountRequestList";
import EHRDetails from "pages/ProviderOrganizationList/EHRDetails";


function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PrivateRoute path="/dashboard" component={AdminDashboard} />
      <PrivateRoute path="/delete-account" component={DeleteAccountRequestList} />
      <PrivateRoute path="/healthcamplist" component={HealthcampList} />
      <PrivateRoute path="/organizationlist" component={OrganizationList} />
      <PrivateRoute path="/competitionList" component={CompetitionListComponent} />
      <PrivateRoute path="/providerorganizationlist" component={ProviderOrganizationList} />
      <PrivateRoute path="/addproviderorganization" component={AddProviderOrganization} />
      <PrivateRoute path="/doctors" component={DoctorList} />
      <PrivateRoute path="/patients" component={PatientListComponent} />
      <PrivateRoute path="/allcaselist" component={AllCaseList} />
      <PrivateRoute path="/patientdetails/:patientid" component={PatientDetails} />
      <PrivateRoute path="/unprocessesedpatientdetails" component={UnprocessedPatientDetails} />
      <PrivateRoute
        path="/patientdataobservationlist/:patientid?"
        component={DataObservationList}
      />
      <PrivateRoute
        path="/providerorganizationdetails/:organizationid?"
        component={ProviderOrganizationDetails}
      />
      <PrivateRoute
        path="/administratordetails/:administratorid?"
        component={ProviderOrganizationAdministratorDetails}
      />
      <PrivateRoute
        path="/administratorlist/:organizationid?"
        component={ProviderOrganizationAdministratorList}
      />
      <PrivateRoute path="/addhealthrecord/:patientid?" component={AddHealthRecord} />
      <PrivateRoute path="/demographicdetails/:patientid?" component={DemographicDetails} />
      <PrivateRoute path="/casedetails/:patientid?/:caseid?/" component={CaseDetails} />
      <PrivateRoute path="/patient-queries" component={PatientQueries} />
      <PrivateRoute path="/doctor-queries" component={DoctorQueries} />
      <PrivateRoute path="/payment" component={Payment} />
      <PrivateRoute path="/data-management" component={DataManagement} />
      <PrivateRoute path="/doctoronboardingrequests" component={DoctorsOnboarding} />
      <PrivateRoute path="/appointmentlist/" component={AppointmenList} />
      <PrivateRoute path="/patient-queries-details/:id?" component={PatientQueriesDetails} />
      <PrivateRoute path="/doctor-queries-details/:id?" component={DoctorQueriesDetails} />
      <PrivateRoute path="/task" component={Task} />
      <PrivateRoute path="/question-answer" component={QuestionAnswer} />
      <PrivateRoute path="/taskdetails/:currentPage?/:rowIndex?" component={TaskDetails} />
      <PrivateRoute path="/selftaskdetails/:rowIndex?" component={SelfTaskDetails} />
      <PrivateRoute
        path="/patientlistbyhealthcamp/:healthcampid?"
        component={PatientListByHealthcamp}
      />
      <PrivateRoute
        path="/healthcampinstance/:healthcampid?/:instanceHealthcampId?"
        component={HealthCampSessionList}
      />
      <PrivateRoute
        path="/healthcampinstance_session/:healthcampid?/:instanceHealthcampId?/:sessionId?"
        component={InstanceSessionDetails}
      />
      <PrivateRoute
        path="/healthcampsession/:healthcampid?/:instanceHealthcampId?/:sessionId?/:patientid?"
        component={PatientDetailsListBySessionId}
      />
      <PrivateRoute
        path="/healthcamppatientcheckin/:healthcampid?/:instanceHealthcampId?/:sessionId?"
        component={CheckInPatientComponent}
      />
      <PrivateRoute path="/patientregistration/:healthcampid?" component={PatientRegistration} />
      <PrivateRoute
        path="/healthcamplistbyorganization/:organizationid?"
        component={HealthcampListByOrganization}
      />
      <PrivateRoute
        path="/organizationlistmapping/:healthcampid?"
        component={PatientOrganizationListMapping}
      />
      <PrivateRoute
        path="/patientlistbyorganization/:organizationid?"
        component={PatientListByOrganization}
      />
      <PrivateRoute
        path="/patientlistbycompetition/:competitionid?"
        component={CompetitionDetails}
      />
      <PrivateRoute path="/executive" component={ExecutiveList} />
      <PrivateRoute path="/doctorapoointment/:doctorId" component={DoctorAppointments} />
      <PrivateRoute path="/executivetaskdetails/:executiveId?" component={ExecutiveTaskDetails} />
      <PrivateRoute path="/doctordetails/:id?" component={DoctorDetails} />
      <PrivateRoute path="/patientteam" component={PatientTeam} />
      <PrivateRoute path="/telehealth" component={TeleHealth} />
      <PrivateRoute
        path="/patientteamdetails/:patientTeamCurrentPage?/:patientDetailsID?"
        component={PatientTeamListDetailsByProfileId}
      />
      <PrivateRoute
        path="/patientteamlistdetails/:patientDetailsID?"
        component={PatientTeamDetails}
      />
      <PrivateRoute path="/corporatepackages" component={CorporatePackage} />
      <PrivateRoute
        path="/activecorporatepackagesdetails/:id?"
        component={ActiveCorporatePackageDetails}
      />
      <PrivateRoute
        path="/inactivecorporatepackagedetails/:id?"
        component={CorporatePackageInactivedetails}
      />
      <PrivateRoute
        path="/activepatientpaymentdetails/:packageId?/:profileId?"
        component={ActivePatientListDetails}
      />
      <PrivateRoute
        path="/inactivepatientpaymentdetails/:packageId?/:profileId?"
        component={InactivePatientDetails}
      />
      <PrivateRoute path="/patientnotes/:profileId?" component={PatientNotes} />
      <PrivateRoute path="/notesdetails/:patientid?/:caseid?/:doctorId?" component={NotesDetails} />
      <PrivateRoute path="/searchpatient" component={SearchPatient} />
      <PrivateRoute path="/searchdoctor" component={SearchDoctorComponent} />
      <PrivateRoute path="/patientcaselist/:patientid" component={CaseListExpanded} />
      <PrivateRoute
        path="/observationdetails/:patientid?/:observationtype"
        component={ObservationDetails}
      />
      <PrivateRoute path="/preference" component={Preference} />
      <PrivateRoute
        path="/patientdetailslist/:competitionid?/:patientid?"
        component={PatientDetailsListByCompetition}
      />
      <PrivateRoute path="/judgingview/:patientdetailsid" component={ImageViewerComponent} />
      <PrivateRoute path="/duplicatePatientdetailsview/:patientoriginalid?/:patientduplicateid?" component={DuplicatePatientDetailsView} />
      <PrivateRoute path="/healthcampregistrationrequest/:healthcampid?" component={HealthCampRegistrationReqList} />
      <PrivateRoute path="/organizationregistrationrequest/:organizationid?" component={OrganizationRegistrationRequestList} />
      <PrivateRoute path="/healthcampregistrationrequestdetails/:healthcampid?/:requestid?" component={HealthCampRegReqDetailsList} />
      <PrivateRoute path="/organizationregistrationrequestdetails/:organizationid?/:requestid?" component={OrganizationRequestListDeatils} />
      <PrivateRoute path="/searchpatientincamp/:healthcampid?/:instanceHealthcampId?" component={SearchpatientInCamp} />
      <PrivateRoute path="/ehrdetails/:organizationid?" component={EHRDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
