import { CreateHealthCampByOrganizationRes } from "./Model";
export const CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING =
  "CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING";
export const CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS =
  "CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS";
export const CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL = "CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL";
export const CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_DATA = "CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_DATA";
export const CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG =
  "CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG";
export const CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES =
  "CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES";

export interface CreateHealthCampByOrganizationListState {
  getDoctorWorkScheduleListRes: CreateHealthCampByOrganizationRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CreateHealthCampByOrganizationListLoading {
  type: typeof CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING;
  loading: boolean;
}
export interface CreateHealthCampByOrganizationListSuccess {
  type: typeof CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS;
  payload: CreateHealthCampByOrganizationRes;
  successMsg: string;
}
export interface CreateHealthCampByOrganizationListFail {
  type: typeof CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL;
  payload: CreateHealthCampByOrganizationRes;
  errorMsg: string;
  status: number;
}
export interface CreateHealthCampByOrganizationListUpdateAPIMsg {
  type: typeof CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG;
  payload: CreateHealthCampByOrganizationRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CreateHealthCampByOrganizationListUpdateAPIRes {
  type: typeof CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES;
  payload: CreateHealthCampByOrganizationRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type CreateHealthCampByOrganizationListActionTypes =
  | CreateHealthCampByOrganizationListLoading
  | CreateHealthCampByOrganizationListSuccess
  | CreateHealthCampByOrganizationListFail
  | CreateHealthCampByOrganizationListUpdateAPIMsg
  | CreateHealthCampByOrganizationListUpdateAPIRes;
