import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddScoundRoundDetailsActionTypes } from "./ActionTypes";
import { AddScoundRoundDetailsBody, AddScoundRoundDetailsRes } from "./Model";
import {
  addScoundRoundDetailsAPIResClearAction,
  addScoundRoundDetailsErrorAction,
  addScoundRoundDetailsLoadingAction,
  addScoundRoundDetailsSuccessAction,
  addScoundRoundDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddScoundRoundDetailsRes;
export const addSecondRoundApi = (
  payload: AddScoundRoundDetailsBody,
) => {
  return function (dispatch: Dispatch<AddScoundRoundDetailsActionTypes>) {
    dispatch(addScoundRoundDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/competitionapp/api/competitionSecondRound/addToCompetitionSecondround`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addScoundRoundDetailsLoadingAction(false));
        dispatch(addScoundRoundDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(addScoundRoundDetailsLoadingAction(false));
        dispatch(
          addScoundRoundDetailsErrorAction(
            {} as AddScoundRoundDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const addSecondRoundAPIResMsg = () => {
  return function (dispatch: Dispatch<AddScoundRoundDetailsActionTypes>) {
    dispatch(
      addScoundRoundDetailsAPIMsgAction(
        apiRes as AddScoundRoundDetailsRes,
        "",
        0,
      ),
    );
  };
};

export const clearAddSecondRoundAPIRes = () => {
  return function (dispatch: Dispatch<AddScoundRoundDetailsActionTypes>) {
    dispatch(
      addScoundRoundDetailsAPIResClearAction(
        {} as AddScoundRoundDetailsRes,
        "",
        0,
      ),
    );
  };
};
