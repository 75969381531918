import { ToggleActiveOrgRegReqRes } from "./Model";
export const TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING = "TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING";
export const TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS = "TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS";
export const TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL = "TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL";
export const TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG = "TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG";
export const TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES = "TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES";

export interface ToggleActiveOrgRegistrationRequestState {
  toggleActiveRegReqList: ToggleActiveOrgRegReqRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveOrgRegistrationRequestLoading {
  type: typeof TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING;
  loading: boolean;
}
export interface ToggleActiveOrgRegistrationRequestSuccess {
  type: typeof TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS;
  payload: ToggleActiveOrgRegReqRes;
  successMsg: string;
}
export interface ToggleActiveOrgRegistrationRequestFail {
  type: typeof TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL;
  payload: ToggleActiveOrgRegReqRes;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveOrgRegistrationRequestUpdateAPIMsg {
  type: typeof TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG;
  payload: ToggleActiveOrgRegReqRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveOrgRegistrationRequestUpdateAPIRes {
  type: typeof TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES;
  payload: ToggleActiveOrgRegReqRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ToggleActiveOrgRegistrationRequestActionTypes =
  | ToggleActiveOrgRegistrationRequestLoading
  | ToggleActiveOrgRegistrationRequestSuccess
  | ToggleActiveOrgRegistrationRequestFail
  | ToggleActiveOrgRegistrationRequestUpdateAPIMsg
  | ToggleActiveOrgRegistrationRequestUpdateAPIRes;
