import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { OrganizationListByPatientIdActionTypes } from "./ActionTypes";
import { OrganizationListByPatientIdBody, OrganizationListByPatientIdDetails } from "./Model";
import {
  OrganizationListByPatientIdAPIResClearAction,
  OrganizationListByPatientIdErrorAction,
  OrganizationListByPatientIdLoadingAction,
  OrganizationListByPatientIdSuccessAction,
  OrganizationListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as OrganizationListByPatientIdDetails;
export const organizationListByPatientIdApi = (
  patientid: OrganizationListByPatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<OrganizationListByPatientIdActionTypes>) {
    dispatch(OrganizationListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
        `/adminapp/api/patientorganizationlist/organization_list_by_patient?page=${Number(
          pageNo,
        )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(OrganizationListByPatientIdLoadingAction(false));
        dispatch(
          OrganizationListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as OrganizationListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(OrganizationListByPatientIdLoadingAction(false));
        dispatch(
          OrganizationListByPatientIdErrorAction(
            {} as OrganizationListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateOrganizationListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<OrganizationListByPatientIdActionTypes>) {
    dispatch(
      OrganizationListByPatientIdAPIMsgAction(apiRes as OrganizationListByPatientIdDetails, "", "", 0),
    );
  };
};

export const clearOrganizationListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<OrganizationListByPatientIdActionTypes>) {
    dispatch(
      OrganizationListByPatientIdAPIResClearAction({} as OrganizationListByPatientIdDetails, "", "", 0),
    );
  };
};
