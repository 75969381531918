import {
  ProviderOrgPatientSearchActionTypes,
  PROVIDER_ORG_PATIENT_SEARCH_FAIL,
  PROVIDER_ORG_PATIENT_SEARCH_LOADING,
  PROVIDER_ORG_PATIENT_SEARCH_SUCCESS,
  ProviderOrgPatientSearchDetailsState,
  PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_MSG,
  PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { ProviderOrgPatientSearchDetails } from "./Model";

const initialStateGetPosts: ProviderOrgPatientSearchDetailsState = {
  loading: false,
  providerOrgPatientSearchRes: {} as ProviderOrgPatientSearchDetails,
  message: "",
  status: 0,
};
export const providerOrgPatientSearchReducer = (
  state = initialStateGetPosts,
  action: ProviderOrgPatientSearchActionTypes,
): ProviderOrgPatientSearchDetailsState => {
  switch (action.type) {
    case PROVIDER_ORG_PATIENT_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        providerOrgPatientSearchRes: {} as ProviderOrgPatientSearchDetails,
      };
    case PROVIDER_ORG_PATIENT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        providerOrgPatientSearchRes: action.payload,
      };
    case PROVIDER_ORG_PATIENT_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        providerOrgPatientSearchRes: {} as ProviderOrgPatientSearchDetails,
        message: action.message,
        status: action.status,
      };
    case PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        providerOrgPatientSearchRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        providerOrgPatientSearchRes: {} as ProviderOrgPatientSearchDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
