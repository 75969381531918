import {
  PATIENT_LIST_BY_COMPETITION_ID_DATA,
  PATIENT_LIST_BY_COMPETITION_ID_FAIL,
  PATIENT_LIST_BY_COMPETITION_ID_LOADING,
  PATIENT_LIST_BY_COMPETITION_ID_SUCCESS,
  GetCompetitionPatientListActionTypes,
} from "./ActionTypes";
import { PageCount, GetCompetitionPatientListDetails } from "./Model";

export const patientSearchCompitionIdAction = (
  GetCompetitionPatientListDetails: GetCompetitionPatientListDetails,
  pageCount: PageCount,
): GetCompetitionPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_COMPETITION_ID_DATA,
    payload: GetCompetitionPatientListDetails,
    pageCount: pageCount,
  };
};

export const GetCompitionPatientListLoadingAction = (
  loading: boolean,
): GetCompetitionPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_COMPETITION_ID_LOADING,
    loading: loading,
  };
};

export const GetCompitionPatientListSuccessAction = (
  competitionPatientListResponse: GetCompetitionPatientListDetails,
): GetCompetitionPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_COMPETITION_ID_SUCCESS,
    payload: competitionPatientListResponse,
  };
};

export const GetCompitionPatientListErrorAction = (
  competitionPatientListResponse: GetCompetitionPatientListDetails,
  errMsg: string,
  status: number,
): GetCompetitionPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_COMPETITION_ID_FAIL,
    payload: competitionPatientListResponse,
    message: errMsg,
    status: status,
  };
};
export const GetCompitionPatientListAPIMsgAction = (
  competitionPatientListResponse: GetCompetitionPatientListDetails,
  errMsg: string,
  status: number,
): GetCompetitionPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_COMPETITION_ID_FAIL,
    payload: competitionPatientListResponse,
    message: errMsg,
    status: status,
  };
};

export const GetCompitionPatientListAPIResClearAction = (
  competitionPatientListResponse: GetCompetitionPatientListDetails,
  errMsg: string,
  status: number,
): GetCompetitionPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_COMPETITION_ID_FAIL,
    payload: competitionPatientListResponse,
    message: errMsg,
    status: status,
  };
};
