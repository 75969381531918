import {
  INACTIVEVITALCOLLECTION_LIST_FAIL,
  INACTIVEVITALCOLLECTION_LIST_LOADING,
  INACTIVEVITALCOLLECTION_LIST_SUCCESS,
  INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG,
  INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES,
  InactiveVitalCOllectionListActionTypes,
} from "./ActionTypes";
import { InactiveVitalCollectionListRes } from "./Model";

export const inactiveVitalCollectionListLoadingAction = (
  loading: boolean,
): InactiveVitalCOllectionListActionTypes => {
  return {
    type: INACTIVEVITALCOLLECTION_LIST_LOADING,
    loading: loading,
  };
};

export const inactiveVitalCollectionListSuccessAction = (
  inactiveVitalCollectionListRes: InactiveVitalCollectionListRes,
  successMsg: string,
): InactiveVitalCOllectionListActionTypes => {
  return {
    type: INACTIVEVITALCOLLECTION_LIST_SUCCESS,
    payload: inactiveVitalCollectionListRes,
    successMsg: successMsg,
  };
};

export const inactiveVitalCollectionListErrorAction = (
  inactiveVitalCollectionListRes: InactiveVitalCollectionListRes,
  errMsg: string,
  status: number,
): InactiveVitalCOllectionListActionTypes => {
  return {
    type: INACTIVEVITALCOLLECTION_LIST_FAIL,
    payload: inactiveVitalCollectionListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const inactiveVitalCollectionListUpdateAPIMsgAction = (
  inactiveVitalCollectionListRes: InactiveVitalCollectionListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): InactiveVitalCOllectionListActionTypes => {
  return {
    type: INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG,
    payload: inactiveVitalCollectionListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const inactiveVitalCollectionListAPIResClearAction = (
  inactiveVitalCollectionListRes: InactiveVitalCollectionListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): InactiveVitalCOllectionListActionTypes => {
  return {
    type: INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES,
    payload: inactiveVitalCollectionListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
