import {
  DeletePatientDataByIdActionTypes,
  DELETE_SCHEDULE_FAIL,
  DELETE_SCHEDULE_LOADING,
  DELETE_SCHEDULE_SUCCESS,
  DeletePatientDataByIdState,
  DELETE_SCHEDULE_UPDATE_API_MSG,
  DELETE_SCHEDULE_UPDATE_API_RES,
} from "./ActionTypes";
import { DeletePatientDataByIdRes } from "./Model";

const initialStateGetPosts: DeletePatientDataByIdState = {
  loading: false,
  deletePatientDataByIdRes: {} as DeletePatientDataByIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const deletePatientDataByIdReducer = (
  state = initialStateGetPosts,
  action: DeletePatientDataByIdActionTypes,
): DeletePatientDataByIdState => {
  switch (action.type) {
    case DELETE_SCHEDULE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        deletePatientDataByIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case DELETE_SCHEDULE_FAIL:
      return {
        ...state,
        deletePatientDataByIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_SCHEDULE_UPDATE_API_MSG:
      return {
        ...state,
        deletePatientDataByIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_SCHEDULE_UPDATE_API_RES:
      return {
        ...state,
        deletePatientDataByIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
