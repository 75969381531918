import { AddPatientRes } from "./Model";

export const ADD_DATA_POINT_LOADING = "ADD_DATA_POINT_LOADING";
export const ADD_DATA_POINT_SUCCESS = "ADD_DATA_POINT_SUCCESS";
export const ADD_DATA_POINT_FAIL = "ADD_DATA_POINT_FAIL";
export const ADD_PATIENT_DATA = "ADD_PATIENT_DATA";
export const ADD_PATIENT_API_MSG = "ADD_PATIENT_API_MSG";
export const ADD_PATIENT_API_RES = "ADD_PATIENT_API_RES";

export interface AddPatientDetails {
  addPatientRes: AddPatientRes;
  loading: boolean;
  message: string;
  status: number;
  errRes: string;
}
export interface AddPatientLoading {
  type: typeof ADD_DATA_POINT_LOADING;
  loading: boolean;
}
export interface AddPatientSuccess {
  type: typeof ADD_DATA_POINT_SUCCESS;
  payload: AddPatientRes;
}
export interface AddPatientFail {
  type: typeof ADD_DATA_POINT_FAIL;
  payload: AddPatientRes;
  message: string;
  status: number;
}

export interface AddPatientUpdateAPIMsg {
  type: typeof ADD_PATIENT_API_MSG;
  payload: AddPatientRes;
  message: string;
  status: number;
}
export interface AddPatientUpdateAPIRes {
  type: typeof ADD_PATIENT_API_RES;
  payload: AddPatientRes;
  message: string;
  status: number;
}
interface AddPatientAction {
  type: typeof ADD_PATIENT_DATA;
  payload: AddPatientRes;
  errRes: string;
}

export type PatientAddPointDataActionTypes =
  | AddPatientAction
  | AddPatientLoading
  | AddPatientSuccess
  | AddPatientFail
  | AddPatientUpdateAPIMsg
  | AddPatientUpdateAPIRes;
