import { PatientSearchByInstanceIdDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_SEARCH_BY_INSTANCE_ID_LOADING = "PATIENT_SEARCH_BY_INSTANCE_ID_LOADING";
export const PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS = "PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS";
export const PATIENT_SEARCH_BY_INSTANCE_ID_FAIL = "PATIENT_SEARCH_BY_INSTANCE_ID_FAIL";
export const PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG = "PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG";
export const PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES = "PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES";

export interface PatientSearchByInstanceIdDetailsState {
  patientSearchByInstanceIdRes: PatientSearchByInstanceIdDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientSearchByInstanceIdLoading {
  type: typeof PATIENT_SEARCH_BY_INSTANCE_ID_LOADING;
  loading: boolean;
}
export interface PatientSearchByInstanceIdSuccess {
  type: typeof PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS;
  payload: PatientSearchByInstanceIdDetails;
}
export interface PatientSearchByInstanceIdFail {
  type: typeof PATIENT_SEARCH_BY_INSTANCE_ID_FAIL;
  payload: PatientSearchByInstanceIdDetails;
  message: string;
  status: number;
}
export interface PatientSearchByInstanceIdAPIMsg {
  type: typeof PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG;
  payload: PatientSearchByInstanceIdDetails;
  message: string;
  status: number;
}
export interface PatientSearchByInstanceIdRes {
  type: typeof PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES;
  payload: PatientSearchByInstanceIdDetails;
  message: string;
  status: number;
}

export type PatientSearchByInstanceIdActionTypes =
  | PatientSearchByInstanceIdLoading
  | PatientSearchByInstanceIdSuccess
  | PatientSearchByInstanceIdFail
  | PatientSearchByInstanceIdAPIMsg
  | PatientSearchByInstanceIdRes;
