import {
  AssignPatientDetailsTypeActionTypes,
  ADD_PATIENT_DETAILS_TYPE_FAIL,
  ADD_PATIENT_DETAILS_TYPE_LOADING,
  ADD_PATIENT_DETAILS_TYPE_SUCCESS,
  AssignPatientDetailsTypeState,
  ADD_PATIENT_DETAILS_TYPE_API_MSG,
  ADD_PATIENT_DETAILS_TYPE_API_RES,
  ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM,
} from "./ActionTypes";
import { AssignPatientDetailsTypeRes } from "./Model";

const initialStateGetPosts: AssignPatientDetailsTypeState = {
  loading: false,
  AssignPatientDetailsTypeRes: {} as AssignPatientDetailsTypeRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
  selectedItem: [] as any[],
};
export const assignPatientDetailsTypeReducer = (
  state = initialStateGetPosts,
  action: AssignPatientDetailsTypeActionTypes,
): AssignPatientDetailsTypeState => {
  switch (action.type) {
    case ADD_PATIENT_DETAILS_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_DETAILS_TYPE_SUCCESS:
      return {
        ...state,
        AssignPatientDetailsTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_DETAILS_TYPE_FAIL:
      return {
        ...state,
        AssignPatientDetailsTypeRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DETAILS_TYPE_API_MSG:
      return {
        ...state,
        AssignPatientDetailsTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DETAILS_TYPE_API_RES:
      return {
        ...state,
        AssignPatientDetailsTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    default:
      return state;
  }
};
