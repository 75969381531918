import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetProviderOrgDetailsActionTypes } from "./ActionTypes";
import { GetProviderOrgDetailsBody, GetProviderOrgDetails } from "./Model";
import {
  getProviderOrgDetailsAPIResClearAction,
  getProviderOrgDetailsErrorAction,
  getProviderOrgDetailsLoadingAction,
  getProviderOrgDetailsSuccessAction,
  getProviderOrgDetailsAPIMsgAction,
} from "./Actions";

import { headers } from "../../component/Utility";

let apiRes = {} as GetProviderOrgDetails;
export const getProviderOrgDetails = (providerOrganizationId: GetProviderOrgDetailsBody) => {
  return function (dispatch: Dispatch<GetProviderOrgDetailsActionTypes>) {
    dispatch(getProviderOrgDetailsLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationAdmin/getproviderOrganizationDetailsAsAdministrator`,
        providerOrganizationId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getProviderOrgDetailsLoadingAction(false));
        dispatch(
          getProviderOrgDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as GetProviderOrgDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getProviderOrgDetailsLoadingAction(false));
        dispatch(
          getProviderOrgDetailsErrorAction(
            {} as GetProviderOrgDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const providerOrganizationDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderOrgDetailsActionTypes>) {
    dispatch(getProviderOrgDetailsAPIMsgAction(apiRes as GetProviderOrgDetails, "", "", 0));
  };
};

export const clearProviderOrgDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderOrgDetailsActionTypes>) {
    dispatch(getProviderOrgDetailsAPIResClearAction({} as GetProviderOrgDetails, "", "", 0));
  };
};
