import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  ActiveCorporateList,
  ActiveCorporatePatientList,
} from "../../../../redux/interfaces/Model";
import * as _ from "lodash";
import {
  getActiveCorporateList,
  getActiveCorporatePatinetList,
} from "../../../../redux/effects/ApiCall";
import AdminLoader from "../../../AdminLoader";
import ActivePatientListUnderPatientTeam from "./ActivePatientListUnderPatientTeam";
import ActivePatientTeamPaymentDetails from "./ActivePatientTeamPaymentDetails";
import { Box, Container, Grid, Stack, Tab } from "@mui/material";
import MWPageTitle from "../../../../component/MWPageTitle";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function ActivePatientListDetails() {
  const { packageId } = useParams() as {
    packageId: string;
  };
  const { profileId } = useParams() as {
    profileId: string;
  };

  const dispatch = useDispatch();

  const loadingMarkup = <AdminLoader />;

  const [activePatientListUnderPatientTeamLoader, setActivePatientListUnderPatientTeamLoader] =
    useState(false);
  const [activePatientTeamPaymentDetailsLoader, setActivePatientTeamPaymentDetailsLoader] =
    useState(false);

  const [selected, setSelected] = useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  // call Active Package List Store
  const activeCorporatePackageList = useSelector(
    (state: AppState) => state.activeCorporateListValue,
  );
  const activeCorporateListDetails: ActiveCorporateList = _.filter(
    activeCorporatePackageList.activeCorporateListValue,
    ["id", Number(packageId)],
  )[0];

  // call Active Corporate Patient List Store
  const activeCorporatePatientList = useSelector(
    (state: AppState) => state.activeCorporatePatientValue,
  );
  const activeCorporatePatientListValues: ActiveCorporatePatientList = _.filter(
    activeCorporatePatientList.activeCorporatePatientListValue,
    ["profileId", Number(profileId)],
  )[0];
  useEffect(
    () => {
      // if (
      //   activeCorporateListDetails === undefined &&
      //   activeCorporatePatientListValues === undefined
      // ) {
      dispatch(getActiveCorporateList());
      dispatch(getActiveCorporatePatinetList(packageId));
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // dispatch, activeCorporateListDetails, activeCorporatePatientListValues
    ],
  );
  return (
    <Box>
      {activePatientListUnderPatientTeamLoader === true ||
      activePatientTeamPaymentDetailsLoader === true
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={
                  activeCorporatePatientListValues === undefined
                    ? ""
                    : activeCorporatePatientListValues.firstname +
                      " " +
                      activeCorporatePatientListValues.lastname +
                      "'s Details"
                }
                enableSubtitle={true}
                subtitle={
                  activeCorporateListDetails === undefined
                    ? ""
                    : "For " + activeCorporateListDetails.name
                }
              />
            </Grid>
          </Grid>
          <TabContext value={selected}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label="Patient List" value="1" />
                <Tab label="Payment List" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {
                <ActivePatientListUnderPatientTeam
                  activePatientListUnderPatientTeamLoader={
                    setActivePatientListUnderPatientTeamLoader
                  }
                />
              }
            </TabPanel>
            <TabPanel value="2">
              {
                <ActivePatientTeamPaymentDetails
                  activePatientTeamPaymentDetailsLoader={setActivePatientTeamPaymentDetailsLoader}
                />
              }
            </TabPanel>
          </TabContext>
        </Stack>
      </Container>
    </Box>
  );
}
