import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetHealthcampStaffActiveListActionTypes } from "./ActionTypes";
import { GetHealthcampStaffActiveListBody, GetHealthcampStaffActiveListRes } from "./Model";
import {
  getHealthcampStaffActiveListAPIResClearAction,
  getHealthcampStaffActiveListErrorAction,
  getHealthcampStaffActiveListLoadingAction,
  getHealthcampStaffActiveListSuccessAction,
  getHealthcampStaffActiveListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetHealthcampStaffActiveListRes;
export const getGetHealthcampStaffActiveList = (
  payload: GetHealthcampStaffActiveListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetHealthcampStaffActiveListActionTypes>) {
    dispatch(getHealthcampStaffActiveListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampstaff/staff_list_healthcamp?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getHealthcampStaffActiveListLoadingAction(false));
        dispatch(
          getHealthcampStaffActiveListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetHealthcampStaffActiveListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Active camp staff list has been fetched successfully",
            res.data.status !== undefined ? res.data.status || 0 : 0,
          ),
        );
      })
      .catch((error) => {
        dispatch(getHealthcampStaffActiveListLoadingAction(false));
        dispatch(
          getHealthcampStaffActiveListErrorAction(
            {} as GetHealthcampStaffActiveListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.response !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetHealthcampStaffActiveListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetHealthcampStaffActiveListActionTypes>) {
    dispatch(
      getHealthcampStaffActiveListUpdateAPIMsgAction(
        apiRes as GetHealthcampStaffActiveListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetHealthcampStaffActiveListAPIRes = () => {
  return function (dispatch: Dispatch<GetHealthcampStaffActiveListActionTypes>) {
    dispatch(
      getHealthcampStaffActiveListAPIResClearAction(
        {} as GetHealthcampStaffActiveListRes,
        "",
        "",
        0,
      ),
    );
  };
};
