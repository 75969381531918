import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddProviderOrganizationActionTypes } from "./ActionTypes";
import { AddProviderOrganizationBody, AddProviderOrganizationDetails } from "./Model";
import {
  AddProviderOrganizationAPIResClearAction,
  AddProviderOrganizationErrorAction,
  AddProviderOrganizationLoadingAction,
  AddProviderOrganizationSuccessAction,
  AddProviderOrganizationAPIMsgActionAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as AddProviderOrganizationDetails;
export const addProviderOrganizationApi = (body: AddProviderOrganizationBody) => {
  return function (dispatch: Dispatch<AddProviderOrganizationActionTypes>) {
    dispatch(AddProviderOrganizationLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/providerorganizationapp/api/providerOrganization/addProviderOrganization`,
        body,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(AddProviderOrganizationLoadingAction(false));
        dispatch(AddProviderOrganizationSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(AddProviderOrganizationLoadingAction(false));
        dispatch(
          AddProviderOrganizationErrorAction(
            {} as AddProviderOrganizationDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const providerOrganizationAPIResMsg = () => {
  return function (dispatch: Dispatch<AddProviderOrganizationActionTypes>) {
    dispatch(
      AddProviderOrganizationAPIMsgActionAction(apiRes as AddProviderOrganizationDetails, "", 0),
    );
  };
};

export const clearAddProOrgAPIRes = () => {
  return function (dispatch: Dispatch<AddProviderOrganizationActionTypes>) {
    dispatch(AddProviderOrganizationAPIResClearAction({} as AddProviderOrganizationDetails, "", 0));
  };
};
