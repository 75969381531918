import {
  PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_FAIL,
  PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_LOADING,
  PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_SUCCESS,
  PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_MSG,
  PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_RES,
  PatientCheckInBySessionIdFromAppointmnetListActionTypes,
} from "./ActionTypes";
import { PatientCheckInBySessionIdFromAppointmnetListDetails } from "./Model";

export const patientCheckInBySessionIdFromAppointmnetListLoadingAction = (
  loading: boolean,
): PatientCheckInBySessionIdFromAppointmnetListActionTypes => {
  return {
    type: PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_LOADING,
    loading: loading,
  };
};

export const patientCheckInBySessionIdFromAppointmnetListSuccessAction = (
  patientCheckInBySessionIdFromAppointmnetListResponse: PatientCheckInBySessionIdFromAppointmnetListDetails,
  successMsg: string,
): PatientCheckInBySessionIdFromAppointmnetListActionTypes => {
  return {
    type: PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_SUCCESS,
    payload: patientCheckInBySessionIdFromAppointmnetListResponse,
    successMsg: successMsg,
  };
};

export const patientCheckInBySessionIdFromAppointmnetListErrorAction = (
  patientCheckInBySessionIdFromAppointmnetListResponse: PatientCheckInBySessionIdFromAppointmnetListDetails,
  errMsg: string,
  status: number,
): PatientCheckInBySessionIdFromAppointmnetListActionTypes => {
  return {
    type: PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_FAIL,
    payload: patientCheckInBySessionIdFromAppointmnetListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const patientCheckInBySessionIdFromAppointmnetListAPIMsgAction = (
  patientCheckInBySessionIdFromAppointmnetListResponse: PatientCheckInBySessionIdFromAppointmnetListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientCheckInBySessionIdFromAppointmnetListActionTypes => {
  return {
    type: PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_MSG,
    payload: patientCheckInBySessionIdFromAppointmnetListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const patientCheckInBySessionIdFromAppointmnetListAPIResClearAction = (
  patientCheckInBySessionIdFromAppointmnetListResponse: PatientCheckInBySessionIdFromAppointmnetListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientCheckInBySessionIdFromAppointmnetListActionTypes => {
  return {
    type: PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_RES,
    payload: patientCheckInBySessionIdFromAppointmnetListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
