import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  AppoinmentListOfHealthcampActionTypes } from "./ActionTypes";
import { GetAppointmentListHealthcampBody, GetAppointmentListHealthcampRes } from "./Model";
import {
   getAppoinmentListOfHealthcampAPIResClearAction,
   getAppoinmentListOfHealthcampErrorAction,
   getAppoinmentListOfHealthcampLoadingAction,
   getAppoinmentListOfHealthcampSuccessAction,
   getAppoinmentListOfHealthcampUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetAppointmentListHealthcampRes;
export const  getAppointmentListOfHealthcampInstance = (payload:GetAppointmentListHealthcampBody,  pageNo: any,) => {
  return function (dispatch: Dispatch< AppoinmentListOfHealthcampActionTypes>) {
    dispatch( getAppoinmentListOfHealthcampLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthCampInstancePatientAppointment/get_patient_appointment_list_of_instance?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( getAppoinmentListOfHealthcampLoadingAction(false));
        dispatch(
           getAppoinmentListOfHealthcampSuccessAction(
            res.data !== undefined ? res.data : ({} as GetAppointmentListHealthcampRes),
            res.data.message !== undefined
              ? res.data.message
              : "Appoinment List of Healthcamp fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( getAppoinmentListOfHealthcampLoadingAction(false));
        dispatch(
           getAppoinmentListOfHealthcampErrorAction(
            {} as GetAppointmentListHealthcampRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAppointmentListOfHealthcampAPIResMsg = () => {
  return function (dispatch: Dispatch< AppoinmentListOfHealthcampActionTypes>) {
    dispatch(
       getAppoinmentListOfHealthcampUpdateAPIMsgAction(apiRes as GetAppointmentListHealthcampRes, "", "", 0),
    );
  };
};

export const clearAppointmentListOfHealthcampAPIRes = () => {
  return function (dispatch: Dispatch< AppoinmentListOfHealthcampActionTypes>) {
    dispatch( getAppoinmentListOfHealthcampAPIResClearAction({} as GetAppointmentListHealthcampRes, "", "", 0));
  };
};
