import { TelehealthMeetingListResponse } from "./Model";

export const TELEHEALTH_LIST_LOADING = "TELEHEALTH_LIST_LOADING";
export const TELEHEALTH_LIST_SUCCESS = "TELEHEALTH_LIST_SUCCESS";
export const TELEHEALTH_LIST_FAIL = "TELEHEALTH_LIST_FAIL";
export const TELEHEALTH_LIST_API_MSG = "TELEHEALTH_LIST_API_MSG";
export const TELEHEALTH_LIST_CLEAR_API_RES = "TELEHEALTH_LIST_CLEAR_API_RES";
export interface TeleHealthDataState {
  teleHealthRes: TelehealthMeetingListResponse;
  loading: boolean;
  message: string;
  status: number;
}
export interface TeleHealthLoading {
  type: typeof TELEHEALTH_LIST_LOADING;
  loading: boolean;
}
export interface TeleHealthSuccess {
  type: typeof TELEHEALTH_LIST_SUCCESS;
  payload: TelehealthMeetingListResponse;
}
export interface TeleHealthFail {
  type: typeof TELEHEALTH_LIST_FAIL;
  payload: TelehealthMeetingListResponse;
  message: string;
  status: number;
}
export interface TeleHealthAPIMsg {
  type: typeof TELEHEALTH_LIST_API_MSG;
  payload: TelehealthMeetingListResponse;
  message: string;
  status: number;
}
export interface TeleHealthAPIRes {
  type: typeof TELEHEALTH_LIST_CLEAR_API_RES;
  payload: TelehealthMeetingListResponse;
  message: string;
  status: number;
}

export type TelehealthListActionTypes =
  | TeleHealthLoading
  | TeleHealthSuccess
  | TeleHealthFail
  | TeleHealthAPIMsg
  | TeleHealthAPIRes;
