import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Button,
  CardHeader,
  Autocomplete,
  TextField,
  Alert,
  Snackbar,
  AlertColor,
  Paper,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../redux/store/Store";
import MWExceptionList from "../../../component/MWExceptionList";
import { GetHealthCampByOrganizationBody } from "../../../redux/getMappedHealthcampsByOrganization/Model";
import { getHealthCampByOrganizationList } from "../../../redux/getMappedHealthcampsByOrganization/ApiCall";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getHealthCampDropDownListApi } from "../../../redux/getAllHealthcampDropdownList/API";
import { CreateHealthCampByOrganizationBody } from "../../../redux/createMappingHealthcampByOrganization/Model";
import { createHealthCampByOrganizationList } from "../../../redux/createMappingHealthcampByOrganization/ApiCall";
import MWSnackbar from "../../../component/MWSnackbar";
import { HealthCampSearchByOrganizationIdBody } from "../../../redux/getAllHealthcampDropdownList/Model";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ImportPatientDialog from "./ImportPatientDialog";

const CustomPaper = (props: any) => {
  return <Paper elevation={10} {...props} />;
};

export default function HealthcampListByOrganization(props: any) {
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const { viewMappedHealcampListLoader } = props;
  const dispatch = useDispatch();
  const [, setHealthCampDropDownListAPIError] = React.useState("");
  const [healthCampMappedMessage, setHealthCampMappedMessage] = React.useState("");
  const [movePatientMessage, setMovePatientMessage] = React.useState("");
  const [movePatientType, setMovePatientType] = useState<AlertColor>("success");
  const [healthCampMappedErrorMessage, setHealthCampMappedErrorMessage] = React.useState("");
  const [, setIsLoading] = React.useState(false);
  const [snackBarType, setSnackBarType] = React.useState<AlertColor>("success");
  const [errMessageType, setErrMessageType] = useState<AlertColor>("success");
  const [searchPatient, setSearchPatient] = useState("");
  const [dialogState, setDialogState] = useState(false);
  const [selectedHealthCampId, setSelectedHealthCampId] = useState("");

  const PatientSearchByOrganizationBody = {
    searchKey: searchPatient,
  } as HealthCampSearchByOrganizationIdBody;

  const history = useHistory();
  function gotoDetails(id: number) {
    history.push(`/patientlistbyhealthcamp/${id}`);
  }

  const updateDialogStateFromChild = (passedValue: boolean) => {
    setDialogState(passedValue);
  };

  const [healthCampExistingMessage, setHealthCampExistingMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleBannerDismiss = () => {
    setHealthCampMappedMessage("");
  };
  const addHealthCampMappedSuccessMessage = healthCampMappedMessage ? (
    <Box my={3}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleBannerDismiss}>
        <Alert onClose={handleClick} severity={snackBarType!} sx={{ width: "100%" }}>
          {healthCampMappedMessage}
        </Alert>
      </Snackbar>
    </Box>
  ) : null;

  const [toastErrorAuthorisedExistingMessageToastActive, setErrorAuthorisedPatientListToastActive] =
    useState(false);
  const toggleErrorAuthorisedExistingMessageActive = () => {
    setErrorAuthorisedPatientListToastActive(
      (toastErrorAuthorisedExistingMessageToastActive) =>
        !toastErrorAuthorisedExistingMessageToastActive,
    );
  };

  const toastErrorAuthorisedExistingMessageMarkup =
    toastErrorAuthorisedExistingMessageToastActive ? (
      <Snackbar
        open={toastErrorAuthorisedExistingMessageToastActive}
        autoHideDuration={6000}
        onClose={toggleErrorAuthorisedExistingMessageActive}
      >
        <Alert
          severity="error"
          onClose={toggleErrorAuthorisedExistingMessageActive}
          sx={{ width: "100%" }}
        >
          {healthCampExistingMessage}
        </Alert>
      </Snackbar>
    ) : null;
  const handleErrorBannerDismiss = () => {
    setHealthCampMappedErrorMessage("");
  };
  // Health Camp Drop Down list
  const [healthCampDropDownListValue, setHealthCampDropDownListValue] = React.useState([] as any[]);
  // select Health Camp Drop Down Values from dropdown
  const [selectedHealthCamp, setSelectedHealthCamp] = React.useState("");
  const filterHealthcampId = (healthCampId: any, _e: any) => {
    setSelectedHealthCamp(healthCampId.id);
  };
  // get Health Camp Drop Down from child component
  // Call Health Camp Drop Down List store
  const getHealthCampDropDownListRes = useSelector(
    (state: AppState) => state.getHealthCampDropDownListValue,
  );

  const addHealthCampValue = useSelector((state: AppState) => state.createHealthCampByOrganization);
  const movePatientRes = useSelector((state: AppState) => state.movePatientToOrgValue);

  useEffect(() => {
    if (movePatientRes?.MovePatientToOrganizationRes?.message !== undefined) {
      setMovePatientMessage(movePatientRes?.successMsg);
      setMovePatientType("success");
    }
    if (movePatientRes?.errorMsg !== "") {
      setMovePatientMessage(movePatientRes?.errorMsg);
      setMovePatientType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movePatientRes]);
  const addHealthcampWithOrg = () => {
    if (selectedHealthCamp !== "") {
      const filterPatientListBody = {
        healthCampId: String(selectedHealthCamp),
        organizationId: organizationid,
      } as CreateHealthCampByOrganizationBody;
      dispatch(createHealthCampByOrganizationList(filterPatientListBody));
    } else {
      setHealthCampMappedErrorMessage("Please Select Healthcamp from list");
      setErrMessageType("error");
    }
  };
  // take following actions when Health Camp Drop Down list api store updated
  useEffect(() => {
    setIsLoading(getHealthCampDropDownListRes?.loading);
    if (getHealthCampDropDownListRes?.getHealthCampDropDownListRes?.HealthCampList !== undefined) {
      let healthCampDropdownValue =
        getHealthCampDropDownListRes?.getHealthCampDropDownListRes?.HealthCampList.map(
          (element: any) => ({
            id: element.id,
            displayName: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setHealthCampDropDownListValue(healthCampDropdownValue);

      if (getHealthCampDropDownListRes?.errorMsg !== "") {
        setHealthCampDropDownListAPIError(getHealthCampDropDownListRes?.errorMsg);
      }
    }
  }, [getHealthCampDropDownListRes]);

  useEffect(() => {
    setIsLoading(addHealthCampValue?.loading);
    if (addHealthCampValue?.errorMsg !== "") {
      setHealthCampExistingMessage(addHealthCampValue?.errorMsg);
      setErrorAuthorisedPatientListToastActive(true);
    }

    if (
      addHealthCampValue?.getDoctorWorkScheduleListRes?.message !== undefined &&
      addHealthCampValue?.getDoctorWorkScheduleListRes?.message !== ""
    ) {
      setHealthCampMappedMessage(addHealthCampValue?.getDoctorWorkScheduleListRes?.message);
      setSnackBarType("success");
      handleClick();
      dispatch(getHealthCampByOrganizationList(mappedHealthCampByOrgBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHealthCampValue]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "displayName",
      headerName: "Health Camp Name",
      width: 420,
      flex: 1,
    },
    {
      field: "ownerFirstName",
      headerName: "Health Camp Owner Name",
      width: 300,
    },
    {
      field: "dateCreated",
      headerName: "Health Camp Created Date",
      width: 250,
      flex: 1,
    },
    {
      field: "importdata",
      headerName: "Import Patient",
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<ImportExportIcon />}
          onClick={() => {
            setDialogState(true);
            setSelectedHealthCampId(params.row.id);
          }}
        >
          Import Patient
        </Button>
      ),
    },
  ];

  const mappedHealthCampByOrgBody = {
    organizationId: organizationid,
  } as GetHealthCampByOrganizationBody;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getHealthCampByOrganizationList(mappedHealthCampByOrgBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.getHealthCampByOrganizationList,
  );
  const healthcampValues =
    AdminAllHealthcampList.getHealthCampByOrganizationListRes?.healthcampList;

  useEffect(() => {
    if (healthcampValues !== undefined) {
      let dataPointArr = healthcampValues.map((element: any) => ({
        id: element.id,
        displayName: element.displayName !== "" ? element.displayName : "",
        ownerFirstName: element.ownerFirstName !== "" ? element.ownerFirstName : "Not Set",
        dateCreated: dayjs(element.dateCreated).format("ddd, MMM D, YYYY"),
        importdata: element.id,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);

  const DataGridMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Autocomplete
                  fullWidth
                  id="healthcamp-id"
                  getOptionLabel={(option) => `${option.displayName}`}
                  options={healthCampDropDownListValue}
                  onChange={(event: any, newValue: any) => {
                    filterHealthcampId(newValue, event);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      dispatch(getHealthCampDropDownListApi(PatientSearchByOrganizationBody, 1));
                      ev.preventDefault();
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Please Search health camp for mapping"
                      variant="outlined"
                    />
                  )}
                  onInputChange={(_event, newInputValue) => {
                    setSearchPatient(newInputValue);
                  }}
                  disableClearable
                  size="small"
                  sx={{ width: 500 }}
                  PaperComponent={CustomPaper}
                />
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => addHealthcampWithOrg()}>
                  Add Healthcamp Mapping
                </Button>
              </Grid>
              <Grid item>{addHealthCampMappedSuccessMessage}</Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {healthcampValues && healthcampValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setMovePatientMessage("");
    }
  };
  return (
    <Box>
      {AdminAllHealthcampList.loading === true || addHealthCampValue.loading === true
        ? viewMappedHealcampListLoader(true)
        : viewMappedHealcampListLoader(false)}

      {DataGridMarkup}
      <MWSnackbar
        msg={healthCampMappedErrorMessage}
        type={errMessageType}
        alertClose={handleErrorBannerDismiss}
      />
      {movePatientMessage !== "" ? (
        <MWSnackbar msg={movePatientMessage} type={movePatientType} alertClose={addImgtoComp} />
      ) : null}
      {toastErrorAuthorisedExistingMessageMarkup}
      <ImportPatientDialog
        dialogState={dialogState}
        updateDialogState={updateDialogStateFromChild}
        selectedHealthCampId={selectedHealthCampId}
      />
    </Box>
  );
}
