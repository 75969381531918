import { GET_LIST } from "../../types/ActionTypes";
import { SymptomListResponse } from "./Model";

// Patient Demographics Loading State
export const SYMPTOM_LOADING = "SYMPTOM_LOADING";
export const SYMPTOM_SUCCESS = "SYMPTOM_SUCCESS";
export const SYMPTOM_FAIL = "SYMPTOM_FAIL";

export interface GetPatientDemographicsState {
  templateList: SymptomListResponse;
  loading: boolean;
}
export interface ExecutiveListLoading {
  type: typeof SYMPTOM_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof SYMPTOM_SUCCESS;
  payload: SymptomListResponse;
}
export interface ExecutiveListFail {
  type: typeof SYMPTOM_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: SymptomListResponse;
}

export type SymptomListActionTypes =
  | GetDoctorAction
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail;
