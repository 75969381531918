import {
    TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL,
    TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING,
    TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS,
    TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG,
    TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES,
    ToggleActiveOrgRegistrationRequestActionTypes,
  } from "./ActionTypes";
  import { ToggleActiveOrgRegReqRes } from "./Model";
  
  export const toggleActiveOrgRegistrationRequestLoadingAction = (
    loading: boolean,
  ): ToggleActiveOrgRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING,
      loading: loading,
    };
  };
  
  export const toggleActiveOrgRegistrationRequestSuccessAction = (
    toggleActiveOrgRegistrationRequestresponse: ToggleActiveOrgRegReqRes,
    successMsg: string,
  ): ToggleActiveOrgRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS,
      payload: toggleActiveOrgRegistrationRequestresponse,
      successMsg: successMsg,
    };
  };
  
  export const toggleActiveOrgRegistrationRequestErrorAction = (
    toggleActiveOrgRegistrationRequestresponse: ToggleActiveOrgRegReqRes,
    errMsg: string,
    status: number,
  ): ToggleActiveOrgRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL,
      payload: toggleActiveOrgRegistrationRequestresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const toggleActiveOrgRegistrationRequestUpdateAPIMsgAction = (
    toggleActiveOrgRegistrationRequestresponse: ToggleActiveOrgRegReqRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): ToggleActiveOrgRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG,
      payload: toggleActiveOrgRegistrationRequestresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const toggleActiveOrgRegistrationRequestAPIResClearAction = (
    toggleActiveOrgRegistrationRequestresponse: ToggleActiveOrgRegReqRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): ToggleActiveOrgRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES,
      payload: toggleActiveOrgRegistrationRequestresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  