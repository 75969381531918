import axios from "axios";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";
import {
  executiveListAPIResClearAction,
  executiveListErrorAction,
  executiveListLoadingAction,
  executiveListSuccessAction,
  executiveListUpdateAPIMsgAction,
} from "./Actions";
import { Dispatch } from "react";
import { ExecutiveListActionTypes } from "./ActionTypes";
import { headers } from "component/Utility";
import Url from "../../../ApiConfig";
import { ExecutiveListRes } from "./Model";

let apiRes = {} as ExecutiveListRes;
export const executiveList = () => {
  return function (dispatch: Dispatch<ExecutiveListActionTypes>) {
    dispatch(executiveListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/adminapp/api/executives/executive_list_all`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(executiveListLoadingAction(false));
        dispatch(
          executiveListSuccessAction(
            res.data !== undefined ? res.data : ({} as ExecutiveListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Executive List has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(executiveListLoadingAction(false));
        dispatch(
          executiveListErrorAction(
            {} as ExecutiveListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateExecutiveListAPIResMsg = () => {
  return function (dispatch: Dispatch<ExecutiveListActionTypes>) {
    dispatch(executiveListUpdateAPIMsgAction(apiRes as ExecutiveListRes, "", "", 0));
  };
};

export const clearExecutiveListAPIRes = () => {
  return function (dispatch: Dispatch<ExecutiveListActionTypes>) {
    dispatch(executiveListAPIResClearAction({} as ExecutiveListRes, "", "", 0));
  };
};
