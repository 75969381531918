import { GetPatientTeamTeamRes } from "./Model";

export const GET_PATIENT_TEAM_BY_ID_LOADING = "GET_PATIENT_TEAM_BY_ID_LOADING";
export const GET_PATIENT_TEAM_BY_ID_SUCCESS = "GET_PATIENT_TEAM_BY_ID_SUCCESS";
export const GET_PATIENT_TEAM_BY_ID_FAIL = "GET_PATIENT_TEAM_BY_ID_FAIL";
export const GET_PATIENT_TEAM_BY_ID = "GET_PATIENT_TEAM_BY_ID";
export const GET_PATIENT_TEAM_BY_ID_MSG = "GET_PATIENT_TEAM_BY_ID_MSG";
export const GET_PATIENT_TEAM_BY_ID_RES = "GET_PATIENT_TEAM_BY_ID_RES";

export interface GetPatientTeamDetails {
  getPatientRes: GetPatientTeamTeamRes;
  loading: boolean;
  message: string;
  status: number;
  errRes: string;
}
export interface GetPatientTeamLoading {
  type: typeof GET_PATIENT_TEAM_BY_ID_LOADING;
  loading: boolean;
}
export interface GetPatientTeamSuccess {
  type: typeof GET_PATIENT_TEAM_BY_ID_SUCCESS;
  payload: GetPatientTeamTeamRes;
}
export interface GetPatientTeamFail {
  type: typeof GET_PATIENT_TEAM_BY_ID_FAIL;
  payload: GetPatientTeamTeamRes;
  message: string;
  status: number;
}

export interface GetPatientTeamUpdateAPIMsg {
  type: typeof GET_PATIENT_TEAM_BY_ID_MSG;
  payload: GetPatientTeamTeamRes;
  message: string;
  status: number;
}
export interface GetPatientTeamUpdateAPIRes {
  type: typeof GET_PATIENT_TEAM_BY_ID_RES;
  payload: GetPatientTeamTeamRes;
  message: string;
  status: number;
}
interface GetPatientTeamAction {
  type: typeof GET_PATIENT_TEAM_BY_ID;
  payload: GetPatientTeamTeamRes;
  errRes: string;
}

export type GetPatientDetailsByIdActionTypes =
  | GetPatientTeamAction
  | GetPatientTeamLoading
  | GetPatientTeamSuccess
  | GetPatientTeamFail
  | GetPatientTeamUpdateAPIMsg
  | GetPatientTeamUpdateAPIRes;
