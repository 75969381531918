import { GetOrganizationAdminListRes } from "./Model";
export const GET_ORGANIZATION_ADMIN_LIST_LOADING = "GET_ORGANIZATION_ADMIN_LIST_LOADING";
export const GET_ORGANIZATION_ADMIN_LIST_SUCCESS = "GET_ORGANIZATION_ADMIN_LIST_SUCCESS";
export const GET_ORGANIZATION_ADMIN_LIST_FAIL = "GET_ORGANIZATION_ADMIN_LIST_FAIL";
export const GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_MSG = "GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_MSG";
export const GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_RES = "GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_RES";

export interface GetOrganizationAdminListState {
  getOrganizationAdminList: GetOrganizationAdminListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationAdminListLoading {
  type: typeof GET_ORGANIZATION_ADMIN_LIST_LOADING;
  loading: boolean;
}
export interface GetOrganizationAdminListSuccess {
  type: typeof GET_ORGANIZATION_ADMIN_LIST_SUCCESS;
  payload: GetOrganizationAdminListRes;
  successMsg: string;
}
export interface GetOrganizationAdminListFail {
  type: typeof GET_ORGANIZATION_ADMIN_LIST_FAIL;
  payload: GetOrganizationAdminListRes;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationAdminListUpdateAPIMsg {
  type: typeof GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_MSG;
  payload: GetOrganizationAdminListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationAdminListUpdateAPIRes {
  type: typeof GET_ORGANIZATION_ADMIN_LIST_UPDATE_API_RES;
  payload: GetOrganizationAdminListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetOrganizationAdminListActionTypes =
  | GetOrganizationAdminListLoading
  | GetOrganizationAdminListSuccess
  | GetOrganizationAdminListFail
  | GetOrganizationAdminListUpdateAPIMsg
  | GetOrganizationAdminListUpdateAPIRes;
