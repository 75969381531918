import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { VitalCollectionActiveInactiveStatusActionTypes } from "./ActionTypes";
import { VitalCollectionActiveInactiveStatusBody, VitalCollectionActiveInactiveStatusRes } from "./Model";
import {
  vitalCollectionActiveInactiveStatusAPIResClearAction,
  vitalCollectionActiveInactiveStatusErrorAction,
  vitalCollectionActiveInactiveStatusLoadingAction,
  vitalCollectionActiveInactiveStatusSuccessAction,
  vitalCollectionActiveInactiveStatusUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as VitalCollectionActiveInactiveStatusRes;
export const vitalCollectionActiveInactiveStatusApi = (
  payload: VitalCollectionActiveInactiveStatusBody
) => {
  return function (dispatch: Dispatch<VitalCollectionActiveInactiveStatusActionTypes>) {
    dispatch(vitalCollectionActiveInactiveStatusLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampSessionVitalsCollection/toggle_vitalCollectionRequest_status_in_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(vitalCollectionActiveInactiveStatusLoadingAction(false));
        dispatch(
          vitalCollectionActiveInactiveStatusSuccessAction(
            res.data !== undefined ? res.data : ({} as VitalCollectionActiveInactiveStatusRes),
            res.data.message !== undefined
              ? res.data.message
              : "Vital Collection Request status of is now 1",
          ),
        );
      })
      .catch((error) => {
        dispatch(vitalCollectionActiveInactiveStatusLoadingAction(false));
        dispatch(
          vitalCollectionActiveInactiveStatusErrorAction(
            {} as VitalCollectionActiveInactiveStatusRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateVitalCollectionActiveInactiveStatusAPIResMsg = () => {
  return function (dispatch: Dispatch<VitalCollectionActiveInactiveStatusActionTypes>) {
    dispatch(
      vitalCollectionActiveInactiveStatusUpdateAPIMsgAction(
        apiRes as VitalCollectionActiveInactiveStatusRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearVitalCollectionActiveInactiveStatusAPIRes = () => {
  return function (dispatch: Dispatch<VitalCollectionActiveInactiveStatusActionTypes>) {
    dispatch(
      vitalCollectionActiveInactiveStatusAPIResClearAction({} as VitalCollectionActiveInactiveStatusRes, "", "", 0),
    );
  };
};
