import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  Container,
  Stack,
  Typography,
  Divider,
  AlertColor,
  Link,
} from "@mui/material";
import AdminLoader from "../AdminLoader";
import { AppState } from "../../redux/store/Store";
import MWPageTitle from "../../component/MWPageTitle";
import {
  getPreferenceDetails,
  updatePreferenceAPIResMsg,
} from "../../redux/Preference/GetPreference/API";
import SetPreferenceDialog from "./SetPreferenceDialog";
import { CareGiverDefaultPreferences } from "../../redux/Preference/GetPreference/Model";
import MWSnackbar from "component/MWSnackbar";
import { clearSetPreferenceAPIRes } from "redux/Preference/SetPreference/API";
import { useHistory } from "react-router-dom";

export default function Preference() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingMarkup = <AdminLoader />;
  const allPreferenceValues = useSelector((state: AppState) => state.getPreferenceDetails);
  const preferenceValue = allPreferenceValues?.GetPreferenceDetailsResponse;
  const [showSetPreferenceDialog, setShowSetPreferenceDialog] = React.useState(false);

  const setPreferenceDialogState = () => {
    setShowSetPreferenceDialog(true);
  };
  const updatePreferenceDialogValFromChild = (passedVal: boolean) => {
    setShowSetPreferenceDialog(passedVal);
  };

  const [preferenceList, setPreferenceList] = useState({} as CareGiverDefaultPreferences);

  useEffect(() => {
    dispatch(getPreferenceDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (preferenceValue !== undefined) {
      setPreferenceList(preferenceValue.CareGiverDefaultPreferences);
    } else {
      setPreferenceList({} as CareGiverDefaultPreferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPreferenceValues]);

  function reloadPreference() {
    dispatch(getPreferenceDetails());
  }

  const [getPreferenceAPIMsgType, setGetPreferenceAPIMsgType] = useState<AlertColor>("success");
  const [getPreferenceAPIMsg, setGetPreferenceAPIMsg] = useState("");
  // Call Set Preference Store
  useEffect(() => {
    if (allPreferenceValues?.errorMsg !== "") {
      setGetPreferenceAPIMsgType("error");
      setGetPreferenceAPIMsg(allPreferenceValues?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPreferenceValues]);
  const getPreferenceCloseAction = (passedVal: boolean) => {
    if (passedVal) {
      setGetPreferenceAPIMsg("");
      dispatch(updatePreferenceAPIResMsg());
    }
  };

  const [setPreferenceAPIMsgType, setSetPreferenceAPIMsgType] = useState<AlertColor>("success");
  const [setPreferenceAPIMsg, setSetPreferenceAPIMsg] = useState("");
  // Call Set Preference Store
  const setPreferenceValueRes = useSelector((state: AppState) => state.setPreferenceValue);
  useEffect(() => {
    if (setPreferenceValueRes?.successMsg !== "") {
      setSetPreferenceAPIMsgType("success");
      setSetPreferenceAPIMsg(setPreferenceValueRes?.successMsg);
    }
    if (setPreferenceValueRes?.errorMsg !== "") {
      setSetPreferenceAPIMsgType("error");
      setSetPreferenceAPIMsg(setPreferenceValueRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPreferenceValueRes]);
  const alertCloseAction = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getPreferenceDetails());
      setSetPreferenceAPIMsg("");
      dispatch(clearSetPreferenceAPIRes());
    }
  };
  const gotoOrganization = (organizationId: number) => {
    history.push(`/patientlistbyorganization/${organizationId}`);
  };
  const gotoHealthCamp = (healthCampId: number) => {
    history.push(`/patientlistbyhealthcamp/${healthCampId}`);
  };

  const gotoInstanceList = (healthCampId: number, instanceId: number) => {
    history.push(`/healthcampinstance/${healthCampId}/${instanceId}`);
  };

  const gotoSessionSection = (
    healthCampId: number,
    instanceId: number,
    healthcampSessionId: number,
  ) => {
    history.push(
      `/healthcampinstance_session/${healthCampId}/${instanceId}/${healthcampSessionId}`,
    );
  };

  return (
    <Box>
      {allPreferenceValues.loading ? loadingMarkup : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle title="Preference" reload={true} reloadAction={reloadPreference} />
            </Grid>
            <Grid item>
              <Box>
                <Button variant="contained" onClick={() => setPreferenceDialogState()}>
                  Set Preference
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Card>
            <CardContent>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                sx={{ pt: 2 }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Organization Name :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        gotoOrganization(preferenceList?.organizationId);
                      }}
                    >
                      {preferenceList?.organizationName ? (
                        <Typography variant="body1">{preferenceList?.organizationName}</Typography>
                      ) : (
                        <Typography variant="body1">N/A</Typography>
                      )}
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Staff Name :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    {preferenceList?.staffName ? (
                      <Typography variant="body1">{preferenceList?.staffName}</Typography>
                    ) : (
                      <Typography variant="body1">N/A</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Healthcamp Name :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        gotoHealthCamp(preferenceList?.healthcampId);
                      }}
                    >
                      {preferenceList?.healthcampName ? (
                        <Typography variant="body1">{preferenceList?.healthcampName}</Typography>
                      ) : (
                        <Typography variant="body1">N/A</Typography>
                      )}
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Instance Name :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        gotoInstanceList(
                          preferenceList?.healthcampId,
                          preferenceList?.healthcampInstanceId,
                        );
                      }}
                    >
                      {preferenceList?.healthcampInstanceName ? (
                        <Typography variant="body1">
                          {preferenceList?.healthcampInstanceName}
                        </Typography>
                      ) : (
                        <Typography variant="body1">N/A</Typography>
                      )}
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Session Name :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        gotoSessionSection(
                          preferenceList?.healthcampId,
                          preferenceList?.healthcampInstanceId,
                          preferenceList?.healthcampSessionId,
                        );
                      }}
                    >
                      {preferenceList?.healthcampSessionName ? (
                        <Typography variant="body1">
                          {preferenceList?.healthcampSessionName}
                        </Typography>
                      ) : (
                        <Typography variant="body1">N/A</Typography>
                      )}
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Preference Id :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    {preferenceList?.id ? (
                      <Typography variant="body1">{preferenceList?.id}</Typography>
                    ) : (
                      <Typography variant="body1">N/A</Typography>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </CardContent>
          </Card>
          {showSetPreferenceDialog ? (
            <SetPreferenceDialog
              setPreferenceDialogEnable={showSetPreferenceDialog}
              updateDialogState={updatePreferenceDialogValFromChild}
            />
          ) : null}
        </Stack>
      </Container>
      {getPreferenceAPIMsg !== "" && getPreferenceAPIMsgType === "error" ? (
        <MWSnackbar
          msg={getPreferenceAPIMsg}
          type={getPreferenceAPIMsgType}
          alertClose={getPreferenceCloseAction}
        />
      ) : null}
      {setPreferenceAPIMsg !== "" ? (
        <MWSnackbar
          msg={setPreferenceAPIMsg}
          type={setPreferenceAPIMsgType}
          alertClose={alertCloseAction}
        />
      ) : null}
    </Box>
  );
}
