import {
  GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL,
  GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING,
  GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS,
  GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG,
  GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES,
  GetPendingPatientDetailsTypeListBySessionIdActionTypes,
} from "./ActionTypes";
import { GetPendingPatientDetailsTypeListBySessionIdRes } from "./Model";

export const getPendingPatientDetailsTypeListBySessionIdLoadingAction = (
  loading: boolean,
): GetPendingPatientDetailsTypeListBySessionIdActionTypes => {
  return {
    type: GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const getPendingPatientDetailsTypeListBySessionIdSuccessAction = (
  getPendingPatientDetailsTypeListBySessionIdResponse: GetPendingPatientDetailsTypeListBySessionIdRes,
  successMsg: string,
): GetPendingPatientDetailsTypeListBySessionIdActionTypes => {
  return {
    type: GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS,
    payload: getPendingPatientDetailsTypeListBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const getPendingPatientDetailsTypeListBySessionIdErrorAction = (
  getPendingPatientDetailsTypeListBySessionIdResponse: GetPendingPatientDetailsTypeListBySessionIdRes,
  errMsg: string,
  status: number,
): GetPendingPatientDetailsTypeListBySessionIdActionTypes => {
  return {
    type: GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL,
    payload: getPendingPatientDetailsTypeListBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPendingPatientDetailsTypeListBySessionIdUpdateAPIMsgAction = (
  getPendingPatientDetailsTypeListBySessionIdResponse: GetPendingPatientDetailsTypeListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPendingPatientDetailsTypeListBySessionIdActionTypes => {
  return {
    type: GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG,
    payload: getPendingPatientDetailsTypeListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPendingPatientDetailsTypeListBySessionIdAPIResClearAction = (
  getPendingPatientDetailsTypeListBySessionIdResponse: GetPendingPatientDetailsTypeListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPendingPatientDetailsTypeListBySessionIdActionTypes => {
  return {
    type: GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES,
    payload: getPendingPatientDetailsTypeListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
