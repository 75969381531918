import {
  DOCTOR_QURIES_LIST_CLEAR_API_RES,
  DOCTOR_QURIES_LIST_FAIL,
  DOCTOR_QURIES_LIST_LOADING,
  DOCTOR_QURIES_LIST_SUCCESS,
  DOCTOR_QURIES_LIST_API_MSG,
  DoctorQuriesListActionTypes,
} from "./ActionTypes";
import { DoctorQuriesListResponse } from "./Model";

export const doctorQuriesListLoadingAction = (
  loading: boolean
): DoctorQuriesListActionTypes => {
  return {
    type: DOCTOR_QURIES_LIST_LOADING,
    loading: loading,
  };
};

export const doctorQuriesListSuccessAction = (
  executiveRes: DoctorQuriesListResponse
): DoctorQuriesListActionTypes => {
  return {
    type: DOCTOR_QURIES_LIST_SUCCESS,
    payload: executiveRes,
  };
};

export const executiveListErrorAction = (
  executiveRes: DoctorQuriesListResponse,
  errMsg: string,
  status: number
): DoctorQuriesListActionTypes => {
  return {
    type: DOCTOR_QURIES_LIST_FAIL,
    payload: executiveRes,
    message: errMsg,
    status: status,
  };
};

export const executiveListUpdateAPIMsgAction = (
  executiveRes: DoctorQuriesListResponse,
  errMsg: string,
  status: number
): DoctorQuriesListActionTypes => {
  return {
    type: DOCTOR_QURIES_LIST_API_MSG,
    payload: executiveRes,
    message: errMsg,
    status: status,
  };
};

export const executiveListAPIResClearAction = (
  executiveRes: DoctorQuriesListResponse,
  errMsg: string,
  status: number
): DoctorQuriesListActionTypes => {
  return {
    type: DOCTOR_QURIES_LIST_CLEAR_API_RES,
    payload: executiveRes,
    message: errMsg,
    status: status,
  };
};
