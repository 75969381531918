import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DiactiveStaffOfOrgActionTypes } from "./ActionTypes";
import { DiactiveStaffOfOrgBody, DiactiveStaffOfOrgDetails } from "./Model";
import {
  DiactiveStaffOfOrgAPIResClearAction,
  DiactiveStaffOfOrgErrorAction,
  DiactiveStaffOfOrgLoadingAction,
  DiactiveStaffOfOrgSuccessAction,
  DiactiveStaffOfOrgAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as DiactiveStaffOfOrgDetails;
export const diactiveStaffOfOrgApiCall = (payload: DiactiveStaffOfOrgBody) => {
  return function (dispatch: Dispatch<DiactiveStaffOfOrgActionTypes>) {
    dispatch(DiactiveStaffOfOrgLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/adminapp/api/patientorganization/toggle_active_staff_organization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DiactiveStaffOfOrgLoadingAction(false));
        dispatch(
          DiactiveStaffOfOrgSuccessAction(
            res.data !== undefined ? res.data : ({} as DiactiveStaffOfOrgDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(DiactiveStaffOfOrgLoadingAction(false));
        dispatch(
          DiactiveStaffOfOrgErrorAction(
            {} as DiactiveStaffOfOrgDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const diactiveStaffOfAPIResMsg = () => {
  return function (dispatch: Dispatch<DiactiveStaffOfOrgActionTypes>) {
    dispatch(DiactiveStaffOfOrgAPIMsgAction(apiRes as DiactiveStaffOfOrgDetails, "", "", 0));
  };
};

export const clearDiactiveStaffOfAPIRes = () => {
  return function (dispatch: Dispatch<DiactiveStaffOfOrgActionTypes>) {
    dispatch(DiactiveStaffOfOrgAPIResClearAction({} as DiactiveStaffOfOrgDetails, "", "", 0));
  };
};
