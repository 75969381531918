import {
  EditDataCurationProblemActionTypes,
  EDIT_DATA_CURATION_PROBLEM_FAIL,
  EDIT_DATA_CURATION_PROBLEM_LOADING,
  EDIT_DATA_CURATION_PROBLEM_SUCCESS,
  EditDataCurationProblemState,
  EDIT_DATA_CURATION_PROBLEM_API_MSG,
  EDIT_DATA_CURATION_PROBLEM_API_RES,
} from "./ActionTypes";
import { EditDataCurationProblemDetails } from "./Model";

const initialStateGetPosts: EditDataCurationProblemState = {
  loading: false,
  editDataCurationProblemRes: {} as EditDataCurationProblemDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editDataCurationProblemReducer = (
  state = initialStateGetPosts,
  action: EditDataCurationProblemActionTypes,
): EditDataCurationProblemState => {
  switch (action.type) {
    case EDIT_DATA_CURATION_PROBLEM_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DATA_CURATION_PROBLEM_SUCCESS:
      return {
        ...state,
        editDataCurationProblemRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DATA_CURATION_PROBLEM_FAIL:
      return {
        ...state,
        editDataCurationProblemRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DATA_CURATION_PROBLEM_API_MSG:
      return {
        ...state,
        editDataCurationProblemRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DATA_CURATION_PROBLEM_API_RES:
      return {
        ...state,
        editDataCurationProblemRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
