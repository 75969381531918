import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { OrganizationAddRegistrationRequestActionTypes } from "./ActionTypes";
import { OrganizationAddRegistrationRequestBody, OrganizationAddRegistrationRequestDetails } from "./Model";
import {
  OrganizationAddRegistrationRequestAPIResClearAction,
  OrganizationAddRegistrationRequestErrorAction,
  OrganizationAddRegistrationRequestLoadingAction,
  OrganizationAddRegistrationRequestSuccessAction,
  OrganizationAddRegistrationRequestAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as OrganizationAddRegistrationRequestDetails;
export const organizationAddRegistrationRequestApiCall = (payload: OrganizationAddRegistrationRequestBody) => {
  return function (dispatch: Dispatch<OrganizationAddRegistrationRequestActionTypes>) {
    dispatch(OrganizationAddRegistrationRequestLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/patientOrganizationRegistrationRequest/add_registration_request_patientOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(OrganizationAddRegistrationRequestLoadingAction(false));
        dispatch(
          OrganizationAddRegistrationRequestSuccessAction(
            res.data !== undefined ? res.data : ({} as OrganizationAddRegistrationRequestDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Registration request fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(OrganizationAddRegistrationRequestLoadingAction(false));
        dispatch(
          OrganizationAddRegistrationRequestErrorAction(
            {} as OrganizationAddRegistrationRequestDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateOrganizationAddRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch<OrganizationAddRegistrationRequestActionTypes>) {
    dispatch(OrganizationAddRegistrationRequestAPIMsgAction(apiRes as OrganizationAddRegistrationRequestDetails, "", "", 0));
  };
};

export const clearOrganizationAddRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch<OrganizationAddRegistrationRequestActionTypes>) {
    dispatch(OrganizationAddRegistrationRequestAPIResClearAction({} as OrganizationAddRegistrationRequestDetails, "", "", 0));
  };
};
