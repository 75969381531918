import {
  AddPatientTeamStateType,
  AddPatientTeamTypes,
  ADD_PATIENT_TEAM_LOADING,
  ADD_PATIENT_TEAM_SUCCESS,
  PatientTeamStateType,
  PatientTeamTypes,
  PATIENT_TEAM_FAIL,
  PATIENT_TEAM_LOADING,
  PATIENT_TEAM_SUCCESS,
  ADD_PATIENT_TEAM_FAIL,
} from "./ActionTypes";

// get appointment payment list
const initialStatePaymentList: PatientTeamStateType = {
  loading: false,
  ownerDetails: Object.assign({}),
  patientCasePhone: Object.assign({}),
  patientTeam: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const appointmentPatientTeamListReducer = (
  state = initialStatePaymentList,
  action: PatientTeamTypes,
): PatientTeamStateType => {
  switch (action.type) {
    case PATIENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        ownerDetails: action.ownerDetails,
        patientCasePhone: action.patientCasePhone,
        patientTeam: action.patientTeam,
        message: action.message,
        status: action.status,
      };
    case PATIENT_TEAM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PATIENT_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
// get appointment payment list
const initialStateAddPatientTeam: AddPatientTeamStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const addPatientTeamReducer = (
  state = initialStateAddPatientTeam,
  action: AddPatientTeamTypes,
): AddPatientTeamStateType => {
  switch (action.type) {
    case ADD_PATIENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_TEAM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_PATIENT_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
