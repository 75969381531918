import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetStaffListByCompetitionActionTypes } from "./ActionTypes";
import { GetStaffListByCompetitionBody, GetStaffListByCompetitionDetails } from "./Model";
import {
  getStaffListByCompetitionAPIResClearAction,
  getStaffListByCompetitionErrorAction,
  getStaffListByCompetitionLoadingAction,
  getStaffListByCompetitionSuccessAction,
  getStaffListByCompetitionAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetStaffListByCompetitionDetails;
export const getStaffListByCompition = (
  competionId: GetStaffListByCompetitionBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetStaffListByCompetitionActionTypes>) {
    dispatch(getStaffListByCompetitionLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionStaffList/staff_list_by_competition?page=${Number(
            pageNo,
          )}&size=10`,
          competionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getStaffListByCompetitionLoadingAction(false));
        dispatch(getStaffListByCompetitionSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getStaffListByCompetitionLoadingAction(false));
        dispatch(
          getStaffListByCompetitionErrorAction(
            {} as GetStaffListByCompetitionDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const compitionStaffListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetStaffListByCompetitionActionTypes>) {
    dispatch(
      getStaffListByCompetitionAPIMsgAction(apiRes as GetStaffListByCompetitionDetails, "", 0),
    );
  };
};

export const clearCompetitionStaffListAPIRes = () => {
  return function (dispatch: Dispatch<GetStaffListByCompetitionActionTypes>) {
    dispatch(
      getStaffListByCompetitionAPIResClearAction({} as GetStaffListByCompetitionDetails, "", 0),
    );
  };
};
