import {
  GET_DOCTOR_DETAILS_BY_ID_FAIL,
  GET_DOCTOR_DETAILS_BY_ID_LOADING,
  GET_DOCTOR_DETAILS_BY_ID_SUCCESS,
  GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_MSG,
  GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_RES,
  GetDoctorDetailsByIdActionTypes,
} from "./ActionTypes";
import { GetDoctorDetailsByIdRes } from "./Model";

export const getDoctorDetailsByIdLoadingAction = (
  loading: boolean
): GetDoctorDetailsByIdActionTypes => {
  return {
    type: GET_DOCTOR_DETAILS_BY_ID_LOADING,
    loading: loading,
  };
};

export const getDoctorDetailsByIdSuccessAction = (
  getDoctorDetailsByIdResponse: GetDoctorDetailsByIdRes,
  successMsg: string
): GetDoctorDetailsByIdActionTypes => {
  return {
    type: GET_DOCTOR_DETAILS_BY_ID_SUCCESS,
    payload: getDoctorDetailsByIdResponse,
    successMsg: successMsg,
  };
};

export const getDoctorDetailsByIdErrorAction = (
  getDoctorDetailsByIdResponse: GetDoctorDetailsByIdRes,
  errMsg: string,
  status: number
): GetDoctorDetailsByIdActionTypes => {
  return {
    type: GET_DOCTOR_DETAILS_BY_ID_FAIL,
    payload: getDoctorDetailsByIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorDetailsByIdUpdateAPIMsgAction = (
  getDoctorDetailsByIdResponse: GetDoctorDetailsByIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorDetailsByIdActionTypes => {
  return {
    type: GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_MSG,
    payload: getDoctorDetailsByIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorDetailsByIdAPIResClearAction = (
  getDoctorDetailsByIdResponse: GetDoctorDetailsByIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorDetailsByIdActionTypes => {
  return {
    type: GET_DOCTOR_DETAILS_BY_ID_UPDATE_API_RES,
    payload: getDoctorDetailsByIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
