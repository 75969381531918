import React, { useEffect } from "react";
import { Box, Button, CardActionArea, Container, Grid, Stack, Typography } from "@mui/material";
import Organization from "../../image/organization.png";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { getOrganizationListValue } from "../../redux/OrganizationList/API";
import { PatientOrganizationList } from "../../redux/OrganizationList/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function OrganizationList() {
  const [listCount, setListCount] = React.useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function organizationListApi() {
    dispatch(getOrganizationListValue());
  }

  const organizationList = useSelector((state: AppState) => state.organizationListValue);
  const orgValues = organizationList?.getOrganizationRes?.patientOrganizationList;
  useEffect(() => {
    dispatch(toggleAppBarLoading(organizationList?.loading));
    organizationList?.getOrganizationRes?.patientOrganizationList !== undefined
      ? setListCount(String(organizationList?.getOrganizationRes?.patientOrganizationList.length))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);
  const actualPageMarkup = (
    <Box m={3}>
      <Grid container spacing={1}>
        <Grid container item sm={12} spacing={3}>
          {orgValues && orgValues.length ? (
            <React.Fragment>
              {organizationList?.getOrganizationRes?.patientOrganizationList.map(
                (organizationItem: PatientOrganizationList) => (
                  <Grid item md={4}>
                    {organizationItem.id && (
                      <Card>
                        <CardActionArea href={`/patientlistbyorganization/${organizationItem.id}`}>
                          <CardHeader
                            avatar={
                              <img
                                id={`${organizationItem.id}`}
                                src={Organization}
                                alt=""
                                width="64"
                                height="64"
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "center",
                                }}
                              />
                            }
                            title={
                              <Typography variant="h5" style={{ fontWeight: "medium" }}>
                                {organizationItem.displayName}
                              </Typography>
                            }
                          />
                          <Divider />
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="outlined"
                            href={`/patientlistbyorganization/${organizationItem.id}`}
                            fullWidth
                          >
                            View Patient Organization
                          </Button>
                        </CardActions>
                      </Card>
                    )}
                  </Grid>
                ),
              )}
            </React.Fragment>
          ) : (
            <Box p={3}>
              <MWExceptionList />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
  const orgName = "Patient Organization List";
  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              title={orgName}
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={organizationListApi}
            />
          </Grid>
        </Grid>
        {actualPageMarkup}
      </Stack>
    </Container>
  );
}
