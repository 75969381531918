import { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { GetSessionListBody, HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { AppState } from "redux/store/Store";
import { getOrganizationListValue } from "redux/OrganizationList/API";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import { getHealthcampListValue } from "redux/healthcampList/API";
import { PatientOrganizationList } from "redux/OrganizationList/Model";
import {
  ModifiedInstanceList,
  ModifiedSessionList,
} from "pages/Preference/SetPreferenceDialog/Model";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { HealthcampList } from "redux/getMappedHealthcampsByOrganization/Model";
import { MovePatientToOrganizationBody } from "redux/MovePatientToOrganization/Model";
import { movePatientToOrganizationApiCall } from "redux/MovePatientToOrganization/API";
import { LoadingButton } from "@mui/lab";

type Props = {
  dialogState: boolean;
  updateDialogState: any;
  selectedHealthCampId: string;
};

export default function ImportPatientDialog({
  dialogState,
  updateDialogState,
  selectedHealthCampId,
}: Props) {
  const dispatch = useDispatch();
  const addAdvCheckIn = useSelector((state: AppState) => state.addPatientBySessionIdValues);
  const importPatientRes = useSelector((state: AppState) => state.movePatientToOrgValue);

  const [importModalActive, setImportModalState] = useState(false);

  const { organizationid } = useParams() as {
    organizationid: string;
  };

  const closeModal = (passedVal: boolean) => {
    updateDialogState(passedVal);
    setTypes("");
  };

  useEffect(() => {
    setImportModalState(dialogState);
    setHealthCampId(selectedHealthCampId);
    const viewHealthcampStaffListBody = {
      healthcampId: selectedHealthCampId,
    } as GetInstanceListBody;
    dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState, selectedHealthCampId]);

  const organizationListRes = useSelector((state: AppState) => state.organizationListValue);
  const healthcampValues: PatientOrganizationList | undefined = _.find(
    organizationListRes?.getOrganizationRes?.patientOrganizationList,
    ["id", Number(organizationid)],
  );

  const [healthCampList, setHealthCampList] = useState([] as HealthcampList[]);
  const [healthCampId, setHealthCampId] = useState("");
  const changeSelectedHealthCamp = (passedVal: any | null) => {
    setHealthCampId(passedVal.id);
    if (passedVal !== null) {
      const viewHealthcampStaffListBody = {
        healthcampId: passedVal.id,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
  };
  // Call All HealthCamp List Store
  const adminAllHealthCampListRes = useSelector(
    (state: AppState) => state.getHealthCampByOrganizationList,
  );

  useEffect(() => {
    if (
      adminAllHealthCampListRes?.getHealthCampByOrganizationListRes?.healthcampList !== undefined
    ) {
      let healthCampDropdownValue =
        adminAllHealthCampListRes?.getHealthCampByOrganizationListRes?.healthcampList.map(
          (element: HealthcampList) => ({
            id: element.id,
            displayName: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setHealthCampList(healthCampDropdownValue);
    } else setHealthCampList([] as HealthcampList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAllHealthCampListRes]);

  const [instanceList, setInstanceList] = useState([] as ModifiedInstanceList[]);
  const [selectedInstance, setSelectedInstance] = useState<ModifiedInstanceList | null>(null);
  const [instanceId, setInstanceId] = useState<string | null>("");
  const changeSelectedInstance = (passedVal: any | null) => {
    setSelectedInstance(passedVal);
    setInstanceId(passedVal.value);
    if (passedVal !== null) {
      const healthcampSessionListBody = {
        healthcampInstanceId: String(passedVal?.value),
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
  };
  // Call Instance List Store
  const getInstanceListRes = useSelector((state: AppState) => state.instanceListValue);
  useEffect(() => {
    if (getInstanceListRes?.getInstanceList?.healthcampInstanceList !== undefined) {
      let instanceListDropdownValue =
        getInstanceListRes?.getInstanceList?.healthcampInstanceList.map(
          (element: HealthcampInstanceList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setInstanceList(instanceListDropdownValue);
    } else setInstanceList([] as ModifiedInstanceList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstanceListRes]);

  const [sessionList, setSessionList] = useState([] as ModifiedSessionList[]);
  const [selectedSession, setSelectedSession] = useState<ModifiedSessionList | null>(null);
  const [sessionId, setSessionId] = useState("");
  const changeSelectedSession = (passedVal: any | null) => {
    setSelectedSession(passedVal);
    setSessionId(passedVal.value);
  };
  const getSeasonListRes = useSelector((state: AppState) => state.sessionListValues);
  useEffect(() => {
    if (getSeasonListRes?.getSessionList?.healthcampSessionList !== undefined) {
      let healthCampSessionListDropdownValue =
        getSeasonListRes?.getSessionList?.healthcampSessionList.map(
          (element: HealthcampSessionList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setSessionList(healthCampSessionListDropdownValue);
    } else setSessionList([] as ModifiedSessionList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeasonListRes]);

  const [types, setTypes] = useState("");
  useEffect(() => {
    if (types === "") {
      setTypes("1");
    } else {
      setTypes(types);
    }
  }, [types]);

  const importPatient = () => {
    if (healthCampId !== "" && instanceId === "" && sessionId === "") {
      let movePatientToOrgBody = {
        organizationId: organizationid,
        healthCampId: String(healthCampId),
      } as MovePatientToOrganizationBody;
      dispatch(movePatientToOrganizationApiCall(movePatientToOrgBody));
    }
    if (healthCampId !== "" && instanceId !== "" && sessionId === "") {
      let movePatientToOrgBody = {
        organizationId: organizationid,
        instanceId: String(instanceId),
      } as MovePatientToOrganizationBody;
      dispatch(movePatientToOrganizationApiCall(movePatientToOrgBody));
    }
    if (healthCampId !== "" && instanceId !== "" && sessionId !== "") {
      let movePatientToOrgBody = {
        organizationId: organizationid,
        sessionId: String(sessionId),
      } as MovePatientToOrganizationBody;
      dispatch(movePatientToOrganizationApiCall(movePatientToOrgBody));
    }
  };

  useEffect(() => {
    dispatch(getOrganizationListValue());
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const autocompleteone = (
    <Autocomplete
      fullWidth
      size="small"
      value={
        healthCampList.find((element: any) => element.id === healthCampId) || null || undefined
      }
      options={healthCampList}
      onChange={(_event: any, newValue: any) => {
        changeSelectedHealthCamp(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Healthcamp Name" variant="outlined" />}
      getOptionLabel={(option) => `${option.displayName}`}
      isOptionEqualToValue={(option: any, value) => option.id === value.id}
      sx={{ marginTop: 2 }}
      disableClearable
    />
  );

  const autocompletetwo = (
    <Autocomplete
      fullWidth
      id="controllable-states-demo"
      value={selectedInstance || undefined}
      options={instanceList}
      onChange={(_event: any, newValue: any) => {
        changeSelectedInstance(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Instance name" variant="outlined" />}
      getOptionLabel={(option) => `${option.label}`}
      isOptionEqualToValue={(option: any, value) => option.id === value.value}
      size="small"
      sx={{ marginTop: 2 }}
      disableClearable
    />
  );

  const autocompletethree = (
    <Autocomplete
      size="small"
      fullWidth
      value={selectedSession || undefined}
      options={sessionList}
      onChange={(_event: any, newValue: any) => {
        changeSelectedSession(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Session name" variant="outlined" />}
      getOptionLabel={(option) => `${option.label}`}
      isOptionEqualToValue={(option: any, value) => option.id === value.value}
      sx={{ marginTop: 2 }}
      disableClearable
    />
  );

  return (
    <Dialog open={importModalActive} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography fontWeight="bold" justifyContent="center">
                  Import Patient
                </Typography>
              </Grid>
              <Grid item>
                {organizationListRes?.loading ? <CircularProgress size="20" /> : null}
                {adminAllHealthCampListRes?.loading ? <CircularProgress size="20" /> : null}
                {getInstanceListRes?.loading ? <CircularProgress size="20" /> : null}
                {getSeasonListRes?.loading ? <CircularProgress size="20" /> : null}
                {addAdvCheckIn?.loading ? <CircularProgress size="20" /> : null}
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(e) => setTypes(e.target.value)}
                    value={types}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="By Health Camp" />
                    <FormControlLabel value="2" control={<Radio />} label="By Instance" />
                    <FormControlLabel value="3" control={<Radio />} label="By Session" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeModal(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
            <Typography fontWeight="bold" variant="h6">
              From
            </Typography>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
              {types === "1" ? <>{autocompleteone}</> : null}
              {types === "2" ? (
                <>
                  {autocompleteone}
                  {autocompletetwo}
                </>
              ) : null}
              {types === "3" ? (
                <>
                  {autocompleteone}
                  {autocompletetwo}
                  {autocompletethree}
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
            <Typography fontWeight="bold" variant="h6">
              To
            </Typography>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item mt={types === "1" ? 3 : types === "2" ? 6 : types === "3" ? 10 : 0}>
                <Typography fontWeight="bold">
                  {healthcampValues?.displayName !== undefined ? healthcampValues?.displayName : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoadingButton
          onClick={() => importPatient()}
          loading={importPatientRes?.loading}
          variant="contained"
        >
          Import
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
