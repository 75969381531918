import { AddRegistrationRequestDetailsDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_REGISTRATION_REQUEST_DETAILS_LOADING = "ADD_REGISTRATION_REQUEST_DETAILS_LOADING";
export const ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_REGISTRATION_REQUEST_DETAILS_FAIL = "ADD_REGISTRATION_REQUEST_DETAILS_FAIL";
export const ADD_REGISTRATION_REQUEST_DETAILS_API_MSG = "ADD_REGISTRATION_REQUEST_DETAILS_API_MSG";
export const ADD_REGISTRATION_REQUEST_DETAILS_API_RES = "ADD_REGISTRATION_REQUEST_DETAILS_API_RES";

export interface AddRegistrationRequestDetailsState {
  addRegistrationRequestDetailsRes: AddRegistrationRequestDetailsDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddRegistrationRequestDetailsLoading {
  type: typeof ADD_REGISTRATION_REQUEST_DETAILS_LOADING;
  loading: boolean;
}
export interface AddRegistrationRequestDetailsSuccess {
  type: typeof ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS;
  payload: AddRegistrationRequestDetailsDetails;
  successMsg: string;
}
export interface AddRegistrationRequestDetailsFail {
  type: typeof ADD_REGISTRATION_REQUEST_DETAILS_FAIL;
  payload: AddRegistrationRequestDetailsDetails;
  errorMsg: string;
  status: number;
}
export interface AddRegistrationRequestDetailsAPIMsg {
  type: typeof ADD_REGISTRATION_REQUEST_DETAILS_API_MSG;
  payload: AddRegistrationRequestDetailsDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddRegistrationRequestDetailsAPIRes {
  type: typeof ADD_REGISTRATION_REQUEST_DETAILS_API_RES;
  payload: AddRegistrationRequestDetailsDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddRegistrationRequestDetailsActionTypes =
  | AddRegistrationRequestDetailsLoading
  | AddRegistrationRequestDetailsSuccess
  | AddRegistrationRequestDetailsFail
  | AddRegistrationRequestDetailsAPIMsg
  | AddRegistrationRequestDetailsAPIRes;
