import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddRegistrationRequestActionTypes } from "./ActionTypes";
import { AddRegistrationRequestBody, AddRegistrationRequestDetails } from "./Model";
import {
  addRegistrationRequestAPIResClearAction,
  addRegistrationRequestErrorAction,
  addRegistrationRequestLoadingAction,
  addRegistrationRequestSuccessAction,
  addRegistrationRequestAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddRegistrationRequestDetails;
export const addRegistrationRequestApiCall = (payload: AddRegistrationRequestBody) => {
  return function (dispatch: Dispatch<AddRegistrationRequestActionTypes>) {
    dispatch(addRegistrationRequestLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampRegistrationRequest/add_registration_request_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addRegistrationRequestLoadingAction(false));
        dispatch(
          addRegistrationRequestSuccessAction(
            res.data !== undefined ? res.data : ({} as AddRegistrationRequestDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Registration request fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addRegistrationRequestLoadingAction(false));
        dispatch(
          addRegistrationRequestErrorAction(
            {} as AddRegistrationRequestDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch<AddRegistrationRequestActionTypes>) {
    dispatch(addRegistrationRequestAPIMsgAction(apiRes as AddRegistrationRequestDetails, "", "", 0));
  };
};

export const clearAddRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch<AddRegistrationRequestActionTypes>) {
    dispatch(addRegistrationRequestAPIResClearAction({} as AddRegistrationRequestDetails, "", "", 0));
  };
};
