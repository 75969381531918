import {
  ExecutiveListActionTypes,
  GetPatientDemographicsState,
  ASSIGN_EXECUTIVE_SUCCESS,
  ASSIGN_EXECUTIVE_LOADING,
  ASSIGN_EXECUTIVE_FAIL,
} from "./ActionTypes";
import { AssignExecutiveResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  assignExecutiveRes: {} as AssignExecutiveResponse,
};
export const assignExecutiveReducer = (
  state = initialStateGetPosts,
  action: ExecutiveListActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case ASSIGN_EXECUTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        assignExecutiveRes: action.payload,
      };
    case ASSIGN_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: true,
        assignExecutiveRes: {} as AssignExecutiveResponse,
      };
    case ASSIGN_EXECUTIVE_FAIL:
      return {
        ...state,
        assignExecutiveRes: {} as AssignExecutiveResponse,
        loading: false,
      };
    default:
      return state;
  }
};
