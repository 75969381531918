import {
  ADD_PROVIDER_ORGANIZATION_DATA,
  ADD_PROVIDER_ORGANIZATION_FAIL,
  ADD_PROVIDER_ORGANIZATION_LOADING,
  ADD_PROVIDER_ORGANIZATION_SUCCESS,
  AddProviderOrganizationActionTypes,
} from "./ActionTypes";
import { AddProviderOrganizationDetails } from "./Model";

export const patientSearchOrganizationIdAction = (
  AddProviderOrganizationDetails: AddProviderOrganizationDetails,
): AddProviderOrganizationActionTypes => {
  return {
    type: ADD_PROVIDER_ORGANIZATION_DATA,
    payload: AddProviderOrganizationDetails,
  };
};

export const AddProviderOrganizationLoadingAction = (
  loading: boolean,
): AddProviderOrganizationActionTypes => {
  return {
    type: ADD_PROVIDER_ORGANIZATION_LOADING,
    loading: loading,
  };
};

export const AddProviderOrganizationSuccessAction = (
  addProviderOrganizationResponse: AddProviderOrganizationDetails,
): AddProviderOrganizationActionTypes => {
  return {
    type: ADD_PROVIDER_ORGANIZATION_SUCCESS,
    payload: addProviderOrganizationResponse,
  };
};

export const AddProviderOrganizationErrorAction = (
  addProviderOrganizationResponse: AddProviderOrganizationDetails,
  errMsg: string,
  status: number,
): AddProviderOrganizationActionTypes => {
  return {
    type: ADD_PROVIDER_ORGANIZATION_FAIL,
    payload: addProviderOrganizationResponse,
    message: errMsg,
    status: status,
  };
};
export const AddProviderOrganizationAPIMsgActionAction = (
  addProviderOrganizationResponse: AddProviderOrganizationDetails,
  errMsg: string,
  status: number,
): AddProviderOrganizationActionTypes => {
  return {
    type: ADD_PROVIDER_ORGANIZATION_FAIL,
    payload: addProviderOrganizationResponse,
    message: errMsg,
    status: status,
  };
};

export const AddProviderOrganizationAPIResClearAction = (
  addProviderOrganizationResponse: AddProviderOrganizationDetails,
  errMsg: string,
  status: number,
): AddProviderOrganizationActionTypes => {
  return {
    type: ADD_PROVIDER_ORGANIZATION_FAIL,
    payload: addProviderOrganizationResponse,
    message: errMsg,
    status: status,
  };
};
