import {
 GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL,
 GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING,
 GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS,
 GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG,
 GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES,
  GetUnprocessedPatientDetailsListActionTypes,
 GET_UNPROCESSED_PATIENT_DETAILS_LIST_DATA,
} from "./ActionTypes";
import { GetUnprocessedPatientDetailsListRes, PageCount } from "./Model";

export const GetUnprocessedPatientDetailsListDataAction = (
  GetUnprocessedPatientDetailsListRes: GetUnprocessedPatientDetailsListRes,
  pageCount: PageCount,
): GetUnprocessedPatientDetailsListActionTypes => {
  return {
    type:GET_UNPROCESSED_PATIENT_DETAILS_LIST_DATA,
    payload: GetUnprocessedPatientDetailsListRes,
    pageCount: pageCount,
  };
};

export const getUnprocessedPatientDetailsListLoadingAction = (
  loading: boolean,
): GetUnprocessedPatientDetailsListActionTypes => {
  return {
    type:GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING,
    loading: loading,
  };
};

export const getUnprocessedPatientDetailsListSuccessAction = (
  getUnprocessedPatientDetailsListResponse: GetUnprocessedPatientDetailsListRes,
): GetUnprocessedPatientDetailsListActionTypes => {
  return {
    type:GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS,
    payload: getUnprocessedPatientDetailsListResponse,
  };
};

export const getUnprocessedPatientDetailsListErrorAction = (
  getUnprocessedPatientDetailsListResponse: GetUnprocessedPatientDetailsListRes,
  errMsg: string,
  status: number,
): GetUnprocessedPatientDetailsListActionTypes => {
  return {
    type:GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL,
    payload: getUnprocessedPatientDetailsListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getUnprocessedPatientDetailsListAPIMsgAction = (
  getUnprocessedPatientDetailsListResponse: GetUnprocessedPatientDetailsListRes,
  errMsg: string,
  status: number,
): GetUnprocessedPatientDetailsListActionTypes => {
  return {
    type:GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG,
    payload: getUnprocessedPatientDetailsListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getUnprocessedPatientDetailsListAPIResClearAction = (
  getUnprocessedPatientDetailsListResponse: GetUnprocessedPatientDetailsListRes,
  errMsg: string,
  status: number,
): GetUnprocessedPatientDetailsListActionTypes => {
  return {
    type:GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES,
    payload: getUnprocessedPatientDetailsListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
