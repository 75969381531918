import {
  TOGGLE_STAFF_OF_PROVIDER_ORG_DATA,
  TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL,
  TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING,
  TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS,
  TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG,
  TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES,
  DiactiveStaffOfProviderOrgActionTypes,
} from "./ActionTypes";
import { PageCount, DiactiveStaffOfProviderOrgDetails } from "./Model";

export const diactiveStaffOrganizationIdAction = (
  DiactiveStaffOfProviderOrgDetails: DiactiveStaffOfProviderOrgDetails,
  pageCount: PageCount,
): DiactiveStaffOfProviderOrgActionTypes => {
  return {
    type: TOGGLE_STAFF_OF_PROVIDER_ORG_DATA,
    payload: DiactiveStaffOfProviderOrgDetails,
    pageCount: pageCount,
  };
};

export const DiactiveStaffOfProviderOrgLoadingAction = (
  loading: boolean,
): DiactiveStaffOfProviderOrgActionTypes => {
  return {
    type: TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING,
    loading: loading,
  };
};

export const DiactiveStaffOfProviderOrgSuccessAction = (
  diactiveStaffResponse: DiactiveStaffOfProviderOrgDetails,
  successMsg: string,
): DiactiveStaffOfProviderOrgActionTypes => {
  return {
    type: TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
  };
};

export const DiactiveStaffOfProviderOrgErrorAction = (
  diactiveStaffResponse: DiactiveStaffOfProviderOrgDetails,
  errMsg: string,
  status: number,
): DiactiveStaffOfProviderOrgActionTypes => {
  return {
    type: TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL,
    payload: diactiveStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DiactiveStaffOfProviderOrgAPIMsgAction = (
  diactiveStaffResponse: DiactiveStaffOfProviderOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DiactiveStaffOfProviderOrgActionTypes => {
  return {
    type: TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const DiactiveStaffOfProviderOrgAPIResClearAction = (
  diactiveStaffResponse: DiactiveStaffOfProviderOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DiactiveStaffOfProviderOrgActionTypes => {
  return {
    type: TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
