import React, { useEffect } from "react";
import { getDoctorListValueAdmin } from "../redux/effects/ApiCall";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { useHistory } from "react-router-dom";
import { Button, Card, CardContent, Container, Grid, Stack } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function Doctor() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDoctorListValueAdmin());
  }, [dispatch]);

  const doctorDetails = (doctorId: any) => {
    console.log("doctorId==", doctorId);
    history.push(`/doctordetails/${doctorId}`);
  };
  const viewDoctorAppointment = (doctorId: any) => {
    history.push(`/doctorapoointment/${doctorId}`);
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const [listCount, setListCount] = React.useState("");
  // Api call from store.....
  const doctorList = useSelector((state: AppState) => state.doctorListValue);
  const doctorListValue = doctorList.doctorListValue;
  useEffect(() => {
    dispatch(toggleAppBarLoading(doctorList?.loading));
    doctorList?.doctorListValue !== undefined
      ? setListCount(String(doctorList?.doctorListValue.length))
      : setListCount("0");

    if (doctorListValue !== undefined) {
      let dataPointArr = doctorListValue.map((element: any) => ({
        id: element.id,
        doctorName: element.fullName,
        appoinmentDetails: element.id,
        doctorQualification: element.qualification,
        doctorExperience: element.specialtyWithYearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList]);
  const doctorListReload = () => {
    dispatch(getDoctorListValueAdmin());
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Doctor Details",
      minWidth: 110,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            doctorDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "doctorName", headerName: "Doctor Name", minWidth: 250, flex: 1 },
    {
      field: "appoinmentDetails",
      headerName: "Appoinment Details",
      minWidth: 195,
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            viewDoctorAppointment(params.value);
          }}
          variant="outlined"
        >
          Appointment Details
        </Button>
      ),
    },
    { field: "doctorQualification", headerName: "Qualification", minWidth: 250, flex: 1 },
    { field: "doctorExperience", headerName: "Experience", minWidth: 250, flex: 1 },
  ];
  const DoctorListTable = (
    <Card>
      <CardContent>
        {doctorListValue && doctorListValue.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              title="Doctor List"
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={doctorListReload}
            />
          </Grid>
        </Grid>
        {DoctorListTable}
      </Stack>
    </Container>
  );
}
