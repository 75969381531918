import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import MWUploader from "component/MWUploader";

import { AddRegistrationRequestDetailsBody } from "redux/HealthCampRegistrationRequestList/AddRegistrationRequestDetails/Model";
import {
  addRegistrationRequestDetailsApiCall,
  clearAddRegistrationRequestDetailsAPIRes,
  updateAddRegistrationRequestDetailsAPIResMsg,
} from "redux/HealthCampRegistrationRequestList/AddRegistrationRequestDetails/API";
import { getHealthCampRegistrationRequestDetailsList } from "redux/HealthCampRegistrationRequestList/HealthCampRegistrationReqDetailList/API";
import { GetHealthcampRequestDetailsListBody } from "redux/HealthCampRegistrationRequestList/HealthCampRegistrationReqDetailList/Model";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  healthcampid: string;
  requestid: string;
};

type RegistrationRequestDocumentList = {
  label: string;
  value: number;
};
export default function AddRegistrationDetailsDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  healthcampid,
  requestid,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const addResponse = useSelector((state: AppState) => state.addRegistrationRequestDetailsValue);
  const [description, setDescription] = useState("");

  const reqReqDocTypeList = [
    { label: "Text", value: 1 },
    { label: "Image", value: 2 },
  ] as RegistrationRequestDocumentList[];
  const [selectedRegDocType, setSelectedRegDocType] =
    useState<RegistrationRequestDocumentList | null>(reqReqDocTypeList[0]);
  const changeRegDocType = async (passedVal: RegistrationRequestDocumentList) => {
    setSelectedRegDocType(passedVal);
  };
  const [imageId, setImageId] = useState("");
  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };
  // const delImgAction = (_passeVal: boolean) => {};
  const [delImgAction, setDelImgAction] = useState(false);
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearAddRegistrationRequestDetailsAPIRes());
    dispatch(updateAddRegistrationRequestDetailsAPIResMsg());
    setDescription("");
    setImageId("");
    setSelectedRegDocType(reqReqDocTypeList[0]);
  };

  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      handleDialogClose();
      dispatch(
        getHealthCampRegistrationRequestDetailsList({
          registrationRequestId: requestid,
        } as GetHealthcampRequestDetailsListBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const addRegRequestDetails = () => {
    if (delImgAction) {
      setDelImgAction(false);
    }
    if (requestid !== "" && selectedRegDocType !== null) {
      let addRegRequestDetailsBody = {
        registrationRequestId: requestid,
        requestDetailsType: String(selectedRegDocType?.value),
        healthcampId: healthcampid!,
        requestDocumentId: imageId,
        description: description,
      } as AddRegistrationRequestDetailsBody;
      if (selectedRegDocType?.value === 2) {
        dispatch(addRegistrationRequestDetailsApiCall(addRegRequestDetailsBody));
      } else {
        delete addRegRequestDetailsBody?.requestDocumentId;
        dispatch(addRegistrationRequestDetailsApiCall(addRegRequestDetailsBody));
      }
    }
  };

  const imageUploadTypeValue = (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          value={description}
          label="Add Description"
          placeholder="Enter description"
          id="descriptionID"
          onChange={(event) => setDescription(event.target.value)}
          fullWidth
          size="small"
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <MWUploader
          documentType="patient-image"
          criteria="Upload a file less than 50 mb."
          updatedDataState={updateDataStateFromChild}
          title={"Upload HealthCamp Patient Photo"}
          deleteImgAction={delImgAction}
          previewImageData={viewPreviewImageData}
        ></MWUploader>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {addResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Add Registration Details</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Autocomplete
                id="reqDocType"
                value={selectedRegDocType}
                options={reqReqDocTypeList}
                disableClearable={true as any}
                onChange={(_event: any, newValue: any) => {
                  changeRegDocType(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Relationship" size="small" />
                )}
                getOptionLabel={(option) => `${option.label}`}
              />
            </Grid>
            {selectedRegDocType?.value === 1 ? (
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <TextField
                  value={description}
                  label="Add Description"
                  placeholder="Enter description"
                  id="descriptionID"
                  onChange={(event) => setDescription(event.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
            ) : null}
            {selectedRegDocType?.value === 2 ? (
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                {imageUploadTypeValue}
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Button
                autoFocus
                disabled={checkImgUpload}
                onClick={() => addRegRequestDetails()}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
