import React, {  useState } from "react";
import { Box, Grid, Tab, Typography } from "@mui/material";
import ActiveNotes from "./ActiveNotes";
import InactiveNotes from "./InActiveNotes";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import PageLayout from "pages/Layout/PageLayout";
import { useDispatch } from "react-redux";
import { getDoctorNoteListForDoctor } from "redux/Notes/ApiCall";
type Props = {
  noteListLoader?: boolean | any;
};
export default function Notes({noteListLoader}:Props) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };
  const doctorId = localStorage.getItem("doctorID");
  const getActiveDrNoteListBody = {
    doctorId: doctorId,
    isActive: true,
  };
  const getInactiveDrNoteListBody = {
    doctorId: doctorId,
    isActive: true,
  };
 
  const notesPage = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Active Notes</Typography>
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                    onClick={() => {
                      dispatch(getDoctorNoteListForDoctor(getActiveDrNoteListBody));
                    }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Inactive Notes</Typography>
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                    onClick={() => {
                      dispatch(getDoctorNoteListForDoctor(getInactiveDrNoteListBody));
                    }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ActiveNotes noteListLoader={noteListLoader} />
            </TabPanel>
            <TabPanel value="2">
              <InactiveNotes noteListLoader={noteListLoader}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box>
    <PageLayout>
      {notesPage}
    </PageLayout>
  </Box>
  );
}
