import { SessionListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const SESSION_LIST_BY_PATIENT_ID_LOADING = "SESSION_LIST_BY_PATIENT_ID_LOADING";
export const SESSION_LIST_BY_PATIENT_ID_SUCCESS = "SESSION_LIST_BY_PATIENT_ID_SUCCESS";
export const SESSION_LIST_BY_PATIENT_ID_FAIL = "SESSION_LIST_BY_PATIENT_ID_FAIL";
export const SESSION_LIST_BY_PATIENT_ID_API_MSG = "SESSION_LIST_BY_PATIENT_ID_API_MSG";
export const SESSION_LIST_BY_PATIENT_ID_API_RES = "SESSION_LIST_BY_PATIENT_ID_API_RES";

export interface SessionListByPatientIdDetailsState {
  sessionListByPatientIdRes: SessionListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface SessionListByPatientIdLoading {
  type: typeof SESSION_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface SessionListByPatientIdSuccess {
  type: typeof SESSION_LIST_BY_PATIENT_ID_SUCCESS;
  payload: SessionListByPatientIdDetails;
  successMsg: string;
}
export interface SessionListByPatientIdFail {
  type: typeof SESSION_LIST_BY_PATIENT_ID_FAIL;
  payload: SessionListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface SessionListByPatientIdAPIMsg {
  type: typeof SESSION_LIST_BY_PATIENT_ID_API_MSG;
  payload: SessionListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof SESSION_LIST_BY_PATIENT_ID_API_RES;
  payload: SessionListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type SessionListByPatientIdActionTypes =
  | SessionListByPatientIdLoading
  | SessionListByPatientIdSuccess
  | SessionListByPatientIdFail
  | SessionListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
