import {
  GetProviderOrganizationDeactiveStaffListActionTypes,
  PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL,
  PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING,
  PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS,
  GetProviderOrganizationDeactiveStaffListState,
  PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG,
  PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetProviderOrganizationDeactiveStaffListRes } from "./Model";

const initialStateGetPosts: GetProviderOrganizationDeactiveStaffListState = {
  loading: false,
  getProviderOrganizationDeactiveStaffList: {} as GetProviderOrganizationDeactiveStaffListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDeactiveStaffListByProviderOrganizationIdReducer = (
  state = initialStateGetPosts,
  action: GetProviderOrganizationDeactiveStaffListActionTypes,
): GetProviderOrganizationDeactiveStaffListState => {
  switch (action.type) {
    case PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS:
      return {
        ...state,
        getProviderOrganizationDeactiveStaffList: action.payload,
        successMsg: action.successMsg,
      };
    case PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL:
      return {
        ...state,
        getProviderOrganizationDeactiveStaffList: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getProviderOrganizationDeactiveStaffList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PROVIDER_ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES:
      return {
        ...state,
        getProviderOrganizationDeactiveStaffList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
