import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetProviderOrgStaffListActionTypes } from "./ActionTypes";
import { GetProviderOrgStaffListBody, GetProviderOrgStaffListRes } from "./Model";
import {
  getProviderOrgStaffListAPIResClearAction,
  getProviderOrgStaffListErrorAction,
  getProviderOrgStaffListLoadingAction,
  getProviderOrgStaffListSuccessAction,
  getProviderOrgStaffListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetProviderOrgStaffListRes;
export const getStaffListByProviderOrgId = (payload: GetProviderOrgStaffListBody, pageNo: any) => {
  return function (dispatch: Dispatch<GetProviderOrgStaffListActionTypes>) {
    dispatch(getProviderOrgStaffListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationStaff/staff_list_providerOrganization?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getProviderOrgStaffListLoadingAction(false));
        dispatch(
          getProviderOrgStaffListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetProviderOrgStaffListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getProviderOrgStaffListLoadingAction(false));
        dispatch(
          getProviderOrgStaffListErrorAction(
            {} as GetProviderOrgStaffListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateStaffListByProviderOrgIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderOrgStaffListActionTypes>) {
    dispatch(
      getProviderOrgStaffListUpdateAPIMsgAction(apiRes as GetProviderOrgStaffListRes, "", "", 0),
    );
  };
};

export const clearStaffListByProviderOrgIdAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderOrgStaffListActionTypes>) {
    dispatch(getProviderOrgStaffListAPIResClearAction({} as GetProviderOrgStaffListRes, "", "", 0));
  };
};
