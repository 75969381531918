import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { Box, Chip, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getSingleEncounterListApi } from "redux/PatientEncounterListByid/SingleEncounterList/API";
import SingleEncounterListByPatient from "./SingleEncounterListByPatient";
import GroupEncounterListByPatient from "./GroupEncounterListByPatient";

type Props = {
  encounterListLoader?: boolean | any;
};

export default function EncounterListByPatient({ encounterListLoader }: Props) {
  const dispatch = useDispatch();

  // call Store
  const singleEncounterList = useSelector((state: AppState) => state.getSingleEncounterListValue);
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  // const [activeListCount, setActiveListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const reloadActionSingleEncounter = () => {
    dispatch(getSingleEncounterListApi(1));
  };

  useEffect(() => {
    singleEncounterList?.getSingleEncounterListRes?.count !== undefined
      ? setListCount(String(singleEncounterList?.getSingleEncounterListRes?.count))
      : setListCount("0");
  }, [singleEncounterList]);

  const EncounterList = (
    <Box>
      <TabContext value={String(selected)}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab
              label={
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography>Single Encounter</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={listCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    reloadActionSingleEncounter();
                  }}
                />
              }
              iconPosition="end"
              value="1"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Group Encounter</Typography>
                  </Grid>
                  {/* <Grid item>
                        <Chip label={activeListCount} variant="outlined" size="small" />
                      </Grid> */}
                </Grid>
              }
              icon={<RefreshIcon />}
              iconPosition="end"
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SingleEncounterListByPatient encounterListLoader={encounterListLoader} />
        </TabPanel>
        <TabPanel value="2">
          <GroupEncounterListByPatient encounterListLoader={encounterListLoader} />
        </TabPanel>
      </TabContext>
    </Box>
  );

  return <Box>{EncounterList}</Box>;
}
