import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetOrganizationDeactiveStaffListActionTypes } from "./ActionTypes";
import { GetOrganizationDeactiveStaffListBody, GetOrganizationDeactiveStaffListRes } from "./Model";
import {
  getOrganizationDeactiveStaffListAPIResClearAction,
  getOrganizationDeactiveStaffListErrorAction,
  getOrganizationDeactiveStaffListLoadingAction,
  getOrganizationDeactiveStaffListSuccessAction,
  getOrganizationDeactiveStaffListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetOrganizationDeactiveStaffListRes;
export const getDeactiveStaffListByOrganizationId = (
  payload: GetOrganizationDeactiveStaffListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetOrganizationDeactiveStaffListActionTypes>) {
    dispatch(getOrganizationDeactiveStaffListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientorganizationstafflist/staff_list_patient_organization?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getOrganizationDeactiveStaffListLoadingAction(false));
        dispatch(
          getOrganizationDeactiveStaffListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetOrganizationDeactiveStaffListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient organization staff list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getOrganizationDeactiveStaffListLoadingAction(false));
        dispatch(
          getOrganizationDeactiveStaffListErrorAction(
            {} as GetOrganizationDeactiveStaffListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDeactiveStaffListByOrganizationIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetOrganizationDeactiveStaffListActionTypes>) {
    dispatch(
      getOrganizationDeactiveStaffListUpdateAPIMsgAction(
        apiRes as GetOrganizationDeactiveStaffListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearDeactiveStaffListByOrganizationIdAPIRes = () => {
  return function (dispatch: Dispatch<GetOrganizationDeactiveStaffListActionTypes>) {
    dispatch(
      getOrganizationDeactiveStaffListAPIResClearAction(
        {} as GetOrganizationDeactiveStaffListRes,
        "",
        "",
        0,
      ),
    );
  };
};
