import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import { Alert, AlertColor, Box, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import { clearAddExistingPtAPIRes } from "../../../redux/addExistingHealthcampPatient/API";
import { clearSearchAPIRes, patientSearchApi } from "../../../redux/PatientSearchApi/API";
import { PatientList, PatientSearchBody } from "../../../redux/PatientSearchApi/Model";
import dayjs from "dayjs";
import AdminLoader from "../../AdminLoader";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import SearchPatient from "../../SearchPatient";
import { GetOrganizationPatientListBody } from "../../../redux/PatientListByOrganization/Model";
import { getPatientListByOrganization } from "../../../redux/PatientListByOrganization/API";

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

export interface State extends SnackbarOrigin {
  open: boolean;
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  itemId?: string | any;
  addExistingPatientDialogEnable?: boolean | any;
  addExistingPatientDialogUpdateState?: boolean | any;
  title?: string | any;
  addPt?: boolean | any;
  showPtDetail?: boolean | any;
  showPtDoc?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

export default function AddExistingPatientDialog({
  itemId,
  addExistingPatientDialogEnable,
  addExistingPatientDialogUpdateState,
  title,
}: Props) {
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  console.log(state);
  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);
  console.log(transition);
  const showSnackBar =
    (newState: SnackbarOrigin, Transition: React.ComponentType<TransitionProps>) => () => {
      setState({ open: true, ...newState });
      setTransition(() => Transition);
    };

  const dispatch = useDispatch();
  const closeSummaryBanner = () => {
    setAddHealthCampPatientSuccess("");
    setDialogState(false);
    addExistingPatientDialogUpdateState(false);
  };
  const [addHealthCampPatientSuccess, setAddHealthCampPatientSuccess] = React.useState("");
  const addHealthCampPatientSuccessDiv = addHealthCampPatientSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{addHealthCampPatientSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [selectedItem] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addExistingPatientDialogUpdateState(false);
    dispatch(clearSearchAPIRes());
    dispatch(clearAddExistingPtAPIRes());
  };

  const addPatientValueRes = useSelector((state: AppState) => state.addHealthcampPatientData);

  const [, setPatientList] = useState([] as any[]);
  const [inputValue] = React.useState("" as any);
  const [, setDataGridArray] = useState([] as any[]);
  const [, setPageListCount] = React.useState("");
  const [, setListCount] = React.useState("");

  const patientSearchListValueRes = useSelector((state: AppState) => state.patientSearchListValue);
  const searchPatientList = patientSearchListValueRes?.patientSearchRes.patientList;

  const addExistingPatientValueRes = useSelector(
    (state: AppState) => state.addExistingPatientValue,
  );
  const [addExistingPt, setAddExistingPt] = useState("");
  console.log(addExistingPt);
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  console.log(snackBarType);

  useEffect(() => {
    setIsLoading(addExistingPatientValueRes?.loading);
    if (addExistingPatientValueRes?.addPatientRes?.message !== undefined) {
      setAddExistingPt(addExistingPatientValueRes?.addPatientRes?.message);
      setSnackBarType("success");
      showSnackBar(
        {
          vertical: "bottom",
          horizontal: "center",
        },
        TransitionUp,
      );
    }
    if (addExistingPatientValueRes?.message !== "") {
      setAddExistingPt(addExistingPatientValueRes?.message);
      setSnackBarType("error");
      showSnackBar(
        {
          vertical: "bottom",
          horizontal: "center",
        },
        TransitionUp,
      );
    }
    showSnackBar(
      {
        vertical: "bottom",
        horizontal: "center",
      },
      TransitionUp,
    );
  }, [addExistingPatientValueRes]);
  const patientSearchBody = {
    searchKey: inputValue,
  } as PatientSearchBody;

  const [page, setPage] = useState(1);
  console.log(page);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(patientSearchApi(patientSearchBody, value));
  };
  console.log(handleChange);

  useEffect(() => {
    if (patientSearchListValueRes?.patientSearchRes?.patientList !== undefined) {
      setPatientList(patientSearchListValueRes?.patientSearchRes.patientList);
    } else {
      setPatientList([]);
    }
    setIsLoading(patientSearchListValueRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  useEffect(() => {
    patientSearchListValueRes?.patientSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientSearchListValueRes?.patientSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (patientSearchListValueRes?.patientSearchRes?.count !== undefined) {
      setListCount(String(patientSearchListValueRes?.patientSearchRes?.count));
    }
    if (searchPatientList !== undefined) {
      let dataPointArr = searchPatientList.map((element: PatientList) => ({
        id: element.patientId,
        patientId: element.patientId,
        givenName: element.givenName,
        familyName: element.familyName,
        userName: element.userName,
        createDate: dayjs(element.createDate).format("ddd, MMM D, YYYY"),
        dob: dayjs(element.dob).format("ddd, MMM D, YYYY"),
        phone: element.phone,
        profilePhotoId: element.profilePhotoId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(patientSearchListValueRes?.patientSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  useEffect(() => {
    if (addPatientValueRes?.addPatientRes?.message !== undefined) {
      const patientListBody = {
        organizationId: selectedItem,
      } as GetOrganizationPatientListBody;
      dispatch(getPatientListByOrganization(patientListBody, 1));
      setAddHealthCampPatientSuccess(addPatientValueRes?.addPatientRes?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  React.useEffect(() => {
    setDialogState(addExistingPatientDialogEnable);
  }, [addExistingPatientDialogEnable]);

  return (
    <Box>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        {loadingMarkup}
        <DialogTitle id="customized-dialog-title">
          <Typography sx={{fontSize: "20px"}}>Search Patient in medicalWISDOM Platform for <b>{title}</b></Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <SearchPatient organizationid={itemId} />
        </DialogContent>
        {addHealthCampPatientSuccessDiv}
      </Dialog>
    </Box>
  );
}
