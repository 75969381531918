import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Card,
  Typography,
  Tabs,
  Tab,
  Chip,
  tabsClasses,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getPatientDataDetailsList } from "redux/patientDataDetails/patientDataPointListByPatientID/API";
import { AppState } from "redux/store/Store";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory, useLocation } from "react-router-dom";
import PatientDetailsOfOriginalPatient from "./PatientDetailsOfOriginalPatient";
import ObservationDetails from "./ObservationDetails";
import { getExtractObservationByPatientId } from "redux/getExtractObservationByPatientId/API";
import OriginalDemographicsDetails from "./OriginalDemographicsDetails";
import { organizationListByPatientIdApi } from "redux/PatientOrganizationListById/API";
import { OrganizationListByPatientIdBody } from "redux/PatientOrganizationListById/Model";
import OrganizationListByPatient from "./OrganizationListByPatient";
import HealthcampListByPatient from "./HealthcampListByPatient";
import { healthcampListByPatientIdApi } from "redux/PatientHealthcampListById/API";
import { HealthcampListByPatientIdBody } from "redux/PatientHealthcampListById/Model";
import CompetitionListByPatient from "./CompetitionListByPatient";
import { competitionListByPatientIdApi } from "redux/PatientCompetitionListById/API";
import { CompetitionListByPatientIdBody } from "redux/PatientCompetitionListById/Model";
import InstanceListByPatient from "./InstanceListByPatient";
import { instanceListByPatientIdApi } from "redux/PatientInstanceListById/API";
import { InstanceListByPatientIdBody } from "redux/PatientInstanceListById/Model";
import SessionListByPatient from "./SessionListByPatient";
import { sessionListByPatientIdApi } from "redux/PatientSessionListById/API";
import { SessionListByPatientIdBody } from "redux/PatientSessionListById/Model";

type Props = {
  patientoriginalid?: any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OriginalPatientDetailsView({ patientoriginalid }: Props) {
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const history = useHistory();
  // call Store
  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const patientListRes = patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList;
  const getExtractObservationValue = useSelector(
    (state: AppState) => state.getExtractObservationValue,
  );
  const organizationList = useSelector((state: AppState) => state.organizationListByPatientValues);
  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.healthcampListByPatientValues,
  );
  const competitionList = useSelector((state: AppState) => state.competitionListByPatientIdValues);
  const instanceList = useSelector((state: AppState) => state.instanceListBuPatientIdValues);
  const sessionListValues = useSelector((state: AppState) => state.sessionListByPatientIdValues);
  const [listCount, setLiseCount] = React.useState("");
  const [obsListCount, setObsLiseCount] = React.useState("");
  const [organizationCountList, setOrganizationCountList] = React.useState("");
  const [healthcampCountList, setHealthcampCountList] = React.useState("");
  const [competitionCountList, setCompetitionCountList] = React.useState("");
  const [instanceCountList, setInstanceCountList] = React.useState("");
  const [sessionCountList, setSessionCountList] = React.useState("");

  useEffect(() => {
    if (patientListRes !== undefined) {
      setLiseCount(String(patientPointDataList?.getPatientDataDetailsRes?.count));
    } else {
      setLiseCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);
  useEffect(() => {
    getExtractObservationValue?.getExtractObservationRes?.count !== undefined
      ? setObsLiseCount(String(getExtractObservationValue?.getExtractObservationRes?.count))
      : setObsLiseCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExtractObservationValue]);
  useEffect(() => {
    organizationList?.organizationListByPatientIdRes?.count !== undefined
      ? setOrganizationCountList(String(organizationList?.organizationListByPatientIdRes?.count))
      : setOrganizationCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);
  useEffect(() => {
    AdminAllHealthcampList?.healthcampListByPatientIdRes?.count !== undefined
      ? setHealthcampCountList(String(AdminAllHealthcampList?.healthcampListByPatientIdRes?.count))
      : setHealthcampCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);
  useEffect(() => {
    competitionList?.competitionListByPatientIdRes?.count !== undefined
      ? setCompetitionCountList(String(competitionList?.competitionListByPatientIdRes?.count))
      : setCompetitionCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionList]);
  useEffect(() => {
    instanceList?.instanceListByPatientIdRes?.count !== undefined
      ? setInstanceCountList(String(instanceList?.instanceListByPatientIdRes?.count))
      : setInstanceCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceList]);
  useEffect(() => {
    sessionListValues?.sessionListByPatientIdRes?.count !== undefined
      ? setSessionCountList(String(sessionListValues?.sessionListByPatientIdRes?.count))
      : setSessionCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListValues]);
  const [tabValue, setTabValue] = useState(0);
  const [selected, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=originalpatientdetailsList"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=observationlist"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=patientorganizationlist"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=patienthealthcamplist"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=patientcompetitionlist"}`);
    } else if (newValue === 5) {
      history.push(`${window.location.pathname + "?section=patientinstancelist"}`);
    } else if (newValue === 6) {
      history.push(`${window.location.pathname + "?section=patientsessionlist"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "originalpatientdetailsList") {
      setTabValue(0);
    } else if (tabId === "observationlist") {
      setTabValue(1);
    } else if (tabId === "patientorganizationlist") {
      setTabValue(2);
    } else if (tabId === "patienthealthcamplist") {
      setTabValue(3);
    } else if (tabId === "patientcompetitionlist") {
      setTabValue(4);
    } else if (tabId === "patientinstancelist") {
      setTabValue(5);
    } else if (tabId === "patientsessionlist") {
      setTabValue(6);
    }
    // eslint-disable-next-line
  }, [tabId]);

  const originalPatientDetailApi = () => {
    dispatch(getPatientDataDetailsList(patientoriginalid, 1));
  };

  const observationList = () => {
    dispatch(getExtractObservationByPatientId(patientoriginalid, 1));
  };

  const patientOrgList = () => {
    dispatch(
      organizationListByPatientIdApi(
        {
          patientId: patientoriginalid,
        } as OrganizationListByPatientIdBody,
        1,
      ),
    );
  };

  const patientHealthcampList = () => {
    dispatch(
      healthcampListByPatientIdApi(
        { patientId: patientoriginalid } as HealthcampListByPatientIdBody,
        1,
      ),
    );
  };
  const patientCompetitionList = () => {
    dispatch(
      competitionListByPatientIdApi(
        {
          patientId: patientoriginalid,
        } as CompetitionListByPatientIdBody,
        1,
      ),
    );
  };
  const patientInstanceList = () => {
    dispatch(
      instanceListByPatientIdApi(
        {
          patientId: patientoriginalid,
        } as InstanceListByPatientIdBody,
        1,
      ),
    );
  };
  const patientSessionList = () => {
    dispatch(
      sessionListByPatientIdApi(
        {
          patientId: patientoriginalid,
        } as SessionListByPatientIdBody,
        1,
      ),
    );
  };

  const OriginalPatientDetails = (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Original Patient Details List</Typography>
              </Grid>
              <Grid item>
                <Chip label={listCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                originalPatientDetailApi();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Observation List</Typography>
              </Grid>
              <Grid item>
                <Chip label={obsListCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                observationList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(1)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Organization List</Typography>
              </Grid>
              <Grid item>
                <Chip label={organizationCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientOrgList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(2)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Healthcamp List</Typography>
              </Grid>
              <Grid item>
                <Chip label={healthcampCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientHealthcampList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(3)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Competition List</Typography>
              </Grid>
              <Grid item>
                <Chip label={competitionCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientCompetitionList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(4)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Instance List</Typography>
              </Grid>
              <Grid item>
                <Chip label={instanceCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientInstanceList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(5)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Session List</Typography>
              </Grid>
              <Grid item>
                <Chip label={sessionCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientSessionList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(6)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <PatientDetailsOfOriginalPatient patientoriginalid={patientoriginalid} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ObservationDetails patientoriginalid={patientoriginalid} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <OrganizationListByPatient patientoriginalid={patientoriginalid} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <HealthcampListByPatient patientoriginalid={patientoriginalid} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <CompetitionListByPatient patientoriginalid={patientoriginalid} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <InstanceListByPatient patientoriginalid={patientoriginalid} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <SessionListByPatient patientoriginalid={patientoriginalid} />
      </TabPanel>
    </Box>
  );

  const patientListTable = (
    <Card>
      <CardContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <OriginalDemographicsDetails patientoriginalid={patientoriginalid} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {OriginalPatientDetails}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {patientListTable}
        </Stack>
      </Container>
    </Box>
  );
}
