import {
    ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL,
    ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING,
    ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS,
    ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG,
    ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES,
    AddAppoinmentListToHealthcampActionTypes,
  } from "./ActionTypes";
  import { AddAppointmentListHealthcampRes } from "./Model";
  
  export const addAppoinmentListToHealthcampLoadingAction = (
    loading: boolean,
  ): AddAppoinmentListToHealthcampActionTypes => {
    return {
      type: ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING,
      loading: loading,
    };
  };
  
  export const addAppoinmentListToHealthcampSuccessAction = (
    addAppoinmentListToHealthcampResponse: AddAppointmentListHealthcampRes,
    successMsg: string,
  ): AddAppoinmentListToHealthcampActionTypes => {
    return {
      type: ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS,
      payload: addAppoinmentListToHealthcampResponse,
      successMsg: successMsg,
    };
  };
  
  export const addAppoinmentListToHealthcampErrorAction = (
    addAppoinmentListToHealthcampResponse: AddAppointmentListHealthcampRes,
    errMsg: string,
    status: number,
  ): AddAppoinmentListToHealthcampActionTypes => {
    return {
      type: ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL,
      payload: addAppoinmentListToHealthcampResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const addAppoinmentListToHealthcampUpdateAPIMsgAction = (
    addAppoinmentListToHealthcampResponse: AddAppointmentListHealthcampRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): AddAppoinmentListToHealthcampActionTypes => {
    return {
      type: ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG,
      payload: addAppoinmentListToHealthcampResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const addAppoinmentListToHealthcampAPIResClearAction = (
    addAppoinmentListToHealthcampResponse: AddAppointmentListHealthcampRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): AddAppoinmentListToHealthcampActionTypes => {
    return {
      type: ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES,
      payload: addAppoinmentListToHealthcampResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  