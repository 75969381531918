import { AddDoctorByProviderOrgIdDetails } from "./Model";

export const ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING = "ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING";
export const ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS = "ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS";
export const ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL = "ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL";
export const ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG = "ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG";
export const ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES = "ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES";

export interface AddDoctorByProviderOrgIdState {
  addDoctorByProviderOrgIdRes: AddDoctorByProviderOrgIdDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorByProviderOrgIdLoading {
  type: typeof ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING;
  loading: boolean;
}
export interface AddDoctorByProviderOrgIdSuccess {
  type: typeof ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS;
  payload: AddDoctorByProviderOrgIdDetails;
  successMsg: string;
}
export interface AddDoctorByProviderOrgIdFail {
  type: typeof ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL;
  payload: AddDoctorByProviderOrgIdDetails;
  errorMsg: string;
  status: number;
}
export interface AddDoctorByProviderOrgIdAPIMsg {
  type: typeof ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG;
  payload: AddDoctorByProviderOrgIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorByProviderOrgIdAPIRes {
  type: typeof ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES;
  payload: AddDoctorByProviderOrgIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type AddDoctorByProviderOrgIdActionTypes =
  | AddDoctorByProviderOrgIdLoading
  | AddDoctorByProviderOrgIdSuccess
  | AddDoctorByProviderOrgIdFail
  | AddDoctorByProviderOrgIdAPIMsg
  | AddDoctorByProviderOrgIdAPIRes;
