import { OrganizationToggleActiveRegReqDetailsRes } from "./Model";
export const ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING = "ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING";
export const ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS = "ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS";
export const ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL = "ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL";
export const ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG = "ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG";
export const ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES = "ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES";

export interface OrganizationToggleActiveRegistrationRequestDetailsState {
  organizationToggleActiveRegReqDetailsList: OrganizationToggleActiveRegReqDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OrganizationToggleActiveRegistrationRequestDetailsLoading {
  type: typeof ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING;
  loading: boolean;
}
export interface OrganizationToggleActiveRegistrationRequestDetailsSuccess {
  type: typeof ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS;
  payload: OrganizationToggleActiveRegReqDetailsRes;
  successMsg: string;
}
export interface OrganizationToggleActiveRegistrationRequestDetailsFail {
  type: typeof ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL;
  payload: OrganizationToggleActiveRegReqDetailsRes;
  errorMsg: string;
  status: number;
}
export interface OrganizationToggleActiveRegistrationRequestDetailsUpdateAPIMsg {
  type: typeof ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG;
  payload: OrganizationToggleActiveRegReqDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OrganizationToggleActiveRegistrationRequestDetailsUpdateAPIRes {
  type: typeof ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES;
  payload: OrganizationToggleActiveRegReqDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OrganizationToggleActiveRegistrationRequestDetailsActionTypes =
  | OrganizationToggleActiveRegistrationRequestDetailsLoading
  | OrganizationToggleActiveRegistrationRequestDetailsSuccess
  | OrganizationToggleActiveRegistrationRequestDetailsFail
  | OrganizationToggleActiveRegistrationRequestDetailsUpdateAPIMsg
  | OrganizationToggleActiveRegistrationRequestDetailsUpdateAPIRes;
