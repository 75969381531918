import {
  GOVT_ID_PROOF_FAIL,
  GOVT_ID_PROOF_LOADING,
  GOVT_ID_PROOF_SUCCESS,
  GOVT_ID_PROOF_API_MSG,
  GOVT_ID_PROOF_API_RES,
  GovtIDProofActionTypes,
} from "./ActionTypes";
import { GovtIdProofResponse } from "./Model";

export const govtIDProofListLoadingAction = (
  loading: boolean
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_LOADING,
    loading: loading,
  };
};

export const govtIDProofListSuccessAction = (
  getGenderListRes: GovtIdProofResponse,
  successMsg: string
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_SUCCESS,
    payload: getGenderListRes,
    successMsg: successMsg,
  };
};

export const govtIDProofListErrorAction = (
  getGenderListRes: GovtIdProofResponse,
  errMsg: string,
  status: number
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_FAIL,
    payload: getGenderListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const govtIDProofListUpdateAPIMsgAction = (
  getGenderListRes: GovtIdProofResponse,
  successMsg: string,
  errMsg: string,
  status: number
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_API_MSG,
    payload: getGenderListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const govtIDProofListAPIResClearAction = (
  getGenderListRes: GovtIdProofResponse,
  successMsg: string,
  errMsg: string,
  status: number
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_API_RES,
    payload: getGenderListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

