import { GET_LIST } from "../types/ActionTypes";
import { AdministratorDetailsActionTypes } from "./ActionTypes";
import { GetAdministratorDetailsById } from "./Model";

export const getAdministratorDetails = (
  GetAdministratorDetailsById: GetAdministratorDetailsById,
): AdministratorDetailsActionTypes => {
  return {
    type: GET_LIST,
    payload: GetAdministratorDetailsById,
  };
};
