import { GetDataCurationProblemListRes } from "./Model";
export const GET_DATA_CURATION_PROBLEM_LIST_LOADING = "GET_DATA_CURATION_PROBLEM_LIST_LOADING";
export const GET_DATA_CURATION_PROBLEM_LIST_SUCCESS = "GET_DATA_CURATION_PROBLEM_LIST_SUCCESS";
export const GET_DATA_CURATION_PROBLEM_LIST_FAIL = "GET_DATA_CURATION_PROBLEM_LIST_FAIL";
export const GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_MSG = "GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_MSG";
export const GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_RES = "GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_RES";

export interface GetDataCurationProblemListState {
  getDataCurationProblemListRes: GetDataCurationProblemListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDataCurationProblemListLoading {
  type: typeof GET_DATA_CURATION_PROBLEM_LIST_LOADING;
  loading: boolean;
}
export interface GetDataCurationProblemListSuccess {
  type: typeof GET_DATA_CURATION_PROBLEM_LIST_SUCCESS;
  payload: GetDataCurationProblemListRes;
  successMsg: string;
}
export interface GetDataCurationProblemListFail {
  type: typeof GET_DATA_CURATION_PROBLEM_LIST_FAIL;
  payload: GetDataCurationProblemListRes;
  errorMsg: string;
  status: number;
}
export interface GetDataCurationProblemListUpdateAPIMsg {
  type: typeof GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_MSG;
  payload: GetDataCurationProblemListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDataCurationProblemListUpdateAPIRes {
  type: typeof GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_RES;
  payload: GetDataCurationProblemListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDataCurationProblemListActionTypes =
  | GetDataCurationProblemListLoading
  | GetDataCurationProblemListSuccess
  | GetDataCurationProblemListFail
  | GetDataCurationProblemListUpdateAPIMsg
  | GetDataCurationProblemListUpdateAPIRes;
