import {
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
  SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
  SessionListByDuplicatePatientIdActionTypes,
} from "./ActionTypes";
import { SessionListByDuplicatePatientIdDetails } from "./Model";

export const SessionListByDuplicatePatientIdLoadingAction = (
  loading: boolean,
): SessionListByDuplicatePatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const SessionListByDuplicatePatientIdSuccessAction = (
  sessionListByDuplicatePatientIdResponse: SessionListByDuplicatePatientIdDetails,
  successMsg: string,
): SessionListByDuplicatePatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
    payload: sessionListByDuplicatePatientIdResponse,
    successMsg: successMsg,
  };
};

export const SessionListByDuplicatePatientIdErrorAction = (
  sessionListByDuplicatePatientIdResponse: SessionListByDuplicatePatientIdDetails,
  errMsg: string,
  status: number,
): SessionListByDuplicatePatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
    payload: sessionListByDuplicatePatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const SessionListByDuplicatePatientIdAPIMsgAction = (
  sessionListByDuplicatePatientIdResponse: SessionListByDuplicatePatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): SessionListByDuplicatePatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
    payload: sessionListByDuplicatePatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const SessionListByDuplicatePatientIdAPIResClearAction = (
  sessionListByDuplicatePatientIdResponse: SessionListByDuplicatePatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): SessionListByDuplicatePatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
    payload: sessionListByDuplicatePatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
