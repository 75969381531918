import { GetHealthcampRequestDetailsListRes } from "./Model";
export const HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_LOADING = "HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_LOADING";
export const HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_SUCCESS = "HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_SUCCESS";
export const HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_FAIL = "HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_FAIL";
export const HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG = "HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG";
export const HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES = "HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES";

export interface GetHealthCampRegistrationReqDetailsListState {
  getHealthCampRegistrationRequestDetailsList: GetHealthcampRequestDetailsListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampRegistrationReqDetailsListLoading {
  type: typeof HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthCampRegistrationReqDetailsListSuccess {
  type: typeof HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_SUCCESS;
  payload: GetHealthcampRequestDetailsListRes;
  successMsg: string;
}
export interface GetHealthCampRegistrationReqDetailsListFail {
  type: typeof HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_FAIL;
  payload: GetHealthcampRequestDetailsListRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampRegistrationReqDetailsListUpdateAPIMsg {
  type: typeof HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG;
  payload: GetHealthcampRequestDetailsListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampRegistrationReqDetailsListUpdateAPIRes {
  type: typeof HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES;
  payload: GetHealthcampRequestDetailsListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthCampRegistrationReqDetailsListActionTypes =
  | GetHealthCampRegistrationReqDetailsListLoading
  | GetHealthCampRegistrationReqDetailsListSuccess
  | GetHealthCampRegistrationReqDetailsListFail
  | GetHealthCampRegistrationReqDetailsListUpdateAPIMsg
  | GetHealthCampRegistrationReqDetailsListUpdateAPIRes;
