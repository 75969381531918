import {
  GetUnprocessedPatientDetailsListActionTypes,
  GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL,
  GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING,
  GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS,
  GetUnprocessedPatientDetailsListState,
  GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG,
  GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES,
} from "./ActionTypes";
import { GetUnprocessedPatientDetailsListRes } from "./Model";

const initialStateUpdatePosts: GetUnprocessedPatientDetailsListState = {
  loading: false,
  getUnprocessedPatientDetailsListRes: {} as GetUnprocessedPatientDetailsListRes,
  errorMsg: "",
  status: 0,
};
export const getUnprocessedPatientDetailsListReducer = (
  state = initialStateUpdatePosts,
  action: GetUnprocessedPatientDetailsListActionTypes,
): GetUnprocessedPatientDetailsListState => {
  switch (action.type) {
    case GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        getUnprocessedPatientDetailsListRes: action.payload,
      };
    case GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL:
      return {
        ...state,
        getUnprocessedPatientDetailsListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG:
      return {
        ...state,
        getUnprocessedPatientDetailsListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES:
      return {
        ...state,
        getUnprocessedPatientDetailsListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
