import { GET_LIST } from "../../types/ActionTypes";
import { GetInsuranceResponse } from "./Model";

// Patient Demographics Loading State
export const GET_INSURANCE_LOADING = "GET_INSURANCE_LOADING";
export const GET_INSURANCE_SUCCESS = "GET_INSURANCE_SUCCESS";
export const GET_INSURANCE_FAIL = "GET_INSURANCE_FAIL";

export interface GetPatientDemographicsState {
  getInsuranceDetails: GetInsuranceResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof GET_INSURANCE_LOADING;
}
export interface DoctorListSuccess {
  type: typeof GET_INSURANCE_SUCCESS;
  payload: GetInsuranceResponse;
}
export interface DoctorListFail {
  type: typeof GET_INSURANCE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: GetInsuranceResponse;
}

export type GetInsuranceActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
