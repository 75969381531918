import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { PatientDataRangeBySessionIdTypeActionTypes } from "./ActionTypes";
import {
  PatientDataRangeBySessionIdTypeBody,
  PatientDataRangeBySessionIdTypeDetails,
} from "./Model";
import {
  PatientDataRangeBySessionIdTypeAPIResClearAction,
  PatientDataRangeBySessionIdTypeErrorAction,
  PatientDataRangeBySessionIdTypeLoadingAction,
  PatientDataRangeBySessionIdTypeSuccessAction,
  PatientDataRangeBySessionIdTypeAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as PatientDataRangeBySessionIdTypeDetails;
export const patientDataRangeBySessionIdApi = (
  searchKey: PatientDataRangeBySessionIdTypeBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<PatientDataRangeBySessionIdTypeActionTypes>) {
    dispatch(PatientDataRangeBySessionIdTypeLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
        `/healthcampapp/api/healthcampsessionpatient/session_patient_by_observation_data_range?page=${Number(
          pageNo,
        )}&size=50`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(PatientDataRangeBySessionIdTypeLoadingAction(false));
        dispatch(
          PatientDataRangeBySessionIdTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as PatientDataRangeBySessionIdTypeDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient data range fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(PatientDataRangeBySessionIdTypeLoadingAction(false));
        dispatch(
          PatientDataRangeBySessionIdTypeErrorAction(
            {} as PatientDataRangeBySessionIdTypeDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePatientDataRangeBySessionIdTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientDataRangeBySessionIdTypeActionTypes>) {
    dispatch(
      PatientDataRangeBySessionIdTypeAPIMsgAction(
        apiRes as PatientDataRangeBySessionIdTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearPatientDataRangeBySessionIdTypeAPIRes = () => {
  return function (dispatch: Dispatch<PatientDataRangeBySessionIdTypeActionTypes>) {
    dispatch(
      PatientDataRangeBySessionIdTypeAPIResClearAction(
        {} as PatientDataRangeBySessionIdTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};
