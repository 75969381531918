import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetInstanceListActionTypes } from "./ActionTypes";
import { GetInstanceListBody, GetInstanceListRes } from "./Model";
import {
  getInstanceListAPIResClearAction,
  getInstanceListErrorAction,
  getInstanceListLoadingAction,
  getInstanceListSuccessAction,
  getInstanceListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetInstanceListRes;
export const getInstanceListByHealthCampId = (payload: GetInstanceListBody, pageNo: any) => {
  return function (dispatch: Dispatch<GetInstanceListActionTypes>) {
    dispatch(getInstanceListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampinstance/instance_list_healthcamp?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getInstanceListLoadingAction(false));
        dispatch(
          getInstanceListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetInstanceListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getInstanceListLoadingAction(false));
        dispatch(
          getInstanceListErrorAction(
            {} as GetInstanceListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateInstanceListByHealthCampIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetInstanceListActionTypes>) {
    dispatch(
      getInstanceListUpdateAPIMsgAction(apiRes as GetInstanceListRes, "", "", 0),
    );
  };
};

export const clearInstanceListByHealthCampIdAPIRes = () => {
  return function (dispatch: Dispatch<GetInstanceListActionTypes>) {
    dispatch(getInstanceListAPIResClearAction({} as GetInstanceListRes, "", "", 0));
  };
};
