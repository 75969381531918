import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddInstanceActionTypes } from "./ActionTypes";
import { AddInstanceBody, AddInstanceDetails } from "./Model";
import {
  addInstanceAPIResClearAction,
  addInstanceErrorAction,
  addInstanceLoadingAction,
  addInstanceSuccessAction,
  addInstanceAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddInstanceDetails;
export const addInstanceApiCall = (payload: AddInstanceBody) => {
  return function (dispatch: Dispatch<AddInstanceActionTypes>) {
    dispatch(addInstanceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampinstance/add_instance_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addInstanceLoadingAction(false));
        dispatch(
          addInstanceSuccessAction(
            res.data !== undefined ? res.data : ({} as AddInstanceDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addInstanceLoadingAction(false));
        dispatch(
          addInstanceErrorAction(
            {} as AddInstanceDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddInstanceAPIResMsg = () => {
  return function (dispatch: Dispatch<AddInstanceActionTypes>) {
    dispatch(addInstanceAPIMsgAction(apiRes as AddInstanceDetails, "", "", 0));
  };
};

export const clearAddInstanceAPIRes = () => {
  return function (dispatch: Dispatch<AddInstanceActionTypes>) {
    dispatch(addInstanceAPIResClearAction({} as AddInstanceDetails, "", "", 0));
  };
};
