import React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import { DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { clearAddExistingPtAPIRes } from "../../../../../../redux/addExistingHealthcampPatient/API";
import { clearSearchAPIRes } from "../../../../../../redux/PatientSearchApi/API";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import SearchExecutiveForAddStaff from "../SearchExecutiveForAddStaff";
import DialogTransitions from "component/DialogTransitions";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import { clearExecutiveSearchAPIRes } from "redux/ExecutiveSearchApi/API";

export interface State extends SnackbarOrigin {
  open: boolean;
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  itemId?: string | any;
  addOrganizationStaffDialogEnable?: boolean | any;
  addOrganizationStaffDialogUpdateState?: boolean | any;
  title?: string | any;
  addPt?: boolean | any;
  showPtDetail?: boolean | any;
  showPtDoc?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

export default function AddHealthcampStaffDialog({
  itemId,
  addOrganizationStaffDialogEnable,
  addOrganizationStaffDialogUpdateState,
  title,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addOrganizationStaffDialogUpdateState(false);
    dispatch(clearSearchAPIRes());
    dispatch(clearAddExistingPtAPIRes());
    dispatch(clearExecutiveSearchAPIRes());
  };

  React.useEffect(() => {
    setDialogState(addOrganizationStaffDialogEnable);
  }, [addOrganizationStaffDialogEnable]);

  const executiveSearchListValueRes = useSelector((state: AppState) => state.executiveSearchValue);
  const addExistingPatientValueRes = useSelector((state: AppState) => state.addHealhcampStaffValue);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={dialogState}
      TransitionComponent={DialogTransitions}
      disableEscapeKeyDown
      fullScreen
      onClose={handleDialogClose}
    >
      {executiveSearchListValueRes?.loading ||
        (addExistingPatientValueRes?.loading && <AdminLoader />)}
      <DialogTitle id="customized-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={10} sm={10} md={10} lg="auto" xl="auto">
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg="auto" xl="auto">
            <IconButton aria-label="close" onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <SearchExecutiveForAddStaff healthcampid={itemId} />
      </DialogContent>
    </Dialog>
  );
}
