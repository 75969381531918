import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../../../redux/store/Store";
import {
  updateAddExistingPtAPIResMsg,
} from "../../../../../redux/addExistingHealthcampPatient/API";
import { patientSearchApi } from "../../../../../redux/PatientSearchApi/API";
import { PatientList, PatientSearchBody } from "../../../../../redux/PatientSearchApi/Model";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../component/MWExceptionList";
import dayjs from "dayjs";
import MWPageTitle from "../../../../../component/MWPageTitle";
import MWTextField from "../../../../../component/MWTextField";
import AdminLoader from "../../../../AdminLoader";
import Snackbar from "@mui/material/Snackbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { Portal } from "@mui/base";
import { GetOrganizationPatientListBody } from "../../../../../redux/PatientListByOrganization/Model";
import { getPatientListByOrganization } from "../../../../../redux/PatientListByOrganization/API";

type Props = {
  organizationid?: string | any;
  title?: string | any;
  originalValue?: any;
  dialogClose?: boolean | any;
  dialogUpdate?: boolean | any;
};

export default function SearchDuplicatePatient({ organizationid, title, originalValue, dialogClose, dialogUpdate }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [urlPath, setUrlPath] = useState(history.location.pathname);
  useEffect(() => {
    setUrlPath(history.location.pathname);
  }, [history]);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(updateAddExistingPtAPIResMsg());
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const addExistingPatientSubmit = (element: string) => {
    originalValue(element);
    dialogClose(false);
    dialogUpdate(false);
  };

  const addPatientValueRes = useSelector((state: AppState) => state.addHealthcampPatientData);

  const [inputValue, setInputValue] = React.useState("" as any);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("0");

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const patientSearchListValueRes = useSelector((state: AppState) => state.patientSearchListValue);
  const searchPatientList = patientSearchListValueRes?.patientSearchRes.patientList;

  const addExistingPatientValueRes = useSelector(
    (state: AppState) => state.addExistingPatientValue,
  );
  const [addExistingPt, setAddExistingPt] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    setIsLoading(addExistingPatientValueRes?.loading);
    if (addExistingPatientValueRes?.addPatientRes?.message !== undefined) {
      setAddExistingPt(addExistingPatientValueRes?.addPatientRes?.message);
      setSnackBarType("success");
      handleClick();
    }
    if (addExistingPatientValueRes?.message !== "") {
      setAddExistingPt(addExistingPatientValueRes?.message);
      setSnackBarType("error");
      handleClick();
    }
  }, [addExistingPatientValueRes]);
  const patientSearchBody = {
    searchKey: inputValue,
  } as PatientSearchBody;

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(patientSearchApi(patientSearchBody, value));
  };

  useEffect(() => {
    setIsLoading(patientSearchListValueRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  useEffect(() => {
    patientSearchListValueRes?.patientSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientSearchListValueRes?.patientSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (patientSearchListValueRes?.patientSearchRes?.count !== undefined) {
      setListCount(String(patientSearchListValueRes?.patientSearchRes?.count));
    }
    if (searchPatientList !== undefined) {
      let dataPointArr = searchPatientList.map((element: PatientList) => ({
        id: element.patientId,
        patientId: element.patientId,
        givenName: element.givenName,
        familyName: element.familyName,
        pincode: element.pincode,
        createDate: dayjs(element.createDate).format("ddd, MMM D, YYYY"),
        dob: dayjs(element.dob).format("ddd, MMM D, YYYY"),
        phone: element.phone,
        profilePhotoId: element.profilePhotoId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(patientSearchListValueRes?.patientSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  const columns: GridColDef[] = [
    {
      field: "ptDetails",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "ptDocDetails",
      headerName: "View Pt. Doc.",
      width: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View Document
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      width: 60,
      align: "left",
      flex: 1,
    },
    {
      field: "givenName",
      headerName: "First Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "familyName",
      headerName: "Last Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "createDate",
      headerName: "Created Date",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pin Code",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "patientId",
      headerName: "Action",
      width: 50,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => {
            addExistingPatientSubmit(params.row.patientId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add Original Id
          </span>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (addPatientValueRes?.addPatientRes?.message !== undefined) {
      const filterPatientListBody = {
        organizationId: organizationid,
      } as GetOrganizationPatientListBody;
      dispatch(getPatientListByOrganization(filterPatientListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  const PatientSearchListData = (
    <Card sx={{ height: "85vh" }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <MWPageTitle title="Patient Search List" enableCount={true} count={listCount} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                pt={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {searchPatientList && searchPatientList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const pageBody = (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <MWPageTitle
            backButton={true}
            title={urlPath !== "/searchpatient" ? title : "Search Patient"}
          />
        ) : null}
        <MWTextField
          label="Patient Search In MedicalWISDOM Platform"
          value={inputValue}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              dispatch(patientSearchApi(patientSearchBody, page));
              ev.preventDefault();
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
          placeholder="Please enter value"
          id="searchValue"
          fullWidth
          size="small"
        />
        <Box>{PatientSearchListData}</Box>
      </Stack>
      <Portal>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} action={action}>
          <Alert severity={snackBarType!}>{addExistingPt}</Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
  return (
    <Box>
      {isLoading && urlPath === "/searchpatient" ? <AdminLoader /> : null}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <Container maxWidth="xl">{pageBody}</Container>
        ) : (
          <Box>{pageBody}</Box>
        )}
      </Stack>
    </Box>
  );
}
