import {
  PATIENT_CASE_LIST_BY_SESSION_ID_FAIL,
  PATIENT_CASE_LIST_BY_SESSION_ID_LOADING,
  PATIENT_CASE_LIST_BY_SESSION_ID_SUCCESS,
  PATIENT_CASE_LIST_BY_SESSION_ID_UPDATE_API_MSG,
  PATIENT_CASE_LIST_BY_SESSION_ID_UPDATE_API_RES,
  GetPatientCaseListBySessionIdActionTypes,
} from "./ActionTypes";
import { GetPatientCaseListBySessionIdRes } from "./Model";

export const getPatientCaseListBySessionIdLoadingAction = (
  loading: boolean,
): GetPatientCaseListBySessionIdActionTypes => {
  return {
    type: PATIENT_CASE_LIST_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const getPatientCaseListBySessionIdSuccessAction = (
  getPatientCaseListBySessionIdResponse: GetPatientCaseListBySessionIdRes,
  successMsg: string,
): GetPatientCaseListBySessionIdActionTypes => {
  return {
    type: PATIENT_CASE_LIST_BY_SESSION_ID_SUCCESS,
    payload: getPatientCaseListBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const getPatientCaseListBySessionIdErrorAction = (
  getPatientCaseListBySessionIdResponse: GetPatientCaseListBySessionIdRes,
  errMsg: string,
  status: number,
): GetPatientCaseListBySessionIdActionTypes => {
  return {
    type: PATIENT_CASE_LIST_BY_SESSION_ID_FAIL,
    payload: getPatientCaseListBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientCaseListBySessionIdUpdateAPIMsgAction = (
  getPatientCaseListBySessionIdResponse: GetPatientCaseListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientCaseListBySessionIdActionTypes => {
  return {
    type: PATIENT_CASE_LIST_BY_SESSION_ID_UPDATE_API_MSG,
    payload: getPatientCaseListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientCaseListBySessionIdAPIResClearAction = (
  getPatientCaseListBySessionIdResponse: GetPatientCaseListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientCaseListBySessionIdActionTypes => {
  return {
    type: PATIENT_CASE_LIST_BY_SESSION_ID_UPDATE_API_RES,
    payload: getPatientCaseListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
