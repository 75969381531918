import { PageCount, GetJudgingDetailsListDetails } from "./Model";

// Patient Demographics Loading State
export const GET_JUDGING_LIST_ID_LOADING = "GET_JUDGING_LIST_ID_LOADING";
export const GET_JUDGING_LIST_ID_SUCCESS = "GET_JUDGING_LIST_ID_SUCCESS";
export const GET_JUDGING_LIST_ID_FAIL = "GET_JUDGING_LIST_ID_FAIL";
export const GET_JUDGING_LIST_ID_DATA = "GET_JUDGING_LIST_ID_DATA";
export const GET_JUDGING_LIST_ID_API_MSG = "GET_JUDGING_LIST_ID_API_MSG";
export const GET_JUDGING_LIST_ID_API_RES = "GET_JUDGING_LIST_ID_API_RES";

export interface GetJudgingDetailsListState {
  getJudgingDetailsListRes: GetJudgingDetailsListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface GetJudgingDetailsListLoading {
  type: typeof GET_JUDGING_LIST_ID_LOADING;
  loading: boolean;
}
export interface GetJudgingDetailsListSuccess {
  type: typeof GET_JUDGING_LIST_ID_SUCCESS;
  payload: GetJudgingDetailsListDetails;
}
export interface GetJudgingDetailsListFail {
  type: typeof GET_JUDGING_LIST_ID_FAIL;
  payload: GetJudgingDetailsListDetails;
  message: string;
  status: number;
}
export interface GetJudgingDetailsListAPIMsg {
  type: typeof GET_JUDGING_LIST_ID_API_MSG;
  payload: GetJudgingDetailsListDetails;
  message: string;
  status: number;
}
export interface GetJudgingDetailsListRes {
  type: typeof GET_JUDGING_LIST_ID_API_RES;
  payload: GetJudgingDetailsListDetails;
  message: string;
  status: number;
}
interface GetJudgingDetailsListDataAction {
  type: typeof GET_JUDGING_LIST_ID_DATA;
  payload: GetJudgingDetailsListDetails;
  pageCount: PageCount;
}

export type GetJudgingDetailsListActionTypes =
  | GetJudgingDetailsListDataAction
  | GetJudgingDetailsListLoading
  | GetJudgingDetailsListSuccess
  | GetJudgingDetailsListFail
  | GetJudgingDetailsListAPIMsg
  | GetJudgingDetailsListRes;
