import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../component/MWExceptionList";
import { GetJuryListByCompetitionBody, JuryList } from "redux/JuryListByCompetition/Model";
import { getJuryListByCompition } from "redux/JuryListByCompetition/API";

type Props = {
    juryListOfCompetitonLoader?: boolean | any;
};

export default function JuryListOfCompetition({ juryListOfCompetitonLoader }: Props) {
    /*  create a piece of state (toSignin)
      with a method to update that state (setToHome)
      and give it a default value of false */
    const dispatch = useDispatch();
    const { competitionid } = useParams() as {
        competitionid: string;
    };

    const [page, setPage] = useState(1);
    const [pageListCount, setPageListCount] = React.useState("");
    const [dataGridArray, setDataGridArray] = useState([] as any[]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const patientListBody = {
            competitionId: competitionid,
        } as GetJuryListByCompetitionBody;
        dispatch(getJuryListByCompition(patientListBody, 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // call Store
    const getJuryListValues = useSelector((state: AppState) => state.getJuryListByCompetitionValue);
    const juryListValues = getJuryListValues?.getJuryListByCompetitionRes?.juryList;

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        if (juryListValues !== undefined) {
            const patientListBody = {
                competitionId: competitionid,
            } as GetJuryListByCompetitionBody;
            dispatch(getJuryListByCompition(patientListBody, value));
        }
    };
   
    useEffect(() => {
        getJuryListValues?.getJuryListByCompetitionRes?.numberOfPages !== undefined
            ? setPageListCount(String(getJuryListValues?.getJuryListByCompetitionRes?.numberOfPages))
            : setPageListCount("0");
        if (getJuryListValues?.getJuryListByCompetitionRes?.juryList !== undefined) {
            let dataPointArr = juryListValues.map(
                (element: JuryList) => ({
                    id: element.juryId,
                    juryId: element.juryId,
                    juryFullName: element.juryFullName,
                    juryEmail: element.juryEmail,
                }),
            );
            setDataGridArray(dataPointArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getJuryListValues]);


    const columns: GridColDef[] = [
        {
            field: "juryId",
            headerName: "Jury Id",
            width: 150,
        },
        { field: "juryFullName", headerName: "Jury Name", width: 500, flex: 1 },
        { field: "juryEmail", headerName: "Email", width: 500, flex: 1 },
    ];

    const JuryListOfCompetition = (
        <Card>
            <CardHeader
                title={
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item></Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                spacing={2}
                                pt={2}
                            >
                                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                                <Pagination
                                    color="primary"
                                    count={Number(pageListCount) || 0}
                                    page={page}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent>
                <Box>
                    {juryListValues && juryListValues.length ? (
                        <DataGrid
                            rows={dataGridArray}
                            columns={columns}
                            disableSelectionOnClick
                            hideFooter
                            hideFooterPagination
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true },
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 250 },
                                },
                            }}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                        />
                    ) : (
                        <MWExceptionList />
                    )}
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <Box>
            {getJuryListValues.loading === true
                ? juryListOfCompetitonLoader(true)
                : juryListOfCompetitonLoader(false)}
            <Container maxWidth="xl">
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    pt={2}
                >
                    {JuryListOfCompetition}
                </Stack>
            </Container>
        </Box>
    );
}
