import {
  DELETE_SCHEDULE_FAIL,
  DELETE_SCHEDULE_LOADING,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_UPDATE_API_MSG,
  DELETE_SCHEDULE_UPDATE_API_RES,
  DeleteScheduleActionTypes,
} from "./ActionTypes";
import { DeleteScheduleRes } from "./Model";

export const deleteScheduleLoadingAction = (
  loading: boolean
): DeleteScheduleActionTypes => {
  return {
    type: DELETE_SCHEDULE_LOADING,
    loading: loading,
  };
};

export const deleteScheduleSuccessAction = (
  deleteScheduleResponse: DeleteScheduleRes,
  successMsg: string
): DeleteScheduleActionTypes => {
  return {
    type: DELETE_SCHEDULE_SUCCESS,
    payload: deleteScheduleResponse,
    successMsg: successMsg,
  };
};

export const deleteScheduleErrorAction = (
  deleteScheduleResponse: DeleteScheduleRes,
  errMsg: string,
  status: number
): DeleteScheduleActionTypes => {
  return {
    type: DELETE_SCHEDULE_FAIL,
    payload: deleteScheduleResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deleteScheduleUpdateAPIMsgAction = (
  deleteScheduleResponse: DeleteScheduleRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeleteScheduleActionTypes => {
  return {
    type: DELETE_SCHEDULE_UPDATE_API_MSG,
    payload: deleteScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deleteScheduleAPIResClearAction = (
  deleteScheduleResponse: DeleteScheduleRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeleteScheduleActionTypes => {
  return {
    type: DELETE_SCHEDULE_UPDATE_API_RES,
    payload: deleteScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
