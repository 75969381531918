import { ViewHistorySessionRes } from "./Model";
export const VIEW_HISTORY_SESSION_LOADING = "VIEW_HISTORY_SESSION_LOADING";
export const VIEW_HISTORY_SESSION_SUCCESS = "VIEW_HISTORY_SESSION_SUCCESS";
export const VIEW_HISTORY_SESSION_FAIL = "VIEW_HISTORY_SESSION_FAIL";
export const VIEW_HISTORY_SESSION_UPDATE_API_MSG = "VIEW_HISTORY_SESSION_UPDATE_API_MSG";
export const VIEW_HISTORY_SESSION_UPDATE_API_RES = "VIEW_HISTORY_SESSION_UPDATE_API_RES";

export interface ViewHistorySessionState {
  viewHistorySession: ViewHistorySessionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewHistorySessionLoading {
  type: typeof VIEW_HISTORY_SESSION_LOADING;
  loading: boolean;
}
export interface ViewHistorySessionSuccess {
  type: typeof VIEW_HISTORY_SESSION_SUCCESS;
  payload: ViewHistorySessionRes;
  successMsg: string;
}
export interface ViewHistorySessionFail {
  type: typeof VIEW_HISTORY_SESSION_FAIL;
  payload: ViewHistorySessionRes;
  errorMsg: string;
  status: number;
}
export interface ViewHistorySessionUpdateAPIMsg {
  type: typeof VIEW_HISTORY_SESSION_UPDATE_API_MSG;
  payload: ViewHistorySessionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewHistorySessionUpdateAPIRes {
  type: typeof VIEW_HISTORY_SESSION_UPDATE_API_RES;
  payload: ViewHistorySessionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ViewHistorySessionActionTypes =
  | ViewHistorySessionLoading
  | ViewHistorySessionSuccess
  | ViewHistorySessionFail
  | ViewHistorySessionUpdateAPIMsg
  | ViewHistorySessionUpdateAPIRes;
