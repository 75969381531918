import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { PreviewMarksDetailsActionTypes } from "./ActionTypes";
import { PreviewMarksDetailsBody, PreviewMarksDetailsRes } from "./Model";
import {
  previewMarksDetailsAPIResClearAction,
  previewMarksDetailsErrorAction,
  previewMarksDetailsLoadingAction,
  previewMarksDetailsSuccessAction,
  previewMarksDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as PreviewMarksDetailsRes;
export const previewCutoffMarksApi = (
  payload: PreviewMarksDetailsBody,
) => {
  return function (dispatch: Dispatch<PreviewMarksDetailsActionTypes>) {
    dispatch(previewMarksDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/competitionapp/api/competitionSecondRound/getPatientDetailsListPreviewToNextRoundByCutoff`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(previewMarksDetailsLoadingAction(false));
        dispatch(previewMarksDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(previewMarksDetailsLoadingAction(false));
        dispatch(
          previewMarksDetailsErrorAction(
            {} as PreviewMarksDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const previewCutoffMarksAPIResMsg = () => {
  return function (dispatch: Dispatch<PreviewMarksDetailsActionTypes>) {
    dispatch(
      previewMarksDetailsAPIMsgAction(
        apiRes as PreviewMarksDetailsRes,
        "",
        0,
      ),
    );
  };
};

export const clearPreviewCutoffMarksAPIRes = () => {
  return function (dispatch: Dispatch<PreviewMarksDetailsActionTypes>) {
    dispatch(
      previewMarksDetailsAPIResClearAction(
        {} as PreviewMarksDetailsRes,
        "",
        0,
      ),
    );
  };
};
