import { ActivatePatientRes } from "./Model";
export const ACTIVATE_PATIENT_LOADING = "ACTIVATE_PATIENT_LOADING";
export const ACTIVATE_PATIENT_SUCCESS = "ACTIVATE_PATIENT_SUCCESS";
export const ACTIVATE_PATIENT_FAIL = "ACTIVATE_PATIENT_FAIL";
export const ACTIVATE_PATIENT_DATA = "ACTIVATE_PATIENT_DATA";
export const ACTIVATE_PATIENT_UPDATE_API_MSG = "ACTIVATE_PATIENT_UPDATE_API_MSG";
export const ACTIVATE_PATIENT_UPDATE_API_RES = "ACTIVATE_PATIENT_UPDATE_API_RES";

export interface ActivatePatientState {
  activatePatientRes: ActivatePatientRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ActivatePatientLoading {
  type: typeof ACTIVATE_PATIENT_LOADING;
  loading: boolean;
}
export interface ActivatePatientSuccess {
  type: typeof ACTIVATE_PATIENT_SUCCESS;
  payload: ActivatePatientRes;
  successMsg: string;
}
export interface ActivatePatientFail {
  type: typeof ACTIVATE_PATIENT_FAIL;
  payload: ActivatePatientRes;
  errorMsg: string;
  status: number;
}
export interface ActivatePatientUpdateAPIMsg {
  type: typeof ACTIVATE_PATIENT_UPDATE_API_MSG;
  payload: ActivatePatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ActivatePatientUpdateAPIRes {
  type: typeof ACTIVATE_PATIENT_UPDATE_API_RES;
  payload: ActivatePatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ActivatePatientActionTypes =
  | ActivatePatientLoading
  | ActivatePatientSuccess
  | ActivatePatientFail
  | ActivatePatientUpdateAPIMsg
  | ActivatePatientUpdateAPIRes;
