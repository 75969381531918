import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import MWPageTitle from "component/MWPageTitle";
import MWTextField from "component/MWTextField";
import { AddDoctorByProviderOrgIdBody } from "redux/AddDoctorByProviderOrgId/Model";
import { addDoctorByProviderOrgIdApiCall, clearAddDoctorByProviderOrgIdAPIRes } from "redux/AddDoctorByProviderOrgId/API";
import { DoctorList, DoctorSearchBody } from "redux/DoctorSearchByProviderOrg/Model";
import { DoctorSearchApi } from "redux/DoctorSearchByProviderOrg/API";
import MWSnackbar from "component/MWSnackbar";
import { getDoctorListByProviderOrganizationIdApi } from "redux/GetDoctorListByProviderOrgId/API";
import { GetDoctorListByProviderOrganizationIdBody } from "redux/GetDoctorListByProviderOrgId/Model";

type Props = {
  title: string | any;
};

export default function SearchDoctorComponent({ title }: Props) {
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [urlPath, setUrlPath] = useState(history.location.pathname);
  useEffect(() => {
    setUrlPath(history.location.pathname);
  }, [history]);

  const doctorDetails = (doctorId: any) => {
    history.push(`/doctordetails/${doctorId}`);
  };
  const viewDoctorAppointment = (doctorId: any) => {
    // history.push(`/doctorapoointment/${doctorId}`);
    let apiBody = {
      providerorgId: organizationid,
      doctorId: doctorId,
    } as AddDoctorByProviderOrgIdBody;
    dispatch(addDoctorByProviderOrgIdApiCall(apiBody))
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const [inputValue, setInputValue] = React.useState("" as any);
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("1");
  const [listCount, setListCount] = React.useState("0");
  const [addExistingDoctorMsg, setAddExistingDoctorMsg] = useState("");
  const [addExistingDoctorMsgType, setAddExistingDoctorMsgType] = useState<AlertColor>("success");
  // Api call from store.....
  const doctorList = useSelector((state: AppState) => state.doctorSearchResult);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(DoctorSearchApi({ searchKey: inputValue } as DoctorSearchBody, value));
  };
  useEffect(() => {
    doctorList?.DoctorSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(doctorList?.DoctorSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (doctorList?.DoctorSearchRes?.count !== undefined) {
      setListCount(String(doctorList?.DoctorSearchRes?.count));
    }
    if (doctorList?.DoctorSearchRes?.doctorList !== undefined) {
      let dataPointArr = doctorList?.DoctorSearchRes?.doctorList.map(
        (element: DoctorList) => ({
          id: element.id,
          doctorName: element.fullName,
          doctorId: element.id,
          doctorQualification: element.qualification,
          doctorExperience: element.specialtyWithYearsOfExperience,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    else {
      setPageListCount(String(doctorList?.DoctorSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList]);

  const addDoctorResponse = useSelector((state: AppState) => state.addDoctorByProviderOrgValue);
  useEffect(() => {
    if (addDoctorResponse?.successMsg !== "") {
      setAddExistingDoctorMsgType("success");
      setAddExistingDoctorMsg(addDoctorResponse?.successMsg);
      dispatch(
        getDoctorListByProviderOrganizationIdApi({
          providerorgId: organizationid,
        } as GetDoctorListByProviderOrganizationIdBody),
      );
    }
    if (addDoctorResponse?.errorMsg !== "") {
      setAddExistingDoctorMsgType("error");
      setAddExistingDoctorMsg(addDoctorResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDoctorResponse]);

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddDoctorByProviderOrgIdAPIRes());
      setAddExistingDoctorMsg("");
    }
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Doctor Details",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            doctorDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "doctorName", headerName: "Doctor Name", minWidth: 300 },
    { field: "doctorQualification", headerName: "Qualification", flex: 1 },
    { field: "doctorExperience", headerName: "Experience", minWidth: 300 },
    {
      field: "doctorId",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            viewDoctorAppointment(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          Add Doctor
        </Button>
      ),
    },
  ];

  const PatientSearchListData = (
    <Card sx={{ height: "85vh" }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <MWPageTitle title="Doctor Search List" enableCount={true} count={listCount} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                pt={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {doctorList?.DoctorSearchRes?.doctorList &&
          doctorList?.DoctorSearchRes?.doctorList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const pageBody = (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchdoctor" ? (
          <MWPageTitle
            backButton={true}
            title={urlPath !== "/searchdoctor" ? title : "Search Doctor "}
          />
        ) : null}
        <MWTextField
          label="Doctor Search In MedicalWISDOM Platform"
          value={inputValue}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              dispatch(DoctorSearchApi({ searchKey: inputValue } as DoctorSearchBody, 1));
              ev.preventDefault();
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
          placeholder="Please enter value"
          id="doctorValue"
          fullWidth
          size="small"
        />
        <Box>{PatientSearchListData}</Box>
      </Stack>
    </Box>
  );
  return (
    <Box>
      {/* {isLoading && urlPath === "/searchpatient" ? <AdminLoader /> : null} */}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchdoctor" ? (
          <Container maxWidth="xl">{pageBody}</Container>
        ) : (
          <Box>{pageBody}</Box>
        )}
      </Stack>
      {addExistingDoctorMsg !== "" ? (
        <MWSnackbar
          msg={addExistingDoctorMsg}
          type={addExistingDoctorMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
