import React,{} from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SearchPatientInHealthcampcamp from "../SearchPatientInHealthcampcamp";
import { clearSearchAPIRes } from "redux/PatientSearchApi/API";
import { useParams } from "react-router-dom";
import { GetAppointmentListHealthcampBody } from "redux/AppointmentListOfHealthcampInstance/Model";
import { getAppointmentListOfHealthcampInstance } from "redux/AppointmentListOfHealthcampInstance/API";


type Props = {
  itemId?: string | any;
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
};

export default function AddAppoinmentDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  itemId
}: Props) {
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = React.useState(false);
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearSearchAPIRes());
    const healthcampAppoinmentListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetAppointmentListHealthcampBody;
    dispatch(getAppointmentListOfHealthcampInstance(healthcampAppoinmentListBody, 1));
    
  };

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  return (
    <Box>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Add Appointment</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <SearchPatientInHealthcampcamp organizationid={itemId} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
