import {
    HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_FAIL,
    HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_LOADING,
    HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_SUCCESS,
    HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG,
    HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES,
    GetHealthCampRegistrationReqDetailsListActionTypes,
  } from "./ActionTypes";
  import { GetHealthcampRequestDetailsListRes } from "./Model";
  
  export const getHealthCampRegistrationReqDetailsListLoadingAction = (
    loading: boolean,
  ): GetHealthCampRegistrationReqDetailsListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getHealthCampRegistrationReqDetailsListSuccessAction = (
    getHealthCampRegistrationReqDetailsListresponse: GetHealthcampRequestDetailsListRes,
    successMsg: string,
  ): GetHealthCampRegistrationReqDetailsListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_SUCCESS,
      payload: getHealthCampRegistrationReqDetailsListresponse,
      successMsg: successMsg,
    };
  };
  
  export const getHealthCampRegistrationReqDetailsListErrorAction = (
    getHealthCampRegistrationReqDetailsListresponse: GetHealthcampRequestDetailsListRes,
    errMsg: string,
    status: number,
  ): GetHealthCampRegistrationReqDetailsListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_FAIL,
      payload: getHealthCampRegistrationReqDetailsListresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getHealthCampRegistrationReqDetailsListUpdateAPIMsgAction = (
    getHealthCampRegistrationReqDetailsListresponse: GetHealthcampRequestDetailsListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetHealthCampRegistrationReqDetailsListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG,
      payload: getHealthCampRegistrationReqDetailsListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getHealthCampRegistrationReqDetailsListAPIResClearAction = (
    getHealthCampRegistrationReqDetailsListresponse: GetHealthcampRequestDetailsListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetHealthCampRegistrationReqDetailsListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES,
      payload: getHealthCampRegistrationReqDetailsListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  