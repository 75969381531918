import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { SetPreferenceActionTypes } from "./ActionTypes";
import { SetPreferenceBody, SetPreferenceRes } from "./Model";
import {
  setPreferenceAPIResClearAction,
  setPreferenceErrorAction,
  setPreferenceLoadingAction,
  setPreferenceSuccessAction,
  setPreferenceUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as SetPreferenceRes;
export const setPreference = (payload: SetPreferenceBody) => {
  return function (dispatch: Dispatch<SetPreferenceActionTypes>) {
    dispatch(setPreferenceLoadingAction(true));
    axios
      .post(Url.baseUrl + `/caregiverapp/api/defaultPref/setPreferences`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(setPreferenceLoadingAction(false));
        dispatch(
          setPreferenceSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Preferences set successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(setPreferenceLoadingAction(false));
        dispatch(
          setPreferenceErrorAction(
            {} as SetPreferenceRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const setPreferenceAPIResMsg = () => {
  return function (dispatch: Dispatch<SetPreferenceActionTypes>) {
    dispatch(setPreferenceUpdateAPIMsgAction(apiRes as SetPreferenceRes, "", "", 0));
  };
};

export const clearSetPreferenceAPIRes = () => {
  return function (dispatch: Dispatch<SetPreferenceActionTypes>) {
    dispatch(setPreferenceAPIResClearAction({} as SetPreferenceRes, "", "", 0));
  };
};
