import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetVitalCollectionStatusActionTypes } from "./ActionTypes";
import { GetVitalCollectionStatusBody, GetVitalCollectionStatusRes } from "./Model";
import {
  getVitalCollectionStatusAPIResClearAction,
  getVitalCollectionStatusErrorAction,
  getVitalCollectionStatusLoadingAction,
  getVitalCollectionStatusSuccessAction,
  getVitalCollectionStatusUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetVitalCollectionStatusRes;
export const getVitalCollectionStatusApi = (
  payload: GetVitalCollectionStatusBody
) => {
  return function (dispatch: Dispatch<GetVitalCollectionStatusActionTypes>) {
    dispatch(getVitalCollectionStatusLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampSessionVitalsCollection/get_vitalCollectionRequest_status_in_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getVitalCollectionStatusLoadingAction(false));
        dispatch(
          getVitalCollectionStatusSuccessAction(
            res.data !== undefined ? res.data : ({} as GetVitalCollectionStatusRes),
            res.data.message !== undefined
              ? res.data.message
              : "Vital Collection status successful",
          ),
        );
      })
      .catch((error) => {
        dispatch(getVitalCollectionStatusLoadingAction(false));
        dispatch(
          getVitalCollectionStatusErrorAction(
            {} as GetVitalCollectionStatusRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetVitalCollectionStatusAPIResMsg = () => {
  return function (dispatch: Dispatch<GetVitalCollectionStatusActionTypes>) {
    dispatch(
      getVitalCollectionStatusUpdateAPIMsgAction(
        apiRes as GetVitalCollectionStatusRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetVitalCollectionStatusAPIRes = () => {
  return function (dispatch: Dispatch<GetVitalCollectionStatusActionTypes>) {
    dispatch(
      getVitalCollectionStatusAPIResClearAction({} as GetVitalCollectionStatusRes, "", "", 0),
    );
  };
};
