import { AddDataCurationProblemDetails } from "./Model";
export const ADD_DATA_CURATION_PROBLEM_LOADING = "ADD_DATA_CURATION_PROBLEM_LOADING";
export const ADD_DATA_CURATION_PROBLEM_SUCCESS = "ADD_DATA_CURATION_PROBLEM_SUCCESS";
export const ADD_DATA_CURATION_PROBLEM_FAIL = "ADD_DATA_CURATION_PROBLEM_FAIL";
export const ADD_DATA_CURATION_PROBLEM_API_MSG = "ADD_DATA_CURATION_PROBLEM_API_MSG";
export const ADD_DATA_CURATION_PROBLEM_API_RES = "ADD_DATA_CURATION_PROBLEM_API_RES";

export interface AddDataCurationProblemState {
  addDataCurationProblemRes: AddDataCurationProblemDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDataCurationProblemLoading {
  type: typeof ADD_DATA_CURATION_PROBLEM_LOADING;
  loading: boolean;
}
export interface AddDataCurationProblemSuccess {
  type: typeof ADD_DATA_CURATION_PROBLEM_SUCCESS;
  payload: AddDataCurationProblemDetails;
  successMsg: string;
}
export interface AddDataCurationProblemFail {
  type: typeof ADD_DATA_CURATION_PROBLEM_FAIL;
  payload: AddDataCurationProblemDetails;
  errorMsg: string;
  status: number;
}
export interface AddDataCurationProblemAPIMsg {
  type: typeof ADD_DATA_CURATION_PROBLEM_API_MSG;
  payload: AddDataCurationProblemDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDataCurationProblemAPIRes {
  type: typeof ADD_DATA_CURATION_PROBLEM_API_RES;
  payload: AddDataCurationProblemDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDataCurationProblemActionTypes =
  | AddDataCurationProblemLoading
  | AddDataCurationProblemSuccess
  | AddDataCurationProblemFail
  | AddDataCurationProblemAPIMsg
  | AddDataCurationProblemAPIRes;
