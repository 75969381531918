import { useEffect, useState } from "react";
import AppBars from "./AppBar";
import AppDrawer from "./Drawer";
import { Box, CssBaseline } from "@mui/material";
import PreferenceDrawer from "component/AppNavDrawer";
import { useHistory, useLocation } from "react-router-dom";
import SecureLS from "secure-ls";

function AppFrame() {
  const location = useLocation();
  const history = useHistory();
  var ls = new SecureLS({ encodingType: "aes" });
  const Token = ls.get("access");
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const updateDrawerState = (passedVal: boolean) => {
    setIsDrawerOpen(passedVal);
  };
  const updateDrawerStateFromAppBar = (passedVal: boolean) => {
    setIsDrawerOpen(passedVal);
  };
  const updateHoverState = (passedVal: boolean) => {
    setIsDrawerOpen(passedVal);
  };
  const [showPreferenceDrawer, setShowPreferenceDrawer] = useState(false);
  const [showPreferenceDrawerVal, setShowPreferenceDrawerVal] = useState("0");

  const updatePreferenceDrawerState = (passedVal: boolean) => {
    setShowPreferenceDrawer(passedVal);
  };
  const updatePreferenceDrawerVal = (passedVal: string) => {
    setShowPreferenceDrawerVal(passedVal);
  };
  const closeDrawer = (value: boolean) => {
    setShowPreferenceDrawer(value);
    location.pathName !== undefined &&
      history.push(`${location.pathName.split("?preferences")[0]}`);
  };
  useEffect(() => {
    document.addEventListener("keydown", function (zEvent) {
      if (Token && zEvent.altKey && zEvent.key === "p") {
        setShowPreferenceDrawer(!showPreferenceDrawer);
        setShowPreferenceDrawerVal("0");
        updatePreferenceDrawerVal("0");
      }
    });
    document.addEventListener("keydown", function (zEvent) {
      if (Token && zEvent.altKey && zEvent.key === "u") {
        setShowPreferenceDrawer(!showPreferenceDrawer);
        setShowPreferenceDrawerVal("1");
        updatePreferenceDrawerVal("1");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPreferenceDrawer]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBars
        drawerState={isDrawerOpen}
        updateDrawerStateFromAppBar={updateDrawerStateFromAppBar}
        updateHoverState={updateHoverState}
        updatePreferenceDrawerState={updatePreferenceDrawerState}
        updatePreferenceDrawerVal={updatePreferenceDrawerVal}
      />
      <AppDrawer drawerState={isDrawerOpen} updateDrawerState={updateDrawerState} />
      {showPreferenceDrawer && (
        <PreferenceDrawer
          selection={showPreferenceDrawerVal}
          enable={showPreferenceDrawer}
          disable={closeDrawer}
        />
      )}
    </Box>
  );
}

export default AppFrame;
