import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AddSessionBody } from "redux/AddSession/Model";
import { addSessionApiCall, clearAddSessionAPIRes } from "redux/AddSession/API";
import { HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import _ from "lodash";
import { EditSessionBody } from "redux/EditSession/Model";
import {
  clearEditSessionAPIRes,
  editSessionApiCall,
  updateEditSessionAPIResMsg,
} from "redux/EditSession/API";
import SessionHistoryDialog from "../SessionHistoryDialog";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  instanceHealthcampId?: string | any;
  healthcampSessionId?: string | any;
};

export default function AddHealthcampSessionDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  instanceHealthcampId,
  healthcampSessionId,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(updateEditSessionAPIResMsg());
    dispatch(clearAddSessionAPIRes());
    dispatch(clearEditSessionAPIRes());
  };
  const addResponse = useSelector((state: AppState) => state.addSessionResValue);
  const editessionResponse = useSelector((state: AppState) => state.editSessionValue);
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const [instanceValue, setInstanceValue] = useState("");
  const [startTime, setStartTime] = React.useState<Dayjs | null | string>(
    dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
  );
  const [endTime, setEndTime] = React.useState<Dayjs | null | string>(
    dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
  );
  const [sessionDate, setSessionDate] = React.useState<Dayjs | null | string>(dayjs(new Date()));
  const [showHistory, setShowHistory] = useState(false);
  const handleDateChange = (sessionDateValue: Dayjs | null | string) => {
    setSessionDate(dayjs(sessionDateValue));
  };
  const handleChange = (startTimeValue: Dayjs | null | string) => {
    setStartTime(dayjs(startTimeValue));
  };

  const handleChangeEndTime = (endTimeValue: Dayjs | null | string) => {
    setEndTime(dayjs(endTimeValue));
  };

  const [getSessionListObject, setGetSessionListObject] = React.useState<
    HealthcampSessionList | undefined
  >(undefined);

  useEffect(() => {
    if (sessionListResponseValue?.getSessionList?.healthcampSessionList !== undefined) {
      setGetSessionListObject(
        _.find(sessionListResponseValue?.getSessionList?.healthcampSessionList, [
          "id",
          Number(healthcampSessionId),
        ]),
      );
    } else {
      setGetSessionListObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListResponseValue]);

  useEffect(() => {
    if (getSessionListObject !== undefined) {
      if (getSessionListObject.displayName !== undefined) {
        setInstanceValue(getSessionListObject.displayName);
      } else setInstanceValue("");
      setSessionDate(
        getSessionListObject?.sessionDate !== undefined ? getSessionListObject?.sessionDate : "",
      );
      setStartTime(
        getSessionListObject?.sessionStartTime !== undefined
          ? dayjs(
              getSessionListObject?.sessionDate + "T" + getSessionListObject?.sessionStartTime,
            ).format("YYYY-MM-DDTHH:mm:ss")
          : "",
      );
      setEndTime(
        getSessionListObject?.sessionEndTime !== undefined
          ? dayjs(
              getSessionListObject?.sessionDate + "T" + getSessionListObject?.sessionEndTime,
            ).format("YYYY-MM-DDTHH:mm:ss")
          : "",
      );
    } else {
      setInstanceValue("");
      setSessionDate(dayjs(new Date()));
      setStartTime(dayjs(new Date()));
      setEndTime(dayjs(new Date()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSessionListObject]);

  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  useEffect(() => {
    if (editessionResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editessionResponse]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const addSession = () => {
    if (healthcampSessionId !== "") {
      let editSessionBody = {
        healthcampSessionId: healthcampSessionId,
        displayName: instanceValue,
        sessionDate: sessionDate !== null && dayjs(sessionDate).format("YYYY-MM-DD"),
        sessionStartTime: startTime !== null && dayjs(startTime).format("HH:mm:ss"),
        sessionEndTime: endTime !== null && dayjs(endTime).format("HH:mm:ss"),
      } as EditSessionBody;
      dispatch(editSessionApiCall(editSessionBody));
    } else {
      let addSessionBody = {
        healthcampInstanceId: instanceHealthcampId,
        displayName: instanceValue,
        sessionDate: sessionDate !== null && dayjs(sessionDate).format("YYYY-MM-DD"),
        sessionStartTime: startTime !== null && dayjs(startTime).format("HH:mm:ss"),
        sessionEndTime: endTime !== null && dayjs(endTime).format("HH:mm:ss"),
      } as AddSessionBody;
      dispatch(addSessionApiCall(addSessionBody));
    }
  };

  const changeState = () => {
    setShowHistory(true);
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {addResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                {getSessionListObject !== undefined ? "Edit" : "Add"} Healthcamp Session
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={instanceValue}
                label="Session Name"
                placeholder="Enter session name"
                id="sessionID"
                onChange={(event) => setInstanceValue(event.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={sessionDate}
                  label="Session Date"
                  onChange={(date) => handleDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <TimePicker
                    label="Session Start Time"
                    value={startTime}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <TimePicker
                    label="Session End Time"
                    value={endTime}
                    onChange={handleChangeEndTime}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              {getSessionListObject !== undefined ? (
                <Button
                  onClick={() => changeState()}
                  variant="outlined"
                  color="primary"
                  style={{ fontSize: "12px" }}
                >
                  View History
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={() => addSession()}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {showHistory ? <SessionHistoryDialog healthcampSessionId={healthcampSessionId} /> : null}
      </Dialog>
    </div>
  );
}
