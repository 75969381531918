import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Stack,
  Autocomplete,
  AlertColor,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminLoader from "../../AdminLoader";
import { WeekDay } from "../../../redux/Doctor/Schedule/WeekDayList/Model";
import { AddScheduleBody } from "../../../redux/Doctor/Schedule/AddSchedule/Model";
import {
  addSchedule,
  clearAddWorkScheduleAPIRes,
  updateAddScheduleAPIResMsg,
} from "../../../redux/Doctor/Schedule/AddSchedule/API";
import MWSnackbar from "../../../component/MWSnackbar";
import { GetDoctorWorkScheduleListBody } from "../../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/Model";
import { getDoctorWorkScheduleList } from "../../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/API";
type Props = {
  addScheduleDialog?: boolean | any;
  updateAddScheduleDialogUpdatedState: boolean | any;
};

export default function AddSchedule({
  addScheduleDialog,
  updateAddScheduleDialogUpdatedState,
}: Props) {
  const dispatch = useDispatch();
  const { id } = useParams() as {
    id: string;
  };
  const [addScheduleDialogVal, setAddScheduleDialogVal] = useState(false);
  useEffect(() => {
    setAddScheduleDialogVal(addScheduleDialog);
  }, [addScheduleDialog]);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(new Date());
  const handleEndDateChange = (endDate: Date | null) => {
    setSelectedEndDate(endDate);
  };

  const addWorkScheduleRes = useSelector((state: AppState) => state.addWorkSchedule);
  const [selectedDay, setSelectedDay] = useState<any | null>(null);
  const handleDayChange = (_e: any, dropdownDayName: any) => {
    setSelectedDay(dropdownDayName);
  };
  const [startTime, setStartTime] = React.useState<Dayjs | null | any>(dayjs().format());
  const [endTime, setEndTime] = React.useState<Dayjs | null | any>(dayjs().format());

  // popup close action...........
  const closeSchedule = () => {
    setAddScheduleDialogVal((addScheduleDialogVal) => !addScheduleDialogVal);
    updateAddScheduleDialogUpdatedState(false);
  };

  const weekDayListRes = useSelector((state: AppState) => state.weekdayList.getWeekDayListRes);
  const [weekDays, setWeekDays] = useState([] as WeekDay[]);
  useEffect(() => {
    weekDayListRes?.weekDays !== undefined
      ? weekDayListRes?.weekDays.length > 0
        ? setWeekDays(weekDayListRes?.weekDays)
        : setWeekDays([] as WeekDay[])
      : setWeekDays([] as WeekDay[]);
  }, [weekDayListRes]);

  // Add schedule submit Action.....
  const scheduleBody = {
    doctorId: id,
    weekDayId: String(selectedDay?.value),
    startTime: dayjs(startTime).format("HH:mm:ss"),
    endTime: dayjs(endTime).format("HH:mm:ss"),
    startDateOfValidity: dayjs(selectedDate).format("YYYY-MM-DD"),
    endDateOfValidity:
      selectedEndDate !== null ? dayjs(selectedEndDate).format("YYYY-MM-DD") : null,
  } as AddScheduleBody;

  const addDrSchedule = () => {
    dispatch(addSchedule(scheduleBody));
  };
  const addScheduleValRes = useSelector((state: AppState) => state.addWorkSchedule);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<AlertColor>("success");

  const [errMessage, setErrMessage] = useState("");
  const [errMessageType, setErrMessageType] = useState<AlertColor>("success");

  const getScheduleBody = {
    doctorId: id,
  } as GetDoctorWorkScheduleListBody;

  useEffect(() => {
    if (addScheduleValRes?.successMsg !== "") {
      setMessage(addScheduleValRes?.successMsg);
      setMessageType("success");
      closeSchedule();
      dispatch(getDoctorWorkScheduleList(getScheduleBody));
    } else if (addScheduleValRes?.errorMsg !== "") {
      setErrMessage(addScheduleValRes?.errorMsg);
      setErrMessageType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addScheduleValRes]);
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateAddScheduleAPIResMsg());
      dispatch(clearAddWorkScheduleAPIRes());
    }
  };
  const closeBanner = () => {
    dispatch(updateAddScheduleAPIResMsg());
    dispatch(clearAddWorkScheduleAPIRes());
  };

  return (
    <Box>
      <Dialog open={addScheduleDialogVal} onClose={closeSchedule} fullWidth>
        {addWorkScheduleRes?.loading ? <AdminLoader /> : null}
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">Add Work Schedule</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={closeSchedule}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{ pt: 2 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box>
                  <Typography style={{ marginTop: "-2px" }}>Schedule Day</Typography>
                  <Autocomplete
                    value={selectedDay}
                    id="templateId"
                    options={weekDays}
                    onChange={(event: any, newValue: any) => {
                      handleDayChange(event, newValue);
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="select Date" />}
                    size="small"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box>
                  <Typography>Start Time</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                      }}
                      ampmInClock={false}
                      ampm={false}
                      minutesStep={15}
                      renderInput={(params) => <TextField {...params} error={false} size="small" />}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box>
                  <Typography>End Time</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                      value={endTime}
                      onChange={(newValue) => {
                        setEndTime(newValue);
                      }}
                      minutesStep={15}
                      ampmInClock={false}
                      ampm={false}
                      renderInput={(params) => <TextField {...params} error={false} size="small" />}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography>Start date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={selectedDate}
                      onChange={(date) => handleDateChange(date)}
                      views={["year", "month", "day"]}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          inputProps={{ ...params.inputProps, readOnly: true }}
                          size="small"
                        />
                      )}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography>End date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={selectedEndDate}
                      onChange={(date) => handleEndDateChange(date)}
                      views={["year", "month", "day"]}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          inputProps={{ ...params.inputProps, readOnly: true }}
                          placeholder="Enter end date"
                          size="small"
                        />
                      )}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Stack>
          {errMessageType === "error" && errMessage !== "" ? (
            <Box my={1}>
              <Alert severity={errMessageType} onClose={() => closeBanner()}>
                {errMessage}
              </Alert>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => addDrSchedule()}
            variant="contained"
            disabled={addWorkScheduleRes?.loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <MWSnackbar msg={message} type={messageType} alertClose={checkAlertClose} />
    </Box>
  );
}
