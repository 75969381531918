import { GetHealthcampAdminListRes } from "./Model";
export const GET_HEALTHCAMP_ADMIN_LIST_LOADING = "GET_HEALTHCAMP_ADMIN_LIST_LOADING";
export const GET_HEALTHCAMP_ADMIN_LIST_SUCCESS = "GET_HEALTHCAMP_ADMIN_LIST_SUCCESS";
export const GET_HEALTHCAMP_ADMIN_LIST_FAIL = "GET_HEALTHCAMP_ADMIN_LIST_FAIL";
export const GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_MSG = "GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_MSG";
export const GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_RES = "GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_RES";

export interface GetHealthcampAdminListState {
  getHealthcampAdminList: GetHealthcampAdminListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthcampAdminListLoading {
  type: typeof GET_HEALTHCAMP_ADMIN_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthcampAdminListSuccess {
  type: typeof GET_HEALTHCAMP_ADMIN_LIST_SUCCESS;
  payload: GetHealthcampAdminListRes;
  successMsg: string;
}
export interface GetHealthcampAdminListFail {
  type: typeof GET_HEALTHCAMP_ADMIN_LIST_FAIL;
  payload: GetHealthcampAdminListRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthcampAdminListUpdateAPIMsg {
  type: typeof GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_MSG;
  payload: GetHealthcampAdminListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthcampAdminListUpdateAPIRes {
  type: typeof GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_RES;
  payload: GetHealthcampAdminListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthcampAdminListActionTypes =
  | GetHealthcampAdminListLoading
  | GetHealthcampAdminListSuccess
  | GetHealthcampAdminListFail
  | GetHealthcampAdminListUpdateAPIMsg
  | GetHealthcampAdminListUpdateAPIRes;
