import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { AddDoctorForCaseActionTypes } from "./ActionTypes";
import { AddDoctorForCaseBody, AddDoctorForCaseRes } from "./Model";
import {
  addDoctorForCaseAPIResClearAction,
  addDoctorForCaseErrorAction,
  addDoctorForCaseLoadingAction,
  addDoctorForCaseSuccessAction,
  addDoctorForCaseUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "../../../../component/Utility";

let apiRes = {} as AddDoctorForCaseRes;
export const addDoctorForCase = (payload: AddDoctorForCaseBody) => {
  return function (dispatch: Dispatch<AddDoctorForCaseActionTypes>) {
    dispatch(addDoctorForCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/add_doctorTo_patientCase`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addDoctorForCaseLoadingAction(false));
        dispatch(
          addDoctorForCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addDoctorForCaseLoadingAction(false));
        dispatch(
          addDoctorForCaseErrorAction(
            {} as AddDoctorForCaseRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddDoctorForCaseAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDoctorForCaseActionTypes>) {
    dispatch(addDoctorForCaseUpdateAPIMsgAction(apiRes as AddDoctorForCaseRes, "", "", 0));
  };
};

export const clearAddWorkDoctorForCaseAPIRes = () => {
  return function (dispatch: Dispatch<AddDoctorForCaseActionTypes>) {
    dispatch(addDoctorForCaseAPIResClearAction({} as AddDoctorForCaseRes, "", "", 0));
  };
};
