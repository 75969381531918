import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "../../../../component/MWExceptionList";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  AppointmentRequestList,
  GetAppointmentListBody,
} from "../../../../redux/Patient/GetAppointmentList/Model";
import { requestAppointmentDetails } from "../../../../redux/Patient/GetAppointmentList/API";
import dayjs from "dayjs";
import { ModifiedAppointmentList } from "./Model";

type Props = {
  caseId?: string | any;
};
export default function ExistingAppointmentList({ caseId }: Props) {
  const dispatch = useDispatch();
  const [isBookAppointmentResult, setIsBookAppointmentResult] = useState(
    [] as ModifiedAppointmentList[],
  );

  // RequestAppointment Api Value from store
  let requestAppointmentListDataFromAPI = useSelector(
    (state: AppState) =>
      state.getRequestAppointmentDetails?.getAppointmentListRes?.appointmentRequestList,
  );

  useEffect(() => {
    if (requestAppointmentListDataFromAPI !== undefined) {
      let appointmentListConfig = requestAppointmentListDataFromAPI.map(
        (item: AppointmentRequestList) => {
          return {
            id: item.id,
            description: item?.name !== undefined ? item.name : "",
            createdDate:
              item?.createDate !== undefined
                ? dayjs(item?.createDate).format("ddd, MM/DD/YYYY")
                : "",
            active: item?.isActive !== undefined ? (item.isActive ? "True" : "False") : "",
            modifiedDate:
              item?.modifiedDate !== undefined
                ? dayjs(item?.modifiedDate).format("ddd, MM/DD/YYYY")
                : "",
            appointmentStatus: item?.status !== undefined ? item.status : "",
          } as ModifiedAppointmentList;
        },
      );
      setIsBookAppointmentResult(appointmentListConfig);
    }
  }, [requestAppointmentListDataFromAPI]);
  const columns: GridColDef[] = [
    { field: "description", headerName: "Description", flex: 1 },
    { field: "createdDate", headerName: "Create Date", minWidth: 130 },
    { field: "active", headerName: "Active", minWidth: 3 },
    { field: "modifiedDate", headerName: "Modified Date", minWidth: 130 },
    { field: "appointmentStatus", headerName: "Status", minWidth: 50 },
  ];

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>Existing Appointment Details</Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() =>
                  dispatch(
                    requestAppointmentDetails({
                      caseId: caseId,
                    } as GetAppointmentListBody),
                  )
                }
                sx={{ textTransform: "none" }}
              >
                Reload Appointment List
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {isBookAppointmentResult && isBookAppointmentResult.length ? (
          <DataGrid
            rows={isBookAppointmentResult}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
}
