import { SimpleHistoryRes } from "./Model";

// Patient Demographics Loading State
export const DATA_POINT_HISTORY_SEARCH_LOADING = "PATIENT_SEARCH_LOADING";
export const DATA_POINT_HISTORY_SEARCH_SUCCESS = "PATIENT_SEARCH_SUCCESS";
export const DATA_POINT_HISTORY_SEARCH_FAIL = "PATIENT_SEARCH_FAIL";
export const DATA_POINT_HISTORY_SEARCH_DATA = "PATIENT_SEARCH_DATA";
export const PATIENT_SEARCH_UPDATE_API_MSG = "PATIENT_SEARCH_UPDATE_API_MSG";
export const PATIENT_SEARCH_UPDATE_API_RES = "PATIENT_SEARCH_UPDATE_API_RES";

export interface UpdatePatientDataPointState {
  updatePatientDataPointRes: SimpleHistoryRes;
  loading: boolean;
  message: string;
  status: number;
}
export interface UpdatePatientDataPointLoading {
  type: typeof DATA_POINT_HISTORY_SEARCH_LOADING;
  loading: boolean;
}
export interface UpdatePatientDataPointSuccess {
  type: typeof DATA_POINT_HISTORY_SEARCH_SUCCESS;
  payload: SimpleHistoryRes;
}
export interface UpdatePatientDataPointFail {
  type: typeof DATA_POINT_HISTORY_SEARCH_FAIL;
  payload: SimpleHistoryRes;
  message: string;
  status: number;
}
export interface UpdatePatientDataPointUpdateAPIMsg {
  type: typeof PATIENT_SEARCH_UPDATE_API_MSG;
  payload: SimpleHistoryRes;
  message: string;
  status: number;
}
export interface UpdatePatientDataPointUpdateAPIRes {
  type: typeof PATIENT_SEARCH_UPDATE_API_RES;
  payload: SimpleHistoryRes;
  message: string;
  status: number;
}
interface UpdatePatientDataPointAction {
  type: typeof DATA_POINT_HISTORY_SEARCH_DATA;
  payload: SimpleHistoryRes;
}

export type UpdatePatientDataPointActionTypes =
  | UpdatePatientDataPointAction
  | UpdatePatientDataPointLoading
  | UpdatePatientDataPointSuccess
  | UpdatePatientDataPointFail
  | UpdatePatientDataPointUpdateAPIMsg
  | UpdatePatientDataPointUpdateAPIRes;
