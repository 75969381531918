import {
  ADD_STAFF_OF_COMPETITION_DATA,
  ADD_STAFF_OF_COMPETITION_FAIL,
  ADD_STAFF_OF_COMPETITION_LOADING,
  ADD_STAFF_OF_COMPETITION_SUCCESS,
  ADD_STAFF_OF_COMPETITION_API_MSG,
  ADD_STAFF_OF_COMPETITION_API_RES,
  AddStaffOfCompetitionActionTypes,
} from "./ActionTypes";
import { PageCount, AddStaffOfCompetitionDetails } from "./Model";

export const addStaffCompetitionIdAction = (
  AddStaffOfCompetitionDetails: AddStaffOfCompetitionDetails,
  pageCount: PageCount,
): AddStaffOfCompetitionActionTypes => {
  return {
    type: ADD_STAFF_OF_COMPETITION_DATA,
    payload: AddStaffOfCompetitionDetails,
    pageCount: pageCount,
  };
};

export const addStaffOfCompetitionLoadingAction = (
  loading: boolean,
): AddStaffOfCompetitionActionTypes => {
  return {
    type: ADD_STAFF_OF_COMPETITION_LOADING,
    loading: loading,
  };
};

export const addStaffOfCompetitionSuccessAction = (
  addCompetitionStaffResponse: AddStaffOfCompetitionDetails,
  successMsg: string,
): AddStaffOfCompetitionActionTypes => {
  return {
    type: ADD_STAFF_OF_COMPETITION_SUCCESS,
    payload: addCompetitionStaffResponse,
    successMsg: successMsg,
  };
};

export const addStaffOfCompetitionErrorAction = (
  addCompetitionStaffResponse: AddStaffOfCompetitionDetails,
  errMsg: string,
  status: number,
): AddStaffOfCompetitionActionTypes => {
  return {
    type: ADD_STAFF_OF_COMPETITION_FAIL,
    payload: addCompetitionStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addStaffOfCompetitionAPIMsgAction = (
  addCompetitionStaffResponse: AddStaffOfCompetitionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfCompetitionActionTypes => {
  return {
    type: ADD_STAFF_OF_COMPETITION_API_MSG,
    payload: addCompetitionStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addStaffOfCompetitionAPIResClearAction = (
  addCompetitionStaffResponse: AddStaffOfCompetitionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfCompetitionActionTypes => {
  return {
    type: ADD_STAFF_OF_COMPETITION_API_RES,
    payload: addCompetitionStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
