import {
  EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL,
  EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING,
  EXECUTIVE_SIGHUP_REQUEST_LIST_SUCCESS,
  EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG,
  EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES,
  ExecutiveSignupRequestListActionTypes,
} from "./ActionTypes";
import { ExecutiveSignupRequestListDetails } from "./Model";

export const executiveSignupRequestListLoadingAction = (
  loading: boolean,
): ExecutiveSignupRequestListActionTypes => {
  return {
    type: EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING,
    loading: loading,
  };
};

export const executiveSignupRequestListSuccessAction = (
  executiveSignupRequestListResponse: ExecutiveSignupRequestListDetails,
  successMsg: string,
): ExecutiveSignupRequestListActionTypes => {
  return {
    type: EXECUTIVE_SIGHUP_REQUEST_LIST_SUCCESS,
    payload: executiveSignupRequestListResponse,
    successMsg: successMsg,
  };
};

export const executiveSignupRequestListErrorAction = (
  executiveSignupRequestListResponse: ExecutiveSignupRequestListDetails,
  errMsg: string,
  status: number,
): ExecutiveSignupRequestListActionTypes => {
  return {
    type: EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL,
    payload: executiveSignupRequestListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const executiveSignupRequestListAPIMsgAction = (
  executiveSignupRequestListResponse: ExecutiveSignupRequestListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): ExecutiveSignupRequestListActionTypes => {
  return {
    type: EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG,
    payload: executiveSignupRequestListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const executiveSignupRequestListAPIResClearAction = (
  executiveSignupRequestListResponse: ExecutiveSignupRequestListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): ExecutiveSignupRequestListActionTypes => {
  return {
    type: EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES,
    payload: executiveSignupRequestListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
