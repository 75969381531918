import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { PatientSearchByHealthCampIdActionTypes } from "./ActionTypes";
import { PatientSearchByHealthCampIdBody, PatientSearchByHealthCampIdDetails } from "./Model";
import {
  PatientSearchByHealthCampIdAPIResClearAction,
  PatientSearchByHealthCampIdErrorAction,
  PatientSearchByHealthCampIdLoadingAction,
  PatientSearchByHealthCampIdSuccessAction,
  PatientSearchByHealthCampIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as PatientSearchByHealthCampIdDetails;
export const patientSearchByHealthCampIdApi = (
  searchKey: PatientSearchByHealthCampIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<PatientSearchByHealthCampIdActionTypes>) {
    dispatch(PatientSearchByHealthCampIdLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/searchapp/api/searchPatientInHealthcamp/searchPatientListInHealthCamp?page=${Number(
            pageNo,
          )}&size=12`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(PatientSearchByHealthCampIdLoadingAction(false));
        dispatch(PatientSearchByHealthCampIdSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(PatientSearchByHealthCampIdLoadingAction(false));
        dispatch(
          PatientSearchByHealthCampIdErrorAction(
            {} as PatientSearchByHealthCampIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientSearchByHealthCampIdActionTypes>) {
    dispatch(
      PatientSearchByHealthCampIdAPIMsgAction(apiRes as PatientSearchByHealthCampIdDetails, "", 0),
    );
  };
};

export const clearAPIRes = () => {
  return function (dispatch: Dispatch<PatientSearchByHealthCampIdActionTypes>) {
    dispatch(
      PatientSearchByHealthCampIdAPIResClearAction({} as PatientSearchByHealthCampIdDetails, "", 0),
    );
  };
};
