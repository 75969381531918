import React, { useEffect } from "react";
import { Box, Chip, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { TabContext, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import DashboardTaskList from "./DashboardTaskList";
import { getTaskListValue } from "../../redux/effects/ApiCall";
import ModifiedPatientList from "./ModifiedPatientList";
import { getLastModifiedPatientListApi } from "../../redux/LastModifiedPatientList/API";
import RegistrationRequestList from "./RegistrationRequestList";
import VitalRequestList from "./VitalRequestList";
import { dashboardHealthCampRegistrationRequestList } from "redux/DashboardRegReqList/API";
import { GetDashboardHealthcampRequestListRes } from "redux/DashboardRegReqList/Model";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState("1");
  const [listCount, setListCount] = React.useState("0");
  const [lastModifiedPatientListCount, setLastModifiedPatientListCount] = React.useState("0");
  const [vitalRequestListCount, setVitalRequestListCount] = React.useState("0");
  const [regReqListListCount, setRegReqListListCount] = React.useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  const AllTaskList = useSelector((state: AppState) => state.taskListValue);
  const lastModifiedPatientListValue = useSelector(
    (state: AppState) => state.getLastModifiedPatientListValue,
  );
  const AdminDashboardHealthcampRegistrationReqList = useSelector(
    (state: AppState) => state.dashboardHealthCampRegistrationRequestListValue,
  );
  const vitalRequestListRes = useSelector((state: AppState) => state.vitalRequestList);

  useEffect(() => {
    dispatch(getTaskListValue());
    dispatch(getLastModifiedPatientListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function taskListApi() {
    dispatch(getTaskListValue());
  }

  function lastModifiedPatientListApi() {
    dispatch(getLastModifiedPatientListApi());
  }

  const reloadRegReqList = () => {
    dispatch(
      dashboardHealthCampRegistrationRequestList({} as GetDashboardHealthcampRequestListRes),
    );
  };
  const taskListValues = AllTaskList?.taskListValue;
  useEffect(() => {
    taskListValues !== undefined
      ? setListCount(String(AllTaskList?.taskListValue?.length))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllTaskList, selected]);

  useEffect(() => {
    lastModifiedPatientListValue?.getLastModifiedPatientListRes?.patientDataList?.length !==
    undefined
      ? setLastModifiedPatientListCount(
          String(
            lastModifiedPatientListValue?.getLastModifiedPatientListRes?.patientDataList?.length,
          ),
        )
      : setVitalRequestListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLastModifiedPatientListCount, selected]);

  useEffect(() => {
    AdminDashboardHealthcampRegistrationReqList?.getDashboardHealthcampRegistrationListResponse
      ?.healthcampRegistrationRequestList !== undefined
      ? setRegReqListListCount(
          String(
            AdminDashboardHealthcampRegistrationReqList
              ?.getDashboardHealthcampRegistrationListResponse?.healthcampRegistrationRequestList
              .length,
          ),
        )
      : setRegReqListListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminDashboardHealthcampRegistrationReqList, selected]);

  useEffect(() => {
    vitalRequestListRes?.vitalRequestListRes?.vitalsCollectionRequestList?.length !== undefined
      ? setVitalRequestListCount(
          String(vitalRequestListRes?.vitalRequestListRes?.vitalsCollectionRequestList?.length),
        )
      : setVitalRequestListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalRequestListRes, selected]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dashboardPage = (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
          <Tabs
            value={selected}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Admin Task List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={listCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    taskListApi();
                  }}
                />
              }
              iconPosition="end"
              value="1"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Last Modified Patient List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={lastModifiedPatientListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    lastModifiedPatientListApi();
                  }}
                />
              }
              iconPosition="end"
              value="2"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Registration Request List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={regReqListListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    reloadRegReqList();
                  }}
                />
              }
              iconPosition="end"
              value="3"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Vital Request List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={vitalRequestListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    lastModifiedPatientListApi();
                  }}
                />
              }
              iconPosition="end"
              value="4"
            />
          </Tabs>
        </Box>
        <Divider />
        {fullScreen ? (
          <Box pt={2}>
            {selected === "1" ? (
              <DashboardTaskList />
            ) : selected === "2" ? (
              <ModifiedPatientList />
            ) : selected === "3" ? (
              <RegistrationRequestList />
            ) : (
              <VitalRequestList />
            )}
          </Box>
        ) : (
          <TabContext value={String(selected)}>
            <TabPanel value="1">
              <DashboardTaskList />
            </TabPanel>
            <TabPanel value="2">
              <ModifiedPatientList />
            </TabPanel>
            <TabPanel value="3">
              <RegistrationRequestList />
            </TabPanel>
            <TabPanel value="4">
              <VitalRequestList />
            </TabPanel>
          </TabContext>
        )}
      </Grid>
    </Grid>
  );

  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <MWPageTitle title={"Dashboard"} />
        </Grid>
      </Grid>
      {dashboardPage}
    </PageLayout>
  );
}
