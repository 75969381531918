import Url from "../../ApiConfig";
import axios from "axios";
import { Dispatch } from "redux";
import {
  AddDrNotesTypes,
  AddNotesForDrActionTypes,
  ADD_DR_NOTES,
  ADD_DR_NOTE_FAIL,
  ADD_DR_NOTE_LOADING,
  ADD_DR_NOTE_SUCCESS,
  ADD_NOTES_FOR_DR_FAIL,
  ADD_NOTES_FOR_DR_LOADING,
  ADD_NOTES_FOR_DR_SUCCESS,
  ChangeDoctorNotesActionTypes,
  ChangeDrNoteStatusTypes,
  CHANGE_DR_NOTE_STATUS_FAIL,
  CHANGE_DR_NOTE_STATUS_LOADING,
  CHANGE_DR_NOTE_STATUS_SUCCESS,
  CHANGE_NOTES_FOR_DR_FAIL,
  CHANGE_NOTES_FOR_DR_LOADING,
  CHANGE_NOTES_FOR_DR_SUCCESS,
  CHANGE_NOTE_STATUS,
  DoctorNotesActionTypes,
  DRNOTE_LIST_FAIL,
  DRNOTE_LIST_LOADING,
  DRNOTE_LIST_SUCCESS,
  NotesForDoctorActionTypes,
  NOTE_LIST_FOR_DR_FAIL,
  NOTE_LIST_FOR_DR_LOADING,
  NOTE_LIST_FOR_DR_SUCCESS,
} from "./ActionTypes";
import {
  AddDoctorNote,
  AddNotesForDr,
  ChangeNotesForDr,
  DrNoteStatusChangeAction,
  getDrNoteAction,
  getNoteForDrAction,
} from "./Action";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

// Get Active / Inactive Dr. notes by patient
export const getDoctorNoteList = (getDrNoteListBody: object) => {
  return function (dispatch: Dispatch<DoctorNotesActionTypes>) {
    dispatch({
      type: DRNOTE_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/erpapp/api/adminNote/get_admin_notes_by_patient", getDrNoteListBody, {
        headers: headers,
      })
      .then((res) => {
        let resData = res.data.noteList;
        dispatch(getDrNoteAction(resData));
        dispatch({
          type: DRNOTE_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: DRNOTE_LIST_FAIL,
        });
      });
  };
};

export const addDrNotes = (state = {}) => {
  return function (dispatch: Dispatch<AddDrNotesTypes>) {
    dispatch({
      type: ADD_DR_NOTE_LOADING,
    });
    dispatch(AddDoctorNote(addDrNotes(ADD_DR_NOTES)));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/erpapp/api/adminNote/add_adminNote_for_patient", state, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          dispatch({
            type: ADD_DR_NOTE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((_error) => {
        dispatch({
          type: ADD_DR_NOTE_FAIL,
        });
      });
  };
};

export const changeDrNoteStatus = (noteId: any) => {
  return function (dispatch: Dispatch<ChangeDrNoteStatusTypes>) {
    dispatch({
      type: CHANGE_DR_NOTE_STATUS_LOADING,
    });
    dispatch(DrNoteStatusChangeAction(CHANGE_NOTE_STATUS));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/erpapp/api/adminNote/${Number(noteId)}/toggle_active`, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          //console.log("CCADDED==>", result);
          dispatch({
            type: CHANGE_DR_NOTE_STATUS_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((_error) => {
        dispatch({
          type: CHANGE_DR_NOTE_STATUS_FAIL,
        });
      });
  };
};

// Get Active / Inactive notes for Dr.
export const getDoctorNoteListForDoctor = (getDrNoteListBody: object) => {
  return function (dispatch: Dispatch<NotesForDoctorActionTypes>) {
    dispatch({
      type: NOTE_LIST_FOR_DR_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/erpapp/api/adminNote/get_admin_notes_by_doctor", getDrNoteListBody, {
        headers: headers,
      })
      .then((res) => {
        let noteList = res.data.noteList;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(getNoteForDrAction(noteList, message, status));
        dispatch({
          type: NOTE_LIST_FOR_DR_SUCCESS,
          noteList: noteList,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.message;
        dispatch({
          type: NOTE_LIST_FOR_DR_FAIL,
          message: message,
          status: status,
        });
      });
  };
};

export const addNotesByDr = (body: object) => {
  return function (dispatch: Dispatch<AddNotesForDrActionTypes>) {
    dispatch({
      type: ADD_NOTES_FOR_DR_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + "/erpapp/api/adminNote/add_adminNote_for_doctor", body, {
        headers: headers,
      })
      .then((result) => {
        let message = result.data.message;
        let status = result.data.status;
        dispatch(AddNotesForDr(message, status));
        if (result) {
          dispatch({
            type: ADD_NOTES_FOR_DR_SUCCESS,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.message;
        dispatch({
          type: ADD_NOTES_FOR_DR_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
//Change Dr note status
export const changeNotesForDoctorStatus = (noteId: any) => {
  return function (dispatch: Dispatch<ChangeDoctorNotesActionTypes>) {
    dispatch({
      type: CHANGE_NOTES_FOR_DR_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/erpapp/api/adminNote/${Number(noteId)}/toggle_active`, {
        headers: headers,
      })
      .then((result) => {
        let message = result.data.message;
        let status = result.data.status;
        dispatch(ChangeNotesForDr(message, status));
        dispatch({
          type: CHANGE_NOTES_FOR_DR_SUCCESS,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.message;
        dispatch({
          type: CHANGE_NOTES_FOR_DR_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
