import {
  PROVIDER_ORG_STAFF_LIST_FAIL,
  PROVIDER_ORG_STAFF_LIST_LOADING,
  PROVIDER_ORG_STAFF_LIST_SUCCESS,
  PROVIDER_ORG_STAFF_LIST_UPDATE_API_MSG,
  PROVIDER_ORG_STAFF_LIST_UPDATE_API_RES,
  GetProviderOrgStaffListActionTypes,
} from "./ActionTypes";
import { GetProviderOrgStaffListRes } from "./Model";

export const getProviderOrgStaffListLoadingAction = (
  loading: boolean,
): GetProviderOrgStaffListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_LIST_LOADING,
    loading: loading,
  };
};

export const getProviderOrgStaffListSuccessAction = (
  getProviderOrgStaffListresponse: GetProviderOrgStaffListRes,
  successMsg: string,
): GetProviderOrgStaffListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_LIST_SUCCESS,
    payload: getProviderOrgStaffListresponse,
    successMsg: successMsg,
  };
};

export const getProviderOrgStaffListErrorAction = (
  getProviderOrgStaffListresponse: GetProviderOrgStaffListRes,
  errMsg: string,
  status: number,
): GetProviderOrgStaffListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_LIST_FAIL,
    payload: getProviderOrgStaffListresponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getProviderOrgStaffListUpdateAPIMsgAction = (
  getProviderOrgStaffListresponse: GetProviderOrgStaffListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetProviderOrgStaffListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_LIST_UPDATE_API_MSG,
    payload: getProviderOrgStaffListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getProviderOrgStaffListAPIResClearAction = (
  getProviderOrgStaffListresponse: GetProviderOrgStaffListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetProviderOrgStaffListActionTypes => {
  return {
    type: PROVIDER_ORG_STAFF_LIST_UPDATE_API_RES,
    payload: getProviderOrgStaffListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
