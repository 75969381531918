import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { EditDataCurationProblemActionTypes } from "./ActionTypes";
import { EditDataCurationProblemBody, EditDataCurationProblemDetails } from "./Model";
import {
  editDataCurationProblemAPIResClearAction,
  editDataCurationProblemErrorAction,
  editDataCurationProblemLoadingAction,
  editDataCurationProblemSuccessAction,
  editDataCurationProblemAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as EditDataCurationProblemDetails;
export const editDataCurationProblemApiCall = (payload: EditDataCurationProblemBody) => {
  return function (dispatch: Dispatch<EditDataCurationProblemActionTypes>) {
    dispatch(editDataCurationProblemLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/curatorapp/api/dataCurationProblem/edit_data_Curation_Problem`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editDataCurationProblemLoadingAction(false));
        dispatch(
          editDataCurationProblemSuccessAction(
            res.data !== undefined ? res.data : ({} as EditDataCurationProblemDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(editDataCurationProblemLoadingAction(false));
        dispatch(
          editDataCurationProblemErrorAction(
            {} as EditDataCurationProblemDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateEditDataCurationProblemAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDataCurationProblemActionTypes>) {
    dispatch(editDataCurationProblemAPIMsgAction(apiRes as EditDataCurationProblemDetails, "", "", 0));
  };
};

export const clearEditDataCurationProblemAPIRes = () => {
  return function (dispatch: Dispatch<EditDataCurationProblemActionTypes>) {
    dispatch(editDataCurationProblemAPIResClearAction({} as EditDataCurationProblemDetails, "", "", 0));
  };
};
