import {
  EXECUTIVE_LIST_API_MSG,
  EXECUTIVE_LIST_CLEAR_API_RES,
  EXECUTIVE_LIST_FAIL,
  EXECUTIVE_LIST_LOADING,
  EXECUTIVE_LIST_SUCCESS,
  ExecutiveListDataState,
  ExecutiveListActionTypes,
} from "./ActionTypes";
import { ExecutiveListResponse } from "./Model";

const initialStateGetPosts: ExecutiveListDataState = {
  loading: false,
  executiveRes: {} as ExecutiveListResponse,
  message: "",
  status: 0,
};

export const executiveDropDownListReducer = (
  state = initialStateGetPosts,
  action: ExecutiveListActionTypes,
): ExecutiveListDataState => {
  switch (action.type) {
    case EXECUTIVE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EXECUTIVE_LIST_SUCCESS:
      return {
        ...state,
        executiveRes: action.payload,
      };
    case EXECUTIVE_LIST_FAIL:
      return {
        ...state,
        executiveRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case EXECUTIVE_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        executiveRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case EXECUTIVE_LIST_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        executiveRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
