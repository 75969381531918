import {
  EDIT_INSTANCE_FAIL,
  EDIT_INSTANCE_LOADING,
  EDIT_INSTANCE_SUCCESS,
  EDIT_INSTANCE_API_MSG,
  EDIT_INSTANCE_API_RES,
  EditInstanceActionTypes,
} from "./ActionTypes";
import { EditInstanceDetails } from "./Model";

export const editInstanceLoadingAction = (
  loading: boolean,
): EditInstanceActionTypes => {
  return {
    type: EDIT_INSTANCE_LOADING,
    loading: loading,
  };
};

export const editInstanceSuccessAction = (
  editInstanceResponse: EditInstanceDetails,
  successMsg: string,
): EditInstanceActionTypes => {
  return {
    type: EDIT_INSTANCE_SUCCESS,
    payload: editInstanceResponse,
    successMsg: successMsg,
  };
};

export const editInstanceErrorAction = (
  editInstanceResponse: EditInstanceDetails,
  errMsg: string,
  status: number,
): EditInstanceActionTypes => {
  return {
    type: EDIT_INSTANCE_FAIL,
    payload: editInstanceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editInstanceAPIMsgAction = (
  editInstanceResponse: EditInstanceDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): EditInstanceActionTypes => {
  return {
    type: EDIT_INSTANCE_API_MSG,
    payload: editInstanceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editInstanceAPIResClearAction = (
  editInstanceResponse: EditInstanceDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): EditInstanceActionTypes => {
  return {
    type: EDIT_INSTANCE_API_RES,
    payload: editInstanceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
