import {
  ImportDataFromRecentCallActionTypes,
  IMPORT_DATA_FROM_RECENT_CALL_FAIL,
  IMPORT_DATA_FROM_RECENT_CALL_LOADING,
  IMPORT_DATA_FROM_RECENT_CALL_SUCCESS,
  ImportDataFromRecentCallState,
  IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG,
  IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES,
} from "./ActionTypes";
import { ImportDataFromRecentCallRes } from "./Model";

const initialStateGetPosts: ImportDataFromRecentCallState = {
  loading: false,
  importDataFromRecentCallRes: {} as ImportDataFromRecentCallRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const importDataFromRecentCallReducer = (
  state = initialStateGetPosts,
  action: ImportDataFromRecentCallActionTypes,
): ImportDataFromRecentCallState => {
  switch (action.type) {
    case IMPORT_DATA_FROM_RECENT_CALL_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case IMPORT_DATA_FROM_RECENT_CALL_SUCCESS:
      return {
        ...state,
        importDataFromRecentCallRes: action.payload,
        successMsg: action.successMsg,
      };
    case IMPORT_DATA_FROM_RECENT_CALL_FAIL:
      return {
        ...state,
        importDataFromRecentCallRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG:
      return {
        ...state,
        importDataFromRecentCallRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES:
      return {
        ...state,
        importDataFromRecentCallRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
