import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import { DialogTitle, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import { getJudgingDetailsList } from "redux/JudgingDetailsList/API";
import { GetJudgingDetailsListBody } from "redux/JudgingDetailsList/Model";
import { PreviewMarksDetailsBody } from "redux/PreviewCutoffMarks/Model";
import { clearPreviewCutoffMarksAPIRes, previewCutoffMarksApi } from "redux/PreviewCutoffMarks/API";
import PreviewMarksList from "../PreviewMarksList";

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  previewCutoffMarksDialogEnable?: boolean | any;
  previewCutoffMarksDialogUpdateState?: boolean | any;
  competitionid?: string | any;
  secondRoundValue?: any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

export default function PreviewCutoffMarksDialog({
  previewCutoffMarksDialogEnable,
  previewCutoffMarksDialogUpdateState,
  competitionid,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    previewCutoffMarksDialogUpdateState(false);
    dispatch(clearPreviewCutoffMarksAPIRes());
  };

  const [rankNumber, setRankNumber] = useState("");

  // call Store
  const addSecondRoundResValues = useSelector(
    (state: AppState) => state.addSecondRoundDetailsValue,
  );

  const previewvalues = useSelector((state: AppState) => state.previewCutoffMarksValue);

  useEffect(() => {
    if (addSecondRoundResValues?.addSecondRoundRes?.message !== undefined) {
      setDialogState(false);
      previewCutoffMarksDialogUpdateState(false);
      const patientListBody = {
        competitionId: competitionid,
        patientObservationKey: "photograding",
      } as GetJudgingDetailsListBody;
      dispatch(getJudgingDetailsList(patientListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSecondRoundResValues]);

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const rankErrordiv = phoneNumberError ? (
    <Typography variant="body1">{phoneNumberError}</Typography>
  ) : (
    ""
  );

  const addSecondRoundBody = {
    cutOff: rankNumber,
    competitionId: competitionid,
  } as PreviewMarksDetailsBody;

  // Add patient eam API
  const addSecondRound = () => {
    if (rankNumber === "") {
      setPhoneNumberError("Please enter cutoff marks");
    } else {
      dispatch(previewCutoffMarksApi(addSecondRoundBody));
    }
  };

  React.useEffect(() => {
    setDialogState(previewCutoffMarksDialogEnable);
  }, [previewCutoffMarksDialogEnable]);

  return (
    <div>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h5">Preview Cutoff Marks</Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              pb={2}
            >
              <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                <TextField
                  label="Mark"
                  value={rankNumber}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      dispatch(previewCutoffMarksApi(addSecondRoundBody));
                      ev.preventDefault();
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setRankNumber(event.target.value);
                  }}
                  placeholder="Please enter mark"
                  id="rnakID"
                  fullWidth
                  size="small"
                />
                <Typography mt={1}>{rankErrordiv}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                <Button
                  autoFocus
                  disabled={
                    previewvalues?.previewCutoffMarksRes?.patientDetailsList !== undefined
                      ? true
                      : false
                  }
                  onClick={addSecondRound}
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none" }}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <PreviewMarksList rankNumber={rankNumber} />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
