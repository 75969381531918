import {
  GetIdentifyActionTypes,
  GetPatientDemographicsState,
  GET_IDENTIFY_SUCCESS,
  GET_IDENTIFY_LOADING,
  GET_IDENTIFY_FAIL,
} from "./ActionTypes";
import { IdentifyResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  getIdentifyDetails: {} as IdentifyResponse,
};
export const getIdentifyReducer = (
  state = initialStateGetPosts,
  action: GetIdentifyActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case GET_IDENTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        getIdentifyDetails: action.payload,
      };
    case GET_IDENTIFY_LOADING:
      return {
        ...state,
        loading: true,
        getIdentifyDetails: {} as IdentifyResponse,
      };
    case GET_IDENTIFY_FAIL:
      return {
        ...state,
        getIdentifyDetails: {} as IdentifyResponse,
        loading: false,
      };
    default:
      return state;
  }
};
