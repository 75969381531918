import { PageCount, AddStaffOfCompetitionDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_STAFF_OF_COMPETITION_LOADING = "ADD_STAFF_OF_COMPETITION_LOADING";
export const ADD_STAFF_OF_COMPETITION_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_STAFF_OF_COMPETITION_FAIL = "ADD_STAFF_OF_COMPETITION_FAIL";
export const ADD_STAFF_OF_COMPETITION_DATA = "ADD_STAFF_OF_COMPETITION_DATA";
export const ADD_STAFF_OF_COMPETITION_API_MSG = "ADD_STAFF_OF_COMPETITION_API_MSG";
export const ADD_STAFF_OF_COMPETITION_API_RES = "ADD_STAFF_OF_COMPETITION_API_RES";

export interface AddStaffOfCompetitionState {
  AddStaffOfCompetitionRes: AddStaffOfCompetitionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfCompetitionLoading {
  type: typeof ADD_STAFF_OF_COMPETITION_LOADING;
  loading: boolean;
}
export interface AddStaffOfCompetitionSuccess {
  type: typeof ADD_STAFF_OF_COMPETITION_SUCCESS;
  payload: AddStaffOfCompetitionDetails;
  successMsg: string;
}
export interface AddStaffOfCompetitionFail {
  type: typeof ADD_STAFF_OF_COMPETITION_FAIL;
  payload: AddStaffOfCompetitionDetails;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfCompetitionAPIMsg {
  type: typeof ADD_STAFF_OF_COMPETITION_API_MSG;
  payload: AddStaffOfCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfCompetitionAPIRes {
  type: typeof ADD_STAFF_OF_COMPETITION_API_RES;
  payload: AddStaffOfCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddStaffOfCompetitionDataAction {
  type: typeof ADD_STAFF_OF_COMPETITION_DATA;
  payload: AddStaffOfCompetitionDetails;
  pageCount: PageCount;
}

export type AddStaffOfCompetitionActionTypes =
  | AddStaffOfCompetitionDataAction
  | AddStaffOfCompetitionLoading
  | AddStaffOfCompetitionSuccess
  | AddStaffOfCompetitionFail
  | AddStaffOfCompetitionAPIMsg
  | AddStaffOfCompetitionAPIRes;
