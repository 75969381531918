import { GetPatientDataListRes } from "./Model";
export const GET_PATIENT_DATA_LIST_LOADING = "GET_PATIENT_DATA_LIST_LOADING";
export const GET_PATIENT_DATA_LIST_SUCCESS = "GET_PATIENT_DATA_LIST_SUCCESS";
export const GET_PATIENT_DATA_LIST_FAIL = "GET_PATIENT_DATA_LIST_FAIL";
export const GET_PATIENT_DATA_LIST_UPDATE_API_MSG = "GET_PATIENT_DATA_LIST_UPDATE_API_MSG";
export const GET_PATIENT_DATA_LIST_UPDATE_API_RES = "GET_PATIENT_DATA_LIST_UPDATE_API_RES";

export interface GetPatientDataListState {
  getPatientDataListRes: GetPatientDataListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDataListLoading {
  type: typeof GET_PATIENT_DATA_LIST_LOADING;
  loading: boolean;
}
export interface GetPatientDataListSuccess {
  type: typeof GET_PATIENT_DATA_LIST_SUCCESS;
  payload: GetPatientDataListRes;
  successMsg: string;
}
export interface GetPatientDataListFail {
  type: typeof GET_PATIENT_DATA_LIST_FAIL;
  payload: GetPatientDataListRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientDataListUpdateAPIMsg {
  type: typeof GET_PATIENT_DATA_LIST_UPDATE_API_MSG;
  payload: GetPatientDataListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDataListUpdateAPIRes {
  type: typeof GET_PATIENT_DATA_LIST_UPDATE_API_RES;
  payload: GetPatientDataListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientDataListActionTypes =
  | GetPatientDataListLoading
  | GetPatientDataListSuccess
  | GetPatientDataListFail
  | GetPatientDataListUpdateAPIMsg
  | GetPatientDataListUpdateAPIRes;
