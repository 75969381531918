import {
  GET_SESSION_PATIENT_DETAILS_LIST_FAIL,
  GET_SESSION_PATIENT_DETAILS_LIST_LOADING,
  GET_SESSION_PATIENT_DETAILS_LIST_SUCCESS,
  GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_MSG,
  GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_RES,
  GetSessionPatientDetailsListActionTypes,
} from "./ActionTypes";
import { GetSessionPatientDetailsListRes } from "./Model";

export const getSessionPatientDetailsListLoadingAction = (
  loading: boolean,
): GetSessionPatientDetailsListActionTypes => {
  return {
    type: GET_SESSION_PATIENT_DETAILS_LIST_LOADING,
    loading: loading,
  };
};

export const getSessionPatientDetailsListSuccessAction = (
  getSessionPatientDetailsListResponse: GetSessionPatientDetailsListRes,
  successMsg: string,
): GetSessionPatientDetailsListActionTypes => {
  return {
    type: GET_SESSION_PATIENT_DETAILS_LIST_SUCCESS,
    payload: getSessionPatientDetailsListResponse,
    successMsg: successMsg,
  };
};

export const getSessionPatientDetailsListErrorAction = (
  getSessionPatientDetailsListResponse: GetSessionPatientDetailsListRes,
  errMsg: string,
  status: number,
): GetSessionPatientDetailsListActionTypes => {
  return {
    type: GET_SESSION_PATIENT_DETAILS_LIST_FAIL,
    payload: getSessionPatientDetailsListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getSessionPatientDetailsListUpdateAPIMsgAction = (
  getSessionPatientDetailsListResponse: GetSessionPatientDetailsListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetSessionPatientDetailsListActionTypes => {
  return {
    type: GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_MSG,
    payload: getSessionPatientDetailsListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getSessionPatientDetailsListAPIResClearAction = (
  getSessionPatientDetailsListResponse: GetSessionPatientDetailsListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetSessionPatientDetailsListActionTypes => {
  return {
    type: GET_SESSION_PATIENT_DETAILS_LIST_UPDATE_API_RES,
    payload: getSessionPatientDetailsListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
