import {
  GetProviderOrgStaffAdminListActionTypes,
  PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL,
  PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING,
  PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS,
  GetProviderOrgStaffAdminListState,
  PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG,
  PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { ProviderOrgStaffAdminRes } from "./Model";

const initialStateGetPosts: GetProviderOrgStaffAdminListState = {
  loading: false,
  getProviderOrgStaffAdminList: {} as ProviderOrgStaffAdminRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getStaffAdminListByProviderOrgIdReducer = (
  state = initialStateGetPosts,
  action: GetProviderOrgStaffAdminListActionTypes,
): GetProviderOrgStaffAdminListState => {
  switch (action.type) {
    case PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        getProviderOrgStaffAdminList: action.payload,
        successMsg: action.successMsg,
      };
    case PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL:
      return {
        ...state,
        getProviderOrgStaffAdminList: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getProviderOrgStaffAdminList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES:
      return {
        ...state,
        getProviderOrgStaffAdminList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
