import {
    GET_SUBSCRIPTIONLIST_BY_ID_FAIL,
    GET_SUBSCRIPTIONLIST_BY_ID_LOADING,
    GET_SUBSCRIPTIONLIST_BY_ID_SUCCESS,
    GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_MSG,
    GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_RES,
    GetSubscriptionListByIdActionTypes,
  } from "./ActionTypes";
  import { GetSubscriptionResponse } from "./Model";
  
  export const getSubscriptionLoadingAction = (loading: boolean): GetSubscriptionListByIdActionTypes => {
    return {
      type: GET_SUBSCRIPTIONLIST_BY_ID_LOADING,
      loading: loading,
    };
  };
  
  export const getSubscriptionSuccessAction = (
    GetSubscriptionListByIdResponse: GetSubscriptionResponse,
    successMsg: string,
  ): GetSubscriptionListByIdActionTypes => {
    return {
      type: GET_SUBSCRIPTIONLIST_BY_ID_SUCCESS,
      payload: GetSubscriptionListByIdResponse,
      successMsg: successMsg,
    };
  };
  
  export const getSubscriptionErrorAction = (
    GetSubscriptionListByIdResponse: GetSubscriptionResponse,
    errMsg: string,
    status: number,
  ): GetSubscriptionListByIdActionTypes => {
    return {
      type: GET_SUBSCRIPTIONLIST_BY_ID_FAIL,
      payload: GetSubscriptionListByIdResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getSubscriptionUpdateAPIMsgAction = (
    GetSubscriptionListByIdResponse: GetSubscriptionResponse,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetSubscriptionListByIdActionTypes => {
    return {
      type: GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_MSG,
      payload: GetSubscriptionListByIdResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getSubscriptionAPIResClearAction = (
    GetSubscriptionListByIdResponse: GetSubscriptionResponse,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetSubscriptionListByIdActionTypes => {
    return {
      type: GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_RES,
      payload: GetSubscriptionListByIdResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  