import { GET_LIST } from "../../types/ActionTypes";
import { SymptomAddResponse } from "./Model";

export const SYMPTOM_ADD_LOADING = "SYMPTOM_ADD_LOADING";
export const SYMPTOM_ADD_SUCCESS = "SYMPTOM_ADD_SUCCESS";
export const SYMPTOM_ADD_FAIL = "SYMPTOM_ADD_FAIL";

export interface GetPatientDemographicsState {
  templateList: SymptomAddResponse;
  loading: boolean;
}
export interface ExecutiveListLoading {
  type: typeof SYMPTOM_ADD_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof SYMPTOM_ADD_SUCCESS;
  payload: SymptomAddResponse;
}
export interface ExecutiveListFail {
  type: typeof SYMPTOM_ADD_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: SymptomAddResponse;
}

export type SymptomAddActionTypes =
  | GetDoctorAction
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail;
