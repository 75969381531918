import { HealthcampPatientList } from "./Model";
export const HCPATIENT_LIST_LOADING = "HCPATIENT_LIST_LOADING";
export const HCPATIENT_LIST_SUCCESS = "HCPATIENT_LIST_SUCCESS";
export const HCPATIENT_LIST_FAIL = "HCPATIENT_LIST_FAIL";
export const HCPATIENT_LIST_DATA = "HCPATIENT_LIST_DATA";
export const HCPATIENT_LIST_UPDATE_API_MSG = "HCPATIENT_LIST_UPDATE_API_MSG";
export const HCPATIENT_LIST_UPDATE_API_RES = "HCPATIENT_LIST_UPDATE_API_RES";

export interface GetPatientListByHealthcampListState {
  getPatientListByHealthcampListRes: HealthcampPatientList;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientListByHealthcampListLoading {
  type: typeof HCPATIENT_LIST_LOADING;
  loading: boolean;
}
export interface GetPatientListByHealthcampListSuccess {
  type: typeof HCPATIENT_LIST_SUCCESS;
  payload: HealthcampPatientList;
  successMsg: string;
}
export interface GetPatientListByHealthcampListFail {
  type: typeof HCPATIENT_LIST_FAIL;
  payload: HealthcampPatientList;
  errorMsg: string;
  status: number;
}
export interface GetPatientListByHealthcampListUpdateAPIMsg {
  type: typeof HCPATIENT_LIST_UPDATE_API_MSG;
  payload: HealthcampPatientList;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientListByHealthcampListUpdateAPIRes {
  type: typeof HCPATIENT_LIST_UPDATE_API_RES;
  payload: HealthcampPatientList;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type HealthcampPatientGetActionTypes =
  | GetPatientListByHealthcampListLoading
  | GetPatientListByHealthcampListSuccess
  | GetPatientListByHealthcampListFail
  | GetPatientListByHealthcampListUpdateAPIMsg
  | GetPatientListByHealthcampListUpdateAPIRes;
