import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppState } from "../../../../redux/store/Store";
import { ActiveCorporatePatientList } from "../../../../redux/interfaces/Model";
import * as _ from "lodash";
import { getActiveCorporatePatinetList } from "../../../../redux/effects/ApiCall";
import { CorporatePackageList } from "../../../../redux/CorporatePackage/Package/Model";
import { getCorporatePackageList } from "../../../../redux/CorporatePackage/Package/ApiCall";
import { getPatientList } from "../../../../redux/CorporatePackage/PatientTeam/PatientList/ApiCall";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "../../../../component/MWExceptionList";

export default function ActivePatientListUnderPatientTeam(props: any) {
  const { activePatientListUnderPatientTeamLoader } = props;
  const { packageId } = useParams() as {
    packageId: string;
  };
  const { profileId } = useParams() as {
    profileId: string;
  };

  const dispatch = useDispatch();

  const history = useHistory();

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  const truncate = (str: String) => {
    return str.length > 20 ? str.substring(0, 25) + "..." : str;
  };

  // call Active Corporate Patient List Store
  const activeCorporatePatientList = useSelector(
    (state: AppState) => state.activeCorporatePatientValue,
  );
  const activeCorporatePatientListValues: ActiveCorporatePatientList = _.filter(
    activeCorporatePatientList.activeCorporatePatientListValue,
    ["profileId", Number(profileId)],
  )[0];

  // call Active Package List Store

  const activeCorporatePackageList = useSelector((state: AppState) => state.corporatePackageValue);
  const activeCorporateListDetails: CorporatePackageList = _.filter(
    activeCorporatePackageList.corporatePackageListValue,
    ["id", Number(packageId)],
  )[0];

  // call Patient list Store
  const patientListLoadingValue = useSelector(
    (state: AppState) => state.patientTeamPatientListvalue.loading,
  );
  const patientList = useSelector(
    (state: AppState) => state.patientTeamPatientListvalue.patientListValue,
  );
  //const patientListStatus = useSelector((state: AppState) => state.patientTeamPatientListvalue.status);

  // reload patient list API
  const getPatientListByPatientTeam = () => {
    dispatch(getPatientList(profileId));
  };
  const corporatePackageBody = {
    active: Number(1),
  };
  useEffect(() => {
    dispatch(getPatientList(profileId));
    if (
      activeCorporateListDetails === undefined &&
      activeCorporatePatientListValues === undefined
    ) {
      dispatch(getCorporatePackageList(corporatePackageBody));
      dispatch(getActiveCorporatePatinetList(packageId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeCorporateListDetails, activeCorporatePatientListValues]);

  let patientListConfig = [];
  for (var i = 0; i < patientList.length; i++) {
    // let taskSeqNo: number = i;
    let patientId: number = patientList[i].id;
    let firstname =
      patientList[i].firstname === "" || patientList[i].firstname === null
        ? " "
        : patientList[i].firstname;
    let lastname =
      patientList[i].lastname === "" || patientList[i].lastname === null
        ? " "
        : patientList[i].lastname;
    let dob =
      patientList[i].dob === "" || patientList[i].dob === null
        ? " "
        : new Date(patientList[i].dob).toLocaleDateString("en-US", DATE_OPTIONS);
    let patientRelationshipWithOwner = patientList[i].patientRelationshipWithOwner?.displayValue;
    let gender = patientList[i].gender?.label;
    let bloodGroup = patientList[i].bloodGroup?.label;
    let description =
      patientList[i].description === "" || patientList[i].description === null
        ? " "
        : truncate(patientList[i].description);
    patientListConfig.push([
      <Button
        variant="outlined"
        onClick={() => gotoDetails(patientId)}
        startIcon={<VisibilityIcon />}
        sx={{ textTransform: "none" }}
      >
        View
      </Button>,
      patientId,
      firstname,
      lastname,
      dob,
      patientRelationshipWithOwner,
      gender,
      bloodGroup,
      description,
    ]);
  }
  const patientListTableValue: any[] = patientListConfig;

  const actualPageMarkup = (
    <Card>
      <CardHeader title="Patient Details">
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={getPatientListByPatientTeam}
              sx={{ textTransform: "none" }}
            >
              Reload
            </Button>
          </Grid>
        </Grid>
      </CardHeader>
      <CardContent>
        {patientListTableValue && patientListTableValue.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>View</TableCell>
                  <TableCell>Patient Id</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>DOB</TableCell>
                  <TableCell>Relationship</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>BloodGroup</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientListTableValue.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row[0]}</TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">{row[4]}</TableCell>
                    <TableCell align="left">{row[5]}</TableCell>
                    <TableCell align="left">{row[6]}</TableCell>
                    <TableCell align="left">{row[7]}</TableCell>
                    <TableCell align="left">{row[8]}</TableCell>
                    <TableCell align="left">{row[9]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {activeCorporatePatientListValues === undefined ||
      activeCorporateListDetails === undefined ||
      patientListLoadingValue === true
        ? activePatientListUnderPatientTeamLoader(true)
        : activePatientListUnderPatientTeamLoader(false)}
      <Container maxWidth="xl">{actualPageMarkup}</Container>
    </Box>
  );
}
