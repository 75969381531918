import { ObservationListByInstanceIdRes } from "./Model";
export const OBSERVATION_LIST_BY_INSTANCE_ID_LOADING = "OBSERVATION_LIST_BY_INSTANCE_ID_LOADING";
export const OBSERVATION_LIST_BY_INSTANCE_ID_SUCCESS = "OBSERVATION_LIST_BY_INSTANCE_ID_SUCCESS";
export const OBSERVATION_LIST_BY_INSTANCE_ID_FAIL = "OBSERVATION_LIST_BY_INSTANCE_ID_FAIL";
export const OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_MSG = "OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_MSG";
export const OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_RES = "OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_RES";

export interface ObservationListByInstanceIdState {
  observationListByInstanceIdRes: ObservationListByInstanceIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ObservationListByInstanceIdLoading {
  type: typeof OBSERVATION_LIST_BY_INSTANCE_ID_LOADING;
  loading: boolean;
}
export interface ObservationListByInstanceIdSuccess {
  type: typeof OBSERVATION_LIST_BY_INSTANCE_ID_SUCCESS;
  payload: ObservationListByInstanceIdRes;
  successMsg: string;
}
export interface ObservationListByInstanceIdFail {
  type: typeof OBSERVATION_LIST_BY_INSTANCE_ID_FAIL;
  payload: ObservationListByInstanceIdRes;
  errorMsg: string;
  status: number;
}
export interface ObservationListByInstanceIdUpdateAPIMsg {
  type: typeof OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_MSG;
  payload: ObservationListByInstanceIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ObservationListByInstanceIdUpdateAPIRes {
  type: typeof OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_RES;
  payload: ObservationListByInstanceIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ObservationListByInstanceIdActionTypes =
  | ObservationListByInstanceIdLoading
  | ObservationListByInstanceIdSuccess
  | ObservationListByInstanceIdFail
  | ObservationListByInstanceIdUpdateAPIMsg
  | ObservationListByInstanceIdUpdateAPIRes;
