import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { HealthcampPatientGetActionTypes } from "./ActionTypes";
import { GetPatientListByHealthCampMainBody, HealthcampPatientList } from "./Model";
import {
  getPatientListByHealthcampAPIResClearAction,
  getPatientListByHealthcampListErrorAction,
  getPatientListByHealthcampLoadingAction,
  getPatientListByHealthcampListSuccessAction,
  getPatientListByHealthcampListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as HealthcampPatientList;
export const getPatientListByHealthcamp = (payload: GetPatientListByHealthCampMainBody) => {
  return function (dispatch: Dispatch<HealthcampPatientGetActionTypes>) {
    dispatch(getPatientListByHealthcampLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/caregiverapp/api/healthcamppatient/patient_list_of_healthcamp?page=${Number(
            payload.pageNo,
          )}&size=50`,
        payload.patientList,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        //  let pageCount = res.data.numberOfPages;
        dispatch(getPatientListByHealthcampLoadingAction(false));
        dispatch(
          getPatientListByHealthcampListSuccessAction(
            res.data,
            " Patient list by Health camp details has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientListByHealthcampLoadingAction(false));
        dispatch(
          getPatientListByHealthcampListErrorAction(
            {} as HealthcampPatientList,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -3,
          ),
        );
      });
  };
};

export const updateGetHealthCampByOrganizationListAPIResMsg = () => {
  return function (dispatch: Dispatch<HealthcampPatientGetActionTypes>) {
    dispatch(
      getPatientListByHealthcampListUpdateAPIMsgAction(apiRes as HealthcampPatientList, "", "", 0),
    );
  };
};

export const clearHealthCampByOrganizationListAPIRes = () => {
  return function (dispatch: Dispatch<HealthcampPatientGetActionTypes>) {
    dispatch(getPatientListByHealthcampAPIResClearAction({} as HealthcampPatientList, "", "", 0));
  };
};
