import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { InstanceListByDuplicatePatientIdActionTypes } from "./ActionTypes";
import { InstanceListByDuplicatePatientIdBody, InstanceListByDuplicatePatientIdDetails } from "./Model";
import {
  InstanceListByDuplicatePatientIdAPIResClearAction,
  InstanceListByDuplicatePatientIdErrorAction,
  InstanceListByDuplicatePatientIdLoadingAction,
  InstanceListByDuplicatePatientIdSuccessAction,
  InstanceListByDuplicatePatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as InstanceListByDuplicatePatientIdDetails;
export const instanceListByDuplicatePatientIdApi = (
  patientid: InstanceListByDuplicatePatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<InstanceListByDuplicatePatientIdActionTypes>) {
    dispatch(InstanceListByDuplicatePatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampinstance/instance_list_by_patient?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(InstanceListByDuplicatePatientIdLoadingAction(false));
        dispatch(
          InstanceListByDuplicatePatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as InstanceListByDuplicatePatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient instance list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(InstanceListByDuplicatePatientIdLoadingAction(false));
        dispatch(
          InstanceListByDuplicatePatientIdErrorAction(
            {} as InstanceListByDuplicatePatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateInstanceListByDuplicatePatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<InstanceListByDuplicatePatientIdActionTypes>) {
    dispatch(
      InstanceListByDuplicatePatientIdAPIMsgAction(
        apiRes as InstanceListByDuplicatePatientIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearInstanceListByDuplicatePatientIdAPIRes = () => {
  return function (dispatch: Dispatch<InstanceListByDuplicatePatientIdActionTypes>) {
    dispatch(
      InstanceListByDuplicatePatientIdAPIResClearAction(
        {} as InstanceListByDuplicatePatientIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};
