import {
  AddInsuranceActionTypes,
  GetPatientDemographicsState,
  ADD_INSURANCE_SUCCESS,
  ADD_INSURANCE_LOADING,
  ADD_INSURANCE_FAIL,
} from "./ActionTypes";
import { AddInsuranceResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  addInsuranceDetails: {} as AddInsuranceResponse,
};
export const addInsuranceReducer = (
  state = initialStateGetPosts,
  action: AddInsuranceActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case ADD_INSURANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        addInsuranceDetails: action.payload,
      };
    case ADD_INSURANCE_LOADING:
      return {
        ...state,
        loading: true,
        addInsuranceDetails: {} as AddInsuranceResponse,
      };
    case ADD_INSURANCE_FAIL:
      return {
        ...state,
        addInsuranceDetails: {} as AddInsuranceResponse,
        loading: false,
      };
    default:
      return state;
  }
};
