import {
  AddRegistrationRequestActionTypes,
  ADD_REGISTRATION_REQUEST_FAIL,
  ADD_REGISTRATION_REQUEST_LOADING,
  ADD_REGISTRATION_REQUEST_SUCCESS,
  AddRegistrationRequestState,
  ADD_REGISTRATION_REQUEST_API_MSG,
  ADD_REGISTRATION_REQUEST_API_RES,
} from "./ActionTypes";
import { AddRegistrationRequestDetails } from "./Model";

const initialStateGetPosts: AddRegistrationRequestState = {
  loading: false,
  addRegistrationRequestRes: {} as AddRegistrationRequestDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addRegistrationRequestReducer = (
  state = initialStateGetPosts,
  action: AddRegistrationRequestActionTypes,
): AddRegistrationRequestState => {
  switch (action.type) {
    case ADD_REGISTRATION_REQUEST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_REGISTRATION_REQUEST_SUCCESS:
      return {
        ...state,
        addRegistrationRequestRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_REGISTRATION_REQUEST_FAIL:
      return {
        ...state,
        addRegistrationRequestRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_REGISTRATION_REQUEST_API_MSG:
      return {
        ...state,
        addRegistrationRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_REGISTRATION_REQUEST_API_RES:
      return {
        ...state,
        addRegistrationRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
