import { GET_LIST } from "../types/ActionTypes";
import { ExecutiveListResponse } from "./Model";

// Patient Demographics Loading State
export const GET_EXECUTIVE_LOADING = "GET_EXECUTIVE_LOADING";
export const GET_EXECUTIVE_SUCCESS = "GET_EXECUTIVE_SUCCESS";
export const GET_EXECUTIVE_FAIL = "GET_EXECUTIVE_FAIL";

export interface GetPatientDemographicsState {
  executiveListDropDownRes: ExecutiveListResponse;
  loading: boolean;
}
export interface ExecutiveListLoading {
  type: typeof GET_EXECUTIVE_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof GET_EXECUTIVE_SUCCESS;
  payload: ExecutiveListResponse;
}
export interface ExecutiveListFail {
  type: typeof GET_EXECUTIVE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: ExecutiveListResponse;
}

export type ExecutiveListActionTypes =
  | GetDoctorAction
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail;
