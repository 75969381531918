import { Card, Grid, Typography, Avatar, CardHeader, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { AgeRange, NameGenerate } from "../../../../../../component/Utility";
import { AppState } from "redux/store/Store";
import { useEffect } from "react";
import { getDuplicateDemographicsDetailsByPatientId } from "redux/PatientDuplicateDemographics/API";
import { DuplicatePatientDemographicsDetailsBody } from "redux/PatientDuplicateDemographics/Model";

type Props = {
  patientduplicateid?: any;
};

function DuplicateDemographicsDetails({ patientduplicateid }: Props) {
  const dispatch = useDispatch();
  const patientDemographicsDetails = useSelector(
    (state: AppState) => state.duplicatePatientDemographicsDetailsValue,
  );

  useEffect(() => {
    dispatch(
      getDuplicateDemographicsDetailsByPatientId({
        healthRecordId: patientduplicateid,
      } as DuplicatePatientDemographicsDetailsBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Card style={{ borderRadius: 20 }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Demographics
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>Patient Id : {patientduplicateid}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes?.personalDetails
              ?.profilePicture !== null ? (
              <img
                src={
                  patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.profilePicture?.document
                }
                srcSet={
                  patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.profilePicture?.document
                }
                alt=""
                loading="lazy"
                style={{
                  maxWidth: "180px",
                  maxHeight: "180px",
                  flex: 1,
                }}
              />
            ) : (
              <Avatar sx={{ width: 120, height: 120 }} />
            )}
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Image Id:
              {patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes?.personalDetails
                ?.profilePicture !== null
                ? patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.profilePicture?.id
                : " Not Available"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {NameGenerate.changeName(
                    patientduplicateid,
                    patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                      ?.personalDetails?.firstname !== undefined &&
                      patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                        ?.personalDetails?.lastname !== undefined
                      ? patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.firstname! +
                          " " +
                          patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                            ?.personalDetails?.lastname!
                      : "",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Gender:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.gender?.label !== undefined
                    ? " " +
                      patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                        ?.personalDetails?.gender?.label +
                      " "
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Age:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.dob !== null ||
                  patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes?.personalDetails
                    ?.dob !== ""
                    ? localStorage.getItem("togglename") === "true"
                      ? AgeRange.ageRangeCheck(
                          dayjs(new Date()).diff(
                            patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                              ?.personalDetails?.dob,
                            "year",
                          ),
                        )
                      : dayjs(new Date()).diff(
                          patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                            ?.personalDetails?.dob,
                          "year",
                        ) + " years old "
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Phone:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.phone !== ""
                    ? patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                        ?.personalDetails?.phone
                    : "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>City:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.city !== ""
                    ? patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                        ?.personalDetails?.city
                    : "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Pincode:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.pincode !== null
                    ? patientDemographicsDetails.getDuplicateDemographicsByPatientIdRes
                        ?.personalDetails?.pincode
                    : "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Identity Card:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.idProof !== null ? (
                    <img
                      src={
                        patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.supportingDocuments?.document
                      }
                      srcSet={
                        patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.supportingDocuments?.document
                      }
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />
                  ) : (
                    "Not Available"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Identity Type:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.idProof !== null ? (
                    <img
                      src={
                        patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idType?.label
                      }
                      srcSet={
                        patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idType?.label
                      }
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />
                  ) : (
                    "Not Available"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Typography>Identity Number:</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={8} lg={8} xl={8}>
                <Typography>
                  {patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                    ?.personalDetails?.idProof !== null ? (
                    <img
                      src={
                        patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idNumber
                      }
                      srcSet={
                        patientDemographicsDetails?.getDuplicateDemographicsByPatientIdRes
                          ?.personalDetails?.idProof?.idNumber
                      }
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />
                  ) : (
                    "Not Available"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DuplicateDemographicsDetails;
