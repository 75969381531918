import {
  GetMandatoryPendingDetailsListBySessionIdActionTypes,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS,
  GetMandatoryPendingDetailsListBySessionIdState,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { GetMandatoryPendingDetailsListBySessionIdRes } from "./Model";

const initialStateGetPosts: GetMandatoryPendingDetailsListBySessionIdState = {
  loading: false,
  getMandatoryPendingDetailsListBySessionIdRes: {} as GetMandatoryPendingDetailsListBySessionIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getMandatoryPendingDetailsListBySessionIdReducer = (
  state = initialStateGetPosts,
  action: GetMandatoryPendingDetailsListBySessionIdActionTypes,
): GetMandatoryPendingDetailsListBySessionIdState => {
  switch (action.type) {
    case GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        getMandatoryPendingDetailsListBySessionIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL:
      return {
        ...state,
        getMandatoryPendingDetailsListBySessionIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG:
      return {
        ...state,
        getMandatoryPendingDetailsListBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES:
      return {
        ...state,
        getMandatoryPendingDetailsListBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
