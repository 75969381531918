import { SecondOpinionResponse } from "./Model";
export const SECOND_OPINION_TYPE_LOADING =
  "SECOND_OPINION_TYPE_LOADING";
export const SECOND_OPINION_TYPE_SUCCESS =
  "SECOND_OPINION_TYPE_SUCCESS";
export const SECOND_OPINION_TYPE_FAIL =
  "SECOND_OPINION_TYPE_FAIL";
export const SECOND_OPINION_TYPE_DATA =
  "SECOND_OPINION_TYPE_DATA";
export const SECOND_OPINION_TYPE_API_MSG =
  "SECOND_OPINION_TYPE_API_MSG";
export const SECOND_OPINION_TYPE_API_RES =
  "SECOND_OPINION_TYPE_API_RES";

export interface SecondOpinionTypeState {
  secondOpinionTypeRes: SecondOpinionResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SecondOpinionTypeLoading {
  type: typeof SECOND_OPINION_TYPE_LOADING;
  loading: boolean;
}
export interface SecondOpinionTypeSuccess {
  type: typeof SECOND_OPINION_TYPE_SUCCESS;
  payload: SecondOpinionResponse;
  successMsg: string;
  status: number;
}
export interface SecondOpinionTypeFail {
  type: typeof SECOND_OPINION_TYPE_FAIL;
  payload: SecondOpinionResponse;
  errorMsg: string;
  status: number;
}
export interface SecondOpinionTypeUpdateAPIMsg {
  type: typeof SECOND_OPINION_TYPE_API_MSG;
  payload: SecondOpinionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SecondOpinionTypeUpdateAPIRes {
  type: typeof SECOND_OPINION_TYPE_API_RES;
  payload: SecondOpinionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface SecondOpinionTypeAction {
  type: typeof SECOND_OPINION_TYPE_DATA;
  payload: SecondOpinionResponse;
}

export type SecondOpinionTypeActionTypes =
  | SecondOpinionTypeAction
  | SecondOpinionTypeLoading
  | SecondOpinionTypeSuccess
  | SecondOpinionTypeFail
  | SecondOpinionTypeUpdateAPIMsg
  | SecondOpinionTypeUpdateAPIRes;
