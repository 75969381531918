import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardContent, CardHeader, Grid, Pagination, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import MWExceptionList from "../../component/MWExceptionList";
import { vitalRequestList } from "redux/VitalRequestList/API";
import { VitalRequestListBody } from "redux/VitalRequestList/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function VitalRequestList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const gotoTaskDetails = (rowIndex: any) => {
    history.push(
      `/healthcampsession/${rowIndex.healthcampId}/${rowIndex.healthcampInstanceId}/${rowIndex.sessionId}/${rowIndex.patientId}-1`,
    );
  };

  // call Store
  const vitalRequestListRes = useSelector((state: AppState) => state.vitalRequestList);
  console.log("vitalRequestList", vitalRequestListRes);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(vitalRequestList({ statusType: "1" } as VitalRequestListBody));
  }, [dispatch]);

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(vitalRequestList({ statusType: "1" } as VitalRequestListBody));
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = useState("");

  useEffect(() => {
    dispatch(toggleAppBarLoading(vitalRequestListRes?.loading));
    vitalRequestListRes?.vitalRequestListRes?.numberOfPages !== undefined
      ? setPageListCount(String(vitalRequestListRes?.vitalRequestListRes?.numberOfPages))
      : setPageListCount("0");
    if (
      vitalRequestListRes?.vitalRequestListRes !== undefined &&
      vitalRequestListRes?.vitalRequestListRes?.vitalsCollectionRequestList?.length > 0
    ) {
      let dataPointArr = vitalRequestListRes?.vitalRequestListRes?.vitalsCollectionRequestList?.map(
        (element: any) => ({
          id: element.id,
          displayName: element.displayName,
          encounterId: element.encounterId,
          encounter: element.encounter,
          patientId: element.patientId,
          patientName: element.patientName,
          sessionId: element.sessionId,
          sessionDisplayName: element.sessionName,
          healthcampId: element.healthcampId,
          healthcampDisplayName: element.healthcampDisplayName,
          healthcampInstanceId: element.healthcampId,
          healthcampInstanceName: element.healthcampInstanceName,
          status: element.status,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalRequestListRes]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoTaskDetails(params.row);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "displayName", headerName: "Display Name", minWidth: 250, flex: 1 },
    { field: "encounterId", headerName: "Encounter Id", minWidth: 100, align: "center" },
    {
      field: "encounter",
      headerName: "Encounter",
      minWidth: 250,
    },
    { field: "patientId", headerName: "Patient Id", minWidth: 80, align: "center" },
    { field: "patientName", headerName: "Patient Name", minWidth: 250, flex: 1 },
    { field: "sessionId", headerName: "Session Id", minWidth: 80, align: "center" },
    { field: "sessionDisplayName", headerName: "Session Name", minWidth: 120, flex: 1 },
    { field: "healthcampId", headerName: "HealthCamp Id", minWidth: 120, align: "center" },
    { field: "healthcampDisplayName", headerName: "HealthCamp Name", minWidth: 250 },
    { field: "status", headerName: "Status", minWidth: 80, align: "center" },
  ];

  return (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {vitalRequestListRes?.vitalRequestListRes?.vitalsCollectionRequestList !== undefined ? (
          vitalRequestListRes?.vitalRequestListRes?.vitalsCollectionRequestList.length > 0 ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
}
