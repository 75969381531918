import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddStaffOfProviderOrgActionTypes } from "./ActionTypes";
import { AddStaffOfProviderOrgBody, AddStaffOfProviderOrgDetails } from "./Model";
import {
  addStaffOfProviderOrgAPIResClearAction,
  addStaffOfProviderOrgErrorAction,
  addStaffOfProviderOrgLoadingAction,
  addStaffOfProviderOrgSuccessAction,
  addStaffOfProviderOrgAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddStaffOfProviderOrgDetails;
export const addStaffOfProviderOrgApiCall = (payload: AddStaffOfProviderOrgBody) => {
  return function (dispatch: Dispatch<AddStaffOfProviderOrgActionTypes>) {
    dispatch(addStaffOfProviderOrgLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/providerorganizationapp/api/providerOrganizationStaffAdmin/add_staff_providerOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addStaffOfProviderOrgLoadingAction(false));
        dispatch(
          addStaffOfProviderOrgSuccessAction(
            res.data !== undefined ? res.data : ({} as AddStaffOfProviderOrgDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addStaffOfProviderOrgLoadingAction(false));
        dispatch(
          addStaffOfProviderOrgErrorAction(
            {} as AddStaffOfProviderOrgDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const addStaffOfProviderOrgAPIResMsg = () => {
  return function (dispatch: Dispatch<AddStaffOfProviderOrgActionTypes>) {
    dispatch(addStaffOfProviderOrgAPIMsgAction(apiRes as AddStaffOfProviderOrgDetails, "", "", 0));
  };
};

export const clearAddStaffOfProviderOrgAPIRes = () => {
  return function (dispatch: Dispatch<AddStaffOfProviderOrgActionTypes>) {
    dispatch(addStaffOfProviderOrgAPIResClearAction({} as AddStaffOfProviderOrgDetails, "", "", 0));
  };
};
