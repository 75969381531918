import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatientCaseList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import { Pagination, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PatientCaseList } from "../../redux/interfaces/Model";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../component/MWExceptionList";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function ActiveAllCaseList() {
  const dispatch = useDispatch();
  const history = useHistory();

  function gotoDetails(patientId: number, caseId: number) {
    history.push(`/casedetails/${patientId}/${caseId}`);
  }

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  // encounter list api call ...
  useEffect(() => {
    dispatch(getAllPatientCaseList(page, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const AllCaseListValue = useSelector((state: AppState) => state.allcaseValue);
  const allcaseValues = AllCaseListValue.allcaseValue.patientCaseList;

  useEffect(() => {
    dispatch(toggleAppBarLoading(AllCaseListValue?.loading));
    AllCaseListValue?.pageCount !== undefined
      ? setPageListCount(String(AllCaseListValue?.pageCount))
      : setPageListCount("0");
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientCaseList) => ({
        id: element.caseId,
        caseId: element.caseId,
        patientId: element.patientId,
        patientName: element.patientName,
        WhyWant2ndOpinion: element.whyWant2ndOpinion,
        reasons: element.secondOpinionReasons,
        consultType: element.consultationType,
        docTeamType: element.doctorTeamType,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(AllCaseListValue?.pageCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllCaseListValue]);

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllPatientCaseList(value, 1));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.row.patientId, params.row.caseId);
          }}
          variant="outlined"
        >
          View
        </Button>
      ),
    },
    {
      field: "caseId",
      headerName: "Case Id",
      minWidth: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "patientName",
      headerName: "Patient name",
      editable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "WhyWant2ndOpinion",
      headerName: "Why Want 2nd Opinion",
      editable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "reasons",
      headerName: "Second Opinion Reasons",
      type: "string",
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "consultType",
      headerName: "Consultation Type",
      sortable: false,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "docTeamType",
      headerName: "DoctorTeamType",
      minWidth: 150,
      align: "left",
      flex: 1,
    },
  ];

  const activeCaseListPage = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {allcaseValues && allcaseValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return <Box>{activeCaseListPage}</Box>;
}
