import { GetPendingPatientDetailsTypeListBySessionIdRes } from "./Model";
export const GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING = "GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING";
export const GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS = "GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS";
export const GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL = "GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL";
export const GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG = "GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG";
export const GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES = "GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES";

export interface GetPendingPatientDetailsTypeListBySessionIdState {
  getPendingPatientDetailsTypeListBySessionIdRes: GetPendingPatientDetailsTypeListBySessionIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPendingPatientDetailsTypeListBySessionIdLoading {
  type: typeof GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface GetPendingPatientDetailsTypeListBySessionIdSuccess {
  type: typeof GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS;
  payload: GetPendingPatientDetailsTypeListBySessionIdRes;
  successMsg: string;
}
export interface GetPendingPatientDetailsTypeListBySessionIdFail {
  type: typeof GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL;
  payload: GetPendingPatientDetailsTypeListBySessionIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPendingPatientDetailsTypeListBySessionIdUpdateAPIMsg {
  type: typeof GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG;
  payload: GetPendingPatientDetailsTypeListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPendingPatientDetailsTypeListBySessionIdUpdateAPIRes {
  type: typeof GET_PENDING_PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES;
  payload: GetPendingPatientDetailsTypeListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPendingPatientDetailsTypeListBySessionIdActionTypes =
  | GetPendingPatientDetailsTypeListBySessionIdLoading
  | GetPendingPatientDetailsTypeListBySessionIdSuccess
  | GetPendingPatientDetailsTypeListBySessionIdFail
  | GetPendingPatientDetailsTypeListBySessionIdUpdateAPIMsg
  | GetPendingPatientDetailsTypeListBySessionIdUpdateAPIRes;
