import { GET_LIST } from "../types/ActionTypes";
import { StepsAndSectionResponse } from "./Model";

// Patient Demographics Loading State
export const STEP_AND_SECTION_LOADING = "STEP_AND_SECTION_LOADING";
export const STEP_AND_SECTION_SUCCESS = "STEP_AND_SECTION_SUCCESS";
export const STEP_AND_SECTION_FAIL = "STEP_AND_SECTION_FAIL";

export interface GetStepAndSectionState {
  stepandsectionRes: StepsAndSectionResponse;
  loading: boolean;
}
export interface StepAndSectionListLoading {
  type: typeof STEP_AND_SECTION_LOADING;
}
export interface StepAndSectionListSuccess {
  type: typeof STEP_AND_SECTION_SUCCESS;
  payload: StepsAndSectionResponse;
}
export interface StepAndSectionListFail {
  type: typeof STEP_AND_SECTION_FAIL;
}
interface GetStepAndSectionAction {
  type: typeof GET_LIST;
  payload: StepsAndSectionResponse;
}

export type StepAndSectionListActionTypes =
  | GetStepAndSectionAction
  | StepAndSectionListLoading
  | StepAndSectionListSuccess
  | StepAndSectionListFail;
