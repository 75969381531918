import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface Props {
  label: string;
  dateState?: any;
  disabledValue: boolean;
  selectedDateValue?: Date | null;
  disableFuture?: boolean | any;
  defaultDateForObsType?: boolean | any;
}

const MWDatePicker: React.FC<Props> = ({
  label,
  dateState,
  disabledValue,
  selectedDateValue,
  disableFuture,
  defaultDateForObsType,
}) => {
  const [labelValue, setLabel] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null | string>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setLabel(label);
    setDisabled(disabledValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, disabledValue]);

  useEffect(() => {
    if (selectedDateValue !== undefined) {
      if (selectedDateValue !== null && defaultDateForObsType) {
        setSelectedDate(dayjs(new Date().toString()).subtract(1, "year").format("YYYY-MM-DD")!);
      } else {
        setSelectedDate(new Date());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDateForObsType]);

  useEffect(() => {
    dateState(dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:Z"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
          views={["year", "month", "day"]}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{ ...params.inputProps }}
              size="small"
              variant="outlined"
              label={labelValue}
            />
          )}
          InputAdornmentProps={{ position: "start" }}
          disabled={disabled}
          maxDate={
            disableFuture !== undefined ? (disableFuture ? new Date() : undefined) : undefined
          }
        />
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default MWDatePicker;
