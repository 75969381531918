import {
    ORGANIZATION_REGISTRATION_LIST_FAIL,
    ORGANIZATION_REGISTRATION_LIST_LOADING,
    ORGANIZATION_REGISTRATION_LIST_SUCCESS,
    ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG,
    ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES,
    GetOrganizationRegistrationListActionTypes,
  } from "./ActionTypes";
  import { OrganizationRegistrationRequestListRes } from "./Model";
  
  export const getOrganizationRegistrationRequestListLoadingAction = (
    loading: boolean,
  ): GetOrganizationRegistrationListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getOrganizationRegistrationRequestListSuccessAction = (
    getOrganizationRegistrationListresponse: OrganizationRegistrationRequestListRes,
    successMsg: string,
  ): GetOrganizationRegistrationListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_LIST_SUCCESS,
      payload: getOrganizationRegistrationListresponse,
      successMsg: successMsg,
    };
  };
  
  export const getOrganizationRegistrationRequestListErrorAction = (
    getOrganizationRegistrationListresponse: OrganizationRegistrationRequestListRes,
    errMsg: string,
    status: number,
  ): GetOrganizationRegistrationListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_LIST_FAIL,
      payload: getOrganizationRegistrationListresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getOrganizationRegistrationRequestListUpdateAPIMsgAction = (
    getOrganizationRegistrationListresponse: OrganizationRegistrationRequestListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetOrganizationRegistrationListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_LIST_UPDATE_API_MSG,
      payload: getOrganizationRegistrationListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getOrganizationRegistrationRequestListAPIResClearAction = (
    getOrganizationRegistrationListresponse: OrganizationRegistrationRequestListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetOrganizationRegistrationListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_LIST_UPDATE_API_RES,
      payload: getOrganizationRegistrationListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  