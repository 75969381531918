import {
  GetHealthCampSessionReportActionTypes,
  HEALTH_CAMP_SESSION_REPORT_LIST_FAIL,
  HEALTH_CAMP_SESSION_REPORT_LIST_LOADING,
  HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS,
  GetHealthCampSessionReportState,
  HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG,
  HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetHealthCampSessionReportRes } from "./Model";

const initialStateGetPosts: GetHealthCampSessionReportState = {
  loading: false,
  getHealthCampSessionReport: {} as GetHealthCampSessionReportRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getHealthCampSessionReportReducer = (
  state = initialStateGetPosts,
  action: GetHealthCampSessionReportActionTypes,
): GetHealthCampSessionReportState => {
  switch (action.type) {
    case HEALTH_CAMP_SESSION_REPORT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS:
      return {
        ...state,
        getHealthCampSessionReport: action.payload,
        successMsg: action.successMsg,
      };
    case HEALTH_CAMP_SESSION_REPORT_LIST_FAIL:
      return {
        ...state,
        getHealthCampSessionReport: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getHealthCampSessionReport: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES:
      return {
        ...state,
        getHealthCampSessionReport: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
