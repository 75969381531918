import { PageCount, AddDuplicatePatientDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_DUPLICATE_PATIENT_LOADING = "ADD_DUPLICATE_PATIENT_LOADING";
export const ADD_DUPLICATE_PATIENT_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_DUPLICATE_PATIENT_FAIL = "ADD_DUPLICATE_PATIENT_FAIL";
export const ADD_DUPLICATE_PATIENT_DATA = "ADD_DUPLICATE_PATIENT_DATA";
export const ADD_DUPLICATE_PATIENT_API_MSG = "ADD_DUPLICATE_PATIENT_API_MSG";
export const ADD_DUPLICATE_PATIENT_API_RES = "ADD_DUPLICATE_PATIENT_API_RES";

export interface AddDuplicatePatientState {
  addDuplicatePatientRes: AddDuplicatePatientDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDuplicatePatientLoading {
  type: typeof ADD_DUPLICATE_PATIENT_LOADING;
  loading: boolean;
}
export interface AddDuplicatePatientSuccess {
  type: typeof ADD_DUPLICATE_PATIENT_SUCCESS;
  payload: AddDuplicatePatientDetails;
  successMsg: string;
}
export interface AddDuplicatePatientFail {
  type: typeof ADD_DUPLICATE_PATIENT_FAIL;
  payload: AddDuplicatePatientDetails;
  errorMsg: string;
  status: number;
}
export interface AddDuplicatePatientAPIMsg {
  type: typeof ADD_DUPLICATE_PATIENT_API_MSG;
  payload: AddDuplicatePatientDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDuplicatePatientAPIRes {
  type: typeof ADD_DUPLICATE_PATIENT_API_RES;
  payload: AddDuplicatePatientDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddDuplicatePatientDataAction {
  type: typeof ADD_DUPLICATE_PATIENT_DATA;
  payload: AddDuplicatePatientDetails;
  pageCount: PageCount;
}

export type AddDuplicatePatientActionTypes =
  | AddDuplicatePatientDataAction
  | AddDuplicatePatientLoading
  | AddDuplicatePatientSuccess
  | AddDuplicatePatientFail
  | AddDuplicatePatientAPIMsg
  | AddDuplicatePatientAPIRes;
