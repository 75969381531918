import {
  DeleteAccountRequestActionTypes,
  DELETE_ACCOUNT_REQUEST_FAIL,
  DELETE_ACCOUNT_REQUEST_LOADING,
  DELETE_ACCOUNT_REQUEST_SUCCESS,
  DeleteAccountRequestState,
  DELETE_ACCOUNT_REQUEST_API_MSG,
  DELETE_ACCOUNT_REQUEST_API_RES,
} from "./ActionTypes";
import { DeleteAccountRequestDetails } from "./Model";

const initialStateGetPosts: DeleteAccountRequestState = {
  loading: false,
  deleteAccountRequestRes: {} as DeleteAccountRequestDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const deleteAccountRequestReducer = (
  state = initialStateGetPosts,
  action: DeleteAccountRequestActionTypes,
): DeleteAccountRequestState => {
  switch (action.type) {
    case DELETE_ACCOUNT_REQUEST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DELETE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteAccountRequestRes: action.payload,
        successMsg: action.successMsg,
      };
    case DELETE_ACCOUNT_REQUEST_FAIL:
      return {
        ...state,
        deleteAccountRequestRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_ACCOUNT_REQUEST_API_MSG:
      return {
        ...state,
        deleteAccountRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_ACCOUNT_REQUEST_API_RES:
      return {
        ...state,
        deleteAccountRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
