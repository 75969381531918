import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddSessionActionTypes } from "./ActionTypes";
import { AddSessionBody, AddSessionDetails } from "./Model";
import {
  addSessionAPIResClearAction,
  addSessionErrorAction,
  addSessionLoadingAction,
  addSessionSuccessAction,
  addSessionAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddSessionDetails;
export const addSessionApiCall = (payload: AddSessionBody) => {
  return function (dispatch: Dispatch<AddSessionActionTypes>) {
    dispatch(addSessionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampsession/add_session_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addSessionLoadingAction(false));
        dispatch(
          addSessionSuccessAction(
            res.data !== undefined ? res.data : ({} as AddSessionDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addSessionLoadingAction(false));
        dispatch(
          addSessionErrorAction(
            {} as AddSessionDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddSessionAPIResMsg = () => {
  return function (dispatch: Dispatch<AddSessionActionTypes>) {
    dispatch(addSessionAPIMsgAction(apiRes as AddSessionDetails, "", "", 0));
  };
};

export const clearAddSessionAPIRes = () => {
  return function (dispatch: Dispatch<AddSessionActionTypes>) {
    dispatch(addSessionAPIResClearAction({} as AddSessionDetails, "", "", 0));
  };
};
