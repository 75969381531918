import {
  OrganizationAddRegistrationRequestActionTypes,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS,
  OrganizationAddRegistrationRequestState,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES,
} from "./ActionTypes";
import { OrganizationAddRegistrationRequestDetails } from "./Model";

const initialStateGetPosts: OrganizationAddRegistrationRequestState = {
  loading: false,
  OrganizationAddRegistrationRequestRes: {} as OrganizationAddRegistrationRequestDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const OrganizationAddRegistrationRequestReducer = (
  state = initialStateGetPosts,
  action: OrganizationAddRegistrationRequestActionTypes,
): OrganizationAddRegistrationRequestState => {
  switch (action.type) {
    case ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS:
      return {
        ...state,
        OrganizationAddRegistrationRequestRes: action.payload,
        successMsg: action.successMsg,
      };
    case ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL:
      return {
        ...state,
        OrganizationAddRegistrationRequestRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG:
      return {
        ...state,
        OrganizationAddRegistrationRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES:
      return {
        ...state,
        OrganizationAddRegistrationRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
