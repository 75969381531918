import { GetObservationListBySessionIdRes } from "./Model";
export const GET_OBSERVATION_LIST_BY_SESSION_ID_LOADING = "GET_OBSERVATION_LIST_BY_SESSION_ID_LOADING";
export const GET_OBSERVATION_LIST_BY_SESSION_ID_SUCCESS = "GET_OBSERVATION_LIST_BY_SESSION_ID_SUCCESS";
export const GET_OBSERVATION_LIST_BY_SESSION_ID_FAIL = "GET_OBSERVATION_LIST_BY_SESSION_ID_FAIL";
export const GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_MSG = "GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_MSG";
export const GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_RES = "GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_RES";

export interface GetObservationListBySessionIdState {
  getObservationListBySessionIdRes: GetObservationListBySessionIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetObservationListBySessionIdLoading {
  type: typeof GET_OBSERVATION_LIST_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface GetObservationListBySessionIdSuccess {
  type: typeof GET_OBSERVATION_LIST_BY_SESSION_ID_SUCCESS;
  payload: GetObservationListBySessionIdRes;
  successMsg: string;
}
export interface GetObservationListBySessionIdFail {
  type: typeof GET_OBSERVATION_LIST_BY_SESSION_ID_FAIL;
  payload: GetObservationListBySessionIdRes;
  errorMsg: string;
  status: number;
}
export interface GetObservationListBySessionIdUpdateAPIMsg {
  type: typeof GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_MSG;
  payload: GetObservationListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetObservationListBySessionIdUpdateAPIRes {
  type: typeof GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_RES;
  payload: GetObservationListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetObservationListBySessionIdActionTypes =
  | GetObservationListBySessionIdLoading
  | GetObservationListBySessionIdSuccess
  | GetObservationListBySessionIdFail
  | GetObservationListBySessionIdUpdateAPIMsg
  | GetObservationListBySessionIdUpdateAPIRes;
