import {
  DIACTIVE_STAFF_OF_ORG_DATA,
  DIACTIVE_STAFF_OF_ORG_FAIL,
  DIACTIVE_STAFF_OF_ORG_LOADING,
  DIACTIVE_STAFF_OF_ORG_SUCCESS,
  DIACTIVE_STAFF_OF_ORG_API_MSG,
  DIACTIVE_STAFF_OF_ORG_API_RES,
  DiactiveStaffOfOrgActionTypes,
} from "./ActionTypes";
import { PageCount, DiactiveStaffOfOrgDetails } from "./Model";

export const diactiveStaffOrganizationIdAction = (
  DiactiveStaffOfOrgDetails: DiactiveStaffOfOrgDetails,
  pageCount: PageCount,
): DiactiveStaffOfOrgActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_ORG_DATA,
    payload: DiactiveStaffOfOrgDetails,
    pageCount: pageCount,
  };
};

export const DiactiveStaffOfOrgLoadingAction = (
  loading: boolean,
): DiactiveStaffOfOrgActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_ORG_LOADING,
    loading: loading,
  };
};

export const DiactiveStaffOfOrgSuccessAction = (
  diactiveStaffResponse: DiactiveStaffOfOrgDetails,
  successMsg: string,
): DiactiveStaffOfOrgActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_ORG_SUCCESS,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
  };
};

export const DiactiveStaffOfOrgErrorAction = (
  diactiveStaffResponse: DiactiveStaffOfOrgDetails,
  errMsg: string,
  status: number,
): DiactiveStaffOfOrgActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_ORG_FAIL,
    payload: diactiveStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DiactiveStaffOfOrgAPIMsgAction = (
  diactiveStaffResponse: DiactiveStaffOfOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DiactiveStaffOfOrgActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_ORG_API_MSG,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const DiactiveStaffOfOrgAPIResClearAction = (
  diactiveStaffResponse: DiactiveStaffOfOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DiactiveStaffOfOrgActionTypes => {
  return {
    type: DIACTIVE_STAFF_OF_ORG_API_RES,
    payload: diactiveStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
