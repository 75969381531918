import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetOriganizationListActionTypes } from "./ActionTypes";
import { GetOrganizationListRes } from "./Model";
import {
  getOriganizationListAPIResClearAction,
  getOriganizationListErrorAction,
  getOriganizationListLoadingAction,
  getOriganizationListSuccessAction,
  getOriganizationListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetOrganizationListRes;
export const getOrganizationListValue = () => {
  return function (dispatch: Dispatch<GetOriganizationListActionTypes>) {
    dispatch(getOriganizationListLoadingAction(true));
    axios
      .get(Url.baseUrl + `/adminapp/api/patientorganizationlist/org_list_where_as_staff`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getOriganizationListLoadingAction(false));
        dispatch(getOriganizationListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getOriganizationListLoadingAction(false));
        dispatch(
          getOriganizationListErrorAction(
            {} as GetOrganizationListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getOrganizationListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetOriganizationListActionTypes>) {
    dispatch(getOriganizationListAPIMsgAction(apiRes as GetOrganizationListRes, "", 0));
  };
};

export const clearOrganizationListAPIRes = () => {
  return function (dispatch: Dispatch<GetOriganizationListActionTypes>) {
    dispatch(getOriganizationListAPIResClearAction({} as GetOrganizationListRes, "", 0));
  };
};
