import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddDoctorInHealthCampActionTypes } from "./ActionTypes";
import { AddDoctorInHealthCampBody, AddDoctorInHealthCampRes } from "./Model";
import {
  getHealthcampStaffListAPIResClearAction,
  getHealthcampStaffListErrorAction,
  getHealthcampStaffListLoadingAction,
  getHealthcampStaffListSuccessAction,
  getHealthcampStaffListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as AddDoctorInHealthCampRes;
export const addDoctorInHealthCamp = (payload: AddDoctorInHealthCampBody) => {
  return function (dispatch: Dispatch<AddDoctorInHealthCampActionTypes>) {
    dispatch(getHealthcampStaffListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/healthcampapp/api/healthcampDoctor/add_doctor_in_healthcamp`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getHealthcampStaffListLoadingAction(false));
        dispatch(
          getHealthcampStaffListSuccessAction(
            res.data !== undefined ? res.data : ({} as AddDoctorInHealthCampRes),
            res.data.message !== undefined
              ? res.data.message
              : "Doctor has been added into the health camp successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getHealthcampStaffListLoadingAction(false));
        dispatch(
          getHealthcampStaffListErrorAction(
            {} as AddDoctorInHealthCampRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddDoctorInHealthCampAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDoctorInHealthCampActionTypes>) {
    dispatch(
      getHealthcampStaffListUpdateAPIMsgAction(apiRes as AddDoctorInHealthCampRes, "", "", 0),
    );
  };
};

export const clearAddDoctorInHealthCampAPIRes = () => {
  return function (dispatch: Dispatch<AddDoctorInHealthCampActionTypes>) {
    dispatch(getHealthcampStaffListAPIResClearAction({} as AddDoctorInHealthCampRes, "", "", 0));
  };
};
