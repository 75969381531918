import { DeleteScheduleRes } from "./Model";
export const DELETE_SCHEDULE_LOADING = "DELETE_SCHEDULE_LOADING";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAIL = "DELETE_SCHEDULE_FAIL";
export const DELETE_SCHEDULE_UPDATE_API_MSG = "DELETE_SCHEDULE_UPDATE_API_MSG";
export const DELETE_SCHEDULE_UPDATE_API_RES = "DELETE_SCHEDULE_UPDATE_API_RES";

export interface DeleteScheduleState {
  deleteScheduleRes: DeleteScheduleRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteScheduleLoading {
  type: typeof DELETE_SCHEDULE_LOADING;
  loading: boolean;
}
export interface DeleteScheduleSuccess {
  type: typeof DELETE_SCHEDULE_SUCCESS;
  payload: DeleteScheduleRes;
  successMsg: string;
}
export interface DeleteScheduleFail {
  type: typeof DELETE_SCHEDULE_FAIL;
  payload: DeleteScheduleRes;
  errorMsg: string;
  status: number;
}
export interface DeleteScheduleUpdateAPIMsg {
  type: typeof DELETE_SCHEDULE_UPDATE_API_MSG;
  payload: DeleteScheduleRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteScheduleUpdateAPIRes {
  type: typeof DELETE_SCHEDULE_UPDATE_API_RES;
  payload: DeleteScheduleRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DeleteScheduleActionTypes =
  | DeleteScheduleLoading
  | DeleteScheduleSuccess
  | DeleteScheduleFail
  | DeleteScheduleUpdateAPIMsg
  | DeleteScheduleUpdateAPIRes;
