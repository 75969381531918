import {
  AddExistingPatientByProviderOrgIdActionTypes,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS,
  AddExistingPatientByProviderOrgIdState,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES,
} from "./ActionTypes";
import { AddExistingPatientByProviderOrgIdDetails } from "./Model";

const initialStateGetPosts: AddExistingPatientByProviderOrgIdState = {
  loading: false,
  addExistingPatientByProviderOrgIdRes: {} as AddExistingPatientByProviderOrgIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addExistingPatientByProviderOrgIdReducer = (
  state = initialStateGetPosts,
  action: AddExistingPatientByProviderOrgIdActionTypes,
): AddExistingPatientByProviderOrgIdState => {
  switch (action.type) {
    case ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS:
      return {
        ...state,
        addExistingPatientByProviderOrgIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL:
      return {
        ...state,
        addExistingPatientByProviderOrgIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG:
      return {
        ...state,
        addExistingPatientByProviderOrgIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES:
      return {
        ...state,
        addExistingPatientByProviderOrgIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
