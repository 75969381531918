import {
     GetHealthcampRegistrationListActionTypes,
    HEALTHCAMP_REGISTRATION_LIST_FAIL,
    HEALTHCAMP_REGISTRATION_LIST_LOADING,
    HEALTHCAMP_REGISTRATION_LIST_SUCCESS,
    GetHealthCampRegistrationListState,
    HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG,
    HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { GetHealthcampRequestListRes } from "./Model";
  
  const initialStateGetPosts: GetHealthCampRegistrationListState = {
    loading: false,
    getHealthCampRegistrationRequestList: {} as GetHealthcampRequestListRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const  getHealthCampRegistrationRequestListReducer = (
    state = initialStateGetPosts,
    action:  GetHealthcampRegistrationListActionTypes,
  ): GetHealthCampRegistrationListState => {
    switch (action.type) {
      case HEALTHCAMP_REGISTRATION_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case HEALTHCAMP_REGISTRATION_LIST_SUCCESS:
        return {
          ...state,
          getHealthCampRegistrationRequestList: action.payload,
          successMsg: action.successMsg,
        };
      case HEALTHCAMP_REGISTRATION_LIST_FAIL:
        return {
          ...state,
          getHealthCampRegistrationRequestList: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG:
        return {
          ...state,
          getHealthCampRegistrationRequestList: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES:
        return {
          ...state,
          getHealthCampRegistrationRequestList: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  