import {
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG,
  GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES,
  GetMandatoryPendingDetailsListBySessionIdActionTypes,
} from "./ActionTypes";
import { GetMandatoryPendingDetailsListBySessionIdRes } from "./Model";

export const getMandatoryPendingDetailsListBySessionIdLoadingAction = (
  loading: boolean,
): GetMandatoryPendingDetailsListBySessionIdActionTypes => {
  return {
    type: GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const getMandatoryPendingDetailsListBySessionIdSuccessAction = (
  getMandatoryPendingDetailsListBySessionIdResponse: GetMandatoryPendingDetailsListBySessionIdRes,
  successMsg: string,
): GetMandatoryPendingDetailsListBySessionIdActionTypes => {
  return {
    type: GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS,
    payload: getMandatoryPendingDetailsListBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const getMandatoryPendingDetailsListBySessionIdErrorAction = (
  getMandatoryPendingDetailsListBySessionIdResponse: GetMandatoryPendingDetailsListBySessionIdRes,
  errMsg: string,
  status: number,
): GetMandatoryPendingDetailsListBySessionIdActionTypes => {
  return {
    type: GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL,
    payload: getMandatoryPendingDetailsListBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getMandatoryPendingDetailsListBySessionIdUpdateAPIMsgAction = (
  getMandatoryPendingDetailsListBySessionIdResponse: GetMandatoryPendingDetailsListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetMandatoryPendingDetailsListBySessionIdActionTypes => {
  return {
    type: GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG,
    payload: getMandatoryPendingDetailsListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getMandatoryPendingDetailsListBySessionIdAPIResClearAction = (
  getMandatoryPendingDetailsListBySessionIdResponse: GetMandatoryPendingDetailsListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetMandatoryPendingDetailsListBySessionIdActionTypes => {
  return {
    type: GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES,
    payload: getMandatoryPendingDetailsListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
