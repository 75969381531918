import {
  GetDoctorListByProviderOrganizationIdActionTypes,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS,
  GetDoctorListByProviderOrganizationIdState,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { GetDoctorListByProviderOrganizationIdRes } from "./Model";

const initialStateGetPosts: GetDoctorListByProviderOrganizationIdState = {
  loading: false,
  getDoctorListByProviderOrganizationIdRes: {} as GetDoctorListByProviderOrganizationIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDoctorListByProviderOrganizationIdReducer = (
  state = initialStateGetPosts,
  action: GetDoctorListByProviderOrganizationIdActionTypes,
): GetDoctorListByProviderOrganizationIdState => {
  switch (action.type) {
    case DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS:
      return {
        ...state,
        getDoctorListByProviderOrganizationIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL:
      return {
        ...state,
        getDoctorListByProviderOrganizationIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG:
      return {
        ...state,
        getDoctorListByProviderOrganizationIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES:
      return {
        ...state,
        getDoctorListByProviderOrganizationIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
