import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import React from "react";
import _ from "lodash";
import MWSnackbar from "component/MWSnackbar";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import MWExceptionList from "component/MWExceptionList";
import PageLayout from "pages/Layout/PageLayout";
import MWPageTitle from "component/MWPageTitle";
import { PatientOrganizationList } from "redux/OrganizationList/Model";
import { getOrganizationListValue } from "redux/OrganizationList/API";
import OrganizationAddRegistrationDetailsDialog from "pages/OrganizationAddRegistrationDetailsDialog";
import { getOrganizationRegistrationRequestDetailsList } from "redux/OrganizationRegistrationReqDetailList/API";
import { GetOrganizationRequestDetailsListBody } from "redux/OrganizationRegistrationReqDetailList/Model";
import { OrganizationToggleActiveRegReqDetailsBody } from "redux/OrganizationToggleActiveRegistrationRequestDetails/Model";
import {
  clearOrganizationToggleActiveRegistrationRequestDetailsAPIRes,
  organizationToggleActiveRegistrationRequestDetails,
} from "redux/OrganizationToggleActiveRegistrationRequestDetails/API";

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "auto",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);
export default function OrganizationRequestListDetails() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const [descriptionModalActive, setDescriptionModalActive] = useState(false);
  const closeModal = () => {
    setDescriptionModalActive(false);
  };
  const [organizationReqDetailsListValues, setOrganizationReqDetailsListValues] = useState(
    [] as any[],
  );
  const [detailimg, setDetailimg] = useState("");
  const registrationReqDetailsList = (rowIndex: any) => {
    setDetailimg(rowIndex);
    if (detailimg !== undefined) {
      setDescriptionModalActive(true);
    }
  };

  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowFilterDialog(passedVal);
  };
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const { requestid } = useParams() as {
    requestid: string;
  };

  const toggleActiveClose = () => {
    dispatch(clearOrganizationToggleActiveRegistrationRequestDetailsAPIRes());
    setToggleActiveRegReqMsg("");
  };
  const [toggleActiveRegReqMsg, setToggleActiveRegReqMsg] = useState("");
  const [toggleActiveRegReqMsgType, setToggleActiveRegReqMsgType] = useState<AlertColor>("success");

  // call Store
  const GetOrganizationRegistrationRequestDetailsListValue = useSelector(
    (state: AppState) => state.getOrganizationRegistrationRequestDetailsListValue,
  );

  const organizationToggleActiveRegistrationRequestDetailsStoreValue = useSelector(
    (state: AppState) => state.organozationToggleActiveRegistrationRequestDetailsValue,
  );

  const addResponse = useSelector(
    (state: AppState) => state.organizationAddRegistrationRequestDetails,
  );

  const requestList: GridColDef[] = [
    {
      field: "requestDocument",
      headerName: "View",
      width: 150,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          disabled={params.row.requestDocument === "" ? true : false}
          variant="outlined"
          onClick={() => {
            registrationReqDetailsList(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 120,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      width: 220,
      flex: 1,
    },
    { field: "requestDetailsType", headerName: "Request Details Type", width: 150, flex: 1 },
    {
      field: "status",
      headerName: "Request Status",
      sortable: true,
      hideable: false,
      minWidth: 200,
      flex: 1,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(
              organizationToggleActiveRegistrationRequestDetails({
                patientOrgRegistrationRequestDetailId: params.row.id,
              } as OrganizationToggleActiveRegReqDetailsBody),
            );
          }}
        >
          Deactive
        </Button>
      ),
    },
  ];

  const reloadDoctorQueryList = () => {
    dispatch(
      getOrganizationRegistrationRequestDetailsList({
        registrationRequestId: requestid,
      } as GetOrganizationRequestDetailsListBody),
    );
  };

  const OrganizationList = useSelector((state: AppState) => state.organizationListValue);
  const organizationValues: PatientOrganizationList | undefined = _.find(
    OrganizationList?.getOrganizationRes?.patientOrganizationList,
    ["id", Number(organizationid)],
  );

  useEffect(() => {
    if (organizationValues === undefined) {
      dispatch(getOrganizationListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getOrganizationRegistrationRequestDetailsList({
        registrationRequestId: requestid,
      } as GetOrganizationRequestDetailsListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      GetOrganizationRegistrationRequestDetailsListValue
        ?.getOrganizationRegistrationReqDetailsListresponse
        ?.patientOrganizationRegistrationRequestDetailList !== undefined
    ) {
      let regRequestDetailsList =
        GetOrganizationRegistrationRequestDetailsListValue?.getOrganizationRegistrationReqDetailsListresponse?.patientOrganizationRegistrationRequestDetailList.map(
          (element: any) => ({
            id: element.id,
            description: element?.description !== "" ? element?.description : "Not Set",
            registrationRequestId:
              element?.registrationRequestId !== "" ? element?.registrationRequestId : "",
            requestDetailsType:
              element?.requestDetailsType !== "" ? element?.requestDetailsType : "",
            requestDocument:
              element?.requestDocument !== null ? element?.requestDocument.document : "",
          }),
        );
      setOrganizationReqDetailsListValues(regRequestDetailsList);
    }
  }, [GetOrganizationRegistrationRequestDetailsListValue]);

  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      setToggleActiveRegReqMsgType("success");
      setToggleActiveRegReqMsg(addResponse?.successMsg);
      dispatch(
        getOrganizationRegistrationRequestDetailsList({
          registrationRequestId: requestid,
        } as GetOrganizationRequestDetailsListBody),
      );
    }
    if (addResponse?.errorMsg !== "") {
      setToggleActiveRegReqMsgType("error");
      setToggleActiveRegReqMsg(addResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  useEffect(() => {
    if (organizationToggleActiveRegistrationRequestDetailsStoreValue?.successMsg !== undefined) {
      setToggleActiveRegReqMsgType("success");
      setToggleActiveRegReqMsg(
        organizationToggleActiveRegistrationRequestDetailsStoreValue?.successMsg,
      );
      dispatch(
        getOrganizationRegistrationRequestDetailsList({
          registrationRequestId: requestid,
        } as GetOrganizationRequestDetailsListBody),
      );
    }
    if (organizationToggleActiveRegistrationRequestDetailsStoreValue?.errorMsg !== "") {
      setToggleActiveRegReqMsgType("error");
      setToggleActiveRegReqMsg(
        organizationToggleActiveRegistrationRequestDetailsStoreValue?.errorMsg,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationToggleActiveRegistrationRequestDetailsStoreValue]);

  const displayModalBody = (
    <Dialog open={descriptionModalActive} onClose={closeModal} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">View Document</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Paper square sx={{ minHeight: "auto" }}>
          <TransformWrapper initialScale={1}>
            {(utils) => (
              <React.Fragment>
                <Controls {...utils} />
                <TransformComponent
                  wrapperStyle={{
                    maxWidth: "auto",
                    height: "auto",
                    border: "solid 2px gray",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                    }}
                    src={detailimg}
                    alt="photoimage"
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Paper>
      </DialogContent>
      <Divider />
    </Dialog>
  );

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6">Request Details List</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={
                      GetOrganizationRegistrationRequestDetailsListValue
                        ?.getOrganizationRegistrationReqDetailsListresponse
                        ?.patientOrganizationRegistrationRequestDetailList !== undefined
                        ? String(
                            GetOrganizationRegistrationRequestDetailsListValue
                              ?.getOrganizationRegistrationReqDetailsListresponse
                              ?.patientOrganizationRegistrationRequestDetailList.length,
                          )
                        : "0"
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item mt={1}>
                  <RefreshIcon
                    onClick={() => {
                      reloadDoctorQueryList();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => setShowFilterDialog(true)}>
                Add Registration Details
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {organizationReqDetailsListValues && organizationReqDetailsListValues.length ? (
          <DataGrid
            rows={organizationReqDetailsListValues}
            columns={requestList}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {GetOrganizationRegistrationRequestDetailsListValue.loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle
          title={
            organizationValues?.displayName !== undefined
              ? organizationValues?.displayName + " Registration Request Details List"
              : ""
          }
          backButton={false}
        />
        {actualPageMarkup}
      </PageLayout>
      <OrganizationAddRegistrationDetailsDialog
        filterDialogEnable={showFilterDialog}
        filterDialogUpdateState={updateFilterDialogValFromChild}
        healthcampid={organizationid}
        requestid={requestid}
      />
      {displayModalBody}

      {toggleActiveRegReqMsg !== "" ? (
        <MWSnackbar
          msg={toggleActiveRegReqMsg}
          type={toggleActiveRegReqMsgType}
          alertClose={toggleActiveClose}
        />
      ) : null}
    </Box>
  );
}
