import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import MWExceptionList from "../../component/MWExceptionList";
import { getProviderOrganizationAdministratorListApi } from "../../redux/ProviderOrganizationAdministratorList /API";
import { useHistory } from "react-router-dom";
import MWPageTitle from "../../component/MWPageTitle";
import AdminLoader from "../AdminLoader";

export default function ProviderOrganizationAdministratorListPage(_props: any) {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;
  const [page, setPage] = useState(1);
  const [listCount, setListCount] = React.useState("0");
  const [pageListCount, setPageListCount] = React.useState("");
  useEffect(() => {
    dispatch(getProviderOrganizationAdministratorListApi(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getProviderOrganizationAdministratorListApi(value));
  };
  const providerOrganizationAdministratorListValue = useSelector(
    (state: AppState) => state.getProviderOrganizationAdministratorListValue,
  );
  const orgValues =
    providerOrganizationAdministratorListValue?.getProviderOrgAdministratorRes
      ?.providerOrganizationAdministratorList;
  useEffect(() => {
    orgValues !== undefined
      ? setListCount(
          String(providerOrganizationAdministratorListValue?.getProviderOrgAdministratorRes.count),
        )
      : setListCount("0");
    if (
      providerOrganizationAdministratorListValue?.getProviderOrgAdministratorRes
        ?.providerOrganizationAdministratorList !== undefined
    ) {
      setPageListCount(
        String(
          providerOrganizationAdministratorListValue?.getProviderOrgAdministratorRes?.numberOfPages,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOrganizationAdministratorListValue]);

  const handleAdministratorDetails = (administratorid: any) => {
    history.push(`/administratordetails/${administratorid}`);
  };

  const administratorListApi = () => {
    dispatch(getProviderOrganizationAdministratorListApi(1));
  };

  const actualPageMarkup = (
    <Box>
      <Paper sx={{ my: 2 }}>
        <Grid container spacing={1}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
                mt={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 1}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sm={12} spacing={3} my={5} ml={2}>
            {orgValues && orgValues.length ? (
              <React.Fragment>
                {orgValues.map((organizationItem: any) => (
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Card
                      raised
                      sx={{
                        margin: "0 auto",
                        padding: "0.1em",
                        height: 450,
                      }}
                    >
                      <CardHeader
                        title={
                          <Typography variant="h6" fontWeight="bold">
                            {organizationItem?.organization?.displayName}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "1",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {organizationItem?.organization?.tagLine}
                          </Typography>
                        }
                      />
                      <CardMedia
                        component="img"
                        image={
                          organizationItem?.organization?.logoImage !== null
                            ? organizationItem?.organization?.logoImage?.document
                            : "../organization-sample.jpg"
                        }
                        alt="coverpicture"
                        sx={{
                          padding: "1em 1em 0 1em",
                          objectFit: "contain",
                        }}
                        height="200"
                      />
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {organizationItem?.organization?.description}
                        </Typography>
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          my={1}
                        >
                          <Grid item>
                            <Button
                              variant="outlined"
                              sx={{ textTransform: "none" }}
                              onClick={() => {
                                handleAdministratorDetails(organizationItem?.organization?.id);
                              }}
                            >
                              View Administrator
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </React.Fragment>
            ) : (
              <Box p={3}>
                <MWExceptionList />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );

  return (
    <Box>
      {providerOrganizationAdministratorListValue.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={"Organization Administrator List"}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={administratorListApi}
              />
            </Grid>
          </Grid>
          {actualPageMarkup}
        </Stack>
      </Container>
    </Box>
  );
}
