import {
  GET_HEALTH_CAMP_DROPDOWN_LIST_FAIL,
  GET_HEALTH_CAMP_DROPDOWN_LIST_LOADING,
  GET_HEALTH_CAMP_DROPDOWN_LIST_SUCCESS,
  GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_MSG,
  GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_RES,
  GetHealthCampDropDownListActionTypes,
} from "./ActionTypes";
import { GetHealthCampDropDownListRes } from "./Model";

export const getHealthCampDropDownListLoadingAction = (
  loading: boolean,
): GetHealthCampDropDownListActionTypes => {
  return {
    type: GET_HEALTH_CAMP_DROPDOWN_LIST_LOADING,
    loading: loading,
  };
};

export const getHealthCampDropDownListSuccessAction = (
  getHealthCampDropDownListResponse: GetHealthCampDropDownListRes,
  successMsg: string,
): GetHealthCampDropDownListActionTypes => {
  return {
    type: GET_HEALTH_CAMP_DROPDOWN_LIST_SUCCESS,
    payload: getHealthCampDropDownListResponse,
    successMsg: successMsg,
  };
};

export const getHealthCampDropDownListErrorAction = (
  getHealthCampDropDownListResponse: GetHealthCampDropDownListRes,
  errMsg: string,
  status: number,
): GetHealthCampDropDownListActionTypes => {
  return {
    type: GET_HEALTH_CAMP_DROPDOWN_LIST_FAIL,
    payload: getHealthCampDropDownListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthCampDropDownListUpdateAPIMsgAction = (
  getHealthCampDropDownListResponse: GetHealthCampDropDownListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthCampDropDownListActionTypes => {
  return {
    type: GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_MSG,
    payload: getHealthCampDropDownListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthCampDropDownListAPIResClearAction = (
  getHealthCampDropDownListResponse: GetHealthCampDropDownListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthCampDropDownListActionTypes => {
  return {
    type: GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_RES,
    payload: getHealthCampDropDownListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
