import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { Button, Card, CardContent, CardHeader, Grid, Box, AlertColor } from "@mui/material";
import MWExceptionList from "../../../../component/MWExceptionList";
import LoopIcon from "@mui/icons-material/Loop";
import { GetPatientNoteListBody, NoteList } from "../../../../redux/Notes/PatientNotesList/Model";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import DeletePatientNote from "../DeletePatientNote";
import { useParams } from "react-router-dom";
import { getAdminNoteListByPatient } from "../../../../redux/Notes/PatientNotesList/API";
import { clearNoteAPIRes } from "../../../../redux/Notes/updateNoteStatus/API";
import MWSnackbar from "../../../../component/MWSnackbar";

type Props = {
  inactiveNoteLoader: boolean | any;
};
export default function ActivePatientCaseNotes({ inactiveNoteLoader }: Props) {
  const dispatch = useDispatch();
  const { profileId } = useParams() as {
    profileId: string;
  };
  const [updateNoteStatusDialog, setUpdateNoteStatusDialog] = useState(false);
  const updateNoteStatusEditDialogUpdateState = (passedVal: boolean) => {
    setUpdateNoteStatusDialog(passedVal);
  };

  // Patient Notes List Api Value from store
  const patientNoteListResVal = useSelector((state: AppState) => state.patientNoteListRes);

  const [noteList, setNoteList] = useState([] as any[]);
  const [selectedItem, setSelectedItem] = useState("");
  useEffect(() => {
    inactiveNoteLoader(patientNoteListResVal?.loading);
    if (patientNoteListResVal?.getPatientNoteList?.noteList !== undefined) {
      if (patientNoteListResVal?.getPatientNoteList?.noteList.length > 0) {
        let noteItem = patientNoteListResVal?.getPatientNoteList?.noteList.map(
          (item: NoteList) => ({
            id: item.id,
            note:
              item?.note !== undefined
                ? item?.note !== null
                  ? item?.note !== ""
                    ? item.note
                    : ""
                  : ""
                : "",
            createdDate:
              item?.createdDate !== undefined
                ? item?.createdDate !== null
                  ? dayjs(item.createdDate).format("ddd, DD/MM/YYYY")
                  : ""
                : "",
            createdBy:
              item?.createdBy?.user?.first_name !== undefined &&
              item?.createdBy?.user?.last_name !== undefined
                ? item?.createdBy?.user?.first_name + " " + item?.createdBy?.user?.last_name
                : "",
            modifiedDate:
              item?.modifiedDate !== undefined
                ? item?.modifiedDate !== null
                  ? dayjs(item.modifiedDate).format("ddd, DD/MM/YYYY")
                  : ""
                : "",
            modifiedBy:
              item?.modifiedBy?.user?.first_name !== undefined &&
              item?.modifiedBy?.user?.last_name !== undefined
                ? item?.modifiedBy?.user?.first_name + " " + item?.modifiedBy?.user?.last_name
                : "",
          }),
        );
        setNoteList(noteItem);
      } else setNoteList([] as any[]);
    } else setNoteList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientNoteListResVal]);
  const columns: GridColDef[] = [
    { field: "note", headerName: "Note", flex: 1 },
    { field: "createdDate", headerName: "Created Date", minWidth: 140 },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
    { field: "modifiedBy", headerName: "Modified By", flex: 1 },
    { field: "modifiedDate", headerName: "Modified Date", minWidth: 140 },
    {
      field: "id",
      headerName: "Modify Status",
      minWidth: 35,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<LoopIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
          sx={{ textTransform: "none" }}
          disabled={updateNoteStatusDialog}
        >
          Edit
        </Button>
      ),
    },
  ];
  // Notes Status change API
  function toggleNoteStatus(item: number) {
    setUpdateNoteStatusDialog(true);
    setSelectedItem(String(item));
  }

  // Update Notes status Api Value from store
  const updateNoteResVal = useSelector((state: AppState) => state.updateNoteRes);
  const [addNoteErrMsg, setAddNoteErrMsg] = useState("");
  const [addNoteErrMsgType, setAddNoteErrMsgType] = useState<AlertColor>("error");
  useEffect(() => {
    if (updateNoteResVal?.successMsg !== "") {
      setAddNoteErrMsg(updateNoteResVal?.successMsg);
      setAddNoteErrMsgType("success");
      dispatch(
        getAdminNoteListByPatient({
          patientId: profileId,
          isActive: false,
        } as GetPatientNoteListBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNoteResVal]);

  const closeModifyNoteStatusAlert = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearNoteAPIRes());
      setAddNoteErrMsg("");
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>Note List</Grid>
            </Grid>
          }
        />
        <CardContent>
          {noteList.length > 0 ? (
            <DataGrid
              rows={noteList}
              columns={columns}
              loading={patientNoteListResVal?.loading}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {updateNoteStatusDialog ? (
        <DeletePatientNote
          status={true}
          selectedItem={selectedItem}
          updateNoteStatusDialog={updateNoteStatusDialog}
          updateNoteStatusEditDialogUpdateState={updateNoteStatusEditDialogUpdateState}
        />
      ) : null}
      <MWSnackbar
        msg={addNoteErrMsg}
        type={addNoteErrMsgType}
        alertClose={closeModifyNoteStatusAlert}
      />
    </Box>
  );
}
