import { GetDoctorWorkScheduleListRes } from "./Model";
export const GET_DOCTOR_SCHEDULE_LIST_LOADING =
  "GET_DOCTOR_SCHEDULE_LIST_LOADING";
export const GET_DOCTOR_SCHEDULE_LIST_SUCCESS =
  "GET_DOCTOR_SCHEDULE_LIST_SUCCESS";
export const GET_DOCTOR_SCHEDULE_LIST_FAIL = "GET_DOCTOR_SCHEDULE_LIST_FAIL";
export const GET_DOCTOR_SCHEDULE_LIST_DATA = "GET_DOCTOR_SCHEDULE_LIST_DATA";
export const GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_MSG =
  "GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_MSG";
export const GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_RES =
  "GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_RES";

export interface GetDoctorWorkScheduleListState {
  getDoctorWorkScheduleListRes: GetDoctorWorkScheduleListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorWorkScheduleListLoading {
  type: typeof GET_DOCTOR_SCHEDULE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorWorkScheduleListSuccess {
  type: typeof GET_DOCTOR_SCHEDULE_LIST_SUCCESS;
  payload: GetDoctorWorkScheduleListRes;
  successMsg: string;
}
export interface GetDoctorWorkScheduleListFail {
  type: typeof GET_DOCTOR_SCHEDULE_LIST_FAIL;
  payload: GetDoctorWorkScheduleListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorWorkScheduleListUpdateAPIMsg {
  type: typeof GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_MSG;
  payload: GetDoctorWorkScheduleListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorWorkScheduleListUpdateAPIRes {
  type: typeof GET_DOCTOR_SCHEDULE_LIST_UPDATE_API_RES;
  payload: GetDoctorWorkScheduleListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorWorkScheduleListActionTypes =
  | GetDoctorWorkScheduleListLoading
  | GetDoctorWorkScheduleListSuccess
  | GetDoctorWorkScheduleListFail
  | GetDoctorWorkScheduleListUpdateAPIMsg
  | GetDoctorWorkScheduleListUpdateAPIRes;
