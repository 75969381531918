import {
  DeleteIdentifyActionTypes,
  GetPatientDemographicsState,
  DELETE_IDENTIFY_SUCCESS,
  DELETE_IDENTIFY_LOADING,
  DELETE_IDENTIFY_FAIL,
} from "./ActionTypes";
import { DeleteIdentifyResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  deleteIdentify: {} as DeleteIdentifyResponse,
};
export const deleteIdentifyReducer = (
  state = initialStateGetPosts,
  action: DeleteIdentifyActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case DELETE_IDENTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteIdentify: action.payload,
      };
    case DELETE_IDENTIFY_LOADING:
      return {
        ...state,
        loading: true,
        deleteIdentify: {} as DeleteIdentifyResponse,
      };
    case DELETE_IDENTIFY_FAIL:
      return {
        ...state,
        deleteIdentify: {} as DeleteIdentifyResponse,
        loading: false,
      };
    default:
      return state;
  }
};
