import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { adminExecutiveList } from "redux/TaskAdmin/ExecutiveList/API";
import { assignTaskToExecutive } from "redux/Task/API";

type Props = {
  assignExecutiveDialogEnable?: boolean | any;
  assignToExecutiveDialogUpdateState?: boolean | any;
  taskValues?: string | any;
  rowIndex?: number | any;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AssignToExecutiveDialog({
  assignExecutiveDialogEnable,
  assignToExecutiveDialogUpdateState,
  taskValues,
  rowIndex,
}: Props) {
  const dispatch = useDispatch();
  const executiveListData = useSelector(
    (state: AppState) => state.executiveDropdownListvalue?.executiveRes?.executiveList,
  );
  const [executiveValues, setExecutiveValue] = useState<any[]>([]);
  const handleClose = () => {
    assignToExecutiveDialogUpdateState(false);
  };
  const [dialogState, setDialogState] = React.useState(false);

  useEffect(() => {
    setDialogState(assignExecutiveDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignExecutiveDialogEnable]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(adminExecutiveList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    if (executiveListData !== undefined) {
      setExecutiveValue(executiveListData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [executiveListData]);
  const [selectedExe, setSelectedExe] = useState<any | null>(null);

  let assignExecutiveBody = {
    taskId: Number(rowIndex),
    executiveId: Number(selectedExe?.value),
  };
  // Assign Executive API
  const assignExecutive = () => {
    dispatch(assignTaskToExecutive(assignExecutiveBody));
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Select Executive
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Select executive for task: "{taskValues === undefined ? "" : taskValues.title}"
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                value={selectedExe}
                options={executiveValues}
                onChange={(_event: any, newValue: any) => {
                  setSelectedExe(newValue);
                }}
                //disabled={addDoctorInHealthCampRes?.loading}
                getOptionLabel={(option) => `${option.label}`}
                renderInput={(params) => (
                  <TextField {...params} label="Select Executive" fullWidth size="small" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={assignExecutive}
                disabled={selectedExe !== null ? false : true}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
