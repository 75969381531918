import { EditDataCurationProblemDetails } from "./Model";
export const EDIT_DATA_CURATION_PROBLEM_LOADING = "EDIT_DATA_CURATION_PROBLEM_LOADING";
export const EDIT_DATA_CURATION_PROBLEM_SUCCESS = "EDIT_DATA_CURATION_PROBLEM_SUCCESS";
export const EDIT_DATA_CURATION_PROBLEM_FAIL = "EDIT_DATA_CURATION_PROBLEM_FAIL";
export const EDIT_DATA_CURATION_PROBLEM_API_MSG = "EDIT_DATA_CURATION_PROBLEM_API_MSG";
export const EDIT_DATA_CURATION_PROBLEM_API_RES = "EDIT_DATA_CURATION_PROBLEM_API_RES";

export interface EditDataCurationProblemState {
  editDataCurationProblemRes: EditDataCurationProblemDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDataCurationProblemLoading {
  type: typeof EDIT_DATA_CURATION_PROBLEM_LOADING;
  loading: boolean;
}
export interface EditDataCurationProblemSuccess {
  type: typeof EDIT_DATA_CURATION_PROBLEM_SUCCESS;
  payload: EditDataCurationProblemDetails;
  successMsg: string;
}
export interface EditDataCurationProblemFail {
  type: typeof EDIT_DATA_CURATION_PROBLEM_FAIL;
  payload: EditDataCurationProblemDetails;
  errorMsg: string;
  status: number;
}
export interface EditDataCurationProblemAPIMsg {
  type: typeof EDIT_DATA_CURATION_PROBLEM_API_MSG;
  payload: EditDataCurationProblemDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDataCurationProblemAPIRes {
  type: typeof EDIT_DATA_CURATION_PROBLEM_API_RES;
  payload: EditDataCurationProblemDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDataCurationProblemActionTypes =
  | EditDataCurationProblemLoading
  | EditDataCurationProblemSuccess
  | EditDataCurationProblemFail
  | EditDataCurationProblemAPIMsg
  | EditDataCurationProblemAPIRes;
