import { AddExecutiveRes } from "./Model";
export const ADD_EXECUTIVE_LOADING = "ADD_EXECUTIVE_LOADING";
export const ADD_EXECUTIVE_SUCCESS = "ADD_EXECUTIVE_SUCCESS";
export const ADD_EXECUTIVE_FAIL = "ADD_EXECUTIVE_FAIL";
export const ADD_EXECUTIVE_UPDATE_API_MSG = "ADD_EXECUTIVE_UPDATE_API_MSG";
export const ADD_EXECUTIVE_UPDATE_API_RES = "ADD_EXECUTIVE_UPDATE_API_RES";

export interface AddExecutiveState {
  addExecutiveRes: AddExecutiveRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExecutiveLoading {
  type: typeof ADD_EXECUTIVE_LOADING;
  loading: boolean;
}
export interface AddExecutiveSuccess {
  type: typeof ADD_EXECUTIVE_SUCCESS;
  payload: AddExecutiveRes;
  successMsg: string;
}
export interface AddExecutiveFail {
  type: typeof ADD_EXECUTIVE_FAIL;
  payload: AddExecutiveRes;
  errorMsg: string;
  status: number;
}
export interface AddExecutiveUpdateAPIMsg {
  type: typeof ADD_EXECUTIVE_UPDATE_API_MSG;
  payload: AddExecutiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExecutiveUpdateAPIRes {
  type: typeof ADD_EXECUTIVE_UPDATE_API_RES;
  payload: AddExecutiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddExecutiveActionTypes =
  | AddExecutiveLoading
  | AddExecutiveSuccess
  | AddExecutiveFail
  | AddExecutiveUpdateAPIMsg
  | AddExecutiveUpdateAPIRes;
