import {
  ADD_CUTOFF_MARKS_FAIL,
  ADD_CUTOFF_MARKS_LOADING,
  ADD_CUTOFF_MARKS_SUCCESS,
  ADD_CUTOFF_MARKS_API_MSG,
  ADD_CUTOFF_MARKS_API_RES,
  AddCutoffMarksDetailsActionTypes,
} from "./ActionTypes";
import { AddCutoffMarksDetailsRes } from "./Model";

export const addCutoffMarksDetailsLoadingAction = (
  loading: boolean,
): AddCutoffMarksDetailsActionTypes => {
  return {
    type: ADD_CUTOFF_MARKS_LOADING,
    loading: loading,
  };
};

export const addCutoffMarksDetailsSuccessAction = (
  addCutoffMarksResponse: AddCutoffMarksDetailsRes,
): AddCutoffMarksDetailsActionTypes => {
  return {
    type: ADD_CUTOFF_MARKS_SUCCESS,
    payload: addCutoffMarksResponse,
  };
};

export const addCutoffMarksDetailsErrorAction = (
  addCutoffMarksResponse: AddCutoffMarksDetailsRes,
  errMsg: string,
  status: number,
): AddCutoffMarksDetailsActionTypes => {
  return {
    type: ADD_CUTOFF_MARKS_FAIL,
    payload: addCutoffMarksResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addCutoffMarksDetailsAPIMsgAction = (
  addCutoffMarksResponse: AddCutoffMarksDetailsRes,
  errMsg: string,
  status: number,
): AddCutoffMarksDetailsActionTypes => {
  return {
    type: ADD_CUTOFF_MARKS_API_MSG,
    payload: addCutoffMarksResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const addCutoffMarksDetailsAPIResClearAction = (
  addCutoffMarksResponse: AddCutoffMarksDetailsRes,
  errMsg: string,
  status: number,
): AddCutoffMarksDetailsActionTypes => {
  return {
    type: ADD_CUTOFF_MARKS_API_RES,
    payload: addCutoffMarksResponse,
    errorMsg: errMsg,
    status: status,
  };
};
