import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import { Button, Card, CardContent, Grid } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { CompetitionList } from "redux/CompetitionList//Model";
import dayjs from "dayjs";
import { getCompetitionList } from "redux/CompetitionList/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import PageLayout from "pages/Layout/PageLayout";

export default function CompetitionListComponent() {
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const dispatch = useDispatch();
  const [listCount, setListCount] = React.useState("");
  const getCompetitionListValues = useSelector((state: AppState) => state.getCompetitionListValue);
  const compititionValues = getCompetitionListValues?.getCompetitionListRes?.competitionList;

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoCompetitionDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    { field: "displayName", headerName: "Competition Name", minWidth: 500 },
    { field: "dateCreated", headerName: "Create Date", minWidth: 250 },
  ];

  const history = useHistory();
  const gotoCompetitionDetails = (rowIndex: any) => {
    history.push(`/patientlistbycompetition/${rowIndex}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCompetitionList(1));
  }, [dispatch]);

  function CompetitionListApi() {
    dispatch(getCompetitionList(1));
  }

  // call Store

  useEffect(() => {
    dispatch(toggleAppBarLoading(getCompetitionListValues?.loading));
    getCompetitionListValues?.getCompetitionListRes?.count !== undefined
      ? setListCount(String(getCompetitionListValues?.getCompetitionListRes?.count))
      : setListCount("0");

    if (getCompetitionListValues?.getCompetitionListRes?.competitionList !== undefined) {
      let dataPointArr = getCompetitionListValues?.getCompetitionListRes?.competitionList.map(
        (element: CompetitionList) => ({
          id: element.id,
          displayName: element.displayName,
          dateCreated: dayjs(element.dateCreated).format("dddd, MMMM D, YYYY"),
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompetitionListValues]);
  const CompetitionName = "Competition List";
  const DataGridMarkup = (
    <Card>
      <CardContent>
        {compititionValues && compititionValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <MWPageTitle
            title={CompetitionName}
            enableCount={true}
            count={listCount}
            reload={true}
            reloadAction={CompetitionListApi}
          />
        </Grid>
      </Grid>
      {DataGridMarkup}
    </PageLayout>
  );
}
