import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import {  PatientDetailsActionTypes } from "./ActionTypes";
import { PatientDetailsFromDashboardBody, PatientDetailsById } from "./Model";
import {
   PatientDetailsAPIResClearAction,
   PatientDetailsErrorAction,
   PatientDetailsLoadingAction,
   PatientDetailsSuccessAction,
   PatientDetailsUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as PatientDetailsById;
export const  getPatientDataDetailsById = (payload: PatientDetailsFromDashboardBody) => {
  return function (dispatch: Dispatch< PatientDetailsActionTypes>) {
    dispatch( PatientDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientDetails/getPatientDetailsFromDashboard`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( PatientDetailsLoadingAction(false));
        dispatch(
           PatientDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as PatientDetailsById),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Details fetch successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( PatientDetailsLoadingAction(false));
        dispatch(
           PatientDetailsErrorAction(
            {} as PatientDetailsById,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updatePatientDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch< PatientDetailsActionTypes>) {
    dispatch(
       PatientDetailsUpdateAPIMsgAction(apiRes as PatientDetailsById, "", "", 0),
    );
  };
};

export const clearPatientDetailsAPIRes = () => {
  return function (dispatch: Dispatch< PatientDetailsActionTypes>) {
    dispatch( PatientDetailsAPIResClearAction({} as PatientDetailsById, "", "", 0));
  };
};

