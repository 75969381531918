import {
  HealthcampPatientGetActionTypes,
  HCPATIENT_LIST_FAIL,
  HCPATIENT_LIST_LOADING,
  HCPATIENT_LIST_SUCCESS,
  GetPatientListByHealthcampListState,
  HCPATIENT_LIST_UPDATE_API_MSG,
  HCPATIENT_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { HealthcampPatientList } from "./Model";

const initialStateGetPosts: GetPatientListByHealthcampListState = {
  loading: false,
  getPatientListByHealthcampListRes: {} as HealthcampPatientList,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getHealthcampPatientReducer = (
  state = initialStateGetPosts,
  action: HealthcampPatientGetActionTypes,
): GetPatientListByHealthcampListState => {
  switch (action.type) {
    case HCPATIENT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case HCPATIENT_LIST_SUCCESS:
      return {
        ...state,
        getPatientListByHealthcampListRes: action.payload,
        successMsg: action.successMsg,
      };
    case HCPATIENT_LIST_FAIL:
      return {
        ...state,
        getPatientListByHealthcampListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HCPATIENT_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getPatientListByHealthcampListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HCPATIENT_LIST_UPDATE_API_RES:
      return {
        ...state,
        getPatientListByHealthcampListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
