import {
  TrendsApiActionTypes,
  TRENDS_API_FAIL,
  TRENDS_API_LOADING,
  TRENDS_API_SUCCESS,
  TrendsApiDataState,
  TRENDS_API_API_MSG,
  TRENDS_API_CLEAR_API_RES,
} from "./ActionTypes";
import { AddPatientRes } from "./Model";

const initialStateGetPosts: TrendsApiDataState = {
  loading: false,
  trendsApiRes: {} as AddPatientRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const trendsApiReducer = (
  state = initialStateGetPosts,
  action: TrendsApiActionTypes,
): TrendsApiDataState => {
  switch (action.type) {
    case TRENDS_API_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case TRENDS_API_SUCCESS:
      return {
        ...state,
        trendsApiRes: action.payload,
        successMsg: action.successMsg,
      };
    case TRENDS_API_FAIL:
      return {
        ...state,
        trendsApiRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case TRENDS_API_API_MSG:
      return {
        ...state,
        trendsApiRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case TRENDS_API_CLEAR_API_RES:
      return {
        ...state,
        trendsApiRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
