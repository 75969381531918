import axios from "axios";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";
import {
  findUserAPIResClearAction,
  findUserErrorAction,
  findUserLoadingAction,
  findUserSuccessAction,
  findUserUpdateAPIMsgAction,
} from "./Actions";
import { Dispatch } from "react";
import { FindUserActionTypes } from "./ActionTypes";
import { headers } from "component/Utility";
import Url from "../../../ApiConfig";
import { FindUserRes, FindUserBody } from "./Model";

let apiRes = {} as FindUserRes;
export const findUser = (payload: FindUserBody) => {
  return function (dispatch: Dispatch<FindUserActionTypes>) {
    dispatch(findUserLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/ehrapp/api/incomeCall/find_user_by_phone_number`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(findUserLoadingAction(false));
        dispatch(
          findUserSuccessAction(
            res.data !== undefined ? res.data : ({} as FindUserRes),
            res.data.message !== undefined
              ? res.data.message
              : "user has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(findUserLoadingAction(false));
        dispatch(
          findUserErrorAction(
            {} as FindUserRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateFindUserAPIResMsg = () => {
  return function (dispatch: Dispatch<FindUserActionTypes>) {
    dispatch(findUserUpdateAPIMsgAction(apiRes as FindUserRes, "", "", 0));
  };
};

export const clearFindUserAPIRes = () => {
  return function (dispatch: Dispatch<FindUserActionTypes>) {
    dispatch(findUserAPIResClearAction({} as FindUserRes, "", "", 0));
  };
};
