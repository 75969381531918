import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { CheckAppointmentPaymentStatusActionTypes } from "./ActionTypes";
import { CheckPaymentStatusBody, CheckAppointmentPaymentStatusRes } from "./Model";
import {
  checkAppointmentPaymentStatusAPIResClearAction,
  checkAppointmentPaymentStatusErrorAction,
  checkAppointmentPaymentStatusLoadingAction,
  checkAppointmentPaymentStatusSuccessAction,
  checkAppointmentPaymentStatusUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "../../../../component/Utility";

let apiRes = {} as CheckAppointmentPaymentStatusRes;
export const checkAppointmentPaymentStatus = (payload: CheckPaymentStatusBody) => {
  return function (dispatch: Dispatch<CheckAppointmentPaymentStatusActionTypes>) {
    dispatch(checkAppointmentPaymentStatusLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/paymentapp/api/payment/check_payment_link`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(checkAppointmentPaymentStatusLoadingAction(false));
        dispatch(
          checkAppointmentPaymentStatusSuccessAction(
            res.data !== undefined ? res.data : ({} as CheckAppointmentPaymentStatusRes),
            "Payment list has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(checkAppointmentPaymentStatusLoadingAction(false));
        dispatch(
          checkAppointmentPaymentStatusErrorAction(
            {} as CheckAppointmentPaymentStatusRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateCheckAppointmentPaymentStatusAPIResMsg = () => {
  return function (dispatch: Dispatch<CheckAppointmentPaymentStatusActionTypes>) {
    dispatch(
      checkAppointmentPaymentStatusUpdateAPIMsgAction(
        apiRes as CheckAppointmentPaymentStatusRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearAppointmentPaymentStatusAPIRes = () => {
  return function (dispatch: Dispatch<CheckAppointmentPaymentStatusActionTypes>) {
    dispatch(
      checkAppointmentPaymentStatusAPIResClearAction(
        {} as CheckAppointmentPaymentStatusRes,
        "",
        "",
        0,
      ),
    );
  };
};
