import { GetPatientListByTeamIdRes } from "./Model";
export const PATIENT_LIST_BY_TEAM_ID_LOADING = "PATIENT_LIST_BY_TEAM_ID_LOADING";
export const PATIENT_LIST_BY_TEAM_ID_SUCCESS = "PATIENT_LIST_BY_TEAM_ID_SUCCESS";
export const PATIENT_LIST_BY_TEAM_ID_FAIL = "PATIENT_LIST_BY_TEAM_ID_FAIL";
export const PATIENT_LIST_BY_TEAM_ID_UPDATE_API_MSG = "PATIENT_LIST_BY_TEAM_ID_UPDATE_API_MSG";
export const PATIENT_LIST_BY_TEAM_ID_UPDATE_API_RES = "PATIENT_LIST_BY_TEAM_ID_UPDATE_API_RES";

export interface GetPatientListByTeamIdState {
  getPatientListByTeamIdRes: GetPatientListByTeamIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientListByTeamIdLoading {
  type: typeof PATIENT_LIST_BY_TEAM_ID_LOADING;
  loading: boolean;
}
export interface GetPatientListByTeamIdSuccess {
  type: typeof PATIENT_LIST_BY_TEAM_ID_SUCCESS;
  payload: GetPatientListByTeamIdRes;
  successMsg: string;
}
export interface GetPatientListByTeamIdFail {
  type: typeof PATIENT_LIST_BY_TEAM_ID_FAIL;
  payload: GetPatientListByTeamIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientListByTeamIdUpdateAPIMsg {
  type: typeof PATIENT_LIST_BY_TEAM_ID_UPDATE_API_MSG;
  payload: GetPatientListByTeamIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientListByTeamIdUpdateAPIRes {
  type: typeof PATIENT_LIST_BY_TEAM_ID_UPDATE_API_RES;
  payload: GetPatientListByTeamIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientListByTeamIdActionTypes =
  | GetPatientListByTeamIdLoading
  | GetPatientListByTeamIdSuccess
  | GetPatientListByTeamIdFail
  | GetPatientListByTeamIdUpdateAPIMsg
  | GetPatientListByTeamIdUpdateAPIRes;
