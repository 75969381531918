import {
  ADD_REGISTRATION_REQUEST_DETAILS_FAIL,
  ADD_REGISTRATION_REQUEST_DETAILS_LOADING,
  ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS,
  ADD_REGISTRATION_REQUEST_DETAILS_API_MSG,
  ADD_REGISTRATION_REQUEST_DETAILS_API_RES,
  AddRegistrationRequestDetailsActionTypes,
} from "./ActionTypes";
import { AddRegistrationRequestDetailsDetails } from "./Model";


export const addRegistrationRequestDetailsLoadingAction = (
  loading: boolean,
): AddRegistrationRequestDetailsActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_DETAILS_LOADING,
    loading: loading,
  };
};

export const addRegistrationRequestDetailsSuccessAction = (
  addRegistrationRequestDetailsResponseResponse: AddRegistrationRequestDetailsDetails,
  successMsg: string,
): AddRegistrationRequestDetailsActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS,
    payload: addRegistrationRequestDetailsResponseResponse,
    successMsg: successMsg,
  };
};

export const addRegistrationRequestDetailsErrorAction = (
  addRegistrationRequestDetailsResponseResponse: AddRegistrationRequestDetailsDetails,
  errMsg: string,
  status: number,
): AddRegistrationRequestDetailsActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_DETAILS_FAIL,
    payload: addRegistrationRequestDetailsResponseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addRegistrationRequestDetailsAPIMsgAction = (
  addRegistrationRequestDetailsResponseResponse: AddRegistrationRequestDetailsDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddRegistrationRequestDetailsActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_DETAILS_API_MSG,
    payload: addRegistrationRequestDetailsResponseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addRegistrationRequestDetailsAPIResClearAction = (
  addRegistrationRequestDetailsResponseResponse: AddRegistrationRequestDetailsDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddRegistrationRequestDetailsActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_DETAILS_API_RES,
    payload: addRegistrationRequestDetailsResponseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
