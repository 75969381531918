import {
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES,
  GetDataCurationProblemTypeListActionTypes,
} from "./ActionTypes";
import { GetDataCurationProblemTypeListResponse } from "./Model";

export const getDataCurationProblemTypeListLoadingAction = (
  loading: boolean,
): GetDataCurationProblemTypeListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDataCurationProblemTypeListSuccessAction = (
  GetDataCurationProblemTypeListResponse: GetDataCurationProblemTypeListResponse,
  successMsg: string,
): GetDataCurationProblemTypeListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS,
    payload: GetDataCurationProblemTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDataCurationProblemTypeListErrorAction = (
  GetDataCurationProblemTypeListResponse: GetDataCurationProblemTypeListResponse,
  errMsg: string,
  status: number,
): GetDataCurationProblemTypeListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL,
    payload: GetDataCurationProblemTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDataCurationProblemTypeListUpdateAPIMsgAction = (
  GetDataCurationProblemTypeListResponse: GetDataCurationProblemTypeListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDataCurationProblemTypeListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG,
    payload: GetDataCurationProblemTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDataCurationProblemTypeListAPIResClearAction = (
  GetDataCurationProblemTypeListResponse: GetDataCurationProblemTypeListResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDataCurationProblemTypeListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES,
    payload: GetDataCurationProblemTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
