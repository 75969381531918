import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetPatientListByTeamIdActionTypes } from "./ActionTypes";
import { GetPatientListByTeamIdBody, GetPatientListByTeamIdRes } from "./Model";
import {
  getPatientListByTeamIdAPIResClearAction,
  getPatientListByTeamIdErrorAction,
  getPatientListByTeamIdLoadingAction,
  getPatientListByTeamIdSuccessAction,
  getPatientListByTeamIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "../../../../component/Utility";

let apiRes = {} as GetPatientListByTeamIdRes;
export const getPatientListByTeamIdApi = (
  payload: GetPatientListByTeamIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetPatientListByTeamIdActionTypes>) {
    dispatch(getPatientListByTeamIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/adminActions/patient_list_by_patientTeam_id?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientListByTeamIdLoadingAction(false));
        dispatch(
          getPatientListByTeamIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientListByTeamIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientListByTeamIdLoadingAction(false));
        dispatch(
          getPatientListByTeamIdErrorAction(
            {} as GetPatientListByTeamIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientListByTeamIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientListByTeamIdActionTypes>) {
    dispatch(
      getPatientListByTeamIdUpdateAPIMsgAction(
        apiRes as GetPatientListByTeamIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetPatientListByTeamIdAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientListByTeamIdActionTypes>) {
    dispatch(
      getPatientListByTeamIdAPIResClearAction({} as GetPatientListByTeamIdRes, "", "", 0),
    );
  };
};
