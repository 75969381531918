import { PatientImportConfigListValue } from "./Model";

// Patient Meeting Loading State
export const GET_PATIENT_IMPORT_CONFIG_LIST_LOADING = "GET_PATIENT_IMPORT_CONFIG_LIST_LOADING";
export const GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS = "GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS";
export const GET_PATIENT_IMPORT_CONFIG_LIST_FAIL = "GET_PATIENT_IMPORT_CONFIG_LIST_FAIL";
export const PATIENT_IMPORT_CONFIG_LIST = "PATIENT_IMPORT_CONFIG_LIST";

export interface PatientImportConfigListDetails {
  getPatientImportConfigListRes: PatientImportConfigListValue;
  loading: boolean;
  errRes: string;
}
export interface PatientImportConfigListLoading {
  type: typeof GET_PATIENT_IMPORT_CONFIG_LIST_LOADING;
}
export interface PatientImportConfigListSuccess {
  type: typeof GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS;
  payload: PatientImportConfigListValue;
  errRes: string;
}
export interface PatientImportConfigListFail {
  type: typeof GET_PATIENT_IMPORT_CONFIG_LIST_FAIL;
  errRes: string;
}

interface PatientImportConfigListAction {
  type: typeof PATIENT_IMPORT_CONFIG_LIST;
  payload: PatientImportConfigListValue;
  errRes: string;
}

export type getPatientImportConfigListActionTypes =
  | PatientImportConfigListAction
  | PatientImportConfigListLoading
  | PatientImportConfigListSuccess
  | PatientImportConfigListFail;
