import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetOrganizationRegistrationReqDetailsListActionTypes } from "./ActionTypes";
import { GetOrganizationRequestDetailsListBody, GetOrganizationRequestDetailsListRes } from "./Model";
import {
  getOrganizationRegistrationReqDetailsListAPIResClearAction,
  getOrganizationRegistrationReqDetailsListErrorAction,
  getOrganizationRegistrationReqDetailsListLoadingAction,
  getOrganizationRegistrationReqDetailsListSuccessAction,
  getOrganizationRegistrationReqDetailsListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetOrganizationRequestDetailsListRes;
export const getOrganizationRegistrationRequestDetailsList = (
  payload: GetOrganizationRequestDetailsListBody,
) => {
  return function (dispatch: Dispatch<GetOrganizationRegistrationReqDetailsListActionTypes>) {
    dispatch(getOrganizationRegistrationReqDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientOrganizationRegistrationDetail/registration_request_detail_list_patientOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getOrganizationRegistrationReqDetailsListLoadingAction(false));
        dispatch(
          getOrganizationRegistrationReqDetailsListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetOrganizationRequestDetailsListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Registration list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getOrganizationRegistrationReqDetailsListLoadingAction(false));
        dispatch(
          getOrganizationRegistrationReqDetailsListErrorAction(
            {} as GetOrganizationRequestDetailsListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateOrganizationRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch<GetOrganizationRegistrationReqDetailsListActionTypes>) {
    dispatch(
      getOrganizationRegistrationReqDetailsListUpdateAPIMsgAction(
        apiRes as GetOrganizationRequestDetailsListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearOrganizationRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch<GetOrganizationRegistrationReqDetailsListActionTypes>) {
    dispatch(
      getOrganizationRegistrationReqDetailsListAPIResClearAction(
        {} as GetOrganizationRequestDetailsListRes,
        "",
        "",
        0,
      ),
    );
  };
};
