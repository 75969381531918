import { OriginalPatientDemographicsDetailsRes } from "./Model";
export const ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_LOADING = "ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_LOADING";
export const ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS = "ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS";
export const ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_FAIL = "ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_FAIL";
export const ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG = "ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG";
export const ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES = "ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES";

export interface GetDuplicateDemographicsDetailsState {
  getOriginalDemographicsByPatientIdRes: OriginalPatientDemographicsDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDuplicateDemographicsDetailsLoading {
  type: typeof ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDuplicateDemographicsDetailsSuccess {
  type: typeof ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS;
  payload: OriginalPatientDemographicsDetailsRes;
  successMsg: string;
}
export interface GetDuplicateDemographicsDetailsFail {
  type: typeof ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_FAIL;
  payload: OriginalPatientDemographicsDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDuplicateDemographicsDetailsUpdateAPIMsg {
  type: typeof ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG;
  payload: OriginalPatientDemographicsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDuplicateDemographicsDetailsUpdateAPIRes {
  type: typeof ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES;
  payload: OriginalPatientDemographicsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OriginalPatientDemographicsDetailsActionTypes =
  | GetDuplicateDemographicsDetailsLoading
  | GetDuplicateDemographicsDetailsSuccess
  | GetDuplicateDemographicsDetailsFail
  | GetDuplicateDemographicsDetailsUpdateAPIMsg
  | GetDuplicateDemographicsDetailsUpdateAPIRes;
