import {
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES,
    CreateHealthCampByOrganizationListActionTypes,
  } from "./ActionTypes";
  import { CreateHealthCampByOrganizationRes } from "./Model";
  
  export const createHealthCampByOrganizationLoadingAction = (
    loading: boolean
  ): CreateHealthCampByOrganizationListActionTypes => {
    return {
      type: CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const createHealthCampByOrganizationListSuccessAction = (
    createHealthCampByOrganizationListResponse: CreateHealthCampByOrganizationRes,
    successMsg: string
  ): CreateHealthCampByOrganizationListActionTypes => {
    return {
      type: CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS,
      payload: createHealthCampByOrganizationListResponse,
      successMsg: successMsg,
    };
  };
  
  export const createHealthCampByOrganizationListErrorAction = (
    createHealthCampByOrganizationListResponse: CreateHealthCampByOrganizationRes,
    errMsg: string,
    status: number
  ): CreateHealthCampByOrganizationListActionTypes => {
    return {
      type: CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL,
      payload: createHealthCampByOrganizationListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const createHealthCampByOrganizationListUpdateAPIMsgAction = (
    createHealthCampByOrganizationListResponse: CreateHealthCampByOrganizationRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): CreateHealthCampByOrganizationListActionTypes => {
    return {
      type: CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG,
      payload: createHealthCampByOrganizationListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const createHealthCampByOrganizationAPIResClearAction = (
    createHealthCampByOrganizationListResponse: CreateHealthCampByOrganizationRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): CreateHealthCampByOrganizationListActionTypes => {
    return {
      type: CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES,
      payload: createHealthCampByOrganizationListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  