import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import MWUploader from "component/MWUploader";
import {
  clearAddRegistrationRequestDetailsAPIRes,
  updateAddRegistrationRequestDetailsAPIResMsg,
} from "redux/HealthCampRegistrationRequestList/AddRegistrationRequestDetails/API";
import { OrganizationAddRegistrationRequestDetailsBody } from "redux/OrganizationAddRegistrationRequestDetails/Model";
import { organizationAddRegistrationRequestDetailsApiCall } from "redux/OrganizationAddRegistrationRequestDetails/API";
import { useParams } from "react-router-dom";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  healthcampid: string;
  requestid: string;
};

const requesttype = [
  { label: "Text", value: 1 },
  { label: "Image", value: 2 },
];

export default function OrganizationAddRegistrationDetailsDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  //   healthcampid,
  requestid,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const addResponse = useSelector((state: AppState) => state.organizationAddRegistrationRequestDetails);
  const [textValue, setTextValue] = useState("");
  const [regRequestType, setRegRequestType] = useState<number | null>(requesttype[0].value);
  const relationValueFromChild = async (_selectedRelationValue: any, newvalue: any) => {
    setRegRequestType(newvalue.value);
  };
  const [imageId, setImageId] = useState("");
  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  // const delImgAction = (_passeVal: boolean) => {};
  const [delImgAction, setDelImgAction] = useState(false);
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearAddRegistrationRequestDetailsAPIRes());
    dispatch(updateAddRegistrationRequestDetailsAPIResMsg());
  };

  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const addRegRequestDetails = () => {
    if (delImgAction) {
      setDelImgAction(false);
    }
    if (requestid !== "") {
      let organizationAddRegRequestDetailsBody = {
        registrationRequestId: requestid,
        requestDetailsType: String(regRequestType),
        organizationId: organizationid!,
        requestDocumentId: imageId,
        description: textValue,
      } as OrganizationAddRegistrationRequestDetailsBody;
      dispatch(
        organizationAddRegistrationRequestDetailsApiCall(organizationAddRegRequestDetailsBody),
      );
    }
  };

  const imageUploadTypeValue = (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          value={textValue}
          label="Add Description"
          placeholder="Enter description"
          id="descriptionID"
          onChange={(event) => setTextValue(event.target.value)}
          fullWidth
          size="small"
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <MWUploader
          documentType="patient-image"
          criteria="Upload a file less than 50 mb."
          updatedDataState={updateDataStateFromChild}
          title={"Upload HealthCamp Patient Photo"}
          deleteImgAction={delImgAction}
          previewImageData={viewPreviewImageData}
        ></MWUploader>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {addResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Add Registration Details</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Autocomplete
                value={
                  requesttype.find((element: any) => element.value === regRequestType) || undefined
                }
                id="typeid"
                disableClearable={true}
                options={requesttype}
                onChange={(event: any, newValue: any) => {
                  relationValueFromChild(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Relationship" size="small" />
                )}
                getOptionLabel={(option) => `${option.label}`}
              />
            </Grid>
            {regRequestType === 1 ? (
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <TextField
                  value={textValue}
                  label="Add Description"
                  placeholder="Enter description"
                  id="descriptionID"
                  onChange={(event) => setTextValue(event.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
            ) : null}
            {regRequestType === 2 ? (
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                {imageUploadTypeValue}
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Button
                autoFocus
                disabled={checkImgUpload}
                onClick={() => addRegRequestDetails()}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
