import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { Box } from "@mui/material";
import { HearRateData } from "../Model";

type Props = {
  data?: HearRateData[] | any;
  type?: string | any;
  unit?: string | any;
};

export default function ObservationsTrends({ data, type, unit }: Props) {
  const [chartData, setChartData] = useState([] as HearRateData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);
  const [seriesRangeDataItems, setSeriesRangeDataItem] = useState({} as any);
  console.log("seriesRangeDataItems", seriesRangeDataItems);
  useEffect(() => {
    if (type !== undefined) {
      if (type === "Heart Rate") {
        setSeriesRangeDataItem({ value: 80, endValue: 72 });
      } else if (type === "Temperature") {
        setSeriesRangeDataItem({ value: 98.4, endValue: 0 });
      }
    }
  }, [type]);
  useEffect(() => {
    var root = am5.Root.new("sugarchart");

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      am5themes_Responsive.new(root),
    ]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }),
    );

    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("forceHidden", true);
    cursor.lineY.set("forceHidden", true);

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: unitt,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    xAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 0,
        text: "Months",
        fontSize: 16,
        fontWeight: "bold",
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.Color.fromString("#ffffff"),
      }),
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: `${type} Value`,
        fontSize: 16,
        fontWeight: "bold",
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.Color.fromString("#ffffff"),
      }),
    );

    // Add series
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "right",
          labelText: `${type}: {valueY}`,
        }),
        fill: am5.Color.fromString("#67b7dc"),
        stroke: am5.Color.fromString("#ff0000"),
      }),
    );

    series.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    });

    // Add scrollbar
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    // Set data
    series.data.setAll(chartData);
    console.log("chartData", chartData);

    let rangeDate = new Date();
    am5.time.add(rangeDate, "day", Math.round(series.dataItems.length / 2));

    //add series range
    let seriesRangeDataItem = yAxis.makeDataItem({
      value: seriesRangeDataItems.value,
      endValue: seriesRangeDataItems.endValue,
    });
    let seriesRange = series.createAxisRange(seriesRangeDataItem);
    seriesRange.fills!.template.setAll({
      visible: true,
      opacity: 0.2,
    });

    seriesRange.fills!.template.set("fill", am5.color(0x03fc17));
    seriesRange.strokes!.template.set("stroke", am5.color(0x03fc17));

    seriesRangeDataItem.get("grid")!.setAll({
      strokeOpacity: 1,
      visible: true,
      stroke: am5.color("#ffffff"),
      strokeDasharray: [2, 2],
    });

    seriesRangeDataItem.get("label")!.setAll({
      location: 0,
      visible: true,
      text: "Normal",
      inside: true,
      centerX: 0,
      centerY: am5.p100,
      fontWeight: "bold",
      fill: am5.Color.fromString("#ffffff"),
    });

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, seriesRangeDataItems, unitt]);

  return <Box id="sugarchart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
