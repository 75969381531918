import { FilterPatientDetailsByObservationListRes } from "./Model";

// Patient Demographics Loading State
export const FILTER_PATIENT_DETAILS_BY_OBSERVATION_LOADING = "FILTER_PATIENT_DETAILS_BY_OBSERVATION_LOADING";
export const FILTER_PATIENT_DETAILS_BY_OBSERVATION_SUCCESS = "FILTER_PATIENT_DETAILS_BY_OBSERVATION_SUCCESS";
export const FILTER_PATIENT_DETAILS_BY_OBSERVATION_FAIL = "FILTER_PATIENT_DETAILS_BY_OBSERVATION_FAIL";
export const FILTER_PATIENT_DETAILS_BY_OBSERVATION_DATA = "FILTER_PATIENT_DETAILS_BY_OBSERVATION_DATA";
export const FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_MSG = "FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_MSG";
export const FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_RES = "FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_RES";

export interface FilterPatientDetailsByObservationState {
  filterPatientDetailsObservationRes: FilterPatientDetailsByObservationListRes;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface FilterPatientDetailsByObservationLoading {
  type: typeof FILTER_PATIENT_DETAILS_BY_OBSERVATION_LOADING;
  loading: boolean;
}
export interface FilterPatientDetailsByObservationSuccess {
  type: typeof FILTER_PATIENT_DETAILS_BY_OBSERVATION_SUCCESS;
  payload: FilterPatientDetailsByObservationListRes;
  successMsg: string;
}
export interface FilterPatientDetailsByObservationFail {
  type: typeof FILTER_PATIENT_DETAILS_BY_OBSERVATION_FAIL;
  payload: FilterPatientDetailsByObservationListRes;
  errorMsg: string;
  status: number;
}
export interface FilterPatientDetailsByObservationAPIMsg {
  type: typeof FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_MSG;
  payload: FilterPatientDetailsByObservationListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_RES;
  payload: FilterPatientDetailsByObservationListRes;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type FilterPatientDetailsByObservationActionTypes =
  | FilterPatientDetailsByObservationLoading
  | FilterPatientDetailsByObservationSuccess
  | FilterPatientDetailsByObservationFail
  | FilterPatientDetailsByObservationAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
