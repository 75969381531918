import {
  SymptomListActionTypes,
  GetPatientDemographicsState,
  SYMPTOM_SUCCESS,
  SYMPTOM_LOADING,
  SYMPTOM_FAIL,
} from "./ActionTypes";
import { SymptomListResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  templateList: {} as SymptomListResponse,
};
export const symptomListReducer = (
  state = initialStateGetPosts,
  action: SymptomListActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case SYMPTOM_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };
    case SYMPTOM_LOADING:
      return {
        ...state,
        loading: true,
        templateList: {} as SymptomListResponse,
      };
    case SYMPTOM_FAIL:
      return {
        ...state,
        templateList: {} as SymptomListResponse,
        loading: false,
      };
    default:
      return state;
  }
};
