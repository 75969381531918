import {
  ADMIN_TASK_LIST_CLEAR_API_RES,
  ADMIN_TASK_LIST_FAIL,
  ADMIN_TASK_LIST_LOADING,
  ADMIN_TASK_LIST_SUCCESS,
  ADMIN_TASK_LIST_API_MSG,
  AdminTaskListActionTypes,
} from "./ActionTypes";
import { AdminTaskListResponse } from "./Model";

export const adminTaskLoadingAction = (loading: boolean): AdminTaskListActionTypes => {
  return {
    type: ADMIN_TASK_LIST_LOADING,
    loading: loading,
  };
};

export const adminTaskSuccessAction = (
  adminTaskRes: AdminTaskListResponse,
): AdminTaskListActionTypes => {
  return {
    type: ADMIN_TASK_LIST_SUCCESS,
    payload: adminTaskRes,
  };
};

export const adminTaskErrorAction = (
  adminTaskRes: AdminTaskListResponse,
  errMsg: string,
  status: number,
): AdminTaskListActionTypes => {
  return {
    type: ADMIN_TASK_LIST_FAIL,
    payload: adminTaskRes,
    message: errMsg,
    status: status,
  };
};

export const adminTaskUpdateAPIMsgAction = (
  adminTaskRes: AdminTaskListResponse,
  errMsg: string,
  status: number,
): AdminTaskListActionTypes => {
  return {
    type: ADMIN_TASK_LIST_API_MSG,
    payload: adminTaskRes,
    message: errMsg,
    status: status,
  };
};

export const adminTaskAPIResClearAction = (
  adminTaskRes: AdminTaskListResponse,
  errMsg: string,
  status: number,
): AdminTaskListActionTypes => {
  return {
    type: ADMIN_TASK_LIST_CLEAR_API_RES,
    payload: adminTaskRes,
    message: errMsg,
    status: status,
  };
};
