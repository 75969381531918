import {
    ToggleActiveOrgRegistrationRequestActionTypes,
   TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL,
   TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING,
   TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS,
   ToggleActiveOrgRegistrationRequestState,
   TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG,
   TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES,
 } from "./ActionTypes";
 import { ToggleActiveOrgRegReqRes } from "./Model";
 
 const initialStateGetPosts: ToggleActiveOrgRegistrationRequestState = {
   loading: false,
   toggleActiveRegReqList: {} as ToggleActiveOrgRegReqRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const  toggleActiveOrgRegistrationRequestReducer = (
   state = initialStateGetPosts,
   action:  ToggleActiveOrgRegistrationRequestActionTypes,
 ): ToggleActiveOrgRegistrationRequestState => {
   switch (action.type) {
     case TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_SUCCESS:
       return {
         ...state,
         toggleActiveRegReqList: action.payload,
         successMsg: action.successMsg,
       };
     case TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_FAIL:
       return {
         ...state,
         toggleActiveRegReqList: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_MSG:
       return {
         ...state,
         toggleActiveRegReqList: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case TOGGLE_ACTIVE_ORG_REGISTRATION_REQUEST_UPDATE_API_RES:
       return {
         ...state,
         toggleActiveRegReqList: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 