import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { DeletePatientCaseActionTypes } from "./ActionTypes";
import { DeletePatientCaseBody, DeletePatientCaseRes } from "./Model";
import {
  deletePatientCaseAPIResClearAction,
  deletePatientCaseErrorAction,
  deletePatientCaseLoadingAction,
  deletePatientCaseSuccessAction,
  deletePatientCaseUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as DeletePatientCaseRes;
export const deletePatientCase = (payload: DeletePatientCaseBody) => {
  return function (dispatch: Dispatch<DeletePatientCaseActionTypes>) {
    dispatch(deletePatientCaseLoadingAction(true));
    axios
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/deactivate_patient_case`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(deletePatientCaseLoadingAction(false));
        dispatch(deletePatientCaseSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(deletePatientCaseLoadingAction(false));
        dispatch(
          deletePatientCaseErrorAction(
            {} as DeletePatientCaseRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDeletePatientCaseEditAPIResMsg = () => {
  return function (dispatch: Dispatch<DeletePatientCaseActionTypes>) {
    dispatch(deletePatientCaseUpdateAPIMsgAction(apiRes as DeletePatientCaseRes, "", 0));
  };
};

export const clearDeletePatientCaseEditAPIRes = () => {
  return function (dispatch: Dispatch<DeletePatientCaseActionTypes>) {
    dispatch(deletePatientCaseAPIResClearAction({} as DeletePatientCaseRes, "", "", 0));
  };
};
