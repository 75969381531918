import { InstanceListByDuplicatePatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_LOADING = "INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_LOADING";
export const INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS = "INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS";
export const INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_FAIL = "INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_FAIL";
export const INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG = "INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG";
export const INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_RES = "INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_RES";

export interface InstanceListByDuplicatePatientIdDetailsState {
  instanceListByDuplicatePatientIdRes: InstanceListByDuplicatePatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface InstanceListByDuplicatePatientIdLoading {
  type: typeof INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface InstanceListByDuplicatePatientIdSuccess {
  type: typeof INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS;
  payload: InstanceListByDuplicatePatientIdDetails;
  successMsg: string;
}
export interface InstanceListByDuplicatePatientIdFail {
  type: typeof INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_FAIL;
  payload: InstanceListByDuplicatePatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface InstanceListByDuplicatePatientIdAPIMsg {
  type: typeof INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG;
  payload: InstanceListByDuplicatePatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_RES;
  payload: InstanceListByDuplicatePatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type InstanceListByDuplicatePatientIdActionTypes =
  | InstanceListByDuplicatePatientIdLoading
  | InstanceListByDuplicatePatientIdSuccess
  | InstanceListByDuplicatePatientIdFail
  | InstanceListByDuplicatePatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
