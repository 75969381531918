import { Box, Container, Grid, Stack } from "@mui/material";
import MWPageTitle from "component/MWPageTitle";
import { useParams } from "react-router-dom";
import OriginalPatientDetailsView from "./OriginalPatientDetailsView";
import PatientDetailsOfDuplicateList from "./PatientDetailsOfDuplicateList";

export default function DuplicatePatientDetailsView() {
  const { patientoriginalid } = useParams() as {
    patientoriginalid: string;
  };
  const { patientduplicateid } = useParams() as {
    patientduplicateid: string;
  };

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle backButton={true} title={"Duplicate Patient Details"} />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <OriginalPatientDetailsView patientoriginalid={patientoriginalid} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <PatientDetailsOfDuplicateList patientduplicateid={patientduplicateid} />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
