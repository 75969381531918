import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import { useHistory } from "react-router-dom";
import AdminLoader from "pages/AdminLoader";
import { HealthcampPatientSearchListByObservationTypeBody } from "redux/HealthcampPatientSearchByObservationType/Model";
import {
  healthcampPatientSearchByObservationTypeApi,
  updateHealthcampSearchPatientByObservationTypeAPIResMsg,
} from "redux/HealthcampPatientSearchByObservationType/API";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  healthcampid?: string | any;
  obType: string | any;
  date: string | any;
};

export default function FilterHealthCampDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  healthcampid,
  obType,
  date,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(updateHealthcampSearchPatientByObservationTypeAPIResMsg());
  };

  const observationTypeList = useSelector((state: AppState) => state.getObservationTypeValue);
  const healthcampPatientListByObservationType = useSelector(
    (state: AppState) => state.healthCampPatientListByObsTypeValue,
  );
  const [patientObservationTypeList, setPatientObservationTypeList] = useState([] as any[]);
  const [selectedPatientObservatonType, setSelectedPatientObservatonType] =
    useState<ObservationTypeList | null>(null);
  const [startDate, setStartDate] = React.useState<Dayjs | null | string>(dayjs(new Date().toString()).subtract(1, "year").format("YYYY-MM-DD")!);
  const handleDateChange = (startDateValue: any) => {
    setStartDate(dayjs(startDateValue));
  };
  const patientObservationTypeChange = (
    _selectedObservationVal: any,
    newvalue: ObservationTypeList,
  ) => {
    setSelectedPatientObservatonType(newvalue);
  };
  useEffect(() => {
    if (obType !== null && date !== null) {
      const words = date.split("T");
      if (observationTypeList.GetObservationTypeResponse.observationTypeList !== undefined) {
        let result = observationTypeList.GetObservationTypeResponse.observationTypeList.find(
          (obj) => {
            return obj.name === obType;
          },
        );
        setSelectedPatientObservatonType(result!);
      }
      setStartDate(words[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obType, date]);
  useEffect(() => {
    if (observationTypeList?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      setPatientObservationTypeList(
        observationTypeList?.GetObservationTypeResponse?.observationTypeList,
      );
    } else setPatientObservationTypeList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationTypeList]);

  useEffect(() => {
    if (healthcampPatientListByObservationType?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampPatientListByObservationType]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const filterPatientByObsType = () => {
    history.push(
      `${
        window.location.pathname +
        "?obType=" +
        selectedPatientObservatonType?.name +
        "&date=" +
        dayjs(startDate).format("YYYY-MM-DD")
      }`,
    );
    if (selectedPatientObservatonType?.name !== null) {
      const PatientSearchByObservationBody = {
        patientObservationType: String(selectedPatientObservatonType?.name),
        healthCampId: healthcampid,
        startDate: startDate,
      } as HealthcampPatientSearchListByObservationTypeBody;
      dispatch(healthcampPatientSearchByObservationTypeApi(PatientSearchByObservationBody, 1));
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {healthcampPatientListByObservationType?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Filter Patient by Missing Observation</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                value={selectedPatientObservatonType}
                id="templateId"
                options={patientObservationTypeList}
                onChange={(event: any, newValue: any) => {
                  patientObservationTypeChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Filter by missing observation type"
                    size="small"
                  />
                )}
                getOptionLabel={(option) => `${option.name}`}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  label=" Start Date"
                  onChange={(date) => handleDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={filterPatientByObsType}
            disabled={selectedPatientObservatonType !== null ? false : true}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
