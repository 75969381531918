import {
  EDIT_DATA_CURATION_PROBLEM_FAIL,
  EDIT_DATA_CURATION_PROBLEM_LOADING,
  EDIT_DATA_CURATION_PROBLEM_SUCCESS,
  EDIT_DATA_CURATION_PROBLEM_API_MSG,
  EDIT_DATA_CURATION_PROBLEM_API_RES,
  EditDataCurationProblemActionTypes,
} from "./ActionTypes";
import { EditDataCurationProblemDetails } from "./Model";

export const editDataCurationProblemLoadingAction = (
  loading: boolean,
): EditDataCurationProblemActionTypes => {
  return {
    type: EDIT_DATA_CURATION_PROBLEM_LOADING,
    loading: loading,
  };
};

export const editDataCurationProblemSuccessAction = (
  editDataCurationProblemResponse: EditDataCurationProblemDetails,
  successMsg: string,
): EditDataCurationProblemActionTypes => {
  return {
    type: EDIT_DATA_CURATION_PROBLEM_SUCCESS,
    payload: editDataCurationProblemResponse,
    successMsg: successMsg,
  };
};

export const editDataCurationProblemErrorAction = (
  editDataCurationProblemResponse: EditDataCurationProblemDetails,
  errMsg: string,
  status: number,
): EditDataCurationProblemActionTypes => {
  return {
    type: EDIT_DATA_CURATION_PROBLEM_FAIL,
    payload: editDataCurationProblemResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDataCurationProblemAPIMsgAction = (
  editDataCurationProblemResponse: EditDataCurationProblemDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): EditDataCurationProblemActionTypes => {
  return {
    type: EDIT_DATA_CURATION_PROBLEM_API_MSG,
    payload: editDataCurationProblemResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDataCurationProblemAPIResClearAction = (
  editDataCurationProblemResponse: EditDataCurationProblemDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): EditDataCurationProblemActionTypes => {
  return {
    type: EDIT_DATA_CURATION_PROBLEM_API_RES,
    payload: editDataCurationProblemResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
