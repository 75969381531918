import { GetOrganizationDeactiveStaffListRes } from "./Model";
export const ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING = "ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING";
export const ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS = "ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS";
export const ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL = "ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL";
export const ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG = "ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG";
export const ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES = "ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES";

export interface GetOrganizationDeactiveStaffListState {
  getOrganizationDeactiveStaffList: GetOrganizationDeactiveStaffListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationDeactiveStaffListLoading {
  type: typeof ORGANIZATION_DEACTIVE_STAFF_LIST_LOADING;
  loading: boolean;
}
export interface GetOrganizationDeactiveStaffListSuccess {
  type: typeof ORGANIZATION_DEACTIVE_STAFF_LIST_SUCCESS;
  payload: GetOrganizationDeactiveStaffListRes;
  successMsg: string;
}
export interface GetOrganizationDeactiveStaffListFail {
  type: typeof ORGANIZATION_DEACTIVE_STAFF_LIST_FAIL;
  payload: GetOrganizationDeactiveStaffListRes;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationDeactiveStaffListUpdateAPIMsg {
  type: typeof ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG;
  payload: GetOrganizationDeactiveStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationDeactiveStaffListUpdateAPIRes {
  type: typeof ORGANIZATION_DEACTIVE_STAFF_LIST_UPDATE_API_RES;
  payload: GetOrganizationDeactiveStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetOrganizationDeactiveStaffListActionTypes =
  | GetOrganizationDeactiveStaffListLoading
  | GetOrganizationDeactiveStaffListSuccess
  | GetOrganizationDeactiveStaffListFail
  | GetOrganizationDeactiveStaffListUpdateAPIMsg
  | GetOrganizationDeactiveStaffListUpdateAPIRes;
