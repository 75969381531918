import {
  ADD_DUPLICATE_PATIENT_DATA,
  ADD_DUPLICATE_PATIENT_FAIL,
  ADD_DUPLICATE_PATIENT_LOADING,
  ADD_DUPLICATE_PATIENT_SUCCESS,
  ADD_DUPLICATE_PATIENT_API_MSG,
  ADD_DUPLICATE_PATIENT_API_RES,
  AddDuplicatePatientActionTypes,
} from "./ActionTypes";
import { PageCount, AddDuplicatePatientDetails } from "./Model";

export const AddStaffOrganizationIdAction = (
  AddDuplicatePatientDetails: AddDuplicatePatientDetails,
  pageCount: PageCount,
): AddDuplicatePatientActionTypes => {
  return {
    type: ADD_DUPLICATE_PATIENT_DATA,
    payload: AddDuplicatePatientDetails,
    pageCount: pageCount,
  };
};

export const AddDuplicatePatientLoadingAction = (
  loading: boolean,
): AddDuplicatePatientActionTypes => {
  return {
    type: ADD_DUPLICATE_PATIENT_LOADING,
    loading: loading,
  };
};

export const AddDuplicatePatientSuccessAction = (
  AddStaffResponse: AddDuplicatePatientDetails,
  successMsg: string,
): AddDuplicatePatientActionTypes => {
  return {
    type: ADD_DUPLICATE_PATIENT_SUCCESS,
    payload: AddStaffResponse,
    successMsg: successMsg,
  };
};

export const AddDuplicatePatientErrorAction = (
  AddStaffResponse: AddDuplicatePatientDetails,
  errMsg: string,
  status: number,
): AddDuplicatePatientActionTypes => {
  return {
    type: ADD_DUPLICATE_PATIENT_FAIL,
    payload: AddStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const AddDuplicatePatientAPIMsgAction = (
  AddStaffResponse: AddDuplicatePatientDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDuplicatePatientActionTypes => {
  return {
    type: ADD_DUPLICATE_PATIENT_API_MSG,
    payload: AddStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const AddDuplicatePatientAPIResClearAction = (
  AddStaffResponse: AddDuplicatePatientDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDuplicatePatientActionTypes => {
  return {
    type: ADD_DUPLICATE_PATIENT_API_RES,
    payload: AddStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
