import {
  GetSessionListActionTypes,
  SESSION_LIST_FAIL,
  SESSION_LIST_LOADING,
  SESSION_LIST_SUCCESS,
  GetSessionListState,
  SESSION_LIST_UPDATE_API_MSG,
  SESSION_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetSessionListRes } from "./Model";

const initialStateGetPosts: GetSessionListState = {
  loading: false,
  getSessionList: {} as GetSessionListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getSessionListByHealthCampIdReducer = (
  state = initialStateGetPosts,
  action: GetSessionListActionTypes,
): GetSessionListState => {
  switch (action.type) {
    case SESSION_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SESSION_LIST_SUCCESS:
      return {
        ...state,
        getSessionList: action.payload,
        successMsg: action.successMsg,
      };
    case SESSION_LIST_FAIL:
      return {
        ...state,
        getSessionList: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SESSION_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getSessionList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SESSION_LIST_UPDATE_API_RES:
      return {
        ...state,
        getSessionList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
