import { InactiveVitalCollectionListRes } from "./Model";
export const INACTIVEVITALCOLLECTION_LIST_LOADING = "INACTIVEVITALCOLLECTION_LIST_LOADING";
export const INACTIVEVITALCOLLECTION_LIST_SUCCESS = "INACTIVEVITALCOLLECTION_LIST_SUCCESS";
export const INACTIVEVITALCOLLECTION_LIST_FAIL = "INACTIVEVITALCOLLECTION_LIST_FAIL";
export const INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG = "INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG";
export const INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES = "INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES";

export interface InactiveVitalCOllectionListState {
  inactiveVitalCollectionListRes: InactiveVitalCollectionListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface InactiveVitalCOllectionListLoading {
  type: typeof INACTIVEVITALCOLLECTION_LIST_LOADING;
  loading: boolean;
}
export interface InactiveVitalCOllectionListSuccess {
  type: typeof INACTIVEVITALCOLLECTION_LIST_SUCCESS;
  payload: InactiveVitalCollectionListRes;
  successMsg: string;
}
export interface InactiveVitalCOllectionListFail {
  type: typeof INACTIVEVITALCOLLECTION_LIST_FAIL;
  payload: InactiveVitalCollectionListRes;
  errorMsg: string;
  status: number;
}
export interface InactiveVitalCOllectionListUpdateAPIMsg {
  type: typeof INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG;
  payload: InactiveVitalCollectionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface InactiveVitalCOllectionListUpdateAPIRes {
  type: typeof INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES;
  payload: InactiveVitalCollectionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type InactiveVitalCOllectionListActionTypes =
  | InactiveVitalCOllectionListLoading
  | InactiveVitalCOllectionListSuccess
  | InactiveVitalCOllectionListFail
  | InactiveVitalCOllectionListUpdateAPIMsg
  | InactiveVitalCOllectionListUpdateAPIRes;
