import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPatientCaseListBySessionIdActionTypes } from "./ActionTypes";
import { GetPatientCaseListBySessionIdBody, GetPatientCaseListBySessionIdRes } from "./Model";
import {
  getPatientCaseListBySessionIdAPIResClearAction,
  getPatientCaseListBySessionIdErrorAction,
  getPatientCaseListBySessionIdLoadingAction,
  getPatientCaseListBySessionIdSuccessAction,
  getPatientCaseListBySessionIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetPatientCaseListBySessionIdRes;
export const getPatientCaseListBySessionIdApi = (
  payload: GetPatientCaseListBySessionIdBody
) => {
  return function (dispatch: Dispatch<GetPatientCaseListBySessionIdActionTypes>) {
    dispatch(getPatientCaseListBySessionIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsessionpatientdetails/get_patientCase_list_by_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientCaseListBySessionIdLoadingAction(false));
        dispatch(
          getPatientCaseListBySessionIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientCaseListBySessionIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient case list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientCaseListBySessionIdLoadingAction(false));
        dispatch(
          getPatientCaseListBySessionIdErrorAction(
            {} as GetPatientCaseListBySessionIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientCaseListBySessionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientCaseListBySessionIdActionTypes>) {
    dispatch(
      getPatientCaseListBySessionIdUpdateAPIMsgAction(
        apiRes as GetPatientCaseListBySessionIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetPatientCaseListBySessionIdAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientCaseListBySessionIdActionTypes>) {
    dispatch(
      getPatientCaseListBySessionIdAPIResClearAction({} as GetPatientCaseListBySessionIdRes, "", "", 0),
    );
  };
};
