import {
  DOCTOR_SEARCH_FAIL,
  DOCTOR_SEARCH_LOADING,
  DOCTOR_SEARCH_SUCCESS,
  DoctorSearchActionTypes,
} from "./ActionTypes";
import { DoctorSearchDetails } from "./Model";


export const DoctorSearchLoadingAction = (loading: boolean): DoctorSearchActionTypes => {
  return {
    type: DOCTOR_SEARCH_LOADING,
    loading: loading,
  };
};

export const DoctorSearchSuccessAction = (
  DoctorSearchResponse: DoctorSearchDetails,
): DoctorSearchActionTypes => {
  return {
    type: DOCTOR_SEARCH_SUCCESS,
    payload: DoctorSearchResponse,
  };
};

export const DoctorSearchErrorAction = (
  DoctorSearchResponse: DoctorSearchDetails,
  errMsg: string,
  status: number,
): DoctorSearchActionTypes => {
  return {
    type: DOCTOR_SEARCH_FAIL,
    payload: DoctorSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const DoctorSearchUpdateAPIMsgAction = (
  DoctorSearchResponse: DoctorSearchDetails,
  errMsg: string,
  status: number,
): DoctorSearchActionTypes => {
  return {
    type: DOCTOR_SEARCH_FAIL,
    payload: DoctorSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const DoctorSearchAPIResClearAction = (
  DoctorSearchResponse: DoctorSearchDetails,
  errMsg: string,
  status: number,
): DoctorSearchActionTypes => {
  return {
    type: DOCTOR_SEARCH_FAIL,
    payload: DoctorSearchResponse,
    message: errMsg,
    status: status,
  };
};
