import {
  GET_APPOINTMENT_PAYMENT_STATUS_FAIL,
  GET_APPOINTMENT_PAYMENT_STATUS_LOADING,
  GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS,
  GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG,
  GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES,
  CheckAppointmentPaymentStatusActionTypes,
} from "./ActionTypes";
import { CheckAppointmentPaymentStatusRes } from "./Model";

export const checkAppointmentPaymentStatusLoadingAction = (
  loading: boolean,
): CheckAppointmentPaymentStatusActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_STATUS_LOADING,
    loading: loading,
  };
};

export const checkAppointmentPaymentStatusSuccessAction = (
  checkAppointmentPaymentStatusResponse: CheckAppointmentPaymentStatusRes,
  successMsg: string,
): CheckAppointmentPaymentStatusActionTypes => {
  console.log("checkAppointmentPaymentStatusResponse", checkAppointmentPaymentStatusResponse);
  return {
    type: GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS,
    payload: checkAppointmentPaymentStatusResponse,
    successMsg: successMsg,
  };
};

export const checkAppointmentPaymentStatusErrorAction = (
  checkAppointmentPaymentStatusResponse: CheckAppointmentPaymentStatusRes,
  errMsg: string,
  status: number,
): CheckAppointmentPaymentStatusActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_STATUS_FAIL,
    payload: checkAppointmentPaymentStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const checkAppointmentPaymentStatusUpdateAPIMsgAction = (
  checkAppointmentPaymentStatusResponse: CheckAppointmentPaymentStatusRes,
  successMsg: string,
  errMsg: string,
  status: number,
): CheckAppointmentPaymentStatusActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG,
    payload: checkAppointmentPaymentStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const checkAppointmentPaymentStatusAPIResClearAction = (
  checkAppointmentPaymentStatusResponse: CheckAppointmentPaymentStatusRes,
  successMsg: string,
  errMsg: string,
  status: number,
): CheckAppointmentPaymentStatusActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES,
    payload: checkAppointmentPaymentStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
