import { useImage } from "@lunit/insight-viewer";
import InsightViewer from "@lunit/insight-viewer";

type Props = {
  imageFile: string;
};
const style = {
  width: "700px",
  height: "500px",
};

export default function DicomViewer({ imageFile }: Props) {
  const { image } = useImage({
    dicomfile: `wadouri: ${imageFile}`,
  });

  return (
    <div style={style}>
      <InsightViewer image={image} />
    </div>
  );
}
