import {
  EDIT_PATIENT_TEAM_FAIL,
  EDIT_PATIENT_TEAM_LOADING,
  EDIT_PATIENT_TEAM_SUCCESS,
  EDIT_PATIENT_TEAM_API_MSG,
  EDIT_PATIENT_TEAM_CLEAR_API_RES,
  EditPatientTeamActionTypes,
} from "./ActionTypes";
import { EditPatientRes } from "./Model";

export const editPatientTeamLoadingAction = (loading: boolean): EditPatientTeamActionTypes => {
  return {
    type: EDIT_PATIENT_TEAM_LOADING,
    loading: loading,
  };
};

export const editPatientTeamSuccessAction = (
  editPatientTeameResponse: EditPatientRes,
  successMsg: string,
): EditPatientTeamActionTypes => {
  return {
    type: EDIT_PATIENT_TEAM_SUCCESS,
    payload: editPatientTeameResponse,
    successMsg: successMsg,
  };
};

export const editPatientTeamErrorAction = (
  editPatientTeameResponse: EditPatientRes,
  errMsg: string,
  status: number,
): EditPatientTeamActionTypes => {
  return {
    type: EDIT_PATIENT_TEAM_FAIL,
    payload: editPatientTeameResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editPatientTeamUpdateAPIMsgAction = (
  editPatientTeameResponse: EditPatientRes,
  successMsg: string,
  errMsg: string,
  status: number,
): EditPatientTeamActionTypes => {
  return {
    type: EDIT_PATIENT_TEAM_API_MSG,
    payload: editPatientTeameResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editPatientTeamAPIResClearAction = (
  editPatientTeameResponse: EditPatientRes,
  successMsg: string,
  errMsg: string,
  status: number,
): EditPatientTeamActionTypes => {
  return {
    type: EDIT_PATIENT_TEAM_CLEAR_API_RES,
    payload: editPatientTeameResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
