import {
  AddExistingPatientDetailsBySessionIdActionTypes,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS,
  AddExistingPatientDetailsBySessionIdState,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG,
  ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES,
} from "./ActionTypes";
import { AddExistingPatientDetailsBySessionIdDetails } from "./Model";

const initialStateGetPosts: AddExistingPatientDetailsBySessionIdState = {
  loading: false,
  addExistingPatientDetailsBySessionIdRes: {} as AddExistingPatientDetailsBySessionIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addExistingPatientDetailsBySessionIdReducer = (
  state = initialStateGetPosts,
  action: AddExistingPatientDetailsBySessionIdActionTypes,
): AddExistingPatientDetailsBySessionIdState => {
  switch (action.type) {
    case ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        addExistingPatientDetailsBySessionIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_FAIL:
      return {
        ...state,
        addExistingPatientDetailsBySessionIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_MSG:
      return {
        ...state,
        addExistingPatientDetailsBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXISTING_PATIENT_DETAILS_BY_SESSION_ID_API_RES:
      return {
        ...state,
        addExistingPatientDetailsBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
