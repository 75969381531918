import { GET_LIST } from "../../types/ActionTypes";
import { TemplateListResponse } from "./Model";

// Patient Demographics Loading State
export const TEMPLATE_LOADING = "TEMPLATE_LOADING";
export const TEMPLATE_SUCCESS = "TEMPLATE_SUCCESS";
export const TEMPLATE_FAIL = "TEMPLATE_FAIL";

export interface GetPatientDemographicsState {
  templateList: TemplateListResponse;
  loading: boolean;
}
export interface ExecutiveListLoading {
  type: typeof TEMPLATE_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof TEMPLATE_SUCCESS;
  payload: TemplateListResponse;
}
export interface ExecutiveListFail {
  type: typeof TEMPLATE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: TemplateListResponse;
}

export type TemplateListActionTypes =
  | GetDoctorAction
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail;
