import {
  ADD_EXECUTIVE_FAIL,
  ADD_EXECUTIVE_LOADING,
  ADD_EXECUTIVE_SUCCESS,
  ADD_EXECUTIVE_UPDATE_API_MSG,
  ADD_EXECUTIVE_UPDATE_API_RES,
  AddExecutiveActionTypes,
} from "./ActionTypes";
import { AddExecutiveRes } from "./Model";

export const addExecutiveLoadingAction = (loading: boolean): AddExecutiveActionTypes => {
  return {
    type: ADD_EXECUTIVE_LOADING,
    loading: loading,
  };
};

export const addExecutiveSuccessAction = (
  addExecutiveRes: AddExecutiveRes,
  successMsg: string,
): AddExecutiveActionTypes => {
  return {
    type: ADD_EXECUTIVE_SUCCESS,
    payload: addExecutiveRes,
    successMsg: successMsg,
  };
};

export const addExecutiveErrorAction = (
  addExecutiveRes: AddExecutiveRes,
  errMsg: string,
  status: number,
): AddExecutiveActionTypes => {
  return {
    type: ADD_EXECUTIVE_FAIL,
    payload: addExecutiveRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const addExecutiveUpdateAPIMsgAction = (
  addExecutiveRes: AddExecutiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExecutiveActionTypes => {
  return {
    type: ADD_EXECUTIVE_UPDATE_API_MSG,
    payload: addExecutiveRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addExecutiveAPIResClearAction = (
  addExecutiveRes: AddExecutiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExecutiveActionTypes => {
  return {
    type: ADD_EXECUTIVE_UPDATE_API_RES,
    payload: addExecutiveRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
