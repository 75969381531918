import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddRegistrationRequestDetailsActionTypes } from "./ActionTypes";
import { AddRegistrationRequestDetailsBody, AddRegistrationRequestDetailsDetails } from "./Model";
import {
  addRegistrationRequestDetailsAPIResClearAction,
  addRegistrationRequestDetailsErrorAction,
  addRegistrationRequestDetailsLoadingAction,
  addRegistrationRequestDetailsSuccessAction,
  addRegistrationRequestDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddRegistrationRequestDetailsDetails;
export const addRegistrationRequestDetailsApiCall = (payload: AddRegistrationRequestDetailsBody) => {
  return function (dispatch: Dispatch<AddRegistrationRequestDetailsActionTypes>) {
    dispatch(addRegistrationRequestDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampRegistrationDetail/add_registration_request_detail_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addRegistrationRequestDetailsLoadingAction(false));
        dispatch(
          addRegistrationRequestDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as AddRegistrationRequestDetailsDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Add registration request details successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addRegistrationRequestDetailsLoadingAction(false));
        dispatch(
          addRegistrationRequestDetailsErrorAction(
            {} as AddRegistrationRequestDetailsDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddRegistrationRequestDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<AddRegistrationRequestDetailsActionTypes>) {
    dispatch(addRegistrationRequestDetailsAPIMsgAction(apiRes as AddRegistrationRequestDetailsDetails, "", "", 0));
  };
};

export const clearAddRegistrationRequestDetailsAPIRes = () => {
  return function (dispatch: Dispatch<AddRegistrationRequestDetailsActionTypes>) {
    dispatch(addRegistrationRequestDetailsAPIResClearAction({} as AddRegistrationRequestDetailsDetails, "", "", 0));
  };
};
