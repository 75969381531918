import {
  GET_PHOTO_JUDGING_LIST_ID_DATA,
  GET_PHOTO_JUDGING_LIST_ID_FAIL,
  GET_PHOTO_JUDGING_LIST_ID_LOADING,
  GET_PHOTO_JUDGING_LIST_ID_SUCCESS,
  GetPhotoJudgingDetailsListActionTypes,
} from "./ActionTypes";
import { PageCount, GetPhotoJudgingDetailsListDetails } from "./Model";

export const getPhotoJudgingDetailsListAction = (
  GetPhotoJudgingDetailsListDetails: GetPhotoJudgingDetailsListDetails,
  pageCount: PageCount,
): GetPhotoJudgingDetailsListActionTypes => {
  return {
    type: GET_PHOTO_JUDGING_LIST_ID_DATA,
    payload: GetPhotoJudgingDetailsListDetails,
    pageCount: pageCount,
  };
};

export const getPhotoJudgingDetailsListLoadingAction = (
  loading: boolean,
): GetPhotoJudgingDetailsListActionTypes => {
  return {
    type: GET_PHOTO_JUDGING_LIST_ID_LOADING,
    loading: loading,
  };
};

export const getPhotoJudgingDetailsListSuccessAction = (
  getJudgingDetailsListResponse: GetPhotoJudgingDetailsListDetails,
): GetPhotoJudgingDetailsListActionTypes => {
  return {
    type: GET_PHOTO_JUDGING_LIST_ID_SUCCESS,
    payload: getJudgingDetailsListResponse,
  };
};

export const getPhotoJudgingDetailsListErrorAction = (
  getJudgingDetailsListResponse: GetPhotoJudgingDetailsListDetails,
  errMsg: string,
  status: number,
): GetPhotoJudgingDetailsListActionTypes => {
  return {
    type: GET_PHOTO_JUDGING_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
export const getPhotoJudgingDetailsListAPIMsgAction = (
  getJudgingDetailsListResponse: GetPhotoJudgingDetailsListDetails,
  errMsg: string,
  status: number,
): GetPhotoJudgingDetailsListActionTypes => {
  return {
    type: GET_PHOTO_JUDGING_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};

export const getPhotoJudgingDetailsListAPIResClearAction = (
  getJudgingDetailsListResponse: GetPhotoJudgingDetailsListDetails,
  errMsg: string,
  status: number,
): GetPhotoJudgingDetailsListActionTypes => {
  return {
    type: GET_PHOTO_JUDGING_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
