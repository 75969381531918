import { GetInstanceListRes } from "./Model";
export const INSTANCE_LIST_LOADING = "INSTANCE_LIST_LOADING";
export const INSTANCE_LIST_SUCCESS = "INSTANCE_LIST_SUCCESS";
export const INSTANCE_LIST_FAIL = "INSTANCE_LIST_FAIL";
export const INSTANCE_LIST_UPDATE_API_MSG = "INSTANCE_LIST_UPDATE_API_MSG";
export const INSTANCE_LIST_UPDATE_API_RES = "INSTANCE_LIST_UPDATE_API_RES";

export interface GetInstanceListState {
  getInstanceList: GetInstanceListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetInstanceListLoading {
  type: typeof INSTANCE_LIST_LOADING;
  loading: boolean;
}
export interface GetInstanceListSuccess {
  type: typeof INSTANCE_LIST_SUCCESS;
  payload: GetInstanceListRes;
  successMsg: string;
}
export interface GetInstanceListFail {
  type: typeof INSTANCE_LIST_FAIL;
  payload: GetInstanceListRes;
  errorMsg: string;
  status: number;
}
export interface GetInstanceListUpdateAPIMsg {
  type: typeof INSTANCE_LIST_UPDATE_API_MSG;
  payload: GetInstanceListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetInstanceListUpdateAPIRes {
  type: typeof INSTANCE_LIST_UPDATE_API_RES;
  payload: GetInstanceListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetInstanceListActionTypes =
  | GetInstanceListLoading
  | GetInstanceListSuccess
  | GetInstanceListFail
  | GetInstanceListUpdateAPIMsg
  | GetInstanceListUpdateAPIRes;
