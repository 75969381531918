import { ImportDataFromCallRes } from "./Model";
export const IMPORT_DATA_FROM_CALL_LOADING = "IMPORT_DATA_FROM_CALL_LOADING";
export const IMPORT_DATA_FROM_CALL_SUCCESS = "IMPORT_DATA_FROM_CALL_SUCCESS";
export const IMPORT_DATA_FROM_CALL_FAIL = "IMPORT_DATA_FROM_CALL_FAIL";
export const IMPORT_DATA_FROM_CALL_UPDATE_API_MSG = "IMPORT_DATA_FROM_CALL_UPDATE_API_MSG";
export const IMPORT_DATA_FROM_CALL_UPDATE_API_RES = "IMPORT_DATA_FROM_CALL_UPDATE_API_RES";

export interface ImportDataFromCallState {
  importDataFromCallRes: ImportDataFromCallRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ImportDataFromCallLoading {
  type: typeof IMPORT_DATA_FROM_CALL_LOADING;
  loading: boolean;
}
export interface ImportDataFromCallSuccess {
  type: typeof IMPORT_DATA_FROM_CALL_SUCCESS;
  payload: ImportDataFromCallRes;
  successMsg: string;
}
export interface ImportDataFromCallFail {
  type: typeof IMPORT_DATA_FROM_CALL_FAIL;
  payload: ImportDataFromCallRes;
  errorMsg: string;
  status: number;
}
export interface ImportDataFromCallUpdateAPIMsg {
  type: typeof IMPORT_DATA_FROM_CALL_UPDATE_API_MSG;
  payload: ImportDataFromCallRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ImportDataFromCallUpdateAPIRes {
  type: typeof IMPORT_DATA_FROM_CALL_UPDATE_API_RES;
  payload: ImportDataFromCallRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ImportDataFromCallActionTypes =
  | ImportDataFromCallLoading
  | ImportDataFromCallSuccess
  | ImportDataFromCallFail
  | ImportDataFromCallUpdateAPIMsg
  | ImportDataFromCallUpdateAPIRes;
