import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetPatientCaseDetailsByIdActionTypes } from "./ActionTypes";
import { GetPatientCaseDetailsByIdBody, GetPatientCaseDetailsByIdRes } from "./Model";
import {
  getPatientCaseDetailsByIdAPIResClearAction,
  getPatientCaseDetailsByIdErrorAction,
  getPatientCaseDetailsByIdLoadingAction,
  getPatientCaseDetailsByIdSuccessAction,
  getPatientCaseDetailsByIdUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as GetPatientCaseDetailsByIdRes;
export const getPatientCaseStatus = (payload: GetPatientCaseDetailsByIdBody) => {
  return function (dispatch: Dispatch<GetPatientCaseDetailsByIdActionTypes>) {
    dispatch(getPatientCaseDetailsByIdLoadingAction(true));
    axios
      .post(Url.baseUrl + `/patientapp/api/patient/patient_caseDetails_by_id`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientCaseDetailsByIdLoadingAction(false));
        dispatch(getPatientCaseDetailsByIdSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getPatientCaseDetailsByIdLoadingAction(false));
        dispatch(
          getPatientCaseDetailsByIdErrorAction(
            {} as GetPatientCaseDetailsByIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePatientCaseDetailsEditAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientCaseDetailsByIdActionTypes>) {
    dispatch(
      getPatientCaseDetailsByIdUpdateAPIMsgAction(apiRes as GetPatientCaseDetailsByIdRes, "", 0),
    );
  };
};

export const clearPatientCaseDetailsEditAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientCaseDetailsByIdActionTypes>) {
    dispatch(
      getPatientCaseDetailsByIdAPIResClearAction({} as GetPatientCaseDetailsByIdRes, "", "", 0),
    );
  };
};
