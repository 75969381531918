import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { UpdatePatientCaseStatusActionTypes } from "./ActionTypes";
import { UpdatePatientCaseStatusBody, UpdatePatientCaseStatusRes } from "./Model";
import {
  updatePatientCaseStatusAPIResClearAction,
  updatePatientCaseStatusErrorAction,
  updatePatientCaseStatusLoadingAction,
  updatePatientCaseStatusSuccessAction,
  updatePatientCaseStatusUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as UpdatePatientCaseStatusRes;
export const updatePatientCaseDetails = (payload: UpdatePatientCaseStatusBody) => {
  return function (dispatch: Dispatch<UpdatePatientCaseStatusActionTypes>) {
    dispatch(updatePatientCaseStatusLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/adminActions/update_patientCaseStatus`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(updatePatientCaseStatusLoadingAction(false));
        dispatch(
          updatePatientCaseStatusSuccessAction(res.data, "Case Status updated successfully"),
        );
      })
      .catch((error) => {
        dispatch(updatePatientCaseStatusLoadingAction(false));
        dispatch(
          updatePatientCaseStatusErrorAction(
            {} as UpdatePatientCaseStatusRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePatientCaseDetailsEditAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdatePatientCaseStatusActionTypes>) {
    dispatch(
      updatePatientCaseStatusUpdateAPIMsgAction(apiRes as UpdatePatientCaseStatusRes, "", "", 0),
    );
  };
};

export const clearPatientCaseDetailsEditAPIRes = () => {
  return function (dispatch: Dispatch<UpdatePatientCaseStatusActionTypes>) {
    dispatch(updatePatientCaseStatusAPIResClearAction({} as UpdatePatientCaseStatusRes, "", 0));
  };
};
