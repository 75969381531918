import {
    ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL,
    ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING,
    ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS,
    ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG,
    ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES,
    OrganizationToggleActiveRegistrationRequestDetailsActionTypes,
  } from "./ActionTypes";
  import { OrganizationToggleActiveRegReqDetailsRes } from "./Model";
  
  export const organizationToggleActiveRegistrationRequestDetailsLoadingAction = (
    loading: boolean,
  ): OrganizationToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING,
      loading: loading,
    };
  };
  
  export const organizationToggleActiveRegistrationRequestDetailsSuccessAction = (
    organizationToggleActiveRegReqDetailsList: OrganizationToggleActiveRegReqDetailsRes,
    successMsg: string,
  ): OrganizationToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS,
      payload: organizationToggleActiveRegReqDetailsList,
      successMsg: successMsg,
    };
  };
  
  export const organizationToggleActiveRegistrationRequestDetailsErrorAction = (
    organizationToggleActiveRegReqDetailsList: OrganizationToggleActiveRegReqDetailsRes,
    errMsg: string,
    status: number,
  ): OrganizationToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL,
      payload: organizationToggleActiveRegReqDetailsList,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const organizationToggleActiveRegistrationRequestDetailsUpdateAPIMsgAction = (
    organizationToggleActiveRegReqDetailsList: OrganizationToggleActiveRegReqDetailsRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): OrganizationToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG,
      payload: organizationToggleActiveRegReqDetailsList,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const organizationToggleActiveRegistrationRequestDetailsAPIResClearAction = (
    organizationToggleActiveRegReqDetailsList: OrganizationToggleActiveRegReqDetailsRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): OrganizationToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES,
      payload: organizationToggleActiveRegReqDetailsList,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  