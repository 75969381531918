import {
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_DATA,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING,
  ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS,
  OrganizationListByHealthCampIdActionTypes,
} from "./ActionTypes";
import { OrganizationListMappedWithHealthCampDetails } from "./Model";

export const organizationListMappeByHealthCampIdAction = (
  OrganizationListMappedWithHealthCampDetails: OrganizationListMappedWithHealthCampDetails,
): OrganizationListByHealthCampIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_HEALTHCAMP_ID_DATA,
    payload: OrganizationListMappedWithHealthCampDetails,
  };
};

export const organizationListMappeByHealthCampIdLoadingAction = (
  loading: boolean,
): OrganizationListByHealthCampIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING,
    loading: loading,
  };
};

export const organizationListMappeByHealthCampIdSuccessAction = (
  patientSearchResponse: OrganizationListMappedWithHealthCampDetails,
): OrganizationListByHealthCampIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS,
    payload: patientSearchResponse,
  };
};

export const organizationListMappeByHealthCampIdErrorAction = (
  patientSearchResponse: OrganizationListMappedWithHealthCampDetails,
  errMsg: string,
  status: number,
): OrganizationListByHealthCampIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const organizationListMappeByHealthCampIdUpdateAPIMsgAction = (
  patientSearchResponse: OrganizationListMappedWithHealthCampDetails,
  errMsg: string,
  status: number,
): OrganizationListByHealthCampIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const organizationListMappeByHealthCampIdAPIResClearAction = (
  patientSearchResponse: OrganizationListMappedWithHealthCampDetails,
  errMsg: string,
  status: number,
): OrganizationListByHealthCampIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
