import { DuplicateHealthcampListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING = "DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING";
export const DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS = "DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS";
export const DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL = "DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL";
export const DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG = "DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG";
export const DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES = "DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES";

export interface DuplicateHealthcampListByPatientIdDetailsState {
  duplicateHealthcampListByPatientIdRes: DuplicateHealthcampListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface DuplicateHealthcampListByPatientIdLoading {
  type: typeof DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface DuplicateHealthcampListByPatientIdSuccess {
  type: typeof DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS;
  payload: DuplicateHealthcampListByPatientIdDetails;
  successMsg: string;
}
export interface DuplicateHealthcampListByPatientIdFail {
  type: typeof DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL;
  payload: DuplicateHealthcampListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface DuplicateHealthcampListByPatientIdAPIMsg {
  type: typeof DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG;
  payload: DuplicateHealthcampListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof DUPLICATE_HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES;
  payload: DuplicateHealthcampListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type DuplicateHealthcampListByPatientIdActionTypes =
  | DuplicateHealthcampListByPatientIdLoading
  | DuplicateHealthcampListByPatientIdSuccess
  | DuplicateHealthcampListByPatientIdFail
  | DuplicateHealthcampListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
