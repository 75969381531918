import { AdminGetPatientTeamListActionTypes, ALL_PATIENT_TEAM_LIST } from "./ActionTypes";
import { AdminPatientTeamList, PatientTeamListPageCount } from "./Model";
// Get Patient Team List Action
export const getPatientTeamListAction = (
  patientTeamList: AdminPatientTeamList,
  patientListPageCount: PatientTeamListPageCount,
): AdminGetPatientTeamListActionTypes => {
  return {
    type: ALL_PATIENT_TEAM_LIST,
    payload: patientTeamList,
    patientListPageCount: patientListPageCount,
  };
};
