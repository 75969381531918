import { SessionListByDuplicatePatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING = "SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING";
export const SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS = "SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS";
export const SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL = "SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL";
export const SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG = "SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG";
export const SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES = "SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES";

export interface SessionListByDuplicatePatientIdDetailsState {
  sessionListByDuplicatePatientIdRes: SessionListByDuplicatePatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface SessionListByDuplicatePatientIdLoading {
  type: typeof SESSION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface SessionListByDuplicatePatientIdSuccess {
  type: typeof SESSION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS;
  payload: SessionListByDuplicatePatientIdDetails;
  successMsg: string;
}
export interface SessionListByDuplicatePatientIdFail {
  type: typeof SESSION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL;
  payload: SessionListByDuplicatePatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface SessionListByDuplicatePatientIdAPIMsg {
  type: typeof SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG;
  payload: SessionListByDuplicatePatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof SESSION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES;
  payload: SessionListByDuplicatePatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type SessionListByDuplicatePatientIdActionTypes =
  | SessionListByDuplicatePatientIdLoading
  | SessionListByDuplicatePatientIdSuccess
  | SessionListByDuplicatePatientIdFail
  | SessionListByDuplicatePatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
