import { PageCount, DeactiveStaffOfHealthCampDetails } from "./Model";

// Patient Demographics Loading State
export const DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING = "DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING";
export const DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS = "DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS";
export const DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL = "DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL";
export const DEACTIVE_STAFF_OF_HEALTHCAMP_DATA = "DEACTIVE_STAFF_OF_HEALTHCAMP_DATA";
export const DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG = "DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG";
export const DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES = "DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES";

export interface DeactiveStaffOfHealthcampState {
  deactiveStaffOfHealthcampRes: DeactiveStaffOfHealthCampDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeactiveStaffOfHealthcampLoading {
  type: typeof DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING;
  loading: boolean;
}
export interface DeactiveStaffOfHealthcampSuccess {
  type: typeof DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS;
  payload: DeactiveStaffOfHealthCampDetails;
  successMsg: string;
}
export interface DeactiveStaffOfHealthcampFail {
  type: typeof DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL;
  payload: DeactiveStaffOfHealthCampDetails;
  errorMsg: string;
  status: number;
}
export interface DeactiveStaffOfHealthcampAPIMsg {
  type: typeof DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG;
  payload: DeactiveStaffOfHealthCampDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeactiveStaffOfHealthcampAPIRes {
  type: typeof DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES;
  payload: DeactiveStaffOfHealthCampDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface DeactiveStaffOfHealthcampDataAction {
  type: typeof DEACTIVE_STAFF_OF_HEALTHCAMP_DATA;
  payload: DeactiveStaffOfHealthCampDetails;
  pageCount: PageCount;
}

export type DeactiveStaffOfHealthCampActionTypes =
  | DeactiveStaffOfHealthcampDataAction
  | DeactiveStaffOfHealthcampLoading
  | DeactiveStaffOfHealthcampSuccess
  | DeactiveStaffOfHealthcampFail
  | DeactiveStaffOfHealthcampAPIMsg
  | DeactiveStaffOfHealthcampAPIRes;
