import {
  DUPLICATE_OBSERVATION_LIST_FAIL,
  DUPLICATE_OBSERVATION_LIST_LOADING,
  DUPLICATE_OBSERVATION_LIST_SUCCESS,
  DUPLICATE_OBSERVATION_LIST_API_MSG,
  DUPLICATE_OBSERVATION_LIST_API_RES,
  DuplicateObservationListActionTypes,
} from "./ActionTypes";
import { DuplicateObservationListDetails } from "./Model";

export const DuplicateObservationListLoadingAction = (
  loading: boolean,
): DuplicateObservationListActionTypes => {
  return {
    type: DUPLICATE_OBSERVATION_LIST_LOADING,
    loading: loading,
  };
};

export const DuplicateObservationListSuccessAction = (
  duplicateObservationListResponse: DuplicateObservationListDetails,
  successMsg: string,
): DuplicateObservationListActionTypes => {
  return {
    type: DUPLICATE_OBSERVATION_LIST_SUCCESS,
    payload: duplicateObservationListResponse,
    successMsg: successMsg,
  };
};

export const DuplicateObservationListErrorAction = (
  duplicateObservationListResponse: DuplicateObservationListDetails,
  errMsg: string,
  status: number,
): DuplicateObservationListActionTypes => {
  return {
    type: DUPLICATE_OBSERVATION_LIST_FAIL,
    payload: duplicateObservationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DuplicateObservationListAPIMsgAction = (
  duplicateObservationListResponse: DuplicateObservationListDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): DuplicateObservationListActionTypes => {
  return {
    type: DUPLICATE_OBSERVATION_LIST_API_MSG,
    payload: duplicateObservationListResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const DuplicateObservationListAPIResClearAction = (
  duplicateObservationListResponse: DuplicateObservationListDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): DuplicateObservationListActionTypes => {
  return {
    type: DUPLICATE_OBSERVATION_LIST_API_RES,
    payload: duplicateObservationListResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
