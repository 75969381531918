import {
  SessionListByPatientIdActionTypes,
  SESSION_LIST_BY_PATIENT_ID_FAIL,
  SESSION_LIST_BY_PATIENT_ID_LOADING,
  SESSION_LIST_BY_PATIENT_ID_SUCCESS,
  SessionListByPatientIdDetailsState,
  SESSION_LIST_BY_PATIENT_ID_API_MSG,
  SESSION_LIST_BY_PATIENT_ID_API_RES,
} from "./ActionTypes";
import { SessionListByPatientIdDetails } from "./Model";

const initialStateGetPosts: SessionListByPatientIdDetailsState = {
  loading: false,
  sessionListByPatientIdRes: {} as SessionListByPatientIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const sessionListByPatientIdReducer = (
  state = initialStateGetPosts,
  action: SessionListByPatientIdActionTypes,
): SessionListByPatientIdDetailsState => {
  switch (action.type) {
    case SESSION_LIST_BY_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: true,
        sessionListByPatientIdRes: {} as SessionListByPatientIdDetails,
      };
    case SESSION_LIST_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case SESSION_LIST_BY_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        sessionListByPatientIdRes: {} as SessionListByPatientIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SESSION_LIST_BY_PATIENT_ID_API_MSG:
      return {
        ...state,
        loading: false,
        sessionListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SESSION_LIST_BY_PATIENT_ID_API_RES:
      return {
        ...state,
        loading: false,
        sessionListByPatientIdRes: {} as SessionListByPatientIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
