import {
  ADMIN_SET_TASK_STATUS_FAIL,
  ADMIN_SET_TASK_STATUS_LOADING,
  ADMIN_SET_TASK_STATUS_SUCCESS,
  ADMIN_SET_TASK_STATUS_API_MSG,
  ADMIN_SET_TASK_STATUS_API_RES,
  AdminSetTaskStatusActionTypes,
} from "./ActionTypes";
import { AdminSetTaskStatusDetails } from "./Model";

export const adminSetTaskStatusLoadingAction = (
  loading: boolean,
): AdminSetTaskStatusActionTypes => {
  return {
    type: ADMIN_SET_TASK_STATUS_LOADING,
    loading: loading,
  };
};

export const adminSetTaskStatusSuccessAction = (
  adminSetTaskStatusResponse: AdminSetTaskStatusDetails,
  successMsg: string,
): AdminSetTaskStatusActionTypes => {
  return {
    type: ADMIN_SET_TASK_STATUS_SUCCESS,
    payload: adminSetTaskStatusResponse,
    successMsg: successMsg,
  };
};

export const adminSetTaskStatusErrorAction = (
  adminSetTaskStatusResponse: AdminSetTaskStatusDetails,
  errMsg: string,
  status: number,
): AdminSetTaskStatusActionTypes => {
  return {
    type: ADMIN_SET_TASK_STATUS_FAIL,
    payload: adminSetTaskStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const adminSetTaskStatusAPIMsgAction = (
  adminSetTaskStatusResponse: AdminSetTaskStatusDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AdminSetTaskStatusActionTypes => {
  return {
    type: ADMIN_SET_TASK_STATUS_API_MSG,
    payload: adminSetTaskStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const adminSetTaskStatusAPIResClearAction = (
  adminSetTaskStatusResponse: AdminSetTaskStatusDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AdminSetTaskStatusActionTypes => {
  return {
    type: ADMIN_SET_TASK_STATUS_API_RES,
    payload: adminSetTaskStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
