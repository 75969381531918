import { DuplicateObservationListDetails } from "./Model";

// Patient Demographics Loading State
export const DUPLICATE_OBSERVATION_LIST_LOADING = "DUPLICATE_OBSERVATION_LIST_LOADING";
export const DUPLICATE_OBSERVATION_LIST_SUCCESS = "DUPLICATE_OBSERVATION_LIST_SUCCESS";
export const DUPLICATE_OBSERVATION_LIST_FAIL = "DUPLICATE_OBSERVATION_LIST_FAIL";
export const DUPLICATE_OBSERVATION_LIST_DATA = "DUPLICATE_OBSERVATION_LIST_DATA";
export const DUPLICATE_OBSERVATION_LIST_API_MSG = "DUPLICATE_OBSERVATION_LIST_API_MSG";
export const DUPLICATE_OBSERVATION_LIST_API_RES = "DUPLICATE_OBSERVATION_LIST_API_RES";

export interface DuplicateObservationListDetailsState {
  duplicateObservationListRes: DuplicateObservationListDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface DuplicateObservationListLoading {
  type: typeof DUPLICATE_OBSERVATION_LIST_LOADING;
  loading: boolean;
}
export interface DuplicateObservationListSuccess {
  type: typeof DUPLICATE_OBSERVATION_LIST_SUCCESS;
  payload: DuplicateObservationListDetails;
  successMsg: string;
}
export interface DuplicateObservationListFail {
  type: typeof DUPLICATE_OBSERVATION_LIST_FAIL;
  payload: DuplicateObservationListDetails;
  errorMsg: string;
  status: number;
}
export interface DuplicateObservationListAPIMsg {
  type: typeof DUPLICATE_OBSERVATION_LIST_API_MSG;
  payload: DuplicateObservationListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof DUPLICATE_OBSERVATION_LIST_API_RES;
  payload: DuplicateObservationListDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type DuplicateObservationListActionTypes =
  | DuplicateObservationListLoading
  | DuplicateObservationListSuccess
  | DuplicateObservationListFail
  | DuplicateObservationListAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
