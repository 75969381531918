import {
 GET_COMPETITION_LIST_FAIL,
 GET_COMPETITION_LIST_LOADING,
 GET_COMPETITION_LIST_SUCCESS,
 GET_COMPETITION_LIST_API_MSG,
 GET_COMPETITION_LIST_API_RES,
  GetCompetitionListActionTypes,
 GET_COMPETITION_LIST_DATA,
} from "./ActionTypes";
import { GetCompetitionListRes, PageCount } from "./Model";

export const competitionListAction = (
  GetCompetitionListRes: GetCompetitionListRes,
  pageCount: PageCount,
): GetCompetitionListActionTypes => {
  return {
    type:GET_COMPETITION_LIST_DATA,
    payload: GetCompetitionListRes,
    pageCount: pageCount,
  };
};

export const getCompetitionListLoadingAction = (
  loading: boolean,
): GetCompetitionListActionTypes => {
  return {
    type:GET_COMPETITION_LIST_LOADING,
    loading: loading,
  };
};

export const getCompetitionListSuccessAction = (
  getCompetitionListResponse: GetCompetitionListRes,
): GetCompetitionListActionTypes => {
  return {
    type:GET_COMPETITION_LIST_SUCCESS,
    payload: getCompetitionListResponse,
  };
};

export const getCompetitionListErrorAction = (
  getCompetitionListResponse: GetCompetitionListRes,
  errMsg: string,
  status: number,
): GetCompetitionListActionTypes => {
  return {
    type:GET_COMPETITION_LIST_FAIL,
    payload: getCompetitionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getCompetitionListAPIMsgAction = (
  getCompetitionListResponse: GetCompetitionListRes,
  errMsg: string,
  status: number,
): GetCompetitionListActionTypes => {
  return {
    type:GET_COMPETITION_LIST_API_MSG,
    payload: getCompetitionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getCompetitionListAPIResClearAction = (
  getCompetitionListResponse: GetCompetitionListRes,
  errMsg: string,
  status: number,
): GetCompetitionListActionTypes => {
  return {
    type:GET_COMPETITION_LIST_API_RES,
    payload: getCompetitionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
