import { Route, Redirect } from "react-router-dom";
import SecureLS from "secure-ls";

export const PrivateRoute = ({ component: Component, access, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      var ls = new SecureLS({ encodingType: "aes" });
      const access = ls.get("access");
      if (!access) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: "/signin", state: { from: props.location } }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);
