import {
  GetPatientCaseDetailsByIdActionTypes,
  GET_PATIENT_CASE_DETAILS_BY_ID_FAIL,
  GET_PATIENT_CASE_DETAILS_BY_ID_LOADING,
  GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS,
  GetPatientCaseDetailsByIdState,
  GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG,
  GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPatientCaseDetailsByIdRes } from "./Model";

const initialStateGetPosts: GetPatientCaseDetailsByIdState = {
  loading: false,
  getPatientCaseDetailsByIdRes: {} as GetPatientCaseDetailsByIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientCaseDetailsByIdReducer = (
  state = initialStateGetPosts,
  action: GetPatientCaseDetailsByIdActionTypes,
): GetPatientCaseDetailsByIdState => {
  switch (action.type) {
    case GET_PATIENT_CASE_DETAILS_BY_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PATIENT_CASE_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        getPatientCaseDetailsByIdRes: action.payload,
      };
    case GET_PATIENT_CASE_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        getPatientCaseDetailsByIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        getPatientCaseDetailsByIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_CASE_DETAILS_BY_ID_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        getPatientCaseDetailsByIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
