import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Grid,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Stack,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DrawerList } from "../DrawerList";
import medicalWISDOM from "../../image/logo/appLogo.png";
import AdminLoader from "../../pages/AdminLoader";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { GetAdminDetailsRes } from "../../redux/jwtAdminLogIn/adminLoginDetails/Model";
import SecureLS from "secure-ls";
import { styled, useTheme } from "@mui/material/styles";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

type Props = {
  drawerState: boolean;
  updateDrawerStateFromAppBar: (value: boolean) => void;
  updateHoverState: (value: boolean) => void;
  updatePreferenceDrawerState: (value: boolean) => void;
  updatePreferenceDrawerVal: (value: string) => void;
};

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBars({
  drawerState,
  updateDrawerStateFromAppBar,
  updateHoverState,
  updatePreferenceDrawerState,
  updatePreferenceDrawerVal,
}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  var ls = new SecureLS({ encodingType: "aes" });
  const Token = ls.get("access");
  const adminDetails = ls.get("loginDetails");
  const userDetails = adminDetails.loginDetails as GetAdminDetailsRes;

  const logOutRes = useSelector((state: AppState) => state.logOutRes);
  useEffect(() => {
    dispatch(toggleAppBarLoading(logOutRes?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOutRes]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const appLoaderRes = useSelector((state: AppState) => state.appLoaderRes);
  useEffect(() => {
    setIsLoading(appLoaderRes?.loading);
  }, [appLoaderRes]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  useEffect(() => {
    drawerState !== isDrawerOpen ? setIsDrawerOpen(drawerState) : setIsDrawerOpen(isDrawerOpen);
    // setIsDrawerOpen(drawerState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerState]);

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
    updateHoverState(false);
  };

  useEffect(() => {
    updateDrawerStateFromAppBar(isDrawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {Token ? (
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme: any) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              px={1}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      edge="start"
                    >
                      {drawerState ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Link to={`/`} rel="noreferrer" style={{ textDecoration: "none" }}>
                      <img alt="" src={medicalWISDOM} height="50px" />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                {fullScreen ? (
                  <IconButton
                    color="inherit"
                    aria-describedby={id}
                    onClick={handleClick}
                    edge="end"
                  >
                    <MoreVertIcon />
                  </IconButton>
                ) : (
                  <Button aria-describedby={id} onClick={handleClick}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Avatar />
                      </Grid>
                      <Grid item>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography variant="body2" noWrap>
                            {userDetails?.user?.first_name !== undefined &&
                            userDetails?.user?.last_name !== undefined
                              ? userDetails?.user?.first_name + " " + userDetails?.user?.last_name
                              : null}
                          </Typography>
                          <Typography
                            variant="caption"
                            noWrap
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            {userDetails?.user?.email !== undefined
                              ? userDetails?.user?.email
                              : null}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Button>
                )}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <List dense>
                    <ListItem>
                      <ListItemButton
                        onClick={() => {
                          updatePreferenceDrawerState(true);
                          updatePreferenceDrawerVal("0");
                          handleClose();
                          history.push(`${location.pathname + "?preferences"}`);
                        }}
                      >
                        <ListItemIcon>{DrawerList[22]!.icon!}</ListItemIcon>
                        <ListItemText>
                          <Typography>Preference</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemButton
                        onClick={() => {
                          updatePreferenceDrawerState(true);
                          updatePreferenceDrawerVal("1");
                          handleClose();
                          history.push(`${location.pathname + "?admindetails"}`);
                        }}
                      >
                        <ListItemIcon>{DrawerList[23]!.icon!}</ListItemIcon>
                        <ListItemText>
                          <Typography>User Profile</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemButton
                        onClick={() => {
                          updatePreferenceDrawerState(false);
                          handleClose();
                          history.push("/delete-account");
                        }}
                      >
                        <ListItemIcon>{DrawerList[24]!.icon!}</ListItemIcon>
                        <ListItemText>
                          <Typography>Settings</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </Grid>
            </Grid>
          </Toolbar>
          {loadingMarkup}
        </AppBar>
      ) : null}
    </Box>
  );
}
