import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddExecutiveSignupRequestActionTypes } from "./ActionTypes";
import { AddExecutiveSignupRequestBody, AddExecutiveSignupRequestRes } from "./Model";
import {
  addExecutiveSignupRequestAPIResClearAction,
  addExecutiveSignupRequestErrorAction,
  addExecutiveSignupRequestLoadingAction,
  addExecutiveSignupRequestSuccessAction,
  addExecutiveSignupRequestAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as AddExecutiveSignupRequestRes;
export const addExecutiveSignupRequestApi = (
  payload: AddExecutiveSignupRequestBody,
) => {
  return function (dispatch: Dispatch<AddExecutiveSignupRequestActionTypes>) {
    dispatch(addExecutiveSignupRequestLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/executiveSignupRequest/add_executive_signup_request`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addExecutiveSignupRequestLoadingAction(false));
        dispatch(addExecutiveSignupRequestSuccessAction(res.data !== undefined ? res.data : ({} as AddExecutiveSignupRequestRes),
        res.data.message !== undefined
          ? res.data.message
          : "Executive signup request successfully",));
      })
      .catch((error) => {
        dispatch(addExecutiveSignupRequestLoadingAction(false));
        dispatch(
          addExecutiveSignupRequestErrorAction(
            {} as AddExecutiveSignupRequestRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateaddExecutiveSignupRequestAPIResMsg = () => {
  return function (dispatch: Dispatch<AddExecutiveSignupRequestActionTypes>) {
    dispatch(
      addExecutiveSignupRequestAPIMsgAction(
        apiRes as AddExecutiveSignupRequestRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearAddExecutiveSignupRequestAPIRes = () => {
  return function (dispatch: Dispatch<AddExecutiveSignupRequestActionTypes>) {
    dispatch(
      addExecutiveSignupRequestAPIResClearAction(
        {} as AddExecutiveSignupRequestRes,
        "",
        "",
        0,
      ),
    );
  };
};
