import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { Box } from "@mui/material";
import { HearRateData } from "../Model";

type Props = {
  data?: HearRateData[] | any;
  unit?: string | any;
};

export default function HeartRateTrends({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as HearRateData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);

  useEffect(() => {
    var root = am5.Root.new("heartratechart");

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy",
      dateFields: ["valueX"],
    });

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }),
    );

    // Create axes
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.5,
        groupData: false,
        baseInterval: {
          timeUnit: unitt,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: "zoom",
          minGridDistance: 50,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom" }),
      }),
    );

    // Add series
    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        fill: am5.Color.fromString("#00ddff"),
        stroke: am5.Color.fromString("#00ddff"),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "Heart Rate: {valueY}",
        }),
      }),
    );

    // Set up data processor to parse string dates
    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"],
    });

    series.data.setAll(data);

    series.bullets.push(function () {
      var circle = am5.Circle.new(root, {
        radius: 4,
        //   fill: series.get("fill"),
        //   stroke: root.interfaceColors.get("background"),
        fill: am5.Color.fromString("#00ddff"),
        stroke: am5.Color.fromString("#00ddff"),
        strokeWidth: 2,
      });

      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    // Add cursor
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      }),
    );
    cursor.lineY.set("visible", false);

    // add scrollbar
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unitt]);

  return <Box id="heartratechart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
