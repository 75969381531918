import {
  GetOriganizationListActionTypes,
  GET_ORGANIZATION_LIST_FAIL,
  GET_ORGANIZATION_LIST_LOADING,
  GET_ORGANIZATION_LIST_SUCCESS,
  GetExtractObservationState,
  GET_ORGANIZATION_LIST_API_MSG,
  GET_ORGANIZATION_LIST_API_RES,
} from "./ActionTypes";
import { GetOrganizationListRes } from "./Model";

const initialStateUpdatePosts: GetExtractObservationState = {
  loading: false,
  getOrganizationRes: {} as GetOrganizationListRes,
  errorMsg: "",
  status: 0,
};
export const getOrganizationListReducer = (
  state = initialStateUpdatePosts,
  action: GetOriganizationListActionTypes,
): GetExtractObservationState => {
  switch (action.type) {
    case GET_ORGANIZATION_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        getOrganizationRes: action.payload,
      };
    case GET_ORGANIZATION_LIST_FAIL:
      return {
        ...state,
        getOrganizationRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_ORGANIZATION_LIST_API_MSG:
      return {
        ...state,
        getOrganizationRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_ORGANIZATION_LIST_API_RES:
      return {
        ...state,
        getOrganizationRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
