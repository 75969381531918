import {
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG,
  DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES,
  GetDoctorListByProviderOrganizationIdActionTypes,
} from "./ActionTypes";
import { GetDoctorListByProviderOrganizationIdRes } from "./Model";

export const getDoctorListByProviderOrganizationIdLoadingAction = (
  loading: boolean,
): GetDoctorListByProviderOrganizationIdActionTypes => {
  return {
    type: DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING,
    loading: loading,
  };
};

export const getDoctorListByProviderOrganizationIdSuccessAction = (
  getDoctorListByProviderOrganizationIdResponse: GetDoctorListByProviderOrganizationIdRes,
  successMsg: string,
): GetDoctorListByProviderOrganizationIdActionTypes => {
  return {
    type: DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS,
    payload: getDoctorListByProviderOrganizationIdResponse,
    successMsg: successMsg,
  };
};

export const getDoctorListByProviderOrganizationIdErrorAction = (
  getDoctorListByProviderOrganizationIdResponse: GetDoctorListByProviderOrganizationIdRes,
  errMsg: string,
  status: number,
): GetDoctorListByProviderOrganizationIdActionTypes => {
  return {
    type: DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL,
    payload: getDoctorListByProviderOrganizationIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorListByProviderOrganizationIdUpdateAPIMsgAction = (
  getDoctorListByProviderOrganizationIdResponse: GetDoctorListByProviderOrganizationIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorListByProviderOrganizationIdActionTypes => {
  return {
    type: DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG,
    payload: getDoctorListByProviderOrganizationIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorListByProviderOrganizationIdAPIResClearAction = (
  getDoctorListByProviderOrganizationIdResponse: GetDoctorListByProviderOrganizationIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorListByProviderOrganizationIdActionTypes => {
  return {
    type: DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES,
    payload: getDoctorListByProviderOrganizationIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
