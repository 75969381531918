import {
  PatientAddPointDataActionTypes,
  REGISTER_PATIENT_FAIL,
  REGISTER_PATIENT_LOADING,
  REGISTER_PATIENT_SUCCESS,
  RegisterPatientDataState,
  REGISTER_PATIENT_API_MSG,
  REGISTER_PATIENT_CLEAR_API_RES,
} from "./ActionTypes";
import { AddPatientRes } from "./Model";

const initialStateGetPosts: RegisterPatientDataState = {
  loading: false,
  addPatientRes: {} as AddPatientRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addHealthcampPatientReducer = (
  state = initialStateGetPosts,
  action: PatientAddPointDataActionTypes,
): RegisterPatientDataState => {
  switch (action.type) {
    case REGISTER_PATIENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case REGISTER_PATIENT_SUCCESS:
      return {
        ...state,
        addPatientRes: action.payload,
        successMsg: action.successMsg,
      };
    case REGISTER_PATIENT_FAIL:
      return {
        ...state,
        addPatientRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REGISTER_PATIENT_API_MSG:
      return {
        ...state,
        addPatientRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REGISTER_PATIENT_CLEAR_API_RES:
      return {
        ...state,
        addPatientRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
