import { GetVitalCollectionStatusRes } from "./Model";
export const GET_VITAL_COLLECTION_STATUS_LOADING = "GET_VITAL_COLLECTION_STATUS_LOADING";
export const GET_VITAL_COLLECTION_STATUS_SUCCESS = "GET_VITAL_COLLECTION_STATUS_SUCCESS";
export const GET_VITAL_COLLECTION_STATUS_FAIL = "GET_VITAL_COLLECTION_STATUS_FAIL";
export const GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG = "GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG";
export const GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES = "GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES";

export interface GetVitalCollectionStatusState {
  getVitalCollectionStatusRes: GetVitalCollectionStatusRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetVitalCollectionStatusLoading {
  type: typeof GET_VITAL_COLLECTION_STATUS_LOADING;
  loading: boolean;
}
export interface GetVitalCollectionStatusSuccess {
  type: typeof GET_VITAL_COLLECTION_STATUS_SUCCESS;
  payload: GetVitalCollectionStatusRes;
  successMsg: string;
}
export interface GetVitalCollectionStatusFail {
  type: typeof GET_VITAL_COLLECTION_STATUS_FAIL;
  payload: GetVitalCollectionStatusRes;
  errorMsg: string;
  status: number;
}
export interface GetVitalCollectionStatusUpdateAPIMsg {
  type: typeof GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG;
  payload: GetVitalCollectionStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetVitalCollectionStatusUpdateAPIRes {
  type: typeof GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES;
  payload: GetVitalCollectionStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetVitalCollectionStatusActionTypes =
  | GetVitalCollectionStatusLoading
  | GetVitalCollectionStatusSuccess
  | GetVitalCollectionStatusFail
  | GetVitalCollectionStatusUpdateAPIMsg
  | GetVitalCollectionStatusUpdateAPIRes;
