import {
    CreateHealthCampByOrganizationListActionTypes,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS,
    CreateHealthCampByOrganizationListState,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG,
    CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { CreateHealthCampByOrganizationRes } from "./Model";
  
  const initialStateGetPosts: CreateHealthCampByOrganizationListState = {
    loading: false,
    getDoctorWorkScheduleListRes: {} as CreateHealthCampByOrganizationRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const createHealthCampByOrganizationListReducer = (
    state = initialStateGetPosts,
    action: CreateHealthCampByOrganizationListActionTypes
  ): CreateHealthCampByOrganizationListState => {
    switch (action.type) {
      case CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS:
        return {
          ...state,
          getDoctorWorkScheduleListRes: action.payload,
          successMsg: action.successMsg,
        };
      case CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL:
        return {
          ...state,
          getDoctorWorkScheduleListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG:
        return {
          ...state,
          getDoctorWorkScheduleListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case CREATE_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES:
        return {
          ...state,
          getDoctorWorkScheduleListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  