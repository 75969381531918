import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DuplicatePatientDemographicsDetailsActionTypes } from "./ActionTypes";
import { DuplicatePatientDemographicsDetailsBody, DuplicatePatientDemographicsDetailsRes } from "./Model";
import {
  duplicatePatientDemographicsDetailsAPIResClearAction,
  duplicatePatientDemographicsDetailsErrorAction,
  duplicatePatientDemographicsDetailsLoadingAction,
  duplicatePatientDemographicsDetailsSuccessAction,
  duplicatePatientDemographicsDetailsUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as DuplicatePatientDemographicsDetailsRes;
export const getDuplicateDemographicsDetailsByPatientId = (payload: DuplicatePatientDemographicsDetailsBody) => {
  return function (dispatch: Dispatch<DuplicatePatientDemographicsDetailsActionTypes>) {
    dispatch(duplicatePatientDemographicsDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/patientapp/api/patient/get_patient_demographics_details`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(duplicatePatientDemographicsDetailsLoadingAction(false));
        dispatch(
          duplicatePatientDemographicsDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as DuplicatePatientDemographicsDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(duplicatePatientDemographicsDetailsLoadingAction(false));
        dispatch(
          duplicatePatientDemographicsDetailsErrorAction(
            {} as DuplicatePatientDemographicsDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDuplicateDemographicsDetailsByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<DuplicatePatientDemographicsDetailsActionTypes>) {
    dispatch(
      duplicatePatientDemographicsDetailsUpdateAPIMsgAction(apiRes as DuplicatePatientDemographicsDetailsRes, "", "", 0),
    );
  };
};

export const clearDuplicateDemographicsDetailsByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<DuplicatePatientDemographicsDetailsActionTypes>) {
    dispatch(duplicatePatientDemographicsDetailsAPIResClearAction({} as DuplicatePatientDemographicsDetailsRes, "", "", 0));
  };
};
