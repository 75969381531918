import {
  ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES,
  OrganizationAddRegistrationRequestActionTypes,
} from "./ActionTypes";
import { OrganizationAddRegistrationRequestDetails } from "./Model";


export const OrganizationAddRegistrationRequestLoadingAction = (
  loading: boolean,
): OrganizationAddRegistrationRequestActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING,
    loading: loading,
  };
};

export const OrganizationAddRegistrationRequestSuccessAction = (
  OrganizationAddRegistrationRequestRes: OrganizationAddRegistrationRequestDetails,
  successMsg: string,
): OrganizationAddRegistrationRequestActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS,
    payload: OrganizationAddRegistrationRequestRes,
    successMsg: successMsg,
  };
};

export const OrganizationAddRegistrationRequestErrorAction = (
  OrganizationAddRegistrationRequestRes: OrganizationAddRegistrationRequestDetails,
  errMsg: string,
  status: number,
): OrganizationAddRegistrationRequestActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL,
    payload: OrganizationAddRegistrationRequestRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const OrganizationAddRegistrationRequestAPIMsgAction = (
  OrganizationAddRegistrationRequestRes: OrganizationAddRegistrationRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): OrganizationAddRegistrationRequestActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG,
    payload: OrganizationAddRegistrationRequestRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const OrganizationAddRegistrationRequestAPIResClearAction = (
  OrganizationAddRegistrationRequestRes: OrganizationAddRegistrationRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): OrganizationAddRegistrationRequestActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES,
    payload: OrganizationAddRegistrationRequestRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
