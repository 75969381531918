import {
  EDIT_CASE_DATA,
  EDIT_CASE_FAIL,
  EDIT_CASE_LOADING,
  EDIT_CASE_SUCCESS,
  EditCaseActionTypes,
  EDIT_CASE_API_MSG,
  EDIT_CASE_API_RES,
} from "./ActionTypes";
import { EditCaseResponse } from "./Model";

export const editCaseAction = (
  editCaseRes: EditCaseResponse
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_DATA,
    payload: editCaseRes,
  };
};

export const editCaseLoadingAction = (loading: boolean): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_LOADING,
    loading: loading,
  };
};

export const editCaseSuccessAction = (
  editCaseRes: EditCaseResponse,
  successMsg: string,
  status: number
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_SUCCESS,
    payload: editCaseRes,
    successMsg: successMsg,
    status: status,
  };
};

export const editCaseErrorAction = (
  editCaseRes: EditCaseResponse,
  errMsg: string,
  status: number
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_FAIL,
    payload: editCaseRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const editCaseUpdateAPIMsgAction = (
  editCaseRes: EditCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_API_MSG,
    payload: editCaseRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editCaseAPIResClearAction = (
  editCaseRes: EditCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number
): EditCaseActionTypes => {
  return {
    type: EDIT_CASE_API_RES,
    payload: editCaseRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
