import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  AlertColor,
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { PatientDataBody } from "../../../redux/addHealthcampPatient/Model";
import {
  addHealthcampPatient,
  addPatientAPIResMsg,
  clearAddPatientAPIRes,
} from "../../../redux/addHealthcampPatient/API";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import MWUploader from "../../../component/MWUploader";
import MWErrorText from "../../../component/MWErrorText";
import AdminLoader from "../../AdminLoader";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { AgeCalcutale, DateCalcutale } from "../../../component/Utility";
import { getPreferenceDetails } from "redux/Preference/GetPreference/API";
import { clearAddPatientBySessionIdAPIRes } from "redux/HealthcampSessionList/AddPatientBySessionId/API";
import { clearAPIRes } from "redux/PatientSearchByHealcampId/API";
import MWSnackbar from "component/MWSnackbar";
import { clearOrganizationListAPIRes } from "redux/OrganizationList/API";
import { clearInstanceListByHealthCampIdAPIRes } from "redux/InstanceListOfHealthcamp /API";
import { clearSessionListByHealthCampIdAPIRes } from "redux/HealthcampSessionList/API";
import { clearHealthCampByOrganizationListAPIRes } from "redux/getMappedHealthcampsByOrganization/ApiCall";
import { registrationRequestDetailsListApiCall } from "redux/RegistrationRequestDetailsListByHealthcamp/API";
import { RegistrationRequestDetailsListBody } from "redux/RegistrationRequestDetailsListByHealthcamp/Model";
import { useHistory, useLocation } from "react-router-dom";
import { getHealthCampRegistrationRequestList } from "redux/HealthCampRegistrationRequestList/API";
import { GetHealthcampRequestListBody } from "redux/HealthCampRegistrationRequestList/Model";
import { updateAddRegistrationRequestAPIResMsg } from "redux/AddRegistrationRequestByHealthcampId/API";
import CheckIn from "component/CheckIn";

type Props = {
  itemId?: string | any;
  type?: string | any;
  addPatientDialogEnable?: boolean | any;
  addPatientDialogUpdateState?: boolean | any;
  regRequestId?: string | any;
};

const GenderValueList = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Other", value: 3 },
];

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface RequestOptionType {
  inputValue?: string;
  description: string;
}

export default function AddPatientDialog({
  itemId,
  addPatientDialogEnable,
  addPatientDialogUpdateState,
  type,
  regRequestId,
}: Props) {
  let query = useQuery();
  const requestId = query.get("requestId");
  const dispatch = useDispatch();
  const history = useHistory();
  const [imageId, setImageId] = useState("");
  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };
  // store call
  const addPatientValueRes = useSelector((state: AppState) => state.addHealthcampPatientData);
  const addPatientResponse = useSelector((state: AppState) => state.addPatientBySessionIdValues);
  const getTextValueForRegistration = useSelector(
    (state: AppState) => state.registrationRequestDetailsListValue,
  );
  const [disableDob, setDisableDob] = useState(false);
  const [disableAge, setDisableAge] = useState(true);
  const [enableAge, setAgeVisibility] = useState(false);
  const handleClickAge = () => {
    setAgeVisibility((show) => !show);
    setDisableAge((disableAge) => !disableAge);
    setDisableDob((disableDob) => !disableDob);
  };
  const handleMouseAgePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const closeBanner = (passedVal: boolean) => {
    setBannerState(passedVal);
  };
  const [addCheckInMsg, setAddCheckInMsg] = useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const addCheckInMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setAddCheckInMsg("");
    }
  };
  const [registrationRqsId, setRegistrationRqsId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [bannerState, setBannerState] = useState(false);
  const [firstName, setFirstName] = useState<RequestOptionType | null>(null);
  const [inputFirstNameValue, setInputFirstNameValue] = useState("");
  const [lastName, setLastName] = useState<RequestOptionType | null>(null);
  const [inputLastNameValue, setInputLastNameValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | null | any>(new Date(+0));
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const [selectedItem, setSelectedItem] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const handlePatienAgeChange = (age: string) => {
    setPatientAge(age);
    let ageRespectiveDate = DateCalcutale.yearCalculate(new Date().getFullYear(), Number(age));
    setSelectedDate(new Date(ageRespectiveDate));
  };
  const [patientPhone, setPatientPhone] = useState<RequestOptionType | null>(null);
  const [inputPatientPhoneValue, setInputPatientPhoneValue] = useState("");
  const [patientPincode, setPatientPincode] = useState<RequestOptionType | null>(null);
  const [inputPatientPincodeValue, setInputPatientPincodeValue] = useState("");
  const [patientDescription, setPatientDescription] = useState<RequestOptionType | null>(null);
  const [inputPatientDescriptionValue, setInputPatientDescriptionValue] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [strpatientGender, setStrPatientGender] = useState("");
  const [registrationImage, setRegistrationimage] = useState([] as any[]);
  const [delImgAction, setDelImgAction] = useState(false);

  const filterGenderType = (genderType: any) => {
    setPatientGender(genderType.value);
    setStrPatientGender(genderType.label);
  };
  const [dialogState, setDialogState] = useState(false);
  useEffect(() => {
    setDialogState(addPatientDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDialogEnable]);

  const handleDialogClose = () => {
    setDialogState(false);
    addPatientDialogUpdateState(false);
    setAddPatientSuccessMsg("");
    setPatientId(0);
    dispatch(addPatientAPIResMsg());
    dispatch(clearAddPatientAPIRes());
    dispatch(clearOrganizationListAPIRes());
    dispatch(clearHealthCampByOrganizationListAPIRes());
    dispatch(clearInstanceListByHealthCampIdAPIRes());
    dispatch(clearSessionListByHealthCampIdAPIRes());
    dispatch(updateAddRegistrationRequestAPIResMsg());
    if(type === "organization"){
      history.push(`/patientlistbyorganization/${selectedItem}`);
    }
    else if (type === "competition"){
      history.push(`/patientlistbycompetition/${selectedItem}`);
    }
    else{
    history.push(`/patientregistration/${itemId}`);
    }
  };

  const successMsgUpdateState = (updateValue: string) => {
    setAddPatientSuccessMsg(updateValue);
    addPatientDialogUpdateState(false);
  };
  const [addPatientSuccessMsg, setAddPatientSuccessMsg] = useState("");
  const [patientId, setPatientId] = useState(0);
  const [detailsValueArray, setDetailsValueArray] = useState([] as any[]);

  let addPatientBody = {
    // patientImageId: String(imageId)!,
    firstname: inputFirstNameValue,
    lastname: inputLastNameValue,
    dob: String(dayjs(selectedDate).format("DD/MM/YYYY")),
    phone: inputPatientPhoneValue,
    pincode: inputPatientPincodeValue,
    description: inputPatientDescriptionValue,
    gender: patientGender!,
    healthcampRegistrationRequestId: requestId,
  } as PatientDataBody;

  const clearFormData = () => {
    addPatientBody = {
      patientImageId: "",
      firstname: "",
      lastname: "",
      dob: "01/01/1970",
      phone: "",
      pincode: "",
      description: "",
      gender: "",
      healthcampRegistrationRequestId: "",
    };
    setInputFirstNameValue("");
    setInputLastNameValue("");
    setInputPatientPhoneValue("");
    setInputPatientPincodeValue("");
    setInputPatientDescriptionValue("");
    setImageId("");
    setDelImgAction((delImgAction) => !delImgAction);
    setPatientGender("");
    setStrPatientGender("");
  };

  const addPatientSubmit = () => {
    if (delImgAction) {
      setDelImgAction(false);
    }
    if (inputFirstNameValue === "" || inputFirstNameValue === null) {
      setErrorMsg("Please enter first name");
      setBannerState(true);
    } else if (inputLastNameValue === "" || inputLastNameValue === null) {
      setErrorMsg("Please enter last name");
      setBannerState(true);
    } else if (selectedDate === null) {
      setErrorMsg("Please enter dob");
      setBannerState(true);
    } else if (inputPatientPhoneValue === "" || inputPatientPhoneValue === null) {
      setErrorMsg("Please enter phone number");
      setBannerState(true);
    } else if (inputPatientPincodeValue === "" || inputPatientPincodeValue === null) {
      setErrorMsg("Please enter pincode");
      setBannerState(true);
    } else if (new RegExp("^[1-9][0-9]{5}$").test(inputPatientPincodeValue.toString()!) === null) {
      setErrorMsg("Please enter valid 6 digit pincode");
      setBannerState(true);
    } else if (patientGender === "" || patientGender === null) {
      setErrorMsg("Please select gender");
      setBannerState(true);
    } else {
      if (type === "organization") addPatientBody.organizationId = selectedItem;
      if (type === "healthcamp") addPatientBody.healthCampId = selectedItem;
      if (type === "competition") addPatientBody.competitionId = selectedItem;
      setErrorMsg("");
      setBannerState(false);
      dispatch(clearAddPatientAPIRes());
      if (imageId !== undefined) {
        dispatch(
          addHealthcampPatient(Object.assign(addPatientBody, { patientImageId: String(imageId)! })),
        );
      } else {
        dispatch(addHealthcampPatient(addPatientBody));
      }
    }
  };

  const addImageValue = (imageSelectedId: string) => {
    setImageId(imageSelectedId);
  };
  useEffect(() => {
    if (
      getTextValueForRegistration?.registrationRequestDetailsListRes
        ?.healthcampRegistrationRequestDetailList !== undefined &&
      getTextValueForRegistration?.registrationRequestDetailsListRes
        ?.healthcampRegistrationRequestDetailList.length > 0
    ) {
      let textRequestId =
        getTextValueForRegistration?.registrationRequestDetailsListRes
          ?.healthcampRegistrationRequestDetailList[0]!.registrationRequestId;
      setRegistrationRqsId(String(textRequestId));
      const textArray =
        getTextValueForRegistration?.registrationRequestDetailsListRes?.healthcampRegistrationRequestDetailList.map(
          (obj) => ({ id: obj.id, description: obj.description }),
          // arr1.map(v => ({ user: v.user, liked: v.liked }));
        );
      let dobValue =
        getTextValueForRegistration?.registrationRequestDetailsListRes?.healthcampRegistrationRequestDetailList.find(
          (obj) => dayjs(obj.description, "DD/MM/YYYY", true).isValid(),
        );
      var result =
        getTextValueForRegistration?.registrationRequestDetailsListRes?.healthcampRegistrationRequestDetailList.filter(
          (obj) => {
            return obj.requestDocument;
          },
        );
      setRegistrationimage(result);
      if (dobValue !== undefined) {
        setSelectedDate(dayjs(dobValue?.description, "DD/MM/YYYY"));
      } else {
        setSelectedDate("01/01/1970");
      }
      setDetailsValueArray(textArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTextValueForRegistration]);

  const getRegistrationReqDetails = () => {
    if (registrationRqsId !== undefined) {
      const getRequestDetailsBody = {
        registrationRequestId: requestId,
      } as RegistrationRequestDetailsListBody;
      dispatch(registrationRequestDetailsListApiCall(getRequestDetailsBody));
    }
  };

  useEffect(() => {
    setRegistrationRqsId(regRequestId);
  }, [regRequestId]);

  useEffect(() => {
    if (addPatientValueRes?.successMsg !== "") {
      setAddPatientSuccessMsg(addPatientValueRes?.successMsg);
      setPatientId(addPatientValueRes?.addPatientRes?.id);
      setFirstName(null);
      setLastName(null);
      setPatientPhone(null);
      setPatientPincode(null);
      setPatientDescription(null);
      setPatientGender("");
      setStrPatientGender("");
      setPatientAge(AgeCalcutale.ageCalculate(selectedDate));
      setSelectedDate("01/01/1970");
      dispatch(getPreferenceDetails());
      if(type === "organization"){
        history.push(`/patientlistbyorganization/${selectedItem}?section=mappedHealthcampList`);
      }
      else if (type === "competition"){
        history.push(`/patientlistbycompetition/${selectedItem}`);
      }
      else{
      history.push(`/patientregistration/${selectedItem}`);
      }
      clearFormData();
      dispatch(
        getHealthCampRegistrationRequestList({
          healthcampId: selectedItem,
        } as GetHealthcampRequestListBody),
      );
      // setDialogState(false);
    }
    if (addPatientValueRes?.errorMsg) {
      setErrorMsg(addPatientValueRes?.errorMsg);
      setBannerState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  useEffect(() => {
    setSelectedItem(itemId);
  }, [itemId]);

  useEffect(() => {
    if (selectedDate !== null) {
      setPatientAge(AgeCalcutale.ageCalculate(selectedDate));
    } else {
      setPatientAge("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (addPatientResponse?.successMsg !== "") {
      setAddCheckInMsg(addPatientResponse?.successMsg);
      dispatch(clearAPIRes());
    }
    if (addPatientResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setAddCheckInMsg(addPatientResponse?.errorMsg);
      dispatch(clearAddPatientBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientResponse]);

  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "40vh",
            minWidth: "70vw",
            overflow: "hidden",
          },
        }}
      >
        <Box>{addPatientValueRes?.loading ? <AdminLoader /> : null}</Box>
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>Add Patient</Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                {type !== "organization" && type !== "competition" ? (
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => getRegistrationReqDetails()}
                  >
                    Get Request Details
                  </Button>
                </Grid>
                 ) : null}
                <Grid item>
                  <IconButton aria-label="close" onClick={handleDialogClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} pt={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                size="small"
                fullWidth
                freeSolo
                id="first-name"
                disableClearable
                options={detailsValueArray}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="First Name"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    required
                  />
                )}
                value={firstName?.description !== null ? firstName?.description : null}
                onChange={(_event: any, newValue: string) => {
                  setFirstName({ description: newValue });
                }}
                inputValue={inputFirstNameValue !== undefined ? inputFirstNameValue : ""}
                onInputChange={(_event, newInputValue: string) => {
                  setInputFirstNameValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option.description}`}
                isOptionEqualToValue={(option: any, value) =>
                  option.description === value.description
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                size="small"
                fullWidth
                freeSolo
                id="last-name"
                disableClearable
                options={detailsValueArray}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Last Name"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    required
                  />
                )}
                value={lastName?.description !== null ? lastName?.description : null}
                onChange={(_event: any, newValue: string) => {
                  setLastName({ description: newValue });
                }}
                inputValue={inputLastNameValue !== undefined ? inputLastNameValue : ""}
                onInputChange={(_event, newInputValue: string) => {
                  setInputLastNameValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option.description}`}
                isOptionEqualToValue={(option: any, value) =>
                  option.description === value.description
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    views={["year", "month", "day"]}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        inputProps={{ ...params.inputProps }}
                        size="small"
                      />
                    )}
                    InputAdornmentProps={{ position: "start" }}
                    disabled={disableDob}
                    label="Dob"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                value={patientAge}
                id="age"
                label="Age"
                variant="outlined"
                onChange={(e) => {
                  handlePatienAgeChange(e.target.value);
                }}
                size="small"
                disabled={disableAge}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickAge}
                        onMouseDown={handleMouseAgePassword}
                        edge="end"
                      >
                        {enableAge ? <ToggleOnIcon /> : <ToggleOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                size="small"
                fullWidth
                freeSolo
                id="phone-number"
                disableClearable
                options={detailsValueArray}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Phone Number"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    required
                  />
                )}
                value={patientPhone?.description !== null ? patientPhone?.description : null}
                onChange={(_event: any, newValue: string) => {
                  setPatientPhone({ description: newValue });
                }}
                inputValue={inputPatientPhoneValue !== undefined ? inputPatientPhoneValue : ""}
                onInputChange={(_event, newInputValue: string) => {
                  setInputPatientPhoneValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option.description}`}
                isOptionEqualToValue={(option: any, value) =>
                  option.description === value.description
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                size="small"
                fullWidth
                freeSolo
                id="pin"
                disableClearable
                options={detailsValueArray}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pincode"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    required
                  />
                )}
                value={patientPincode?.description !== null ? patientPincode?.description : null}
                onChange={(_event: any, newValue: string) => {
                  setPatientPincode({ description: newValue });
                }}
                inputValue={inputPatientPincodeValue !== undefined ? inputPatientPincodeValue : ""}
                onInputChange={(_event, newInputValue: string) => {
                  setInputPatientPincodeValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option.description}`}
                isOptionEqualToValue={(option: any, value) =>
                  option.description === value.description
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                fullWidth
                id="gender"
                getOptionLabel={(option) => `${option.label}`}
                options={GenderValueList}
                onChange={(_event: any, newValue: any) => {
                  filterGenderType(newValue);
                }}
                inputValue={strpatientGender}
                renderInput={(params) => (
                  <TextField {...params} label="Gender" variant="outlined" required />
                )}
                size="small"
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                size="small"
                fullWidth
                freeSolo
                id="desc"
                disableClearable
                options={detailsValueArray}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Description"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
                value={
                  patientDescription?.description !== null ? patientDescription?.description : null
                }
                onChange={(_event: any, newValue: string) => {
                  setPatientDescription({ description: newValue });
                }}
                inputValue={
                  inputPatientDescriptionValue !== undefined ? inputPatientDescriptionValue : ""
                }
                onInputChange={(_event, newInputValue: string) => {
                  setInputPatientDescriptionValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option.description}`}
                isOptionEqualToValue={(option: any, value) =>
                  option.description === value.description
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 50 mb."
                updatedDataState={updateDataStateFromChild}
                // title={"Upload HealthCamp Patient Photo"}
                title={type !== "organization" && type !== "competition" ? "Upload HealthCamp Patient Photo" : "Upload Patient Photo"}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid></Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {registrationImage.map((imageItem: any) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Avatar
                        alt="Patient Image"
                        variant="square"
                        src={imageItem?.requestDocument.document}
                        sx={{
                          width: 130,
                          height: 130,
                          border:
                            imageId === imageItem?.requestDocument?.id ? "2px solid green" : "",
                        }}
                        onClick={() => addImageValue(imageItem?.requestDocument?.id)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box px={2}>
            <MWErrorText
              errormessage={errorMsg}
              bannerErrorState={bannerState}
              closeBannerState={closeBanner}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              {addCheckInMsg !== "" ? (
                <MWSnackbar
                  msg={addCheckInMsg}
                  type={linkImageToCompetitionMsgType}
                  alertClose={addCheckInMsgClose}
                />
              ) : null}
            </Grid>
            <Grid item>
              <Button
                onClick={addPatientSubmit}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
                disabled={checkImgUpload}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {addPatientSuccessMsg !== "" ? (
        <CheckIn
          addPatientSuccessMessage={addPatientSuccessMsg}
          patientId={patientId}
          successMsgUpdateState={successMsgUpdateState}
        />
      ) : null}
    </>
  );
}
