import {
  ADD_STAFF_OF_PROVIDER_ORG_DATA,
  ADD_STAFF_OF_PROVIDER_ORG_FAIL,
  ADD_STAFF_OF_PROVIDER_ORG_LOADING,
  ADD_STAFF_OF_PROVIDER_ORG_SUCCESS,
  ADD_STAFF_OF_PROVIDER_ORG_API_MSG,
  ADD_STAFF_OF_PROVIDER_ORG_API_RES,
  AddStaffOfProviderOrgActionTypes,
} from "./ActionTypes";
import { PageCount, AddStaffOfProviderOrgDetails } from "./Model";

export const addStaffOfProviderOrganizationIdAction = (
  AddStaffOfProviderOrgDetails: AddStaffOfProviderOrgDetails,
  pageCount: PageCount,
): AddStaffOfProviderOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_PROVIDER_ORG_DATA,
    payload: AddStaffOfProviderOrgDetails,
    pageCount: pageCount,
  };
};

export const addStaffOfProviderOrgLoadingAction = (
  loading: boolean,
): AddStaffOfProviderOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_PROVIDER_ORG_LOADING,
    loading: loading,
  };
};

export const addStaffOfProviderOrgSuccessAction = (
  addStaffOfProviderOrgResponse: AddStaffOfProviderOrgDetails,
  successMsg: string,
): AddStaffOfProviderOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_PROVIDER_ORG_SUCCESS,
    payload: addStaffOfProviderOrgResponse,
    successMsg: successMsg,
  };
};

export const addStaffOfProviderOrgErrorAction = (
  addStaffOfProviderOrgResponse: AddStaffOfProviderOrgDetails,
  errMsg: string,
  status: number,
): AddStaffOfProviderOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_PROVIDER_ORG_FAIL,
    payload: addStaffOfProviderOrgResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addStaffOfProviderOrgAPIMsgAction = (
  addStaffOfProviderOrgResponse: AddStaffOfProviderOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfProviderOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_PROVIDER_ORG_API_MSG,
    payload: addStaffOfProviderOrgResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addStaffOfProviderOrgAPIResClearAction = (
  addStaffOfProviderOrgResponse: AddStaffOfProviderOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfProviderOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_PROVIDER_ORG_API_RES,
    payload: addStaffOfProviderOrgResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
