import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetDataCurationProblemTypeListActionTypes } from "./ActionTypes";
import { GetDataCurationProblemTypeListResponse } from "./Model";
import {
  getDataCurationProblemTypeListAPIResClearAction,
  getDataCurationProblemTypeListErrorAction,
  getDataCurationProblemTypeListLoadingAction,
  getDataCurationProblemTypeListSuccessAction,
  getDataCurationProblemTypeListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDataCurationProblemTypeListResponse;
export const getDataCurationProblemTypeListDetails = () => {
  return function (dispatch: Dispatch<GetDataCurationProblemTypeListActionTypes>) {
    dispatch(getDataCurationProblemTypeListLoadingAction(true));
    axios
      .get(Url.baseUrl + `/curatorapp/api/dataCurationProblem/dataCurationProblemType_dropdown_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDataCurationProblemTypeListLoadingAction(false));
        dispatch(
          getDataCurationProblemTypeListSuccessAction(res.data, "Preferences has been fetched successfully"),
        );
      })
      .catch((error) => {
        dispatch(getDataCurationProblemTypeListLoadingAction(false));
        dispatch(
          getDataCurationProblemTypeListErrorAction(
            {} as GetDataCurationProblemTypeListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateGetDataCurationProblemTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDataCurationProblemTypeListActionTypes>) {
    dispatch(getDataCurationProblemTypeListUpdateAPIMsgAction(apiRes as GetDataCurationProblemTypeListResponse, "", "", 0));
  };
};

export const clearGetDataCurationProblemTypeListAPIRes = () => {
  return function (dispatch: Dispatch<GetDataCurationProblemTypeListActionTypes>) {
    dispatch(getDataCurationProblemTypeListAPIResClearAction({} as GetDataCurationProblemTypeListResponse, "", "", 0));
  };
};
