import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Card, CardContent, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActiveStaffList from "./ActiveStaffList";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeactiveStaffList from "./DeactiveStaffList";
import AdminList from "./AdminList";
import { clearStaffAdminListByProviderOrgIdAPIRes, getStaffAdminListByProviderOrgId } from "redux/ProviderOrganizationDetails/ProviderOrganizationAdminStaffList/API";
import { GetProviderOrgStaffAdminListBody } from "redux/ProviderOrganizationDetails/ProviderOrganizationAdminStaffList/Model";

type Props = {
  providerOrgStaffListLoader?: boolean | any;
};

export default function ViewProviderOrganizationStaffList({ providerOrgStaffListLoader }: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  // call Store
  // const viewProviderOrgStaffList = useSelector((state: AppState) => state.staffAdminList);

  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    dispatch(clearStaffAdminListByProviderOrgIdAPIRes())
    setSelected(newValue);
  };

  // const [activeAdminListCount, setActiveAdminListCount] = React.useState("");
  // const [activeListCount, setActiveListCount] = React.useState("");
  // const [deactiveListCount, setDeactiveListCount] = React.useState("");

  // useEffect(() => {
  //   if (viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length > 0) {
  //     setActiveAdminListCount(
  //       String(viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length),
  //     );
  //   } else setActiveAdminListCount("0");
  // }, [viewProviderOrgStaffList]);
  // useEffect(() => {
  //   if (viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length > 0) {
  //     setActiveListCount(
  //       String(viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length),
  //     );
  //   } else setActiveListCount("0");
  // }, [viewProviderOrgStaffList]);

  // useEffect(() => {
  //   if (viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length > 0) {
  //     setDeactiveListCount(
  //       String(viewProviderOrgStaffList?.getProviderOrgStaffAdminList?.StaffList.length),
  //     );
  //   } else setDeactiveListCount("0");
  // }, [viewProviderOrgStaffList]);

  const reloadAdminStaffListApi = () => {
    const activeListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 2,
    } as GetProviderOrgStaffAdminListBody;
    dispatch(getStaffAdminListByProviderOrgId(activeListBody, 1));
  };

  const reloadActiveStaffListApi = () => {
    const activeListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 1,
    } as GetProviderOrgStaffAdminListBody;
    dispatch(getStaffAdminListByProviderOrgId(activeListBody, 1));
  };

  const reloadDeactiveStaffListApi = () => {
    const patientListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 0,
    } as GetProviderOrgStaffAdminListBody;
    dispatch(getStaffAdminListByProviderOrgId(patientListBody, 1));
  };

  const StaffListOfOrganization = (
    <Card>
      <CardContent>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Admin List</Typography>
                      </Grid>
                      <Grid item>
                        {/* <Chip label={activeAdminListCount} variant="outlined" size="small" /> */}
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadAdminStaffListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Active List</Typography>
                      </Grid>
                      <Grid item>
                        {/* <Chip label={activeListCount} variant="outlined" size="small" /> */}
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadActiveStaffListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Inactive List</Typography>
                      </Grid>
                      <Grid item>
                        {/* <Chip label={deactiveListCount} variant="outlined" size="small" /> */}
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadDeactiveStaffListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <AdminList viewOrgStaffListloader={providerOrgStaffListLoader} />
            </TabPanel>
            <TabPanel value="2">
              <ActiveStaffList viewOrgStaffListloader={providerOrgStaffListLoader} />
            </TabPanel>
            <TabPanel value="3">
              <DeactiveStaffList viewOrgStaffListloader={providerOrgStaffListLoader} />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );

  return <Box>{StaffListOfOrganization}</Box>;
}
