import { AdminTaskStatusListRes } from "./Model";
export const ADMIN_TASK_STATUS_LIST_LOADING = "ADMIN_TASK_STATUS_LIST_LOADING";
export const ADMIN_TASK_STATUS_LIST_SUCCESS = "ADMIN_TASK_STATUS_LIST_SUCCESS";
export const ADMIN_TASK_STATUS_LIST_FAIL = "ADMIN_TASK_STATUS_LIST_FAIL";
export const ADMIN_TASK_STATUS_LIST_DATA = "ADMIN_TASK_STATUS_LIST_DATA";
export const ADMIN_TASK_STATUS_LIST_UPDATE_API_MSG = "ADMIN_TASK_STATUS_LIST_UPDATE_API_MSG";
export const ADMIN_TASK_STATUS_LIST_UPDATE_API_RES = "ADMIN_TASK_STATUS_LIST_UPDATE_API_RES";

export interface AdminTaskStatusListState {
  adminTaskStatusListRes: AdminTaskStatusListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AdminTaskStatusListLoading {
  type: typeof ADMIN_TASK_STATUS_LIST_LOADING;
  loading: boolean;
}
export interface AdminTaskStatusListSuccess {
  type: typeof ADMIN_TASK_STATUS_LIST_SUCCESS;
  payload: AdminTaskStatusListRes;
  successMsg: string;
}
export interface AdminTaskStatusListFail {
  type: typeof ADMIN_TASK_STATUS_LIST_FAIL;
  payload: AdminTaskStatusListRes;
  errorMsg: string;
  status: number;
}
export interface AdminTaskStatusListUpdateAPIMsg {
  type: typeof ADMIN_TASK_STATUS_LIST_UPDATE_API_MSG;
  payload: AdminTaskStatusListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AdminTaskStatusListUpdateAPIRes {
  type: typeof ADMIN_TASK_STATUS_LIST_UPDATE_API_RES;
  payload: AdminTaskStatusListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AdminTaskStatusListActionTypes =
  | AdminTaskStatusListLoading
  | AdminTaskStatusListSuccess
  | AdminTaskStatusListFail
  | AdminTaskStatusListUpdateAPIMsg
  | AdminTaskStatusListUpdateAPIRes;
