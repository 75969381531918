import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import { PatientSearchListByObservationTypeBody } from "redux/PatientSearchByObservationType/Model";
import {
  patientSearchByObservationTypeApi,
  updateSearchPatientByObservationTypeAPIResMsg,
} from "redux/PatientSearchByObservationType/API";
import MWDatePicker from "component/MWDatePicker";
import { useHistory } from "react-router-dom";
import AdminLoader from "pages/AdminLoader";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  organizationid?: string | any;
  obType: string | any;
  date: string | any;
};

export default function FilterDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  organizationid,
  obType,
  date,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(updateSearchPatientByObservationTypeAPIResMsg());
  };

  const observationTypeList = useSelector((state: AppState) => state.getObservationTypeValue);
  const patientListByObservationType = useSelector(
    (state: AppState) => state.patientListByobservationTypeValue,
  );
  const [patientObservationTypeList, setPatientObservationTypeList] = useState([] as any[]);
  const [selectedPatientObservatonType, setSelectedPatientObservatonType] =
    useState<ObservationTypeList | null>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const updateStartDateFromChild = (startDateValue: any) => {
    setStartDate(startDateValue);
  };
  const [fieldDisabled] = useState(false);
  const patientObservationTypeChange = (
    _selectedObservationVal: any,
    newvalue: ObservationTypeList,
  ) => {
    setSelectedPatientObservatonType(newvalue);
  };

  useEffect(() => {
    if (observationTypeList?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      setPatientObservationTypeList(
        observationTypeList?.GetObservationTypeResponse?.observationTypeList,
      );
    } else setPatientObservationTypeList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationTypeList]);

  useEffect(() => {
    if (patientListByObservationType?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientListByObservationType]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  useEffect(() => {
    if (obType !== null && date !== null) {
      const words = date.split("T");
      if (observationTypeList.GetObservationTypeResponse.observationTypeList !== undefined) {
        let result = observationTypeList.GetObservationTypeResponse.observationTypeList.find(
          (obj) => {
            return obj.name === obType;
          },
        );
        setSelectedPatientObservatonType(result!);
      }
      setStartDate(words[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obType, date]);

  const filterPatientByObsType = () => {
    history.push(
      `${
        window.location.pathname +
        "?obType=" +
        selectedPatientObservatonType?.name +
        "&date=" +
        encodeURIComponent(`${startDate}`)
      }`,
    );
    if (selectedPatientObservatonType?.name !== null) {
      const PatientSearchByObservationBody = {
        patientObservationType: String(selectedPatientObservatonType?.name),
        organizationId: organizationid,
        startDate: startDate,
      } as PatientSearchListByObservationTypeBody;
      dispatch(patientSearchByObservationTypeApi(PatientSearchByObservationBody, 1));
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {patientListByObservationType?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Filter Patient by Missing Observation</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                value={selectedPatientObservatonType}
                id="templateId"
                options={patientObservationTypeList}
                onChange={(event: any, newValue: any) => {
                  patientObservationTypeChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Filter by missing observation type"
                    size="small"
                  />
                )}
                getOptionLabel={(option) => `${option.name}`}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWDatePicker
                label="Start Date"
                selectedDateValue={startDate}
                dateState={updateStartDateFromChild}
                disabledValue={fieldDisabled}
                defaultDateForObsType={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={filterPatientByObsType}
            disabled={selectedPatientObservatonType !== null ? false : true}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
