import {
  AddDuplicatePatientActionTypes,
  ADD_DUPLICATE_PATIENT_FAIL,
  ADD_DUPLICATE_PATIENT_LOADING,
  ADD_DUPLICATE_PATIENT_SUCCESS,
  AddDuplicatePatientState,
  ADD_DUPLICATE_PATIENT_API_MSG,
  ADD_DUPLICATE_PATIENT_API_RES,
} from "./ActionTypes";
import { AddDuplicatePatientDetails } from "./Model";

const initialStateGetPosts: AddDuplicatePatientState = {
  loading: false,
  addDuplicatePatientRes: {} as AddDuplicatePatientDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDuplicatePatientReducer = (
  state = initialStateGetPosts,
  action: AddDuplicatePatientActionTypes,
): AddDuplicatePatientState => {
  switch (action.type) {
    case ADD_DUPLICATE_PATIENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DUPLICATE_PATIENT_SUCCESS:
      return {
        ...state,
        addDuplicatePatientRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DUPLICATE_PATIENT_FAIL:
      return {
        ...state,
        addDuplicatePatientRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DUPLICATE_PATIENT_API_MSG:
      return {
        ...state,
        addDuplicatePatientRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DUPLICATE_PATIENT_API_RES:
      return {
        ...state,
        addDuplicatePatientRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
