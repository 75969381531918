import {
  GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_FAIL,
  GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_LOADING,
  GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_SUCCESS,
  GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_API_MSG,
  GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_API_RES,
  GetProviderOriganizationListActionTypes,
  GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_DATA,
} from "./ActionTypes";
import { GetProviderOrganizationAdministratorListRes, PageCount } from "./Model";

export const providerOrgAdministratorListAction = (
  GetProviderOrganizationAdministratorListRes: GetProviderOrganizationAdministratorListRes,
  pageCount: PageCount,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_DATA,
    payload: GetProviderOrganizationAdministratorListRes,
    pageCount: pageCount,
  };
};

export const getProviderOrgAdministratorListLoadingAction = (
  loading: boolean,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_LOADING,
    loading: loading,
  };
};

export const getProviderOrgAdministratorListSuccessAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationAdministratorListRes,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_SUCCESS,
    payload: getProviderOrganizationListResponse,
  };
};

export const getProviderOrgAdministratorListErrorAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationAdministratorListRes,
  errMsg: string,
  status: number,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_FAIL,
    payload: getProviderOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getProviderOrgAdministratorListAPIMsgAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationAdministratorListRes,
  errMsg: string,
  status: number,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_API_MSG,
    payload: getProviderOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getProviderOrgAdministratorListAPIResClearAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationAdministratorListRes,
  errMsg: string,
  status: number,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_ADMINISTRATOR_LIST_API_RES,
    payload: getProviderOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
