import { GovtIdProofResponse } from "./Model";

export const GOVT_ID_PROOF_LOADING = "GOVT_ID_PROOF_LOADING";
export const GOVT_ID_PROOF_SUCCESS = "GOVT_ID_PROOF_SUCCESS";
export const GOVT_ID_PROOF_PROGRESS = "GOVT_ID_PROOF_PROGRESS";
export const GOVT_ID_PROOF_FAIL = "GOVT_ID_PROOF_FAIL";
export const GOVT_ID_PROOF_API_MSG = "GOVT_ID_PROOF_MSG";
export const GOVT_ID_PROOF_API_RES = "GOVT_ID_PROOF_RES";

export interface GovtIDProofDetails {
  govtIdProofResponse: GovtIdProofResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GovtIDProofLoading {
  type: typeof GOVT_ID_PROOF_LOADING;
  loading: boolean;
}
export interface GovtIDProofSuccess {
  type: typeof GOVT_ID_PROOF_SUCCESS;
  payload: GovtIdProofResponse;
  successMsg: string;
}
export interface GovtIDProofFail {
  type: typeof GOVT_ID_PROOF_FAIL;
  payload: GovtIdProofResponse;
  errorMsg: string;
  status: number;
}
export interface GovtIDProofUpdateAPIMsg {
  type: typeof GOVT_ID_PROOF_API_MSG;
  payload: GovtIdProofResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GovtIDProofUpdateAPIRes {
  type: typeof GOVT_ID_PROOF_API_RES;
  payload: GovtIdProofResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GovtIDProofActionTypes =
  | GovtIDProofLoading
  | GovtIDProofSuccess
  | GovtIDProofFail
  | GovtIDProofUpdateAPIMsg
  | GovtIDProofUpdateAPIRes;
