import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorDetailsList } from "../../../redux/Doctor/ApiCall";
import { AppState } from "../../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  Stack,
  AlertColor,
} from "@mui/material";
import {
  Award,
  Language,
  Membership,
  Workexperience,
} from "../../../redux/Doctor/GetDoctorDetailsById/Model";
import { UpdateDoctorDetailsBody } from "../../../redux/Doctor/updateDoctorDetails/Model";
import {
  clearDoctorDetailsAPIRes,
  updateDoctorDetails,
} from "../../../redux/Doctor/updateDoctorDetails/API";
import MWSnackbar from "../../../component/MWSnackbar";
import AdminLoader from "../../AdminLoader";
import { getDoctorDetailsById } from "../../../redux/Doctor/GetDoctorDetailsById/API";
type Props = {
  doctorDetailsLoader: boolean | any;
  updateDoctorDetailsDialog?: boolean | any;
  updateDoctorDetailsDialogUpdatedState: boolean | any;
};

export default function DoctorDetails({
  doctorDetailsLoader,
  updateDoctorDetailsDialog,
  updateDoctorDetailsDialogUpdatedState,
}: Props) {
  const dispatch = useDispatch();
  const { id } = useParams() as {
    id: string;
  };

  const [workExp, setWorkExp] = useState<string[]>([]);
  const [awardName, setAwardName] = useState<string[]>([]);
  const [language, setLanguage] = useState<string[]>([]);
  const [membership, setMemberShip] = useState<any[]>([]);

  const [isDisableEducationSummaryField, setIsDisableEducationSummaryField] = useState(false);
  const [isDisableWorkSummaryField, setIsDisableWorkSummaryField] = useState(false);

  const [updateEducationSummary, setUpdateEducationSummary] = useState("");
  const [updateWorkSummary, setUpdateWorkSummary] = useState("");

  const [editDoctorDetailsDialog, setEditDoctorDetailsDialog] = useState(false);
  const closeEditDoctorDetailsDialog = () => {
    setEditDoctorDetailsDialog(false);
    updateDoctorDetailsDialogUpdatedState(false);
  };

  useEffect(() => {
    setEditDoctorDetailsDialog(updateDoctorDetailsDialog);
  }, [updateDoctorDetailsDialog, updateDoctorDetailsDialogUpdatedState]);
  const [educationSummaryFieldError, setEducationSummaryFieldError] = useState("");
  const educationSummaryFieldErrorDiv = educationSummaryFieldError ? (
    <Typography>{educationSummaryFieldError}</Typography>
  ) : null;
  const [workSummaryFieldError, setWorkSummaryFieldError] = useState("");
  const workSummaryFieldErrorDiv = workSummaryFieldError ? (
    <Typography>{workSummaryFieldError}</Typography>
  ) : null;
  const closeDoctorSummaryDetailsError = () => {
    dispatch(clearDoctorDetailsAPIRes());
  };
  const [updateDoctorSummaryDetailsError, setUpdateDoctorSummaryDetailsError] = useState("");
  const updateSummaryErrorDiv =
    updateDoctorSummaryDetailsError !== "" ? (
      <Alert onClick={closeDoctorSummaryDetailsError} severity="error">
        <Typography>{updateDoctorSummaryDetailsError}</Typography>
      </Alert>
    ) : null;
  const [updateDrDetailsAPIMsg, setUpdateDrDetailsAPIMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const updateDoctorDetailsResVal = useSelector((state: AppState) => state.updateDoctorDetailsRes);
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearDoctorDetailsAPIRes());
    }
  };
  useEffect(() => {
    doctorDetailsLoader(updateDoctorDetailsResVal?.loading);
    if (updateDoctorDetailsResVal?.updateNoteResRes?.message !== undefined) {
      setMsgType("success");
      setUpdateDrDetailsAPIMsg(updateDoctorDetailsResVal?.updateNoteResRes?.message);
      closeEditDoctorDetailsDialog();
      dispatch(getDoctorDetailsById(id));
    } else if (updateDoctorDetailsResVal?.errorMsg !== "") {
      setUpdateDoctorSummaryDetailsError(updateDoctorDetailsResVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDoctorDetailsResVal]);

  const drSummaryBody = {
    doctorId: Number(id),
    workSummaryText: updateWorkSummary,
    edicationSummaryText: updateEducationSummary,
  } as UpdateDoctorDetailsBody;

  // update dr. sumaary details
  const updateDrSummary = () => {
    if (updateWorkSummary === "" && updateEducationSummary === "") {
      setWorkSummaryFieldError("Please add work summary");
      setEducationSummaryFieldError("Please add education summary");
    } else if (updateWorkSummary === "" && updateEducationSummary !== "") {
      setWorkSummaryFieldError("Please add work summary");
      setEducationSummaryFieldError("");
    } else if (updateWorkSummary !== "" && updateEducationSummary === "") {
      setWorkSummaryFieldError("");
      setEducationSummaryFieldError("Please add education summary");
    } else {
      setWorkSummaryFieldError("");
      setEducationSummaryFieldError("");
      dispatch(updateDoctorDetails(drSummaryBody));
    }
  };

  // getDoctorDetailsList store ...
  const doctorsDetailValue = useSelector((state: AppState) => state.doctorDetails);

  useEffect(() => {
    // Do api call here on page load....
    dispatch(getDoctorDetailsList(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {}, []);

  useEffect(() => {
    doctorDetailsLoader(doctorsDetailValue?.loading);
    if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.language !== undefined) {
      if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.language.length > 0) {
        let langItem = doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.language.map(
          (item: Language) => {
            return item.label;
          },
        );
        setLanguage(langItem);
      } else setLanguage([] as string[]);
    } else setLanguage([] as string[]);
    if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.workexperience !== undefined) {
      if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.workexperience.length > 0) {
        let hospitalItem =
          doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.workexperience.map(
            (item: Workexperience) => {
              return item.name;
            },
          );
        setWorkExp(hospitalItem);
      } else setWorkExp([] as string[]);
    } else setWorkExp([] as string[]);
    if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.membership !== undefined) {
      if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.membership.length > 0) {
        let professionalItem =
          doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.membership.map(
            (item: Membership) => {
              if (item.name !== null) {
                return item.name;
              } else {
                return "";
              }
            },
          )!;
        if (professionalItem !== undefined) {
          setMemberShip(professionalItem);
        }
      } else setMemberShip([] as string[]);
    } else setMemberShip([] as string[]);
    if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.awards !== undefined) {
      if (doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.awards.length > 0) {
        let awardItem = doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.awards.map(
          (item: Award) => {
            return item.name;
          },
        );
        setAwardName(awardItem);
      } else setAwardName([] as string[]);
    } else setAwardName([] as string[]);
    if (
      doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.educationSummaryDisplayText !==
      undefined
    ) {
      setUpdateEducationSummary(
        doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.educationSummaryDisplayText,
      );
      setIsDisableEducationSummaryField(true);
    } else {
      setUpdateEducationSummary("");
      setIsDisableEducationSummaryField(false);
    }
    if (
      doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.workSummaryDisplayText !==
      undefined
    ) {
      setUpdateWorkSummary(
        doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.workSummaryDisplayText,
      );
      setIsDisableWorkSummaryField(true);
    } else {
      setUpdateWorkSummary("");
      setIsDisableWorkSummaryField(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorsDetailValue]);

  const editDoctorDetailsLayout = (
    <Dialog open={editDoctorDetailsDialog} onClose={closeEditDoctorDetailsDialog} fullWidth>
      {updateDoctorDetailsResVal?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h5">Update Doctor Summary</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeEditDoctorDetailsDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={8} xl={6}>
            <TextField
              value={updateEducationSummary}
              label="Education Summary"
              placeholder="Enter education summary details"
              id="educationSummaryID"
              onChange={(event) => setUpdateEducationSummary(event?.target.value)}
              disabled={isDisableEducationSummaryField}
              fullWidth
            />
            <Typography sx={{ color: "#c62828" }}>{educationSummaryFieldErrorDiv}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={8} xl={6}>
            <TextField
              value={updateWorkSummary}
              label="Work Summary"
              placeholder="Enter work summary"
              id="workSummaryID"
              onChange={(event) => setUpdateWorkSummary(event?.target.value)}
              disabled={isDisableWorkSummaryField}
              fullWidth
            />
            <Typography sx={{ color: "#c62828" }}>{workSummaryFieldErrorDiv}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Box py={1}>{updateSummaryErrorDiv}</Box>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={closeEditDoctorDetailsDialog}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={updateDrSummary}
              disabled={updateDoctorDetailsResVal?.loading || isDisableEducationSummaryField}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  const doctorDetails = (
    <CardContent>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
        sx={{ pt: 2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Education Summary:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails
                ?.educationSummaryDisplayText === undefined
                ? "Details not available "
                : doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails
                    ?.educationSummaryDisplayText}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Academic Qualifications:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                ?.highestQualification !== undefined
                ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                    ?.highestQualification !== null
                  ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                      ?.highestQualification?.label + ", "
                  : null
                : null}
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                ?.secondHighestQualification !== undefined
                ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                    ?.secondHighestQualification !== null
                  ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                      ?.secondHighestQualification.label + ", "
                  : null
                : null}
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                ?.mbbsDegree !== undefined
                ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                    ?.mbbsDegree !== null
                  ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.qualification
                      ?.mbbsDegree.label
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Registration with Medical Council:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.registration.length > 0
                ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.registration[0].idType
                    ?.label !== undefined
                  ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.registration[0]
                      .idType?.label
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Language Skills:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">{language.join(", ")}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Years of Experience after Post-graduation:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.yearofExperience
                ?.totalYearsOfPracticeAfterHighestQualification !== undefined
                ? doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails?.yearofExperience
                    ?.totalYearsOfPracticeAfterHighestQualification
                : null}{" "}
              years
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Work Summary:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails
                ?.workSummaryDisplayText === undefined
                ? "Details not available"
                : doctorsDetailValue?.getDoctorDetailsByIdRes?.doctorDetails
                    ?.workSummaryDisplayText}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Work Experience:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">{workExp.join(", ")}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Professional Affiliations:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">{membership.join(", ")}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Awards Received:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">{awardName.join(", ")}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </CardContent>
  );

  return (
    <Box>
      {editDoctorDetailsLayout}
      {doctorDetails}
      <MWSnackbar msg={updateDrDetailsAPIMsg} type={msgType} alertClose={checkAlertClose} />
    </Box>
  );
}
