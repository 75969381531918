import {
  CONSULTATION_TYPE_API_MSG,
    CONSULTATION_TYPE_API_RES,
    CONSULTATION_TYPE_DATA,
    CONSULTATION_TYPE_FAIL,
    CONSULTATION_TYPE_LOADING,
    CONSULTATION_TYPE_SUCCESS,
    ConsultationTypeActionTypes,
  } from "./ActionTypes";
  import { ConsultationTypeResponse } from "./Model";
  
  export const consultationTypeAction = (
    consultationTypeRes: ConsultationTypeResponse
  ): ConsultationTypeActionTypes => {
    return {
      type: CONSULTATION_TYPE_DATA,
      payload: consultationTypeRes,
    };
  };
  
  export const consultationTypeLoadingAction = (
    loading: boolean
  ): ConsultationTypeActionTypes => {
    return {
      type: CONSULTATION_TYPE_LOADING,
      loading: loading,
    };
  };
  
  export const consultationTypeSuccessAction = (
    consultationTypeRes: ConsultationTypeResponse,
    successMsg: string,
    status: number    
  ): ConsultationTypeActionTypes => {
    return {
      type: CONSULTATION_TYPE_SUCCESS,
      payload: consultationTypeRes,
      successMsg: successMsg,
      status: status      
    };
  };
  
  export const consultationTypeErrorAction = (
    consultationTypeRes: ConsultationTypeResponse,
    errMsg: string,
    status: number
  ): ConsultationTypeActionTypes => {
    return {
      type: CONSULTATION_TYPE_FAIL,
      payload: consultationTypeRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const consultationTypeUpdateAPIMsgAction = (
    consultationTypeRes: ConsultationTypeResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): ConsultationTypeActionTypes => {
    return {
      type: CONSULTATION_TYPE_API_MSG,
      payload: consultationTypeRes,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const consultationTypeAPIResClearAction = (
    consultationTypeRes: ConsultationTypeResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): ConsultationTypeActionTypes => {
    return {
      type: CONSULTATION_TYPE_API_RES,
      payload: consultationTypeRes,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  