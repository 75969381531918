import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AssignPatientDetailsTypeActionTypes } from "./ActionTypes";
import { AssignPatientDetailsTypeBody, AssignPatientDetailsTypeRes } from "./Model";
import {
  assignPatientDetailsTypeAPIResClearAction,
  assignPatientDetailsTypeFailAction,
  assignPatientDetailsTypeLoadingAction,
  assignPatientDetailsTypeSuccessAction,
  assignPatientDetailsTypeAPIMsgAction,
  assignPatientDetailsTypeSelectedItemAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AssignPatientDetailsTypeRes;
export const assignPatientDetailsTypeCall = (payload: AssignPatientDetailsTypeBody) => {
  return function (dispatch: Dispatch<AssignPatientDetailsTypeActionTypes>) {
    dispatch(assignPatientDetailsTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/patientDetails/edit_patientDetailsType`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(assignPatientDetailsTypeLoadingAction(false));
        dispatch(
          assignPatientDetailsTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as AssignPatientDetailsTypeRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient details type has been assigned successfully",
          ),
        );
        dispatch(assignPatientDetailsTypeSelectedItemAction([] as any[]));
      })
      .catch((error) => {
        dispatch(assignPatientDetailsTypeLoadingAction(false));
        dispatch(
          assignPatientDetailsTypeFailAction(
            {} as AssignPatientDetailsTypeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAssignPatientDetailsTypeResMsg = () => {
  return function (dispatch: Dispatch<AssignPatientDetailsTypeActionTypes>) {
    dispatch(
      assignPatientDetailsTypeAPIMsgAction(apiRes as AssignPatientDetailsTypeRes, "", "", 0),
    );
  };
};

export const clearAssignPatientDetailsTypeRes = () => {
  return function (dispatch: Dispatch<AssignPatientDetailsTypeActionTypes>) {
    dispatch(
      assignPatientDetailsTypeAPIResClearAction({} as AssignPatientDetailsTypeRes, "", "", 0),
    );
  };
};

export const assignPatientDetailsTypeSelectedItem = (selectedItem: any[]) => {
  return function (dispatch: Dispatch<AssignPatientDetailsTypeActionTypes>) {
    dispatch(assignPatientDetailsTypeSelectedItemAction(selectedItem));
  };
};
