import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { MovePatientToOrganizationActionTypes } from "./ActionTypes";
import { MovePatientToOrganizationBody, MovePatientToOrganizationDetails } from "./Model";
import {
  movePatientToOrganizationAPIResClearAction,
  movePatientToOrganizationErrorAction,
  movePatientToOrganizationLoadingAction,
  movePatientToOrganizationSuccessAction,
  movePatientToOrganizationAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as MovePatientToOrganizationDetails;
export const movePatientToOrganizationApiCall = (payload: MovePatientToOrganizationBody) => {
  return function (dispatch: Dispatch<MovePatientToOrganizationActionTypes>) {
    dispatch(movePatientToOrganizationLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/patientorganization/move_patient_healthcamp_to_Organization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(movePatientToOrganizationLoadingAction(false));
        dispatch(
          movePatientToOrganizationSuccessAction(
            res.data !== undefined ? res.data : ({} as MovePatientToOrganizationDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(movePatientToOrganizationLoadingAction(false));
        dispatch(
          movePatientToOrganizationErrorAction(
            {} as MovePatientToOrganizationDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const movePatientToOrganizationAPIResMsg = () => {
  return function (dispatch: Dispatch<MovePatientToOrganizationActionTypes>) {
    dispatch(movePatientToOrganizationAPIMsgAction(apiRes as MovePatientToOrganizationDetails, "", "", 0));
  };
};

export const clearMovePatientToOrganizationAPIRes = () => {
  return function (dispatch: Dispatch<MovePatientToOrganizationActionTypes>) {
    dispatch(movePatientToOrganizationAPIResClearAction({} as MovePatientToOrganizationDetails, "", "", 0));
  };
};
