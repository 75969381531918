import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import JudgementView from "./JudgementView";
import { AppState } from "redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import { GetJudgingDetailsListBody } from "redux/JudgingDetailsList/Model";
import { useParams } from "react-router-dom";
import { getJudgingDetailsList } from "redux/JudgingDetailsList/API";
import JudgementSecondRoundView from "./JudgementSecondRoundView";
import WinnersView from "./WinnersView";
import { GetJudgingSecondRoundDetailsListBody } from "redux/JudgingSecondRoundDetailsList/Model";
import { getJudgingSecondRoundDetailsList } from "redux/JudgingSecondRoundDetailsList/API";
import { GetJudgingFinalRoundDetailsListBody } from "redux/FinalRoundDetailsList/Model";
import { getJudgingFinalRoundDetailsList } from "redux/FinalRoundDetailsList/API";
import JudgementNextRoundView from "./JudgementNextRoundView";
import { GetPhotoJudgingDetailsListBody } from "redux/GetPhotoJudgingDetailsList/Model";
import { getPhotoJudgingDetailsList } from "redux/GetPhotoJudgingDetailsList/API";

type Props = {
  judgmentListOfCompetitonLoader: boolean | any;
};

export default function JudgmentViewOfCompetiition({ judgmentListOfCompetitonLoader }: Props) {
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const [listCount, setListCount] = React.useState("");
  const [nextListCount, setNextListCount] = React.useState("");
  const [secondRoundListCount, setSecondRoundListCount] = React.useState("");
  const [finalRoundListCount, setFinalRoundListCount] = React.useState("");
  const [selected, setSelected] = React.useState("1");
  const [judgementViewLoader, setJudgementViewLoader] = React.useState(false);  
  const [judgementNextRoundViewLoader, setJudgementNextRoundViewLoader] = React.useState(false);
  const [judgementSecondRoundViewLoader, setJudgementSecondRoundViewLoader] = React.useState(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  const judgementViewList = useSelector((state: AppState) => state.getJudgingListValue);
  const judgementNextViewList = useSelector((state: AppState) => state.getPhotoJudgingValue);
  const patientPointDataList = useSelector((state: AppState) => state.getJudgingScoundRoundValue);
  const finalRoundList = useSelector((state: AppState) => state.getFinalRoundvalue);

  useEffect(() => {
    window.scrollTo(0, 0);
    const patientListBody = {
      competitionId: competitionid,
      patientObservationKey: "photograding",
    } as GetJudgingDetailsListBody;
    dispatch(getJudgingDetailsList(patientListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (judgementViewList?.getJudgingDetailsListRes?.count !== undefined) {
      setListCount(String(judgementViewList?.getJudgingDetailsListRes?.count));
    } else {
      setListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgementViewList]);

  useEffect(() => {
    if (judgementNextViewList?.getPhotoJudgingDetailsListRes?.count !== undefined) {
      setNextListCount(String(judgementNextViewList?.getPhotoJudgingDetailsListRes?.count));
    } else {
      setNextListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgementNextViewList]);

  useEffect(() => {
    if (patientPointDataList?.getJudgingSecondRoundDetailsListRes?.count !== undefined) {
      setSecondRoundListCount(
        String(patientPointDataList?.getJudgingSecondRoundDetailsListRes?.count),
      );
    } else {
      setSecondRoundListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  useEffect(() => {
    if (finalRoundList?.getJudgingFinalRoundDetailsListRes?.count !== undefined) {
      setFinalRoundListCount(String(finalRoundList?.getJudgingFinalRoundDetailsListRes?.count));
    } else {
      setFinalRoundListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalRoundList]);

  const judgmentViewResult = () => {
    const patientListBody = {
      competitionId: competitionid,
      patientObservationKey: "photograding",
    } as GetJudgingDetailsListBody;
    dispatch(getJudgingDetailsList(patientListBody, 1));
  };

  const photoJudgmentViewResult = () => {
    const patientListBody = {
      competitionId: competitionid,
      patientObservationKey: "photojudging",
    } as GetPhotoJudgingDetailsListBody;
    dispatch(getPhotoJudgingDetailsList(patientListBody, 1));
  };

  const secondRoundListOfCompetition = () => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetJudgingSecondRoundDetailsListBody;
    dispatch(getJudgingSecondRoundDetailsList(patientListBody));
  };

  const finalRoundListOfCompetition = () => {
    const finalRoundListBody = {
      competitionId: competitionid,
    } as GetJudgingFinalRoundDetailsListBody;
    dispatch(getJudgingFinalRoundDetailsList(finalRoundListBody));
  };

  const patientListByCompetitionPage = (
    <Card>
      <CardContent>
        <Grid>
          <Grid item>
            <Box>
              <TabContext value={String(selected)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography>Judgment View</Typography>
                          </Grid>
                          <Grid item>
                            <Chip label={listCount} variant="outlined" size="small" />
                          </Grid>
                        </Grid>
                      }
                      icon={
                        <RefreshIcon
                          onClick={() => {
                            judgmentViewResult();
                          }}
                        />
                      }
                      iconPosition="end"
                      value="1"
                    />
                    <Tab
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography>Judging Next Round View</Typography>
                          </Grid>
                          <Grid item>
                            <Chip label={nextListCount} variant="outlined" size="small" />
                          </Grid>
                        </Grid>
                      }
                      icon={
                        <RefreshIcon
                          onClick={() => {
                            photoJudgmentViewResult();
                          }}
                        />
                      }
                      iconPosition="end"
                      value="2"
                    />
                    <Tab
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography>Judging Final Round View</Typography>
                          </Grid>
                          <Grid item>
                            <Chip
                              label={String(secondRoundListCount)}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      }
                      icon={
                        <RefreshIcon
                          onClick={() => {
                            secondRoundListOfCompetition();
                          }}
                        />
                      }
                      iconPosition="end"
                      value="3"
                    />
                    <Tab
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography>Winner View</Typography>
                          </Grid>
                          <Grid item>
                            <Chip
                              label={String(finalRoundListCount)}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      }
                      icon={
                        <RefreshIcon
                          onClick={() => {
                            finalRoundListOfCompetition();
                          }}
                        />
                      }
                      iconPosition="end"
                      value="4"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <JudgementView judgementViewLoader={setJudgementViewLoader} />
                </TabPanel>
                <TabPanel value="2">
                  <JudgementNextRoundView
                    judgementNextRoundViewLoader={setJudgementNextRoundViewLoader}
                  />
                </TabPanel>
                <TabPanel value="3">
                  <JudgementSecondRoundView
                    judgementSecondRoundViewLoader={setJudgementSecondRoundViewLoader}
                  />
                </TabPanel>
                <TabPanel value="4">
                  <WinnersView judgementSecondRoundViewLoader={setJudgementSecondRoundViewLoader} />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {judgementViewLoader || judgementSecondRoundViewLoader || judgementNextRoundViewLoader
        ? judgmentListOfCompetitonLoader(true)
        : judgmentListOfCompetitonLoader(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {patientListByCompetitionPage}
        </Stack>
      </Container>
    </Box>
  );
}
