import { PageCount, AddStaffOfOrgDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_STAFF_OF_ORG_LOADING = "ADD_STAFF_OF_ORG_LOADING";
export const ADD_STAFF_OF_ORG_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_STAFF_OF_ORG_FAIL = "ADD_STAFF_OF_ORG_FAIL";
export const ADD_STAFF_OF_ORG_DATA = "ADD_STAFF_OF_ORG_DATA";
export const ADD_STAFF_OF_ORG_API_MSG = "ADD_STAFF_OF_ORG_API_MSG";
export const ADD_STAFF_OF_ORG_API_RES = "ADD_STAFF_OF_ORG_API_RES";

export interface AddStaffOfOrgState {
  AddStaffOfOrgRes: AddStaffOfOrgDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfOrgLoading {
  type: typeof ADD_STAFF_OF_ORG_LOADING;
  loading: boolean;
}
export interface AddStaffOfOrgSuccess {
  type: typeof ADD_STAFF_OF_ORG_SUCCESS;
  payload: AddStaffOfOrgDetails;
  successMsg: string;
}
export interface AddStaffOfOrgFail {
  type: typeof ADD_STAFF_OF_ORG_FAIL;
  payload: AddStaffOfOrgDetails;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfOrgAPIMsg {
  type: typeof ADD_STAFF_OF_ORG_API_MSG;
  payload: AddStaffOfOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfOrgAPIRes {
  type: typeof ADD_STAFF_OF_ORG_API_RES;
  payload: AddStaffOfOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddStaffOfOrgDataAction {
  type: typeof ADD_STAFF_OF_ORG_DATA;
  payload: AddStaffOfOrgDetails;
  pageCount: PageCount;
}

export type AddStaffOfOrgActionTypes =
  | AddStaffOfOrgDataAction
  | AddStaffOfOrgLoading
  | AddStaffOfOrgSuccess
  | AddStaffOfOrgFail
  | AddStaffOfOrgAPIMsg
  | AddStaffOfOrgAPIRes;
