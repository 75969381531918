import {
  HealthcampListByPatientIdActionTypes,
  HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL,
  HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING,
  HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS,
  HealthcampListByPatientIdDetailsState,
  HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG,
  HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES,
} from "./ActionTypes";
import { HealthcampListByPatientIdDetails } from "./Model";

const initialStateGetPosts: HealthcampListByPatientIdDetailsState = {
  loading: false,
  healthcampListByPatientIdRes: {} as HealthcampListByPatientIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const healthcampListByPatientIdReducer = (
  state = initialStateGetPosts,
  action: HealthcampListByPatientIdActionTypes,
): HealthcampListByPatientIdDetailsState => {
  switch (action.type) {
    case HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: true,
        healthcampListByPatientIdRes: {} as HealthcampListByPatientIdDetails,
      };
    case HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        healthcampListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        healthcampListByPatientIdRes: {} as HealthcampListByPatientIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG:
      return {
        ...state,
        loading: false,
        healthcampListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES:
      return {
        ...state,
        loading: false,
        healthcampListByPatientIdRes: {} as HealthcampListByPatientIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
