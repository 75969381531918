import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { HealthcampPatientSearchListByObservationTypeActionTypes } from "./ActionTypes";
import {
  HealthcampPatientSearchListByObservationTypeBody,
  HealthcampPatientSearchListByObservationTypeDetails,
} from "./Model";
import {
  HealthcampPatientSearchListByObservationTypeAPIResClearAction,
  HealthcampPatientSearchListByObservationTypeErrorAction,
  HealthcampPatientSearchListByObservationTypeLoadingAction,
  HealthcampPatientSearchListByObservationTypeSuccessAction,
  HealthcampPatientSearchListByObservationTypeAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as HealthcampPatientSearchListByObservationTypeDetails;
export const healthcampPatientSearchByObservationTypeApi = (
  searchKey: HealthcampPatientSearchListByObservationTypeBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<HealthcampPatientSearchListByObservationTypeActionTypes>) {
    dispatch(HealthcampPatientSearchListByObservationTypeLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/caregiverapp/api/healthcamppatient/healthcamp_patient_missing_details_by_observation_type?page=${Number(
            pageNo,
          )}&size=50`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(HealthcampPatientSearchListByObservationTypeLoadingAction(false));
        dispatch(
          HealthcampPatientSearchListByObservationTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as HealthcampPatientSearchListByObservationTypeDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(HealthcampPatientSearchListByObservationTypeLoadingAction(false));
        dispatch(
          HealthcampPatientSearchListByObservationTypeErrorAction(
            {} as HealthcampPatientSearchListByObservationTypeDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateHealthcampSearchPatientByObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<HealthcampPatientSearchListByObservationTypeActionTypes>) {
    dispatch(
      HealthcampPatientSearchListByObservationTypeAPIMsgAction(
        apiRes as HealthcampPatientSearchListByObservationTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearHealthcampSearchPatientByObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<HealthcampPatientSearchListByObservationTypeActionTypes>) {
    dispatch(
      HealthcampPatientSearchListByObservationTypeAPIResClearAction(
        {} as HealthcampPatientSearchListByObservationTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};
