import { PageCount, DiactiveStaffOfProviderOrgDetails } from "./Model";

// Patient Demographics Loading State
export const TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING = "TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING";
export const TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS = "TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS";
export const TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL = "TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL";
export const TOGGLE_STAFF_OF_PROVIDER_ORG_DATA = "TOGGLE_STAFF_OF_PROVIDER_ORG_DATA";
export const TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG = "TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG";
export const TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES = "TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES";

export interface DiactiveStaffOfProviderOrgState {
  DiactiveStaffOfProviderOrgRes: DiactiveStaffOfProviderOrgDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfProviderOrgLoading {
  type: typeof TOGGLE_STAFF_OF_PROVIDER_ORG_LOADING;
  loading: boolean;
}
export interface DiactiveStaffOfProviderOrgSuccess {
  type: typeof TOGGLE_STAFF_OF_PROVIDER_ORG_SUCCESS;
  payload: DiactiveStaffOfProviderOrgDetails;
  successMsg: string;
}
export interface DiactiveStaffOfProviderOrgFail {
  type: typeof TOGGLE_STAFF_OF_PROVIDER_ORG_FAIL;
  payload: DiactiveStaffOfProviderOrgDetails;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfProviderOrgAPIMsg {
  type: typeof TOGGLE_STAFF_OF_PROVIDER_ORG_API_MSG;
  payload: DiactiveStaffOfProviderOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfProviderOrgAPIRes {
  type: typeof TOGGLE_STAFF_OF_PROVIDER_ORG_API_RES;
  payload: DiactiveStaffOfProviderOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface DiactiveStaffOfProviderOrgDataAction {
  type: typeof TOGGLE_STAFF_OF_PROVIDER_ORG_DATA;
  payload: DiactiveStaffOfProviderOrgDetails;
  pageCount: PageCount;
}

export type DiactiveStaffOfProviderOrgActionTypes =
  | DiactiveStaffOfProviderOrgDataAction
  | DiactiveStaffOfProviderOrgLoading
  | DiactiveStaffOfProviderOrgSuccess
  | DiactiveStaffOfProviderOrgFail
  | DiactiveStaffOfProviderOrgAPIMsg
  | DiactiveStaffOfProviderOrgAPIRes;
