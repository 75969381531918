import { GetCompetitionDeactiveStaffListRes } from "./Model";
export const COMPETITION_DEACTIVE_STAFF_LIST_LOADING = "COMPETITION_DEACTIVE_STAFF_LIST_LOADING";
export const COMPETITION_DEACTIVE_STAFF_LIST_SUCCESS = "COMPETITION_DEACTIVE_STAFF_LIST_SUCCESS";
export const COMPETITION_DEACTIVE_STAFF_LIST_FAIL = "COMPETITION_DEACTIVE_STAFF_LIST_FAIL";
export const COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG = "COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG";
export const COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_RES = "COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_RES";

export interface GetCompetitionDeactiveStaffListState {
  getCompetitionDeactiveStaffList: GetCompetitionDeactiveStaffListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetCompetitionDeactiveStaffListLoading {
  type: typeof COMPETITION_DEACTIVE_STAFF_LIST_LOADING;
  loading: boolean;
}
export interface GetCompetitionDeactiveStaffListSuccess {
  type: typeof COMPETITION_DEACTIVE_STAFF_LIST_SUCCESS;
  payload: GetCompetitionDeactiveStaffListRes;
  successMsg: string;
}
export interface GetCompetitionDeactiveStaffListFail {
  type: typeof COMPETITION_DEACTIVE_STAFF_LIST_FAIL;
  payload: GetCompetitionDeactiveStaffListRes;
  errorMsg: string;
  status: number;
}
export interface GetCompetitionDeactiveStaffListUpdateAPIMsg {
  type: typeof COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG;
  payload: GetCompetitionDeactiveStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetCompetitionDeactiveStaffListUpdateAPIRes {
  type: typeof COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_RES;
  payload: GetCompetitionDeactiveStaffListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetCompetitionDeactiveStaffListActionTypes =
  | GetCompetitionDeactiveStaffListLoading
  | GetCompetitionDeactiveStaffListSuccess
  | GetCompetitionDeactiveStaffListFail
  | GetCompetitionDeactiveStaffListUpdateAPIMsg
  | GetCompetitionDeactiveStaffListUpdateAPIRes;
