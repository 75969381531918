import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTelehealthMeetingList } from "../../redux/Telehealth/API";
import { AppState } from "../../redux/store/Store";
import MWExceptionList from "../../component/MWExceptionList";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { MeetingList } from "../../redux/Telehealth/Model";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function ActiveMeeting() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getTelehealthMeetingList({
        type: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Call Meeting List Store
  const activeMeetingListFromModel = useSelector((state: AppState) => state.telehealthMeetingValue);
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  const activeMeetingListColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 80,
      align: "left",
    },
    { field: "patientCase", headerName: "Patient Case Id", minWidth: 120, align: "center" },
    { field: "displayName", headerName: "Appointment Name", minWidth: 150, flex: 1 },
    {
      field: "appointmentDate",
      headerName: "Appointment Date & Time",
      minWidth: 240,
      align: "left",
    },
    {
      field: "dateCreated",
      headerName: "Create Date",
      minWidth: 240,
      align: "left",
    },
  ];

  useEffect(() => {
    dispatch(toggleAppBarLoading(activeMeetingListFromModel?.loading));
    if (activeMeetingListFromModel?.teleHealthRes?.meetingList !== undefined) {
      let activeMeetingList = activeMeetingListFromModel?.teleHealthRes?.meetingList.map(
        (element: any) => ({
          id: element.id,
          patientCase: element.patientCase,
          displayName: element.displayName,
          appointmentDate: dayjs(element.appointmentDate).format("ddd, MMMM D, YYYY h:mm A"),
          dateCreated: dayjs(element.dateCreated).format("ddd, MMMM D, YYYY"),
        }),
      );
      setDataArray(activeMeetingList);
    } else setDataArray([] as MeetingList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMeetingListFromModel]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">Active Meeting Details</Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      dispatch(
                        getTelehealthMeetingList({
                          type: true,
                        }),
                      )
                    }
                    startIcon={<RefreshIcon />}
                  >
                    Reload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {dataGridArray !== undefined ? (
          dataGridArray.length > 0 ? (
            <DataGrid
              rows={dataGridArray}
              columns={activeMeetingListColumns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
}
