import {
  HEALTHCAMP_STAFF_ACTIVE_LIST_FAIL,
  HEALTHCAMP_STAFF_ACTIVE_LIST_LOADING,
  HEALTHCAMP_STAFF_ACTIVE_LIST_SUCCESS,
  HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_MSG,
  HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_RES,
  GetHealthcampStaffActiveListActionTypes,
} from "./ActionTypes";
import { GetHealthcampStaffActiveListRes } from "./Model";

export const getHealthcampStaffActiveListLoadingAction = (
  loading: boolean,
): GetHealthcampStaffActiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_ACTIVE_LIST_LOADING,
    loading: loading,
  };
};

export const getHealthcampStaffActiveListSuccessAction = (
  getHealthcampStaffActiveListresponse: GetHealthcampStaffActiveListRes,
  successMsg: string,
  status: number,
): GetHealthcampStaffActiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_ACTIVE_LIST_SUCCESS,
    payload: getHealthcampStaffActiveListresponse,
    successMsg: successMsg,
    status: status,
  };
};

export const getHealthcampStaffActiveListErrorAction = (
  getHealthcampStaffActiveListresponse: GetHealthcampStaffActiveListRes,
  errMsg: string,
  status: number,
): GetHealthcampStaffActiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_ACTIVE_LIST_FAIL,
    payload: getHealthcampStaffActiveListresponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthcampStaffActiveListUpdateAPIMsgAction = (
  getHealthcampStaffActiveListresponse: GetHealthcampStaffActiveListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthcampStaffActiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_MSG,
    payload: getHealthcampStaffActiveListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthcampStaffActiveListAPIResClearAction = (
  getHealthcampStaffActiveListresponse: GetHealthcampStaffActiveListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthcampStaffActiveListActionTypes => {
  return {
    type: HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_RES,
    payload: getHealthcampStaffActiveListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
