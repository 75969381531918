import { CheckAppointmentPaymentStatusRes } from "./Model";
export const GET_APPOINTMENT_PAYMENT_STATUS_LOADING = "GET_APPOINTMENT_PAYMENT_STATUS_LOADING";
export const GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS = "GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS";
export const GET_APPOINTMENT_PAYMENT_STATUS_FAIL = "GET_APPOINTMENT_PAYMENT_STATUS_FAIL";
export const GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG =
  "GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG";
export const GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES =
  "GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES";

export interface CheckAppointmentPaymentStatusState {
  checkAppointmentPaymentStatusRes: CheckAppointmentPaymentStatusRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CheckAppointmentPaymentStatusLoading {
  type: typeof GET_APPOINTMENT_PAYMENT_STATUS_LOADING;
  loading: boolean;
}
export interface CheckAppointmentPaymentStatusSuccess {
  type: typeof GET_APPOINTMENT_PAYMENT_STATUS_SUCCESS;
  payload: CheckAppointmentPaymentStatusRes;
  successMsg: string;
}
export interface CheckAppointmentPaymentStatusFail {
  type: typeof GET_APPOINTMENT_PAYMENT_STATUS_FAIL;
  payload: CheckAppointmentPaymentStatusRes;
  errorMsg: string;
  status: number;
}
export interface CheckAppointmentPaymentStatusUpdateAPIMsg {
  type: typeof GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_MSG;
  payload: CheckAppointmentPaymentStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CheckAppointmentPaymentStatusUpdateAPIRes {
  type: typeof GET_APPOINTMENT_PAYMENT_STATUS_UPDATE_API_RES;
  payload: CheckAppointmentPaymentStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type CheckAppointmentPaymentStatusActionTypes =
  | CheckAppointmentPaymentStatusLoading
  | CheckAppointmentPaymentStatusSuccess
  | CheckAppointmentPaymentStatusFail
  | CheckAppointmentPaymentStatusUpdateAPIMsg
  | CheckAppointmentPaymentStatusUpdateAPIRes;
