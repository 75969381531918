import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DeleteAccountRequestActionTypes } from "./ActionTypes";
import { DeleteAccountRequestBody, DeleteAccountRequestDetails } from "./Model";
import {
  deleteAccountRequestAPIResClearAction,
  deleteAccountRequestErrorAction,
  deleteAccountRequestLoadingAction,
  deleteAccountRequestSuccessAction,
  deleteAccountRequestAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as DeleteAccountRequestDetails;
export const deleteAccountRequestApiCall = (payload: DeleteAccountRequestBody) => {
  return function (dispatch: Dispatch<DeleteAccountRequestActionTypes>) {
    dispatch(deleteAccountRequestLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/executiveDeleteRequest/delete_executive_account_request`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(deleteAccountRequestLoadingAction(false));
        dispatch(
          deleteAccountRequestSuccessAction(
            res.data !== undefined ? res.data : ({} as DeleteAccountRequestDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deleteAccountRequestLoadingAction(false));
        dispatch(
          deleteAccountRequestErrorAction(
            {} as DeleteAccountRequestDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDeleteAccountRequestAPIResMsg = () => {
  return function (dispatch: Dispatch<DeleteAccountRequestActionTypes>) {
    dispatch(deleteAccountRequestAPIMsgAction(apiRes as DeleteAccountRequestDetails, "", "", 0));
  };
};

export const clearDeleteAccountRequestAPIRes = () => {
  return function (dispatch: Dispatch<DeleteAccountRequestActionTypes>) {
    dispatch(deleteAccountRequestAPIResClearAction({} as DeleteAccountRequestDetails, "", "", 0));
  };
};
