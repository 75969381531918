import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MWPageTitle from "../../../component/MWPageTitle";
import PageLayout from "../../Layout/PageLayout";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { getProviderOrganizationListValue } from "../../../redux/ProviderOrganizationList/API";
import { AppState } from "../../../redux/store/Store";
import AdminLoader from "../../AdminLoader";
import PatientListComponent from "../PatientListComponent";
import DoctorListComponent from "../DoctorListComonent";
import { getDoctorListValueAdmin } from "../../../redux/effects/ApiCall";
import ProviderOrganizationStaffList from "../ProviderOrganizationStaffList";
import ProviderOrganizationReportList from "../ProviderOrganizationReportList ";
import ProviderOrgDetailsComponent from "../ProviderOrgDetailsComponent";
import { getStaffListByProviderOrgId } from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/API";
import { GetProviderOrgStaffListBody } from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/Model";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PatientDetailsList from "../PatientDetailsList";
import { ProviderOrgListBody } from "redux/ProviderOrganizationPatientDetailsList/Model";
import { providerOrgPtDetails } from "redux/ProviderOrganizationPatientDetailsList/API";
import { GetProviderOrganizationPatientListBody } from "redux/PatientListByProviderOrganizationId/Model";
import { getDoctorListByProviderOrganizationIdApi } from "redux/GetDoctorListByProviderOrgId/API";
import { GetDoctorListByProviderOrganizationIdBody } from "redux/GetDoctorListByProviderOrgId/Model";
import _ from "lodash";
import { ProviderOrganizationList } from "redux/ProviderOrganizationList/Model";
import { getPatientListByProviderOrganization } from "redux/PatientListByProviderOrganizationId/API";
import ProviderOrganizationIntegrationList from "../ProviderOrganizationIntegrationList";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ProviderOrganizationDetails() {
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const providerOrganizationList = useSelector(
    (state: AppState) => state.getProviderOrganizationListValue,
  );
  const providerOrgValues: ProviderOrganizationList | undefined = _.find(
    providerOrganizationList?.getProviderOrganizationRes?.providerOrganizationList,
    ["id", Number(organizationid)],
  );

  const loadingMarkup = <AdminLoader />;
  const [tabValue, setTabValue] = useState(0);
  const [patientListLoader, setPatientListLoader] = useState(false);
  const [doctorListLoader, setDoctorListLoader] = useState(false);
  const [providerOrgStaffListLoader, setProviderOrgStaffListLoader] = useState(false);
  const [selected, setSelected] = React.useState(1);
  const [patientListCount, setPatientListCount] = React.useState("0");
  const [doctorListCount, setDoctorListCount] = React.useState("0");
  const [staffListCount, setStaffListCount] = React.useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=provider-organization-details"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=patient-list"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=doctor-list"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=staff-list"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=report-list"}`);
    } else if (newValue === 5) {
      history.push(`${window.location.pathname + "?section=patient-details-list"}`);
    } else if (newValue === 6) {
      history.push(`${window.location.pathname + "?section=integration-list"}`);
    }
  };
  const viewProviderOrgStaffList = useSelector(
    (state: AppState) => state.getProviderOrgStaffListValue,
  );
  const changePtCount = (value: string) => {
    setPatientListCount(value);
  };

  const changeDoctorCount = (value: string) => {
    setDoctorListCount(value);
  };

  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "provider-organization-details") {
      setTabValue(0);
    } else if (tabId === "patient-list") {
      setTabValue(1);
    } else if (tabId === "doctor-list") {
      setTabValue(2);
    } else if (tabId === "staff-list") {
      setTabValue(3);
    } else if (tabId === "report-list") {
      setTabValue(4);
    } else if (tabId === "patient-details-list") {
      setTabValue(5);
    }  else if (tabId === "integration-list") {
      setTabValue(6);
    }
    // eslint-disable-next-line
  }, [tabId]);
  useEffect(() => {
    if (providerOrgValues === undefined) {
      dispatch(getProviderOrganizationListValue(1));
    }
    dispatch(getDoctorListValueAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOrgValues]);

  useEffect(() => {
    viewProviderOrgStaffList?.getProviderOrgStaffList?.count !== undefined
      ? setStaffListCount(String(viewProviderOrgStaffList?.getProviderOrgStaffList?.count))
      : setStaffListCount("0");
  }, [viewProviderOrgStaffList]);

  const providerOrganizationPatientDetailsListApi = () => {
    dispatch(providerOrgPtDetails({ providerorgid: organizationid } as ProviderOrgListBody, 1));
  };

  const organizationStaffListApi = () => {
    const viewProviderOrgStaffListBody = {
      organizationId: organizationid,
    } as GetProviderOrgStaffListBody;
    dispatch(getStaffListByProviderOrgId(viewProviderOrgStaffListBody, 1));
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const providerOrgListPage = (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Provider Organization Details</Typography>
                  </Grid>
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={patientListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    dispatch(
                      getPatientListByProviderOrganization(
                        {
                          providerorgId: organizationid,
                        } as GetProviderOrganizationPatientListBody,
                        1,
                      ),
                    );
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Doctor List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={doctorListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    dispatch(
                      getDoctorListByProviderOrganizationIdApi({
                        providerorgId: organizationid,
                      } as GetDoctorListByProviderOrganizationIdBody),
                    );
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Staff List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={staffListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    organizationStaffListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(3)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Report List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={"7"} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              // icon={
              //   <RefreshIcon
              //     onClick={() => {
              //       organizationListApi();
              //     }}
              //   />
              // }
              iconPosition="end"
              {...a11yProps(4)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient Details List</Typography>
                  </Grid>
                  <Grid item>{/* <Chip label={"7"} variant="outlined" size="small" /> */}</Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    providerOrganizationPatientDetailsListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(5)}
            />
             <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient Portal Connection</Typography>
                  </Grid>
                  <Grid item>{/* <Chip label={"7"} variant="outlined" size="small" /> */}</Grid>
                </Grid>
              }
              {...a11yProps(6)}
            />
          </Tabs>
          <Divider />
          {fullScreen ? (
            <Box pt={2}>
              {selected === 0 ? (
                <ProviderOrgDetailsComponent />
              ) : selected === 1 ? (
                <PatientListComponent
                  patientListCount={changePtCount}
                  patientListLoader={setPatientListLoader}
                  title={providerOrgValues?.displayName}
                />
              ) : selected === 2 ? (
                <DoctorListComponent
                  doctorListCount={changeDoctorCount}
                  doctorListLoader={setDoctorListLoader}
                  title={providerOrgValues?.displayName}
                />
              ) : selected === 3 ? (
                <ProviderOrganizationStaffList
                  providerOrgStaffListLoader={setProviderOrgStaffListLoader}
                />
              ) : selected === 4 ? (
                <ProviderOrganizationReportList />
              ) : selected === 5 ? (
                <PatientDetailsList />
              ) : (
                <ProviderOrganizationIntegrationList />
              )}
            </Box>
          ) : (
            <Box>
              <TabPanel value={tabValue} index={0}>
                <ProviderOrgDetailsComponent />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <PatientListComponent
                  patientListCount={changePtCount}
                  patientListLoader={setPatientListLoader}
                  title={providerOrgValues?.displayName}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <DoctorListComponent
                  doctorListCount={changeDoctorCount}
                  doctorListLoader={setDoctorListLoader}
                  title={providerOrgValues?.displayName}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <ProviderOrganizationStaffList
                  providerOrgStaffListLoader={setProviderOrgStaffListLoader}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <ProviderOrganizationReportList />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <PatientDetailsList />
              </TabPanel>
              <TabPanel value={tabValue} index={6}>
                <ProviderOrganizationIntegrationList />
              </TabPanel>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {patientListLoader === true ||
      doctorListLoader === true ||
      providerOrgStaffListLoader === true
        ? loadingMarkup
        : ""}
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle title={providerOrgValues?.displayName} backButton={true} />
          </Grid>
        </Grid>
        {providerOrgListPage}
      </PageLayout>
    </Box>
  );
}
