import { PageCount, AddStaffOfProviderOrgDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_STAFF_OF_PROVIDER_ORG_LOADING = "ADD_STAFF_OF_PROVIDER_ORG_LOADING";
export const ADD_STAFF_OF_PROVIDER_ORG_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_STAFF_OF_PROVIDER_ORG_FAIL = "ADD_STAFF_OF_PROVIDER_ORG_FAIL";
export const ADD_STAFF_OF_PROVIDER_ORG_DATA = "ADD_STAFF_OF_PROVIDER_ORG_DATA";
export const ADD_STAFF_OF_PROVIDER_ORG_API_MSG = "ADD_STAFF_OF_PROVIDER_ORG_API_MSG";
export const ADD_STAFF_OF_PROVIDER_ORG_API_RES = "ADD_STAFF_OF_PROVIDER_ORG_API_RES";

export interface AddStaffOfProviderOrgState {
  addStaffOfProviderOrgRes: AddStaffOfProviderOrgDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfProviderOrgLoading {
  type: typeof ADD_STAFF_OF_PROVIDER_ORG_LOADING;
  loading: boolean;
}
export interface AddStaffOfProviderOrgSuccess {
  type: typeof ADD_STAFF_OF_PROVIDER_ORG_SUCCESS;
  payload: AddStaffOfProviderOrgDetails;
  successMsg: string;
}
export interface AddStaffOfProviderOrgFail {
  type: typeof ADD_STAFF_OF_PROVIDER_ORG_FAIL;
  payload: AddStaffOfProviderOrgDetails;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfProviderOrgAPIMsg {
  type: typeof ADD_STAFF_OF_PROVIDER_ORG_API_MSG;
  payload: AddStaffOfProviderOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfProviderOrgAPIRes {
  type: typeof ADD_STAFF_OF_PROVIDER_ORG_API_RES;
  payload: AddStaffOfProviderOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddStaffOfOrgDataAction {
  type: typeof ADD_STAFF_OF_PROVIDER_ORG_DATA;
  payload: AddStaffOfProviderOrgDetails;
  pageCount: PageCount;
}

export type AddStaffOfProviderOrgActionTypes =
  | AddStaffOfOrgDataAction
  | AddStaffOfProviderOrgLoading
  | AddStaffOfProviderOrgSuccess
  | AddStaffOfProviderOrgFail
  | AddStaffOfProviderOrgAPIMsg
  | AddStaffOfProviderOrgAPIRes;
