import {
  GetJudgingFinalRoundDetailsListActionTypes,
  GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL,
  GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING,
  GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS,
  GetJudgingFinalRoundDetailsListState,
  GET_JUDGING_FINAL_ROUND_LIST_ID_API_MSG,
  GET_JUDGING_FINAL_ROUND_LIST_ID_API_RES,
} from "./ActionTypes";
import { GetJudgingFinalRoundDetailsListDetails } from "./Model";

const initialStateGetPosts: GetJudgingFinalRoundDetailsListState = {
  loading: false,
  getJudgingFinalRoundDetailsListRes: {} as GetJudgingFinalRoundDetailsListDetails,
  message: "",
  status: 0,
};
export const getJudgingFinalRoundDetailsListReducer = (
  state = initialStateGetPosts,
  action: GetJudgingFinalRoundDetailsListActionTypes,
): GetJudgingFinalRoundDetailsListState => {
  switch (action.type) {
    case GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING:
      return {
        ...state,
        loading: true,
        getJudgingFinalRoundDetailsListRes: {} as GetJudgingFinalRoundDetailsListDetails,
      };
    case GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getJudgingFinalRoundDetailsListRes: action.payload,
      };
    case GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL:
      return {
        ...state,
        loading: false,
        getJudgingFinalRoundDetailsListRes: {} as GetJudgingFinalRoundDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    case GET_JUDGING_FINAL_ROUND_LIST_ID_API_MSG:
      return {
        ...state,
        loading: false,
        getJudgingFinalRoundDetailsListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case GET_JUDGING_FINAL_ROUND_LIST_ID_API_RES:
      return {
        ...state,
        loading: false,
        getJudgingFinalRoundDetailsListRes: {} as GetJudgingFinalRoundDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
