import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getOrganizationListValue } from "../../../redux/OrganizationList/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MWExceptionList from "../../../component/MWExceptionList";

import {
  clearOrgPtSearchAPIRes,
  patientSearchByOrganizationIdApi,
} from "../../../redux/PatientSearchByOrganizationId/API";
import { PatientSearchByOrganizationIdBody } from "../../../redux/PatientSearchByOrganizationId/Model";
import {
  GetOrganizationPatientListBody,
  PatientList,
} from "../../../redux/PatientListByOrganization/Model";
import {
  clearOrgPatientListAPIRes,
  getPatientListByOrganization,
} from "../../../redux/PatientListByOrganization/API";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterDialog from "./FilterDialog";
import { getObservationTypeDetails } from "redux/GetObservationTypeList/API";
import { patientSearchByObservationTypeApi } from "redux/PatientSearchByObservationType/API";
import { PatientSearchListByObservationTypeBody } from "redux/PatientSearchByObservationType/Model";

type Props = {
  patientListByOrgLoader?: boolean | any;
  listCount?: string | any;
  obType: string | any;
  date: string | any;
};

export default function PatientListByOrganization({
  patientListByOrgLoader,
  listCount,
  obType,
  date,
}: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const history = useHistory();
  const [page, setPage] = useState(1);
  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  const [ptId, setPtId] = useState("");
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      history.push(`/patientdetails/${ptId}`);
    }
  };
  const [pageListCount, setPageListCount] = React.useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [showFilterDialog, setShowSecondRoundDialog] = React.useState(false);
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowSecondRoundDialog(passedVal);
  };

  useEffect(() => {
    dispatch(getObservationTypeDetails());
    if (obType !== null && date !== null) {
      dispatch(
        patientSearchByObservationTypeApi(
          {
            patientObservationType: decodeURIComponent(obType),
            organizationId: organizationid,
            startDate: decodeURIComponent(date),
          } as PatientSearchListByObservationTypeBody,
          1,
        ),
      );
    } else {
      const patientListBody = {
        organizationId: organizationid,
      } as GetOrganizationPatientListBody;
      dispatch(getPatientListByOrganization(patientListBody, page));
      dispatch(clearOrgPtSearchAPIRes());
      dispatch(getOrganizationListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.organizationPatientListValue);
  const SearchPatientList = useSelector(
    (state: AppState) => state.patientSearchByOrganizationIdValue,
  );
  const patientListByObservationType = useSelector(
    (state: AppState) => state.patientListByobservationTypeValue,
  );
  const allcaseValues = AdminPatientList?.PatientListOfOrganizationIdRes?.patientList;
  const searchPatientByOrganizationValue =
    SearchPatientList?.PatientSearchOrganizationIdRes?.patientList;
  const patientListByObservationTypeValue =
    patientListByObservationType?.patientSearchObservationTypeRes?.patientList;

  const PatientSearchByOrganizationBody = {
    searchKey: searchPatient,
    organizationId: organizationid,
  } as PatientSearchByOrganizationIdBody;

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (obType !== null && date !== null) {
      dispatch(
        patientSearchByObservationTypeApi(
          {
            patientObservationType: decodeURIComponent(obType),
            organizationId: organizationid,
            startDate: decodeURIComponent(date),
          } as PatientSearchListByObservationTypeBody,
          value,
        ),
      );
    } else {
      if (allcaseValues !== undefined) {
        const patientListBody = {
          organizationId: organizationid,
        } as GetOrganizationPatientListBody;
        dispatch(getPatientListByOrganization(patientListBody, value));
        dispatch(clearOrgPtSearchAPIRes());
      } else if (searchPatientByOrganizationValue !== undefined) {
        dispatch(patientSearchByOrganizationIdApi(PatientSearchByOrganizationBody, value));
        dispatch(clearOrgPatientListAPIRes());
      }
    }
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    AdminPatientList?.PatientListOfOrganizationIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(AdminPatientList?.PatientListOfOrganizationIdRes?.numberOfPages))
      : setPageListCount("0");
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        userName: element.userName,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        phone: element.phone,
        pincode: element.pincode,
        createdDate: element.createDate,
        modifiedDate: element.modifiedDate,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  useEffect(() => {
    SearchPatientList?.PatientSearchOrganizationIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(SearchPatientList?.PatientSearchOrganizationIdRes?.numberOfPages))
      : setPageListCount("0");
    if (SearchPatientList?.PatientSearchOrganizationIdRes?.count !== undefined) {
      listCount(SearchPatientList?.PatientSearchOrganizationIdRes?.count);
    }
    if (searchPatientByOrganizationValue !== undefined) {
      let dataPointArr = searchPatientByOrganizationValue.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        userName: element.userName,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        phone: element.phone,
        pincode: element.pincode,
        createdDate: element.createDate,
        modifiedDate: element.modifiedDate,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchPatientList]);

  useEffect(() => {
    patientListByObservationType?.patientSearchObservationTypeRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(patientListByObservationType?.patientSearchObservationTypeRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (patientListByObservationType?.patientSearchObservationTypeRes?.count !== undefined) {
      listCount(patientListByObservationType?.patientSearchObservationTypeRes?.count);
    }
    if (patientListByObservationTypeValue !== undefined) {
      let dataPointArr = patientListByObservationTypeValue.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        userName: element.userName,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        phone: element.phone,
        pincode: element.pincode,
        createdDate: element.createDate,
        modifiedDate: element.modifiedDate,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientListByObservationType]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    obType !== null && date !== null ? setOpen(true) : setOpen(false);
  }, [obType, date]);

  const PatientListOfOrganization = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Tooltip
                open={open}
                title={
                  obType !== null && date !== null
                    ? `Observation Type= ${obType}, Date= ${dayjs(date.split("T")[0]).format(
                        "DD/MM/YYYY",
                      )}`
                    : ""
                }
                arrow
              >
                <Button
                  variant="outlined"
                  startIcon={<FilterListIcon />}
                  onClick={() => {
                    setShowSecondRoundDialog(true);
                  }}
                >
                  Filter
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Box>
                    <TextField
                      label="Search Patient in this organization"
                      size="small"
                      value={searchPatient}
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          dispatch(
                            patientSearchByOrganizationIdApi(PatientSearchByOrganizationBody, 1),
                          );
                          dispatch(clearOrgPatientListAPIRes());
                          setPage(1);
                          ev.preventDefault();
                        }
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchPatient(event.target.value);
                      }}
                      sx={{ width: 280 }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onKeyDown={keyDownEvent}>
                    <TextField
                      label="Go To Patient ID"
                      size="small"
                      onChange={(event: any) => setPtId(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 0}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {(allcaseValues && allcaseValues.length) ||
          (searchPatientByOrganizationValue && searchPatientByOrganizationValue.length) ||
          (patientListByObservationTypeValue && patientListByObservationTypeValue.length) ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {AdminPatientList.loading === true
        ? patientListByOrgLoader(true)
        : patientListByOrgLoader(false)}
      {PatientListOfOrganization}
      {showFilterDialog ? (
        <FilterDialog
          filterDialogEnable={showFilterDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          organizationid={organizationid}
          obType={obType}
          date={date}
        />
      ) : null}
    </Box>
  );
}
