import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  clearToggleActiveRegistrationRequestAPIRes,
  toggleActiveRegistrationRequest,
} from "redux/ToggleActiveRegistrationRequest/API";
import { ToggleActiveRegReqBody } from "redux/ToggleActiveRegistrationRequest/Model";
import MWSnackbar from "component/MWSnackbar";
import { dashboardHealthCampRegistrationRequestList } from "redux/DashboardRegReqList/API";
import { GetDashboardHealthcampRequestListRes } from "redux/DashboardRegReqList/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function RegistrationRequestList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const registrationReqDetailsList = (rowIndex: any, healthcampId: number) => {
    if (healthcampId !== null) {
      history.push(`/healthcampregistrationrequestdetails/${healthcampId}/${rowIndex}`);
    }
  };
  const [dashboardHealthCampReqListValues, setDashboardHealthCampReqListValues] = useState(
    [] as any[],
  );

  // call Store
  const AdminDashboardHealthcampRegistrationReqList = useSelector(
    (state: AppState) => state.dashboardHealthCampRegistrationRequestListValue,
  );
  const ToggleActiveRegistrationRequestStoreVal = useSelector(
    (state: AppState) => state.toggleActiveRegistrationRequestValue,
  );

  const requestList: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            registrationReqDetailsList(params.value, params.row.healthcampId);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "regreqId",
      headerName: "ID",
      valueGetter: ({ id }) => {
        const item = dashboardHealthCampReqListValues.find((item) => item.id === id);
        return item.id;
      },
      minWidth: 80,
    },
    {
      field: "displayName",
      headerName: "Display Name",
      minWidth: 150,
      flex: 1,
    },
    { field: "ownerFirstName", headerName: "Owner First Name", minWidth: 200 },
    {
      field: "dateCreated",
      headerName: "Create Date",
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Request Status",
      sortable: false,
      hideable: false,
      minWidth: 120,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(
              toggleActiveRegistrationRequest({
                healthcampRegistrationRequestId: params.row.id,
              } as ToggleActiveRegReqBody),
            );
          }}
        >
          Deactive
        </Button>
      ),
    },
  ];

  const toggleActiveClose = () => {
    dispatch(clearToggleActiveRegistrationRequestAPIRes());
    setToggleActiveRegReqMsg("");
  };
  const [toggleActiveRegReqMsg, setToggleActiveRegReqMsg] = useState("");
  const [toggleActiveRegReqMsgType, setToggleActiveRegReqMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (ToggleActiveRegistrationRequestStoreVal?.successMsg !== undefined) {
      setToggleActiveRegReqMsgType("success");
      setToggleActiveRegReqMsg(ToggleActiveRegistrationRequestStoreVal?.successMsg);
      dispatch(
        dashboardHealthCampRegistrationRequestList({} as GetDashboardHealthcampRequestListRes),
      );
    }
    if (ToggleActiveRegistrationRequestStoreVal?.errorMsg !== "") {
      setToggleActiveRegReqMsgType("error");
      setToggleActiveRegReqMsg(ToggleActiveRegistrationRequestStoreVal?.errorMsg);
    }
  }, [ToggleActiveRegistrationRequestStoreVal, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      dashboardHealthCampRegistrationRequestList({} as GetDashboardHealthcampRequestListRes),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminDashboardHealthcampRegistrationReqList?.loading));
    if (
      AdminDashboardHealthcampRegistrationReqList?.getDashboardHealthcampRegistrationListResponse
        ?.healthcampRegistrationRequestList !== undefined
    ) {
      let regRequestList =
        AdminDashboardHealthcampRegistrationReqList?.getDashboardHealthcampRegistrationListResponse?.healthcampRegistrationRequestList.map(
          (element: any) => ({
            id: element.id,
            displayName: element?.displayName !== "" ? element?.displayName : "",
            dateCreated: dayjs(element.dateCreated).format("ddd, MMM D, YYYY"),
            ownerFirstName: element?.ownerFirstName !== "" ? element?.ownerFirstName : "",
            healthcampId: element?.healthcampId !== null ? element?.healthcampId : "",
            healthcampDisplayName:
              element?.healthcampDisplayName !== "" ? element?.healthcampDisplayName : "",
          }),
        );
      setDashboardHealthCampReqListValues(regRequestList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminDashboardHealthcampRegistrationReqList]);

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6"> Request List</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {dashboardHealthCampReqListValues && dashboardHealthCampReqListValues.length ? (
          <DataGrid
            rows={dashboardHealthCampReqListValues}
            columns={requestList}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {actualPageMarkup}
      {toggleActiveRegReqMsg !== "" ? (
        <MWSnackbar
          msg={toggleActiveRegReqMsg}
          type={toggleActiveRegReqMsgType}
          alertClose={toggleActiveClose}
        />
      ) : null}
    </Box>
  );
}
