import { OrganizationListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const ORGANIZATION_LIST_BY_PATIENT_ID_LOADING = "ORGANIZATION_LIST_BY_PATIENT_ID_LOADING";
export const ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS = "ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS";
export const ORGANIZATION_LIST_BY_PATIENT_ID_FAIL = "ORGANIZATION_LIST_BY_PATIENT_ID_FAIL";
export const ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG = "ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG";
export const ORGANIZATION_LIST_BY_PATIENT_ID_API_RES = "ORGANIZATION_LIST_BY_PATIENT_ID_API_RES";

export interface OrganizationListByPatientIdDetailsState {
  organizationListByPatientIdRes: OrganizationListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface OrganizationListByPatientIdLoading {
  type: typeof ORGANIZATION_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface OrganizationListByPatientIdSuccess {
  type: typeof ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS;
  payload: OrganizationListByPatientIdDetails;
  successMsg: string;
}
export interface OrganizationListByPatientIdFail {
  type: typeof ORGANIZATION_LIST_BY_PATIENT_ID_FAIL;
  payload: OrganizationListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface OrganizationListByPatientIdAPIMsg {
  type: typeof ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG;
  payload: OrganizationListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof ORGANIZATION_LIST_BY_PATIENT_ID_API_RES;
  payload: OrganizationListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type OrganizationListByPatientIdActionTypes =
  | OrganizationListByPatientIdLoading
  | OrganizationListByPatientIdSuccess
  | OrganizationListByPatientIdFail
  | OrganizationListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
