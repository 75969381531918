import { PageCount, ExecutiveSearchDetails } from "./Model";

// Patient Demographics Loading State
export const EXECUTIVE_SEARCH_LOADING = "EXECUTIVE_SEARCH_LOADING";
export const EXECUTIVE_SEARCH_SUCCESS = "EXECUTIVE_SEARCH_SUCCESS";
export const EXECUTIVE_SEARCH_FAIL = "EXECUTIVE_SEARCH_FAIL";
export const EXECUTIVE_SEARCH_DATA = "EXECUTIVE_SEARCH_DATA";
export const EXECUTIVE_SEARCH_UPDATE_API_MSG = "EXECUTIVE_SEARCH_UPDATE_API_MSG";
export const EXECUTIVE_SEARCH_UPDATE_API_RES = "EXECUTIVE_SEARCH_UPDATE_API_RES";

export interface ExecutiveSearchDetailsState {
  executiveSearchRes: ExecutiveSearchDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface ExecutivePointDataLoading {
  type: typeof EXECUTIVE_SEARCH_LOADING;
  loading: boolean;
}
export interface ExecutivePointDataSuccess {
  type: typeof EXECUTIVE_SEARCH_SUCCESS;
  payload: ExecutiveSearchDetails;
}
export interface ExecutivePointDataFail {
  type: typeof EXECUTIVE_SEARCH_FAIL;
  payload: ExecutiveSearchDetails;
  message: string;
  status: number;
}
export interface ExecutivePointDataUpdateAPIMsg {
  type: typeof EXECUTIVE_SEARCH_UPDATE_API_MSG;
  payload: ExecutiveSearchDetails;
  message: string;
  status: number;
}
export interface ExecutivePointDataUpdateAPIRes {
  type: typeof EXECUTIVE_SEARCH_UPDATE_API_RES;
  payload: ExecutiveSearchDetails;
  message: string;
  status: number;
}
interface ExecutivePointDataAction {
  type: typeof EXECUTIVE_SEARCH_DATA;
  payload: ExecutiveSearchDetails;
  pageCount: PageCount;
}

export type ExecutiveSearchActionTypes =
  | ExecutivePointDataAction
  | ExecutivePointDataLoading
  | ExecutivePointDataSuccess
  | ExecutivePointDataFail
  | ExecutivePointDataUpdateAPIMsg
  | ExecutivePointDataUpdateAPIRes;
