import { GetSingleEncounterListRes, PageCount } from "./Model";
export const GET_SINGLE_ENCOUNTER_LIST_LOADING =
  "GET_SINGLE_ENCOUNTER_LIST_LOADING";
export const GET_SINGLE_ENCOUNTER_LIST_SUCCESS =
  "GET_SINGLE_ENCOUNTER_LIST_SUCCESS";
export const GET_SINGLE_ENCOUNTER_LIST_FAIL =
  "GET_SINGLE_ENCOUNTER_LIST_FAIL";
export const GET_SINGLE_ENCOUNTER_LIST_DATA =
  "GET_SINGLE_ENCOUNTER_LIST_DATA";
export const GET_SINGLE_ENCOUNTER_LIST_API_MSG =
  "GET_SINGLE_ENCOUNTER_LIST_API_MSG";
export const GET_SINGLE_ENCOUNTER_LIST_API_RES =
  "GET_SINGLE_ENCOUNTER_LIST_API_RES";

export interface GetSingleEncounterListState {
  getSingleEncounterListRes: GetSingleEncounterListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetSingleEncounterListLoading {
  type: typeof GET_SINGLE_ENCOUNTER_LIST_LOADING;
  loading: boolean;
}
export interface GetSingleEncounterListSuccess {
  type: typeof GET_SINGLE_ENCOUNTER_LIST_SUCCESS;
  payload: GetSingleEncounterListRes;
}
export interface GetSingleEncounterListFail {
  type: typeof GET_SINGLE_ENCOUNTER_LIST_FAIL;
  payload: GetSingleEncounterListRes;
  errorMsg: string;
  status: number;
}
export interface GetSingleEncounterListAPIMsg {
  type: typeof GET_SINGLE_ENCOUNTER_LIST_API_MSG;
  payload: GetSingleEncounterListRes;
  errorMsg: string;
  status: number;
}
export interface GetSingleEncounterListAPIRes {
  type: typeof GET_SINGLE_ENCOUNTER_LIST_API_RES;
  payload: GetSingleEncounterListRes;
  errorMsg: string;
  status: number;
}

interface GetSingleEncounterListDataAction {
  type: typeof GET_SINGLE_ENCOUNTER_LIST_DATA;
  payload: GetSingleEncounterListRes;
  pageCount: PageCount;
}

export type GetSingleEncounterListActionTypes =
  | GetSingleEncounterListLoading
  | GetSingleEncounterListSuccess
  | GetSingleEncounterListFail
  | GetSingleEncounterListAPIMsg
  | GetSingleEncounterListDataAction
  | GetSingleEncounterListAPIRes;
