import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../component/MWExceptionList";
import { GetStaffListByCompetitionBody, StaffList } from "redux/StaffListByCompetition/Model";
import { getStaffListByCompition } from "redux/StaffListByCompetition/API";

type Props = {
    staffListOfCompetitonLoader?: boolean | any;
};

export default function StaffListOfCompetition({ staffListOfCompetitonLoader }: Props) {
    /*  create a piece of state (toSignin)
      with a method to update that state (setToHome)
      and give it a default value of false */
    const dispatch = useDispatch();
    const { competitionid } = useParams() as {
        competitionid: string;
    };
    const [page, setPage] = useState(1);
    const [pageListCount, setPageListCount] = React.useState("");


    useEffect(() => {
        window.scrollTo(0, 0);
        const patientListBody = {
            competitionId: competitionid,
        } as GetStaffListByCompetitionBody;
        dispatch(getStaffListByCompition(patientListBody, 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // call Store
    const getStaffListValues = useSelector((state: AppState) => state.getStaffListByCompetitionValue);
    const staffListValues = getStaffListValues?.getStaffListByCompetitionRes?.StaffList;

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        if (staffListValues !== undefined) {
            const patientListBody = {
                competitionId: competitionid,
            } as GetStaffListByCompetitionBody;
            dispatch(getStaffListByCompition(patientListBody, value));
        }
    };
    const [dataGridArray, setDataGridArray] = useState([] as any[]);
    useEffect(() => {
        getStaffListValues?.getStaffListByCompetitionRes?.numberOfPages !== undefined
            ? setPageListCount(String(getStaffListValues?.getStaffListByCompetitionRes?.numberOfPages))
            : setPageListCount("0");
        if (getStaffListValues?.getStaffListByCompetitionRes?.StaffList !== undefined) {
            let dataPointArr = getStaffListValues?.getStaffListByCompetitionRes?.StaffList.map(
                (element: StaffList) => ({
                    id: element.staffId,
                    staffId: element.staffId,
                    staffFullName: element.staffFullName,
                    staffEmail: element.staffEmail,
                }),
            );
            setDataGridArray(dataPointArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getStaffListValues]);


    const columns: GridColDef[] = [
        { field: "staffId", headerName: "Staff Id", width: 250, flex: 1 },
        { field: "staffFullName", headerName: "Staff Name", width: 500, flex: 1 },
        { field: "staffEmail", headerName: "Email", width: 500, flex: 1 },
    ];

    const StaffListOfCompetition = (
        <Card>
            <CardHeader
                title={
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item></Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                spacing={2}
                                pt={2}
                            >
                                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                                <Pagination
                                    color="primary"
                                    count={Number(pageListCount) || 0}
                                    page={page}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent>
                <Box>
                    {staffListValues && staffListValues.length ? (
                        <DataGrid
                            rows={dataGridArray}
                            columns={columns}
                            disableSelectionOnClick
                            hideFooter
                            hideFooterPagination
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true },
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 250 },
                                },
                            }}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                        />
                    ) : (
                        <MWExceptionList />
                    )}
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <Box>
            {getStaffListValues.loading === true
                ? staffListOfCompetitonLoader(true)
                : staffListOfCompetitonLoader(false)}
            <Container maxWidth="xl">
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    pt={2}
                >
                    {StaffListOfCompetition}
                </Stack>
            </Container>
        </Box>
    );
}
