import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import { clearAddRegistrationRequestDetailsAPIRes } from "redux/HealthCampRegistrationRequestList/AddRegistrationRequestDetails/API";
import { patientDetailsTypeList } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/API";
import { PatientDetailsTypeList } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/Model";
import { AddPatientDetailsTypeToSessionBody } from "redux/GetPatientDetailsTypeOfSession/AddPatientDetailsTypeToSession/Model";
import {
  addPatientDetailsTypeToSession,
  clearAddPatientDetailsTypeToSessionListAPIRes,
} from "redux/GetPatientDetailsTypeOfSession/AddPatientDetailsTypeToSession/API";
import { useParams } from "react-router-dom";
type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
};

export default function AddMappingDialog({ filterDialogEnable, filterDialogUpdateState }: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);

  // call store
  const patientDetailsTypeListValue = useSelector((state: AppState) => state.patientDetailsType);

  const [patientDetailsTypeName, setPatientDetailsTypeName] = useState("");

  const [patientDetailsTypeDropDownList, setPatientDetailsTypeDropDownList] = React.useState(
    [] as any[],
  );
  const { sessionId } = useParams() as {
    sessionId: string;
  };

  const filterPatientDetailsType = (patientDetailsType: any, _e: any) => {
    setPatientDetailsTypeName(patientDetailsType.id);
  };

  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearAddRegistrationRequestDetailsAPIRes());
  };

  useEffect(() => {
    dispatch(patientDetailsTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      patientDetailsTypeListValue?.patientDetailsTypeListRes?.patientDetailsTypeList !== undefined
    ) {
      let patientDetailsTypeDropdownValue =
        patientDetailsTypeListValue?.patientDetailsTypeListRes?.patientDetailsTypeList.map(
          (element: PatientDetailsTypeList) => ({
            id: element.id,
            displayValue: element.displayValue !== "" ? element.displayValue : "",
            name: element.name !== "" ? element.name : "",
            sequence: element.sequence,
          }),
        );
      setPatientDetailsTypeDropDownList(patientDetailsTypeDropdownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsTypeListValue]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    dispatch(clearAddPatientDetailsTypeToSessionListAPIRes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const addMappingtDetails = () => {
    if (patientDetailsTypeName !== "") {
      const healthcampAppoinmentListBody = {
        healthcampSessionId: sessionId,
        patientDetailsTypeId: patientDetailsTypeName,
      } as AddPatientDetailsTypeToSessionBody;
      dispatch(addPatientDetailsTypeToSession(healthcampAppoinmentListBody));
    }
  };

  return (
    <Box>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {patientDetailsTypeListValue?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Add Mapping With Patient Details Type</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                fullWidth
                id="controllable-states-demo"
                getOptionLabel={(option) => `${option.displayValue}`}
                options={patientDetailsTypeDropDownList}
                onChange={(event: any, newValue: any) => {
                  filterPatientDetailsType(newValue, event);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Patient Details Type Name" variant="outlined" />
                )}
                size="small"
                disableClearable
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={() => addMappingtDetails()}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
