import { VitalRequestResponse } from "./Model";
export const VITALREQUEST_LIST_LOADING = "VITALREQUEST_LIST_LOADING";
export const VITALREQUEST_LIST_SUCCESS = "VITALREQUEST_LIST_SUCCESS";
export const VITALREQUEST_LIST_FAIL = "VITALREQUEST_LIST_FAIL";
export const VITALREQUEST_LIST_UPDATE_API_MSG = "VITALREQUEST_LIST_UPDATE_API_MSG";
export const VITALREQUEST_LIST_UPDATE_API_RES = "VITALREQUEST_LIST_UPDATE_API_RES";

export interface VitalRequestListState {
  vitalRequestListRes: VitalRequestResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface VitalRequestListLoading {
  type: typeof VITALREQUEST_LIST_LOADING;
  loading: boolean;
}
export interface VitalRequestListSuccess {
  type: typeof VITALREQUEST_LIST_SUCCESS;
  payload: VitalRequestResponse;
  successMsg: string;
}
export interface VitalRequestListFail {
  type: typeof VITALREQUEST_LIST_FAIL;
  payload: VitalRequestResponse;
  errorMsg: string;
  status: number;
}
export interface VitalRequestListUpdateAPIMsg {
  type: typeof VITALREQUEST_LIST_UPDATE_API_MSG;
  payload: VitalRequestResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface VitalRequestListUpdateAPIRes {
  type: typeof VITALREQUEST_LIST_UPDATE_API_RES;
  payload: VitalRequestResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type VitalRequestListActionTypes =
  | VitalRequestListLoading
  | VitalRequestListSuccess
  | VitalRequestListFail
  | VitalRequestListUpdateAPIMsg
  | VitalRequestListUpdateAPIRes;
