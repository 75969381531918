import { ExecutiveListResponse } from "./Model";

export const EXECUTIVE_LIST_LOADING = "EXECUTIVE_LIST_LOADING";
export const EXECUTIVE_LIST_SUCCESS = "EXECUTIVE_LIST_SUCCESS";
export const EXECUTIVE_LIST_FAIL = "EXECUTIVE_LIST_FAIL";
export const EXECUTIVE_LIST_API_MSG = "EXECUTIVE_LIST_API_MSG";
export const EXECUTIVE_LIST_CLEAR_API_RES = "EXECUTIVE_LIST_CLEAR_API_RES";

export interface ExecutiveListDataState {
  executiveRes: ExecutiveListResponse;
  loading: boolean;
  message: string;
  status: number;
}

export interface ExecutiveListLoading {
  type: typeof EXECUTIVE_LIST_LOADING;
  loading: boolean;
}
export interface ExecutiveListSuccess {
  type: typeof EXECUTIVE_LIST_SUCCESS;
  payload: ExecutiveListResponse;
}
export interface ExecutiveListFail {
  type: typeof EXECUTIVE_LIST_FAIL;
  payload: ExecutiveListResponse;
  message: string;
  status: number;
}
export interface ExecutiveAPIMsg {
  type: typeof EXECUTIVE_LIST_API_MSG;
  payload: ExecutiveListResponse;
  message: string;
  status: number;
}
export interface ExecutiveAPIRes {
  type: typeof EXECUTIVE_LIST_CLEAR_API_RES;
  payload: ExecutiveListResponse;
  message: string;
  status: number;
}

export type ExecutiveListActionTypes =
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail
  | ExecutiveAPIMsg
  | ExecutiveAPIRes;
