import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPreferenceDetails } from "redux/Preference/GetPreference/API";
import { AppState } from "redux/store/Store";
import { PatientOrganizationList } from "redux/OrganizationList/Model";
import {
  ModifiedHealthCampList,
  ModifiedInstanceList,
  ModifiedSessionList,
} from "./SetPreferenceDialog/Model";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { GetSessionListBody, HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { getOrganizationListValue } from "redux/OrganizationList/API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import { HealthCampList } from "redux/healthcampList/Model";
import {
  clearSetPreferenceAPIRes,
  setPreference,
  setPreferenceAPIResMsg,
} from "redux/Preference/SetPreference/API";
import { SetPreferenceBody } from "redux/Preference/SetPreference/Model";

type Props = {
  disable: (value: boolean) => void;
};
export default function PreferenceView({ disable }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getPreferenceDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const allPreferenceRes = useSelector((state: AppState) => state.getPreferenceDetails);

  const [enableEdit, setEnableEdit] = useState(false);

  const [organizationList, setOrganizationList] = React.useState([] as PatientOrganizationList[]);
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<PatientOrganizationList | null>(null);
  const [healthCampList, setHealthCampList] = React.useState([] as ModifiedHealthCampList[]);
  const [selectedHealthCamp, setSelectedHealthCamp] = React.useState<ModifiedHealthCampList | null>(
    null,
  );
  const changeSelectedOrganization = (value: PatientOrganizationList | null) => {
    setSelectedOrganization(value);
  };
  // Call Organization List Store
  const organizationListRes = useSelector((state: AppState) => state.organizationListValue);
  const allPreferenceValues = useSelector((state: AppState) => state.getPreferenceDetails);
  useEffect(() => {
    if (organizationListRes?.getOrganizationRes?.patientOrganizationList !== undefined) {
      let orgDropdownValue = organizationListRes?.getOrganizationRes?.patientOrganizationList.map(
        (element: PatientOrganizationList) => ({
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
        }),
      );
      setOrganizationList(orgDropdownValue);
    } else setOrganizationList([] as PatientOrganizationList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationListRes]);

  useEffect(() => {
    if (
      allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences !== undefined
    ) {
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.organizationId !== undefined
      ) {
        let organizationObj = {
          displayName:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.organizationName,
          id: allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.organizationId,
        };
        setSelectedOrganization(organizationObj);
      } else setSelectedOrganization(null);
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampId !== undefined
      ) {
        let healthcampObj = {
          label:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampName,
          value:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampId,
        };
        setSelectedHealthCamp(healthcampObj);
      } else setSelectedHealthCamp(null);
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampInstanceId !== undefined
      ) {
        let healthcampInstanceObj = {
          label:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampInstanceName,
          value:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampInstanceId,
        };
        setSelectedInstance(healthcampInstanceObj);
      } else setSelectedInstance(null);
      if (
        allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampSessionId !== null
      ) {
        let healthcampInstanceSessionObj = {
          label:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampSessionName,
          value:
            allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
              ?.healthcampSessionId,
        };
        setSelectedSession(healthcampInstanceSessionObj);
      } else setSelectedSession(null);
    } else {
      setSelectedHealthCamp(null);
      setSelectedOrganization(null);
      setSelectedInstance(null);
      setSelectedSession(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPreferenceValues]);

  const changeSelectedHealthCamp = (passedVal: any | null) => {
    setSelectedHealthCamp(passedVal);
    if (passedVal !== null) {
      const viewHealthcampStaffListBody = {
        healthcampId: passedVal.value,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
  };
  // Call All HealthCamp List Store
  const adminAllHealthCampListRes = useSelector((state: AppState) => state.healthcampListValue);
  useEffect(() => {
    if (adminAllHealthCampListRes?.healthcampListValue?.HealthCampList !== undefined) {
      let healthCampDropdownValue =
        adminAllHealthCampListRes?.healthcampListValue?.HealthCampList.map(
          (element: HealthCampList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setHealthCampList(healthCampDropdownValue);
    } else setHealthCampList([] as ModifiedHealthCampList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAllHealthCampListRes]);

  const [instanceList, setInstanceList] = React.useState([] as ModifiedInstanceList[]);
  const [selectedInstance, setSelectedInstance] = React.useState<ModifiedInstanceList | null>(null);
  const changeSelectedInstance = (passedVal: ModifiedInstanceList | null) => {
    setSelectedInstance(passedVal);
    if (passedVal !== null) {
      const healthcampSessionListBody = {
        healthcampInstanceId: String(passedVal?.value),
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
  };
  // Call Instance List Store
  const getInstanceListRes = useSelector((state: AppState) => state.instanceListValue);
  useEffect(() => {
    if (getInstanceListRes?.getInstanceList?.healthcampInstanceList !== undefined) {
      let instanceListDropdownValue =
        getInstanceListRes?.getInstanceList?.healthcampInstanceList.map(
          (element: HealthcampInstanceList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setInstanceList(instanceListDropdownValue);
    } else setInstanceList([] as ModifiedInstanceList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstanceListRes]);

  const getSeasonListRes = useSelector((state: AppState) => state.sessionListValues);

  useEffect(() => {
    dispatch(getOrganizationListValue());
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [sessionList, setSessionList] = React.useState([] as ModifiedSessionList[]);
  const [selectedSession, setSelectedSession] = React.useState<ModifiedSessionList | null>(null);
  const changeSelectedSession = (passedVal: ModifiedSessionList | null) => {
    setSelectedSession(passedVal);
  };
  useEffect(() => {
    if (getSeasonListRes?.getSessionList?.healthcampSessionList !== undefined) {
      let healthCampSessionListDropdownValue =
        getSeasonListRes?.getSessionList?.healthcampSessionList.map(
          (element: HealthcampSessionList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setSessionList(healthCampSessionListDropdownValue);
    } else setSessionList([] as ModifiedSessionList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeasonListRes]);

  const OrganizationPatientBody = {
    organizationId:
      selectedOrganization !== null
        ? String(selectedOrganization?.id)
        : allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.organizationId !== undefined
        ? -1
        : null,
    healthCampId:
      selectedHealthCamp !== null
        ? String(selectedHealthCamp?.value)
        : allPreferenceValues?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.healthcampId
        ? -1
        : null,
    healthcampInstanceId: selectedInstance !== null ? String(selectedInstance?.value) : null,
    healthcampSessionId: selectedSession !== null ? String(selectedSession?.value) : null,
  } as SetPreferenceBody;

  const setPreferenceSubmit = () => {
    dispatch(setPreference(OrganizationPatientBody));
  };

  const [setPreferenceAPIMsgType, setSetPreferenceAPIMsgType] = useState<AlertColor>("success");
  const [setPreferenceAPIMsg, setSetPreferenceAPIMsg] = useState("");
  // Call Set Preference Store
  const setPreferenceValueRes = useSelector((state: AppState) => state.setPreferenceValue);
  useEffect(() => {
    if (setPreferenceValueRes?.successMsg !== "") {
      setSetPreferenceAPIMsgType("success");
      setSetPreferenceAPIMsg(setPreferenceValueRes?.successMsg);
      setEnableEdit(false);
      dispatch(getPreferenceDetails());
      dispatch(clearSetPreferenceAPIRes());
    }
    if (setPreferenceValueRes?.errorMsg !== "") {
      setSetPreferenceAPIMsgType("error");
      setSetPreferenceAPIMsg(setPreferenceValueRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPreferenceValueRes]);

  const gotoOrganization = (organizationId: number) => {
    history.push(`/patientlistbyorganization/${organizationId}`);
    disable(false);
  };
  const gotoHealthCamp = (healthCampId: number) => {
    history.push(`/patientlistbyhealthcamp/${healthCampId}`);
    disable(false);
  };

  const gotoInstanceList = (healthCampId: number, instanceId: number) => {
    history.push(`/healthcampinstance/${healthCampId}/${instanceId}`);
    disable(false);
  };
  const gotoSessionDetails = (healthCampId: number, instanceId: number, sessionId: number) => {
    console.log("gotoSessionDetails", healthCampId, instanceId, sessionId);
    history.push(`/healthcampinstance_session/${healthCampId}/${instanceId}/${sessionId}`);
    // history.push(`/healthcampinstance_session/36/7/13`)
    disable(false);
  };

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} m={2}>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              Preference
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={() => setEnableEdit(!enableEdit)}>
              {!enableEdit ? <EditIcon /> : <EditOffIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Organization Name:
        </Typography>
        {!enableEdit ? (
          <Typography
            onClick={() =>
              selectedOrganization !== null && gotoOrganization(selectedOrganization?.id)
            }
            sx={{ color: "#03d3f3", cursor: "pointer" }}
          >
            {selectedOrganization !== null ? selectedOrganization?.displayName : "N/A"}
          </Typography>
        ) : (
          <Autocomplete
            fullWidth
            size="small"
            disabled={setPreferenceValueRes?.loading}
            value={selectedOrganization}
            options={organizationList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedOrganization(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.displayName}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.id}
          />
        )}
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Staff Name:
        </Typography>
        <Typography sx={{ color: "#03d3f3" }}>
          {allPreferenceRes?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
            ?.staffName !== undefined
            ? allPreferenceRes?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences?.staffName
            : "N/A"}
        </Typography>
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Healthcamp Name:
        </Typography>
        {!enableEdit ? (
          <Typography
            onClick={() => selectedHealthCamp !== null && gotoHealthCamp(selectedHealthCamp?.value)}
            sx={{ color: "#03d3f3", cursor: "pointer" }}
          >
            {selectedHealthCamp !== null ? selectedHealthCamp?.label : "N/A"}
          </Typography>
        ) : (
          <Autocomplete
            fullWidth
            size="small"
            disabled={setPreferenceValueRes?.loading}
            value={selectedHealthCamp}
            options={healthCampList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedHealthCamp(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Healthcamp Name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.value}
          />
        )}
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Instance Name:
        </Typography>
        {!enableEdit ? (
          <Typography
            onClick={() =>
              selectedHealthCamp !== null &&
              selectedInstance !== null &&
              gotoInstanceList(selectedHealthCamp?.value, selectedInstance?.value)
            }
            sx={{ color: "#03d3f3", cursor: "pointer" }}
          >
            {selectedInstance !== null ? selectedInstance?.label : "N/A"}
          </Typography>
        ) : (
          <Autocomplete
            fullWidth
            id="controllable-states-demo"
            disabled={setPreferenceValueRes?.loading}
            value={selectedInstance}
            options={instanceList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedInstance(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instance name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.value}
            size="small"
          />
        )}
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Session Name:
        </Typography>
        {!enableEdit ? (
          <Typography
            onClick={() =>
              selectedHealthCamp !== null &&
              selectedInstance !== null &&
              selectedSession !== null &&
              gotoSessionDetails(
                selectedHealthCamp?.value,
                selectedInstance?.value,
                selectedSession?.value,
              )
            }
            sx={{ color: "#03d3f3", cursor: "pointer" }}
          >
            {selectedSession !== null ? selectedSession?.label : "N/A"}
          </Typography>
        ) : (
          <Autocomplete
            size="small"
            fullWidth
            disabled={setPreferenceValueRes?.loading}
            value={selectedSession}
            options={sessionList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedSession(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Session name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.value}
          />
        )}
      </Stack>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Preference Id:
        </Typography>
        <Typography>
          {allPreferenceRes?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences?.id}
        </Typography>
      </Stack>
      {enableEdit && (
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Divider />
          {setPreferenceAPIMsgType === "error" && setPreferenceAPIMsg !== "" ? (
            <Box py={2}>
              <Alert
                severity={setPreferenceAPIMsgType}
                onClose={() => {
                  setSetPreferenceAPIMsg("");
                  dispatch(setPreferenceAPIResMsg());
                }}
              >
                {setPreferenceAPIMsg}
              </Alert>
            </Box>
          ) : null}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                autoFocus
                size="small"
                disabled={setPreferenceValueRes?.loading}
                onClick={() => {
                  setEnableEdit(false);
                }}
                sx={{ textTransform: "none" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                disabled={
                  setPreferenceValueRes?.loading ||
                  (selectedOrganization === null &&
                    selectedHealthCamp === null &&
                    selectedInstance === null &&
                    selectedSession === null)
                }
                onClick={() => {
                  setPreferenceSubmit();
                }}
                sx={{ textTransform: "none" }}
              >
                {setPreferenceValueRes?.loading ? <CircularProgress /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      )}
    </Stack>
  );
}
