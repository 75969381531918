import {
  VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_FAIL,
  VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_LOADING,
  VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_SUCCESS,
  VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_MSG,
  VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_RES,
  VitalCollectionActiveInactiveStatusActionTypes,
} from "./ActionTypes";
import { VitalCollectionActiveInactiveStatusRes } from "./Model";

export const vitalCollectionActiveInactiveStatusLoadingAction = (
  loading: boolean,
): VitalCollectionActiveInactiveStatusActionTypes => {
  return {
    type: VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_LOADING,
    loading: loading,
  };
};

export const vitalCollectionActiveInactiveStatusSuccessAction = (
  vitalCollectionActiveInactiveStatusResponse: VitalCollectionActiveInactiveStatusRes,
  successMsg: string,
): VitalCollectionActiveInactiveStatusActionTypes => {
  return {
    type: VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_SUCCESS,
    payload: vitalCollectionActiveInactiveStatusResponse,
    successMsg: successMsg,
  };
};

export const vitalCollectionActiveInactiveStatusErrorAction = (
  vitalCollectionActiveInactiveStatusResponse: VitalCollectionActiveInactiveStatusRes,
  errMsg: string,
  status: number,
): VitalCollectionActiveInactiveStatusActionTypes => {
  return {
    type: VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_FAIL,
    payload: vitalCollectionActiveInactiveStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const vitalCollectionActiveInactiveStatusUpdateAPIMsgAction = (
  vitalCollectionActiveInactiveStatusResponse: VitalCollectionActiveInactiveStatusRes,
  successMsg: string,
  errMsg: string,
  status: number,
): VitalCollectionActiveInactiveStatusActionTypes => {
  return {
    type: VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_MSG,
    payload: vitalCollectionActiveInactiveStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const vitalCollectionActiveInactiveStatusAPIResClearAction = (
  vitalCollectionActiveInactiveStatusResponse: VitalCollectionActiveInactiveStatusRes,
  successMsg: string,
  errMsg: string,
  status: number,
): VitalCollectionActiveInactiveStatusActionTypes => {
  return {
    type: VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_RES,
    payload: vitalCollectionActiveInactiveStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
