import { CompetitionListByDuplicatePatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING = "COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING";
export const COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS = "COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS";
export const COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL = "COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL";
export const COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG = "COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG";
export const COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES = "COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES";

export interface CompetitionListByDuplicatePatientIdDetailsState {
  competitionListByDuplicatePatientIdRes: CompetitionListByDuplicatePatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface CompetitionListByDuplicatePatientIdLoading {
  type: typeof COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface CompetitionListByDuplicatePatientIdSuccess {
  type: typeof COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS;
  payload: CompetitionListByDuplicatePatientIdDetails;
  successMsg: string;
}
export interface CompetitionListByDuplicatePatientIdFail {
  type: typeof COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL;
  payload: CompetitionListByDuplicatePatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface CompetitionListByDuplicatePatientIdAPIMsg {
  type: typeof COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG;
  payload: CompetitionListByDuplicatePatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES;
  payload: CompetitionListByDuplicatePatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type CompetitionListByDuplicatePatientIdActionTypes =
  | CompetitionListByDuplicatePatientIdLoading
  | CompetitionListByDuplicatePatientIdSuccess
  | CompetitionListByDuplicatePatientIdFail
  | CompetitionListByDuplicatePatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
