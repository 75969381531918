import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetViewCompetitionStaffListActionTypes } from "./ActionTypes";
import { GetViewCompetitionStaffListBody, GetViewCompetitionStaffListDetails } from "./Model";
import {
  getViewCompetitionStaffListAPIResClearAction,
  getViewCompetitionStaffListErrorAction,
  getViewCompetitionStaffListLoadingAction,
  getViewCompetitionStaffListSuccessAction,
  getViewCompetitionStaffListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetViewCompetitionStaffListDetails;
export const getViewCompetitionStaffList = (
  competitionId: GetViewCompetitionStaffListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetViewCompetitionStaffListActionTypes>) {
    dispatch(getViewCompetitionStaffListLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionStaffList/staff_list_patient_competition?page=${Number(
            pageNo,
          )}&size=50`,
          competitionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getViewCompetitionStaffListLoadingAction(false));
        dispatch(
          getViewCompetitionStaffListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetViewCompetitionStaffListDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getViewCompetitionStaffListLoadingAction(false));
        dispatch(
          getViewCompetitionStaffListErrorAction(
            {} as GetViewCompetitionStaffListDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const viewCompetitionStaffListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetViewCompetitionStaffListActionTypes>) {
    dispatch(
      getViewCompetitionStaffListAPIMsgAction(
        apiRes as GetViewCompetitionStaffListDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearViewCompetitionStaffListAPIRes = () => {
  return function (dispatch: Dispatch<GetViewCompetitionStaffListActionTypes>) {
    dispatch(
      getViewCompetitionStaffListAPIResClearAction(
        {} as GetViewCompetitionStaffListDetails,
        "",
        "",
        0,
      ),
    );
  };
};
