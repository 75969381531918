import {
  ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_FAIL,
  ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_LOADING,
  ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS,
  ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG,
  ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES,
  OriginalPatientDemographicsDetailsActionTypes,
} from "./ActionTypes";
import { OriginalPatientDemographicsDetailsRes } from "./Model";

export const originalPatientDemographicsDetailsLoadingAction = (
  loading: boolean,
): OriginalPatientDemographicsDetailsActionTypes => {
  return {
    type: ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_LOADING,
    loading: loading,
  };
};

export const originalPatientDemographicsDetailsSuccessAction = (
  getOriginalPatientDemographicsDetailsResponse: OriginalPatientDemographicsDetailsRes,
  successMsg: string,
): OriginalPatientDemographicsDetailsActionTypes => {
  return {
    type: ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS,
    payload: getOriginalPatientDemographicsDetailsResponse,
    successMsg: successMsg,
  };
};

export const originalPatientDemographicsDetailsErrorAction = (
  getOriginalPatientDemographicsDetailsResponse: OriginalPatientDemographicsDetailsRes,
  errMsg: string,
  status: number,
): OriginalPatientDemographicsDetailsActionTypes => {
  return {
    type: ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_FAIL,
    payload: getOriginalPatientDemographicsDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const originalPatientDemographicsDetailsUpdateAPIMsgAction = (
  getOriginalPatientDemographicsDetailsResponse: OriginalPatientDemographicsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): OriginalPatientDemographicsDetailsActionTypes => {
  return {
    type: ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG,
    payload: getOriginalPatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const originalPatientDemographicsDetailsAPIResClearAction = (
  getOriginalPatientDemographicsDetailsResponse: OriginalPatientDemographicsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): OriginalPatientDemographicsDetailsActionTypes => {
  return {
    type: ORIGINAL_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES,
    payload: getOriginalPatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
