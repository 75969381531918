import { GetPatientListBySessionIdRes } from "./Model";
export const PATIENT_LIST_BY_SESSION_ID_LOADING = "PATIENT_LIST_BY_SESSION_ID_LOADING";
export const PATIENT_LIST_BY_SESSION_ID_SUCCESS = "PATIENT_LIST_BY_SESSION_ID_SUCCESS";
export const PATIENT_LIST_BY_SESSION_ID_FAIL = "PATIENT_LIST_BY_SESSION_ID_FAIL";
export const PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG = "PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG";
export const PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES = "PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES";

export interface GetHealthCampDeactiveStaffListState {
  getPatientListBySessionIdRes: GetPatientListBySessionIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListLoading {
  type: typeof PATIENT_LIST_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface GetHealthCampDeactiveStaffListSuccess {
  type: typeof PATIENT_LIST_BY_SESSION_ID_SUCCESS;
  payload: GetPatientListBySessionIdRes;
  successMsg: string;
}
export interface GetHealthCampDeactiveStaffListFail {
  type: typeof PATIENT_LIST_BY_SESSION_ID_FAIL;
  payload: GetPatientListBySessionIdRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListUpdateAPIMsg {
  type: typeof PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG;
  payload: GetPatientListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDeactiveStaffListUpdateAPIRes {
  type: typeof PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES;
  payload: GetPatientListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientListBySessionIdActionTypes =
  | GetHealthCampDeactiveStaffListLoading
  | GetHealthCampDeactiveStaffListSuccess
  | GetHealthCampDeactiveStaffListFail
  | GetHealthCampDeactiveStaffListUpdateAPIMsg
  | GetHealthCampDeactiveStaffListUpdateAPIRes;
