import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddPatientDetailsListInCompetitionIdActionTypes } from "./ActionTypes";
import {
  AddPatientDetailsListInCompetitionIdBody,
  AddPatientDetailsListInCompetitionIdDetails,
} from "./Model";
import {
  addPatientDetailsListInCompetitionIdClearAction,
  addPatientDetailsListInCompetitionIdErrorAction,
  addPatientDetailsListInCompetitionIdLoadingAction,
  addPatientDetailsListInCompetitionIdSuccessAction,
  addPatientDetailsListInCompetitionIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddPatientDetailsListInCompetitionIdDetails;
export const addPatientDetailsListInCompitionId = (
  payload: AddPatientDetailsListInCompetitionIdBody,
) => {
  return function (dispatch: Dispatch<AddPatientDetailsListInCompetitionIdActionTypes>) {
    dispatch(addPatientDetailsListInCompetitionIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionPatientDetailsListAsStaff/add_patientDetail_in_competition`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientDetailsListInCompetitionIdLoadingAction(false));
        dispatch(
          addPatientDetailsListInCompetitionIdSuccessAction(
            res.data,
            res?.data?.message !== undefined
              ? res?.data?.message
              : "Competition Details Has been updated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addPatientDetailsListInCompetitionIdLoadingAction(false));
        dispatch(
          addPatientDetailsListInCompetitionIdErrorAction(
            {} as AddPatientDetailsListInCompetitionIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const addPatientDetailsListInCompetitionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientDetailsListInCompetitionIdActionTypes>) {
    dispatch(
      addPatientDetailsListInCompetitionIdAPIMsgAction(
        apiRes as AddPatientDetailsListInCompetitionIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearAddPatientDetailsListInCompetitionIdAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientDetailsListInCompetitionIdActionTypes>) {
    dispatch(
      addPatientDetailsListInCompetitionIdClearAction(
        {} as AddPatientDetailsListInCompetitionIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};
