import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetDoctorCategoryListActionTypes } from "./ActionTypes";
import { GetDoctorCategoryListRes } from "./Model";
import {
  getDoctorCategoryListAPIResClearAction,
  getDoctorCategoryListErrorAction,
  getDoctorCategoryListLoadingAction,
  getDoctorCategoryListSuccessAction,
  getDoctorCategoryListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "../../../../component/Utility";

let apiRes = {} as GetDoctorCategoryListRes;
export const getDoctorCategoryList = () => {
  return function (dispatch: Dispatch<GetDoctorCategoryListActionTypes>) {
    dispatch(getDoctorCategoryListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/secondopinionapp/api/doctorCategory/doctor_category_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorCategoryListLoadingAction(false));
        dispatch(
          getDoctorCategoryListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetDoctorCategoryListRes),
            res.data.message !== undefined
              ? res.data.message !== ""
                ? res.data.message
                : "Doctor category list details has been fetched successfully"
              : "Doctor category list details has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDoctorCategoryListLoadingAction(false));
        dispatch(
          getDoctorCategoryListErrorAction(
            {} as GetDoctorCategoryListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateGetDoctorCategoryListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorCategoryListActionTypes>) {
    dispatch(
      getDoctorCategoryListUpdateAPIMsgAction(apiRes as GetDoctorCategoryListRes, "", "", 0),
    );
  };
};

export const clearDoctorCategoryListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorCategoryListActionTypes>) {
    dispatch(getDoctorCategoryListAPIResClearAction({} as GetDoctorCategoryListRes, "", "", 0));
  };
};
