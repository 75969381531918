import { PageCount, AddExistingPatientCaseToSessionDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_EXISTING_PATIENT_TO_SESSION_LOADING = "ADD_EXISTING_PATIENT_TO_SESSION_LOADING";
export const ADD_EXISTING_PATIENT_TO_SESSION_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_EXISTING_PATIENT_TO_SESSION_FAIL = "ADD_EXISTING_PATIENT_TO_SESSION_FAIL";
export const ADD_EXISTING_PATIENT_TO_SESSION_DATA = "ADD_EXISTING_PATIENT_TO_SESSION_DATA";
export const ADD_EXISTING_PATIENT_TO_SESSION_API_MSG = "ADD_EXISTING_PATIENT_TO_SESSION_API_MSG";
export const ADD_EXISTING_PATIENT_TO_SESSION_API_RES = "ADD_EXISTING_PATIENT_TO_SESSION_API_RES";

export interface AddExistingPatientCaseToSessionState {
  addExistingPatientCaseToSessionRes: AddExistingPatientCaseToSessionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientCaseToSessionLoading {
  type: typeof ADD_EXISTING_PATIENT_TO_SESSION_LOADING;
  loading: boolean;
}
export interface AddExistingPatientCaseToSessionSuccess {
  type: typeof ADD_EXISTING_PATIENT_TO_SESSION_SUCCESS;
  payload: AddExistingPatientCaseToSessionDetails;
  successMsg: string;
}
export interface AddExistingPatientCaseToSessionFail {
  type: typeof ADD_EXISTING_PATIENT_TO_SESSION_FAIL;
  payload: AddExistingPatientCaseToSessionDetails;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientCaseToSessionAPIMsg {
  type: typeof ADD_EXISTING_PATIENT_TO_SESSION_API_MSG;
  payload: AddExistingPatientCaseToSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddExistingPatientCaseToSessionAPIRes {
  type: typeof ADD_EXISTING_PATIENT_TO_SESSION_API_RES;
  payload: AddExistingPatientCaseToSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddExistingPatientCaseToSessionDataAction {
  type: typeof ADD_EXISTING_PATIENT_TO_SESSION_DATA;
  payload: AddExistingPatientCaseToSessionDetails;
  pageCount: PageCount;
}

export type AddExistingPatientCaseToSessionActionTypes =
  | AddExistingPatientCaseToSessionDataAction
  | AddExistingPatientCaseToSessionLoading
  | AddExistingPatientCaseToSessionSuccess
  | AddExistingPatientCaseToSessionFail
  | AddExistingPatientCaseToSessionAPIMsg
  | AddExistingPatientCaseToSessionAPIRes;
