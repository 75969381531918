import {
  VIEW_HISTORY_SESSION_FAIL,
  VIEW_HISTORY_SESSION_LOADING,
  VIEW_HISTORY_SESSION_SUCCESS,
  VIEW_HISTORY_SESSION_UPDATE_API_MSG,
  VIEW_HISTORY_SESSION_UPDATE_API_RES,
  ViewHistorySessionActionTypes,
} from "./ActionTypes";
import { ViewHistorySessionRes } from "./Model";

export const viewHistorySessionLoadingAction = (
  loading: boolean,
): ViewHistorySessionActionTypes => {
  return {
    type: VIEW_HISTORY_SESSION_LOADING,
    loading: loading,
  };
};

export const viewHistorySessionSuccessAction = (
  viewHistorySessionResponse: ViewHistorySessionRes,
  successMsg: string,
): ViewHistorySessionActionTypes => {
  return {
    type: VIEW_HISTORY_SESSION_SUCCESS,
    payload: viewHistorySessionResponse,
    successMsg: successMsg,
  };
};

export const viewHistorySessionErrorAction = (
  viewHistorySessionResponse: ViewHistorySessionRes,
  errMsg: string,
  status: number,
): ViewHistorySessionActionTypes => {
  return {
    type: VIEW_HISTORY_SESSION_FAIL,
    payload: viewHistorySessionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const viewHistorySessionUpdateAPIMsgAction = (
  viewHistorySessionResponse: ViewHistorySessionRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ViewHistorySessionActionTypes => {
  return {
    type: VIEW_HISTORY_SESSION_UPDATE_API_MSG,
    payload: viewHistorySessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const viewHistorySessionAPIResClearAction = (
  viewHistorySessionResponse: ViewHistorySessionRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ViewHistorySessionActionTypes => {
  return {
    type: VIEW_HISTORY_SESSION_UPDATE_API_RES,
    payload: viewHistorySessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
