import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { GovtIDProofActionTypes } from "./ActionTypes";
import { GovtIdProofResponse } from "./Model";
import {
  govtIDProofListAPIResClearAction,
  govtIDProofListErrorAction,
  govtIDProofListLoadingAction,
  govtIDProofListSuccessAction,
  govtIDProofListUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GovtIdProofResponse;
export const govtIdProofListApi = () => {
  return function (dispatch: Dispatch<GovtIDProofActionTypes>) {
    dispatch(govtIDProofListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        baseUrl.baseUrl + `/indivoapp/api/govtIdType/dropdown_list`,
        { headers: headers }
      )
      .then((res) => {
        apiRes =
          res.data !== undefined ? res.data : ({} as GovtIdProofResponse);
        dispatch(govtIDProofListLoadingAction(false));
        dispatch(
          govtIDProofListSuccessAction(
            res.data !== undefined ? res.data : ({} as GovtIdProofResponse),
            res.data.message !== undefined
              ? res.data.message
              : "Govt. id proof list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(govtIDProofListLoadingAction(false));
        dispatch(
          govtIDProofListErrorAction(
            {} as GovtIdProofResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updategovtIDProofListAPIResMsg = () => {
  return function (dispatch: Dispatch<GovtIDProofActionTypes>) {
    dispatch(
      govtIDProofListUpdateAPIMsgAction(
        apiRes as GovtIdProofResponse,
        "",
        "",
        0
      )
    );
  };
};

export const cleargovtIDProofListAPIRes = () => {
  return function (dispatch: Dispatch<GovtIDProofActionTypes>) {
    dispatch(
      govtIDProofListAPIResClearAction({} as GovtIdProofResponse, "", "", 0)
    );
  };
};
