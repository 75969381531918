import {
  ProviderOrgPtDetailsActionTypes,
  PROV_ORG_PT_DETAILS_FAIL,
  PROV_ORG_PT_DETAILS_LOADING,
  PROV_ORG_PT_DETAILS_SUCCESS,
  ProviderOrgPtDetailsState,
  PROV_ORG_PT_DETAILS_API_MSG,
  PROV_ORG_PT_DETAILS_API_RES,
} from "./ActionTypes";
import { ProviderOrgPatientDetailsRes } from "./Model";

const initialStateUpdatePosts: ProviderOrgPtDetailsState = {
  loading: false,
  providerOrgPtDtRes: {} as ProviderOrgPatientDetailsRes,
  errorMsg: "",
  status: 0,
};
export const providerOrgPatientDetailsReducer = (
  state = initialStateUpdatePosts,
  action: ProviderOrgPtDetailsActionTypes,
): ProviderOrgPtDetailsState => {
  switch (action.type) {
    case PROV_ORG_PT_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PROV_ORG_PT_DETAILS_SUCCESS:
      return {
        ...state,
        providerOrgPtDtRes: action.payload,
      };
    case PROV_ORG_PT_DETAILS_FAIL:
      return {
        ...state,
        providerOrgPtDtRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PROV_ORG_PT_DETAILS_API_MSG:
      return {
        ...state,
        providerOrgPtDtRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PROV_ORG_PT_DETAILS_API_RES:
      return {
        ...state,
        providerOrgPtDtRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
