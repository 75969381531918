import { RegistrationRequestDetailsListDetails } from "./Model";
export const REGISTRATION_REQUEST_DETAILS_LIST_LOADING = "REGISTRATION_REQUEST_DETAILS_LIST_LOADING";
export const REGISTRATION_REQUEST_DETAILS_LIST_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const REGISTRATION_REQUEST_DETAILS_LIST_FAIL = "REGISTRATION_REQUEST_DETAILS_LIST_FAIL";
export const REGISTRATION_REQUEST_DETAILS_LIST_DATA = "REGISTRATION_REQUEST_DETAILS_LIST_DATA";
export const REGISTRATION_REQUEST_DETAILS_LIST_API_MSG = "REGISTRATION_REQUEST_DETAILS_LIST_API_MSG";
export const REGISTRATION_REQUEST_DETAILS_LIST_API_RES = "REGISTRATION_REQUEST_DETAILS_LIST_API_RES";

export interface RegistrationRequestDetailsListState {
  registrationRequestDetailsListRes: RegistrationRequestDetailsListDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RegistrationRequestDetailsListLoading {
  type: typeof REGISTRATION_REQUEST_DETAILS_LIST_LOADING;
  loading: boolean;
}
export interface RegistrationRequestDetailsListSuccess {
  type: typeof REGISTRATION_REQUEST_DETAILS_LIST_SUCCESS;
  payload: RegistrationRequestDetailsListDetails;
  successMsg: string;
}
export interface RegistrationRequestDetailsListFail {
  type: typeof REGISTRATION_REQUEST_DETAILS_LIST_FAIL;
  payload: RegistrationRequestDetailsListDetails;
  errorMsg: string;
  status: number;
}
export interface RegistrationRequestDetailsListAPIMsg {
  type: typeof REGISTRATION_REQUEST_DETAILS_LIST_API_MSG;
  payload: RegistrationRequestDetailsListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RegistrationRequestDetailsListAPIRes {
  type: typeof REGISTRATION_REQUEST_DETAILS_LIST_API_RES;
  payload: RegistrationRequestDetailsListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type RegistrationRequestDetailsListActionTypes =
  | RegistrationRequestDetailsListLoading
  | RegistrationRequestDetailsListSuccess
  | RegistrationRequestDetailsListFail
  | RegistrationRequestDetailsListAPIMsg
  | RegistrationRequestDetailsListAPIRes;
