import { PageCount, DiactiveStaffOfOrgDetails } from "./Model";

// Patient Demographics Loading State
export const DIACTIVE_STAFF_OF_ORG_LOADING = "DIACTIVE_STAFF_OF_ORG_LOADING";
export const DIACTIVE_STAFF_OF_ORG_SUCCESS = "DIACTIVE_STAFF_OF_ORG_SUCCESS";
export const DIACTIVE_STAFF_OF_ORG_FAIL = "DIACTIVE_STAFF_OF_ORG_FAIL";
export const DIACTIVE_STAFF_OF_ORG_DATA = "DIACTIVE_STAFF_OF_ORG_DATA";
export const DIACTIVE_STAFF_OF_ORG_API_MSG = "DIACTIVE_STAFF_OF_ORG_API_MSG";
export const DIACTIVE_STAFF_OF_ORG_API_RES = "DIACTIVE_STAFF_OF_ORG_API_RES";

export interface DiactiveStaffOfOrgState {
  DiactiveStaffOfOrgRes: DiactiveStaffOfOrgDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfOrgLoading {
  type: typeof DIACTIVE_STAFF_OF_ORG_LOADING;
  loading: boolean;
}
export interface DiactiveStaffOfOrgSuccess {
  type: typeof DIACTIVE_STAFF_OF_ORG_SUCCESS;
  payload: DiactiveStaffOfOrgDetails;
  successMsg: string;
}
export interface DiactiveStaffOfOrgFail {
  type: typeof DIACTIVE_STAFF_OF_ORG_FAIL;
  payload: DiactiveStaffOfOrgDetails;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfOrgAPIMsg {
  type: typeof DIACTIVE_STAFF_OF_ORG_API_MSG;
  payload: DiactiveStaffOfOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfOrgAPIRes {
  type: typeof DIACTIVE_STAFF_OF_ORG_API_RES;
  payload: DiactiveStaffOfOrgDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface DiactiveStaffOfOrgDataAction {
  type: typeof DIACTIVE_STAFF_OF_ORG_DATA;
  payload: DiactiveStaffOfOrgDetails;
  pageCount: PageCount;
}

export type DiactiveStaffOfOrgActionTypes =
  | DiactiveStaffOfOrgDataAction
  | DiactiveStaffOfOrgLoading
  | DiactiveStaffOfOrgSuccess
  | DiactiveStaffOfOrgFail
  | DiactiveStaffOfOrgAPIMsg
  | DiactiveStaffOfOrgAPIRes;
