import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import {
  GetExtractObservationActionTypes,
  GET_EXTRACT_OBSERVATION_FAIL,
  GET_EXTRACT_OBSERVATION_LOADING,
  GET_EXTRACT_OBSERVATION_SUCCESS,
} from "./ActionTypes";
import { GetExtractObservationRes } from "./Model";
import { headers } from "../../component/Utility";

export const getExtractObservationByPatientId = (patientid: string, pageNo: any) => {
  return function (dispatch: Dispatch<GetExtractObservationActionTypes>) {
    dispatch({
      type: GET_EXTRACT_OBSERVATION_LOADING,
    });
    axios
      .post(
        baseUrl.baseUrl +
          `/adminapp/api/patientObservation/getListOfObservationsExtractedFromPatientDetails?page=${Number(
            pageNo,
          )}&size=50`,
        { patientId: patientid },
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data;
        let pageCount = res.data.numberOfPages;
        dispatch({
          type: GET_EXTRACT_OBSERVATION_SUCCESS,
          payload: resData,
          pageCount: pageCount,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_EXTRACT_OBSERVATION_FAIL,
          payload: {} as GetExtractObservationRes,
          errRes: error.response.data.message,
        });
      });
  };
};
