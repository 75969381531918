import { GetProviderOrganizationPatientListDetails } from "./Model";
export const PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_LOADING = "PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_LOADING";
export const PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_SUCCESS = "PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_SUCCESS";
export const PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL = "PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL";
export const PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_API_MSG = "PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_API_MSG";
export const PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_API_RES = "PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_API_RES";

export interface PatientListOfProviderOrganizationIdDetailsState {
  patientListOfProviderOrganizationIdRes: GetProviderOrganizationPatientListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientListOfProviderOrganizationIdDetailsLoading {
  type: typeof PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_LOADING;
  loading: boolean;
}
export interface PatientListOfProviderOrganizationIdDetailsSuccess {
  type: typeof PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_SUCCESS;
  payload: GetProviderOrganizationPatientListDetails;
}
export interface PatientListOfProviderOrganizationIdDetailsFail {
  type: typeof PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_FAIL;
  payload: GetProviderOrganizationPatientListDetails;
  message: string;
  status: number;
}
export interface PatientListOfProviderOrganizationIdDetailsAPIMsg {
  type: typeof PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_API_MSG;
  payload: GetProviderOrganizationPatientListDetails;
  message: string;
  status: number;
}
export interface PatientListOfProviderOrganizationIdRes {
  type: typeof PATIENT_LIST_BY_PROVIDER_ORGANIZATION_ID_API_RES;
  payload: GetProviderOrganizationPatientListDetails;
  message: string;
  status: number;
}


export type GetProviderOrganizationPatientListActionTypes =
  | PatientListOfProviderOrganizationIdDetailsLoading
  | PatientListOfProviderOrganizationIdDetailsSuccess
  | PatientListOfProviderOrganizationIdDetailsFail
  | PatientListOfProviderOrganizationIdDetailsAPIMsg
  | PatientListOfProviderOrganizationIdRes;
