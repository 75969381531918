import {
  SymptomAddActionTypes,
  GetPatientDemographicsState,
  SYMPTOM_ADD_SUCCESS,
  SYMPTOM_ADD_LOADING,
  SYMPTOM_ADD_FAIL,
} from "./ActionTypes";
import { SymptomAddResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  templateList: {} as SymptomAddResponse,
};
export const symptomAddReducer = (
  state = initialStateGetPosts,
  action: SymptomAddActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case SYMPTOM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };
    case SYMPTOM_ADD_LOADING:
      return {
        ...state,
        loading: true,
        templateList: {} as SymptomAddResponse,
      };
    case SYMPTOM_ADD_FAIL:
      return {
        ...state,
        templateList: {} as SymptomAddResponse,
        loading: false,
      };
    default:
      return state;
  }
};
