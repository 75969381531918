import {
  GetPatientListBySessionIdActionTypes,
  PATIENT_LIST_BY_SESSION_ID_FAIL,
  PATIENT_LIST_BY_SESSION_ID_LOADING,
  PATIENT_LIST_BY_SESSION_ID_SUCCESS,
  GetHealthCampDeactiveStaffListState,
  PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG,
  PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPatientListBySessionIdRes } from "./Model";

const initialStateGetPosts: GetHealthCampDeactiveStaffListState = {
  loading: false,
  getPatientListBySessionIdRes: {} as GetPatientListBySessionIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientListBySessionIdReducer = (
  state = initialStateGetPosts,
  action: GetPatientListBySessionIdActionTypes,
): GetHealthCampDeactiveStaffListState => {
  switch (action.type) {
    case PATIENT_LIST_BY_SESSION_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PATIENT_LIST_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        getPatientListBySessionIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case PATIENT_LIST_BY_SESSION_ID_FAIL:
      return {
        ...state,
        getPatientListBySessionIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG:
      return {
        ...state,
        getPatientListBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES:
      return {
        ...state,
        getPatientListBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
