import { AddInstanceDetails } from "./Model";

export const ADD_INSTANCE_LOADING = "ADD_INSTANCE_LOADING";
export const ADD_INSTANCE_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_INSTANCE_FAIL = "ADD_INSTANCE_FAIL";
export const ADD_INSTANCE_API_MSG = "ADD_INSTANCE_API_MSG";
export const ADD_INSTANCE_API_RES = "ADD_INSTANCE_API_RES";

export interface AddInstanceState {
  addInstanceRes: AddInstanceDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddInstanceLoading {
  type: typeof ADD_INSTANCE_LOADING;
  loading: boolean;
}
export interface AddInstanceSuccess {
  type: typeof ADD_INSTANCE_SUCCESS;
  payload: AddInstanceDetails;
  successMsg: string;
}
export interface AddInstanceFail {
  type: typeof ADD_INSTANCE_FAIL;
  payload: AddInstanceDetails;
  errorMsg: string;
  status: number;
}
export interface AddInstanceAPIMsg {
  type: typeof ADD_INSTANCE_API_MSG;
  payload: AddInstanceDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddInstanceAPIRes {
  type: typeof ADD_INSTANCE_API_RES;
  payload: AddInstanceDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type AddInstanceActionTypes =
  | AddInstanceLoading
  | AddInstanceSuccess
  | AddInstanceFail
  | AddInstanceAPIMsg
  | AddInstanceAPIRes;
