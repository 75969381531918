import {
    GetPatientTeamDetails,
    GetPatientDetailsByIdActionTypes,
    GET_PATIENT_TEAM_BY_ID_FAIL,
    GET_PATIENT_TEAM_BY_ID_LOADING,
    GET_PATIENT_TEAM_BY_ID_SUCCESS,
    GET_PATIENT_TEAM_BY_ID_RES,
    GET_PATIENT_TEAM_BY_ID_MSG,
  } from "./ActionTypes";
  import { GetPatientTeamTeamRes } from "./Model";
  
  const initialStateGetPosts: GetPatientTeamDetails = {
    loading: false,
    getPatientRes: {} as GetPatientTeamTeamRes,
    message: "",
    status: 0,
    errRes: "",
  };
  export const getPatientTeamDetailsByIdReducer = (
    state = initialStateGetPosts,
    action: GetPatientDetailsByIdActionTypes,
  ): GetPatientTeamDetails => {
    switch (action.type) {
      case GET_PATIENT_TEAM_BY_ID_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case GET_PATIENT_TEAM_BY_ID_SUCCESS:
        return {
          ...state,
          getPatientRes: action.payload,
        };
  
      case GET_PATIENT_TEAM_BY_ID_FAIL:
        return {
          ...state,
          getPatientRes: action.payload,
          message: action.message,
          status: action.status,
        };
      case GET_PATIENT_TEAM_BY_ID_MSG:
        return {
          ...state,
          getPatientRes: action.payload,
          message: action.message,
          status: action.status,
        };
      case GET_PATIENT_TEAM_BY_ID_RES:
        return {
          ...state,
          getPatientRes: action.payload,
          message: action.message,
          status: action.status,
        };
      default:
        return state;
    }
  };
  