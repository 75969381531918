import {
  JURY_LIST_BY_COMPETITION_ID_DATA,
  JURY_LIST_BY_COMPETITION_ID_FAIL,
  JURY_LIST_BY_COMPETITION_ID_LOADING,
  JURY_LIST_BY_COMPETITION_ID_SUCCESS,
  GetJuryListByCompetitionActionTypes,
} from "./ActionTypes";
import { PageCount, GetJuryListByCompetitionDetails } from "./Model";

export const juryCompitionIdAction = (
  GetJuryListByCompetitionDetails: GetJuryListByCompetitionDetails,
  pageCount: PageCount,
): GetJuryListByCompetitionActionTypes => {
  return {
    type: JURY_LIST_BY_COMPETITION_ID_DATA,
    payload: GetJuryListByCompetitionDetails,
    pageCount: pageCount,
  };
};

export const getJuryListByCompetitionLoadingAction = (
  loading: boolean,
): GetJuryListByCompetitionActionTypes => {
  return {
    type: JURY_LIST_BY_COMPETITION_ID_LOADING,
    loading: loading,
  };
};

export const getJuryListByCompetitionSuccessAction = (
  getJuryListByCompetitionResponse: GetJuryListByCompetitionDetails,
): GetJuryListByCompetitionActionTypes => {
  return {
    type: JURY_LIST_BY_COMPETITION_ID_SUCCESS,
    payload: getJuryListByCompetitionResponse,
  };
};

export const getJuryListByCompetitionErrorAction = (
  getJuryListByCompetitionResponse: GetJuryListByCompetitionDetails,
  errMsg: string,
  status: number,
): GetJuryListByCompetitionActionTypes => {
  return {
    type: JURY_LIST_BY_COMPETITION_ID_FAIL,
    payload: getJuryListByCompetitionResponse,
    message: errMsg,
    status: status,
  };
};
export const getJuryListByCompetitionAPIMsgAction = (
  getJuryListByCompetitionResponse: GetJuryListByCompetitionDetails,
  errMsg: string,
  status: number,
): GetJuryListByCompetitionActionTypes => {
  return {
    type: JURY_LIST_BY_COMPETITION_ID_FAIL,
    payload: getJuryListByCompetitionResponse,
    message: errMsg,
    status: status,
  };
};

export const getJuryListByCompetitionAPIResClearAction = (
  getJuryListByCompetitionResponse: GetJuryListByCompetitionDetails,
  errMsg: string,
  status: number,
): GetJuryListByCompetitionActionTypes => {
  return {
    type: JURY_LIST_BY_COMPETITION_ID_FAIL,
    payload: getJuryListByCompetitionResponse,
    message: errMsg,
    status: status,
  };
};
