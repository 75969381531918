import {
  REGISTRATION_REQUEST_DETAILS_LIST_FAIL,
  REGISTRATION_REQUEST_DETAILS_LIST_LOADING,
  REGISTRATION_REQUEST_DETAILS_LIST_SUCCESS,
  REGISTRATION_REQUEST_DETAILS_LIST_API_MSG,
  REGISTRATION_REQUEST_DETAILS_LIST_API_RES,
  RegistrationRequestDetailsListActionTypes,
} from "./ActionTypes";
import { RegistrationRequestDetailsListDetails } from "./Model";

export const registrationRequestDetailsListLoadingAction = (
  loading: boolean,
): RegistrationRequestDetailsListActionTypes => {
  return {
    type: REGISTRATION_REQUEST_DETAILS_LIST_LOADING,
    loading: loading,
  };
};

export const registrationRequestDetailsListSuccessAction = (
  registrationRequestDetailsListResponse: RegistrationRequestDetailsListDetails,
  successMsg: string,
): RegistrationRequestDetailsListActionTypes => {
  return {
    type: REGISTRATION_REQUEST_DETAILS_LIST_SUCCESS,
    payload: registrationRequestDetailsListResponse,
    successMsg: successMsg,
  };
};

export const registrationRequestDetailsListFailAction = (
  registrationRequestDetailsListResponse: RegistrationRequestDetailsListDetails,
  errMsg: string,
  status: number,
): RegistrationRequestDetailsListActionTypes => {
  return {
    type: REGISTRATION_REQUEST_DETAILS_LIST_FAIL,
    payload: registrationRequestDetailsListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const registrationRequestDetailsListAPIMsgAction = (
  registrationRequestDetailsListResponse: RegistrationRequestDetailsListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): RegistrationRequestDetailsListActionTypes => {
  return {
    type: REGISTRATION_REQUEST_DETAILS_LIST_API_MSG,
    payload: registrationRequestDetailsListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const registrationRequestDetailsListAPIResClearAction = (
  registrationRequestDetailsListResponse: RegistrationRequestDetailsListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): RegistrationRequestDetailsListActionTypes => {
  return {
    type: REGISTRATION_REQUEST_DETAILS_LIST_API_RES,
    payload: registrationRequestDetailsListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
