import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DuplicateHealthcampListByPatientIdActionTypes } from "./ActionTypes";
import { DuplicateHealthcampListByPatientIdBody, DuplicateHealthcampListByPatientIdDetails } from "./Model";
import {
  DuplicateHealthcampListByPatientIdAPIResClearAction,
  DuplicateHealthcampListByPatientIdErrorAction,
  DuplicateHealthcampListByPatientIdLoadingAction,
  DuplicateHealthcampListByPatientIdSuccessAction,
  DuplicateHealthcampListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as DuplicateHealthcampListByPatientIdDetails;
export const duplicateHealthcampListByPatientIdApi = (
  patientid: DuplicateHealthcampListByPatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<DuplicateHealthcampListByPatientIdActionTypes>) {
    dispatch(DuplicateHealthcampListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
        `/caregiverapp/api/healthcamp/health_camp_list_by_patient?page=${Number(
          pageNo,
        )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DuplicateHealthcampListByPatientIdLoadingAction(false));
        dispatch(
          DuplicateHealthcampListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as DuplicateHealthcampListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Duplicate Patient healthcamp list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(DuplicateHealthcampListByPatientIdLoadingAction(false));
        dispatch(
          DuplicateHealthcampListByPatientIdErrorAction(
            {} as DuplicateHealthcampListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDuplicateHealthcampListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<DuplicateHealthcampListByPatientIdActionTypes>) {
    dispatch(
      DuplicateHealthcampListByPatientIdAPIMsgAction(apiRes as DuplicateHealthcampListByPatientIdDetails, "", "", 0),
    );
  };
};

export const clearDuplicateHealthcampListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<DuplicateHealthcampListByPatientIdActionTypes>) {
    dispatch(
      DuplicateHealthcampListByPatientIdAPIResClearAction({} as DuplicateHealthcampListByPatientIdDetails, "", "", 0),
    );
  };
};
