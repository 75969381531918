import {
  ADD_PATIENT_DATA_POINT_CLEAR_API_RES,
  ADD_PATIENT_DATA_POINT_FAIL,
  ADD_PATIENT_DATA_POINT_LOADING,
  ADD_PATIENT_DATA_POINT_SUCCESS,
  ADD_PATIENT_DATA_POINT_UPDATE_API_MSG,
  AddPatientDataPointActionTypes,
} from "./ActionTypes";
import { AddPatientPointDataDetails } from "./Model";

export const addPatientDataPointLoadingAction = (
  loading: boolean,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_POINT_LOADING,
    loading: loading,
  };
};

export const addPatientDataPointSuccessAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_POINT_SUCCESS,
    payload: addPatientDataPointResponse,
  };
};

export const addPatientDataPointErrorAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  errMsg: string,
  status: number,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_POINT_FAIL,
    payload: addPatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
export const addPatientDataPointUpdateAPIMsgAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  errMsg: string,
  status: number,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_POINT_UPDATE_API_MSG,
    payload: addPatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};

export const addPatientDataPointAPIResClearAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  errMsg: string,
  status: number,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_POINT_CLEAR_API_RES,
    payload: addPatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
