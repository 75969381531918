import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getOrganizationListValue } from "../../../redux/OrganizationList/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MWExceptionList from "../../../component/MWExceptionList";

import { clearOrgPtSearchAPIRes } from "../../../redux/PatientSearchByOrganizationId/API";
// import AddPatientDialog from "pages/PatientListByOrganization/AddPatientDialog";
import { getPatientListByCompition } from "redux/PatientListByCompetition/API";
import { GetCompetitionPatientListBody, PatientList } from "redux/PatientListByCompetition/Model";
import { PatientDataBody } from "redux/addExistingHealthcampPatient/Model";
import { addHealthcampExistingPatient } from "redux/addExistingHealthcampPatient/API";

type Props = {
  patientListByCompetitionLoader?: boolean | any;
};

export default function PatientListByCompetition({ patientListByCompetitionLoader }: Props) {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };

  const history = useHistory();
  const [page, setPage] = useState(1);
  function gotoDetails(patientId: number) {
    history.push(`/patientdetailslist/${competitionid}/${patientId}`);
  }
  const [patientDetailsId, setPatientDetailsId] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");


  useEffect(() => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetCompetitionPatientListBody;
    dispatch(getPatientListByCompition(patientListBody, page));
    dispatch(clearOrgPtSearchAPIRes());
    dispatch(getOrganizationListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.getCompetitionPatientList);
  const allcaseValues = AdminPatientList?.PatientListOfCompetitionIdRes?.patientList;

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (allcaseValues !== undefined) {
      const patientListBody = {
        competitionId: competitionid,
      } as GetCompetitionPatientListBody;
      dispatch(getPatientListByCompition(patientListBody, value));
      dispatch(clearOrgPtSearchAPIRes());
    }
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    AdminPatientList?.PatientListOfCompetitionIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(AdminPatientList?.PatientListOfCompetitionIdRes?.numberOfPages))
      : setPageListCount("0");
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        userName: element.userName,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        phone: element.phone,
        pincode: element.pincode,
        createdDate: dayjs(element.createDate).format("MMMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const addExistingMemberCompetition = () => {
    const addExistingMemberBody = {
      patientId: patientDetailsId,
      competitionId: competitionid!,
    } as PatientDataBody;
    dispatch(addHealthcampExistingPatient(addExistingMemberBody));
  };
  const addExistingPatientValueRes = useSelector(
    (state: AppState) => state.addExistingPatientValue,
  );

  useEffect(() => {
    if (addExistingPatientValueRes?.addPatientRes?.message !== "") {
      dispatch(
        getPatientListByCompition(
          {
            competitionId: competitionid,
          } as GetCompetitionPatientListBody,
          page,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExistingPatientValueRes]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];

  const PatientListOfOrganization = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <TextField
                    label="Enter Patient Id"
                    variant="outlined"
                    placeholder="Please provide Patient Id"
                    onChange={(event) => setPatientDetailsId(event.target.value)}
                    fullWidth
                    id="patientDetailsID"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={addExistingMemberCompetition}>
                    Add Existing Patient to Competition
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
                pt={2}
              >
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                <Pagination
                  color="primary"
                  count={Number(pageListCount) || 0}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {allcaseValues && allcaseValues.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminPatientList.loading === true
        ? patientListByCompetitionLoader(true)
        : patientListByCompetitionLoader(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {PatientListOfOrganization}
        </Stack>
      </Container>
    </Box>
  );
}
