import {
  GET_QUESTIONNAIRE_LIST_FAIL,
  GET_QUESTIONNAIRE_LIST_LOADING,
  GET_QUESTIONNAIRE_LIST_SUCCESS,
  GET_QUESTIONNAIRE_LIST_UPDATE_API_MSG,
  GET_QUESTIONNAIRE_LIST_UPDATE_API_RES,
  GetQuestionnaireListActionTypes,
} from "./ActionTypes";
import { GetQuestionnaireListRes } from "./Model";

export const getQuestionnaireListLoadingAction = (
  loading: boolean,
): GetQuestionnaireListActionTypes => {
  return {
    type: GET_QUESTIONNAIRE_LIST_LOADING,
    loading: loading,
  };
};

export const getQuestionnaireListSuccessAction = (
  getQuestionnaireListResponse: GetQuestionnaireListRes,
  successMsg: string,
): GetQuestionnaireListActionTypes => {
  return {
    type: GET_QUESTIONNAIRE_LIST_SUCCESS,
    payload: getQuestionnaireListResponse,
    successMsg: successMsg,
  };
};

export const getQuestionnaireListErrorAction = (
  getQuestionnaireListResponse: GetQuestionnaireListRes,
  errMsg: string,
  status: number,
): GetQuestionnaireListActionTypes => {
  return {
    type: GET_QUESTIONNAIRE_LIST_FAIL,
    payload: getQuestionnaireListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getQuestionnaireListUpdateAPIMsgAction = (
  getQuestionnaireListResponse: GetQuestionnaireListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetQuestionnaireListActionTypes => {
  return {
    type: GET_QUESTIONNAIRE_LIST_UPDATE_API_MSG,
    payload: getQuestionnaireListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getQuestionnaireListAPIResClearAction = (
  getQuestionnaireListResponse: GetQuestionnaireListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetQuestionnaireListActionTypes => {
  return {
    type: GET_QUESTIONNAIRE_LIST_UPDATE_API_RES,
    payload: getQuestionnaireListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
