import { GetDuplicatePatientListRes, PageCount } from "./Model";
export const GET_DUPLICATE_PATIENT_LIST_LOADING = "GET_DUPLICATE_PATIENT_LIST_LOADING";
export const GET_DUPLICATE_PATIENT_LIST_SUCCESS = "GET_DUPLICATE_PATIENT_LIST_SUCCESS";
export const GET_DUPLICATE_PATIENT_LIST_FAIL = "GET_DUPLICATE_PATIENT_LIST_FAIL";
export const GET_DUPLICATE_PATIENT_LIST_DATA = "GET_DUPLICATE_PATIENT_LIST_DATA";
export const GET_DUPLICATE_PATIENT_LIST_API_MSG = "GET_DUPLICATE_PATIENT_LIST_API_MSG";
export const GET_DUPLICATE_PATIENT_LIST_API_RES = "GET_DUPLICATE_PATIENT_LIST_API_RES";

export interface GetDuplicatePatientListState {
  getDuplicatePatientListRes: GetDuplicatePatientListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDuplicatePatientListLoading {
  type: typeof GET_DUPLICATE_PATIENT_LIST_LOADING;
  loading: boolean;
}
export interface GetDuplicatePatientListSuccess {
  type: typeof GET_DUPLICATE_PATIENT_LIST_SUCCESS;
  payload: GetDuplicatePatientListRes;
}
export interface GetDuplicatePatientListFail {
  type: typeof GET_DUPLICATE_PATIENT_LIST_FAIL;
  payload: GetDuplicatePatientListRes;
  errorMsg: string;
  status: number;
}
export interface GetDuplicatePatientListAPIMsg {
  type: typeof GET_DUPLICATE_PATIENT_LIST_API_MSG;
  payload: GetDuplicatePatientListRes;
  errorMsg: string;
  status: number;
}
export interface GetDuplicatePatientListAPIRes {
  type: typeof GET_DUPLICATE_PATIENT_LIST_API_RES;
  payload: GetDuplicatePatientListRes;
  errorMsg: string;
  status: number;
}

interface GetDuplicatePatientListDataAction {
  type: typeof GET_DUPLICATE_PATIENT_LIST_DATA;
  payload: GetDuplicatePatientListRes;
  pageCount: PageCount;
}

export type GetDuplicatePatientListActionTypes =
  | GetDuplicatePatientListLoading
  | GetDuplicatePatientListSuccess
  | GetDuplicatePatientListFail
  | GetDuplicatePatientListAPIMsg
  | GetDuplicatePatientListDataAction
  | GetDuplicatePatientListAPIRes;
