import { PageCount, DiactiveStaffOfCompetitionDetails } from "./Model";

// Patient Demographics Loading State
export const DIACTIVE_STAFF_OF_COMPETITION_LOADING = "DIACTIVE_STAFF_OF_COMPETITION_LOADING";
export const DIACTIVE_STAFF_OF_COMPETITION_SUCCESS = "DIACTIVE_STAFF_OF_COMPETITION_SUCCESS";
export const DIACTIVE_STAFF_OF_COMPETITION_FAIL = "DIACTIVE_STAFF_OF_COMPETITION_FAIL";
export const DIACTIVE_STAFF_OF_COMPETITION_DATA = "DIACTIVE_STAFF_OF_COMPETITION_DATA";
export const DIACTIVE_STAFF_OF_COMPETITION_API_MSG = "DIACTIVE_STAFF_OF_COMPETITION_API_MSG";
export const DIACTIVE_STAFF_OF_COMPETITION_API_RES = "DIACTIVE_STAFF_OF_COMPETITION_API_RES";

export interface DiactiveStaffOfCompetitionState {
  DiactiveStaffOfCompetitionRes: DiactiveStaffOfCompetitionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfCompetitionLoading {
  type: typeof DIACTIVE_STAFF_OF_COMPETITION_LOADING;
  loading: boolean;
}
export interface DiactiveStaffOfCompetitionSuccess {
  type: typeof DIACTIVE_STAFF_OF_COMPETITION_SUCCESS;
  payload: DiactiveStaffOfCompetitionDetails;
  successMsg: string;
}
export interface DiactiveStaffOfCompetitionFail {
  type: typeof DIACTIVE_STAFF_OF_COMPETITION_FAIL;
  payload: DiactiveStaffOfCompetitionDetails;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfCompetitionAPIMsg {
  type: typeof DIACTIVE_STAFF_OF_COMPETITION_API_MSG;
  payload: DiactiveStaffOfCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DiactiveStaffOfCompetitionAPIRes {
  type: typeof DIACTIVE_STAFF_OF_COMPETITION_API_RES;
  payload: DiactiveStaffOfCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface DiactiveStaffOfCompetitionDataAction {
  type: typeof DIACTIVE_STAFF_OF_COMPETITION_DATA;
  payload: DiactiveStaffOfCompetitionDetails;
  pageCount: PageCount;
}

export type DiactiveStaffOfCompetitionActionTypes =
  | DiactiveStaffOfCompetitionDataAction
  | DiactiveStaffOfCompetitionLoading
  | DiactiveStaffOfCompetitionSuccess
  | DiactiveStaffOfCompetitionFail
  | DiactiveStaffOfCompetitionAPIMsg
  | DiactiveStaffOfCompetitionAPIRes;
