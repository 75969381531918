import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { FilterPatientDetailsByObservationActionTypes } from "./ActionTypes";
import {
  FilterPatientDetailsByObservationBody,
  FilterPatientDetailsByObservationListRes,
} from "./Model";
import {
  FilterPatientDetailsByObservationAPIResClearAction,
  FilterPatientDetailsByObservationErrorAction,
  FilterPatientDetailsByObservationLoadingAction,
  FilterPatientDetailsByObservationSuccessAction,
  FilterPatientDetailsByObservationAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as FilterPatientDetailsByObservationListRes;
export const filterPatientDetailsObservation = (
  searchKey: FilterPatientDetailsByObservationBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<FilterPatientDetailsByObservationActionTypes>) {
    dispatch(FilterPatientDetailsByObservationLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
        `/healthcampapp/api/healthCampSessionPatientDetails/session_patientDetail_by_observation_data_range?page=${Number(
          pageNo,
        )}&size=50`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(FilterPatientDetailsByObservationLoadingAction(false));
        dispatch(
          FilterPatientDetailsByObservationSuccessAction(
            res.data !== undefined ? res.data : ({} as FilterPatientDetailsByObservationListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient details observation list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(FilterPatientDetailsByObservationLoadingAction(false));
        dispatch(
          FilterPatientDetailsByObservationErrorAction(
            {} as FilterPatientDetailsByObservationListRes,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateFilterPatientDetailsByObservationAPIResMsg = () => {
  return function (dispatch: Dispatch<FilterPatientDetailsByObservationActionTypes>) {
    dispatch(
      FilterPatientDetailsByObservationAPIMsgAction(
        apiRes as FilterPatientDetailsByObservationListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearFilterPatientDetailsByObservationAPIRes = () => {
  return function (dispatch: Dispatch<FilterPatientDetailsByObservationActionTypes>) {
    dispatch(
      FilterPatientDetailsByObservationAPIResClearAction(
        {} as FilterPatientDetailsByObservationListRes,
        "",
        "",
        0,
      ),
    );
  };
};
