import {
  ExecutiveListActionTypes,
  EXECUTIVE_LIST_FAIL,
  EXECUTIVE_LIST_LOADING,
  EXECUTIVE_LIST_SUCCESS,
  ExecutiveListState,
  EXECUTIVE_LIST_UPDATE_API_MSG,
  EXECUTIVE_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { ExecutiveListRes } from "./Model";

const initialStateGetPosts: ExecutiveListState = {
  loading: false,
  executiveListRes: {} as ExecutiveListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getExecutiveListReducer = (
  state = initialStateGetPosts,
  action: ExecutiveListActionTypes,
): ExecutiveListState => {
  switch (action.type) {
    case EXECUTIVE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EXECUTIVE_LIST_SUCCESS:
      return {
        ...state,
        executiveListRes: action.payload,
        successMsg: action.successMsg,
      };
    case EXECUTIVE_LIST_FAIL:
      return {
        ...state,
        executiveListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXECUTIVE_LIST_UPDATE_API_MSG:
      return {
        ...state,
        executiveListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXECUTIVE_LIST_UPDATE_API_RES:
      return {
        ...state,
        executiveListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
