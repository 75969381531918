import { RemoveExecutiveRes } from "./Model";
export const REMOVE_EXECUTIVE_LOADING = "REMOVE_EXECUTIVE_LOADING";
export const REMOVE_EXECUTIVE_SUCCESS = "REMOVE_EXECUTIVE_SUCCESS";
export const REMOVE_EXECUTIVE_FAIL = "REMOVE_EXECUTIVE_FAIL";
export const REMOVE_EXECUTIVE_UPDATE_API_MSG = "REMOVE_EXECUTIVE_UPDATE_API_MSG";
export const REMOVE_EXECUTIVE_UPDATE_API_RES = "REMOVE_EXECUTIVE_UPDATE_API_RES";

export interface RemoveExecutiveState {
  removeExecutiveRes: RemoveExecutiveRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RemoveExecutiveLoading {
  type: typeof REMOVE_EXECUTIVE_LOADING;
  loading: boolean;
}
export interface RemoveExecutiveSuccess {
  type: typeof REMOVE_EXECUTIVE_SUCCESS;
  payload: RemoveExecutiveRes;
  successMsg: string;
}
export interface RemoveExecutiveFail {
  type: typeof REMOVE_EXECUTIVE_FAIL;
  payload: RemoveExecutiveRes;
  errorMsg: string;
  status: number;
}
export interface RemoveExecutiveUpdateAPIMsg {
  type: typeof REMOVE_EXECUTIVE_UPDATE_API_MSG;
  payload: RemoveExecutiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RemoveExecutiveUpdateAPIRes {
  type: typeof REMOVE_EXECUTIVE_UPDATE_API_RES;
  payload: RemoveExecutiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type RemoveExecutiveActionTypes =
  | RemoveExecutiveLoading
  | RemoveExecutiveSuccess
  | RemoveExecutiveFail
  | RemoveExecutiveUpdateAPIMsg
  | RemoveExecutiveUpdateAPIRes;
