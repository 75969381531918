import {
  ADD_PATIENT_DETAILS_TYPE_FAIL,
  ADD_PATIENT_DETAILS_TYPE_LOADING,
  ADD_PATIENT_DETAILS_TYPE_SUCCESS,
  ADD_PATIENT_DETAILS_TYPE_API_MSG,
  ADD_PATIENT_DETAILS_TYPE_API_RES,
  AssignPatientDetailsTypeActionTypes,
  ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM,
} from "./ActionTypes";
import { AssignPatientDetailsTypeRes } from "./Model";

export const assignPatientDetailsTypeLoadingAction = (
  loading: boolean,
): AssignPatientDetailsTypeActionTypes => {
  return {
    type: ADD_PATIENT_DETAILS_TYPE_LOADING,
    loading: loading,
  };
};

export const assignPatientDetailsTypeSuccessAction = (
  AddStaffResponse: AssignPatientDetailsTypeRes,
  successMsg: string,
): AssignPatientDetailsTypeActionTypes => {
  return {
    type: ADD_PATIENT_DETAILS_TYPE_SUCCESS,
    payload: AddStaffResponse,
    successMsg: successMsg,
  };
};

export const assignPatientDetailsTypeFailAction = (
  AddStaffResponse: AssignPatientDetailsTypeRes,
  errMsg: string,
  status: number,
): AssignPatientDetailsTypeActionTypes => {
  return {
    type: ADD_PATIENT_DETAILS_TYPE_FAIL,
    payload: AddStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const assignPatientDetailsTypeAPIMsgAction = (
  AddStaffResponse: AssignPatientDetailsTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AssignPatientDetailsTypeActionTypes => {
  return {
    type: ADD_PATIENT_DETAILS_TYPE_API_MSG,
    payload: AddStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const assignPatientDetailsTypeAPIResClearAction = (
  AddStaffResponse: AssignPatientDetailsTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AssignPatientDetailsTypeActionTypes => {
  return {
    type: ADD_PATIENT_DETAILS_TYPE_API_RES,
    payload: AddStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
export const assignPatientDetailsTypeSelectedItemAction = (
  selectedItem: any[],
): AssignPatientDetailsTypeActionTypes => {
  return {
    type: ADD_PATIENT_DETAILS_TYPE_SELECTED_ITEM,
    selectedItem: selectedItem,
  };
};
