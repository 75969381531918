import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  LinearProgress,
  LinearProgressProps,
  Typography,
  Grid,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import IFile from "./types/File";
import { AppState } from "../redux/store/Store";
import { imageUploadApi } from "../redux/ImageUpload/API";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "auto",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);
interface Props {
  criteria: string;
  documentType: string;
  updatedDataState: any;
  visibility?: boolean;
  image?: any;
  title?: string | any;
  imageUrl?: any;
  deleteImgAction: boolean;
  previewImageData: any;
  docImg?: any;
}
const ImageUpload: React.FC<Props> = ({
  criteria,
  documentType,
  updatedDataState,
  visibility,
  image,
  title,
  imageUrl,
  deleteImgAction,
  previewImageData,
  docImg,
}) => {
  const [currentImage, setCurrentImage] = useState<File>();
  const [previewImage, setPreviewImage] = useState<IFile | undefined>({} as IFile);
  const [message, setMessage] = useState<string>("");
  const [errMessage, setErrMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [visibilityValue, setVisibilityValue] = useState(false);
  const [deleteImg, setDeleteImg] = useState(true);
  const [descriptionModalActive, setDescriptionModalActive] = useState(false);

  const closeModal = () => {
    setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
  };
  useEffect(() => {
    if (deleteImg || deleteImgAction) {
      setPreviewImage({} as IFile);
      setCurrentImage({} as File);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteImg, deleteImgAction]);
  useEffect(() => {
    if (previewImage?.uploaded === "" && previewImage?.image === undefined) {
      // image not selected
      previewImageData(false);
      setErrMessage("");
    } else if (previewImage?.uploaded === "" && previewImage?.image !== "") {
      // image selected
      previewImageData(true);
      setErrMessage("");
    } else if (previewImage?.uploaded === "1" && previewImage?.image !== "") {
      // image upload success
      previewImageData(false);
      setErrMessage("");
    } else if (
      (previewImage?.uploaded === "0" && previewImage?.image === undefined) ||
      "uploaded" in previewImage! === false
    ) {
      // image deleted
      previewImageData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  useEffect(() => {
    if (visibilityValue) {
      setErrMessage("");
    }
  }, [visibilityValue]);

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const selectViewImage = () => {
    setDescriptionModalActive(true);
  };
  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    if (selectedFiles?.[0].size <= 104857600) {
      setCurrentImage(selectedFiles?.[0]);
      setMessage("");
      setErrMessage("");
      setProgress(0);
      let selectedFileDetails: IFile = {
        image: URL.createObjectURL(selectedFiles?.[0]),
        name: selectedFiles?.[0].name,
        url: "",
        size: formatBytes(selectedFiles?.[0].size),
        uploaded: "",
      };
      setPreviewImage(selectedFileDetails);
      setDeleteImg(false);
    } else {
      setPreviewImage({} as IFile);
      setDeleteImg(true);
      setErrMessage("File size too large");
    }
    event.target.value = '';
  };
  const [imageId, setImageId] = useState(0);
  const dispatch = useDispatch();

  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  useEffect(() => {
    setImageId(imageUploadRes?.imageUploadResponse?.image?.id);
    updatedDataState(imageId);
    updatedDataState(imageUploadRes?.imageUploadResponse?.image?.id);
    setPreviewImage((prevState) => ({
      ...prevState,
      uploaded: imageUploadRes?.status,
    }));
    if (imageUploadRes?.successMsg !== undefined) {
      setMessage(imageUploadRes?.successMsg);
    }
    if (imageUploadRes?.errMsg !== "") {
      setMessage(imageUploadRes?.errMsg);
    }
    setProgress(imageUploadRes?.progress);
    if (imageUploadRes?.status === "failed") {
      setProgress(0);
      if (!message) {
        setMessage(message);
      }
    }
    if (imageUploadRes?.imageUploadResponse?.image?.id !== undefined) {
      setDeleteImg(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploadRes]);

  useEffect(() => {
    setVisibilityValue(visibility!);
    setPreviewImage((prevState) => ({
      ...prevState,
      image: image,
    }));
    if (image !== "") {
      setDeleteImg(false);
    }
  }, [visibility, image]);

  const upload = () => {
    if (!currentImage) return;
    const formData = new FormData();
    formData.append("document", currentImage);
    formData.append("typeName", documentType);
    dispatch(imageUploadApi(formData));
  };

  const displayModalBody = (
    <Dialog open={descriptionModalActive} onClose={closeModal}>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">View Uploaded Document</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Paper square sx={{ minHeight: "auto" }}>
          <TransformWrapper initialScale={1}>
            {(utils) => (
              <React.Fragment>
                <Controls {...utils} />
                <TransformComponent
                  wrapperStyle={{
                    maxWidth: "auto",
                    height: "auto",
                    border: "solid 2px gray",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                    }}
                    src={
                      imageUploadRes?.imageUploadResponse?.image !== undefined
                        ? imageUploadRes?.imageUploadResponse?.image?.document !== undefined
                          ? imageUploadRes?.imageUploadResponse?.image?.document
                          : docImg
                        : docImg
                    }
                    srcSet={
                      previewImage !== undefined
                        ? previewImage.image !== undefined
                          ? previewImage.image
                          : imageUrl
                        : imageUrl
                    }
                    alt="photoimage"
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Paper>
      </DialogContent>
      <Divider />
    </Dialog>
  );

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", p: 2 }}>
      <Typography variant="h6">{title !== undefined ? title : null}</Typography>
      <Typography color="primary" variant="caption">
        {criteria}
      </Typography>
      {imageUrl !== "" &&
      deleteImg !== true &&
      (documentType === "patient-id-Image" || title === "Update Demogra image") ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ border: "1px dashed grey", borderRadius: 2, mt: 1 }}
        >
          <Button
            size="large"
            onClick={() => {
              selectViewImage();
            }}
            sx={{ p: 2, width: "100%", height: "100%", borderRadius: 0 }}
          >
            <RemoveRedEyeIcon />
          </Button>
        </Stack>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ border: "1px dashed grey", borderRadius: 2, mt: 1 }}
        >
          <IconButton
            disabled={visibilityValue}
            sx={{ p: 2, width: "100%", height: "100%", borderRadius: 0 }}
            aria-label="upload picture"
            component="label"
          >
            <input
              style={{ display: "none", background: "cover" }}
              id="raised-button-file"
              type="file"
              onChange={selectImage}
            />
            <PhotoCamera />
          </IconButton>
        </Stack>
      )}
      {(previewImage?.name !== undefined || imageUrl !== undefined) && !deleteImg && (
        <Box
          sx={{
            display: "flex",
            borderRadius: 2,
            my: 1,
            border: "1px solid lightgray",
          }}
        >
          <Box>
            <img
              src={
                previewImage !== undefined
                  ? previewImage.image !== undefined
                    ? previewImage.image
                    : imageUrl
                  : imageUrl
              }
              srcSet={
                previewImage !== undefined
                  ? previewImage.image !== undefined
                    ? previewImage.image
                    : imageUrl
                  : imageUrl
              }
              alt=""
              loading="lazy"
              style={{
                width: "80px",
                height: "auto",
                borderRadius: 2,
                padding: 2,
              }}
            />
          </Box>
          <Box sx={{ width: "85%", boxSizing: "border-box" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              wrap="wrap-reverse"
            >
              <Grid item xs={12} sm={12} md={11} lg={9} xl={9}>
                <Box component="b" sx={{ wordWrap: "break-word" }}>
                  {previewImage!.name}
                </Box>
                <Typography variant="subtitle2" color="text.secondary" component="div">
                  {previewImage!.size}
                </Typography>
                {previewImage?.name !== undefined && (
                  <LinearProgressWithLabel variant="determinate" value={progress} />
                )}
                {message && (
                  <Typography
                    color={previewImage?.uploaded === "Failed" ? "error" : "success"}
                    variant="caption"
                  >
                    {message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={3} xl={3}>
                <IconButton
                  onClick={() => setDeleteImg(true)}
                  disabled={imageUploadRes?.loading || visibility}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {errMessage !== "" ? (
        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item>
            <Typography color="error" variant="caption">
              {errMessage}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <Grid container justifyContent="flex-end" spacing={2} sx={{ pt: 3 }}>
        <Button
          variant="outlined"
          disabled={!currentImage || errMessage !== "" || imageUploadRes?.loading}
          onClick={upload}
        >
          Upload
        </Button>
      </Grid>
      {displayModalBody}
    </Box>
  );
};

export default ImageUpload;
