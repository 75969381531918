import { AlertColor, Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MWExceptionList from "../../../../../../../../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddPatientBySessionIdBody } from "redux/HealthcampSessionList/AddPatientBySessionId/Model";
import {
  addPatientBySessionIdApiCall,
  clearAddPatientBySessionIdAPIRes,
} from "redux/HealthcampSessionList/AddPatientBySessionId/API";
import { AppState } from "redux/store/Store";
import { clearAPIRes } from "redux/PatientSearchByHealcampId/API";
import MWSnackbar from "component/MWSnackbar";

type Props = {
  patientDetails?: any;
  sessionId?: string | any;
  instanceHealthcampId?: string | any;
  medicalWisdomDetails?: any;
};

export default function GridView({
  patientDetails,
  sessionId,
  instanceHealthcampId,
  medicalWisdomDetails,
}: Props) {
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const history = useHistory();
  const addPatientResponse = useSelector((state: AppState) => state.addPatientBySessionIdValues);
  const [addCheckInMsg, setAddCheckInMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const [searchInHealthCampPatientList, setSearchInHealthCampPatientList] = useState([] as any[]);
  const [searchInAppointmentPatientList, setSearchInAppointmentPatientList] = useState([] as any[]);
  useEffect(() => {
    setSearchInHealthCampPatientList(patientDetails);
    setSearchInAppointmentPatientList(medicalWisdomDetails);
  }, [patientDetails, medicalWisdomDetails]);

  useEffect(() => {
    if (addPatientResponse?.successMsg !== "") {
      dispatch(clearAPIRes());
      history.push(
        `/healthcampinstance_session/${healthcampid}/${instanceHealthcampId}/${sessionId}`,
      );
    }
    if (addPatientResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setAddCheckInMsg(addPatientResponse?.errorMsg);
      dispatch(clearAddPatientBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientResponse]);

  const addCheckPatientSubmit = (element: string) => {
    const addExistingPatientBody = {
      healthcampSessionId: sessionId!,
      patientId: element,
    } as AddPatientBySessionIdBody;
    dispatch(addPatientBySessionIdApiCall(addExistingPatientBody));
  };

  const addCheckInMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setAddCheckInMsg("");
    }
  };

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {(searchInHealthCampPatientList !== undefined && searchInHealthCampPatientList.length) ||
        (searchInAppointmentPatientList && searchInAppointmentPatientList.length) ? (
          <React.Fragment>
            {searchInHealthCampPatientList?.map((healthcampItem: any) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                {healthcampItem?.patientId && (
                  <Card
                    raised
                    sx={{
                      margin: "0 auto",
                      padding: "0.1em",
                    }}
                  >
                    <Tooltip title={healthcampItem.patientId}>
                      <CardHeader
                        title={
                          <Typography variant="h6" fontWeight="bold">
                            {healthcampItem.givenName} {healthcampItem.familyName}
                          </Typography>
                        }
                        subheader={`${healthcampItem.userName}`}
                      />
                    </Tooltip>
                    <CardMedia
                      component="img"
                      image={
                        healthcampItem?.profilePicture !== null
                          ? healthcampItem?.profilePicture?.document
                          : "../../../medical.png"
                      }
                      alt="coverpicture"
                      sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                      height="250"
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {healthcampItem?.phone !== "" ? healthcampItem?.phone : "Not Set"}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                              addCheckPatientSubmit(healthcampItem?.patientId);
                            }}
                          >
                            Check-In Patient
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                )}
              </Grid>
            ))}
            {searchInAppointmentPatientList?.map((appointmentItem: any) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                {appointmentItem?.patientId && (
                  <Card
                    raised
                    sx={{
                      margin: "0 auto",
                      padding: "0.1em",
                    }}
                  >
                    <Tooltip title={appointmentItem.patientId}>
                      <CardHeader
                        title={
                          <Typography variant="h6" fontWeight="bold">
                            {appointmentItem.givenName} {appointmentItem.familyName}
                          </Typography>
                        }
                        subheader={`${appointmentItem.userName}`}
                      />
                    </Tooltip>
                    <CardMedia
                      component="img"
                      image={
                        appointmentItem?.profilePicture !== null
                          ? appointmentItem?.profilePicture?.document
                          : "../../../medical.png"
                      }
                      alt="coverpicture"
                      sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                      height="250"
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {appointmentItem?.phone !== "" ? appointmentItem?.phone : "Not Set"}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                              addCheckPatientSubmit(appointmentItem?.patientId);
                            }}
                          >
                            Check-In Patient
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                )}
              </Grid>
            ))}
          </React.Fragment>
        ) : (
          <Box p={3}>
            <MWExceptionList />
          </Box>
        )}
      </Grid>
      {addCheckInMsg !== "" ? (
        <MWSnackbar
          msg={addCheckInMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addCheckInMsgClose}
        />
      ) : null}
    </Box>
  );
}
