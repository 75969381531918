import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MWExceptionList from "../component/MWExceptionList";
import PreviewIcon from "@mui/icons-material/Preview";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWPageTitle from "../component/MWPageTitle";
import { DoctorQuriesListApi } from "../redux/DoctorQuriesList/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DoctorHelpQuery } from "../redux/DoctorQuriesList/Model";
import PageLayout from "./Layout/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function Doctor() {
  const dispatch = useDispatch();

  const [descriptionModalActive, setDescriptionModalActive] = useState(false);
  const [description, setDescription] = useState("");

  const closeModal = () => {
    setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
    setDescription("");
  };
  const viewDescription = (descriptionText: any) => {
    setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
    setDescription(descriptionText);
  };

  // call Store
  const AdminDoctorQueryList = useSelector((state: AppState) => state.adminDoctorQueryListValue);

  const doctorDataQueries: GridColDef[] = [
    {
      field: "number",
      headerName: "Phone Number",
      minWidth: 110,
    },
    { field: "description", headerName: "Description", width: 250, flex: 1 },
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => {
            viewDescription(params.row.description);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const reloadDoctorQueryList = () => {
    dispatch(DoctorQuriesListApi());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(DoctorQuriesListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [doctorQueryValues, setDoctorQueryValues] = useState([] as any[]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminDoctorQueryList?.loading));
    if (AdminDoctorQueryList !== undefined) {
      let imprtList = AdminDoctorQueryList?.doctorQueriesRes?.doctorHelpQueries?.map(
        (element: DoctorHelpQuery) => ({
          id: element.id,
          number: element?.callbackNumbers !== null ? element?.callbackNumbers[0].number : "",
          description: element?.description !== "" ? element?.description : "",
        }),
      );
      setDoctorQueryValues(imprtList);
    } else setDoctorQueryValues([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminDoctorQueryList]);

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Doctor Queries List</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => reloadDoctorQueryList()}
              >
                Reload
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {doctorQueryValues && doctorQueryValues.length ? (
          <DataGrid
            rows={doctorQueryValues}
            columns={doctorDataQueries}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const descriptionModalBody = (
    <Dialog open={descriptionModalActive} onClose={closeModal} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Description</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>{description}</DialogContent>
      <Divider />
    </Dialog>
  );
  return (
    <Box>
      <PageLayout>
        <MWPageTitle title={"Doctor Queries"} />
        {actualPageMarkup}
      </PageLayout>
      {descriptionModalBody}
    </Box>
  );
}
