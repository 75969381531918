import { GetAllUserListRes } from "./Model";
export const GET_ALL_USER_LIST_LOADING = "GET_ALL_USER_LIST_LOADING";
export const GET_ALL_USER_LIST_SUCCESS = "GET_ALL_USER_LIST_SUCCESS";
export const GET_ALL_USER_LIST_FAIL = "GET_ALL_USER_LIST_FAIL";
export const GET_ALL_USER_LIST_UPDATE_API_MSG = "GET_ALL_USER_LIST_UPDATE_API_MSG";
export const GET_ALL_USER_LIST_UPDATE_API_RES = "GET_ALL_USER_LIST_UPDATE_API_RES";

export interface GetAllUserListState {
  getAllUserList: GetAllUserListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAllUserListLoading {
  type: typeof GET_ALL_USER_LIST_LOADING;
  loading: boolean;
}
export interface GetAllUserListSuccess {
  type: typeof GET_ALL_USER_LIST_SUCCESS;
  payload: GetAllUserListRes;
  successMsg: string;
}
export interface GetAllUserListFail {
  type: typeof GET_ALL_USER_LIST_FAIL;
  payload: GetAllUserListRes;
  errorMsg: string;
  status: number;
}
export interface GetAllUserListUpdateAPIMsg {
  type: typeof GET_ALL_USER_LIST_UPDATE_API_MSG;
  payload: GetAllUserListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAllUserListUpdateAPIRes {
  type: typeof GET_ALL_USER_LIST_UPDATE_API_RES;
  payload: GetAllUserListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetAllUserListActionTypes =
  | GetAllUserListLoading
  | GetAllUserListSuccess
  | GetAllUserListFail
  | GetAllUserListUpdateAPIMsg
  | GetAllUserListUpdateAPIRes;
