import { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { getHealthcampListValue } from "redux/healthcampList/API";
import MWSnackbar from "component/MWSnackbar";
import HealthCampDataCurationProblemListComponent from "../HealthCampDetails/HealthCampDataCurationProblemListComponent";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import EditHealthCamp from "./EditHealthCamp";
import dayjs from "dayjs";
import { HealthCampList } from "redux/healthcampList/Model";
import { clearHealthCampEditAPIRes } from "redux/healthcampList/EditHealthcampName/API";
export default function HealthcampManagement() {
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(false);
  const updateDialogState = (passedVal: boolean) => {
    setDialogState(passedVal);
  };

  const [instanceManagementLoader, setInstanceManagementLoader] = useState(false);
  const dataCurationListLoaderUpdateVal = (passedVal: boolean) => {
    setInstanceManagementLoader(passedVal);
  };
  useEffect(() => {
    dispatch(toggleAppBarLoading(instanceManagementLoader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceManagementLoader]);
  const [dataCurationListCount, setDataCurationListCount] = useState("0");
  console.log(dataCurationListCount);
  const changeInstanceManagementCount = (value: string) => {
    setDataCurationListCount(value);
  };

  const editHealthcampHandleClick = () => {
    setDialogState(true);
  };

  // call All health Camp List API store (Which are currently available for the user)
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  // Filer Current Health camp details from All Health Camp List API Store by match health camp id.
  const currentHealthCampDetails: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );

  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminAllHealthcampList?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);

  const [editHealthCampNameAPIMsg, setEditHealthCampNameAPIMsg] = useState("");
  const [editHealthCampNameMsgType, setEditHealthCampNameMsgType] = useState<AlertColor>("success");

  const AdminEditHealthcampList = useSelector(
    (state: AppState) => state.editHealthcampNameReducerValue,
  );

  useEffect(() => {
    if (AdminEditHealthcampList?.successMsg !== "") {
      setEditHealthCampNameMsgType("success");
      setEditHealthCampNameAPIMsg(AdminEditHealthcampList?.successMsg);
      setDialogState(false);
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminEditHealthcampList]);

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" fontWeight={"bold"}>
                Health Camp Details
              </Typography>
            </Grid>
            <Grid item>
              {currentHealthCampDetails !== undefined ? (
                <Button
                  onClick={editHealthcampHandleClick}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  Edit Health Camp
                </Button>
              ) : null}
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {!_.isEmpty(currentHealthCampDetails) ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Healthcamp Name :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography variant="body1">{currentHealthCampDetails?.displayName}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Health Camp Owner Name :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography variant="body1">
                {currentHealthCampDetails?.ownerFirstName === undefined
                  ? "Details not available"
                  : currentHealthCampDetails?.ownerFirstName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Health Camp Owner Email:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography variant="body1">
                {currentHealthCampDetails?.ownerUserName === undefined
                  ? "Details not available"
                  : currentHealthCampDetails?.ownerUserName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Camp Owner Profile-ID :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography variant="body1">
                {currentHealthCampDetails?.ownerProfileId === undefined
                  ? "Details not available"
                  : currentHealthCampDetails?.ownerProfileId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Health Camp Create Date :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography variant="body1">
                {dayjs(currentHealthCampDetails.dateCreated).format("dddd, MMMM D, YYYY")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Alert severity="error">{"You are not a staff for this health camp"}</Alert>
        )}
      </CardContent>
    </Card>
  );

  const closeEditHealthcampNameMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearHealthCampEditAPIRes());
      setEditHealthCampNameAPIMsg("");
    }
  };
  return (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {actualPageMarkup}
        <HealthCampDataCurationProblemListComponent
          instanceManagementLoader={dataCurationListLoaderUpdateVal}
          count={changeInstanceManagementCount}
        />
      </Stack>
      <EditHealthCamp enableDialog={dialogState} updateDialogState={updateDialogState} />
      {editHealthCampNameAPIMsg !== "" ? (
        <MWSnackbar
          msg={editHealthCampNameAPIMsg}
          type={editHealthCampNameMsgType}
          alertClose={closeEditHealthcampNameMsg}
        />
      ) : null}
    </Box>
  );
}
