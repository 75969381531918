import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectedOptions from "./SelectedOptions";
import PaymentList from "./PaymentList";
import ExistingAppointmentDetails from "./ExistingAppointmentDetails";
import { Stack } from "@mui/material";
import { requestAppointmentDetails } from "../../../redux/Patient/GetAppointmentList/API";
import { GetAppointmentListBody } from "../../../redux/Patient/GetAppointmentList/Model";
import { getAppointmentPaymentList } from "../../../redux/Patient/AppointmentPaymentList/API";
import { GetPaymentListBody } from "../../../redux/Patient/AppointmentPaymentList/Model";
import { AppState } from "../../../redux/store/Store";
interface Props {
  appointmentPaymentLoader?: boolean | any;
}
export default function AppointmentPayment({ appointmentPaymentLoader }: Props) {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(requestAppointmentDetails({ caseId: caseid } as GetAppointmentListBody));
    dispatch(getAppointmentPaymentList({ caseId: caseid } as GetPaymentListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequestAppointmentDetailsVal = useSelector(
    (state: AppState) => state.getRequestAppointmentDetails,
  );
  useEffect(() => {
    appointmentPaymentLoader(getRequestAppointmentDetailsVal?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestAppointmentDetailsVal]);

  let checkAppointmentPaymentStatusRes = useSelector(
    (state: AppState) => state.checkAppointmentPaymentStatusRes,
  );
  useEffect(() => {
    appointmentPaymentLoader(checkAppointmentPaymentStatusRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAppointmentPaymentStatusRes]);

  let getAppointmentPaymentListRes = useSelector(
    (state: AppState) => state.getAppointmentPaymentListRes,
  );
  useEffect(() => {
    appointmentPaymentLoader(getAppointmentPaymentListRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAppointmentPaymentListRes]);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <SelectedOptions />
      <PaymentList />
      <ExistingAppointmentDetails />
    </Stack>
  );
}
