import {
  PatientDemographicsActionTypes,
  GetPatientDemographicsState,
  DOCTOR_ONBOARD_INCOMPLETE_SUCCESS,
  DOCTOR_ONBOARD_INCOMPLETE_LOADING,
  DOCTOR_ONBOARD_INCOMPLETE_FAIL,
} from "./ActionTypes";
import { DoctorOnboardIncompleteResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  patientDemographicsRes: {} as DoctorOnboardIncompleteResponse,
};
export const doctorsOnboardingImcompleteReducer = (
  state = initialStateGetPosts,
  action: PatientDemographicsActionTypes
): GetPatientDemographicsState => {
  switch (action.type) {
    case DOCTOR_ONBOARD_INCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDemographicsRes: action.payload,
      };
    case DOCTOR_ONBOARD_INCOMPLETE_LOADING:
      return {
        ...state,
        loading: true,
        patientDemographicsRes: {} as DoctorOnboardIncompleteResponse,
      };
    case DOCTOR_ONBOARD_INCOMPLETE_FAIL:
      return {
        ...state,
        patientDemographicsRes: {} as DoctorOnboardIncompleteResponse,
        loading: false,
      };
    default:
      return state;
  }
};
