import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetProviderOrgStaffAdminListActionTypes } from "./ActionTypes";
import { GetProviderOrgStaffAdminListBody, ProviderOrgStaffAdminRes } from "./Model";
import {
  getProviderOrgStaffAdminListAPIResClearAction,
  getProviderOrgStaffAdminListErrorAction,
  getProviderOrgStaffAdminListLoadingAction,
  getProviderOrgStaffAdminListSuccessAction,
  getProviderOrgStaffAdminListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as ProviderOrgStaffAdminRes;
export const getStaffAdminListByProviderOrgId = (payload: GetProviderOrgStaffAdminListBody, pageNo: any) => {
  return function (dispatch: Dispatch<GetProviderOrgStaffAdminListActionTypes>) {
    dispatch(getProviderOrgStaffAdminListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationStaffAdmin/staff_list_providerOrganization?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getProviderOrgStaffAdminListLoadingAction(false));
        dispatch(
          getProviderOrgStaffAdminListSuccessAction(
            res.data !== undefined ? res.data : ({} as ProviderOrgStaffAdminRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getProviderOrgStaffAdminListLoadingAction(false));
        dispatch(
          getProviderOrgStaffAdminListErrorAction(
            {} as ProviderOrgStaffAdminRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateStaffAdminListByProviderOrgIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderOrgStaffAdminListActionTypes>) {
    dispatch(
      getProviderOrgStaffAdminListUpdateAPIMsgAction(apiRes as ProviderOrgStaffAdminRes, "", "", 0),
    );
  };
};

export const clearStaffAdminListByProviderOrgIdAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderOrgStaffAdminListActionTypes>) {
    dispatch(getProviderOrgStaffAdminListAPIResClearAction({} as ProviderOrgStaffAdminRes, "", "", 0));
  };
};
