import { PreviewMarksDetailsRes } from "./Model";
export const PREVIEW_CUTOFF_MARKS_LOADING = "PREVIEW_CUTOFF_MARKS_LOADING";
export const PREVIEW_CUTOFF_MARKS_SUCCESS = "PREVIEW_CUTOFF_MARKS_SUCCESS";
export const PREVIEW_CUTOFF_MARKS_FAIL = "PREVIEW_CUTOFF_MARKS_FAIL";
export const PREVIEW_CUTOFF_MARKS_API_MSG = "PREVIEW_CUTOFF_MARKS_API_MSG";
export const PREVIEW_CUTOFF_MARKS_API_RES = "PREVIEW_CUTOFF_MARKS_API_RES";

export interface PreviewCutoffMarksState {
  previewCutoffMarksRes: PreviewMarksDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface PreviewCutoffMarksLoading {
  type: typeof PREVIEW_CUTOFF_MARKS_LOADING;
  loading: boolean;
}
export interface PreviewCutoffMarksSuccess {
  type: typeof PREVIEW_CUTOFF_MARKS_SUCCESS;
  payload: PreviewMarksDetailsRes;
}
export interface PreviewCutoffMarksFail {
  type: typeof PREVIEW_CUTOFF_MARKS_FAIL;
  payload: PreviewMarksDetailsRes;
  errorMsg: string;
  status: number;
}
export interface PreviewCutoffMarksAPIMsg {
  type: typeof PREVIEW_CUTOFF_MARKS_API_MSG;
  payload: PreviewMarksDetailsRes;
  errorMsg: string;
  status: number;
}
export interface PreviewCutoffMarksAPIRes {
  type: typeof PREVIEW_CUTOFF_MARKS_API_RES;
  payload: PreviewMarksDetailsRes;
  errorMsg: string;
  status: number;
}

export type PreviewMarksDetailsActionTypes =
  | PreviewCutoffMarksLoading
  | PreviewCutoffMarksSuccess
  | PreviewCutoffMarksFail
  | PreviewCutoffMarksAPIMsg
  | PreviewCutoffMarksAPIRes;
