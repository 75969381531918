import { GetOrganizationRequestDetailsListRes } from "./Model";
export const ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING = "ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING";
export const ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS = "ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS";
export const ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL = "ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL";
export const ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG = "ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG";
export const ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES = "ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES";

export interface GetOrganizationRegistrationReqDetailsListState {
  getOrganizationRegistrationReqDetailsListresponse: GetOrganizationRequestDetailsListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationRegistrationReqDetailsListLoading {
  type: typeof ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING;
  loading: boolean;
}
export interface GetOrganizationRegistrationReqDetailsListSuccess {
  type: typeof ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS;
  payload: GetOrganizationRequestDetailsListRes;
  successMsg: string;
}
export interface GetOrganizationRegistrationReqDetailsListFail {
  type: typeof ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL;
  payload: GetOrganizationRequestDetailsListRes;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationRegistrationReqDetailsListUpdateAPIMsg {
  type: typeof ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG;
  payload: GetOrganizationRequestDetailsListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetOrganizationRegistrationReqDetailsListUpdateAPIRes {
  type: typeof ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES;
  payload: GetOrganizationRequestDetailsListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetOrganizationRegistrationReqDetailsListActionTypes =
  | GetOrganizationRegistrationReqDetailsListLoading
  | GetOrganizationRegistrationReqDetailsListSuccess
  | GetOrganizationRegistrationReqDetailsListFail
  | GetOrganizationRegistrationReqDetailsListUpdateAPIMsg
  | GetOrganizationRegistrationReqDetailsListUpdateAPIRes;
