import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  addDoctorDialogEnable?: boolean | any;
  addDoctorDialogUpdateState?: boolean | any;
  title: string | any;
};

export default function AddNewDoctor({
  addDoctorDialogEnable,
  addDoctorDialogUpdateState,
  title,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailError, setEmailError] = useState("");
  const emailErrordiv = emailError ? <Typography variant="body1" sx={{color: "red"}}>{emailError}</Typography> : "";

  const [firstNameError, setFirstNameError] = useState("");
  const firstNameErrordiv = firstNameError ? (
    <Typography variant="body1" sx={{color: "red"}}>{firstNameError}</Typography>
  ) : (
    ""
  );
  const [lastNameError, setLastNameError] = useState("");
  const lastNameErrordiv = lastNameError ? (
    <Typography variant="body1" sx={{color: "red"}}>{lastNameError}</Typography>
  ) : (
    ""
  );
  const handleDialogClose = () => {
    setDialogState(false);
    addDoctorDialogUpdateState(false);
  };

  React.useEffect(() => {
    setDialogState(addDoctorDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDoctorDialogEnable]);

  // Add Doctor API
  const addNewDoctor = () => {
    if (email === "" && firstName === "" && lastName === "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("Please enter first name");
      setLastNameError("Please enter last name");
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase()) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setEmailError("Please enter valid email id or phone number");
    } else if (email === "" && firstName === "" && lastName !== "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("Please enter first name");
      setLastNameError("");
    } else if (email === "" && firstName !== "" && lastName === "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("");
      setLastNameError("Please enter last name");
    } else if (email === "" && firstName !== "" && lastName !== "") {
      setEmailError("Please enter email or phone number");
      setFirstNameError("");
      setLastNameError("");
    } else if (email !== "" && firstName === "" && lastName === "") {
      setEmailError("");
      setFirstNameError("Please enter first name");
      setLastNameError("Please enter last name");
    } else if (email !== "" && firstName === "" && lastName !== "") {
      setEmailError("");
      setFirstNameError("Please enter first name");
      setLastNameError("");
    } else if (email !== "" && firstName !== "" && lastName === "") {
      setEmailError("");
      setFirstNameError("");
      setLastNameError("Please enter last name");
    } else {
      //dispatch(addDrOnBoarding(addDoctorBody));
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Add New Doctor for {title}</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                label="Email / Phone Number"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                placeholder="Please email or phone number"
                id="emailPhoneID"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{emailErrordiv}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="First Name"
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                }}
                placeholder="Please First Name"
                id="firstNameId"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{firstNameErrordiv}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                }}
                placeholder="Please Last Name"
                id="lastNameId"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{lastNameErrordiv}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={()=> addNewDoctor()}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
