import {
  ACTIVATE_PATIENT_FAIL,
  ACTIVATE_PATIENT_LOADING,
  ACTIVATE_PATIENT_SUCCESS,
  ACTIVATE_PATIENT_UPDATE_API_MSG,
  ACTIVATE_PATIENT_UPDATE_API_RES,
  ActivatePatientActionTypes,
} from "./ActionTypes";
import { ActivatePatientRes } from "./Model";

export const activatePatientLoadingAction = (loading: boolean): ActivatePatientActionTypes => {
  return {
    type: ACTIVATE_PATIENT_LOADING,
    loading: loading,
  };
};

export const activatePatientSuccessAction = (
  activatePatientResponse: ActivatePatientRes,
  successMsg: string,
): ActivatePatientActionTypes => {
  return {
    type: ACTIVATE_PATIENT_SUCCESS,
    payload: activatePatientResponse,
    successMsg: successMsg,
  };
};

export const activatePatientErrorAction = (
  activatePatientResponse: ActivatePatientRes,
  errMsg: string,
  status: number,
): ActivatePatientActionTypes => {
  return {
    type: ACTIVATE_PATIENT_FAIL,
    payload: activatePatientResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const activatePatientUpdateAPIMsgAction = (
  activatePatientResponse: ActivatePatientRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ActivatePatientActionTypes => {
  return {
    type: ACTIVATE_PATIENT_UPDATE_API_MSG,
    payload: activatePatientResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const activatePatientAPIResClearAction = (
  activatePatientResponse: ActivatePatientRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ActivatePatientActionTypes => {
  return {
    type: ACTIVATE_PATIENT_UPDATE_API_RES,
    payload: activatePatientResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
