import { ViewHistoryInstanceRes } from "./Model";
export const INSTANCE_VIEW_HISTORY_LOADING = "INSTANCE_VIEW_HISTORY_LOADING";
export const INSTANCE_VIEW_HISTORY_SUCCESS = "INSTANCE_VIEW_HISTORY_SUCCESS";
export const INSTANCE_VIEW_HISTORY_FAIL = "INSTANCE_VIEW_HISTORY_FAIL";
export const INSTANCE_VIEW_HISTORY_UPDATE_API_MSG = "INSTANCE_VIEW_HISTORY_UPDATE_API_MSG";
export const INSTANCE_VIEW_HISTORY_UPDATE_API_RES = "INSTANCE_VIEW_HISTORY_UPDATE_API_RES";

export interface ViewHistoryInstanceState {
  viewHistoryInstance: ViewHistoryInstanceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewHistoryInstanceLoading {
  type: typeof INSTANCE_VIEW_HISTORY_LOADING;
  loading: boolean;
}
export interface ViewHistoryInstanceSuccess {
  type: typeof INSTANCE_VIEW_HISTORY_SUCCESS;
  payload: ViewHistoryInstanceRes;
  successMsg: string;
}
export interface ViewHistoryInstanceFail {
  type: typeof INSTANCE_VIEW_HISTORY_FAIL;
  payload: ViewHistoryInstanceRes;
  errorMsg: string;
  status: number;
}
export interface ViewHistoryInstanceUpdateAPIMsg {
  type: typeof INSTANCE_VIEW_HISTORY_UPDATE_API_MSG;
  payload: ViewHistoryInstanceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewHistoryInstanceUpdateAPIRes {
  type: typeof INSTANCE_VIEW_HISTORY_UPDATE_API_RES;
  payload: ViewHistoryInstanceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ViewHistoryInstanceActionTypes =
  | ViewHistoryInstanceLoading
  | ViewHistoryInstanceSuccess
  | ViewHistoryInstanceFail
  | ViewHistoryInstanceUpdateAPIMsg
  | ViewHistoryInstanceUpdateAPIRes;
