import { PageCount, GetJuryListByCompetitionDetails } from "./Model";

// Patient Demographics Loading State
export const JURY_LIST_BY_COMPETITION_ID_LOADING = "JURY_LIST_BY_COMPETITION_ID_LOADING";
export const JURY_LIST_BY_COMPETITION_ID_SUCCESS = "JURY_LIST_BY_COMPETITION_ID_SUCCESS";
export const JURY_LIST_BY_COMPETITION_ID_FAIL = "JURY_LIST_BY_COMPETITION_ID_FAIL";
export const JURY_LIST_BY_COMPETITION_ID_DATA = "JURY_LIST_BY_COMPETITION_ID_DATA";
export const COMPETITION_JURY_LIST_API_MSG = "COMPETITION_JURY_LIST_API_MSG";
export const COMPETITION_JURY_LIST_API_RES = "COMPETITION_JURY_LIST_API_RES";

export interface GetJuryListByCompetitionState {
  getJuryListByCompetitionRes: GetJuryListByCompetitionDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface GetJuryListByCompetitionLoading {
  type: typeof JURY_LIST_BY_COMPETITION_ID_LOADING;
  loading: boolean;
}
export interface GetJuryListByCompetitionSuccess {
  type: typeof JURY_LIST_BY_COMPETITION_ID_SUCCESS;
  payload: GetJuryListByCompetitionDetails;
}
export interface GetJuryListByCompetitionFail {
  type: typeof JURY_LIST_BY_COMPETITION_ID_FAIL;
  payload: GetJuryListByCompetitionDetails;
  message: string;
  status: number;
}
export interface GetJuryListByCompetitionAPIMsg {
  type: typeof COMPETITION_JURY_LIST_API_MSG;
  payload: GetJuryListByCompetitionDetails;
  message: string;
  status: number;
}
export interface GetJuryListByCompetitionRes {
  type: typeof COMPETITION_JURY_LIST_API_RES;
  payload: GetJuryListByCompetitionDetails;
  message: string;
  status: number;
}
interface GetJuryListByCompetitionDataAction {
  type: typeof JURY_LIST_BY_COMPETITION_ID_DATA;
  payload: GetJuryListByCompetitionDetails;
  pageCount: PageCount;
}

export type GetJuryListByCompetitionActionTypes =
  | GetJuryListByCompetitionDataAction
  | GetJuryListByCompetitionLoading
  | GetJuryListByCompetitionSuccess
  | GetJuryListByCompetitionFail
  | GetJuryListByCompetitionAPIMsg
  | GetJuryListByCompetitionRes;
