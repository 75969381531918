import { AddProviderOrganizationDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_PROVIDER_ORGANIZATION_LOADING = "ADD_PROVIDER_ORGANIZATION_LOADING";
export const ADD_PROVIDER_ORGANIZATION_SUCCESS = "ADD_PROVIDER_ORGANIZATION_SUCCESS";
export const ADD_PROVIDER_ORGANIZATION_FAIL = "ADD_PROVIDER_ORGANIZATION_FAIL";
export const ADD_PROVIDER_ORGANIZATION_DATA = "ADD_PROVIDER_ORGANIZATION_DATA";
export const ADD_PROVIDER_ORGANIZATION_API_MSG = "ADD_PROVIDER_ORGANIZATION_API_MSG";
export const ADD_PROVIDER_ORGANIZATION_API_RES = "ADD_PROVIDER_ORGANIZATION_API_RES";

export interface AddProviderOrganizationDetailsState {
  AddProviderOrganizationRes: AddProviderOrganizationDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface AddProviderOrganizationLoading {
  type: typeof ADD_PROVIDER_ORGANIZATION_LOADING;
  loading: boolean;
}
export interface AddProviderOrganizationSuccess {
  type: typeof ADD_PROVIDER_ORGANIZATION_SUCCESS;
  payload: AddProviderOrganizationDetails;
}
export interface AddProviderOrganizationFail {
  type: typeof ADD_PROVIDER_ORGANIZATION_FAIL;
  payload: AddProviderOrganizationDetails;
  message: string;
  status: number;
}
export interface AddProviderOrganizationAPIMsg {
  type: typeof ADD_PROVIDER_ORGANIZATION_API_MSG;
  payload: AddProviderOrganizationDetails;
  message: string;
  status: number;
}
export interface AddProviderOrganizationRes {
  type: typeof ADD_PROVIDER_ORGANIZATION_API_RES;
  payload: AddProviderOrganizationDetails;
  message: string;
  status: number;
}
interface AddProviderOrganizationDataAction {
  type: typeof ADD_PROVIDER_ORGANIZATION_DATA;
  payload: AddProviderOrganizationDetails;
}

export type AddProviderOrganizationActionTypes =
  | AddProviderOrganizationDataAction
  | AddProviderOrganizationLoading
  | AddProviderOrganizationSuccess
  | AddProviderOrganizationFail
  | AddProviderOrganizationAPIMsg
  | AddProviderOrganizationRes;
