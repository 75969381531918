import {
  HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL,
  HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING,
  HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS,
  HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG,
  HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES,
  HealthcampListByPatientIdActionTypes,
} from "./ActionTypes";
import { HealthcampListByPatientIdDetails } from "./Model";

export const HealthcampListByPatientIdLoadingAction = (
  loading: boolean,
): HealthcampListByPatientIdActionTypes => {
  return {
    type: HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const HealthcampListByPatientIdSuccessAction = (
  healthcampListByPatientIdResponse: HealthcampListByPatientIdDetails,
  successMsg: string,
): HealthcampListByPatientIdActionTypes => {
  return {
    type: HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS,
    payload: healthcampListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const HealthcampListByPatientIdErrorAction = (
  healthcampListByPatientIdResponse: HealthcampListByPatientIdDetails,
  errMsg: string,
  status: number,
): HealthcampListByPatientIdActionTypes => {
  return {
    type: HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL,
    payload: healthcampListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const HealthcampListByPatientIdAPIMsgAction = (
  healthcampListByPatientIdResponse: HealthcampListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): HealthcampListByPatientIdActionTypes => {
  return {
    type: HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG,
    payload: healthcampListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const HealthcampListByPatientIdAPIResClearAction = (
  healthcampListByPatientIdResponse: HealthcampListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): HealthcampListByPatientIdActionTypes => {
  return {
    type: HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES,
    payload: healthcampListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
