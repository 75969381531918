import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Card, CardContent, Box, Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../../redux/store/Store";
import MWExceptionList from "../../../../component/MWExceptionList";
import { mappedOrganizationListByHealthcampIdApi } from "../../../../redux/OrganizationListMappedWithHealthCamp/API";
import {
  OrganizationListMappedWithHealthCampBody,
  PatientOrganizationList,
} from "../../../../redux/OrganizationListMappedWithHealthCamp/Model";
import VisibilityIcon from "@mui/icons-material/Visibility";

type Props = {
  viewMappedOrganizationListLoader?: boolean | any;
};

export default function OrganizationListByHealthCamp({ viewMappedOrganizationListLoader }: Props) {
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  function gotoDetails(id: number) {
    history.push(`/patientlistbyorganization/${id}`);
  }
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 250,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "displayName",
      headerName: "Organization Name",
      width: 300,
      flex: 1,
    },
  ];

  const mappedHealthCampBody = {
    healthCampId: healthcampid,
  } as OrganizationListMappedWithHealthCampBody;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(mappedOrganizationListByHealthcampIdApi(mappedHealthCampBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.organizationListMappedWithHealthCampIdValue,
  );
  const healthcampValues = AdminAllHealthcampList.patientSearchRes?.patientOrganizationList;

  useEffect(() => {
    if (healthcampValues !== undefined) {
      let dataPointArr = healthcampValues.map((element: PatientOrganizationList) => ({
        id: element.id,
        displayName: element.displayName,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);
  const DataGridMarkup = (
    <Card>
      <CardContent>
        {healthcampValues && healthcampValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminAllHealthcampList.loading === true
        ? viewMappedOrganizationListLoader(true)
        : viewMappedOrganizationListLoader(false)}
      {DataGridMarkup}
    </Box>
  );
}
