import { PatientList } from "./Model";

export const PATIENT_LIST = 'PATIENT_LIST';
export const PATIENT_LIST_LOADING = "PATIENT_LIST_LOADING";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAIL = "PATIENT_LIST_FAIL";

// Get Patient List Under Patient Team
export interface GetPatientListStateType {
    patientListValue: PatientList[];
    loading: boolean;
    status: String;
}
interface PatientListActionType {
    type: typeof PATIENT_LIST;
    payload: PatientList[];
    status: String;
}
export interface PatientListLoading {
    type: typeof PATIENT_LIST_LOADING;
}
export interface PatientListSuccess {
    type: typeof PATIENT_LIST_SUCCESS;
    payload: PatientList[];
    status: String;
}
export interface PatientListFail {
    type: typeof PATIENT_LIST_FAIL;
    status: String;
}
export type PatientListTypes = PatientListActionType | PatientListLoading | PatientListSuccess | PatientListFail;