import {
  ADD_CASE_DATA,
  ADD_CASE_FAIL,
  ADD_CASE_LOADING,
  ADD_CASE_SUCCESS,
  AddCaseActionTypes,
  ADD_CASE_API_MSG,
  ADD_CASE_API_RES,
} from "./ActionTypes";
import { AddCaseResponse } from "./Model";

export const addCaseAction = (
  addCaseRes: AddCaseResponse
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_DATA,
    payload: addCaseRes,
  };
};

export const addCaseLoadingAction = (loading: boolean): AddCaseActionTypes => {
  return {
    type: ADD_CASE_LOADING,
    loading: loading,
  };
};

export const addCaseSuccessAction = (
  addCaseRes: AddCaseResponse,
  successMsg: string,
  status: number
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_SUCCESS,
    payload: addCaseRes,
    successMsg: successMsg,
    status: status,
  };
};

export const addCaseErrorAction = (
  addCaseRes: AddCaseResponse,
  errMsg: string,
  status: number
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_FAIL,
    payload: addCaseRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const addCaseUpdateAPIMsgAction = (
  addCaseRes: AddCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_API_MSG,
    payload: addCaseRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addCaseAPIResClearAction = (
  addCaseRes: AddCaseResponse,
  successMsg: string,
  errMsg: string,
  status: number
): AddCaseActionTypes => {
  return {
    type: ADD_CASE_API_RES,
    payload: addCaseRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
