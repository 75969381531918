import {
  ADD_STAFF_OF_HEALTHCAMP_DATA,
  ADD_STAFF_OF_HEALTHCAMP_FAIL,
  ADD_STAFF_OF_HEALTHCAMP_LOADING,
  ADD_STAFF_OF_HEALTHCAMP_SUCCESS,
  ADD_STAFF_OF_HEALTHCAMP_API_MSG,
  ADD_STAFF_OF_HEALTHCAMP_API_RES,
  AddStaffOfHealthcampActionTypes,
} from "./ActionTypes";
import { PageCount, AddStaffOfHealthcampDetails } from "./Model";

export const AddStaffHealthcampIdAction = (
  AddStaffOfHealthcampDetails: AddStaffOfHealthcampDetails,
  pageCount: PageCount,
): AddStaffOfHealthcampActionTypes => {
  return {
    type: ADD_STAFF_OF_HEALTHCAMP_DATA,
    payload: AddStaffOfHealthcampDetails,
    pageCount: pageCount,
  };
};

export const addStaffOfHealthcampLoadingAction = (
  loading: boolean,
): AddStaffOfHealthcampActionTypes => {
  return {
    type: ADD_STAFF_OF_HEALTHCAMP_LOADING,
    loading: loading,
  };
};

export const addStaffOfHealthcampSuccessAction = (
  addStaffHealthcampResponse: AddStaffOfHealthcampDetails,
  successMsg: string,
): AddStaffOfHealthcampActionTypes => {
  return {
    type: ADD_STAFF_OF_HEALTHCAMP_SUCCESS,
    payload: addStaffHealthcampResponse,
    successMsg: successMsg,
  };
};

export const addStaffOfHealthcampErrorAction = (
  addStaffHealthcampResponse: AddStaffOfHealthcampDetails,
  errMsg: string,
  status: number,
): AddStaffOfHealthcampActionTypes => {
  return {
    type: ADD_STAFF_OF_HEALTHCAMP_FAIL,
    payload: addStaffHealthcampResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addStaffOfHealthcampAPIMsgAction = (
  addStaffHealthcampResponse: AddStaffOfHealthcampDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfHealthcampActionTypes => {
  return {
    type: ADD_STAFF_OF_HEALTHCAMP_API_MSG,
    payload: addStaffHealthcampResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addStaffOfHealthcampAPIResClearAction = (
  addStaffHealthcampResponse: AddStaffOfHealthcampDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfHealthcampActionTypes => {
  return {
    type: ADD_STAFF_OF_HEALTHCAMP_API_RES,
    payload: addStaffHealthcampResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
