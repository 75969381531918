import axios from "axios";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";
import {
  removeExecutiveAPIResClearAction,
  removeExecutiveErrorAction,
  removeExecutiveLoadingAction,
  removeExecutiveSuccessAction,
  removeExecutiveUpdateAPIMsgAction,
} from "./Actions";
import { Dispatch } from "react";
import { RemoveExecutiveActionTypes } from "./ActionTypes";
import { headers } from "component/Utility";
import Url from "../../../ApiConfig";
import { RemoveExecutivePayload, RemoveExecutiveRes } from "./Model";

let apiRes = {} as RemoveExecutiveRes;
export const removeExecutive = (payload: RemoveExecutivePayload) => {
  return function (dispatch: Dispatch<RemoveExecutiveActionTypes>) {
    dispatch(removeExecutiveLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/executives/delete_excutive`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(removeExecutiveLoadingAction(false));
        dispatch(
          removeExecutiveSuccessAction(
            res.data !== undefined ? res.data : ({} as RemoveExecutiveRes),
            res.data.message !== undefined
              ? res.data.message
              : "Executive has been removed successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(removeExecutiveLoadingAction(false));
        dispatch(
          removeExecutiveErrorAction(
            {} as RemoveExecutiveRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateRemoveExecutiveAPIResMsg = () => {
  return function (dispatch: Dispatch<RemoveExecutiveActionTypes>) {
    dispatch(removeExecutiveUpdateAPIMsgAction(apiRes as RemoveExecutiveRes, "", "", 0));
  };
};

export const clearRemoveExecutiveAPIRes = () => {
  return function (dispatch: Dispatch<RemoveExecutiveActionTypes>) {
    dispatch(removeExecutiveAPIResClearAction({} as RemoveExecutiveRes, "", "", 0));
  };
};
