import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetHealthCampDropDownListActionTypes } from "./ActionTypes";
import { GetHealthCampDropDownListRes, HealthCampSearchByOrganizationIdBody } from "./Model";
import {
  getHealthCampDropDownListAPIResClearAction,
  getHealthCampDropDownListErrorAction,
  getHealthCampDropDownListLoadingAction,
  getHealthCampDropDownListSuccessAction,
  getHealthCampDropDownListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetHealthCampDropDownListRes;
export const getHealthCampDropDownListApi = (
  searchKey: HealthCampSearchByOrganizationIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetHealthCampDropDownListActionTypes>) {
    dispatch(getHealthCampDropDownListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/caregiverapp/api/healthcamp/searchAllHealthcampDropdownList?page=${Number(
            pageNo,
          )}&size=10`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getHealthCampDropDownListLoadingAction(false));
        dispatch(getHealthCampDropDownListSuccessAction(res.data, "1"));
      })
      .catch((error) => {
        dispatch(getHealthCampDropDownListLoadingAction(false));
        dispatch(
          getHealthCampDropDownListErrorAction(
            {} as GetHealthCampDropDownListRes,
            error.response !== undefined ? error.response.data.message : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateGetHealthCampDropDownListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetHealthCampDropDownListActionTypes>) {
    dispatch(
      getHealthCampDropDownListUpdateAPIMsgAction(
        apiRes as GetHealthCampDropDownListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearHealthCampDropDownListAPIRes = () => {
  return function (dispatch: Dispatch<GetHealthCampDropDownListActionTypes>) {
    dispatch(
      getHealthCampDropDownListAPIResClearAction({} as GetHealthCampDropDownListRes, "", "", 0),
    );
  };
};
