import { PageCount, PatientSearchByOrganizationIdDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_SEARCH_BY_ORGANIZATION_LOADING = "PATIENT_SEARCH_BY_ORGANIZATION_LOADING";
export const PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS = "PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS";
export const PATIENT_SEARCH_BY_ORGANIZATION_FAIL = "PATIENT_SEARCH_BY_ORGANIZATION_FAIL";
export const PATIENT_SEARCH_BY_ORGANIZATION_DATA = "PATIENT_SEARCH_BY_ORGANIZATION_DATA";
export const PATIENT_SEARCH_UPDATE_API_MSG = "PATIENT_SEARCH_UPDATE_API_MSG";
export const PATIENT_SEARCH_UPDATE_API_RES = "PATIENT_SEARCH_UPDATE_API_RES";

export interface PatientSearchByOrganizationIdDetailsState {
  PatientSearchOrganizationIdRes: PatientSearchByOrganizationIdDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientSearchByOrganizationIdLoading {
  type: typeof PATIENT_SEARCH_BY_ORGANIZATION_LOADING;
  loading: boolean;
}
export interface PatientSearchByOrganizationIdSuccess {
  type: typeof PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS;
  payload: PatientSearchByOrganizationIdDetails;
}
export interface PatientSearchByOrganizationIdFail {
  type: typeof PATIENT_SEARCH_BY_ORGANIZATION_FAIL;
  payload: PatientSearchByOrganizationIdDetails;
  message: string;
  status: number;
}
export interface PatientSearchByOrganizationIdAPIMsg {
  type: typeof PATIENT_SEARCH_UPDATE_API_MSG;
  payload: PatientSearchByOrganizationIdDetails;
  message: string;
  status: number;
}
export interface PatientSearchOrganizationIdRes {
  type: typeof PATIENT_SEARCH_UPDATE_API_RES;
  payload: PatientSearchByOrganizationIdDetails;
  message: string;
  status: number;
}
interface PatientSearchByOrganizationIdDataAction {
  type: typeof PATIENT_SEARCH_BY_ORGANIZATION_DATA;
  payload: PatientSearchByOrganizationIdDetails;
  pageCount: PageCount;
}

export type PatientSearchByOrganizationIdActionTypes =
  | PatientSearchByOrganizationIdDataAction
  | PatientSearchByOrganizationIdLoading
  | PatientSearchByOrganizationIdSuccess
  | PatientSearchByOrganizationIdFail
  | PatientSearchByOrganizationIdAPIMsg
  | PatientSearchOrganizationIdRes;
