import {
  DELETE_ACCOUNT_REQUEST_FAIL,
  DELETE_ACCOUNT_REQUEST_LOADING,
  DELETE_ACCOUNT_REQUEST_SUCCESS,
  DELETE_ACCOUNT_REQUEST_API_MSG,
  DELETE_ACCOUNT_REQUEST_API_RES,
  DeleteAccountRequestActionTypes,
} from "./ActionTypes";
import { DeleteAccountRequestDetails } from "./Model";

export const deleteAccountRequestLoadingAction = (
  loading: boolean,
): DeleteAccountRequestActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_LOADING,
    loading: loading,
  };
};

export const deleteAccountRequestSuccessAction = (
  deleteAccountRequestResponse: DeleteAccountRequestDetails,
  successMsg: string,
): DeleteAccountRequestActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_SUCCESS,
    payload: deleteAccountRequestResponse,
    successMsg: successMsg,
  };
};

export const deleteAccountRequestErrorAction = (
  deleteAccountRequestResponse: DeleteAccountRequestDetails,
  errMsg: string,
  status: number,
): DeleteAccountRequestActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_FAIL,
    payload: deleteAccountRequestResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deleteAccountRequestAPIMsgAction = (
  deleteAccountRequestResponse: DeleteAccountRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DeleteAccountRequestActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_API_MSG,
    payload: deleteAccountRequestResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deleteAccountRequestAPIResClearAction = (
  deleteAccountRequestResponse: DeleteAccountRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DeleteAccountRequestActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_API_RES,
    payload: deleteAccountRequestResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
