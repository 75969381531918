import { Box, Button, ButtonGroup, Chip, Grid } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import MWResourceList from "component/MWResourceList";
import MWExceptionList from "component/MWExceptionList";
import PageLayout from "pages/Layout/PageLayout";
import { useEffect } from "react";
import { getPatientCaseListBySessionIdApi } from "redux/HealthcampSessionList/GetPatientCaseListBySessionId/API";
import { GetPatientCaseListBySessionIdBody } from "redux/HealthcampSessionList/GetPatientCaseListBySessionId/Model";
import React from "react";
import AddExistingPatientCaseDialog from "./AddExistingPatientCaseDialog";
import MWPageTitle from "component/MWPageTitle";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  GetPatientListBySessionIdBody,
  PatientList,
} from "redux/HealthcampSessionList/PatientListBySessionId/Model";
import { getPatientListBySessionIdApi } from "redux/HealthcampSessionList/PatientListBySessionId/API";
import _ from "lodash";
import AddNewPatientCaseDialog from "./AddNewPatientCaseDialog";

type Props = {
  patientCaseListLoader: boolean | any;
};
export default function PatientCaseList({ patientCaseListLoader }: Props) {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const patientId = patientid.split("-")[0];
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const caseHistoryBySessionId = useSelector(
    (state: AppState) => state.getPatientCaseListBySessionIdResponse,
  );
  const getPatientListValues = useSelector(
    (state: AppState) => state.getPatientListBySessionIdValues,
  );

  useEffect(() => {
    dispatch(
      getPatientCaseListBySessionIdApi({
        healthcampSessionId: sessionId,
      } as GetPatientCaseListBySessionIdBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [addNewPatientCaseDialog, setAddNewPatientCaseDialog] = React.useState(false);
  const [showAddExistingPatientCaseDialog, setShowAddExistingPatientCaseDialog] =
    React.useState(false);
  const [getPatientListObject, setGetPatientListObject] = React.useState<PatientList | undefined>(
    undefined,
  );
  useEffect(() => {
    if (getPatientListObject === undefined) {
      let apiBody = {
        healthcampSessionId: sessionId,
      } as GetPatientListBySessionIdBody;
      dispatch(getPatientListBySessionIdApi(apiBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListObject]);
  useEffect(() => {
    if (getPatientListValues?.getPatientListBySessionIdRes?.patientList !== undefined) {
      setGetPatientListObject(
        _.find(getPatientListValues?.getPatientListBySessionIdRes?.patientList, [
          "patientId",
          Number(patientId),
        ]),
      );
    } else {
      setGetPatientListObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListValues]);
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowAddExistingPatientCaseDialog(passedVal);
  };

  const updateAddNewDialogValFromChild = (addPassedVal: boolean) => {
    setAddNewPatientCaseDialog(addPassedVal);
  };

  const patientCaseListApi = () => {
    dispatch(
      getPatientCaseListBySessionIdApi({
        healthcampSessionId: sessionId,
      } as GetPatientCaseListBySessionIdBody),
    );
  };

  const addNewCase = () => {
    //history.push(`/casedetails/${patientId}?&tab=consultationType`);
    setAddNewPatientCaseDialog(true);
  };

  const pageBody = (
    <Box py={2}>
      {caseHistoryBySessionId?.getPatientCaseListBySessionIdRes?.patientCaseList !== undefined ? (
        caseHistoryBySessionId?.getPatientCaseListBySessionIdRes?.patientCaseList.length > 0 ? (
          caseHistoryBySessionId?.getPatientCaseListBySessionIdRes?.patientCaseList.map(
            (element: any) => (
              <Box key={element.id} style={{ textDecoration: "none", textTransform: "none" }}>
                <Link
                  to={`/casedetails/${element.patientId}/${element.caseId}`}
                  style={{ textDecoration: "none" }}
                >
                  <MWResourceList
                    icon={true}
                    title={element?.whyWant2ndOpinion}
                    showSubtitle={true}
                    subtitle={element?.secondOpinionReasons}
                    showReason={true}
                    reason={element?.consultationType}
                    showNavigation={true}
                  />
                </Link>
              </Box>
            ),
          )
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );
  return (
    <Box>
      {caseHistoryBySessionId?.loading ? patientCaseListLoader(true) : patientCaseListLoader(false)}
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <MWPageTitle
                  title={`Patient Details List of ${
                    getPatientListObject?.givenName + " " + getPatientListObject?.familyName
                  }`}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={
                    caseHistoryBySessionId?.getPatientCaseListBySessionIdRes?.patientCaseList !==
                    undefined
                      ? String(
                          caseHistoryBySessionId?.getPatientCaseListBySessionIdRes?.patientCaseList
                            .length,
                        )
                      : "0"
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item>
                <RefreshIcon
                  sx={{ mt: 2, cursor: "pointer" }}
                  onClick={() => {
                    patientCaseListApi();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <ButtonGroup variant="outlined" disableElevation aria-label="Disabled button group">
                <Button onClick={() => setShowAddExistingPatientCaseDialog(true)}>
                  Add Existing Patient Case
                </Button>
                <Button onClick={() => addNewCase()}>Add New Patient Case</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        {pageBody}
        {addNewPatientCaseDialog ? (
          <AddNewPatientCaseDialog
            filterDialogEnable={addNewPatientCaseDialog}
            filterDialogUpdateState={updateAddNewDialogValFromChild}
          />
        ) : null}
        {showAddExistingPatientCaseDialog ? (
          <AddExistingPatientCaseDialog
            filterDialogEnable={showAddExistingPatientCaseDialog}
            filterDialogUpdateState={updateFilterDialogValFromChild}
            healthcampSessionId={sessionId}
            patientid={patientId}
          />
        ) : null}
      </PageLayout>
    </Box>
  );
}
