import {
  ADD_DOCTOR_IN_HEALTH_CAMP_FAIL,
  ADD_DOCTOR_IN_HEALTH_CAMP_LOADING,
  ADD_DOCTOR_IN_HEALTH_CAMP_SUCCESS,
  ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_MSG,
  ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_RES,
  AddDoctorInHealthCampActionTypes,
} from "./ActionTypes";
import { AddDoctorInHealthCampRes } from "./Model";

export const getHealthcampStaffListLoadingAction = (
  loading: boolean,
): AddDoctorInHealthCampActionTypes => {
  return {
    type: ADD_DOCTOR_IN_HEALTH_CAMP_LOADING,
    loading: loading,
  };
};

export const getHealthcampStaffListSuccessAction = (
  getHealthCampStaffListresponse: AddDoctorInHealthCampRes,
  successMsg: string,
): AddDoctorInHealthCampActionTypes => {
  return {
    type: ADD_DOCTOR_IN_HEALTH_CAMP_SUCCESS,
    payload: getHealthCampStaffListresponse,
    successMsg: successMsg,
  };
};

export const getHealthcampStaffListErrorAction = (
  getHealthCampStaffListresponse: AddDoctorInHealthCampRes,
  errMsg: string,
  status: number,
): AddDoctorInHealthCampActionTypes => {
  return {
    type: ADD_DOCTOR_IN_HEALTH_CAMP_FAIL,
    payload: getHealthCampStaffListresponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthcampStaffListUpdateAPIMsgAction = (
  getHealthCampStaffListresponse: AddDoctorInHealthCampRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDoctorInHealthCampActionTypes => {
  return {
    type: ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_MSG,
    payload: getHealthCampStaffListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthcampStaffListAPIResClearAction = (
  getHealthCampStaffListresponse: AddDoctorInHealthCampRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDoctorInHealthCampActionTypes => {
  return {
    type: ADD_DOCTOR_IN_HEALTH_CAMP_UPDATE_API_RES,
    payload: getHealthCampStaffListresponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
