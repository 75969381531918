import { useDispatch, useSelector } from "react-redux";
import { clearSessionListByHealthCampIdAPIRes } from "redux/HealthcampSessionList/API";
import {
  addPatientBySessionIdApiCall,
  clearAddPatientBySessionIdAPIRes,
} from "redux/HealthcampSessionList/AddPatientBySessionId/API";
import { AddPatientBySessionIdBody } from "redux/HealthcampSessionList/AddPatientBySessionId/Model";
import { clearInstanceListByHealthCampIdAPIRes } from "redux/InstanceListOfHealthcamp /API";
import { clearOrganizationListAPIRes } from "redux/OrganizationList/API";
import { addPatientAPIResMsg, clearAddPatientAPIRes } from "redux/addHealthcampPatient/API";
import { clearHealthCampByOrganizationListAPIRes } from "redux/getMappedHealthcampsByOrganization/ApiCall";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Link,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { AppState } from "redux/store/Store";
import AdvCheckIn from "pages/PatientListByOrganization/AddPatientDialog/AdvCheckIn";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getPreferenceDetails } from "redux/Preference/GetPreference/API";
import Appointment from "./Appointment";

type Props = {
  addPatientSuccessMessage: string;
  patientId: number;
  successMsgUpdateState: any;
};

export default function CheckIn({
  addPatientSuccessMessage,
  patientId,
  successMsgUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const preferenceDetails = useSelector((state: AppState) => state.getPreferenceDetails);
  const addPatientResponse = useSelector((state: AppState) => state.addPatientBySessionIdValues);

  const [updateaddPatientSuccessMsg, setUpdateAddPatientSuccessMsg] = useState("");
  const [updatepatientId, setUpdatePatientId] = useState(0);

  const [selected, setSelected] = useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  const [checkInDialogState, setCheckInDialogState] = useState(false);
  useEffect(() => {
    if (addPatientSuccessMessage !== "") {
      setCheckInDialogState(true);
    }
  }, [addPatientSuccessMessage]);

  useEffect(() => {
    dispatch(getPreferenceDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUpdateAddPatientSuccessMsg(addPatientSuccessMessage);
    setUpdatePatientId(patientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientSuccessMessage, patientId]);

  const closeAlert = () => {
    setUpdateAddPatientSuccessMsg("");
    setUpdatePatientId(0);
    dispatch(addPatientAPIResMsg());
    dispatch(clearAddPatientAPIRes());
    dispatch(clearOrganizationListAPIRes());
    dispatch(clearHealthCampByOrganizationListAPIRes());
    dispatch(clearInstanceListByHealthCampIdAPIRes());
    dispatch(clearSessionListByHealthCampIdAPIRes());
    dispatch(clearAddPatientBySessionIdAPIRes());
    successMsgUpdateState("");
    setCheckInDialogState(false);
  };
  const gotoDetails = (patientId: number) => {
    window.open(`/demographicdetails/${patientId}`);
  };

  const defaultCheckin = (updatepatientId: number) => {
    const addExistingPatientBody = {
      healthcampSessionId: String(
        preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampSessionId,
      ),
      patientId: String(updatepatientId),
    } as AddPatientBySessionIdBody;
    dispatch(addPatientBySessionIdApiCall(addExistingPatientBody));
  };
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={checkInDialogState}
      disableEscapeKeyDown
      PaperProps={{
        style: {
          minHeight: "40vh",
          minWidth: "70vw",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // sx={{ px: 2, pt: 2 }}
        >
          <Grid item>
            <Typography variant="h5" color="#67be23">
              <CheckCircleOutlineIcon />
              &nbsp;{updateaddPatientSuccessMsg}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeAlert}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Stack direction="column">
            <Link
              sx={{ m: 2, maxWidth: 150 }}
              component="button"
              variant="h2"
              onClick={() => gotoDetails(updatepatientId)}
            >
              {updatepatientId}
            </Link>

            <Box p={2}>
              <TabContext value={String(selected)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabChange}>
                    <Tab label={<Typography>Check-In</Typography>} value="1" />
                    <Tab label={<Typography>Appointment</Typography>} value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ pl: 2, pr: 4 }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                          <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            sx={{ padding: 1 }}
                            spacing={1}
                          >
                            <Typography variant="h6">
                              Check-In Patient with following Preference
                            </Typography>

                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={0.5}
                            >
                              {preferenceDetails?.GetPreferenceDetailsResponse
                                ?.CareGiverDefaultPreferences !== undefined ? (
                                preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences.hasOwnProperty(
                                  "organizationName",
                                ) ? (
                                  <>
                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                      <CorporateFareOutlinedIcon />
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11} lg={11}>
                                      <Typography variant="body2">
                                        {
                                          preferenceDetails?.GetPreferenceDetailsResponse
                                            ?.CareGiverDefaultPreferences?.organizationName
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : null
                              ) : null}

                              {preferenceDetails?.GetPreferenceDetailsResponse
                                ?.CareGiverDefaultPreferences !== undefined ? (
                                preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences.hasOwnProperty(
                                  "healthcampInstanceName",
                                ) ? (
                                  <>
                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                      <HolidayVillageOutlinedIcon />
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11} lg={11}>
                                      <Typography variant="body2">
                                        {
                                          preferenceDetails?.GetPreferenceDetailsResponse
                                            ?.CareGiverDefaultPreferences?.healthcampInstanceName
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : null
                              ) : null}

                              {preferenceDetails?.GetPreferenceDetailsResponse
                                ?.CareGiverDefaultPreferences !== undefined ? (
                                preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences.hasOwnProperty(
                                  "healthcampSessionName",
                                ) ? (
                                  <>
                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                      <AccessTimeOutlinedIcon />
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11} lg={11}>
                                      <Typography variant="body2">
                                        {
                                          preferenceDetails?.GetPreferenceDetailsResponse
                                            ?.CareGiverDefaultPreferences?.healthcampSessionName
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : null
                              ) : null}
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <LoadingButton
                            onClick={() => defaultCheckin(patientId)}
                            loading={addPatientResponse?.loading}
                            variant="contained"
                          >
                            Check-In
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <AdvCheckIn updatepatientId={updatepatientId} />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Appointment updatepatientId={updatepatientId} />
                </TabPanel>
              </TabContext>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
