import { PatientQuriesListResponse } from "./Model";

export const PATIENT_QURIES_LIST_LOADING = "PATIENT_QURIES_LIST_LOADING";
export const PATIENT_QURIES_LIST_SUCCESS = "PATIENT_QURIES_LIST_SUCCESS";
export const PATIENT_QURIES_LIST_FAIL = "PATIENT_QURIES_LIST_FAIL";
export const PATIENT_QURIES_LIST_API_MSG = "PATIENT_QURIES_LIST_API_MSG";
export const PATIENT_QURIES_LIST_CLEAR_API_RES = "PATIENT_QURIES_LIST_CLEAR_API_RES";

export interface PatientQuriesListDataState {
  patientQueriesRes: PatientQuriesListResponse;
  loading: boolean;
  message: string;
  status: number;
}

export interface PatientQuriesListLoading {
  type: typeof PATIENT_QURIES_LIST_LOADING;
  loading: boolean;
}
export interface PatientQuriesListSuccess {
  type: typeof PATIENT_QURIES_LIST_SUCCESS;
  payload: PatientQuriesListResponse;
}
export interface PatientQuriesListFail {
  type: typeof PATIENT_QURIES_LIST_FAIL;
  payload: PatientQuriesListResponse;
  message: string;
  status: number;
}
export interface PatientQuriesAPIMsg {
  type: typeof PATIENT_QURIES_LIST_API_MSG;
  payload: PatientQuriesListResponse;
  message: string;
  status: number;
}
export interface PatientQuriesAPIRes {
  type: typeof PATIENT_QURIES_LIST_CLEAR_API_RES;
  payload: PatientQuriesListResponse;
  message: string;
  status: number;
}

export type PatientQuriesListActionTypes =
  | PatientQuriesListLoading
  | PatientQuriesListSuccess
  | PatientQuriesListFail
  | PatientQuriesAPIMsg
  | PatientQuriesAPIRes;
