import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { GetSessionListBody, HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import { PatientCheckInBySessionIdFromAppointmnetListBody } from "redux/HealthcampSessionList/PatientCheckInFromAppointmentList/Model";
import { patientCheckInBySessionIdFromAppointmnetListApiCall, updatePatientCheckInBySessionIdFromAppointmnetListAPIResMsg } from "redux/HealthcampSessionList/PatientCheckInFromAppointmentList/API";
import { GetAppointmentListHealthcampBody } from "redux/AppointmentListOfHealthcampInstance/Model";
import { getAppointmentListOfHealthcampInstance } from "redux/AppointmentListOfHealthcampInstance/API";

type Props = {
  checkInDialogEnable?: boolean | any;
  checkInDialogUpdateState?: boolean | any;
  patientId: string;
  instanceHealthcampId: string;
};

export default function AddCheckInDialogBySession({
  checkInDialogEnable,
  checkInDialogUpdateState,
  patientId,
  instanceHealthcampId,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    checkInDialogUpdateState(false);
    dispatch(updatePatientCheckInBySessionIdFromAppointmnetListAPIResMsg());
  };
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const addResponse = useSelector((state: AppState) => state.patientCheckInValues);
  const [sessionList, setSessionList] = useState([] as any[]);
  const [selectedSessionName, setSelectedSessionName] = useState<number | null>(null);
  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      handleDialogClose();
      const healthcampAppoinmentListBody = {
        healthcampInstanceId: instanceHealthcampId,
      } as GetAppointmentListHealthcampBody;
      dispatch(getAppointmentListOfHealthcampInstance(healthcampAppoinmentListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  React.useEffect(() => {
    setDialogState(checkInDialogEnable);
    const healthcampSessionListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetSessionListBody;
    dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInDialogEnable]);

  React.useEffect(() => {
    if (sessionListResponseValue?.getSessionList?.healthcampSessionList !== undefined) {
      setSessionList(sessionListResponseValue?.getSessionList?.healthcampSessionList);
    } else setSessionList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListResponseValue]);

  const patientSessionChange = (_selectedObservationVal: any, newvalue: HealthcampSessionList) => {
    setSelectedSessionName(newvalue.id);
  };

  const patientCheckIn = () => {
    let apiBody = {
      healthcampSessionId: String(selectedSessionName),
      patientId: patientId,
    } as PatientCheckInBySessionIdFromAppointmnetListBody;
    dispatch(patientCheckInBySessionIdFromAppointmnetListApiCall(apiBody));
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {addResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Patient Check In</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                value={
                  sessionList.find((element: any) => element.id === selectedSessionName) || null
                }
                id="sessionId"
                options={sessionList}
                onChange={(event: any, newValue: any) => {
                  patientSessionChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Session Name" size="small" />
                )}
                getOptionLabel={(option) => `${option.displayName}`}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={() => patientCheckIn()}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
