import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { DeleteDoctorForCaseActionTypes } from "./ActionTypes";
import { DeleteDoctorForCaseBody, DeleteDoctorForCaseRes } from "./Model";
import {
  deleteDoctorForCaseAPIResClearAction,
  deleteDoctorForCaseErrorAction,
  deleteDoctorForCaseLoadingAction,
  deleteDoctorForCaseSuccessAction,
  deleteDoctorForCaseUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "../../../../component/Utility";

let apiRes = {} as DeleteDoctorForCaseRes;
export const deleteDoctorForCase = (payload: DeleteDoctorForCaseBody) => {
  return function (dispatch: Dispatch<DeleteDoctorForCaseActionTypes>) {
    dispatch(deleteDoctorForCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/delete_selected_doctor`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(deleteDoctorForCaseLoadingAction(false));
        dispatch(
          deleteDoctorForCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor has been deleted successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deleteDoctorForCaseLoadingAction(false));
        dispatch(
          deleteDoctorForCaseErrorAction(
            {} as DeleteDoctorForCaseRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDeleteDoctorForCaseAPIResMsg = () => {
  return function (dispatch: Dispatch<DeleteDoctorForCaseActionTypes>) {
    dispatch(deleteDoctorForCaseUpdateAPIMsgAction(apiRes as DeleteDoctorForCaseRes, "", "", 0));
  };
};

export const clearDeleteDoctorForCaseAPIRes = () => {
  return function (dispatch: Dispatch<DeleteDoctorForCaseActionTypes>) {
    dispatch(deleteDoctorForCaseAPIResClearAction({} as DeleteDoctorForCaseRes, "", "", 0));
  };
};
