import {
    OrganizationToggleActiveRegistrationRequestDetailsActionTypes,
   ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL,
   ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING,
   ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS,
   OrganizationToggleActiveRegistrationRequestDetailsState,
   ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG,
   ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES,
 } from "./ActionTypes";
 import { OrganizationToggleActiveRegReqDetailsRes } from "./Model";
 
 const initialStateGetPosts: OrganizationToggleActiveRegistrationRequestDetailsState = {
   loading: false,
   organizationToggleActiveRegReqDetailsList: {} as OrganizationToggleActiveRegReqDetailsRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const  organizationToggleActiveRegistrationRequestDetailsReducer = (
   state = initialStateGetPosts,
   action:  OrganizationToggleActiveRegistrationRequestDetailsActionTypes,
 ): OrganizationToggleActiveRegistrationRequestDetailsState => {
   switch (action.type) {
     case ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS:
       return {
         ...state,
         organizationToggleActiveRegReqDetailsList: action.payload,
         successMsg: action.successMsg,
       };
     case ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL:
       return {
         ...state,
         organizationToggleActiveRegReqDetailsList: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG:
       return {
         ...state,
         organizationToggleActiveRegReqDetailsList: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case ORGANIZATION_TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES:
       return {
         ...state,
         organizationToggleActiveRegReqDetailsList: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 