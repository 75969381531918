import {
  DOCTOR_LIST_IN_HEALTH_CAMP_FAIL,
  DOCTOR_LIST_IN_HEALTH_CAMP_LOADING,
  DOCTOR_LIST_IN_HEALTH_CAMP_SUCCESS,
  DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_MSG,
  DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_RES,
  GetDoctorListInHealthCampActionTypes,
} from "./ActionTypes";
import { GetDoctorListInHealthCampRes } from "./Model";

export const getDoctorListInHealthCampLoadingAction = (
  loading: boolean,
): GetDoctorListInHealthCampActionTypes => {
  return {
    type: DOCTOR_LIST_IN_HEALTH_CAMP_LOADING,
    loading: loading,
  };
};

export const getDoctorListInHealthCampSuccessAction = (
  getDoctorListInHealthCampListResponse: GetDoctorListInHealthCampRes,
  successMsg: string,
): GetDoctorListInHealthCampActionTypes => {
  return {
    type: DOCTOR_LIST_IN_HEALTH_CAMP_SUCCESS,
    payload: getDoctorListInHealthCampListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorListInHealthCampErrorAction = (
  getDoctorListInHealthCampListResponse: GetDoctorListInHealthCampRes,
  errMsg: string,
  status: number,
): GetDoctorListInHealthCampActionTypes => {
  return {
    type: DOCTOR_LIST_IN_HEALTH_CAMP_FAIL,
    payload: getDoctorListInHealthCampListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorListInHealthCampUpdateAPIMsgAction = (
  getDoctorListInHealthCampListResponse: GetDoctorListInHealthCampRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorListInHealthCampActionTypes => {
  return {
    type: DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_MSG,
    payload: getDoctorListInHealthCampListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorListInHealthCampAPIResClearAction = (
  getDoctorListInHealthCampListResponse: GetDoctorListInHealthCampRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorListInHealthCampActionTypes => {
  return {
    type: DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_RES,
    payload: getDoctorListInHealthCampListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
