import {
  REMOVE_EXECUTIVE_FAIL,
  REMOVE_EXECUTIVE_LOADING,
  REMOVE_EXECUTIVE_SUCCESS,
  REMOVE_EXECUTIVE_UPDATE_API_MSG,
  REMOVE_EXECUTIVE_UPDATE_API_RES,
  RemoveExecutiveActionTypes,
} from "./ActionTypes";
import { RemoveExecutiveRes } from "./Model";

export const removeExecutiveLoadingAction = (loading: boolean): RemoveExecutiveActionTypes => {
  return {
    type: REMOVE_EXECUTIVE_LOADING,
    loading: loading,
  };
};

export const removeExecutiveSuccessAction = (
  removeExecutiveRes: RemoveExecutiveRes,
  successMsg: string,
): RemoveExecutiveActionTypes => {
  return {
    type: REMOVE_EXECUTIVE_SUCCESS,
    payload: removeExecutiveRes,
    successMsg: successMsg,
  };
};

export const removeExecutiveErrorAction = (
  removeExecutiveRes: RemoveExecutiveRes,
  errMsg: string,
  status: number,
): RemoveExecutiveActionTypes => {
  return {
    type: REMOVE_EXECUTIVE_FAIL,
    payload: removeExecutiveRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const removeExecutiveUpdateAPIMsgAction = (
  removeExecutiveRes: RemoveExecutiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): RemoveExecutiveActionTypes => {
  return {
    type: REMOVE_EXECUTIVE_UPDATE_API_MSG,
    payload: removeExecutiveRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const removeExecutiveAPIResClearAction = (
  removeExecutiveRes: RemoveExecutiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): RemoveExecutiveActionTypes => {
  return {
    type: REMOVE_EXECUTIVE_UPDATE_API_RES,
    payload: removeExecutiveRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
