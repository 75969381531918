import { PageCount, GetStaffListByCompetitionDetails } from "./Model";

// Patient Demographics Loading State
export const STAFF_LIST_BY_COMPETITION_ID_LOADING = "STAFF_LIST_BY_COMPETITION_ID_LOADING";
export const STAFF_LIST_BY_COMPETITION_ID_SUCCESS = "STAFF_LIST_BY_COMPETITION_ID_SUCCESS";
export const STAFF_LIST_BY_COMPETITION_ID_FAIL = "STAFF_LIST_BY_COMPETITION_ID_FAIL";
export const STAFF_LIST_BY_COMPETITION_ID_DATA = "STAFF_LIST_BY_COMPETITION_ID_DATA";
export const COMPETITION_PATIENT_LIST_API_MSG = "COMPETITION_PATIENT_LIST_API_MSG";
export const COMPETITION_PATIENT_LIST_API_RES = "COMPETITION_PATIENT_LIST_API_RES";

export interface GetStaffListByCompetitionState {
  getStaffListByCompetitionRes: GetStaffListByCompetitionDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface GetStaffListByCompetitionLoading {
  type: typeof STAFF_LIST_BY_COMPETITION_ID_LOADING;
  loading: boolean;
}
export interface GetStaffListByCompetitionSuccess {
  type: typeof STAFF_LIST_BY_COMPETITION_ID_SUCCESS;
  payload: GetStaffListByCompetitionDetails;
}
export interface GetStaffListByCompetitionFail {
  type: typeof STAFF_LIST_BY_COMPETITION_ID_FAIL;
  payload: GetStaffListByCompetitionDetails;
  message: string;
  status: number;
}
export interface GetStaffListByCompetitionAPIMsg {
  type: typeof COMPETITION_PATIENT_LIST_API_MSG;
  payload: GetStaffListByCompetitionDetails;
  message: string;
  status: number;
}
export interface GetStaffListByCompetitionRes {
  type: typeof COMPETITION_PATIENT_LIST_API_RES;
  payload: GetStaffListByCompetitionDetails;
  message: string;
  status: number;
}
interface GetStaffListByCompetitionDataAction {
  type: typeof STAFF_LIST_BY_COMPETITION_ID_DATA;
  payload: GetStaffListByCompetitionDetails;
  pageCount: PageCount;
}

export type GetStaffListByCompetitionActionTypes =
  | GetStaffListByCompetitionDataAction
  | GetStaffListByCompetitionLoading
  | GetStaffListByCompetitionSuccess
  | GetStaffListByCompetitionFail
  | GetStaffListByCompetitionAPIMsg
  | GetStaffListByCompetitionRes;
