import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ObservationListByHealthcampIdActionTypes } from "./ActionTypes";
import { ObservationListByHealthcampIdBody, ObservationListByHealthcampIdRes } from "./Model";
import {
  observationListByHealthcampIdAPIResClearAction,
  observationListByHealthcampIdErrorAction,
  observationListByHealthcampIdLoadingAction,
  observationListByHealthcampIdSuccessAction,
  observationListByHealthcampIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as ObservationListByHealthcampIdRes;
export const observationListByHealthcampIdApi = (
  payload: ObservationListByHealthcampIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<ObservationListByHealthcampIdActionTypes>) {
    dispatch(observationListByHealthcampIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientObservation/get_patient_observation_of_session?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(observationListByHealthcampIdLoadingAction(false));
        dispatch(
          observationListByHealthcampIdSuccessAction(
            res.data !== undefined ? res.data : ({} as ObservationListByHealthcampIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Observation list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(observationListByHealthcampIdLoadingAction(false));
        dispatch(
          observationListByHealthcampIdErrorAction(
            {} as ObservationListByHealthcampIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateObservationListByHealthcampIdAPIResMsg = () => {
  return function (dispatch: Dispatch<ObservationListByHealthcampIdActionTypes>) {
    dispatch(
      observationListByHealthcampIdUpdateAPIMsgAction(
        apiRes as ObservationListByHealthcampIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearObservationListByHealthcampIdAPIRes = () => {
  return function (dispatch: Dispatch<ObservationListByHealthcampIdActionTypes>) {
    dispatch(
      observationListByHealthcampIdAPIResClearAction({} as ObservationListByHealthcampIdRes, "", "", 0),
    );
  };
};
