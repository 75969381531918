import {
  GET_LAST_MODIFIED_PATIENT_LIST_FAIL,
  GET_LAST_MODIFIED_PATIENT_LIST_LOADING,
  GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS,
  GET_LAST_MODIFIED_PATIENT_LIST_API_MSG,
  GET_LAST_MODIFIED_PATIENT_LIST_API_RES,
  GetLastModifiedPatientListActionTypes,
  GET_LAST_MODIFIED_PATIENT_LIST_DATA,
} from "./ActionTypes";
import { GetLastModifiedPatientListRes, PageCount } from "./Model";

export const lastModifiedPatientListAction = (
  GetLastModifiedPatientListRes: GetLastModifiedPatientListRes,
  pageCount: PageCount,
): GetLastModifiedPatientListActionTypes => {
  return {
    type: GET_LAST_MODIFIED_PATIENT_LIST_DATA,
    payload: GetLastModifiedPatientListRes,
    pageCount: pageCount,
  };
};

export const getLastModifiedPatientListLoadingAction = (
  loading: boolean,
): GetLastModifiedPatientListActionTypes => {
  return {
    type: GET_LAST_MODIFIED_PATIENT_LIST_LOADING,
    loading: loading,
  };
};

export const getLastModifiedPatientListSuccessAction = (
  getLastModifiedPatientListResponse: GetLastModifiedPatientListRes,
): GetLastModifiedPatientListActionTypes => {
  return {
    type: GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS,
    payload: getLastModifiedPatientListResponse,
  };
};

export const getLastModifiedPatientListErrorAction = (
  getLastModifiedPatientListResponse: GetLastModifiedPatientListRes,
  errMsg: string,
  status: number,
): GetLastModifiedPatientListActionTypes => {
  return {
    type: GET_LAST_MODIFIED_PATIENT_LIST_FAIL,
    payload: getLastModifiedPatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getLastModifiedPatientListAPIMsgAction = (
  getLastModifiedPatientListResponse: GetLastModifiedPatientListRes,
  errMsg: string,
  status: number,
): GetLastModifiedPatientListActionTypes => {
  return {
    type: GET_LAST_MODIFIED_PATIENT_LIST_API_MSG,
    payload: getLastModifiedPatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getLastModifiedPatientListAPIResClearAction = (
  getLastModifiedPatientListResponse: GetLastModifiedPatientListRes,
  errMsg: string,
  status: number,
): GetLastModifiedPatientListActionTypes => {
  return {
    type: GET_LAST_MODIFIED_PATIENT_LIST_API_RES,
    payload: getLastModifiedPatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
