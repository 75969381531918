import {
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES,
  DuplicatePatientDemographicsDetailsActionTypes,
} from "./ActionTypes";
import { DuplicatePatientDemographicsDetailsRes } from "./Model";

export const duplicatePatientDemographicsDetailsLoadingAction = (
  loading: boolean,
): DuplicatePatientDemographicsDetailsActionTypes => {
  return {
    type: DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING,
    loading: loading,
  };
};

export const duplicatePatientDemographicsDetailsSuccessAction = (
  getDuplicatePatientDemographicsDetailsResponse: DuplicatePatientDemographicsDetailsRes,
  successMsg: string,
): DuplicatePatientDemographicsDetailsActionTypes => {
  return {
    type: DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS,
    payload: getDuplicatePatientDemographicsDetailsResponse,
    successMsg: successMsg,
  };
};

export const duplicatePatientDemographicsDetailsErrorAction = (
  getDuplicatePatientDemographicsDetailsResponse: DuplicatePatientDemographicsDetailsRes,
  errMsg: string,
  status: number,
): DuplicatePatientDemographicsDetailsActionTypes => {
  return {
    type: DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL,
    payload: getDuplicatePatientDemographicsDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const duplicatePatientDemographicsDetailsUpdateAPIMsgAction = (
  getDuplicatePatientDemographicsDetailsResponse: DuplicatePatientDemographicsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DuplicatePatientDemographicsDetailsActionTypes => {
  return {
    type: DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG,
    payload: getDuplicatePatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const duplicatePatientDemographicsDetailsAPIResClearAction = (
  getDuplicatePatientDemographicsDetailsResponse: DuplicatePatientDemographicsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DuplicatePatientDemographicsDetailsActionTypes => {
  return {
    type: DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES,
    payload: getDuplicatePatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
