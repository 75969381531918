import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetHealthCampSessionReportActionTypes } from "./ActionTypes";
import { GetHealthCampSessionReportBody, GetHealthCampSessionReportRes } from "./Model";
import {
  getHealthCampSessionReportAPIResClearAction,
  getHealthCampSessionReportErrorAction,
  getHealthCampSessionReportLoadingAction,
  getHealthCampSessionReportSuccessAction,
  getHealthCampSessionReportUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetHealthCampSessionReportRes;
export const getReportByHealthCampSession = (payload: GetHealthCampSessionReportBody) => {
  return function (dispatch: Dispatch<GetHealthCampSessionReportActionTypes>) {
    dispatch(getHealthCampSessionReportLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthCampSessionReporting/get_status_report_of_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getHealthCampSessionReportLoadingAction(false));
        dispatch(
          getHealthCampSessionReportSuccessAction(
            res.data !== undefined ? res.data : ({} as GetHealthCampSessionReportRes),
            res.data.message !== undefined
              ? res.data.message
              : "Health camp session report has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getHealthCampSessionReportLoadingAction(false));
        dispatch(
          getHealthCampSessionReportErrorAction(
            {} as GetHealthCampSessionReportRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateReportByHealthCampSessionAPIResMsg = () => {
  return function (dispatch: Dispatch<GetHealthCampSessionReportActionTypes>) {
    dispatch(
      getHealthCampSessionReportUpdateAPIMsgAction(
        apiRes as GetHealthCampSessionReportRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearReportByHealthCampSessionAPIRes = () => {
  return function (dispatch: Dispatch<GetHealthCampSessionReportActionTypes>) {
    dispatch(
      getHealthCampSessionReportAPIResClearAction({} as GetHealthCampSessionReportRes, "", "", 0),
    );
  };
};
