import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddExistingPatientByProviderOrgIdActionTypes } from "./ActionTypes";
import { AddExistingPatientByProviderOrgIdBody, AddExistingPatientByProviderOrgIdDetails } from "./Model";
import {
  addExistingPatientByProviderOrgIdAPIResClearAction,
  addExistingPatientByProviderOrgIdErrorAction,
  addExistingPatientByProviderOrgIdLoadingAction,
  addExistingPatientByProviderOrgIdSuccessAction,
  addExistingPatientByProviderOrgIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddExistingPatientByProviderOrgIdDetails;
export const addExistingPatientByProviderOrgIdApiCall = (payload: AddExistingPatientByProviderOrgIdBody) => {
  return function (dispatch: Dispatch<AddExistingPatientByProviderOrgIdActionTypes>) {
    dispatch(addExistingPatientByProviderOrgIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/providerorganizationapp/api/providerOrganizationPatientList/map_patient_providerOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addExistingPatientByProviderOrgIdLoadingAction(false));
        dispatch(
          addExistingPatientByProviderOrgIdSuccessAction(
            res.data !== undefined ? res.data : ({} as AddExistingPatientByProviderOrgIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Add Doctor successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addExistingPatientByProviderOrgIdLoadingAction(false));
        dispatch(
          addExistingPatientByProviderOrgIdErrorAction(
            {} as AddExistingPatientByProviderOrgIdDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddExistingPatientByProviderOrgIdAPIResMsg = () => {
  return function (dispatch: Dispatch<AddExistingPatientByProviderOrgIdActionTypes>) {
    dispatch(addExistingPatientByProviderOrgIdAPIMsgAction(apiRes as AddExistingPatientByProviderOrgIdDetails, "", "", 0));
  };
};

export const clearAddExistingPatientByProviderOrgIdAPIRes = () => {
  return function (dispatch: Dispatch<AddExistingPatientByProviderOrgIdActionTypes>) {
    dispatch(addExistingPatientByProviderOrgIdAPIResClearAction({} as AddExistingPatientByProviderOrgIdDetails, "", "", 0));
  };
};
