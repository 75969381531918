import {
  InactiveCorporatePackagesActionTypes,
  INACTIVE_CORPORATE_LIST_FAIL,
  INACTIVE_CORPORATE_LIST_LOADING,
  INACTIVE_CORPORATE_LIST_SUCCESS,
  GetInactiveCorporatePackageListStateType,
} from "./ActionTypes";

const initialStateGetActiveCorporateList: GetInactiveCorporatePackageListStateType =
  { loading: false, inactiveCorporatePackageListValue: [] };

export const getInactiveCorporatePackageListReducer = (
  state = initialStateGetActiveCorporateList,
  action: InactiveCorporatePackagesActionTypes
): GetInactiveCorporatePackageListStateType => {
  switch (action.type) {
    case INACTIVE_CORPORATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        inactiveCorporatePackageListValue: action.payload,
      };
    case INACTIVE_CORPORATE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        inactiveCorporatePackageListValue: [],
      };
    case INACTIVE_CORPORATE_LIST_FAIL:
      return {
        ...state,
        inactiveCorporatePackageListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};
