import { ObservationListByHealthcampIdRes } from "./Model";
export const OBSERVATION_LIST_BY_HEALTHCAMP_ID_LOADING = "OBSERVATION_LIST_BY_HEALTHCAMP_ID_LOADING";
export const OBSERVATION_LIST_BY_HEALTHCAMP_ID_SUCCESS = "OBSERVATION_LIST_BY_HEALTHCAMP_ID_SUCCESS";
export const OBSERVATION_LIST_BY_HEALTHCAMP_ID_FAIL = "OBSERVATION_LIST_BY_HEALTHCAMP_ID_FAIL";
export const OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG = "OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG";
export const OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES = "OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES";

export interface ObservationListByHealthcampIdState {
  observationListByHealthcampIdRes: ObservationListByHealthcampIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ObservationListByHealthcampIdLoading {
  type: typeof OBSERVATION_LIST_BY_HEALTHCAMP_ID_LOADING;
  loading: boolean;
}
export interface ObservationListByHealthcampIdSuccess {
  type: typeof OBSERVATION_LIST_BY_HEALTHCAMP_ID_SUCCESS;
  payload: ObservationListByHealthcampIdRes;
  successMsg: string;
}
export interface ObservationListByHealthcampIdFail {
  type: typeof OBSERVATION_LIST_BY_HEALTHCAMP_ID_FAIL;
  payload: ObservationListByHealthcampIdRes;
  errorMsg: string;
  status: number;
}
export interface ObservationListByHealthcampIdUpdateAPIMsg {
  type: typeof OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG;
  payload: ObservationListByHealthcampIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ObservationListByHealthcampIdUpdateAPIRes {
  type: typeof OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES;
  payload: ObservationListByHealthcampIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ObservationListByHealthcampIdActionTypes =
  | ObservationListByHealthcampIdLoading
  | ObservationListByHealthcampIdSuccess
  | ObservationListByHealthcampIdFail
  | ObservationListByHealthcampIdUpdateAPIMsg
  | ObservationListByHealthcampIdUpdateAPIRes;
