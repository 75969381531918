import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPreferenceDetailsActionTypes } from "./ActionTypes";
import { GetPreferenceResponse } from "./Model";
import {
  getPreferenceAPIResClearAction,
  getPreferenceErrorAction,
  getPreferenceLoadingAction,
  getPreferenceSuccessAction,
  getPreferenceUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetPreferenceResponse;
export const getPreferenceDetails = () => {
  return function (dispatch: Dispatch<GetPreferenceDetailsActionTypes>) {
    dispatch(getPreferenceLoadingAction(true));
    axios
      .get(Url.baseUrl + `/caregiverapp/api/defaultPref/getPreferences`, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(getPreferenceLoadingAction(false));
        dispatch(getPreferenceSuccessAction(res.data, "Preferences has been fetched successfully"));
      })
      .catch((error) => {
        dispatch(getPreferenceLoadingAction(false));
        dispatch(
          getPreferenceErrorAction(
            {} as GetPreferenceResponse,
            error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePreferenceAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPreferenceDetailsActionTypes>) {
    dispatch(getPreferenceUpdateAPIMsgAction(apiRes as GetPreferenceResponse, "", "", 0));
  };
};

export const clearPreferenceAPIRes = () => {
  return function (dispatch: Dispatch<GetPreferenceDetailsActionTypes>) {
    dispatch(getPreferenceAPIResClearAction({} as GetPreferenceResponse, "", "", 0));
  };
};
