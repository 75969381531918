import {
  AddExecutiveSignupRequestActionTypes,
  ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL,
  ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING,
  ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS,
  AddProviderOrgAdministratorState,
  ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG,
  ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES,
} from "./ActionTypes";
import { AddExecutiveSignupRequestRes } from "./Model";

const initialStateUpdatePosts: AddProviderOrgAdministratorState = {
  loading: false,
  addExecutiveSignupRequestRes: {} as AddExecutiveSignupRequestRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addExecutiveSignupRequestReducer = (
  state = initialStateUpdatePosts,
  action: AddExecutiveSignupRequestActionTypes,
): AddProviderOrgAdministratorState => {
  switch (action.type) {
    case ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        addExecutiveSignupRequestRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL:
      return {
        ...state,
        addExecutiveSignupRequestRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG:
      return {
        ...state,
        addExecutiveSignupRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES:
      return {
        ...state,
        addExecutiveSignupRequestRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
