import {
  AddStaffOfOrgActionTypes,
  ADD_STAFF_OF_ORG_FAIL,
  ADD_STAFF_OF_ORG_LOADING,
  ADD_STAFF_OF_ORG_SUCCESS,
  AddStaffOfOrgState,
  ADD_STAFF_OF_ORG_API_MSG,
  ADD_STAFF_OF_ORG_API_RES,
} from "./ActionTypes";
import { AddStaffOfOrgDetails } from "./Model";

const initialStateGetPosts: AddStaffOfOrgState = {
  loading: false,
  AddStaffOfOrgRes: {} as AddStaffOfOrgDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addStaffOfOrgReducer = (
  state = initialStateGetPosts,
  action: AddStaffOfOrgActionTypes,
): AddStaffOfOrgState => {
  switch (action.type) {
    case ADD_STAFF_OF_ORG_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_STAFF_OF_ORG_SUCCESS:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_STAFF_OF_ORG_FAIL:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_STAFF_OF_ORG_API_MSG:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_STAFF_OF_ORG_API_RES:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
