import { PageCount, ProviderOrgPatientSearchDetails } from "./Model";

// Patient Demographics Loading State
export const PROVIDER_ORG_PATIENT_SEARCH_LOADING = "PROVIDER_ORG_PATIENT_SEARCH_LOADING";
export const PROVIDER_ORG_PATIENT_SEARCH_SUCCESS = "PROVIDER_ORG_PATIENT_SEARCH_SUCCESS";
export const PROVIDER_ORG_PATIENT_SEARCH_FAIL = "PROVIDER_ORG_PATIENT_SEARCH_FAIL";
export const PROVIDER_ORG_PATIENT_SEARCH_DATA = "PROVIDER_ORG_PATIENT_SEARCH_DATA";
export const PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_MSG = "PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_MSG";
export const PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_RES = "PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_RES";

export interface ProviderOrgPatientSearchDetailsState {
  providerOrgPatientSearchRes: ProviderOrgPatientSearchDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface ProviderOrgPatientSearchLoading {
  type: typeof PROVIDER_ORG_PATIENT_SEARCH_LOADING;
  loading: boolean;
}
export interface ProviderOrgPatientSearchSuccess {
  type: typeof PROVIDER_ORG_PATIENT_SEARCH_SUCCESS;
  payload: ProviderOrgPatientSearchDetails;
}
export interface ProviderOrgPatientSearchFail {
  type: typeof PROVIDER_ORG_PATIENT_SEARCH_FAIL;
  payload: ProviderOrgPatientSearchDetails;
  message: string;
  status: number;
}
export interface ProviderOrgPatientSearchUpdateAPIMsg {
  type: typeof PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_MSG;
  payload: ProviderOrgPatientSearchDetails;
  message: string;
  status: number;
}
export interface ProviderOrgPatientSearchUpdateAPIRes {
  type: typeof PROVIDER_ORG_PATIENT_SEARCH_UPDATE_API_RES;
  payload: ProviderOrgPatientSearchDetails;
  message: string;
  status: number;
}
interface ProviderOrgPatientSearchAction {
  type: typeof PROVIDER_ORG_PATIENT_SEARCH_DATA;
  payload: ProviderOrgPatientSearchDetails;
  pageCount: PageCount;
}

export type ProviderOrgPatientSearchActionTypes =
  | ProviderOrgPatientSearchAction
  | ProviderOrgPatientSearchLoading
  | ProviderOrgPatientSearchSuccess
  | ProviderOrgPatientSearchFail
  | ProviderOrgPatientSearchUpdateAPIMsg
  | ProviderOrgPatientSearchUpdateAPIRes;
