import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { OrganizationListByHealthCampIdActionTypes } from "./ActionTypes";
import {
  OrganizationListMappedWithHealthCampBody,
  OrganizationListMappedWithHealthCampDetails,
} from "./Model";
import {
  organizationListMappeByHealthCampIdAPIResClearAction,
  organizationListMappeByHealthCampIdErrorAction,
  organizationListMappeByHealthCampIdLoadingAction,
  organizationListMappeByHealthCampIdSuccessAction,
  organizationListMappeByHealthCampIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as OrganizationListMappedWithHealthCampDetails;
export const mappedOrganizationListByHealthcampIdApi = (
  healthCampId: OrganizationListMappedWithHealthCampBody,
) => {
  return function (dispatch: Dispatch<OrganizationListByHealthCampIdActionTypes>) {
    dispatch(organizationListMappeByHealthCampIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/patientorganization/getMappedOrganizationsByHealthcamp`,
        healthCampId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(organizationListMappeByHealthCampIdLoadingAction(false));
        dispatch(organizationListMappeByHealthCampIdSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(organizationListMappeByHealthCampIdLoadingAction(false));
        dispatch(
          organizationListMappeByHealthCampIdErrorAction(
            {} as OrganizationListMappedWithHealthCampDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateAPIResMsg = () => {
  return function (dispatch: Dispatch<OrganizationListByHealthCampIdActionTypes>) {
    dispatch(
      organizationListMappeByHealthCampIdUpdateAPIMsgAction(
        apiRes as OrganizationListMappedWithHealthCampDetails,
        "",
        0,
      ),
    );
  };
};

export const clearSearchAPIRes = () => {
  return function (dispatch: Dispatch<OrganizationListByHealthCampIdActionTypes>) {
    dispatch(
      organizationListMappeByHealthCampIdAPIResClearAction(
        {} as OrganizationListMappedWithHealthCampDetails,
        "",
        0,
      ),
    );
  };
};
