import { GET_LIST } from "../../types/ActionTypes";
import { PreviewListResponse } from "./Model";

// Patient Demographics Loading State
export const PREVIEW_LOADING = "PREVIEW_LOADING";
export const PREVIEW_SUCCESS = "PREVIEW_SUCCESS";
export const PREVIEW_FAIL = "PREVIEW_FAIL";

export interface GetPatientDemographicsState {
  previewList: PreviewListResponse;
  loading: boolean;
}
export interface ExecutiveListLoading {
  type: typeof PREVIEW_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof PREVIEW_SUCCESS;
  payload: PreviewListResponse;
}
export interface ExecutiveListFail {
  type: typeof PREVIEW_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: PreviewListResponse;
}

export type PreviewListActionTypes =
  | GetDoctorAction
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail;
