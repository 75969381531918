import {
  ADD_SECOND_ROUND_FAIL,
  ADD_SECOND_ROUND_LOADING,
  ADD_SECOND_ROUND_SUCCESS,
  ADD_SECOND_ROUND_API_MSG,
  ADD_SECOND_ROUND_API_RES,
  AddScoundRoundDetailsActionTypes,
} from "./ActionTypes";
import { AddScoundRoundDetailsRes } from "./Model";

export const addScoundRoundDetailsLoadingAction = (
  loading: boolean,
): AddScoundRoundDetailsActionTypes => {
  return {
    type: ADD_SECOND_ROUND_LOADING,
    loading: loading,
  };
};

export const addScoundRoundDetailsSuccessAction = (
  addSecondRoundDetailsResponse: AddScoundRoundDetailsRes,
): AddScoundRoundDetailsActionTypes => {
  return {
    type: ADD_SECOND_ROUND_SUCCESS,
    payload: addSecondRoundDetailsResponse,
  };
};

export const addScoundRoundDetailsErrorAction = (
  addSecondRoundDetailsResponse: AddScoundRoundDetailsRes,
  errMsg: string,
  status: number,
): AddScoundRoundDetailsActionTypes => {
  return {
    type: ADD_SECOND_ROUND_FAIL,
    payload: addSecondRoundDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addScoundRoundDetailsAPIMsgAction = (
  addSecondRoundDetailsResponse: AddScoundRoundDetailsRes,
  errMsg: string,
  status: number,
): AddScoundRoundDetailsActionTypes => {
  return {
    type: ADD_SECOND_ROUND_API_MSG,
    payload: addSecondRoundDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const addScoundRoundDetailsAPIResClearAction = (
  addSecondRoundDetailsResponse: AddScoundRoundDetailsRes,
  errMsg: string,
  status: number,
): AddScoundRoundDetailsActionTypes => {
  return {
    type: ADD_SECOND_ROUND_API_RES,
    payload: addSecondRoundDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
