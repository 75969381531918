import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { EditPatientTeamActionTypes } from "./ActionTypes";
import { EditPatientRes, EditPatientTeamBody } from "./Model";
import {
  editPatientTeamAPIResClearAction,
  editPatientTeamErrorAction,
  editPatientTeamLoadingAction,
  editPatientTeamSuccessAction,
  editPatientTeamUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as EditPatientRes;
export const editPatientTeam = (payload: EditPatientTeamBody) => {
  return function (dispatch: Dispatch<EditPatientTeamActionTypes>) {
    dispatch(editPatientTeamLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/adminapp/api/adminActions/edit_patientTeam`, payload, {
        headers: headers,
      })
      .then((res) => {
        dispatch(editPatientTeamLoadingAction(false));
        dispatch(
          editPatientTeamSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "Patient Team Edit successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(editPatientTeamLoadingAction(false));
        dispatch(
          editPatientTeamErrorAction(
            {} as EditPatientRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const editPatientTeamAPIResMsg = () => {
  return function (dispatch: Dispatch<EditPatientTeamActionTypes>) {
    dispatch(editPatientTeamUpdateAPIMsgAction(apiRes as EditPatientRes, "", "", 0));
  };
};

export const clearEditPatientTeamAPIRes = () => {
  return function (dispatch: Dispatch<EditPatientTeamActionTypes>) {
    dispatch(editPatientTeamAPIResClearAction({} as EditPatientRes, "", "", 0));
  };
};
