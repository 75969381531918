import {
  AddPatientTeamTypes,
  ADD_PATIENT_TEAM_TYPE,
  PatientTeamTypes,
  PATIENT_TEAM_TYPE,
} from "./ActionTypes";

// PatientTeam List Action
export const patientTeamListAction = (
  patientCasePhone: any,
  ownerDetails: any,
  patientTeam: [],
  message: any,
  status: number,
): PatientTeamTypes => {
  return {
    type: PATIENT_TEAM_TYPE,
    patientCasePhone: patientCasePhone,
    ownerDetails: ownerDetails,
    patientTeam: patientTeam,
    message: message,
    status: status,
  };
};
// Add Patient Team Action
export const addPatientTeamListAction = (message: any, status: number): AddPatientTeamTypes => {
  return {
    type: ADD_PATIENT_TEAM_TYPE,
    message: message,
    status: status,
  };
};
