import {
  RemoveExecutiveActionTypes,
  REMOVE_EXECUTIVE_FAIL,
  REMOVE_EXECUTIVE_LOADING,
  REMOVE_EXECUTIVE_SUCCESS,
  RemoveExecutiveState,
  REMOVE_EXECUTIVE_UPDATE_API_MSG,
  REMOVE_EXECUTIVE_UPDATE_API_RES,
} from "./ActionTypes";
import { RemoveExecutiveRes } from "./Model";

const initialStateGetPosts: RemoveExecutiveState = {
  loading: false,
  removeExecutiveRes: {} as RemoveExecutiveRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const removeExecutiveReducer = (
  state = initialStateGetPosts,
  action: RemoveExecutiveActionTypes,
): RemoveExecutiveState => {
  switch (action.type) {
    case REMOVE_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case REMOVE_EXECUTIVE_SUCCESS:
      return {
        ...state,
        removeExecutiveRes: action.payload,
        successMsg: action.successMsg,
      };
    case REMOVE_EXECUTIVE_FAIL:
      return {
        ...state,
        removeExecutiveRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REMOVE_EXECUTIVE_UPDATE_API_MSG:
      return {
        ...state,
        removeExecutiveRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REMOVE_EXECUTIVE_UPDATE_API_RES:
      return {
        ...state,
        removeExecutiveRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
