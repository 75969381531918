import { AddProviderAdministratorDetailsRes } from "./Model";
export const ADD_PROVIDER_ADMINISTRATOR_LOADING = "ADD_PROVIDER_ADMINISTRATOR_LOADING";
export const ADD_PROVIDER_ADMINISTRATOR_SUCCESS = "ADD_PROVIDER_ADMINISTRATOR_SUCCESS";
export const ADD_PROVIDER_ADMINISTRATOR_FAIL = "ADD_PROVIDER_ADMINISTRATOR_FAIL";
export const ADD_PROVIDER_ADMINISTRATOR_DATA = "ADD_PROVIDER_ADMINISTRATOR_DATA";
export const ADD_PROVIDER_ADMINISTRATOR_API_MSG = "ADD_PROVIDER_ADMINISTRATOR_API_MSG";
export const ADD_PROVIDER_ADMINISTRATOR_API_RES = "ADD_PROVIDER_ADMINISTRATOR_API_RES";

export interface AddProviderOrgAdministratorState {
  addProviderOrgAdministratorRes: AddProviderAdministratorDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface AddProviderOrgAdministratorLoading {
  type: typeof ADD_PROVIDER_ADMINISTRATOR_LOADING;
  loading: boolean;
}
export interface AddProviderOrgAdministratorSuccess {
  type: typeof ADD_PROVIDER_ADMINISTRATOR_SUCCESS;
  payload: AddProviderAdministratorDetailsRes;
}
export interface AddProviderOrgAdministratorFail {
  type: typeof ADD_PROVIDER_ADMINISTRATOR_FAIL;
  payload: AddProviderAdministratorDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddProviderOrgAdministratorAPIMsg {
  type: typeof ADD_PROVIDER_ADMINISTRATOR_API_MSG;
  payload: AddProviderAdministratorDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddProviderOrgAdministratorAPIRes {
  type: typeof ADD_PROVIDER_ADMINISTRATOR_API_RES;
  payload: AddProviderAdministratorDetailsRes;
  errorMsg: string;
  status: number;
}

export type AddProviderAdministratorDetailsActionTypes =
  | AddProviderOrgAdministratorLoading
  | AddProviderOrgAdministratorSuccess
  | AddProviderOrgAdministratorFail
  | AddProviderOrgAdministratorAPIMsg
  | AddProviderOrgAdministratorAPIRes;
