import {
  AnswerQuestionTypes,
  ANSWER_QUESTION_TYPE,
  AskQuestionTypes,
  ASK_QUESTION_TYPE,
  CreateOrScheduleMeetingTypes,
  CREATE_OR_SCHEDULE_TYPE,
  DeleteMeetingTypes,
  DELETE_MEETING_TYPE,
  DoctorListForCaseTypes,
  DOCTOR_LIST_TYPE,
  MeetingListTypes,
  MEETING_LIST_TYPE,
  QuestionListTypes,
  QUESTION_LIST_TYPE,
} from "./ActionTypes";

// Doctor Dropdown List Action
export const doctorListForCaseAction = (
  patientCaseDoctorlist: [],
  message: any,
  status: number,
): DoctorListForCaseTypes => {
  return {
    type: DOCTOR_LIST_TYPE,
    patientCaseDoctorlist: patientCaseDoctorlist,
    message: message,
    status: status,
  };
};
// Meeting List Action
export const meetingListAction = (
  meetingList: [],
  message: any,
  status: number,
): MeetingListTypes => {
  return {
    type: MEETING_LIST_TYPE,
    meetingList: meetingList,
    message: message,
    status: status,
  };
};
// create or schedule meeting Action
export const createOrScheduleMeetingAction = (
  message: any,
  status: number,
): CreateOrScheduleMeetingTypes => {
  return {
    type: CREATE_OR_SCHEDULE_TYPE,
    message: message,
    status: status,
  };
};
// delete meeting Action
export const deleteMeetingAction = (message: any, status: number): DeleteMeetingTypes => {
  return {
    type: DELETE_MEETING_TYPE,
    message: message,
    status: status,
  };
};
// Question List Action
export const questionListAction = (
  questionList: [],
  message: string,
  status: number,
): QuestionListTypes => {
  return {
    type: QUESTION_LIST_TYPE,
    questionList: questionList,
    message: message,
    status: status,
  };
};
// Ask Question Action
export const askQuestionAction = (message: any, status: number): AskQuestionTypes => {
  return {
    type: ASK_QUESTION_TYPE,
    message: message,
    status: status,
  };
};
// Answer Question Action
export const answerQuestionAction = (message: any, status: number): AnswerQuestionTypes => {
  return {
    type: ANSWER_QUESTION_TYPE,
    message: message,
    status: status,
  };
};
