import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { HealthcampListByPatientIdActionTypes } from "./ActionTypes";
import { HealthcampListByPatientIdBody, HealthcampListByPatientIdDetails } from "./Model";
import {
  HealthcampListByPatientIdAPIResClearAction,
  HealthcampListByPatientIdErrorAction,
  HealthcampListByPatientIdLoadingAction,
  HealthcampListByPatientIdSuccessAction,
  HealthcampListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as HealthcampListByPatientIdDetails;
export const healthcampListByPatientIdApi = (
  patientid: HealthcampListByPatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<HealthcampListByPatientIdActionTypes>) {
    dispatch(HealthcampListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
        `/caregiverapp/api/healthcamp/health_camp_list_by_patient?page=${Number(
          pageNo,
        )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(HealthcampListByPatientIdLoadingAction(false));
        dispatch(
          HealthcampListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as HealthcampListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(HealthcampListByPatientIdLoadingAction(false));
        dispatch(
          HealthcampListByPatientIdErrorAction(
            {} as HealthcampListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateHealthcampListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<HealthcampListByPatientIdActionTypes>) {
    dispatch(
      HealthcampListByPatientIdAPIMsgAction(apiRes as HealthcampListByPatientIdDetails, "", "", 0),
    );
  };
};

export const clearHealthcampListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<HealthcampListByPatientIdActionTypes>) {
    dispatch(
      HealthcampListByPatientIdAPIResClearAction({} as HealthcampListByPatientIdDetails, "", "", 0),
    );
  };
};
