import { AddCutoffMarksDetailsRes } from "./Model";
export const ADD_CUTOFF_MARKS_LOADING = "ADD_CUTOFF_MARKS_LOADING";
export const ADD_CUTOFF_MARKS_SUCCESS = "ADD_CUTOFF_MARKS_SUCCESS";
export const ADD_CUTOFF_MARKS_FAIL = "ADD_CUTOFF_MARKS_FAIL";
export const ADD_CUTOFF_MARKS_API_MSG = "ADD_CUTOFF_MARKS_API_MSG";
export const ADD_CUTOFF_MARKS_API_RES = "ADD_CUTOFF_MARKS_API_RES";

export interface AddCutoffMarksState {
  previewCutoffMarksRes: AddCutoffMarksDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface AddCutoffMarksLoading {
  type: typeof ADD_CUTOFF_MARKS_LOADING;
  loading: boolean;
}
export interface AddCutoffMarksSuccess {
  type: typeof ADD_CUTOFF_MARKS_SUCCESS;
  payload: AddCutoffMarksDetailsRes;
}
export interface AddCutoffMarksFail {
  type: typeof ADD_CUTOFF_MARKS_FAIL;
  payload: AddCutoffMarksDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddCutoffMarksAPIMsg {
  type: typeof ADD_CUTOFF_MARKS_API_MSG;
  payload: AddCutoffMarksDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddCutoffMarksAPIRes {
  type: typeof ADD_CUTOFF_MARKS_API_RES;
  payload: AddCutoffMarksDetailsRes;
  errorMsg: string;
  status: number;
}

export type AddCutoffMarksDetailsActionTypes =
  | AddCutoffMarksLoading
  | AddCutoffMarksSuccess
  | AddCutoffMarksFail
  | AddCutoffMarksAPIMsg
  | AddCutoffMarksAPIRes;
