import { combineReducers } from "redux";
import { addHealthcampPatientReducer } from "../addHealthcampPatient/Reducer";
import { addPointDataReducer } from "../addPointData/Reducer";
import { getHealthcampListReducer } from "../healthcampList/Reducer";
import { getPatientDataPointListReducer } from "../patientDataPointList/patientDataPointListByPatientID/Reducer";
import { doctorsOnboardingImcompleteReducer } from "../doctorsOnboardingImcomplete/Reducer";
import { importDataFromRecentCallReducer } from "../importDataFromRecentCall/Reducer";
import { executiveListReducer } from "../executiveListDropDown/Reducer";
import { assignExecutiveReducer } from "../assignExecutive/Reducer";
import { previewListReducer } from "../Preview/previewApi/Reducer";
import { templateListReducer } from "../Preview/templateListApi/Reducer";
import { historyandIllnessReducer } from "../HistoryAndIllness/AddHealthAndIllness/Reducer";
import { addhistoryandIllnessReducer } from "../HistoryAndIllness/GetHealthAndIllness/Reducer";
import { getIdentifyReducer } from "../IdentifydataAndSource/GetIdentify/Reducer";
import { addIdentifyReducer } from "../IdentifydataAndSource/AddIdentify/Reducer";
import { deleteIdentifyReducer } from "../IdentifydataAndSource/DeleteIdentify/Reducer";
import { getInsuranceReducer } from "../Insurance/GetInsurance/Reducer";
import { addInsuranceReducer } from "../Insurance/AddInsurance/Reducer";
import { acceptorRejectBookingReducer } from "../AppointmentBooking/Reducer";
import { getPatientCaseDetailsReducer } from "../ConsultationOption/Reducer";
import { getCorporatePackageListReducer } from "../CorporatePackage/Package/Reducer";
import { getCorporatePackageToggleReducer } from "../CorporatePackage/TogglePackageStatus/Reducer";
import { getPatientListReducer } from "../CorporatePackage/PatientTeam/PatientList/Reducer";
import { getCorporatePackagePaymentListReducer } from "../CorporatePackage/Payment/Reducer";
import { addSummary, contactDetail, doctorAppointment } from "../Doctor/Reducer";
import { addDoctorReducer, drApproveOrReject } from "../DoctorOnboarding/Reducer";
import {
  getDoctorReducer,
  getOnboardingDoctorRequestReducer,
  getOnboardingDoctorRejectReducer,
  getOnboardingDoctorIncompleteReducer,
  getDoctorListReducer,
  getPatientReducer,
  getTaskListReducer,
  getQuestionListReducer,
  getCaseReducer,
  getQuestionReducer,
  getCCReducer,
  addCCReducer,
  deleteDocumentReducer,
  getAllCaseReducer,
  getAllInactiveTaskListReducer,
  getActiveCorporateListReducer,
  getInactiveCorporateListReducer,
  getActiveCorporatePatientListReducer,
  getInactiveCorporatePatientListReducer,
  acceptOrRejectBookingAppointment,
  taskCloseByAdmiin,
} from "./Reducer";
import { stepandSectionListReducer } from "../StepsAndSection/Reducer";
import { addPastMedicalReducer } from "../PastMedicallHistory/AddPastMedicalHistory/Reducer";
import { getPastMedicalReducer } from "../PastMedicallHistory/GetPastMedicalHistory/Reducer";
import { deletePastMedicalReducer } from "../PastMedicallHistory/DeletePastMedicalHistory/Reducer";
import { symptomAddReducer } from "../Symptom/addSymptom/Reducer";
import { symptomListReducer } from "../Symptom/getSymptom/Reducer";
import { getTelehealthMeetingReducer } from "../Telehealth/Reducer";
import { getExecutiveTaskListReducer, taskCloseReducer } from "../Task/Reducer";
import {
  addPatientTeamReducer,
  appointmentPatientTeamListReducer,
} from "../Patient/AppointmentPatientTeam/Reducer";
import {
  addDrNotes,
  addNotesForDrReducer,
  changeDrNoteStatus,
  changeNoteStatusForDrReducer,
  NotesForDrReducer,
  getDrNoteReducer,
} from "../Notes/Reducer";
import {
  askQuestionReducer,
  createOrScheduleReducer,
  deleteMeetingReducer,
  doctorListForCaseReducer,
  meetingListReducer,
  questionListReducer,
} from "../Patient/DoctorPatientInteraction/Reducer";
import { addAnswer, questionverifyReducer } from "../QuestionAnswer/Reducer";
import {
  addPatientInPackageReducer,
  getPatientTeamListReducer,
} from "../CorporatePackage/PatientTeam/Reducer";
import {
  addOrupdateHealthRecordReducer,
  getBloodGroupDropdownListReducer,
  getHealthRecordReducer,
  getLanguageDropdownListReducer,
  getMaritalStatusDropdownListReducer,
  getSexualorientationDropdownListReducer,
  gettGenderDropdownListReducer,
  gettRelationshipDropdownListReducer,
} from "../Patient/DemographicsDetails/Reducer";
import {
  addOrEditPatientDataImportConfigReducer,
  getPatientDataImportConfigListReducer,
  getPatientImportListReducer,
} from "../Patient/ImportData/Reducer";
import { deleteOpinion, getOpinion } from "../Patient/FinalOpinion/Reducer";
import { getpatientTeamListReducer } from "../PatientTeam/Reducer";
import { getHealthcampPatientReducer } from "../patientListOfHealthcamp/Reducer";
import { addDemographicsReducer } from "../DemographicsDetails/AddDemographicsDetails/Reducer";
import { getBloodGroupListReducer } from "../DemographicsDetails/BloodGroupList/Reducer";
import { getGenderListReducer } from "../DemographicsDetails/GenderList/Reducer";
import { getDemographicsDetailsByPtIdReducer } from "../DemographicsDetails/GetPatientDemographicsDetails/Reducer";
import { getLanguageListReducer } from "../DemographicsDetails/LanguageList/Reducer";
import { getMaritalListListReducer } from "../DemographicsDetails/MaritalStatusList/Reducer";
import { getRelationshipListReducer } from "../DemographicsDetails/RelationShipList/Reducer";
import { getSexualOrientationListReducer } from "../DemographicsDetails/SexualOrientationList/Reducer";
import { updateDemographicsDetailsByPatientIdReducer } from "../DemographicsDetails/UpdateDemographicsDetails/Reducer";
import { imageUploadReducer } from "../ImageUpload/Reducer";
import { logOutReducer } from "../logOut/Reducer";
import { addHealthcampExistingPatientReducer } from "../addExistingHealthcampPatient/Reducer";
import { govtIDProofReducer } from "../DemographicsDetails/govtIdProofList/Reducer";
import { patientSearchReducer } from "../PatientSearchApi/Reducer";
import { addMasterConfigReducer } from "../addMasterImportConfig/Reducer";
import { getPatientImportConfigListReducer } from "../getPatientImportConfigList/Reducer";
import { addPatientImportConfigReducer } from "../addPatientImportConfig/Reducer";
import { editPatientImportConfigReducer } from "../editPatientImportConfig/Reducer";
import { UpdatePatientDataPointReducer } from "../UpdatePatientDataPoint/Reducer";
import { simpleHistoryReducer } from "../SimpleHistory/Reducer";
import { getPatientDataDetailsListReducer } from "../patientDataDetails/patientDataPointListByPatientID/Reducer";
import { addPointDataDetailsReducer } from "../patientDataDetails/addPatientDetails/Reducer";
import { updatePatientDataDetailsReducer } from "../patientDataDetails/UpdatePatientDataPoint/Reducer";
import { getAllTaskListReducer } from "../TaskAdmin/List/Reducer";
import { executiveDropDownListReducer } from "../TaskAdmin/ExecutiveList/Reducer";
import { assigntaskToExecutiveReducer } from "../TaskAdmin/AssignExecutive/Reducer";
import { updatePatientCaseStatusReducer } from "../Patient/AppointmentSelectPatient/updatePatientCaseDetails/Reducer";
import { getCaseStatusListReducer } from "../Patient/AppointmentSelectPatient/caseStatusList/Reducer";
import { getPatientCaseDetailsByIdReducer } from "../Patient/AppointmentSelectPatient/getPatientCseDetailsById/Reducer";
import { deletePatientCaseReducer } from "../Patient/FilePreparation/deletePatientCase/Reducer";
import { findUserReducer } from "../ImportData/FindUserNumber/Reducer";
import { importDataFromCallReducer } from "../ImportData/ImportFromPhone/Reducer";
import { getPatientCaseNoteListReducer } from "../Notes/PatientCaseNoteList/Reducer";
import { AddNoteReducer } from "../Notes/addNotes/Reducer";
import { updateNoteReducer } from "../Notes/updateNoteStatus/Reducer";
import { adminPaymentReducer } from "../PaymentAdmin/PaymentList/Reducer";
import { doctorQuriesListReducer } from "../DoctorQuriesList/Reducer";
import { getDoctorDetailsByIdReducer } from "../Doctor/GetDoctorDetailsById/Reducer";
import { statusPaymentReducer } from "../PaymentAdmin/PaymentStatus/Reducer";
import { patientQuriesListReducer } from "../PatientQuriesList /Reducer";
import { updateDoctorDetailsReducer } from "../Doctor/updateDoctorDetails/Reducer";
import { getDoctorContactDetailsReducer } from "../Doctor/GetDoctorContactDetails/Reducer";
import { updateDoctorContactDetailsReducer } from "../Doctor/updateDoctorContactDetails/Reducer";
import { allappointmentListReducer } from "../Appointment/AppointmentList/Reducer";
import { appointmentDeactivateReducer } from "../Appointment/AppointmentDeactive/Reducer";
import { GetExtractObservationByPatientIdReducer } from "../getExtractObservationByPatientId/Reducer";
import { getPatientDetailsByIdReducer } from "../patientDataDetails/patientDetailsById/Reducer";
import { extractObservationByDetailsIdReducer } from "../extractObservationDetailsById/Reducer";
import { extractObservationByPatientIdReducer } from "../extractObservationDetailsByPatientId/Reducer";
import { GetExtractObservationByDetailsIdReducer } from "../GetViewExtractObservation/Reducer";
import { reviewNeededReducer } from "../patientDataDetails/reviewNeeded/Reducer";
import { getDoctorWorkScheduleListReducer } from "../Doctor/Schedule/GetDoctorWorkScheduleList/Reducer";
import { getWeekDayListReducer } from "../Doctor/Schedule/WeekDayList/Reducer";
import { addScheduleReducer } from "../Doctor/Schedule/AddSchedule/Reducer";
import { deleteScheduleReducer } from "../Doctor/Schedule/deleteSchedule/Reducer";
import { getOpinionTypeListReducer } from "../ConsultationOption/OpinionType/Reducer";
import { getSecondOpinionTypeListReducer } from "../ConsultationOption/SecondOpinionType/Reducer";
import { getConsultationTypeListReducer } from "../ConsultationOption/ConsultationType/Reducer";
import { getResponseTimeTypeListReducer } from "../ConsultationOption/ResponseTimeType/Reducer";
import { getQuestionnaireListReducer } from "../FilePreparation/Questionnaire/Reducer";
import { getPatientDataListReducer } from "../FilePreparation/GetPatientDataList/Reducer";
import { deletePatientDataByIdReducer } from "../FilePreparation/DeletePatientDataById/Reducer";
import { addPatientDataReducer } from "../FilePreparation/AddPatientDataList/Reducer";
import { getDoctorTeamTypeListReducer } from "../Patient/AppointmentSelectDoctor/DoctorTeamTypeList/Reducer";
import { AddCaseReducer } from "../ConsultationOption/AddCase/Reducer";
import { EditCaseReducer } from "../ConsultationOption/EditCase/Reducer";
import { addRequestAppointmentReducer } from "../Patient/PatientRequestAppointment/Reducer";
import { requestAppointmentDetailsReducer } from "../Patient/GetAppointmentList/Reducer";
import { patientSearchByHealthCampIdReducer } from "../PatientSearchByHealcampId/Reducer";
import { getFilteredHealthCampPatientListReducer } from "../FilterHealthCampDetails/Reducer";
import { getOrganizationListReducer } from "../OrganizationList/Reducer";
import { patientSearchByOrganizationIdReducer } from "../PatientSearchByOrganizationId/Reducer";
import { getOrganizationPatientReducer } from "../PatientListByOrganization/Reducer";
import { organizationListMappedWithHealthCampReducer } from "../OrganizationListMappedWithHealthCamp/Reducer";
import { getHealthCampByOrganizationListReducer } from "../getMappedHealthcampsByOrganization/Reducer";
import { createHealthCampByOrganizationListReducer } from "../createMappingHealthcampByOrganization/Reducer";
import { editDoctorTeamTypeReducer } from "../Patient/AppointmentSelectDoctor/EditDoctorTeamType/Reducer";
import { getDoctorCategoryListReducer } from "../Patient/AppointmentSelectDoctor/DoctorCategoryList/Reducer";
import { getDoctorListByCategoryReducer } from "../Patient/AppointmentSelectDoctor/DoctorListByCategory/Reducer";
import { getDoctorListForCaseReducer } from "../Patient/AppointmentSelectDoctor/DoctorListForCase/Reducer";
import { deleteDoctorForCaseReducer } from "../Patient/AppointmentSelectDoctor/DeleteDoctor/Reducer";
import { getDoctorTeamTypeForCaseReducer } from "../Patient/AppointmentSelectDoctor/DoctorTeamTypeForCase/Reducer";
import { addDoctorForCaseReducer } from "../Patient/AppointmentSelectDoctor/AddDoctorForCase/Reducer";
import { getPatientNoteListReducer } from "../Notes/PatientNotesList/Reducer";
import { getHealthCampDropDownListReducer } from "../getAllHealthcampDropdownList/Reducer";
import { getInactiveCorporatePackageListReducer } from "../CorporatePackage/InactiveCorporatePackage/Reducer";
import { getProviderOrganizationListReducer } from "../ProviderOrganizationList/Reducer";
import { addProviderOrganizationReducer } from "../AddProviderOrganization/Reducer";
import { addProviderOrgAdministratorReducer } from "../AddAdministratorApi/Reducer";
import { getProviderOrganizationAdministratorListReducer } from "../ProviderOrganizationAdministratorList /Reducer";
import { getAdministratorDetailsReducer } from "../ProviderOrganizationAdministratorDetails/Reducer";
import { getAppointmentPaymentListReducer } from "../Patient/AppointmentPaymentList/Reducer";
import { checkAppointmentPaymentStatusReducer } from "../Patient/AppointmentPaymentList/CheckPaymentStatus/Reducer";
import { confirmAppointmentReducer } from "../Patient/AppointmentPaymentList/CheckPaymentStatus copy/Reducer";
import { trendsApiReducer } from "../TrendApi/Reducer";
import { getInactiveCaseListReducer } from "../GetInactiveCaseList/Reducer";
import { getPatientDashboardLatestDataListReducer } from "../patientDashboardLatestDataList/Reducer";
import { dataListByObservationTypeReducer } from "../patientDashboardDataListByObservationType/Reducer";
import { getLastModifiedPatientListReducer } from "../LastModifiedPatientList/Reducer";
import { adminLoginReducer } from "../logIn/Reducer";
import { setPreferenceReducer } from "../Preference/SetPreference/Reducer";
import { getPreferenceReducer } from "../Preference/GetPreference/Reducer";
import { jwtAuthTokenReducer } from "../jwtAdminLogIn/Reducer";
import { adminLoginDetailsReducer } from "../jwtAdminLogIn/adminLoginDetails/Reducer";
import { getViewOrganizationStaffListReducer } from "../ViewOrganizationStaffList/ActiveListByOrganizationId/Reducer";
import { getStaffListByHealthCampIdReducer } from "../HealthcampStaffList/Reducer";
import { getAllUserListReducer } from "../ShowAllUserList/Reducer";
import { getProviderOrgDetailsReducer } from "../ProviderOrganizationDetails/Reducer";
import { getCompetitionListReducer } from "redux/CompetitionList/Reducer";
import { getCompetitionPatientReducer } from "redux/PatientListByCompetition/Reducer";
import { getPatientDetailsByCompetitionIdReducer } from "redux/PatientDetailsListOfPatientByCompetitionId/Reducer";
import { addPatientDetailsInCompetitionIdReducer } from "redux/AddPatientDetailsListInCompetitionId/Reducer";
import { activatePatientReducer } from "redux/activatePatients/Reducer";
import { getPatientTeamDetailsByIdReducer } from "redux/GetPatientTeamByPatientID/Reducer";
import { getPatientListOfCompetitionReducer } from "redux/PatientListOfCompetition/Reducer";
import { getStaffListByCompetitionReducer } from "redux/StaffListByCompetition/Reducer";
import { getJuryListByCompetitionReducer } from "redux/JuryListByCompetition/Reducer";
import { getJudgingDetailsListReducer } from "redux/JudgingDetailsList/Reducer";
import { getJudgingSecondRoundDetailsListReducer } from "redux/JudgingSecondRoundDetailsList/Reducer";
import { addSecoundRoundReducer } from "redux/AddSecondRound/Reducer";
import { diactiveStaffOfOrgReducer } from "redux/DiactiveStaffOfOrganization/Reducer";
import { addStaffOfOrgReducer } from "redux/AddOrganizationStaff/Reducer";
import { addRankForFinalRoundReducer } from "redux/AddFinalRank/Reducer";
import { getJudgingFinalRoundDetailsListReducer } from "redux/FinalRoundDetailsList/Reducer";
import { getPhotoJudgingDetailsListReducer } from "redux/GetPhotoJudgingDetailsList/Reducer";
import { executiveSearchReducer } from "redux/ExecutiveSearchApi/Reducer";
import { addStaffOfHealthcampReducer } from "redux/AddHealthcampStaff/Reducer";
import { getViewCompetitionStaffListReducer } from "redux/CompetitionStaffViewList/Reducer";
import { addStaffOfCompetitionReducer } from "redux/AddCompetitionStaff /Reducer";
import { deactiveStaffOfHealthcampReducer } from "redux/HealthcampStaffList/DeactiveStaffOfHealthcamp/Reducer";
import { getStaffListByProviderOrgIdReducer } from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/Reducer";
import { addStaffOfProviderOrgReducer } from "redux/AddProviderOrganizationStaff/Reducer";
import { previewCutoffMarksReducer } from "redux/PreviewCutoffMarks/Reducer";
import { addCutoffMarksReducer } from "redux/AddNextRoundByCutoffMarks/Reducer";
import { movePatientToOrganizationReducer } from "redux/MovePatientToOrganization/Reducer";
import { patientSearchByObservationTypeReducer } from "redux/PatientSearchByObservationType/Reducer";
import { getObservationTypeReducer } from "redux/GetObservationTypeList/Reducer";
import { healthcampPatientSearchByObservationTypeReducer } from "redux/HealthcampPatientSearchByObservationType/Reducer";
import { getDeactiveStaffListByOrganizationIdReducer } from "redux/ViewOrganizationStaffList/OrganizationDeactiveStaffList/Reducer";
import { getDeactiveStaffListByCompetitionIdReducer } from "redux/StaffListByCompetition/CompetitionDeactiveStaffList/Reducer";
import { diactiveStaffOfCompetitionReducer } from "redux/StaffListByCompetition/DiactiveStaffOfCompetition/Reducer";
import { getDeactiveStaffListByProviderOrganizationIdReducer } from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/OrganizationDeactiveStaffList/Reducer";
import { toggleStaffOfProviderOrgReducer } from "redux/ProviderOrganizationDetails/ProviderOrganizationStaffList/ToggleStaffOfOrganization/Reducer";
import { getInstanceListByHealthCampIdReducer } from "redux/InstanceListOfHealthcamp /Reducer";
import { addInstanceReducer } from "redux/AddInstance/Reducer";
import { getSessionListByHealthCampIdReducer } from "redux/HealthcampSessionList/Reducer";
import { addSessionReducer } from "redux/AddSession/Reducer";
import { getPatientListBySessionIdReducer } from "redux/HealthcampSessionList/PatientListBySessionId/Reducer";
import { addPatientBySessionIdReducer } from "redux/HealthcampSessionList/AddPatientBySessionId/Reducer";
import { getPatientDetailsListBySessionIdReducer } from "redux/HealthcampSessionList/PatientDetailsListBySessionId/Reducer";
import { addPatientDataDetailsReducer } from "redux/HealthcampSessionList/addPatientDetails/Reducer";
import { addExistingPatientDetailsBySessionIdReducer } from "redux/AddExistingPatientDetailsBySession/Reducer";
import { editInstanceReducer } from "redux/EditInstance/Reducer";
import { editSessionReducer } from "redux/EditSession/Reducer";
import { viewHistoryInstanceByHealthCampIdReducer } from "redux/ViewHistoryInstanceofHealthcamp /Reducer";
import { viewHistoryBySessionIdReducer } from "redux/SessionViewHistory/Reducer";
import { extractObsBySessionIdReducer } from "redux/extractPatientDetailsBySessionId/Reducer";
import { getUnprocessedPatientDetailsListReducer } from "redux/UnprocessedPatientDetails/Reducer";
import { patientDetailsFixedReducer } from "redux/MarkPatientDetails/Reducer";
import { addDuplicatePatientReducer } from "redux/AddDuplicatePatient/Reducer";
import { getDuplicatePatientListReducer } from "redux/DuplicatePatientList/Reducer";
import { getPatientDetailsListByDuplicateIdReducer } from "redux/patientDataDetails/PatientDetailsListByDuplicateId/Reducer";
import { duplicateObservationListReducer } from "redux/DuplicatePatientObservationList/Reducer";
import { getGetDuplicateDemographicsDetailsByPatientIdReducer } from "redux/PatientDuplicateDemographics/Reducer";
import { getGetOriginalDemographicsDetailsByPatientIdReducer } from "redux/OriginalPatientDemographics/Reducer";
import { addRegistrationRequestReducer } from "redux/AddRegistrationRequestByHealthcampId/Reducer";
import { registrationRequestDetailsListReducer } from "redux/RegistrationRequestDetailsListByHealthcamp/Reducer";
import { addDoctorInHealthCampReducer } from "redux/HealthcampStaffList/AddDoctorInHealthCamp/Reducer";
import { getDoctorListInHealthCampReducer } from "redux/HealthcampStaffList/DoctorListInHealthCamp/Reducer";
import { getHealthCampRegistrationRequestListReducer } from "redux/HealthCampRegistrationRequestList/Reducer";
import { toggleActiveRegistrationRequestReducer } from "redux/ToggleActiveRegistrationRequest/Reducer";
import { getHealthCampRegistrationRequestDetailsListReducer } from "redux/HealthCampRegistrationRequestList/HealthCampRegistrationReqDetailList/Reducer";
import { addRegistrationRequestDetailsReducer } from "redux/HealthCampRegistrationRequestList/AddRegistrationRequestDetails/Reducer";
import { toggleActiveRegistrationRequestDetailsReducer } from "redux/ToggleActiveRegistrationRequestDetails/Reducer";
import { getVitalCollectionActiveReducer } from "redux/VitalCollectionStatus/VitalActiveStatus/Reducer";
import { vitalCollectionActiveInactiveStatusReducer } from "redux/VitalCollectionStatus/ActiveInactiveDataStatus/Reducer";
import { getVitalCollectionStatusReducer } from "redux/VitalCollectionStatus/GetVitalCollectionStatus/Reducer";
import { sessionPatientAvailableByObservationTypeReducer } from "redux/HealthcampSessionList/AvailablePatientObservationType/Reducer";
import { dashboardHealthCampRegistrationRequestListReducer } from "redux/DashboardRegReqList/Reducer";
import { vitalRequestListReducer } from "redux/VitalRequestList/Reducer";
import { editHealthcampNameReducer } from "redux/healthcampList/EditHealthcampName/Reducer";
import { getPatientCaseListBySessionIdReducer } from "redux/HealthcampSessionList/GetPatientCaseListBySessionId/Reducer";
import { addExistingPatientCaseToSessionReducer } from "redux/HealthcampSessionList/AddExistingPatientCase/Reducer";
import { getPatientCaseListByPatientIdReducer } from "redux/GetPatientCaseListByPatientId/Reducer";
import { getAppointmentListOfHealthcampInstanceReducer } from "redux/AppointmentListOfHealthcampInstance/Reducer";
import { inactiveVitalCollectionListReducer } from "redux/InactiveVitalCollectionStatus/Reducer";
import { addAppointmentToHealthcampInstanceReducer } from "redux/AppointmentListOfHealthcampInstance/AddAppoinmentHealthCampInstance/Reducer";
import { organizationListByPatientIdReducer } from "redux/PatientOrganizationListById/Reducer";
import { healthcampListByPatientIdReducer } from "redux/PatientHealthcampListById/Reducer";
import { competitionListByPatientIdReducer } from "redux/PatientCompetitionListById/Reducer";
import { instanceListByPatientIdReducer } from "redux/PatientInstanceListById/Reducer";
import { sessionListByPatientIdReducer } from "redux/PatientSessionListById/Reducer";
import { duplicateOrganizationListByPatientIdReducer } from "redux/DuplicatePatientDetails/PatientOrganizationListById/Reducer";
import { duplicateHealthcampListByPatientIdReducer } from "redux/DuplicatePatientDetails/PatientHealthcampListById/Reducer";
import { competitionListByDuplicatePatientIdReducer } from "redux/DuplicatePatientDetails/PatientCompetitionListById/Reducer";
import { instanceListByDuplicatePatientIdReducer } from "redux/DuplicatePatientDetails/PatientInstanceListById/Reducer";
import { sessionListByDuplicatePatientIdReducer } from "redux/DuplicatePatientDetails/PatientSessionListById/Reducer";
import { getPatientDetailsTypeOfSessionListReducer } from "redux/GetPatientDetailsTypeOfSession/Reducer";
import { patientDetailsTypeReducer } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/Reducer";
import { addPatientDetailsTypeToSessionReducer } from "redux/GetPatientDetailsTypeOfSession/AddPatientDetailsTypeToSession/Reducer";
import { patientDataRangeBySessionIdTypeReducer } from "redux/HealthcampSessionList/PatientDataRangeBySessionId/Reducer";
import { getSessionPatientDetailsListReducer } from "redux/HealthcampSessionList/SessionPatientDetailsList/Reducer";
import { filterPatientDetailsByObservationReducer } from "redux/HealthcampSessionList/FilterPatientDetails/Reducer";
import { getHealthCampSessionReportReducer } from "redux/HealthCampSessionReportList/Reducer";
import { getAdminListByHealthCampIdReducer } from "redux/HealthcampStaffList/AdminListByHealthcampId/Reducer";
import { getInactiveListByHealthCampIdReducer } from "redux/HealthcampStaffList/InactiveStaffList/Reducer";
import { getAdminListByOrgIdReducer } from "redux/ViewOrganizationStaffList/AdminListByOrganizationId/Reducer";
import { getOrganizationRegistrationRequestListReducer } from "redux/OrganizationRegistrationRequestList/Reducer";
import { toggleActiveOrgRegistrationRequestReducer } from "redux/ToggleActiveOrganizationRegistrationRequest/Reducer";
import { organizationAddRegistrationRequestDetailsReducer } from "redux/OrganizationAddRegistrationRequestDetails/Reducer";
import { getOrganizationRegistrationRequestDetailsListReducer } from "redux/OrganizationRegistrationReqDetailList/Reducer";
import { organizationToggleActiveRegistrationRequestDetailsReducer } from "redux/OrganizationToggleActiveRegistrationRequestDetails/Reducer";
import { patientCheckInBySessionIdFromAppointmnetListReducer } from "redux/HealthcampSessionList/PatientCheckInFromAppointmentList/Reducer";
import { patientSearchByInstanceIdReducer } from "redux/HealthcampSessionList/PatientSearchByInstanceId/Reducer";
import { assignPatientDetailsTypeReducer } from "redux/AssignPatientDetailsType/Reducer";
import { getPendingPatientDetailsTypeListBySessionIdReducer } from "redux/HealthcampSessionList/PendingMandatoryPatientDetailsType/Reducer";
import { OrganizationAddRegistrationRequestReducer } from "redux/OrganizationAddRegistrationRequest/Reducer";
import { getObservationListBySessionIdReducer } from "redux/HealthcampSessionList/ObservationListBySessionId/Reducer";
import { observationListByInstanceIdReducer } from "redux/HealthcampSessionList/ObservationListByInstanceId/Reducer";
import { observationListByHealthcampIdReducer } from "redux/healthcampList/ObservationListByHealthcampId/Reducer";
import { getDataCurationProblemListReducer } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/Reducer";
import { getDataCurationProblemTypeListReducer } from "redux/HealthcampSessionList/GetDataCurationProblemTypeList/Reducer";
import { addDataCurationProblemReducer } from "redux/HealthcampSessionList/AddDataCuration/Reducer";
import { getPatientListByTeamIdReducer } from "redux/CorporatePackage/PatientTeam/PatientDetailsListByProfileId/Reducer";
import { getPatientSubscriptionListByIdReducer } from "redux/PatientSubscriptionListById/Reducer";
import { getSingleEncounterListReducer } from "redux/PatientEncounterListByid/SingleEncounterList/Reducer";
import { getMandatoryPendingDetailsListBySessionIdReducer } from "redux/HealthcampSessionList/MandatoryPendingListBySessionId/Reducer";
import { editDataCurationProblemReducer } from "redux/HealthcampSessionList/EditDataCuration/Reducer";
import { getStaffAdminListByProviderOrgIdReducer } from "redux/ProviderOrganizationDetails/ProviderOrganizationAdminStaffList/Reducer";
import { providerOrgPatientDetailsReducer } from "redux/ProviderOrganizationPatientDetailsList/Reducer";
import { getProviderOrganizationPatientReducer } from "redux/PatientListByProviderOrganizationId/Reducer";
import { getDoctorListByProviderOrganizationIdReducer } from "redux/GetDoctorListByProviderOrgId/Reducer";
import { addDoctorByProviderOrgIdReducer } from "redux/AddDoctorByProviderOrgId/Reducer";
import { addExistingPatientByProviderOrgIdReducer } from "redux/AddExistingPatientByProviderOrgId/Reducer";
import { DoctorSearchReducer } from "redux/DoctorSearchByProviderOrg/Reducer";
import { dataListByObservationTypeMappingListReducer } from "redux/PatientDashboardUiComponentObservationTypeMappingList/Reducer";
import { sleepScoreObservationTypeReducer } from "redux/SleepAppApi/SleepScoreObservationTypeApi/Reducer";
import { sleepTimeObservationTypeReducer } from "redux/SleepAppApi/SleepTimeObservationTypeApi/Reducer";
import { appLoaderReducer } from "redux/appLoader/Reducer";
import { editPatientTeamReducer } from "redux/EditPatientTeam/Reducer";
import { sleepScheduleObservationTypeReducer } from "redux/SleepAppApi/SleepScheduleObservationTypeApi/Reducer";
import { sleepQualityObservationTypeReducer } from "redux/SleepAppApi/SleepQualityObservationTypeApi/Reducer";
import { addPatientTeamByAdminReducer } from "redux/PatientTeam/addPatientTeamByAdmin/Reducer";
import { adminSetTaskStatusReducer } from "redux/TaskAdmin/AdminSetTaskStatus/Reducer";
import { adminTaskStatusListReducer } from "redux/TaskAdmin/AdminTaskStatusListDropDown/Reducer";
import { deleteAccountRequestReducer } from "redux/DeleteAccountRequest/Reducer";
import { deleteAccountRequestListByTypeReducer } from "redux/DeleteAccountRequestListByType/Reducer";
import { executiveSignupRequestListReducer } from "redux/Executive/ExecutiveSignupRequestList/Reducer";
import { addExecutiveSignupRequestReducer } from "redux/Executive/AddExecutiveSignupReqApi/Reducer";
import { getExecutiveListReducer } from "redux/Executive/GetExecutiveList/Reducer";
import { removeExecutiveReducer } from "redux/Executive/RemoveExecutive/Reducer";
import { addExecutiveReducer } from "redux/Executive/AddExecutive/Reducer";
import { editExecutiveReducer } from "redux/Executive/EditExecutive/Reducer";
import { providerOrgPatientSearchReducer } from "redux/ProviderOrganizationDetails/PatientSearchApi/Reducer";

const Reducer = combineReducers({
  apiAuthToken: jwtAuthTokenReducer,
  adminLoginDetailsRes: adminLoginDetailsReducer,
  doctorValue: getDoctorReducer,
  doctorRequestValue: getOnboardingDoctorRequestReducer,
  doctorRejectValue: getOnboardingDoctorRejectReducer,
  doctorIncompleteValue: getOnboardingDoctorIncompleteReducer,
  doctorListValue: getDoctorListReducer,
  patientValue: getPatientReducer,
  taskListValue: getTaskListReducer,
  healthcampListValue: getHealthcampListReducer,
  organizationListValue: getOrganizationListReducer,
  healthcampPatientListValue: getHealthcampPatientReducer,
  organizationPatientListValue: getOrganizationPatientReducer,
  alltaskListValue: getAllTaskListReducer,
  allinactivetasklist: getAllInactiveTaskListReducer,
  questionListValue: getQuestionListReducer,
  caseValue: getCaseReducer,
  allcaseValue: getAllCaseReducer,
  questionValue: getQuestionReducer,
  ccValue: getCCReducer,
  ccAddVlaue: addCCReducer,
  deleteDocumentValue: deleteDocumentReducer,
  deleteAppointment: appointmentDeactivateReducer,
  allPatientTeamListValue: getpatientTeamListReducer,
  allPaymentListValue: adminPaymentReducer,
  adminPatientQueryListValue: patientQuriesListReducer,
  adminDoctorQueryListValue: doctorQuriesListReducer,
  activeCorporateListValue: getActiveCorporateListReducer,
  inactiveCorporateListValue: getInactiveCorporateListReducer,
  activeCorporatePatientValue: getActiveCorporatePatientListReducer,
  inactiveCorporatePatientValue: getInactiveCorporatePatientListReducer,
  patientDataPointList: getPatientDataPointListReducer,
  addPointData: addPointDataReducer,
  addHealthcampPatientData: addHealthcampPatientReducer,
  doctorOnboardingStatus: doctorsOnboardingImcompleteReducer,
  stepAndSection: stepandSectionListReducer,
  addpastMedical: addPastMedicalReducer,
  getPastMedical: getPastMedicalReducer,
  deletePastMedical: deletePastMedicalReducer,
  addSymptom: symptomAddReducer,
  symptomList: symptomListReducer,
  importDataFromRecentCallRes: importDataFromRecentCallReducer,
  importDataFromCallRes: importDataFromCallReducer,
  findUserRes: findUserReducer,
  executiveList: executiveListReducer,
  assignExecutive: assignExecutiveReducer,
  previewList: previewListReducer,
  templateList: templateListReducer,
  acceptOrRejectBookingAppointmentValue: acceptorRejectBookingReducer,
  healthandIllness: historyandIllnessReducer,
  addHealthAndIllness: addhistoryandIllnessReducer,
  getIdentify: getIdentifyReducer,
  addIdentify: addIdentifyReducer,
  deleteIdentify: deleteIdentifyReducer,
  getInsurance: getInsuranceReducer,
  addInsurance: addInsuranceReducer,
  opinionTypeValue: getOpinionTypeListReducer,
  secondOpinionValue: getSecondOpinionTypeListReducer,
  consultationTypeValue: getConsultationTypeListReducer,
  responseTimeValue: getResponseTimeTypeListReducer,
  addPatientCase: AddCaseReducer,
  editPatientCase: EditCaseReducer,
  getPatientCaseDetails: getPatientCaseDetailsReducer,
  corporatePackageTogglevalue: getCorporatePackageToggleReducer,
  corporatePackageValue: getCorporatePackageListReducer,
  patientTeamPatientListvalue: getPatientListReducer,
  patientteamPatientPaymentValue: getCorporatePackagePaymentListReducer,
  addDoctorSummary: addSummary,
  doctorContactDetails: contactDetail,
  workSchedule: getDoctorWorkScheduleListReducer,
  deleteSchedule: deleteScheduleReducer,
  addWorkSchedule: addScheduleReducer,
  appointment: doctorAppointment,
  doctorDetails: getDoctorDetailsByIdReducer,
  weekdayList: getWeekDayListReducer,
  addDoctorvalue: addDoctorReducer,
  drApproveRejectValue: drApproveOrReject,
  telehealthMeetingValue: getTelehealthMeetingReducer,
  executiveDropdownListvalue: executiveDropDownListReducer,
  assignExecutiveValues: assigntaskToExecutiveReducer,
  taskCloseValues: taskCloseReducer,
  executiveTaskList: getExecutiveTaskListReducer,
  executiveListRes: getExecutiveListReducer,
  addRequestAppointment: addRequestAppointmentReducer,
  getRequestAppointmentDetails: requestAppointmentDetailsReducer,
  removeExecutiveRes: removeExecutiveReducer,
  addExecutiveRes: addExecutiveReducer,
  editExecutiveRes: editExecutiveReducer,
  appointmentPatientTeamValue: appointmentPatientTeamListReducer,
  addPatientTeamValue: addPatientTeamReducer,
  doctorTeamTypeListValue: getDoctorTeamTypeListReducer,
  caseStatusList: getCaseStatusListReducer,
  addStatus: updatePatientCaseStatusReducer,
  getPatientCaseStatus: getPatientCaseDetailsByIdReducer,
  changeDrNotesStatusValue: changeNoteStatusForDrReducer,
  addNotesForDrValue: addNotesForDrReducer,
  notesForDrvalues: NotesForDrReducer,
  changeDrNoteStatusValue: changeDrNoteStatus,
  addDrNote: addDrNotes,
  getDrNote: getDrNoteReducer,
  patientCaseNoteListRes: getPatientCaseNoteListReducer,
  bookingValue: acceptOrRejectBookingAppointment,
  taskCloseValue: taskCloseByAdmiin,
  updateDoctorDetails: updateDoctorContactDetailsReducer,
  createOrScheduleMeetingValue: createOrScheduleReducer,
  deletetelehealthMeetingValue: deleteMeetingReducer,
  askQuestionToPatientValue: askQuestionReducer,
  doctorListForcasevalue: doctorListForCaseReducer,
  meetingListValue: meetingListReducer,
  questionListValueUnderCase: questionListReducer,
  adminQuestionAnswerValue: addAnswer,
  verifyQuestionValue: questionverifyReducer,
  patientTeamValue: getPatientTeamListReducer,
  addPatientInCorporatePackagevalue: addPatientInPackageReducer,
  genderListvalue: gettGenderDropdownListReducer,
  relationshipListValue: gettRelationshipDropdownListReducer,
  bloodGroupListvalue: getBloodGroupDropdownListReducer,
  maritalStatusValue: getMaritalStatusDropdownListReducer,
  sexualOrientationValue: getSexualorientationDropdownListReducer,
  languagelistValue: getLanguageDropdownListReducer,
  addOrUpdateHealthRecordValue: addOrupdateHealthRecordReducer,
  gethealthRecordvalue: getHealthRecordReducer,
  getPatientImportConfigListConfigValue: getPatientDataImportConfigListReducer,
  addEditImportConfigValue: addOrEditPatientDataImportConfigReducer,
  getpatientDataListValue: getPatientImportListReducer,
  deleteCaseValue: deletePatientCaseReducer,
  opinion: getOpinion,
  deleteOpinion: deleteOpinion,
  addPatientTeamByAdminRes: addPatientTeamByAdminReducer,
  verifyPaymentvalue: statusPaymentReducer,
  addDemographics: addDemographicsReducer,
  getDemographicsDetailsByPtId: getDemographicsDetailsByPtIdReducer,
  getPatientRelationshipList: getRelationshipListReducer,
  getPatientBloodGroupList: getBloodGroupListReducer,
  getPatientGenderList: getGenderListReducer,
  getPatientLanguageList: getLanguageListReducer,
  getPatientMaritalList: getMaritalListListReducer,
  getPatientSexualOrientation: getSexualOrientationListReducer,
  updateDemographicsDetails: updateDemographicsDetailsByPatientIdReducer,
  imageUploading: imageUploadReducer,
  logOutRes: logOutReducer,
  addExistingPatientValue: addHealthcampExistingPatientReducer,
  govtIdProofList: govtIDProofReducer,
  patientSearchListValue: patientSearchReducer,
  addMasterImport: addMasterConfigReducer,
  getPatientImportConfigList: getPatientImportConfigListReducer,
  addPatientImportConfig: addPatientImportConfigReducer,
  editImportConfig: editPatientImportConfigReducer,
  editPatientDataPoint: UpdatePatientDataPointReducer,
  showHistory: simpleHistoryReducer,
  getPatientDataDetailsRes: getPatientDataDetailsListReducer,
  addPointDataDetailsRes: addPointDataDetailsReducer,
  updatePatientDataDetailsRes: updatePatientDataDetailsReducer,
  addNoteRes: AddNoteReducer,
  updateNoteRes: updateNoteReducer,
  updateDoctorDetailsRes: updateDoctorDetailsReducer,
  getDoctorContactDetailsRes: getDoctorContactDetailsReducer,
  allApptList: allappointmentListReducer,
  extractObservationByDetailsIdValue: extractObservationByDetailsIdReducer,
  extractObservationByPatientIdValue: extractObservationByPatientIdReducer,
  getExtractObservationValue: GetExtractObservationByPatientIdReducer,
  getPatientDetailsById: getPatientDetailsByIdReducer,
  getExtractObservationByDetailsValue: GetExtractObservationByDetailsIdReducer,
  reviewNeeded: reviewNeededReducer,
  patientQuestionnaireListRes: getQuestionnaireListReducer,
  patientDataListRes: getPatientDataListReducer,
  deletePatientDataByIdRes: deletePatientDataByIdReducer,
  addPatientDataListRes: addPatientDataReducer,
  searchPatientByHealthCampIdValue: patientSearchByHealthCampIdReducer,
  filteredHealthCampPatientListRes: getFilteredHealthCampPatientListReducer,
  patientSearchByOrganizationIdValue: patientSearchByOrganizationIdReducer,
  organizationListMappedWithHealthCampIdValue: organizationListMappedWithHealthCampReducer,
  getHealthCampByOrganizationList: getHealthCampByOrganizationListReducer,
  createHealthCampByOrganization: createHealthCampByOrganizationListReducer,
  getDoctorTeamTypeForCaseRes: getDoctorTeamTypeForCaseReducer,
  editDoctorTeamTypeRes: editDoctorTeamTypeReducer,
  getDoctorCategoryListRes: getDoctorCategoryListReducer,
  getDoctorListByCategoryRes: getDoctorListByCategoryReducer,
  getDoctorListForCaseRes: getDoctorListForCaseReducer,
  deleteDoctorForCaseRes: deleteDoctorForCaseReducer,
  addDoctorForCaseRes: addDoctorForCaseReducer,
  patientNoteListRes: getPatientNoteListReducer,
  getHealthCampDropDownListValue: getHealthCampDropDownListReducer,
  getInactiveCorporatePackageListValue: getInactiveCorporatePackageListReducer,
  getProviderOrganizationListValue: getProviderOrganizationListReducer,
  addProviderOrganizationValue: addProviderOrganizationReducer,
  addProviderOrgAdministratorValue: addProviderOrgAdministratorReducer,
  getProviderOrganizationAdministratorListValue: getProviderOrganizationAdministratorListReducer,
  getAdministratorDetailsValue: getAdministratorDetailsReducer,
  getAppointmentPaymentListRes: getAppointmentPaymentListReducer,
  checkAppointmentPaymentStatusRes: checkAppointmentPaymentStatusReducer,
  confirmAppointmentRes: confirmAppointmentReducer,
  trendsApiRes: trendsApiReducer,
  getInactiveCaseListValue: getInactiveCaseListReducer,
  getPatientDashboardLatestDataListRes: getPatientDashboardLatestDataListReducer,
  dataListByObservationTypeRes: dataListByObservationTypeReducer,
  patientDashboardLatestdataList: getPatientDashboardLatestDataListReducer,
  dataListByObservationType: dataListByObservationTypeReducer,
  getLastModifiedPatientListValue: getLastModifiedPatientListReducer,
  adminLoginRes: adminLoginReducer,
  getPreferenceDetails: getPreferenceReducer,
  setPreferenceValue: setPreferenceReducer,
  getWiewOrgStaffListValue: getViewOrganizationStaffListReducer,
  getViewHealthcampStaffListValue: getStaffListByHealthCampIdReducer,
  getProviderOrgDetailsValue: getProviderOrgDetailsReducer,
  getAllUserListValue: getAllUserListReducer,
  getCompetitionListValue: getCompetitionListReducer,
  getCompetitionPatientList: getCompetitionPatientReducer,
  getPatientDetailsByCompetitionValue: getPatientDetailsByCompetitionIdReducer,
  addPatientDetailsInCompetitionValue: addPatientDetailsInCompetitionIdReducer,
  activatePatientRes: activatePatientReducer,
  getPatientDetailsByIdRes: getPatientTeamDetailsByIdReducer,
  getPatientListOfCompetitionValue: getPatientListOfCompetitionReducer,
  getStaffListByCompetitionValue: getStaffListByCompetitionReducer,
  getJuryListByCompetitionValue: getJuryListByCompetitionReducer,
  getJudgingListValue: getJudgingDetailsListReducer,
  getJudgingScoundRoundValue: getJudgingSecondRoundDetailsListReducer,
  addSecondRoundDetailsValue: addSecoundRoundReducer,
  diactiveStaffValue: diactiveStaffOfOrgReducer,
  addStaffOfOrgValue: addStaffOfOrgReducer,
  addRankForFinalRoundvalue: addRankForFinalRoundReducer,
  getFinalRoundvalue: getJudgingFinalRoundDetailsListReducer,
  getPhotoJudgingValue: getPhotoJudgingDetailsListReducer,
  executiveSearchValue: executiveSearchReducer,
  addHealhcampStaffValue: addStaffOfHealthcampReducer,
  viewStaffListOfCompetitionValue: getViewCompetitionStaffListReducer,
  addStaffOfCompetitionValue: addStaffOfCompetitionReducer,
  deactivateHealthcampStaffValue: deactiveStaffOfHealthcampReducer,
  getProviderOrgStaffListValue: getStaffListByProviderOrgIdReducer,
  addStaffOfProviderOrgValue: addStaffOfProviderOrgReducer,
  previewCutoffMarksValue: previewCutoffMarksReducer,
  addCutoffMarksValue: addCutoffMarksReducer,
  inactiveStaffListValue: getInactiveListByHealthCampIdReducer,
  movePatientToOrgValue: movePatientToOrganizationReducer,
  patientListByobservationTypeValue: patientSearchByObservationTypeReducer,
  getObservationTypeValue: getObservationTypeReducer,
  healthCampPatientListByObsTypeValue: healthcampPatientSearchByObservationTypeReducer,
  organizationDeactiveStaffValues: getDeactiveStaffListByOrganizationIdReducer,
  competitionDeactiveStaffValues: getDeactiveStaffListByCompetitionIdReducer,
  toggleCompetitionStaffValues: diactiveStaffOfCompetitionReducer,
  providerOrgDeactiveStaffListValue: getDeactiveStaffListByProviderOrganizationIdReducer,
  toggleProviderOrgStaffResponse: toggleStaffOfProviderOrgReducer,
  instanceListValue: getInstanceListByHealthCampIdReducer,
  addInstanceResValue: addInstanceReducer,
  sessionListValues: getSessionListByHealthCampIdReducer,
  addSessionResValue: addSessionReducer,
  getPatientListBySessionIdValues: getPatientListBySessionIdReducer,
  addPatientBySessionIdValues: addPatientBySessionIdReducer,
  patientDetailsBySessionIdValues: getPatientDetailsListBySessionIdReducer,
  addPatientDetailsBySessionValue: addPatientDataDetailsReducer,
  addExistingPatientDetailsValue: addExistingPatientDetailsBySessionIdReducer,
  editInstanceValue: editInstanceReducer,
  editSessionValue: editSessionReducer,
  viewHistoryInstanceResult: viewHistoryInstanceByHealthCampIdReducer,
  viewStorySessionResult: viewHistoryBySessionIdReducer,
  extractObsBySessionIdResult: extractObsBySessionIdReducer,
  getUnprocessedPatientDetailsValues: getUnprocessedPatientDetailsListReducer,
  patientDetailsFixedValue: patientDetailsFixedReducer,
  addDuplicatePatientValue: addDuplicatePatientReducer,
  getDuplicatePatientListValue: getDuplicatePatientListReducer,
  getPatientDetailsByDuplicateIdValues: getPatientDetailsListByDuplicateIdReducer,
  duplicatePatientObservationListValue: duplicateObservationListReducer,
  duplicatePatientDemographicsDetailsValue: getGetDuplicateDemographicsDetailsByPatientIdReducer,
  getOriginalDemographicsValue: getGetOriginalDemographicsDetailsByPatientIdReducer,
  addRegistrationRequestValue: addRegistrationRequestReducer,
  registrationRequestDetailsListValue: registrationRequestDetailsListReducer,
  addDoctorInHealthCampRes: addDoctorInHealthCampReducer,
  getDoctorListInHealthCampRes: getDoctorListInHealthCampReducer,
  healthcampRegistrationRequestListValue: getHealthCampRegistrationRequestListReducer,
  toggleActiveRegistrationRequestValue: toggleActiveRegistrationRequestReducer,
  getHealthCampRegistrationRequestDetailsListValue:
    getHealthCampRegistrationRequestDetailsListReducer,
  addRegistrationRequestDetailsValue: addRegistrationRequestDetailsReducer,
  toggleActiveRegistrationRequestDetailsValue: toggleActiveRegistrationRequestDetailsReducer,
  getVitalCollectionActiveList: getVitalCollectionActiveReducer,
  activeInactiveStatusValue: vitalCollectionActiveInactiveStatusReducer,
  getVitalCollectionStatusResponse: getVitalCollectionStatusReducer,
  avaliableObservationResponse: sessionPatientAvailableByObservationTypeReducer,
  vitalRequestList: vitalRequestListReducer,
  dashboardHealthCampRegistrationRequestListValue:
    dashboardHealthCampRegistrationRequestListReducer,
  editHealthcampNameReducerValue: editHealthcampNameReducer,
  getPatientCaseListBySessionIdResponse: getPatientCaseListBySessionIdReducer,
  addExistingPatientToSessionResponse: addExistingPatientCaseToSessionReducer,
  getPatientCaseListResult: getPatientCaseListByPatientIdReducer,
  getAppointmentListOfHealthcampInstance: getAppointmentListOfHealthcampInstanceReducer,
  inactiveVitalCollection: inactiveVitalCollectionListReducer,
  addAppointmentToHealthcampInstance: addAppointmentToHealthcampInstanceReducer,
  organizationListByPatientValues: organizationListByPatientIdReducer,
  healthcampListByPatientValues: healthcampListByPatientIdReducer,
  competitionListByPatientIdValues: competitionListByPatientIdReducer,
  instanceListBuPatientIdValues: instanceListByPatientIdReducer,
  sessionListByPatientIdValues: sessionListByPatientIdReducer,
  duplicateOrgListByPatientIdResult: duplicateOrganizationListByPatientIdReducer,
  duplicatePatientHealthcampListResult: duplicateHealthcampListByPatientIdReducer,
  competitionListByDuplicatePatientResult: competitionListByDuplicatePatientIdReducer,
  instanceListByDuplicatePatientResult: instanceListByDuplicatePatientIdReducer,
  sessionListByDuplicatePatientResult: sessionListByDuplicatePatientIdReducer,
  getPatientDetailsTypeOfSessionList: getPatientDetailsTypeOfSessionListReducer,
  patientDetailsType: patientDetailsTypeReducer,
  addPatientDetailsTypeToSession: addPatientDetailsTypeToSessionReducer,
  patientDataRangBySessionIdResult: patientDataRangeBySessionIdTypeReducer,
  getSessionPatientDetailsListValue: getSessionPatientDetailsListReducer,
  filterPatientDetailsByObservation: filterPatientDetailsByObservationReducer,
  getHealthCampSessionReportRes: getHealthCampSessionReportReducer,
  getPatientListByProfileIdValue: getPatientListByTeamIdReducer,
  getAdminListByHealthcampIdValues: getAdminListByHealthCampIdReducer,
  getAdminListByOrgIdValues: getAdminListByOrgIdReducer,
  patientCheckInValues: patientCheckInBySessionIdFromAppointmnetListReducer,
  getOrganizationRegistrationRequestListValues: getOrganizationRegistrationRequestListReducer,
  searchPatientByHealthcampInstanceId: patientSearchByInstanceIdReducer,
  toggleActiveOrgRegistrationRequestValue: toggleActiveOrgRegistrationRequestReducer,
  organizationAddRegistrationRequestDetails: organizationAddRegistrationRequestDetailsReducer,
  getOrganizationRegistrationRequestDetailsListValue:
    getOrganizationRegistrationRequestDetailsListReducer,
  organozationToggleActiveRegistrationRequestDetailsValue:
    organizationToggleActiveRegistrationRequestDetailsReducer,
  assignPatientDetailsTypeRes: assignPatientDetailsTypeReducer,
  getPendingPatientDetailsTypeListValue: getPendingPatientDetailsTypeListBySessionIdReducer,
  OrganizationAddRegistrationRequestValue: OrganizationAddRegistrationRequestReducer,
  observationListBySessionIdValue: getObservationListBySessionIdReducer,
  observationListOfInstanceIdValue: observationListByInstanceIdReducer,
  observationListOfHealthcampIdValue: observationListByHealthcampIdReducer,
  getDataCurationProblemListValue: getDataCurationProblemListReducer,
  getDataCurationProblemTypeValues: getDataCurationProblemTypeListReducer,
  addDataCurationProbValues: addDataCurationProblemReducer,
  getPatientSubscriptionListbyIdValues: getPatientSubscriptionListByIdReducer,
  getSingleEncounterListValue: getSingleEncounterListReducer,
  getMandatoryPatientDetailsValue: getMandatoryPendingDetailsListBySessionIdReducer,
  editDataCurationResult: editDataCurationProblemReducer,
  staffAdminList: getStaffAdminListByProviderOrgIdReducer,
  providerOrgPtDetails: providerOrgPatientDetailsReducer,
  patientListOfProviderOrgIdValues: getProviderOrganizationPatientReducer,
  doctorListByProviderOrgIdValues: getDoctorListByProviderOrganizationIdReducer,
  addDoctorByProviderOrgValue: addDoctorByProviderOrgIdReducer,
  addExistingPatientByProviderOrgValues: addExistingPatientByProviderOrgIdReducer,
  doctorSearchResult: DoctorSearchReducer,
  observationTypeMappingListValue: dataListByObservationTypeMappingListReducer,
  sleepScoreObservationTypeValue: sleepScoreObservationTypeReducer,
  sleepTimeObservationTypeValue: sleepTimeObservationTypeReducer,
  appLoaderRes: appLoaderReducer,
  editPatientTeamRes: editPatientTeamReducer,
  sleepScheduleTypeValue: sleepScheduleObservationTypeReducer,
  sleepQualityTypeValue: sleepQualityObservationTypeReducer,
  adminSettaskStatusResponseValue: adminSetTaskStatusReducer,
  adminTaskStatusListValues: adminTaskStatusListReducer,
  deleteAccoutRequestValue: deleteAccountRequestReducer,
  deleteAccountRequestListValues: deleteAccountRequestListByTypeReducer,
  executiveRequestListValue: executiveSignupRequestListReducer,
  addExecutiveSignupRequestVlaue: addExecutiveSignupRequestReducer,
  providerOrgPatientSearchValue: providerOrgPatientSearchReducer,
});

export default Reducer;
