import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { InstanceListByPatientIdActionTypes } from "./ActionTypes";
import { InstanceListByPatientIdBody, InstanceListByPatientIdDetails } from "./Model";
import {
  InstanceListByPatientIdAPIResClearAction,
  InstanceListByPatientIdErrorAction,
  InstanceListByPatientIdLoadingAction,
  InstanceListByPatientIdSuccessAction,
  InstanceListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as InstanceListByPatientIdDetails;
export const instanceListByPatientIdApi = (
  patientid: InstanceListByPatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<InstanceListByPatientIdActionTypes>) {
    dispatch(InstanceListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampinstance/instance_list_by_patient?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(InstanceListByPatientIdLoadingAction(false));
        dispatch(
          InstanceListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as InstanceListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient instance list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(InstanceListByPatientIdLoadingAction(false));
        dispatch(
          InstanceListByPatientIdErrorAction(
            {} as InstanceListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateInstanceListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<InstanceListByPatientIdActionTypes>) {
    dispatch(
      InstanceListByPatientIdAPIMsgAction(
        apiRes as InstanceListByPatientIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearInstanceListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<InstanceListByPatientIdActionTypes>) {
    dispatch(
      InstanceListByPatientIdAPIResClearAction(
        {} as InstanceListByPatientIdDetails,
        "",
        "",
        0,
      ),
    );
  };
};
