import { AddPatientDetailsTypeToSessionRes } from "./Model";
export const ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING = "ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING";
export const ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS = "ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS";
export const ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL = "ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL";
export const ADD_PATIENT_DETAILS_TYPE_TO_SESSION_DATA = "ADD_PATIENT_DETAILS_TYPE_TO_SESSION_DATA";
export const ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG = "ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG";
export const ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES = "ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES";

export interface AddPatientDetailsTypeToSessionListState {
  addPatientDetailsTypeToSessionRes: AddPatientDetailsTypeToSessionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsTypeToSessionListLoading {
  type: typeof ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING;
  loading: boolean;
}
export interface AddPatientDetailsTypeToSessionListSuccess {
  type: typeof ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS;
  payload: AddPatientDetailsTypeToSessionRes;
  successMsg: string;
}
export interface AddPatientDetailsTypeToSessionListFail {
  type: typeof ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL;
  payload: AddPatientDetailsTypeToSessionRes;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsTypeToSessionListAPIMsg {
  type: typeof ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG;
  payload: AddPatientDetailsTypeToSessionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsTypeToSessionListAPIRes {
  type: typeof ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES;
  payload: AddPatientDetailsTypeToSessionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type AddPatientDetailsTypeToSessionListActionTypes =
  | AddPatientDetailsTypeToSessionListLoading
  | AddPatientDetailsTypeToSessionListSuccess
  | AddPatientDetailsTypeToSessionListFail
  | AddPatientDetailsTypeToSessionListAPIMsg
  | AddPatientDetailsTypeToSessionListAPIRes;
