import {
  GetDataCurationProblemTypeListActionTypes,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS,
  GetDataCurationProblemTypeListState,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG,
  GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetDataCurationProblemTypeListResponse } from "./Model";

const initialStateGetPosts: GetDataCurationProblemTypeListState = {
  loading: false,
  GetDataCurationProblemTypeListResponse: {} as GetDataCurationProblemTypeListResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDataCurationProblemTypeListReducer = (
  state = initialStateGetPosts,
  action: GetDataCurationProblemTypeListActionTypes,
): GetDataCurationProblemTypeListState => {
  switch (action.type) {
    case GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS:
      return {
        ...state,
        GetDataCurationProblemTypeListResponse: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL:
      return {
        ...state,
        GetDataCurationProblemTypeListResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG:
      return {
        ...state,
        GetDataCurationProblemTypeListResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES:
      return {
        ...state,
        GetDataCurationProblemTypeListResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
