import { GetFIlteredHealthCampPatientListRes } from "./Model";
export const GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_LOADING =
  "GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_LOADING";
export const GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_SUCCESS =
  "GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_SUCCESS";
export const GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_FAIL =
  "GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_FAIL";
export const GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_MSG =
  "GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_MSG";
export const GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_RES =
  "GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_RES";

export interface GetFilteredHealthCampPatientListState {
  getFilteredHealthCampPatientListRes: GetFIlteredHealthCampPatientListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetFilteredHealthCampPatientListLoading {
  type: typeof GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_LOADING;
  loading: boolean;
}
export interface GetFilteredHealthCampPatientListSuccess {
  type: typeof GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_SUCCESS;
  payload: GetFIlteredHealthCampPatientListRes;
  successMsg: string;
}
export interface GetFilteredHealthCampPatientListFail {
  type: typeof GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_FAIL;
  payload: GetFIlteredHealthCampPatientListRes;
  errorMsg: string;
  status: number;
}
export interface GetFilteredHealthCampPatientListUpdateAPIMsg {
  type: typeof GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_MSG;
  payload: GetFIlteredHealthCampPatientListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetFilteredHealthCampPatientListUpdateAPIRes {
  type: typeof GET_FILTERED_HEALTH_CAMP_PATIENT_LIST_UPDATE_API_RES;
  payload: GetFIlteredHealthCampPatientListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetFilteredHealthCampPatientListActionTypes =
  | GetFilteredHealthCampPatientListLoading
  | GetFilteredHealthCampPatientListSuccess
  | GetFilteredHealthCampPatientListFail
  | GetFilteredHealthCampPatientListUpdateAPIMsg
  | GetFilteredHealthCampPatientListUpdateAPIRes;
