import React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import { Box, DialogTitle, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { clearAddExistingPtAPIRes } from "../../../../redux/addExistingHealthcampPatient/API";
import { clearSearchAPIRes } from "../../../../redux/PatientSearchApi/API";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import SearchStaff from "../SearchStaff";

export interface State extends SnackbarOrigin {
  open: boolean;
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  itemId?: string | any;
  addOrganizationStaffDialogEnable?: boolean | any;
  addOrganizationStaffDialogUpdateState?: boolean | any;
  title?: string | any;
  addPt?: boolean | any;
  showPtDetail?: boolean | any;
  showPtDoc?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

export default function AddOrganizationStaffDialog({
  itemId,
  addOrganizationStaffDialogEnable,
  addOrganizationStaffDialogUpdateState,
  title,
}: Props) {
  const dispatch = useDispatch();  
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addOrganizationStaffDialogUpdateState(false);
    dispatch(clearSearchAPIRes());
    dispatch(clearAddExistingPtAPIRes());
  };

  React.useEffect(() => {
    setDialogState(addOrganizationStaffDialogEnable);
  }, [addOrganizationStaffDialogEnable]);

  return (
    <Box>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h5">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <SearchStaff organizationid={itemId} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
