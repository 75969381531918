import {
  PATIENT_LIST_BY_TEAM_ID_FAIL,
  PATIENT_LIST_BY_TEAM_ID_LOADING,
  PATIENT_LIST_BY_TEAM_ID_SUCCESS,
  PATIENT_LIST_BY_TEAM_ID_UPDATE_API_MSG,
  PATIENT_LIST_BY_TEAM_ID_UPDATE_API_RES,
  GetPatientListByTeamIdActionTypes,
} from "./ActionTypes";
import { GetPatientListByTeamIdRes } from "./Model";

export const getPatientListByTeamIdLoadingAction = (
  loading: boolean,
): GetPatientListByTeamIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_TEAM_ID_LOADING,
    loading: loading,
  };
};

export const getPatientListByTeamIdSuccessAction = (
  getPatientListByTeamIdResponse: GetPatientListByTeamIdRes,
  successMsg: string,
): GetPatientListByTeamIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_TEAM_ID_SUCCESS,
    payload: getPatientListByTeamIdResponse,
    successMsg: successMsg,
  };
};

export const getPatientListByTeamIdErrorAction = (
  getPatientListByTeamIdResponse: GetPatientListByTeamIdRes,
  errMsg: string,
  status: number,
): GetPatientListByTeamIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_TEAM_ID_FAIL,
    payload: getPatientListByTeamIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientListByTeamIdUpdateAPIMsgAction = (
  getPatientListByTeamIdResponse: GetPatientListByTeamIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientListByTeamIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_TEAM_ID_UPDATE_API_MSG,
    payload: getPatientListByTeamIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientListByTeamIdAPIResClearAction = (
  getPatientListByTeamIdResponse: GetPatientListByTeamIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientListByTeamIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_TEAM_ID_UPDATE_API_RES,
    payload: getPatientListByTeamIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
