import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getTaskListValue } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import MWExceptionList from "../../component/MWExceptionList";
import dayjs from "dayjs";
import {
  adminSetTaskStatusApiCall,
  clearAdminSetTaskStatusAPIRes,
} from "redux/TaskAdmin/AdminSetTaskStatus/API";
import { AdminSetTaskStatusBody } from "redux/TaskAdmin/AdminSetTaskStatus/Model";
import MWSnackbar from "component/MWSnackbar";
import { adminTaskStatusListApi } from "redux/TaskAdmin/AdminTaskStatusListDropDown/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function DashboardTasklist() {
  const dispatch = useDispatch();
  const history = useHistory();
  const gotoTaskDetails = (rowIndex: any) => {
    history.push(`/selftaskdetails/${rowIndex}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTaskListValue());
    dispatch(adminTaskStatusListApi());
  }, [dispatch]);

  // call Store
  const AdminAllTaskList = useSelector((state: AppState) => state.taskListValue);
  const AdminTaskStatus = useSelector((state: AppState) => state.adminSettaskStatusResponseValue);
  const AdminTaskStatusDropdownList = useSelector(
    (state: AppState) => state.adminTaskStatusListValues,
  );
  const [taskStatusValueList, setTaskStatusValueList] = useState<any[]>([]);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [selectedRows, setSelectedRows] = useState([] as any[]);
  const [isEdit, setIsEdit] = useState(true);
  const [taskStatusMsg, setTaskStatusMsg] = useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (AdminAllTaskList?.taskListValue !== undefined) {
      let dataPointArr = AdminAllTaskList?.taskListValue.map((element: any) => ({
        id: element.id,
        patientName: element.patientName,
        title: element.title,
        consultationType: element.consultationType,
        created_date: dayjs(element.created_date).format("MMMM D, YYYY"),
        taskStatus: element.status,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllTaskList]);

  useEffect(() => {
    setTaskStatusValueList(AdminTaskStatusDropdownList?.adminTaskStatusListRes?.taskStatusList);
  }, [AdminTaskStatusDropdownList]);

  const handleSelectedExecutiveChange = (_event: any, newvalue: any) => {
    if (newvalue.name !== null) {
      if (selectedRows.length > 0) {
        let id = selectedRows;
        id.forEach((element) => {
          dispatch(
            adminSetTaskStatusApiCall({
              taskId: element,
              taskStatus: newvalue.name,
            } as AdminSetTaskStatusBody),
          );
        });
      }
    }
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminAllTaskList?.loading));
    if (AdminTaskStatus?.successMsg !== "") {
      setTaskStatusMsg(AdminTaskStatus?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(getTaskListValue());
      setIsEdit(true);
    }
    if (AdminTaskStatus?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setTaskStatusMsg(AdminTaskStatus?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminTaskStatus]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoTaskDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "patientName", headerName: "Patient name", minWidth: 200, flex: 1 },
    { field: "title", headerName: "Title", minWidth: 350, flex: 1 },
    {
      field: "consultationType",
      headerName: "Type",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "taskStatus",
      headerName: "Task Status",
      width: 100,
      align: "center",
    },
    { field: "created_date", headerName: "Create Date", minWidth: 120, align: "center" },
  ];

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAdminSetTaskStatusAPIRes());
    }
  };

  const DataGridMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
                id="taskId"
                options={taskStatusValueList}
                onChange={(event: any, newValue: any) => {
                  handleSelectedExecutiveChange(event, newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Select Task Status" />}
                disableClearable
                getOptionLabel={(option) => option.name}
                disabled={isEdit}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                size="small"
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {AdminAllTaskList?.taskListValue !== undefined ? (
          AdminAllTaskList?.taskListValue.length > 0 ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              pageSize={10}
              checkboxSelection
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = AdminAllTaskList?.taskListValue.filter((row) =>
                  selectedIDs.has(row.id),
                );
                var result = selectedRows.map((element) => {
                  return element.id;
                });
                setSelectedRows(result);
              }}
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {DataGridMarkup}
      {taskStatusMsg !== "" ? (
        <MWSnackbar
          msg={taskStatusMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
