import {
  AlertColor,
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Paper,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import AddDataPointDialog from "../AddDataPointDialog";
import DataPointHistoryDialog from "../DataPointHistoryDialog";
import { useDispatch, useSelector } from "react-redux";
import { clearPatientDataPointClearAPIRes } from "../../../redux/patientDataDetails/addPatientDetails/API";
import { clearPatientDataPointEditAPIRes } from "../../../redux/patientDataDetails/UpdatePatientDataPoint/API";
import { clearImageUploadAPIRes } from "../../../redux/ImageUpload/API";
import ViewObservation from "../ViewObservation/Index";
import {
  clearPatientDetailsAPIRes,
  getPatientDataDetailsById,
} from "../../../redux/patientDataDetails/patientDetailsById/API";
import { AppState } from "../../../redux/store/Store";
import {
  clearExtractObservationDetailsAPIRes,
  extractObservationDetailsById,
} from "../../../redux/extractObservationDetailsById/API";
import { ExtractObservationDetailsBody } from "../../../redux/extractObservationDetailsById/Model";
import MWSnackbar from "../../../component/MWSnackbar";
import {
  clearViewExtractObservationDetailsAPIRes,
  getExtractObservationByDetailsId,
} from "../../../redux/GetViewExtractObservation/API";
import { ViewExtractObservationBody } from "../../../redux/GetViewExtractObservation/Model";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import { NameGenerate } from "../../../component/Utility";
import { useLocation, useParams } from "react-router-dom";
import DicomViewer from "../../DicomViewer";
import { PatientDetailsFromDashboardBody } from "redux/patientDataDetails/patientDetailsById/Model";
import {
  clearGetPatientCaseListByPatientIdAPIRes,
  getPatientCaseListByPatientIdApi,
} from "redux/GetPatientCaseListByPatientId/API";
import { GetPatientCaseListByPatientIdBody } from "redux/GetPatientCaseListByPatientId/Model";

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "calc(100% - 20px)",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);

type Props = {
  dialogState?: boolean | any;
  dialogClose?: any;
  value?: any;
  page?: number | any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function DocumentViewer({ dialogState, dialogClose, value, page }: Props) {
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const dispatch = useDispatch();
  let query = useQuery();
  const patientDetailsId = query.get("documentid");
  const [valueNav, setValueNav] = React.useState(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValueNav(newValue);
  };
  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = () => {
    setDocViwerActive(false);
    dialogClose(false);
    dispatch(clearPatientDataPointClearAPIRes());
    dispatch(clearPatientDataPointEditAPIRes());
    dispatch(clearImageUploadAPIRes());
    dispatch(clearViewExtractObservationDetailsAPIRes());
    dispatch(clearPatientDetailsAPIRes());
    dispatch(clearGetPatientCaseListByPatientIdAPIRes());
  };
  const getPatientDetailsById = useSelector((state: AppState) => state.getPatientDetailsById);
  const { patientid } = useParams() as {
    patientid: string;
  };
  const patientId = patientid.split("-")[0];
  useEffect(() => {
    setDocViwerActive(dialogState);
    let patientDetailsBody = {
      patientDetailsId: String(patientDetailsId),
    } as PatientDetailsFromDashboardBody;
    dispatch(getPatientDataDetailsById(patientDetailsBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState]);

  const [showAddDataPointDialog, setShowAddDataPointDialog] = React.useState(false);
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
  };
  const [addPatientDetailsMsg, setAddPatientDetailsMsg] = useState("");
  const [editPatientDetailsMsg, setEditPatientDetailsMsg] = useState("");
  const [updateDrDetailsAPIMsg, setUpdateDrDetailsAPIMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const extractObservationByDetailsIdVal = useSelector(
    (state: AppState) => state.extractObservationByDetailsIdValue,
  );
  const getExtractObservationDetailsValue = useSelector(
    (state: AppState) =>
      state.getExtractObservationByDetailsValue.getExtractObservationRes.observationList,
  );
  const caseHistoryPtName = useSelector(
    (state: AppState) =>
      state.getPatientCaseListResult.getPatientCaseListByPatientIdRes.patientName,
  );
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearExtractObservationDetailsAPIRes());
      dispatch(clearPatientDataPointClearAPIRes());
      dispatch(clearPatientDataPointEditAPIRes());
    }
  };
  const addDataDetailsStoreVal = useSelector((state: AppState) => state.addPointDataDetailsRes);
  React.useEffect(() => {
    if (addDataDetailsStoreVal?.addPatientDataDetailsRes.message !== undefined) {
      setAddPatientDetailsMsg(addDataDetailsStoreVal?.addPatientDataDetailsRes.message);
      setMsgType("success");
    } else {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);
  const updateDataDetailsVal = useSelector((state: AppState) => state.updatePatientDataDetailsRes);
  React.useEffect(() => {
    if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
      setEditPatientDetailsMsg(updateDataDetailsVal?.updatePatientDataDetailsRes?.message);
      setMsgType("success");
    } else {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataDetailsVal]);
  useEffect(() => {
    if (caseHistoryPtName === undefined) {
      let patientCaseListBody = {
        patientId: patientId,
      } as GetPatientCaseListByPatientIdBody;
      dispatch(getPatientCaseListByPatientIdApi(patientCaseListBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistoryPtName]);

  useEffect(() => {
    if (extractObservationByDetailsIdVal?.extractObservationDetailsRes?.message !== undefined) {
      setMsgType("success");
      setUpdateDrDetailsAPIMsg(
        extractObservationByDetailsIdVal?.extractObservationDetailsRes?.message,
      );
      viewExtractObservation();
    } else if (extractObservationByDetailsIdVal?.errorMsg !== "") {
      setUpdateDrDetailsAPIMsg(extractObservationByDetailsIdVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractObservationByDetailsIdVal]);
  const extractObservation = () => {
    let extractBody = {
      patientDetailsId: String(patientDetailsId),
    } as ExtractObservationDetailsBody;
    dispatch(extractObservationDetailsById(extractBody));
  };

  const viewExtractObservation = () => {
    let viewExtractBody = {
      patientDetailsId: String(patientDetailsId),
    } as ViewExtractObservationBody;
    dispatch(getExtractObservationByDetailsId(viewExtractBody));
  };

  return (
    <Box>
      <Dialog fullScreen open={docViwerActive}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDocViewerChange}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {caseHistoryPtName !== undefined
                ? `Health Details Of ${NameGenerate.changeName(patientId, caseHistoryPtName)}`
                : `Health Details Of ${NameGenerate.changeName(patientId, value.patientName)}`}
            </Typography>
            <Button sx={{ mr: 2 }} onClick={() => setShowAddDataPointDialog(true)}>
              Edit
            </Button>
            <Button variant="outlined" onClick={extractObservation}>
              Extract Observation
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid xs={7}>
            <Tooltip
              title={
                getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.id !==
                undefined
                  ? `ImageID: ${getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.id}`
                  : "File Not Uploaded "
              }
              arrow
            >
              <Paper square sx={{ minHeight: "100vh" }}>
                {getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.id !==
                undefined ? (
                  <TransformWrapper initialScale={1} initialPositionX={50} initialPositionY={50}>
                    {(utils) => (
                      <React.Fragment>
                        <Controls {...utils} />
                        <TransformComponent
                          wrapperStyle={{
                            width: "60vw",
                            height: "100vw",
                            maxWidth: "100%",
                            maxHeight: "calc(100vh - 50px)",
                          }}
                        >
                          {getPatientDetailsById?.patientDetailsById?.patientDetails
                            ?.patientDocument?.document !== "" ? (
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "mp4",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "pdf",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "quicktime",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "webm",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "3gpp",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "x-flv",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "x-ms-asf",
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "mp3",
                            ) ? (
                              <iframe
                                src={
                                  getPatientDetailsById?.patientDetailsById?.patientDetails
                                    ?.patientDocument?.document
                                }
                                title="Small document"
                                width="900"
                                height="700"
                                allow="fullscreen"
                              ></iframe>
                            ) : getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                                "DCM",
                              ) ||
                              getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                                "dcm",
                              ) ? (
                              <DicomViewer
                                imageFile={
                                  getPatientDetailsById?.patientDetailsById?.patientDetails
                                    ?.patientDocument?.document
                                }
                              />
                            ) : (
                              <img
                                style={{
                                  maxInlineSize: "100%",
                                  blockSize: "auto",
                                }}
                                src={
                                  getPatientDetailsById?.patientDetailsById?.patientDetails
                                    ?.patientDocument?.document
                                }
                                alt="test"
                              />
                            )
                          ) : null}
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    pt={8}
                  >
                    <Alert variant="outlined" severity="info">
                      Image File Not Uploaded
                    </Alert>
                  </Grid>
                )}
              </Paper>
            </Tooltip>
          </Grid>
          <Grid xs={5}>
            <Box sx={{ width: "100%", px: 2, py: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography>
                  <Grid item>
                    <Typography gutterBottom>
                      Health Details Of &nbsp;
                      {caseHistoryPtName !== undefined ? (
                        <b>{NameGenerate.changeName(patientId, caseHistoryPtName)}</b>
                      ) : (
                        <b>{NameGenerate.changeName(patientId, value.patientName)}</b>
                      )}{" "}
                      || Recorded on:{" "}
                      <b>
                        {dayjs(
                          getPatientDetailsById?.patientDetailsById?.patientDetails?.startDate,
                        ).format("dddd, MMMM D, YYYY h:mm A")}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom>
                      Observation:{" "}
                      <b>
                        {getPatientDetailsById?.patientDetailsById?.patientDetails?.description.replace(
                          /(\d{1,2})-(\d{2})\s([AP]M)\s~\s(\d{1,2})-(\d{2})\s([AP]M)/,
                          "$1:$2 $3 to $4:$5 $6",
                        )}
                      </b>
                    </Typography>
                  </Grid>
                </Typography>
                <Grid item>
                  <Tooltip
                    title={`Patient Details Id: ${getPatientDetailsById?.patientDetailsById?.patientDetails?.id}`}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueNav}
                  onChange={handleChange}
                  aria-label="Health Details Tabs"
                  centered
                >
                  <Tab label="History" {...a11yProps(0)} />
                  <Tab label="Observations" onClick={viewExtractObservation} {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={valueNav} index={0}>
                <DataPointHistoryDialog />
              </TabPanel>
              <TabPanel value={valueNav} index={1}>
                <ViewObservation
                  getExtractObservationDetailsValue={getExtractObservationDetailsValue}
                />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      {addPatientDetailsMsg !== "" ? (
        <MWSnackbar msg={addPatientDetailsMsg} type={msgType} alertClose={checkAlertClose} />
      ) : null}
      {editPatientDetailsMsg !== "" ? (
        <MWSnackbar msg={editPatientDetailsMsg} type={msgType} alertClose={checkAlertClose} />
      ) : null}
      {updateDrDetailsAPIMsg ? (
        <MWSnackbar msg={updateDrDetailsAPIMsg} type={msgType} alertClose={checkAlertClose} />
      ) : null}
      {showAddDataPointDialog ? (
        <AddDataPointDialog
          itemId={patientDetailsId}
          addDataPointDialogEnable={showAddDataPointDialog}
          addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
          value={getPatientDetailsById?.patientDetailsById?.patientDetails}
          page={page}
          sessionId={sessionId}
        />
      ) : null}
    </Box>
  );
}
