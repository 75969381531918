import {
  PATIENT_DETAILS_FIXED_FAIL,
  PATIENT_DETAILS_FIXED_LOADING,
  PATIENT_DETAILS_FIXED_SUCCESS,
  PATIENT_DETAILS_FIXED_API_MSG,
  PATIENT_DETAILS_FIXED_API_RES,
  PatientDetailsFixedActionTypes,
} from "./ActionTypes";
import { PatientDetailsFixedDetails } from "./Model";

export const patientDetailsFixedLoadingAction = (
  loading: boolean,
): PatientDetailsFixedActionTypes => {
  return {
    type: PATIENT_DETAILS_FIXED_LOADING,
    loading: loading,
  };
};

export const patientDetailsFixedSuccessAction = (
  patientDetailsFixedResponse: PatientDetailsFixedDetails,
  successMsg: string,
): PatientDetailsFixedActionTypes => {
  return {
    type: PATIENT_DETAILS_FIXED_SUCCESS,
    payload: patientDetailsFixedResponse,
    successMsg: successMsg,
  };
};

export const patientDetailsFixedErrorAction = (
  patientDetailsFixedResponse: PatientDetailsFixedDetails,
  errMsg: string,
  status: number,
): PatientDetailsFixedActionTypes => {
  return {
    type: PATIENT_DETAILS_FIXED_FAIL,
    payload: patientDetailsFixedResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const patientDetailsFixedAPIMsgAction = (
  patientDetailsFixedResponse: PatientDetailsFixedDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientDetailsFixedActionTypes => {
  return {
    type: PATIENT_DETAILS_FIXED_API_MSG,
    payload: patientDetailsFixedResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const patientDetailsFixedAPIResClearAction = (
  patientDetailsFixedResponse: PatientDetailsFixedDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientDetailsFixedActionTypes => {
  return {
    type: PATIENT_DETAILS_FIXED_API_RES,
    payload: patientDetailsFixedResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
