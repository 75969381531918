import {
  ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_FAIL,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_LOADING,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_MSG,
  ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_RES,
  OrganizationAddRegistrationRequestDetailsActionTypes,
} from "./ActionTypes";
import { OrganizationAddRegistrationRequestDetails } from "./Model";


export const organizationAddRegistrationRequestDetailsLoadingAction = (
  loading: boolean,
): OrganizationAddRegistrationRequestDetailsActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_LOADING,
    loading: loading,
  };
};

export const organizationAddRegistrationRequestDetailsSuccessAction = (
  organizationAddRegistrationRequestDetailsResponseResponse: OrganizationAddRegistrationRequestDetails,
  successMsg: string,
): OrganizationAddRegistrationRequestDetailsActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS,
    payload: organizationAddRegistrationRequestDetailsResponseResponse,
    successMsg: successMsg,
  };
};

export const organizationAddRegistrationRequestDetailsErrorAction = (
  organizationAddRegistrationRequestDetailsResponseResponse: OrganizationAddRegistrationRequestDetails,
  errMsg: string,
  status: number,
): OrganizationAddRegistrationRequestDetailsActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_FAIL,
    payload: organizationAddRegistrationRequestDetailsResponseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const organizationAddRegistrationRequestDetailsAPIMsgAction = (
  organizationAddRegistrationRequestDetailsResponseResponse: OrganizationAddRegistrationRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): OrganizationAddRegistrationRequestDetailsActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_MSG,
    payload: organizationAddRegistrationRequestDetailsResponseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const organizationAddRegistrationRequestDetailsAPIResClearAction = (
  organizationAddRegistrationRequestDetailsResponseResponse: OrganizationAddRegistrationRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): OrganizationAddRegistrationRequestDetailsActionTypes => {
  return {
    type: ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_RES,
    payload: organizationAddRegistrationRequestDetailsResponseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
