import {
  ADD_REGISTRATION_REQUEST_FAIL,
  ADD_REGISTRATION_REQUEST_LOADING,
  ADD_REGISTRATION_REQUEST_SUCCESS,
  ADD_REGISTRATION_REQUEST_API_MSG,
  ADD_REGISTRATION_REQUEST_API_RES,
  AddRegistrationRequestActionTypes,
} from "./ActionTypes";
import { AddRegistrationRequestDetails } from "./Model";


export const addRegistrationRequestLoadingAction = (
  loading: boolean,
): AddRegistrationRequestActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_LOADING,
    loading: loading,
  };
};

export const addRegistrationRequestSuccessAction = (
  addRegistrationRequestResponseResponse: AddRegistrationRequestDetails,
  successMsg: string,
): AddRegistrationRequestActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_SUCCESS,
    payload: addRegistrationRequestResponseResponse,
    successMsg: successMsg,
  };
};

export const addRegistrationRequestErrorAction = (
  addRegistrationRequestResponseResponse: AddRegistrationRequestDetails,
  errMsg: string,
  status: number,
): AddRegistrationRequestActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_FAIL,
    payload: addRegistrationRequestResponseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addRegistrationRequestAPIMsgAction = (
  addRegistrationRequestResponseResponse: AddRegistrationRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddRegistrationRequestActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_API_MSG,
    payload: addRegistrationRequestResponseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addRegistrationRequestAPIResClearAction = (
  addRegistrationRequestResponseResponse: AddRegistrationRequestDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddRegistrationRequestActionTypes => {
  return {
    type: ADD_REGISTRATION_REQUEST_API_RES,
    payload: addRegistrationRequestResponseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
