import { PatientDetailsById } from "./Model";
export const PATIENT_DETAILS_LOADING = "PATIENT_DETAILS_LOADING";
export const PATIENT_DETAILS_SUCCESS = "PATIENT_DETAILS_SUCCESS";
export const PATIENT_DETAILS_FAIL = "PATIENT_DETAILS_FAIL";
export const PATIENT_DETAILS_UPDATE_API_MSG = "PATIENT_DETAILS_UPDATE_API_MSG";
export const PATIENT_DETAILS_UPDATE_API_RES = "PATIENT_DETAILS_UPDATE_API_RES";

export interface PatientDetailsState {
  patientDetailsById: PatientDetailsById;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsLoading {
  type: typeof PATIENT_DETAILS_LOADING;
  loading: boolean;
}
export interface PatientDetailsSuccess {
  type: typeof PATIENT_DETAILS_SUCCESS;
  payload: PatientDetailsById;
  successMsg: string;
}
export interface PatientDetailsFail {
  type: typeof PATIENT_DETAILS_FAIL;
  payload: PatientDetailsById;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsUpdateAPIMsg {
  type: typeof PATIENT_DETAILS_UPDATE_API_MSG;
  payload: PatientDetailsById;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsUpdateAPIRes {
  type: typeof PATIENT_DETAILS_UPDATE_API_RES;
  payload: PatientDetailsById;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientDetailsActionTypes =
  | PatientDetailsLoading
  | PatientDetailsSuccess
  | PatientDetailsFail
  | PatientDetailsUpdateAPIMsg
  | PatientDetailsUpdateAPIRes;

