import {
  PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL,
  PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING,
  PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS,
  PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG,
  PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES,
  PatientDataRangeBySessionIdTypeActionTypes,
} from "./ActionTypes";
import { PatientDataRangeBySessionIdTypeDetails } from "./Model";

export const PatientDataRangeBySessionIdTypeLoadingAction = (
  loading: boolean,
): PatientDataRangeBySessionIdTypeActionTypes => {
  return {
    type: PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const PatientDataRangeBySessionIdTypeSuccessAction = (
  patientDataRangeBySessionIdTypeResponse: PatientDataRangeBySessionIdTypeDetails,
  successMsg: string,
): PatientDataRangeBySessionIdTypeActionTypes => {
  return {
    type: PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS,
    payload: patientDataRangeBySessionIdTypeResponse,
    successMsg: successMsg,
  };
};

export const PatientDataRangeBySessionIdTypeErrorAction = (
  patientDataRangeBySessionIdTypeResponse: PatientDataRangeBySessionIdTypeDetails,
  errMsg: string,
  status: number,
): PatientDataRangeBySessionIdTypeActionTypes => {
  return {
    type: PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL,
    payload: patientDataRangeBySessionIdTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const PatientDataRangeBySessionIdTypeAPIMsgAction = (
  patientDataRangeBySessionIdTypeResponse: PatientDataRangeBySessionIdTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): PatientDataRangeBySessionIdTypeActionTypes => {
  return {
    type: PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG,
    payload: patientDataRangeBySessionIdTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const PatientDataRangeBySessionIdTypeAPIResClearAction = (
  patientDataRangeBySessionIdTypeResponse: PatientDataRangeBySessionIdTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): PatientDataRangeBySessionIdTypeActionTypes => {
  return {
    type: PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES,
    payload: patientDataRangeBySessionIdTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
