import { ProviderOrgStaffAdminRes } from "./Model";
export const PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING = "PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING";
export const PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS = "PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS";
export const PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL = "PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL";
export const PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG = "PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG";
export const PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES = "PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES";

export interface GetProviderOrgStaffAdminListState {
  getProviderOrgStaffAdminList: ProviderOrgStaffAdminRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgStaffAdminListLoading {
  type: typeof PROVIDER_ORG_STAFF_ADMIN_LIST_LOADING;
  loading: boolean;
}
export interface GetProviderOrgStaffAdminListSuccess {
  type: typeof PROVIDER_ORG_STAFF_ADMIN_LIST_SUCCESS;
  payload: ProviderOrgStaffAdminRes;
  successMsg: string;
}
export interface GetProviderOrgStaffAdminListFail {
  type: typeof PROVIDER_ORG_STAFF_ADMIN_LIST_FAIL;
  payload: ProviderOrgStaffAdminRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgStaffAdminListUpdateAPIMsg {
  type: typeof PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_MSG;
  payload: ProviderOrgStaffAdminRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrgStaffAdminListUpdateAPIRes {
  type: typeof PROVIDER_ORG_STAFF_ADMIN_LIST_UPDATE_API_RES;
  payload: ProviderOrgStaffAdminRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetProviderOrgStaffAdminListActionTypes =
  | GetProviderOrgStaffAdminListLoading
  | GetProviderOrgStaffAdminListSuccess
  | GetProviderOrgStaffAdminListFail
  | GetProviderOrgStaffAdminListUpdateAPIMsg
  | GetProviderOrgStaffAdminListUpdateAPIRes;
