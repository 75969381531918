import {
  DEACTIVE_STAFF_OF_HEALTHCAMP_DATA,
  DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL,
  DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING,
  DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS,
  DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG,
  DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES,
  DeactiveStaffOfHealthCampActionTypes,
} from "./ActionTypes";
import { PageCount, DeactiveStaffOfHealthCampDetails } from "./Model";

export const deactiveStaffOfHealthcampIdAction = (
  DeactiveStaffOfHealthCampDetails: DeactiveStaffOfHealthCampDetails,
  pageCount: PageCount,
): DeactiveStaffOfHealthCampActionTypes => {
  return {
    type: DEACTIVE_STAFF_OF_HEALTHCAMP_DATA,
    payload: DeactiveStaffOfHealthCampDetails,
    pageCount: pageCount,
  };
};

export const deactiveStaffOfHealthcampLoadingAction = (
  loading: boolean,
): DeactiveStaffOfHealthCampActionTypes => {
  return {
    type: DEACTIVE_STAFF_OF_HEALTHCAMP_LOADING,
    loading: loading,
  };
};

export const deactiveStaffOfHealthcampSuccessAction = (
  deactiveStaffOfHealthcampResponse: DeactiveStaffOfHealthCampDetails,
  successMsg: string,
): DeactiveStaffOfHealthCampActionTypes => {
  return {
    type: DEACTIVE_STAFF_OF_HEALTHCAMP_SUCCESS,
    payload: deactiveStaffOfHealthcampResponse,
    successMsg: successMsg,
  };
};

export const deactiveStaffOfHealthcampErrorAction = (
  deactiveStaffOfHealthcampResponse: DeactiveStaffOfHealthCampDetails,
  errMsg: string,
  status: number,
): DeactiveStaffOfHealthCampActionTypes => {
  return {
    type: DEACTIVE_STAFF_OF_HEALTHCAMP_FAIL,
    payload: deactiveStaffOfHealthcampResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deactiveStaffOfHealthcampAPIMsgAction = (
  deactiveStaffOfHealthcampResponse: DeactiveStaffOfHealthCampDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DeactiveStaffOfHealthCampActionTypes => {
  return {
    type: DEACTIVE_STAFF_OF_HEALTHCAMP_API_MSG,
    payload: deactiveStaffOfHealthcampResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deactiveStaffOfHealthcampAPIResClearAction = (
  deactiveStaffOfHealthcampResponse: DeactiveStaffOfHealthCampDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DeactiveStaffOfHealthCampActionTypes => {
  return {
    type: DEACTIVE_STAFF_OF_HEALTHCAMP_API_RES,
    payload: deactiveStaffOfHealthcampResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
