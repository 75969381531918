import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExecutiveList } from "redux/Executive/GetExecutiveList/Model";
import _ from "lodash";
import DeleteExecutive from "../DeleteExecutive";
import { DeleteExecutiveDialogClose } from "../Model";
import { AppState } from "redux/store/Store";
import { clearEditExecutiveAPIRes, editExecutive } from "redux/Executive/EditExecutive/API";
import { addExecutive, clearAddExecutiveAPIRes } from "redux/Executive/AddExecutive/API";
import { executiveList } from "redux/Executive/GetExecutiveList/API";
import { EditExecutivePayload } from "redux/Executive/EditExecutive/Model";
type Props = {
  enableDialog: boolean;
  updateDialogState: (value: boolean) => void;
  executiveDetails?: any;
  updateExecutiveDetails: (value: any) => void;
};

export default function UpdateExecutive({
  enableDialog,
  updateDialogState,
  executiveDetails,
  updateExecutiveDetails,
}: Props) {
  const dispatch = useDispatch();
  const [executiveFirstName, setExecutiveFirstName] = useState("");
  const [errorExecutiveFirstName, setErrorExecutiveFirstName] = useState("");
  const [executiveLastName, setExecutiveLastName] = useState("");
  const [errorExecutiveLastName, setErrorExecutiveLastName] = useState("");
  const [executiveEmail, setExecutiveEmail] = useState("");
  const [errorExecutiveEmail, setErrorExecutiveEmail] = useState("");
  const [executivePhoneNo, setExecutivePhoneNo] = useState("");
  const [errorExecutivePhoneNo, setErrorExecutivePhoneNo] = useState("");
  const [executiveUserId, setExecutiveUserId] = useState("");
  const [executiveId, setExecutiveId] = useState("");
  const [editExecutiveModalActive, setEditExecutiveModalActive] = useState(false);
  useEffect(() => {
    setEditExecutiveModalActive(enableDialog);
  }, [enableDialog]);
  useEffect(() => {
    if (!_.isEmpty(executiveDetails)) {
      setExecutiveFirstName(executiveDetails?.executiveFirstName);
      setExecutiveLastName(executiveDetails?.executiveLastName);
      setExecutiveEmail(executiveDetails?.executiveEmail);
      setExecutivePhoneNo(executiveDetails?.executivePhoneNumber);
      setExecutiveId(executiveDetails?.id);
      setExecutiveUserId(executiveDetails?.userId);
    }
  }, [executiveDetails]);
  const handleEditExecutiveModalToggle = () => {
    setEditExecutiveModalActive(false);
    updateDialogState(false);
    updateExecutiveDetails({} as ExecutiveList);
    setExecutiveFirstName("");
    setExecutiveLastName("");
    setExecutiveEmail("");
    setExecutivePhoneNo("");
    setExecutiveId("");
    setErrorExecutiveFirstName("");
    setErrorExecutiveLastName("");
    setErrorExecutiveEmail("");
    setErrorExecutivePhoneNo("");
    setAddExecutiveMsg("");
    dispatch(clearAddExecutiveAPIRes());
    setEditExecutiveMsg("");
    dispatch(clearEditExecutiveAPIRes());
  };

  // Executive Add Body
  const executiveAddBody = {
    first_name: executiveFirstName,
    last_name: executiveLastName,
    email: executiveEmail,
    phoneNumber: executivePhoneNo,
  };
  // Add / Edit Executive API
  const addUpdateExecutive = () => {
    if (
      executiveFirstName === "" &&
      executiveLastName === "" &&
      executiveEmail === "" &&
      executivePhoneNo === ""
    ) {
      setErrorExecutiveFirstName("First name is required");
      setErrorExecutiveLastName("Last name is required");
      setErrorExecutiveEmail("Email is required");
      setErrorExecutivePhoneNo("Phone Number is required");
    } else if (executiveFirstName !== "" && executiveLastName === "" && executiveEmail !== "") {
      setErrorExecutiveFirstName("First name is required");
      setErrorExecutiveLastName("Last name is required");
      setErrorExecutiveEmail("Email is required");
    } else if (executiveFirstName === "" && executiveLastName !== "" && executiveEmail === "") {
      setErrorExecutiveFirstName("First name is required");
      setErrorExecutiveLastName("");
      setErrorExecutiveEmail("Email is required");
    } else if (executiveFirstName === "" && executiveLastName !== "" && executiveEmail !== "") {
      setErrorExecutiveFirstName("First name is required");
      setErrorExecutiveLastName("");
      setErrorExecutiveEmail("");
    } else if (executiveFirstName !== "" && executiveLastName === "" && executiveEmail === "") {
      setErrorExecutiveFirstName("");
      setErrorExecutiveLastName("Last name is required");
      setErrorExecutiveEmail("Email is required");
    } else if (executiveFirstName !== "" && executiveLastName === "" && executiveEmail !== "") {
      setErrorExecutiveFirstName("");
      setErrorExecutiveLastName("Last name is required");
      setErrorExecutiveEmail("");
    } else if (executiveFirstName !== "" && executiveLastName !== "" && executiveEmail === "") {
      setErrorExecutiveFirstName("");
      setErrorExecutiveLastName("");
      setErrorExecutiveEmail("Email is required");
    } else {
      //setIsLoading(true);
      let executiveEditSubmitBody;
      if (executiveId) {
        executiveEditSubmitBody = Object.assign(executiveAddBody, {
          executiveId: String(executiveId),
          userId: executiveUserId,
        }) as EditExecutivePayload;
        setEditExecutiveMsg("");
        dispatch(clearEditExecutiveAPIRes());
        dispatch(editExecutive(executiveEditSubmitBody));
      } else {
        setAddExecutiveMsg("");
        dispatch(clearAddExecutiveAPIRes());
        dispatch(addExecutive(executiveAddBody));
      }
    }
  };

  const [enableDeleteExecutiveDialog, setEnableDeleteExecutiveDialog] = useState(false);
  const updateDeleteExecutiveDialogState = (passedVal: DeleteExecutiveDialogClose) => {
    setEnableDeleteExecutiveDialog(passedVal?.value);
    if (passedVal?.category === 1) {
      handleEditExecutiveModalToggle();
      dispatch(executiveList());
    } else if (passedVal?.category === 0) {
      setEditExecutiveModalActive(true);
    }
  };

  const addExecutiveRes = useSelector((state: AppState) => state.addExecutiveRes);
  const [addExecutiveMsg, setAddExecutiveMsg] = useState("");
  const [addExecutiveMsgType, setAddExecutiveMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (addExecutiveRes?.successMsg !== "") {
      dispatch(executiveList());
      handleEditExecutiveModalToggle();
    }
    if (addExecutiveRes?.errorMsg !== "") {
      setAddExecutiveMsgType("error");
      setAddExecutiveMsg(addExecutiveRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExecutiveRes]);

  const editExecutiveRes = useSelector((state: AppState) => state.editExecutiveRes);
  const [editExecutiveMsg, setEditExecutiveMsg] = useState("");
  const [editExecutiveMsgType, setEditExecutiveMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (editExecutiveRes?.successMsg !== "") {
      dispatch(executiveList());
      handleEditExecutiveModalToggle();
    }
    if (editExecutiveRes?.errorMsg !== "") {
      setEditExecutiveMsgType("error");
      setEditExecutiveMsg(editExecutiveRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editExecutiveRes]);
  return (
    <Box>
      <Dialog open={editExecutiveModalActive} fullWidth maxWidth="md">
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={11} sm={11} md="auto" lg="auto" xl="auto">
              <Typography variant="h5">
                {executiveDetails?.id !== undefined ? "Edit" : "Add"} MedicalWISDOM Executive
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md="auto" lg="auto" xl="auto">
              <IconButton onClick={handleEditExecutiveModalToggle}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                label="First Name"
                placeholder="Enter first name"
                value={executiveFirstName}
                onChange={(e) => {
                  setExecutiveFirstName(e.target.value);
                }}
                id="executiveFName"
                fullWidth
                size="small"
              />
              <Typography sx={{ color: "#c62828" }}>{errorExecutiveFirstName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                label="Last Name"
                placeholder="Enter last name"
                value={executiveLastName}
                onChange={(e) => {
                  setExecutiveLastName(e.target.value);
                }}
                id="executiveLName"
                fullWidth
                size="small"
              />
              <Typography sx={{ color: "#c62828" }}>{errorExecutiveLastName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                label="Email"
                placeholder="Enter email"
                value={executiveEmail}
                onChange={(e) => {
                  setExecutiveEmail(e.target.value);
                }}
                id="executiveEmail"
                fullWidth
                size="small"
              />
              <Typography sx={{ color: "#c62828" }}>{errorExecutiveEmail}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                label="Phone Number"
                placeholder="Enter Phone Number"
                value={executivePhoneNo}
                onChange={(e) => {
                  setExecutivePhoneNo(e.target.value);
                }}
                id="executivePhoneNumber"
                fullWidth
                size="small"
              />
              <Typography sx={{ color: "#c62828" }}>{errorExecutivePhoneNo}</Typography>
            </Grid>
            {addExecutiveMsg !== "" && (
              <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                <Alert
                  onClose={() => {
                    setAddExecutiveMsg("");
                    dispatch(clearAddExecutiveAPIRes());
                  }}
                  severity={addExecutiveMsgType}
                >
                  {addExecutiveMsg}
                </Alert>
              </Grid>
            )}
            {editExecutiveMsg !== "" && (
              <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                <Alert
                  onClose={() => {
                    setEditExecutiveMsg("");
                    dispatch(clearEditExecutiveAPIRes());
                  }}
                  severity={editExecutiveMsgType}
                >
                  {editExecutiveMsg}
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent={executiveDetails?.id !== undefined ? "space-between" : "flex-end"}
            alignItems="center"
          >
            {executiveDetails?.id !== undefined && (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEnableDeleteExecutiveDialog(true);
                    setEditExecutiveModalActive(false);
                  }}
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "darkred",
                    },
                    textTransform: "none",
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleEditExecutiveModalToggle}
                    sx={{ textTransform: "none" }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={addUpdateExecutive}
                    sx={{ textTransform: "none" }}
                  >
                    {executiveDetails?.id !== undefined ? "Edit" : "Add"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {enableDeleteExecutiveDialog && (
        <DeleteExecutive
          enableDialog={enableDeleteExecutiveDialog}
          updateDialogState={updateDeleteExecutiveDialogState}
          executiveDetails={executiveDetails}
        />
      )}
    </Box>
  );
}
