import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  List,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWExceptionList from "../../../component/MWExceptionList";
import ModifiedList from "component/ModifiedList";

export default function CaseList() {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const gotoPatientCaseList = () => {
    history.push(`/patientcaselist/${patientid}`);
  };

  const caseHistory = useSelector((state: AppState) => state.caseValue);
  console.log("caseHistory", caseHistory);

  return (
    <Card style={{ borderRadius: 20, height: "100%" }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Case History List
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {caseHistory?.caseValue.length > 0 ? (
          <Box
            style={{
              textDecoration: "none",
              textTransform: "none",
              maxHeight: "232px",
              overflow: "auto",
            }}
          >
            <List>
              <ModifiedList
                icon={true}
                showSubtitle={true}
                showReason={true}
                showNavigation={true}
                data={caseHistory?.caseValue}
                path={"casedetails"}
              />
            </List>
          </Box>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="text"
              endIcon={<ArrowRightAltIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => gotoPatientCaseList()}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
