import { Autocomplete, Box, Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataList, EHRDataType } from "./DataList";
import ConnectEHRList from "./ConnectedEHRList";
import MWPageTitle from "component/MWPageTitle";

type EHRList = {
  label: string;
  value: number;
};
function EHRDetails() {
  let history = useHistory();
  const [selectedEHR, setSelectedEHR] = useState<EHRList | null>({} as EHRList);
  const ehrList = [
    {
      label: "FHIR EPIC",
      value: 1,
    },
  ] as EHRList[];
  const selectedEHRChange = (passedValue: EHRList | null) => {
    passedValue !== null ? setSelectedEHR(passedValue) : setSelectedEHR({} as EHRList);
    passedValue !== null && passedValue?.value === 1 && connectWithFHIREPIC();
  };
  const connectWithFHIREPIC = () => {
    const payload = {
      scope: "launch/patient.read",
      responseType: "code",
      redirectURL: "https://medical-wisdom-admin-ui-int.web.app/",
      clientId: "853a224f-baa8-457f-bd01-253648dd5007",
      launch:
        "GwWCqm4CCTxJaqJiROISsEsOqN7xrdixqTl2uWZhYxMAS7QbFEbtKgi7AN96fKc2kDYfaFrLi8LQivMkD0BY-942hYgGO0_6DfewP2iwH_pe6tR_-fRfiJ2WB_-1uwG0",
      state: "abc123",
      aud: "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4",
    };
    localStorage.setItem("redirectURLfromFHIREPIC", history.location.pathname);
    window.open(
      `https://fhir.epic.com/interconnect-fhir-oauth/oauth2/authorize?scope=${payload.scope}&response_type=${payload.responseType}&redirect_uri=${payload.redirectURL}&client_id=${payload.clientId}&launch=${payload.launch}&state=${payload.state}&aud=${payload.aud}`,
      "_self",
    );
  };
  let checkFHIREPICConnection = localStorage.getItem("authCode");
  useEffect(() => {
    if (checkFHIREPICConnection !== null) {
      setSelectedEHR({
        label: "FHIR EPIC",
        value: 1,
      });
    }
  }, [checkFHIREPICConnection]);
  return (
    <Box>
      <Container maxWidth="xl">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item>
            <MWPageTitle title={`EHR Details`} backButton={true} />
          </Grid>
          <Grid item>
            <Autocomplete
              value={selectedEHR}
              id="selectedEHR"
              options={ehrList}
              onChange={(_event: any, newValue: EHRList | null) => {
                selectedEHRChange(newValue);
              }}
              fullWidth
              disableClearable={true as any}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="EHR System"
                  placeholder="Select your preferred EHR"
                  variant="standard"
                />
              )}
              getOptionLabel={(option: any) => option?.label! || ""}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" fontWeight={"bold"} sx={{ my: 2 }}>
              Connected EHR:
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              {DataList.map((item: EHRDataType) => (
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <ConnectEHRList data={item} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default EHRDetails;
