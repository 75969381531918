import {
  FIND_USER_FAIL,
  FIND_USER_LOADING,
  FIND_USER_SUCCESS,
  FIND_USER_UPDATE_API_MSG,
  FIND_USER_UPDATE_API_RES,
  FindUserActionTypes,
} from "./ActionTypes";
import { FindUserRes } from "./Model";

export const findUserLoadingAction = (
  loading: boolean,
): FindUserActionTypes => {
  return {
    type: FIND_USER_LOADING,
    loading: loading,
  };
};

export const findUserSuccessAction = (
  findUserRes: FindUserRes,
  successMsg: string,
): FindUserActionTypes => {
  return {
    type: FIND_USER_SUCCESS,
    payload: findUserRes,
    successMsg: successMsg,
  };
};

export const findUserErrorAction = (
  findUserRes: FindUserRes,
  errMsg: string,
  status: number,
): FindUserActionTypes => {
  return {
    type: FIND_USER_FAIL,
    payload: findUserRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const findUserUpdateAPIMsgAction = (
  findUserRes: FindUserRes,
  successMsg: string,
  errMsg: string,
  status: number,
): FindUserActionTypes => {
  return {
    type: FIND_USER_UPDATE_API_MSG,
    payload: findUserRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const findUserAPIResClearAction = (
  findUserRes: FindUserRes,
  successMsg: string,
  errMsg: string,
  status: number,
): FindUserActionTypes => {
  return {
    type: FIND_USER_UPDATE_API_RES,
    payload: findUserRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
