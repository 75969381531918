import { Alert, Collapse } from "@mui/material";
import { useEffect, useState } from "react";

type Props = {
  errormessage?: string;
  bannerErrorState?: boolean;
  closeBannerState?: boolean | any;
};

export default function MWErrorText({ errormessage, bannerErrorState, closeBannerState }: Props) {
  const [message, setMessage] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const closeBanner = () => {
    setOpen(false);
  };

  useEffect(() => {
    setMessage(errormessage!);
    setOpen(bannerErrorState!);
  }, [errormessage, bannerErrorState]);

  useEffect(() => {
    closeBannerState(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Collapse in={open}>
      <Alert severity="error" onClose={closeBanner}>
        {message}
      </Alert>
    </Collapse>
  );
}
