import {
  PREVIEW_CUTOFF_MARKS_FAIL,
  PREVIEW_CUTOFF_MARKS_LOADING,
  PREVIEW_CUTOFF_MARKS_SUCCESS,
  PREVIEW_CUTOFF_MARKS_API_MSG,
  PREVIEW_CUTOFF_MARKS_API_RES,
  PreviewMarksDetailsActionTypes,
} from "./ActionTypes";
import { PreviewMarksDetailsRes } from "./Model";

export const previewMarksDetailsLoadingAction = (
  loading: boolean,
): PreviewMarksDetailsActionTypes => {
  return {
    type: PREVIEW_CUTOFF_MARKS_LOADING,
    loading: loading,
  };
};

export const previewMarksDetailsSuccessAction = (
  previewCutoffMarksResponse: PreviewMarksDetailsRes,
): PreviewMarksDetailsActionTypes => {
  return {
    type: PREVIEW_CUTOFF_MARKS_SUCCESS,
    payload: previewCutoffMarksResponse,
  };
};

export const previewMarksDetailsErrorAction = (
  previewCutoffMarksResponse: PreviewMarksDetailsRes,
  errMsg: string,
  status: number,
): PreviewMarksDetailsActionTypes => {
  return {
    type: PREVIEW_CUTOFF_MARKS_FAIL,
    payload: previewCutoffMarksResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const previewMarksDetailsAPIMsgAction = (
  previewCutoffMarksResponse: PreviewMarksDetailsRes,
  errMsg: string,
  status: number,
): PreviewMarksDetailsActionTypes => {
  return {
    type: PREVIEW_CUTOFF_MARKS_API_MSG,
    payload: previewCutoffMarksResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const previewMarksDetailsAPIResClearAction = (
  previewCutoffMarksResponse: PreviewMarksDetailsRes,
  errMsg: string,
  status: number,
): PreviewMarksDetailsActionTypes => {
  return {
    type: PREVIEW_CUTOFF_MARKS_API_RES,
    payload: previewCutoffMarksResponse,
    errorMsg: errMsg,
    status: status,
  };
};
