import { EditHealthcampNameRes } from "./Model";
export const EDIT_HEALTHCAMP_NAME_LOADING = "EDIT_HEALTHCAMP_NAME_LOADING";
export const EDIT_HEALTHCAMP_NAME_SUCCESS = "EDIT_HEALTHCAMP_NAME_SUCCESS";
export const EDIT_HEALTHCAMP_NAME_FAIL = "EDIT_HEALTHCAMP_NAME_FAIL";
export const EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG = "EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG";
export const EDIT_HEALTHCAMP_NAME_UPDATE_API_RES = "EDIT_HEALTHCAMP_NAME_UPDATE_API_RES";

export interface EditHealthcampNameState {
  editHealthcampNameResponse: EditHealthcampNameRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditHealthcampNameLoading {
  type: typeof EDIT_HEALTHCAMP_NAME_LOADING;
  loading: boolean;
}
export interface EditHealthcampNameSuccess {
  type: typeof EDIT_HEALTHCAMP_NAME_SUCCESS;
  payload: EditHealthcampNameRes;
  successMsg: string;
}
export interface EditHealthcampNameFail {
  type: typeof EDIT_HEALTHCAMP_NAME_FAIL;
  payload: EditHealthcampNameRes;
  errorMsg: string;
  status: number;
}
export interface EditHealthcampNameUpdateAPIMsg {
  type: typeof EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG;
  payload: EditHealthcampNameRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditHealthcampNameUpdateAPIRes {
  type: typeof EDIT_HEALTHCAMP_NAME_UPDATE_API_RES;
  payload: EditHealthcampNameRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditHealthcampNameActionTypes =
  | EditHealthcampNameLoading
  | EditHealthcampNameSuccess
  | EditHealthcampNameFail
  | EditHealthcampNameUpdateAPIMsg
  | EditHealthcampNameUpdateAPIRes;
