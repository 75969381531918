import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import {  GetHealthCampRegistrationReqDetailsListActionTypes } from "./ActionTypes";
import { GetHealthcampRequestDetailsListBody, GetHealthcampRequestDetailsListRes } from "./Model";
import {
   getHealthCampRegistrationReqDetailsListAPIResClearAction,
   getHealthCampRegistrationReqDetailsListErrorAction,
   getHealthCampRegistrationReqDetailsListLoadingAction,
   getHealthCampRegistrationReqDetailsListSuccessAction,
   getHealthCampRegistrationReqDetailsListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetHealthcampRequestDetailsListRes;
export const  getHealthCampRegistrationRequestDetailsList = (payload: GetHealthcampRequestDetailsListBody) => {
  return function (dispatch: Dispatch< GetHealthCampRegistrationReqDetailsListActionTypes>) {
    dispatch( getHealthCampRegistrationReqDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampRegistrationDetail/registration_request_detail_list_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( getHealthCampRegistrationReqDetailsListLoadingAction(false));
        dispatch(
           getHealthCampRegistrationReqDetailsListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetHealthcampRequestDetailsListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Registration list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( getHealthCampRegistrationReqDetailsListLoadingAction(false));
        dispatch(
           getHealthCampRegistrationReqDetailsListErrorAction(
            {} as GetHealthcampRequestDetailsListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateHealthCampRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch< GetHealthCampRegistrationReqDetailsListActionTypes>) {
    dispatch(
       getHealthCampRegistrationReqDetailsListUpdateAPIMsgAction(apiRes as GetHealthcampRequestDetailsListRes, "", "", 0),
    );
  };
};

export const clearHealthCampRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch< GetHealthCampRegistrationReqDetailsListActionTypes>) {
    dispatch( getHealthCampRegistrationReqDetailsListAPIResClearAction({} as GetHealthcampRequestDetailsListRes, "", "", 0));
  };
};
