import { FC, useEffect, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
  CardActions,
  Button,
  Stack,
  Box,
} from "@mui/material";
import CardLogo from "./CardLogo";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "redux/store/Store";

interface CardTitleProps {
  id?: number;
  heading?: string;
  subHeading?: string;
  onButtonClick?: string;
  buttonText: string;
  image?: string;
}

const CustomCard: FC<CardTitleProps> = ({
  id,
  heading,
  subHeading,
  onButtonClick,
  buttonText,
  image,
}) => {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigateToDetails = (id: number, observationType: string | any) => {
    if (observationType !== undefined) {
      history.push(`/observationdetails/${patientid}/${observationType}/${String(id)}`);
    }
  };
  const getPatientDashboardLatestDataListResponse = useSelector(
    (state: AppState) => state.getPatientDashboardLatestDataListRes,
  );

  useEffect(() => {
    if (
      onButtonClick === "social" ||
      onButtonClick === "activediagnosis" ||
      onButtonClick === "ecg" ||
      onButtonClick === "livingcondition" ||
      onButtonClick === "food"
    ) {
      setButtonDisabled(true);
    }
  }, [onButtonClick]);
  return (
    <Box>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          boxShadow: 4,
        }}
      >
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <Typography gutterBottom variant="h6" sx={{ fontWeight: "bold" }}>
                  {heading}
                </Typography>
              </Grid>
            </Grid>
          }
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack direction="column" justifyContent="center" alignItems="stretch">
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <CardLogo image={image} />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {subHeading!.slice(0, 26).length === 26
                    ? subHeading!.slice(0, 26) + "..."
                    : subHeading!.slice(0, 26)}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button
                size="small"
                endIcon={<ArrowRightAltIcon />}
                disabled={getPatientDashboardLatestDataListResponse?.loading || buttonDisabled}
                onClick={() => navigateToDetails(id!, onButtonClick)}
                sx={{ textTransform: "none" }}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );
};

CustomCard.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  image: PropTypes.any,
};

export default CustomCard;
