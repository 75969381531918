import { PageCount, GetJudgingFinalRoundDetailsListDetails } from "./Model";

// Patient Demographics Loading State
export const GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING = "GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING";
export const GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS = "GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS";
export const GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL = "GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL";
export const GET_JUDGING_FINAL_ROUND_LIST_ID_DATA = "GET_JUDGING_FINAL_ROUND_LIST_ID_DATA";
export const GET_JUDGING_FINAL_ROUND_LIST_ID_API_MSG = "GET_JUDGING_FINAL_ROUND_LIST_ID_API_MSG";
export const GET_JUDGING_FINAL_ROUND_LIST_ID_API_RES = "GET_JUDGING_FINAL_ROUND_LIST_ID_API_RES";

export interface GetJudgingFinalRoundDetailsListState {
  getJudgingFinalRoundDetailsListRes: GetJudgingFinalRoundDetailsListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface GetJudgingFinalRoundDetailsListLoading {
  type: typeof GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING;
  loading: boolean;
}
export interface GetJudgingFinalRoundDetailsListSuccess {
  type: typeof GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS;
  payload: GetJudgingFinalRoundDetailsListDetails;
}
export interface GetJudgingFinalRoundDetailsListFail {
  type: typeof GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL;
  payload: GetJudgingFinalRoundDetailsListDetails;
  message: string;
  status: number;
}
export interface GetJudgingFinalRoundDetailsListAPIMsg {
  type: typeof GET_JUDGING_FINAL_ROUND_LIST_ID_API_MSG;
  payload: GetJudgingFinalRoundDetailsListDetails;
  message: string;
  status: number;
}
export interface GetJudgingFinalRoundDetailsListRes {
  type: typeof GET_JUDGING_FINAL_ROUND_LIST_ID_API_RES;
  payload: GetJudgingFinalRoundDetailsListDetails;
  message: string;
  status: number;
}
interface GetJudgingFinalRoundDetailsListDataAction {
  type: typeof GET_JUDGING_FINAL_ROUND_LIST_ID_DATA;
  payload: GetJudgingFinalRoundDetailsListDetails;
  pageCount: PageCount;
}

export type GetJudgingFinalRoundDetailsListActionTypes =
  | GetJudgingFinalRoundDetailsListDataAction
  | GetJudgingFinalRoundDetailsListLoading
  | GetJudgingFinalRoundDetailsListSuccess
  | GetJudgingFinalRoundDetailsListFail
  | GetJudgingFinalRoundDetailsListAPIMsg
  | GetJudgingFinalRoundDetailsListRes;
