import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AdminLoader from "pages/AdminLoader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import {
  clearHealthCampEditAPIRes,
  editHealthcampName,
} from "redux/healthcampList/EditHealthcampName/API";
import { EditHealthcampNameBody } from "redux/healthcampList/EditHealthcampName/Model";
import { useParams } from "react-router-dom";
import { HealthCampList } from "redux/healthcampList/Model";
import _ from "lodash";
type Props = {
  enableDialog: boolean;
  updateDialogState: (data: boolean) => void;
};
function EditHealthCamp({ enableDialog, updateDialogState }: Props) {
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const dispatch = useDispatch();
  const [errorCampOwnerId, setErrorCampOwnerId] = useState("");
  const [campOwnerProfileId, setCampOwnerProfileId] = useState("");
  const [healthcampDisplayName, setHealthcampDisplayName] = useState("");
  const handleHealthcareNameChange = useCallback((newValue: any) => {
    setHealthcampDisplayName(newValue);
  }, []);
  const [dialogState, setDialogState] = useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    updateDialogState(false);
    setErrorCampOwnerId("");
    dispatch(clearHealthCampEditAPIRes());
    setEditHealthCampNameAPIMsg("");
  };
  useEffect(() => {
    enableDialog !== undefined && setDialogState(enableDialog);
  }, [enableDialog]);

  const editHealthcampNameSubmit = () => {
    if (
      //eslint-disable-next-line
      new RegExp(/^(0|[1-9][0-9]*)$/).test(campOwnerProfileId.toString()) === false
    ) {
      setErrorCampOwnerId("Please enter valid Camp OwnerId");
    } else {
      setErrorCampOwnerId("");
      let editHealthcampBody = {
        healthcampId: healthcampid,
        displayName: healthcampDisplayName,
        ownerId: campOwnerProfileId,
      } as EditHealthcampNameBody;
      dispatch(editHealthcampName(editHealthcampBody));
    }
  };

  // call All health Camp List API store (Which are currently available for the user)
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);

  // Filer Current Health camp details from All Health Camp List API Store by match health camp id.
  const currentHealthCampDetails: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );
  // Update Health Camp details field according to store value
  useEffect(() => {
    if (currentHealthCampDetails !== undefined) {
      setHealthcampDisplayName(currentHealthCampDetails?.displayName);
      setCampOwnerProfileId(currentHealthCampDetails?.ownerProfileId);
    } else {
      setHealthcampDisplayName("");
      setCampOwnerProfileId("");
    }
  }, [currentHealthCampDetails]);

  const [editHealthCampNameAPIMsg, setEditHealthCampNameAPIMsg] = useState("");
  const [editHealthCampNameMsgType, setEditHealthCampNameMsgType] = useState<AlertColor>("success");
  // Edit health Camp Store
  const AdminEditHealthcampList = useSelector(
    (state: AppState) => state.editHealthcampNameReducerValue,
  );
  useEffect(() => {
    if (AdminEditHealthcampList?.successMsg !== "") {
      setEditHealthCampNameMsgType("success");
      setEditHealthCampNameAPIMsg(AdminEditHealthcampList?.successMsg);
      setDialogState(false);
      updateDialogState(false);
      setHealthcampDisplayName("");
      setCampOwnerProfileId("");
    }
    if (AdminEditHealthcampList?.errorMsg !== "") {
      setEditHealthCampNameMsgType("error");
      setEditHealthCampNameAPIMsg(AdminEditHealthcampList?.errorMsg);
      setDialogState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminEditHealthcampList]);
  return (
    <Dialog
      aria-labelledby="edit-health-camp-dialog"
      open={dialogState}
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
    >
      {AdminEditHealthcampList?.loading ? <AdminLoader /> : null}
      <DialogTitle id="edit-health-camp-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            <Typography variant="h6">Edit Healthcamp Name</Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <IconButton aria-label="close" onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="ProfileID"
              label="Owner Profile ID"
              placeholder="Enter Profile ID"
              value={campOwnerProfileId}
              onChange={(event) => setCampOwnerProfileId(event.target.value)}
              error={errorCampOwnerId === "" ? false : true}
              disabled={AdminEditHealthcampList?.loading}
              helperText={errorCampOwnerId === "" ? "" : errorCampOwnerId}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <TextField
              id="campNameID"
              label="Edit Healthcamp Name"
              placeholder="Please Enter Healthcamp Name"
              value={healthcampDisplayName}
              onChange={(event) => {
                handleHealthcareNameChange(event.target.value);
              }}
              disabled={AdminEditHealthcampList?.loading}
              fullWidth
              size="small"
            />
          </Grid>
          {editHealthCampNameAPIMsg !== "" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                severity={editHealthCampNameMsgType}
                onClose={() => {
                  setEditHealthCampNameAPIMsg("");
                  dispatch(clearHealthCampEditAPIRes());
                }}
              >
                {editHealthCampNameAPIMsg}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              disabled={AdminEditHealthcampList?.loading}
              onClick={() => handleDialogClose()}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={AdminEditHealthcampList?.loading}
              onClick={() => editHealthcampNameSubmit()}
              sx={{ textTransform: "none" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default EditHealthCamp;
