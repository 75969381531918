import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { GetSessionListBody, HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import {
  clearSessionListByHealthCampIdAPIRes,
  getSessionListByHealthCampId,
} from "redux/HealthcampSessionList/API";
import { getPreferenceDetails } from "redux/Preference/GetPreference/API";
import { AppState } from "redux/store/Store";
import MWErrorText from "component/MWErrorText";
import {
  clearHealthCampByOrganizationListAPIRes,
  // getHealthCampByOrganizationList,
} from "redux/getMappedHealthcampsByOrganization/ApiCall";
import { getOrganizationListValue } from "redux/OrganizationList/API";
import {
  clearInstanceListByHealthCampIdAPIRes,
  getInstanceListByHealthCampId,
} from "redux/InstanceListOfHealthcamp /API";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import {
  addPatientBySessionIdApiCall,
  clearAddPatientBySessionIdAPIRes,
} from "redux/HealthcampSessionList/AddPatientBySessionId/API";
import { AddPatientBySessionIdBody } from "redux/HealthcampSessionList/AddPatientBySessionId/Model";
import MWSnackbar from "component/MWSnackbar";
import { clearAPIRes } from "redux/PatientSearchByHealcampId/API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import { PatientOrganizationList } from "redux/OrganizationList/Model";
import { HealthCampList } from "redux/healthcampList/Model";
import {
  ModifiedHealthCampList,
  ModifiedInstanceList,
  ModifiedSessionList,
} from "pages/Preference/SetPreferenceDialog/Model";

type Props = {
  updatepatientId: number;
};

export default function AdvCheckIn({ updatepatientId }: Props) {
  const dispatch = useDispatch();
  const addPatientValueRes = useSelector((state: AppState) => state.addHealthcampPatientData);
  const setPreferenceValueRes = useSelector((state: AppState) => state.setPreferenceValue);
  const addAdvCheckIn = useSelector((state: AppState) => state.addPatientBySessionIdValues);

  const [errorMsg, setErrorMsg] = useState("");
  const [bannerState, setBannerState] = useState(false);

  const [patientId, setPatientId] = useState(0);
  const [addCheckInMsg, setAddCheckInMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const addCheckInMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setAddCheckInMsg("");
    }
  };

  const closeBanner = (passedVal: boolean) => {
    setBannerState(passedVal);
  };

  const closeSummaryBanner = () => {
    setSetPreferenceSuccess("");
  };
  const [setPreferenceSuccess, setSetPreferenceSuccess] = useState("");
  const [setPreferenceError, setSetPreferenceError] = useState("");

  const addHealthCampPatientSuccessDiv = setPreferenceSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      {setPreferenceSuccess}
    </Alert>
  ) : (
    ""
  );

  const [organizationList, setOrganizationList] = useState([] as PatientOrganizationList[]);
  const [selectedOrganization, setSelectedOrganization] = useState<PatientOrganizationList | null>(
    null,
  );
  const changeSelectedOrganization = (value: PatientOrganizationList | null) => {
    setSelectedOrganization(value);
  };
  // Call Organization List Store
  const organizationListRes = useSelector((state: AppState) => state.organizationListValue);
  useEffect(() => {
    if (organizationListRes?.getOrganizationRes?.patientOrganizationList !== undefined) {
      let orgDropdownValue = organizationListRes?.getOrganizationRes?.patientOrganizationList.map(
        (element: PatientOrganizationList) => ({
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
        }),
      );
      setOrganizationList(orgDropdownValue);
    } else setOrganizationList([] as PatientOrganizationList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationListRes]);

  const [healthCampList, setHealthCampList] = useState([] as ModifiedHealthCampList[]);
  const [selectedHealthCamp, setSelectedHealthCamp] = useState<ModifiedHealthCampList | null>(null);
  const changeSelectedHealthCamp = (passedVal: any | null) => {
    setSelectedHealthCamp(passedVal);
    if (passedVal !== null) {
      const viewHealthcampStaffListBody = {
        healthcampId: passedVal.value,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
  };
  // Call All HealthCamp List Store
  const adminAllHealthCampListRes = useSelector((state: AppState) => state.healthcampListValue);
  useEffect(() => {
    if (adminAllHealthCampListRes?.healthcampListValue?.HealthCampList !== undefined) {
      let healthCampDropdownValue =
        adminAllHealthCampListRes?.healthcampListValue?.HealthCampList.map(
          (element: HealthCampList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setHealthCampList(healthCampDropdownValue);
    } else setHealthCampList([] as ModifiedHealthCampList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAllHealthCampListRes]);

  const [instanceList, setInstanceList] = useState([] as ModifiedInstanceList[]);
  const [selectedInstance, setSelectedInstance] = useState<ModifiedInstanceList | null>(null);
  const changeSelectedInstance = (passedVal: ModifiedInstanceList | null) => {
    setSelectedInstance(passedVal);
    if (passedVal !== null) {
      const healthcampSessionListBody = {
        healthcampInstanceId: String(passedVal?.value),
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
  };
  // Call Instance List Store
  const getInstanceListRes = useSelector((state: AppState) => state.instanceListValue);
  useEffect(() => {
    if (getInstanceListRes?.getInstanceList?.healthcampInstanceList !== undefined) {
      let instanceListDropdownValue =
        getInstanceListRes?.getInstanceList?.healthcampInstanceList.map(
          (element: HealthcampInstanceList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setInstanceList(instanceListDropdownValue);
    } else setInstanceList([] as ModifiedInstanceList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstanceListRes]);

  const [sessionList, setSessionList] = useState([] as ModifiedSessionList[]);
  const [selectedSession, setSelectedSession] = useState<ModifiedSessionList | null>(null);
  const changeSelectedSession = (passedVal: ModifiedSessionList | null) => {
    setSelectedSession(passedVal);
  };
  const getSeasonListRes = useSelector((state: AppState) => state.sessionListValues);
  useEffect(() => {
    if (getSeasonListRes?.getSessionList?.healthcampSessionList !== undefined) {
      let healthCampSessionListDropdownValue =
        getSeasonListRes?.getSessionList?.healthcampSessionList.map(
          (element: HealthcampSessionList) => ({
            value: element.id,
            label: element.displayName !== "" ? element.displayName : "",
          }),
        );
      setSessionList(healthCampSessionListDropdownValue);
    } else setSessionList([] as ModifiedSessionList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeasonListRes]);

  const advCheckInSubmit = () => {
    if (selectedHealthCamp === null) {
      setErrorMsg("Please select health camp");
      setBannerState(true);
    } else if (selectedInstance === null) {
      setErrorMsg("Please select instance name");
      setBannerState(true);
    } else if (selectedSession === null) {
      setErrorMsg("Please select session");
      setBannerState(true);
    } else {
      setBannerState(false);
      dispatch(
        addPatientBySessionIdApiCall({
          healthcampSessionId: selectedSession !== null && String(selectedSession?.value),
          patientId: String(patientId),
        } as AddPatientBySessionIdBody),
      );
    }
  };

  useEffect(() => {
    setPatientId(updatepatientId);
  }, [updatepatientId]);

  useEffect(() => {
    if (addPatientValueRes?.addPatientRes?.message !== undefined) {
      setPatientId(addPatientValueRes?.addPatientRes?.id);
    }
    if (addPatientValueRes?.errorMsg) {
      setErrorMsg(addPatientValueRes?.errorMsg);
      setBannerState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  useEffect(() => {
    dispatch(getOrganizationListValue());
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (setPreferenceValueRes?.addScheduleRes?.message !== undefined) {
      setSetPreferenceSuccess(setPreferenceValueRes?.addScheduleRes?.message);
      dispatch(getPreferenceDetails());
    } else {
      setSetPreferenceError(setPreferenceValueRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPreferenceValueRes]);

  useEffect(() => {
    if (addAdvCheckIn?.successMsg !== "") {
      setAddCheckInMsg(addAdvCheckIn?.successMsg);
      dispatch(clearAPIRes());
      dispatch(clearHealthCampByOrganizationListAPIRes());
      dispatch(clearInstanceListByHealthCampIdAPIRes());
      dispatch(clearSessionListByHealthCampIdAPIRes());
    }
    if (addAdvCheckIn?.errorMsg !== "") {
      setMsgType("error");
      setAddCheckInMsg(addAdvCheckIn?.errorMsg);
      dispatch(clearAddPatientBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAdvCheckIn]);

  return (
    <Paper sx={{ p: 2, mr: 4, mb: 2 }}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item>
          <Typography fontWeight="bold" mb={1} justifyContent="center">
            Advance Check-In
          </Typography>
        </Grid>
        <Grid item>
          {organizationListRes?.loading ? <CircularProgress size="20" /> : null}
          {adminAllHealthCampListRes?.loading ? <CircularProgress size="20" /> : null}
          {getInstanceListRes?.loading ? <CircularProgress size="20" /> : null}
          {getSeasonListRes?.loading ? <CircularProgress size="20" /> : null}
          {addAdvCheckIn?.loading ? <CircularProgress size="20" /> : null}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Autocomplete
            fullWidth
            size="small"
            disabled={setPreferenceValueRes?.loading}
            value={selectedOrganization}
            options={organizationList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedOrganization(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.displayName}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.id}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Autocomplete
            fullWidth
            size="small"
            disabled={setPreferenceValueRes?.loading}
            value={selectedHealthCamp}
            options={healthCampList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedHealthCamp(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Healthcamp Name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.value}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Autocomplete
            fullWidth
            id="controllable-states-demo"
            disabled={setPreferenceValueRes?.loading}
            value={selectedInstance}
            options={instanceList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedInstance(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instance name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.value}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Autocomplete
            size="small"
            fullWidth
            disabled={setPreferenceValueRes?.loading}
            value={selectedSession}
            options={sessionList}
            onChange={(_event: any, newValue: any) => {
              changeSelectedSession(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Session name" variant="outlined" />
            )}
            getOptionLabel={(option) => `${option.label}`}
            isOptionEqualToValue={(option: any, value) => option.id === value.value}
          />
        </Grid>
      </Grid>
      <Box py={2}>
        <MWErrorText
          errormessage={errorMsg}
          bannerErrorState={bannerState}
          closeBannerState={closeBanner}
        />
        {addHealthCampPatientSuccessDiv}
      </Box>
      <Box py={2}>{setPreferenceError}</Box>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button autoFocus onClick={advCheckInSubmit} variant="outlined">
          Check-In
        </Button>
      </Grid>

      {addCheckInMsg !== "" ? (
        <MWSnackbar msg={addCheckInMsg} type={msgType} alertClose={addCheckInMsgClose} />
      ) : null}
    </Paper>
  );
}
