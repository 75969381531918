import { GET_LIST } from "../../types/ActionTypes";
import { HistoryAndIllnessResponse } from "./Model";

// Patient Demographics Loading State
export const ADD_HISTORY_AND_ILLNESS_LOADING = "ADD_HISTORY_AND_ILLNESS_LOADING";
export const ADD_HISTORY_AND_ILLNESS_SUCCESS = "ADD_HISTORY_AND_ILLNESS_SUCCESS";
export const ADD_HISTORY_AND_ILLNESS_FAIL = "ADD_HISTORY_AND_ILLNESS_FAIL";

export interface GetPatientDemographicsState {
  historyandillnessDetails: HistoryAndIllnessResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof ADD_HISTORY_AND_ILLNESS_LOADING;
}
export interface DoctorListSuccess {
  type: typeof ADD_HISTORY_AND_ILLNESS_SUCCESS;
  payload: HistoryAndIllnessResponse;
}
export interface DoctorListFail {
  type: typeof ADD_HISTORY_AND_ILLNESS_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: HistoryAndIllnessResponse;
}

export type AddHistoryAndIllnessActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
