import { AddCaseResponse } from "./Model";
export const ADD_CASE_LOADING = "ADD_CASE_LOADING";
export const ADD_CASE_SUCCESS = "ADD_CASE_SUCCESS";
export const ADD_CASE_FAIL = "ADD_CASE_FAIL";
export const ADD_CASE_DATA = "ADD_CASE_DATA";
export const ADD_CASE_API_MSG = "ADD_CASE_API_MSG";
export const ADD_CASE_API_RES = "ADD_CASE_API_RES";

export interface AddCaseState {
  addCaseRes: AddCaseResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddCaseLoading {
  type: typeof ADD_CASE_LOADING;
  loading: boolean;
}
export interface AddCaseSuccess {
  type: typeof ADD_CASE_SUCCESS;
  payload: AddCaseResponse;
  successMsg: string;
  status: number;
}
export interface AddCaseFail {
  type: typeof ADD_CASE_FAIL;
  payload: AddCaseResponse;
  errorMsg: string;
  status: number;
}
export interface AddCaseUpdateAPIMsg {
  type: typeof ADD_CASE_API_MSG;
  payload: AddCaseResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddCaseUpdateAPIRes {
  type: typeof ADD_CASE_API_RES;
  payload: AddCaseResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddCaseAction {
  type: typeof ADD_CASE_DATA;
  payload: AddCaseResponse;
}

export type AddCaseActionTypes =
  | AddCaseAction
  | AddCaseLoading
  | AddCaseSuccess
  | AddCaseFail
  | AddCaseUpdateAPIMsg
  | AddCaseUpdateAPIRes;
