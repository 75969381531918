import React, { useState } from "react";
import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import ImportFromPhone from "../../ImportFromPhone";
import ImportFromEmail from "../../ImportFromEmail";
import ImportFromWhatsApp from "../../ImportFromWhatsApp";
import ImportFromChat from "../../ImportFromChat";
import { useDispatch } from "react-redux";
import { importDataFromCall } from "redux/ImportData/ImportFromPhone/ApiCall";

export default function ImportData() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const dispatch = useDispatch();
  return (
    <Box>
      <Box sx={{ width: fullScreen ? "calc(100vw - 12vw)" : "100%" }}>
        <Tabs
          value={selected}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Import From Phone</Typography>
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  dispatch(importDataFromCall());
                }}
              />
            }
            iconPosition="end"
            value="0"
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Import From Email</Typography>
                </Grid>
              </Grid>
            }
            value="1"
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Import From WhatsApp</Typography>
                </Grid>
              </Grid>
            }
            value="2"
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Import From Chat</Typography>
                </Grid>
              </Grid>
            }
            value="3"
          />
        </Tabs>
      </Box>
      <Divider />
      {fullScreen ? (
        <Box pt={2}>
          {selected === "0" ? (
            <ImportFromPhone />
          ) : selected === "1" ? (
            <ImportFromEmail />
          ) : selected === "2" ? (
            <ImportFromWhatsApp />
          ) : (
            <ImportFromChat />
          )}
        </Box>
      ) : (
        <TabContext value={String(selected)}>
          <TabPanel value="0">
            <ImportFromPhone />
          </TabPanel>
          <TabPanel value="1">
            <ImportFromEmail />
          </TabPanel>
          <TabPanel value="2">
            <ImportFromWhatsApp />
          </TabPanel>
          <TabPanel value="3">
            <ImportFromChat />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
}
