import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetPatientCaseListByPatientIdActionTypes } from "./ActionTypes";
import { GetPatientCaseListByPatientIdBody, GetPatientCaseListByPatientIdRes } from "./Model";
import {
  getPatientCaseListByPatientIdAPIResClearAction,
  getPatientCaseListByPatientIdErrorAction,
  getPatientCaseListByPatientIdLoadingAction,
  getPatientCaseListByPatientIdSuccessAction,
  getPatientCaseListByPatientIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetPatientCaseListByPatientIdRes;
export const getPatientCaseListByPatientIdApi = (
  payload: GetPatientCaseListByPatientIdBody
) => {
  return function (dispatch: Dispatch<GetPatientCaseListByPatientIdActionTypes>) {
    dispatch(getPatientCaseListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/adminActions/patient_case_list`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientCaseListByPatientIdLoadingAction(false));
        dispatch(
          getPatientCaseListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientCaseListByPatientIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient case list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientCaseListByPatientIdLoadingAction(false));
        dispatch(
          getPatientCaseListByPatientIdErrorAction(
            {} as GetPatientCaseListByPatientIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientCaseListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientCaseListByPatientIdActionTypes>) {
    dispatch(
      getPatientCaseListByPatientIdUpdateAPIMsgAction(
        apiRes as GetPatientCaseListByPatientIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetPatientCaseListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientCaseListByPatientIdActionTypes>) {
    dispatch(
      getPatientCaseListByPatientIdAPIResClearAction({} as GetPatientCaseListByPatientIdRes, "", "", 0),
    );
  };
};
