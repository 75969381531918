import { ExecutiveSignupRequestListDetails } from "./Model";

// Patient Demographics Loading State
export const EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING = "EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING";
export const EXECUTIVE_SIGHUP_REQUEST_LIST_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL = "EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL";
export const EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG = "EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG";
export const EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES = "EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES";

export interface ExecutiveSignupRequestListState {
  executiveSignupRequestListRes: ExecutiveSignupRequestListDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ExecutiveSignupRequestListLoading {
  type: typeof EXECUTIVE_SIGHUP_REQUEST_LIST_LOADING;
  loading: boolean;
}
export interface ExecutiveSignupRequestListSuccess {
  type: typeof EXECUTIVE_SIGHUP_REQUEST_LIST_SUCCESS;
  payload: ExecutiveSignupRequestListDetails;
  successMsg: string;
}
export interface ExecutiveSignupRequestListFail {
  type: typeof EXECUTIVE_SIGHUP_REQUEST_LIST_FAIL;
  payload: ExecutiveSignupRequestListDetails;
  errorMsg: string;
  status: number;
}
export interface ExecutiveSignupRequestListAPIMsg {
  type: typeof EXECUTIVE_SIGHUP_REQUEST_LIST_API_MSG;
  payload: ExecutiveSignupRequestListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ExecutiveSignupRequestListAPIRes {
  type: typeof EXECUTIVE_SIGHUP_REQUEST_LIST_API_RES;
  payload: ExecutiveSignupRequestListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}


export type ExecutiveSignupRequestListActionTypes =
  | ExecutiveSignupRequestListLoading
  | ExecutiveSignupRequestListSuccess
  | ExecutiveSignupRequestListFail
  | ExecutiveSignupRequestListAPIMsg
  | ExecutiveSignupRequestListAPIRes;
