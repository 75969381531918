import { GET_LIST } from "../../types/ActionTypes";
import { DeletePastMedicalResponse } from "./Model";

// Patient Demographics Loading State
export const DELETE_PAST_MEDICAL_LOADING = "DELETE_PAST_MEDICAL_LOADING";
export const DELETE_PAST_MEDICAL_SUCCESS = "DELETE_PAST_MEDICAL_SUCCESS";
export const DELETE_PAST_MEDICAL_FAIL = "DELETE_PAST_MEDICAL_FAIL";

export interface GetPatientDemographicsState {
  deleteIdentify: DeletePastMedicalResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof DELETE_PAST_MEDICAL_LOADING;
}
export interface DoctorListSuccess {
  type: typeof DELETE_PAST_MEDICAL_SUCCESS;
  payload: DeletePastMedicalResponse;
}
export interface DoctorListFail {
  type: typeof DELETE_PAST_MEDICAL_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: DeletePastMedicalResponse;
}

export type DeletePastMedicalActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
