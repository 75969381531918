import { GetSubscriptionResponse } from "./Model";
export const GET_SUBSCRIPTIONLIST_BY_ID_LOADING = "GET_SUBSCRIPTIONLIST_BY_ID_LOADING";
export const GET_SUBSCRIPTIONLIST_BY_ID_SUCCESS = "GET_SUBSCRIPTIONLIST_BY_ID_SUCCESS";
export const GET_SUBSCRIPTIONLIST_BY_ID_FAIL = "GET_SUBSCRIPTIONLIST_BY_ID_FAIL";
export const GET_SUBSCRIPTIONLIST_BY_ID_DATA = "GET_SUBSCRIPTIONLIST_BY_ID_DATA";
export const GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_MSG = "GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_MSG";
export const GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_RES = "GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_RES";

export interface GetSubscriptionListByIdState {
  GetSubscriptionListByIdResponse: GetSubscriptionResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSubscriptionListByIdLoading {
  type: typeof GET_SUBSCRIPTIONLIST_BY_ID_LOADING;
  loading: boolean;
}
export interface GetSubscriptionListByIdSuccess {
  type: typeof GET_SUBSCRIPTIONLIST_BY_ID_SUCCESS;
  payload: GetSubscriptionResponse;
  successMsg: string;
}
export interface GetSubscriptionListByIdFail {
  type: typeof GET_SUBSCRIPTIONLIST_BY_ID_FAIL;
  payload: GetSubscriptionResponse;
  errorMsg: string;
  status: number;
}
export interface GetSubscriptionListByIdUpdateAPIMsg {
  type: typeof GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_MSG;
  payload: GetSubscriptionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSubscriptionListByIdUpdateAPIRes {
  type: typeof GET_SUBSCRIPTIONLIST_BY_ID_UPDATE_API_RES;
  payload: GetSubscriptionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetSubscriptionListByIdActionTypes =
  | GetSubscriptionListByIdLoading
  | GetSubscriptionListByIdSuccess
  | GetSubscriptionListByIdFail
  | GetSubscriptionListByIdUpdateAPIMsg
  | GetSubscriptionListByIdUpdateAPIRes;
