import { ExecutiveDropdownList, ExecutiveTaskList } from "./Model";

// Executive Dropdown List
export const EXECUTIVE_DROPDOWN_LIST_LOADING = "EXECUTIVE_DROPDOWN_LIST_LOADING";
export const EXECUTIVE_DROPDOWN_LIST_SUCCESS = "EXECUTIVE_DROPDOWN_LIST_SUCCESS";
export const EXECUTIVE_DROPDOWN_LIST_FAIL = "EXECUTIVE_DROPDOWN_LIST_FAIL";
export const EXECUTIVE_DROPDOWN_LIST_TYPE = "EXECUTIVE_DROPDOWN_LIST_TYPE";

export interface ExecutiveDropdownListStateType {
  loading: boolean;
  executiveList: ExecutiveDropdownList[];
  message: string;
  status: number;
}
export interface ExecutiveDropdownListLoading {
  type: typeof EXECUTIVE_DROPDOWN_LIST_LOADING;
}
export interface ExecutiveDropdownListSuccess {
  type: typeof EXECUTIVE_DROPDOWN_LIST_SUCCESS;
  payload: ExecutiveDropdownList[];
  message: string;
  status: number;
}
export interface ExecutiveDropdownListFail {
  type: typeof EXECUTIVE_DROPDOWN_LIST_FAIL;
  message: string;
  status: number;
}

interface ExecutiveDropdownListType {
  type: typeof EXECUTIVE_DROPDOWN_LIST_TYPE;
  payload: ExecutiveDropdownList[];
  message: string;
  status: number;
}

export type ExecutiveDropdownListTypes =
  | ExecutiveDropdownListType
  | ExecutiveDropdownListLoading
  | ExecutiveDropdownListSuccess
  | ExecutiveDropdownListFail;

// Executive Dropdown List
export const ASSIGN_EXECUTIVE_LOADING = "ASSIGN_EXECUTIVE_LOADING";
export const ASSIGN_EXECUTIVE_SUCCESS = "ASSIGN_EXECUTIVE_SUCCESS";
export const ASSIGN_EXECUTIVE_FAIL = "ASSIGN_EXECUTIVE_FAIL";
export const ASSIGN_EXECUTIVE_TYPE = "ASSIGN_EXECUTIVE_TYPE";

export interface AssigntaskToExecutiveStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface AssigntaskToExecutiveLoading {
  type: typeof ASSIGN_EXECUTIVE_LOADING;
}
export interface AssigntaskToExecutiveSuccess {
  type: typeof ASSIGN_EXECUTIVE_SUCCESS;
  message: string;
  status: number;
}
export interface AssigntaskToExecutiveFail {
  type: typeof ASSIGN_EXECUTIVE_FAIL;
  message: string;
  status: number;
}

interface AssigntaskToExecutiveType {
  type: typeof ASSIGN_EXECUTIVE_TYPE;
  message: string;
  status: number;
}

export type AssignTaskToExecutiveTypes =
  | AssigntaskToExecutiveType
  | AssigntaskToExecutiveLoading
  | AssigntaskToExecutiveSuccess
  | AssigntaskToExecutiveFail;
// Executive Dropdown List
export const TASK_CLOSE_LOADING = "TASK_CLOSE_LOADING";
export const TASK_CLOSE_SUCCESS = "TASK_CLOSE_SUCCESS";
export const TASK_CLOSE_FAIL = "TASK_CLOSE_FAIL";
export const TASK_CLOSE_TYPE = "TASK_CLOSE_TYPE";

export interface TaskCloseStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface TaskCloseLoading {
  type: typeof TASK_CLOSE_LOADING;
}
export interface TaskCloseSuccess {
  type: typeof TASK_CLOSE_SUCCESS;
  message: string;
  status: number;
}
export interface TaskCloseFail {
  type: typeof TASK_CLOSE_FAIL;
  message: string;
  status: number;
}

interface TaskCloseType {
  type: typeof TASK_CLOSE_TYPE;
  message: string;
  status: number;
}

export type TaskCloseTypes = TaskCloseType | TaskCloseLoading | TaskCloseSuccess | TaskCloseFail;

// Executive Dropdown List
export const EXECUTIVE_TASK_LIST_LOADING = "EXECUTIVE_TASK_LIST_LOADING";
export const EXECUTIVE_TASK_LIST_SUCCESS = "EXECUTIVE_TASK_LIST_SUCCESS";
export const EXECUTIVE_TASK_LIST_FAIL = "EXECUTIVE_TASK_LIST_FAIL";
export const EXECUTIVE_TASK_LIST_TYPE = "EXECUTIVE_TASK_LIST_TYPE";

export interface ExecutiveTaskListStateType {
  loading: boolean;
  executiveList: ExecutiveTaskList[];
}
export interface ExecutiveTaskListLoading {
  type: typeof EXECUTIVE_TASK_LIST_LOADING;
}
export interface ExecutiveTaskListSuccess {
  type: typeof EXECUTIVE_TASK_LIST_SUCCESS;
  payload: ExecutiveTaskList[];
}
export interface ExecutiveTaskListFail {
  type: typeof EXECUTIVE_TASK_LIST_FAIL;
}

interface ExecutiveTaskListType {
  type: typeof EXECUTIVE_TASK_LIST_TYPE;
  payload: ExecutiveTaskList[];
}

export type ExecutiveTaskListTypes =
  | ExecutiveTaskListType
  | ExecutiveTaskListLoading
  | ExecutiveTaskListSuccess
  | ExecutiveTaskListFail;
