import { OpinionTypeResponse } from "./Model";
export const OPINION_TYPE_LOADING =
  "OPINION_TYPE_LOADING";
export const OPINION_TYPE_SUCCESS =
  "OPINION_TYPE_SUCCESS";
export const OPINION_TYPE_FAIL =
  "OPINION_TYPE_FAIL";
export const OPINION_TYPE_DATA =
  "OPINION_TYPE_DATA";
export const OPINION_TYPE_API_MSG =
  "OPINION_TYPE_API_MSG";
export const OPINION_TYPE_API_RES =
  "OPINION_TYPE_API_RES";

export interface OpinionTypeState {
  opinionTypeRes: OpinionTypeResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OpinionTypeLoading {
  type: typeof OPINION_TYPE_LOADING;
  loading: boolean;
}
export interface OpinionTypeSuccess {
  type: typeof OPINION_TYPE_SUCCESS;
  payload: OpinionTypeResponse;
  successMsg: string;
  status: number;
}
export interface OpinionTypeFail {
  type: typeof OPINION_TYPE_FAIL;
  payload: OpinionTypeResponse;
  errorMsg: string;
  status: number;
}
export interface OpinionTypeUpdateAPIMsg {
  type: typeof OPINION_TYPE_API_MSG;
  payload: OpinionTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OpinionTypeUpdateAPIRes {
  type: typeof OPINION_TYPE_API_RES;
  payload: OpinionTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface OpinionTypeAction {
  type: typeof OPINION_TYPE_DATA;
  payload: OpinionTypeResponse;
}

export type OpinionTypeActionTypes =
  | OpinionTypeAction
  | OpinionTypeLoading
  | OpinionTypeSuccess
  | OpinionTypeFail
  | OpinionTypeUpdateAPIMsg
  | OpinionTypeUpdateAPIRes;
