import {
  OpinionTypeActionTypes,
  OPINION_TYPE_FAIL,
  OPINION_TYPE_LOADING,
  OPINION_TYPE_SUCCESS,
  OpinionTypeState,
  OPINION_TYPE_API_MSG,
  OPINION_TYPE_API_RES,
} from "./ActionTypes";
import { OpinionTypeResponse } from "./Model";

const initialStateGetPosts: OpinionTypeState = {
  loading: false,
  opinionTypeRes: {} as OpinionTypeResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getOpinionTypeListReducer = (
  state = initialStateGetPosts,
  action: OpinionTypeActionTypes
): OpinionTypeState => {
  switch (action.type) {
    case OPINION_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        opinionTypeRes: {} as OpinionTypeResponse,
      };
    case OPINION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        opinionTypeRes: action.payload,
        successMsg: action.successMsg,
        status: action.status
      };
    case OPINION_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        opinionTypeRes: {} as OpinionTypeResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case OPINION_TYPE_API_MSG:
      return {
        ...state,
        loading: false,
        opinionTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case OPINION_TYPE_API_RES:
      return {
        ...state,
        loading: false,
        opinionTypeRes: {} as OpinionTypeResponse,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
