import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DiactiveStaffOfCompetitionActionTypes } from "./ActionTypes";
import { DiactiveStaffOfCompetitionBody, DiactiveStaffOfCompetitionDetails } from "./Model";
import {
  DiactiveStaffOfCompetitionAPIResClearAction,
  DiactiveStaffOfCompetitionErrorAction,
  DiactiveStaffOfCompetitionLoadingAction,
  DiactiveStaffOfCompetitionSuccessAction,
  DiactiveStaffOfCompetitionAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as DiactiveStaffOfCompetitionDetails;
export const diactiveStaffOfCompetitionApiCall = (payload: DiactiveStaffOfCompetitionBody) => {
  return function (dispatch: Dispatch<DiactiveStaffOfCompetitionActionTypes>) {
    dispatch(DiactiveStaffOfCompetitionLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/competitionapp/api/competitionStaffList/toggle_active_staff_competition`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DiactiveStaffOfCompetitionLoadingAction(false));
        dispatch(
          DiactiveStaffOfCompetitionSuccessAction(
            res.data !== undefined ? res.data : ({} as DiactiveStaffOfCompetitionDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Competition staff list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(DiactiveStaffOfCompetitionLoadingAction(false));
        dispatch(
          DiactiveStaffOfCompetitionErrorAction(
            {} as DiactiveStaffOfCompetitionDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateCompetitionDiactiveStaffOfAPIResMsg = () => {
  return function (dispatch: Dispatch<DiactiveStaffOfCompetitionActionTypes>) {
    dispatch(DiactiveStaffOfCompetitionAPIMsgAction(apiRes as DiactiveStaffOfCompetitionDetails, "", "", 0));
  };
};

export const clearCompetitionDiactiveStaffOfAPIRes = () => {
  return function (dispatch: Dispatch<DiactiveStaffOfCompetitionActionTypes>) {
    dispatch(DiactiveStaffOfCompetitionAPIResClearAction({} as DiactiveStaffOfCompetitionDetails, "", "", 0));
  };
};
