import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetDoctorContactDetailsActionTypes } from "./ActionTypes";
import { DoctorContactDetailsBody, GetDoctorContactDetailsRes } from "./Model";
import {
  getDoctorContactDetailsAPIResClearAction,
  getDoctorContactDetailsErrorAction,
  getDoctorContactDetailsLoadingAction,
  getDoctorContactDetailsSuccessAction,
  getDoctorContactDetailsUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDoctorContactDetailsRes;
export const getDoctorContactDetails = (payload: DoctorContactDetailsBody) => {
  return function (dispatch: Dispatch<GetDoctorContactDetailsActionTypes>) {
    dispatch(getDoctorContactDetailsLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/adminapp/api/doctorContact/contactDetailsOfDoctor`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorContactDetailsLoadingAction(false));
        dispatch(
          getDoctorContactDetailsSuccessAction(
            res.data,
            "Doctor contact details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorContactDetailsLoadingAction(false));
        dispatch(
          getDoctorContactDetailsErrorAction(
            {} as GetDoctorContactDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateDoctorContactDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorContactDetailsActionTypes>) {
    dispatch(
      getDoctorContactDetailsUpdateAPIMsgAction(
        apiRes as GetDoctorContactDetailsRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDoctorContactDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorContactDetailsActionTypes>) {
    dispatch(
      getDoctorContactDetailsAPIResClearAction(
        {} as GetDoctorContactDetailsRes,
        "",
        "",
        0
      )
    );
  };
};
