import axios from "axios";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";
import {
  importDataFromCallAPIResClearAction,
  importDataFromCallErrorAction,
  importDataFromCallLoadingAction,
  importDataFromCallSuccessAction,
  importDataFromCallUpdateAPIMsgAction,
} from "./Actions";
import { Dispatch } from "react";
import { ImportDataFromCallActionTypes } from "./ActionTypes";
import { headers } from "component/Utility";
import Url from "../../../ApiConfig";
import { ImportDataFromCallRes } from "./Model";

let apiRes = {} as ImportDataFromCallRes;
export const importDataFromCall = () => {
  return function (dispatch: Dispatch<ImportDataFromCallActionTypes>) {
    dispatch(importDataFromCallLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/ehrapp/api/incomeCall/get_all_incoming_calls`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(importDataFromCallLoadingAction(false));
        dispatch(
          importDataFromCallSuccessAction(
            res.data !== undefined ? res.data : ({} as ImportDataFromCallRes),
            res.data.message !== undefined
              ? res.data.message
              : "Import data from call has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(importDataFromCallLoadingAction(false));
        dispatch(
          importDataFromCallErrorAction(
            {} as ImportDataFromCallRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateImportCallAPIResMsg = () => {
  return function (dispatch: Dispatch<ImportDataFromCallActionTypes>) {
    dispatch(importDataFromCallUpdateAPIMsgAction(apiRes as ImportDataFromCallRes, "", "", 0));
  };
};

export const clearImportCallAPIRes = () => {
  return function (dispatch: Dispatch<ImportDataFromCallActionTypes>) {
    dispatch(importDataFromCallAPIResClearAction({} as ImportDataFromCallRes, "", "", 0));
  };
};
