import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  GetDashboardHealthCampRegistrationListActionTypes } from "./ActionTypes";
import { GetDashboardHealthcampRequestListRes } from "./Model";
import {
   getDashboardHealthcampRegistrationListAPIResClearAction,
   getDashboardHealthcampRegistrationListErrorAction,
   getDashboardHealthcampRegistrationListLoadingAction,
   getDashboardHealthcampRegistrationListSuccessAction,
   getDashboardHealthcampRegistrationListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetDashboardHealthcampRequestListRes;
export const  dashboardHealthCampRegistrationRequestList = (payload:{}) => {
  return function (dispatch: Dispatch< GetDashboardHealthCampRegistrationListActionTypes>) {
    dispatch( getDashboardHealthcampRegistrationListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampRegistrationRequest/registration_request_list_from_dashboard`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( getDashboardHealthcampRegistrationListLoadingAction(false));
        dispatch(
           getDashboardHealthcampRegistrationListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetDashboardHealthcampRequestListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Registration request list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( getDashboardHealthcampRegistrationListLoadingAction(false));
        dispatch(
           getDashboardHealthcampRegistrationListErrorAction(
            {} as GetDashboardHealthcampRequestListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDashboardHealthCampRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch< GetDashboardHealthCampRegistrationListActionTypes>) {
    dispatch(
       getDashboardHealthcampRegistrationListUpdateAPIMsgAction(apiRes as GetDashboardHealthcampRequestListRes, "", "", 0),
    );
  };
};

export const clearDashboardHealthCampRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch< GetDashboardHealthCampRegistrationListActionTypes>) {
    dispatch( getDashboardHealthcampRegistrationListAPIResClearAction({} as GetDashboardHealthcampRequestListRes, "", "", 0));
  };
};
