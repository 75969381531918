import { VitalCollectionActiveInactiveStatusRes } from "./Model";
export const VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_LOADING = "VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_LOADING";
export const VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_SUCCESS = "VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_SUCCESS";
export const VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_FAIL = "VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_FAIL";
export const VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_MSG = "VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_MSG";
export const VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_RES = "VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_RES";

export interface VitalCollectionActiveInactiveStatusState {
  vitalCollectionActiveInactiveStatusRes: VitalCollectionActiveInactiveStatusRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface VitalCollectionActiveInactiveStatusLoading {
  type: typeof VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_LOADING;
  loading: boolean;
}
export interface VitalCollectionActiveInactiveStatusSuccess {
  type: typeof VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_SUCCESS;
  payload: VitalCollectionActiveInactiveStatusRes;
  successMsg: string;
}
export interface VitalCollectionActiveInactiveStatusFail {
  type: typeof VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_FAIL;
  payload: VitalCollectionActiveInactiveStatusRes;
  errorMsg: string;
  status: number;
}
export interface VitalCollectionActiveInactiveStatusUpdateAPIMsg {
  type: typeof VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_MSG;
  payload: VitalCollectionActiveInactiveStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface VitalCollectionActiveInactiveStatusUpdateAPIRes {
  type: typeof VITAL_COLLECTION_ACTIVE_INACTIVE_STATUS_UPDATE_API_RES;
  payload: VitalCollectionActiveInactiveStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type VitalCollectionActiveInactiveStatusActionTypes =
  | VitalCollectionActiveInactiveStatusLoading
  | VitalCollectionActiveInactiveStatusSuccess
  | VitalCollectionActiveInactiveStatusFail
  | VitalCollectionActiveInactiveStatusUpdateAPIMsg
  | VitalCollectionActiveInactiveStatusUpdateAPIRes;
