import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../component/MWExceptionList";
import {
  DoctorList,
  GetDoctorListByProviderOrganizationIdBody,
} from "redux/GetDoctorListByProviderOrgId/Model";
import { getDoctorListByProviderOrganizationIdApi } from "redux/GetDoctorListByProviderOrgId/API";
import AddDoctorForProviderOrg from "./AddDoctorForProviderOrg";
import AddNewDoctor from "./AddNewDoctor";

type Props = {
  doctorListCount: (value: string) => void;
  doctorListLoader?: boolean | any;
  title: string | any;
};

export default function DoctorListComponent({ doctorListLoader, doctorListCount, title }: Props) {
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getDoctorListByProviderOrganizationIdApi({
        providerorgId: organizationid,
      } as GetDoctorListByProviderOrganizationIdBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const doctorDetails = (doctorId: any) => {
    history.push(`/doctordetails/${doctorId}`);
  };
  const viewDoctorAppointment = (doctorId: any) => {
    history.push(`/doctorapoointment/${doctorId}`);
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const [showAddDoctorDialog, setShowAddDoctorDialog] = React.useState(false);
  const [addNewDoctorDialog, setAddNewDoctorDialog] = React.useState(false);
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowAddDoctorDialog(passedVal);
  };
  const updateAddDoctorDialogValFromChild = (passedVal: boolean) => {
    setAddNewDoctorDialog(passedVal);
  };
  // Api call from store.....
  const doctorList = useSelector((state: AppState) => state.doctorListByProviderOrgIdValues);
  useEffect(() => {
    doctorList?.getDoctorListByProviderOrganizationIdRes?.count !== undefined
      ? doctorListCount(String(doctorList?.getDoctorListByProviderOrganizationIdRes?.count))
      : doctorListCount("0");
    if (doctorList?.getDoctorListByProviderOrganizationIdRes?.DoctorList !== undefined) {
      let dataPointArr = doctorList?.getDoctorListByProviderOrganizationIdRes?.DoctorList.map(
        (element: DoctorList) => ({
          id: element.id,
          doctorName: element.fullName,
          appoinmentDetails: element.id,
          doctorQualification: element.qualification,
          doctorExperience: element.specialtyWithYearsOfExperience,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Doctor Details",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            doctorDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "doctorName", headerName: "Doctor Name", minWidth: 300 },
    { field: "doctorQualification", headerName: "Qualification", flex: 1 },
    { field: "doctorExperience", headerName: "Experience", minWidth: 300 },
    {
      field: "appoinmentDetails",
      headerName: "Appoinment Details",
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            viewDoctorAppointment(params.value);
          }}
          variant="outlined"
        >
          Appointment Details
        </Button>
      ),
    },
  ];

  const DoctorListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <ButtonGroup disableElevation variant="contained" aria-label="Disabled button group">
                <Button
                  variant="outlined"
                  onClick={() => setShowAddDoctorDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  Add Existing Doctor
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAddNewDoctorDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  Add New Doctor
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {doctorList?.getDoctorListByProviderOrganizationIdRes?.DoctorList &&
          doctorList?.getDoctorListByProviderOrganizationIdRes?.DoctorList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {doctorList.loading === true ? doctorListLoader(true) : doctorListLoader(false)}
      {DoctorListTable}
      {showAddDoctorDialog ? (
        <AddDoctorForProviderOrg
          filterDialogEnable={showAddDoctorDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          title={title}
        />
      ) : null}
      {addNewDoctorDialog ? (
        <AddNewDoctor
          addDoctorDialogEnable={addNewDoctorDialog}
          addDoctorDialogUpdateState={updateAddDoctorDialogValFromChild}
          title={title}
        />
      ) : null}
    </Box>
  );
}
