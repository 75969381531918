import React, { useEffect } from "react";
import { Box, Button, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AdminLoader from "pages/AdminLoader";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { sessionListByDuplicatePatientIdApi } from "redux/DuplicatePatientDetails/PatientSessionListById/API";
import { HealthcampSessionList, SessionListByDuplicatePatientIdBody } from "redux/DuplicatePatientDetails/PatientSessionListById/Model";

type Props = {
  patientDuplicateId: any;
};

export default function SessionListByPatient({ patientDuplicateId }: Props) {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      sessionListByDuplicatePatientIdApi(
        {
          patientId: patientDuplicateId,
        } as SessionListByDuplicatePatientIdBody,
        value,
      ),
    );
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  useEffect(() => {
    dispatch(
      sessionListByDuplicatePatientIdApi(
        {
          patientId: patientDuplicateId,
        } as SessionListByDuplicatePatientIdBody,
        page,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const sessionListValues = useSelector((state: AppState) => state.sessionListByDuplicatePatientResult);
  useEffect(() => {
    sessionListValues?.sessionListByDuplicatePatientIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(sessionListValues?.sessionListByDuplicatePatientIdRes?.numberOfPages))
      : setPageListCount("0");
    if (sessionListValues?.sessionListByDuplicatePatientIdRes?.healthcampSessionList !== undefined) {
      let dataPointArr = sessionListValues?.sessionListByDuplicatePatientIdRes?.healthcampSessionList.map(
        (element: HealthcampSessionList) => ({
          id: element.id,
          displayName: element.displayName,
          dateCreated: dayjs(element.dateCreated).format("DD/MM/YYYY"),
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListValues]);

  const gotoCompetitionDetails = (rowIndex: number) => {
    history.push(`/patientlistbycompetition/${rowIndex}`);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoCompetitionDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "displayName",
      headerName: "Session Name",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "dateCreated",
      headerName: "Created Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
  ];

  const organizationListTable = (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {sessionListValues?.sessionListByDuplicatePatientIdRes?.healthcampSessionList &&
        sessionListValues?.sessionListByDuplicatePatientIdRes?.healthcampSessionList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      {sessionListValues.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {organizationListTable}
        </Stack>
      </Container>
    </Box>
  );
}
