import {
  GET_OBSERVATION_LIST_BY_SESSION_ID_FAIL,
  GET_OBSERVATION_LIST_BY_SESSION_ID_LOADING,
  GET_OBSERVATION_LIST_BY_SESSION_ID_SUCCESS,
  GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_MSG,
  GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_RES,
  GetObservationListBySessionIdActionTypes,
} from "./ActionTypes";
import { GetObservationListBySessionIdRes } from "./Model";

export const getObservationListBySessionIdLoadingAction = (
  loading: boolean,
): GetObservationListBySessionIdActionTypes => {
  return {
    type: GET_OBSERVATION_LIST_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const getObservationListBySessionIdSuccessAction = (
  getObservationListBySessionIdResponse: GetObservationListBySessionIdRes,
  successMsg: string,
): GetObservationListBySessionIdActionTypes => {
  return {
    type: GET_OBSERVATION_LIST_BY_SESSION_ID_SUCCESS,
    payload: getObservationListBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const getObservationListBySessionIdErrorAction = (
  getObservationListBySessionIdResponse: GetObservationListBySessionIdRes,
  errMsg: string,
  status: number,
): GetObservationListBySessionIdActionTypes => {
  return {
    type: GET_OBSERVATION_LIST_BY_SESSION_ID_FAIL,
    payload: getObservationListBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getObservationListBySessionIdUpdateAPIMsgAction = (
  getObservationListBySessionIdResponse: GetObservationListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetObservationListBySessionIdActionTypes => {
  return {
    type: GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_MSG,
    payload: getObservationListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getObservationListBySessionIdAPIResClearAction = (
  getObservationListBySessionIdResponse: GetObservationListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetObservationListBySessionIdActionTypes => {
  return {
    type: GET_OBSERVATION_LIST_BY_SESSION_ID_UPDATE_API_RES,
    payload: getObservationListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
