import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DeletePatientDataByIdActionTypes } from "./ActionTypes";
import { DeletePatientDataByIdBody, DeletePatientDataByIdRes } from "./Model";
import {
  deletePatientDataByIdAPIResClearAction,
  deletePatientDataByIdErrorAction,
  deletePatientDataByIdLoadingAction,
  deletePatientDataByIdSuccessAction,
  deletePatientDataByIdUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as DeletePatientDataByIdRes;
export const deletePatientDataById = (payload: DeletePatientDataByIdBody) => {
  return function (dispatch: Dispatch<DeletePatientDataByIdActionTypes>) {
    dispatch(deletePatientDataByIdLoadingAction(true));
    axios
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/delete_patientDetails`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(deletePatientDataByIdLoadingAction(false));
        dispatch(
          deletePatientDataByIdSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient detail has been deleted successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deletePatientDataByIdLoadingAction(false));
        dispatch(
          deletePatientDataByIdErrorAction(
            {} as DeletePatientDataByIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDeletePatientDataByIdAPIResMsg = () => {
  return function (dispatch: Dispatch<DeletePatientDataByIdActionTypes>) {
    dispatch(
      deletePatientDataByIdUpdateAPIMsgAction(apiRes as DeletePatientDataByIdRes, "", "", 0),
    );
  };
};

export const clearDeletePatientDataByIdAPIRes = () => {
  return function (dispatch: Dispatch<DeletePatientDataByIdActionTypes>) {
    dispatch(deletePatientDataByIdAPIResClearAction({} as DeletePatientDataByIdRes, "", "", 0));
  };
};
