import { GetMandatoryPendingDetailsListBySessionIdRes } from "./Model";
export const GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING = "GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING";
export const GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS = "GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS";
export const GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL = "GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL";
export const GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG = "GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG";
export const GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES = "GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES";

export interface GetMandatoryPendingDetailsListBySessionIdState {
  getMandatoryPendingDetailsListBySessionIdRes: GetMandatoryPendingDetailsListBySessionIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetMandatoryPendingDetailsListBySessionIdLoading {
  type: typeof GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface GetMandatoryPendingDetailsListBySessionIdSuccess {
  type: typeof GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_SUCCESS;
  payload: GetMandatoryPendingDetailsListBySessionIdRes;
  successMsg: string;
}
export interface GetMandatoryPendingDetailsListBySessionIdFail {
  type: typeof GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_FAIL;
  payload: GetMandatoryPendingDetailsListBySessionIdRes;
  errorMsg: string;
  status: number;
}
export interface GetMandatoryPendingDetailsListBySessionIdUpdateAPIMsg {
  type: typeof GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_MSG;
  payload: GetMandatoryPendingDetailsListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetMandatoryPendingDetailsListBySessionIdUpdateAPIRes {
  type: typeof GET_MANDATORY_PATIENT_DETAILS_BY_SESSION_ID_UPDATE_API_RES;
  payload: GetMandatoryPendingDetailsListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetMandatoryPendingDetailsListBySessionIdActionTypes =
  | GetMandatoryPendingDetailsListBySessionIdLoading
  | GetMandatoryPendingDetailsListBySessionIdSuccess
  | GetMandatoryPendingDetailsListBySessionIdFail
  | GetMandatoryPendingDetailsListBySessionIdUpdateAPIMsg
  | GetMandatoryPendingDetailsListBySessionIdUpdateAPIRes;
