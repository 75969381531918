import { GetCompetitionListRes, PageCount } from "./Model";
export const GET_COMPETITION_LIST_LOADING = "GET_COMPETITION_LIST_LOADING";
export const GET_COMPETITION_LIST_SUCCESS = "GET_COMPETITION_LIST_SUCCESS";
export const GET_COMPETITION_LIST_FAIL = "GET_COMPETITION_LIST_FAIL";
export const GET_COMPETITION_LIST_DATA = "GET_COMPETITION_LIST_DATA";
export const GET_COMPETITION_LIST_API_MSG = "GET_COMPETITION_LIST_API_MSG";
export const GET_COMPETITION_LIST_API_RES = "GET_COMPETITION_LIST_API_RES";

export interface GetCompetitionListState {
  getCompetitionListRes: GetCompetitionListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetCompetitionListLoading {
  type: typeof GET_COMPETITION_LIST_LOADING;
  loading: boolean;
}
export interface GetCompetitionListSuccess {
  type: typeof GET_COMPETITION_LIST_SUCCESS;
  payload: GetCompetitionListRes;
}
export interface GetCompetitionListFail {
  type: typeof GET_COMPETITION_LIST_FAIL;
  payload: GetCompetitionListRes;
  errorMsg: string;
  status: number;
}
export interface GetCompetitionListAPIMsg {
  type: typeof GET_COMPETITION_LIST_API_MSG;
  payload: GetCompetitionListRes;
  errorMsg: string;
  status: number;
}
export interface GetCompetitionListAPIRes {
  type: typeof GET_COMPETITION_LIST_API_RES;
  payload: GetCompetitionListRes;
  errorMsg: string;
  status: number;
}

interface CompetitionListDataAction {
  type: typeof GET_COMPETITION_LIST_DATA;
  payload: GetCompetitionListRes;
  pageCount: PageCount;
}

export type GetCompetitionListActionTypes =
  | GetCompetitionListLoading
  | GetCompetitionListSuccess
  | GetCompetitionListFail
  | GetCompetitionListAPIMsg
  | CompetitionListDataAction
  | GetCompetitionListAPIRes;
