import {
    DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_FAIL,
    DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_LOADING,
    DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_SUCCESS,
    DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG,
    DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES,
    GetDashboardHealthCampRegistrationListActionTypes,
  } from "./ActionTypes";
  import { GetDashboardHealthcampRequestListRes } from "./Model";
  
  export const getDashboardHealthcampRegistrationListLoadingAction = (
    loading: boolean,
  ): GetDashboardHealthCampRegistrationListActionTypes => {
    return {
      type: DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getDashboardHealthcampRegistrationListSuccessAction = (
    getDashboardHealthcampRegistrationListResponse: GetDashboardHealthcampRequestListRes,
    successMsg: string,
  ): GetDashboardHealthCampRegistrationListActionTypes => {
    return {
      type: DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_SUCCESS,
      payload: getDashboardHealthcampRegistrationListResponse,
      successMsg: successMsg,
    };
  };
  
  export const getDashboardHealthcampRegistrationListErrorAction = (
    getDashboardHealthcampRegistrationListResponse: GetDashboardHealthcampRequestListRes,
    errMsg: string,
    status: number,
  ): GetDashboardHealthCampRegistrationListActionTypes => {
    return {
      type: DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_FAIL,
      payload: getDashboardHealthcampRegistrationListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getDashboardHealthcampRegistrationListUpdateAPIMsgAction = (
    getDashboardHealthcampRegistrationListResponse: GetDashboardHealthcampRequestListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetDashboardHealthCampRegistrationListActionTypes => {
    return {
      type: DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG,
      payload: getDashboardHealthcampRegistrationListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getDashboardHealthcampRegistrationListAPIResClearAction = (
    getDashboardHealthcampRegistrationListResponse: GetDashboardHealthcampRequestListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetDashboardHealthCampRegistrationListActionTypes => {
    return {
      type: DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES,
      payload: getDashboardHealthcampRegistrationListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  