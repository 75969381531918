import {
  PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_FAIL,
  PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_LOADING,
  PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_SUCCESS,
  PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_MSG,
  PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_RES,
  GetPatientDetailsListByDuplicateIdActionTypes,
} from "./ActionTypes";
import { GetPatientDetailsListByDuplicateIdRes } from "./Model";

export const getPatientDetailsListByDuplicateIdLoadingAction = (
  loading: boolean,
): GetPatientDetailsListByDuplicateIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_LOADING,
    loading: loading,
  };
};

export const getPatientDetailsListByDuplicateIdSuccessAction = (
  getPatientDetailsListByDuplicateIdResponse: GetPatientDetailsListByDuplicateIdRes,
  successMsg: string,
): GetPatientDetailsListByDuplicateIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_SUCCESS,
    payload: getPatientDetailsListByDuplicateIdResponse,
    successMsg: successMsg,
  };
};

export const getPatientDetailsListByDuplicateIdErrorAction = (
  getPatientDetailsListByDuplicateIdResponse: GetPatientDetailsListByDuplicateIdRes,
  errMsg: string,
  status: number,
): GetPatientDetailsListByDuplicateIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_FAIL,
    payload: getPatientDetailsListByDuplicateIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientDetailsListByDuplicateIdUpdateAPIMsgAction = (
  getPatientDetailsListByDuplicateIdResponse: GetPatientDetailsListByDuplicateIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientDetailsListByDuplicateIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_MSG,
    payload: getPatientDetailsListByDuplicateIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientDetailsListByDuplicateIdAPIResClearAction = (
  getPatientDetailsListByDuplicateIdResponse: GetPatientDetailsListByDuplicateIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientDetailsListByDuplicateIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_RES,
    payload: getPatientDetailsListByDuplicateIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
