import { InstanceListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const INSTANCE_LIST_BY_PATIENT_ID_LOADING = "INSTANCE_LIST_BY_PATIENT_ID_LOADING";
export const INSTANCE_LIST_BY_PATIENT_ID_SUCCESS = "INSTANCE_LIST_BY_PATIENT_ID_SUCCESS";
export const INSTANCE_LIST_BY_PATIENT_ID_FAIL = "INSTANCE_LIST_BY_PATIENT_ID_FAIL";
export const INSTANCE_LIST_BY_PATIENT_ID_API_MSG = "INSTANCE_LIST_BY_PATIENT_ID_API_MSG";
export const INSTANCE_LIST_BY_PATIENT_ID_API_RES = "INSTANCE_LIST_BY_PATIENT_ID_API_RES";

export interface InstanceListByPatientIdDetailsState {
  instanceListByPatientIdRes: InstanceListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface InstanceListByPatientIdLoading {
  type: typeof INSTANCE_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface InstanceListByPatientIdSuccess {
  type: typeof INSTANCE_LIST_BY_PATIENT_ID_SUCCESS;
  payload: InstanceListByPatientIdDetails;
  successMsg: string;
}
export interface InstanceListByPatientIdFail {
  type: typeof INSTANCE_LIST_BY_PATIENT_ID_FAIL;
  payload: InstanceListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface InstanceListByPatientIdAPIMsg {
  type: typeof INSTANCE_LIST_BY_PATIENT_ID_API_MSG;
  payload: InstanceListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof INSTANCE_LIST_BY_PATIENT_ID_API_RES;
  payload: InstanceListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type InstanceListByPatientIdActionTypes =
  | InstanceListByPatientIdLoading
  | InstanceListByPatientIdSuccess
  | InstanceListByPatientIdFail
  | InstanceListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
