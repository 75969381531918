import { GET_LIST } from "../../types/ActionTypes";
import { IdentifyResponse } from "./Model";

// Patient Demographics Loading State
export const GET_IDENTIFY_LOADING = "GET_IDENTIFY_LOADING";
export const GET_IDENTIFY_SUCCESS = "GET_IDENTIFY_SUCCESS";
export const GET_IDENTIFY_FAIL = "GET_IDENTIFY_FAIL";

export interface GetPatientDemographicsState {
  getIdentifyDetails: IdentifyResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof GET_IDENTIFY_LOADING;
}
export interface DoctorListSuccess {
  type: typeof GET_IDENTIFY_SUCCESS;
  payload: IdentifyResponse;
}
export interface DoctorListFail {
  type: typeof GET_IDENTIFY_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: IdentifyResponse;
}

export type GetIdentifyActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
