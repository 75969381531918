import {
  SESSION_LIST_BY_PATIENT_ID_FAIL,
  SESSION_LIST_BY_PATIENT_ID_LOADING,
  SESSION_LIST_BY_PATIENT_ID_SUCCESS,
  SESSION_LIST_BY_PATIENT_ID_API_MSG,
  SESSION_LIST_BY_PATIENT_ID_API_RES,
  SessionListByPatientIdActionTypes,
} from "./ActionTypes";
import { SessionListByPatientIdDetails } from "./Model";

export const SessionListByPatientIdLoadingAction = (
  loading: boolean,
): SessionListByPatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const SessionListByPatientIdSuccessAction = (
  sessionListByPatientIdResponse: SessionListByPatientIdDetails,
  successMsg: string,
): SessionListByPatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_PATIENT_ID_SUCCESS,
    payload: sessionListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const SessionListByPatientIdErrorAction = (
  sessionListByPatientIdResponse: SessionListByPatientIdDetails,
  errMsg: string,
  status: number,
): SessionListByPatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_PATIENT_ID_FAIL,
    payload: sessionListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const SessionListByPatientIdAPIMsgAction = (
  sessionListByPatientIdResponse: SessionListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): SessionListByPatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_PATIENT_ID_API_MSG,
    payload: sessionListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const SessionListByPatientIdAPIResClearAction = (
  sessionListByPatientIdResponse: SessionListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): SessionListByPatientIdActionTypes => {
  return {
    type: SESSION_LIST_BY_PATIENT_ID_API_RES,
    payload: sessionListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
