import {
  ActivatePatientActionTypes,
  ACTIVATE_PATIENT_FAIL,
  ACTIVATE_PATIENT_LOADING,
  ACTIVATE_PATIENT_SUCCESS,
  ActivatePatientState,
  ACTIVATE_PATIENT_UPDATE_API_MSG,
  ACTIVATE_PATIENT_UPDATE_API_RES,
} from "./ActionTypes";
import { ActivatePatientRes } from "./Model";

const initialStateGetPosts: ActivatePatientState = {
  loading: false,
  activatePatientRes: {} as ActivatePatientRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const activatePatientReducer = (
  state = initialStateGetPosts,
  action: ActivatePatientActionTypes,
): ActivatePatientState => {
  switch (action.type) {
    case ACTIVATE_PATIENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ACTIVATE_PATIENT_SUCCESS:
      return {
        ...state,
        activatePatientRes: action.payload,
        successMsg: action.successMsg,
      };
    case ACTIVATE_PATIENT_FAIL:
      return {
        ...state,
        activatePatientRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ACTIVATE_PATIENT_UPDATE_API_MSG:
      return {
        ...state,
        activatePatientRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ACTIVATE_PATIENT_UPDATE_API_RES:
      return {
        ...state,
        activatePatientRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
