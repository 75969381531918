import {
  ADD_PATIENT_TEAM_BY_ADMIN_FAIL,
  ADD_PATIENT_TEAM_BY_ADMIN_LOADING,
  ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS,
  ADD_PATIENT_TEAM_BY_ADMIN_API_MSG,
  ADD_PATIENT_TEAM_BY_ADMIN_API_RES,
  AddPatientTeamByAdminActionTypes,
} from "./ActionTypes";
import { AddPatientTeamByAdminRes } from "./Model";

export const addPatientTeamByAdminLoadingAction = (
  loading: boolean
): AddPatientTeamByAdminActionTypes => {
  return {
    type: ADD_PATIENT_TEAM_BY_ADMIN_LOADING,
    loading: loading,
  };
};

export const addPatientTeamByAdminSuccessAction = (
  addPatientTeamByAdminRes: AddPatientTeamByAdminRes,
  successMsg: string
): AddPatientTeamByAdminActionTypes => {
  return {
    type: ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS,
    payload: addPatientTeamByAdminRes,
    successMsg: successMsg,
  };
};

export const addPatientTeamByAdminErrorAction = (
  addPatientTeamByAdminRes: AddPatientTeamByAdminRes,
  errMsg: string,
  status: number
): AddPatientTeamByAdminActionTypes => {
  return {
    type: ADD_PATIENT_TEAM_BY_ADMIN_FAIL,
    payload: addPatientTeamByAdminRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientTeamByAdminAPIMsgAction = (
  addPatientTeamByAdminRes: AddPatientTeamByAdminRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientTeamByAdminActionTypes => {
  return {
    type: ADD_PATIENT_TEAM_BY_ADMIN_API_MSG,
    payload: addPatientTeamByAdminRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientTeamByAdminAPIResClearAction = (
  addPatientTeamByAdminRes: AddPatientTeamByAdminRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientTeamByAdminActionTypes => {
  return {
    type: ADD_PATIENT_TEAM_BY_ADMIN_API_RES,
    payload: addPatientTeamByAdminRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
