import { PageCount, AddStaffOfHealthcampDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_STAFF_OF_HEALTHCAMP_LOADING = "ADD_STAFF_OF_HEALTHCAMP_LOADING";
export const ADD_STAFF_OF_HEALTHCAMP_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_STAFF_OF_HEALTHCAMP_FAIL = "ADD_STAFF_OF_HEALTHCAMP_FAIL";
export const ADD_STAFF_OF_HEALTHCAMP_DATA = "ADD_STAFF_OF_HEALTHCAMP_DATA";
export const ADD_STAFF_OF_HEALTHCAMP_API_MSG = "ADD_STAFF_OF_HEALTHCAMP_API_MSG";
export const ADD_STAFF_OF_HEALTHCAMP_API_RES = "ADD_STAFF_OF_HEALTHCAMP_API_RES";

export interface AddStaffOfHealthcampState {
  AddStaffOfOrgRes: AddStaffOfHealthcampDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfHealthcampLoading {
  type: typeof ADD_STAFF_OF_HEALTHCAMP_LOADING;
  loading: boolean;
}
export interface AddStaffOfHealthcampSuccess {
  type: typeof ADD_STAFF_OF_HEALTHCAMP_SUCCESS;
  payload: AddStaffOfHealthcampDetails;
  successMsg: string;
}
export interface AddStaffOfHealthcampFail {
  type: typeof ADD_STAFF_OF_HEALTHCAMP_FAIL;
  payload: AddStaffOfHealthcampDetails;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfHealthcampAPIMsg {
  type: typeof ADD_STAFF_OF_HEALTHCAMP_API_MSG;
  payload: AddStaffOfHealthcampDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddStaffOfHealthcampAPIRes {
  type: typeof ADD_STAFF_OF_HEALTHCAMP_API_RES;
  payload: AddStaffOfHealthcampDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddStaffOfHealthcampDataAction {
  type: typeof ADD_STAFF_OF_HEALTHCAMP_DATA;
  payload: AddStaffOfHealthcampDetails;
  pageCount: PageCount;
}

export type AddStaffOfHealthcampActionTypes =
  | AddStaffOfHealthcampDataAction
  | AddStaffOfHealthcampLoading
  | AddStaffOfHealthcampSuccess
  | AddStaffOfHealthcampFail
  | AddStaffOfHealthcampAPIMsg
  | AddStaffOfHealthcampAPIRes;
