import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { PatientCheckInBySessionIdFromAppointmnetListActionTypes } from "./ActionTypes";
import { PatientCheckInBySessionIdFromAppointmnetListBody, PatientCheckInBySessionIdFromAppointmnetListDetails } from "./Model";
import {
  patientCheckInBySessionIdFromAppointmnetListAPIResClearAction,
  patientCheckInBySessionIdFromAppointmnetListErrorAction,
  patientCheckInBySessionIdFromAppointmnetListLoadingAction,
  patientCheckInBySessionIdFromAppointmnetListSuccessAction,
  patientCheckInBySessionIdFromAppointmnetListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as PatientCheckInBySessionIdFromAppointmnetListDetails;
export const patientCheckInBySessionIdFromAppointmnetListApiCall = (payload: PatientCheckInBySessionIdFromAppointmnetListBody) => {
  return function (dispatch: Dispatch<PatientCheckInBySessionIdFromAppointmnetListActionTypes>) {
    dispatch(patientCheckInBySessionIdFromAppointmnetListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampsessionpatient/add_patient_session_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(patientCheckInBySessionIdFromAppointmnetListLoadingAction(false));
        dispatch(
          patientCheckInBySessionIdFromAppointmnetListSuccessAction(
            res.data !== undefined ? res.data : ({} as PatientCheckInBySessionIdFromAppointmnetListDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(patientCheckInBySessionIdFromAppointmnetListLoadingAction(false));
        dispatch(
          patientCheckInBySessionIdFromAppointmnetListErrorAction(
            {} as PatientCheckInBySessionIdFromAppointmnetListDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updatePatientCheckInBySessionIdFromAppointmnetListAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientCheckInBySessionIdFromAppointmnetListActionTypes>) {
    dispatch(patientCheckInBySessionIdFromAppointmnetListAPIMsgAction(apiRes as PatientCheckInBySessionIdFromAppointmnetListDetails, "", "", 0));
  };
};

export const clearPatientCheckInBySessionIdFromAppointmnetListAPIRes = () => {
  return function (dispatch: Dispatch<PatientCheckInBySessionIdFromAppointmnetListActionTypes>) {
    dispatch(patientCheckInBySessionIdFromAppointmnetListAPIResClearAction({} as PatientCheckInBySessionIdFromAppointmnetListDetails, "", "", 0));
  };
};
