import {
  InactiveVitalCOllectionListActionTypes,
  INACTIVEVITALCOLLECTION_LIST_FAIL,
  INACTIVEVITALCOLLECTION_LIST_LOADING,
  INACTIVEVITALCOLLECTION_LIST_SUCCESS,
  InactiveVitalCOllectionListState,
  INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG,
  INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { InactiveVitalCollectionListRes } from "./Model";

const initialStateGetPosts: InactiveVitalCOllectionListState = {
  loading: false,
  inactiveVitalCollectionListRes: {} as InactiveVitalCollectionListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const inactiveVitalCollectionListReducer = (
  state = initialStateGetPosts,
  action: InactiveVitalCOllectionListActionTypes,
): InactiveVitalCOllectionListState => {
  switch (action.type) {
    case INACTIVEVITALCOLLECTION_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case INACTIVEVITALCOLLECTION_LIST_SUCCESS:
      return {
        ...state,
        inactiveVitalCollectionListRes: action.payload,
        successMsg: action.successMsg,
      };
    case INACTIVEVITALCOLLECTION_LIST_FAIL:
      return {
        ...state,
        inactiveVitalCollectionListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case INACTIVEVITALCOLLECTION_LIST_UPDATE_API_MSG:
      return {
        ...state,
        inactiveVitalCollectionListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case INACTIVEVITALCOLLECTION_LIST_UPDATE_API_RES:
      return {
        ...state,
        inactiveVitalCollectionListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
