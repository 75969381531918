import {
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES,
  DeleteAccountRequestListByTypeActionTypes,
} from "./ActionTypes";
import { DeleteAccountRequestListByTypeDetails } from "./Model";

export const deleteAccountRequestListByTypeLoadingAction = (
  loading: boolean,
): DeleteAccountRequestListByTypeActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING,
    loading: loading,
  };
};

export const deleteAccountRequestListByTypeSuccessAction = (
  deleteAccountRequestListByTypeResponse: DeleteAccountRequestListByTypeDetails,
  successMsg: string,
): DeleteAccountRequestListByTypeActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS,
    payload: deleteAccountRequestListByTypeResponse,
    successMsg: successMsg,
  };
};

export const deleteAccountRequestListByTypeErrorAction = (
  deleteAccountRequestListByTypeResponse: DeleteAccountRequestListByTypeDetails,
  errMsg: string,
  status: number,
): DeleteAccountRequestListByTypeActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL,
    payload: deleteAccountRequestListByTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deleteAccountRequestListByTypeAPIMsgAction = (
  deleteAccountRequestListByTypeResponse: DeleteAccountRequestListByTypeDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DeleteAccountRequestListByTypeActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG,
    payload: deleteAccountRequestListByTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deleteAccountRequestListByTypeAPIResClearAction = (
  deleteAccountRequestListByTypeResponse: DeleteAccountRequestListByTypeDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): DeleteAccountRequestListByTypeActionTypes => {
  return {
    type: DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES,
    payload: deleteAccountRequestListByTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
