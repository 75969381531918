import {
  IMPORT_DATA_FROM_CALL_FAIL,
  IMPORT_DATA_FROM_CALL_LOADING,
  IMPORT_DATA_FROM_CALL_SUCCESS,
  IMPORT_DATA_FROM_CALL_UPDATE_API_MSG,
  IMPORT_DATA_FROM_CALL_UPDATE_API_RES,
  ImportDataFromCallActionTypes,
} from "./ActionTypes";
import { ImportDataFromCallRes } from "./Model";

export const importDataFromCallLoadingAction = (
  loading: boolean,
): ImportDataFromCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_CALL_LOADING,
    loading: loading,
  };
};

export const importDataFromCallSuccessAction = (
  importDataFromCallRes: ImportDataFromCallRes,
  successMsg: string,
): ImportDataFromCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_CALL_SUCCESS,
    payload: importDataFromCallRes,
    successMsg: successMsg,
  };
};

export const importDataFromCallErrorAction = (
  importDataFromCallRes: ImportDataFromCallRes,
  errMsg: string,
  status: number,
): ImportDataFromCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_CALL_FAIL,
    payload: importDataFromCallRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const importDataFromCallUpdateAPIMsgAction = (
  importDataFromCallRes: ImportDataFromCallRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ImportDataFromCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_CALL_UPDATE_API_MSG,
    payload: importDataFromCallRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const importDataFromCallAPIResClearAction = (
  importDataFromCallRes: ImportDataFromCallRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ImportDataFromCallActionTypes => {
  return {
    type: IMPORT_DATA_FROM_CALL_UPDATE_API_RES,
    payload: importDataFromCallRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
