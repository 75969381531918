import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetDoctorListByProviderOrganizationIdActionTypes } from "./ActionTypes";
import { GetDoctorListByProviderOrganizationIdBody, GetDoctorListByProviderOrganizationIdRes } from "./Model";
import {
  getDoctorListByProviderOrganizationIdAPIResClearAction,
  getDoctorListByProviderOrganizationIdErrorAction,
  getDoctorListByProviderOrganizationIdLoadingAction,
  getDoctorListByProviderOrganizationIdSuccessAction,
  getDoctorListByProviderOrganizationIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetDoctorListByProviderOrganizationIdRes;
export const getDoctorListByProviderOrganizationIdApi = (
  payload: GetDoctorListByProviderOrganizationIdBody
) => {
  return function (dispatch: Dispatch<GetDoctorListByProviderOrganizationIdActionTypes>) {
    dispatch(getDoctorListByProviderOrganizationIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationDoctorList/get_doctorList_providerOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorListByProviderOrganizationIdLoadingAction(false));
        dispatch(
          getDoctorListByProviderOrganizationIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetDoctorListByProviderOrganizationIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Doctor list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDoctorListByProviderOrganizationIdLoadingAction(false));
        dispatch(
          getDoctorListByProviderOrganizationIdErrorAction(
            {} as GetDoctorListByProviderOrganizationIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetDoctorListByProviderOrganizationIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorListByProviderOrganizationIdActionTypes>) {
    dispatch(
      getDoctorListByProviderOrganizationIdUpdateAPIMsgAction(
        apiRes as GetDoctorListByProviderOrganizationIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetDoctorListByProviderOrganizationIdAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorListByProviderOrganizationIdActionTypes>) {
    dispatch(
      getDoctorListByProviderOrganizationIdAPIResClearAction({} as GetDoctorListByProviderOrganizationIdRes, "", "", 0),
    );
  };
};
