import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
  Box,
  Button,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Pagination,
  Paper,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../component/MWExceptionList";
import { addDuplicatePatientApiCall } from "redux/AddDuplicatePatient/API";
import AddDuplicatePatientDialog from "./AddDuplicatePatientDialog";
import AddOriginalPatientDialog from "./AddOriginalPatientIdDialog";
import { AddDuplicatePatientBody } from "redux/AddDuplicatePatient/Model";
import { getDuplicatePatientList } from "redux/DuplicatePatientList/API";
import { useHistory } from "react-router-dom";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function DuplicatePatientList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const getDuplicatePatientDataList = useSelector(
    (state: AppState) => state.getDuplicatePatientListValue,
  );
  const duplicatePatientDetails = useSelector((state: AppState) => state.addDuplicatePatientValue);
  useEffect(() => {
    dispatch(getDuplicatePatientList(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [originalValue, setOriginalValue] = React.useState("");
  const [duplicateValue, setDuplicateValue] = React.useState("");
  const [addoriginalPatientDialog, setAddOriginalPatientDialog] = React.useState(false);
  const [addDuplicatePatientDialog, setAddDuplicatePatientDialog] = React.useState(false);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getDuplicatePatientList(value));
  };
  const updateOriginalDialogValFromChild = (passedVal: boolean) => {
    setAddOriginalPatientDialog(passedVal);
  };
  const updateFilterDialogValFromChild = (duplicateVal: boolean) => {
    setAddDuplicatePatientDialog(duplicateVal);
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(getDuplicatePatientDataList?.loading));
    getDuplicatePatientDataList?.getDuplicatePatientListRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(getDuplicatePatientDataList?.getDuplicatePatientListRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (
      getDuplicatePatientDataList?.getDuplicatePatientListRes?.patientDuplicateList !== undefined
    ) {
      // setListCount(String(getDuplicatePatientDataList?.getUnprocessedPatientDetailsListRes?.count));
      let dataPointArr =
        getDuplicatePatientDataList?.getDuplicatePatientListRes?.patientDuplicateList.map(
          (element: any) => ({
            id: element.id,
            originalId:
              element.originalPatient.patientId !== undefined
                ? element.originalPatient.patientId
                : "",
            duplicateId:
              element.duplicatePatient.patientId !== undefined
                ? element.duplicatePatient.patientId
                : "",
            description:
              element.originalPatient.givenName + " " + element.originalPatient.familyName,
            createdDate: dayjs(element.originalPatient.createDate).format(
              "ddd MMM DD, YYYY hh:mm A",
            ),
            duplicatecreateDate: dayjs(element.duplicatePatient.createDate).format(
              "ddd MMM D, YYYY hh:mm A",
            ),
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      // setListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDuplicatePatientDataList]);

  const addPatientMap = () => {
    let mapBody = {
      originalPatientId: originalValue,
      duplicatePatientId: duplicateValue,
    } as AddDuplicatePatientBody;
    dispatch(addDuplicatePatientApiCall(mapBody));
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(duplicatePatientDetails?.loading));
    if (duplicatePatientDetails?.addDuplicatePatientRes?.message !== undefined) {
      setOriginalValue("");
      setDuplicateValue("");
      dispatch(getDuplicatePatientList(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicatePatientDetails]);

  const gotoDetails = (originalId: string, duplicateId: string) => {
    history.push(`/duplicatePatientdetailsview/${originalId}/${duplicateId}`);
  };

  const columns: GridColDef[] = [
    {
      field: "Patient Details",
      headerName: "View",
      minWidth: 140,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.originalId, params.row.duplicateId);
          }}
          sx={{ textTransform: "none" }}
        >
          Patient Details
        </Button>
      ),
    },
    {
      field: "originalId",
      headerName: "Original Patient Id",
      minWidth: 150,
      align: "left",
    },
    {
      field: "duplicateId",
      headerName: "Duplicate Patient Id",
      minWidth: 150,
      align: "left",
    },
    {
      field: "description",
      headerName: "Patient Name",
      minWidth: 200,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },

    {
      field: "createdDate",
      headerName: "Original Create Date",
      type: "string",
      minWidth: 200,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "duplicatecreateDate",
      headerName: "Duplicate Create Date",
      type: "string",
      minWidth: 200,
      align: "left",
      disableColumnMenu: true,
    },
  ];

  const searchOriginalValue = () => {
    setAddOriginalPatientDialog(true);
  };
  const searchDuplicateValue = () => {
    setAddDuplicatePatientDialog(true);
  };
  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Paper
                variant="outlined"
                component="form"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <InputBase
                  value={originalValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOriginalValue(event.target.value);
                  }}
                  onClick={() => {
                    searchOriginalValue();
                  }}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Original Id"
                  inputProps={{ "aria-label": "search patient" }}
                />
                |
                <InputBase
                  value={duplicateValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDuplicateValue(event.target.value);
                  }}
                  onClick={() => {
                    searchDuplicateValue();
                  }}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Duplicate Id"
                  inputProps={{ "aria-label": "search patient" }}
                />
                <Button
                  sx={{ borderRadius: 0 }}
                  onClick={() => {
                    addPatientMap();
                  }}
                >
                  Map
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                pt={2}
              >
                {!fullScreen && (
                  <Grid item>
                    <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                  </Grid>
                )}
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {patientPointDataTable}
        {addDuplicatePatientDialog ? (
          <AddDuplicatePatientDialog
            addDuplicateDialogEnable={addDuplicatePatientDialog}
            addDuplicateDialogUpdateState={updateFilterDialogValFromChild}
            duplicateValue={setDuplicateValue}
          />
        ) : null}
        {addoriginalPatientDialog ? (
          <AddOriginalPatientDialog
            addOriginalDialogEnable={addoriginalPatientDialog}
            addOriginalDialogUpdateState={updateOriginalDialogValFromChild}
            originalValue={setOriginalValue}
          />
        ) : null}
      </Stack>
    </Box>
  );
}
