import {
  AddStaffOfHealthcampActionTypes,
  ADD_STAFF_OF_HEALTHCAMP_FAIL,
  ADD_STAFF_OF_HEALTHCAMP_LOADING,
  ADD_STAFF_OF_HEALTHCAMP_SUCCESS,
  AddStaffOfHealthcampState,
  ADD_STAFF_OF_HEALTHCAMP_API_MSG,
  ADD_STAFF_OF_HEALTHCAMP_API_RES,
} from "./ActionTypes";
import { AddStaffOfHealthcampDetails } from "./Model";

const initialStateGetPosts: AddStaffOfHealthcampState = {
  loading: false,
  AddStaffOfOrgRes: {} as AddStaffOfHealthcampDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addStaffOfHealthcampReducer = (
  state = initialStateGetPosts,
  action: AddStaffOfHealthcampActionTypes,
): AddStaffOfHealthcampState => {
  switch (action.type) {
    case ADD_STAFF_OF_HEALTHCAMP_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_STAFF_OF_HEALTHCAMP_SUCCESS:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_STAFF_OF_HEALTHCAMP_FAIL:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_STAFF_OF_HEALTHCAMP_API_MSG:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_STAFF_OF_HEALTHCAMP_API_RES:
      return {
        ...state,
        AddStaffOfOrgRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
