import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { DiactiveStaffOfProviderOrgActionTypes } from "./ActionTypes";
import { DiactiveStaffOfProviderOrgBody, DiactiveStaffOfProviderOrgDetails } from "./Model";
import {
  DiactiveStaffOfProviderOrgAPIResClearAction,
  DiactiveStaffOfProviderOrgErrorAction,
  DiactiveStaffOfProviderOrgLoadingAction,
  DiactiveStaffOfProviderOrgSuccessAction,
  DiactiveStaffOfProviderOrgAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as DiactiveStaffOfProviderOrgDetails;
export const toggleProviderOrgStaffOfOrgApiCall = (payload: DiactiveStaffOfProviderOrgBody) => {
  return function (dispatch: Dispatch<DiactiveStaffOfProviderOrgActionTypes>) {
    dispatch(DiactiveStaffOfProviderOrgLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/providerorganizationapp/api/providerOrganizationStaffAdmin/toggle_active_staff_providerorganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DiactiveStaffOfProviderOrgLoadingAction(false));
        dispatch(
          DiactiveStaffOfProviderOrgSuccessAction(
            res.data !== undefined ? res.data : ({} as DiactiveStaffOfProviderOrgDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(DiactiveStaffOfProviderOrgLoadingAction(false));
        dispatch(
          DiactiveStaffOfProviderOrgErrorAction(
            {} as DiactiveStaffOfProviderOrgDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateToggleStaffOfProviderOrgAPIResMsg = () => {
  return function (dispatch: Dispatch<DiactiveStaffOfProviderOrgActionTypes>) {
    dispatch(DiactiveStaffOfProviderOrgAPIMsgAction(apiRes as DiactiveStaffOfProviderOrgDetails, "", "", 0));
  };
};

export const clearToggleStaffOfProviderOrgAPIRes = () => {
  return function (dispatch: Dispatch<DiactiveStaffOfProviderOrgActionTypes>) {
    dispatch(DiactiveStaffOfProviderOrgAPIResClearAction({} as DiactiveStaffOfProviderOrgDetails, "", "", 0));
  };
};
