import { Typography } from "@mui/material";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { useHistory } from "react-router-dom";

export function DoctorListInHealthCampTableColumn() {
  const ShowName: React.FC<GridRenderCellParams> = (params: GridCellParams): JSX.Element => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();
    return (
      <Typography
        onClick={() => {
          history.push(`/doctordetails/${params.row.id}`);
        }}
        sx={{ color: "#30a289", "&:hover": { color: "#134872", cursor: "pointer" } }}
      >
        <b>{params.row.name}</b>
      </Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      hideable: false,
      minWidth: 180,
      flex: 1,
      align: "left",
      renderCell: (params: GridCellParams) => <ShowName api={undefined} {...params} />,
    },
    {
      field: "qualification",
      headerName: "Qualification",
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: "left",
      flex: 1,
    },
    {
      field: "specialty",
      headerName: "Specialty",
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: "left",
      flex: 1,
    },
  ];
  return columns;
}
