import { RequestAppointmentDataRes } from "./Model";
export const REQUEST_APPOINTMENT_LOADING = "REQUEST_APPOINTMENT_LOADING";
export const REQUEST_APPOINTMENT_SUCCESS = "REQUEST_APPOINTMENT_SUCCESS";
export const REQUEST_APPOINTMENT_FAIL = "REQUEST_APPOINTMENT_FAIL";
export const REQUEST_APPOINTMENT_API_MSG = "REQUEST_APPOINTMENT_API_MSG";
export const REQUEST_APPOINTMENT_API_RES = "REQUEST_APPOINTMENT_API_RES";

export interface RequestAppointmentDataState {
  requestAppointmentDataRes: RequestAppointmentDataRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RequestAppointmentDataLoading {
  type: typeof REQUEST_APPOINTMENT_LOADING;
  loading: boolean;
}
export interface RequestAppointmentDataSuccess {
  type: typeof REQUEST_APPOINTMENT_SUCCESS;
  payload: RequestAppointmentDataRes;
  successMsg: string;
}
export interface RequestAppointmentDataFail {
  type: typeof REQUEST_APPOINTMENT_FAIL;
  payload: RequestAppointmentDataRes;
  errorMsg: string;
  status: number;
}
export interface RequestAppointmentDataAPIMsg {
  type: typeof REQUEST_APPOINTMENT_API_MSG;
  payload: RequestAppointmentDataRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RequestAppointmentDataAPIRes {
  type: typeof REQUEST_APPOINTMENT_API_RES;
  payload: RequestAppointmentDataRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type RequestAppointmentActionTypes =
  | RequestAppointmentDataLoading
  | RequestAppointmentDataSuccess
  | RequestAppointmentDataFail
  | RequestAppointmentDataAPIMsg
  | RequestAppointmentDataAPIRes;
