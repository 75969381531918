import { GetHealthCampByOrganizationRes } from "./Model";
export const GET_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING =
  "GET_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING";
export const GET_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS =
  "GET_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS";
export const GET_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL = "GET_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL";
export const GET_HEALTHCAMP_BY_ORGANIZATION_LIST_DATA = "GET_HEALTHCAMP_BY_ORGANIZATION_LIST_DATA";
export const GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG =
  "GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG";
export const GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES =
  "GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES";

export interface GetHealthCampByOrganizationListState {
  getHealthCampByOrganizationListRes: GetHealthCampByOrganizationRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampByOrganizationListLoading {
  type: typeof GET_HEALTHCAMP_BY_ORGANIZATION_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthCampByOrganizationListSuccess {
  type: typeof GET_HEALTHCAMP_BY_ORGANIZATION_LIST_SUCCESS;
  payload: GetHealthCampByOrganizationRes;
  successMsg: string;
}
export interface GetHealthCampByOrganizationListFail {
  type: typeof GET_HEALTHCAMP_BY_ORGANIZATION_LIST_FAIL;
  payload: GetHealthCampByOrganizationRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampByOrganizationListUpdateAPIMsg {
  type: typeof GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_MSG;
  payload: GetHealthCampByOrganizationRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampByOrganizationListUpdateAPIRes {
  type: typeof GET_HEALTHCAMP_BY_ORGANIZATION_LIST_UPDATE_API_RES;
  payload: GetHealthCampByOrganizationRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthCampByOrganizationListActionTypes =
  | GetHealthCampByOrganizationListLoading
  | GetHealthCampByOrganizationListSuccess
  | GetHealthCampByOrganizationListFail
  | GetHealthCampByOrganizationListUpdateAPIMsg
  | GetHealthCampByOrganizationListUpdateAPIRes;
