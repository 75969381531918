import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import EditIcon from "@mui/icons-material/Edit";
import {
  addNotesByDr,
  changeNotesForDoctorStatus,
  getDoctorNoteListForDoctor,
} from "../../redux/Notes/ApiCall";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  Snackbar,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
type Props = {
  noteListLoader?: boolean | any;
};

export default function ActiveNotes({ noteListLoader }: Props) {
  const dispatch = useDispatch();

  const [activeNoteListValue, setActiveNoteListValue] = useState([] as any[]);
  const activeNotesList: GridColDef[] = [
    {
      field: "note",
      headerName: "Note",
      width: 150,
    },
    {
      field: "createdDate",
      headerName: "Create Date",
      width: 120,
      flex: 1,
    },
    { field: "createBy", headerName: "Create By", width: 180,flex: 1},
    {
      field: "modifyBy",
      headerName: "Modify By",
      width: 180,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 180,
    },
    {
      field: "id",
      headerName: "Request Status",
      sortable: true,
      hideable: false,
      minWidth: 180,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];
  const doctorId = localStorage.getItem("doctorID");

  const [notes, setNotes] = useState("");
  const handleNotesChange = useCallback((event: any) => {
    setNotes(event.target.value);
  }, []);

  const [notesError, setNotesError] = useState("");
  const notesErrorDiv = notesError ? <Typography>{notesError}</Typography> : "";

  const closeNotesSubmitSuccess = () => {
    setNotesSubmitSuccess("");
  };

  const [notesSubmitSuccess, setNotesSubmitSuccess] = useState("");
  const notesSubmitSuccessDiv = notesSubmitSuccess ? (
    <Alert onClick={closeNotesSubmitSuccess} security="success">
      <Typography>{notesSubmitSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const closeNotesSubmitError = () => {
    setNotesSubmitError("");
  };

  const [notesSubmitError, setNotesSubmitError] = useState("");
  const notesSubmitErrorDiv = notesSubmitError ? (
    <Alert onClick={closeNotesSubmitError} security="error">
      <Typography>{notesSubmitError}</Typography>
    </Alert>
  ) : (
    ""
  );
  // Call Note List for Dr Store
  const activeNoteListFromAPIStoreValue = useSelector((state: AppState) => state.notesForDrvalues);

  const [notesModalActive, setNotesModalActive] = useState(false);
  const handleNotesModalToggle = () => {
    setNotesModalActive((notesModalActive) => !notesModalActive);
    setNotesError("");
    setNotesSubmitSuccess("");
    setNotesSubmitError("");
  };

  // Call Add Dr Notes Store
  const AddDrNoteLoadingvalue = useSelector((state: AppState) => state.addNotesForDrValue.loading);
  const AddChiefComplaintStatus = useSelector((state: AppState) => state.addNotesForDrValue.status);

  // Add Dr Notes success toast
  const [successAddNotesForDoctor, setSuccessAddNotesForDoctor] = useState(false);
  const toggleAddNotesForDrSuccessToastActive = () => {
    setSuccessAddNotesForDoctor((successAddNotesForDoctor) => !successAddNotesForDoctor);
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
  };

  const toastSuccessAddNotesForDoctorMarkup = successAddNotesForDoctor ? (
    <Snackbar
      open={successAddNotesForDoctor}
      autoHideDuration={6000}
      onClose={toggleAddNotesForDrSuccessToastActive}
    >
      <Alert
        severity="success"
        onClose={toggleAddNotesForDrSuccessToastActive}
        sx={{ width: "100%" }}
      >
        Note added successfully
      </Alert>
    </Snackbar>
  ) : null;

  // Add Dr Notes error toast
  const [errorAddNotesForDoctorToastActive, setErrorAddNotesForDoctorToastActive] = useState(false);
  const toggleAddNotesForDrErrorToastActive = () => {
    setErrorAddNotesForDoctorToastActive(
      (errorAddNotesForDoctorToastActive) => !errorAddNotesForDoctorToastActive,
    );
  };
  const toastErrorAddNotesForDoctorMarkup = errorAddNotesForDoctorToastActive ? (
    <Snackbar
      open={errorAddNotesForDoctorToastActive}
      autoHideDuration={6000}
      onClose={toggleAddNotesForDrErrorToastActive}
    >
      <Alert severity="error" onClose={toggleAddNotesForDrErrorToastActive} sx={{ width: "100%" }}>
        Something went wrong
      </Alert>
    </Snackbar>
  ) : null;

  // call Change Dr. Notes Status Store
  const changeDrNoteStatusLoadingvalue = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.loading,
  );
  const changeDrActiveNoteStatusStatusvalue = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue
  );
  const changeDrNoteStatusMessage = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.message,
  );
 const  changeDrNoteStatusStatus = changeDrActiveNoteStatusStatusvalue.status
  // Change Dr notes status Success toast
  const [successChangeDrNoteStatusToastActive, setSuccessChangeDrNoteStatusToastActive] =
    useState(false);
  const toggleSuccessChangeDrNoteStatusActive = () => {
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) => !successChangeDrNoteStatusToastActive,
    );
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
  };

  const toastChangeDrNoteStatusSuccessMarkup = successChangeDrNoteStatusToastActive ? (
    <Snackbar
      open={successChangeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessChangeDrNoteStatusActive}
    >
      <Alert
        severity="success"
        onClose={toggleSuccessChangeDrNoteStatusActive}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Change Dr notes status Error toast
  const [changeDrNoteStatusToastActive, setErrorChangeDrNoteStatusToastActive] = useState(false);
  const handleErrorChangeDrNoteStatusToastChange = () => {
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive,
    );
  };
  const toastChangeDrNoteStatusErrorMarkup = changeDrNoteStatusToastActive ? (
    <Snackbar
      open={changeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={handleErrorChangeDrNoteStatusToastChange}
    >
      <Alert
        severity="success"
        onClose={handleErrorChangeDrNoteStatusToastChange}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Get Doctor Note List Body
  const getDrNoteListBody = {
    doctorId: doctorId,
    isActive: true,
  };

  // Add Dr Note Body
  const addNoteBody = {
    doctorId: doctorId,
    note: notes,
  };
  // Add notes for Doctor
  const addNotes = () => {
    if (notes === "") {
      setNotesError("Please add notes");
    } else {
      setNotesSubmitError("");
      dispatch(addNotesByDr(addNoteBody));
      setNotesModalActive((notesModalActive) => !notesModalActive);
      setSuccessAddNotesForDoctor((successAddNotesForDoctor) => !successAddNotesForDoctor);
      setErrorAddNotesForDoctorToastActive(
        (errorAddNotesForDoctorToastActive) => !errorAddNotesForDoctorToastActive,
      );
    }
  };

  // Notes Status change API
  function toggleNoteStatus(response: any) {
    dispatch(changeNotesForDoctorStatus(response));
  }

  useEffect(() => {
    if(changeDrNoteStatusStatus !== undefined){
      setSuccessChangeDrNoteStatusToastActive(
        (successChangeDrNoteStatusToastActive) => !successChangeDrNoteStatusToastActive,
      );
      dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
    }
    else{
      setErrorChangeDrNoteStatusToastActive(
        (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDrNoteStatusStatus]);

  useEffect(() => {
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Configure Dr note list table

  useEffect(() => {
    noteListLoader(activeNoteListFromAPIStoreValue?.loading);
    if (activeNoteListFromAPIStoreValue?.noteList !== undefined) {
      let regRequestList = activeNoteListFromAPIStoreValue?.noteList.map((element: any) => ({
        id: element.id,
        note: element?.note !== "" ? element?.note : "",
        createdDate: dayjs(element.createdDate).format("ddd, MMM D, YYYY"),
        createBy:
          element.createdBy === null
            ? ""
            : element.createdBy.user.first_name + " " + element.createdBy.user.last_name,
        modifyBy:
          element.modifiedBy === null
            ? ""
            : element.modifiedBy.user.first_name + " " + element.modifiedBy.user.last_name,
        modifiedDate: dayjs(element.modifiedDate).format("ddd, MMM D, YYYY"),
      }));
      setActiveNoteListValue(regRequestList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNoteListFromAPIStoreValue]);

  // Active Notes List
  const getInActiveNoteList = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6"> Active Notes List</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNotesModalToggle}>
                Add Note
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {activeNoteListValue && activeNoteListValue.length ? (
          <DataGrid
            rows={activeNoteListValue}
            columns={activeNotesList}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  //Dr. Notes Modal
  const drNotesModalMarkup = (
    <Dialog open={notesModalActive} onClose={handleNotesModalToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Add Note</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleNotesModalToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              value={notes}
              label="Doctor Notes"
              placeholder="Please provide notes"
              id="notesID"
              onChange={handleNotesChange}
              fullWidth
            />
            <Typography sx={{ color: "#c62828" }}>{notesErrorDiv}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Box pt={1}>{notesSubmitSuccessDiv}</Box>
      <Box pt={1}>{notesSubmitErrorDiv}</Box>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleNotesModalToggle}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={addNotes}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {activeNoteListFromAPIStoreValue.loading === true ||
      AddDrNoteLoadingvalue === true ||
      changeDrNoteStatusLoadingvalue === true
      ? noteListLoader(true)
      : noteListLoader(false)}
      {getInActiveNoteList}
      {drNotesModalMarkup}
      {AddChiefComplaintStatus! === 1 ? toastSuccessAddNotesForDoctorMarkup : ""}
      {AddChiefComplaintStatus! === 0 ? toastErrorAddNotesForDoctorMarkup : ""}
      {changeDrNoteStatusStatus! === 1 ? toastChangeDrNoteStatusSuccessMarkup : ""}
      {changeDrNoteStatusStatus! === 0 ? toastChangeDrNoteStatusErrorMarkup : ""}
    </Box>
  );
}
