import { GET_LIST } from "../../types/ActionTypes";
import { AddInsuranceResponse } from "./Model";

// Patient Demographics Loading State
export const ADD_INSURANCE_LOADING = "ADD_INSURANCE_LOADING";
export const ADD_INSURANCE_SUCCESS = "ADD_INSURANCE_SUCCESS";
export const ADD_INSURANCE_FAIL = "ADD_INSURANCE_FAIL";

export interface GetPatientDemographicsState {
  addInsuranceDetails: AddInsuranceResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof ADD_INSURANCE_LOADING;
}
export interface DoctorListSuccess {
  type: typeof ADD_INSURANCE_SUCCESS;
  payload: AddInsuranceResponse;
}
export interface DoctorListFail {
  type: typeof ADD_INSURANCE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: AddInsuranceResponse;
}

export type AddInsuranceActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
