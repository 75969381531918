import {
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_FAIL,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_LOADING,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_SUCCESS,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_MSG,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_RES,
    ToggleActiveRegistrationRequestActionTypes,
  } from "./ActionTypes";
  import { ToggleActiveRegReqRes } from "./Model";
  
  export const toggleActiveRegistrationRequestLoadingAction = (
    loading: boolean,
  ): ToggleActiveRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_LOADING,
      loading: loading,
    };
  };
  
  export const toggleActiveRegistrationRequestSuccessAction = (
    toggleActiveRegistrationRequestresponse: ToggleActiveRegReqRes,
    successMsg: string,
  ): ToggleActiveRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_SUCCESS,
      payload: toggleActiveRegistrationRequestresponse,
      successMsg: successMsg,
    };
  };
  
  export const toggleActiveRegistrationRequestErrorAction = (
    toggleActiveRegistrationRequestresponse: ToggleActiveRegReqRes,
    errMsg: string,
    status: number,
  ): ToggleActiveRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_FAIL,
      payload: toggleActiveRegistrationRequestresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const toggleActiveRegistrationRequestUpdateAPIMsgAction = (
    toggleActiveRegistrationRequestresponse: ToggleActiveRegReqRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): ToggleActiveRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_MSG,
      payload: toggleActiveRegistrationRequestresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const toggleActiveRegistrationRequestAPIResClearAction = (
    toggleActiveRegistrationRequestresponse: ToggleActiveRegReqRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): ToggleActiveRegistrationRequestActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_UPDATE_API_RES,
      payload: toggleActiveRegistrationRequestresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  