import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import {  AddAppoinmentListToHealthcampActionTypes } from "./ActionTypes";
import { AddAppointmentListHealthcampBody, AddAppointmentListHealthcampRes } from "./Model";
import {
   addAppoinmentListToHealthcampAPIResClearAction,
   addAppoinmentListToHealthcampErrorAction,
   addAppoinmentListToHealthcampLoadingAction,
   addAppoinmentListToHealthcampSuccessAction,
   addAppoinmentListToHealthcampUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as AddAppointmentListHealthcampRes;
export const  addAppointmentToHealthcampInstance = (payload:AddAppointmentListHealthcampBody) => {
  return function (dispatch: Dispatch< AddAppoinmentListToHealthcampActionTypes>) {
    dispatch( addAppoinmentListToHealthcampLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthCampInstancePatientAppointment/add_patient_appointment_to_instance`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( addAppoinmentListToHealthcampLoadingAction(false));
        dispatch(
           addAppoinmentListToHealthcampSuccessAction(
            res.data !== undefined ? res.data : ({} as AddAppointmentListHealthcampRes),
            res.data.message !== undefined
              ? res.data.message
              : "Appoinment List of Healthcamp fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( addAppoinmentListToHealthcampLoadingAction(false));
        dispatch(
           addAppoinmentListToHealthcampErrorAction(
            {} as AddAppointmentListHealthcampRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAppointmentListOfHealthcampAPIResMsg = () => {
  return function (dispatch: Dispatch< AddAppoinmentListToHealthcampActionTypes>) {
    dispatch(
       addAppoinmentListToHealthcampUpdateAPIMsgAction(apiRes as AddAppointmentListHealthcampRes, "", "", 0),
    );
  };
};

export const clearAppointmentListOfHealthcampAPIRes = () => {
  return function (dispatch: Dispatch< AddAppoinmentListToHealthcampActionTypes>) {
    dispatch( addAppoinmentListToHealthcampAPIResClearAction({} as AddAppointmentListHealthcampRes, "", "", 0));
  };
};
