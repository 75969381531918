import { HealthcampListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING = "HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING";
export const HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS = "HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS";
export const HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL = "HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL";
export const HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG = "HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG";
export const HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES = "HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES";

export interface HealthcampListByPatientIdDetailsState {
  healthcampListByPatientIdRes: HealthcampListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface HealthcampListByPatientIdLoading {
  type: typeof HEALTHCAMP_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface HealthcampListByPatientIdSuccess {
  type: typeof HEALTHCAMP_LIST_BY_PATIENT_ID_SUCCESS;
  payload: HealthcampListByPatientIdDetails;
  successMsg: string;
}
export interface HealthcampListByPatientIdFail {
  type: typeof HEALTHCAMP_LIST_BY_PATIENT_ID_FAIL;
  payload: HealthcampListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface HealthcampListByPatientIdAPIMsg {
  type: typeof HEALTHCAMP_LIST_BY_PATIENT_ID_API_MSG;
  payload: HealthcampListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof HEALTHCAMP_LIST_BY_PATIENT_ID_API_RES;
  payload: HealthcampListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type HealthcampListByPatientIdActionTypes =
  | HealthcampListByPatientIdLoading
  | HealthcampListByPatientIdSuccess
  | HealthcampListByPatientIdFail
  | HealthcampListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
