import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MWExceptionList from "../../../../../component/MWExceptionList";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { PatientDetailsList } from "redux/JudgingSecondRoundDetailsList/Model";
import { GetJudgingFinalRoundDetailsListBody } from "redux/FinalRoundDetailsList/Model";
import { getJudgingFinalRoundDetailsList } from "redux/FinalRoundDetailsList/API";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React from "react";
import { AddCutoffMarksDetailsBody } from "redux/AddNextRoundByCutoffMarks/Model";
import { addCutoffMarksApi } from "redux/AddNextRoundByCutoffMarks/API";

type Props = {
  rankNumber?: string | any;
};

export default function PreviewMarksList({ rankNumber }: Props) {
  const dispatch = useDispatch();
  const patientPointDataList = useSelector((state: AppState) => state.previewCutoffMarksValue);
  const patientDetailsValue = patientPointDataList?.previewCutoffMarksRes?.patientDetailsList;
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [previewCutoffCount, setPreviewCutoffCount] = React.useState("");

  useEffect(() => {
    if (patientPointDataList?.previewCutoffMarksRes?.count !== undefined) {
      setPreviewCutoffCount(String(patientPointDataList?.previewCutoffMarksRes?.count));
    } else setPreviewCutoffCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const finalRoundListBody = {
      competitionId: competitionid,
    } as GetJudgingFinalRoundDetailsListBody;
    dispatch(getJudgingFinalRoundDetailsList(finalRoundListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (patientPointDataList?.previewCutoffMarksRes?.patientDetailsList !== undefined) {
      let dataPointArr = patientDetailsValue.map((element: PatientDetailsList) => ({
        id: element.id,
        description: element.description,
        startDate:
          element.startDate !== null ? dayjs(element.startDate).format("ddd, MMM D, YYYY") : "",
        patientDocument: element.patientDocument,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Patient Details Id",
      minWidth: 150,
      renderCell: (params) => <Link to={`/judgingview/${params.value}`}>{params.value}</Link>,
    },
    { field: "description", headerName: "Description", width: 500, flex: 1 },
    { field: "startDate", headerName: "Start Date", width: 200 },
  ];

  const addNextRoundByCutoffMarks = () => {
    let nextRoundBody = {
      competitionId: competitionid,
      cutOff: rankNumber,
    } as AddCutoffMarksDetailsBody;
    dispatch(addCutoffMarksApi(nextRoundBody));
  };

  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h5">Preview Cutoff Marks list</Typography>
            </Grid>
            <Grid item>
              <Chip label={String(previewCutoffCount)} variant="outlined" />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" item>
              <Button
                autoFocus
                disabled={
                  patientPointDataList?.previewCutoffMarksRes?.patientDetailsList !== undefined
                    ? false
                    : true
                }
                onClick={addNextRoundByCutoffMarks}
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                style={{ fontSize: "15px" }}
              >
                Add Next Round By Cutoff Mark
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {patientDetailsValue && patientDetailsValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
