import React, { useState } from "react";
import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import MWPageTitle from "component/MWPageTitle";
import PageLayout from "pages/Layout/PageLayout";
import ImportData from "./ImportData";
import FixedData from "./FixedData";

export default function DataManagement() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selected, setSelected] = useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  return (
    <PageLayout>
      <MWPageTitle title={"Data Management"} />
      <Box>
        <Box sx={{ width: fullScreen ? "calc(100vw - 20vw)" : "100%" }}>
          <Tabs
            value={selected}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Admin Task List</Typography>
                  </Grid>
                </Grid>
              }
              value="0"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Last Modified Patient List</Typography>
                  </Grid>
                </Grid>
              }
              value="1"
            />
          </Tabs>
        </Box>
        <Divider />
        {fullScreen ? (
          <Box pt={2} px={1}>
            {selected === "0" ? <ImportData /> : <FixedData />}
          </Box>
        ) : (
          <TabContext value={String(selected)}>
            <TabPanel value="0">
              <ImportData />
            </TabPanel>
            <TabPanel value="1">
              <FixedData />
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </PageLayout>
  );
}
