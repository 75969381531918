import {
  AdminGetQuestionListActionTypes,
  AnswerTypes,
  ANSWER_STATUS,
  GET_QUESTION_LIST,
  QUESTION_VERIFY_LIST,
  verifyActionTypes,
} from "./ActionTypes";
import { AdminQuestionList, QuestionVerifyResponse } from "./Model";

// Get Question Reducer
export const getQuestionListAction = (
  questionlist: AdminQuestionList[],
): AdminGetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST,
    payload: questionlist,
  };
};
// Add question answer reducer
export const addAnswerAction = (answerResponse: any): AnswerTypes => {
  return {
    type: ANSWER_STATUS,
    payload: answerResponse,
  };
};

// Verify Answer reducer
export const verifyQuestionAction = (
  verifyQuestionResponse: QuestionVerifyResponse,
): verifyActionTypes => {
  return {
    type: QUESTION_VERIFY_LIST,
    payload: verifyQuestionResponse,
  };
};
