import { EditPatientRes } from "./Model";

export const EDIT_PATIENT_TEAM_LOADING = "EDIT_PATIENT_TEAM_LOADING";
export const EDIT_PATIENT_TEAM_SUCCESS = "EDIT_PATIENT_TEAM_SUCCESS";
export const EDIT_PATIENT_TEAM_FAIL = "EDIT_PATIENT_TEAM_FAIL";
export const EDIT_PATIENT_TEAM_API_MSG = "EDIT_PATIENT_TEAM_API_MSG";
export const EDIT_PATIENT_TEAM_CLEAR_API_RES = "EDIT_PATIENT_TEAM_CLEAR_API_RES";

export interface EditPatientTeamDataState {
  addPatientRes: EditPatientRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientTeamLoading {
  type: typeof EDIT_PATIENT_TEAM_LOADING;
  loading: boolean;
}
export interface EditPatientTeamSuccess {
  type: typeof EDIT_PATIENT_TEAM_SUCCESS;
  payload: EditPatientRes;
  successMsg: string;
}
export interface EditPatientTeamFail {
  type: typeof EDIT_PATIENT_TEAM_FAIL;
  payload: EditPatientRes;
  errorMsg: string;
  status: number;
}
export interface EditPatientTeamAPIMsg {
  type: typeof EDIT_PATIENT_TEAM_API_MSG;
  payload: EditPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientTeamAPIRes {
  type: typeof EDIT_PATIENT_TEAM_CLEAR_API_RES;
  payload: EditPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditPatientTeamActionTypes =
  | EditPatientTeamLoading
  | EditPatientTeamSuccess
  | EditPatientTeamFail
  | EditPatientTeamAPIMsg
  | EditPatientTeamAPIRes;
