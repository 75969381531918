import { GetVitalCollectionActiveRes } from "./Model";
export const GET_VITAL_COLLECTION_ACTIVE_LOADING = "GET_VITAL_COLLECTION_ACTIVE_LOADING";
export const GET_VITAL_COLLECTION_ACTIVE_SUCCESS = "GET_VITAL_COLLECTION_ACTIVE_SUCCESS";
export const GET_VITAL_COLLECTION_ACTIVE_FAIL = "GET_VITAL_COLLECTION_ACTIVE_FAIL";
export const GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_MSG = "GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_MSG";
export const GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_RES = "GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_RES";

export interface GetVitalCollectionActiveState {
  getVitalCollectionActiveRes: GetVitalCollectionActiveRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetVitalCollectionActiveLoading {
  type: typeof GET_VITAL_COLLECTION_ACTIVE_LOADING;
  loading: boolean;
}
export interface GetVitalCollectionActiveSuccess {
  type: typeof GET_VITAL_COLLECTION_ACTIVE_SUCCESS;
  payload: GetVitalCollectionActiveRes;
  successMsg: string;
}
export interface GetVitalCollectionActiveFail {
  type: typeof GET_VITAL_COLLECTION_ACTIVE_FAIL;
  payload: GetVitalCollectionActiveRes;
  errorMsg: string;
  status: number;
}
export interface GetVitalCollectionActiveUpdateAPIMsg {
  type: typeof GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_MSG;
  payload: GetVitalCollectionActiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetVitalCollectionActiveUpdateAPIRes {
  type: typeof GET_VITAL_COLLECTION_ACTIVE_UPDATE_API_RES;
  payload: GetVitalCollectionActiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetVitalCollectionActiveActionTypes =
  | GetVitalCollectionActiveLoading
  | GetVitalCollectionActiveSuccess
  | GetVitalCollectionActiveFail
  | GetVitalCollectionActiveUpdateAPIMsg
  | GetVitalCollectionActiveUpdateAPIRes;
