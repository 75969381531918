import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AssignExecutiveBody } from "../../redux/assignExecutive/Model";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MWExceptionList from "../../component/MWExceptionList";
import { getAllTaskListValue } from "../../redux/TaskAdmin/List/API";
import {
  assignExecutiveApiCall,
  clearAssignExecutiveAPIRes,
} from "../../redux/TaskAdmin/AssignExecutive/API";
import MWSnackbar from "../../component/MWSnackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function InactiveTaskList() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const AdminAllTaskList = useSelector((state: AppState) => state.alltaskListValue);
  const pageCount = useSelector(
    (state: AppState) => state.alltaskListValue.adminTaskRes.numberOfPages,
  );
  const executiveListData = useSelector(
    (state: AppState) => state.executiveDropdownListvalue?.executiveRes?.executiveList,
  );
  const assignExecutiveData = useSelector((state: AppState) => state.assignExecutiveValues);
  const [selectedRows, setSelectedRows] = React.useState([] as any[]);
  const [isEdit, setIsEdit] = useState(true);
  const [executiveValues, setExecutiveValue] = useState<any[]>([]);
  const handleSelectedExecutiveChange = (_event: any, newvalue: any) => {
    if (newvalue.label != null) {
      if (selectedRows.length > 0) {
        let id = selectedRows;
        id.forEach((element) => {
          // setIsLoading(true);
          dispatch(
            assignExecutiveApiCall({
              taskId: element,
              executiveId: Number(newvalue.value),
            } as AssignExecutiveBody),
          );
        });
      }
    }
  };
  const inActiveColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<RemoveRedEyeIcon />}
          onClick={() => {
            gotoTaskDetails(currentPage, params.value);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "assigned_to", headerName: "Assigned To", minWidth: 150, flex: 1 },
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },
    {
      field: "consultationType",
      headerName: "Type",
      minWidth: 150,
      flex: 1,
    },
    { field: "patientName", headerName: "Patient name", minWidth: 150, flex: 1 },
  ];
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllTaskListValue(value, 0));
  };
  const history = useHistory();
  const gotoTaskDetails = (currentPage: any, rowIndex: any) => {
    history.push(`/taskdetails/${currentPage}/${rowIndex}`);
  };
  const reloadSelfTaskList = () => {
    dispatch(getAllTaskListValue(page, 0));
  };
  const [responseType, setResponseType] = useState<AlertColor>("success");
  const [responseMsg, setResponseMsg] = useState("");
  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearAssignExecutiveAPIRes());
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllTaskListValue(page, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setExecutiveValue(executiveListData);
  }, [executiveListData]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(assignExecutiveData.loading));
    if (assignExecutiveData?.message !== "") {
      setResponseType("error");
      setResponseMsg(assignExecutiveData?.message);
    } else if (assignExecutiveData?.assignExecutiveRes?.status !== undefined) {
      setResponseType("success");
      setResponseMsg("Task assign successfull");
      dispatch(getAllTaskListValue(page, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignExecutiveData]);

  const taskValues = AdminAllTaskList.adminTaskRes.taskDataList;
  const currentPage = AdminAllTaskList.adminTaskRes.current_page_no;

  const DataGridMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Autocomplete
                id="templateId"
                options={executiveValues}
                onChange={(event: any, newValue: any) => {
                  handleSelectedExecutiveChange(event, newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Select Executive" />}
                disableClearable
                getOptionLabel={(option) => option.label}
                disabled={isEdit}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                {!fullScreen && (
                  <Grid item>
                    <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                  </Grid>
                )}
                {fullScreen ? (
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Pagination
                      color="primary"
                      count={Number(pageCount)}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                ) : (
                  <Grid item>
                    <Pagination
                      color="primary"
                      count={Number(pageCount)}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                {fullScreen ? (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={reloadSelfTaskList}
                      fullWidth={fullScreen}
                    >
                      Reload
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={reloadSelfTaskList}
                    >
                      Reload
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        <Box style={{ height: 600, width: "100%" }}>
          {taskValues && taskValues.length ? (
            <DataGrid
              rows={taskValues}
              columns={inActiveColumns}
              pageSize={10}
              disableSelectionOnClick
              checkboxSelection
              hideFooter={true}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = taskValues.filter((row) => selectedIDs.has(row.id));
                var result = selectedRows.map((element) => {
                  return element.id;
                });
                setSelectedRows(result);
              }}
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );
  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminAllTaskList?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllTaskList]);

  return (
    <Box>
      {DataGridMarkup}
      {responseMsg !== "" ? (
        <MWSnackbar msg={responseMsg} type={responseType} alertClose={alertCloseAction} />
      ) : null}
    </Box>
  );
}
