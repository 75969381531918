import { AddPatientRes } from "./Model";

export const TRENDS_API_LOADING = "TRENDS_API_LOADING";
export const TRENDS_API_SUCCESS = "TRENDS_API_SUCCESS";
export const TRENDS_API_FAIL = "TRENDS_API_FAIL";
export const TRENDS_API_API_MSG = "TRENDS_API_API_MSG";
export const TRENDS_API_CLEAR_API_RES = "TRENDS_API_CLEAR_API_RES";

export interface TrendsApiDataState {
  trendsApiRes: AddPatientRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface TrendsApiLoading {
  type: typeof TRENDS_API_LOADING;
  loading: boolean;
}
export interface TrendsApiSuccess {
  type: typeof TRENDS_API_SUCCESS;
  payload: AddPatientRes;
  successMsg: string;
}
export interface TrendsApiFail {
  type: typeof TRENDS_API_FAIL;
  payload: AddPatientRes;
  errorMsg: string;
  status: number;
}
export interface TrendsApiAPIMsg {
  type: typeof TRENDS_API_API_MSG;
  payload: AddPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface TrendsApiAPIRes {
  type: typeof TRENDS_API_CLEAR_API_RES;
  payload: AddPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type TrendsApiActionTypes =
  | TrendsApiLoading
  | TrendsApiSuccess
  | TrendsApiFail
  | TrendsApiAPIMsg
  | TrendsApiAPIRes;
