import * as React from "react";
import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import dayjs from "dayjs";
import { sleepQualityObservationType } from "redux/SleepAppApi/SleepQualityObservationTypeApi/ApiCall";
import {
  PatientObservationsList,
  SleepQualityObservationTypeBody,
} from "redux/SleepAppApi/SleepQualityObservationTypeApi/Model";

type Props = {
  observationtype?: any;
  patientid: any;
};

export default function SleepQuality({ observationtype, patientid }: Props) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      sleepQualityObservationType({
        patientId: patientid,
        observationType: "sleep",
      } as SleepQualityObservationTypeBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sleepQualityObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepQualityTypeValue,
  );
  const [sleepQualityObservationListByType, setSleepQualityObservationListByType] = React.useState(
    [] as any[],
  );

  React.useEffect(() => {
    if (observationtype === "sleep") {
      if (
        sleepQualityObservationTypeValueList?.sleepQualityObservationTypeRes
          ?.patientObservationsList !== undefined
      ) {
        if (
          sleepQualityObservationTypeValueList?.sleepQualityObservationTypeRes
            ?.patientObservationsList !== undefined
        ) {
          let modifiedSleepObservationList =
            sleepQualityObservationTypeValueList?.sleepQualityObservationTypeRes?.patientObservationsList.map(
              (element: PatientObservationsList) => ({
                id: element.id,
                name: element.patientObservationType.displayValue,
                sleepQuality: element.value,
                startDate:
                  element.startDate !== null
                    ? dayjs(element.startDate).format("dddd, DD/MM/YYYY")
                    : "N/A",
                createDate:
                  element.createDate !== null
                    ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                modifiedDate:
                  element.modifiedDate !== null
                    ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                endDate:
                  element.endDate !== null
                    ? dayjs(element.endDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                isActive: element.isActive === true ? "Active" : "Inactive",
              }),
            );
          setSleepQualityObservationListByType(modifiedSleepObservationList);
        } else {
          setSleepQualityObservationListByType([] as any[]);
        }
      } else {
        setSleepQualityObservationListByType([] as any[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepQualityObservationTypeValueList]);

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          maxHeight: 490,
          "& ul": { padding: 0 },
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          sx={{ width: "100%", bgcolor: "background.paper" }}
          py={2}
        >
          Sleep Quality
        </Typography>
        <Divider sx={{ width: "100%", bgcolor: "background.paper" }} />
        {sleepQualityObservationListByType.map((element: any) => (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem alignItems="center">
              <ListItemText
                primary={element.startDate}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="h6"
                      color="text.primary"
                    >
                      {element.sleepQuality}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </List>
        ))}
      </Grid>
    </Box>
  );
}
