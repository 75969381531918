import { InactiveCorporatePackageList } from "./Model";

export const INACTIVE_CORPORATE_LIST = "INACTIVE_CORPORATE_LIST";
export const INACTIVE_CORPORATE_LIST_LOADING =
  "INACTIVE_CORPORATE_LIST_LOADING";
export const INACTIVE_CORPORATE_LIST_SUCCESS =
  "INACTIVE_CORPORATE_LIST_SUCCESS";
export const INACTIVE_CORPORATE_LIST_FAIL = "INACTIVE_CORPORATE_LIST_FAIL";

// Get Active Corporate Package List
export interface GetInactiveCorporatePackageListStateType {
  inactiveCorporatePackageListValue: InactiveCorporatePackageList[];
  loading: boolean;
}
interface InactiveCorporatePackageListActionType {
  type: typeof INACTIVE_CORPORATE_LIST;
  payload: InactiveCorporatePackageList[];
}
export interface InactiveCorporatePackageListLoading {
  type: typeof INACTIVE_CORPORATE_LIST_LOADING;
}
export interface InactiveCorporatePackageListSuccess {
  type: typeof INACTIVE_CORPORATE_LIST_SUCCESS;
  payload: InactiveCorporatePackageList[];
}
export interface InactiveCorporatePackageListFail {
  type: typeof INACTIVE_CORPORATE_LIST_FAIL;
}
export type InactiveCorporatePackagesActionTypes =
  | InactiveCorporatePackageListActionType
  | InactiveCorporatePackageListLoading
  | InactiveCorporatePackageListSuccess
  | InactiveCorporatePackageListFail;
