import {
  DoctorSearchActionTypes,
  DOCTOR_SEARCH_FAIL,
  DOCTOR_SEARCH_LOADING,
  DOCTOR_SEARCH_SUCCESS,
  DoctorSearchDetailsState,
  DOCTOR_SEARCH_UPDATE_API_MSG,
  DOCTOR_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { DoctorSearchDetails } from "./Model";

const initialStateGetPosts: DoctorSearchDetailsState = {
  loading: false,
  DoctorSearchRes: {} as DoctorSearchDetails,
  message: "",
  status: 0,
};
export const DoctorSearchReducer = (
  state = initialStateGetPosts,
  action: DoctorSearchActionTypes,
): DoctorSearchDetailsState => {
  switch (action.type) {
    case DOCTOR_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        DoctorSearchRes: {} as DoctorSearchDetails,
      };
    case DOCTOR_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        DoctorSearchRes: action.payload,
      };
    case DOCTOR_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        DoctorSearchRes: {} as DoctorSearchDetails,
        message: action.message,
        status: action.status,
      };
    case DOCTOR_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        DoctorSearchRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case DOCTOR_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        DoctorSearchRes: {} as DoctorSearchDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
