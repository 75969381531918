import { EditExecutiveRes } from "./Model";
export const EDIT_EXECUTIVE_LOADING = "EDIT_EXECUTIVE_LOADING";
export const EDIT_EXECUTIVE_SUCCESS = "EDIT_EXECUTIVE_SUCCESS";
export const EDIT_EXECUTIVE_FAIL = "EDIT_EXECUTIVE_FAIL";
export const EDIT_EXECUTIVE_UPDATE_API_MSG = "EDIT_EXECUTIVE_UPDATE_API_MSG";
export const EDIT_EXECUTIVE_UPDATE_API_RES = "EDIT_EXECUTIVE_UPDATE_API_RES";

export interface EditExecutiveState {
  editExecutiveRes: EditExecutiveRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditExecutiveLoading {
  type: typeof EDIT_EXECUTIVE_LOADING;
  loading: boolean;
}
export interface EditExecutiveSuccess {
  type: typeof EDIT_EXECUTIVE_SUCCESS;
  payload: EditExecutiveRes;
  successMsg: string;
}
export interface EditExecutiveFail {
  type: typeof EDIT_EXECUTIVE_FAIL;
  payload: EditExecutiveRes;
  errorMsg: string;
  status: number;
}
export interface EditExecutiveUpdateAPIMsg {
  type: typeof EDIT_EXECUTIVE_UPDATE_API_MSG;
  payload: EditExecutiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditExecutiveUpdateAPIRes {
  type: typeof EDIT_EXECUTIVE_UPDATE_API_RES;
  payload: EditExecutiveRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditExecutiveActionTypes =
  | EditExecutiveLoading
  | EditExecutiveSuccess
  | EditExecutiveFail
  | EditExecutiveUpdateAPIMsg
  | EditExecutiveUpdateAPIRes;
