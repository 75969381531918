import { GetDoctorListByProviderOrganizationIdRes } from "./Model";
export const DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING = "DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING";
export const DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS = "DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS";
export const DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL = "DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL";
export const DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG = "DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG";
export const DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES = "DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES";

export interface GetDoctorListByProviderOrganizationIdState {
  getDoctorListByProviderOrganizationIdRes: GetDoctorListByProviderOrganizationIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByProviderOrganizationIdLoading {
  type: typeof DOCTOR_LIST_BY_PROVIDER_ORG_ID_LOADING;
  loading: boolean;
}
export interface GetDoctorListByProviderOrganizationIdSuccess {
  type: typeof DOCTOR_LIST_BY_PROVIDER_ORG_ID_SUCCESS;
  payload: GetDoctorListByProviderOrganizationIdRes;
  successMsg: string;
}
export interface GetDoctorListByProviderOrganizationIdFail {
  type: typeof DOCTOR_LIST_BY_PROVIDER_ORG_ID_FAIL;
  payload: GetDoctorListByProviderOrganizationIdRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByProviderOrganizationIdUpdateAPIMsg {
  type: typeof DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_MSG;
  payload: GetDoctorListByProviderOrganizationIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByProviderOrganizationIdUpdateAPIRes {
  type: typeof DOCTOR_LIST_BY_PROVIDER_ORG_ID_UPDATE_API_RES;
  payload: GetDoctorListByProviderOrganizationIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorListByProviderOrganizationIdActionTypes =
  | GetDoctorListByProviderOrganizationIdLoading
  | GetDoctorListByProviderOrganizationIdSuccess
  | GetDoctorListByProviderOrganizationIdFail
  | GetDoctorListByProviderOrganizationIdUpdateAPIMsg
  | GetDoctorListByProviderOrganizationIdUpdateAPIRes;
