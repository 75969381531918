import {
  GET_APPOINTMENT_LIST_FAIL,
  GET_APPOINTMENT_LIST_LOADING,
  GET_APPOINTMENT_LIST_SUCCESS,
  GET_APPOINTMENT_LIST_API_MSG,
  GET_APPOINTMENT_LIST_API_RES,
  GetAppointmentListActionTypes,
} from "./ActionTypes";
import { GetAppointmentListRes } from "./Model";

export const getAppointmentListLoadingAction = (
  loading: boolean,
): GetAppointmentListActionTypes => {
  return {
    type: GET_APPOINTMENT_LIST_LOADING,
    loading: loading,
  };
};

export const getAppointmentListSuccessAction = (
  getPatientDataListResponse: GetAppointmentListRes,
  successMsg: string,
): GetAppointmentListActionTypes => {
  return {
    type: GET_APPOINTMENT_LIST_SUCCESS,
    payload: getPatientDataListResponse,
    successMsg: successMsg,
  };
};

export const getAppointmentListErrorAction = (
  getPatientDataListResponse: GetAppointmentListRes,
  errMsg: string,
  status: number,
): GetAppointmentListActionTypes => {
  return {
    type: GET_APPOINTMENT_LIST_FAIL,
    payload: getPatientDataListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getAppointmentListAPIMsgAction = (
  getPatientDataListResponse: GetAppointmentListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetAppointmentListActionTypes => {
  return {
    type: GET_APPOINTMENT_LIST_API_MSG,
    payload: getPatientDataListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getAppointmentListAPIResClearAction = (
  getPatientDataListResponse: GetAppointmentListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetAppointmentListActionTypes => {
  return {
    type: GET_APPOINTMENT_LIST_API_RES,
    payload: getPatientDataListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
