import { AddScoundRoundDetailsRes } from "./Model";
export const ADD_SECOND_ROUND_LOADING = "ADD_SECOND_ROUND_LOADING";
export const ADD_SECOND_ROUND_SUCCESS = "ADD_SECOND_ROUND_SUCCESS";
export const ADD_SECOND_ROUND_FAIL = "ADD_SECOND_ROUND_FAIL";
export const ADD_SECOND_ROUND_DATA = "ADD_SECOND_ROUND_DATA";
export const ADD_SECOND_ROUND_API_MSG = "ADD_SECOND_ROUND_API_MSG";
export const ADD_SECOND_ROUND_API_RES = "ADD_SECOND_ROUND_API_RES";

export interface AddSecondRoundState {
  addSecondRoundRes: AddScoundRoundDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface AddSecondRoundLoading {
  type: typeof ADD_SECOND_ROUND_LOADING;
  loading: boolean;
}
export interface AddSecondRoundSuccess {
  type: typeof ADD_SECOND_ROUND_SUCCESS;
  payload: AddScoundRoundDetailsRes;
}
export interface AddSecondRoundFail {
  type: typeof ADD_SECOND_ROUND_FAIL;
  payload: AddScoundRoundDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddSecondRoundAPIMsg {
  type: typeof ADD_SECOND_ROUND_API_MSG;
  payload: AddScoundRoundDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddSecondRoundAPIRes {
  type: typeof ADD_SECOND_ROUND_API_RES;
  payload: AddScoundRoundDetailsRes;
  errorMsg: string;
  status: number;
}

export type AddScoundRoundDetailsActionTypes =
  | AddSecondRoundLoading
  | AddSecondRoundSuccess
  | AddSecondRoundFail
  | AddSecondRoundAPIMsg
  | AddSecondRoundAPIRes;
