import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Container, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import MWPageTitle from "../../../component/MWPageTitle";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import PatientListByCompitition from "../PatientListByCompitition";
import { CompetitionList } from "redux/CompetitionList/Model";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GetCompetitionPatientListBody } from "redux/PatientListByCompetition/Model";
import { getPatientListByCompition } from "redux/PatientListByCompetition/API";
import PatientListOfCompitition from "../PatientListOfCompitition";
import { GetPatientListOfCompetitionBody } from "redux/PatientListOfCompetition/Model";
import { getPatientListOfCompetition } from "redux/PatientListOfCompetition/API";
import AddPatientDialog from "pages/PatientListByOrganization/AddPatientDialog";
import AdminLoader from "pages/AdminLoader";
import JudgmentViewOfCompetiition from "../JudgementViewOfCompetiition";
import StaffListOfCompetition from "../StaffListOfCompetition";
import { GetStaffListByCompetitionBody } from "redux/StaffListByCompetition/Model";
import { getStaffListByCompition } from "redux/StaffListByCompetition/API";
import { getCompetitionList } from "redux/CompetitionList/API";
import JuryListOfCompetition from "../JuryListOfCompetition";
import { getJuryListByCompition } from "redux/JuryListByCompetition/API";
import { GetJuryListByCompetitionBody } from "redux/JuryListByCompetition/Model";
import ViewCompetitionStaffList from "../ViewCompetitionStaffList";
import { GetViewCompetitionStaffListBody } from "redux/CompetitionStaffViewList/Model";
import { getViewCompetitionStaffList } from "redux/CompetitionStaffViewList/API";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CompetitionDetails() {
  // call Store
  const AdminAllOrganizationList = useSelector((state: AppState) => state.getCompetitionListValue);
  const PatientDetailsListValue = useSelector(
    (state: AppState) => state.getPatientListOfCompetitionValue,
  );
  const getStaffListValues = useSelector((state: AppState) => state.getStaffListByCompetitionValue);
  const getJuryListValues = useSelector((state: AppState) => state.getJuryListByCompetitionValue);
  const getViewStaffListValues = useSelector(
    (state: AppState) => state.viewStaffListOfCompetitionValue,
  );
  const history = useHistory();
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  let type = "competition";
  const loadingMarkup = <AdminLoader />;
  const [organizationName, setOrganizationName] = useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const [detailsListCount, setDetailsListCount] = React.useState("");
  const [staffListCount, setStaffListCount] = useState("");
  const [juryListCount, setJuryListCount] = useState("");
  const [viewStaffListCount, setViewStaffListCount] = useState("");
  const [selected, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?&section=patientList"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?&section=submitionList"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?&section=staffList"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?&section=juryList"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?&section=juryView"}`);
    } else if (newValue === 5) {
      history.push(`${window.location.pathname + "?&section=staffView"}`);
    }
  };
  const AdminPatientList = useSelector((state: AppState) => state.getCompetitionPatientList);
  const [showAddPatientDialog, setShowAddPatientDialog] = React.useState(false);
  const [patientListByCompetitionLoader, setPatientListByCompetitionLoader] = React.useState(false);
  const [patientListOfCompetitionLoader, setPatientListOfCompetitionLoader] = React.useState(false);
  const [staffListOfCompetitonLoader, setStaffListOfCompetitionLoader] = React.useState(false);
  const [juryListOfCompetitonLoader, setJuryListOfCompetitonLoader] = React.useState(false);
  const [judgmentListOfCompetitonLoader, setJudgmentListOfCompetitonLoader] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);
  const updateDialogState = () => {
    setShowAddPatientDialog(true);
  };
  const updateAddPatientDialog = (passedVal: boolean) => {
    setShowAddPatientDialog(passedVal);
  };

  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "patientList") {
      setTabValue(0);
    } else if (tabId === "submitionList") {
      setTabValue(1);
    } else if (tabId === "staffList") {
      setTabValue(2);
    } else if (tabId === "juryList") {
      setTabValue(3);
    } else if (tabId === "juryView") {
      setTabValue(4);
    } else if (tabId === "staffView") {
      setTabValue(5);
    }
    // eslint-disable-next-line
  }, [tabId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCompetitionList(1));
    const patientListBody = {
      competitionId: competitionid,
    } as GetCompetitionPatientListBody;
    dispatch(getPatientListByCompition(patientListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (AdminAllOrganizationList?.getCompetitionListRes?.competitionList !== undefined) {
      var result = AdminAllOrganizationList?.getCompetitionListRes?.competitionList.find(
        (obj: CompetitionList) => {
          return obj.id === Number(competitionid);
        },
      )?.displayName;
      if (result !== null) {
        setOrganizationName(result!);
      }
    }
    // eslint-disable-next-line
  }, [AdminAllOrganizationList]);

  useEffect(() => {
    organizationName !== ""
      ? setPageTitle(`Patient List of ( ${organizationName} )`)
      : setPageTitle("");
  }, [organizationName]);

  useEffect(() => {
    if (AdminPatientList?.PatientListOfCompetitionIdRes?.count !== undefined) {
      setListCount(String(AdminPatientList?.PatientListOfCompetitionIdRes?.count));
    } else {
      setListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  useEffect(() => {
    PatientDetailsListValue?.PatientListOfCompetitionDetailsRes?.count !== undefined
      ? setDetailsListCount(
          String(PatientDetailsListValue?.PatientListOfCompetitionDetailsRes?.count),
        )
      : setDetailsListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientDetailsListValue]);

  useEffect(() => {
    getStaffListValues?.getStaffListByCompetitionRes?.count !== undefined
      ? setStaffListCount(String(getStaffListValues?.getStaffListByCompetitionRes?.count))
      : setStaffListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStaffListValues]);

  useEffect(() => {
    getViewStaffListValues?.viewCompetitionStaffListRes?.count !== undefined
      ? setViewStaffListCount(String(getViewStaffListValues?.viewCompetitionStaffListRes?.count))
      : setViewStaffListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getViewStaffListValues]);

  useEffect(() => {
    getJuryListValues?.getJuryListByCompetitionRes?.count !== undefined
      ? setJuryListCount(String(getJuryListValues?.getJuryListByCompetitionRes?.count))
      : setJuryListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJuryListValues]);

  const patientListByCompetitionResult = () => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetCompetitionPatientListBody;
    dispatch(getPatientListByCompition(patientListBody, 1));
  };

  const patientDetailsListOfCompetition = () => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetPatientListOfCompetitionBody;
    dispatch(getPatientListOfCompetition(patientListBody, 1));
  };

  const staffListOfCompetition = () => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetStaffListByCompetitionBody;
    dispatch(getStaffListByCompition(patientListBody, 1));
  };

  const juryListOfCompetition = () => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetJuryListByCompetitionBody;
    dispatch(getJuryListByCompition(patientListBody, 1));
  };

  const viewStaffListOfCompetition = (() => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetViewCompetitionStaffListBody;
    dispatch(getViewCompetitionStaffList(patientListBody, 1));
  });

  const patientListByCompetitionPage = (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="scrollable"
        aria-label="full width tabs example"
        scrollButtons
        TabIndicatorProps={{
          style: {
            backgroundColor: "#047ab3",
          },
        }}
      >
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient List Of Competition</Typography>
              </Grid>
              <Grid item>
                <Chip label={listCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientListByCompetitionResult();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Submission List Of Competition</Typography>
              </Grid>
              <Grid item>
                <Chip label={String(detailsListCount)} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                patientDetailsListOfCompetition();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(1)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Staff List Of Competition</Typography>
              </Grid>
              <Grid item>
                <Chip label={String(staffListCount)} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                staffListOfCompetition();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(2)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Jury List Of Competition</Typography>
              </Grid>
              <Grid item>
                <Chip label={String(juryListCount)} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                juryListOfCompetition();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(3)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Judgment View Of Competition</Typography>
              </Grid>
            </Grid>
          }
          {...a11yProps(4)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>View Staff Of Competition</Typography>
              </Grid>
              <Grid item>
                <Chip label={viewStaffListCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                viewStaffListOfCompetition();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(5)}
        />
      </Tabs>
      <Box>
        <TabPanel value={tabValue} index={0}>
          <PatientListByCompitition
            patientListByCompetitionLoader={setPatientListByCompetitionLoader}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <PatientListOfCompitition
            patientListOfCompetitionLoader={setPatientListOfCompetitionLoader}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <StaffListOfCompetition staffListOfCompetitonLoader={setStaffListOfCompetitionLoader} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <JuryListOfCompetition juryListOfCompetitonLoader={setJuryListOfCompetitonLoader} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <JudgmentViewOfCompetiition
            judgmentListOfCompetitonLoader={setJudgmentListOfCompetitonLoader}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <ViewCompetitionStaffList
            judgmentListOfCompetitonLoader={setJudgmentListOfCompetitonLoader}
          />
        </TabPanel>
      </Box>
    </Box>
  );
  return (
    <Box>
      {patientListByCompetitionLoader === true ||
      patientListOfCompetitionLoader === true ||
      staffListOfCompetitonLoader === true ||
      juryListOfCompetitonLoader === true ||
      judgmentListOfCompetitonLoader === true
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle backButton={true} title={pageTitle} 
              />
            </Grid>
            <Grid item>
              <Box>
                <Button variant="contained" onClick={() => updateDialogState()}>
                  Add New Participant
                </Button>
              </Box>
            </Grid>
          </Grid>
          {showAddPatientDialog ? (
            <AddPatientDialog
              itemId={competitionid}
              type={type}
              addPatientDialogEnable={showAddPatientDialog}
              addPatientDialogUpdateState={updateAddPatientDialog}
            />
          ) : null}
          {patientListByCompetitionPage}
        </Stack>
      </Container>
    </Box>
  );
}
