import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MWAutocomplete from "../../../component/MWAutocomplete";
import MWTextField from "../../../component/MWTextField";
import MWUploader from "../../../component/MWUploader";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { updateDemographicsDetailsByPatientId } from "../../../redux/DemographicsDetails/UpdateDemographicsDetails/API";
import { useParams } from "react-router-dom";

export default function GovtId() {
  const dispatch = useDispatch();
  const { patientid } = useParams() as { patientid: string };
  const [isEdit, setIsEdit] = useState(patientid ? true : false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [idCardValues, setIdCardValues] = useState([] as any[]);
  const [selectedIdProofValue, setSelectedIdProofValue] = useState<number | null>(null);
  const [govtIdTypeError, setGovtIdTypeError] = useState("");
  const idProofValueFromChild = async (idproofval: any) => {
    setSelectedIdProofValue(idproofval);
  };
  const [idValue, setIdValue] = useState("");
  const [govtIdNumberError, setGovtIdNumberError] = useState("");

  const [govtImgId, setGovtImgId] = useState("");
  const updateGovtDataStateFromChild = async (passedval: any) => {
    setGovtImgId(passedval);
  };
  const [govtImageInfo, setGovtImageInfo] = useState("");

  const getDemographicsDetailsByPtIdRes = useSelector(
    (state: AppState) => state.getDemographicsDetailsByPtId,
  );
  useEffect(() => {
    getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes?.personalDetails?.idProof
      ?.idType?.value !== undefined
      ? setSelectedIdProofValue(
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes?.personalDetails?.idProof
            ?.idType?.value,
        )
      : setSelectedIdProofValue(null);
    getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes?.personalDetails?.idProof
      ?.idNumber !== undefined
      ? setIdValue(
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes?.personalDetails?.idProof
            ?.idNumber,
        )
      : setIdValue("");
    getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes?.personalDetails?.idProof
      ?.supportingDocuments?.document !== undefined
      ? setGovtImageInfo(
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes?.personalDetails?.idProof
            ?.supportingDocuments?.document,
        )
      : setGovtImageInfo("");
  }, [getDemographicsDetailsByPtIdRes]);
  // Call Govt Id type store
  const getGovtIDProofStoreValue = useSelector((state: AppState) => state.govtIdProofList);
  // Get Govt. ID List
  useEffect(() => {
    if (getGovtIDProofStoreValue?.govtIdProofResponse?.govtIdTypeList !== undefined) {
      setIdCardValues(getGovtIDProofStoreValue?.govtIdProofResponse.govtIdTypeList);
    } else setIdCardValues([] as any[]);
  }, [getGovtIDProofStoreValue]);

  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };

  // const [delImg, setDelImg] = useState(false);
  const [delImgAction, setDelImgAction] = useState(false);
  // const delImgAction = (passeVal: boolean) => {
  //   setDelImg(passeVal);
  // };

  // const showDocument = () => {
  //   setDescriptionModalActive(true);
  // };
  // Call govt. image update Body
  const updateGovtIdImg = () => {
    if (delImgAction) {
      setDelImgAction(false);
      // When user delete image
      if (selectedIdProofValue === null && idValue === "") {
        setGovtIdTypeError("Please select a id card");
        setGovtIdNumberError("Please enter id card number");
      } else if (selectedIdProofValue === null && idValue !== "") {
        setGovtIdTypeError("Please select a id card");
        setGovtIdNumberError("");
      } else if (selectedIdProofValue !== null && idValue === "") {
        setGovtIdTypeError("");
        setGovtIdNumberError("Please enter id card number");
      } else {
        setGovtIdTypeError("");
        setGovtIdNumberError("");
        const govtIdUpdateBody = {
          healthRecordId: patientid,
          govtIdNumber: idValue,
          govtIdType: String(selectedIdProofValue),
          govtIdImage: "-1",
        } as any;
        dispatch(updateDemographicsDetailsByPatientId(govtIdUpdateBody));
      }
    } else {
      if (govtImgId !== undefined) {
        // when user add new image
        if (selectedIdProofValue === null && idValue === "") {
          setGovtIdTypeError("Please select a id card");
          setGovtIdNumberError("Please enter id card number");
        } else if (selectedIdProofValue === null && idValue !== "") {
          setGovtIdTypeError("Please select a id card");
          setGovtIdNumberError("");
        } else if (selectedIdProofValue !== null && idValue === "") {
          setGovtIdTypeError("");
          setGovtIdNumberError("Please enter id card number");
        } else {
          setGovtIdTypeError("");
          setGovtIdNumberError("");
          const govtIdUpdateBody = {
            healthRecordId: patientid,
            govtIdNumber: idValue,
            govtIdType: String(selectedIdProofValue),
            govtIdImage: String(govtImgId),
          } as any;
          dispatch(updateDemographicsDetailsByPatientId(govtIdUpdateBody));
        }
      } else {
        // when user edit other details
        if (selectedIdProofValue === null && idValue === "") {
          setGovtIdTypeError("Please select a id card");
          setGovtIdNumberError("Please enter id card number");
        } else if (selectedIdProofValue === null && idValue !== "") {
          setGovtIdTypeError("Please select a id card");
          setGovtIdNumberError("");
        } else if (selectedIdProofValue !== null && idValue === "") {
          setGovtIdTypeError("");
          setGovtIdNumberError("Please enter id card number");
        } else {
          setGovtIdTypeError("");
          setGovtIdNumberError("");
          const govtIdUpdateBody = {
            healthRecordId: patientid,
            govtIdNumber: idValue,
            govtIdType: String(selectedIdProofValue),
          } as any;
          dispatch(updateDemographicsDetailsByPatientId(govtIdUpdateBody));
        }
      }
    }
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item xs={12} sm={6}>
                  <MWAutocomplete
                    placeHolder="Id Card Type"
                    listvalue={idCardValues}
                    selectedItem={selectedIdProofValue}
                    dropdownValue={idProofValueFromChild}
                    visibility={isEdit}
                  />
                  {govtIdTypeError !== "" ? (
                    <Typography variant="body1" style={{ color: "#d32f2f" }}>
                      {govtIdTypeError}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MWTextField
                    label="ID Card Number"
                    placeholder="Enter ID Card Number"
                    value={idValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIdValue(event.target.value);
                    }}
                    disabled={isEdit}
                    fullWidth
                    size="small"
                  />
                  {govtIdNumberError !== "" ? (
                    <Typography variant="body1" style={{ color: "#d32f2f" }}>
                      {govtIdNumberError}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MWUploader
                documentType="patient-id-Image"
                criteria="Document size should be maximum 10MB."
                updatedDataState={updateGovtDataStateFromChild}
                title="Update Govt. Doc. image"
                visibility={isEdit}
                imageUrl={govtImageInfo}
                docImg={govtImageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit ? "Edit" : "Cancel"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit || checkImgUpload}
                onClick={() => updateGovtIdImg()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {/* {displayModalBody} */}
    </Box>
  );
}
