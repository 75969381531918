import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import { clearAddPatientDetailsTypeToSessionListAPIRes } from "redux/GetPatientDetailsTypeOfSession/AddPatientDetailsTypeToSession/API";
import { DataCurationProblemTypeList } from "redux/HealthcampSessionList/GetDataCurationProblemTypeList/Model";
import { getDataCurationProblemTypeListDetails } from "redux/HealthcampSessionList/GetDataCurationProblemTypeList/API";
import { AddDataCurationProblemBody } from "redux/HealthcampSessionList/AddDataCuration/Model";
import {
  addDataCurationProblemApiCall,
  updateAddDataCurationProblemAPIResMsg,
} from "redux/HealthcampSessionList/AddDataCuration/API";
import { DataCurationProblemList } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/Model";
import _ from "lodash";
import { EditDataCurationProblemBody } from "redux/HealthcampSessionList/EditDataCuration/Model";
import { editDataCurationProblemApiCall, updateEditDataCurationProblemAPIResMsg } from "redux/HealthcampSessionList/EditDataCuration/API";
type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  dataCurationValue?: string | any;
};

export default function AddDataCurationDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  dataCurationValue,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  // call store
  const dataCurationProblemTypeListValue = useSelector(
    (state: AppState) => state.getDataCurationProblemTypeValues,
  );
  const addDataCurationProbResponse = useSelector(
    (state: AppState) => state.addDataCurationProbValues,
  );
  const dataCurationProblemListRes = useSelector(
    (state: AppState) => state.getDataCurationProblemListValue,
  );
  const editDataCurationProbResponse = useSelector(
    (state: AppState) => state.editDataCurationResult,
  );
  const [targetId, setTargetId] = React.useState("");
  const [sourceId, setSourceId] = React.useState("");
  const [instAssocitated, setInstAssociated] = React.useState("");
  const [description, setDescription] = useState("");
  const [destinationId, setDestinationId] = useState("");
  const [dataCurationProblemType, setDataCurationProblemType] = useState<any | null>(null);
  const [dataCurationProblemTypeDropDownList, setDataCurationProblemTypeDropDownList] =
    React.useState([] as any[]);
  const filterDataCurationProblemType = (dataCurationType: any, _e: any) => {
    setDataCurationProblemType(dataCurationType);
  };
  useEffect(() => {
    if (dataCurationProblemTypeDropDownList.length > 0 && getDataCurationListObject !== undefined) {
      setDataCurationProblemType(
        dataCurationProblemTypeDropDownList.find(
          (element: any) => element.displayValue === getDataCurationListObject.problemType,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurationProblemTypeDropDownList, dataCurationProblemTypeListValue]);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(updateAddDataCurationProblemAPIResMsg());
    dispatch(updateEditDataCurationProblemAPIResMsg());
  };
  useEffect(() => {
    dispatch(getDataCurationProblemTypeListDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      dataCurationProblemTypeListValue?.GetDataCurationProblemTypeListResponse
        ?.dataCurationProblemTypeList !== undefined
    ) {
      let patientDetailsTypeDropdownValue =
        dataCurationProblemTypeListValue?.GetDataCurationProblemTypeListResponse?.dataCurationProblemTypeList.map(
          (element: DataCurationProblemTypeList) => ({
            id: element.id,
            displayValue: element.description !== "" ? element.description : "",
            name: element.name !== "" ? element.name : "",
            sequence: element.sequence,
          }),
        );
      setDataCurationProblemTypeDropDownList(patientDetailsTypeDropdownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurationProblemTypeListValue]);

  useEffect(() => {
    if (addDataCurationProbResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataCurationProbResponse]);

  useEffect(() => {
    if (editDataCurationProbResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDataCurationProbResponse]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    dispatch(clearAddPatientDetailsTypeToSessionListAPIRes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);
  const [getDataCurationListObject, setGetDataCurationListObject] = React.useState<
    DataCurationProblemList | undefined
  >(undefined);

  useEffect(() => {
    if (
      dataCurationProblemListRes?.getDataCurationProblemListRes?.dataCurationProblemList !==
      undefined
    ) {
      setGetDataCurationListObject(
        _.find(dataCurationProblemListRes?.getDataCurationProblemListRes?.dataCurationProblemList, [
          "id",
          Number(dataCurationValue),
        ]),
      );
    } else {
      setGetDataCurationListObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurationProblemListRes]);

  useEffect(() => {
    if (getDataCurationListObject !== undefined) {
      setDescription(
        getDataCurationListObject.description !== undefined
          ? getDataCurationListObject.description
          : "",
      );
      setTargetId(
        getDataCurationListObject?.targetModelId !== undefined
          ? String(getDataCurationListObject?.targetModelId)
          : "",
      );
      setSourceId(
        getDataCurationListObject?.sourceModelId !== undefined
          ? String(getDataCurationListObject?.sourceModelId)
          : "",
      );
      setDestinationId(
        getDataCurationListObject?.destinationModelId !== undefined
          ? String(getDataCurationListObject?.destinationModelId)
          : "",
      );
      setInstAssociated(
        getDataCurationListObject?.sessionAssociatedId !== undefined
          ? String(getDataCurationListObject?.sessionAssociatedId)
          : "",
      );
    } else {
      setDataCurationProblemType(null);
      setDescription("");
      setTargetId("");
      setSourceId("");
      setDestinationId("");
      setInstAssociated("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataCurationListObject]);

  const addDataCurationProblemSubmit = () => {
    if (dataCurationProblemType?.id !== null) {
      const addDataCurationProbApiBody = {
        problemType: String(dataCurationProblemType?.id),
        description: description,
        targetModelId: targetId,
        sourceModelId: sourceId,
        destinationModelId: destinationId,
        sessionAssociated: instAssocitated,
      } as AddDataCurationProblemBody;
      dispatch(addDataCurationProblemApiCall(addDataCurationProbApiBody));
    }
  };

  const editDataCurationProblemSubmit = () => {
    const editDataCurationProbApiBody = {
      dataCurationProblemId: String(getDataCurationListObject?.id),
      problemTypeId: String(dataCurationProblemType?.id),
      description: description,
      targetModelId: targetId,
      sourceModelId: sourceId,
      destinationModelId: destinationId,
      sessionAssociated: instAssocitated,
    } as EditDataCurationProblemBody;
    dispatch(editDataCurationProblemApiCall(editDataCurationProbApiBody));
  };

  return (
    <Box>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "50vw",
            overflow: "hidden",
          },
        }}
      >
        {dataCurationProblemTypeListValue?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                {getDataCurationListObject !== undefined ? "Edit" : "Add"} Data Curation Problem
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                fullWidth
                id="controllable-states-demo"
                value={dataCurationProblemType}
                getOptionLabel={(option) => `${option.displayValue}`}
                options={dataCurationProblemTypeDropDownList}
                onChange={(event: any, newValue: any) => {
                  filterDataCurationProblemType(newValue, event);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Data Curation Problem Type" variant="outlined" />
                )}
                size="small"
                disableClearable
                isOptionEqualToValue={(option: any, value) => option.id === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                autoFocus
                id="des"
                value={description}
                label="Description"
                variant="outlined"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                autoFocus
                id="targetid"
                value={targetId}
                label="Target Id"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setTargetId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                autoFocus
                id="sourceid"
                value={sourceId}
                label="Source Id"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setSourceId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                autoFocus
                id="destinationid"
                value={destinationId}
                label="Destination Id"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setDestinationId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                autoFocus
                id="instanceAssociated"
                value={instAssocitated}
                label="Session Associated"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setInstAssociated(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              {getDataCurationListObject !== undefined ? (
                <Button
                  autoFocus
                  onClick={() => editDataCurationProblemSubmit()}
                  variant="contained"
                  color="primary"
                  style={{ fontSize: "12px" }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  autoFocus
                  onClick={() => addDataCurationProblemSubmit()}
                  variant="contained"
                  color="primary"
                  style={{ fontSize: "12px" }}
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
