import {
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
  COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
  CompetitionListByDuplicatePatientIdActionTypes,
} from "./ActionTypes";
import { CompetitionListByDuplicatePatientIdDetails } from "./Model";

export const CompetitionListByDuplicatePatientIdLoadingAction = (
  loading: boolean,
): CompetitionListByDuplicatePatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const CompetitionListByDuplicatePatientIdSuccessAction = (
  competitionListByDuplicatePatientIdResponse: CompetitionListByDuplicatePatientIdDetails,
  successMsg: string,
): CompetitionListByDuplicatePatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
    payload: competitionListByDuplicatePatientIdResponse,
    successMsg: successMsg,
  };
};

export const CompetitionListByDuplicatePatientIdErrorAction = (
  competitionListByDuplicatePatientIdResponse: CompetitionListByDuplicatePatientIdDetails,
  errMsg: string,
  status: number,
): CompetitionListByDuplicatePatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
    payload: competitionListByDuplicatePatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const CompetitionListByDuplicatePatientIdAPIMsgAction = (
  competitionListByDuplicatePatientIdResponse: CompetitionListByDuplicatePatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): CompetitionListByDuplicatePatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
    payload: competitionListByDuplicatePatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const CompetitionListByDuplicatePatientIdAPIResClearAction = (
  competitionListByDuplicatePatientIdResponse: CompetitionListByDuplicatePatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): CompetitionListByDuplicatePatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
    payload: competitionListByDuplicatePatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
