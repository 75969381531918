import {
  PreviewMarksDetailsActionTypes,
  PREVIEW_CUTOFF_MARKS_FAIL,
  PREVIEW_CUTOFF_MARKS_LOADING,
  PREVIEW_CUTOFF_MARKS_SUCCESS,
  PreviewCutoffMarksState,
  PREVIEW_CUTOFF_MARKS_API_MSG,
  PREVIEW_CUTOFF_MARKS_API_RES,
} from "./ActionTypes";
import { PreviewMarksDetailsRes } from "./Model";

const initialStateUpdatePosts: PreviewCutoffMarksState = {
  loading: false,
  previewCutoffMarksRes: {} as PreviewMarksDetailsRes,
  errorMsg: "",
  status: 0,
};
export const previewCutoffMarksReducer = (
  state = initialStateUpdatePosts,
  action: PreviewMarksDetailsActionTypes,
): PreviewCutoffMarksState => {
  switch (action.type) {
    case PREVIEW_CUTOFF_MARKS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PREVIEW_CUTOFF_MARKS_SUCCESS:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
      };
    case PREVIEW_CUTOFF_MARKS_FAIL:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PREVIEW_CUTOFF_MARKS_API_MSG:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PREVIEW_CUTOFF_MARKS_API_RES:
      return {
        ...state,
        previewCutoffMarksRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
