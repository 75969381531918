import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetJudgingDetailsListActionTypes } from "./ActionTypes";
import { GetJudgingDetailsListBody, GetJudgingDetailsListDetails } from "./Model";
import {
  getJudgingDetailsListAPIResClearAction,
  getJudgingDetailsListErrorAction,
  getJudgingDetailsListLoadingAction,
  getJudgingDetailsListSuccessAction,
  getJudgingDetailsListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetJudgingDetailsListDetails;
export const getJudgingDetailsList = (
  competionId: GetJudgingDetailsListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetJudgingDetailsListActionTypes>) {
    dispatch(getJudgingDetailsListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionJudgingResultAsStaff/getJudgingDetailsList?page=${Number(
            pageNo,
          )}&size=25`,
          competionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getJudgingDetailsListLoadingAction(false));
        dispatch(getJudgingDetailsListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getJudgingDetailsListLoadingAction(false));
        dispatch(
          getJudgingDetailsListErrorAction(
            {} as GetJudgingDetailsListDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getJudgingDetailsListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetJudgingDetailsListActionTypes>) {
    dispatch(
      getJudgingDetailsListAPIMsgAction(apiRes as GetJudgingDetailsListDetails, "", 0),
    );
  };
};

export const clearGetJudgingDetailsListAPIRes = () => {
  return function (dispatch: Dispatch<GetJudgingDetailsListActionTypes>) {
    dispatch(
      getJudgingDetailsListAPIResClearAction({} as GetJudgingDetailsListDetails, "", 0),
    );
  };
};
