import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import PaymentIcon from "@mui/icons-material/Payment";
import PreviewIcon from "@mui/icons-material/Preview";
import { Button, Card, CardContent, CardHeader, Grid, Box } from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import { paymentCallApi } from "../redux/PaymentAdmin/PaymentList/ApiCall";
import { AdminPaymentBody, PaymentList } from "../redux/PaymentAdmin/PaymentList/Model";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import PageLayout from "./Layout/PageLayout";
import { paymentStatusCallApi } from "../redux/PaymentAdmin/PaymentStatus/ApiCall";
import { PaymentStatusBody } from "../redux/PaymentAdmin/PaymentStatus/Model";
import MWResponseBanner from "../component/MWResponseBanner";
import { checkReturnValue } from "component/Utility";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function Payment() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const allPaymentValues = useSelector((state: AppState) => state.allPaymentListValue);
  const pageCount = useSelector(
    (state: AppState) => state.allPaymentListValue?.adminPaymentRes?.numberOfPages,
  );
  const paymentStatusValues = useSelector((state: AppState) => state.verifyPaymentvalue);

  function checkPaymentStatusDetails(id: number) {
    setBannerOpen(false);
    dispatch(paymentStatusCallApi({ paymentId: id } as PaymentStatusBody));
  }
  function capitalizeFirstLetter(status: string): string {
    const convertedStatus = status > "0" ? status.charAt(0).toUpperCase() + status.slice(1) : "";
    return convertedStatus;
  }
  const body = {
    paymentStatus: "",
  } as AdminPaymentBody;
  const paymentDatacolumns: GridColDef[] = [
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      flex: 1,
    },
    { field: "createDate", headerName: "Create Date", minWidth: 140 },
    {
      field: "amount",
      headerName: "Amount",
      maxWidth: 90,
    },
    {
      field: "paymentLink",
      headerName: "Pay",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PaymentIcon />}
          onClick={() => {
            paymentLink(params.row.paymentLink);
          }}
        >
          Pay Now
        </Button>
      ),
      minWidth: 130,
    },
    { field: "paidTime", headerName: "Paid On", minWidth: 140 },
    { field: "status", headerName: "Status", maxWidth: 80 },
    {
      field: "id",
      headerName: "Check Status",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => {
            checkPaymentStatusDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(paymentCallApi(value, body));
  };

  function paymentLink(response: string) {
    window.open(response);
  }

  function reloadPaymentList() {
    dispatch(paymentCallApi(page, body));
  }

  const closeBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  };
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState("");
  const [bannerMsg, setBannerMsg] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(paymentCallApi(page, body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [paymentDataListValue, setPaymentDataListValue] = useState<any[]>([]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(allPaymentValues?.loading));
    if (allPaymentValues !== undefined) {
      let paymentList = allPaymentValues?.adminPaymentRes?.paymentList?.map(
        (element: PaymentList) => ({
          id: element.id,
          description: checkReturnValue(element?.note) !== "Not Set" ? element?.note : "",
          createDate:
            checkReturnValue(element?.create_date) !== "Not Set"
              ? dayjs(element?.create_date).format("ddd, MMM D, YYYY")
              : "",
          amount: checkReturnValue(element?.amount) !== "Not Set" ? element?.amount : "",
          paymentLink:
            checkReturnValue(element.paymentLink) !== "Not Set" ? element.paymentLink : "",
          paidTime:
            checkReturnValue(element.paidTime) !== "Not Set"
              ? dayjs(element?.paidTime).format("ddd, MMM D, YYYY")
              : "Not Paid",
          status:
            checkReturnValue(element.status) !== "Not Set"
              ? capitalizeFirstLetter(element.status)
              : "",
        }),
      );
      setPaymentDataListValue(paymentList);
    } else setPaymentDataListValue([] as PaymentList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPaymentValues]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(paymentStatusValues?.loading));
    if (paymentStatusValues?.successMsg !== "") {
      setBannerOpen(true);
      setBannerColor("success");
      setBannerMsg(paymentStatusValues?.successMsg);
    } else if (paymentStatusValues?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(paymentStatusValues?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusValues]);

  const tableMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">Payment List For Admin</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="subtitle2">Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {paymentDataListValue && paymentDataListValue.length ? (
          <DataGrid
            rows={paymentDataListValue}
            columns={paymentDatacolumns}
            hideFooter={true}
            autoHeight
            getRowId={(row) => row.id}
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <PageLayout>
        <MWPageTitle title={"Payment"} reload={true} reloadAction={reloadPaymentList} />
        {tableMarkup}
        {
          <MWResponseBanner
            bannerOpen={bannerOpen}
            bannerColor={bannerColor}
            responseMsg={bannerMsg}
            bannerClose={closeBannerState}
          />
        }
      </PageLayout>
    </Box>
  );
}
