import {
  ADD_STAFF_OF_ORG_DATA,
  ADD_STAFF_OF_ORG_FAIL,
  ADD_STAFF_OF_ORG_LOADING,
  ADD_STAFF_OF_ORG_SUCCESS,
  ADD_STAFF_OF_ORG_API_MSG,
  ADD_STAFF_OF_ORG_API_RES,
  AddStaffOfOrgActionTypes,
} from "./ActionTypes";
import { PageCount, AddStaffOfOrgDetails } from "./Model";

export const AddStaffOrganizationIdAction = (
  AddStaffOfOrgDetails: AddStaffOfOrgDetails,
  pageCount: PageCount,
): AddStaffOfOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_ORG_DATA,
    payload: AddStaffOfOrgDetails,
    pageCount: pageCount,
  };
};

export const AddStaffOfOrgLoadingAction = (
  loading: boolean,
): AddStaffOfOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_ORG_LOADING,
    loading: loading,
  };
};

export const AddStaffOfOrgSuccessAction = (
  AddStaffResponse: AddStaffOfOrgDetails,
  successMsg: string,
): AddStaffOfOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_ORG_SUCCESS,
    payload: AddStaffResponse,
    successMsg: successMsg,
  };
};

export const AddStaffOfOrgErrorAction = (
  AddStaffResponse: AddStaffOfOrgDetails,
  errMsg: string,
  status: number,
): AddStaffOfOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_ORG_FAIL,
    payload: AddStaffResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const AddStaffOfOrgAPIMsgAction = (
  AddStaffResponse: AddStaffOfOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_ORG_API_MSG,
    payload: AddStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const AddStaffOfOrgAPIResClearAction = (
  AddStaffResponse: AddStaffOfOrgDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddStaffOfOrgActionTypes => {
  return {
    type: ADD_STAFF_OF_ORG_API_RES,
    payload: AddStaffResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
