import { AddPatientDetailsListInCompetitionIdDetails } from "./Model";

export const ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING =
  "ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING";
export const ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS =
  "ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS";
export const ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL =
  "ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL";
export const ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_DATA =
  "ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_DATA";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG =
  "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES =
  "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES";

export interface AddPatientDetailsListInCompetitionIdState {
  addPatientDetailsListInCompetitionIdRes: AddPatientDetailsListInCompetitionIdDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsListOfCompetitionIdLoading {
  type: typeof ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING;
  loading: boolean;
}
export interface AddPatientDetailsListOfCompetitionIdSuccess {
  type: typeof ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS;
  payload: AddPatientDetailsListInCompetitionIdDetails;
  successMsg: string;
}
export interface PatientDetailsListOfCompetitionIdFail {
  type: typeof ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL;
  payload: AddPatientDetailsListInCompetitionIdDetails;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsListOfCompetitionIdAPIMsg {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG;
  payload: AddPatientDetailsListInCompetitionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDetailsListOfCompetitionIdRes {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES;
  payload: AddPatientDetailsListInCompetitionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddPatientDetailsListInCompetitionIdActionTypes =
  | AddPatientDetailsListOfCompetitionIdLoading
  | AddPatientDetailsListOfCompetitionIdSuccess
  | PatientDetailsListOfCompetitionIdFail
  | AddPatientDetailsListOfCompetitionIdAPIMsg
  | AddPatientDetailsListOfCompetitionIdRes;
