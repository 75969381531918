import axios from "axios";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";
import {
  editExecutiveAPIResClearAction,
  editExecutiveErrorAction,
  editExecutiveLoadingAction,
  editExecutiveSuccessAction,
  editExecutiveUpdateAPIMsgAction,
} from "./Actions";
import { Dispatch } from "react";
import { EditExecutiveActionTypes } from "./ActionTypes";
import { headers } from "component/Utility";
import Url from "../../../ApiConfig";
import { EditExecutivePayload, EditExecutiveRes } from "./Model";

let apiRes = {} as EditExecutiveRes;
export const editExecutive = (payload: EditExecutivePayload) => {
  return function (dispatch: Dispatch<EditExecutiveActionTypes>) {
    dispatch(editExecutiveLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/executives/edit_excutive`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(editExecutiveLoadingAction(false));
        dispatch(
          editExecutiveSuccessAction(
            res.data !== undefined ? res.data : ({} as EditExecutiveRes),
            res.data.message !== undefined
              ? res.data.message
              : "Executive details has been updated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(editExecutiveLoadingAction(false));
        dispatch(
          editExecutiveErrorAction(
            {} as EditExecutiveRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateEditExecutiveAPIResMsg = () => {
  return function (dispatch: Dispatch<EditExecutiveActionTypes>) {
    dispatch(editExecutiveUpdateAPIMsgAction(apiRes as EditExecutiveRes, "", "", 0));
  };
};

export const clearEditExecutiveAPIRes = () => {
  return function (dispatch: Dispatch<EditExecutiveActionTypes>) {
    dispatch(editExecutiveAPIResClearAction({} as EditExecutiveRes, "", "", 0));
  };
};
