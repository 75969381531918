import {
  UpdatePatientDataPointActionTypes,
  PATIENT_SEARCH_FAIL,
  PATIENT_SEARCH_LOADING,
  PATIENT_SEARCH_SUCCESS,
  UpdatePatientDataPointState,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { UpdatePatientDataPointRes } from "./Model";

const initialStateGetPosts: UpdatePatientDataPointState = {
  loading: false,
  updatePatientDataPointRes: {} as UpdatePatientDataPointRes,
  message: "",
  status: 0,
};
export const UpdatePatientDataPointReducer = (
  state = initialStateGetPosts,
  action: UpdatePatientDataPointActionTypes,
): UpdatePatientDataPointState => {
  switch (action.type) {
    case PATIENT_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        updatePatientDataPointRes: {} as UpdatePatientDataPointRes,
      };
    case PATIENT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: action.payload,
      };
    case PATIENT_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: {} as UpdatePatientDataPointRes,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: {} as UpdatePatientDataPointRes,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
