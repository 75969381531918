import { PatientSearchListByObservationTypeDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING = "PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING";
export const PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS = "PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS";
export const PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL = "PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL";
export const PATIENT_SEARCH_BY_OBSERVATION_TYPE_DATA = "PATIENT_SEARCH_BY_OBSERVATION_TYPE_DATA";
export const PATIENT_SEARCH_UPDATE_API_MSG = "PATIENT_SEARCH_UPDATE_API_MSG";
export const PATIENT_SEARCH_UPDATE_API_RES = "PATIENT_SEARCH_UPDATE_API_RES";

export interface PatientSearchListByObservationTypeDetailsState {
  patientSearchObservationTypeRes: PatientSearchListByObservationTypeDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface PatientSearchListByObservationTypeLoading {
  type: typeof PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface PatientSearchListByObservationTypeSuccess {
  type: typeof PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS;
  payload: PatientSearchListByObservationTypeDetails;
  successMsg: string;
}
export interface PatientSearchListByObservationTypeFail {
  type: typeof PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL;
  payload: PatientSearchListByObservationTypeDetails;
  errorMsg: string;
  status: number;
}
export interface PatientSearchListByObservationTypeAPIMsg {
  type: typeof PATIENT_SEARCH_UPDATE_API_MSG;
  payload: PatientSearchListByObservationTypeDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface patientSearchObservationTypeRes {
  type: typeof PATIENT_SEARCH_UPDATE_API_RES;
  payload: PatientSearchListByObservationTypeDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type PatientSearchListByObservationTypeActionTypes =
  | PatientSearchListByObservationTypeLoading
  | PatientSearchListByObservationTypeSuccess
  | PatientSearchListByObservationTypeFail
  | PatientSearchListByObservationTypeAPIMsg
  | patientSearchObservationTypeRes;
