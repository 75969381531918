import { Box, Button, Grid, List } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWPageTitle from "../../../component/MWPageTitle";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import AdminLoader from "../../AdminLoader";
import PageLayout from "../../Layout/PageLayout";
import MWExceptionList from "../../../component/MWExceptionList";
import { useEffect } from "react";
import ModifiedList from "component/ModifiedList";

export default function CaseListExpanded() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  // Reload Patient Case List API
  const reloadPatientCaseList = () => {
    dispatch(getPatientCaseList(patientid));
  };
  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}?&tab=consultationType`);
  };

  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const caseHistory = useSelector((state: AppState) => state.caseValue);

  return (
    <Box>
      {caseHistory?.loading ? <AdminLoader /> : null}
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title="Patient Case List"
              enableCount={true}
              count={caseHistory?.caseValue !== undefined ? caseHistory?.caseValue.length : "0"}
              reload={true}
              reloadAction={reloadPatientCaseList}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleAddEncounterToggle()}
              sx={{ textTransform: "none" }}
            >
              Add Case
            </Button>
          </Grid>
        </Grid>
        <Box>
          {caseHistory?.caseValue.length > 0 ? (
            <Box
              style={{
                textDecoration: "none",
                textTransform: "none",
              }}
            >
              <List>
                <ModifiedList
                  icon={true}
                  showSubtitle={true}
                  showReason={true}
                  showNavigation={true}
                  data={caseHistory?.caseValue}
                  path={"casedetails"}
                />
              </List>
            </Box>
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </PageLayout>
    </Box>
  );
}
