import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../../../../redux/store/Store";
import { Alert, AlertColor, Box, Button, Card, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../../../../component/MWExceptionList";
import { useParams } from "react-router-dom";
import {
  GetPatientDetailsTypeOfSessionBody,
  MandatoryPatientDeatilTypeList,
} from "redux/GetPatientDetailsTypeOfSession/Model";
import AddMappingDialog from "./AddMappingDialog";
import { getPatientDetailsTypeOfSessionList } from "redux/GetPatientDetailsTypeOfSession/API";
import MWSnackbar from "component/MWSnackbar";
import { clearAddPatientDetailsTypeToSessionListAPIRes } from "redux/GetPatientDetailsTypeOfSession/AddPatientDetailsTypeToSession/API";
import { InactiveVitalCollectionListBody } from "redux/InactiveVitalCollectionStatus/Model";
import { inactiveVitalCollectionList } from "redux/InactiveVitalCollectionStatus/API";
import DataCurationProblemListComponent from "./DataCurationProblemListComponent";

type Props = {
  sessionManagementListLoader?: boolean | any;
};

export default function SessionManagement({ sessionManagementListLoader }: Props) {
  const dispatch = useDispatch();
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowFilterDialog(passedVal);
  };

  // call Store
  const inactiveVitalCollectionRes = useSelector(
    (state: AppState) => state.inactiveVitalCollection,
  );
  const getPatientDetailsTypeofSessionListValue = useSelector(
    (state: AppState) => state.getPatientDetailsTypeOfSessionList,
  );
  const addpatientDetailsTypeToSessionValue = useSelector(
    (state: AppState) => state.addPatientDetailsTypeToSession,
  );

  const inactiveSessionRequest = () => {
    dispatch(
      inactiveVitalCollectionList({
        healthcampSessionId: Number(sessionId),
      } as InactiveVitalCollectionListBody),
    );
  };

  useEffect(() => {
    // dispatch(inactiveVitalCollectionListAPIResMsg());
    let sessionManageMentApiBody = {
      healthcampSessionId: sessionId,
    } as GetPatientDetailsTypeOfSessionBody;
    dispatch(getPatientDetailsTypeOfSessionList(sessionManageMentApiBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [sessionManagementGridArray, setSessionManagementGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");

  const closePatientDetailsTypeToSessionMsg = () => {
    dispatch(clearAddPatientDetailsTypeToSessionListAPIRes());
    setPatientDetailsTypeToSessionAPIMsg("");
  };

  const [vitalCollectionReqMsg, setVitalCollectionReqMsg] = useState("");
  const [VitalCollectionReqMsgType, setVitalCollectionReqMsgType] = useState<AlertColor>("success");

  const [patientDetailsTypeToSessionMsg, setPatientDetailsTypeToSessionMsg] =
    useState<AlertColor>("success");
  const [patientDetailsTypeToSessionAPIMsg, setPatientDetailsTypeToSessionAPIMsg] = useState("");

  useEffect(() => {
    if (inactiveVitalCollectionRes?.successMsg !== "") {
      setVitalCollectionReqMsgType("success");
      setVitalCollectionReqMsg(inactiveVitalCollectionRes?.successMsg);
    }
    if (inactiveVitalCollectionRes?.errorMsg !== "") {
      setVitalCollectionReqMsgType("error");
      setVitalCollectionReqMsg(inactiveVitalCollectionRes?.errorMsg);
    }
  }, [inactiveVitalCollectionRes]);

  useEffect(() => {
    sessionManagementListLoader(getPatientDetailsTypeofSessionListValue?.loading);
    if (
      getPatientDetailsTypeofSessionListValue?.getPatientDetailsTypeofSessionListRes
        ?.mandatoryPatientDeatilTypeList !== undefined
    ) {
      let healthcampAppoinmentArr =
        getPatientDetailsTypeofSessionListValue?.getPatientDetailsTypeofSessionListRes?.mandatoryPatientDeatilTypeList.map(
          (element: MandatoryPatientDeatilTypeList) => ({
            id: element.id,
            displayValue: element.displayValue,
            name: element.name,
            sequence: element.sequence,
          }),
        );
      setSessionManagementGridArray(healthcampAppoinmentArr);
    }
    if (getPatientDetailsTypeofSessionListValue?.errorMsg !== "") {
      setNotAdministratorMsg(getPatientDetailsTypeofSessionListValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDetailsTypeofSessionListValue]);

  useEffect(() => {
    if (addpatientDetailsTypeToSessionValue?.successMsg !== "") {
      setPatientDetailsTypeToSessionMsg("success");
      setPatientDetailsTypeToSessionAPIMsg(addpatientDetailsTypeToSessionValue?.successMsg);
      setShowFilterDialog(false);
      let sessionManageMentApiBody = {
        healthcampSessionId: sessionId,
      } as GetPatientDetailsTypeOfSessionBody;
      dispatch(getPatientDetailsTypeOfSessionList(sessionManageMentApiBody));
    }
    if (addpatientDetailsTypeToSessionValue?.errorMsg !== "") {
      setPatientDetailsTypeToSessionMsg("error");
      setPatientDetailsTypeToSessionAPIMsg(addpatientDetailsTypeToSessionValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addpatientDetailsTypeToSessionValue]);

  const columns: GridColDef[] = [
    {
      field: "displayValue",
      headerName: "Patient Details Type",
      minWidth: 300,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 300,
      align: "left",
    },
    {
      field: "sequence",
      headerName: "Sequence",
      minWidth: 300,
      align: "left",
      flex: 1,
    },
  ];

  const GetPatientDetailsTypeOfSessionList = (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card sx={{ p: 2 }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">All Vital Collection Request Inactive Session</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => inactiveSessionRequest()}
                  >
                    Set Inactive Session Request
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {vitalCollectionReqMsg !== "" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
              <Alert severity={VitalCollectionReqMsgType}>{vitalCollectionReqMsg}</Alert>
            </Grid>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card sx={{ p: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6">Mandatory Patient Details Type List</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => setShowFilterDialog(true)}
                  >
                    Add Mapping
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {notAdministratorMsg !== "" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
              <Alert severity="error">{notAdministratorMsg}</Alert>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
              {getPatientDetailsTypeofSessionListValue?.getPatientDetailsTypeofSessionListRes
                ?.mandatoryPatientDeatilTypeList !== undefined &&
              getPatientDetailsTypeofSessionListValue?.getPatientDetailsTypeofSessionListRes
                ?.mandatoryPatientDeatilTypeList.length > 0 ? (
                <DataGrid
                  rows={sessionManagementGridArray}
                  columns={columns}
                  getRowId={(row) => row.id}
                  disableSelectionOnClick
                  hideFooter
                  hideFooterPagination
                  experimentalFeatures={{ newEditingApi: true }}
                  autoHeight
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              ) : (
                <MWExceptionList />
              )}
            </Grid>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} m={2}>
        <DataCurationProblemListComponent />
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {GetPatientDetailsTypeOfSessionList}
      <AddMappingDialog
        filterDialogEnable={showFilterDialog}
        filterDialogUpdateState={updateFilterDialogValFromChild}
      />

      {patientDetailsTypeToSessionAPIMsg !== "" ? (
        <MWSnackbar
          msg={patientDetailsTypeToSessionAPIMsg}
          type={patientDetailsTypeToSessionMsg}
          alertClose={closePatientDetailsTypeToSessionMsg}
        />
      ) : null}
    </Box>
  );
}
