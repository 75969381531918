import {
  GET_JUDGING_LIST_ID_DATA,
  GET_JUDGING_LIST_ID_FAIL,
  GET_JUDGING_LIST_ID_LOADING,
  GET_JUDGING_LIST_ID_SUCCESS,
  GetJudgingDetailsListActionTypes,
} from "./ActionTypes";
import { PageCount, GetJudgingDetailsListDetails } from "./Model";

export const juryCompitionIdAction = (
  GetJudgingDetailsListDetails: GetJudgingDetailsListDetails,
  pageCount: PageCount,
): GetJudgingDetailsListActionTypes => {
  return {
    type: GET_JUDGING_LIST_ID_DATA,
    payload: GetJudgingDetailsListDetails,
    pageCount: pageCount,
  };
};

export const getJudgingDetailsListLoadingAction = (
  loading: boolean,
): GetJudgingDetailsListActionTypes => {
  return {
    type: GET_JUDGING_LIST_ID_LOADING,
    loading: loading,
  };
};

export const getJudgingDetailsListSuccessAction = (
  getJudgingDetailsListResponse: GetJudgingDetailsListDetails,
): GetJudgingDetailsListActionTypes => {
  return {
    type: GET_JUDGING_LIST_ID_SUCCESS,
    payload: getJudgingDetailsListResponse,
  };
};

export const getJudgingDetailsListErrorAction = (
  getJudgingDetailsListResponse: GetJudgingDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingDetailsListActionTypes => {
  return {
    type: GET_JUDGING_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
export const getJudgingDetailsListAPIMsgAction = (
  getJudgingDetailsListResponse: GetJudgingDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingDetailsListActionTypes => {
  return {
    type: GET_JUDGING_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};

export const getJudgingDetailsListAPIResClearAction = (
  getJudgingDetailsListResponse: GetJudgingDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingDetailsListActionTypes => {
  return {
    type: GET_JUDGING_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
