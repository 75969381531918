import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetAppointmentPaymentListActionTypes } from "./ActionTypes";
import { GetPaymentListBody, GetAppointmentPaymentListRes } from "./Model";
import {
  getAppointmentPaymentListAPIResClearAction,
  getAppointmentPaymentListErrorAction,
  getAppointmentPaymentListLoadingAction,
  getAppointmentPaymentListSuccessAction,
  getAppointmentPaymentListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetAppointmentPaymentListRes;
export const getAppointmentPaymentList = (payload: GetPaymentListBody) => {
  return function (dispatch: Dispatch<GetAppointmentPaymentListActionTypes>) {
    dispatch(getAppointmentPaymentListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/paymentapp/api/payment/get_payment_link`, payload, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(getAppointmentPaymentListLoadingAction(false));
        dispatch(
          getAppointmentPaymentListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetAppointmentPaymentListRes),
            "Payment list has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getAppointmentPaymentListLoadingAction(false));
        dispatch(
          getAppointmentPaymentListErrorAction(
            {} as GetAppointmentPaymentListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetAppointmentPaymentListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetAppointmentPaymentListActionTypes>) {
    dispatch(
      getAppointmentPaymentListUpdateAPIMsgAction(
        apiRes as GetAppointmentPaymentListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearAppointmentPaymentListAPIRes = () => {
  return function (dispatch: Dispatch<GetAppointmentPaymentListActionTypes>) {
    dispatch(
      getAppointmentPaymentListAPIResClearAction({} as GetAppointmentPaymentListRes, "", "", 0),
    );
  };
};
