import {
  AssigntaskToExecutiveStateType,
  AssignTaskToExecutiveTypes,
  ASSIGN_EXECUTIVE_FAIL,
  ASSIGN_EXECUTIVE_LOADING,
  ASSIGN_EXECUTIVE_SUCCESS,
  ExecutiveDropdownListStateType,
  ExecutiveDropdownListTypes,
  ExecutiveTaskListStateType,
  ExecutiveTaskListTypes,
  EXECUTIVE_DROPDOWN_LIST_FAIL,
  EXECUTIVE_DROPDOWN_LIST_LOADING,
  EXECUTIVE_DROPDOWN_LIST_SUCCESS,
  EXECUTIVE_TASK_LIST_FAIL,
  EXECUTIVE_TASK_LIST_LOADING,
  EXECUTIVE_TASK_LIST_SUCCESS,
  TaskCloseStateType,
  TaskCloseTypes,
  TASK_CLOSE_FAIL,
  TASK_CLOSE_LOADING,
  TASK_CLOSE_SUCCESS,
} from "./ActionTypes";

// Add patient in corporate package
const initialStateExecutiveDropDownList: ExecutiveDropdownListStateType = {
  loading: false,
  executiveList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getExecutiveDropdownListReducer = (
  state = initialStateExecutiveDropDownList,
  action: ExecutiveDropdownListTypes,
): ExecutiveDropdownListStateType => {
  switch (action.type) {
    case EXECUTIVE_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        executiveList: action.payload,
        message: action.message,
        status: action.status,
      };
    case EXECUTIVE_DROPDOWN_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EXECUTIVE_DROPDOWN_LIST_FAIL:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false,
      };
    default:
      return state;
  }
};
// Add patient in corporate package
const initialStateAssignExecutive: AssigntaskToExecutiveStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const assigntaskToExecutiveReducer = (
  state = initialStateAssignExecutive,
  action: AssignTaskToExecutiveTypes,
): AssigntaskToExecutiveStateType => {
  switch (action.type) {
    case ASSIGN_EXECUTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ASSIGN_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ASSIGN_EXECUTIVE_FAIL:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false,
      };
    default:
      return state;
  }
};

// Add patient in corporate package
const initialStateTaskClose: TaskCloseStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const taskCloseReducer = (
  state = initialStateTaskClose,
  action: TaskCloseTypes,
): TaskCloseStateType => {
  switch (action.type) {
    case TASK_CLOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case TASK_CLOSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TASK_CLOSE_FAIL:
      return {
        ...state,
        message: action.message,
        status: action.status,
        loading: false,
      };
    default:
      return state;
  }
};

// Task List
const initialStateExecutiveTaskList: ExecutiveTaskListStateType = {
  loading: false,
  executiveList: [],
};

export const getExecutiveTaskListReducer = (
  state = initialStateExecutiveTaskList,
  action: ExecutiveTaskListTypes,
): ExecutiveTaskListStateType => {
  switch (action.type) {
    case EXECUTIVE_TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        executiveList: action.payload,
      };
    case EXECUTIVE_TASK_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EXECUTIVE_TASK_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
