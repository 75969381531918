import {
  INSTANCE_VIEW_HISTORY_FAIL,
  INSTANCE_VIEW_HISTORY_LOADING,
  INSTANCE_VIEW_HISTORY_SUCCESS,
  INSTANCE_VIEW_HISTORY_UPDATE_API_MSG,
  INSTANCE_VIEW_HISTORY_UPDATE_API_RES,
  ViewHistoryInstanceActionTypes,
} from "./ActionTypes";
import { ViewHistoryInstanceRes } from "./Model";

export const viewHistoryInstanceLoadingAction = (
  loading: boolean,
): ViewHistoryInstanceActionTypes => {
  return {
    type: INSTANCE_VIEW_HISTORY_LOADING,
    loading: loading,
  };
};

export const viewHistoryInstanceSuccessAction = (
  viewHistoryInstanceResponse: ViewHistoryInstanceRes,
  successMsg: string,
): ViewHistoryInstanceActionTypes => {
  return {
    type: INSTANCE_VIEW_HISTORY_SUCCESS,
    payload: viewHistoryInstanceResponse,
    successMsg: successMsg,
  };
};

export const viewHistoryInstanceErrorAction = (
  viewHistoryInstanceResponse: ViewHistoryInstanceRes,
  errMsg: string,
  status: number,
): ViewHistoryInstanceActionTypes => {
  return {
    type: INSTANCE_VIEW_HISTORY_FAIL,
    payload: viewHistoryInstanceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const viewHistoryInstanceUpdateAPIMsgAction = (
  viewHistoryInstanceResponse: ViewHistoryInstanceRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ViewHistoryInstanceActionTypes => {
  return {
    type: INSTANCE_VIEW_HISTORY_UPDATE_API_MSG,
    payload: viewHistoryInstanceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const viewHistoryInstanceAPIResClearAction = (
  viewHistoryInstanceResponse: ViewHistoryInstanceRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ViewHistoryInstanceActionTypes => {
  return {
    type: INSTANCE_VIEW_HISTORY_UPDATE_API_RES,
    payload: viewHistoryInstanceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
