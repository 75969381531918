import { DoctorSearchDetails } from "./Model";

// Patient Demographics Loading State
export const DOCTOR_SEARCH_LOADING = "DOCTOR_SEARCH_LOADING";
export const DOCTOR_SEARCH_SUCCESS = "DOCTOR_SEARCH_SUCCESS";
export const DOCTOR_SEARCH_FAIL = "DOCTOR_SEARCH_FAIL";
export const DOCTOR_SEARCH_UPDATE_API_MSG = "DOCTOR_SEARCH_UPDATE_API_MSG";
export const DOCTOR_SEARCH_UPDATE_API_RES = "DOCTOR_SEARCH_UPDATE_API_RES";

export interface DoctorSearchDetailsState {
  DoctorSearchRes: DoctorSearchDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface DoctorSearchLoading {
  type: typeof DOCTOR_SEARCH_LOADING;
  loading: boolean;
}
export interface DoctorSearchSuccess {
  type: typeof DOCTOR_SEARCH_SUCCESS;
  payload: DoctorSearchDetails;
}
export interface DoctorSearchFail {
  type: typeof DOCTOR_SEARCH_FAIL;
  payload: DoctorSearchDetails;
  message: string;
  status: number;
}
export interface DoctorSearchUpdateAPIMsg {
  type: typeof DOCTOR_SEARCH_UPDATE_API_MSG;
  payload: DoctorSearchDetails;
  message: string;
  status: number;
}
export interface DoctorSearchUpdateAPIRes {
  type: typeof DOCTOR_SEARCH_UPDATE_API_RES;
  payload: DoctorSearchDetails;
  message: string;
  status: number;
}
export type DoctorSearchActionTypes =
  | DoctorSearchLoading
  | DoctorSearchSuccess
  | DoctorSearchFail
  | DoctorSearchUpdateAPIMsg
  | DoctorSearchUpdateAPIRes;
