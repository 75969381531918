import {
  ViewHistorySessionActionTypes,
  VIEW_HISTORY_SESSION_FAIL,
  VIEW_HISTORY_SESSION_LOADING,
  VIEW_HISTORY_SESSION_SUCCESS,
  ViewHistorySessionState,
  VIEW_HISTORY_SESSION_UPDATE_API_MSG,
  VIEW_HISTORY_SESSION_UPDATE_API_RES,
} from "./ActionTypes";
import { ViewHistorySessionRes } from "./Model";

const initialStateGetPosts: ViewHistorySessionState = {
  loading: false,
  viewHistorySession: {} as ViewHistorySessionRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const viewHistoryBySessionIdReducer = (
  state = initialStateGetPosts,
  action: ViewHistorySessionActionTypes,
): ViewHistorySessionState => {
  switch (action.type) {
    case VIEW_HISTORY_SESSION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case VIEW_HISTORY_SESSION_SUCCESS:
      return {
        ...state,
        viewHistorySession: action.payload,
        successMsg: action.successMsg,
      };
    case VIEW_HISTORY_SESSION_FAIL:
      return {
        ...state,
        viewHistorySession: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case VIEW_HISTORY_SESSION_UPDATE_API_MSG:
      return {
        ...state,
        viewHistorySession: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case VIEW_HISTORY_SESSION_UPDATE_API_RES:
      return {
        ...state,
        viewHistorySession: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
