import { PageCount, PatientDetailsFixedDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_DETAILS_FIXED_LOADING = "PATIENT_DETAILS_FIXED_LOADING";
export const PATIENT_DETAILS_FIXED_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const PATIENT_DETAILS_FIXED_FAIL = "PATIENT_DETAILS_FIXED_FAIL";
export const PATIENT_DETAILS_FIXED_DATA = "PATIENT_DETAILS_FIXED_DATA";
export const PATIENT_DETAILS_FIXED_API_MSG = "PATIENT_DETAILS_FIXED_API_MSG";
export const PATIENT_DETAILS_FIXED_API_RES = "PATIENT_DETAILS_FIXED_API_RES";

export interface PatientDetailsFixedState {
  patientDetailsFixedRes: PatientDetailsFixedDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsFixedLoading {
  type: typeof PATIENT_DETAILS_FIXED_LOADING;
  loading: boolean;
}
export interface PatientDetailsFixedSuccess {
  type: typeof PATIENT_DETAILS_FIXED_SUCCESS;
  payload: PatientDetailsFixedDetails;
  successMsg: string;
}
export interface PatientDetailsFixedFail {
  type: typeof PATIENT_DETAILS_FIXED_FAIL;
  payload: PatientDetailsFixedDetails;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsFixedAPIMsg {
  type: typeof PATIENT_DETAILS_FIXED_API_MSG;
  payload: PatientDetailsFixedDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsFixedAPIRes {
  type: typeof PATIENT_DETAILS_FIXED_API_RES;
  payload: PatientDetailsFixedDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface PatientDetailsFixedDataAction {
  type: typeof PATIENT_DETAILS_FIXED_DATA;
  payload: PatientDetailsFixedDetails;
  pageCount: PageCount;
}

export type PatientDetailsFixedActionTypes =
  | PatientDetailsFixedDataAction
  | PatientDetailsFixedLoading
  | PatientDetailsFixedSuccess
  | PatientDetailsFixedFail
  | PatientDetailsFixedAPIMsg
  | PatientDetailsFixedAPIRes;
