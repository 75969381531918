import { GetPreferenceResponse } from "./Model";
export const GET_PREFERENCE_LOADING = "GET_PREFERENCE_LOADING";
export const GET_PREFERENCE_SUCCESS = "GET_PREFERENCE_SUCCESS";
export const GET_PREFERENCE_FAIL = "GET_PREFERENCE_FAIL";
export const GET_PREFERENCE_DATA = "GET_PREFERENCE_DATA";
export const GET_PREFERENCE_UPDATE_API_MSG = "GET_PREFERENCE_UPDATE_API_MSG";
export const GET_PREFERENCE_UPDATE_API_RES = "GET_PREFERENCE_UPDATE_API_RES";

export interface GetPreferenceDetailsState {
  GetPreferenceDetailsResponse: GetPreferenceResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPreferenceDetailsLoading {
  type: typeof GET_PREFERENCE_LOADING;
  loading: boolean;
}
export interface GetPreferenceDetailsSuccess {
  type: typeof GET_PREFERENCE_SUCCESS;
  payload: GetPreferenceResponse;
  successMsg: string;
}
export interface GetPreferenceDetailsFail {
  type: typeof GET_PREFERENCE_FAIL;
  payload: GetPreferenceResponse;
  errorMsg: string;
  status: number;
}
export interface GetPreferenceDetailsUpdateAPIMsg {
  type: typeof GET_PREFERENCE_UPDATE_API_MSG;
  payload: GetPreferenceResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPreferenceDetailsUpdateAPIRes {
  type: typeof GET_PREFERENCE_UPDATE_API_RES;
  payload: GetPreferenceResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPreferenceDetailsActionTypes =
  | GetPreferenceDetailsLoading
  | GetPreferenceDetailsSuccess
  | GetPreferenceDetailsFail
  | GetPreferenceDetailsUpdateAPIMsg
  | GetPreferenceDetailsUpdateAPIRes;
