import { ToggleActiveRegReqDetailsRes } from "./Model";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG";
export const TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES = "TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES";

export interface ToggleActiveRegistrationRequestDetailsState {
  toggleActiveRegReqDetailsList: ToggleActiveRegReqDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveRegistrationRequestDetailsLoading {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING;
  loading: boolean;
}
export interface ToggleActiveRegistrationRequestDetailsSuccess {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS;
  payload: ToggleActiveRegReqDetailsRes;
  successMsg: string;
}
export interface ToggleActiveRegistrationRequestDetailsFail {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL;
  payload: ToggleActiveRegReqDetailsRes;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveRegistrationRequestDetailsUpdateAPIMsg {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG;
  payload: ToggleActiveRegReqDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ToggleActiveRegistrationRequestDetailsUpdateAPIRes {
  type: typeof TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES;
  payload: ToggleActiveRegReqDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ToggleActiveRegistrationRequestDetailsActionTypes =
  | ToggleActiveRegistrationRequestDetailsLoading
  | ToggleActiveRegistrationRequestDetailsSuccess
  | ToggleActiveRegistrationRequestDetailsFail
  | ToggleActiveRegistrationRequestDetailsUpdateAPIMsg
  | ToggleActiveRegistrationRequestDetailsUpdateAPIRes;
