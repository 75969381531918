import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DataListByObservationTypeMappingListActionTypes } from "./ActionTypes";
import { DataListByObservationTypeMappingListBody, DataListByObservationTypeMappingListRes } from "./Model";
import {
  dataListByObservationTypeMappingListAPIResClearAction,
  dataListByObservationTypeMappingListErrorAction,
  dataListByObservationTypeMappingListLoadingAction,
  dataListByObservationTypeMappingListSuccessAction,
  dataListByObservationTypeMappingListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as DataListByObservationTypeMappingListRes;
export const dataListByObservationTypeMappingList = (payload: DataListByObservationTypeMappingListBody) => {
  return function (dispatch: Dispatch<DataListByObservationTypeMappingListActionTypes>) {
    dispatch(dataListByObservationTypeMappingListLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/patientapp/api/patientdashboard/patientDashboardUiComponentObservationTypeMappingList`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(dataListByObservationTypeMappingListLoadingAction(false));
        dispatch(
          dataListByObservationTypeMappingListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "patient observation mapping list showing successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(dataListByObservationTypeMappingListLoadingAction(false));
        dispatch(
          dataListByObservationTypeMappingListErrorAction(
            {} as DataListByObservationTypeMappingListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDataListByObservationTypeMappingListAPIResMsg = () => {
  return function (dispatch: Dispatch<DataListByObservationTypeMappingListActionTypes>) {
    dispatch(
      dataListByObservationTypeMappingListUpdateAPIMsgAction(
        apiRes as DataListByObservationTypeMappingListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearDataListByObservationTypeMappingListAPIRes = () => {
  return function (dispatch: Dispatch<DataListByObservationTypeMappingListActionTypes>) {
    dispatch(
      dataListByObservationTypeMappingListAPIResClearAction({} as DataListByObservationTypeMappingListRes, "", "", 0),
    );
  };
};
