import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useParams } from "react-router-dom";
import MWExceptionList from "component/MWExceptionList";
import { AppState } from "redux/store/Store";
import React from "react";
import { DataCurationProblemList } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/Model";
import dayjs from "dayjs";
import { getDataCurationProblemListApi } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/API";
import HealthCampAddDataCurationDialog from "./HealthCampAddDataCurationDialog";
import MWSnackbar from "component/MWSnackbar";
import { clearAddDataCurationProblemAPIRes } from "redux/HealthcampSessionList/AddDataCuration/API";

type Props = {
  instanceManagementLoader: (value: boolean) => void;
  count: (value: string) => void;
};

export default function HealthCampDataCurationProblemListComponent({
  instanceManagementLoader,
  count,
}: Props) {
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };

  useEffect(() => {
    let apiBody = {
      healthcampId: healthcampid,
    };
    dispatch(getDataCurationProblemListApi(apiBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [addDataCurationProblemMessage, setAddDataCurationProblemMessage] = useState("");
  const [addDataCurationProblemMessageType, setAddDataCurationProblemMessageType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  // call Store
  const dataCurationProblemListRes = useSelector(
    (state: AppState) => state.getDataCurationProblemListValue,
  );

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    instanceManagementLoader(dataCurationProblemListRes?.loading);
    dataCurationProblemListRes?.getDataCurationProblemListRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(dataCurationProblemListRes?.getDataCurationProblemListRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (dataCurationProblemListRes?.getDataCurationProblemListRes?.count !== undefined) {
      count(String(dataCurationProblemListRes?.getDataCurationProblemListRes?.count));

      let dataPointArr =
        dataCurationProblemListRes?.getDataCurationProblemListRes?.dataCurationProblemList.map(
          (element: DataCurationProblemList) => ({
            id: element.id,
            problemType: element.problemType,
            description: element.description,
            targetModelId: element.targetModelId,
            sourceModelId: element.sourceModelId,
            healthcampAssociatedId: element.healthcampAssociatedId,
            destinationModelId: element.destinationModelId,
            createDate: dayjs(element.createDate).format("MM/DD/YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("ddd, MMM DD, YYYY h:mm A"),
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(dataCurationProblemListRes?.getDataCurationProblemListRes?.numberOfPages),
      );
      count("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurationProblemListRes]);

  const addDataCurationProbResponse = useSelector(
    (state: AppState) => state.addDataCurationProbValues,
  );
  useEffect(() => {
    if (addDataCurationProbResponse?.successMsg !== "") {
      setAddDataCurationProblemMessageType("success");
      setAddDataCurationProblemMessage(addDataCurationProbResponse?.successMsg);
      if (addDataCurationProbResponse?.errorMsg !== "") {
        setAddDataCurationProblemMessageType("error");
        setAddDataCurationProblemMessage(addDataCurationProbResponse?.errorMsg);
      }
      let apiBody = {
        healthcampId: healthcampid,
      };
      dispatch(getDataCurationProblemListApi(apiBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataCurationProbResponse]);

  const [showAddDataCurationDialog, setShowAddDataCurationDialog] = useState(false);
  const updateAddDataCurationDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataCurationDialog(passedVal);
  };

  const columns: GridColDef[] = [
    {
      field: "problemType",
      headerName: "Problem Type",
      align: "left",
      minWidth: 280,
    },
    {
      field: "description",
      headerName: "Description",
      align: "left",
      flex: 1,
    },
    {
      field: "targetModelId",
      headerName: "Target Id",
      minWidth: 100,
      align: "right",
    },
    {
      field: "sourceModelId",
      headerName: "Source Id",
      minWidth: 100,
      align: "right",
    },
    {
      field: "destinationModelId",
      headerName: "Destination Id",
      minWidth: 100,
      align: "right",
    },
    {
      field: "healthcampAssociatedId",
      headerName: "HealthCamp Associated",
      minWidth: 170,
      align: "right",
    },
    {
      field: "createDate",
      headerName: "Create Date",
      minWidth: 150,
      align: "left",
    },
  ];

  const GetDataCurationProblemList = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6">Data Curation Problem List</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={dataCurationProblemListRes?.getDataCurationProblemListRes?.count}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <IconButton>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      setShowAddDataCurationDialog(true);
                    }}
                    fullWidth={fullScreen}
                  >
                    Add Data Curation
                  </Button>
                </Grid>
                {!fullScreen && (
                  <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
                    <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                  </Grid>
                )}
                <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray.length > 0 ? (
          <Box>
            {fullScreen ? (
              <Box sx={{ height: "calc(100vh - 20vh)" }}>
                <DataGrid
                  rows={dataGridArray}
                  columns={columns}
                  disableSelectionOnClick
                  hideFooter
                  hideFooterPagination
                  experimentalFeatures={{ newEditingApi: true }}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  rowHeight={50}
                />
              </Box>
            ) : (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rowHeight={50}
              />
            )}
          </Box>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddDataCurationProblemAPIRes());
      setAddDataCurationProblemMessage("");
    }
  };

  return (
    <Box>
      {GetDataCurationProblemList}
      {showAddDataCurationDialog && (
        <HealthCampAddDataCurationDialog
          filterDialogEnable={showAddDataCurationDialog}
          filterDialogUpdateState={updateAddDataCurationDialogValFromChild}
        />
      )}
      {addDataCurationProblemMessage !== "" ? (
        <MWSnackbar
          msg={addDataCurationProblemMessage}
          type={addDataCurationProblemMessageType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
