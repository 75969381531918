import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AppointmentDeactivateResponse } from "./Model";
import {
  appointmentDeactivateAPIResClearAction,
  appointmentDeactivateErrorAction,
  appointmentDeactivateLoadingAction,
  appointmentDeactivateSuccessAction,
  appointmentDeactivateUpdateAPIMsgAction,
} from "./Actions";
import { AppointmentDeactivateActionTypes } from "./ActionTypes";
import { headers } from "../../../component/Utility";

let apiRes = {} as AppointmentDeactivateResponse;
export const appointmentDeactivateCallApi = (appointmentId: number) => {
  return function (dispatch: Dispatch<AppointmentDeactivateActionTypes>) {
    dispatch(appointmentDeactivateLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/appointmentapp/api/appointmentList/deactivate_appointment_for_admin`,
        { appointmentId: String(appointmentId) },
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(appointmentDeactivateLoadingAction(false));
        dispatch(
          appointmentDeactivateSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Appointment deactivated successfully",
            res.data.status,
          ),
        );
      })
      .catch((error) => {
        dispatch(appointmentDeactivateLoadingAction(false));
        dispatch(
          appointmentDeactivateErrorAction(
            {} as AppointmentDeactivateResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const appointmentDeactivateAPIResMsg = () => {
  return function (dispatch: Dispatch<AppointmentDeactivateActionTypes>) {
    dispatch(
      appointmentDeactivateUpdateAPIMsgAction(apiRes as AppointmentDeactivateResponse, "", 0),
    );
  };
};

export const clearappointmentDeactivateAPIRes = () => {
  return function (dispatch: Dispatch<AppointmentDeactivateActionTypes>) {
    dispatch(appointmentDeactivateAPIResClearAction({} as AppointmentDeactivateResponse, "", 0));
  };
};
