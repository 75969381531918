import { ConfirmAppointmentRes } from "./Model";
export const CONFIRM_APPOINTMENT_LOADING = "CONFIRM_APPOINTMENT_LOADING";
export const CONFIRM_APPOINTMENT_SUCCESS = "CONFIRM_APPOINTMENT_SUCCESS";
export const CONFIRM_APPOINTMENT_FAIL = "CONFIRM_APPOINTMENT_FAIL";
export const CONFIRM_APPOINTMENT_UPDATE_API_MSG = "CONFIRM_APPOINTMENT_UPDATE_API_MSG";
export const CONFIRM_APPOINTMENT_UPDATE_API_RES = "CONFIRM_APPOINTMENT_UPDATE_API_RES";

export interface ConfirmAppointmentState {
  confirmAppointmentRes: ConfirmAppointmentRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConfirmAppointmentLoading {
  type: typeof CONFIRM_APPOINTMENT_LOADING;
  loading: boolean;
}
export interface ConfirmAppointmentSuccess {
  type: typeof CONFIRM_APPOINTMENT_SUCCESS;
  payload: ConfirmAppointmentRes;
  successMsg: string;
}
export interface ConfirmAppointmentFail {
  type: typeof CONFIRM_APPOINTMENT_FAIL;
  payload: ConfirmAppointmentRes;
  errorMsg: string;
  status: number;
}
export interface ConfirmAppointmentUpdateAPIMsg {
  type: typeof CONFIRM_APPOINTMENT_UPDATE_API_MSG;
  payload: ConfirmAppointmentRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConfirmAppointmentUpdateAPIRes {
  type: typeof CONFIRM_APPOINTMENT_UPDATE_API_RES;
  payload: ConfirmAppointmentRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ConfirmAppointmentActionTypes =
  | ConfirmAppointmentLoading
  | ConfirmAppointmentSuccess
  | ConfirmAppointmentFail
  | ConfirmAppointmentUpdateAPIMsg
  | ConfirmAppointmentUpdateAPIRes;
