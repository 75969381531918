import {
  AddPatientBySessionIdActionTypes,
  ADD_PATIENT_BY_SESSION_ID_FAIL,
  ADD_PATIENT_BY_SESSION_ID_LOADING,
  ADD_PATIENT_BY_SESSION_ID_SUCCESS,
  AddPatientBySessionIdState,
  ADD_PATIENT_BY_SESSION_ID_API_MSG,
  ADD_PATIENT_BY_SESSION_ID_API_RES,
} from "./ActionTypes";
import { AddPatientBySessionIdDetails } from "./Model";

const initialStateGetPosts: AddPatientBySessionIdState = {
  loading: false,
  addPatientBySessionIdRes: {} as AddPatientBySessionIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addPatientBySessionIdReducer = (
  state = initialStateGetPosts,
  action: AddPatientBySessionIdActionTypes,
): AddPatientBySessionIdState => {
  switch (action.type) {
    case ADD_PATIENT_BY_SESSION_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        addPatientBySessionIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_BY_SESSION_ID_FAIL:
      return {
        ...state,
        addPatientBySessionIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_BY_SESSION_ID_API_MSG:
      return {
        ...state,
        addPatientBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_BY_SESSION_ID_API_RES:
      return {
        ...state,
        addPatientBySessionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
