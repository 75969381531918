import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PageLayoutProps {
  children?: ReactNode;
}
const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <React.Fragment>
      {!fullScreen ? (
        <Container maxWidth="xl">
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            pt={2}
          >
            {children}
          </Stack>
        </Container>
      ) : (
        <Box px={2}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            // pt={2}
          >
            {children}
          </Stack>
        </Box>
      )}
    </React.Fragment>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

export default PageLayout;
