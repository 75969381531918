import {
    AddAppoinmentListToHealthcampActionTypes,
   ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL,
   ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING,
   ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS,
   AddAppoinmentListToHealthcampState,
   ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG,
   ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES,
 } from "./ActionTypes";
 import { AddAppointmentListHealthcampRes } from "./Model";
 
 const initialStateGetPosts: AddAppoinmentListToHealthcampState = {
   loading: false,
   addAppoinmentListToHealthcampResponse: {} as AddAppointmentListHealthcampRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const  addAppointmentToHealthcampInstanceReducer = (
   state = initialStateGetPosts,
   action:  AddAppoinmentListToHealthcampActionTypes,
 ): AddAppoinmentListToHealthcampState => {
   switch (action.type) {
     case ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS:
       return {
         ...state,
         addAppoinmentListToHealthcampResponse: action.payload,
         successMsg: action.successMsg,
       };
     case ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL:
       return {
         ...state,
         addAppoinmentListToHealthcampResponse: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG:
       return {
         ...state,
         addAppoinmentListToHealthcampResponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES:
       return {
         ...state,
         addAppoinmentListToHealthcampResponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 