import {
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG,
  ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES,
  AddExistingPatientByProviderOrgIdActionTypes,
} from "./ActionTypes";
import { AddExistingPatientByProviderOrgIdDetails } from "./Model";

export const addExistingPatientByProviderOrgIdLoadingAction = (
  loading: boolean,
): AddExistingPatientByProviderOrgIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_LOADING,
    loading: loading,
  };
};

export const addExistingPatientByProviderOrgIdSuccessAction = (
  addExistingPatientByProviderOrgIdResponse: AddExistingPatientByProviderOrgIdDetails,
  successMsg: string,
): AddExistingPatientByProviderOrgIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_SUCCESS,
    payload: addExistingPatientByProviderOrgIdResponse,
    successMsg: successMsg,
  };
};

export const addExistingPatientByProviderOrgIdErrorAction = (
  addExistingPatientByProviderOrgIdResponse: AddExistingPatientByProviderOrgIdDetails,
  errMsg: string,
  status: number,
): AddExistingPatientByProviderOrgIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_FAIL,
    payload: addExistingPatientByProviderOrgIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addExistingPatientByProviderOrgIdAPIMsgAction = (
  addExistingPatientByProviderOrgIdResponse: AddExistingPatientByProviderOrgIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExistingPatientByProviderOrgIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_MSG,
    payload: addExistingPatientByProviderOrgIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addExistingPatientByProviderOrgIdAPIResClearAction = (
  addExistingPatientByProviderOrgIdResponse: AddExistingPatientByProviderOrgIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExistingPatientByProviderOrgIdActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_BY_PROVIDER_ORG_ID_API_RES,
    payload: addExistingPatientByProviderOrgIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
