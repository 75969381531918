import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SleepQuality from "../../../image/design-assets/sleep-quality.svg";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { useEffect, useState } from "react";
import { PatientObservationsList } from "redux/patientDashboardLatestDataList/Model";
import { useHistory, useParams } from "react-router-dom";
import { formatTimeRange, formatTimeString } from "component/Utility";

export default function SleepSummary() {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const getPatientDashboardLatestDataListResponse = useSelector(
    (state: AppState) => state.getPatientDashboardLatestDataListRes,
  );
  const [observationList, setObservationList] = useState([] as any[]);
  useEffect(() => {
    if (
      getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
        ?.patientObservationsList !== undefined
    ) {
      if (
        getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
          ?.patientObservationsList.length > 0
      ) {
        // map array item with selected fields
        let ptList =
          getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              name: element.LatestObservation.patientObservationType.displayValue,
              value:
                element.LatestObservation.patientObservationType.name === "sleepschedule"
                  ? formatTimeRange(element.LatestObservation.value)
                  : element.LatestObservation.value ||
                    element.LatestObservation.patientObservationType.name === "sleephours"
                  ? formatTimeString(element.LatestObservation.value)
                  : element.LatestObservation.value,
              type: element.LatestObservation.patientObservationType.name,
            }),
          );
        let values = ptList.filter(
          (person) =>
            person.type === "sleep" ||
            person.type === "sleepschedule" ||
            person.type === "sleephours" ||
            person.type === "sleeptracker" ||
            person.type === "sleepscore",
        );
        let order = [
          "Sleep Schedule",
          "Sleep Score",
          "Time Asleep",
          "Sleep Quality",
          "Sleep Tracking Device",
        ];
        let arrangeValues = values.sort((x, y) => order.indexOf(x.name) - order.indexOf(y.name));
        setObservationList(arrangeValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDashboardLatestDataListResponse]);

  const navigateToDetails = () => {
    history.push(`/observationdetails/${patientid}/sleep`);
  };

  function splitTimeRange(timeRange: any) {
    // Split the string by " ~ "
    const times = timeRange.split(" ~ ");
    return times;
  }

  return (
    <Card style={{ borderRadius: 20, height: "100%" }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <Avatar>
                <img
                  alt="sleep quality"
                  src={SleepQuality}
                  style={{
                    width: 60,
                    height: 130,
                  }}
                />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: "sans-serif" }}>
                Sleep
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {observationList.map((element: any, index: any, observationList: any) => (
            <Grid item>
              {index + 1 === observationList.length ? (
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  textAlign="left"
                  px={2}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      sx={{ fontWeight: "bold", mb: 1, color: "#08c0dd", fontFamily: "sans-serif" }}
                    >
                      {element.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      sx={{ fontWeight: "bold", mb: 1, fontSize: "20px", fontFamily: "sans-serif" }}
                    >
                      {element.value}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  textAlign="left"
                  sx={{ borderInlineEnd: "1px solid #8c8c89", px: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      sx={{ fontWeight: "bold", mb: 1, color: "#08c0dd", fontFamily: "sans-serif" }}
                    >
                      {element.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {element.type === "sleepschedule" ? (
                      <>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            mb: 1,
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {splitTimeRange(element.value)[0]}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            mb: 1,
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          to
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            mb: 1,
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {splitTimeRange(element.value)[1]}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                        }}
                      >
                        {element.value}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Button
                  variant="text"
                  endIcon={<ArrowRightAltIcon />}
                  sx={{ textTransform: "none" }}
                  onClick={() => navigateToDetails()}
                >
                  View All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
