import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetOrganizationAdminListActionTypes } from "./ActionTypes";
import { GetOrganizationAdminListBody, GetOrganizationAdminListRes } from "./Model";
import {
  getOrganizationAdminListAPIResClearAction,
  getOrganizationAdminListErrorAction,
  getOrganizationAdminListLoadingAction,
  getOrganizationAdminListSuccessAction,
  getOrganizationAdminListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetOrganizationAdminListRes;
export const getOrganizationAdminListByOrgId = (payload: GetOrganizationAdminListBody, pageNo: any) => {
  return function (dispatch: Dispatch<GetOrganizationAdminListActionTypes>) {
    dispatch(getOrganizationAdminListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientorganizationstafflist/staff_list_patient_organization?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getOrganizationAdminListLoadingAction(false));
        dispatch(
          getOrganizationAdminListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetOrganizationAdminListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Admin list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getOrganizationAdminListLoadingAction(false));
        dispatch(
          getOrganizationAdminListErrorAction(
            {} as GetOrganizationAdminListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetOrganizationAdminListByOrgIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetOrganizationAdminListActionTypes>) {
    dispatch(
      getOrganizationAdminListUpdateAPIMsgAction(apiRes as GetOrganizationAdminListRes, "", "", 0),
    );
  };
};

export const clearGetOrganizationAdminListByOrgIdAPIRes = () => {
  return function (dispatch: Dispatch<GetOrganizationAdminListActionTypes>) {
    dispatch(getOrganizationAdminListAPIResClearAction({} as GetOrganizationAdminListRes, "", "", 0));
  };
};
