import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { getPatientDataPointListAction } from "./Actions";
import {
  PatientPointDataListActionType,
  PATIENT_DATA_DETAILS_POINT_FAIL,
  PATIENT_DATA_DETAILS_POINT_LOADING,
  PATIENT_DATA_DETAILS_POINT_SUCCESS,
} from "./ActionTypes";
import { headers } from "../../../component/Utility";

export const getPatientDataDetailsList = (patientid: string, pageNo: any) => {
  return function (dispatch: Dispatch<PatientPointDataListActionType>) {
    dispatch({
      type: PATIENT_DATA_DETAILS_POINT_LOADING,
    });

    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientDetails/get_patientDetailsList_from_dashboard?page=${Number(
            pageNo,
          )}&size=50`,
        { patientId: patientid },
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data;
        let pageCount = res.data.numberOfPages;
        dispatch(getPatientDataPointListAction(resData, pageCount));
        dispatch({
          type: PATIENT_DATA_DETAILS_POINT_SUCCESS,
          payload: resData,
          pageCount: pageCount,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: PATIENT_DATA_DETAILS_POINT_FAIL,
        });
      });
  };
};
