import {
  AssignTaskToExecutiveTypes,
  ASSIGN_EXECUTIVE_TYPE,
  ExecutiveDropdownListTypes,
  ExecutiveTaskListTypes,
  EXECUTIVE_DROPDOWN_LIST_TYPE,
  EXECUTIVE_TASK_LIST_TYPE,
  TaskCloseTypes,
  TASK_CLOSE_TYPE,
} from "./ActionTypes";

// Executive list dropdown action
export const executiveDropdownListAction = (
  executiveList: any,
  message: string,
  status: number,
): ExecutiveDropdownListTypes => {
  return {
    type: EXECUTIVE_DROPDOWN_LIST_TYPE,
    payload: executiveList,
    status: status,
    message: message,
  };
};
// Assign task to Executive Action
export const assignTasktoExecutiveAction = (
  message: string,
  status: number,
): AssignTaskToExecutiveTypes => {
  return {
    type: ASSIGN_EXECUTIVE_TYPE,
    status: status,
    message: message,
  };
};
// Task Close Action
export const taskCloseAction = (message: string, status: number): TaskCloseTypes => {
  return {
    type: TASK_CLOSE_TYPE,
    status: status,
    message: message,
  };
};

// Executive list dropdown action
export const executiveTaskListAction = (executiveList: any): ExecutiveTaskListTypes => {
  return {
    type: EXECUTIVE_TASK_LIST_TYPE,
    payload: executiveList,
  };
};
