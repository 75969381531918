import { Autocomplete, Grid, TextField } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedPatientDetailsTypeList } from "./Model";
import { AssignPatientDetailsTypeBody } from "redux/AssignPatientDetailsType/Model";
import { assignPatientDetailsTypeCall } from "redux/AssignPatientDetailsType/API";
import { PatientDetailsTypeList } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/Model";
import { AppState } from "redux/store/Store";
import { useParams } from "react-router-dom";
import { getSessionPatientDetailsListApi } from "redux/HealthcampSessionList/SessionPatientDetailsList/API";
import { GetSessionPatientDetailsListBody } from "redux/HealthcampSessionList/SessionPatientDetailsList/Model";
export default function CustomPatientDetailsListOfSessionHeader() {
  const dispatch = useDispatch();
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const assignPatientDetailsTypeRes = useSelector(
    (state: AppState) => state.assignPatientDetailsTypeRes,
  );
  const [selectedItem, setSelectedItem] = useState([] as any[]);
  useEffect(() => {
    setSelectedItem(assignPatientDetailsTypeRes?.selectedItem);
  }, [assignPatientDetailsTypeRes]);
  const [patientDetailsTypeList, setPatientDetailsTypeList] = React.useState([] as any[]);
  const [selectedPatientDetailsType, setSelectedPatientDetailsType] =
    React.useState<ModifiedPatientDetailsTypeList | null>(null);

  function initiatePatientDetailsListOfSession() {
    dispatch(
      getSessionPatientDetailsListApi(
        { healthcampSessionId: sessionId } as GetSessionPatientDetailsListBody,
        1,
      ),
    );
  }
  async function handlePatientDetailsTypeChange(value: ModifiedPatientDetailsTypeList | null) {
    setSelectedPatientDetailsType(value);
    if (value !== null && selectedItem.length !== 0) {
      dispatch(
        assignPatientDetailsTypeCall({
          patientDetailsType: String(value.value),
          patientDetailsIdArray: selectedItem,
        } as AssignPatientDetailsTypeBody),
      );
      setTimeout(initiatePatientDetailsListOfSession, 1500);
      setSelectedPatientDetailsType(null);
    }
  }

  const patientDetailsTypeListRes = useSelector((state: AppState) => state.patientDetailsType);
  useEffect(() => {
    if (
      patientDetailsTypeListRes?.patientDetailsTypeListRes?.patientDetailsTypeList !== undefined
    ) {
      let patientDetailsTypeDropdownValue =
        patientDetailsTypeListRes?.patientDetailsTypeListRes?.patientDetailsTypeList.map(
          (element: PatientDetailsTypeList) => ({
            value: element.id,
            label: element.displayValue !== "" ? element.displayValue : "",
          }),
        );
      setPatientDetailsTypeList(patientDetailsTypeDropdownValue);
    } else setPatientDetailsTypeList([] as ModifiedPatientDetailsTypeList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsTypeListRes]);
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" p={1}>
      <Grid item>
        <Autocomplete
          id="license-period"
          disableClearable
          value={selectedPatientDetailsType}
          options={patientDetailsTypeList}
          onChange={(_event: any, newValue: any) => {
            handlePatientDetailsTypeChange(newValue);
          }}
          size="small"
          disabled={selectedItem.length === 0 || assignPatientDetailsTypeRes?.loading}
          renderInput={(params) => (
            <TextField {...params} label="Set Patient Details Type" variant="outlined" fullWidth />
          )}
          sx={{ minWidth: "350px" }}
        />
      </Grid>
      <Grid item>
        <GridToolbarQuickFilter />
      </Grid>
    </Grid>
  );
}
