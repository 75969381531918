import {
  AddPatientDataPointActionTypes,
  ADD_PATIENT_DATA_POINT_FAIL,
  ADD_PATIENT_DATA_POINT_LOADING,
  ADD_PATIENT_DATA_POINT_SUCCESS,
  AddPatientPointDataState,
  ADD_PATIENT_DATA_POINT_UPDATE_API_MSG,
  ADD_PATIENT_DATA_POINT_CLEAR_API_RES,
} from "./ActionTypes";
import { AddPatientPointDataDetails } from "./Model";

const initialStateGetPosts: AddPatientPointDataState = {
  loading: false,
  addPatientDataPointRes: {} as AddPatientPointDataDetails,
  message: "",
  status: 0,
};
export const addPointDataReducer = (
  state = initialStateGetPosts,
  action: AddPatientDataPointActionTypes,
): AddPatientPointDataState => {
  switch (action.type) {
    case ADD_PATIENT_DATA_POINT_LOADING:
      return {
        ...state,
        loading: true,
        addPatientDataPointRes: {} as AddPatientPointDataDetails,
      };
    case ADD_PATIENT_DATA_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        addPatientDataPointRes: action.payload,
      };
    case ADD_PATIENT_DATA_POINT_FAIL:
      return {
        ...state,
        loading: false,
        addPatientDataPointRes: {} as AddPatientPointDataDetails,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_DATA_POINT_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        addPatientDataPointRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_DATA_POINT_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        addPatientDataPointRes: {} as AddPatientPointDataDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
