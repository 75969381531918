import { OrganizationAddRegistrationRequestDetails } from "./Model";

// Patient Demographics Loading State
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING = "ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS = "ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL = "ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG = "ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES = "ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES";

export interface OrganizationAddRegistrationRequestState {
  OrganizationAddRegistrationRequestRes: OrganizationAddRegistrationRequestDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OrganizationAddRegistrationRequestLoading {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_LOADING;
  loading: boolean;
}
export interface OrganizationAddRegistrationRequestSuccess {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_SUCCESS;
  payload: OrganizationAddRegistrationRequestDetails;
  successMsg: string;
}
export interface OrganizationAddRegistrationRequestFail {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_FAIL;
  payload: OrganizationAddRegistrationRequestDetails;
  errorMsg: string;
  status: number;
}
export interface OrganizationAddRegistrationRequestAPIMsg {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_API_MSG;
  payload: OrganizationAddRegistrationRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OrganizationAddRegistrationRequestAPIRes {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_API_RES;
  payload: OrganizationAddRegistrationRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OrganizationAddRegistrationRequestActionTypes =
  | OrganizationAddRegistrationRequestLoading
  | OrganizationAddRegistrationRequestSuccess
  | OrganizationAddRegistrationRequestFail
  | OrganizationAddRegistrationRequestAPIMsg
  | OrganizationAddRegistrationRequestAPIRes;
