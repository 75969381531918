import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import AdminLoader from "../../../AdminLoader";
import MWExceptionList from "../../../../component/MWExceptionList";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWPageTitle from "../../../../component/MWPageTitle";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import PageLayout from "../../../Layout/PageLayout";
import { useHistory, useParams } from "react-router-dom";
import { getHealthCampRegistrationRequestList } from "redux/HealthCampRegistrationRequestList/API";
import { GetHealthcampRequestListBody } from "redux/HealthCampRegistrationRequestList/Model";
import dayjs from "dayjs";
import { HealthCampList } from "redux/healthcampList/Model";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  clearToggleActiveRegistrationRequestAPIRes,
  toggleActiveRegistrationRequest,
} from "redux/ToggleActiveRegistrationRequest/API";
import { ToggleActiveRegReqBody } from "redux/ToggleActiveRegistrationRequest/Model";
import MWSnackbar from "component/MWSnackbar";
import _ from "lodash";
import { getHealthcampListValue } from "redux/healthcampList/API";

export default function HealthCampRegistrationReqList() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const history = useHistory();
  const registrationReqDetailsList = (rowIndex: any) => {
    history.push(`/healthcampregistrationrequestdetails/${healthcampid}/${rowIndex}`);
  };
  const [healthCampReqListValues, setHealthCampReqListValues] = useState([] as any[]);

  const { healthcampid } = useParams() as {
    healthcampid: string;
  };

  // call Store
  const AdminHealthcampRegistrationReqList = useSelector(
    (state: AppState) => state.healthcampRegistrationRequestListValue,
  );
  const ToggleActiveRegistrationRequestStoreVal = useSelector(
    (state: AppState) => state.toggleActiveRegistrationRequestValue,
  );
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const healthcampValues: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );

  useEffect(() => {
    if (healthcampValues === undefined) {
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampValues]);

  const requestList: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            registrationReqDetailsList(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "regreqId",
      headerName: "ID",
      valueGetter: ({ id }) => {
        const item = healthCampReqListValues.find((item) => item.id === id);
        return item.id;
      },
      width: 120,
    },
    {
      field: "displayName",
      headerName: "Display Name",
      width: 420,
      flex: 1,
    },
    { field: "ownerFirstName", headerName: "Owner First Name", width: 150 },
    {
      field: "dateCreated",
      headerName: "Create Date",
      width: 150,
    },
    {
      field: "status",
      headerName: "Request Status",
      sortable: true,
      hideable: false,
      minWidth: 200,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(
              toggleActiveRegistrationRequest({
                healthcampRegistrationRequestId: params.row.id,
              } as ToggleActiveRegReqBody),
            );
          }}
        >
          Deactive
        </Button>
      ),
    },
  ];

  const toggleActiveClose = () => {
    dispatch(clearToggleActiveRegistrationRequestAPIRes());
    setToggleActiveRegReqMsg("");
  };
  const [toggleActiveRegReqMsg, setToggleActiveRegReqMsg] = useState("");
  const [toggleActiveRegReqMsgType, setToggleActiveRegReqMsgType] = useState<AlertColor>("success");

  const reloadRegReqList = () => {
    dispatch(
      getHealthCampRegistrationRequestList({
        healthcampId: healthcampid,
      } as GetHealthcampRequestListBody),
    );
  };

  useEffect(() => {
    if (ToggleActiveRegistrationRequestStoreVal?.successMsg !== undefined) {
      setToggleActiveRegReqMsgType("success");
      setToggleActiveRegReqMsg(ToggleActiveRegistrationRequestStoreVal?.successMsg);
      dispatch(
        getHealthCampRegistrationRequestList({
          healthcampId: healthcampid,
        } as GetHealthcampRequestListBody),
      );
    }
    if (ToggleActiveRegistrationRequestStoreVal?.errorMsg !== "") {
      setToggleActiveRegReqMsgType("error");
      setToggleActiveRegReqMsg(ToggleActiveRegistrationRequestStoreVal?.errorMsg);
    }
  }, [ToggleActiveRegistrationRequestStoreVal, dispatch, healthcampid]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getHealthCampRegistrationRequestList({
        healthcampId: healthcampid,
      } as GetHealthcampRequestListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      AdminHealthcampRegistrationReqList?.getHealthCampRegistrationRequestList
        ?.healthcampRegistrationRequestList !== undefined
    ) {
      let regRequestList =
        AdminHealthcampRegistrationReqList?.getHealthCampRegistrationRequestList?.healthcampRegistrationRequestList.map(
          (element: any) => ({
            id: element.id,
            displayName: element?.displayName !== "" ? element?.displayName : "",
            dateCreated: dayjs(element.dateCreated).format("ddd, MMM D, YYYY"),
            ownerFirstName: element?.ownerFirstName !== "" ? element?.ownerFirstName : "",
            healthcampId: element?.healthcampId !== null ? element?.healthcampId : "",
            healthcampDisplayName:
              element?.healthcampDisplayName !== "" ? element?.healthcampDisplayName : "",
          }),
        );
      setHealthCampReqListValues(regRequestList);
    }
  }, [AdminHealthcampRegistrationReqList]);

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6"> Request List</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={
                      AdminHealthcampRegistrationReqList?.getHealthCampRegistrationRequestList
                        ?.healthcampRegistrationRequestList !== undefined
                        ? String(
                            AdminHealthcampRegistrationReqList?.getHealthCampRegistrationRequestList
                              ?.healthcampRegistrationRequestList.length,
                          )
                        : "0"
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item mt={1}>
                  <RefreshIcon
                    onClick={() => {
                      reloadRegReqList();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {healthCampReqListValues && healthCampReqListValues.length ? (
          <DataGrid
            rows={healthCampReqListValues}
            columns={requestList}
            getRowId={(row) => row.id}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {AdminHealthcampRegistrationReqList.loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle
          title={
            healthcampValues?.displayName !== undefined
              ? healthcampValues?.displayName + " Registration Request List"
              : ""
          }
          backButton={true}
          customRoute={`/patientregistration/${healthcampid}`}
        />
        {actualPageMarkup}
      </PageLayout>
      {toggleActiveRegReqMsg !== "" ? (
        <MWSnackbar
          msg={toggleActiveRegReqMsg}
          type={toggleActiveRegReqMsgType}
          alertClose={toggleActiveClose}
        />
      ) : null}
    </Box>
  );
}
