import {
  GetPhotoJudgingDetailsListActionTypes,
  GET_PHOTO_JUDGING_LIST_ID_FAIL,
  GET_PHOTO_JUDGING_LIST_ID_LOADING,
  GET_PHOTO_JUDGING_LIST_ID_SUCCESS,
  GetPhotoJudgingDetailsListState,
  GET_PHOTO_JUDGING_LIST_ID_API_MSG,
  GET_PHOTO_JUDGING_LIST_ID_API_RES,
} from "./ActionTypes";
import { GetPhotoJudgingDetailsListDetails } from "./Model";

const initialStateGetPosts: GetPhotoJudgingDetailsListState = {
  loading: false,
  getPhotoJudgingDetailsListRes: {} as GetPhotoJudgingDetailsListDetails,
  message: "",
  status: 0,
};
export const getPhotoJudgingDetailsListReducer = (
  state = initialStateGetPosts,
  action: GetPhotoJudgingDetailsListActionTypes,
): GetPhotoJudgingDetailsListState => {
  switch (action.type) {
    case GET_PHOTO_JUDGING_LIST_ID_LOADING:
      return {
        ...state,
        loading: true,
        getPhotoJudgingDetailsListRes: {} as GetPhotoJudgingDetailsListDetails,
      };
    case GET_PHOTO_JUDGING_LIST_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getPhotoJudgingDetailsListRes: action.payload,
      };
    case GET_PHOTO_JUDGING_LIST_ID_FAIL:
      return {
        ...state,
        loading: false,
        getPhotoJudgingDetailsListRes: {} as GetPhotoJudgingDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    case GET_PHOTO_JUDGING_LIST_ID_API_MSG:
      return {
        ...state,
        loading: false,
        getPhotoJudgingDetailsListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case GET_PHOTO_JUDGING_LIST_ID_API_RES:
      return {
        ...state,
        loading: false,
        getPhotoJudgingDetailsListRes: {} as GetPhotoJudgingDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
