import React, { useState } from "react";
import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import DoctorsOnboardingReject from "./DoctorsOnboardingReject";
import DoctorsOnboardingRequest from "./DoctorsOnboardingRequest";
import DoctorsOnboardingImcomplete from "./DoctorsOnboardingImcomplete";
import PageLayout from "pages/Layout/PageLayout";

export default function DoctorsOnboarding() {
  const [selected, setSelected] = useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PageLayout>
      <Box>
        <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
          <Tabs
            value={selected}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Onboarding Requested</Typography>
                  </Grid>
                </Grid>
              }
              iconPosition="end"
              value="0"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Onboarding Rejected</Typography>
                  </Grid>
                </Grid>
              }
              iconPosition="end"
              value="1"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Onboarding Incomplete</Typography>
                  </Grid>
                </Grid>
              }
              iconPosition="end"
              value="2"
            />
          </Tabs>
        </Box>
        <Divider />
        {fullScreen ? (
          <Box pt={2}>
            {selected === "1" ? (
              <DoctorsOnboardingRequest />
            ) : selected === "2" ? (
              <DoctorsOnboardingReject />
            ) : (
              <DoctorsOnboardingImcomplete />
            )}
          </Box>
        ) : (
          <TabContext value={String(selected)}>
            <TabPanel value="0">
              <DoctorsOnboardingRequest />
            </TabPanel>
            <TabPanel value="1">
              <DoctorsOnboardingReject />
            </TabPanel>
            <TabPanel value="2">
              <DoctorsOnboardingImcomplete />
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </PageLayout>
  );
}
