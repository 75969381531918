import {
  AddCaseActionTypes,
  ADD_CASE_FAIL,
  ADD_CASE_LOADING,
  ADD_CASE_SUCCESS,
  AddCaseState,
  ADD_CASE_API_MSG,
  ADD_CASE_API_RES,
} from "./ActionTypes";
import { AddCaseResponse } from "./Model";

const initialStateGetPosts: AddCaseState = {
  loading: false,
  addCaseRes: {} as AddCaseResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const AddCaseReducer = (
  state = initialStateGetPosts,
  action: AddCaseActionTypes
): AddCaseState => {
  switch (action.type) {
    case ADD_CASE_LOADING:
      return {
        ...state,
        loading: true,
        addCaseRes: {} as AddCaseResponse,
      };
    case ADD_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        addCaseRes: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case ADD_CASE_FAIL:
      return {
        ...state,
        loading: false,
        addCaseRes: {} as AddCaseResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_CASE_API_MSG:
      return {
        ...state,
        loading: false,
        addCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_CASE_API_RES:
      return {
        ...state,
        loading: false,
        addCaseRes: {} as AddCaseResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
