import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { SessionListByPatientIdActionTypes } from "./ActionTypes";
import { SessionListByPatientIdBody, SessionListByPatientIdDetails } from "./Model";
import {
  SessionListByPatientIdAPIResClearAction,
  SessionListByPatientIdErrorAction,
  SessionListByPatientIdLoadingAction,
  SessionListByPatientIdSuccessAction,
  SessionListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as SessionListByPatientIdDetails;
export const sessionListByPatientIdApi = (patientid: SessionListByPatientIdBody, pageNo: any) => {
  return function (dispatch: Dispatch<SessionListByPatientIdActionTypes>) {
    dispatch(SessionListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsession/session_list_by_patient?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(SessionListByPatientIdLoadingAction(false));
        dispatch(
          SessionListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as SessionListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient session list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(SessionListByPatientIdLoadingAction(false));
        dispatch(
          SessionListByPatientIdErrorAction(
            {} as SessionListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSessionListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<SessionListByPatientIdActionTypes>) {
    dispatch(
      SessionListByPatientIdAPIMsgAction(apiRes as SessionListByPatientIdDetails, "", "", 0),
    );
  };
};

export const clearSessionListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<SessionListByPatientIdActionTypes>) {
    dispatch(
      SessionListByPatientIdAPIResClearAction({} as SessionListByPatientIdDetails, "", "", 0),
    );
  };
};
