import {
  SET_PREFERENCE_FAIL,
  SET_PREFERENCE_LOADING,
  SET_PREFERENCE_SUCCESS,
  SET_PREFERENCE_UPDATE_API_MSG,
  SET_PREFERENCE_UPDATE_API_RES,
  SetPreferenceActionTypes,
} from "./ActionTypes";
import { SetPreferenceRes } from "./Model";

export const setPreferenceLoadingAction = (loading: boolean): SetPreferenceActionTypes => {
  return {
    type: SET_PREFERENCE_LOADING,
    loading: loading,
  };
};

export const setPreferenceSuccessAction = (
  addScheduleResponse: SetPreferenceRes,
  successMsg: string,
): SetPreferenceActionTypes => {
  return {
    type: SET_PREFERENCE_SUCCESS,
    payload: addScheduleResponse,
    successMsg: successMsg,
  };
};

export const setPreferenceErrorAction = (
  addScheduleResponse: SetPreferenceRes,
  errMsg: string,
  status: number,
): SetPreferenceActionTypes => {
  return {
    type: SET_PREFERENCE_FAIL,
    payload: addScheduleResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const setPreferenceUpdateAPIMsgAction = (
  addScheduleResponse: SetPreferenceRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SetPreferenceActionTypes => {
  return {
    type: SET_PREFERENCE_UPDATE_API_MSG,
    payload: addScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const setPreferenceAPIResClearAction = (
  addScheduleResponse: SetPreferenceRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SetPreferenceActionTypes => {
  return {
    type: SET_PREFERENCE_UPDATE_API_RES,
    payload: addScheduleResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
