import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/store/Store";
import { Alert, AlertColor, Box, Grid, Pagination, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../component/MWExceptionList";
import { useParams } from "react-router-dom";
import AddHealthcampStaffDialog from "../AddHealthcampStaffDialog";

import MWSnackbar from "component/MWSnackbar";
import { getHealthcampAdminListByHealthCampId } from "redux/HealthcampStaffList/AdminListByHealthcampId/API";
import {
  GetHealthcampAdminListBody,
  StaffList,
} from "redux/HealthcampStaffList/AdminListByHealthcampId/Model";

type Props = {
  viewHealthcampStaffListLoader?: boolean | any;
  adminListCount: (value: string) => void;
};

export default function AdminList({ viewHealthcampStaffListLoader, adminListCount }: Props) {
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  // call Store
  const viewHealthCampStaffList = useSelector(
    (state: AppState) => state.getAdminListByHealthcampIdValues,
  );

  const deactivateHealthcampStaffValueList = useSelector(
    (state: AppState) => state.deactivateHealthcampStaffValue,
  );
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const viewHealthcampAdminListBody = {
      healthcampId: healthcampid,
      isActiveOrAdmin: 2,
    } as GetHealthcampAdminListBody;
    dispatch(getHealthcampAdminListByHealthCampId(viewHealthcampAdminListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (deactivateHealthcampStaffValueList?.deactiveStaffOfHealthcampRes?.message !== undefined) {
      setDiactiveMsg(deactivateHealthcampStaffValueList?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const viewHealthcampAdminListBody = {
        healthcampId: healthcampid,
        isActiveOrAdmin: 2,
      } as GetHealthcampAdminListBody;
      dispatch(getHealthcampAdminListByHealthCampId(viewHealthcampAdminListBody, 1));
    }
    if (deactivateHealthcampStaffValueList?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(deactivateHealthcampStaffValueList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateHealthcampStaffValueList]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  const [addHealthcampStaffDialog, setAddHealthcampStaffDialog] = React.useState(false);
  const updateAddOrganizationStaffDialogValFromChild = (passedVal: boolean) => {
    setAddHealthcampStaffDialog(passedVal);
  };
  useEffect(() => {
    viewHealthcampStaffListLoader(viewHealthCampStaffList?.loading);
    viewHealthCampStaffList?.getHealthcampAdminList?.numberOfPages !== undefined
      ? setPageListCount(String(viewHealthCampStaffList?.getHealthcampAdminList?.numberOfPages))
      : setPageListCount("0");
    viewHealthCampStaffList?.getHealthcampAdminList?.count !== undefined
      ? adminListCount(String(viewHealthCampStaffList?.getHealthcampAdminList?.count))
      : adminListCount("0");
    if (viewHealthCampStaffList?.getHealthcampAdminList?.StaffList !== undefined) {
      let dataPointArr = viewHealthCampStaffList?.getHealthcampAdminList?.StaffList.map(
        (element: StaffList) => ({
          id: element.id,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
          phoneNumber: element.phoneNumber,
          userId: element.userId,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    if (viewHealthCampStaffList?.errorMsg !== "") {
      setNotAdministratorMsg(viewHealthCampStaffList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewHealthCampStaffList]);

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
  ];
  const StaffListOfHealthCamp = (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          {!fullScreen && (
            <Grid item>
              <Typography>Page: {page}</Typography>
            </Grid>
          )}
          <Grid item>
            <Pagination
              color="primary"
              count={Number(pageListCount) || 0}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {notAdministratorMsg !== "" ? (
          <Alert severity="error">{notAdministratorMsg}</Alert>
        ) : (
          <Box>
            {viewHealthCampStaffList?.getHealthcampAdminList?.StaffList &&
            viewHealthCampStaffList?.getHealthcampAdminList?.StaffList.length ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
    }
  };

  return (
    <Box>
      {StaffListOfHealthCamp}
      {addHealthcampStaffDialog ? (
        <AddHealthcampStaffDialog
          itemId={healthcampid}
          addOrganizationStaffDialogEnable={addHealthcampStaffDialog}
          addOrganizationStaffDialogUpdateState={updateAddOrganizationStaffDialogValFromChild}
        />
      ) : null}
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
