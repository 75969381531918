import {
  StepAndSectionListActionTypes,
  GetStepAndSectionState,
  STEP_AND_SECTION_SUCCESS,
  STEP_AND_SECTION_LOADING,
  STEP_AND_SECTION_FAIL,
} from "./ActionTypes";
import { StepsAndSectionResponse } from "./Model";

const initialStateGetPosts: GetStepAndSectionState = {
  loading: false,
  stepandsectionRes: {} as StepsAndSectionResponse,
};
export const stepandSectionListReducer = (
  state = initialStateGetPosts,
  action: StepAndSectionListActionTypes,
): GetStepAndSectionState => {
  switch (action.type) {
    case STEP_AND_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        stepandsectionRes: action.payload,
      };
    case STEP_AND_SECTION_LOADING:
      return {
        ...state,
        loading: true,
        stepandsectionRes: {} as StepsAndSectionResponse,
      };
    case STEP_AND_SECTION_FAIL:
      return {
        ...state,
        stepandsectionRes: {} as StepsAndSectionResponse,
        loading: false,
      };
    default:
      return state;
  }
};
