import axios from "axios";
import Url from "../../ApiConfig";
import { Dispatch } from "react";
import {
  AcceportRejectBookingTypes,
  ACCEPT_OR_REJECT_BOOKING_FAIL,
  ACCEPT_OR_REJECT_BOOKING_LOADING,
  ACCEPT_OR_REJECT_BOOKING_SUCCESS,
} from "./ActionTypes";
import { getAcceptorRejectActions } from "./Actions";
import { headers } from "../../component/Utility";

// Active Corporate List API
export const acceptorRejectBooking = (body: any) => {
  return function (dispatch: Dispatch<AcceportRejectBookingTypes>) {
    dispatch({
      type: ACCEPT_OR_REJECT_BOOKING_LOADING,
    });
    axios
      .post(
        Url.baseUrl + `/appointmentapp/api/appointment/doctor_accept_appointment_by_admin`,
        body,
        { headers: headers },
      )
      .then((res) => {
        let message = res.data.message;
        let status = res.data.status;
        dispatch(getAcceptorRejectActions(message, status));
        dispatch({
          type: ACCEPT_OR_REJECT_BOOKING_SUCCESS,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: ACCEPT_OR_REJECT_BOOKING_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
