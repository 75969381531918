import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { SessionPatientAvailableByObservationTypeActionTypes } from "./ActionTypes";
import {
  SessionPatientAvailableByObservationTypeBody,
  SessionPatientAvailableByObservationTypeDetails,
} from "./Model";
import {
  SessionPatientAvailableByObservationTypeAPIResClearAction,
  SessionPatientAvailableByObservationTypeErrorAction,
  SessionPatientAvailableByObservationTypeLoadingAction,
  SessionPatientAvailableByObservationTypeSuccessAction,
  SessionPatientAvailableByObservationTypeAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as SessionPatientAvailableByObservationTypeDetails;
export const sessionPatientAvailableByObservationTypeApi = (
  searchKey: SessionPatientAvailableByObservationTypeBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<SessionPatientAvailableByObservationTypeActionTypes>) {
    dispatch(SessionPatientAvailableByObservationTypeLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
        `/healthcampapp/api/healthcampsessionpatient/session_patient_missing_details_by_observation_type?page=${Number(
          pageNo,
        )}&size=50`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(SessionPatientAvailableByObservationTypeLoadingAction(false));
        dispatch(
          SessionPatientAvailableByObservationTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as SessionPatientAvailableByObservationTypeDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(SessionPatientAvailableByObservationTypeLoadingAction(false));
        dispatch(
          SessionPatientAvailableByObservationTypeErrorAction(
            {} as SessionPatientAvailableByObservationTypeDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSessionPatientAvailableByObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<SessionPatientAvailableByObservationTypeActionTypes>) {
    dispatch(
      SessionPatientAvailableByObservationTypeAPIMsgAction(
        apiRes as SessionPatientAvailableByObservationTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearSessionPatientAvailableByObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<SessionPatientAvailableByObservationTypeActionTypes>) {
    dispatch(
      SessionPatientAvailableByObservationTypeAPIResClearAction(
        {} as SessionPatientAvailableByObservationTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};
