import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import { useHistory } from "react-router-dom";
import AdminLoader from "pages/AdminLoader";
import { getObservationTypeDetails } from "redux/GetObservationTypeList/API";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GetVitalCollectionActiveBody } from "redux/VitalCollectionStatus/VitalActiveStatus/Model";
import {
  clearGetVitalCollectionActiveAPIRes,
  getVitalCollectionActiveApi,
  updateGetVitalCollectionActiveAPIResMsg,
} from "redux/VitalCollectionStatus/VitalActiveStatus/API";
import {
  clearSessionPatientAvailableByObservationTypeAPIRes,
  sessionPatientAvailableByObservationTypeApi,
  updateSessionPatientAvailableByObservationTypeAPIResMsg,
} from "redux/HealthcampSessionList/AvailablePatientObservationType/API";
import { SessionPatientAvailableByObservationTypeBody } from "redux/HealthcampSessionList/AvailablePatientObservationType/Model";
import {
  clearGetPatientListBySessionIdAPIRes,
  updateGetPatientListBySessionIdAPIResMsg,
} from "redux/HealthcampSessionList/PatientListBySessionId/API";
import { PatientDataRangeBySessionIdTypeBody } from "redux/HealthcampSessionList/PatientDataRangeBySessionId/Model";
import {
  clearPatientDataRangeBySessionIdTypeAPIRes,
  patientDataRangeBySessionIdApi,
  updatePatientDataRangeBySessionIdTypeAPIResMsg,
} from "redux/HealthcampSessionList/PatientDataRangeBySessionId/API";
import { clearGetMandatoryPendingDetailsListBySessionIdAPIRes, getMandatoryPendingDetailsListBySessionIdApi, updateGetMandatoryPendingDetailsListBySessionIdAPIResMsg } from "redux/HealthcampSessionList/MandatoryPendingListBySessionId/API";
import { GetMandatoryPendingDetailsListBySessionIdBody } from "redux/HealthcampSessionList/MandatoryPendingListBySessionId/Model";

type Props = {
  filterDialogEnable?: boolean;
  filterDialogUpdateState: (value: boolean) => void;
  sessionId: string | any;
  obType: string | any;
  date: string | any;
};

export default function FilterHealthCampDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  sessionId,
  obType,
  date,
}: Props) {
  const operationTypeValue = [
    {
      id: 1,
      displayValue: "With in Range",
      name: "withInRange",
      sequence: 1,
    },
    {
      id: 2,
      displayValue: "Greater",
      name: "greater",
      sequence: 2,
    },
    {
      id: 3,
      displayValue: "Less Than",
      name: "lessThan",
      sequence: 3,
    },
    {
      id: 4,
      displayValue: "Equal",
      name: "equal",
      sequence: 4,
    },
    {
      id: 4,
      displayValue: "Not Equal",
      name: "notEqual",
      sequence: 4,
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  // store call
  const observationTypeList = useSelector((state: AppState) => state.getObservationTypeValue);
  const vitalCollectionActiveStatusList = useSelector(
    (state: AppState) => state.getVitalCollectionActiveList,
  );
  const availableDataByObservationType = useSelector(
    (state: AppState) => state.avaliableObservationResponse,
  );
  const patientDataRangeValues = useSelector(
    (state: AppState) => state.patientDataRangBySessionIdResult,
  );
  const [filterDialogState, setFilterDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setFilterDialogState(false);
    filterDialogUpdateState(false);
    dispatch(updateSessionPatientAvailableByObservationTypeAPIResMsg());
    dispatch(updateGetVitalCollectionActiveAPIResMsg());
    dispatch(updateGetPatientListBySessionIdAPIResMsg());
    dispatch(updatePatientDataRangeBySessionIdTypeAPIResMsg());
    dispatch(updateGetMandatoryPendingDetailsListBySessionIdAPIResMsg());
  };

  const [value, setValue] = React.useState("");
  const handleChangeForRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  useEffect(() => {
    if (value !== undefined && value === "active") {
      let activeBody = {
        healthcampSessionId: sessionId,
        statusType: "1",
      } as GetVitalCollectionActiveBody;
      dispatch(getVitalCollectionActiveApi(activeBody, 1));
    } else if (value !== undefined && value === "inactive") {
      let activeBody = {
        healthcampSessionId: sessionId,
        statusType: "0",
      } as GetVitalCollectionActiveBody;
      dispatch(getVitalCollectionActiveApi(activeBody, 1));
    } else if (value !== undefined && value === "complete") {
      let activeBody = {
        healthcampSessionId: sessionId,
        statusType: "2",
      } as GetVitalCollectionActiveBody;
      dispatch(getVitalCollectionActiveApi(activeBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const [missingObsSelected, setMissingObsSelected] = useState(true);
  const [activeSelected, setActiveSelected] = useState(false);
  const [observationValueSelected, setObservationValueSelected] = useState(false);
  const [mandatoryValueSelected, setMandatoryValueSelected] = useState(false);
  const [patientObservationTypeList, setPatientObservationTypeList] = useState([] as any[]);
  const [selectedPatientObservatonType, setSelectedPatientObservatonType] =
    useState<ObservationTypeList | null>(null);
  const [patientObservatonType, setPatientObservatonType] = useState<ObservationTypeList | null>(
    null,
  );
  const [startDate, setStartDate] = React.useState<Dayjs | null | string>(
    dayjs(new Date()).format("YYYY-MM-DD")!,
  );
  const [selectedPatientOperationType, setSelectedPatientOperationType] = useState("");
  const handleDateChange = (filterDateValue: Dayjs | null | string) => {
    setStartDate(dayjs(filterDateValue));
  };
  const [parameterName, setParameterName] = useState("");
  let str1 = parameterName;
  const parametersValue = str1.split(",");
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const patientObservationTypeChange = (
    _selectedObservationVal: any,
    newvalue: ObservationTypeList,
  ) => {
    setSelectedPatientObservatonType(newvalue);
  };
  const observationTypeChange = (_observationVal: any, newvalue: ObservationTypeList) => {
    setPatientObservatonType(newvalue);
  };
  const patientOperationTypeChange = (operationType: any) => {
    setSelectedPatientOperationType(operationType.name);
  };
  useEffect(() => {
    dispatch(getObservationTypeDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    if (obType !== null && date !== null && checked !== null) {
      const words = date.split("T");
      if (observationTypeList.GetObservationTypeResponse.observationTypeList !== undefined) {
        let result = observationTypeList.GetObservationTypeResponse.observationTypeList.find(
          (obj: any) => {
            return obj.name === obType;
          },
        );
        setSelectedPatientObservatonType(result!);
      }
      setStartDate(words[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obType, date, checked]);
  useEffect(() => {
    if (observationTypeList?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      setPatientObservationTypeList(
        observationTypeList?.GetObservationTypeResponse?.observationTypeList,
      );
    } else setPatientObservationTypeList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationTypeList]);

  useEffect(() => {
    if (availableDataByObservationType?.successMsg !== "") {
      handleDialogClose();
      dispatch(clearGetPatientListBySessionIdAPIRes());
      dispatch(clearGetVitalCollectionActiveAPIRes());
      dispatch(clearPatientDataRangeBySessionIdTypeAPIRes());
      dispatch(clearGetMandatoryPendingDetailsListBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDataByObservationType]);

  useEffect(() => {
    if (vitalCollectionActiveStatusList?.successMsg !== "") {
      handleDialogClose();
      dispatch(clearSessionPatientAvailableByObservationTypeAPIRes());
      dispatch(clearGetPatientListBySessionIdAPIRes());
      dispatch(clearPatientDataRangeBySessionIdTypeAPIRes());
      dispatch(clearGetMandatoryPendingDetailsListBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalCollectionActiveStatusList]);

  useEffect(() => {
    if (patientDataRangeValues?.successMsg !== "") {
      handleDialogClose();
      dispatch(clearSessionPatientAvailableByObservationTypeAPIRes());
      dispatch(clearGetPatientListBySessionIdAPIRes());
      dispatch(clearGetVitalCollectionActiveAPIRes());
      dispatch(clearGetMandatoryPendingDetailsListBySessionIdAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataRangeValues]);

  const mandatoryPatientDetailsValue = useSelector(
    (state: AppState) => state.getMandatoryPatientDetailsValue,
  );

  useEffect(() => {
    if (mandatoryPatientDetailsValue?.successMsg !== "") {
      handleDialogClose();
      dispatch(clearSessionPatientAvailableByObservationTypeAPIRes());
      dispatch(clearGetPatientListBySessionIdAPIRes());
      dispatch(clearGetVitalCollectionActiveAPIRes());
      dispatch(clearPatientDataRangeBySessionIdTypeAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatoryPatientDetailsValue]);

  React.useEffect(() => {
    setFilterDialogState(filterDialogEnable!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const availableObservation = () => {
    history.push(
      `${
        window.location.pathname +
        "?obType=" +
        selectedPatientObservatonType?.name +
        "&date=" +
        dayjs(decodeURIComponent(`${startDate}`)).format("YYYY-MM-DD") +
        "&status=" +
        checked
      }`,
    );
    if (selectedPatientObservatonType?.name !== null) {
      const PatientSearchByObservationBody = {
        patientObservationType: String(selectedPatientObservatonType?.name),
        healthcampSessionId: sessionId,
        startDate: startDate,
        isMissingData: checked,
      } as SessionPatientAvailableByObservationTypeBody;
      dispatch(sessionPatientAvailableByObservationTypeApi(PatientSearchByObservationBody, 1));
    }
  };

  const missingObservation = useCallback(() => {
    if (!activeSelected && !observationValueSelected && !mandatoryValueSelected) return;
    setMissingObsSelected(true);
    setActiveSelected(false);
    setObservationValueSelected(false);
    setMandatoryValueSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingObsSelected]);

  const activePatient = useCallback(() => {
    if (!missingObsSelected && !observationValueSelected && !mandatoryValueSelected) return;
    setMissingObsSelected(false);
    setActiveSelected(true);
    setObservationValueSelected(false);
    setMandatoryValueSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSelected]);

  const observationValue = useCallback(() => {
    if (!missingObsSelected && !activeSelected && !mandatoryValueSelected) return;
    setMissingObsSelected(false);
    setActiveSelected(false);
    setObservationValueSelected(true);
    setMandatoryValueSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationValueSelected]);

  const mandatoryValue = useCallback(() => {
    if (!missingObsSelected && !activeSelected && !observationValueSelected) return;
    setMissingObsSelected(false);
    setActiveSelected(false);
    setObservationValueSelected(false);
    setMandatoryValueSelected(true);
    let apiBody = {
      healthcampSessionId: sessionId,
    } as GetMandatoryPendingDetailsListBySessionIdBody;
    dispatch(getMandatoryPendingDetailsListBySessionIdApi(apiBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatoryValueSelected]);

  const patientDataRangeAction = () => {
    let dataRangeBody = {
      healthcampSessionId: sessionId,
      patientObservationType: String(patientObservatonType?.name),
      operationType: String(selectedPatientOperationType),
      parameters: parametersValue,
    } as PatientDataRangeBySessionIdTypeBody;
    dispatch(patientDataRangeBySessionIdApi(dataRangeBody, 1));
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={filterDialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        {availableDataByObservationType?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Filter Patient by </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item mt={1}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                <ButtonGroup disableElevation variant="outlined" aria-label="Disabled button group">
                  <Button
                    sx={{ textTransform: "none" }}
                    variant={missingObsSelected === true ? "contained" : "outlined"}
                    onClick={() => missingObservation()}
                  >
                    Observations Type
                  </Button>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={activePatient}
                    variant={activeSelected === true ? "contained" : "outlined"}
                  >
                    Vital Collection Request
                  </Button>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={observationValue}
                    variant={observationValueSelected === true ? "contained" : "outlined"}
                  >
                    Observation Value
                  </Button>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={mandatoryValue}
                    variant={mandatoryValueSelected === true ? "contained" : "outlined"}
                  >
                    Mandatory Patient Details
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {missingObsSelected === true ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Autocomplete
                  value={selectedPatientObservatonType}
                  id="templateId"
                  options={patientObservationTypeList}
                  onChange={(event: any, newValue: any) => {
                    patientObservationTypeChange(event, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filter by missing observation type"
                      size="small"
                    />
                  )}
                  getOptionLabel={(option) => `${option.name}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    label=" Start Date"
                    onChange={(date) => handleDateChange(date)}
                    views={["year", "month", "day"]}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        size="small"
                      />
                    )}
                    InputAdornmentProps={{ position: "start" }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="end"
                    control={
                      <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Find Missing"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : null}
          {activeSelected === true ? (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
                value={value}
                onChange={handleChangeForRadioButton}
              >
                <FormControlLabel value="active" control={<Radio />} label="Active" />
                <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                <FormControlLabel value="complete" control={<Radio />} label="Complete" />
              </RadioGroup>
            </FormControl>
          ) : null}
          {observationValueSelected === true ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Autocomplete
                  value={patientObservatonType}
                  id="operationTypeId"
                  options={patientObservationTypeList}
                  onChange={(event: any, newValue: any) => {
                    observationTypeChange(event, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Filter by observation type" size="small" />
                  )}
                  getOptionLabel={(option) => `${option.name}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Autocomplete
                  value={
                    operationTypeValue.find(
                      (element: any) => element.name === selectedPatientOperationType,
                    ) || null
                  }
                  id="operationId"
                  options={operationTypeValue}
                  onChange={(_event: any, newValue: any) => {
                    patientOperationTypeChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Filter by operation type" size="small" />
                  )}
                  getOptionLabel={(option) => `${option.name}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label="Parameter"
                  id="fullWidth"
                  size="small"
                  placeholder="Enter parameter"
                  value={parameterName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setParameterName(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          {missingObsSelected === true ? (
            <Button
              autoFocus
              onClick={availableObservation}
              disabled={selectedPatientObservatonType !== null ? false : true}
              variant="contained"
              color="primary"
              style={{ fontSize: "12px" }}
            >
              Submit
            </Button>
          ) : null}
          {observationValueSelected === true ? (
            <Button
              autoFocus
              onClick={() => patientDataRangeAction()}
              disabled={patientObservatonType !== null ? false : true}
              variant="contained"
              color="primary"
              style={{ fontSize: "12px" }}
            >
              Submit
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
