import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Pagination,
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { observationListByHealthcampIdApi } from "redux/healthcampList/ObservationListByHealthcampId/API";
import {
  ObservationList,
  ObservationListByHealthcampIdBody,
} from "redux/healthcampList/ObservationListByHealthcampId/Model";

type Props = {
  observationDetailsLoader: (value: boolean) => void;
  count: (value: string) => void;
};

export default function ObservationListByHealthcampId({ observationDetailsLoader, count }: Props) {
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = React.useState(1);
  const getObservationValue = useSelector(
    (state: AppState) => state.observationListOfHealthcampIdValue,
  );

  // extract observation list api call ...
  useEffect(() => {
    dispatch(
      observationListByHealthcampIdApi(
        { healthcampId: healthcampid } as ObservationListByHealthcampIdBody,
        page,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      observationListByHealthcampIdApi(
        { healthcampId: healthcampid } as ObservationListByHealthcampIdBody,
        value,
      ),
    );
  };

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    observationDetailsLoader(getObservationValue?.loading);
    getObservationValue?.observationListByHealthcampIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(getObservationValue?.observationListByHealthcampIdRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (getObservationValue?.observationListByHealthcampIdRes?.count !== undefined) {
      count(String(getObservationValue?.observationListByHealthcampIdRes?.count));
    } else count("0");
    if (getObservationValue?.observationListByHealthcampIdRes?.observationList !== undefined) {
      let dataPointArr = getObservationValue?.observationListByHealthcampIdRes?.observationList.map(
        (element: ObservationList) => ({
          id: element.id,
          value: element.value,
          startDate: dayjs(element.startDate).format("ddd, MMM DD, YYYY"),
          displayValue: element.patientObservationType.displayValue,
          modifiedDate: dayjs(element.modifiedDate).format("ddd, MMM DD, YYYY"),
          patientId: element.patient.id,
        }),
      );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(getObservationValue?.observationListByHealthcampIdRes?.numberOfPages),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObservationValue]);

  const columns: GridColDef[] = [
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 100,
      align: "left",
    },
    {
      field: "displayValue",
      headerName: "Observation Name",
      type: "string",
      align: "left",
      minWidth: 220,
      flex: 1,
    },
    {
      field: "value",
      headerName: "Observation Value",
      type: "string",
      align: "left",
      minWidth: 140,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      align: "left",
      minWidth: 135,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      align: "left",
      minWidth: 135,
    },
  ];

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
                  <Typography variant="h6" fontWeight={"bold"}>
                    Observation List
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  {!fullScreen && (
                    <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
                      <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
                    <Pagination
                      color="primary"
                      count={Number(pageListCount)}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {dataGridArray.length > 0 ? (
            <Box>
              {fullScreen ? (
                <Box sx={{ height: "calc(100vh - 52vh)" }}>
                  <DataGrid
                    rows={dataGridArray}
                    columns={columns}
                    disableSelectionOnClick
                    hideFooter
                    hideFooterPagination
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rowHeight={50}
                  />
                </Box>
              ) : (
                <DataGrid
                  rows={dataGridArray}
                  columns={columns}
                  disableSelectionOnClick
                  hideFooter
                  hideFooterPagination
                  experimentalFeatures={{ newEditingApi: true }}
                  autoHeight
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  rowHeight={50}
                />
              )}
            </Box>
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
