import {
  OBSERVATION_LIST_BY_HEALTHCAMP_ID_FAIL,
  OBSERVATION_LIST_BY_HEALTHCAMP_ID_LOADING,
  OBSERVATION_LIST_BY_HEALTHCAMP_ID_SUCCESS,
  OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG,
  OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES,
  ObservationListByHealthcampIdActionTypes,
} from "./ActionTypes";
import { ObservationListByHealthcampIdRes } from "./Model";

export const observationListByHealthcampIdLoadingAction = (
  loading: boolean,
): ObservationListByHealthcampIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_HEALTHCAMP_ID_LOADING,
    loading: loading,
  };
};

export const observationListByHealthcampIdSuccessAction = (
  observationListByHealthcampIdResponse: ObservationListByHealthcampIdRes,
  successMsg: string,
): ObservationListByHealthcampIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_HEALTHCAMP_ID_SUCCESS,
    payload: observationListByHealthcampIdResponse,
    successMsg: successMsg,
  };
};

export const observationListByHealthcampIdErrorAction = (
  observationListByHealthcampIdResponse: ObservationListByHealthcampIdRes,
  errMsg: string,
  status: number,
): ObservationListByHealthcampIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_HEALTHCAMP_ID_FAIL,
    payload: observationListByHealthcampIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const observationListByHealthcampIdUpdateAPIMsgAction = (
  observationListByHealthcampIdResponse: ObservationListByHealthcampIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ObservationListByHealthcampIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG,
    payload: observationListByHealthcampIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const observationListByHealthcampIdAPIResClearAction = (
  observationListByHealthcampIdResponse: ObservationListByHealthcampIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ObservationListByHealthcampIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES,
    payload: observationListByHealthcampIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
