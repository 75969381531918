import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddDoctorByProviderOrgIdActionTypes } from "./ActionTypes";
import { AddDoctorByProviderOrgIdBody, AddDoctorByProviderOrgIdDetails } from "./Model";
import {
  addDoctorByProviderOrgIdAPIResClearAction,
  addDoctorByProviderOrgIdErrorAction,
  addDoctorByProviderOrgIdLoadingAction,
  addDoctorByProviderOrgIdSuccessAction,
  addDoctorByProviderOrgIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddDoctorByProviderOrgIdDetails;
export const addDoctorByProviderOrgIdApiCall = (payload: AddDoctorByProviderOrgIdBody) => {
  return function (dispatch: Dispatch<AddDoctorByProviderOrgIdActionTypes>) {
    dispatch(addDoctorByProviderOrgIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/providerorganizationapp/api/providerOrganizationDoctorList/map_doctor_providerOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDoctorByProviderOrgIdLoadingAction(false));
        dispatch(
          addDoctorByProviderOrgIdSuccessAction(
            res.data !== undefined ? res.data : ({} as AddDoctorByProviderOrgIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Add Doctor successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addDoctorByProviderOrgIdLoadingAction(false));
        dispatch(
          addDoctorByProviderOrgIdErrorAction(
            {} as AddDoctorByProviderOrgIdDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddDoctorByProviderOrgIdAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDoctorByProviderOrgIdActionTypes>) {
    dispatch(addDoctorByProviderOrgIdAPIMsgAction(apiRes as AddDoctorByProviderOrgIdDetails, "", "", 0));
  };
};

export const clearAddDoctorByProviderOrgIdAPIRes = () => {
  return function (dispatch: Dispatch<AddDoctorByProviderOrgIdActionTypes>) {
    dispatch(addDoctorByProviderOrgIdAPIResClearAction({} as AddDoctorByProviderOrgIdDetails, "", "", 0));
  };
};
