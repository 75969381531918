import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Pagination,
  Typography,
  ButtonGroup,
  AlertColor,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import MWExceptionList from "../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
  GetProviderOrganizationPatientListBody,
  PatientList,
} from "redux/PatientListByProviderOrganizationId/Model";
import AddPatientForProviderOrg from "./AddPatientForProviderOrg";
import { getPatientListByProviderOrganization } from "redux/PatientListByProviderOrganizationId/API";
import AddNewPatientByProviderOrgId from "./AddNewPatientByProviderOrgId";
import MWSnackbar from "component/MWSnackbar";
import { clearAddPatientAPIRes } from "redux/addHealthcampPatient/API";

type Props = {
  patientListCount: (value: string) => void;
  patientListLoader?: boolean | any;
  title: string | any;
};

export default function PatientListComponent({
  patientListLoader,
  patientListCount,
  title,
}: Props) {
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [showAddPatientDialog, setShowAddPatientDialog] = React.useState(false);
  const updateAddPatientDialogValFromChild = (passedVal: boolean) => {
    setShowAddPatientDialog(passedVal);
  };
  const [addNewPatientDialog, setAddNewPatientDialog] = React.useState(false);
  const updateAddNewPatientDialogValFromChild = (passedVal: boolean) => {
    setAddNewPatientDialog(passedVal);
  };
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getPatientListByProviderOrganization(
        { providerorgId: organizationid } as GetProviderOrganizationPatientListBody,
        value,
      ),
    );
  };

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  // Call Patient List API on Page loading
  useEffect(() => {
    dispatch(
      getPatientListByProviderOrganization(
        { providerorgId: organizationid } as GetProviderOrganizationPatientListBody,
        page,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [addNewPatientMsg, setAddNewPatientMsg] = useState("");
  const [addNewPatientMsgType, setAddNewPatientMsgType] = useState<AlertColor>("success");

  const addPatientValueRes = useSelector((state: AppState) => state.addHealthcampPatientData);

  useEffect(() => {
    if (addPatientValueRes?.successMsg !== "") {
      setAddNewPatientMsgType("success");
      setAddNewPatientMsg(addPatientValueRes?.successMsg);
    }
    if (addPatientValueRes?.errorMsg !== "") {
      setAddNewPatientMsgType("error");
      setAddNewPatientMsg(addPatientValueRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.patientListOfProviderOrgIdValues);
  const patientListRes = AdminPatientList?.patientListOfProviderOrganizationIdRes?.patientList;
  const [patientList, setPatientList] = useState([] as any[]);
  useEffect(() => {
    AdminPatientList?.patientListOfProviderOrganizationIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(AdminPatientList?.patientListOfProviderOrganizationIdRes?.numberOfPages),
        )
      : setPageListCount("0");
    AdminPatientList?.patientListOfProviderOrganizationIdRes?.count !== undefined &&
      patientListCount(String(AdminPatientList?.patientListOfProviderOrganizationIdRes?.count));
    if (patientListRes !== undefined) {
      let ptList = patientListRes.map((element: PatientList) => ({
        id: element.patientId,
        firstName: element?.givenName !== undefined ? element?.givenName : "",
        lastName: element?.familyName !== undefined ? element?.familyName : "",
        dob: dayjs(element.dob).format("MMM D, YYYY"),
        email: element?.email !== undefined ? element?.email : "",
        phone: element?.phone !== undefined ? element?.phone : "",
        pincode: element?.pincode !== undefined ? element?.pincode : "",
      }));
      setPatientList(ptList);
    } else setPatientList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      minWidth: 120,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      minWidth: 250,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      minWidth: 180,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      minWidth: 100,
    },
  ];

  const patientListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <ButtonGroup disableElevation variant="contained" aria-label="Disabled button group">
                <Button
                  variant="outlined"
                  onClick={() => setShowAddPatientDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  Add Existing Patient
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAddNewPatientDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  Add New Patient
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item>
              <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            </Grid>
            <Grid item>
              <Pagination
                color="primary"
                count={Number(pageListCount)}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {patientList && patientList.length ? (
          <DataGrid
            rows={patientList}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddPatientAPIRes());
      setAddNewPatientMsg("");
    }
  };

  return (
    <Box>
      {AdminPatientList?.loading === true ? patientListLoader(true) : patientListLoader(false)}
      {patientListTable}
      {showAddPatientDialog ? (
        <AddPatientForProviderOrg
          filterDialogEnable={showAddPatientDialog}
          filterDialogUpdateState={updateAddPatientDialogValFromChild}
          title={title}
          providerorgId={organizationid}
        />
      ) : null}

      {addNewPatientDialog ? (
        <AddNewPatientByProviderOrgId
          addNewPatientDialogEnable={addNewPatientDialog}
          addNewPatientDialogUpdateState={updateAddNewPatientDialogValFromChild}
          title={title}
          providerorgId={organizationid}
        />
      ) : null}
      {addNewPatientMsg !== "" ? (
        <MWSnackbar msg={addNewPatientMsg} type={addNewPatientMsgType} alertClose={addImgtoComp} />
      ) : null}
    </Box>
  );
}
