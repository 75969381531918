import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetSingleEncounterListActionTypes } from "./ActionTypes";
import { GetSingleEncounterListRes } from "./Model";
import {
  getSingleEncounterListListAPIResClearAction,
  getSingleEncounterListListErrorAction,
  getSingleEncounterListListLoadingAction,
  getSingleEncounterListListSuccessAction,
  getSingleEncounterListListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetSingleEncounterListRes;
export const getSingleEncounterListApi = (pageNo: any) => {
  return function (dispatch: Dispatch<GetSingleEncounterListActionTypes>) {
    dispatch(getSingleEncounterListListLoadingAction(true));
    axios
      .get(
        Url.baseUrl +
          `/encounterapp/api/patientEncounter/get_single_encounter_list?page=${Number(
            pageNo,
          )}&size=3`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getSingleEncounterListListLoadingAction(false));
        dispatch(getSingleEncounterListListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getSingleEncounterListListLoadingAction(false));
        dispatch(
          getSingleEncounterListListErrorAction(
            {} as GetSingleEncounterListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getSingleEncounterListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetSingleEncounterListActionTypes>) {
    dispatch(
      getSingleEncounterListListAPIMsgAction(
        apiRes as GetSingleEncounterListRes,
        "",
        0,
      ),
    );
  };
};

export const clearSingleEncounterListAPIRes = () => {
  return function (dispatch: Dispatch<GetSingleEncounterListActionTypes>) {
    dispatch(
      getSingleEncounterListListAPIResClearAction(
        {} as GetSingleEncounterListRes,
        "",
        0,
      ),
    );
  };
};
