import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MWExceptionList from "../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";

export default function ProviderOrganizationIntegrationList() {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const history = useHistory();
  // call Store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const integrationListById = {
    // eslint-disable-next-line no-sparse-arrays
    integrationList: [
      {
        id: 1,
        integration_name: "Epic Integration",
      },
      // {
      //   id: 2,
      //   integration_name: "OpenEHR Integration",
      // },
      // {
      //   id: 3,
      //   integration_name: "OpenEMR Integration",
      // },
    ],
    status: 1,
  };

  const integrationClick = () => {
    history.push(`/ehrdetails/${organizationid}`);
  };

  const StaffListOfOrganization = (
    <Card>
      <CardContent>
        <Box>
          {integrationListById.integrationList.length > 0 ? (
            <>
              {integrationListById.integrationList.map((value, index) => (
                <List>
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Button
                        variant="outlined"
                        onClick={() => integrationClick()}
                        sx={{ textTransform: "none" }}
                        disabled={index === 2 ? true : false || index === 1 ? true : false}
                      >
                        Integration
                      </Button>
                    }
                    disablePadding
                  >
                    <ListItemText primary={value.integration_name} />
                  </ListItem>
                  <Divider />
                </List>
              ))}
            </>
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return <Box>{StaffListOfOrganization}</Box>;
}
