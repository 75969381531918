import axios from "axios";
import { Dispatch } from "react";
import Url from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { ExecutiveListResponse } from "./Model";
import {
  executiveListErrorAction,
  executiveListLoadingAction,
  executiveListSuccessAction,
} from "./Actions";
import { ExecutiveListActionTypes } from "./ActionTypes";
import { headers } from "../../../component/Utility";

export const adminExecutiveList = () => {
  return function (dispatch: Dispatch<ExecutiveListActionTypes>) {
    dispatch(executiveListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/adminapp/api/executives/executive_list_dropdown`, { headers: headers })
      .then((res) => {
        dispatch(executiveListLoadingAction(false));
        dispatch(executiveListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(executiveListLoadingAction(false));
        dispatch(
          executiveListErrorAction(
            {} as ExecutiveListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};
