import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import {  EditHealthcampNameActionTypes } from "./ActionTypes";
import { EditHealthcampNameBody, EditHealthcampNameRes } from "./Model";
import {
   editHealthcampNameAPIResClearAction,
   editHealthcampNameErrorAction,
   editHealthcampNameLoadingAction,
   editHealthcampNameSuccessAction,
   editHealthcampNameUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as EditHealthcampNameRes;
export const  editHealthcampName = (payload: EditHealthcampNameBody) => {
  return function (dispatch: Dispatch< EditHealthcampNameActionTypes>) {
    dispatch( editHealthcampNameLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/caregiverapp/api/healthcamp/edit_healthcamp_name`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( editHealthcampNameLoadingAction(false));
        dispatch(
           editHealthcampNameSuccessAction(
            res.data !== undefined ? res.data : ({} as EditHealthcampNameRes),
            res.data.message !== undefined
              ? res.data.message
              : "Edit Healthcamp Name successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( editHealthcampNameLoadingAction(false));
        dispatch(
           editHealthcampNameErrorAction(
            {} as EditHealthcampNameRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateHealthCampEditAPIResMsg = () => {
  return function (dispatch: Dispatch< EditHealthcampNameActionTypes>) {
    dispatch(
       editHealthcampNameUpdateAPIMsgAction(apiRes as EditHealthcampNameRes, "", "", 0),
    );
  };
};

export const clearHealthCampEditAPIRes = () => {
  return function (dispatch: Dispatch< EditHealthcampNameActionTypes>) {
    dispatch( editHealthcampNameAPIResClearAction({} as EditHealthcampNameRes, "", "", 0));
  };
};
