import { ConsultationTypeResponse } from "./Model";
export const CONSULTATION_TYPE_LOADING =
  "CONSULTATION_TYPE_LOADING";
export const CONSULTATION_TYPE_SUCCESS =
  "CONSULTATION_TYPE_SUCCESS";
export const CONSULTATION_TYPE_FAIL =
  "CONSULTATION_TYPE_FAIL";
export const CONSULTATION_TYPE_DATA =
  "CONSULTATION_TYPE_DATA";
export const CONSULTATION_TYPE_API_MSG =
  "CONSULTATION_TYPE_API_MSG";
export const CONSULTATION_TYPE_API_RES =
  "CONSULTATION_TYPE_API_RES";

export interface ConsultationTypeState {
  consultationTypeRes: ConsultationTypeResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConsultationTypeLoading {
  type: typeof CONSULTATION_TYPE_LOADING;
  loading: boolean;
}
export interface ConsultationTypeSuccess {
  type: typeof CONSULTATION_TYPE_SUCCESS;
  payload: ConsultationTypeResponse;
  successMsg: string;
  status: number;
}
export interface ConsultationTypeFail {
  type: typeof CONSULTATION_TYPE_FAIL;
  payload: ConsultationTypeResponse;
  errorMsg: string;
  status: number;
}
export interface ConsultationTypeUpdateAPIMsg {
  type: typeof CONSULTATION_TYPE_API_MSG;
  payload: ConsultationTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConsultationTypeUpdateAPIRes {
  type: typeof CONSULTATION_TYPE_API_RES;
  payload: ConsultationTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface ConsultationTypeAction {
  type: typeof CONSULTATION_TYPE_DATA;
  payload: ConsultationTypeResponse;
}

export type ConsultationTypeActionTypes =
  | ConsultationTypeAction
  | ConsultationTypeLoading
  | ConsultationTypeSuccess
  | ConsultationTypeFail
  | ConsultationTypeUpdateAPIMsg
  | ConsultationTypeUpdateAPIRes;
