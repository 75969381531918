import { PageCount, PatientSearchByHealthCampIdDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_SEARCH_BY_HEALTHCAMP_LOADING = "PATIENT_SEARCH_BY_HEALTHCAMP_LOADING";
export const PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS = "PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS";
export const PATIENT_SEARCH_BY_HEALTHCAMP_FAIL = "PATIENT_SEARCH_BY_HEALTHCAMP_FAIL";
export const PATIENT_SEARCH_BY_HEALTHCAMP_DATA = "PATIENT_SEARCH_BY_HEALTHCAMP_DATA";
export const PATIENT_SEARCH_UPDATE_API_MSG = "PATIENT_SEARCH_UPDATE_API_MSG";
export const PATIENT_SEARCH_UPDATE_API_RES = "PATIENT_SEARCH_UPDATE_API_RES";

export interface PatientSearchByHealthcampIdDetailsState {
  PatientSearchByHealthcampIdRes: PatientSearchByHealthCampIdDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientSearchByHealthcampIdLoading {
  type: typeof PATIENT_SEARCH_BY_HEALTHCAMP_LOADING;
  loading: boolean;
}
export interface PatientSearchByHealthcampIdSuccess {
  type: typeof PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS;
  payload: PatientSearchByHealthCampIdDetails;
}
export interface PatientSearchByHealthcampIdFail {
  type: typeof PATIENT_SEARCH_BY_HEALTHCAMP_FAIL;
  payload: PatientSearchByHealthCampIdDetails;
  message: string;
  status: number;
}
export interface PatientSearchByHealthcampIdAPIMsg {
  type: typeof PATIENT_SEARCH_UPDATE_API_MSG;
  payload: PatientSearchByHealthCampIdDetails;
  message: string;
  status: number;
}
export interface PatientSearchByHealthcampIdRes {
  type: typeof PATIENT_SEARCH_UPDATE_API_RES;
  payload: PatientSearchByHealthCampIdDetails;
  message: string;
  status: number;
}
interface PatientSearchByHealthcampIdDataAction {
  type: typeof PATIENT_SEARCH_BY_HEALTHCAMP_DATA;
  payload: PatientSearchByHealthCampIdDetails;
  pageCount: PageCount;
}

export type PatientSearchByHealthCampIdActionTypes =
  | PatientSearchByHealthcampIdDataAction
  | PatientSearchByHealthcampIdLoading
  | PatientSearchByHealthcampIdSuccess
  | PatientSearchByHealthcampIdFail
  | PatientSearchByHealthcampIdAPIMsg
  | PatientSearchByHealthcampIdRes;
