import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { PatientSearchListByObservationTypeActionTypes } from "./ActionTypes";
import {
  PatientSearchListByObservationTypeBody,
  PatientSearchListByObservationTypeDetails,
} from "./Model";
import {
  PatientSearchListByObservationTypeAPIResClearAction,
  PatientSearchListByObservationTypeErrorAction,
  PatientSearchListByObservationTypeLoadingAction,
  PatientSearchListByObservationTypeSuccessAction,
  PatientSearchListByObservationTypeAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as PatientSearchListByObservationTypeDetails;
export const patientSearchByObservationTypeApi = (
  searchKey: PatientSearchListByObservationTypeBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<PatientSearchListByObservationTypeActionTypes>) {
    dispatch(PatientSearchListByObservationTypeLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientorganization/organization_patient_missing_details_by_observation_type?page=${Number(
            pageNo,
          )}&size=50`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(PatientSearchListByObservationTypeLoadingAction(false));
        dispatch(
          PatientSearchListByObservationTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as PatientSearchListByObservationTypeDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(PatientSearchListByObservationTypeLoadingAction(false));
        dispatch(
          PatientSearchListByObservationTypeErrorAction(
            {} as PatientSearchListByObservationTypeDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSearchPatientByObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientSearchListByObservationTypeActionTypes>) {
    dispatch(
      PatientSearchListByObservationTypeAPIMsgAction(
        apiRes as PatientSearchListByObservationTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearSearchPatientByObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<PatientSearchListByObservationTypeActionTypes>) {
    dispatch(
      PatientSearchListByObservationTypeAPIResClearAction(
        {} as PatientSearchListByObservationTypeDetails,
        "",
        "",
        0,
      ),
    );
  };
};
