import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  PatientDemographicsActionTypes,
  DOCTOR_ONBOARD_INCOMPLETE_FAIL,
  DOCTOR_ONBOARD_INCOMPLETE_LOADING,
  DOCTOR_ONBOARD_INCOMPLETE_SUCCESS,
} from "./ActionTypes";
import { DocotorOnboardIncompleteBody } from "./Model";
import { headers } from "../../component/Utility";

export const onBoardDoctorIncomplete = (
  patientDetails: DocotorOnboardIncompleteBody
) => {
  return function (dispatch: Dispatch<PatientDemographicsActionTypes>) {
    dispatch({
      type: DOCTOR_ONBOARD_INCOMPLETE_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/adminapp/api/onboardRequestApproval/`,
        patientDetails,
        { headers: headers }
      )
      .then((res) => {
        dispatch({
          type: DOCTOR_ONBOARD_INCOMPLETE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: DOCTOR_ONBOARD_INCOMPLETE_FAIL,
        });
      });
  };
};
