import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DeactiveStaffOfHealthCampActionTypes } from "./ActionTypes";
import { DeactiveStaffOfHealthCampBody, DeactiveStaffOfHealthCampDetails } from "./Model";
import {
  deactiveStaffOfHealthcampAPIResClearAction,
  deactiveStaffOfHealthcampErrorAction,
  deactiveStaffOfHealthcampLoadingAction,
  deactiveStaffOfHealthcampSuccessAction,
  deactiveStaffOfHealthcampAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as DeactiveStaffOfHealthCampDetails;
export const deactiveStaffOfHealthcampApiCall = (payload: DeactiveStaffOfHealthCampBody) => {
  return function (dispatch: Dispatch<DeactiveStaffOfHealthCampActionTypes>) {
    dispatch(deactiveStaffOfHealthcampLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampstaff/toggle_active_staff_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(deactiveStaffOfHealthcampLoadingAction(false));
        dispatch(
          deactiveStaffOfHealthcampSuccessAction(
            res.data !== undefined ? res.data : ({} as DeactiveStaffOfHealthCampDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deactiveStaffOfHealthcampLoadingAction(false));
        dispatch(
          deactiveStaffOfHealthcampErrorAction(
            {} as DeactiveStaffOfHealthCampDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const diactiveStaffOfHealthcampAPIResMsg = () => {
  return function (dispatch: Dispatch<DeactiveStaffOfHealthCampActionTypes>) {
    dispatch(deactiveStaffOfHealthcampAPIMsgAction(apiRes as DeactiveStaffOfHealthCampDetails, "", "", 0));
  };
};

export const clearDiactiveStaffOfHealthcampAPIRes = () => {
  return function (dispatch: Dispatch<DeactiveStaffOfHealthCampActionTypes>) {
    dispatch(deactiveStaffOfHealthcampAPIResClearAction({} as DeactiveStaffOfHealthCampDetails, "", "", 0));
  };
};
