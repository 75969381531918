import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { UpdateDoctorDetailsActionTypes } from "./ActionTypes";
import { UpdateDoctorDetailsBody, UpdateDoctorDetailsRes } from "./Model";
import {
  updateDoctorDetailsAPIResClearAction,
  updateDoctorDetailsErrorAction,
  updateDoctorDetailsLoadingAction,
  updateDoctorDetailsSuccessAction,
  updateDoctorDetailsUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as UpdateDoctorDetailsRes;
export const updateDoctorDetails = (payload: UpdateDoctorDetailsBody) => {
  return function (dispatch: Dispatch<UpdateDoctorDetailsActionTypes>) {
    dispatch(updateDoctorDetailsLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/adminapp/api/adminActions/update_doctor_summary`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updateDoctorDetailsLoadingAction(false));
        dispatch(updateDoctorDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(updateDoctorDetailsLoadingAction(false));
        dispatch(
          updateDoctorDetailsErrorAction(
            {} as UpdateDoctorDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateDoctorDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateDoctorDetailsActionTypes>) {
    dispatch(
      updateDoctorDetailsUpdateAPIMsgAction(
        apiRes as UpdateDoctorDetailsRes,
        "",
        0
      )
    );
  };
};

export const clearDoctorDetailsAPIRes = () => {
  return function (dispatch: Dispatch<UpdateDoctorDetailsActionTypes>) {
    dispatch(
      updateDoctorDetailsAPIResClearAction({} as UpdateDoctorDetailsRes, "", 0)
    );
  };
};
