import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { PatientAddPointDataActionTypes } from "./ActionTypes";
import { AddPatientRes, PatientDataBody } from "./Model";
import {
  addExistingPatientAPIResClearAction,
  addExistingPatientErrorAction,
  addExistingPatientLoadingAction,
  addExistingPatientSuccessAction,
  addExistingPatientUpdateAPIMsgAction,
} from "./Action";
import { headers } from "../../component/Utility";

let apiRes = {} as AddPatientRes;
export const addHealthcampExistingPatient = (editBody: PatientDataBody) => {
  return function (dispatch: Dispatch<PatientAddPointDataActionTypes>) {
    dispatch(addExistingPatientLoadingAction(true));
    axios
      .post(
        baseUrl.baseUrl +
          `/caregiverapp/api/healthcamppatient/add_existing_patient_to_healthcamp_or_patient_organization`,
        editBody,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addExistingPatientLoadingAction(false));
        dispatch(addExistingPatientSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(addExistingPatientLoadingAction(false));
        dispatch(
          addExistingPatientErrorAction(
            {} as AddPatientRes,
            error.response !== undefined ? error.response.data.message : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : 0,
          ),
        );
      });
  };
};

export const updateAddExistingPtAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientAddPointDataActionTypes>) {
    dispatch(addExistingPatientUpdateAPIMsgAction(apiRes as AddPatientRes, "", 0));
  };
};

export const clearAddExistingPtAPIRes = () => {
  return function (dispatch: Dispatch<PatientAddPointDataActionTypes>) {
    dispatch(addExistingPatientAPIResClearAction({} as AddPatientRes, "", 0));
  };
};
