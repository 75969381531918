import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteExecutiveDialogClose } from "../Model";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { clearRemoveExecutiveAPIRes, removeExecutive } from "redux/Executive/RemoveExecutive/API";
import { RemoveExecutivePayload } from "redux/Executive/RemoveExecutive/Model";
import AdminLoader from "pages/AdminLoader";

type Props = {
  enableDialog: boolean;
  updateDialogState: (value: DeleteExecutiveDialogClose) => void;
  executiveDetails?: any;
};

export default function DeleteExecutive({
  enableDialog,
  updateDialogState,
  executiveDetails,
}: Props) {
  const dispatch = useDispatch();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const handleDeleteExecutiveModalChange = (passedVal: DeleteExecutiveDialogClose) => {
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
    updateDialogState(passedVal);
  };
  useEffect(() => {
    setDeleteModalActive(enableDialog);
  }, [enableDialog]);

  const removeExecutiveRes = useSelector((state: AppState) => state.removeExecutiveRes);
  const [removeExecutiveMsg, setRemoveExecutiveMsg] = useState("");
  const [removeExecutiveMsgType, setRemoveExecutiveMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (removeExecutiveRes?.successMsg !== "") {
      handleDeleteExecutiveModalChange({ value: false, category: 1 });
    }
    if (removeExecutiveRes?.errorMsg !== "") {
      setRemoveExecutiveMsgType("error");
      setRemoveExecutiveMsg(removeExecutiveRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeExecutiveRes]);

  return (
    <Dialog open={deleteModalActive} fullWidth maxWidth="sm">
      {removeExecutiveRes?.loading && <AdminLoader />}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Delete Executive</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => handleDeleteExecutiveModalChange({ value: false, category: 0 })}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="body1">
              Are you sure, you want to delete this Executive :{" "}
              {executiveDetails?.executiveFirstName !== "" &&
              executiveDetails?.executiveLastName !== ""
                ? executiveDetails?.executiveFirstName +
                  " " +
                  executiveDetails?.executiveLastName +
                  "?"
                : executiveDetails?.executiveFirstName + "?"}
            </Typography>
          </Grid>
          {removeExecutiveMsg !== "" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                onClose={() => {
                  setRemoveExecutiveMsg("");
                  dispatch(clearRemoveExecutiveAPIRes());
                }}
                severity={removeExecutiveMsgType}
              >
                {removeExecutiveMsg}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => handleDeleteExecutiveModalChange({ value: false, category: 0 })}
              sx={{ textTransform: "none" }}
            >
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() =>
                dispatch(
                  removeExecutive({
                    executiveId: String(executiveDetails?.id),
                    isActive: false,
                  } as RemoveExecutivePayload),
                )
              }
              sx={{ textTransform: "none" }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
