import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTaskListValue } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import * as _ from "lodash";
import { acceptorRejectBooking } from "../../redux/AppointmentBooking/ApiCall";
import { taskClose } from "../../redux/Task/API";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import { TaskDataList } from "../../redux/TaskAdmin/List/Model";
import PageLayout from "pages/Layout/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import AssignToExecutiveDialog from "./AssignToExecutiveDialog/inedx";

export default function TaskDetails() {
  const dispatch = useDispatch();
  const { currentPage } = useParams() as {
    currentPage: string;
  };
  const { rowIndex } = useParams() as {
    rowIndex: string;
  };

  // Call Store
  const adminAllTaskList = useSelector((state: AppState) => state.alltaskListValue);
  const taskValues: TaskDataList | undefined = _.filter(adminAllTaskList.adminTaskRes.taskDataList, [
    "id",
    Number(rowIndex),
  ])[0];

  const history = useHistory();

  // Call Accept or Reject Booking Appointment Store
  const acceptrejectBookingLoadingvalue = useSelector(
    (state: AppState) => state.acceptOrRejectBookingAppointmentValue.loading,
  );
  const acceptrejectBookingStatus = useSelector(
    (state: AppState) => state.acceptOrRejectBookingAppointmentValue.status,
  );
  const acceptrejectBookingMessage = useSelector(
    (state: AppState) => state.acceptOrRejectBookingAppointmentValue.message,
  );

  const [acceptRejectBookingSuccessToastActive, setAcceptRejectBookingSuccessToastActive] =
    useState(false);
  const toggleAcceptRejectBookingSuccessActive = () => {
    setAcceptRejectBookingSuccessToastActive(
      (acceptRejectBookingSuccessToastActive) => !acceptRejectBookingSuccessToastActive,
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const acceptRejectBookingSuccessToastmarkup = acceptRejectBookingSuccessToastActive ? (
    // <Toast
    //   content={acceptrejectBookingMessage}
    //   onDismiss={toggleAcceptRejectBookingSuccessActive}
    //   duration={1500}
    // />
    <Snackbar
      open={acceptRejectBookingSuccessToastActive}
      autoHideDuration={6000}
      onClose={toggleAcceptRejectBookingSuccessActive}
    >
      <Alert
        severity="success"
        onClose={toggleAcceptRejectBookingSuccessActive}
        sx={{ width: "100%" }}
      >
        {acceptrejectBookingMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const [acceptRejectBookingErrorToastActive, setAcceptRejectBookingErrorToastActive] =
    useState(false);
  const toggleAcceptRejectBookingErrorActive = () => {
    setAcceptRejectBookingErrorToastActive(
      (acceptRejectBookingErrorToastActive) => !acceptRejectBookingErrorToastActive,
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const acceptRejectBookingErrorToastmarkup = acceptRejectBookingErrorToastActive ? (
    // <Toast
    //   content={acceptrejectBookingMessage}
    //   error
    //   onDismiss={toggleAcceptRejectBookingErrorActive}
    //   duration={1500}
    // />
    <Snackbar
      open={acceptRejectBookingErrorToastActive}
      autoHideDuration={6000}
      onClose={toggleAcceptRejectBookingErrorActive}
    >
      <Alert
        severity="success"
        onClose={toggleAcceptRejectBookingErrorActive}
        sx={{ width: "100%" }}
      >
        {acceptrejectBookingMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Call Accept or Reject Booking Appointment Store
  const executiveDropDownListLoadingvalue = useSelector(
    (state: AppState) => state.executiveDropdownListvalue.loading,
  );
  // Call Accept or Reject Booking Appointment Store
  const assigntaskToExecutiveStatus = useSelector(
    (state: AppState) => state.assignExecutiveValues.status,
  );
  const [assigntaskToExecutiveSuccessToastActive, setAssigntaskToExecutiveSuccessToastActive] =
    useState(false);
  const toggleAssignTaskToExecutiveSuccessActive = () => {
    setAssigntaskToExecutiveSuccessToastActive(
      (assigntaskToExecutiveSuccessToastActive) => !assigntaskToExecutiveSuccessToastActive,
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const assignTaskToExecutveSuccessToastMarkup = assigntaskToExecutiveSuccessToastActive ? (
    // <Toast
    //   content="Task assigned successfully"
    //   onDismiss={toggleAssignTaskToExecutiveSuccessActive}
    //   duration={1500}
    // />
    <Snackbar
      open={assigntaskToExecutiveSuccessToastActive}
      autoHideDuration={6000}
      onClose={toggleAssignTaskToExecutiveSuccessActive}
    >
      <Alert
        severity="success"
        onClose={toggleAssignTaskToExecutiveSuccessActive}
        sx={{ width: "100%" }}
      >
        Task assigned successfully
      </Alert>
    </Snackbar>
  ) : null;

  const [assigntaskToExecutiveErrorToastActive, setAssigntaskToExecutiveErrorToastActive] =
    useState(false);
  console.log(assigntaskToExecutiveErrorToastActive);
  const toggleAssignTaskToExecutiveErrorActive = () => {
    setAssigntaskToExecutiveErrorToastActive(
      (assigntaskToExecutiveErrorToastActive) => !assigntaskToExecutiveErrorToastActive,
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const assignTaskToExecutveErrorToastMarkup = acceptRejectBookingErrorToastActive ? (
    // <Toast
    //   content="Something went wrong"
    //   error
    //   onDismiss={toggleAssignTaskToExecutiveErrorActive}
    //   duration={1500}
    // />
    <Snackbar
      open={acceptRejectBookingErrorToastActive}
      autoHideDuration={6000}
      onClose={toggleAssignTaskToExecutiveErrorActive}
    >
      <Alert
        severity="error"
        onClose={toggleAssignTaskToExecutiveErrorActive}
        sx={{ width: "100%" }}
      >
        Something went wrong
      </Alert>
    </Snackbar>
  ) : null;

  // Call task Close Store
  const taskCloseLoadingvalue = useSelector((state: AppState) => state.taskCloseValues.loading);
  const taskCloseStatus = useSelector((state: AppState) => state.taskCloseValues.status);
  const taskCloseMessage = useSelector((state: AppState) => state.taskCloseValues.message);

  const [taskCloseSuccessToastActive, setTaskCloseSuccessToastActive] = useState(false);
  const toggletaskCloseSuccessActive = () => {
    setTaskCloseSuccessToastActive((taskCloseSuccessToastActive) => !taskCloseSuccessToastActive);
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const taskCloseSuccessToastMarkup = taskCloseSuccessToastActive ? (
    // <Toast
    //   content={taskCloseMessage}
    //   onDismiss={toggletaskCloseSuccessActive}
    //   duration={1500}
    // />
    <Snackbar
      open={taskCloseSuccessToastActive}
      autoHideDuration={6000}
      onClose={toggletaskCloseSuccessActive}
    >
      <Alert severity="success" onClose={toggletaskCloseSuccessActive} sx={{ width: "100%" }}>
        {taskCloseMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const [taskCloseErrorToastActive, setTaskCloseErrorToastActive] = useState(false);
  const toggletaskCloseErrorActive = () => {
    setTaskCloseErrorToastActive((taskCloseErrorToastActive) => !taskCloseErrorToastActive);
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const taskCloseErrorToastMarkup = taskCloseErrorToastActive ? (
    // <Toast
    //   content={taskCloseMessage}
    //   error
    //   onDismiss={toggletaskCloseErrorActive}
    //   duration={1500}
    // />
    <Snackbar
      open={taskCloseErrorToastActive}
      autoHideDuration={6000}
      onClose={toggletaskCloseErrorActive}
    >
      <Alert severity="error" onClose={toggletaskCloseErrorActive} sx={{ width: "100%" }}>
        {taskCloseMessage}
      </Alert>
    </Snackbar>
  ) : null;

  function gotoCaseDetails() {
    history.push(`/casedetails/${taskValues?.patientId}/${taskValues?.caseId}`);
  }

  const acceptbokingBody = {
    taskId: Number(rowIndex),
    requestStatus: Number(1),
  };
  // Accept Booking Appointment API
  const acceptBooking = () => {
    dispatch(acceptorRejectBooking(acceptbokingBody));
    setAcceptRejectBookingSuccessToastActive(
      (acceptRejectBookingSuccessToastActive) => !acceptRejectBookingSuccessToastActive,
    );
    setAcceptRejectBookingErrorToastActive(
      (acceptRejectBookingErrorToastActive) => !acceptRejectBookingErrorToastActive,
    );
  };

  const rejectBookingBody = {
    taskId: Number(rowIndex),
    requestStatus: Number(-1),
  };
  // Receject Appointment Appointment API
  const rejectBooking = () => {
    dispatch(acceptorRejectBooking(rejectBookingBody));
    setAcceptRejectBookingSuccessToastActive(
      (acceptRejectBookingSuccessToastActive) => !acceptRejectBookingSuccessToastActive,
    );
    setAcceptRejectBookingErrorToastActive(
      (acceptRejectBookingErrorToastActive) => !acceptRejectBookingErrorToastActive,
    );
  };
 
  let taskCompleteBody = {
    taskId: String(rowIndex),
    taskCloseComment: "taskCloseByAdmin",
  };
  const handleTaskComplete = () => {
    dispatch(taskClose(taskCompleteBody));
    setTaskCloseSuccessToastActive((taskCloseSuccessToastActive) => !taskCloseSuccessToastActive);
    setTaskCloseErrorToastActive((taskCloseErrorToastActive) => !taskCloseErrorToastActive);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (taskValues === undefined) {
      dispatch(getAllTaskListValue(Number(currentPage)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taskValues]);

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        acceptrejectBookingLoadingvalue ||
          executiveDropDownListLoadingvalue ||
          taskCloseLoadingvalue,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptrejectBookingLoadingvalue, executiveDropDownListLoadingvalue, taskCloseLoadingvalue]);
  const [showAssignExecutiveDialog, setShowAssignExecutiveDialog] = useState(false);
  const updateDialogState = () => {
    setShowAssignExecutiveDialog(true);
  };
  const updateAssignToExeDialogValFromChild = (passedVal: boolean) => {
    setShowAssignExecutiveDialog(passedVal);
  };
 
  const taskTable = (
    <Box pt={4}>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  <Button
                    variant="outlined"
                    onClick={acceptBooking}
                    sx={{ textTransform: "none", fontSize: "bold" }}
                  >
                    Accept Appointment For Doctor
                  </Button>
                  <Button variant="outlined" onClick={rejectBooking} sx={{ textTransform: "none" }}>
                    Reject Appointment For Doctor
                  </Button>
                  <Button
                    variant="contained"
                    onClick={gotoCaseDetails}
                    sx={{ textTransform: "none" }}
                  >
                    View Case Details
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                {[
                  {
                    term: "Title",
                    description: `${taskValues === undefined ? "" : taskValues.title}`,
                  },
                  {
                    term: "Task For",
                    description: `${taskValues === undefined ? "" : taskValues.patientName}`,
                  },
                  {
                    term: "Why want 2nd opinion ?",
                    description: `${taskValues === undefined ? "" : taskValues.whyWant2ndOpinion}`,
                  },
                  {
                    term: "Reason for 2ndOpinion",
                    description: `${
                      taskValues === undefined ? "" : taskValues.secondOpinionReasons
                    }`,
                  },
                  {
                    term: "Consultation Type",
                    description: `${taskValues === undefined ? "" : taskValues.consultationType}`,
                  },
                  {
                    term: "Task Create Date",
                    description: `${taskValues === undefined ? "" : taskValues.created_date}`,
                  },
                  {
                    term: "Note",
                    description: `${
                      taskValues === undefined
                        ? ""
                        : taskValues.note === null
                        ? ""
                        : taskValues.note
                    }`,
                  },
                  {
                    term: "Completed",
                    description: `${
                      taskValues === undefined || taskValues === null
                        ? ""
                        : taskValues.completed === false
                        ? "No"
                        : "yes"
                    }`,
                  },
                  {
                    term: "Assigned To",
                    description: `${
                      taskValues === undefined || taskValues === null ? "" : taskValues.assigned_to
                    }`,
                  },
                ].map((row) => (
                  <TableRow key={row.term}>
                    <TableCell component="th" scope="row">
                      <Typography variant="h5">{row.term}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">{row.description}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{ backgroundColor: "#f70000", color: "#ffffff" }}
                onClick={handleTaskComplete}
              >
                Mark as Complete
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => updateDialogState()}>
                Assign to Executive
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );

  return (
    <PageLayout>
      <MWPageTitle backButton={true} title="Task List Details" />
      {showAssignExecutiveDialog ? (
          <AssignToExecutiveDialog
            assignExecutiveDialogEnable={showAssignExecutiveDialog}
            assignToExecutiveDialogUpdateState={updateAssignToExeDialogValFromChild}
            taskValues={taskValues}
            rowIndex={rowIndex}
          />
        ) : null}
      {taskTable}
      {acceptrejectBookingStatus! === 1 ? acceptRejectBookingSuccessToastmarkup : ""}
      {acceptrejectBookingStatus! === -1 ? acceptRejectBookingErrorToastmarkup : ""}
      {assigntaskToExecutiveStatus! === 1 ? assignTaskToExecutveSuccessToastMarkup : ""}
      {assigntaskToExecutiveStatus! !== 1 ? assignTaskToExecutveErrorToastMarkup : ""}
      {taskCloseStatus! === 1 ? taskCloseSuccessToastMarkup : ""}
      {taskCloseStatus! === -2 || taskCloseStatus! === -3 ? taskCloseErrorToastMarkup : ""}
    </PageLayout>
  );
}
