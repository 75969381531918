import { CompetitionListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const COMPETITION_LIST_BY_PATIENT_ID_LOADING = "COMPETITION_LIST_BY_PATIENT_ID_LOADING";
export const COMPETITION_LIST_BY_PATIENT_ID_SUCCESS = "COMPETITION_LIST_BY_PATIENT_ID_SUCCESS";
export const COMPETITION_LIST_BY_PATIENT_ID_FAIL = "COMPETITION_LIST_BY_PATIENT_ID_FAIL";
export const COMPETITION_LIST_BY_PATIENT_ID_API_MSG = "COMPETITION_LIST_BY_PATIENT_ID_API_MSG";
export const COMPETITION_LIST_BY_PATIENT_ID_API_RES = "COMPETITION_LIST_BY_PATIENT_ID_API_RES";

export interface CompetitionListByPatientIdDetailsState {
  competitionListByPatientIdRes: CompetitionListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface CompetitionListByPatientIdLoading {
  type: typeof COMPETITION_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface CompetitionListByPatientIdSuccess {
  type: typeof COMPETITION_LIST_BY_PATIENT_ID_SUCCESS;
  payload: CompetitionListByPatientIdDetails;
  successMsg: string;
}
export interface CompetitionListByPatientIdFail {
  type: typeof COMPETITION_LIST_BY_PATIENT_ID_FAIL;
  payload: CompetitionListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface CompetitionListByPatientIdAPIMsg {
  type: typeof COMPETITION_LIST_BY_PATIENT_ID_API_MSG;
  payload: CompetitionListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof COMPETITION_LIST_BY_PATIENT_ID_API_RES;
  payload: CompetitionListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type CompetitionListByPatientIdActionTypes =
  | CompetitionListByPatientIdLoading
  | CompetitionListByPatientIdSuccess
  | CompetitionListByPatientIdFail
  | CompetitionListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
