import {
  PAYMENT_STATUS_DATA,
  PAYMENT_STATUS_FAIL,
  PAYMENT_STATUS_LOADING,
  PAYMENT_STATUS_SUCCESS,
  PaymentStatusActionTypes,
} from "./ActionTypes";
import { PaymentStatusResponse } from "./Model";

export const statusPaymentAction = (
  statusPaymentRes: PaymentStatusResponse,
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_DATA,
    payload: statusPaymentRes,
  };
};

export const statusPaymentLoadingAction = (loading: boolean): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_LOADING,
    loading: loading,
  };
};

export const statusPaymentSuccessAction = (
  statusPaymentRes: PaymentStatusResponse,
  successMsg: string,
  status: number,
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_SUCCESS,
    payload: statusPaymentRes,
    successMsg: successMsg,
    status: status,
  };
};

export const statusPaymentErrorAction = (
  statusPaymentRes: PaymentStatusResponse,
  errMsg: string,
  status: number,
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_FAIL,
    payload: statusPaymentRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const statusPaymentUpdateAPIMsgAction = (
  statusPaymentRes: PaymentStatusResponse,
  errMsg: string,
  status: number,
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_FAIL,
    payload: statusPaymentRes,
    errorMsg: errMsg,
    status: status,
  };
};

export const statusPaymentAPIResClearAction = (
  statusPaymentRes: PaymentStatusResponse,
  errMsg: string,
  status: number,
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_FAIL,
    payload: statusPaymentRes,
    errorMsg: errMsg,
    status: status,
  };
};
