import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  Typography,
  Portal,
  Snackbar,
  Alert,
  AlertColor,
  IconButton,
  Grid,
  Paper,
  Autocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";
import { AppState } from "redux/store/Store";
import { useEffect, useState } from "react";
import { getPreferenceDetails } from "redux/Preference/GetPreference/API";
import { addAppointmentToHealthcampInstance } from "redux/AppointmentListOfHealthcampInstance/AddAppoinmentHealthCampInstance/Api";
import { AddAppointmentListHealthcampBody } from "redux/AppointmentListOfHealthcampInstance/AddAppoinmentHealthCampInstance/Model";
import CloseIcon from "@mui/icons-material/Close";
import MWErrorText from "./MWErrorText";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { GetInstanceListBody } from "redux/InstanceListOfHealthcamp /Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { GetSessionListBody } from "redux/HealthcampSessionList/Model";
import { getOrganizationListValue } from "redux/OrganizationList/API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import { LoadingButton } from "@mui/lab";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

type Props = {
  updatepatientId: number;
};

export default function Appointment({ updatepatientId }: Props) {
  const dispatch = useDispatch();
  const preferenceDetails = useSelector((state: AppState) => state.getPreferenceDetails);
  const addAppointmentToHealthcampInstanceValue = useSelector(
    (state: AppState) => state.addAppointmentToHealthcampInstance,
  );
  const organizationList = useSelector((state: AppState) => state.organizationListValue);
  const healthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const instanceList = useSelector((state: AppState) => state.instanceListValue);
  const sessionList = useSelector((state: AppState) => state.sessionListValues);
  const addAdvCheckIn = useSelector((state: AppState) => state.addPatientBySessionIdValues);

  const [organizationDropDownListValue, setOrganizationDropDownListValue] = useState([] as any[]);
  const [healthCampDropDownListValue, setHealthCampDropDownListValue] = useState([] as any[]);
  const [instanceListDropDownListValue, setInstanceListDropDownListValue] = useState([] as any[]);
  // const [healthCampSessionDropDownListValue, setHealthCampSessionDropDownListValue] = useState(
  //   [] as any[],
  // );

  const [errorMsg, setErrorMsg] = useState("");
  const [bannerState, setBannerState] = useState(false);

  // const [organizationName, setOrganizationName] = useState("");
  //   const [healthCampName, setHealthCampName] = useState("");
  const [instanceName, setInstanceName] = useState("");
  //   const [healthcampSessionName, setHealthcampSessionName] = useState("");
  //   const [patientId, setPatientId] = useState(0);
  //   const [addCheckInMsg, setAddCheckInMsg] = useState("");
  //   const [msgType, setMsgType] = useState<AlertColor>("success");
  //   const addCheckInMsgClose = (passedVal: boolean) => {
  //     if (passedVal) {
  //       setAddCheckInMsg("");
  //     }
  //   };

  const closeBanner = (passedVal: boolean) => {
    setBannerState(passedVal);
  };

  const closeSummaryBanner = () => {
    setSetPreferenceSuccess("");
  };
  const [setPreferenceSuccess, setSetPreferenceSuccess] = useState("");
  //   const [setPreferenceError, setSetPreferenceError] = useState("");

  const addHealthCampPatientSuccessDiv = setPreferenceSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      {setPreferenceSuccess}
    </Alert>
  ) : (
    ""
  );

  const filterOrgType = (_orgType: any, _e: any) => {
    // setOrganizationName(orgType.id);
    // dispatch(getHealthCampByOrganizationList({ organizationId: orgType.id }));
  };
  const filterHealthcampType = (healthcampType: any, _e: any) => {
    // setHealthCampName(healthcampType.id);
    dispatch(
      getInstanceListByHealthCampId(
        {
          healthcampId: healthcampType.id,
        } as GetInstanceListBody,
        1,
      ),
    );
  };
  const filterHealthcampInstanceType = (instanceType: any, _e: any) => {
    setInstanceName(instanceType.id);
    dispatch(
      getSessionListByHealthCampId({
        healthcampInstanceId: instanceType.id,
      } as GetSessionListBody),
    );
  };
  // const filterHealthcampSessionType = (_sessionType: any, _e: any) => {
  // setHealthcampSessionName(sessionType.id);
  // setHealthcampSessionName(sessionType.id);
  // };

  const [addAppointmentPt, setAddAppoinmentPt] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  const [open, setOpen] = useState(false);
  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const addAppointmentSubmit = () => {
    const addHealthcampAppoinmentListBody = {
      healthcampInstanceId: String(instanceName),
      patientId: String(updatepatientId),
    } as AddAppointmentListHealthcampBody;
    if (instanceName === "") {
      setErrorMsg("Please select instance name");
      setBannerState(true);
    } else {
      dispatch(addAppointmentToHealthcampInstance(addHealthcampAppoinmentListBody));
    }
  };

  useEffect(() => {
    dispatch(getPreferenceDetails());
    dispatch(getOrganizationListValue());
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatepatientId]);

  useEffect(() => {
    setInstanceName(
      String(
        preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences
          ?.healthcampInstanceId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferenceDetails]);

  useEffect(() => {
    if (organizationList?.getOrganizationRes?.patientOrganizationList !== undefined) {
      let orgDropdownValue = organizationList?.getOrganizationRes?.patientOrganizationList.map(
        (element: any) => ({
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
        }),
      );
      setOrganizationDropDownListValue(orgDropdownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);

  useEffect(() => {
    if (healthcampList?.healthcampListValue?.HealthCampList !== undefined) {
      let healthCampDropdownValue = healthcampList?.healthcampListValue?.HealthCampList.map(
        (element: any) => ({
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
        }),
      );
      setHealthCampDropDownListValue(healthCampDropdownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampList]);

  useEffect(() => {
    if (instanceList?.getInstanceList?.healthcampInstanceList !== undefined) {
      let instanceListDropdownValue = instanceList?.getInstanceList?.healthcampInstanceList.map(
        (element: any) => ({
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
        }),
      );
      setInstanceListDropDownListValue(instanceListDropdownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceList]);

  // useEffect(() => {
  //   if (sessionList?.getSessionList?.healthcampSessionList !== undefined) {
  //     let healthCampSessionListDropdownValue =
  //       sessionList?.getSessionList?.healthcampSessionList.map((element: any) => ({
  //         id: element.id,
  //         displayName: element.displayName !== "" ? element.displayName : "",
  //       }));
  //     setHealthCampSessionDropDownListValue(healthCampSessionListDropdownValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sessionList]);

  useEffect(() => {
    if (
      addAppointmentToHealthcampInstanceValue?.addAppoinmentListToHealthcampResponse?.message !==
      undefined
    ) {
      setAddAppoinmentPt(addAppointmentToHealthcampInstanceValue?.successMsg);
      setSnackBarType("success");
      setOpen(true);
    }
    if (addAppointmentToHealthcampInstanceValue?.errorMsg !== "") {
      setSnackBarType("error");
      setAddAppoinmentPt(addAppointmentToHealthcampInstanceValue?.errorMsg);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAppointmentToHealthcampInstanceValue]);

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ pl: 2, pr: 4 }}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={9} sm={9} md={9} lg={8} xl={8}>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  sx={{ padding: 1 }}
                  spacing={1}
                >
                  <Typography variant="h6">
                    Book an appointment with following Preference
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                  >
                    {preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences.hasOwnProperty(
                      "organizationName",
                    ) ? (
                      <>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <CorporateFareOutlinedIcon />
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Typography variant="body2">
                            {
                              preferenceDetails?.GetPreferenceDetailsResponse
                                ?.CareGiverDefaultPreferences?.organizationName
                            }
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences.hasOwnProperty(
                      "healthcampInstanceName",
                    ) ? (
                      <>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <HolidayVillageOutlinedIcon />
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Typography variant="body2">
                            {
                              preferenceDetails?.GetPreferenceDetailsResponse
                                ?.CareGiverDefaultPreferences?.healthcampInstanceName
                            }
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {preferenceDetails?.GetPreferenceDetailsResponse?.CareGiverDefaultPreferences.hasOwnProperty(
                      "healthcampSessionName",
                    ) ? (
                      <>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <AccessTimeOutlinedIcon />
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Typography variant="body2">
                            {
                              preferenceDetails?.GetPreferenceDetailsResponse
                                ?.CareGiverDefaultPreferences?.healthcampSessionName
                            }
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={4} xl={4}>
                <LoadingButton
                  onClick={() => addAppointmentSubmit()}
                  loading={addAppointmentToHealthcampInstanceValue?.loading}
                  variant="contained"
                >
                  Book Appointment
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Paper sx={{ p: 2, mr: 4, mb: 2 }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography fontWeight="bold" mb={1} justifyContent="center">
                  Advance Check-In
                </Typography>
              </Grid>
              <Grid item>
                {organizationList?.loading ? <CircularProgress size="20" /> : null}
                {healthcampList?.loading ? <CircularProgress size="20" /> : null}
                {instanceList?.loading ? <CircularProgress size="20" /> : null}
                {sessionList?.loading ? <CircularProgress size="20" /> : null}
                {addAdvCheckIn?.loading ? <CircularProgress size="20" /> : null}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  id="controllable-states-demo"
                  getOptionLabel={(option) => `${option.displayName}`}
                  options={organizationDropDownListValue}
                  onChange={(event: any, newValue: any) => {
                    filterOrgType(newValue, event);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Organization Name" variant="outlined" />
                  )}
                  size="small"
                  disableClearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  id="controllable-states-demo"
                  getOptionLabel={(option) => `${option.displayName}`}
                  options={healthCampDropDownListValue}
                  onChange={(event: any, newValue: any) => {
                    filterHealthcampType(newValue, event);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Healthcamp Name" variant="outlined" />
                  )}
                  size="small"
                  disableClearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  id="controllable-states-demo"
                  getOptionLabel={(option) => `${option.displayName}`}
                  options={instanceListDropDownListValue}
                  onChange={(event: any, newValue: any) => {
                    filterHealthcampInstanceType(newValue, event);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Instance name" variant="outlined" />
                  )}
                  size="small"
                  disableClearable
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  id="controllable-states-demo"
                  getOptionLabel={(option) => `${option.displayName}`}
                  options={healthCampSessionDropDownListValue}
                  onChange={(event: any, newValue: any) => {
                    filterHealthcampSessionType(newValue, event);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Session name" variant="outlined" />
                  )}
                  size="small"
                  disableClearable
                />
              </Grid> */}
            </Grid>
            <Box py={2}>
              <MWErrorText
                errormessage={errorMsg}
                bannerErrorState={bannerState}
                closeBannerState={closeBanner}
              />
              {addHealthCampPatientSuccessDiv}
            </Box>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              {/* <Button onClick={addAppointmentSubmit} variant="outlined">
                Book Appointment
              </Button> */}
              <LoadingButton
                onClick={() => addAppointmentSubmit()}
                loading={addAppointmentToHealthcampInstanceValue?.loading}
                variant="outlined"
              >
                Book Appointment
              </LoadingButton>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Portal>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} action={action}>
          <Alert severity={snackBarType!}>{addAppointmentPt}</Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
}
