import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddStaffOfCompetitionActionTypes } from "./ActionTypes";
import { AddStaffOfCompetitionBody, AddStaffOfCompetitionDetails } from "./Model";
import {
  addStaffOfCompetitionAPIResClearAction,
  addStaffOfCompetitionErrorAction,
  addStaffOfCompetitionLoadingAction,
  addStaffOfCompetitionSuccessAction,
  addStaffOfCompetitionAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddStaffOfCompetitionDetails;
export const addStaffOfCompetitionApiCall = (payload: AddStaffOfCompetitionBody) => {
  return function (dispatch: Dispatch<AddStaffOfCompetitionActionTypes>) {
    dispatch(addStaffOfCompetitionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/competitionapp/api/competitionStaffList/add_staff_competition`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addStaffOfCompetitionLoadingAction(false));
        dispatch(
          addStaffOfCompetitionSuccessAction(
            res.data !== undefined ? res.data : ({} as AddStaffOfCompetitionDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addStaffOfCompetitionLoadingAction(false));
        dispatch(
          addStaffOfCompetitionErrorAction(
            {} as AddStaffOfCompetitionDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const addStaffOfCompetitionAPIResMsg = () => {
  return function (dispatch: Dispatch<AddStaffOfCompetitionActionTypes>) {
    dispatch(addStaffOfCompetitionAPIMsgAction(apiRes as AddStaffOfCompetitionDetails, "", "", 0));
  };
};

export const clearAddStaffOfCompetitionAPIRes = () => {
  return function (dispatch: Dispatch<AddStaffOfCompetitionActionTypes>) {
    dispatch(addStaffOfCompetitionAPIResClearAction({} as AddStaffOfCompetitionDetails, "", "", 0));
  };
};
