import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import { DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import MWTextField from "../../../../../component/MWTextField";
import { AddScoundRoundDetailsBody } from "redux/AddSecondRound/Model";
import { addSecondRoundApi } from "redux/AddSecondRound/API";
import { AppState } from "redux/store/Store";
import { getJudgingDetailsList } from "redux/JudgingDetailsList/API";
import { GetJudgingDetailsListBody } from "redux/JudgingDetailsList/Model";

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  addPatientTeamDialogEnable?: boolean | any;
  addPatientTeamDialogUpdateState?: boolean | any;
  competitionMapId?: string | any;
  competitionid?: string | any;
  secondRoundValue?: any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((_theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((_theme: Theme) => ({
  root: {
    margin: 0,
    padding: "8px",
  },
}))(MuiDialogActions);

export default function AddSecondRoundDialog({
  addPatientTeamDialogEnable,
  addPatientTeamDialogUpdateState,
  competitionMapId,
  competitionid,
  secondRoundValue,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addPatientTeamDialogUpdateState(false);
  };
  
  const [rankNumber, setRankNumber] = useState("");

  // call Store
  const addSecondRoundResValues = useSelector((state: AppState) => state.addSecondRoundDetailsValue);

  useEffect(() => {
    if (addSecondRoundResValues?.addSecondRoundRes?.message !== undefined) {
      setDialogState(false);
      addPatientTeamDialogUpdateState(false);
      const patientListBody = {
        competitionId: competitionid,
        patientObservationKey: "photograding",
      } as GetJudgingDetailsListBody;
      dispatch(getJudgingDetailsList(patientListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSecondRoundResValues]);


  const [phoneNumberError, setPhoneNumberError] = useState("");
  const rankErrordiv = phoneNumberError ? <Typography variant="body1">{phoneNumberError}</Typography> : "";

  // Add second round body
  const addSecondRoundBody = {
    rankInCompetition: rankNumber,
    patientDetailCompetitionMappingId: competitionMapId,
  } as AddScoundRoundDetailsBody;
  // Add patient eam API
  const addSecondRound = () => {
    if (rankNumber === "") {
      setPhoneNumberError("Please enter rank");
    } else {
      if (secondRoundValue !== undefined) {
        secondRoundValue.forEach((element: any) => {
          const addSecondRoundBody = {
            rankInCompetition: rankNumber,
            patientDetailCompetitionMappingId: element.patientDetailCompetitionMappingId,
          } as AddScoundRoundDetailsBody;
          dispatch(addSecondRoundApi(addSecondRoundBody));
        })

      } else { dispatch(addSecondRoundApi(addSecondRoundBody)); }
    }
  };

  React.useEffect(() => {
    setDialogState(addPatientTeamDialogEnable);
  }, [addPatientTeamDialogEnable]);

  return (
    <div>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h5">Select Next Round</Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <MWTextField
                label="Rank"
                value={rankNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRankNumber(event.target.value);
                }}
                placeholder="Please enter rank"
                id="rnakID"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{rankErrordiv}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={addSecondRound}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
