import {
  AddExistingPatientCaseToSessionActionTypes,
  ADD_EXISTING_PATIENT_TO_SESSION_FAIL,
  ADD_EXISTING_PATIENT_TO_SESSION_LOADING,
  ADD_EXISTING_PATIENT_TO_SESSION_SUCCESS,
  AddExistingPatientCaseToSessionState,
  ADD_EXISTING_PATIENT_TO_SESSION_API_MSG,
  ADD_EXISTING_PATIENT_TO_SESSION_API_RES,
} from "./ActionTypes";
import { AddExistingPatientCaseToSessionDetails } from "./Model";

const initialStateGetPosts: AddExistingPatientCaseToSessionState = {
  loading: false,
  addExistingPatientCaseToSessionRes: {} as AddExistingPatientCaseToSessionDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addExistingPatientCaseToSessionReducer = (
  state = initialStateGetPosts,
  action: AddExistingPatientCaseToSessionActionTypes,
): AddExistingPatientCaseToSessionState => {
  switch (action.type) {
    case ADD_EXISTING_PATIENT_TO_SESSION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_EXISTING_PATIENT_TO_SESSION_SUCCESS:
      return {
        ...state,
        addExistingPatientCaseToSessionRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_EXISTING_PATIENT_TO_SESSION_FAIL:
      return {
        ...state,
        addExistingPatientCaseToSessionRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXISTING_PATIENT_TO_SESSION_API_MSG:
      return {
        ...state,
        addExistingPatientCaseToSessionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_EXISTING_PATIENT_TO_SESSION_API_RES:
      return {
        ...state,
        addExistingPatientCaseToSessionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
