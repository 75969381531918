import { AddAppointmentListHealthcampRes } from "./Model";
export const ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING = "ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING";
export const ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS = "ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS";
export const ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL = "ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL";
export const ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG = "ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG";
export const ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES = "ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES";

export interface AddAppoinmentListToHealthcampState {
  addAppoinmentListToHealthcampResponse: AddAppointmentListHealthcampRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddAppoinmentListToHealthcampLoading {
  type: typeof ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_LOADING;
  loading: boolean;
}
export interface AddAppoinmentListToHealthcampSuccess {
  type: typeof ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_SUCCESS;
  payload: AddAppointmentListHealthcampRes;
  successMsg: string;
}
export interface AddAppoinmentListToHealthcampFail {
  type: typeof ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_FAIL;
  payload: AddAppointmentListHealthcampRes;
  errorMsg: string;
  status: number;
}
export interface AddAppoinmentListToHealthcampUpdateAPIMsg {
  type: typeof ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_MSG;
  payload: AddAppointmentListHealthcampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddAppoinmentListToHealthcampUpdateAPIRes {
  type: typeof ADD_APPOINTMENT_LIST_TO_HEALTHCAMP_INSTANCE_UPDATE_API_RES;
  payload: AddAppointmentListHealthcampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddAppoinmentListToHealthcampActionTypes =
  | AddAppoinmentListToHealthcampLoading
  | AddAppoinmentListToHealthcampSuccess
  | AddAppoinmentListToHealthcampFail
  | AddAppoinmentListToHealthcampUpdateAPIMsg
  | AddAppoinmentListToHealthcampUpdateAPIRes;
