import {
  COMPETITION_DEACTIVE_STAFF_LIST_FAIL,
  COMPETITION_DEACTIVE_STAFF_LIST_LOADING,
  COMPETITION_DEACTIVE_STAFF_LIST_SUCCESS,
  COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG,
  COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_RES,
  GetCompetitionDeactiveStaffListActionTypes,
} from "./ActionTypes";
import { GetCompetitionDeactiveStaffListRes } from "./Model";

export const getCompetitionDeactiveStaffListLoadingAction = (
  loading: boolean,
): GetCompetitionDeactiveStaffListActionTypes => {
  return {
    type: COMPETITION_DEACTIVE_STAFF_LIST_LOADING,
    loading: loading,
  };
};

export const getCompetitionDeactiveStaffListSuccessAction = (
  getCompetitionDeactiveStaffListResponse: GetCompetitionDeactiveStaffListRes,
  successMsg: string,
): GetCompetitionDeactiveStaffListActionTypes => {
  return {
    type: COMPETITION_DEACTIVE_STAFF_LIST_SUCCESS,
    payload: getCompetitionDeactiveStaffListResponse,
    successMsg: successMsg,
  };
};

export const getCompetitionDeactiveStaffListErrorAction = (
  getCompetitionDeactiveStaffListResponse: GetCompetitionDeactiveStaffListRes,
  errMsg: string,
  status: number,
): GetCompetitionDeactiveStaffListActionTypes => {
  return {
    type: COMPETITION_DEACTIVE_STAFF_LIST_FAIL,
    payload: getCompetitionDeactiveStaffListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getCompetitionDeactiveStaffListUpdateAPIMsgAction = (
  getCompetitionDeactiveStaffListResponse: GetCompetitionDeactiveStaffListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetCompetitionDeactiveStaffListActionTypes => {
  return {
    type: COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_MSG,
    payload: getCompetitionDeactiveStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getCompetitionDeactiveStaffListAPIResClearAction = (
  getCompetitionDeactiveStaffListResponse: GetCompetitionDeactiveStaffListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetCompetitionDeactiveStaffListActionTypes => {
  return {
    type: COMPETITION_DEACTIVE_STAFF_LIST_UPDATE_API_RES,
    payload: getCompetitionDeactiveStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
