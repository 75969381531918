import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import {
  addInstanceApiCall,
  clearAddInstanceAPIRes,
  updateAddInstanceAPIResMsg,
} from "redux/AddInstance/API";
import { AddInstanceBody } from "redux/AddInstance/Model";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import _ from "lodash";
import { EditInstanceBody } from "redux/EditInstance/Model";
import { clearEditInstanceAPIRes, editInstanceApiCall } from "redux/EditInstance/API";
import InstanceHistoryDialog from "../InstanceHistoryDialog";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  healthcampid?: string | any;
  healthcampInstanceId?: string | any;
};

export default function AddHealthcampInstanceDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  healthcampid,
  healthcampInstanceId,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const addResponse = useSelector((state: AppState) => state.addInstanceResValue);
  const editInstanceResponse = useSelector((state: AppState) => state.editInstanceValue);
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const [instanceValue, setInstanceValue] = useState("");
  const [startDate, setStartDate] = React.useState<Dayjs | null | string>(dayjs(new Date()));
  const [endDate, setEndDate] = React.useState<Dayjs | null | string>(dayjs(new Date()));
  const [showHistory, setShowHistory] = useState(false);
  const handleDateChange = (startDateValue: Dayjs | null | string) => {
    setStartDate(dayjs(startDateValue));
  };
  const handleEndDateChange = (endDateValue: Dayjs | null | string) => {
    setEndDate(dayjs(endDateValue));
  };

  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(updateAddInstanceAPIResMsg());
    dispatch(clearAddInstanceAPIRes());
    dispatch(clearEditInstanceAPIRes());
  };

  const [getInstanceListObject, setGetInstanceListObject] = React.useState<
    HealthcampInstanceList | undefined
  >(undefined);

  useEffect(() => {
    if (instanceListResponseValue?.getInstanceList?.healthcampInstanceList !== undefined) {
      setGetInstanceListObject(
        _.find(instanceListResponseValue?.getInstanceList?.healthcampInstanceList, [
          "id",
          Number(healthcampInstanceId),
        ]),
      );
    } else {
      setGetInstanceListObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceListResponseValue]);

  useEffect(() => {
    if (getInstanceListObject !== undefined) {
      if (getInstanceListObject.displayName !== undefined) {
        setInstanceValue(getInstanceListObject.displayName);
      } else setInstanceValue("");
      setStartDate(
        getInstanceListObject?.instanceStartDate !== undefined
          ? getInstanceListObject?.instanceStartDate
          : null,
      );
      setEndDate(
        getInstanceListObject?.instanceEndDate !== undefined
          ? getInstanceListObject?.instanceEndDate
          : null,
      );
    } else {
      setInstanceValue("");
      setEndDate(dayjs(new Date()));
      setEndDate(dayjs(new Date()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstanceListObject]);

  useEffect(() => {
    if (addResponse?.successMsg !== "" || editInstanceResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse, editInstanceResponse]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  const addInstance = () => {
    if (healthcampInstanceId !== "") {
      let editInstanceBody = {
        healthinstanceId: healthcampInstanceId,
        displayName: instanceValue,
        instanceStartDate: startDate !== null && dayjs(startDate).format("YYYY-MM-DD"),
        instanceEndDate: endDate !== null && dayjs(endDate).format("YYYY-MM-DD"),
      } as EditInstanceBody;
      dispatch(editInstanceApiCall(editInstanceBody));
    } else {
      let addInstanceBody = {
        healthcampId: healthcampid,
        displayName: instanceValue,
        instanceStartDate: startDate !== null && dayjs(startDate).format("YYYY-MM-DD"),
        instanceEndDate: endDate !== null && dayjs(endDate).format("YYYY-MM-DD"),
      } as AddInstanceBody;
      dispatch(addInstanceApiCall(addInstanceBody));
    }
  };

  const changeState = () => {
    setShowHistory(true);
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        
      >
        {addResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                {getInstanceListObject !== undefined ? "Edit" : "Add"} Instance
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={instanceValue}
                label="Instance Name"
                placeholder="Enter instance name"
                id="instanceID"
                onChange={(event) => setInstanceValue(event.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  label="Instance Start Date"
                  onChange={(date) => handleDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  label="Instance End Date"
                  onChange={(date) => handleEndDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              {getInstanceListObject !== undefined ? (
                <Button
                  onClick={() => changeState()}
                  variant="outlined"
                  color="primary"
                  style={{ fontSize: "12px" }}
                >
                  View History
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={() => addInstance()}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {showHistory === true ? (
          <InstanceHistoryDialog healthcampInstanceId={healthcampInstanceId} />
        ) : null}
      </Dialog>
    </div>
  );
}
