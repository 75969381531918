import { PageCount, GetPatientListOfCompetitionDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_LIST_OF_COMPETITION_ID_LOADING = "PATIENT_LIST_OF_COMPETITION_ID_LOADING";
export const PATIENT_LIST_OF_COMPETITION_ID_SUCCESS = "PATIENT_LIST_OF_COMPETITION_ID_SUCCESS";
export const PATIENT_LIST_OF_COMPETITION_ID_FAIL = "PATIENT_LIST_OF_COMPETITION_ID_FAIL";
export const PATIENT_LIST_OF_COMPETITION_ID_DATA = "PATIENT_LIST_OF_COMPETITION_ID_DATA";
export const PATIENT_LIST_OF_COMPETITION_API_MSG = "PATIENT_LIST_OF_COMPETITION_API_MSG";
export const PATIENT_LIST_OF_COMPETITION_API_RES = "PATIENT_LIST_OF_COMPETITION_API_RES";

export interface PatientListOfCompetitionDetailsState {
  PatientListOfCompetitionDetailsRes: GetPatientListOfCompetitionDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientListOfCompetitionDetailsLoading {
  type: typeof PATIENT_LIST_OF_COMPETITION_ID_LOADING;
  loading: boolean;
}
export interface PatientListOfCompetitionDetailsSuccess {
  type: typeof PATIENT_LIST_OF_COMPETITION_ID_SUCCESS;
  payload: GetPatientListOfCompetitionDetails;
}
export interface PatientListOfCompetitionDetailsFail {
  type: typeof PATIENT_LIST_OF_COMPETITION_ID_FAIL;
  payload: GetPatientListOfCompetitionDetails;
  message: string;
  status: number;
}
export interface PatientListOfCompetitionDetailsAPIMsg {
  type: typeof PATIENT_LIST_OF_COMPETITION_API_MSG;
  payload: GetPatientListOfCompetitionDetails;
  message: string;
  status: number;
}
export interface PatientListOfCompetitionDetailsRes {
  type: typeof PATIENT_LIST_OF_COMPETITION_API_RES;
  payload: GetPatientListOfCompetitionDetails;
  message: string;
  status: number;
}
interface PatientListOfCompetitionDetailsDataAction {
  type: typeof PATIENT_LIST_OF_COMPETITION_ID_DATA;
  payload: GetPatientListOfCompetitionDetails;
  pageCount: PageCount;
}

export type GetPatientListOfCompetitionActionTypes =
  | PatientListOfCompetitionDetailsDataAction
  | PatientListOfCompetitionDetailsLoading
  | PatientListOfCompetitionDetailsSuccess
  | PatientListOfCompetitionDetailsFail
  | PatientListOfCompetitionDetailsAPIMsg
  | PatientListOfCompetitionDetailsRes;
