import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ExecutiveSearchActionTypes } from "./ActionTypes";
import { ExecutiveSearchBody, ExecutiveSearchDetails } from "./Model";
import {
  executiveSearchAPIResClearAction,
  executiveSearchErrorAction,
  executiveSearchLoadingAction,
  executiveSearchSuccessAction,
  executiveSearchUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as ExecutiveSearchDetails;
export const executiveSearchApi = (searchKey: ExecutiveSearchBody, pageNo: any) => {
  return function (dispatch: Dispatch<ExecutiveSearchActionTypes>) {
    dispatch(executiveSearchLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/searchapp/api/searchExecutive/searchExecutiveList?page=${Number(pageNo)}&size=12`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(executiveSearchLoadingAction(false));
        dispatch(executiveSearchSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(executiveSearchLoadingAction(false));
        dispatch(
          executiveSearchErrorAction(
            {} as ExecutiveSearchDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateExecutiveSearchAPIResMsg = () => {
  return function (dispatch: Dispatch<ExecutiveSearchActionTypes>) {
    dispatch(executiveSearchUpdateAPIMsgAction(apiRes as ExecutiveSearchDetails, "", 0));
  };
};

export const clearExecutiveSearchAPIRes = () => {
  return function (dispatch: Dispatch<ExecutiveSearchActionTypes>) {
    dispatch(executiveSearchAPIResClearAction({} as ExecutiveSearchDetails, "", 0));
  };
};
