import {
  Card,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
  CardHeader,
  CardContent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import dayjs from "dayjs";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useHistory, useParams } from "react-router-dom";
import {
  AgeRange,
  NameGenerate,
  PhoneNumberGenerate,
  cityGenerate,
  pincodeGenerate,
} from "../../../component/Utility";

function Demographics() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const patientDemographicsDetails = useSelector((state: AppState) => state.gethealthRecordvalue);

  const handleDemographicToggle = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const { patientid } = useParams() as {
    patientid: string;
  };

  return (
    <Card style={{ borderRadius: 20 }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Demographics
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction={fullScreen ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {patientDemographicsDetails?.personalDetails?.profilePicture?.document ? (
              localStorage.getItem("togglename") === "true" ? (
                <img
                  src={localStorage.getItem("image" + patientid)?.toString()}
                  srcSet={localStorage.getItem("image" + patientid)?.toString()}
                  alt=""
                  loading="lazy"
                  style={{
                    maxWidth: "180px",
                    maxHeight: "180px",
                    flex: 1,
                  }}
                />
              ) : (
                <img
                  src={patientDemographicsDetails?.personalDetails?.profilePicture?.document}
                  srcSet={patientDemographicsDetails?.personalDetails?.profilePicture?.document}
                  alt=""
                  loading="lazy"
                  style={{
                    maxWidth: "180px",
                    maxHeight: "180px",
                    flex: 1,
                  }}
                />
              )
            ) : (
              <Avatar sx={{ width: 120, height: 120 }} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            <Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography>Name:</Typography>
                <Typography>Gender:</Typography>
                <Typography>Age:</Typography>
                <Typography>Phone:</Typography>
                <Typography>City:</Typography>
                <Typography>Pincode:</Typography>
              </Stack>

              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {NameGenerate.changeName(
                    patientid,
                    patientDemographicsDetails?.personalDetails?.firstname !== undefined &&
                      patientDemographicsDetails?.personalDetails?.lastname !== undefined
                      ? patientDemographicsDetails?.personalDetails?.firstname! +
                          " " +
                          patientDemographicsDetails?.personalDetails?.lastname!
                      : "",
                  )}
                </Typography>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails?.personalDetails?.gender?.label !== undefined
                    ? " " + patientDemographicsDetails.personalDetails?.gender?.label + " "
                    : null}
                </Typography>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails.personalDetails?.dob !== null ||
                  patientDemographicsDetails.personalDetails?.dob !== ""
                    ? localStorage.getItem("togglename") === "true"
                      ? AgeRange.ageRangeCheck(
                          dayjs(new Date()).diff(
                            patientDemographicsDetails.personalDetails?.dob,
                            "year",
                          ),
                        )
                      : dayjs(new Date()).diff(
                          patientDemographicsDetails.personalDetails?.dob,
                          "year",
                          // eslint-disable-next-line no-useless-concat
                        ) +
                        " years old " +
                        `(${dayjs(patientDemographicsDetails.personalDetails?.dob).format(
                          "DD/MM/YYYY",
                        )})`
                    : ""}
                </Typography>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails?.personalDetails?.phone !== ""
                    ? localStorage.getItem("togglename") === "true"
                      ? PhoneNumberGenerate.PhoneCheck(
                          patientDemographicsDetails?.personalDetails?.phone,
                        )
                      : patientDemographicsDetails?.personalDetails?.phone
                    : "Not Set"}
                </Typography>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails?.personalDetails?.city !== "" &&
                  patientDemographicsDetails?.personalDetails?.city !== null
                    ? localStorage.getItem("togglename") === "true"
                      ? cityGenerate.CityCheck(patientDemographicsDetails?.personalDetails?.city)
                      : patientDemographicsDetails?.personalDetails?.city
                    : "Not Set"}
                </Typography>
                <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                  {patientDemographicsDetails?.personalDetails?.pincode !== undefined ||
                  patientDemographicsDetails?.personalDetails?.pincode !== ""
                    ? localStorage.getItem("togglename") === "true"
                      ? pincodeGenerate.PincodeCheck(
                          patientDemographicsDetails?.personalDetails?.pincode,
                        )
                      : patientDemographicsDetails?.personalDetails?.pincode
                    : ""}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="text"
              endIcon={<ArrowRightAltIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => handleDemographicToggle()}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default Demographics;
