import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetDoctorListInHealthCampActionTypes } from "./ActionTypes";
import { GetDoctorListInHealthCampBody, GetDoctorListInHealthCampRes } from "./Model";
import {
  getDoctorListInHealthCampAPIResClearAction,
  getDoctorListInHealthCampErrorAction,
  getDoctorListInHealthCampLoadingAction,
  getDoctorListInHealthCampSuccessAction,
  getDoctorListInHealthCampUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDoctorListInHealthCampRes;
export const getDoctorListInHealthCamp = (payload: GetDoctorListInHealthCampBody) => {
  return function (dispatch: Dispatch<GetDoctorListInHealthCampActionTypes>) {
    dispatch(getDoctorListInHealthCampLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampDoctor/doctor_list_in_healthcamp?page=${payload.pageNo}&size=20`,
        { healthcampId: payload.healthcampId },
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorListInHealthCampLoadingAction(false));
        dispatch(
          getDoctorListInHealthCampSuccessAction(
            res.data !== undefined ? res.data : ({} as GetDoctorListInHealthCampRes),
            res.data.message !== undefined
              ? res.data.message
              : "Doctor list in health camp has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDoctorListInHealthCampLoadingAction(false));
        dispatch(
          getDoctorListInHealthCampErrorAction(
            {} as GetDoctorListInHealthCampRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetDoctorListInHealthCampAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorListInHealthCampActionTypes>) {
    dispatch(
      getDoctorListInHealthCampUpdateAPIMsgAction(
        apiRes as GetDoctorListInHealthCampRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetDoctorListInHealthCampAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorListInHealthCampActionTypes>) {
    dispatch(
      getDoctorListInHealthCampAPIResClearAction({} as GetDoctorListInHealthCampRes, "", "", 0),
    );
  };
};
