import {
  AddPatientDetailsListInCompetitionIdActionTypes,
  ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL,
  ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING,
  ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS,
  AddPatientDetailsListInCompetitionIdState,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES,
} from "./ActionTypes";
import { AddPatientDetailsListInCompetitionIdDetails } from "./Model";

const initialStateGetPosts: AddPatientDetailsListInCompetitionIdState = {
  loading: false,
  addPatientDetailsListInCompetitionIdRes: {} as AddPatientDetailsListInCompetitionIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addPatientDetailsInCompetitionIdReducer = (
  state = initialStateGetPosts,
  action: AddPatientDetailsListInCompetitionIdActionTypes,
): AddPatientDetailsListInCompetitionIdState => {
  switch (action.type) {
    case ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: {} as AddPatientDetailsListInCompetitionIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES:
      return {
        ...state,
        addPatientDetailsListInCompetitionIdRes: {} as AddPatientDetailsListInCompetitionIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
