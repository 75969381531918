import { AdminSetTaskStatusDetails } from "./Model";
export const ADMIN_SET_TASK_STATUS_LOADING = "ADMIN_SET_TASK_STATUS_LOADING";
export const ADMIN_SET_TASK_STATUS_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADMIN_SET_TASK_STATUS_FAIL = "ADMIN_SET_TASK_STATUS_FAIL";
export const ADMIN_SET_TASK_STATUS_API_MSG = "ADMIN_SET_TASK_STATUS_API_MSG";
export const ADMIN_SET_TASK_STATUS_API_RES = "ADMIN_SET_TASK_STATUS_API_RES";

export interface AdminSetTaskStatusState {
  adminSetTaskStatusRes: AdminSetTaskStatusDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AdminSetTaskStatusLoading {
  type: typeof ADMIN_SET_TASK_STATUS_LOADING;
  loading: boolean;
}
export interface AdminSetTaskStatusSuccess {
  type: typeof ADMIN_SET_TASK_STATUS_SUCCESS;
  payload: AdminSetTaskStatusDetails;
  successMsg: string;
}
export interface AdminSetTaskStatusFail {
  type: typeof ADMIN_SET_TASK_STATUS_FAIL;
  payload: AdminSetTaskStatusDetails;
  errorMsg: string;
  status: number;
}
export interface AdminSetTaskStatusAPIMsg {
  type: typeof ADMIN_SET_TASK_STATUS_API_MSG;
  payload: AdminSetTaskStatusDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AdminSetTaskStatusAPIRes {
  type: typeof ADMIN_SET_TASK_STATUS_API_RES;
  payload: AdminSetTaskStatusDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type AdminSetTaskStatusActionTypes =
  | AdminSetTaskStatusLoading
  | AdminSetTaskStatusSuccess
  | AdminSetTaskStatusFail
  | AdminSetTaskStatusAPIMsg
  | AdminSetTaskStatusAPIRes;
