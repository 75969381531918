import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, Popover, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import TranslateIcon from "@mui/icons-material/Translate";
interface Props {
    isMenu?: boolean;
}
const MWSelectLang: React.FC<Props> = ({
    isMenu = false
  }) => {
    const { t, i18n } = useTranslation(['home']);
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lng") || "en");
    const onClickLanguageChange = (e: any) => {
        const language = e.target.value;
        localStorage.setItem("lng", language);
        setSelectedLang(language);
        i18n.changeLanguage(language); //change the language
        window.location.reload();
    }
    return (
        <FormControl sx={{ m: 1, minHeight: 1 }} size="small">
            <Select value={selectedLang} onChange={onClickLanguageChange}>
                <MenuItem value="en" >English</MenuItem>
                <MenuItem value="ben" >Bengali</MenuItem>
            </Select>
            {!isMenu && <FormHelperText variant="standard" sx={{ fontSize: 12 }}>{t("common.selectLang", { ns: ['home'] })}</FormHelperText>}
        </FormControl>
    );
};

export default MWSelectLang;

export function MobileSelectLang() {
    const { i18n } = useTranslation(["home"]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [selectedIndex, setSelectedIndex] = React.useState(1);
  
    const handleListItemClick = (
      _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
    ) => {
      setSelectedIndex(index);
    };
  
    // const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lng") || "de");
    console.log("localStorage", localStorage.getItem("lng"));
    const onClickLanguageChange = (passedVal: string) => {
      localStorage.setItem("lng", passedVal);
      i18n.changeLanguage(passedVal); //change the language
      window.location.reload();
    };
    useEffect(() => {
      localStorage.getItem("lng") !== null
        ? localStorage.getItem("lng") === "en"
          ? setSelectedIndex(0)
          : localStorage.getItem("lng") === "ben"
          ? setSelectedIndex(1)
          : setSelectedIndex(0)
        : setSelectedIndex(0);
    }, []);
    return (
      <>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleClick}
          sx={{ display: { sm: "none" } }}
        >
          <TranslateIcon sx={{ color: "#757575" }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List dense>
            <ListItem disablePadding>
              <ListItemButton
                style={{
                  textDecoration: "none",
                }}
                selected={selectedIndex === 0}
                onClick={(event) => {
                  handleClose();
                  onClickLanguageChange("en");
                  handleListItemClick(event, 0);
                }}
              >
                <ListItemText>English</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                style={{
                  textDecoration: "none",
                }}
                selected={selectedIndex === 1}
                onClick={(event) => {
                  handleClose();
                  onClickLanguageChange("ben");
                  handleListItemClick(event, 1);
                }}
              >
                <ListItemText>Bengali</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }
