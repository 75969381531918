import * as React from "react";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import { getPatientTeamList } from "../../redux/PatientTeam/ApiCall";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { PatientList } from "../../redux/PatientTeam/Model";
import {
  AlertColor,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  useMediaQuery,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWPageTitle from "../../component/MWPageTitle";
import AddPatientTeamDialog from "./AddPatientTeamDialog";
import MWExceptionList from "../../component/MWExceptionList";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import MWSnackbar from "component/MWSnackbar";
import { clearAddPatientTeamByAdminAPIRes } from "redux/PatientTeam/addPatientTeamByAdmin/API";
import PageLayout from "pages/Layout/PageLayout";

export default function PatientTeam() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientTeamList(value));
  };

  // get Patient Team List api store value
  const patientTeamList = useSelector((state: AppState) => state.allPatientTeamListValue);
  const patientTeamListValue = patientTeamList?.allPatientTeamListValue?.patientList;
  const patientTeamPageCount = useSelector(
    (state: AppState) => state.allPatientTeamListValue.patientListPageCount,
  );
  const patientTeamCurrentPage = patientTeamList.patientTeamCurrentPageCount;

  function patientTeamDetails(patientTeamCurrentPage: any, patientDetailsID: any) {
    history.push(`/patientteamdetails/${patientTeamCurrentPage}/${patientDetailsID}`);
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            patientTeamDetails(patientTeamCurrentPage, params.value);
          }}
          variant="outlined"
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "User ID",
      minWidth: 110,
      align: "right",
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      editable: true,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Phone Number",
      editable: true,
      minWidth: 110,
      align: "right",
    },
  ];

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(patientTeamList?.loading));
    if (patientTeamListValue !== undefined) {
      let dataPointArr = patientTeamListValue.map((element: PatientList) => ({
        id: element.profileId,
        firstname: element.firstname,
        lastname: element.lastname,
        email: element.email,
        patientId: element.profileId,
        username: element.phone,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientTeamList]);

  useEffect(() => {
    dispatch(getPatientTeamList(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Reload Appointment API
  const reloadPatientList = () => {
    dispatch(getPatientTeamList(page));
  };

  const [showAddPatientTeamDialog, setShowAddPatientTeamDialog] = React.useState(false);
  const updatePatientTeamDialogState = () => {
    setShowAddPatientTeamDialog(true);
  };
  const updateAddPatientTeamDialogValFromChild = (passedVal: boolean) => {
    setShowAddPatientTeamDialog(passedVal);
  };

  const [addPatientTeamByAdminMsg, setAddPatientTeamByAdminMsg] = useState("");
  const [addPatientTeamByAdminMsgType, setAddPatientTeamByAdminMsgType] =
    useState<AlertColor>("success");

  const AddPatientTeamRes = useSelector((state: AppState) => state.addPatientTeamByAdminRes);
  useEffect(() => {
    if (AddPatientTeamRes?.successMsg !== "") {
      setAddPatientTeamByAdminMsgType("success");
      setAddPatientTeamByAdminMsg(AddPatientTeamRes?.successMsg);
      dispatch(getPatientTeamList(page));
    }
    if (AddPatientTeamRes?.errorMsg !== "") {
      setAddPatientTeamByAdminMsgType("error");
      setAddPatientTeamByAdminMsg(AddPatientTeamRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddPatientTeamRes]);
  const closeAddPatientTeamByAdmin = (passedVal: boolean) => {
    if (passedVal) {
      setAddPatientTeamByAdminMsg("");
      dispatch(clearAddPatientTeamByAdminAPIRes());
    }
  };

  const patientTeamTable = (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              {!fullScreen && <Typography>Page: {page}</Typography>}
              <Pagination
                color="primary"
                count={Number(patientTeamPageCount)}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          }
        />
        <CardContent>
          {patientTeamListValue && patientTeamListValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </div>
  );
  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <MWPageTitle
            title="Patient Team List"
            enableCount={true}
            count={patientTeamList.allPatientTeamListValue.count}
            reload={true}
            reloadAction={reloadPatientList}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={updatePatientTeamDialogState}
            sx={{ textTransform: "none" }}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      {patientTeamTable}
      {showAddPatientTeamDialog ? (
        <AddPatientTeamDialog
          addPatientTeamDialogEnable={showAddPatientTeamDialog}
          addPatientTeamDialogUpdateState={updateAddPatientTeamDialogValFromChild}
        />
      ) : null}
      {addPatientTeamByAdminMsg !== "" && (
        <MWSnackbar
          msg={addPatientTeamByAdminMsg}
          type={addPatientTeamByAdminMsgType}
          alertClose={closeAddPatientTeamByAdmin}
        />
      )}
    </PageLayout>
  );
}
