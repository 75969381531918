import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  Pagination,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getPatientListByHealthcamp } from "../../../../redux/patientListOfHealthcamp/API";
import { getHealthcampListValue } from "../../../../redux/healthcampList/API";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import {
  GetPatientListByHealthCampBody,
  GetPatientListByHealthCampMainBody,
  PatientList,
} from "../../../../redux/patientListOfHealthcamp/Model";
import dayjs from "dayjs";
import MWExceptionList from "../../../../component/MWExceptionList";
import {
  PatientSearchByHealthCampIdBody,
  SearchPatientList,
} from "../../../../redux/PatientSearchByHealcampId/Model";
import { patientSearchByHealthCampIdApi } from "../../../../redux/PatientSearchByHealcampId/API";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterHealthCampDialog from "./FilterHealthCampDialog";
import { getObservationTypeDetails } from "redux/GetObservationTypeList/API";
import { healthcampPatientSearchByObservationTypeApi } from "redux/HealthcampPatientSearchByObservationType/API";
import { HealthcampPatientSearchListByObservationTypeBody } from "redux/HealthcampPatientSearchByObservationType/Model";
import SortingComponent from "./SortingComponent";

type Props = {
  patientListByHealthCampLoader?: boolean | any;
  listCount?: string | any;
  obType: string | any;
  date: string | any;
};

export default function PatientListByHealthCamp({
  patientListByHealthCampLoader,
  listCount,
  obType,
  date,
}: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };

  const [ptId, setPtId] = useState("");
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      history.push(`/patientdetails/${ptId}`);
    }
  };
  const searchValueList = useSelector((state: AppState) => state.searchPatientByHealthCampIdValue);
  const healthcampPatientListByObservationType = useSelector(
    (state: AppState) => state.healthCampPatientListByObsTypeValue,
  );
  const healthCampObsTypeValue =
    healthcampPatientListByObservationType?.healthcampPatientSearchObservationTypeRes?.patientList;
  const patientSearchByHealthCampIdRes = searchValueList.PatientSearchByHealthcampIdRes.patientList;
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (obType !== null && date !== null) {
      dispatch(
        healthcampPatientSearchByObservationTypeApi(
          {
            patientObservationType: decodeURIComponent(obType),
            healthCampId: healthcampid,
            startDate: dayjs(decodeURIComponent(date)).format("YYYY-MM-DD"),
          } as HealthcampPatientSearchListByObservationTypeBody,
          value,
        ),
      );
    } else {
      const filterPatientListBody = {
        healthCampId: healthcampid,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: value,
        } as GetPatientListByHealthCampMainBody),
      );
    }
  };

  const [patientListErrorMsg, setPatientListErrorMsg] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  // list patient error toast
  const [errorAuthorisedPatientListToastActive, setErrorAuthorisedPatientListToastActive] =
    useState(false);
  const toggleErrorAuthorisedpatientListActive = () => {
    setErrorAuthorisedPatientListToastActive(
      (errorAuthorisedPatientListToastActive) => !errorAuthorisedPatientListToastActive,
    );
  };

  const toastErrorAuthorisedpatientListMarkup = errorAuthorisedPatientListToastActive ? (
    <Snackbar
      open={errorAuthorisedPatientListToastActive}
      autoHideDuration={6000}
      onClose={toggleErrorAuthorisedpatientListActive}
    >
      <Alert
        severity="error"
        onClose={toggleErrorAuthorisedpatientListActive}
        sx={{ minWidth: "100%" }}
      >
        {patientListErrorMsg}
      </Alert>
    </Snackbar>
  ) : null;

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const patientSearchByHealthcampBody = {
    searchKey: searchPatient,
    healthCampId: healthcampid,
  } as PatientSearchByHealthCampIdBody;

  useEffect(() => {
    const filterPatientListBody = {
      healthCampId: healthcampid,
    } as GetPatientListByHealthCampBody;
    dispatch(
      getPatientListByHealthcamp({
        patientList: filterPatientListBody,
        pageNo: 1,
      } as GetPatientListByHealthCampMainBody),
    );
    dispatch(getHealthcampListValue());
    dispatch(getObservationTypeDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages))
      : setPageListCount("0");
    if (searchValueList?.PatientSearchByHealthcampIdRes?.count !== undefined) {
      listCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.count));
    }
    if (patientSearchByHealthCampIdRes !== undefined) {
      let dataPointArr = patientSearchByHealthCampIdRes.map((element: SearchPatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        username: element.userName,
        phone: element.phone,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueList]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.healthcampPatientListValue);
  const allcaseValues = AdminPatientList.getPatientListByHealthcampListRes.patientList;
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [showFilterDialog, setShowFilterDialog] = React.useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowFilterDialog(passedVal);
  };
  useEffect(() => {
    AdminPatientList?.getPatientListByHealthcampListRes?.numberOfPages !== undefined
      ? setPageListCount(String(AdminPatientList?.getPatientListByHealthcampListRes?.numberOfPages))
      : setPageListCount("0");
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        username: element.userName,
        phone: element.phone,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMM DD, YYYY"),
        createDate: dayjs(element.createDate).format("MMM DD, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMM DD, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(AdminPatientList?.getPatientListByHealthcampListRes?.numberOfPages));
    }
    if (
      (AdminPatientList.status === -3 &&
        AdminPatientList.getPatientListByHealthcampListRes !== null) ||
      AdminPatientList.successMsg === ""
    ) {
      setPatientListErrorMsg(AdminPatientList.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  useEffect(() => {
    healthcampPatientListByObservationType?.healthcampPatientSearchObservationTypeRes
      ?.numberOfPages !== undefined
      ? setPageListCount(
          String(
            healthcampPatientListByObservationType?.healthcampPatientSearchObservationTypeRes
              ?.numberOfPages,
          ),
        )
      : setPageListCount("0");
    if (
      healthcampPatientListByObservationType?.healthcampPatientSearchObservationTypeRes?.count !==
      undefined
    ) {
      listCount(
        String(
          healthcampPatientListByObservationType?.healthcampPatientSearchObservationTypeRes?.count,
        ),
      );
    }

    console.log("healthcampPatientListByObservationType", healthcampPatientListByObservationType);
    if (healthCampObsTypeValue !== undefined) {
      let dataPointArr = healthCampObsTypeValue.map((element: SearchPatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        username: element.userName,
        phone: element.phone,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMM DD, YYYY"),
        createDate: dayjs(element.createDate).format("MMM DD, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMM DD, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(
        String(
          healthcampPatientListByObservationType?.healthcampPatientSearchObservationTypeRes
            ?.numberOfPages,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampPatientListByObservationType]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 75,
      align: "right",
    },
    {
      field: "firstname",
      headerName: "First name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone No",
      minWidth: 120,
      align: "left",
    },
    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      minWidth: 105,
      align: "left",
    },
    {
      field: "createDate",
      headerName: "Create date",
      type: "string",
      minWidth: 120,
      align: "left",
    },
    {
      field: "modifiedDate",
      headerName: "Modified date",
      type: "string",
      minWidth: 115,
      align: "left",
    },
  ];
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    obType !== null && date !== null ? setOpen(true) : setOpen(false);
  }, [obType, date]);
  const PatientListOfHealthcamp = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={4} lg="auto" xl="auto">
              <Tooltip
                open={open}
                title={
                  obType !== null && date !== null
                    ? `Observation Type= ${obType}, Date= ${dayjs(date).format("DD/MM/YYYY")}`
                    : ""
                }
                arrow
              >
                <Button
                  variant="outlined"
                  fullWidth={fullScreen}
                  startIcon={<FilterListIcon />}
                  onClick={() => {
                    setShowFilterDialog(true);
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Filter
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg="auto" xl="auto">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg="auto" xl="auto">
                  <Box>
                    <TextField
                      label="Search Patient in this camp"
                      size="small"
                      value={searchPatient}
                      fullWidth={fullScreen}
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          if (searchPatient !== "") {
                            dispatch(
                              patientSearchByHealthCampIdApi(patientSearchByHealthcampBody, 1),
                            );
                            //dispatch(clearSearchAPIRes());
                            setPage(1);
                            setIsFormInvalid(false);
                          } else {
                            setIsFormInvalid(true);
                          }
                          ev.preventDefault();
                        }
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchPatient(event.target.value);
                      }}
                    />
                  </Box>
                  {isFormInvalid && (
                    <FormHelperText error id="my-helper-text">
                      Please enter patient name
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg="auto" xl="auto">
                  <Box onKeyDown={keyDownEvent}>
                    <TextField
                      label="Go To Patient ID"
                      size="small"
                      fullWidth={fullScreen}
                      onChange={(event: any) => setPtId(event.target.value)}
                    />
                  </Box>
                </Grid>
                {!fullScreen && (
                  <Grid item>
                    <Typography>Page: {page}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 0}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {(allcaseValues && allcaseValues.length) ||
        (patientSearchByHealthCampIdRes && patientSearchByHealthCampIdRes.length) ||
        (healthCampObsTypeValue && healthCampObsTypeValue.length) ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: SortingComponent }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {AdminPatientList.loading || searchValueList.loading
        ? patientListByHealthCampLoader(true)
        : patientListByHealthCampLoader(false)}
      {PatientListOfHealthcamp}
      {toastErrorAuthorisedpatientListMarkup}
      {showFilterDialog ? (
        <FilterHealthCampDialog
          filterDialogEnable={showFilterDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          healthcampid={healthcampid}
          obType={obType}
          date={date}
        />
      ) : null}
    </Box>
  );
}
