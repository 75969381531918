import { PageCount, PatientCheckInBySessionIdFromAppointmnetListDetails } from "./Model";
export const PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_LOADING = "PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_LOADING";
export const PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_SUCCESS = "PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_SUCCESS";
export const PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_FAIL = "PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_FAIL";
export const PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_DATA = "PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_DATA";
export const PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_MSG = "PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_MSG";
export const PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_RES = "PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_RES";

export interface PatientCheckInBySessionIdFromAppointmnetListState {
  patientCheckInBySessionIdFromAppointmnetListRes: PatientCheckInBySessionIdFromAppointmnetListDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientCheckInBySessionIdFromAppointmnetListLoading {
  type: typeof PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_LOADING;
  loading: boolean;
}
export interface PatientCheckInBySessionIdFromAppointmnetListSuccess {
  type: typeof PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_SUCCESS;
  payload: PatientCheckInBySessionIdFromAppointmnetListDetails;
  successMsg: string;
}
export interface PatientCheckInBySessionIdFromAppointmnetListFail {
  type: typeof PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_FAIL;
  payload: PatientCheckInBySessionIdFromAppointmnetListDetails;
  errorMsg: string;
  status: number;
}
export interface PatientCheckInBySessionIdFromAppointmnetListAPIMsg {
  type: typeof PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_MSG;
  payload: PatientCheckInBySessionIdFromAppointmnetListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientCheckInBySessionIdFromAppointmnetListAPIRes {
  type: typeof PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_API_RES;
  payload: PatientCheckInBySessionIdFromAppointmnetListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface PatientCheckInBySessionIdFromAppointmnetListDataAction {
  type: typeof PATIENT_CHECK_IN_FROM_APPOINTMENT_LIST_DATA;
  payload: PatientCheckInBySessionIdFromAppointmnetListDetails;
  pageCount: PageCount;
}

export type PatientCheckInBySessionIdFromAppointmnetListActionTypes =
  | PatientCheckInBySessionIdFromAppointmnetListDataAction
  | PatientCheckInBySessionIdFromAppointmnetListLoading
  | PatientCheckInBySessionIdFromAppointmnetListSuccess
  | PatientCheckInBySessionIdFromAppointmnetListFail
  | PatientCheckInBySessionIdFromAppointmnetListAPIMsg
  | PatientCheckInBySessionIdFromAppointmnetListAPIRes;
