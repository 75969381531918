import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../../../../redux/store/Store";
import { Alert, Box, Grid } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../../../../component/MWExceptionList";
import { ModifiedReportList } from "./Model";
import { getReportByHealthCampSession } from "redux/HealthCampSessionReportList/API";
import { useParams } from "react-router-dom";
import { GetHealthCampSessionReportBody } from "redux/HealthCampSessionReportList/Model";
import _ from "lodash";

type Props = {
  reportingLoader: (value: boolean) => void;
};

export default function SessionReport({ reportingLoader }: Props) {
  const dispatch = useDispatch();
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  // call Store
  const getHealthCampSessionReportRes = useSelector(
    (state: AppState) => state.getHealthCampSessionReportRes,
  );
  useEffect(() => {
    dispatch(
      getReportByHealthCampSession({
        healthcampSessionId: sessionId,
      } as GetHealthCampSessionReportBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [sessionReportList, setSessionReportList] = useState([] as ModifiedReportList[]);
  const [notAdministratorMsg] = useState("");

  useEffect(() => {
    reportingLoader(getHealthCampSessionReportRes?.loading);
    if (getHealthCampSessionReportRes?.getHealthCampSessionReport !== undefined) {
      if (!_.isEmpty(getHealthCampSessionReportRes?.getHealthCampSessionReport)) {
        let reportList = [] as ModifiedReportList[];
        reportList.push(getHealthCampSessionReportRes?.getHealthCampSessionReport);
        reportList[0].id = reportList[0].status;
        setSessionReportList(reportList);
      } else {
        setSessionReportList([] as ModifiedReportList[]);
      }
    } else {
      setSessionReportList([] as ModifiedReportList[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHealthCampSessionReportRes]);

  const columns: GridColDef[] = [
    {
      field: "patientCount",
      headerName: "Patient Count",
      minWidth: 80,
      align: "left",
    },
    {
      field: "staffCount",
      headerName: "Staff Count",
      minWidth: 80,
      align: "center",
    },
    {
      field: "activeRegistartionRequesCount",
      headerName: "Active Registration request Count",
      minWidth: 230,
      align: "center",
    },
    {
      field: "inactiveRegistartionRequesCount",
      headerName: "Inactive Registration request Count",
      minWidth: 235,
      align: "center",
    },
    {
      field: "mandatoryPatientDetailsCount",
      headerName: "Mandatory Patient Details Count",
      minWidth: 220,
      align: "center",
    },
    {
      field: "activeVitalRequestCount",
      headerName: "Active Vital Request Count",
      minWidth: 190,
      align: "center",
    },
    {
      field: "inactiveVitalRequestCount",
      headerName: "Inactive Vital Request Count",
      minWidth: 190,
      align: "center",
    },
    {
      field: "completeVitalRequestCount",
      headerName: "Complete Vital Request Count",
      minWidth: 199,
      align: "center",
    },
  ];

  return (
    <Box>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {notAdministratorMsg !== "" ? (
            <Alert severity="error">{notAdministratorMsg}</Alert>
          ) : (
            <Box>
              {sessionReportList.length > 0 ? (
                <DataGrid
                  rows={sessionReportList}
                  columns={columns}
                  disableSelectionOnClick
                  hideFooter
                  hideFooterPagination
                  experimentalFeatures={{ newEditingApi: true }}
                  autoHeight
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  getRowHeight={() => "auto"}
                />
              ) : (
                <MWExceptionList />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
