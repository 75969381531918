import {
  AdminTaskStatusListActionTypes,
  ADMIN_TASK_STATUS_LIST_FAIL,
  ADMIN_TASK_STATUS_LIST_LOADING,
  ADMIN_TASK_STATUS_LIST_SUCCESS,
  AdminTaskStatusListState,
  ADMIN_TASK_STATUS_LIST_UPDATE_API_MSG,
  ADMIN_TASK_STATUS_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { AdminTaskStatusListRes } from "./Model";

const initialStateUpdatePosts: AdminTaskStatusListState = {
  loading: false,
  adminTaskStatusListRes: {} as AdminTaskStatusListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const adminTaskStatusListReducer = (
  state = initialStateUpdatePosts,
  action: AdminTaskStatusListActionTypes,
): AdminTaskStatusListState => {
  switch (action.type) {
    case ADMIN_TASK_STATUS_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADMIN_TASK_STATUS_LIST_SUCCESS:
      return {
        ...state,
        adminTaskStatusListRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADMIN_TASK_STATUS_LIST_FAIL:
      return {
        ...state,
        adminTaskStatusListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADMIN_TASK_STATUS_LIST_UPDATE_API_MSG:
      return {
        ...state,
        adminTaskStatusListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADMIN_TASK_STATUS_LIST_UPDATE_API_RES:
      return {
        ...state,
        adminTaskStatusListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
