import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  Divider,
} from "@mui/material";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { GetSessionListBody } from "redux/HealthcampSessionList/Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";
import SessionList from "./SessionList";
import { AppState } from "redux/store/Store";
import { HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import _ from "lodash";
import { GetInstanceListBody } from "redux/InstanceListOfHealthcamp /Model";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { HealthCampList } from "redux/healthcampList/Model";
import { getHealthcampListValue } from "redux/healthcampList/API";
import MWPageTitle from "component/MWPageTitle";
import AppointmentList from "../AppointmentList";
import Reporting from "../Reporting";
import { GetAppointmentListHealthcampBody } from "redux/AppointmentListOfHealthcampInstance/Model";
import { getAppointmentListOfHealthcampInstance } from "redux/AppointmentListOfHealthcampInstance/API";
import { clearAPIRes } from "redux/PatientSearchByHealcampId/API";
import { clearSearchAPIRes } from "redux/PatientSearchApi/API";
import PatientObservationListByInstanceId from "./PatientObservationListByInstanceId/Index";
import InstanceManagement from "../InstanceManagement";
import { getDataCurationProblemListApi } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function HealthCampSessionList() {
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  // call Store
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const healthcampInsttanceAppoinmentResponse = useSelector(
    (state: AppState) => state.getAppointmentListOfHealthcampInstance,
  );
  const [viewSessionListLoader, setViewSessionListLoader] = useState(false);
  const [appoinmentListLoader, setAppoinmentListLoader] = useState(false);
  const [reportingLoader, setReportingLoader] = useState(false);
  const [observationListLoader, setObservationListLoader] = useState(false);
  const [instanceManagementLoader, setInstanceManagementLoader] = useState(false);
  const [listCount, setListCount] = useState("");
  const [appoinmentCount, setAppoinmentCount] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selected, setSelected] = React.useState(1);
  const healthcampValues: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );
  const instanceValues: HealthcampInstanceList | undefined = _.find(
    instanceListResponseValue.getInstanceList?.healthcampInstanceList,
    ["id", Number(instanceHealthcampId)],
  );
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=session-list"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=appointment-list"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=reporting"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=observation-list"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=instance-management"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "session-list") {
      setTabValue(0);
    } else if (tabId === "appointment-list") {
      setTabValue(1);
    } else if (tabId === "reporting") {
      setTabValue(2);
    } else if (tabId === "observation-list") {
      setTabValue(3);
    } else if (tabId === "instance-management") {
      setTabValue(4);
    }
    // eslint-disable-next-line
  }, [tabId]);
  const handleClick = () => {
    history.push(`/patientregistration/${healthcampid}`);
    dispatch(clearAPIRes());
    dispatch(clearSearchAPIRes());
  };
  useEffect(() => {
    if (instanceValues === undefined) {
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceValues]);

  useEffect(() => {
    if (healthcampValues === undefined) {
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampValues]);

  useEffect(() => {
    sessionListResponseValue?.getSessionList?.healthcampSessionList !== undefined
      ? setListCount(String(sessionListResponseValue?.getSessionList?.healthcampSessionList.length))
      : setListCount("0");
  }, [sessionListResponseValue]);

  useEffect(() => {
    healthcampInsttanceAppoinmentResponse?.getAppoinmentListOfHealthcampResponse?.patientList !==
    undefined
      ? setAppoinmentCount(
          String(
            healthcampInsttanceAppoinmentResponse?.getAppoinmentListOfHealthcampResponse
              ?.patientList.length,
          ),
        )
      : setAppoinmentCount("0");
  }, [healthcampInsttanceAppoinmentResponse]);

  const sessionListApi = () => {
    const healthcampSessionListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetSessionListBody;
    dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
  };

  const instanceAppoinmentListApi = () => {
    const healthcampAppoinmentListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetAppointmentListHealthcampBody;
    dispatch(getAppointmentListOfHealthcampInstance(healthcampAppoinmentListBody, 1));
  };

  const [reportCount, setReportCount] = useState("0");
  const changeReportCount = (value: string) => {
    setReportCount(value);
  };

  const [obsListCount, setObsListCount] = useState("0");
  const changeObsListCount = (value: string) => {
    setObsListCount(value);
  };

  const [dataCurationListCount, setDataCurationListCount] = useState("0");
  const changeInstanceManagementCount = (value: string) => {
    setDataCurationListCount(value);
  };

  const StaffListOfHealthCamp = (
    <Card>
      <CardContent>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Session List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={listCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    sessionListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Appointment List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={appoinmentCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    instanceAppoinmentListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Reporting</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={reportCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    //
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Observation List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={obsListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    //
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(3)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Instance Management</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={dataCurationListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    let apiBody = {
                      healthcampInstanceId: instanceHealthcampId,
                    };
                    dispatch(getDataCurationProblemListApi(apiBody, 1));
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(4)}
            />
          </Tabs>
          <Divider />
          <TabPanel value={tabValue} index={0}>
            <SessionList sessionListLoader={setViewSessionListLoader} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <AppointmentList appoinmentListLoader={setAppoinmentListLoader} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Reporting reportingLoader={setReportingLoader} count={changeReportCount} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <PatientObservationListByInstanceId
              observationDetailsLoader={setObservationListLoader}
              count={changeObsListCount}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <InstanceManagement
              instanceManagementLoader={setInstanceManagementLoader}
              count={changeInstanceManagementCount}
            />
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        viewSessionListLoader ||
          appoinmentListLoader ||
          reportingLoader ||
          observationListLoader ||
          instanceManagementLoader,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewSessionListLoader,
    appoinmentListLoader,
    reportingLoader,
    observationListLoader,
    instanceManagementLoader,
  ]);
  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={2}
        >
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <MWPageTitle backButton={true} />
              </Grid>
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                  {healthcampValues?.displayName !== undefined ? (
                    <Link
                      color="inherit"
                      to={`/patientlistbyhealthcamp/${healthcampid}?section=instanceList`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography>
                        {healthcampValues?.displayName!.slice(0, 26) +
                          (healthcampValues?.displayName.length > 26
                            ? "... " + healthcampValues?.displayName!.slice(-10)
                            : "")}
                      </Typography>
                    </Link>
                  ) : null}

                  {instanceValues?.displayName !== undefined ? (
                    <Link
                      color="inherit"
                      to={`/healthcampinstance/${healthcampid}/${instanceHealthcampId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography color="text.primary">
                        {instanceValues?.displayName!.slice(0, 26) +
                          (instanceValues?.displayName.length > 26
                            ? "... " + instanceValues?.displayName!.slice(-10)
                            : "")}
                      </Typography>
                    </Link>
                  ) : null}
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button onClick={handleClick} variant="contained">
              Registration
            </Button>
          </Grid>
        </Grid>

        {StaffListOfHealthCamp}
      </Stack>
    </Container>
  );
}
