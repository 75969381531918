import {
  ADD_INSTANCE_FAIL,
  ADD_INSTANCE_LOADING,
  ADD_INSTANCE_SUCCESS,
  ADD_INSTANCE_API_MSG,
  ADD_INSTANCE_API_RES,
  AddInstanceActionTypes,
} from "./ActionTypes";
import { AddInstanceDetails } from "./Model";

export const addInstanceLoadingAction = (
  loading: boolean,
): AddInstanceActionTypes => {
  return {
    type: ADD_INSTANCE_LOADING,
    loading: loading,
  };
};

export const addInstanceSuccessAction = (
  addInstanceResponse: AddInstanceDetails,
  successMsg: string,
): AddInstanceActionTypes => {
  return {
    type: ADD_INSTANCE_SUCCESS,
    payload: addInstanceResponse,
    successMsg: successMsg,
  };
};

export const addInstanceErrorAction = (
  addInstanceResponse: AddInstanceDetails,
  errMsg: string,
  status: number,
): AddInstanceActionTypes => {
  return {
    type: ADD_INSTANCE_FAIL,
    payload: addInstanceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addInstanceAPIMsgAction = (
  addInstanceResponse: AddInstanceDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddInstanceActionTypes => {
  return {
    type: ADD_INSTANCE_API_MSG,
    payload: addInstanceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addInstanceAPIResClearAction = (
  addInstanceResponse: AddInstanceDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddInstanceActionTypes => {
  return {
    type: ADD_INSTANCE_API_RES,
    payload: addInstanceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
