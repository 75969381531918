import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { InactiveVitalCOllectionListActionTypes } from "./ActionTypes";
import { InactiveVitalCollectionListBody, InactiveVitalCollectionListRes } from "./Model";
import {
  inactiveVitalCollectionListAPIResClearAction,
  inactiveVitalCollectionListErrorAction,
  inactiveVitalCollectionListLoadingAction,
  inactiveVitalCollectionListSuccessAction,
  inactiveVitalCollectionListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as InactiveVitalCollectionListRes;
export const inactiveVitalCollectionList = (payload: InactiveVitalCollectionListBody) => {
  return function (dispatch: Dispatch<InactiveVitalCOllectionListActionTypes>) {
    dispatch(inactiveVitalCollectionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampSessionVitalsCollection/mark_all_vitalCollectionRequest_status_inactive_in_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(inactiveVitalCollectionListLoadingAction(false));
        dispatch(
          inactiveVitalCollectionListSuccessAction(
            res.data !== undefined ? res.data : ({} as InactiveVitalCollectionListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Inactive vital collection list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(inactiveVitalCollectionListLoadingAction(false));
        dispatch(
          inactiveVitalCollectionListErrorAction(
            {} as InactiveVitalCollectionListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const inactiveVitalCollectionListAPIResMsg = () => {
  return function (dispatch: Dispatch<InactiveVitalCOllectionListActionTypes>) {
    dispatch(
      inactiveVitalCollectionListUpdateAPIMsgAction(apiRes as InactiveVitalCollectionListRes, "", "", 0),
    );
  };
};

export const clearInactiveVitalCollectionListAPIRes = () => {
  return function (dispatch: Dispatch<InactiveVitalCOllectionListActionTypes>) {
    dispatch(inactiveVitalCollectionListAPIResClearAction({} as InactiveVitalCollectionListRes, "", "", 0));
  };
};
