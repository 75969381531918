import {
  AddStaffOfCompetitionActionTypes,
  ADD_STAFF_OF_COMPETITION_FAIL,
  ADD_STAFF_OF_COMPETITION_LOADING,
  ADD_STAFF_OF_COMPETITION_SUCCESS,
  AddStaffOfCompetitionState,
  ADD_STAFF_OF_COMPETITION_API_MSG,
  ADD_STAFF_OF_COMPETITION_API_RES,
} from "./ActionTypes";
import { AddStaffOfCompetitionDetails } from "./Model";

const initialStateGetPosts: AddStaffOfCompetitionState = {
  loading: false,
  AddStaffOfCompetitionRes: {} as AddStaffOfCompetitionDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addStaffOfCompetitionReducer = (
  state = initialStateGetPosts,
  action: AddStaffOfCompetitionActionTypes,
): AddStaffOfCompetitionState => {
  switch (action.type) {
    case ADD_STAFF_OF_COMPETITION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_STAFF_OF_COMPETITION_SUCCESS:
      return {
        ...state,
        AddStaffOfCompetitionRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_STAFF_OF_COMPETITION_FAIL:
      return {
        ...state,
        AddStaffOfCompetitionRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_STAFF_OF_COMPETITION_API_MSG:
      return {
        ...state,
        AddStaffOfCompetitionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_STAFF_OF_COMPETITION_API_RES:
      return {
        ...state,
        AddStaffOfCompetitionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
