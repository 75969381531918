import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MWExceptionList from "../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PatientDataBody } from "../../../redux/addExistingHealthcampPatient/Model";
import { addHealthcampExistingPatient } from "../../../redux/addExistingHealthcampPatient/API";
import dayjs from "dayjs";

type Props = {
  patientDetails?: any;
  medicalWisdomDetais?: any;
};

export default function GridView({ patientDetails, medicalWisdomDetais }: Props) {
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const history = useHistory();
  const [searchMedicalWisdomPatientList, setSearchMedicalWisdomPatientList] = useState([] as any[]);
  const [searchInHealthCampPatientList, setSearchInHealthCampPatientList] = useState([] as any[]);
  useEffect(() => {
    setSearchMedicalWisdomPatientList(medicalWisdomDetais);
    setSearchInHealthCampPatientList(patientDetails);
  }, [medicalWisdomDetais, patientDetails]);

  const addExistingPatientSubmit = (element: string) => {
    const addExistingPatientBody = {
      healthCampId: healthcampid!,
      patientId: element,
    } as PatientDataBody;
    dispatch(addHealthcampExistingPatient(addExistingPatientBody));
  };

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      {(searchMedicalWisdomPatientList !== undefined && searchMedicalWisdomPatientList.length) ||
      (searchInHealthCampPatientList !== undefined && searchInHealthCampPatientList.length) ? (
        <React.Fragment>
          {searchMedicalWisdomPatientList?.map((healthcampItem: any) => (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              {healthcampItem?.patientId && (
                <Card
                  raised
                  sx={{
                    margin: "0 auto",
                    padding: "0.1em",
                  }}
                >
                  <CardHeader
                    title={
                      <Typography variant="h6" fontWeight="bold">
                        {healthcampItem.givenName} {healthcampItem.familyName}
                      </Typography>
                    }
                    subheader={`${healthcampItem.userName}`}
                  />
                  <CardMedia
                    component="img"
                    image={
                      healthcampItem?.profilePicture !== null
                        ? healthcampItem?.profilePicture?.document
                        : "../medical.png"
                    }
                    alt="coverpicture"
                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                    height="250"
                  />
                  <CardContent>
                    <Typography variant="body2" fontWeight="bold">
                      Patient Id : {healthcampItem?.patientId}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      Phone No : {healthcampItem?.phone !== "" ? healthcampItem?.phone : "Not Set"}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      Pin Code :{" "}
                      {healthcampItem?.pincode !== "" ? healthcampItem?.pincode : "Not Set"}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      Dob :{" "}
                      {healthcampItem?.dob !== ""
                        ? dayjs(healthcampItem?.dob).format("DD/MM/YYYY")
                        : "Not Set"}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                      <Grid item>
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            addExistingPatientSubmit(healthcampItem?.patientId);
                          }}
                        >
                          Add Patient In Healthcamp
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              )}
            </Grid>
          ))}
          {searchInHealthCampPatientList?.map((healthcampItem: any) => (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              {healthcampItem?.patientId && (
                <Card
                  raised
                  sx={{
                    margin: "0 auto",
                    padding: "0.1em",
                  }}
                >
                  <CardHeader
                    title={
                      <Typography variant="h6" fontWeight="bold">
                        {healthcampItem.givenName} {healthcampItem.familyName}
                      </Typography>
                    }
                    subheader={`${healthcampItem.userName}`}
                  />
                  <CardMedia
                    component="img"
                    image={
                      healthcampItem?.profilePicture !== null
                        ? healthcampItem?.profilePicture?.document
                        : "../medical.png"
                    }
                    alt="coverpicture"
                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                    height="250"
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {healthcampItem?.phone !== "" ? healthcampItem?.phone : "Not Set"}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                      <Grid item>
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            gotoDetails(healthcampItem?.patientId);
                          }}
                        >
                          View Details
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              )}
            </Grid>
          ))}
        </React.Fragment>
      ) : (
        <Box p={3}>
          <MWExceptionList />
        </Box>
      )}
    </Grid>
  );
}
