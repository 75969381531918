import {
    ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL,
    ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING,
    ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS,
    ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG,
    ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES,
    GetOrganizationRegistrationReqDetailsListActionTypes,
  } from "./ActionTypes";
  import { GetOrganizationRequestDetailsListRes } from "./Model";
  
  export const getOrganizationRegistrationReqDetailsListLoadingAction = (
    loading: boolean,
  ): GetOrganizationRegistrationReqDetailsListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getOrganizationRegistrationReqDetailsListSuccessAction = (
    getOrganizationRegistrationReqDetailsListresponse: GetOrganizationRequestDetailsListRes,
    successMsg: string,
  ): GetOrganizationRegistrationReqDetailsListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_SUCCESS,
      payload: getOrganizationRegistrationReqDetailsListresponse,
      successMsg: successMsg,
    };
  };
  
  export const getOrganizationRegistrationReqDetailsListErrorAction = (
    getOrganizationRegistrationReqDetailsListresponse: GetOrganizationRequestDetailsListRes,
    errMsg: string,
    status: number,
  ): GetOrganizationRegistrationReqDetailsListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_FAIL,
      payload: getOrganizationRegistrationReqDetailsListresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getOrganizationRegistrationReqDetailsListUpdateAPIMsgAction = (
    getOrganizationRegistrationReqDetailsListresponse: GetOrganizationRequestDetailsListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetOrganizationRegistrationReqDetailsListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_MSG,
      payload: getOrganizationRegistrationReqDetailsListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getOrganizationRegistrationReqDetailsListAPIResClearAction = (
    getOrganizationRegistrationReqDetailsListresponse: GetOrganizationRequestDetailsListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetOrganizationRegistrationReqDetailsListActionTypes => {
    return {
      type: ORGANIZATION_REGISTRATION_REQ_DETAILS_LIST_UPDATE_API_RES,
      payload: getOrganizationRegistrationReqDetailsListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  