import { HealthcampPatientSearchListByObservationTypeDetails } from "./Model";

// Patient Demographics Loading State
export const HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING = "HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING";
export const HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS = "HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS";
export const HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL = "HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL";
export const HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_DATA = "HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_DATA";
export const HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_MSG = "HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_MSG";
export const HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_RES = "HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_RES";

export interface HealthcampPatientSearchListByObservationTypeDetailsState {
  healthcampPatientSearchObservationTypeRes: HealthcampPatientSearchListByObservationTypeDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchListByObservationTypeLoading {
  type: typeof HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface HealthcampPatientSearchListByObservationTypeSuccess {
  type: typeof HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS;
  payload: HealthcampPatientSearchListByObservationTypeDetails;
  successMsg: string;
}
export interface HealthcampPatientSearchListByObservationTypeFail {
  type: typeof HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL;
  payload: HealthcampPatientSearchListByObservationTypeDetails;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchListByObservationTypeAPIMsg {
  type: typeof HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_MSG;
  payload: HealthcampPatientSearchListByObservationTypeDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_RES;
  payload: HealthcampPatientSearchListByObservationTypeDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type HealthcampPatientSearchListByObservationTypeActionTypes =
  | HealthcampPatientSearchListByObservationTypeLoading
  | HealthcampPatientSearchListByObservationTypeSuccess
  | HealthcampPatientSearchListByObservationTypeFail
  | HealthcampPatientSearchListByObservationTypeAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
