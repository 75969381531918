import { PageCount, MovePatientToOrganizationDetails } from "./Model";

// Patient Demographics Loading State
export const MOVE_PATIENT_TO_ORGANIZATION_LOADING = "MOVE_PATIENT_TO_ORGANIZATION_LOADING";
export const MOVE_PATIENT_TO_ORGANIZATION_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const MOVE_PATIENT_TO_ORGANIZATION_FAIL = "MOVE_PATIENT_TO_ORGANIZATION_FAIL";
export const MOVE_PATIENT_TO_ORGANIZATION_DATA = "MOVE_PATIENT_TO_ORGANIZATION_DATA";
export const MOVE_PATIENT_TO_ORGANIZATION_API_MSG = "MOVE_PATIENT_TO_ORGANIZATION_API_MSG";
export const MOVE_PATIENT_TO_ORGANIZATION_API_RES = "MOVE_PATIENT_TO_ORGANIZATION_API_RES";

export interface MovePatientToOrganizationState {
  MovePatientToOrganizationRes: MovePatientToOrganizationDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface MovePatientToOrganizationLoading {
  type: typeof MOVE_PATIENT_TO_ORGANIZATION_LOADING;
  loading: boolean;
}
export interface MovePatientToOrganizationSuccess {
  type: typeof MOVE_PATIENT_TO_ORGANIZATION_SUCCESS;
  payload: MovePatientToOrganizationDetails;
  successMsg: string;
}
export interface MovePatientToOrganizationFail {
  type: typeof MOVE_PATIENT_TO_ORGANIZATION_FAIL;
  payload: MovePatientToOrganizationDetails;
  errorMsg: string;
  status: number;
}
export interface MovePatientToOrganizationAPIMsg {
  type: typeof MOVE_PATIENT_TO_ORGANIZATION_API_MSG;
  payload: MovePatientToOrganizationDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface MovePatientToOrganizationAPIRes {
  type: typeof MOVE_PATIENT_TO_ORGANIZATION_API_RES;
  payload: MovePatientToOrganizationDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface MovePatientToOrganizationDataAction {
  type: typeof MOVE_PATIENT_TO_ORGANIZATION_DATA;
  payload: MovePatientToOrganizationDetails;
  pageCount: PageCount;
}

export type MovePatientToOrganizationActionTypes =
  | MovePatientToOrganizationDataAction
  | MovePatientToOrganizationLoading
  | MovePatientToOrganizationSuccess
  | MovePatientToOrganizationFail
  | MovePatientToOrganizationAPIMsg
  | MovePatientToOrganizationAPIRes;
