import {
  AddDoctorByProviderOrgIdActionTypes,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS,
  AddDoctorByProviderOrgIdState,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES,
} from "./ActionTypes";
import { AddDoctorByProviderOrgIdDetails } from "./Model";

const initialStateGetPosts: AddDoctorByProviderOrgIdState = {
  loading: false,
  addDoctorByProviderOrgIdRes: {} as AddDoctorByProviderOrgIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDoctorByProviderOrgIdReducer = (
  state = initialStateGetPosts,
  action: AddDoctorByProviderOrgIdActionTypes,
): AddDoctorByProviderOrgIdState => {
  switch (action.type) {
    case ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS:
      return {
        ...state,
        addDoctorByProviderOrgIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL:
      return {
        ...state,
        addDoctorByProviderOrgIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG:
      return {
        ...state,
        addDoctorByProviderOrgIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES:
      return {
        ...state,
        addDoctorByProviderOrgIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
