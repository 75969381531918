import {
  CompetitionListByPatientIdActionTypes,
  COMPETITION_LIST_BY_PATIENT_ID_FAIL,
  COMPETITION_LIST_BY_PATIENT_ID_LOADING,
  COMPETITION_LIST_BY_PATIENT_ID_SUCCESS,
  CompetitionListByPatientIdDetailsState,
  COMPETITION_LIST_BY_PATIENT_ID_API_MSG,
  COMPETITION_LIST_BY_PATIENT_ID_API_RES,
} from "./ActionTypes";
import { CompetitionListByPatientIdDetails } from "./Model";

const initialStateGetPosts: CompetitionListByPatientIdDetailsState = {
  loading: false,
  competitionListByPatientIdRes: {} as CompetitionListByPatientIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const competitionListByPatientIdReducer = (
  state = initialStateGetPosts,
  action: CompetitionListByPatientIdActionTypes,
): CompetitionListByPatientIdDetailsState => {
  switch (action.type) {
    case COMPETITION_LIST_BY_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: true,
        competitionListByPatientIdRes: {} as CompetitionListByPatientIdDetails,
      };
    case COMPETITION_LIST_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        competitionListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case COMPETITION_LIST_BY_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        competitionListByPatientIdRes: {} as CompetitionListByPatientIdDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case COMPETITION_LIST_BY_PATIENT_ID_API_MSG:
      return {
        ...state,
        loading: false,
        competitionListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case COMPETITION_LIST_BY_PATIENT_ID_API_RES:
      return {
        ...state,
        loading: false,
        competitionListByPatientIdRes: {} as CompetitionListByPatientIdDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
