import {
  PATIENT_SEARCH_DATA,
  PATIENT_SEARCH_FAIL,
  PATIENT_SEARCH_LOADING,
  PATIENT_SEARCH_SUCCESS,
  PatientSearchActionTypes,
} from "./ActionTypes";
import { PageCount, PatientSearchDetails } from "./Model";

export const patientSearchAction = (
  PatientSearchDetails: PatientSearchDetails,
  pageCount: PageCount,
): PatientSearchActionTypes => {
  return {
    type: PATIENT_SEARCH_DATA,
    payload: PatientSearchDetails,
    pageCount: pageCount,
  };
};

export const patientSearchLoadingAction = (loading: boolean): PatientSearchActionTypes => {
  return {
    type: PATIENT_SEARCH_LOADING,
    loading: loading,
  };
};

export const patientSearchSuccessAction = (
  patientSearchResponse: PatientSearchDetails,
): PatientSearchActionTypes => {
  return {
    type: PATIENT_SEARCH_SUCCESS,
    payload: patientSearchResponse,
  };
};

export const patientSearchErrorAction = (
  patientSearchResponse: PatientSearchDetails,
  errMsg: string,
  status: number,
): PatientSearchActionTypes => {
  return {
    type: PATIENT_SEARCH_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const patientSearchUpdateAPIMsgAction = (
  patientSearchResponse: PatientSearchDetails,
  errMsg: string,
  status: number,
): PatientSearchActionTypes => {
  return {
    type: PATIENT_SEARCH_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const patientSearchAPIResClearAction = (
  patientSearchResponse: PatientSearchDetails,
  errMsg: string,
  status: number,
): PatientSearchActionTypes => {
  return {
    type: PATIENT_SEARCH_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
