import { useState, useCallback, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientTeam,
  getAppointmentPatientTeamList,
} from "../../redux/Patient/AppointmentPatientTeam/ApiCall";
import { AppState } from "../../redux/store/Store";
import MWExceptionList from "../../component/MWExceptionList";
import MWResourceList from "../../component/MWResourceList";

export default function AddPatientTeam(props: any) {
  const dispatch = useDispatch();

  const { appointmentPatientTeamLoader } = props;

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [patientteamemail, setPatientTeamEmail] = useState("");
  const handleOtherValueChange = useCallback(
    (event: any) => setPatientTeamEmail(event.target.value),
    [],
  );

  const [patientTeamOwnerName, setPatientTeamOwnerName] = useState("");
  const [patientTeamOwnerEmail, setPatientTeamOwnerEmail] = useState("");
  const [patientTeamOwnerPhone, setpatientTeamOwnerPhone] = useState("");
  const [patientCasePhone, setpatientCasePhone] = useState("");
  console.log(patientCasePhone);
  const closebanner = () => {
    setbannerbody("");
  };

  const [bannerbody, setbannerbody] = useState("");
  const errorbannermarkup = bannerbody ? (
    <Alert severity="error" onClick={closebanner}>
      <Typography>{bannerbody}</Typography>
    </Alert>
  ) : null;

  // call patientTeam List from store
  const PatientTeamListLoadingvalue = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.loading,
  );
  const patientTeamListFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.patientTeam,
  );
  const patientOwner = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails,
  );
  const patientcasePhoneFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.patientCasePhone,
  );
  const patientTeamEmailFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails.email,
  );
  const patientTeamNameFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails.name,
  );
  const patientTeamPhoneFromAPI = useSelector(
    (state: AppState) => state.appointmentPatientTeamValue.ownerDetails.phone,
  );

  let selectedPatientTeamList: any[] = patientTeamListFromAPI;

  // call Confirm Appointment Store
  const addPatientTeamLoadingValue = useSelector(
    (state: AppState) => state.addPatientTeamValue.loading,
  );
  const addPatientTeamMessage = useSelector((state: AppState) => state.addPatientTeamValue.message);
  const addPatientTeamStatus = useSelector((state: AppState) => state.addPatientTeamValue.status);

  // Confirm appointment success toast
  const [sucessAddPatientTeamToastActive, setSucessAddPatientTeamToastActive] = useState(false);
  const toggleSuccessAddPatientTeamToggleActive = () => {
    setSucessAddPatientTeamToastActive(
      (sucessAddPatientTeamToastActive) => !sucessAddPatientTeamToastActive,
    );
    dispatch(getAppointmentPatientTeamList(caseid));
  };

  const toastSucessAddpatientTeamMarkup = sucessAddPatientTeamToastActive ? (
    <Snackbar
      open={sucessAddPatientTeamToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessAddPatientTeamToggleActive}
    >
      <Alert
        severity="success"
        onClose={toggleSuccessAddPatientTeamToggleActive}
        sx={{ width: "100%" }}
      >
        {addPatientTeamMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Confirm appointment error toast
  const [errorAddPatientTeamToastActive, setErrorAddPatientTeamToastActive] = useState(false);
  const toggleErrorAddPatientTeamToggleActive = () => {
    setErrorAddPatientTeamToastActive(
      (errorAddPatientTeamToastActive) => !errorAddPatientTeamToastActive,
    );
    dispatch(getAppointmentPatientTeamList(caseid));
  };
  const toastErrorAddpatientTeamMarkup = errorAddPatientTeamToastActive ? (
    <Snackbar
      open={errorAddPatientTeamToastActive}
      autoHideDuration={6000}
      onClose={toggleErrorAddPatientTeamToggleActive}
    >
      <Alert
        severity="error"
        onClose={toggleErrorAddPatientTeamToggleActive}
        sx={{ width: "100%" }}
      >
        {addPatientTeamMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Get Patient Team List API
  const getSelectedPatientTeam = () => {
    dispatch(getAppointmentPatientTeamList(caseid));
  };
  // Add PatientTeam API
  const body = {
    caseId: caseid,
    email: patientteamemail,
  };

  // Add patient Team API
  const addPatientTeamInCase = () => {
    if (patientteamemail === "") {
      setbannerbody("Patient Team email can't be empty");
    } else {
      dispatch(addPatientTeam(body));
      setSucessAddPatientTeamToastActive(
        (sucessAddPatientTeamToastActive) => !sucessAddPatientTeamToastActive,
      );
      setErrorAddPatientTeamToastActive(
        (errorAddPatientTeamToastActive) => !errorAddPatientTeamToastActive,
      );
    }
  };

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getSelectedPatientTeam();
    Object.keys(patientOwner).length > 0
      ? setPatientTeamOwnerName(patientTeamNameFromAPI)
      : setPatientTeamOwnerName("");
    Object.keys(patientOwner).length > 0
      ? setPatientTeamOwnerEmail(patientTeamEmailFromAPI)
      : setPatientTeamOwnerEmail("");
    Object.keys(patientOwner).length > 0
      ? setpatientTeamOwnerPhone(patientTeamPhoneFromAPI)
      : setpatientTeamOwnerPhone("");
    Object.keys(patientcasePhoneFromAPI).length > 0
      ? setpatientCasePhone(patientcasePhoneFromAPI)
      : setpatientCasePhone("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, patientTeamNameFromAPI]);
  const symptomsAddInfo = (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Card>
        <CardHeader title="Patient Team Owner Details:" />
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Name:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerName}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Email:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerEmail}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Contact Number:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerPhone}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box>
            <CardHeader
              title={
                <Typography variant="subtitle1" fontWeight="bold">
                  Selected Patient Team List
                </Typography>
              }
            />
            <Box>
              {selectedPatientTeamList !== undefined ? (
                selectedPatientTeamList.length > 0 ? (
                  selectedPatientTeamList.map((element: any) => (
                    <MWResourceList
                      icon={false}
                      title={element?.email}
                      showSubtitle={false}
                      showNavigation={false}
                    />
                  ))
                ) : (
                  <MWExceptionList />
                )
              ) : (
                <MWExceptionList />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <Typography variant="subtitle2" fontWeight="bold">
                  Patient Case's Contact Number:
                </Typography>
                <Typography variant="subtitle2">Email of Patient Team Member</Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} sm={10} md={10} lg={10}>
                    <TextField
                      fullWidth
                      multiline
                      rows={1}
                      value={patientteamemail}
                      type="email"
                      label=""
                      placeholder="e.g. abc@xyz.com"
                      id="patientteamEmail"
                      onChange={handleOtherValueChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} style={{ marginTop: "5px" }}>
                    <Button variant="outlined" onClick={addPatientTeamInCase}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={2}>{errorbannermarkup}</Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
  return (
    <Typography>
      {PatientTeamListLoadingvalue === true || addPatientTeamLoadingValue === true
        ? appointmentPatientTeamLoader(true)
        : appointmentPatientTeamLoader(false)}
      {symptomsAddInfo}
      {addPatientTeamStatus! === 1 ? toastSucessAddpatientTeamMarkup : ""}
      {addPatientTeamStatus! === 0 ? toastErrorAddpatientTeamMarkup : ""}
    </Typography>
  );
}
