import {
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG,
  ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES,
  AddDoctorByProviderOrgIdActionTypes,
} from "./ActionTypes";
import { AddDoctorByProviderOrgIdDetails } from "./Model";

export const addDoctorByProviderOrgIdLoadingAction = (
  loading: boolean,
): AddDoctorByProviderOrgIdActionTypes => {
  return {
    type: ADD_DOCTOR_BY_PROVIDER_ORG_ID_LOADING,
    loading: loading,
  };
};

export const addDoctorByProviderOrgIdSuccessAction = (
  addDoctorByProviderOrgIdResponse: AddDoctorByProviderOrgIdDetails,
  successMsg: string,
): AddDoctorByProviderOrgIdActionTypes => {
  return {
    type: ADD_DOCTOR_BY_PROVIDER_ORG_ID_SUCCESS,
    payload: addDoctorByProviderOrgIdResponse,
    successMsg: successMsg,
  };
};

export const addDoctorByProviderOrgIdErrorAction = (
  addDoctorByProviderOrgIdResponse: AddDoctorByProviderOrgIdDetails,
  errMsg: string,
  status: number,
): AddDoctorByProviderOrgIdActionTypes => {
  return {
    type: ADD_DOCTOR_BY_PROVIDER_ORG_ID_FAIL,
    payload: addDoctorByProviderOrgIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDoctorByProviderOrgIdAPIMsgAction = (
  addDoctorByProviderOrgIdResponse: AddDoctorByProviderOrgIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDoctorByProviderOrgIdActionTypes => {
  return {
    type: ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_MSG,
    payload: addDoctorByProviderOrgIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDoctorByProviderOrgIdAPIResClearAction = (
  addDoctorByProviderOrgIdResponse: AddDoctorByProviderOrgIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDoctorByProviderOrgIdActionTypes => {
  return {
    type: ADD_DOCTOR_BY_PROVIDER_ORG_ID_API_RES,
    payload: addDoctorByProviderOrgIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
