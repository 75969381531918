import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  GetOrganizationRegistrationListActionTypes } from "./ActionTypes";
import { GetOrganizationRegistrationRequestListBody, OrganizationRegistrationRequestListRes } from "./Model";
import {
   getOrganizationRegistrationRequestListAPIResClearAction,
   getOrganizationRegistrationRequestListErrorAction,
   getOrganizationRegistrationRequestListLoadingAction,
   getOrganizationRegistrationRequestListSuccessAction,
   getOrganizationRegistrationRequestListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as OrganizationRegistrationRequestListRes;
export const  getOrganizationRegistrationRequestList = (payload: GetOrganizationRegistrationRequestListBody) => {
  return function (dispatch: Dispatch< GetOrganizationRegistrationListActionTypes>) {
    dispatch( getOrganizationRegistrationRequestListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientOrganizationRegistrationRequest/registration_request_list_patientOrganization`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( getOrganizationRegistrationRequestListLoadingAction(false));
        dispatch(
           getOrganizationRegistrationRequestListSuccessAction(
            res.data !== undefined ? res.data : ({} as OrganizationRegistrationRequestListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Organization Registration list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( getOrganizationRegistrationRequestListLoadingAction(false));
        dispatch(
           getOrganizationRegistrationRequestListErrorAction(
            {} as OrganizationRegistrationRequestListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateOrgatizationRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch< GetOrganizationRegistrationListActionTypes>) {
    dispatch(
       getOrganizationRegistrationRequestListUpdateAPIMsgAction(apiRes as OrganizationRegistrationRequestListRes, "", "", 0),
    );
  };
};

export const clearOrgatizationRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch< GetOrganizationRegistrationListActionTypes>) {
    dispatch( getOrganizationRegistrationRequestListAPIResClearAction({} as OrganizationRegistrationRequestListRes, "", "", 0));
  };
};
