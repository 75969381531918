import {
  AddPatientDataPointActionTypes,
  ADD_PATIENT_DATA_DETAILS_FAIL,
  ADD_PATIENT_DATA_DETAILS_LOADING,
  ADD_PATIENT_DATA_DETAILS_SUCCESS,
  AddPatientPointDataState,
  ADD_PATIENT_DATA_DETAILS_UPDATE_API_MSG,
  ADD_PATIENT_DATA_DETAILS_CLEAR_API_RES,
} from "./ActionTypes";
import { AddPatientPointDataDetails } from "./Model";

const initialStateGetPosts: AddPatientPointDataState = {
  loading: false,
  addPatientDataDetailsRes: {} as AddPatientPointDataDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addPointDataDetailsReducer = (
  state = initialStateGetPosts,
  action: AddPatientDataPointActionTypes,
): AddPatientPointDataState => {
  switch (action.type) {
    case ADD_PATIENT_DATA_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        addPatientDataDetailsRes: {} as AddPatientPointDataDetails,
      };
    case ADD_PATIENT_DATA_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        addPatientDataDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_DATA_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        addPatientDataDetailsRes: {} as AddPatientPointDataDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DATA_DETAILS_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        addPatientDataDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DATA_DETAILS_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        addPatientDataDetailsRes: {} as AddPatientPointDataDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
