import { checkReturnValue } from "../../../../../component/Utility";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DoctorList } from "redux/HealthcampStaffList/DoctorListInHealthCamp/Model";
import { AppState } from "redux/store/Store";
import { ModifiedDrListInHealthCamp } from "./Model";
// import { Result } from 'reduxStore/subcription/Active/Model'

export function AllDoctorListInHealthCamp() {
  const getDoctorListInHealthCampRes = useSelector(
    (state: AppState) => state.getDoctorListInHealthCampRes,
  );
  const [doctorList, setDoctorList] = useState([] as ModifiedDrListInHealthCamp[]);

  // Modify dataset for subscription table
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (
        checkReturnValue(
          getDoctorListInHealthCampRes?.getDoctorListInHealthCampList?.DoctorList,
        ) !== "Not Set"
      ) {
        let array = getDoctorListInHealthCampRes?.getDoctorListInHealthCampList?.DoctorList.map(
          (item: DoctorList) => {
            return {
              id: "id" in item ? item.id : 0,
              name: checkReturnValue(item.fullName),
              qualification: checkReturnValue(item.qualification),
              specialty: checkReturnValue(item.specialtyWithYearsOfExperience),
            };
          },
        ) as any;
        setDoctorList(array);
      } else setDoctorList([] as ModifiedDrListInHealthCamp[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorListInHealthCampRes]);
  return doctorList;
}
