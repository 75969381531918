import {
  GetStaffListByCompetitionActionTypes,
  STAFF_LIST_BY_COMPETITION_ID_FAIL,
  STAFF_LIST_BY_COMPETITION_ID_LOADING,
  STAFF_LIST_BY_COMPETITION_ID_SUCCESS,
  GetStaffListByCompetitionState,
  COMPETITION_PATIENT_LIST_API_MSG,
  COMPETITION_PATIENT_LIST_API_RES,
} from "./ActionTypes";
import { GetStaffListByCompetitionDetails } from "./Model";

const initialStateGetPosts: GetStaffListByCompetitionState = {
  loading: false,
  getStaffListByCompetitionRes: {} as GetStaffListByCompetitionDetails,
  message: "",
  status: 0,
};
export const getStaffListByCompetitionReducer = (
  state = initialStateGetPosts,
  action: GetStaffListByCompetitionActionTypes,
): GetStaffListByCompetitionState => {
  switch (action.type) {
    case STAFF_LIST_BY_COMPETITION_ID_LOADING:
      return {
        ...state,
        loading: true,
        getStaffListByCompetitionRes: {} as GetStaffListByCompetitionDetails,
      };
    case STAFF_LIST_BY_COMPETITION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getStaffListByCompetitionRes: action.payload,
      };
    case STAFF_LIST_BY_COMPETITION_ID_FAIL:
      return {
        ...state,
        loading: false,
        getStaffListByCompetitionRes: {} as GetStaffListByCompetitionDetails,
        message: action.message,
        status: action.status,
      };
    case COMPETITION_PATIENT_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        getStaffListByCompetitionRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case COMPETITION_PATIENT_LIST_API_RES:
      return {
        ...state,
        loading: false,
        getStaffListByCompetitionRes: {} as GetStaffListByCompetitionDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
