import {
  ADD_RANK_FOR_FINAL_ROUND_FAIL,
  ADD_RANK_FOR_FINAL_ROUND_LOADING,
  ADD_RANK_FOR_FINAL_ROUND_SUCCESS,
  ADD_RANK_FOR_FINAL_ROUND_API_MSG,
  ADD_RANK_FOR_FINAL_ROUND_API_RES,
  AddRankForFinalRoundDetailsActionTypes,
} from "./ActionTypes";
import { AddRankForFinalRoundDetailsRes } from "./Model";

export const addRankForFinalRoundDetailsLoadingAction = (
  loading: boolean,
): AddRankForFinalRoundDetailsActionTypes => {
  return {
    type: ADD_RANK_FOR_FINAL_ROUND_LOADING,
    loading: loading,
  };
};

export const addRankForFinalRoundDetailsSuccessAction = (
  addRankForFinalRoundDetailsResponse: AddRankForFinalRoundDetailsRes,
): AddRankForFinalRoundDetailsActionTypes => {
  return {
    type: ADD_RANK_FOR_FINAL_ROUND_SUCCESS,
    payload: addRankForFinalRoundDetailsResponse,
  };
};

export const addRankForFinalRoundDetailsErrorAction = (
  addRankForFinalRoundDetailsResponse: AddRankForFinalRoundDetailsRes,
  errMsg: string,
  status: number,
): AddRankForFinalRoundDetailsActionTypes => {
  return {
    type: ADD_RANK_FOR_FINAL_ROUND_FAIL,
    payload: addRankForFinalRoundDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addRankForFinalRoundDetailsAPIMsgAction = (
  addRankForFinalRoundDetailsResponse: AddRankForFinalRoundDetailsRes,
  errMsg: string,
  status: number,
): AddRankForFinalRoundDetailsActionTypes => {
  return {
    type: ADD_RANK_FOR_FINAL_ROUND_API_MSG,
    payload: addRankForFinalRoundDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const addRankForFinalRoundDetailsAPIResClearAction = (
  addRankForFinalRoundDetailsResponse: AddRankForFinalRoundDetailsRes,
  errMsg: string,
  status: number,
): AddRankForFinalRoundDetailsActionTypes => {
  return {
    type: ADD_RANK_FOR_FINAL_ROUND_API_RES,
    payload: addRankForFinalRoundDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
