import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetProviderOriganizationListActionTypes } from "./ActionTypes";
import { GetProviderOrganizationListRes } from "./Model";
import {
  getProviderOriganizationListAPIResClearAction,
  getProviderOriganizationListErrorAction,
  getProviderOriganizationListLoadingAction,
  getProviderOriganizationListSuccessAction,
  getProviderOriganizationListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetProviderOrganizationListRes;
export const getProviderOrganizationListValue = (pageNo: any) => {
  return function (dispatch: Dispatch<GetProviderOriganizationListActionTypes>) {
    dispatch(getProviderOriganizationListLoadingAction(true));
    axios
      .get(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationStaff/providerOrganizationListAsStaff?page=${Number(
            pageNo,
          )}&size=10`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getProviderOriganizationListLoadingAction(false));
        dispatch(getProviderOriganizationListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getProviderOriganizationListLoadingAction(false));
        dispatch(
          getProviderOriganizationListErrorAction(
            {} as GetProviderOrganizationListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getProviderOrganizationListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderOriganizationListActionTypes>) {
    dispatch(
      getProviderOriganizationListAPIMsgAction(apiRes as GetProviderOrganizationListRes, "", 0),
    );
  };
};

export const clearProviderOrganizationListAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderOriganizationListActionTypes>) {
    dispatch(
      getProviderOriganizationListAPIResClearAction({} as GetProviderOrganizationListRes, "", 0),
    );
  };
};
