import {
  GET_PROVIDER_ORGANIZATION_LIST_FAIL,
  GET_PROVIDER_ORGANIZATION_LIST_LOADING,
  GET_PROVIDER_ORGANIZATION_LIST_SUCCESS,
  GET_PROVIDER_ORGANIZATION_LIST_API_MSG,
  GET_PROVIDER_ORGANIZATION_LIST_API_RES,
  GetProviderOriganizationListActionTypes,
  GET_PROVIDER_ORGANIZATION_DATA,
} from "./ActionTypes";
import { GetProviderOrganizationListRes, PageCount } from "./Model";

export const providerOrganizationIdAction = (
  GetProviderOrganizationListRes: GetProviderOrganizationListRes,
  pageCount: PageCount,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_DATA,
    payload: GetProviderOrganizationListRes,
    pageCount: pageCount,
  };
};

export const getProviderOriganizationListLoadingAction = (
  loading: boolean,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_LIST_LOADING,
    loading: loading,
  };
};

export const getProviderOriganizationListSuccessAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationListRes,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_LIST_SUCCESS,
    payload: getProviderOrganizationListResponse,
  };
};

export const getProviderOriganizationListErrorAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationListRes,
  errMsg: string,
  status: number,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_LIST_FAIL,
    payload: getProviderOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getProviderOriganizationListAPIMsgAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationListRes,
  errMsg: string,
  status: number,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_LIST_API_MSG,
    payload: getProviderOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getProviderOriganizationListAPIResClearAction = (
  getProviderOrganizationListResponse: GetProviderOrganizationListRes,
  errMsg: string,
  status: number,
): GetProviderOriganizationListActionTypes => {
  return {
    type: GET_PROVIDER_ORGANIZATION_LIST_API_RES,
    payload: getProviderOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
