import {
  ORGANIZATION_LIST_BY_PATIENT_ID_FAIL,
  ORGANIZATION_LIST_BY_PATIENT_ID_LOADING,
  ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS,
  ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG,
  ORGANIZATION_LIST_BY_PATIENT_ID_API_RES,
  OrganizationListByPatientIdActionTypes,
} from "./ActionTypes";
import { OrganizationListByPatientIdDetails } from "./Model";

export const OrganizationListByPatientIdLoadingAction = (
  loading: boolean,
): OrganizationListByPatientIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const OrganizationListByPatientIdSuccessAction = (
  organizationListByPatientIdResponse: OrganizationListByPatientIdDetails,
  successMsg: string,
): OrganizationListByPatientIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS,
    payload: organizationListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const OrganizationListByPatientIdErrorAction = (
  organizationListByPatientIdResponse: OrganizationListByPatientIdDetails,
  errMsg: string,
  status: number,
): OrganizationListByPatientIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_PATIENT_ID_FAIL,
    payload: organizationListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const OrganizationListByPatientIdAPIMsgAction = (
  organizationListByPatientIdResponse: OrganizationListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): OrganizationListByPatientIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG,
    payload: organizationListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const OrganizationListByPatientIdAPIResClearAction = (
  organizationListByPatientIdResponse: OrganizationListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): OrganizationListByPatientIdActionTypes => {
  return {
    type: ORGANIZATION_LIST_BY_PATIENT_ID_API_RES,
    payload: organizationListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
