import Diagnosis from "../../../image/design-assets/active-diagnosis.svg";
import BloodSugar from "../../../image/design-assets/blood-sugar.svg";
import Bmi from "../../../image/design-assets/bmi.svg";
import Bp from "../../../image/design-assets/bp.svg";
import Ecg from "../../../image/design-assets/ecg.svg";
import HeartRate from "../../../image/design-assets/heart-rate.svg";
import Temp from "../../../image/design-assets/temp.svg";
import Weight from "../../../image/design-assets/weight.svg";
import SPO2 from "../../../image/design-assets/spo2.svg";
import SocialLife from "../../../image/design-assets/social-life.svg";
import FoodHabit from "../../../image/design-assets/food-habit.svg";
import PhysicalActivity from "../../../image/design-assets/physical-activity.svg";
import LivingCondition from "../../../image/design-assets/living-condition.svg";
import Height from "../../../image/design-assets/height.svg";
import BloodGroup from "../../../image/design-assets/blood-group.svg";
import BodyFat from "../../../image/design-assets/body-fat.svg";
import BodyMass from "../../../image/design-assets/body-mass.svg";
import { PatientVitalDetails } from "./Model";

export const CustomCardValueList = [
  {
    id: 1,
    name: "BP",
    content: "122 / 69",
    button: "View All",
    path: "armbp",
    info: "1.General, analytical and material accounting     2.Budget Management",
    image: Bp,
  },
  {
    id: 2,
    name: "BMI",
    content: "30",
    button: "View All",
    path: "bmi",
    info: "1.Supply Orders     2.Inventory Management       3.Stock Management       4.Distribution of blood & blood components",
    image: Bmi,
  },
  {
    id: 3,
    name: "Blood Sugar",
    content: "118",
    button: "View All",
    path: "bloodsugar",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: BloodSugar,
  },
  {
    id: 4,
    name: "ECG",
    content: "Normal Sinus Rhythm",
    button: "View All",
    path: "ecg",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Ecg,
  },
  {
    id: 5,
    name: "Heart Rate",
    content: "66",
    button: "View All",
    path: "bpm",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: HeartRate,
  },
  {
    id: 6,
    name: "Temperature",
    content: "98.5",
    button: "View All",
    path: "temp",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Temp,
  },
  {
    id: 7,
    name: "Height",
    content: "166 cm",
    button: "View All",
    path: "height",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Height,
  },
  {
    id: 8,
    name: "Weight",
    content: "60.3 Kg",
    button: "View All",
    path: "weight",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Weight,
  },
  {
    id: 9,
    name: "SPO2",
    content: "75",
    button: "View All",
    path: "spo2",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: SPO2,
  },
  {
    id: 10,
    name: "Social Life",
    content: "Active",
    button: "View All",
    path: "social",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: SocialLife,
  },
  {
    id: 11,
    name: "Active Diagnosis",
    content: "Hypertension",
    button: "View All",
    path: "activediagnosis",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Diagnosis,
  },
  {
    id: 12,
    name: "Food Habit",
    content: "Non Vegetarian",
    button: "View All",
    path: "food",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: FoodHabit,
  },
  {
    id: 13,
    name: "Physical Activity",
    content: "Active",
    button: "View All",
    path: "gym",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: PhysicalActivity,
  },
  {
    id: 14,
    name: "Living Condition",
    content: "Healthy",
    button: "View All",
    path: "livingcondition",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: LivingCondition,
  },
  {
    id: 15,
    name: "Body Fat",
    content: "Normal",
    button: "View All",
    path: "bodyfat",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: BodyFat,
  },
  {
    id: 16,
    name: "Blood Group",
    content: "NA",
    button: "View All",
    path: "bloodgroup",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: BloodGroup,
  },
  {
    id: 17,
    name: "Lean Body Mass",
    content: "Normal",
    button: "View All",
    path: "leanbodymass",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: BodyMass,
  },
] as PatientVitalDetails[];
export default CustomCardValueList;
