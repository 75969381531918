import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetExtractObservationDetailsActionTypes } from "./ActionTypes";
import { GetExtractObservationDetailsRes, ViewExtractObservationBody } from "./Model";
import {
  getExtractObservationDetailsAPIResClearAction,
  getExtractObservationDetailsErrorAction,
  getExtractObservationDetailsLoadingAction,
  getExtractObservationDetailsSuccessAction,
  getExtractObservationDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetExtractObservationDetailsRes;
export const getExtractObservationByDetailsId = (patientDetailsId: ViewExtractObservationBody) => {
  return function (dispatch: Dispatch<GetExtractObservationDetailsActionTypes>) {
    dispatch(getExtractObservationDetailsLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientObservation/getListOfObservationsExtractedFromPatientDetails`,
        patientDetailsId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getExtractObservationDetailsLoadingAction(false));
        dispatch(getExtractObservationDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getExtractObservationDetailsLoadingAction(false));
        dispatch(
          getExtractObservationDetailsErrorAction(
            {} as GetExtractObservationDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getExtractObservationDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetExtractObservationDetailsActionTypes>) {
    dispatch(
      getExtractObservationDetailsAPIMsgAction(apiRes as GetExtractObservationDetailsRes, "", 0),
    );
  };
};

export const clearViewExtractObservationDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetExtractObservationDetailsActionTypes>) {
    dispatch(
      getExtractObservationDetailsAPIResClearAction({} as GetExtractObservationDetailsRes, "", 0),
    );
  };
};
