import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ViewHistorySessionActionTypes } from "./ActionTypes";
import { ViewHistorySessionBody, ViewHistorySessionRes } from "./Model";
import {
  viewHistorySessionAPIResClearAction,
  viewHistorySessionErrorAction,
  viewHistorySessionLoadingAction,
  viewHistorySessionSuccessAction,
  viewHistorySessionUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as ViewHistorySessionRes;
export const viewHistoryBySessionId = (payload: ViewHistorySessionBody, pageNo: any) => {
  return function (dispatch: Dispatch<ViewHistorySessionActionTypes>) {
    dispatch(viewHistorySessionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsession/getsessionhealthcampHistory?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(viewHistorySessionLoadingAction(false));
        dispatch(
          viewHistorySessionSuccessAction(
            res.data !== undefined ? res.data : ({} as ViewHistorySessionRes),
            res.data.message !== undefined
              ? res.data.message
              : "View History fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(viewHistorySessionLoadingAction(false));
        dispatch(
          viewHistorySessionErrorAction(
            {} as ViewHistorySessionRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateViewHistoryOfSessionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<ViewHistorySessionActionTypes>) {
    dispatch(viewHistorySessionUpdateAPIMsgAction(apiRes as ViewHistorySessionRes, "", "", 0));
  };
};

export const clearViewHistoryOfSessionIdAPIRes = () => {
  return function (dispatch: Dispatch<ViewHistorySessionActionTypes>) {
    dispatch(viewHistorySessionAPIResClearAction({} as ViewHistorySessionRes, "", "", 0));
  };
};
