import axios from "axios";
import Url from "../../ApiConfig";
import { Dispatch } from "react";
import {
  AddDrOnboardingRequestTypes,
  ADD_DR_ONBOARDING_REQUEST_FAIL,
  ADD_DR_ONBOARDING_REQUEST_LOADING,
  ADD_DR_ONBOARDING_REQUEST_SUCCESS,
  DrApproveOrRejectTypes,
  DR_APPROVE_REJECT_REQUEST_FAIL,
  DR_APPROVE_REJECT_REQUEST_LOADING,
  DR_APPROVE_REJECT_REQUEST_SUCCESS,
} from "./ActionTypes";
import { addDrOnboardRequestActions, drApproveOrRejectActions } from "./Action";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

// Add Dr API
export const addDrOnBoarding = (body: object) => {
  return function (dispatch: Dispatch<AddDrOnboardingRequestTypes>) {
    dispatch({
      type: ADD_DR_ONBOARDING_REQUEST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/adminActions/add_doctor_onboarding_request`,
        body,
        { headers: headers }
      )
      .then((res) => {
        let message = res.data.message;
        let status = res.data.status;
        dispatch(addDrOnboardRequestActions(message, status));
        dispatch({
          type: ADD_DR_ONBOARDING_REQUEST_SUCCESS,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: ADD_DR_ONBOARDING_REQUEST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};

// Active Corporate List API
export const drApproveOrReject = (doctorId: any, approveOrreject: any) => {
  return function (dispatch: Dispatch<DrApproveOrRejectTypes>) {
    dispatch({
      type: DR_APPROVE_REJECT_REQUEST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/adminActions/add_doctor_onboarding_request`,
        { isApproved: approveOrreject, onboardReqId: doctorId },
        { headers: headers }
      )
      .then((res) => {
        let message = res.data.message;
        let status = res.data.status;
        dispatch(drApproveOrRejectActions(message, status));
        dispatch({
          type: DR_APPROVE_REJECT_REQUEST_SUCCESS,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: DR_APPROVE_REJECT_REQUEST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
