import React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchDuplicatePatient from "../SearchDuplicatePatient";

type Props = {
  addDuplicateDialogEnable?: boolean | any;
  addDuplicateDialogUpdateState?: boolean | any;
  duplicateValue?: any;
};

export default function AddDuplicatePatientDialog({
  addDuplicateDialogEnable,
  addDuplicateDialogUpdateState,
  duplicateValue,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addDuplicateDialogUpdateState(false);
  };

  React.useEffect(() => {
    setDialogState(addDuplicateDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDuplicateDialogEnable]);

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Search Duplicate Patient</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <SearchDuplicatePatient
            duplicateValue={duplicateValue}
            dialogClose={setDialogState}
            dialogUpdate={addDuplicateDialogUpdateState}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
