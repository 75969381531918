import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { UpdateDoctorDetailsBody } from "../../../redux/updateDoctorContactDetails/Model";
import {
  Alert,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Box,
  Stack,
  AlertColor,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DoctorContactDetailsBody } from "../../../redux/Doctor/GetDoctorContactDetails/Model";
import { getDoctorContactDetails } from "../../../redux/Doctor/GetDoctorContactDetails/API";
import MWSnackbar from "../../../component/MWSnackbar";
import {
  clearDoctorContactDetailsUpdateAPIRes,
  updateDoctorContactDetails,
} from "../../../redux/Doctor/updateDoctorContactDetails/API";
import AdminLoader from "../../AdminLoader";

type Props = {
  contactDetailsLoader: boolean | any;
  updateDoctorContactDetailsDialog?: boolean | any;
  updateDoctorContactDetailsDialogUpdatedState: boolean | any;
};
export default function ContactDetails({
  contactDetailsLoader,
  updateDoctorContactDetailsDialog,
  updateDoctorContactDetailsDialogUpdatedState,
}: Props) {
  const dispatch = useDispatch();

  const { id } = useParams() as {
    id: string;
  };

  const [updateDrPhone, setUpdateDrPhone] = useState("");
  const [updateDrPhoneError, setUpdateDrPhoneError] = useState("");
  const [updateDrEmail, setUpdateDrEmail] = useState("");
  const [updateDrEmailError, setUpdateDrEmailError] = useState("");
  const [updateDrPrescriptionPhone, setUpdateDrPrescriptionPhone] = useState("");
  const [updateDrPrescriptionPhoneError, setUpdateDrPrescriptionPhoneError] = useState("");
  const [updateDrPrescriptionAddress, setUpdateDrPrescriptionAddress] = useState("");
  const [updateDrPrescriptionAddressError, setUpdateDrPrescriptionAddressError] = useState("");

  const [updateDrDetailsDialog, setUpdateDrDetailsDialog] = useState(false);
  const closeDrContactDetailsDialog = () => {
    setUpdateDrDetailsDialog((updateDrDetailsDialog) => !updateDrDetailsDialog);
    updateDoctorContactDetailsDialogUpdatedState(false);
  };
  // get dialog state from parent & pass updated dialog state to parent
  useEffect(() => {
    setUpdateDrDetailsDialog(updateDoctorContactDetailsDialog);
  }, [updateDoctorContactDetailsDialog, updateDoctorContactDetailsDialogUpdatedState]);
  const [updateDrDetailsAPIMsg, setUpdateDrDetailsAPIMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearDoctorContactDetailsUpdateAPIRes());
    }
  };

  const closeContactDetailsError = () => {
    dispatch(clearDoctorContactDetailsUpdateAPIRes());
  };

  const [updateContactDetailsError, setUpdateContactDetailsError] = useState("");
  const updateContactDetailsErrorDiv =
    updateContactDetailsError !== "" ? (
      <Box py={2}>
        <Alert onClose={closeContactDetailsError} severity="error">
          <Typography>{updateContactDetailsError}</Typography>
        </Alert>
      </Box>
    ) : null;

  const updateDrContactDetailsBody = {
    doctorId: Number(id),
    phoneContact: updateDrPhone,
    emailContact: updateDrEmail,
    prescriptionPhone: updateDrPrescriptionPhone,
    prescriptionAdress: updateDrPrescriptionAddress,
  } as UpdateDoctorDetailsBody;

  // call update dr. contact details API
  const updateDrContactDetails = () => {
    if (
      updateDrPhone === "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone === "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("Dr. phone number is empty");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail === "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("Dr. email is empty");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone === "" &&
      updateDrPrescriptionAddress !== ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("Dr. prescription is empty");
      setUpdateDrPrescriptionAddressError("");
    } else if (
      updateDrPhone !== "" &&
      updateDrEmail !== "" &&
      updateDrPrescriptionPhone !== "" &&
      updateDrPrescriptionAddress === ""
    ) {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("Dr. prescription address is empty");
    } else {
      setUpdateDrPhoneError("");
      setUpdateDrEmailError("");
      setUpdateDrPrescriptionPhoneError("");
      setUpdateDrPrescriptionAddressError("");
      dispatch(updateDoctorContactDetails(updateDrContactDetailsBody));
    }
  };

  const getDoctorContactDetailsBody = {
    doctorId: Number(id),
  } as DoctorContactDetailsBody;
  // Initiate api call on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDoctorContactDetails(getDoctorContactDetailsBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ContactDetails from Store
  const getDrContactDetailsResVal = useSelector(
    (state: AppState) => state.getDoctorContactDetailsRes,
  );
  useEffect(() => {
    contactDetailsLoader(getDrContactDetailsResVal?.loading);
    setUpdateDrPhone(
      getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails?.phoneContact !==
        undefined
        ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
            ?.phoneContact !== null
          ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
              ?.phoneContact
          : ""
        : "",
    );

    setUpdateDrEmail(
      getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails?.emailContact !==
        undefined
        ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
            ?.emailContact !== null
          ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
              ?.emailContact
          : ""
        : "",
    );
    setUpdateDrPrescriptionPhone(
      getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
        ?.doctorPrescriptionPhoneNumber !== undefined
        ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
            ?.doctorPrescriptionPhoneNumber !== null
          ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
              ?.doctorPrescriptionPhoneNumber
          : ""
        : "",
    );
    setUpdateDrPrescriptionAddress(
      getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
        ?.doctorPrescriptionAddress !== undefined
        ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
            ?.doctorPrescriptionAddress !== null
          ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
              ?.doctorPrescriptionAddress
          : ""
        : "",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDrContactDetailsResVal]);

  // Update Contact Details value from Store
  const updateDrContactDetailsResVal = useSelector((state: AppState) => state.updateDoctorDetails);
  useEffect(() => {
    contactDetailsLoader(updateDrContactDetailsResVal?.loading);
    if (updateDrContactDetailsResVal?.successMsg !== "") {
      setUpdateDrDetailsAPIMsg(updateDrContactDetailsResVal?.successMsg);
      setMsgType("success");
      closeDrContactDetailsDialog();
      dispatch(getDoctorContactDetails(getDoctorContactDetailsBody));
    }
    if (updateDrContactDetailsResVal?.errorMsg !== "") {
      setUpdateContactDetailsError(updateDrContactDetailsResVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDrContactDetailsResVal]);

  const doctorDetails = (
    <CardContent>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
        sx={{ pt: 2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Doctor Phone Contact:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                ?.phoneContact !== undefined
                ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                    ?.phoneContact !== null
                  ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                      ?.phoneContact
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Doctor Email Contact:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                ?.emailContact !== undefined
                ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                    ?.emailContact !== null
                  ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                      ?.emailContact
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Doctor Contact Person From AIMS2Health Director:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                ?.contactPersonFromAIMS2HealthDirector !== undefined
                ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                    ?.contactPersonFromAIMS2HealthDirector !== null
                  ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                      ?.contactPersonFromAIMS2HealthDirector
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Doctor Phone Number For Prescription:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                ?.doctorPrescriptionPhoneNumber !== undefined
                ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                    ?.doctorPrescriptionPhoneNumber !== null
                  ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                      ?.doctorPrescriptionPhoneNumber
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Doctor Address For Prescription:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant="body2">
              {getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                ?.doctorPrescriptionAddress !== undefined
                ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                    ?.doctorPrescriptionAddress !== null
                  ? getDrContactDetailsResVal?.getDoctorContactDetailsRes?.doctorContactDetails
                      ?.doctorPrescriptionAddress
                  : null
                : null}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </CardContent>
  );
  const drContactDetailsDialog = (
    <Dialog open={updateDrDetailsDialog} onClose={closeDrContactDetailsDialog} fullWidth>
      {updateDrContactDetailsResVal?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h5">Update Doctor Contact Details</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeDrContactDetailsDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              value={updateDrPhone}
              label="Doctor Phone Contact"
              placeholder="Enter contact number"
              id="contactNumberID"
              onChange={(event) => setUpdateDrPhone(event.target.value)}
              fullWidth
            />
            {updateDrPhoneError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>{updateDrPhoneError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              value={updateDrEmail}
              label="Doctor Email Contact"
              placeholder="Enter Email Id"
              id="doctorEmailID"
              onChange={(event) => setUpdateDrEmail(event.target.value)}
              fullWidth
            />
            {updateDrEmailError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>{updateDrEmailError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              value={updateDrPrescriptionPhone}
              label="Doctor Phone Number For Prescription"
              placeholder="Enter dr. phone number for prescription"
              id="contactNumberID"
              onChange={(event) => setUpdateDrPrescriptionPhone(event.target.value)}
              fullWidth
            />
            {updateDrPrescriptionPhoneError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>{updateDrPrescriptionPhoneError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <TextField
              value={updateDrPrescriptionAddress}
              label="Doctor Address For Prescription"
              placeholder="Enter dr. address for prescription"
              id="doctorEmailID"
              fullWidth
              rows={2}
              multiline
              onChange={(event) => setUpdateDrPrescriptionAddress(event.target.value)}
            />
            {updateDrPrescriptionAddressError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>{updateDrPrescriptionAddressError}</Typography>
            ) : null}
          </Grid>
        </Grid>
        {updateContactDetailsErrorDiv}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={closeDrContactDetailsDialog}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={updateDrContactDetails}
              disabled={updateDrContactDetailsResVal?.loading}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
  return (
    <Box>
      {doctorDetails}
      {drContactDetailsDialog}
      <MWSnackbar msg={updateDrDetailsAPIMsg} type={msgType} alertClose={checkAlertClose} />
    </Box>
  );
}
