import {
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG,
    GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES,
    GetPatientDetailsTypeOfSessionListActionTypes,
  } from "./ActionTypes";
  import { GetPatientDetailsTypeOfSessionDetails } from "./Model";
  
  export const getPatientDetailsTypeSessionListLoadingAction = (
    loading: boolean,
  ): GetPatientDetailsTypeOfSessionListActionTypes => {
    return {
      type: GET_PATIENT_DETAILS_TYPE_OF_SESSION_LOADING,
      loading: loading,
    };
  };
  
  export const getPatientDetailsTypeSessionListSuccessAction = (
    getPatientDetailsTypeSessionListResponse: GetPatientDetailsTypeOfSessionDetails,
    successMsg: string,
  ): GetPatientDetailsTypeOfSessionListActionTypes => {
    return {
      type: GET_PATIENT_DETAILS_TYPE_OF_SESSION_SUCCESS,
      payload: getPatientDetailsTypeSessionListResponse,
      successMsg: successMsg,
    };
  };
  
  export const getPatientDetailsTypeSessionListFailAction = (
    getPatientDetailsTypeSessionListResponse: GetPatientDetailsTypeOfSessionDetails,
    errMsg: string,
    status: number,
  ): GetPatientDetailsTypeOfSessionListActionTypes => {
    return {
      type: GET_PATIENT_DETAILS_TYPE_OF_SESSION_FAIL,
      payload: getPatientDetailsTypeSessionListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getPatientDetailsTypeSessionListAPIMsgAction = (
    getPatientDetailsTypeSessionListResponse: GetPatientDetailsTypeOfSessionDetails,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetPatientDetailsTypeOfSessionListActionTypes => {
    return {
      type: GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_MSG,
      payload: getPatientDetailsTypeSessionListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getPatientDetailsTypeSessionListAPIResClearAction = (
    getPatientDetailsTypeSessionListResponse: GetPatientDetailsTypeOfSessionDetails,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetPatientDetailsTypeOfSessionListActionTypes => {
    return {
      type: GET_PATIENT_DETAILS_TYPE_OF_SESSION_API_RES,
      payload: getPatientDetailsTypeSessionListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  