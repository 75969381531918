import { useState } from "react";
import { useParams } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import { Card, Container, Typography } from "@mui/material";
import MWPageTitle from "../component/MWPageTitle";
export default function DoctorQueriesDetails() {
  const [isLoading, setIsLoading] = useState(false);
  console.log("DoctorQueriesDetails setIsLoading", setIsLoading);
  const doctorQuriesId = useParams();
  console.log("===Doctor Queries Id===");
  console.log(doctorQuriesId);
  const paymentName = "Doctor Queries Details";

  const importdataDetails = (
    <div style={{ padding: "10px" }}>
      <Typography>No Data Available yet.</Typography>
    </div>
  );
  const loadingMarkup = isLoading ? <AdminLoader /> : null;

  return (
    <Container maxWidth="xl">
      <MWPageTitle title={paymentName} />
      {loadingMarkup}
      <Card>{importdataDetails}</Card>
    </Container>
  );
}
