import {
  GET_JUDGING_SECOND_ROUND_LIST_ID_DATA,
  GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL,
  GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING,
  GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS,
  GetJudgingSecondRoundDetailsListActionTypes,
} from "./ActionTypes";
import { PageCount, GetJudgingSecondRoundDetailsListDetails } from "./Model";

export const juryCompitionIdAction = (
  GetJudgingSecondRoundDetailsListDetails: GetJudgingSecondRoundDetailsListDetails,
  pageCount: PageCount,
): GetJudgingSecondRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_SECOND_ROUND_LIST_ID_DATA,
    payload: GetJudgingSecondRoundDetailsListDetails,
    pageCount: pageCount,
  };
};

export const getJudgingSecondRoundDetailsListLoadingAction = (
  loading: boolean,
): GetJudgingSecondRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_SECOND_ROUND_LIST_ID_LOADING,
    loading: loading,
  };
};

export const getJudgingSecondRoundDetailsListSuccessAction = (
  getJudgingDetailsListResponse: GetJudgingSecondRoundDetailsListDetails,
): GetJudgingSecondRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_SECOND_ROUND_LIST_ID_SUCCESS,
    payload: getJudgingDetailsListResponse,
  };
};

export const getJudgingSecondRoundDetailsListErrorAction = (
  getJudgingDetailsListResponse: GetJudgingSecondRoundDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingSecondRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
export const getJudgingSecondRoundDetailsListAPIMsgAction = (
  getJudgingDetailsListResponse: GetJudgingSecondRoundDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingSecondRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};

export const getJudgingSecondRoundDetailsListAPIResClearAction = (
  getJudgingDetailsListResponse: GetJudgingSecondRoundDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingSecondRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_SECOND_ROUND_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
