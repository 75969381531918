import {
  GET_PREFERENCE_FAIL,
  GET_PREFERENCE_LOADING,
  GET_PREFERENCE_SUCCESS,
  GET_PREFERENCE_UPDATE_API_MSG,
  GET_PREFERENCE_UPDATE_API_RES,
  GetPreferenceDetailsActionTypes,
} from "./ActionTypes";
import { GetPreferenceResponse } from "./Model";

export const getPreferenceLoadingAction = (loading: boolean): GetPreferenceDetailsActionTypes => {
  return {
    type: GET_PREFERENCE_LOADING,
    loading: loading,
  };
};

export const getPreferenceSuccessAction = (
  GetPreferenceDetailsResponse: GetPreferenceResponse,
  successMsg: string,
): GetPreferenceDetailsActionTypes => {
  return {
    type: GET_PREFERENCE_SUCCESS,
    payload: GetPreferenceDetailsResponse,
    successMsg: successMsg,
  };
};

export const getPreferenceErrorAction = (
  GetPreferenceDetailsResponse: GetPreferenceResponse,
  errMsg: string,
  status: number,
): GetPreferenceDetailsActionTypes => {
  return {
    type: GET_PREFERENCE_FAIL,
    payload: GetPreferenceDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPreferenceUpdateAPIMsgAction = (
  GetPreferenceDetailsResponse: GetPreferenceResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPreferenceDetailsActionTypes => {
  return {
    type: GET_PREFERENCE_UPDATE_API_MSG,
    payload: GetPreferenceDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPreferenceAPIResClearAction = (
  GetPreferenceDetailsResponse: GetPreferenceResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPreferenceDetailsActionTypes => {
  return {
    type: GET_PREFERENCE_UPDATE_API_RES,
    payload: GetPreferenceDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
