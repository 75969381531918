import * as React from "react";
import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import dayjs from "dayjs";
import { sleepScheduleObservationType } from "redux/SleepAppApi/SleepScheduleObservationTypeApi/ApiCall";
import {
  PatientObservationsList,
  SleepScheduleObservationTypeBody,
} from "redux/SleepAppApi/SleepScheduleObservationTypeApi/Model";
import { formatTimeRange } from "component/Utility";

type Props = {
  observationtype?: any;
  patientid: any;
};

export default function SleepSchedule({ observationtype, patientid }: Props) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      sleepScheduleObservationType({
        patientId: patientid,
        observationType: "sleepschedule",
      } as SleepScheduleObservationTypeBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sleepScheduleObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepScheduleTypeValue,
  );
  const [sleepScheduleObservationListByType, setSleepScheduleObservationListByType] =
    React.useState([] as any[]);

  React.useEffect(() => {
    if (observationtype === "sleep") {
      if (
        sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes
          ?.patientObservationsList !== undefined
      ) {
        if (
          sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes
            ?.patientObservationsList !== undefined
        ) {
          let modifiedSleepObservationList =
            sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes?.patientObservationsList.map(
              (element: PatientObservationsList) => ({
                id: element.id,
                name: element.patientObservationType.displayValue,
                sleepSchedule: element.value,
                startDate:
                  element.startDate !== null
                    ? dayjs(element.startDate).format("dddd, DD/MM/YYYY")
                    : "N/A",
                createDate:
                  element.createDate !== null
                    ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                modifiedDate:
                  element.modifiedDate !== null
                    ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                endDate:
                  element.endDate !== null
                    ? dayjs(element.endDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                isActive: element.isActive === true ? "Active" : "Inactive",
              }),
            );
          setSleepScheduleObservationListByType(modifiedSleepObservationList);
        } else {
          setSleepScheduleObservationListByType([] as any[]);
        }
      } else {
        setSleepScheduleObservationListByType([] as any[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepScheduleObservationTypeValueList]);

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          maxHeight: 490,
          "& ul": { padding: 0 },
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            bgcolor: "background.paper",
            width: "100%",
          }}
          py={2}
        >
          Sleep Schedule
        </Typography>
        <Divider
          sx={{
            bgcolor: "background.paper",
            width: "100%",
          }}
        />
        {sleepScheduleObservationListByType.map((element: any) => (
          <List
            sx={{
              bgcolor: "background.paper",
              width: "100%",
            }}
          >
            <ListItem alignItems="center">
              <ListItemText
                primary={element.startDate}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="h6"
                      color="text.primary"
                    >
                      {formatTimeRange(element.sleepSchedule)}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </List>
        ))}
      </Grid>
    </Box>
  );
}
