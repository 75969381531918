import { AddPatientRes } from "./Model";

export const REGISTER_PATIENT_LOADING = "REGISTER_PATIENT_LOADING";
export const REGISTER_PATIENT_SUCCESS = "REGISTER_PATIENT_SUCCESS";
export const REGISTER_PATIENT_FAIL = "REGISTER_PATIENT_FAIL";
export const REGISTER_PATIENT_API_MSG = "REGISTER_PATIENT_API_MSG";
export const REGISTER_PATIENT_CLEAR_API_RES = "REGISTER_PATIENT_CLEAR_API_RES";

export interface RegisterPatientDataState {
  addPatientRes: AddPatientRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RegisterPatientLoading {
  type: typeof REGISTER_PATIENT_LOADING;
  loading: boolean;
}
export interface RegisterPatientSuccess {
  type: typeof REGISTER_PATIENT_SUCCESS;
  payload: AddPatientRes;
  successMsg: string;
}
export interface RegisterPatientFail {
  type: typeof REGISTER_PATIENT_FAIL;
  payload: AddPatientRes;
  errorMsg: string;
  status: number;
}
export interface RegisterPatientAPIMsg {
  type: typeof REGISTER_PATIENT_API_MSG;
  payload: AddPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RegisterPatientAPIRes {
  type: typeof REGISTER_PATIENT_CLEAR_API_RES;
  payload: AddPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientAddPointDataActionTypes =
  | RegisterPatientLoading
  | RegisterPatientSuccess
  | RegisterPatientFail
  | RegisterPatientAPIMsg
  | RegisterPatientAPIRes;
