import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import PatientListOfHealthCamp from "./PatientListOfHealthCamp";
import OrganizationListMappedWithHealthCampId from "./OrganizationListMappedWithHealthCampId";
import { clearAPIRes } from "../../../redux/PatientSearchByHealcampId/API";
import { clearSearchAPIRes } from "../../../redux/PatientSearchApi/API";
import { HealthCampList } from "../../../redux/healthcampList/Model";
import {
  GetPatientListByHealthCampBody,
  GetPatientListByHealthCampMainBody,
} from "../../../redux/patientListOfHealthcamp/Model";
import { getPatientListByHealthcamp } from "../../../redux/patientListOfHealthcamp/API";
import { mappedOrganizationListByHealthcampIdApi } from "../../../redux/OrganizationListMappedWithHealthCamp/API";
import { OrganizationListMappedWithHealthCampBody } from "../../../redux/OrganizationListMappedWithHealthCamp/Model";
import ViewHealthcampStaffList from "./ViewHealthcampStaffList";
import { healthcampPatientSearchByObservationTypeApi } from "redux/HealthcampPatientSearchByObservationType/API";
import { HealthcampPatientSearchListByObservationTypeBody } from "redux/HealthcampPatientSearchByObservationType/Model";
import HealthcampInstanceList from "./HealthCampInstance";
import dayjs from "dayjs";
import { GetInstanceListBody } from "redux/InstanceListOfHealthcamp /Model";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import MWPageTitle from "component/MWPageTitle";
import { getHealthcampListValue } from "redux/healthcampList/API";
import _ from "lodash";
import MWSnackbar from "component/MWSnackbar";
import { clearEditInstanceAPIRes, updateEditInstanceAPIResMsg } from "redux/EditInstance/API";
import { clearAddInstanceAPIRes } from "redux/AddInstance/API";
import { clearEditSessionAPIRes } from "redux/EditSession/API";
import { clearAddSessionAPIRes } from "redux/AddSession/API";
import HealthCampReport from "./HealthCampReport";
import HealthcampManagement from "../HealthcampManagement";
import ObservationListByHealthcampId from "./ObservationListByHealthcampId";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import PageLayout from "pages/Layout/PageLayout";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientListByHealthCamp() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let query = UseQuery();
  const tabId = query.get("section");
  const obType = query.get("obType");
  const date = query.get("date");
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const [listCount, setListCount] = React.useState("");
  const [instanceListCount, setInstanceListCount] = React.useState("");
  const [selected, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=instanceList"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=healthcampPatientList"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=mappedOrganizationList"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=staffList"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=reportList"}`);
    } else if (newValue === 5) {
      history.push(`${window.location.pathname + "?section=campManagement"}`);
    } else if (newValue === 6) {
      history.push(`${window.location.pathname + "?section=observation-list"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  const [patientListByHealthCampLoader, setPatientListByHealthCampLoader] = useState(false);
  const [viewMappedOrganizationListLoader, setViewMappedOrganizationListLoader] = useState(false);
  const [healthCampStaffListLoader, setHealthCampStaffListLoader] = useState(false);
  const [healthCampReportListLoading, setHealthCampReportListLoading] = useState(false);
  const [observationListLoader, setObservationListLoader] = useState(false);
  const changeHealthCampReportListLoadingVal = (value: boolean) => {
    setHealthCampReportListLoading(value);
  };
  const [viewMappedOrganizationCount, setViewMappedOrganizationCount] = React.useState("");
  const [healthCampReportListCount, setViewHealthCampReportListCount] = React.useState("");
  const changeHealthCampReportListCount = (value: string) => {
    setViewHealthCampReportListCount(value);
  };
  const [obsListCount, setObsListCount] = useState("0");
  const changeObsListCount = (value: string) => {
    setObsListCount(value);
  };

  const [instanceMsg, setInstanceMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const [tabValue, setTabValue] = useState(0);
  // call Store
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const AdminHealthCampPatientList = useSelector(
    (state: AppState) => state.healthcampPatientListValue,
  );
  const addResponse = useSelector((state: AppState) => state.addInstanceResValue);
  const editInstanceResponse = useSelector((state: AppState) => state.editInstanceValue);
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const healthcampValues: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );
  useEffect(() => {
    if (healthcampValues === undefined) {
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampValues]);
  const mappedHealthCampBody = {
    healthCampId: healthcampid,
  } as OrganizationListMappedWithHealthCampBody;

  useEffect(() => {
    if (tabId === "instanceList") {
      setTabValue(0);
    } else if (tabId === "healthcampPatientList") {
      setTabValue(1);
    } else if (tabId === "mappedOrganizationList") {
      setTabValue(2);
    } else if (tabId === "staffList") {
      setTabValue(3);
    } else if (tabId === "reportList") {
      setTabValue(4);
    } else if (tabId === "campManagement") {
      setTabValue(5);
    } else if (tabId === "observation-list") {
      setTabValue(6);
    }
    // eslint-disable-next-line
  }, [tabId]);

  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      setInstanceMsg(addResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    if (addResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setInstanceMsg(addResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  useEffect(() => {
    console.log("editInstanceResponse", editInstanceResponse);
    if (editInstanceResponse?.successMsg !== "") {
      setInstanceMsg(editInstanceResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
    }
    if (editInstanceResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setInstanceMsg(editInstanceResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editInstanceResponse]);

  useEffect(() => {
    if (obType !== null && date !== null) {
      dispatch(
        healthcampPatientSearchByObservationTypeApi(
          {
            patientObservationType: decodeURIComponent(obType),
            healthCampId: healthcampid,
            startDate: dayjs(decodeURIComponent(date)).format("YYYY-MM-DD"),
          } as HealthcampPatientSearchListByObservationTypeBody,
          1,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obType, date]);

  useEffect(() => {
    if (AdminHealthCampPatientList?.getPatientListByHealthcampListRes?.count !== undefined) {
      setListCount(String(AdminHealthCampPatientList?.getPatientListByHealthcampListRes?.count));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminHealthCampPatientList]);

  useEffect(() => {
    if (instanceListResponseValue?.getInstanceList?.healthcampInstanceList !== undefined) {
      setInstanceListCount(
        String(instanceListResponseValue?.getInstanceList?.healthcampInstanceList.length),
      );
    } else setInstanceListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceListResponseValue]);
  const AdminMappedOrgList = useSelector(
    (state: AppState) => state.organizationListMappedWithHealthCampIdValue,
  );
  useEffect(() => {
    AdminMappedOrgList?.patientSearchRes?.patientOrganizationList !== undefined
      ? setViewMappedOrganizationCount(
          String(AdminMappedOrgList?.patientSearchRes?.patientOrganizationList.length),
        )
      : setViewMappedOrganizationCount("0");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminMappedOrgList]);

  function OrganizationListApi() {
    dispatch(mappedOrganizationListByHealthcampIdApi(mappedHealthCampBody));
  }
  const instanceListApi = () => {
    const viewHealthcampStaffListBody = {
      healthcampId: healthcampid,
    } as GetInstanceListBody;
    dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
  };
  const reloadPatientListApi = () => {
    const filterPatientListBody = {
      healthCampId: healthcampid,
    } as GetPatientListByHealthCampBody;
    dispatch(
      getPatientListByHealthcamp({
        patientList: filterPatientListBody,
        pageNo: 1,
      } as GetPatientListByHealthCampMainBody),
    );
  };
  const handleClick = () => {
    history.push(`/patientregistration/${healthcampid}`);
    dispatch(clearAPIRes());
    dispatch(clearSearchAPIRes());
  };
  const patientListByOrganizationPage = (
    <Box>
      <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="full width tabs example"
          scrollButtons
          TabIndicatorProps={{
            style: {
              backgroundColor: "#047ab3",
            },
          }}
        >
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Instance List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={instanceListCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  instanceListApi();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Patient List Of Healthcamp</Typography>
                </Grid>
                <Grid item>
                  <Chip label={listCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  reloadPatientListApi();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>View Mapped Organization List</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={String(viewMappedOrganizationCount)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  OrganizationListApi();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(2)}
          />

          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>View Healthcamp Staff List</Typography>
                </Grid>
              </Grid>
            }
            {...a11yProps(3)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Report</Typography>
                </Grid>
                <Grid item>
                  <Chip label={String(healthCampReportListCount)} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  //
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(4)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Health Camp Management</Typography>
                </Grid>
              </Grid>
            }
            {...a11yProps(5)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Observation List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={obsListCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            {...a11yProps(6)}
          />
        </Tabs>
      </Box>
      <Divider />
      <Box>
        {fullScreen ? (
          <Box mt={2}>
            {tabValue === 0 ? (
              <HealthcampInstanceList healthcampInstanceListLoader={setHealthCampStaffListLoader} />
            ) : tabValue === 1 ? (
              <PatientListOfHealthCamp
                patientListByHealthCampLoader={setPatientListByHealthCampLoader}
                listCount={setListCount}
                obType={obType}
                date={date}
              />
            ) : tabValue === 2 ? (
              <OrganizationListMappedWithHealthCampId
                viewMappedOrganizationListLoader={setViewMappedOrganizationListLoader}
              />
            ) : tabValue === 3 ? (
              <ViewHealthcampStaffList healthCampStaffListLoader={setHealthCampStaffListLoader} />
            ) : tabValue === 4 ? (
              <HealthCampReport
                reportingLoader={changeHealthCampReportListLoadingVal}
                count={changeHealthCampReportListCount}
              />
            ) : tabValue === 5 ? (
              <HealthcampManagement />
            ) : (
              <ObservationListByHealthcampId
                observationDetailsLoader={setObservationListLoader}
                count={changeObsListCount}
              />
            )}
          </Box>
        ) : (
          <Box>
            <TabPanel value={tabValue} index={0}>
              <HealthcampInstanceList healthcampInstanceListLoader={setHealthCampStaffListLoader} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <PatientListOfHealthCamp
                patientListByHealthCampLoader={setPatientListByHealthCampLoader}
                listCount={setListCount}
                obType={obType}
                date={date}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <OrganizationListMappedWithHealthCampId
                viewMappedOrganizationListLoader={setViewMappedOrganizationListLoader}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <ViewHealthcampStaffList healthCampStaffListLoader={setHealthCampStaffListLoader} />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <HealthCampReport
                reportingLoader={changeHealthCampReportListLoadingVal}
                count={changeHealthCampReportListCount}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <HealthcampManagement />
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              <ObservationListByHealthcampId
                observationDetailsLoader={setObservationListLoader}
                count={changeObsListCount}
              />
            </TabPanel>
          </Box>
        )}
      </Box>
    </Box>
  );
  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setInstanceMsg("");
      dispatch(clearEditInstanceAPIRes());
      dispatch(clearAddInstanceAPIRes());
      dispatch(updateEditInstanceAPIResMsg());
      dispatch(clearEditSessionAPIRes());
      dispatch(clearAddSessionAPIRes());
    }
  };

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        patientListByHealthCampLoader ||
          viewMappedOrganizationListLoader ||
          healthCampStaffListLoader ||
          healthCampReportListLoading ||
          observationListLoader,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientListByHealthCampLoader,
    viewMappedOrganizationListLoader,
    healthCampStaffListLoader,
    healthCampReportListLoading,
    observationListLoader,
  ]);

  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <MWPageTitle backButton={true} />
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                {healthcampValues?.displayName !== undefined ? (
                  <Link
                    color="inherit"
                    to={`/patientlistbyhealthcamp/${healthcampid}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography>{healthcampValues?.displayName}</Typography>
                  </Link>
                ) : null}
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
          <Button
            onClick={handleClick}
            variant="contained"
            fullWidth
            sx={{ textTransform: "none" }}
          >
            Registration
          </Button>
        </Grid>
      </Grid>
      {patientListByOrganizationPage}
      {instanceMsg !== "" ? (
        <MWSnackbar
          msg={instanceMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </PageLayout>
  );
}
