import { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useDispatch, useSelector } from "react-redux";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import dayjs from "dayjs";
import { AppState } from "redux/store/Store";
import { viewHistoryInstanceByHealthCampId } from "redux/ViewHistoryInstanceofHealthcamp /API";
import { ViewHistoryInstanceBody } from "redux/ViewHistoryInstanceofHealthcamp /Model";

type Props = {
  healthcampInstanceId?: string | any;
};

export default function InstanceHistoryDialog({ healthcampInstanceId }: Props) {
  const dispatch = useDispatch();
  const editHistoryList = useSelector((state: AppState) => state.viewHistoryInstanceResult);

  useEffect(() => {
    dispatch(
      viewHistoryInstanceByHealthCampId(
        {
          healthcampinstanceId: healthcampInstanceId,
        } as ViewHistoryInstanceBody,
        1,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampInstanceId]);

  return (
    <Box>
      <Timeline>
        {editHistoryList?.viewHistoryInstance?.healthcampInstanceChangeHistoryList !== undefined ? (
          editHistoryList?.viewHistoryInstance?.healthcampInstanceChangeHistoryList?.length > 0 ? (
            editHistoryList?.viewHistoryInstance?.healthcampInstanceChangeHistoryList?.map(
              (history) => {
                return (
                  <TimelineItem key={history?.history_date}>
                    <TimelineOppositeContent color="textSecondary">
                      <Stack>
                        <Typography>
                          {dayjs(history?.history_date).format("MMM D, YYYY h:mm A")}
                        </Typography>
                        <Typography variant="button" sx={{ textTransform: "none" }}>
                          Changed By: {history?.changedBy}
                        </Typography>
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="h6" component="span">
                        {history?.history_object?.displayName}
                      </Typography>
                      <Box>{history?.changedBy !== null ? history?.changedBy : null}</Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              },
            )
          ) : (
            <Typography variant="h6">No update history available!</Typography>
          )
        ) : (
          <Typography variant="h6">No update history available!</Typography>
        )}
      </Timeline>
    </Box>
  );
}
