import {
  PatientSearchListByObservationTypeActionTypes,
  PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL,
  PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING,
  PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS,
  PatientSearchListByObservationTypeDetailsState,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientSearchListByObservationTypeDetails } from "./Model";

const initialStateGetPosts: PatientSearchListByObservationTypeDetailsState = {
  loading: false,
  patientSearchObservationTypeRes: {} as PatientSearchListByObservationTypeDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const patientSearchByObservationTypeReducer = (
  state = initialStateGetPosts,
  action: PatientSearchListByObservationTypeActionTypes,
): PatientSearchListByObservationTypeDetailsState => {
  switch (action.type) {
    case PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        patientSearchObservationTypeRes: {} as PatientSearchListByObservationTypeDetails,
      };
    case PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        patientSearchObservationTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        patientSearchObservationTypeRes: {} as PatientSearchListByObservationTypeDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        patientSearchObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        patientSearchObservationTypeRes: {} as PatientSearchListByObservationTypeDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
