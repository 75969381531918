import { AddRegistrationRequestDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_REGISTRATION_REQUEST_LOADING = "ADD_REGISTRATION_REQUEST_LOADING";
export const ADD_REGISTRATION_REQUEST_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_REGISTRATION_REQUEST_FAIL = "ADD_REGISTRATION_REQUEST_FAIL";
export const ADD_REGISTRATION_REQUEST_API_MSG = "ADD_REGISTRATION_REQUEST_API_MSG";
export const ADD_REGISTRATION_REQUEST_API_RES = "ADD_REGISTRATION_REQUEST_API_RES";

export interface AddRegistrationRequestState {
  addRegistrationRequestRes: AddRegistrationRequestDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddRegistrationRequestLoading {
  type: typeof ADD_REGISTRATION_REQUEST_LOADING;
  loading: boolean;
}
export interface AddRegistrationRequestSuccess {
  type: typeof ADD_REGISTRATION_REQUEST_SUCCESS;
  payload: AddRegistrationRequestDetails;
  successMsg: string;
}
export interface AddRegistrationRequestFail {
  type: typeof ADD_REGISTRATION_REQUEST_FAIL;
  payload: AddRegistrationRequestDetails;
  errorMsg: string;
  status: number;
}
export interface AddRegistrationRequestAPIMsg {
  type: typeof ADD_REGISTRATION_REQUEST_API_MSG;
  payload: AddRegistrationRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddRegistrationRequestAPIRes {
  type: typeof ADD_REGISTRATION_REQUEST_API_RES;
  payload: AddRegistrationRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddRegistrationRequestActionTypes =
  | AddRegistrationRequestLoading
  | AddRegistrationRequestSuccess
  | AddRegistrationRequestFail
  | AddRegistrationRequestAPIMsg
  | AddRegistrationRequestAPIRes;
