import { Button, ButtonGroup, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ActivatePatientBody } from "redux/activatePatients/Model";
import { activatePatient } from "redux/activatePatients/API";

const options = [
  "Activate",
  "Membership",
  "View patient Observation",
  "View Note",
  "Demographic Details",
];
function PatientDetailsMenu() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setOpen(false);
    if (index === 0) {
      dispatch(
        activatePatient({
          patientId: Number(patientid),
          isActive: 1,
        } as ActivatePatientBody),
      );
    } else if (index === 1) {
      history.push(`/patientteamlistdetails/${patientid}`);
    } else if (index === 2) {
      history.push(`/patientdataobservationlist/${patientid}`);
    } else if (index === 3) {
      history.push(`/patientnotes/${patientid}`);
    } else history.push(`/demographicdetails/${patientid}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        fullWidth
      >
        <Button
          fullWidth
          onClick={() => history.push(`/casedetails/${patientid}?&tab=consultationType`)}
          startIcon={<AddIcon />}
          sx={{ textTransform: "none", display: "flex" }}
          disabled={open}
        >
          Case
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event: any) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default PatientDetailsMenu;
