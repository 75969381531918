import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import {
  GetOrganizationAdminListBody,
  StaffList,
} from "redux/ViewOrganizationStaffList/AdminListByOrganizationId/Model";
import { getOrganizationAdminListByOrgId } from "redux/ViewOrganizationStaffList/AdminListByOrganizationId/API";

type Props = {
  viewOrgStaffListloader?: boolean | any;
  adminListCount: (value: string) => void;
};

export default function AdminList({ viewOrgStaffListloader, adminListCount }: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  // call Store
  const viewAdminListById = useSelector((state: AppState) => state.getAdminListByOrgIdValues);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const viewOrgAdminListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 2,
    } as GetOrganizationAdminListBody;
    dispatch(getOrganizationAdminListByOrgId(viewOrgAdminListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  useEffect(() => {
    viewAdminListById?.getOrganizationAdminList?.numberOfPages !== undefined
      ? setPageListCount(String(viewAdminListById?.getOrganizationAdminList?.numberOfPages))
      : setPageListCount("0");
    viewAdminListById?.getOrganizationAdminList?.count !== undefined
      ? adminListCount(String(viewAdminListById?.getOrganizationAdminList?.count))
      : adminListCount("0");
    if (viewAdminListById?.getOrganizationAdminList?.StaffList !== undefined) {
      let dataPointArr = viewAdminListById?.getOrganizationAdminList?.StaffList.map(
        (element: StaffList) => ({
          id: element.id,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
          phoneNumber: element.phoneNumber,
          userId: element.userId,
        }),
      );
      setDataGridArray(dataPointArr);
    }
    if (viewAdminListById?.errorMsg !== "") {
      setNotAdministratorMsg(viewAdminListById?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAdminListById]);

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];
  const organizationAdminList = (
    <Box>
      {viewAdminListById?.getOrganizationAdminList?.StaffList !== undefined ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item></Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography>Page: {page}</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={Number(pageListCount) || 0}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid mt={2}>
        {notAdministratorMsg !== "" ? (
          <Alert severity="error">{notAdministratorMsg}</Alert>
        ) : (
          <Box>
            {viewAdminListById?.getOrganizationAdminList?.StaffList &&
            viewAdminListById?.getOrganizationAdminList?.StaffList.length ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </Grid>
    </Box>
  );

  return (
    <Box>
      {viewAdminListById?.loading === true
        ? viewOrgStaffListloader(true)
        : viewOrgStaffListloader(false)}
      {organizationAdminList}
    </Box>
  );
}
