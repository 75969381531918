import {
  GetPastMedicalActionTypes,
  GetPatientDemographicsState,
  GET_PAST_MEDICAL_SUCCESS,
  GET_PAST_MEDICAL_LOADING,
  GET_PAST_MEDICAL_FAIL,
} from "./ActionTypes";
import { PastMedicalResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  getPastMedicalDetails: {} as PastMedicalResponse,
};
export const getPastMedicalReducer = (
  state = initialStateGetPosts,
  action: GetPastMedicalActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case GET_PAST_MEDICAL_SUCCESS:
      return {
        ...state,
        loading: false,
        getPastMedicalDetails: action.payload,
      };
    case GET_PAST_MEDICAL_LOADING:
      return {
        ...state,
        loading: true,
        getPastMedicalDetails: {} as PastMedicalResponse,
      };
    case GET_PAST_MEDICAL_FAIL:
      return {
        ...state,
        getPastMedicalDetails: {} as PastMedicalResponse,
        loading: false,
      };
    default:
      return state;
  }
};
