import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ObservationListByInstanceIdActionTypes } from "./ActionTypes";
import { ObservationListByInstanceIdBody, ObservationListByInstanceIdRes } from "./Model";
import {
  observationListByInstanceIdAPIResClearAction,
  observationListByInstanceIdErrorAction,
  observationListByInstanceIdLoadingAction,
  observationListByInstanceIdSuccessAction,
  observationListByInstanceIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as ObservationListByInstanceIdRes;
export const observationListByInstanceIdApi = (
  payload: ObservationListByInstanceIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<ObservationListByInstanceIdActionTypes>) {
    dispatch(observationListByInstanceIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientObservation/get_patient_observation_of_session?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(observationListByInstanceIdLoadingAction(false));
        dispatch(
          observationListByInstanceIdSuccessAction(
            res.data !== undefined ? res.data : ({} as ObservationListByInstanceIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(observationListByInstanceIdLoadingAction(false));
        dispatch(
          observationListByInstanceIdErrorAction(
            {} as ObservationListByInstanceIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateObservationListByInstanceIdAPIResMsg = () => {
  return function (dispatch: Dispatch<ObservationListByInstanceIdActionTypes>) {
    dispatch(
      observationListByInstanceIdUpdateAPIMsgAction(
        apiRes as ObservationListByInstanceIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearObservationListByInstanceIdAPIRes = () => {
  return function (dispatch: Dispatch<ObservationListByInstanceIdActionTypes>) {
    dispatch(
      observationListByInstanceIdAPIResClearAction({} as ObservationListByInstanceIdRes, "", "", 0),
    );
  };
};
