import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ExecutiveSignupRequestListActionTypes } from "./ActionTypes";
import { ExecutiveSignupRequestListBody, ExecutiveSignupRequestListDetails } from "./Model";
import {
  executiveSignupRequestListAPIResClearAction,
  executiveSignupRequestListErrorAction,
  executiveSignupRequestListLoadingAction,
  executiveSignupRequestListSuccessAction,
  executiveSignupRequestListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as ExecutiveSignupRequestListDetails;
export const executiveSignupRequestListApiCall = (payload: ExecutiveSignupRequestListBody) => {
  return function (dispatch: Dispatch<ExecutiveSignupRequestListActionTypes>) {
    dispatch(executiveSignupRequestListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/executiveSignupRequestList/executiveRequestListByType`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(executiveSignupRequestListLoadingAction(false));
        dispatch(
          executiveSignupRequestListSuccessAction(
            res.data !== undefined ? res.data : ({} as ExecutiveSignupRequestListDetails),
            res.data.message !== undefined
              ? res.data.message
              : "",
          ),
        );
      })
      .catch((error) => {
        dispatch(executiveSignupRequestListLoadingAction(false));
        dispatch(
          executiveSignupRequestListErrorAction(
            {} as ExecutiveSignupRequestListDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateExecutiveSignupRequestListAPIResMsg = () => {
  return function (dispatch: Dispatch<ExecutiveSignupRequestListActionTypes>) {
    dispatch(executiveSignupRequestListAPIMsgAction(apiRes as ExecutiveSignupRequestListDetails, "", "", 0));
  };
};

export const clearExecutiveSignupRequestListAPIRes = () => {
  return function (dispatch: Dispatch<ExecutiveSignupRequestListActionTypes>) {
    dispatch(executiveSignupRequestListAPIResClearAction({} as ExecutiveSignupRequestListDetails, "", "", 0));
  };
};
