import {
  OBSERVATION_LIST_BY_INSTANCE_ID_FAIL,
  OBSERVATION_LIST_BY_INSTANCE_ID_LOADING,
  OBSERVATION_LIST_BY_INSTANCE_ID_SUCCESS,
  OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_MSG,
  OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_RES,
  ObservationListByInstanceIdActionTypes,
} from "./ActionTypes";
import { ObservationListByInstanceIdRes } from "./Model";

export const observationListByInstanceIdLoadingAction = (
  loading: boolean,
): ObservationListByInstanceIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_INSTANCE_ID_LOADING,
    loading: loading,
  };
};

export const observationListByInstanceIdSuccessAction = (
  observationListByInstanceIdResponse: ObservationListByInstanceIdRes,
  successMsg: string,
): ObservationListByInstanceIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_INSTANCE_ID_SUCCESS,
    payload: observationListByInstanceIdResponse,
    successMsg: successMsg,
  };
};

export const observationListByInstanceIdErrorAction = (
  observationListByInstanceIdResponse: ObservationListByInstanceIdRes,
  errMsg: string,
  status: number,
): ObservationListByInstanceIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_INSTANCE_ID_FAIL,
    payload: observationListByInstanceIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const observationListByInstanceIdUpdateAPIMsgAction = (
  observationListByInstanceIdResponse: ObservationListByInstanceIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ObservationListByInstanceIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_MSG,
    payload: observationListByInstanceIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const observationListByInstanceIdAPIResClearAction = (
  observationListByInstanceIdResponse: ObservationListByInstanceIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ObservationListByInstanceIdActionTypes => {
  return {
    type: OBSERVATION_LIST_BY_INSTANCE_ID_UPDATE_API_RES,
    payload: observationListByInstanceIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
