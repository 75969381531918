import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { DeleteAccountRequestListByTypeActionTypes } from "./ActionTypes";
import { DeleteAccountRequestListByTypeBody, DeleteAccountRequestListByTypeDetails } from "./Model";
import {
  deleteAccountRequestListByTypeAPIResClearAction,
  deleteAccountRequestListByTypeErrorAction,
  deleteAccountRequestListByTypeLoadingAction,
  deleteAccountRequestListByTypeSuccessAction,
  deleteAccountRequestListByTypeAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as DeleteAccountRequestListByTypeDetails;
export const deleteAccountRequestListByTypeApiCall = (payload: DeleteAccountRequestListByTypeBody) => {
  return function (dispatch: Dispatch<DeleteAccountRequestListByTypeActionTypes>) {
    dispatch(deleteAccountRequestListByTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/executiveDeleteRequest/executive_account_delete_request_List_ByType`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(deleteAccountRequestListByTypeLoadingAction(false));
        dispatch(
          deleteAccountRequestListByTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as DeleteAccountRequestListByTypeDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Executive delete account list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deleteAccountRequestListByTypeLoadingAction(false));
        dispatch(
          deleteAccountRequestListByTypeErrorAction(
            {} as DeleteAccountRequestListByTypeDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDeleteAccountRequestListByTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<DeleteAccountRequestListByTypeActionTypes>) {
    dispatch(deleteAccountRequestListByTypeAPIMsgAction(apiRes as DeleteAccountRequestListByTypeDetails, "", "", 0));
  };
};

export const clearDeleteAccountRequestListByTypeAPIRes = () => {
  return function (dispatch: Dispatch<DeleteAccountRequestListByTypeActionTypes>) {
    dispatch(deleteAccountRequestListByTypeAPIResClearAction({} as DeleteAccountRequestListByTypeDetails, "", "", 0));
  };
};
