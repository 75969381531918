import {
 GET_DUPLICATE_PATIENT_LIST_FAIL,
 GET_DUPLICATE_PATIENT_LIST_LOADING,
 GET_DUPLICATE_PATIENT_LIST_SUCCESS,
 GET_DUPLICATE_PATIENT_LIST_API_MSG,
 GET_DUPLICATE_PATIENT_LIST_API_RES,
  GetDuplicatePatientListActionTypes,
 GET_DUPLICATE_PATIENT_LIST_DATA,
} from "./ActionTypes";
import { GetDuplicatePatientListRes, PageCount } from "./Model";

export const GetDuplicatePatientListDataAction = (
  GetDuplicatePatientListRes: GetDuplicatePatientListRes,
  pageCount: PageCount,
): GetDuplicatePatientListActionTypes => {
  return {
    type:GET_DUPLICATE_PATIENT_LIST_DATA,
    payload: GetDuplicatePatientListRes,
    pageCount: pageCount,
  };
};

export const getDuplicatePatientListLoadingAction = (
  loading: boolean,
): GetDuplicatePatientListActionTypes => {
  return {
    type:GET_DUPLICATE_PATIENT_LIST_LOADING,
    loading: loading,
  };
};

export const getDuplicatePatientListSuccessAction = (
  getDuplicatePatientListResponse: GetDuplicatePatientListRes,
): GetDuplicatePatientListActionTypes => {
  return {
    type:GET_DUPLICATE_PATIENT_LIST_SUCCESS,
    payload: getDuplicatePatientListResponse,
  };
};

export const getDuplicatePatientListErrorAction = (
  getDuplicatePatientListResponse: GetDuplicatePatientListRes,
  errMsg: string,
  status: number,
): GetDuplicatePatientListActionTypes => {
  return {
    type:GET_DUPLICATE_PATIENT_LIST_FAIL,
    payload: getDuplicatePatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDuplicatePatientListAPIMsgAction = (
  getDuplicatePatientListResponse: GetDuplicatePatientListRes,
  errMsg: string,
  status: number,
): GetDuplicatePatientListActionTypes => {
  return {
    type:GET_DUPLICATE_PATIENT_LIST_API_MSG,
    payload: getDuplicatePatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDuplicatePatientListAPIResClearAction = (
  getDuplicatePatientListResponse: GetDuplicatePatientListRes,
  errMsg: string,
  status: number,
): GetDuplicatePatientListActionTypes => {
  return {
    type:GET_DUPLICATE_PATIENT_LIST_API_RES,
    payload: getDuplicatePatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
