import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../../redux/store/Store";
import {
  updateAddExistingPtAPIResMsg,
} from "../../../../redux/addExistingHealthcampPatient/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import MWPageTitle from "../../../../component/MWPageTitle";
import MWTextField from "../../../../component/MWTextField";
import AdminLoader from "../../../AdminLoader";
import Snackbar from "@mui/material/Snackbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { Portal } from "@mui/base";
import { executiveSearchApi } from "redux/ExecutiveSearchApi/API";
import { ExecutiveSearchBody, StaffList } from "redux/ExecutiveSearchApi/Model";
import { GetViewCompetitionStaffListBody } from "redux/CompetitionStaffViewList/Model";
import { getViewCompetitionStaffList } from "redux/CompetitionStaffViewList/API";
import { AddStaffOfCompetitionBody } from "redux/AddCompetitionStaff /Model";
import { addStaffOfCompetitionApiCall } from "redux/AddCompetitionStaff /API";

type Props = {
  competitionid?: string | any;
  addExistingPatientDialogEnable?: boolean | any;
  addExistingPatientDialogUpdateState?: boolean | any;
  title?: string | any;
  addPt?: boolean | any;
  showPtDetail?: boolean | any;
  showPtDoc?: boolean | any;
};

export default function SearchStaff({ competitionid, title }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [urlPath, setUrlPath] = useState(history.location.pathname);
  useEffect(() => {
    setUrlPath(history.location.pathname);
  }, [history]);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(updateAddExistingPtAPIResMsg());
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const addCompetitionStaffSubmit = (element: string) => {
     let addBody = {
      competitionId: competitionid,
      userId: element,
    } as AddStaffOfCompetitionBody;
    dispatch(addStaffOfCompetitionApiCall(addBody));
  };

  const addPatientValueRes = useSelector((state: AppState) => state.addStaffOfCompetitionValue);

  const [inputValue, setInputValue] = React.useState("" as any);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("0");

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const executiveSearchListValueRes = useSelector((state: AppState) => state.executiveSearchValue);
  const searchExecutiveList = executiveSearchListValueRes?.executiveSearchRes.StaffList;

  const addExistingPatientValueRes = useSelector(
    (state: AppState) => state.addExistingPatientValue,
  );
  const [addExistingPt, setAddExistingPt] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    setIsLoading(addExistingPatientValueRes?.loading);
    if (addExistingPatientValueRes?.addPatientRes?.message !== undefined) {
      setAddExistingPt(addExistingPatientValueRes?.addPatientRes?.message);
      setSnackBarType("success");
      handleClick();
    }
    if (addExistingPatientValueRes?.message !== "") {
      setAddExistingPt(addExistingPatientValueRes?.message);
      setSnackBarType("error");
      handleClick();
    }
  }, [addExistingPatientValueRes]);
  const executiveSearchBody = {
    searchKey: inputValue,
  } as ExecutiveSearchBody;

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(executiveSearchApi(executiveSearchBody, value));
  };

  useEffect(() => {
    setIsLoading(executiveSearchListValueRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveSearchListValueRes]);

  useEffect(() => {
    executiveSearchListValueRes?.executiveSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(executiveSearchListValueRes?.executiveSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (executiveSearchListValueRes?.executiveSearchRes?.count !== undefined) {
      setListCount(String(executiveSearchListValueRes?.executiveSearchRes?.count));
    }
    if (searchExecutiveList !== undefined) {
      let dataPointArr = searchExecutiveList.map((element: StaffList) => ({
        id: element.id,
        first_name: element.first_name,
        last_name: element.last_name,
        email: element.email,
        phone: element.phoneNumber,
        userId: element.userId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(executiveSearchListValueRes?.executiveSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveSearchListValueRes]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    
    {
      field: "first_name",
      headerName: "First Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    
    {
      field: "email",
      headerName: "Email",
      width: 80,
      align: "left",
      flex: 1,
    },
    
    {
      field: "phone",
      headerName: "Phone Number",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "userId",
      headerName: "Action",
      width: 50,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => {
            addCompetitionStaffSubmit(params.row.userId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add Staff
          </span>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (addPatientValueRes?.AddStaffOfCompetitionRes?.message !== undefined) {
      const patientListBody = {
        competitionId: competitionid,
      } as GetViewCompetitionStaffListBody;
      dispatch(getViewCompetitionStaffList(patientListBody, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  const ExecutiveSearchListData = (
    <Card sx={{ height: "85vh" }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <MWPageTitle title="Executive Search List" enableCount={true} count={listCount} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                pt={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {searchExecutiveList && searchExecutiveList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const pageBody = (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <MWPageTitle
            backButton={true}
            title={urlPath !== "/searchpatient" ? title : "Search Patient"}
          />
        ) : null}
        <MWTextField
          label="Executive Search In MedicalWISDOM Platform"
          value={inputValue}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              dispatch(executiveSearchApi(executiveSearchBody, page));
              ev.preventDefault();
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
          placeholder="Please enter value"
          id="searchValue"
          fullWidth
          size="small"
        />
        <Box>{ExecutiveSearchListData}</Box>
      </Stack>
      <Portal>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} action={action}>
          <Alert severity={snackBarType!}>{addExistingPt}</Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
  return (
    <Box>
      {isLoading && urlPath === "/searchpatient" ? <AdminLoader /> : null}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <Container maxWidth="xl">{pageBody}</Container>
        ) : (
          <Box>{pageBody}</Box>
        )}
      </Stack>
    </Box>
  );
}
