import {
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG,
    TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES,
    ToggleActiveRegistrationRequestDetailsActionTypes,
  } from "./ActionTypes";
  import { ToggleActiveRegReqDetailsRes } from "./Model";
  
  export const toggleActiveRegistrationRequestDetailsLoadingAction = (
    loading: boolean,
  ): ToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_LOADING,
      loading: loading,
    };
  };
  
  export const toggleActiveRegistrationRequestDetailsSuccessAction = (
    toggleActiveRegistrationRequestDetailsresponse: ToggleActiveRegReqDetailsRes,
    successMsg: string,
  ): ToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_SUCCESS,
      payload: toggleActiveRegistrationRequestDetailsresponse,
      successMsg: successMsg,
    };
  };
  
  export const toggleActiveRegistrationRequestDetailsErrorAction = (
    toggleActiveRegistrationRequestDetailsresponse: ToggleActiveRegReqDetailsRes,
    errMsg: string,
    status: number,
  ): ToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_FAIL,
      payload: toggleActiveRegistrationRequestDetailsresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const toggleActiveRegistrationRequestDetailsUpdateAPIMsgAction = (
    toggleActiveRegistrationRequestDetailsresponse: ToggleActiveRegReqDetailsRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): ToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_MSG,
      payload: toggleActiveRegistrationRequestDetailsresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const toggleActiveRegistrationRequestDetailsAPIResClearAction = (
    toggleActiveRegistrationRequestDetailsresponse: ToggleActiveRegReqDetailsRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): ToggleActiveRegistrationRequestDetailsActionTypes => {
    return {
      type: TOGGLE_ACTIVE_REGISTRATION_REQUEST_DETAILS_UPDATE_API_RES,
      payload: toggleActiveRegistrationRequestDetailsresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  