import { useState, useCallback, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteOpinion, getOpinionApi } from "../../redux/Patient/FinalOpinion/ApiCall";
import { AppState } from "../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";

export default function FinalOpinion(props: any) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [patientDetailsId, setPatientDetailsId] = useState("");

  const dispatch = useDispatch();

  const { appointmentFinalOpinionLoader } = props;
  const [imageInfo, setImageInfo] = useState("");

  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const handleDeleteSecondOpinionModalChange = useCallback(
    () => setDeleteModalActive(!deleteModalActive),
    [deleteModalActive],
  );

  // Delete popup open ........
  function deleteMedicalSummary(patientDetailsID: any) {
    setPatientDetailsId(patientDetailsID);
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
  }

  const truncate = (str: string) => {
    return str.length > 0 ? str.substring(0, 20) + "..." : str;
  };

  const getSecondOpinionBody = {
    caseId: caseid,
    secondOpinionUiSection: "finalOpinion",
  };
  const getSecondOpinionList = () => {
    appointmentFinalOpinionLoader(true);
    dispatch(getOpinionApi(getSecondOpinionBody));
  };

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  // Opinion value
  const OpinionListFromAPI = useSelector((state: AppState) => state.opinion.opinionValue);
  console.log("OPINIONLIST", OpinionListFromAPI);

  let opinionConfig = [];
  for (var i = 0; i < OpinionListFromAPI.length; i++) {
    let patientDetailsID = OpinionListFromAPI[i].id;
    let description =
      OpinionListFromAPI[i].description == null ? "" : truncate(OpinionListFromAPI[i].description);
    let dateStart =
      OpinionListFromAPI[i].startDate == null
        ? ""
        : new Date(OpinionListFromAPI[i].startDate).toLocaleDateString("en-US", DATE_OPTIONS);
    let dateEnd =
      OpinionListFromAPI[i].endDate == null
        ? ""
        : new Date(OpinionListFromAPI[i].endDate).toLocaleDateString("en-US", DATE_OPTIONS);
    let supportingDocuments =
      OpinionListFromAPI[i].patientDocument == null
        ? ""
        : OpinionListFromAPI[i].patientDocument.document;
    opinionConfig.push([
      <Button
        onClick={() => {
          setDocViwerActive(true);
          setImageInfo(supportingDocuments);
        }}
        variant="outlined"
        size="small"
      >
        <IconButton color="primary">
          <DescriptionIcon />
        </IconButton>
      </Button>,
      description,
      dateStart,
      dateEnd,
      <Button
        onClick={() => deleteMedicalSummary(patientDetailsID)}
        variant="outlined"
        size="small"
      >
        <IconButton color="primary">
          <DeleteIcon />
        </IconButton>
      </Button>,
    ]);
  }
  const opinionListValue: any[] = opinionConfig;

  // Delete body....
  const deleteSecondOpinionRecordBody = {
    caseId: caseid,
    patientDetailId: patientDetailsId,
  };
  // delete api call here ....
  const DeleteSecondOpinionSubmit = () => {
    appointmentFinalOpinionLoader(true);
    dispatch(deleteOpinion(deleteSecondOpinionRecordBody));
  };

  // Delete Opinion value
  const DeleteOpinionListFromAPI = useSelector((state: AppState) => state.deleteOpinion.response);
  console.log("DELETEOPINIONLIST", DeleteOpinionListFromAPI);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSecondOpinionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const secondOpinionTableBody = (
    <div>
      <Card>
        <CardHeader title="Documents"></CardHeader>
        <CardContent>
          {opinionListValue && opinionListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opinionListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left"></TableCell>
                      {row[3]}
                      <TableCell align="left">{row[4]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </div>
  );

  const docViewerComponent = (
    <Dialog open={docViwerActive} onClose={handleDocViewerChange}>
      <DialogContent>
        <Container>
          <Typography>
            <iframe
              src={imageInfo}
              title="Small document"
              width="100%"
              height="700"
              frameBorder="0"
            >
              This is an embedded{" "}
              <a target="_blank" href="http://office.com" rel="noopener noreferrer">
                Microsoft Office
              </a>{" "}
              document, powered by{" "}
              <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
                Office Online
              </a>
              .
            </iframe>
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );

  // Delete modal section ....
  const deleteSecondOpinionDetailsBody = (
    <Dialog open={deleteModalActive} onClose={handleDeleteSecondOpinionModalChange} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography>Delete Final Opinion Details</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleDeleteSecondOpinionModalChange}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography style={{ fontWeight: "bold" }}>
            Are you sure, you want to delete this Final Opinion Details ?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleDeleteSecondOpinionModalChange}>
          No
        </Button>
        <Button variant="contained" size="medium" onClick={DeleteSecondOpinionSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <Container maxWidth="xl">
      <MWPageTitle title="Final Opinion" />
      {secondOpinionTableBody}
      {docViewerComponent}
      {deleteSecondOpinionDetailsBody}
    </Container>
  );
}
