import {
  COMPETITION_LIST_BY_PATIENT_ID_FAIL,
  COMPETITION_LIST_BY_PATIENT_ID_LOADING,
  COMPETITION_LIST_BY_PATIENT_ID_SUCCESS,
  COMPETITION_LIST_BY_PATIENT_ID_API_MSG,
  COMPETITION_LIST_BY_PATIENT_ID_API_RES,
  CompetitionListByPatientIdActionTypes,
} from "./ActionTypes";
import { CompetitionListByPatientIdDetails } from "./Model";

export const CompetitionListByPatientIdLoadingAction = (
  loading: boolean,
): CompetitionListByPatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const CompetitionListByPatientIdSuccessAction = (
  competitionListByPatientIdResponse: CompetitionListByPatientIdDetails,
  successMsg: string,
): CompetitionListByPatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_PATIENT_ID_SUCCESS,
    payload: competitionListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const CompetitionListByPatientIdErrorAction = (
  competitionListByPatientIdResponse: CompetitionListByPatientIdDetails,
  errMsg: string,
  status: number,
): CompetitionListByPatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_PATIENT_ID_FAIL,
    payload: competitionListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const CompetitionListByPatientIdAPIMsgAction = (
  competitionListByPatientIdResponse: CompetitionListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): CompetitionListByPatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_PATIENT_ID_API_MSG,
    payload: competitionListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const CompetitionListByPatientIdAPIResClearAction = (
  competitionListByPatientIdResponse: CompetitionListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): CompetitionListByPatientIdActionTypes => {
  return {
    type: COMPETITION_LIST_BY_PATIENT_ID_API_RES,
    payload: competitionListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
