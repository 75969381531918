import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ConsultationTypeResponse } from "./Model";
import {
  consultationTypeAPIResClearAction,
  consultationTypeErrorAction,
  consultationTypeLoadingAction,
  consultationTypeSuccessAction,
  consultationTypeUpdateAPIMsgAction,
} from "./Actions";
import { ConsultationTypeActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as ConsultationTypeResponse;
export const getConsultationTypeList = () => {
  return function (dispatch: Dispatch<ConsultationTypeActionTypes>) {
    dispatch(consultationTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/secondopinionapp/api/consultationtype/consultation_type`,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(consultationTypeLoadingAction(false));
        dispatch(
          consultationTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "consultation type fetch successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(consultationTypeLoadingAction(false));
        dispatch(
          consultationTypeErrorAction(
            {} as ConsultationTypeResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const consultationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<ConsultationTypeActionTypes>) {
    dispatch(
      consultationTypeUpdateAPIMsgAction(
        apiRes as ConsultationTypeResponse,
        "",
        "",
        0
      )
    );
  };
};

export const clearconsultationTypeAPIRes = () => {
  return function (dispatch: Dispatch<ConsultationTypeActionTypes>) {
    dispatch(
      consultationTypeAPIResClearAction(
        {} as ConsultationTypeResponse,
        "",
        "",
        0
      )
    );
  };
};
