import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  Button,
  Card,
  CardContent,
  AlertColor,
  CardHeader,
  Grid,
  Typography,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import MWExceptionList from "../../component/MWExceptionList";
import MWPageTitle from "component/MWPageTitle";
import { getUnprocessedPatientDetailsList } from "redux/UnprocessedPatientDetails/API";
import { PatientDetailsFailedProcessingList } from "redux/UnprocessedPatientDetails/Model";
import { PatientDetailsFixedBody } from "redux/MarkPatientDetails/Model";
import {
  clearPatientDetailsFixedAPIRes,
  patientDetailsFixedApiCall,
} from "redux/MarkPatientDetails/API";
import MWSnackbar from "component/MWSnackbar";
import { useHistory } from "react-router-dom";
import UnprocessedPatientDetailsViewer from "./UnprocessedPatientDetailsViewer";
import PageLayout from "pages/Layout/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function UnprocessedPatientDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const unprocessedPatientDataList = useSelector(
    (state: AppState) => state.getUnprocessedPatientDetailsValues,
  );
  const fixedPatientDetails = useSelector((state: AppState) => state.patientDetailsFixedValue);
  useEffect(() => {
    dispatch(getUnprocessedPatientDetailsList(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  const [listCount, setListCount] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [fixedMsg, setFixedMsg] = React.useState("");
  const [fixedMsgType, setFixedMsgType] = React.useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getUnprocessedPatientDetailsList(value));
  };
  useEffect(() => {
    dispatch(toggleAppBarLoading(unprocessedPatientDataList?.loading));
    unprocessedPatientDataList?.getUnprocessedPatientDetailsListRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(unprocessedPatientDataList?.getUnprocessedPatientDetailsListRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (
      unprocessedPatientDataList?.getUnprocessedPatientDetailsListRes
        ?.patientDetailsFailedProcessingList !== undefined
    ) {
      setListCount(String(unprocessedPatientDataList?.getUnprocessedPatientDetailsListRes?.count));
      let dataPointArr =
        unprocessedPatientDataList?.getUnprocessedPatientDetailsListRes?.patientDetailsFailedProcessingList.map(
          (element: PatientDetailsFailedProcessingList) => ({
            id: element.patientDetailId,
            markId: element.id,
            description: element.description,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            patientId: element.patientId,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unprocessedPatientDataList]);

  const markPatientDetails = (unprocessedId: string) => {
    let markBody = {
      patientDetailFailedProcessingId: unprocessedId,
    } as PatientDetailsFixedBody;
    dispatch(patientDetailsFixedApiCall(markBody));
  };

  useEffect(() => {
    if (fixedPatientDetails?.patientDetailsFixedRes?.message !== undefined) {
      setFixedMsg(fixedPatientDetails?.successMsg);
      setFixedMsgType("success");
      dispatch(getUnprocessedPatientDetailsList(1));
      dispatch(clearPatientDetailsFixedAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedPatientDetails]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setDocViwerActive(true);
            setSelectedItem(Object.assign(params.row, params.id));
            history.push(
              `/unprocessesedpatientdetails?patientid=${params.row.patientId}&documentid=${params.row.id}`,
            );
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "PatientDetails Id",
      minWidth: 130,
      align: "left",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      minWidth: 250,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "markId",
      headerName: "Mark",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            markPatientDetails(params.value);
          }}
        >
          Mark
        </Button>
      ),
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {!fullScreen && (
              <Grid item>
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
              </Grid>
            )}
            <Grid item>
              <Pagination
                color="primary"
                count={Number(pageListCount)}
                page={page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  const [docViwerActive, setDocViwerActive] = React.useState(false);
  const dialogClose = (childState: any) => {
    setDocViwerActive(childState);
  };
  const [selectedItem, setSelectedItem] = React.useState<any>(({} as any) || undefined);

  const closeMarkAlert = () => {
    setFixedMsg("");
    dispatch(clearPatientDetailsFixedAPIRes());
  };

  return (
    <PageLayout>
      <MWPageTitle
        title="Unprocessed Patient Details List"
        enableCount={true}
        count={listCount}
        reload={true}
        reloadAction={()=>dispatch(getUnprocessedPatientDetailsList(page))}
      />
      {patientPointDataTable}
      {docViwerActive ? (
        <UnprocessedPatientDetailsViewer
          dialogState={docViwerActive}
          dialogClose={dialogClose}
          value={selectedItem}
        />
      ) : null}
      {fixedMsg !== "" ? (
        <MWSnackbar msg={fixedMsg} type={fixedMsgType} alertClose={closeMarkAlert} />
      ) : null}
    </PageLayout>
  );
}
