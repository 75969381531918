import {
    PATIENT_DETAILS_TYPE_LIST_FAIL,
    PATIENT_DETAILS_TYPE_LIST_LOADING,
    PATIENT_DETAILS_TYPE_LIST_SUCCESS,
    PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG,
    PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES,
    PatientDetailsTypeListActionTypes,
  } from "./ActionTypes";
  import { PatientDetailsTypeRes } from "./Model";
  
  export const patientDetailsTypeListLoadingAction = (
    loading: boolean
  ): PatientDetailsTypeListActionTypes => {
    return {
      type: PATIENT_DETAILS_TYPE_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const patientDetailsTypeListSuccessAction = (
    patientDetailsTypeListResponse: PatientDetailsTypeRes,
    successMsg: string
  ): PatientDetailsTypeListActionTypes => {
    return {
      type: PATIENT_DETAILS_TYPE_LIST_SUCCESS,
      payload: patientDetailsTypeListResponse,
      successMsg: successMsg,
    };
  };
  
  export const patientDetailsTypeListErrorAction = (
    patientDetailsTypeListResponse: PatientDetailsTypeRes,
    errMsg: string,
    status: number
  ): PatientDetailsTypeListActionTypes => {
    return {
      type: PATIENT_DETAILS_TYPE_LIST_FAIL,
      payload: patientDetailsTypeListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const patientDetailsTypeListUpdateAPIMsgAction = (
    patientDetailsTypeListResponse: PatientDetailsTypeRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): PatientDetailsTypeListActionTypes => {
    return {
      type: PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG,
      payload: patientDetailsTypeListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const patientDetailsTypeListAPIResClearAction = (
    patientDetailsTypeListResponse: PatientDetailsTypeRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): PatientDetailsTypeListActionTypes => {
    return {
      type: PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES,
      payload: patientDetailsTypeListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  