import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { DuplicateOrganizationListByPatientIdActionTypes } from "./ActionTypes";
import { DuplicateOrganizationListByPatientIdBody, DuplicateOrganizationListByPatientIdDetails } from "./Model";
import {
  DuplicateOrganizationListByPatientIdAPIResClearAction,
  DuplicateOrganizationListByPatientIdErrorAction,
  DuplicateOrganizationListByPatientIdLoadingAction,
  DuplicateOrganizationListByPatientIdSuccessAction,
  DuplicateOrganizationListByPatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as DuplicateOrganizationListByPatientIdDetails;
export const duplicateOrganizationListByPatientIdApi = (
  patientid: DuplicateOrganizationListByPatientIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<DuplicateOrganizationListByPatientIdActionTypes>) {
    dispatch(DuplicateOrganizationListByPatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
        `/adminapp/api/patientorganizationlist/organization_list_by_patient?page=${Number(
          pageNo,
        )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DuplicateOrganizationListByPatientIdLoadingAction(false));
        dispatch(
          DuplicateOrganizationListByPatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as DuplicateOrganizationListByPatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(DuplicateOrganizationListByPatientIdLoadingAction(false));
        dispatch(
          DuplicateOrganizationListByPatientIdErrorAction(
            {} as DuplicateOrganizationListByPatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateDuplicateOrganizationListByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<DuplicateOrganizationListByPatientIdActionTypes>) {
    dispatch(
      DuplicateOrganizationListByPatientIdAPIMsgAction(apiRes as DuplicateOrganizationListByPatientIdDetails, "", "", 0),
    );
  };
};

export const clearDuplicateOrganizationListByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<DuplicateOrganizationListByPatientIdActionTypes>) {
    dispatch(
      DuplicateOrganizationListByPatientIdAPIResClearAction({} as DuplicateOrganizationListByPatientIdDetails, "", "", 0),
    );
  };
};
