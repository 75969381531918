import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetWeekDayListActionTypes } from "./ActionTypes";
import { GetWeekDayListRes } from "./Model";
import {
  getWeekDayListAPIResClearAction,
  getWeekDayListErrorAction,
  getWeekDayListLoadingAction,
  getWeekDayListSuccessAction,
  getWeekDayListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as GetWeekDayListRes;
export const getWeekDayList = () => {
  return function (dispatch: Dispatch<GetWeekDayListActionTypes>) {
    dispatch(getWeekDayListLoadingAction(true));
    axios
      .get(
        Url.baseUrl + `/appointmentapp/api/workSchedule/weekDayList`,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getWeekDayListLoadingAction(false));
        dispatch(
          getWeekDayListSuccessAction(
            res.data,
            " work schedule details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getWeekDayListLoadingAction(false));
        dispatch(
          getWeekDayListErrorAction(
            {} as GetWeekDayListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateGetWeekDayListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetWeekDayListActionTypes>) {
    dispatch(
      getWeekDayListUpdateAPIMsgAction(apiRes as GetWeekDayListRes, "", "", 0)
    );
  };
};

export const clearWeekDayListAPIRes = () => {
  return function (dispatch: Dispatch<GetWeekDayListActionTypes>) {
    dispatch(
      getWeekDayListAPIResClearAction({} as GetWeekDayListRes, "", "", 0)
    );
  };
};
