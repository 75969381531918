import {
  PATIENT_LIST_BY_SESSION_ID_FAIL,
  PATIENT_LIST_BY_SESSION_ID_LOADING,
  PATIENT_LIST_BY_SESSION_ID_SUCCESS,
  PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG,
  PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES,
  GetPatientListBySessionIdActionTypes,
} from "./ActionTypes";
import { GetPatientListBySessionIdRes } from "./Model";

export const getPatientListBySessionIdLoadingAction = (
  loading: boolean,
): GetPatientListBySessionIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const getPatientListBySessionIdSuccessAction = (
  getPatientListBySessionIdResponse: GetPatientListBySessionIdRes,
  successMsg: string,
): GetPatientListBySessionIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_SESSION_ID_SUCCESS,
    payload: getPatientListBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const getPatientListBySessionIdErrorAction = (
  getPatientListBySessionIdResponse: GetPatientListBySessionIdRes,
  errMsg: string,
  status: number,
): GetPatientListBySessionIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_SESSION_ID_FAIL,
    payload: getPatientListBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientListBySessionIdUpdateAPIMsgAction = (
  getPatientListBySessionIdResponse: GetPatientListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientListBySessionIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_SESSION_ID_UPDATE_API_MSG,
    payload: getPatientListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientListBySessionIdAPIResClearAction = (
  getPatientListBySessionIdResponse: GetPatientListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientListBySessionIdActionTypes => {
  return {
    type: PATIENT_LIST_BY_SESSION_ID_UPDATE_API_RES,
    payload: getPatientListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
