import {
  DoctorList,
  DoctorListForAdmin,
  PatientList,
  AdminTaskList,
  AdminHealthcampList,
  AdminQuestionList,
  SetVerify,
  CaseList,
  QuestionList,
  HealthcampPatientList,
  organizationPatientList,
  ChiefComplaintList,
  SetChiefComplaint,
  AddResponse,
  SetDeleteDocument,
  AdminAllTaskList,
  Count,
  PageCount,
  PatientPageCount,
  AllCaseList,
  AdminAllAppointmentList,
  AllAppointmentPageCount,
  CaseHistory,
  AdminPatientTeamList,
  PatientTeamListPageCount,
  patientTeamCurrentPageCount,
  AdminAllPaymentList,
  AdminPatientQueryList,
  AdminDoctorQueryList,
  PaymentPageCount,
  AdminAllInactiveTaskList,
  ActiveCorporateList,
  InactiveCorporateList,
  ActiveCorporatePatientList,
  InActiveCorporatePatientList,
  AdminOrganizationList,
  TaskCloseResponse,
  AcceptRejectAppointmentResponse,
} from "../interfaces/Model";

export const GET_LIST = "GET_LIST";
export const GET_ONBOARDING_REQUEST_LIST = "GET_ONBOARDING_REQUEST_LIST";
export const GET_ONBOARDING_REJECT_LIST = "GET_ONBOARDING_REJECT_LIST";
export const GET_ONBOARDING_INCOMPLETE_LIST = "GET_ONBOARDING_INCOMPLETE_LIST";
export const GET_DOCTOR_LIST = "GET__DOCTOR_LIST";
export const GET_PATIENT_LIST = "GET__PATIENT_LIST";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const GET_HEALTHCAMP_LIST = "GET_HEALTHCAMP_LIST";
export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const GET_ALL_TASK_LIST = "GET_ALL_TASK_LIST";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const SET_VERIFY = "SET_VERIFY";
export const GET_CASE_LIST = "GET_CASE_LIST";
export const GET_HEALTHCAMPPATIENT_LIST = "GET_HEALTHCAMPPATIENT_LIST";
export const GET_ORGANIZATIONPATIENT_LIST = "GET_ORGANIZATIONPATIENT_LIST";
export const DOCTOR_REQUEST_LIST_LOADING = "DOCTOR_REQUEST_LIST_LOADING";
export const DOCTOR_REQUEST_LIST_SUCCESS = "DOCTOR_REQUEST_LIST_SUCCESS";
export const DOCTOR_REQUEST_LIST_FAIL = "DOCTOR_REQUEST_LIST_FAIL";

export const DOCTOR_REJECT_LIST_LOADING = "DOCTOR_REJECT_LIST_LOADING";
export const DOCTOR_REJECT_LIST_SUCCESS = "DOCTOR_REJECT_LIST_SUCCESS";
export const DOCTOR_REJECT_LIST_FAIL = "DOCTOR_REJECT_LIST_FAIL";

export const DOCTOR_INCOMPLETE_LIST_LOADING = "DOCTOR_INCOMPLETE_LIST_LOADING";
export const DOCTOR_INCOMPLETE_LIST_SUCCESS = "DOCTOR_INCOMPLETE_LIST_SUCCESS";
export const DOCTOR_INCOMPLETE_LIST_FAIL = "DOCTOR_INCOMPLETE_LIST_FAIL";

export const PATIENT_LIST_LOADING = "PATIENT_LIST_LOADING";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAIL = "PATIENT_LIST_FAIL";

export const TASK_LIST_LOADING = "TASK_LIST_LOADING";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";
export const TASK_LIST_FAIL = "TASK_LIST_FAIL";

export const ORGANIZATION_LIST_LOADING = "ORGANIZATION_LIST_LOADING";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";
export const ORGANIZATION_LIST_FAIL = "ORGANIZATION_LIST_FAIL";

export const ALL_TASK_LIST_LOADING = "ALL_TASK_LIST_LOADING";
export const ALL_TASK_LIST_SUCCESS = "ALL_TASK_LIST_SUCCESS";
export const ALL_TASK_LIST_FAIL = "ALL_TASK_LIST_FAIL";

export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";

export const GET_CC_LIST = "GET_CC_LIST";
export const ADD_CC = "ADD_CC";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const APPOINTMENT_PATIENT_LIST = "APPOINTMENT__PATIENT_LIST";
export const APPOINTMENT_LIST_LOADING = "APPOINTMENT_LIST_LOADING";
export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS";
export const APPOINTMENT_LIST_FAIL = "QUESTION_LIST_FAIL";

export const ALL_PATIENT_TEAM_LIST = "ALL_PATIENT_TEAM_LIST";
export const ALL_PATIENT_TEAM_LOADING = "ALL_PATIENT_TEAM_LOADING";
export const ALL_PATIENT_TEAM_SUCCESS = "ALL_PATIENT_TEAM_SUCCESS";
export const ALL_PATIENT_TEAM_FAIL = "ALL_PATIENT_TEAM_FAIL";

export const DOCTOR_QUERY_LIST = "DOCTOR_QUERY_LIST";
export const DOCTOR_QUERY_LOADING = "DOCTOR_QUERY_LOADING";
export const DOCTOR_QUERY_SUCCESS = "DOCTOR_QUERY_SUCCESS";
export const DOCTOR_QUERY_FAIL = "DOCTOR_QUERY_FAIL";

export const GET_ALL_INACTIVE_TASK_LIST = "GET_ALL_INACTIVE_TASK_LIST";
export const ALL_INACTIVE_TASK_LIST_LOADING = "ALL_INACTIVE_TASK_LIST_LOADING";
export const ALL_INACTIVE_TASK_LIST_SUCCESS = "ALL_INACTIVE_TASK_LIST_SUCCESS";
export const ALL_INACTIVE_TASK_LIST_FAIL = "ALL_INACTIVE_TASK_LIST_FAIL";

export const ACTIVE_CORPORATE_LIST = "ACTIVE_CORPORATE_LIST";
export const ACTIVE_CORPORATE_LIST_LOADING = "ACTIVE_CORPORATE_LIST_LOADING";
export const ACTIVE_CORPORATE_LIST_SUCCESS = "ACTIVE_CORPORATE_LIST_SUCCESS";
export const ACTIVE_CORPORATE_LIST_FAIL = "ACTIVE_CORPORATE_LIST_FAIL";

export const INACTIVE_CORPORATE_LIST = "INACTIVE_CORPORATE_LIST";
export const INACTIVE_CORPORATE_LIST_LOADING = "INACTIVE_CORPORATE_LIST_LOADING";
export const INACTIVE_CORPORATE_LIST_SUCCESS = "INACTIVE_CORPORATE_LIST_SUCCESS";
export const INACTIVE_CORPORATE_LIST_FAIL = "INACTIVE_CORPORATE_LIST_FAIL";

export const ACTIVE_CORPORATE_PATIENT_LIST = "ACTIVE_CORPORATE_PATIENT_LIST";
export const ACTIVE_CORPORATE_PATIENT_LIST_LOADING = "ACTIVE_CORPORATE_PATIENT_LIST_LOADING";
export const ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS = "ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS";
export const ACTIVE_CORPORATE_PATIENT_LIST_FAIL = "ACTIVE_CORPORATE_PATIENT_LIST_FAIL";

export const INACTIVE_CORPORATE_PATIENT_LIST = "INACTIVE_CORPORATE_PATIENT_LIST";
export const INACTIVE_CORPORATE_PATIENT_LIST_LOADING = "INACTIVE_CORPORATE_PATIENT_LIST_LOADING";
export const INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS = "INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS";
export const INACTIVE_CORPORATE_PATIENT_LIST_FAIL = "INACTIVE_CORPORATE_PATIENT_LIST_FAIL";

export interface GetDoctorStateType {
  doctorValue: DoctorList[];
}

interface PatientPointDataActionType {
  type: typeof GET_LIST;
  payload: DoctorList[];
}

export interface GetOnboardingDoctorRequestStateType {
  doctorRequestValue: DoctorList[];
  loading: boolean;
}

interface GetOnboardingDoctorRequestActionType {
  type: typeof GET_ONBOARDING_REQUEST_LIST;
  payload: DoctorList[];
}

export interface GetOnboardingDoctorRejectStateType {
  doctorRejectValue: DoctorList[];
  loading: boolean;
}

interface GetOnboardingDoctorRejectActionType {
  type: typeof GET_ONBOARDING_REJECT_LIST;
  payload: DoctorList[];
}

export interface GetOnboardingDoctorIncompleteStateType {
  doctorIncompleteValue: DoctorList[];
  loading: boolean;
}

interface GetOnboardingDoctorIncompleteActionType {
  type: typeof GET_ONBOARDING_INCOMPLETE_LIST;
  payload: DoctorList[];
}

export interface DoctorAdminGetActionStateType {
  doctorListValue: DoctorListForAdmin[];
  loading: boolean;
}

interface DoctorAdminGetActionType {
  type: typeof GET_DOCTOR_LIST;
  payload: DoctorListForAdmin[];
}

export interface GetPatientStateType {
  patientValue: PatientList[];
  loading: boolean;
  patientPageCount: PatientPageCount;
  count: number;
}

interface GetPatientActionType {
  type: typeof GET_PATIENT_LIST;
  payload: PatientList[];
  patientPageCount: PatientPageCount;
  count: number;
}

export interface DoctorRequestListLoading {
  type: typeof DOCTOR_REQUEST_LIST_LOADING;
}
export interface DoctorRequestListSuccess {
  type: typeof DOCTOR_REQUEST_LIST_SUCCESS;
  payload: DoctorList[];
}
export interface DoctorRequestListFail {
  type: typeof DOCTOR_REQUEST_LIST_FAIL;
}

export interface DoctorRejectListLoading {
  type: typeof DOCTOR_REJECT_LIST_LOADING;
}
export interface DoctorRejectListtSuccess {
  type: typeof DOCTOR_REJECT_LIST_SUCCESS;
  payload: DoctorList[];
}
export interface DoctorRejectListtFail {
  type: typeof DOCTOR_REJECT_LIST_FAIL;
}

export interface DoctorIncompleteListLoading {
  type: typeof DOCTOR_INCOMPLETE_LIST_LOADING;
}
export interface DoctorIncompleteListtSuccess {
  type: typeof DOCTOR_INCOMPLETE_LIST_SUCCESS;
  payload: DoctorList[];
}
export interface DoctorIncompleteListtFail {
  type: typeof DOCTOR_INCOMPLETE_LIST_FAIL;
}

export interface PatientPointDataLoading {
  type: typeof DOCTOR_LIST_LOADING;
}
export interface PatientPointDataSuccess {
  type: typeof DOCTOR_LIST_SUCCESS;
  payload: DoctorList[];
}
export interface PatientPointDataFail {
  type: typeof DOCTOR_LIST_FAIL;
}

export interface PatientListLoading {
  type: typeof PATIENT_LIST_LOADING;
}
export interface PatientListSuccess {
  type: typeof PATIENT_LIST_SUCCESS;
  payload: PatientList[];
  patientPageCount: PatientPageCount;
  count: number;
}

export interface PatientListFail {
  type: typeof PATIENT_LIST_FAIL;
}

export interface GetTaskListStateType {
  taskListValue: AdminTaskList[];
  loading: boolean;
}
interface AdminGetTaskListActionType {
  type: typeof GET_TASK_LIST;
  payload: AdminTaskList[];
}

export interface GetOrganizationListStateType {
  organizationListValue: AdminOrganizationList[];
  loading: boolean;
}

interface AdminGetOrganizationListActionType {
  type: typeof GET_ORGANIZATION_LIST;
  payload: AdminOrganizationList[];
}

// Get active task list
export interface GetAllTaskListStateType {
  alltaskListValue: AdminAllTaskList[];
  loading: boolean;
  count: Count;
  pageCount: PageCount;
}
interface AdminGetAllTaskListActionType {
  type: typeof GET_ALL_TASK_LIST;
  payload: AdminAllTaskList[];
  count: Count;
  pageCount: PageCount;
}
export interface AllTaskListLoading {
  type: typeof ALL_TASK_LIST_LOADING;
}
export interface AllTaskListSuccess {
  type: typeof ALL_TASK_LIST_SUCCESS;
  payload: AdminAllTaskList[];
  count: Count;
  pageCount: PageCount;
}
export interface AllTaskListFail {
  type: typeof ALL_TASK_LIST_FAIL;
}
/////////////////////////////////////

// Get inactive task list
export interface GetAllInactiveTaskListStateType {
  allinactivetasklist: AdminAllInactiveTaskList[];
  loading: boolean;
  count: Count;
  pageCount: PageCount;
}
interface AdminGetAllInactiveTaskListActionType {
  type: typeof GET_ALL_INACTIVE_TASK_LIST;
  payload: AdminAllInactiveTaskList[];
  count: Count;
  pageCount: PageCount;
}
export interface AllInactiveTaskListLoading {
  type: typeof ALL_INACTIVE_TASK_LIST_LOADING;
}
export interface AllInactiveTaskListSuccess {
  type: typeof ALL_INACTIVE_TASK_LIST_SUCCESS;
  payload: AdminAllInactiveTaskList[];
  count: Count;
  pageCount: PageCount;
}
export interface AllInactiveTaskListFail {
  type: typeof ALL_INACTIVE_TASK_LIST_FAIL;
}
/////////////////////////////////////
export interface TaskListLoading {
  type: typeof TASK_LIST_LOADING;
}
export interface TaskListSuccess {
  type: typeof TASK_LIST_SUCCESS;
  payload: AdminTaskList[];
}
export interface TaskListFail {
  type: typeof TASK_LIST_FAIL;
}

export interface OrganizationListLoading {
  type: typeof ORGANIZATION_LIST_LOADING;
}
export interface OrganizationListSuccess {
  type: typeof ORGANIZATION_LIST_SUCCESS;
  payload: AdminHealthcampList[];
}
export interface OrganizationListFail {
  type: typeof ORGANIZATION_LIST_FAIL;
}

export interface GetQuestionListStateType {
  questionListValue: AdminQuestionList[];
  loading: boolean;
}

interface AdminGetQuestionListActionType {
  type: typeof GET_QUESTION_LIST;
  payload: AdminQuestionList[];
}

export interface QuestionListLoading {
  type: typeof QUESTION_LIST_LOADING;
}
export interface QuestionListSuccess {
  type: typeof QUESTION_LIST_SUCCESS;
  payload: AdminQuestionList[];
}
export interface QuestionListFail {
  type: typeof QUESTION_LIST_FAIL;
}

export interface SetVerifyStateType {
  setVerify: SetVerify[];
}

interface SetVerifyActionType {
  type: typeof SET_VERIFY;
  payload: SetVerify[];
}

// Case Loading State
export const CASE_LIST_LOADING = "CASE_LIST_LOADING";
export const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
export const CASE_LIST_FAIL = "CASE_LIST_FAIL";

export interface GetCaseStateType {
  caseValue: CaseList[];
  loading: boolean;
  patientname: CaseHistory;
}
export interface CaseListLoading {
  type: typeof CASE_LIST_LOADING;
}
export interface CaseListSuccess {
  type: typeof CASE_LIST_SUCCESS;
  payload: CaseList[];
  patientname: CaseHistory;
}
export interface CaseListFail {
  type: typeof CASE_LIST_FAIL;
}

interface GetCaseActionType {
  type: typeof GET_CASE_LIST;
  payload: CaseList[];
  patientname: CaseHistory;
}

// Healthcamp Patient Loading State
export const HCPATIENT_LIST_LOADING = "HCPATIENT_LIST_LOADING";
export const HCPATIENT_LIST_SUCCESS = "HCPATIENT_LIST_SUCCESS";
export const HCPATIENT_LIST_FAIL = "HCPATIENT_LIST_FAIL";

export interface GetHealthcampPatientStateType {
  healthcampPatientValue: HealthcampPatientList;
  loading: boolean;
}
export interface HealthcampPatientListLoading {
  type: typeof HCPATIENT_LIST_LOADING;
}
export interface HealthcampPatientListSuccess {
  type: typeof HCPATIENT_LIST_SUCCESS;
  payload: HealthcampPatientList;
}
export interface HealthcampPatientListFail {
  type: typeof HCPATIENT_LIST_FAIL;
}

interface GetHealthcampPatientActionType {
  type: typeof GET_HEALTHCAMPPATIENT_LIST;
  payload: HealthcampPatientList;
}

// Organization Patient Loading State
export const ORGPATIENT_LIST_LOADING = "ORGPATIENT_LIST_LOADING";
export const ORGPATIENT_LIST_SUCCESS = "ORGPATIENT_LIST_SUCCESS";
export const ORGPATIENT_LIST_FAIL = "ORGPATIENT_LIST_FAIL";

export interface GetOrganizationPatientStateType {
  organizationPatientValue: organizationPatientList[];
  loading: boolean;
}
export interface OrganizationPatientListLoading {
  type: typeof ORGPATIENT_LIST_LOADING;
}
export interface OrganizationPatientListSuccess {
  type: typeof ORGPATIENT_LIST_SUCCESS;
  payload: organizationPatientList[];
}
export interface OrganizationPatientListFail {
  type: typeof ORGPATIENT_LIST_FAIL;
}

interface GetOrganizationPatientActionType {
  type: typeof GET_ORGANIZATIONPATIENT_LIST;
  payload: organizationPatientList[];
}

// All case list
export const ALL_CASE_LIST_LOADING = "ALL_CASE_LIST_LOADING";
export const ALL_CASE_LIST_SUCCESS = "ALL_CASE_LIST_SUCCESS";
export const ALL_CASE_LIST_FAIL = "ALL_CASE_LIST_FAIL";
export const GET_ALL_CASE_LIST = "GET_ALL_CASE_LIST";

export interface AllGetCaseStateType {
  allcaseValue: AllCaseList;
  loading: boolean;
  pageCount: PageCount;
}
export interface AllCaseListLoading {
  type: typeof ALL_CASE_LIST_LOADING;
}
export interface AllCaseListSuccess {
  type: typeof ALL_CASE_LIST_SUCCESS;
  payload: AllCaseList;
  pageCount: PageCount;
}
export interface AllCaseListFail {
  type: typeof ALL_CASE_LIST_FAIL;
}

interface GetAllCaseActionType {
  type: typeof GET_ALL_CASE_LIST;
  payload: AllCaseList;
  pageCount: PageCount;
}

export interface GetQuestionStateType {
  questionValue: QuestionList[];
  loading: boolean;
}
export interface PatientQuestionListLoading {
  type: typeof QUESTION_LIST_LOADING;
}
export interface PatientQuestionListSuccess {
  type: typeof QUESTION_LIST_SUCCESS;
  payload: QuestionList[];
}
export interface PatientQuestionListFail {
  type: typeof QUESTION_LIST_FAIL;
}

interface GetQuestionActionType {
  type: typeof GET_QUESTION_LIST;
  payload: QuestionList[];
}

// Chief Complaint Loading State
export const CC_LIST_LOADING = "CC_LIST_LOADING";
export const CC_LIST_SUCCESS = "CC_LIST_SUCCESS";
export const CC_LIST_FAIL = "CC_LIST_FAIL";

export interface GetChiefComplaintStateType {
  ccValue: ChiefComplaintList[];
  loading: boolean;
}
export interface CCListLoading {
  type: typeof CC_LIST_LOADING;
}
export interface CCListSuccess {
  type: typeof CC_LIST_SUCCESS;
  payload: ChiefComplaintList[];
}
export interface CCListFail {
  type: typeof CC_LIST_FAIL;
}

interface GetCCActionType {
  type: typeof GET_CC_LIST;
  payload: ChiefComplaintList[];
}

// ADD CHIEF COMPLAINT
export const CC_ADD_LIST_LOADING = "CC_ADD_LIST_LOADING";
export const CC_ADD_LIST_SUCCESS = "CC_ADD_LIST_SUCCESS";
export const CC_ADD_LIST_FAIL = "CC_ADD_LIST_FAIL";

export interface SetAddChiefComplaintStateType {
  addCC: SetChiefComplaint[];
  loading: boolean;
  response: AddResponse;
}
export interface CCAddLoading {
  type: typeof CC_ADD_LIST_LOADING;
}
export interface CCAddSuccess {
  type: typeof CC_ADD_LIST_SUCCESS;
  payload: AddResponse;
}
export interface CCAddFail {
  type: typeof CC_ADD_LIST_FAIL;
}

interface SetAddChiefComplaintType {
  type: typeof ADD_CC;
  payload: SetChiefComplaint[];
}

// DELETE Document
export const DELETE_DOCUMENT_LIST_LOADING = "DELETE_DOCUMENT_LIST_LOADING";
export const DELETE_DOCUMENT_LIST_SUCCESS = "DELETE_DOCUMENT_LIST_SUCCESS";
export const DELETE_DOCUMENT_LIST_FAIL = "DELETE_DOCUMENT_LIST_FAIL";

export interface SetDeleteDocumentStateType {
  deleteDoc: SetDeleteDocument[];
  loading: boolean;
  response: AddResponse;
}
export interface DeleteDocumentLoading {
  type: typeof DELETE_DOCUMENT_LIST_LOADING;
}
export interface DeleteDocumentSuccess {
  type: typeof DELETE_DOCUMENT_LIST_SUCCESS;
  payload: AddResponse;
}
export interface DeleteDocumentFail {
  type: typeof DELETE_DOCUMENT_LIST_FAIL;
}

interface SetDeleteDocumentType {
  type: typeof DELETE_DOCUMENT;
  payload: SetDeleteDocument[];
}

// Admin All Appointment List Interfaces
interface AdminAllAppointmentActionType {
  type: typeof APPOINTMENT_PATIENT_LIST;
  payload: AdminAllAppointmentList[];
  appointmentPageCount: AllAppointmentPageCount;
}

export interface AdminAllAppointmentListLoading {
  type: typeof APPOINTMENT_LIST_LOADING;
}
export interface AdminAllAppointmentListSuccess {
  type: typeof APPOINTMENT_LIST_SUCCESS;
  payload: AdminAllAppointmentList[];
  appointmentPageCount: AllAppointmentPageCount;
}

export interface AdminAllAppointmentListFail {
  type: typeof APPOINTMENT_LIST_FAIL;
}
export interface GetAllAppointmentListStateType {
  allAppointmentListValue: AdminAllAppointmentList[];
  loading: boolean;
  appointmentPageCount: AllAppointmentPageCount;
}

// Admin All Patient Team List Interfaces
interface AdminAllPatientTeamListActionType {
  type: typeof ALL_PATIENT_TEAM_LIST;
  payload: AdminPatientTeamList[];
  patientListPageCount: PatientTeamListPageCount;
}

export interface AdminAllPatientTeamListLoading {
  type: typeof ALL_PATIENT_TEAM_LOADING;
}
export interface AdminAllPatientTeamListSuccess {
  type: typeof ALL_PATIENT_TEAM_SUCCESS;
  payload: AdminPatientTeamList[];
  patientListPageCount: PatientTeamListPageCount;
  patientTeamCurrentPageCount: patientTeamCurrentPageCount;
}

export interface AdminAllPatientTeamListFail {
  type: typeof ALL_PATIENT_TEAM_FAIL;
}
export interface GetAllPatientListStateType {
  allPatientTeamListValue: AdminPatientTeamList[];
  loading: boolean;
  patientListPageCount: PatientTeamListPageCount;
  patientTeamCurrentPageCount: patientTeamCurrentPageCount;
}
export interface GetAllPaymentListStateType {
  allPaymentListValue: AdminAllPaymentList[];
  loading: boolean;
  PageCount: PaymentPageCount;
}

export interface AdminPatientQuerytListStateType {
  adminPatientQueryListValue: AdminPatientQueryList[];
  loading: boolean;
}

export interface AdminDoctorQueryListLoading {
  type: typeof DOCTOR_QUERY_LOADING;
}
export interface AdminDoctorQueryListSuccess {
  type: typeof DOCTOR_QUERY_SUCCESS;
  payload: AdminDoctorQueryList[];
}

export interface AdminDoctorQueryListFail {
  type: typeof DOCTOR_QUERY_FAIL;
}
export interface AdminDoctorQuerytListStateType {
  adminDoctorQueryListValue: AdminDoctorQueryList[];
  loading: boolean;
}

// Get Active Corporate Package List
export interface GetActiveCorporateListStateType {
  activeCorporateListValue: ActiveCorporateList[];
  loading: boolean;
}
interface ActiveCorporateListActionType {
  type: typeof ACTIVE_CORPORATE_LIST;
  payload: ActiveCorporateList[];
}
export interface ActiveCorporateListLoading {
  type: typeof ACTIVE_CORPORATE_LIST_LOADING;
}
export interface ActiveCorporateListSuccess {
  type: typeof ACTIVE_CORPORATE_LIST_SUCCESS;
  payload: ActiveCorporateList[];
}
export interface ActiveCorporateListFail {
  type: typeof ACTIVE_CORPORATE_LIST_FAIL;
}

// Get Inactive Corporate Package List
export interface GetInctiveCorporateListStateType {
  inactiveCorporateListValue: InactiveCorporateList[];
  loading: boolean;
}
interface InactiveCorporateListActionType {
  type: typeof INACTIVE_CORPORATE_LIST;
  payload: InactiveCorporateList[];
}
export interface InactiveCorporateListLoading {
  type: typeof INACTIVE_CORPORATE_LIST_LOADING;
}
export interface InactiveCorporateListSuccess {
  type: typeof INACTIVE_CORPORATE_LIST_SUCCESS;
  payload: InactiveCorporateList[];
}
export interface InactiveCorporateListFail {
  type: typeof INACTIVE_CORPORATE_LIST_FAIL;
}

// Get Active Corporate Patient list
export interface GetActiveCorporatePatientListStateType {
  activeCorporatePatientListValue: ActiveCorporatePatientList[];
  totalNoOfPatientTeam: any;
  loading: boolean;
}
interface ActiveCorporatePatientListActionType {
  type: typeof ACTIVE_CORPORATE_PATIENT_LIST;
  payload: ActiveCorporatePatientList[];
  totalNoOfPatientTeam: any;
}
export interface ActiveCorporatePatientListLoading {
  type: typeof ACTIVE_CORPORATE_PATIENT_LIST_LOADING;
}
export interface ActiveCorporatePatientListSuccess {
  type: typeof ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS;
  payload: ActiveCorporatePatientList[];
  totalNoOfPatientTeam: any;
}
export interface ActiveCorporatePatientListFail {
  type: typeof ACTIVE_CORPORATE_PATIENT_LIST_FAIL;
}

// Get Inactive Corporate Patient list
export interface GetInactiveCorporatePatientListStateType {
  inActiveCorporatePatientListValue: InActiveCorporatePatientList[];
  loading: boolean;
  totalNoOfPatientTeam: any;
}
interface InactiveCorporatePatientListActionType {
  type: typeof INACTIVE_CORPORATE_PATIENT_LIST;
  payload: InActiveCorporatePatientList[];
  totalNoOfPatientTeam: any;
}
export interface InactiveCorporatePatientListLoading {
  type: typeof INACTIVE_CORPORATE_PATIENT_LIST_LOADING;
}
export interface InactiveCorporatePatientListSuccess {
  type: typeof INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS;
  payload: InActiveCorporatePatientList[];
  totalNoOfPatientTeam: any;
}
export interface InactiveCorporatePatientListFail {
  type: typeof INACTIVE_CORPORATE_PATIENT_LIST_FAIL;
}

export type DoctorGetActionTypes = PatientPointDataActionType;
export type DoctorOnboardingRequestActionTypes =
  | GetOnboardingDoctorRequestActionType
  | DoctorRequestListLoading
  | DoctorRequestListSuccess
  | DoctorRequestListFail;
export type DoctorOnboardingRejectActionTypes =
  | GetOnboardingDoctorRejectActionType
  | DoctorRejectListLoading
  | DoctorRejectListtSuccess
  | DoctorRejectListtFail;
export type DoctorOnboardingIncompleteActionTypes =
  | GetOnboardingDoctorIncompleteActionType
  | DoctorIncompleteListLoading
  | DoctorIncompleteListtSuccess
  | DoctorIncompleteListtFail;
export type DoctorAdminGetActionTypes =
  | DoctorAdminGetActionType
  | PatientPointDataLoading
  | PatientPointDataSuccess
  | PatientPointDataFail;
export type PatientGetActionTypes =
  | GetPatientActionType
  | PatientListLoading
  | PatientListSuccess
  | PatientListFail;
export type AdminGetTaskListActionTypes =
  | AdminGetTaskListActionType
  | TaskListLoading
  | TaskListSuccess
  | TaskListFail;
export type AdminGetOrganizationListActionTypes =
  | AdminGetOrganizationListActionType
  | OrganizationListLoading
  | OrganizationListSuccess
  | OrganizationListFail;
export type AdminGetAllTaskListActionTypes =
  | AdminGetAllTaskListActionType
  | AllTaskListLoading
  | AllTaskListSuccess
  | AllTaskListFail;
export type AdminGetAllInactiveTaskListActionTypes =
  | AdminGetAllInactiveTaskListActionType
  | AllInactiveTaskListLoading
  | AllInactiveTaskListSuccess
  | AllInactiveTaskListFail;
export type AdminGetQuestionListActionTypes =
  | AdminGetQuestionListActionType
  | QuestionListLoading
  | QuestionListSuccess
  | QuestionListFail;
export type SetVerifyActionTypes =
  | SetVerifyActionType
  | AdminGetQuestionListActionType
  | QuestionListLoading
  | QuestionListSuccess
  | QuestionListFail;
export type CaseGetActionTypes =
  | GetCaseActionType
  | CaseListLoading
  | CaseListSuccess
  | CaseListFail;
export type AllCaseGetActionTypes =
  | GetAllCaseActionType
  | AllCaseListLoading
  | AllCaseListSuccess
  | AllCaseListFail;
export type HealthcampPatientGetActionTypes =
  | GetHealthcampPatientActionType
  | HealthcampPatientListLoading
  | HealthcampPatientListSuccess
  | HealthcampPatientListFail;
export type OrganizationPatientGetActionTypes =
  | GetOrganizationPatientActionType
  | OrganizationPatientListLoading
  | OrganizationPatientListSuccess
  | OrganizationPatientListFail;
export type QuestionGetActionTypes =
  | GetQuestionActionType
  | PatientQuestionListLoading
  | PatientQuestionListSuccess
  | PatientQuestionListFail;
export type ChiefComplaintGetActionTypes =
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type AddChiefComplaintTypes =
  | SetAddChiefComplaintType
  | CCAddLoading
  | CCAddSuccess
  | CCAddFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type DeleteDocumentTypes =
  | SetDeleteDocumentType
  | DeleteDocumentLoading
  | DeleteDocumentSuccess
  | DeleteDocumentFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type AdminAllAppointmentGetActionTypes =
  | AdminAllAppointmentActionType
  | AdminAllAppointmentListLoading
  | AdminAllAppointmentListSuccess
  | AdminAllAppointmentListFail;
export type AdminGetPatientTeamListActionTypes =
  | AdminAllPatientTeamListActionType
  | AdminAllPatientTeamListLoading
  | AdminAllPatientTeamListSuccess
  | AdminAllPatientTeamListFail;

export type ActiveCorporatePackagesTypes =
  | ActiveCorporateListActionType
  | ActiveCorporateListLoading
  | ActiveCorporateListSuccess
  | ActiveCorporateListFail;
export type InactiveCorporatePackagesTypes =
  | InactiveCorporateListActionType
  | InactiveCorporateListLoading
  | InactiveCorporateListSuccess
  | InactiveCorporateListFail;

export type ActiveCorporatePatientTypes =
  | ActiveCorporatePatientListActionType
  | ActiveCorporatePatientListLoading
  | ActiveCorporatePatientListSuccess
  | ActiveCorporatePatientListFail;
export type InactiveCorporatePatientTypes =
  | InactiveCorporatePatientListActionType
  | InactiveCorporatePatientListLoading
  | InactiveCorporatePatientListSuccess
  | InactiveCorporatePatientListFail;

export const TASK_CLOSE_LOADING = "TASK_CLOSE_LOADING";
export const TASK_CLOSE_SUCCESS = "TASK_CLOSE_SUCCESS";
export const TASK_CLOSE_FAIL = "TASK_CLOSE_FAIL_FAIL";
export const TASK_CLOSE_STATUS = "TASK_CLOSE_STATUS";

export interface TaskCloseStateType {
  loading: boolean;
  response: TaskCloseResponse;
}
export interface TaskCloseLoading {
  type: typeof TASK_CLOSE_LOADING;
}
export interface TaskCloseSuccess {
  type: typeof TASK_CLOSE_SUCCESS;
  payload: TaskCloseResponse;
}
export interface TaskCloseFail {
  type: typeof TASK_CLOSE_FAIL;
  payload: TaskCloseResponse;
}

interface TaskCloseType {
  type: typeof TASK_CLOSE_STATUS;
  payload: TaskCloseResponse;
}
export type TaskCloseTypes = TaskCloseType | TaskCloseLoading | TaskCloseSuccess | TaskCloseFail;

export const ACCEPT_REJECT_APPOINTMENT_LOADING = "ACCEPT_REJECT_APPOINTMENT_LOADING";
export const ACCEPT_REJECT_APPOINTMENT_SUCCESS = "ACCEPT_REJECT_APPOINTMENT_SUCCESS";
export const ACCEPT_REJECT_APPOINTMENT_FAIL = "ACCEPT_REJECT_APPOINTMENT_FAIL";
export const ACCEPT_REJECT_APPOINTMENT_STATUS = "ACCEPT_REJECT_APPOINTMENT_STATUS";

export interface AcceptRejectAppointmentStateType {
  loading: boolean;
  response: AcceptRejectAppointmentResponse;
}
export interface AcceptRejectAppointmentLoading {
  type: typeof ACCEPT_REJECT_APPOINTMENT_LOADING;
}
export interface AcceptRejectAppointmentSuccess {
  type: typeof ACCEPT_REJECT_APPOINTMENT_SUCCESS;
  payload: AcceptRejectAppointmentResponse;
}
export interface AcceptRejectAppointmentFail {
  type: typeof ACCEPT_REJECT_APPOINTMENT_FAIL;
  payload: AcceptRejectAppointmentResponse;
}

interface AcceptRejectAppointmentType {
  type: typeof ACCEPT_REJECT_APPOINTMENT_STATUS;
  payload: AcceptRejectAppointmentResponse;
}
export type AcceptRejectAppointmentTypes =
  | AcceptRejectAppointmentType
  | AcceptRejectAppointmentLoading
  | AcceptRejectAppointmentSuccess
  | AcceptRejectAppointmentFail;
