import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddPatientDataActionTypes } from "./ActionTypes";
import { addPatientDataBody, AddPatientDataRes } from "./Model";
import {
  addPatientDataUpdateAPIResClearAction,
  addPatientDataErrorAction,
  addPatientDataLoadingAction,
  addPatientDataSuccessAction,
  addPatientDataUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as AddPatientDataRes;
export const addPatientData = (payload: addPatientDataBody) => {
  return function (dispatch: Dispatch<AddPatientDataActionTypes>) {
    dispatch(addPatientDataLoadingAction(true));
    axios
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/add_patientDetails`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientDataLoadingAction(false));
        dispatch(
          addPatientDataSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient Data has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addPatientDataLoadingAction(false));
        dispatch(
          addPatientDataErrorAction(
            {} as AddPatientDataRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddPatientDataAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientDataActionTypes>) {
    dispatch(addPatientDataUpdateAPIMsgAction(apiRes as AddPatientDataRes, "", "", 0));
  };
};

export const clearAddPatientDataAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientDataActionTypes>) {
    dispatch(addPatientDataUpdateAPIResClearAction({} as AddPatientDataRes, "", "", 0));
  };
};
