import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorListValueAdmin } from "redux/effects/ApiCall";
import { AppState } from "redux/store/Store";
import { useParams } from "react-router-dom";
import { AddDoctorInHealthCampBody } from "redux/HealthcampStaffList/AddDoctorInHealthCamp/Model";
import {
  addDoctorInHealthCamp,
  clearAddDoctorInHealthCampAPIRes,
} from "redux/HealthcampStaffList/AddDoctorInHealthCamp/API";
import { getDoctorListInHealthCamp } from "redux/HealthcampStaffList/DoctorListInHealthCamp/API";
import { GetDoctorListInHealthCampBody } from "redux/HealthcampStaffList/DoctorListInHealthCamp/Model";
import MWExceptionList from "component/MWExceptionList";
import { DataGrid } from "@mui/x-data-grid";
import { AllDoctorListInHealthCamp } from "./DoctorList";
import { DoctorListInHealthCampTableColumn } from "./DoctorListColumn";
import { ModifiedDrListInHealthCamp } from "./Model";
import MWSnackbar from "component/MWSnackbar";
interface Props {
  healthCampDoctorLoader: (value: boolean) => void;
}
export function HealthCampDoctor({ healthCampDoctorLoader }: Props) {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };

  useEffect(() => {
    dispatch(getDoctorListValueAdmin());
    dispatch(
      getDoctorListInHealthCamp({
        healthcampId: healthcampid,
        pageNo: page,
      } as GetDoctorListInHealthCampBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const doctorListRes = useSelector((state: AppState) => state.doctorListValue);

  const [doctorList, setDoctorList] = useState([] as any[]);
  const [selectedDoctor, setSelectedDoctor] = useState<any | null>(null);
  useEffect(() => {
    if (doctorListRes?.doctorListValue !== undefined) {
      setDoctorList(
        doctorListRes?.doctorListValue.map((element: any) => {
          return {
            value: element.value,
            label: element.label,
          };
        }),
      );
    }
  }, [doctorListRes]);

  const addDrInHealthCamp = () => {
    if (selectedDoctor !== null) {
      dispatch(
        addDoctorInHealthCamp({
          healthcampId: healthcampid,
          doctorId: selectedDoctor?.value,
        } as AddDoctorInHealthCampBody),
      );
    }
  };
  const addDoctorInHealthCampRes = useSelector((state: AppState) => state.addDoctorInHealthCampRes);

  const [addDrInCampAPIMsg, setAddDrInCampAPIMsg] = useState("");
  const [addDrInCampAPIMsgType, setAddDrInCampAPIMsgType] = useState<AlertColor>("success");
  useEffect(() => {
    healthCampDoctorLoader(addDoctorInHealthCampRes?.loading);
    if (addDoctorInHealthCampRes?.successMsg !== "") {
      setAddDrInCampAPIMsgType("success");
      setAddDrInCampAPIMsg(addDoctorInHealthCampRes?.successMsg);
      dispatch(
        getDoctorListInHealthCamp({
          healthcampId: healthcampid,
          pageNo: page,
        } as GetDoctorListInHealthCampBody),
      );
    }
    if (addDoctorInHealthCampRes?.errorMsg !== "") {
      setAddDrInCampAPIMsgType("error");
      setAddDrInCampAPIMsg(addDoctorInHealthCampRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDoctorInHealthCampRes]);

  const closeAddDrInHealthCamp = (passedVal: boolean) => {
    passedVal && setAddDrInCampAPIMsg("");
    dispatch(clearAddDoctorInHealthCampAPIRes());
  };

  const getDoctorListInHealthCampRes = useSelector(
    (state: AppState) => state.getDoctorListInHealthCampRes,
  );
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    healthCampDoctorLoader(getDoctorListInHealthCampRes?.loading);
    getDoctorListInHealthCampRes?.getDoctorListInHealthCampList?.numberOfPages !== undefined
      ? setPageListCount(
          String(getDoctorListInHealthCampRes?.getDoctorListInHealthCampList?.numberOfPages),
        )
      : setPageListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorListInHealthCampRes]);

  const [doctorListInHealthCamp, setDoctorListInHealthCamp] = useState(
    [] as ModifiedDrListInHealthCamp[],
  );
  // Modify dataset for subscription table
  let allDoctorList = AllDoctorListInHealthCamp();
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    allDoctorList.length > 0
      ? setDoctorListInHealthCamp(allDoctorList)
      : setDoctorListInHealthCamp([] as ModifiedDrListInHealthCamp[]);
  }, [allDoctorList]);

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>Doctor List</Grid>
                    <Grid item>
                      <Chip
                        variant="outlined"
                        label={
                          getDoctorListInHealthCampRes?.getDoctorListInHealthCampList
                            ?.DoctorList !== undefined
                            ? String(
                                getDoctorListInHealthCampRes?.getDoctorListInHealthCampList
                                  ?.DoctorList.length,
                              )
                            : "0"
                        }
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          dispatch(
                            getDoctorListInHealthCamp({
                              healthcampId: healthcampid,
                              pageNo: page,
                            } as GetDoctorListInHealthCampBody),
                          );
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Autocomplete
                        value={selectedDoctor}
                        options={doctorList}
                        onChange={(_event: any, newValue: any) => {
                          setSelectedDoctor(newValue);
                        }}
                        disabled={addDoctorInHealthCampRes?.loading}
                        getOptionLabel={(option) => `${option.label}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select doctor for health camp"
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => addDrInHealthCamp()}
                        disabled={addDoctorInHealthCampRes?.loading || selectedDoctor === null}
                        sx={{ textTransform: "none" }}
                      >
                        Add Doctor into Health Camp
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">Page: {page}</Typography>
                    </Grid>
                    <Grid item>
                      <Pagination
                        color="primary"
                        count={Number(pageListCount) || 0}
                        page={page}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          }
        />
        <CardContent>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {getDoctorListInHealthCampRes?.getDoctorListInHealthCampList?.DoctorList !==
              undefined ? (
                getDoctorListInHealthCampRes?.getDoctorListInHealthCampList?.DoctorList.length >
                0 ? (
                  <Box sx={{ height: "46vh" }}>
                    <DataGrid
                      columns={DoctorListInHealthCampTableColumn()}
                      rows={doctorListInHealthCamp}
                      rowThreshold={0}
                      experimentalFeatures={{ newEditingApi: true }}
                      hideFooter
                      getRowHeight={() => "auto"}
                      density="comfortable"
                    />
                  </Box>
                ) : (
                  <MWExceptionList />
                )
              ) : (
                <MWExceptionList />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {addDrInCampAPIMsg !== "" && (
        <MWSnackbar
          msg={addDrInCampAPIMsg}
          type={addDrInCampAPIMsgType}
          alertClose={closeAddDrInHealthCamp}
        />
      )}
    </Box>
  );
}
