import { AddSummaryResponse, AddWorkScheduleResponse, AdminAppointmentList, ContactDetail, DoctorDetailsData, Message, Status, WeekdayListData, WorkSchedule, WorkScheduleDeleteResponse } from "./Model";

export const APPOINTMENT_LIST = 'APPOINTMENT_PATIENT_LIST';
export const APPOINTMENT_LIST_LOADING = "APPOINTMENT_LIST_LOADING";
export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS";
export const APPOINTMENT_LIST_FAIL = "QUESTION_LIST_FAIL";

// Admin All Appointment List Interfaces
interface AdminAppointmentActionType {
    type: typeof APPOINTMENT_LIST;
    payload: AdminAppointmentList[];
}

export interface AdminAppointmentListLoading {
    type: typeof APPOINTMENT_LIST_LOADING;
}
export interface AdminAppointmentListSuccess {
    type: typeof APPOINTMENT_LIST_SUCCESS;
    payload: AdminAppointmentList[];
}

export interface AdminAppointmentListFail {
    type: typeof APPOINTMENT_LIST_FAIL;
}
export interface GetAppointmentListStateType {
    appointmentValue: AdminAppointmentList[];
    loading: boolean;
}

export type AdminAppointmentGetActionTypes = AdminAppointmentActionType | AdminAppointmentListLoading | AdminAppointmentListSuccess | AdminAppointmentListFail;

export const ADD_SUMMARY_LIST = 'ADD_SUMMARY_LIST';
export const ADD_SUMMARY_LOADING = "ADD_SUMMARY_LOADING";
export const ADD_SUMMARY_SUCCESS = "ADD_SUMMARY_SUCCESS";
export const ADD_SUMMARY_FAIL = "ADD_SUMMARY_FAIL";

// Admin All Appointment List Interfaces
interface AddSummaryActionType {
    type: typeof ADD_SUMMARY_LIST;
    payload: AddSummaryResponse;
}

export interface AddSummaryListLoading {
    type: typeof ADD_SUMMARY_LOADING;
}
export interface AddSummaryListSuccess {
    type: typeof ADD_SUMMARY_SUCCESS;
    payload: AddSummaryResponse;
}

export interface AddSummaryListFail {
    type: typeof ADD_SUMMARY_FAIL;
}
export interface AddSummaryStateType {
    response: AddSummaryResponse;
    loading: boolean;
}

export type AddSummaryActionTypes = AddSummaryActionType | AddSummaryListLoading | AddSummaryListSuccess | AddSummaryListFail;


export const CONTACT_DETAILS_LIST = 'CONTACT_DETAILS_LIST';
export const CONTACT_DETAILS_LOADING = "CONTACT_DETAILS_LOADING";
export const CONTACT_DETAILS_SUCCESS = "CONTACT_DETAILS_SUCCESS";
export const CONTACT_DETAILS_FAIL = "CONTACT_DETAILS_FAIL";

// Admin All Appointment List Interfaces
interface ContactDetailActionType {
    type: typeof CONTACT_DETAILS_LIST;
    payload: ContactDetail;
}

export interface ContactDetailListLoading {
    type: typeof CONTACT_DETAILS_LOADING;
}
export interface ContactDetailListSuccess {
    type: typeof CONTACT_DETAILS_SUCCESS;
    payload: ContactDetail;
}

export interface ContactDetailListFail {
    type: typeof CONTACT_DETAILS_FAIL;
}
export interface ContactDetailsStateType {
    contactDetailValue: ContactDetail;
    loading: boolean;
}

export type ContactDetailsActionTypes = ContactDetailActionType | ContactDetailListLoading | ContactDetailListSuccess | ContactDetailListFail;


export const WORK_SCHEDULE_LIST = 'WORK_SCHEDULE_LIST';
export const WORK_SCHEDULE_LOADING = "WORK_SCHEDULE_LOADING";
export const WORK_SCHEDULE_SUCCESS = "WORK_SCHEDULE_SUCCESS";
export const WORK_SCHEDULE_FAIL = "WORK_SCHEDULE_FAIL";

// Admin All Appointment List Interfaces
interface WorkScheduleActionType {
    type: typeof WORK_SCHEDULE_LIST;
    payload: WorkSchedule[];
}

export interface WorkScheduleListLoading {
    type: typeof WORK_SCHEDULE_LOADING;
}
export interface WorkScheduleListSuccess {
    type: typeof WORK_SCHEDULE_SUCCESS;
    payload: WorkSchedule[];
}

export interface WorkScheduleListFail {
    type: typeof WORK_SCHEDULE_FAIL;
}
export interface WorkScheduleStateType {
    workscheduleValue: WorkSchedule[];
    loading: boolean;
}

export type WorkScheduleActionTypes = WorkScheduleActionType | WorkScheduleListLoading | WorkScheduleListSuccess | WorkScheduleListFail;


export const DELETE_WORK_SCHEDULE_LIST = 'DELETE_WORK_SCHEDULE_LIST';
export const DELETE_WORK_SCHEDULE_LOADING = "DELETE_WORK_SCHEDULE_LOADING";
export const DELETE_WORK_SCHEDULE_SUCCESS = "DELETE_WORK_SCHEDULE_SUCCESS";
export const DELETE_WORK_SCHEDULE_FAIL = "DELETE_WORK_SCHEDULE_FAIL";

// Admin All Appointment List Interfaces
interface DeleteWorkScheduleActionType {
    type: typeof DELETE_WORK_SCHEDULE_LIST;
    payload: WorkScheduleDeleteResponse;
}

export interface DeleteWorkScheduleListLoading {
    type: typeof DELETE_WORK_SCHEDULE_LOADING;
}
export interface DeleteWorkScheduleListSuccess {
    type: typeof DELETE_WORK_SCHEDULE_SUCCESS;
    payload: WorkScheduleDeleteResponse;
}

export interface DeleteWorkScheduleListFail {
    type: typeof DELETE_WORK_SCHEDULE_FAIL;
}
export interface DeleteWorkScheduleStateType {
    deleteResponse: WorkScheduleDeleteResponse;
    loading: boolean;
}

export type DeleteWorkScheduleActionTypes = DeleteWorkScheduleActionType | DeleteWorkScheduleListLoading | DeleteWorkScheduleListSuccess | DeleteWorkScheduleListFail;

// weekday list
export const WEEKDAY_LIST_LOADING = "WEEKDAY_LIST_LOADING";
export const WEEKDAY_LIST_SUCCESS = "WEEKDAY_LIST_SUCCESS";
export const WEEKDAY_LIST_FAIL = "WEEKDAY_LIST_FAIL";
export const WEEKDAY_LIST = "WEEKDAY_LIST";


export interface WeekdayListStateType {
  weekdaysListValue: WeekdayListData[];
  loading: boolean;
  message: Message;
  status: Status;
}

export interface WeekDayListLoading {
  type: typeof WEEKDAY_LIST_LOADING;
}
export interface WeekDayListSuccess {
  type: typeof WEEKDAY_LIST_SUCCESS;
  payload: WeekdayListData[];
  message: Message;
  status: Status;
}
export interface WeekDayListFail {
  type: typeof WEEKDAY_LIST_FAIL;
  message: Message;
  status: Status;
}
interface WeekDayListActionType {
  type: typeof WEEKDAY_LIST;
  payload: WeekdayListData[];
  message: Message;
  status: Status;
}
export type WeekDayListActionTypes = WeekDayListActionType | WeekDayListLoading | WeekDayListSuccess | WeekDayListFail;

// add work schedule 
export const ADD_WORKSCHEDULE_LOADING = "ADD_WORKSCHEDULE_LOADING";
export const ADD_WORKSCHEDULE_SUCCESS = "ADD_WORKSCHEDULE_SUCCESS";
export const ADD_WORKSCHEDULE_FAIL = "ADD_WORKSCHEDULE_FAIL";
export const ADD_WORKSCHEDULE_STATUS = 'ADD_WORKSCHEDULE_STATUS';

export interface AddWorkScheduleStateType {
  loading: boolean;
  response: AddWorkScheduleResponse;
}
export interface AddWorkScheduleLoading {
  type: typeof ADD_WORKSCHEDULE_LOADING;
}
export interface AddWorkScheduleSuccess {
  type: typeof ADD_WORKSCHEDULE_SUCCESS;
  payload: AddWorkScheduleResponse;
}
export interface AddWorkScheduleFail {
  type: typeof ADD_WORKSCHEDULE_FAIL;
  payload: AddWorkScheduleResponse;
}

interface AddWorkScheduleType {
  type: typeof ADD_WORKSCHEDULE_STATUS;
  payload: AddWorkScheduleResponse;
}
export type AddWorkScheduleActionTypes = AddWorkScheduleType | AddWorkScheduleLoading | AddWorkScheduleSuccess | AddWorkScheduleFail;

// doctor details 
export const DOCTOR_DETAILS_LOADING = "DOCTOR_DETAILS_LOADING";
export const DOCTOR_DETAILS_SUCCESS = "DOCTOR_DETAILS_SUCCESS";
export const DOCTOR_DETAILS_FAIL = "DOCTOR_DETAILS_FAIL";
export const DOCTOR_DETAILS_LIST = "DOCTOR_DETAILS_LIST";


export interface DoctorDetailsStateType {
  doctorDetailsValue: DoctorDetailsData;
  loading: boolean;
  message: Message;
  status: Status;
}

export interface DoctorDetailsLoading {
  type: typeof DOCTOR_DETAILS_LOADING;
}
export interface DoctorDetailsSuccess {
  type: typeof DOCTOR_DETAILS_SUCCESS;
  payload: DoctorDetailsData;
  message: Message;
  status: Status;
}
export interface DoctorDetailsFail {
  type: typeof DOCTOR_DETAILS_FAIL;
  message: Message;
  status: Status;
}
interface DoctorDetailsActionType {
  type: typeof DOCTOR_DETAILS_LIST;
  payload: DoctorDetailsData;
  message: Message;
  status: Status;
}
export type DoctorDetailsActionTypes = DoctorDetailsActionType | DoctorDetailsLoading | DoctorDetailsSuccess | DoctorDetailsFail;
