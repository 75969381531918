import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetVitalCollectionActiveActionTypes } from "./ActionTypes";
import { GetVitalCollectionActiveBody, GetVitalCollectionActiveRes } from "./Model";
import {
  getVitalCollectionActiveAPIResClearAction,
  getVitalCollectionActiveErrorAction,
  getVitalCollectionActiveLoadingAction,
  getVitalCollectionActiveSuccessAction,
  getVitalCollectionActiveUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetVitalCollectionActiveRes;
export const getVitalCollectionActiveApi = (
  payload: GetVitalCollectionActiveBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetVitalCollectionActiveActionTypes>) {
    dispatch(getVitalCollectionActiveLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampSessionVitalsCollection/vitals_collection_request_list?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getVitalCollectionActiveLoadingAction(false));
        dispatch(
          getVitalCollectionActiveSuccessAction(
            res.data !== undefined ? res.data : ({} as GetVitalCollectionActiveRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient vital collection list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getVitalCollectionActiveLoadingAction(false));
        dispatch(
          getVitalCollectionActiveErrorAction(
            {} as GetVitalCollectionActiveRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetVitalCollectionActiveAPIResMsg = () => {
  return function (dispatch: Dispatch<GetVitalCollectionActiveActionTypes>) {
    dispatch(
      getVitalCollectionActiveUpdateAPIMsgAction(
        apiRes as GetVitalCollectionActiveRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetVitalCollectionActiveAPIRes = () => {
  return function (dispatch: Dispatch<GetVitalCollectionActiveActionTypes>) {
    dispatch(
      getVitalCollectionActiveAPIResClearAction({} as GetVitalCollectionActiveRes, "", "", 0),
    );
  };
};
