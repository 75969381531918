import { GetDataCurationProblemTypeListResponse } from "./Model";
export const GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING = "GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING";
export const GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS = "GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS";
export const GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL = "GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL";
export const GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG = "GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG";
export const GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES = "GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES";

export interface GetDataCurationProblemTypeListState {
  GetDataCurationProblemTypeListResponse: GetDataCurationProblemTypeListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDataCurationProblemTypeListLoading {
  type: typeof GET_DATA_CURATION_PROBLEM_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDataCurationProblemTypeListSuccess {
  type: typeof GET_DATA_CURATION_PROBLEM_TYPE_LIST_SUCCESS;
  payload: GetDataCurationProblemTypeListResponse;
  successMsg: string;
}
export interface GetDataCurationProblemTypeListFail {
  type: typeof GET_DATA_CURATION_PROBLEM_TYPE_LIST_FAIL;
  payload: GetDataCurationProblemTypeListResponse;
  errorMsg: string;
  status: number;
}
export interface GetDataCurationProblemTypeListUpdateAPIMsg {
  type: typeof GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_MSG;
  payload: GetDataCurationProblemTypeListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDataCurationProblemTypeListUpdateAPIRes {
  type: typeof GET_DATA_CURATION_PROBLEM_TYPE_LIST_UPDATE_API_RES;
  payload: GetDataCurationProblemTypeListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDataCurationProblemTypeListActionTypes =
  | GetDataCurationProblemTypeListLoading
  | GetDataCurationProblemTypeListSuccess
  | GetDataCurationProblemTypeListFail
  | GetDataCurationProblemTypeListUpdateAPIMsg
  | GetDataCurationProblemTypeListUpdateAPIRes;
