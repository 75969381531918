import {
    HEALTHCAMP_REGISTRATION_LIST_FAIL,
    HEALTHCAMP_REGISTRATION_LIST_LOADING,
    HEALTHCAMP_REGISTRATION_LIST_SUCCESS,
    HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG,
    HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES,
    GetHealthcampRegistrationListActionTypes,
  } from "./ActionTypes";
  import { GetHealthcampRequestListRes } from "./Model";
  
  export const getHealthcampRegistrationListLoadingAction = (
    loading: boolean,
  ): GetHealthcampRegistrationListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getHealthcampRegistrationListSuccessAction = (
    getHealthCampRegistrationListresponse: GetHealthcampRequestListRes,
    successMsg: string,
  ): GetHealthcampRegistrationListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_LIST_SUCCESS,
      payload: getHealthCampRegistrationListresponse,
      successMsg: successMsg,
    };
  };
  
  export const getHealthcampRegistrationListErrorAction = (
    getHealthCampRegistrationListresponse: GetHealthcampRequestListRes,
    errMsg: string,
    status: number,
  ): GetHealthcampRegistrationListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_LIST_FAIL,
      payload: getHealthCampRegistrationListresponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getHealthcampRegistrationListUpdateAPIMsgAction = (
    getHealthCampRegistrationListresponse: GetHealthcampRequestListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetHealthcampRegistrationListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG,
      payload: getHealthCampRegistrationListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getHealthcampRegistrationListAPIResClearAction = (
    getHealthCampRegistrationListresponse: GetHealthcampRequestListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetHealthcampRegistrationListActionTypes => {
    return {
      type: HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES,
      payload: getHealthCampRegistrationListresponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  