import { PageCount, GetCompetitionPatientListDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_LIST_BY_COMPETITION_ID_LOADING = "PATIENT_LIST_BY_COMPETITION_ID_LOADING";
export const PATIENT_LIST_BY_COMPETITION_ID_SUCCESS = "PATIENT_LIST_BY_COMPETITION_ID_SUCCESS";
export const PATIENT_LIST_BY_COMPETITION_ID_FAIL = "PATIENT_LIST_BY_COMPETITION_ID_FAIL";
export const PATIENT_LIST_BY_COMPETITION_ID_DATA = "PATIENT_LIST_BY_COMPETITION_ID_DATA";
export const COMPETITION_PATIENT_LIST_API_MSG = "COMPETITION_PATIENT_LIST_API_MSG";
export const COMPETITION_PATIENT_LIST_API_RES = "COMPETITION_PATIENT_LIST_API_RES";

export interface PatientListOfCompetitionIdDetailsState {
  PatientListOfCompetitionIdRes: GetCompetitionPatientListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientListOfCompetitionIdDetailsLoading {
  type: typeof PATIENT_LIST_BY_COMPETITION_ID_LOADING;
  loading: boolean;
}
export interface PatientListOfCompetitionIdDetailsSuccess {
  type: typeof PATIENT_LIST_BY_COMPETITION_ID_SUCCESS;
  payload: GetCompetitionPatientListDetails;
}
export interface PatientListOfCompetitionIdDetailsFail {
  type: typeof PATIENT_LIST_BY_COMPETITION_ID_FAIL;
  payload: GetCompetitionPatientListDetails;
  message: string;
  status: number;
}
export interface PatientListOfCompetitionIdDetailsAPIMsg {
  type: typeof COMPETITION_PATIENT_LIST_API_MSG;
  payload: GetCompetitionPatientListDetails;
  message: string;
  status: number;
}
export interface PatientListOfCompetitionIdRes {
  type: typeof COMPETITION_PATIENT_LIST_API_RES;
  payload: GetCompetitionPatientListDetails;
  message: string;
  status: number;
}
interface PatientListOfCompetitionIdDetailsDataAction {
  type: typeof PATIENT_LIST_BY_COMPETITION_ID_DATA;
  payload: GetCompetitionPatientListDetails;
  pageCount: PageCount;
}

export type GetCompetitionPatientListActionTypes =
  | PatientListOfCompetitionIdDetailsDataAction
  | PatientListOfCompetitionIdDetailsLoading
  | PatientListOfCompetitionIdDetailsSuccess
  | PatientListOfCompetitionIdDetailsFail
  | PatientListOfCompetitionIdDetailsAPIMsg
  | PatientListOfCompetitionIdRes;
