import { PageCount, PatientSearchDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_SEARCH_LOADING = "PATIENT_SEARCH_LOADING";
export const PATIENT_SEARCH_SUCCESS = "PATIENT_SEARCH_SUCCESS";
export const PATIENT_SEARCH_FAIL = "PATIENT_SEARCH_FAIL";
export const PATIENT_SEARCH_DATA = "PATIENT_SEARCH_DATA";
export const PATIENT_SEARCH_UPDATE_API_MSG = "PATIENT_SEARCH_UPDATE_API_MSG";
export const PATIENT_SEARCH_UPDATE_API_RES = "PATIENT_SEARCH_UPDATE_API_RES";

export interface PatientSearchDetailsState {
  patientSearchRes: PatientSearchDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientPointDataLoading {
  type: typeof PATIENT_SEARCH_LOADING;
  loading: boolean;
}
export interface PatientPointDataSuccess {
  type: typeof PATIENT_SEARCH_SUCCESS;
  payload: PatientSearchDetails;
}
export interface PatientPointDataFail {
  type: typeof PATIENT_SEARCH_FAIL;
  payload: PatientSearchDetails;
  message: string;
  status: number;
}
export interface PatientPointDataUpdateAPIMsg {
  type: typeof PATIENT_SEARCH_UPDATE_API_MSG;
  payload: PatientSearchDetails;
  message: string;
  status: number;
}
export interface PatientPointDataUpdateAPIRes {
  type: typeof PATIENT_SEARCH_UPDATE_API_RES;
  payload: PatientSearchDetails;
  message: string;
  status: number;
}
interface PatientPointDataAction {
  type: typeof PATIENT_SEARCH_DATA;
  payload: PatientSearchDetails;
  pageCount: PageCount;
}

export type PatientSearchActionTypes =
  | PatientPointDataAction
  | PatientPointDataLoading
  | PatientPointDataSuccess
  | PatientPointDataFail
  | PatientPointDataUpdateAPIMsg
  | PatientPointDataUpdateAPIRes;
