import {
  UpdateDoctorContactDetailsActionTypes,
  UPDATE_DOCTOR_CONTACT_DETAILS_FAIL,
  UPDATE_DOCTOR_CONTACT_DETAILS_LOADING,
  UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS,
  UpdateDoctorContactDetailsState,
  UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG,
  UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES,
} from "./ActionTypes";
import { UpdateDoctorContactDetailsRes } from "./Model";

const initialStateUpdatePosts: UpdateDoctorContactDetailsState = {
  loading: false,
  updateNoteResRes: {} as UpdateDoctorContactDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const updateDoctorContactDetailsReducer = (
  state = initialStateUpdatePosts,
  action: UpdateDoctorContactDetailsActionTypes
): UpdateDoctorContactDetailsState => {
  switch (action.type) {
    case UPDATE_DOCTOR_CONTACT_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case UPDATE_DOCTOR_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        updateNoteResRes: action.payload,
        successMsg: action.successMsg,
      };
    case UPDATE_DOCTOR_CONTACT_DETAILS_FAIL:
      return {
        ...state,
        updateNoteResRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_MSG:
      return {
        ...state,
        updateNoteResRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_DOCTOR_CONTACT_DETAILS_UPDATE_API_RES:
      return {
        ...state,
        updateNoteResRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
