import {
    AppoinmentListOfHealthcampActionTypes,
   APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL,
   APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING,
   APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS,
   AppoinmentListOfHealthcampState,
   APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG,
   APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES,
 } from "./ActionTypes";
 import { GetAppointmentListHealthcampRes } from "./Model";
 
 const initialStateGetPosts: AppoinmentListOfHealthcampState = {
   loading: false,
   getAppoinmentListOfHealthcampResponse: {} as GetAppointmentListHealthcampRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const  getAppointmentListOfHealthcampInstanceReducer = (
   state = initialStateGetPosts,
   action:  AppoinmentListOfHealthcampActionTypes,
 ): AppoinmentListOfHealthcampState => {
   switch (action.type) {
     case APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS:
       return {
         ...state,
         getAppoinmentListOfHealthcampResponse: action.payload,
         successMsg: action.successMsg,
       };
     case APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL:
       return {
         ...state,
         getAppoinmentListOfHealthcampResponse: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG:
       return {
         ...state,
         getAppoinmentListOfHealthcampResponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES:
       return {
         ...state,
         getAppoinmentListOfHealthcampResponse: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 