import { GetAppointmentListHealthcampRes } from "./Model";
export const APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING = "APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING";
export const APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS = "APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS";
export const APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL = "APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL";
export const APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG = "APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG";
export const APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES = "APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES";

export interface AppoinmentListOfHealthcampState {
  getAppoinmentListOfHealthcampResponse: GetAppointmentListHealthcampRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AppoinmentListOfHealthcampLoading {
  type: typeof APPOINTMENT_LIST_OF_HEALTHCAMP_LOADING;
  loading: boolean;
}
export interface AppoinmentListOfHealthcampSuccess {
  type: typeof APPOINTMENT_LIST_OF_HEALTHCAMP_SUCCESS;
  payload: GetAppointmentListHealthcampRes;
  successMsg: string;
}
export interface AppoinmentListOfHealthcampFail {
  type: typeof APPOINTMENT_LIST_OF_HEALTHCAMP_FAIL;
  payload: GetAppointmentListHealthcampRes;
  errorMsg: string;
  status: number;
}
export interface AppoinmentListOfHealthcampUpdateAPIMsg {
  type: typeof APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_MSG;
  payload: GetAppointmentListHealthcampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AppoinmentListOfHealthcampUpdateAPIRes {
  type: typeof APPOINTMENT_LIST_OF_HEALTHCAMP_UPDATE_API_RES;
  payload: GetAppointmentListHealthcampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AppoinmentListOfHealthcampActionTypes =
  | AppoinmentListOfHealthcampLoading
  | AppoinmentListOfHealthcampSuccess
  | AppoinmentListOfHealthcampFail
  | AppoinmentListOfHealthcampUpdateAPIMsg
  | AppoinmentListOfHealthcampUpdateAPIRes;
