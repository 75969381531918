import {
  ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL,
  ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING,
  ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS,
  ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG,
  ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES,
  AddExecutiveSignupRequestActionTypes,
} from "./ActionTypes";
import { AddExecutiveSignupRequestRes } from "./Model";

export const addExecutiveSignupRequestLoadingAction = (
  loading: boolean,
): AddExecutiveSignupRequestActionTypes => {
  return {
    type: ADD_EXECUTIVE_SIGNUP_REQUEST_LOADING,
    loading: loading,
  };
};

export const addExecutiveSignupRequestSuccessAction = (
  AddExecutiveSignupRequestResponse: AddExecutiveSignupRequestRes,
  successMsg: string,
): AddExecutiveSignupRequestActionTypes => {
  return {
    type: ADD_EXECUTIVE_SIGNUP_REQUEST_SUCCESS,
    payload: AddExecutiveSignupRequestResponse,
    successMsg: successMsg,
  };
};

export const addExecutiveSignupRequestErrorAction = (
  AddExecutiveSignupRequestResponse: AddExecutiveSignupRequestRes,
  errMsg: string,
  status: number,
): AddExecutiveSignupRequestActionTypes => {
  return {
    type: ADD_EXECUTIVE_SIGNUP_REQUEST_FAIL,
    payload: AddExecutiveSignupRequestResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addExecutiveSignupRequestAPIMsgAction = (
  AddExecutiveSignupRequestResponse: AddExecutiveSignupRequestRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExecutiveSignupRequestActionTypes => {
  return {
    type: ADD_EXECUTIVE_SIGNUP_REQUEST_API_MSG,
    payload: AddExecutiveSignupRequestResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addExecutiveSignupRequestAPIResClearAction = (
  AddExecutiveSignupRequestResponse: AddExecutiveSignupRequestRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExecutiveSignupRequestActionTypes => {
  return {
    type: ADD_EXECUTIVE_SIGNUP_REQUEST_API_RES,
    payload: AddExecutiveSignupRequestResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
