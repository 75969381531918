import {
  AlertColor,
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Paper,
  Typography,
  Tooltip,
  Alert,
  Tab,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getPatientDataDetailsById } from "../../../redux/patientDataDetails/patientDetailsById/API";
import { AppState } from "../../../redux/store/Store";
import { clearExtractObservationDetailsAPIRes } from "../../../redux/extractObservationDetailsById/API";
import MWSnackbar from "../../../component/MWSnackbar";
import { getExtractObservationByDetailsId } from "../../../redux/GetViewExtractObservation/API";
import { ViewExtractObservationBody } from "../../../redux/GetViewExtractObservation/Model";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import { useParams } from "react-router-dom";
import DicomViewer from "../../DicomViewer";
import MWPageTitle from "component/MWPageTitle";
import ViewObservation from "../ViewObservation/Index";
import DataPointHistoryDialog from "pages/PatientDataDetails/DataPointHistoryDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PatientDetailsFromDashboardBody } from "redux/patientDataDetails/patientDetailsById/Model";

const Controls = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "calc(100% - 20px)",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);

export default function ImageViewerComponent() {
  const dispatch = useDispatch();
  const { patientdetailsid } = useParams() as {
    patientdetailsid: string;
  };
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const getPatientDetailsById = useSelector((state: AppState) => state.getPatientDetailsById);
  const getObservationValue = useSelector(
    (state: AppState) => state.getExtractObservationByDetailsValue,
  );
  const getExtractObservationDetailsValue =
    getObservationValue.getExtractObservationRes.observationList;

  useEffect(() => {
    let patientDetailsBody = {
      patientDetailsId: patientdetailsid,
    } as PatientDetailsFromDashboardBody;
    dispatch(getPatientDataDetailsById(patientDetailsBody));
    let viewExtractBody = {
      patientDetailsId: patientdetailsid,
    } as ViewExtractObservationBody;
    dispatch(getExtractObservationByDetailsId(viewExtractBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateDrDetailsAPIMsg, setUpdateDrDetailsAPIMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const [listCount, setListCount] = React.useState("");
  const [updateDoctorSummaryDetailsError, setUpdateDoctorSummaryDetailsError] = useState("");
  console.log("updateDoctorSummaryDetailsError", updateDoctorSummaryDetailsError);
  const extractObservationByDetailsIdVal = useSelector(
    (state: AppState) => state.extractObservationByDetailsIdValue,
  );
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearExtractObservationDetailsAPIRes());
    }
  };

  useEffect(() => {
    if (extractObservationByDetailsIdVal?.extractObservationDetailsRes?.message !== undefined) {
      setMsgType("success");
      setUpdateDrDetailsAPIMsg(
        extractObservationByDetailsIdVal?.extractObservationDetailsRes?.message,
      );
      viewExtractObservation();
    } else if (extractObservationByDetailsIdVal?.errorMsg !== "") {
      setUpdateDoctorSummaryDetailsError(extractObservationByDetailsIdVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractObservationByDetailsIdVal]);

  const viewExtractObservation = () => {
    let viewExtractBody = {
      patientDetailsId: patientdetailsid,
    } as ViewExtractObservationBody;
    dispatch(getExtractObservationByDetailsId(viewExtractBody));
  };

  useEffect(() => {
    getObservationValue?.getExtractObservationRes?.observationList !== undefined
      ? setListCount(String(getObservationValue?.getExtractObservationRes?.count))
      : setListCount("0");
  }, [getObservationValue]);

  return (
    <Box>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <MWPageTitle backButton={true} />
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid xs={7}>
          <Paper square sx={{ minHeight: "100vh" }}>
            {getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument
              ?.document !== undefined ? (
              <TransformWrapper initialScale={1} initialPositionX={50} initialPositionY={50}>
                {(utils) => (
                  <React.Fragment>
                    <Controls {...utils} />
                    <TransformComponent
                      wrapperStyle={{
                        width: "60vw",
                        height: "100vw",
                        maxWidth: "100%",
                        maxHeight: "calc(100vh - 50px)",
                      }}
                    >
                      {getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument
                        ?.document !== "" ? (
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "mp4",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "pdf",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "quicktime",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "webm",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "3gpp",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "x-flv",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "x-ms-asf",
                        ) ||
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                          "mp3",
                        ) ? (
                          <iframe
                            src={
                              getPatientDetailsById?.patientDetailsById?.patientDetails
                                ?.patientDocument?.document
                            }
                            title="Small document"
                            width="900"
                            height="700"
                            allow="fullscreen"
                          ></iframe>
                        ) : getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                            "DCM",
                          ) ||
                          getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                            "dcm",
                          ) ? (
                          <DicomViewer
                            imageFile={
                              getPatientDetailsById?.patientDetailsById?.patientDetails
                                ?.patientDocument?.document
                            }
                          />
                        ) : (
                          <img
                            style={{
                              maxInlineSize: "100%",
                              blockSize: "auto",
                            }}
                            src={
                              getPatientDetailsById?.patientDetailsById?.patientDetails
                                ?.patientDocument?.document
                            }
                            alt="test"
                          />
                        )
                      ) : null}
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            ) : (
              <Grid container direction="row" justifyContent="center" alignItems="center" pt={8}>
                <Alert variant="outlined" severity="info">
                  Image File Not Uploaded
                </Alert>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid xs={5}>
          <Box sx={{ width: "100%", px: 2, py: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography>
                <Grid item>
                  <Typography gutterBottom>
                    Health Details Of:{" "}
                    {/* <b>{NameGenerate.changeName(patientid, value.patientName)}</b> || Recorded on:{" "} */}
                    <b>
                      {dayjs(
                        getPatientDetailsById?.patientDetailsById?.patientDetails?.startDate,
                      ).format("dddd, MMMM D, YYYY h:mm A")}
                    </b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom>
                    Observation:{" "}
                    <b>{getPatientDetailsById?.patientDetailsById?.patientDetails?.description}</b>
                  </Typography>
                </Grid>
              </Typography>
              <Grid item>
                <Tooltip
                  title={`Patient Details Id: ${getPatientDetailsById?.patientDetailsById?.patientDetails?.id}`}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabContext value={String(selected)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabChange}>
                    <Tab
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography>History</Typography>
                          </Grid>
                        </Grid>
                      }
                      iconPosition="end"
                      value="1"
                    />
                    <Tab
                      label={
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography>Observations</Typography>
                          </Grid>
                          <Grid item>
                            <Chip label={String(listCount)} variant="outlined" size="small" />
                          </Grid>
                        </Grid>
                      }
                      iconPosition="end"
                      value="2"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <DataPointHistoryDialog />
                </TabPanel>
                <TabPanel value="2">
                  <ViewObservation
                    getExtractObservationDetailsValue={getExtractObservationDetailsValue}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <MWSnackbar msg={updateDrDetailsAPIMsg} type={msgType} alertClose={checkAlertClose} />
    </Box>
  );
}
