import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  AlertColor,
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormHelperText,
  Grid,
  InputBase,
  Pagination,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
  addHealthcampExistingPatient,
  clearAddExistingPtAPIRes,
  updateAddExistingPtAPIResMsg,
} from "../../../redux/addExistingHealthcampPatient/API";
import { PatientDataBody } from "../../../redux/addExistingHealthcampPatient/Model";
import { clearSearchAPIRes, patientSearchApi } from "../../../redux/PatientSearchApi/API";
import { PatientList, PatientSearchBody } from "../../../redux/PatientSearchApi/Model";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../component/MWExceptionList";
import dayjs from "dayjs";
import MWPageTitle from "../../../component/MWPageTitle";
import AdminLoader from "../../AdminLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { HealthCampList } from "../../../redux/healthcampList/Model";
import { getHealthcampListValue } from "../../../redux/healthcampList/API";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridView from "./GridView";
import {
  clearAPIRes,
  patientSearchByHealthCampIdApi,
} from "../../../redux/PatientSearchByHealcampId/API";
import { PatientSearchByHealthCampIdBody } from "../../../redux/PatientSearchByHealcampId/Model";
import AddPatientDialog from "../../PatientListByOrganization/AddPatientDialog";
import MWSnackbar from "component/MWSnackbar";
import {
  addRegistrationRequestApiCall,
  clearAddRegistrationRequestAPIRes,
  updateAddRegistrationRequestAPIResMsg,
} from "redux/AddRegistrationRequestByHealthcampId/API";
import { AddRegistrationRequestBody } from "redux/AddRegistrationRequestByHealthcampId/Model";
import { getHealthCampRegistrationRequestList } from "redux/HealthCampRegistrationRequestList/API";
import { GetHealthcampRequestListBody } from "redux/HealthCampRegistrationRequestList/Model";
import CheckIn from "component/CheckIn";
import LoadingButton from "@mui/lab/LoadingButton";

type Props = {
  addExistingPatientDialogEnable?: boolean | any;
  addExistingPatientDialogUpdateState?: boolean | any;
  title?: string | any;
  addPt?: boolean | any;
  showPtDetail?: boolean | any;
  showPtDoc?: boolean | any;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

// eslint-disable-next-line no-empty-pattern
export default function PatientRegistration({}: Props) {
  let query = useQuery();
  const requestId = query.get("requestId");
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const [urlPath, setUrlPath] = useState(history.location.pathname);
  useEffect(() => {
    setUrlPath(history.location.pathname);
  }, [history]);

  useEffect(() => {
    if (requestId !== null) {
      setShowAddPatientDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  const [isLoading, setIsLoading] = useState(false);
  const addExistingPatientSubmit = (element: string) => {
    const addExistingPatientBody = {
      healthCampId: healthcampid!,
      patientId: element,
    } as PatientDataBody;
    dispatch(addHealthcampExistingPatient(addExistingPatientBody));
  };
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const [campName, setCampName] = useState("");
  const [inputValue, setInputValue] = React.useState("" as any);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [regRequestId, setRegRequestId] = React.useState("");
  const [listCount, setListCount] = React.useState("0");
  const patientSearchByHealthcampBody = {
    searchKey: inputValue,
    healthCampId: healthcampid,
  } as PatientSearchByHealthCampIdBody;

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  // store call
  const searchValueList = useSelector((state: AppState) => state.searchPatientByHealthCampIdValue);
  const patientSearchByHealthCampIdRes = searchValueList.PatientSearchByHealthcampIdRes.patientList;
  const patientSearchListValueRes = useSelector((state: AppState) => state.patientSearchListValue);
  const searchPatientList = patientSearchListValueRes?.patientSearchRes.patientList;
  const addExistingPatientValueRes = useSelector(
    (state: AppState) => state.addExistingPatientValue,
  );
  const addRegistartionRequestValueRes = useSelector(
    (state: AppState) => state.addRegistrationRequestValue,
  );
  const AdminHealthcampRegistrationReqList = useSelector(
    (state: AppState) => state.healthcampRegistrationRequestListValue,
  );
  // const AddRegistrationRequestValue = useSelector(
  //   (state: AppState) => state.addRegistrationRequestValue,
  // );
  const [addExistingPt, setAddExistingPt] = useState("");
  const [addExistingPtError, setAddExistingPtError] = useState("");
  const [patientId, setPatientId] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    if (AdminAllHealthcampList?.healthcampListValue?.HealthCampList !== undefined) {
      var result = AdminAllHealthcampList?.healthcampListValue?.HealthCampList.find(
        (obj: HealthCampList) => {
          return obj.id === Number(healthcampid);
        },
      )?.displayName;
      if (result !== null) {
        setCampName(result! + " Patient List");
      }
    }
    // eslint-disable-next-line
  }, [AdminAllHealthcampList]);
  let type = "healthcamp";
  const [showAddPatientDialog, setShowAddPatientDialog] = React.useState(false);
  const updateDialogState = () => {
    dispatch(clearAddRegistrationRequestAPIRes());
    dispatch(updateAddRegistrationRequestAPIResMsg());
    dispatch(
      addRegistrationRequestApiCall({ healthcampId: healthcampid } as AddRegistrationRequestBody),
    );
  };
  const patientRegReq = () => {
    history.push(`/healthcampregistrationrequest/${healthcampid}`);
  };
  const updateAddPatientDialog = (passedVal: boolean) => {
    setShowAddPatientDialog(passedVal);
  };

  const successMsgUpdateState = (value: string) => {
    setAddExistingPt(value);
    dispatch(updateAddExistingPtAPIResMsg());
    dispatch(clearAddExistingPtAPIRes());
  };
  useEffect(() => {
    setIsLoading(addExistingPatientValueRes?.loading);
    if (addExistingPatientValueRes?.addPatientRes?.message !== undefined) {
      setAddExistingPt(addExistingPatientValueRes?.addPatientRes?.message);
      setPatientId(String(addExistingPatientValueRes?.addPatientRes?.id));
      // setSnackBarType("success");
    }
    if (addExistingPatientValueRes?.message !== "") {
      setAddExistingPt(addExistingPatientValueRes?.message);
      // setSnackBarType("error");
    }
    if (addExistingPatientValueRes?.errRes !== "") {
      setAddExistingPtError(addExistingPatientValueRes?.errRes);
      setSnackBarType("error");
    }
  }, [addExistingPatientValueRes]);
  useEffect(() => {
    if (addRegistartionRequestValueRes?.successMsg !== "") {
      setRegRequestId(String(addRegistartionRequestValueRes?.addRegistrationRequestRes?.id));
      // setAddExistingPt(addRegistartionRequestValueRes?.successMsg);
      setSnackBarType("success");
      // setShowAddPatientDialog(true);
      if (addRegistartionRequestValueRes?.addRegistrationRequestRes?.id !== undefined) {
        history.push(
          `/patientregistration/${healthcampid}/?requestId=${addRegistartionRequestValueRes?.addRegistrationRequestRes?.id}`,
        );
      }
      dispatch(
        getHealthCampRegistrationRequestList({
          healthcampId: healthcampid,
        } as GetHealthcampRequestListBody),
      );
    }
    if (addRegistartionRequestValueRes?.errorMsg !== "") {
      setAddExistingPt(addRegistartionRequestValueRes?.errorMsg);
      setSnackBarType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRegistartionRequestValueRes]);
  const patientSearchBody = {
    searchKey: inputValue,
  } as PatientSearchBody;

  const [page, setPage] = useState(1);
  const [medicalWisdomDetais, setMedicalWisdomDetails] = useState<any[]>([]);
  const [patientDetails, setPatientDetails] = useState<any[]>([]);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  useEffect(() => {
    setIsLoading(patientSearchListValueRes?.loading);
    dispatch(getHealthcampListValue());
    dispatch(
      getHealthCampRegistrationRequestList({
        healthcampId: healthcampid,
      } as GetHealthcampRequestListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  useEffect(() => {
    searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages))
      : setPageListCount("0");
    if (searchValueList?.PatientSearchByHealthcampIdRes?.count !== undefined) {
      setListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.count));
    }
    if (patientSearchByHealthCampIdRes !== undefined) {
      let dataPointArr = patientSearchByHealthCampIdRes.map((element: any) => ({
        id: element.patientId,
        patientId: element.patientId,
        givenName: element.givenName,
        familyName: element.familyName,
        userName: element.userName,
        email: element.email,
        dob: dayjs(element.dob).format("ddd, MMM D, YYYY"),
        phone: element.phone,
        profilePhotoId: element.profilePhotoId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueList]);

  useEffect(() => {
    patientSearchListValueRes?.patientSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientSearchListValueRes?.patientSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (patientSearchListValueRes?.patientSearchRes?.count !== undefined) {
      setListCount(String(patientSearchListValueRes?.patientSearchRes?.count));
    }
    if (searchPatientList !== undefined) {
      let dataPointArr = searchPatientList.map((element: PatientList) => ({
        id: element.patientId,
        patientId: element.patientId,
        givenName: element.givenName,
        familyName: element.familyName,
        userName: element.userName,
        email: element.email,
        dob: dayjs(element.dob).format("ddd, MMM D, YYYY"),
        phone: element.phone,
        profilePhotoId: element.profilePhotoId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(patientSearchListValueRes?.patientSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  const [alignment, setAlignment] = useState<string | null>("1");
  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };
  useEffect(() => {
    setMedicalWisdomDetails(searchPatientList);
    setPatientDetails(patientSearchByHealthCampIdRes);
  }, [searchPatientList, patientSearchByHealthCampIdRes]);

  const patientSearchInCamp = () => {
    if (inputValue !== "") {
      dispatch(patientSearchByHealthCampIdApi(patientSearchByHealthcampBody, 1));
      dispatch(clearSearchAPIRes());
      setPage(1);
      setIsFormInvalid(false);
    } else {
      setIsFormInvalid(true);
    }
  };

  const patientSearchInMedicalWisdomPlatform = () => {
    if (inputValue !== "") {
      dispatch(patientSearchApi(patientSearchBody, 1));
      dispatch(clearAPIRes());
      setPage(1);
      setIsFormInvalid(false);
    } else {
      setIsFormInvalid(true);
    }
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (medicalWisdomDetais !== undefined) {
      dispatch(patientSearchApi(patientSearchBody, value));
      dispatch(clearAPIRes());
    } else if (patientDetails !== undefined) {
      dispatch(patientSearchByHealthCampIdApi(patientSearchByHealthcampBody, value));
      dispatch(clearSearchAPIRes());
    }
  };

  const columns: GridColDef[] = [
    {
      field: "ptDetails",
      headerName: "View",
      width: 100,
      align: "left",
      hide: patientSearchByHealthCampIdRes !== undefined ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "ptDocDetails",
      headerName: "View Pt. Doc.",
      width: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== `/patientregistration/${healthcampid}` ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View Document
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      width: 100,
      align: "left",
    },
    {
      field: "givenName",
      headerName: "First Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "familyName",
      headerName: "Last Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "patientId",
      headerName: "Action",
      width: 50,
      align: "left",
      flex: 1,
      hide: patientSearchByHealthCampIdRes !== undefined ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => {
            addExistingPatientSubmit(params.row.patientId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add Existing Patient
          </span>
        </Button>
      ),
    },
  ];

  const searchTitle =
    medicalWisdomDetais !== undefined
      ? `Search result in medicalWISDOM platform for "${inputValue}"`
      : "" || patientDetails !== undefined
      ? `Search result in camp for "${inputValue}"`
      : "" || searchPatientList !== undefined
      ? `Search result in medicalWISDOM platform for "${inputValue}"`
      : "" || patientSearchByHealthCampIdRes !== undefined
      ? `Search result in camp for "${inputValue}"`
      : "";

  const PatientSearchListData = (
    <Card>
      <CardHeader
        title={
          <Box>
            <Grid container direction="row" justifyContent="space-between">
              <Paper
                variant="outlined"
                component="form"
                sx={{ display: "flex", alignItems: "center", width: 700 }}
              >
                <InputBase
                  value={inputValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInputValue(event.target.value);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      dispatch(patientSearchByHealthCampIdApi(patientSearchByHealthcampBody, page));
                      ev.preventDefault();
                    }
                  }}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Patient"
                  inputProps={{ "aria-label": "search patient" }}
                />
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                  <Button sx={{ borderRadius: 0 }} onClick={patientSearchInCamp}>
                    In Camp
                  </Button>
                  <Button onClick={patientSearchInMedicalWisdomPlatform}>
                    In MedicalWisdom Platform
                  </Button>
                </ButtonGroup>
              </Paper>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                size="small"
              >
                <ToggleButton value="1" aria-label="centered">
                  <GridViewIcon />
                </ToggleButton>
                <ToggleButton value="2" aria-label="centered">
                  <FormatListBulletedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {isFormInvalid && (
              <FormHelperText error id="my-helper-text">
                Please enter patient name
              </FormHelperText>
            )}
          </Box>
        }
      />
      <CardContent>
        {medicalWisdomDetais !== undefined || patientDetails !== undefined ? (
          <Box sx={{ mb: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              my={1}
            >
              <Grid item>
                <MWPageTitle title={searchTitle} enableCount={true} count={listCount} />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography>Page: {page}</Typography>
                  </Grid>
                  <Grid item>
                    <Pagination
                      color="primary"
                      count={Number(pageListCount) || 0}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {addExistingPt !== "" ? (
              <CheckIn
                addPatientSuccessMessage={addExistingPt}
                patientId={Number(patientId)}
                successMsgUpdateState={successMsgUpdateState}
              />
            ) : null}
          </Box>
        ) : (
          ""
        )}
        {alignment === "1" ? (
          <Box>
            <GridView patientDetails={patientDetails} medicalWisdomDetais={medicalWisdomDetais} />
          </Box>
        ) : (
          <Box>
            {(searchPatientList && searchPatientList.length) ||
            (patientSearchByHealthCampIdRes && patientSearchByHealthCampIdRes.length) ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
  const pageBody = (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={campName}
                customRoute={`/patientlistbyhealthcamp/${healthcampid}`}
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => patientRegReq()}
                    sx={{ textTransform: "none" }}
                    disabled={
                      AdminHealthcampRegistrationReqList?.getHealthCampRegistrationRequestList
                        ?.healthcampRegistrationRequestList !== undefined &&
                      AdminHealthcampRegistrationReqList?.getHealthCampRegistrationRequestList
                        ?.healthcampRegistrationRequestList.length === 0
                        ? true
                        : false
                    }
                  >
                    Registration Request List
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    onClick={() => updateDialogState()}
                    loading={addRegistartionRequestValueRes?.loading}
                    variant="contained"
                    sx={{ textTransform: "none" }}
                  >
                    Add New Patient
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {PatientSearchListData}
          {showAddPatientDialog ? (
            <AddPatientDialog
              itemId={healthcampid}
              type={type}
              addPatientDialogEnable={showAddPatientDialog}
              addPatientDialogUpdateState={updateAddPatientDialog}
              regRequestId={regRequestId}
            />
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddExistingPtAPIRes());
      setAddExistingPt("");
    }
  };
  return (
    <Box>
      {isLoading && urlPath === `/patientregistration/${healthcampid}` ? <AdminLoader /> : null}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === `/patientregistration/${healthcampid}` ? <>{pageBody}</> : <>{pageBody}</>}
      </Stack>

      {addExistingPtError !== "" ? (
        <MWSnackbar msg={addExistingPtError} type={snackBarType} alertClose={addImgtoComp} />
      ) : null}
    </Box>
  );
}
