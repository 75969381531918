import {
  EXECUTIVE_SEARCH_DATA,
  EXECUTIVE_SEARCH_FAIL,
  EXECUTIVE_SEARCH_LOADING,
  EXECUTIVE_SEARCH_SUCCESS,
  ExecutiveSearchActionTypes,
} from "./ActionTypes";
import { PageCount, ExecutiveSearchDetails } from "./Model";

export const executiveSearchAction = (
  ExecutiveSearchDetails: ExecutiveSearchDetails,
  pageCount: PageCount,
): ExecutiveSearchActionTypes => {
  return {
    type: EXECUTIVE_SEARCH_DATA,
    payload: ExecutiveSearchDetails,
    pageCount: pageCount,
  };
};

export const executiveSearchLoadingAction = (loading: boolean): ExecutiveSearchActionTypes => {
  return {
    type: EXECUTIVE_SEARCH_LOADING,
    loading: loading,
  };
};

export const executiveSearchSuccessAction = (
  executiveSearchResponse: ExecutiveSearchDetails,
): ExecutiveSearchActionTypes => {
  return {
    type: EXECUTIVE_SEARCH_SUCCESS,
    payload: executiveSearchResponse,
  };
};

export const executiveSearchErrorAction = (
  executiveSearchResponse: ExecutiveSearchDetails,
  errMsg: string,
  status: number,
): ExecutiveSearchActionTypes => {
  return {
    type: EXECUTIVE_SEARCH_FAIL,
    payload: executiveSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const executiveSearchUpdateAPIMsgAction = (
  executiveSearchResponse: ExecutiveSearchDetails,
  errMsg: string,
  status: number,
): ExecutiveSearchActionTypes => {
  return {
    type: EXECUTIVE_SEARCH_FAIL,
    payload: executiveSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const executiveSearchAPIResClearAction = (
  executiveSearchResponse: ExecutiveSearchDetails,
  errMsg: string,
  status: number,
): ExecutiveSearchActionTypes => {
  return {
    type: EXECUTIVE_SEARCH_FAIL,
    payload: executiveSearchResponse,
    message: errMsg,
    status: status,
  };
};
