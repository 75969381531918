import {
  DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_FAIL,
  DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_LOADING,
  DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS,
  DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG,
  DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_RES,
  DuplicateOrganizationListByPatientIdActionTypes,
} from "./ActionTypes";
import { DuplicateOrganizationListByPatientIdDetails } from "./Model";

export const DuplicateOrganizationListByPatientIdLoadingAction = (
  loading: boolean,
): DuplicateOrganizationListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const DuplicateOrganizationListByPatientIdSuccessAction = (
  duplicateOrganizationListByPatientIdResponse: DuplicateOrganizationListByPatientIdDetails,
  successMsg: string,
): DuplicateOrganizationListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS,
    payload: duplicateOrganizationListByPatientIdResponse,
    successMsg: successMsg,
  };
};

export const DuplicateOrganizationListByPatientIdErrorAction = (
  duplicateOrganizationListByPatientIdResponse: DuplicateOrganizationListByPatientIdDetails,
  errMsg: string,
  status: number,
): DuplicateOrganizationListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_FAIL,
    payload: duplicateOrganizationListByPatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DuplicateOrganizationListByPatientIdAPIMsgAction = (
  duplicateOrganizationListByPatientIdResponse: DuplicateOrganizationListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): DuplicateOrganizationListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG,
    payload: duplicateOrganizationListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const DuplicateOrganizationListByPatientIdAPIResClearAction = (
  duplicateOrganizationListByPatientIdResponse: DuplicateOrganizationListByPatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): DuplicateOrganizationListByPatientIdActionTypes => {
  return {
    type: DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_RES,
    payload: duplicateOrganizationListByPatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
