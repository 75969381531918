import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetObservationListBySessionIdActionTypes } from "./ActionTypes";
import { GetObservationListBySessionIdBody, GetObservationListBySessionIdRes } from "./Model";
import {
  getObservationListBySessionIdAPIResClearAction,
  getObservationListBySessionIdErrorAction,
  getObservationListBySessionIdLoadingAction,
  getObservationListBySessionIdSuccessAction,
  getObservationListBySessionIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetObservationListBySessionIdRes;
export const getObservationListBySessionIdApi = (
  payload: GetObservationListBySessionIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetObservationListBySessionIdActionTypes>) {
    dispatch(getObservationListBySessionIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientObservation/get_patient_observation_of_session?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getObservationListBySessionIdLoadingAction(false));
        dispatch(
          getObservationListBySessionIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetObservationListBySessionIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getObservationListBySessionIdLoadingAction(false));
        dispatch(
          getObservationListBySessionIdErrorAction(
            {} as GetObservationListBySessionIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetObservationListBySessionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetObservationListBySessionIdActionTypes>) {
    dispatch(
      getObservationListBySessionIdUpdateAPIMsgAction(
        apiRes as GetObservationListBySessionIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetObservationListBySessionIdAPIRes = () => {
  return function (dispatch: Dispatch<GetObservationListBySessionIdActionTypes>) {
    dispatch(
      getObservationListBySessionIdAPIResClearAction({} as GetObservationListBySessionIdRes, "", "", 0),
    );
  };
};
