import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetPatientListOfCompetitionActionTypes } from "./ActionTypes";
import { GetPatientListOfCompetitionBody, GetPatientListOfCompetitionDetails } from "./Model";
import {
  GetPatientListOfCompetitionAPIResClearAction,
  GetPatientListOfCompetitionErrorAction,
  GetPatientListOfCompetitionLoadingAction,
  GetPatientListOfCompetitionSuccessAction,
  GetPatientListOfCompetitionAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetPatientListOfCompetitionDetails;
export const getPatientListOfCompetition = (
  compitionId: GetPatientListOfCompetitionBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetPatientListOfCompetitionActionTypes>) {
    dispatch(GetPatientListOfCompetitionLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionPatientDetailsListAsStaff/get_patientDetailsList_of_competition?page=${Number(
            pageNo,
          )}&size=50`,
        compitionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(GetPatientListOfCompetitionLoadingAction(false));
        dispatch(GetPatientListOfCompetitionSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(GetPatientListOfCompetitionLoadingAction(false));
        dispatch(
          GetPatientListOfCompetitionErrorAction(
            {} as GetPatientListOfCompetitionDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const patientListOfCompetitionAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientListOfCompetitionActionTypes>) {
    dispatch(
      GetPatientListOfCompetitionAPIMsgAction(apiRes as GetPatientListOfCompetitionDetails, "", 0),
    );
  };
};

export const clearPatientListOfCompetitionAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientListOfCompetitionActionTypes>) {
    dispatch(
      GetPatientListOfCompetitionAPIResClearAction({} as GetPatientListOfCompetitionDetails, "", 0),
    );
  };
};
