import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ProviderOrgPatientSearchActionTypes } from "./ActionTypes";
import { ProviderOrgPatientSearchBody, ProviderOrgPatientSearchDetails } from "./Model";
import {
  providerOrgPatientSearchAPIResClearAction,
  providerOrgPatientSearchErrorAction,
  providerOrgPatientSearchLoadingAction,
  providerOrgPatientSearchSuccessAction,
  providerOrgPatientSearchUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as ProviderOrgPatientSearchDetails;
export const providerOrgPatientSearchApi = (searchKey: ProviderOrgPatientSearchBody) => {
  return function (dispatch: Dispatch<ProviderOrgPatientSearchActionTypes>) {
    dispatch(providerOrgPatientSearchLoadingAction(true));
    axios
      .get(
        Url.baseUrl +
          `/patientportalapp/api/searchPatientPortalList/search_patient_portal_list?page=${Number(searchKey.pageNo)}&size=12&firstName=${searchKey.searchKey}`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(providerOrgPatientSearchLoadingAction(false));
        dispatch(providerOrgPatientSearchSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(providerOrgPatientSearchLoadingAction(false));
        dispatch(
          providerOrgPatientSearchErrorAction(
            {} as ProviderOrgPatientSearchDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateProviderOrgPatientSearchAPIResMsg = () => {
  return function (dispatch: Dispatch<ProviderOrgPatientSearchActionTypes>) {
    dispatch(providerOrgPatientSearchUpdateAPIMsgAction(apiRes as ProviderOrgPatientSearchDetails, "", 0));
  };
};

export const clearProviderOrgPatientSearchAPIRes = () => {
  return function (dispatch: Dispatch<ProviderOrgPatientSearchActionTypes>) {
    dispatch(providerOrgPatientSearchAPIResClearAction({} as ProviderOrgPatientSearchDetails, "", 0));
  };
};
