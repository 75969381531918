import { PageCount, GetViewCompetitionStaffListDetails } from "./Model";

// Patient Demographics Loading State
export const VIEW_COMPETITION_STAFF_LIST_LOADING = "VIEW_COMPETITION_STAFF_LIST_LOADING";
export const VIEW_COMPETITION_STAFF_LIST_SUCCESS = "VIEW_COMPETITION_STAFF_LIST_SUCCESS";
export const VIEW_COMPETITION_STAFF_LIST_FAIL = "VIEW_COMPETITION_STAFF_LIST_FAIL";
export const VIEW_COMPETITION_STAFF_LIST_DATA = "VIEW_COMPETITION_STAFF_LIST_DATA";
export const VIEW_COMPETITION_STAFF_LIST_API_MSG = "VIEW_COMPETITION_STAFF_LIST_API_MSG";
export const VIEW_COMPETITION_STAFF_LIST_API_RES = "VIEW_COMPETITION_STAFF_LIST_API_RES";

export interface ViewCompetitionStaffListState {
  viewCompetitionStaffListRes: GetViewCompetitionStaffListDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewCompetitionStaffListLoading {
  type: typeof VIEW_COMPETITION_STAFF_LIST_LOADING;
  loading: boolean;
}
export interface ViewCompetitionStaffListSuccess {
  type: typeof VIEW_COMPETITION_STAFF_LIST_SUCCESS;
  payload: GetViewCompetitionStaffListDetails;
  successMsg: string;
}
export interface ViewCompetitionStaffListFail {
  type: typeof VIEW_COMPETITION_STAFF_LIST_FAIL;
  payload: GetViewCompetitionStaffListDetails;
  errorMsg: string;
  status: number;
}
export interface ViewCompetitionStaffListAPIMsg {
  type: typeof VIEW_COMPETITION_STAFF_LIST_API_MSG;
  payload: GetViewCompetitionStaffListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewCompetitionStaffListAPIRes {
  type: typeof VIEW_COMPETITION_STAFF_LIST_API_RES;
  payload: GetViewCompetitionStaffListDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface ViewCompetitionStaffListDataAction {
  type: typeof VIEW_COMPETITION_STAFF_LIST_DATA;
  payload: GetViewCompetitionStaffListDetails;
  pageCount: PageCount;
}

export type GetViewCompetitionStaffListActionTypes =
  | ViewCompetitionStaffListDataAction
  | ViewCompetitionStaffListLoading
  | ViewCompetitionStaffListSuccess
  | ViewCompetitionStaffListFail
  | ViewCompetitionStaffListAPIMsg
  | ViewCompetitionStaffListAPIRes;
