import {
  HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL,
  HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING,
  HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS,
  HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_MSG,
  HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_RES,
  HealthcampPatientSearchListByObservationTypeActionTypes,
} from "./ActionTypes";
import { HealthcampPatientSearchListByObservationTypeDetails } from "./Model";

export const HealthcampPatientSearchListByObservationTypeLoadingAction = (
  loading: boolean,
): HealthcampPatientSearchListByObservationTypeActionTypes => {
  return {
    type: HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const HealthcampPatientSearchListByObservationTypeSuccessAction = (
  healthcampPatientSearchObservationTypeResponse: HealthcampPatientSearchListByObservationTypeDetails,
  successMsg: string,
): HealthcampPatientSearchListByObservationTypeActionTypes => {
  return {
    type: HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_SUCCESS,
    payload: healthcampPatientSearchObservationTypeResponse,
    successMsg: successMsg,
  };
};

export const HealthcampPatientSearchListByObservationTypeErrorAction = (
  healthcampPatientSearchObservationTypeResponse: HealthcampPatientSearchListByObservationTypeDetails,
  errMsg: string,
  status: number,
): HealthcampPatientSearchListByObservationTypeActionTypes => {
  return {
    type: HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_FAIL,
    payload: healthcampPatientSearchObservationTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const HealthcampPatientSearchListByObservationTypeAPIMsgAction = (
  healthcampPatientSearchObservationTypeResponse: HealthcampPatientSearchListByObservationTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): HealthcampPatientSearchListByObservationTypeActionTypes => {
  return {
    type: HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_MSG,
    payload: healthcampPatientSearchObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const HealthcampPatientSearchListByObservationTypeAPIResClearAction = (
  healthcampPatientSearchObservationTypeResponse: HealthcampPatientSearchListByObservationTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): HealthcampPatientSearchListByObservationTypeActionTypes => {
  return {
    type: HEALTHCAMP_PATIENT_SEARCH_BY_OBSERVATION_TYPE_API_RES,
    payload: healthcampPatientSearchObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
