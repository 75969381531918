import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetJuryListByCompetitionActionTypes } from "./ActionTypes";
import { GetJuryListByCompetitionBody, GetJuryListByCompetitionDetails } from "./Model";
import {
  getJuryListByCompetitionAPIResClearAction,
  getJuryListByCompetitionErrorAction,
  getJuryListByCompetitionLoadingAction,
  getJuryListByCompetitionSuccessAction,
  getJuryListByCompetitionAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetJuryListByCompetitionDetails;
export const getJuryListByCompition = (
  competionId: GetJuryListByCompetitionBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetJuryListByCompetitionActionTypes>) {
    dispatch(getJuryListByCompetitionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/competitionapp/api/competitionJuryList/jury_list_by_competition?page=${Number(
            pageNo,
          )}&size=10`,
          competionId,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getJuryListByCompetitionLoadingAction(false));
        dispatch(getJuryListByCompetitionSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getJuryListByCompetitionLoadingAction(false));
        dispatch(
          getJuryListByCompetitionErrorAction(
            {} as GetJuryListByCompetitionDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const compitionJuryListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetJuryListByCompetitionActionTypes>) {
    dispatch(
      getJuryListByCompetitionAPIMsgAction(apiRes as GetJuryListByCompetitionDetails, "", 0),
    );
  };
};

export const clearCompetitionJuryListAPIRes = () => {
  return function (dispatch: Dispatch<GetJuryListByCompetitionActionTypes>) {
    dispatch(
      getJuryListByCompetitionAPIResClearAction({} as GetJuryListByCompetitionDetails, "", 0),
    );
  };
};
