import { PageCount, AddPatientBySessionIdDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_PATIENT_BY_SESSION_ID_LOADING = "ADD_PATIENT_BY_SESSION_ID_LOADING";
export const ADD_PATIENT_BY_SESSION_ID_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_PATIENT_BY_SESSION_ID_FAIL = "ADD_PATIENT_BY_SESSION_ID_FAIL";
export const ADD_PATIENT_BY_SESSION_ID_DATA = "ADD_PATIENT_BY_SESSION_ID_DATA";
export const ADD_PATIENT_BY_SESSION_ID_API_MSG = "ADD_PATIENT_BY_SESSION_ID_API_MSG";
export const ADD_PATIENT_BY_SESSION_ID_API_RES = "ADD_PATIENT_BY_SESSION_ID_API_RES";

export interface AddPatientBySessionIdState {
  addPatientBySessionIdRes: AddPatientBySessionIdDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientBySessionIdLoading {
  type: typeof ADD_PATIENT_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface AddPatientBySessionIdSuccess {
  type: typeof ADD_PATIENT_BY_SESSION_ID_SUCCESS;
  payload: AddPatientBySessionIdDetails;
  successMsg: string;
}
export interface AddPatientBySessionIdFail {
  type: typeof ADD_PATIENT_BY_SESSION_ID_FAIL;
  payload: AddPatientBySessionIdDetails;
  errorMsg: string;
  status: number;
}
export interface AddPatientBySessionIdAPIMsg {
  type: typeof ADD_PATIENT_BY_SESSION_ID_API_MSG;
  payload: AddPatientBySessionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientBySessionIdAPIRes {
  type: typeof ADD_PATIENT_BY_SESSION_ID_API_RES;
  payload: AddPatientBySessionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddPatientBySessionIdDataAction {
  type: typeof ADD_PATIENT_BY_SESSION_ID_DATA;
  payload: AddPatientBySessionIdDetails;
  pageCount: PageCount;
}

export type AddPatientBySessionIdActionTypes =
  | AddPatientBySessionIdDataAction
  | AddPatientBySessionIdLoading
  | AddPatientBySessionIdSuccess
  | AddPatientBySessionIdFail
  | AddPatientBySessionIdAPIMsg
  | AddPatientBySessionIdAPIRes;
