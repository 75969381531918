import {
  GetAppointmentListActionTypes,
  GET_APPOINTMENT_LIST_FAIL,
  GET_APPOINTMENT_LIST_LOADING,
  GET_APPOINTMENT_LIST_SUCCESS,
  GetAppointmentListState,
  GET_APPOINTMENT_LIST_API_MSG,
  GET_APPOINTMENT_LIST_API_RES,
} from "./ActionTypes";
import { GetAppointmentListRes } from "./Model";

const initialState: GetAppointmentListState = {
  loading: false,
  getAppointmentListRes: {} as GetAppointmentListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const requestAppointmentDetailsReducer = (
  state = initialState,
  action: GetAppointmentListActionTypes,
): GetAppointmentListState => {
  switch (action.type) {
    case GET_APPOINTMENT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_APPOINTMENT_LIST_SUCCESS:
      return {
        ...state,
        getAppointmentListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_APPOINTMENT_LIST_FAIL:
      return {
        ...state,
        getAppointmentListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_APPOINTMENT_LIST_API_MSG:
      return {
        ...state,
        getAppointmentListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_APPOINTMENT_LIST_API_RES:
      return {
        ...state,
        getAppointmentListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
