import {
  GetPatientCaseListByPatientIdActionTypes,
  PATIENT_CASE_LIST_BY_PATIENT_ID_FAIL,
  PATIENT_CASE_LIST_BY_PATIENT_ID_LOADING,
  PATIENT_CASE_LIST_BY_PATIENT_ID_SUCCESS,
  GetPatientCaseListByPatientIdState,
  PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_MSG,
  PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPatientCaseListByPatientIdRes } from "./Model";

const initialStateGetPosts: GetPatientCaseListByPatientIdState = {
  loading: false,
  getPatientCaseListByPatientIdRes: {} as GetPatientCaseListByPatientIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientCaseListByPatientIdReducer = (
  state = initialStateGetPosts,
  action: GetPatientCaseListByPatientIdActionTypes,
): GetPatientCaseListByPatientIdState => {
  switch (action.type) {
    case PATIENT_CASE_LIST_BY_PATIENT_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PATIENT_CASE_LIST_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        getPatientCaseListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case PATIENT_CASE_LIST_BY_PATIENT_ID_FAIL:
      return {
        ...state,
        getPatientCaseListByPatientIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_MSG:
      return {
        ...state,
        getPatientCaseListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_RES:
      return {
        ...state,
        getPatientCaseListByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
