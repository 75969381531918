import { useState, useEffect } from "react";
import { Box, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getPatientDataDetailsList } from "redux/patientDataDetails/patientDataPointListByPatientID/API";
import { getpatientDetails } from "redux/Patient/DemographicsDetails/ApiCall";
import { PatientDemographicsReq } from "redux/Patient/DemographicsDetails/Model";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";

type Props = {
  patientoriginalid?: any;
};

export default function PatientDetailsOfOriginalPatient({ patientoriginalid }: Props) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDataDetailsList(patientoriginalid, value));
  };
  useEffect(() => {
    if (patientoriginalid) {
      dispatch(getPatientDataDetailsList(patientoriginalid, 1));
      dispatch(
        getpatientDetails({
          healthRecordId: patientoriginalid,
        } as PatientDemographicsReq),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const patientListRes = patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList;
  const [patientList, setPatientList] = useState([] as any[]);
  const [selectedItems, setSelectedItems] = React.useState([] as any[]);
  console.log("selectedItems===", selectedItems);

  useEffect(() => {
    patientPointDataList?.getPatientDataDetailsRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientPointDataList?.getPatientDataDetailsRes?.numberOfPages))
      : setPageListCount("0");
    if (patientListRes !== undefined) {
      let ptList = patientListRes.map((element: any) => ({
        id: element.id,
        patientId: element.patientId,
        description: element?.description !== undefined ? element.description : "",
      }));
      setPatientList(ptList);
    } else {
      setPatientList([] as any[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Original Patient details Id",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      minWidth: 190,
    },
    {
      field: "patientId",
      headerName: "Original Patient Id",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      minWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];

  const patientListTable = (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {patientListRes && patientListRes.length ? (
          <DataGrid
            rows={patientList}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = patientList.filter((row: any) => selectedIDs.has(row.id));
              const mi = selectedRows.map((item: any) => ({
                patientDetailsId: item.id,
                firstName: item.firstName,
              }));
              setSelectedItems(mi);
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {patientListTable}
        </Stack>
      </Container>
    </Box>
  );
}
