import * as React from "react";
import { Box, Chip, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import PageLayout from "pages/Layout/PageLayout";
import MWPageTitle from "component/MWPageTitle";
import ExecutiveListPage from "./ExecutiveListPage";
import ExecutiveRequestList from "./ExecutiveRequestList";
import { useState } from "react";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import { useDispatch } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { ExecutiveSignupRequestListBody } from "redux/Executive/ExecutiveSignupRequestList/Model";
import { executiveSignupRequestListApiCall } from "redux/Executive/ExecutiveSignupRequestList/API";
import { executiveList } from "redux/Executive/GetExecutiveList/API";

export default function BasicTabs() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState("0");
  const [listCount, setListCount] = React.useState("0");
  const [requestListCount, setRequestListCount] = React.useState("0");
  const [executiveListLoader, setExecutiveListLoader] = useState(false);
  const [executiveSignupRequestListLoader, setExecutiveSignupRequestListLoader] = useState(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(toggleAppBarLoading(executiveListLoader || executiveSignupRequestListLoader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveListLoader, executiveSignupRequestListLoader]);

  let executivePage = (
    <Box>
      <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Executive List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={listCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  dispatch(executiveList());
                }}
              />
            }
            iconPosition="end"
            value="0"
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Executive Signup Request List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={requestListCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  dispatch(
                    executiveSignupRequestListApiCall({
                      type: "incomplete",
                    } as ExecutiveSignupRequestListBody),
                  );
                }}
              />
            }
            iconPosition="end"
            value="1"
          />
        </Tabs>
      </Box>
      <Divider />
      {fullScreen ? (
        <Box pt={2}>
          {value === "0" ? (
            <ExecutiveListPage
              executiveListLoader={setExecutiveListLoader}
              listCount={setListCount}
            />
          ) : (
            <ExecutiveRequestList
              executiveSignupRequestListLoader={setExecutiveSignupRequestListLoader}
              requestListCount={setRequestListCount}
            />
          )}
        </Box>
      ) : (
        <TabContext value={String(value)}>
          <TabPanel value="0">
            <ExecutiveListPage
              executiveListLoader={setExecutiveListLoader}
              listCount={setListCount}
            />
          </TabPanel>
          <TabPanel value="1">
            <ExecutiveRequestList
              executiveSignupRequestListLoader={setExecutiveSignupRequestListLoader}
              requestListCount={setRequestListCount}
            />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );

  return (
    <PageLayout>
      <MWPageTitle title={"Executive List"} />
      {executivePage}
    </PageLayout>
  );
}
