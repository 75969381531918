import {
  ADD_EXISTING_PATIENT_TO_SESSION_FAIL,
  ADD_EXISTING_PATIENT_TO_SESSION_LOADING,
  ADD_EXISTING_PATIENT_TO_SESSION_SUCCESS,
  ADD_EXISTING_PATIENT_TO_SESSION_API_MSG,
  ADD_EXISTING_PATIENT_TO_SESSION_API_RES,
  AddExistingPatientCaseToSessionActionTypes,
} from "./ActionTypes";
import { AddExistingPatientCaseToSessionDetails } from "./Model";

export const addExistingPatientCaseToSessionLoadingAction = (
  loading: boolean,
): AddExistingPatientCaseToSessionActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_TO_SESSION_LOADING,
    loading: loading,
  };
};

export const addExistingPatientCaseToSessionSuccessAction = (
  addExistingPatientCaseToSessionResponse: AddExistingPatientCaseToSessionDetails,
  successMsg: string,
): AddExistingPatientCaseToSessionActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_TO_SESSION_SUCCESS,
    payload: addExistingPatientCaseToSessionResponse,
    successMsg: successMsg,
  };
};

export const addExistingPatientCaseToSessionErrorAction = (
  addExistingPatientCaseToSessionResponse: AddExistingPatientCaseToSessionDetails,
  errMsg: string,
  status: number,
): AddExistingPatientCaseToSessionActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_TO_SESSION_FAIL,
    payload: addExistingPatientCaseToSessionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addExistingPatientCaseToSessionAPIMsgAction = (
  addExistingPatientCaseToSessionResponse: AddExistingPatientCaseToSessionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExistingPatientCaseToSessionActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_TO_SESSION_API_MSG,
    payload: addExistingPatientCaseToSessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addExistingPatientCaseToSessionAPIResClearAction = (
  addExistingPatientCaseToSessionResponse: AddExistingPatientCaseToSessionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddExistingPatientCaseToSessionActionTypes => {
  return {
    type: ADD_EXISTING_PATIENT_TO_SESSION_API_RES,
    payload: addExistingPatientCaseToSessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
