import {
  ADD_PATIENT_DATA_DETAILS_CLEAR_API_RES,
  ADD_PATIENT_DATA_DETAILS_FAIL,
  ADD_PATIENT_DATA_DETAILS_LOADING,
  ADD_PATIENT_DATA_DETAILS_SUCCESS,
  ADD_PATIENT_DATA_DETAILS_UPDATE_API_MSG,
  AddPatientDataPointActionTypes,
} from "./ActionTypes";
import { AddPatientPointDataDetails } from "./Model";

export const addPatientDataPointLoadingAction = (
  loading: boolean,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_DETAILS_LOADING,
    loading: loading,
  };
};

export const addPatientDataPointSuccessAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  successMsg: string,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_DETAILS_SUCCESS,
    payload: addPatientDataPointResponse,
    successMsg: successMsg,
  };
};

export const addPatientDataPointErrorAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  errMsg: string,
  status: number,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_DETAILS_FAIL,
    payload: addPatientDataPointResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientDataPointUpdateAPIMsgAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_DETAILS_UPDATE_API_MSG,
    payload: addPatientDataPointResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientDataPointAPIResClearAction = (
  addPatientDataPointResponse: AddPatientPointDataDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddPatientDataPointActionTypes => {
  return {
    type: ADD_PATIENT_DATA_DETAILS_CLEAR_API_RES,
    payload: addPatientDataPointResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
