import { GetUnprocessedPatientDetailsListRes, PageCount } from "./Model";
export const GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING = "GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING";
export const GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS = "GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS";
export const GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL = "GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL";
export const GET_UNPROCESSED_PATIENT_DETAILS_LIST_DATA = "GET_UNPROCESSED_PATIENT_DETAILS_LIST_DATA";
export const GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG = "GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG";
export const GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES = "GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES";

export interface GetUnprocessedPatientDetailsListState {
  getUnprocessedPatientDetailsListRes: GetUnprocessedPatientDetailsListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetUnprocessedPatientDetailsListLoading {
  type: typeof GET_UNPROCESSED_PATIENT_DETAILS_LIST_LOADING;
  loading: boolean;
}
export interface GetUnprocessedPatientDetailsListSuccess {
  type: typeof GET_UNPROCESSED_PATIENT_DETAILS_LIST_SUCCESS;
  payload: GetUnprocessedPatientDetailsListRes;
}
export interface GetUnprocessedPatientDetailsListFail {
  type: typeof GET_UNPROCESSED_PATIENT_DETAILS_LIST_FAIL;
  payload: GetUnprocessedPatientDetailsListRes;
  errorMsg: string;
  status: number;
}
export interface GetUnprocessedPatientDetailsListAPIMsg {
  type: typeof GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_MSG;
  payload: GetUnprocessedPatientDetailsListRes;
  errorMsg: string;
  status: number;
}
export interface GetUnprocessedPatientDetailsListAPIRes {
  type: typeof GET_UNPROCESSED_PATIENT_DETAILS_LIST_API_RES;
  payload: GetUnprocessedPatientDetailsListRes;
  errorMsg: string;
  status: number;
}

interface GetUnprocessedPatientDetailsListDataAction {
  type: typeof GET_UNPROCESSED_PATIENT_DETAILS_LIST_DATA;
  payload: GetUnprocessedPatientDetailsListRes;
  pageCount: PageCount;
}

export type GetUnprocessedPatientDetailsListActionTypes =
  | GetUnprocessedPatientDetailsListLoading
  | GetUnprocessedPatientDetailsListSuccess
  | GetUnprocessedPatientDetailsListFail
  | GetUnprocessedPatientDetailsListAPIMsg
  | GetUnprocessedPatientDetailsListDataAction
  | GetUnprocessedPatientDetailsListAPIRes;
