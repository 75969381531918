import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  ToggleActiveRegistrationRequestActionTypes } from "./ActionTypes";
import { ToggleActiveRegReqBody, ToggleActiveRegReqRes } from "./Model";
import {
   toggleActiveRegistrationRequestAPIResClearAction,
   toggleActiveRegistrationRequestErrorAction,
   toggleActiveRegistrationRequestLoadingAction,
   toggleActiveRegistrationRequestSuccessAction,
   toggleActiveRegistrationRequestUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as ToggleActiveRegReqRes;
export const  toggleActiveRegistrationRequest = (payload: ToggleActiveRegReqBody) => {
  return function (dispatch: Dispatch< ToggleActiveRegistrationRequestActionTypes>) {
    dispatch( toggleActiveRegistrationRequestLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampRegistrationRequest/toggle_active_registration_request`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( toggleActiveRegistrationRequestLoadingAction(false));
        dispatch(
           toggleActiveRegistrationRequestSuccessAction(
            res.data !== undefined ? res.data : ({} as ToggleActiveRegReqRes),
            res.data.message !== undefined
              ? res.data.message
              : "healthcampRegistrationRequest status of is now False",
          ),
        );
      })
      .catch((error) => {
        dispatch( toggleActiveRegistrationRequestLoadingAction(false));
        dispatch(
           toggleActiveRegistrationRequestErrorAction(
            {} as ToggleActiveRegReqRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateToggleActiveRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch< ToggleActiveRegistrationRequestActionTypes>) {
    dispatch(
       toggleActiveRegistrationRequestUpdateAPIMsgAction(apiRes as ToggleActiveRegReqRes, "", "", 0),
    );
  };
};

export const clearToggleActiveRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch< ToggleActiveRegistrationRequestActionTypes>) {
    dispatch( toggleActiveRegistrationRequestAPIResClearAction({} as ToggleActiveRegReqRes, "", "", 0));
  };
};
