import {
    GET_SINGLE_ENCOUNTER_LIST_FAIL,
    GET_SINGLE_ENCOUNTER_LIST_LOADING,
    GET_SINGLE_ENCOUNTER_LIST_SUCCESS,
    GET_SINGLE_ENCOUNTER_LIST_API_MSG,
    GET_SINGLE_ENCOUNTER_LIST_API_RES,
    GetSingleEncounterListActionTypes,
    GET_SINGLE_ENCOUNTER_LIST_DATA,
  } from "./ActionTypes";
  import { GetSingleEncounterListRes, PageCount } from "./Model";
  
  export const getSingleEncounterListAction = (
    GetSingleEncounterListRes: GetSingleEncounterListRes,
    pageCount: PageCount,
  ): GetSingleEncounterListActionTypes => {
    return {
      type: GET_SINGLE_ENCOUNTER_LIST_DATA,
      payload: GetSingleEncounterListRes,
      pageCount: pageCount,
    };
  };
  
  export const getSingleEncounterListListLoadingAction = (
    loading: boolean,
  ): GetSingleEncounterListActionTypes => {
    return {
      type: GET_SINGLE_ENCOUNTER_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getSingleEncounterListListSuccessAction = (
    getSingleEncounterListResponse: GetSingleEncounterListRes,
  ): GetSingleEncounterListActionTypes => {
    return {
      type: GET_SINGLE_ENCOUNTER_LIST_SUCCESS,
      payload: getSingleEncounterListResponse,
    };
  };
  
  export const getSingleEncounterListListErrorAction = (
    getSingleEncounterListResponse: GetSingleEncounterListRes,
    errMsg: string,
    status: number,
  ): GetSingleEncounterListActionTypes => {
    return {
      type: GET_SINGLE_ENCOUNTER_LIST_FAIL,
      payload: getSingleEncounterListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getSingleEncounterListListAPIMsgAction = (
    getSingleEncounterListResponse: GetSingleEncounterListRes,
    errMsg: string,
    status: number,
  ): GetSingleEncounterListActionTypes => {
    return {
      type: GET_SINGLE_ENCOUNTER_LIST_API_MSG,
      payload: getSingleEncounterListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getSingleEncounterListListAPIResClearAction = (
    getSingleEncounterListResponse: GetSingleEncounterListRes,
    errMsg: string,
    status: number,
  ): GetSingleEncounterListActionTypes => {
    return {
      type: GET_SINGLE_ENCOUNTER_LIST_API_RES,
      payload: getSingleEncounterListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  