import {
  GET_HEALTHCAMP_ADMIN_LIST_FAIL,
  GET_HEALTHCAMP_ADMIN_LIST_LOADING,
  GET_HEALTHCAMP_ADMIN_LIST_SUCCESS,
  GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_MSG,
  GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_RES,
  GetHealthcampAdminListActionTypes,
} from "./ActionTypes";
import { GetHealthcampAdminListRes } from "./Model";

export const getHealthcampAdminListLoadingAction = (
  loading: boolean,
): GetHealthcampAdminListActionTypes => {
  return {
    type: GET_HEALTHCAMP_ADMIN_LIST_LOADING,
    loading: loading,
  };
};

export const getHealthcampAdminListSuccessAction = (
  getHealthcampAdminListResponse: GetHealthcampAdminListRes,
  successMsg: string,
): GetHealthcampAdminListActionTypes => {
  return {
    type: GET_HEALTHCAMP_ADMIN_LIST_SUCCESS,
    payload: getHealthcampAdminListResponse,
    successMsg: successMsg,
  };
};

export const getHealthcampAdminListErrorAction = (
  getHealthcampAdminListResponse: GetHealthcampAdminListRes,
  errMsg: string,
  status: number,
): GetHealthcampAdminListActionTypes => {
  return {
    type: GET_HEALTHCAMP_ADMIN_LIST_FAIL,
    payload: getHealthcampAdminListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getHealthcampAdminListUpdateAPIMsgAction = (
  getHealthcampAdminListResponse: GetHealthcampAdminListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthcampAdminListActionTypes => {
  return {
    type: GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_MSG,
    payload: getHealthcampAdminListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getHealthcampAdminListAPIResClearAction = (
  getHealthcampAdminListResponse: GetHealthcampAdminListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetHealthcampAdminListActionTypes => {
  return {
    type: GET_HEALTHCAMP_ADMIN_LIST_UPDATE_API_RES,
    payload: getHealthcampAdminListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
