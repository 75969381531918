import axios from "axios";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";
import {
  addExecutiveAPIResClearAction,
  addExecutiveErrorAction,
  addExecutiveLoadingAction,
  addExecutiveSuccessAction,
  addExecutiveUpdateAPIMsgAction,
} from "./Actions";
import { Dispatch } from "react";
import { AddExecutiveActionTypes } from "./ActionTypes";
import { headers } from "component/Utility";
import Url from "../../../ApiConfig";
import { AddExecutivePayload, AddExecutiveRes } from "./Model";

let apiRes = {} as AddExecutiveRes;
export const addExecutive = (payload: AddExecutivePayload) => {
  return function (dispatch: Dispatch<AddExecutiveActionTypes>) {
    dispatch(addExecutiveLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/executives/add_excutive`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addExecutiveLoadingAction(false));
        dispatch(
          addExecutiveSuccessAction(
            res.data !== undefined ? res.data : ({} as AddExecutiveRes),
            res.data.message !== undefined
              ? res.data.message
              : "New Executive has been added successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(addExecutiveLoadingAction(false));
        dispatch(
          addExecutiveErrorAction(
            {} as AddExecutiveRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddExecutiveAPIResMsg = () => {
  return function (dispatch: Dispatch<AddExecutiveActionTypes>) {
    dispatch(addExecutiveUpdateAPIMsgAction(apiRes as AddExecutiveRes, "", "", 0));
  };
};

export const clearAddExecutiveAPIRes = () => {
  return function (dispatch: Dispatch<AddExecutiveActionTypes>) {
    dispatch(addExecutiveAPIResClearAction({} as AddExecutiveRes, "", "", 0));
  };
};
