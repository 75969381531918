import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { EditInstanceActionTypes } from "./ActionTypes";
import { EditInstanceBody, EditInstanceDetails } from "./Model";
import {
  editInstanceAPIResClearAction,
  editInstanceErrorAction,
  editInstanceLoadingAction,
  editInstanceSuccessAction,
  editInstanceAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as EditInstanceDetails;
export const editInstanceApiCall = (payload: EditInstanceBody) => {
  return function (dispatch: Dispatch<EditInstanceActionTypes>) {
    dispatch(editInstanceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthcampinstance/edit_instance_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editInstanceLoadingAction(false));
        dispatch(
          editInstanceSuccessAction(
            res.data !== undefined ? res.data : ({} as EditInstanceDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(editInstanceLoadingAction(false));
        dispatch(
          editInstanceErrorAction(
            {} as EditInstanceDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateEditInstanceAPIResMsg = () => {
  return function (dispatch: Dispatch<EditInstanceActionTypes>) {
    dispatch(editInstanceAPIMsgAction(apiRes as EditInstanceDetails, "", "", 0));
  };
};

export const clearEditInstanceAPIRes = () => {
  return function (dispatch: Dispatch<EditInstanceActionTypes>) {
    dispatch(editInstanceAPIResClearAction({} as EditInstanceDetails, "", "", 0));
  };
};
