import {
  GetPreferenceDetailsActionTypes,
  GET_PREFERENCE_FAIL,
  GET_PREFERENCE_LOADING,
  GET_PREFERENCE_SUCCESS,
  GetPreferenceDetailsState,
  GET_PREFERENCE_UPDATE_API_MSG,
  GET_PREFERENCE_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPreferenceResponse } from "./Model";

const initialStateGetPosts: GetPreferenceDetailsState = {
  loading: false,
  GetPreferenceDetailsResponse: {} as GetPreferenceResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPreferenceReducer = (
  state = initialStateGetPosts,
  action: GetPreferenceDetailsActionTypes,
): GetPreferenceDetailsState => {
  switch (action.type) {
    case GET_PREFERENCE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PREFERENCE_SUCCESS:
      return {
        ...state,
        GetPreferenceDetailsResponse: action.payload,
        successMsg: action.successMsg,
      };
    case GET_PREFERENCE_FAIL:
      return {
        ...state,
        GetPreferenceDetailsResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PREFERENCE_UPDATE_API_MSG:
      return {
        ...state,
        GetPreferenceDetailsResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PREFERENCE_UPDATE_API_RES:
      return {
        ...state,
        GetPreferenceDetailsResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
