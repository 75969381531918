import { AddScheduleRes } from "./Model";
export const ADD_SCHEDULE_LOADING = "ADD_SCHEDULE_LOADING";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_FAIL = "ADD_SCHEDULE_FAIL";
export const ADD_SCHEDULE_UPDATE_API_MSG = "ADD_SCHEDULE_UPDATE_API_MSG";
export const ADD_SCHEDULE_UPDATE_API_RES = "ADD_SCHEDULE_UPDATE_API_RES";

export interface AddScheduleState {
  addScheduleRes: AddScheduleRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddScheduleLoading {
  type: typeof ADD_SCHEDULE_LOADING;
  loading: boolean;
}
export interface AddScheduleSuccess {
  type: typeof ADD_SCHEDULE_SUCCESS;
  payload: AddScheduleRes;
  successMsg: string;
}
export interface AddScheduleFail {
  type: typeof ADD_SCHEDULE_FAIL;
  payload: AddScheduleRes;
  errorMsg: string;
  status: number;
}
export interface AddScheduleUpdateAPIMsg {
  type: typeof ADD_SCHEDULE_UPDATE_API_MSG;
  payload: AddScheduleRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddScheduleUpdateAPIRes {
  type: typeof ADD_SCHEDULE_UPDATE_API_RES;
  payload: AddScheduleRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddScheduleActionTypes =
  | AddScheduleLoading
  | AddScheduleSuccess
  | AddScheduleFail
  | AddScheduleUpdateAPIMsg
  | AddScheduleUpdateAPIRes;
