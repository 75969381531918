

export const ADD_DR_ONBOARDING_REQUEST = 'ADD_DR_ONBOARDING_REQUEST';
export const ADD_DR_ONBOARDING_REQUEST_LOADING = "ADD_DR_ONBOARDING_REQUEST_LOADING";
export const ADD_DR_ONBOARDING_REQUEST_SUCCESS = "ADD_DR_ONBOARDING_REQUEST_SUCCESS";
export const ADD_DR_ONBOARDING_REQUEST_FAIL = "ADD_DR_ONBOARDING_REQUEST_FAIL";

// Get Active Corporate Package List
export interface AddDrOnboardingRequestStateType {
    loading: boolean;
    message: string;
    status: any;
  }
  interface AddDrOnboardingRequestActionType {
    type: typeof ADD_DR_ONBOARDING_REQUEST;
    message: string;
    status: any;
  }
  export interface AddDrOnboardingRequestLoading {
    type: typeof ADD_DR_ONBOARDING_REQUEST_LOADING;
  }
  export interface AddDrOnboardingRequestSuccess {
    type: typeof ADD_DR_ONBOARDING_REQUEST_SUCCESS;
    message: string;
    status: any;
  }
  export interface AddDrOnboardingRequestListFail {
    type: typeof ADD_DR_ONBOARDING_REQUEST_FAIL;
    message: string;
    status: any;
  }
export type AddDrOnboardingRequestTypes = AddDrOnboardingRequestActionType | AddDrOnboardingRequestLoading | AddDrOnboardingRequestSuccess | AddDrOnboardingRequestListFail;

export const DR_APPROVE_REJECT_REQUEST = 'DR_APPROVE_REJECT_REQUEST';
export const DR_APPROVE_REJECT_REQUEST_LOADING = "DR_APPROVE_REJECT_REQUEST_LOADING";
export const DR_APPROVE_REJECT_REQUEST_SUCCESS = "DR_APPROVE_REJECT_REQUEST_SUCCESS";
export const DR_APPROVE_REJECT_REQUEST_FAIL = "DR_APPROVE_REJECT_REQUEST_FAIL";

// Get Active Corporate Package List
export interface DrApproveOrRejectStateType {
    loading: boolean;
    message: string;
    status: any;
  }
  interface DrApproveOrRejectActionType {
    type: typeof DR_APPROVE_REJECT_REQUEST;
    message: string;
    status: any;
  }
  export interface DrApproveOrRejectLoading {
    type: typeof DR_APPROVE_REJECT_REQUEST_LOADING;
  }
  export interface DrApproveOrRejectSuccess {
    type: typeof DR_APPROVE_REJECT_REQUEST_SUCCESS;
    message: string;
    status: any;
  }
  export interface DrApproveOrRejectFail {
    type: typeof DR_APPROVE_REJECT_REQUEST_FAIL;
    message: string;
    status: any;
  }
export type DrApproveOrRejectTypes = DrApproveOrRejectActionType | DrApproveOrRejectLoading | DrApproveOrRejectSuccess | DrApproveOrRejectFail;