import { GetHealthCampDropDownListRes } from "./Model";
export const GET_HEALTH_CAMP_DROPDOWN_LIST_LOADING = "GET_HEALTH_CAMP_DROPDOWN_LIST_LOADING";
export const GET_HEALTH_CAMP_DROPDOWN_LIST_SUCCESS = "GET_HEALTH_CAMP_DROPDOWN_LIST_SUCCESS";
export const GET_HEALTH_CAMP_DROPDOWN_LIST_FAIL = "GET_HEALTH_CAMP_DROPDOWN_LIST_FAIL";
export const GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_MSG =
  "GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_MSG";
export const GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_RES =
  "GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_RES";

export interface GetHealthCampDropDownListState {
  getHealthCampDropDownListRes: GetHealthCampDropDownListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDropDownListLoading {
  type: typeof GET_HEALTH_CAMP_DROPDOWN_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthCampDropDownListSuccess {
  type: typeof GET_HEALTH_CAMP_DROPDOWN_LIST_SUCCESS;
  payload: GetHealthCampDropDownListRes;
  successMsg: string;
}
export interface GetHealthCampDropDownListFail {
  type: typeof GET_HEALTH_CAMP_DROPDOWN_LIST_FAIL;
  payload: GetHealthCampDropDownListRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDropDownListUpdateAPIMsg {
  type: typeof GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_MSG;
  payload: GetHealthCampDropDownListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampDropDownListUpdateAPIRes {
  type: typeof GET_HEALTH_CAMP_DROPDOWN_LIST_UPDATE_API_RES;
  payload: GetHealthCampDropDownListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthCampDropDownListActionTypes =
  | GetHealthCampDropDownListLoading
  | GetHealthCampDropDownListSuccess
  | GetHealthCampDropDownListFail
  | GetHealthCampDropDownListUpdateAPIMsg
  | GetHealthCampDropDownListUpdateAPIRes;
