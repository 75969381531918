// import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { Button, Card, CardContent } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "component/MWExceptionList";
import {
  ExecutiveSignupRequestlist,
  ExecutiveSignupRequestListBody,
} from "redux/Executive/ExecutiveSignupRequestList/Model";
import { executiveSignupRequestListApiCall } from "redux/Executive/ExecutiveSignupRequestList/API";
import dayjs from "dayjs";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {
  executiveSignupRequestListLoader?: boolean | any;
  requestListCount?: string | any;
};

export default function ExecutiveRequestList({
  executiveSignupRequestListLoader,
  requestListCount,
}: Props) {
  console.log(executiveSignupRequestListLoader);
  console.log(requestListCount);
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  // Get Executive List API
  const adminExecutiveList = useSelector((state: AppState) => state.executiveRequestListValue);

  useEffect(() => {
    adminExecutiveList?.executiveSignupRequestListRes?.executiveSignupRequestlist?.length !==
    undefined
      ? requestListCount(
          String(
            adminExecutiveList?.executiveSignupRequestListRes?.executiveSignupRequestlist?.length,
          ),
        )
      : requestListCount("0");
    if (
      adminExecutiveList?.executiveSignupRequestListRes?.executiveSignupRequestlist !== undefined
    ) {
      let dataPointArr =
        adminExecutiveList?.executiveSignupRequestListRes?.executiveSignupRequestlist.map(
          (element: ExecutiveSignupRequestlist) => ({
            id: element.id,
            executiveId: element.id,
            executiveEmail: element.email,
            createDate: dayjs(element.createDate).format("ddd, MMM D, YYYY"),
            status: element.status,
          }),
        );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminExecutiveList]);

  const columns: GridColDef[] = [
    {
      field: "executiveEmail",
      headerName: "Executive Email",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      minWidth: 200,
      align: "left",
    },

    {
      field: "createDate",
      headerName: "Create Date",
      sortable: false,
      minWidth: 200,
      align: "left",
    },

    {
      field: "id",
      headerName: "Approve",
      minWidth: 200,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DoneIcon />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            console.log(params);
            alert("Comming Soon");
          }}
          variant="outlined"
        >
          Approve
        </Button>
      ),
    },

    {
      field: "executiveId",
      headerName: "Reject",
      minWidth: 200,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ClearIcon />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            console.log(params);
            alert("Comming Soon");
          }}
          variant="outlined"
        >
          Reject
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      executiveSignupRequestListApiCall({ type: "incomplete" } as ExecutiveSignupRequestListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Executive List Table
  const ExecutiveTable = (
    <Card>
      <CardContent>
        {adminExecutiveList?.executiveSignupRequestListRes?.executiveSignupRequestlist &&
        adminExecutiveList?.executiveSignupRequestListRes?.executiveSignupRequestlist?.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {adminExecutiveList.loading
        ? executiveSignupRequestListLoader(true)
        : executiveSignupRequestListLoader(false)}

      {ExecutiveTable}
    </Box>
  );
}
