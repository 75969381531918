import axios from "axios";
import { Dispatch } from "react";
import Url from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { TelehealthListActionTypes } from "./ActionTypes";
import { TelehealthMeetingListResponse } from "./Model";
import { teleHealthErrorAction, teleHealthLoadingAction, teleHealthSuccessAction } from "./Actions";
import { headers } from "../../component/Utility";

export const getTelehealthMeetingList = (meetingListBody: object) => {
  return function (dispatch: Dispatch<TelehealthListActionTypes>) {
    dispatch(teleHealthLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/adminapp/api/meeting/active_meeting_list`, meetingListBody, {
        headers: headers,
      })
      .then((res) => {
        dispatch(teleHealthLoadingAction(false));
        dispatch(teleHealthSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(teleHealthLoadingAction(false));
        dispatch(
          teleHealthErrorAction(
            {} as TelehealthMeetingListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};
