import {
  ADMIN_TASK_LIST_API_MSG,
  ADMIN_TASK_LIST_CLEAR_API_RES,
  ADMIN_TASK_LIST_FAIL,
  ADMIN_TASK_LIST_LOADING,
  ADMIN_TASK_LIST_SUCCESS,
  AdminTaskDataState,
  AdminTaskListActionTypes,
} from "./ActionTypes";
import { AdminTaskListResponse } from "./Model";

const initialStateGetPosts: AdminTaskDataState = {
  loading: false,
  adminTaskRes: {} as AdminTaskListResponse,
  message: "",
  status: 0,
};

export const getAllTaskListReducer = (
  state = initialStateGetPosts,
  action: AdminTaskListActionTypes,
): AdminTaskDataState => {
  switch (action.type) {
    case ADMIN_TASK_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADMIN_TASK_LIST_SUCCESS:
      return {
        ...state,
        adminTaskRes: action.payload,
      };
    case ADMIN_TASK_LIST_FAIL:
      return {
        ...state,
        adminTaskRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADMIN_TASK_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        adminTaskRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADMIN_TASK_LIST_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        adminTaskRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
