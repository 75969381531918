import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AdminLoader from "../AdminLoader";
import MWPageTitle from "../../component/MWPageTitle";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import PatientListOfOrganization from "./PatientListOfOrganization";
import HealthCampListMappedWithOrganization from "./HealthCampListMappedWithOrganization";
import { GetOrganizationPatientListBody } from "../../redux/PatientListByOrganization/Model";
import { getPatientListByOrganization } from "../../redux/PatientListByOrganization/API";
import { clearOrgPtSearchAPIRes } from "../../redux/PatientSearchByOrganizationId/API";
import { getOrganizationListValue } from "../../redux/OrganizationList/API";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PatientOrganizationList } from "../../redux/OrganizationList/Model";
import { getHealthCampByOrganizationList } from "../../redux/getMappedHealthcampsByOrganization/ApiCall";
import { GetHealthCampByOrganizationBody } from "../../redux/getMappedHealthcampsByOrganization/Model";
import { clearHealthCampDropDownListAPIRes } from "../../redux/getAllHealthcampDropdownList/API";
import AddExistingPatientDialog from "./AddExistingPatientDialog";
import ViewOrganizationStaffList from "./ViewOrganizationStaffList";
import { patientSearchByObservationTypeApi } from "redux/PatientSearchByObservationType/API";
import { PatientSearchListByObservationTypeBody } from "redux/PatientSearchByObservationType/Model";
import {
  clearOrganizationAddRegistrationRequestAPIRes,
  organizationAddRegistrationRequestApiCall,
} from "redux/OrganizationAddRegistrationRequest/API";
import { OrganizationAddRegistrationRequestBody } from "redux/OrganizationAddRegistrationRequest/Model";
import { LoadingButton } from "@mui/lab";
import MWSnackbar from "component/MWSnackbar";
import { getOrganizationRegistrationRequestList } from "redux/OrganizationRegistrationRequestList/API";
import { GetOrganizationRegistrationRequestListBody } from "redux/OrganizationRegistrationRequestList/Model";
import OrganizationAddPatientDialog from "pages/OrganizationAddPatientDialog";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CorporatePackage() {
  let query = UseQuery();
  const tabId = query.get("section");
  const obType = query.get("obType");
  const date = query.get("date");
  const requestId = query.get("requestId");
  const history = useHistory();
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  let type = "organization";
  const loadingMarkup = <AdminLoader />;
  const [pageTitle, setPageTitle] = React.useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [listCount, setListCount] = React.useState("");
  const [selected, setSelected] = React.useState(0);
  const [patientListByOrgLoader, setPatientListByOrgLoader] = useState(false);
  const [viewMappedHealcampListLoader, setViewMappedHealcampListLoader] = useState(false);
  const [viewOrgStaffListloader, setViewOrgStaffListLoader] = useState(false);
  const [viewMappedHealthcampCount, setViewMappedHealthcampCount] = React.useState("");
  const [showAddExistingPatientDialog, setShowAddExistingPatientDialog] = React.useState(false);

  const getOrganizationRegistrationRequestListValuesRes = useSelector(
    (state: AppState) => state.getOrganizationRegistrationRequestListValues,
  );
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  const [addRegistrationRequestPt, setAddRegistrationRequestPt] = useState("");

  const registrationRequestList = () => {
    history.push(`/organizationregistrationrequest/${organizationid}`);
  };
  const updateExistingDialogState = () => {
    setShowAddExistingPatientDialog(true);
  };
  const updateAddExistingPatientDialogValFromChild = (passedVal: boolean) => {
    setShowAddExistingPatientDialog(passedVal);
  };
  const [tabValue, setTabValue] = useState(0);
  const [showAddPatientDialog, setShowAddPatientDialog] = React.useState(false);
  useEffect(() => {
    if (requestId !== null) {
      setShowAddPatientDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);
  const updateDialogState = () => {
    setShowAddPatientDialog(true);
    dispatch(
      organizationAddRegistrationRequestApiCall({
        organizationId: organizationid,
      } as OrganizationAddRegistrationRequestBody),
    );
  };
  const updateAddPatientDialog = (passedVal: boolean) => {
    setShowAddPatientDialog(passedVal);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=patientList"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=mappedHealthcampList"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=staffList"}`);
    }
  };

  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "patientList") {
      setTabValue(0);
    } else if (tabId === "mappedHealthcampList") {
      setTabValue(1);
    } else if (tabId === "staffList") {
      setTabValue(2);
    }
    // eslint-disable-next-line
  }, [tabId]);

  useEffect(() => {
    if (obType !== null && date !== null) {
      dispatch(
        patientSearchByObservationTypeApi(
          {
            patientObservationType: decodeURIComponent(obType),
            organizationId: organizationid,
            startDate: decodeURIComponent(date),
          } as PatientSearchListByObservationTypeBody,
          1,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obType, date]);
  // call Store
  const AdminAllOrganizationList = useSelector((state: AppState) => state.organizationListValue);
  const AdminPatientList = useSelector((state: AppState) => state.organizationPatientListValue);
  const organizationAddRegistrationRequestValueRes = useSelector(
    (state: AppState) => state.OrganizationAddRegistrationRequestValue,
  );
  const addPatientValueRes = useSelector((state: AppState) => state.addHealthcampPatientData);
  useEffect(() => {
    organizationName !== "" ? setPageTitle(`${organizationName}`) : setPageTitle("");
  }, [organizationName]);

  const [addCheckInMsg, setAddPatientSuccessMsg] = useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");

  const addCheckInMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setAddPatientSuccessMsg("");
    }
  };

  useEffect(() => {
    if (addPatientValueRes?.successMsg !== "") {
      setAddPatientSuccessMsg(addPatientValueRes?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(
        getOrganizationRegistrationRequestList({
          organizationId: organizationid,
        } as GetOrganizationRegistrationRequestListBody),
      );
    }
    if (addPatientValueRes?.errorMsg) {
      setAddPatientSuccessMsg(addPatientValueRes?.errorMsg);
      setLinkImageToCompetitionMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  useEffect(() => {
    dispatch(clearOrgPtSearchAPIRes());
    dispatch(getOrganizationListValue());
    dispatch(
      getOrganizationRegistrationRequestList({
        organizationId: organizationid,
      } as GetOrganizationRegistrationRequestListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (AdminAllOrganizationList?.getOrganizationRes?.patientOrganizationList !== undefined) {
      var result = AdminAllOrganizationList?.getOrganizationRes?.patientOrganizationList!.find(
        (obj: PatientOrganizationList) => {
          return obj.id === Number(organizationid);
        },
      )?.displayName;
      if (result !== null) {
        setOrganizationName(result!);
      }
    }
    // eslint-disable-next-line
  }, [AdminAllOrganizationList]);

  useEffect(() => {
    AdminPatientList?.PatientListOfOrganizationIdRes?.count !== undefined
      ? setListCount(String(AdminPatientList?.PatientListOfOrganizationIdRes?.count))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.getHealthCampByOrganizationList,
  );

  useEffect(() => {
    AdminAllHealthcampList.getHealthCampByOrganizationListRes?.healthcampList !== undefined
      ? setViewMappedHealthcampCount(
          String(AdminAllHealthcampList.getHealthCampByOrganizationListRes?.healthcampList.length),
        )
      : setViewMappedHealthcampCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);

  useEffect(() => {
    if (organizationAddRegistrationRequestValueRes?.successMsg !== "") {
      dispatch(
        getOrganizationRegistrationRequestList({
          organizationId: organizationid,
        } as GetOrganizationRegistrationRequestListBody),
      );
      setAddRegistrationRequestPt(organizationAddRegistrationRequestValueRes?.successMsg);
      setSnackBarType("success");
      if (
        organizationAddRegistrationRequestValueRes?.OrganizationAddRegistrationRequestRes?.id !==
        undefined
      ) {
        history.push(
          `/patientlistbyorganization/${organizationid}/?requestId=${organizationAddRegistrationRequestValueRes?.OrganizationAddRegistrationRequestRes?.id}`,
        );
      }
    }
    if (organizationAddRegistrationRequestValueRes?.errorMsg !== "") {
      setAddRegistrationRequestPt(organizationAddRegistrationRequestValueRes?.errorMsg);
      setSnackBarType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationAddRegistrationRequestValueRes]);

  const closeMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearOrganizationAddRegistrationRequestAPIRes());
      setAddRegistrationRequestPt("");
    }
  };

  function organizationPatientListApi() {
    const patientListBody = {
      organizationId: organizationid,
    } as GetOrganizationPatientListBody;
    dispatch(getPatientListByOrganization(patientListBody, 1));
  }
  function healthCampListByOrgApi() {
    const mappedHealthCampByOrgBody = {
      organizationId: organizationid,
    } as GetHealthCampByOrganizationBody;
    dispatch(getHealthCampByOrganizationList(mappedHealthCampByOrgBody));
    dispatch(clearHealthCampDropDownListAPIRes());
  }

  const patientListByOrganizationPage = (
    <Grid>
      <Grid item>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs example"
            scrollButtons
            TabIndicatorProps={{
              style: {
                backgroundColor: "#047ab3",
              },
            }}
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>View Mapped Healthcamp List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={String(viewMappedHealthcampCount)}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    healthCampListByOrgApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Patient List By Organization</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={listCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    organizationPatientListApi();
                  }}
                />
              }
              iconPosition="end"
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>View Organization Staff List</Typography>
                  </Grid>
                </Grid>
              }
              {...a11yProps(2)}
            />
          </Tabs>
          <Box>
            <TabPanel value={tabValue} index={0}>
              <HealthCampListMappedWithOrganization
                viewMappedHealcampListLoader={setViewMappedHealcampListLoader}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <PatientListOfOrganization
                patientListByOrgLoader={setPatientListByOrgLoader}
                listCount={setListCount}
                obType={obType}
                date={date}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <ViewOrganizationStaffList viewOrgStaffListloader={setViewOrgStaffListLoader} />
            </TabPanel>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {patientListByOrgLoader === true ||
      viewMappedHealcampListLoader === true ||
      viewOrgStaffListloader === true
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle backButton={true} title={pageTitle} />
            </Grid>
            <Grid item>
              <Box>
                <ButtonGroup variant="outlined" disableElevation aria-label="Disabled button group">
                  <Button
                    onClick={() => registrationRequestList()}
                    sx={{ textTransform: "none" }}
                    disabled={
                      getOrganizationRegistrationRequestListValuesRes
                        ?.patientOrgnizationRegistrationRequestList
                        ?.patientOrgnizationRegistrationRequestList?.length > 0
                        ? false
                        : true
                    }
                  >
                    Registration Request List
                  </Button>
                  <Button
                    onClick={() => updateExistingDialogState()}
                    sx={{ textTransform: "none" }}
                  >
                    Add Existing Patient
                  </Button>
                  <LoadingButton
                    onClick={() => updateDialogState()}
                    loading={organizationAddRegistrationRequestValueRes?.loading}
                    variant="contained"
                    sx={{ textTransform: "none" }}
                  >
                    Add New Patient
                  </LoadingButton>
                </ButtonGroup>
              </Box>
            </Grid>
          </Grid>
          {patientListByOrganizationPage}
          {showAddPatientDialog ? (
            <OrganizationAddPatientDialog
              itemId={organizationid}
              type={type}
              addPatientDialogEnable={showAddPatientDialog}
              addPatientDialogUpdateState={updateAddPatientDialog}
            />
          ) : null}
          {showAddExistingPatientDialog ? (
            <AddExistingPatientDialog
              itemId={organizationid}
              title={pageTitle}
              addExistingPatientDialogEnable={showAddExistingPatientDialog}
              addExistingPatientDialogUpdateState={updateAddExistingPatientDialogValFromChild}
            />
          ) : null}
        </Stack>
      </Container>
      {addRegistrationRequestPt !== "" ? (
        <MWSnackbar msg={addRegistrationRequestPt} type={snackBarType} alertClose={closeMsg} />
      ) : null}
      {addCheckInMsg !== "" ? (
        <MWSnackbar
          msg={addCheckInMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addCheckInMsgClose}
        />
      ) : null}
    </Box>
  );
}
