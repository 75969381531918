import { GetDashboardHealthcampRequestListRes } from "./Model";
export const DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_LOADING = "DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_LOADING";
export const DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_SUCCESS = "DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_SUCCESS";
export const DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_FAIL = "DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_FAIL";
export const DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG = "DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG";
export const DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES = "DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES";

export interface GetDashboardHealthCampRegistrationListState {
  getDashboardHealthcampRegistrationListResponse: GetDashboardHealthcampRequestListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDashboardHealthCampRegistrationListLoading {
  type: typeof DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_LOADING;
  loading: boolean;
}
export interface GetDashboardHealthCampRegistrationListSuccess {
  type: typeof DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_SUCCESS;
  payload: GetDashboardHealthcampRequestListRes;
  successMsg: string;
}
export interface GetDashboardHealthCampRegistrationListFail {
  type: typeof DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_FAIL;
  payload: GetDashboardHealthcampRequestListRes;
  errorMsg: string;
  status: number;
}
export interface GetDashboardHealthCampRegistrationListUpdateAPIMsg {
  type: typeof DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_MSG;
  payload: GetDashboardHealthcampRequestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDashboardHealthCampRegistrationListUpdateAPIRes {
  type: typeof DASHBOARD_HEALTHCAMP_REGISTRATION_LIST_UPDATE_API_RES;
  payload: GetDashboardHealthcampRequestListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDashboardHealthCampRegistrationListActionTypes =
  | GetDashboardHealthCampRegistrationListLoading
  | GetDashboardHealthCampRegistrationListSuccess
  | GetDashboardHealthCampRegistrationListFail
  | GetDashboardHealthCampRegistrationListUpdateAPIMsg
  | GetDashboardHealthCampRegistrationListUpdateAPIRes;
