import {
  GetLastModifiedPatientListActionTypes,
  GET_LAST_MODIFIED_PATIENT_LIST_FAIL,
  GET_LAST_MODIFIED_PATIENT_LIST_LOADING,
  GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS,
  GetLastModifiedPatientListState,
  GET_LAST_MODIFIED_PATIENT_LIST_API_MSG,
  GET_LAST_MODIFIED_PATIENT_LIST_API_RES,
} from "./ActionTypes";
import { GetLastModifiedPatientListRes } from "./Model";

const initialStateUpdatePosts: GetLastModifiedPatientListState = {
  loading: false,
  getLastModifiedPatientListRes: {} as GetLastModifiedPatientListRes,
  errorMsg: "",
  status: 0,
};
export const getLastModifiedPatientListReducer = (
  state = initialStateUpdatePosts,
  action: GetLastModifiedPatientListActionTypes,
): GetLastModifiedPatientListState => {
  switch (action.type) {
    case GET_LAST_MODIFIED_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        getLastModifiedPatientListRes: action.payload,
      };
    case GET_LAST_MODIFIED_PATIENT_LIST_FAIL:
      return {
        ...state,
        getLastModifiedPatientListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_LAST_MODIFIED_PATIENT_LIST_API_MSG:
      return {
        ...state,
        getLastModifiedPatientListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_LAST_MODIFIED_PATIENT_LIST_API_RES:
      return {
        ...state,
        getLastModifiedPatientListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
