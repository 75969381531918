import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../../../../../redux/store/Store";
import { Link, useHistory, useParams } from "react-router-dom";
import AdminLoader from "../../../../../../../../../AdminLoader";
import {
  Typography,
  Box,
  Button,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  AlertColor,
  Breadcrumbs,
  ButtonGroup,
  Tab,
} from "@mui/material";
import { clearAddPatientAPIRes } from "redux/addHealthcampPatient/API";
import MWSnackbar from "component/MWSnackbar";
import { getPatientDetailsListBySessionIdApi } from "redux/HealthcampSessionList/PatientDetailsListBySessionId/API";
import { GetPatientDetailsListBySessionIdBody } from "redux/HealthcampSessionList/PatientDetailsListBySessionId/Model";
import { clearPatientDataPointClearAPIRes } from "redux/HealthcampSessionList/addPatientDetails/API";
import { clearAddExistingPatientDetailsBySessionIdAPIRes } from "redux/AddExistingPatientDetailsBySession/API";
import { HealthCampList } from "redux/healthcampList/Model";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import { GetSessionListBody } from "redux/HealthcampSessionList/Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import _ from "lodash";
import {
  GetPatientListBySessionIdBody,
  PatientList,
} from "redux/HealthcampSessionList/PatientListBySessionId/Model";
import { getPatientListBySessionIdApi } from "redux/HealthcampSessionList/PatientListBySessionId/API";
import MWPageTitle from "component/MWPageTitle";
import { getVitalCollectionStatusApi } from "redux/VitalCollectionStatus/GetVitalCollectionStatus/API";
import { GetVitalCollectionStatusBody } from "redux/VitalCollectionStatus/GetVitalCollectionStatus/Model";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PatientDetailsList from "./PatientDetailsList";
import PatientCaseList from "./PatientCaseList";

export default function PatientDetailsListBySessionId() {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const patientId = patientid.split("-")[0];
  const pageNo = patientid.split("-")[1];

  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const addPatientDetailsInCompetitionRes = useSelector(
    (state: AppState) => state.addPatientDetailsInCompetitionValue,
  );

  const addDataDetailsStoreVal = useSelector(
    (state: AppState) => state.addPatientDetailsBySessionValue,
  );
  const addExistingPatientResponse = useSelector(
    (state: AppState) => state.addExistingPatientDetailsValue,
  );
  const getPatientListValues = useSelector(
    (state: AppState) => state.getPatientListBySessionIdValues,
  );
  const activeInactiveResponse = useSelector((state: AppState) => state.activeInactiveStatusValue);
  const [patientDetailsListLoader, setPatientDetailsListLoader] = React.useState(false);
  const [patientCaseListLoader, setPatientCaseListLoader] = React.useState(false);
  const history = useHistory();
  // const [page, setPage] = React.useState(1);
  const patientDetailsBody = {
    healthcampSessionId: String(sessionId),
    patientId: String(patientId),
  } as GetPatientDetailsListBySessionIdBody;

  // const [pageListCount, setPageListCount] = React.useState("");
  const [getPatientListObject, setGetPatientListObject] = React.useState<PatientList | undefined>(
    undefined,
  );
  const [updateDataPointSuccess, setUpdateDataPointSuccess] = React.useState("");
  const [statusSuccess, setStatusSuccess] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    React.useState<AlertColor>("success");
  const sessionValues: any | undefined = _.find(
    sessionListResponseValue?.getSessionList?.healthcampSessionList,
    ["id", Number(sessionId)],
  );
  const healthcampValues: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );
  const instanceValues: HealthcampInstanceList | undefined = _.find(
    instanceListResponseValue.getInstanceList?.healthcampInstanceList,
    ["id", Number(instanceHealthcampId)],
  );
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  useEffect(() => {
    if (getPatientListObject === undefined) {
      let apiBody = {
        healthcampSessionId: sessionId,
      } as GetPatientListBySessionIdBody;
      dispatch(getPatientListBySessionIdApi(apiBody, pageNo));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListObject]);

  useEffect(() => {
    if (getPatientListValues?.getPatientListBySessionIdRes?.patientList !== undefined) {
      setGetPatientListObject(
        _.find(getPatientListValues?.getPatientListBySessionIdRes?.patientList, [
          "patientId",
          Number(patientId),
        ]),
      );
    } else {
      setGetPatientListObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListValues]);

  useEffect(() => {
    if (sessionValues === undefined) {
      const healthcampSessionListBody = {
        healthcampInstanceId: instanceHealthcampId,
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionValues]);

  useEffect(() => {
    if (instanceValues === undefined) {
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceValues]);

  useEffect(() => {
    if (healthcampValues === undefined) {
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampValues]);

  useEffect(() => {
    dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addPatientDetailsInCompetitionRes?.successMsg !== "") {
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDetailsInCompetitionRes]);
  useEffect(() => {
    if (activeInactiveResponse?.successMsg !== "") {
      setStatusSuccess(activeInactiveResponse?.successMsg);
      let getStatusBody = {
        healthcampSessionId: String(sessionId),
        patientId: String(patientId),
      } as GetVitalCollectionStatusBody;
      dispatch(getVitalCollectionStatusApi(getStatusBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInactiveResponse]);
  React.useEffect(() => {
    if (addDataDetailsStoreVal?.addPatientDataDetailsRes?.message !== undefined) {
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, 1));
      dispatch(clearPatientDataPointClearAPIRes());
      let getStatusBody = {
        healthcampSessionId: String(sessionId),
        patientId: String(patientId),
      } as GetVitalCollectionStatusBody;
      dispatch(getVitalCollectionStatusApi(getStatusBody));
    }
    if (addDataDetailsStoreVal?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);

  React.useEffect(() => {
    if (
      addExistingPatientResponse?.addExistingPatientDetailsBySessionIdRes?.message !== undefined
    ) {
      setUpdateDataPointSuccess(addExistingPatientResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, 1));
      dispatch(clearAddExistingPatientDetailsBySessionIdAPIRes());
    }
    if (addExistingPatientResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setUpdateDataPointSuccess(addExistingPatientResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExistingPatientResponse]);

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Box>
            <TabContext value={String(selected)}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange}>
                  <Tab
                    label={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>Patient Details List</Typography>
                        </Grid>
                        <Grid item>
                          {/* <Chip label={deactiveListCount} variant="outlined" size="small" /> */}
                        </Grid>
                      </Grid>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>Patient Case List</Typography>
                        </Grid>
                        {/* <Grid item>
                    <Chip label={listCount} variant="outlined" size="small" />
                  </Grid> */}
                      </Grid>
                    }
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <PatientDetailsList patientDetailsListLoader={setPatientDetailsListLoader} />
              </TabPanel>
              <TabPanel value="2">
                <PatientCaseList patientCaseListLoader={setPatientCaseListLoader} />
              </TabPanel>
            </TabContext>
          </Box>
        }
      />
    </Card>
  );
  const goToPatientDetails = () => {
    history.push(`/patientdetails/${patientId}`);
  };
  useEffect(() => {
    if (addPatientDetailsInCompetitionRes?.successMsg !== "") {
      dispatch(clearAddPatientAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDetailsInCompetitionRes]);
  const updateAddPatientDetailsInCompetitionRes = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddPatientAPIRes());
    }
  };
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearPatientDataPointClearAPIRes());
      setStatusSuccess("");
    }
  };

  const BreadcrumbsSection = (
    <Breadcrumbs aria-label="breadcrumb">
      {healthcampValues?.displayName !== undefined ? (
        <Link
          color="inherit"
          to={`/patientlistbyhealthcamp/${healthcampid}?section=instanceList`}
          style={{ textDecoration: "none" }}
        >
          <Typography>
            {healthcampValues?.displayName!.slice(0, 26) +
              "... " +
              healthcampValues?.displayName!.slice(-10)}
          </Typography>
        </Link>
      ) : null}

      {instanceValues?.displayName !== undefined ? (
        <Link
          color="inherit"
          to={`/healthcampinstance/${healthcampid}/${instanceHealthcampId}`}
          style={{ textDecoration: "none" }}
        >
          <Typography>
            {instanceValues?.displayName!.slice(0, 26) +
              "... " +
              instanceValues?.displayName!.slice(-10)}
          </Typography>
        </Link>
      ) : null}
      {sessionValues?.displayName !== undefined ? (
        <Link
          color="inherit"
          to={`/healthcampinstance_session/${healthcampid}/${instanceHealthcampId}/${sessionId}`}
          style={{ textDecoration: "none" }}
        >
          <Typography>
            {sessionValues?.displayName!.slice(0, 26) +
              (sessionValues?.displayName.length > 26
                ? "... " + sessionValues?.displayName!.slice(-10)
                : "")}
          </Typography>
        </Link>
      ) : null}
      {getPatientListObject?.givenName !== undefined ? (
        <Link
          color="inherit"
          to={`/healthcampsession/${healthcampid}/${instanceHealthcampId}/${sessionId}/${patientId}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="text.primary">
            {getPatientListObject?.givenName + " " + getPatientListObject?.familyName}
          </Typography>
        </Link>
      ) : null}
    </Breadcrumbs>
  );

  return (
    <Box>
      {patientDetailsListLoader || patientCaseListLoader ? <AdminLoader /> : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <MWPageTitle backButton={true} />
                </Grid>
                <Grid item>{BreadcrumbsSection}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item>
                  <ButtonGroup
                    variant="outlined"
                    disableElevation
                    aria-label="Disabled button group"
                  >
                    <Button onClick={goToPatientDetails}>View Patient Dashboard</Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {patientPointDataTable}

          {addPatientDetailsInCompetitionRes?.successMsg !== "" ? (
            <MWSnackbar
              msg={addPatientDetailsInCompetitionRes?.successMsg}
              type="success"
              alertClose={updateAddPatientDetailsInCompetitionRes}
            />
          ) : null}
          {statusSuccess ? (
            <MWSnackbar
              msg={statusSuccess}
              type={linkImageToCompetitionMsgType}
              alertClose={checkAlertClose}
            />
          ) : null}
          <MWSnackbar
            msg={updateDataPointSuccess}
            type={linkImageToCompetitionMsgType}
            alertClose={checkAlertClose}
          />
        </Stack>
      </Container>
    </Box>
  );
}
