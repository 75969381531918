import {
  OPINION_TYPE_API_MSG,
    OPINION_TYPE_API_RES,
    OPINION_TYPE_DATA,
    OPINION_TYPE_FAIL,
    OPINION_TYPE_LOADING,
    OPINION_TYPE_SUCCESS,
    OpinionTypeActionTypes,
  } from "./ActionTypes";
  import { OpinionTypeResponse } from "./Model";
  
  export const opinionTypeAction = (
    opinionTypeRes: OpinionTypeResponse
  ): OpinionTypeActionTypes => {
    return {
      type: OPINION_TYPE_DATA,
      payload: opinionTypeRes,
    };
  };
  
  export const opinionTypeLoadingAction = (
    loading: boolean
  ): OpinionTypeActionTypes => {
    return {
      type: OPINION_TYPE_LOADING,
      loading: loading,
    };
  };
  
  export const opinionTypeSuccessAction = (
    opinionTypeRes: OpinionTypeResponse,
    successMsg: string,
    status: number    
  ): OpinionTypeActionTypes => {
    return {
      type: OPINION_TYPE_SUCCESS,
      payload: opinionTypeRes,
      successMsg: successMsg,
      status: status      
    };
  };
  
  export const opinionTypeErrorAction = (
    opinionTypeRes: OpinionTypeResponse,
    errMsg: string,
    status: number
  ): OpinionTypeActionTypes => {
    return {
      type: OPINION_TYPE_FAIL,
      payload: opinionTypeRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const opinionTypeUpdateAPIMsgAction = (
    opinionTypeRes: OpinionTypeResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): OpinionTypeActionTypes => {
    return {
      type: OPINION_TYPE_API_MSG,
      payload: opinionTypeRes,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const opinionTypeAPIResClearAction = (
    opinionTypeRes: OpinionTypeResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): OpinionTypeActionTypes => {
    return {
      type: OPINION_TYPE_API_RES,
      payload: opinionTypeRes,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  