import { AddPatientInCorporatePacckageStateType, AddPatientInCorporatePackageTypes, ADD_PATIENT_IN_CORPORATE_PACKAGE_FAIL, ADD_PATIENT_IN_CORPORATE_PACKAGE_LOADING, ADD_PATIENT_IN_CORPORATE_PACKAGE_SUCCESS, GetPatientTeamListStateType, PatientTeamListTypes, PATIENT_TEAM_LIST_FAIL, PATIENT_TEAM_LIST_LOADING, PATIENT_TEAM_LIST_SUCCESS } from "./ActionTypes";

const initialStateGetPatientList: GetPatientTeamListStateType = { loading: false, patientTeamListValue: [], message: Object.assign({}), status: Object.assign({}) };

export const getPatientTeamListReducer = (state = initialStateGetPatientList, action: PatientTeamListTypes): GetPatientTeamListStateType => {
    switch (action.type) {
        case PATIENT_TEAM_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                patientTeamListValue: action.payload,
                message: action.message,
                status: action.status
            };
        case PATIENT_TEAM_LIST_LOADING:
            return {
                ...state,
                loading: true,
                patientTeamListValue: []
            };
        case PATIENT_TEAM_LIST_FAIL:
            return {
                ...state,
                message: action.message,
                status: action.status,
                loading: false
            };
        default:
            return state;
    }
};

// Add patient in corporate package
const initialStateAddPatient: AddPatientInCorporatePacckageStateType = { loading: false, message: Object.assign({}), status: Object.assign({}) };

export const addPatientInPackageReducer = (state = initialStateAddPatient, action: AddPatientInCorporatePackageTypes): AddPatientInCorporatePacckageStateType => {
    switch (action.type) {
        case ADD_PATIENT_IN_CORPORATE_PACKAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case ADD_PATIENT_IN_CORPORATE_PACKAGE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ADD_PATIENT_IN_CORPORATE_PACKAGE_FAIL:
            return {
                ...state,
                message: action.message,
                status: action.status,
                loading: false
            };
        default:
            return state;
    }
};