import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { Box, Card, CardContent, Chip, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getActiveListByOrgId } from "../../../redux/ViewOrganizationStaffList/ActiveListByOrganizationId/API";
import { GetActiveListByOrgIdBody } from "../../../redux/ViewOrganizationStaffList/ActiveListByOrganizationId/Model";
import ActiveStaffList from "./ActiveStaffList";
import DeactiveStaffList from "./DeactiveStaffList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GetOrganizationDeactiveStaffListBody } from "redux/ViewOrganizationStaffList/OrganizationDeactiveStaffList/Model";
import { getDeactiveStaffListByOrganizationId } from "redux/ViewOrganizationStaffList/OrganizationDeactiveStaffList/API";
import AdminList from "./AdminList";
import { GetOrganizationAdminListBody } from "redux/ViewOrganizationStaffList/AdminListByOrganizationId/Model";
import { getOrganizationAdminListByOrgId } from "redux/ViewOrganizationStaffList/AdminListByOrganizationId/API";

type Props = {
  viewOrgStaffListloader?: boolean | any;
};

export default function ViewOrganizationStaffList({ viewOrgStaffListloader }: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  // call Store
  // const viewOrgStaffList = useSelector((state: AppState) => state.getWiewOrgStaffListValue);
  const deactiveStaffListOfOrg = useSelector(
    (state: AppState) => state.organizationDeactiveStaffValues,
  );
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [activeListCount, setActiveListCount] = React.useState("");
  const [deactiveListCount, setDeactiveListCount] = React.useState("");
  const [adminListCount, setAdminListCount] = React.useState("");
  const changeAdminCount = (value: string) => {
    setAdminListCount(value);
  };
  const changeActiveListCount = (value: string) => {
    setActiveListCount(value);
  };

  useEffect(() => {
    if (deactiveStaffListOfOrg?.getOrganizationDeactiveStaffList?.count !== undefined) {
      setDeactiveListCount(String(deactiveStaffListOfOrg?.getOrganizationDeactiveStaffList?.count));
    } else setDeactiveListCount("0");
  }, [deactiveStaffListOfOrg]);
  const reloadDeactiveStaffListApi = () => {
    const patientListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 0,
    } as GetOrganizationDeactiveStaffListBody;
    dispatch(getDeactiveStaffListByOrganizationId(patientListBody, 1));
  };

  const StaffListOfOrganization = (
    <Card>
      <CardContent>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Admin List</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={adminListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        const viewOrgAdminListBody = {
                          organizationId: organizationid,
                          isActiveOrAdmin: 2,
                        } as GetOrganizationAdminListBody;
                        dispatch(getOrganizationAdminListByOrgId(viewOrgAdminListBody, 1));
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Active List</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={activeListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        const activeListBody = {
                          organizationId: organizationid,
                          isActiveOrAdmin: 1,
                        } as GetActiveListByOrgIdBody;
                        dispatch(getActiveListByOrgId(activeListBody, 1));
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Inactive List</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={deactiveListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadDeactiveStaffListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <AdminList
                viewOrgStaffListloader={viewOrgStaffListloader}
                adminListCount={changeAdminCount}
              />
            </TabPanel>
            <TabPanel value="2">
              <ActiveStaffList
                viewOrgStaffListloader={viewOrgStaffListloader}
                activeListCount={changeActiveListCount}
              />
            </TabPanel>
            <TabPanel value="3">
              <DeactiveStaffList viewOrgStaffListloader={viewOrgStaffListloader} />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );

  return <Box>{StaffListOfOrganization}</Box>;
}
