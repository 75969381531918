import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  editPatientImportConfigActionTypes,
  EDIT_PATIENT_IMPORT_CONFIG_FAIL,
  EDIT_PATIENT_IMPORT_CONFIG_LOADING,
  EDIT_PATIENT_IMPORT_CONFIG_SUCCESS,
} from "./ActionTypes";
import {
  EditPatientImportConfigDetails,
  EditPatientImportConfigValue,
} from "./Model";
import { headers } from "../../component/Utility";

export const editPatientImportConfigApi = (
  body: EditPatientImportConfigDetails
) => {
  return function (dispatch: Dispatch<editPatientImportConfigActionTypes>) {
    dispatch({
      type: EDIT_PATIENT_IMPORT_CONFIG_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/dataimport/edit_patient_import_config`,
        body,
        { headers: headers }
      )
      .then((res) => {
        dispatch({
          type: EDIT_PATIENT_IMPORT_CONFIG_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_PATIENT_IMPORT_CONFIG_FAIL,
          payload: {} as EditPatientImportConfigValue,
          errRes: error.response.data.message,
        });
      });
  };
};
