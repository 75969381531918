import {
    EDIT_HEALTHCAMP_NAME_FAIL,
    EDIT_HEALTHCAMP_NAME_LOADING,
    EDIT_HEALTHCAMP_NAME_SUCCESS,
    EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG,
    EDIT_HEALTHCAMP_NAME_UPDATE_API_RES,
    EditHealthcampNameActionTypes,
  } from "./ActionTypes";
  import { EditHealthcampNameRes } from "./Model";
  
  export const editHealthcampNameLoadingAction = (
    loading: boolean,
  ): EditHealthcampNameActionTypes => {
    return {
      type: EDIT_HEALTHCAMP_NAME_LOADING,
      loading: loading,
    };
  };
  
  export const editHealthcampNameSuccessAction = (
    editHealthcampNameResponse: EditHealthcampNameRes,
    successMsg: string,
  ): EditHealthcampNameActionTypes => {
    return {
      type: EDIT_HEALTHCAMP_NAME_SUCCESS,
      payload: editHealthcampNameResponse,
      successMsg: successMsg,
    };
  };
  
  export const editHealthcampNameErrorAction = (
    editHealthcampNameResponse: EditHealthcampNameRes,
    errMsg: string,
    status: number,
  ): EditHealthcampNameActionTypes => {
    return {
      type: EDIT_HEALTHCAMP_NAME_FAIL,
      payload: editHealthcampNameResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const editHealthcampNameUpdateAPIMsgAction = (
    editHealthcampNameResponse: EditHealthcampNameRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): EditHealthcampNameActionTypes => {
    return {
      type: EDIT_HEALTHCAMP_NAME_UPDATE_API_MSG,
      payload: editHealthcampNameResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const editHealthcampNameAPIResClearAction = (
    editHealthcampNameResponse: EditHealthcampNameRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): EditHealthcampNameActionTypes => {
    return {
      type: EDIT_HEALTHCAMP_NAME_UPDATE_API_RES,
      payload: editHealthcampNameResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  