import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetPatientDetailsTypeOfSessionListActionTypes } from "./ActionTypes";
import { GetPatientDetailsTypeOfSessionBody, GetPatientDetailsTypeOfSessionDetails } from "./Model";
import {
  getPatientDetailsTypeSessionListAPIResClearAction,
  getPatientDetailsTypeSessionListFailAction,
  getPatientDetailsTypeSessionListLoadingAction,
  getPatientDetailsTypeSessionListSuccessAction,
  getPatientDetailsTypeSessionListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetPatientDetailsTypeOfSessionDetails;
export const getPatientDetailsTypeOfSessionList = (payload: GetPatientDetailsTypeOfSessionBody) => {
  return function (dispatch: Dispatch<GetPatientDetailsTypeOfSessionListActionTypes>) {
    dispatch(getPatientDetailsTypeSessionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthCampSessionPatientDetailsType/get_patient_detailsType_of_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDetailsTypeSessionListLoadingAction(false));
        dispatch(
          getPatientDetailsTypeSessionListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientDetailsTypeOfSessionDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Details Type of Session fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientDetailsTypeSessionListLoadingAction(false));
        dispatch(
          getPatientDetailsTypeSessionListFailAction(
            {} as GetPatientDetailsTypeOfSessionDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientDetailsTypeOfSessionListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsTypeOfSessionListActionTypes>) {
    dispatch(getPatientDetailsTypeSessionListAPIMsgAction(apiRes as GetPatientDetailsTypeOfSessionDetails, "", "", 0));
  };
};

export const clearGetPatientDetailsTypeOfSessionListAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsTypeOfSessionListActionTypes>) {
    dispatch(getPatientDetailsTypeSessionListAPIResClearAction({} as GetPatientDetailsTypeOfSessionDetails, "", "", 0));
  };
};
