import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { OriginalPatientDemographicsDetailsActionTypes } from "./ActionTypes";
import { OriginalPatientDemographicsDetailsBody, OriginalPatientDemographicsDetailsRes } from "./Model";
import {
  originalPatientDemographicsDetailsAPIResClearAction,
  originalPatientDemographicsDetailsErrorAction,
  originalPatientDemographicsDetailsLoadingAction,
  originalPatientDemographicsDetailsSuccessAction,
  originalPatientDemographicsDetailsUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as OriginalPatientDemographicsDetailsRes;
export const getOriginalDemographicsDetailsByPatientId = (payload: OriginalPatientDemographicsDetailsBody) => {
  return function (dispatch: Dispatch<OriginalPatientDemographicsDetailsActionTypes>) {
    dispatch(originalPatientDemographicsDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/patientapp/api/patient/get_patient_demographics_details`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(originalPatientDemographicsDetailsLoadingAction(false));
        dispatch(
          originalPatientDemographicsDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as OriginalPatientDemographicsDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(originalPatientDemographicsDetailsLoadingAction(false));
        dispatch(
          originalPatientDemographicsDetailsErrorAction(
            {} as OriginalPatientDemographicsDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateOriginalDemographicsDetailsByPatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<OriginalPatientDemographicsDetailsActionTypes>) {
    dispatch(
      originalPatientDemographicsDetailsUpdateAPIMsgAction(apiRes as OriginalPatientDemographicsDetailsRes, "", "", 0),
    );
  };
};

export const clearOriginalDemographicsDetailsByPatientIdAPIRes = () => {
  return function (dispatch: Dispatch<OriginalPatientDemographicsDetailsActionTypes>) {
    dispatch(originalPatientDemographicsDetailsAPIResClearAction({} as OriginalPatientDemographicsDetailsRes, "", "", 0));
  };
};
