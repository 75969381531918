import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetDuplicatePatientListActionTypes } from "./ActionTypes";
import { GetDuplicatePatientListRes } from "./Model";
import {
  getDuplicatePatientListAPIResClearAction,
  getDuplicatePatientListErrorAction,
  getDuplicatePatientListLoadingAction,
  getDuplicatePatientListSuccessAction,
  getDuplicatePatientListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetDuplicatePatientListRes;
export const getDuplicatePatientList = (pageNo: any) => {
  return function (dispatch: Dispatch<GetDuplicatePatientListActionTypes>) {
    dispatch(getDuplicatePatientListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/adminapp/api/patientduplicates/patient_duplicate_list?page=${Number(
            pageNo,
          )}&size=50`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDuplicatePatientListLoadingAction(false));
        dispatch(getDuplicatePatientListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getDuplicatePatientListLoadingAction(false));
        dispatch(
          getDuplicatePatientListErrorAction(
            {} as GetDuplicatePatientListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getDuplicatePatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDuplicatePatientListActionTypes>) {
    dispatch(getDuplicatePatientListAPIMsgAction(apiRes as GetDuplicatePatientListRes, "", 0));
  };
};

export const clearDuplicatePatientListAPIRes = () => {
  return function (dispatch: Dispatch<GetDuplicatePatientListActionTypes>) {
    dispatch(getDuplicatePatientListAPIResClearAction({} as GetDuplicatePatientListRes, "", 0));
  };
};
