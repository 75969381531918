import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { BPData } from "../Model";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

type Props = {
  data?: BPData[] | any;
  unit?: string | any;
};

export function BPChart({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as BPData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);

  useEffect(() => {
    var root = am5.Root.new("bpchart");
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        layout: root.verticalLayout,
      }),
    );

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
    });
    xRenderer.labels.template.setAll({
      rotation: 50,
      centerY: am5.p50,
      centerX: 0,
    });

    xRenderer.grid.template.setAll({
      visible: false,
    });

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.CategoryDateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        maxDeviation: 0.3,
        categoryField: "date",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    // Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 200,
        strictMinMax: true,
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "close",
        openValueYField: "open",
        categoryXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "Systolic: {openValueY} - Diastolic: {valueY}",
        }),
        fill: am5.Color.fromString("#00ddff"),
      }),
    );
    series.columns.template.setAll({
      width: 0.5,
    });

    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
        }),
      });
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: am5.Color.fromString("#00ddff"),
        }),
      });
    });
     // Set data
     series.data.setAll(chartData);
     xAxis.data.setAll(chartData);
     // Make stuff animate on load
     series.appear(1000);
     chart.appear(1000, 100);
     return () => root.dispose();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unitt]);

  return <Box id="bpchart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
