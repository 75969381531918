import {
    GET_PATIENT_TEAM_BY_ID_FAIL,
    GET_PATIENT_TEAM_BY_ID_LOADING,
    GET_PATIENT_TEAM_BY_ID_SUCCESS,
    GET_PATIENT_TEAM_BY_ID_MSG,
    GET_PATIENT_TEAM_BY_ID_RES,
    GetPatientDetailsByIdActionTypes,
  } from "./ActionTypes";
  import { GetPatientTeamTeamRes } from "./Model";
  
  export const getPatientTeamByIdLoadingAction = (
    loading: boolean,
  ): GetPatientDetailsByIdActionTypes => {
    return {
      type: GET_PATIENT_TEAM_BY_ID_LOADING,
      loading: loading,
    };
  };
  
  export const getPatientTeamByIdSuccessAction = (
    getPatientTeamByIdResponse: GetPatientTeamTeamRes,
  ): GetPatientDetailsByIdActionTypes => {
    return {
      type: GET_PATIENT_TEAM_BY_ID_SUCCESS,
      payload: getPatientTeamByIdResponse,
    };
  };
  
  export const getPatientTeamByIdErrorAction = (
    getPatientTeamByIdResponse: GetPatientTeamTeamRes,
    errMsg: string,
    status: number,
  ): GetPatientDetailsByIdActionTypes => {
    return {
      type: GET_PATIENT_TEAM_BY_ID_FAIL,
      payload: getPatientTeamByIdResponse,
      message: errMsg,
      status: status,
    };
  };
  export const getPatientTeamByIdUpdateAPIMsgAction = (
    getPatientTeamByIdResponse: GetPatientTeamTeamRes,
    errMsg: string,
    status: number,
  ): GetPatientDetailsByIdActionTypes => {
    return {
      type: GET_PATIENT_TEAM_BY_ID_MSG,
      payload: getPatientTeamByIdResponse,
      message: errMsg,
      status: status,
    };
  };
  
  export const getPatientTeamByIdAPIResClearAction = (
    getPatientTeamByIdResponse: GetPatientTeamTeamRes,
    message: string,
    status: number,
  ): GetPatientDetailsByIdActionTypes => {
    return {
      type: GET_PATIENT_TEAM_BY_ID_RES,
      payload: getPatientTeamByIdResponse,
      message: message,
      status: status,
    };
  };
  