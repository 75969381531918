import { GetPatientDetailsListBySessionIdRes } from "./Model";
export const PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING = "PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING";
export const PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS = "PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS";
export const PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL = "PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL";
export const PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG = "PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG";
export const PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES = "PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES";

export interface GetPatientDetailsListBySessionIdState {
  getPatientDetailsListBySessionIdRes: GetPatientDetailsListBySessionIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListBySessionIdLoading {
  type: typeof PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface GetPatientDetailsListBySessionIdSuccess {
  type: typeof PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS;
  payload: GetPatientDetailsListBySessionIdRes;
  successMsg: string;
}
export interface GetPatientDetailsListBySessionIdFail {
  type: typeof PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL;
  payload: GetPatientDetailsListBySessionIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListBySessionIdUpdateAPIMsg {
  type: typeof PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG;
  payload: GetPatientDetailsListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListBySessionIdUpdateAPIRes {
  type: typeof PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES;
  payload: GetPatientDetailsListBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientDetailsListBySessionIdActionTypes =
  | GetPatientDetailsListBySessionIdLoading
  | GetPatientDetailsListBySessionIdSuccess
  | GetPatientDetailsListBySessionIdFail
  | GetPatientDetailsListBySessionIdUpdateAPIMsg
  | GetPatientDetailsListBySessionIdUpdateAPIRes;
