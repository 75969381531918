import {
  PatientSearchByInstanceIdActionTypes,
  PATIENT_SEARCH_BY_INSTANCE_ID_FAIL,
  PATIENT_SEARCH_BY_INSTANCE_ID_LOADING,
  PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS,
  PatientSearchByInstanceIdDetailsState,
  PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG,
  PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientSearchByInstanceIdDetails } from "./Model";

const initialStateGetPosts: PatientSearchByInstanceIdDetailsState = {
  loading: false,
  patientSearchByInstanceIdRes: {} as PatientSearchByInstanceIdDetails,
  message: "",
  status: 0,
};
export const patientSearchByInstanceIdReducer = (
  state = initialStateGetPosts,
  action: PatientSearchByInstanceIdActionTypes,
): PatientSearchByInstanceIdDetailsState => {
  switch (action.type) {
    case PATIENT_SEARCH_BY_INSTANCE_ID_LOADING:
      return {
        ...state,
        loading: true,
        patientSearchByInstanceIdRes: {} as PatientSearchByInstanceIdDetails,
      };
    case PATIENT_SEARCH_BY_INSTANCE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        patientSearchByInstanceIdRes: action.payload,
      };
    case PATIENT_SEARCH_BY_INSTANCE_ID_FAIL:
      return {
        ...state,
        loading: false,
        patientSearchByInstanceIdRes: {} as PatientSearchByInstanceIdDetails,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        patientSearchByInstanceIdRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_BY_INSTANCE_ID_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        patientSearchByInstanceIdRes: {} as PatientSearchByInstanceIdDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
