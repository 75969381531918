import { GetActiveListByOrgIdDetails } from "./Model";
export const GET_ACTIVE_LIST_BY_ORG_ID_LOADING = "GET_ACTIVE_LIST_BY_ORG_ID_LOADING";
export const GET_ACTIVE_LIST_BY_ORG_ID_SUCCESS = "GET_ACTIVE_LIST_BY_ORG_ID_SUCCESS";
export const GET_ACTIVE_LIST_BY_ORG_ID_FAIL = "GET_ACTIVE_LIST_BY_ORG_ID_FAIL";
export const GET_ACTIVE_LIST_BY_ORG_ID_API_MSG = "GET_ACTIVE_LIST_BY_ORG_ID_API_MSG";
export const GET_ACTIVE_LIST_BY_ORG_ID_API_RES = "GET_ACTIVE_LIST_BY_ORG_ID_API_RES";

export interface GetActiveListByOrgIdState {
  getActiveListByOrgIdRes: GetActiveListByOrgIdDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ViewOrganizationStaffListLoading {
  type: typeof GET_ACTIVE_LIST_BY_ORG_ID_LOADING;
  loading: boolean;
}
export interface ViewOrganizationStaffListSuccess {
  type: typeof GET_ACTIVE_LIST_BY_ORG_ID_SUCCESS;
  payload: GetActiveListByOrgIdDetails;
  successMsg: string;
}
export interface ViewOrganizationStaffListFail {
  type: typeof GET_ACTIVE_LIST_BY_ORG_ID_FAIL;
  payload: GetActiveListByOrgIdDetails;
  errorMsg: string;
  status: number;
}
export interface ViewOrganizationStaffListAPIMsg {
  type: typeof GET_ACTIVE_LIST_BY_ORG_ID_API_MSG;
  payload: GetActiveListByOrgIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetActiveListByOrgIdAPIRes {
  type: typeof GET_ACTIVE_LIST_BY_ORG_ID_API_RES;
  payload: GetActiveListByOrgIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetActiveListByOrgIdActionTypes =
  | ViewOrganizationStaffListLoading
  | ViewOrganizationStaffListSuccess
  | ViewOrganizationStaffListFail
  | ViewOrganizationStaffListAPIMsg
  | GetActiveListByOrgIdAPIRes;
