import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { PatientDetailsFixedActionTypes } from "./ActionTypes";
import { PatientDetailsFixedBody, PatientDetailsFixedDetails } from "./Model";
import {
  patientDetailsFixedAPIResClearAction,
  patientDetailsFixedErrorAction,
  patientDetailsFixedLoadingAction,
  patientDetailsFixedSuccessAction,
  patientDetailsFixedAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as PatientDetailsFixedDetails;
export const patientDetailsFixedApiCall = (payload: PatientDetailsFixedBody) => {
  return function (dispatch: Dispatch<PatientDetailsFixedActionTypes>) {
    dispatch(patientDetailsFixedLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/patientDetailsFailedProcess/mark_patientDetails_as_fixed`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(patientDetailsFixedLoadingAction(false));
        dispatch(
          patientDetailsFixedSuccessAction(
            res.data !== undefined ? res.data : ({} as PatientDetailsFixedDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(patientDetailsFixedLoadingAction(false));
        dispatch(
          patientDetailsFixedErrorAction(
            {} as PatientDetailsFixedDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updatePatientDetailsFixedAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientDetailsFixedActionTypes>) {
    dispatch(patientDetailsFixedAPIMsgAction(apiRes as PatientDetailsFixedDetails, "", "", 0));
  };
};

export const clearPatientDetailsFixedAPIRes = () => {
  return function (dispatch: Dispatch<PatientDetailsFixedActionTypes>) {
    dispatch(patientDetailsFixedAPIResClearAction({} as PatientDetailsFixedDetails, "", "", 0));
  };
};
