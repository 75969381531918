import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetObservationTypeActionTypes } from "./ActionTypes";
import { GetObservationTypeResponse } from "./Model";
import {
  getObservationTypeAPIResClearAction,
  getObservationTypeErrorAction,
  getObservationTypeLoadingAction,
  getObservationTypeSuccessAction,
  getObservationTypeUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetObservationTypeResponse;
export const getObservationTypeDetails = () => {
  return function (dispatch: Dispatch<GetObservationTypeActionTypes>) {
    dispatch(getObservationTypeLoadingAction(true));
    axios
      .get(Url.baseUrl + `/adminapp/api/patientorganization/patientObservationType_dropdown_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getObservationTypeLoadingAction(false));
        dispatch(
          getObservationTypeSuccessAction(res.data, "Preferences has been fetched successfully"),
        );
      })
      .catch((error) => {
        dispatch(getObservationTypeLoadingAction(false));
        dispatch(
          getObservationTypeErrorAction(
            {} as GetObservationTypeResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<GetObservationTypeActionTypes>) {
    dispatch(getObservationTypeUpdateAPIMsgAction(apiRes as GetObservationTypeResponse, "", "", 0));
  };
};

export const clearObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<GetObservationTypeActionTypes>) {
    dispatch(getObservationTypeAPIResClearAction({} as GetObservationTypeResponse, "", "", 0));
  };
};
