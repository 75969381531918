import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearImportCallAPIRes,
  importDataFromCall,
} from "../redux/ImportData/ImportFromPhone/ApiCall";
import { AppState } from "../redux/store/Store";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SearchIcon from "@mui/icons-material/Search";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  clearImportDataFromRecentCallAPIRes,
  importDataFromRecentCall,
} from "../redux/importDataFromRecentCall/API";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MWExceptionList from "../component/MWExceptionList";
import { clearFindUserAPIRes, findUser } from "../redux/ImportData/FindUserNumber/API";
import { FindUserBody } from "../redux/ImportData/FindUserNumber/Model";
import MWSnackbar from "../component/MWSnackbar";
import { CallList } from "../redux/ImportData/ImportFromPhone/Model";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function ImportFromPhone() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  // Initiate API call on page render
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(importDataFromCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Import Data from All Call Store
  const importDataFromCallRes = useSelector((state: AppState) => state.importDataFromCallRes);
  // Update Table according to Import Data from All Call
  useEffect(() => {
    dispatch(toggleAppBarLoading(importDataFromCallRes?.loading));
    if (importDataFromCallRes?.importDataFromCallRes?.callList !== undefined) {
      if (importDataFromCallRes?.importDataFromCallRes?.callList.length > 0) {
        let importDataFromCallItem = importDataFromCallRes?.importDataFromCallRes?.callList?.map(
          (element: CallList) => ({
            id: element.id,
            createDate:
              element?.createDate !== ""
                ? dayjs(element?.createDate).format("ddd, MMM DD, YYYY")
                : "",
            exoTelCreateDate:
              element?.exoTelCreateDate !== ""
                ? dayjs(element.exoTelCreateDate).format("ddd, MMM DD, YYYY")
                : "",
            phoneNumber: element?.phoneNumber !== 0 ? element?.phoneNumber : "",
            fromNumber: element?.fromNumber !== "" ? element.fromNumber : "",
            toNumber: element?.toNumber !== "" ? element?.toNumber : "",
            recordingUrl: element.recordingUrl !== "" ? element.recordingUrl : "",
            findUser: element.fromNumber !== "" ? element.fromNumber : "",
            sidNumber: element.sidNumber,
          }),
        );
        setImportData(importDataFromCallItem);
      } else setImportData([] as any[]);
    } else setImportData([] as any[]);
    if (importDataFromCallRes?.errorMsg !== "") {
      setImportDataFromCallListMsgType("error");
      setImportDataFromCallListMsg(importDataFromCallRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importDataFromCallRes]);

  const importRecentDataFromPhone = () => {
    dispatch(importDataFromRecentCall());
  };

  function reloadImportedList() {
    dispatch(importDataFromCall());
  }

  const [importDataListValue, setImportData] = useState([] as any[]);

  const [importDataFromCallListMsgType, setImportDataFromCallListMsgType] =
    useState<AlertColor>("success");
  const [importDataFromCallListMsg, setImportDataFromCallListMsg] = useState("");
  const importDataFromCallListMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearImportCallAPIRes());
    }
  };

  const importDataFromRecentCallRes = useSelector(
    (state: AppState) => state.importDataFromRecentCallRes,
  );
  const [importDataFromRecentCallMsgType, setImportDataFromRecentCallMsgType] =
    useState<AlertColor>("success");
  const [importDataFromRecentCallMsg, setImportDataFromRecentCallMsg] = useState("");
  const importDataFromRecentCallMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setImportDataFromRecentCallMsg("");
      dispatch(clearImportDataFromRecentCallAPIRes());
    }
  };
  useEffect(() => {
    if (importDataFromRecentCallRes?.errorMsg !== "") {
      setImportDataFromRecentCallMsg(importDataFromRecentCallRes?.errorMsg);
      setImportDataFromRecentCallMsgType("error");
    }
  }, [importDataFromRecentCallRes]);

  const gotoRecordingUrl = (url: any) => {
    if (url === "" || url === null) {
      alert("No Url Found");
    } else {
      window.open(url);
    }
  };
  const importDataFromPhoneColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
    },
    { field: "createDate", headerName: "Create Date", minWidth: 140 },
    {
      field: "exoTelCreateDate",
      headerName: "Exotel",
      minWidth: 140,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 120,
      flex: 1,
    },
    { field: "fromNumber", headerName: "From", minWidth: 100, flex: 1 },
    { field: "toNumber", headerName: "To", minWidth: 100, flex: 1 },
    {
      field: "recordingUrl",
      headerName: "Recording Url",
      minWidth: 110,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<SurroundSoundIcon />}
          onClick={() => {
            gotoRecordingUrl(params.row.recordingUrl);
          }}
          sx={{ textTransform: "none" }}
        >
          Listen
        </Button>
      ),
    },
    {
      field: "findUser",
      headerName: "Find User",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() =>
            dispatch(findUser({ phone: Number(params.row.fromNumber) } as FindUserBody))
          }
          startIcon={<SearchIcon />}
          disabled={findUserRes?.loading}
          sx={{ textTransform: "none" }}
        >
          Find
        </Button>
      ),
      minWidth: 100,
    },
    { field: "sidNumber", headerName: "Sid", minWidth: 280, flex: 1 },
  ];

  // Find User store
  const findUserRes = useSelector((state: AppState) => state.findUserRes);

  const [findUserMsgType, setFindUserMsgType] = useState<AlertColor>("success");
  const [findUserMsg, setFindUserMsg] = useState("");
  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearFindUserAPIRes());
    }
  };
  useEffect(() => {
    if (findUserRes?.successMsg !== "") {
      setFindUserMsgType("success");
      setFindUserMsg(findUserRes?.successMsg);
    }
    if (findUserRes?.errorMsg !== "") {
      setFindUserMsgType("error");
      setFindUserMsg(findUserRes?.errorMsg);
    }
  }, [findUserRes]);

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">From Phone</Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<ImportExportIcon />}
                      disabled={importDataFromRecentCallRes?.loading}
                      onClick={() => importRecentDataFromPhone()}
                    >
                      Import Recent
                    </Button>
                  </Grid>
                  {!fullScreen && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        disabled={importDataFromCallRes?.loading}
                        onClick={() => reloadImportedList()}
                      >
                        Reload
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {importDataListValue && importDataListValue.length ? (
            <DataGrid
              rows={importDataListValue}
              columns={importDataFromPhoneColumn}
              hideFooter={true}
              sx={{ height: "calc(100vh - 54vh)" }}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {importDataFromCallListMsg !== "" && (
        <MWSnackbar
          msg={importDataFromCallListMsg}
          type={importDataFromCallListMsgType}
          alertClose={importDataFromCallListMsgClose}
        />
      )}
      {importDataFromRecentCallMsg !== "" && (
        <MWSnackbar
          msg={importDataFromRecentCallMsg}
          type={importDataFromRecentCallMsgType}
          alertClose={importDataFromRecentCallMsgClose}
        />
      )}
      {findUserMsg !== "" && (
        <MWSnackbar msg={findUserMsg} type={findUserMsgType} alertClose={alertCloseAction} />
      )}
    </Box>
  );
}
