import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getcaseDetails } from "../../../redux/ConsultationOption/ApiCall";
import { AppState } from "../../../redux/store/Store";
import { t } from "i18next";
import MWTextField from "../../../component/MWTextField";
import { useHistory, useParams } from "react-router-dom";
import { SecondOpinionReasons } from "../../../redux/ConsultationOption/Model";
import MWResponseBanner from "../../../component/MWResponseBanner";
import PageLayout from "../../Layout/PageLayout";
import { OpinionTypeList } from "../../../redux/ConsultationOption/OpinionType/Model";
import { getOpinionTypeList } from "../../../redux/ConsultationOption/OpinionType/ApiCall";
import { SecondOpinionList } from "../../../redux/ConsultationOption/SecondOpinionType/Model";
import { getSecondOpinionTypeList } from "../../../redux/ConsultationOption/SecondOpinionType/ApiCall";
import { AddCaseBody } from "../../../redux/ConsultationOption/AddCase/Model";
import { AddCaseApi } from "../../../redux/ConsultationOption/AddCase/ApiCall";
import { EditCaseApi } from "../../../redux/ConsultationOption/EditCase/ApiCall";

export default function OpinionType() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { patientid } = useParams() as {
    patientid: string;
  };

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    caseid !== undefined ? setIsEdit(true) : setIsEdit(false);
  }, [caseid]);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  const [value, setValue] = useState("1");
  const [requestList, setRequestList] = useState<SecondOpinionReasons[]>([]);
  const [questionValue, setQuestion] = useState("");

  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState<AlertColor>("success");
  const [bannerMsg, setBannerMsg] = useState("");
  const updatedBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  };

  const handleRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let checkedObj = secondOpinionListValue?.secondOpinionTypeRes?.secondOpinionList?.find(
        (i) => i.value.toString() === event.target.value,
      ) as SecondOpinionReasons;
      setRequestList((oldArray) => (oldArray ? [...oldArray!, checkedObj] : [checkedObj]));
    } else {
      setRequestList(requestList.filter((i) => i.value.toString() !== event.target.value));
    }
  };

  const isRequestSelected = (request: SecondOpinionList) => {
    return requestList?.find((i) => i.value === request.value) ? true : false;
  };

  const opinionTypeListValue = useSelector((state: AppState) => state.opinionTypeValue);

  const secondOpinionListValue = useSelector((state: AppState) => state.secondOpinionValue);

  const addPatientCaseIdvalue = useSelector((state: AppState) => state.addPatientCase);

  const patientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails.patientCaseDetail,
  );

  const getBody = JSON.stringify({
    caseId: caseid,
  });

  const addBody = {
    patientId: patientid,
    opinionTypeId: value,
    whyWant2ndOpinion: questionValue,
    secondOpinionReasons: requestList?.map((item) => item.value),
  } as AddCaseBody;

  const createCase = async () => {
    if (caseid) {
      if (questionValue === undefined || questionValue === "") {
        setBannerOpen(true);
        setBannerColor("error");
        setBannerMsg("Please enter your cause");
      } else {
        await dispatch(EditCaseApi(Object.assign(addBody, { caseId: caseid }) as AddCaseBody));
        await setIsEdit((isEdit) => !isEdit);
        await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
      }
    } else {
      if (questionValue === undefined || questionValue === "") {
        setBannerOpen(true);
        setBannerColor("error");
        setBannerMsg("Please enter your cause");
      } else {
        setBannerOpen(false);
        setBannerMsg("");
        await dispatch(AddCaseApi(addBody));
      }
    }
  };

  useEffect(() => {
    dispatch(getOpinionTypeList());
    dispatch(getSecondOpinionTypeList());
    dispatch(getcaseDetails(getBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addPatientCaseIdvalue?.addCaseRes?.patientCaseId !== undefined) {
      history.push(`/casedetails/${patientid}/${addPatientCaseIdvalue?.addCaseRes.patientCaseId}/?tab=consultationType`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientCaseIdvalue]);

  useEffect(() => {
    setValue(
      String(patientCaseDetailsValue === undefined ? "1" : patientCaseDetailsValue?.opinionType!),
    );
    setRequestList(
      patientCaseDetailsValue?.secondOpinionReasons !== undefined
        ? patientCaseDetailsValue?.secondOpinionReasons
        : [],
    );
    patientCaseDetailsValue !== undefined
      ? setQuestion(patientCaseDetailsValue?.whyWant2ndOpinion!)
      : setQuestion("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientCaseDetailsValue]);

  const firstComponent = (
    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item>
        {opinionTypeListValue?.opinionTypeRes?.opinionTypeList?.map((opinion: OpinionTypeList) => (
          <FormControl key={opinion.value} disabled={isEdit}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue((event.target as HTMLInputElement).value)
              }
            >
              <FormControlLabel value={opinion.value} control={<Radio />} label={opinion.label} />
            </RadioGroup>
          </FormControl>
        ))}
      </Grid>
      {value === "1" ? (
        <Grid item>
          <Typography variant="h6" fontWeight="bold">
            {t("consultOpPage.2ndOpReq", { ns: ["home"] })}
          </Typography>
          {secondOpinionListValue?.secondOpinionTypeRes?.secondOpinionList?.map(
            (request: SecondOpinionList) => (
              <FormGroup key={request.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={request.value}
                      onChange={(event) => handleRequest(event)}
                      checked={isRequestSelected(request)}
                    />
                  }
                  label={request.label}
                  disabled={isEdit}
                />
              </FormGroup>
            ),
          )}
        </Grid>
      ) : null}
      <Grid item>
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item>
            {value === "1" ? (
              <Typography variant="h6" fontWeight="bold">
                {opinionTypeListValue?.opinionTypeRes?.opinionTypeList !== undefined
                  ? opinionTypeListValue?.opinionTypeRes?.opinionTypeList[0]?.question
                  : ""}
              </Typography>
            ) : (
              <Typography variant="h6" fontWeight="bold">
                {opinionTypeListValue?.opinionTypeRes?.opinionTypeList !== undefined
                  ? opinionTypeListValue?.opinionTypeRes?.opinionTypeList[1]?.question
                  : ""}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <MWTextField
              fullWidth
              label="What is the cause of your anxiety, concern, fear, worry, or stress."
              placeholder="Please explain in your own words"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestion(event.target.value);
              }}
              value={questionValue}
              disabled={isEdit}
            />
          </Grid>
          <Grid item>
            <MWResponseBanner
              bannerOpen={bannerOpen}
              bannerColor={bannerColor}
              responseMsg={bannerMsg}
              bannerClose={updatedBannerState}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {caseid ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit ? "Edit" : "Cancel"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button size="large" variant="contained" onClick={createCase}>
              Add
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <PageLayout>{firstComponent}</PageLayout>;
}
