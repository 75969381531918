import { ExtractObsTypeBySessionIdRes } from "./Model";
export const EXTRACT_OBS_BY_SESSION_ID_LOADING = "EXTRACT_OBS_BY_SESSION_ID_LOADING";
export const EXTRACT_OBS_BY_SESSION_ID_SUCCESS = "EXTRACT_OBS_BY_SESSION_ID_SUCCESS";
export const EXTRACT_OBS_BY_SESSION_ID_FAIL = "EXTRACT_OBS_BY_SESSION_ID_FAIL";
export const EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG = "EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG";
export const EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES = "EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES";

export interface ExtractObsTypeBySessionIdState {
  extractObsTypeBySessionId: ExtractObsTypeBySessionIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ExtractObsTypeBySessionIdLoading {
  type: typeof EXTRACT_OBS_BY_SESSION_ID_LOADING;
  loading: boolean;
}
export interface ExtractObsTypeBySessionIdSuccess {
  type: typeof EXTRACT_OBS_BY_SESSION_ID_SUCCESS;
  payload: ExtractObsTypeBySessionIdRes;
  successMsg: string;
}
export interface ExtractObsTypeBySessionIdFail {
  type: typeof EXTRACT_OBS_BY_SESSION_ID_FAIL;
  payload: ExtractObsTypeBySessionIdRes;
  errorMsg: string;
  status: number;
}
export interface ExtractObsTypeBySessionIdUpdateAPIMsg {
  type: typeof EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG;
  payload: ExtractObsTypeBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ExtractObsTypeBySessionIdUpdateAPIRes {
  type: typeof EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES;
  payload: ExtractObsTypeBySessionIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ExtractObsTypeBySessionIdActionTypes =
  | ExtractObsTypeBySessionIdLoading
  | ExtractObsTypeBySessionIdSuccess
  | ExtractObsTypeBySessionIdFail
  | ExtractObsTypeBySessionIdUpdateAPIMsg
  | ExtractObsTypeBySessionIdUpdateAPIRes;
