import {
  SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_FAIL,
  SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_LOADING,
  SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_SUCCESS,
  SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_MSG,
  SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_RES,
  SessionPatientAvailableByObservationTypeActionTypes,
} from "./ActionTypes";
import { SessionPatientAvailableByObservationTypeDetails } from "./Model";

export const SessionPatientAvailableByObservationTypeLoadingAction = (
  loading: boolean,
): SessionPatientAvailableByObservationTypeActionTypes => {
  return {
    type: SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const SessionPatientAvailableByObservationTypeSuccessAction = (
  sessionPatientAvailableByObservationTypeResponse: SessionPatientAvailableByObservationTypeDetails,
  successMsg: string,
): SessionPatientAvailableByObservationTypeActionTypes => {
  return {
    type: SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_SUCCESS,
    payload: sessionPatientAvailableByObservationTypeResponse,
    successMsg: successMsg,
  };
};

export const SessionPatientAvailableByObservationTypeErrorAction = (
  sessionPatientAvailableByObservationTypeResponse: SessionPatientAvailableByObservationTypeDetails,
  errMsg: string,
  status: number,
): SessionPatientAvailableByObservationTypeActionTypes => {
  return {
    type: SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_FAIL,
    payload: sessionPatientAvailableByObservationTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const SessionPatientAvailableByObservationTypeAPIMsgAction = (
  sessionPatientAvailableByObservationTypeResponse: SessionPatientAvailableByObservationTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): SessionPatientAvailableByObservationTypeActionTypes => {
  return {
    type: SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_MSG,
    payload: sessionPatientAvailableByObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const SessionPatientAvailableByObservationTypeAPIResClearAction = (
  sessionPatientAvailableByObservationTypeResponse: SessionPatientAvailableByObservationTypeDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): SessionPatientAvailableByObservationTypeActionTypes => {
  return {
    type: SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_RES,
    payload: sessionPatientAvailableByObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
