import {
  INSTANCE_LIST_FAIL,
  INSTANCE_LIST_LOADING,
  INSTANCE_LIST_SUCCESS,
  INSTANCE_LIST_UPDATE_API_MSG,
  INSTANCE_LIST_UPDATE_API_RES,
  GetInstanceListActionTypes,
} from "./ActionTypes";
import { GetInstanceListRes } from "./Model";

export const getInstanceListLoadingAction = (
  loading: boolean,
): GetInstanceListActionTypes => {
  return {
    type: INSTANCE_LIST_LOADING,
    loading: loading,
  };
};

export const getInstanceListSuccessAction = (
  getInstanceListResponse: GetInstanceListRes,
  successMsg: string,
): GetInstanceListActionTypes => {
  return {
    type: INSTANCE_LIST_SUCCESS,
    payload: getInstanceListResponse,
    successMsg: successMsg,
  };
};

export const getInstanceListErrorAction = (
  getInstanceListResponse: GetInstanceListRes,
  errMsg: string,
  status: number,
): GetInstanceListActionTypes => {
  return {
    type: INSTANCE_LIST_FAIL,
    payload: getInstanceListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getInstanceListUpdateAPIMsgAction = (
  getInstanceListResponse: GetInstanceListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetInstanceListActionTypes => {
  return {
    type: INSTANCE_LIST_UPDATE_API_MSG,
    payload: getInstanceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getInstanceListAPIResClearAction = (
  getInstanceListResponse: GetInstanceListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetInstanceListActionTypes => {
  return {
    type: INSTANCE_LIST_UPDATE_API_RES,
    payload: getInstanceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
