import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import MWTextField from "../../../../../component/MWTextField";
import MWUploader from "../../../../../component/MWUploader";
import MWDatePicker from "../../../../../component/MWDatePicker";
import AdminLoader from "../../../../AdminLoader";
import CloseIcon from "@mui/icons-material/Close";
import { addPatientDataBody } from "../../../../../redux/FilePreparation/AddPatientDataList/Model";
import {
  addPatientData,
  clearAddPatientDataAPIRes,
  updateAddPatientDataAPIResMsg,
} from "../../../../../redux/FilePreparation/AddPatientDataList/API";
import { GetPatientDataDetailsBody } from "../../../../../redux/FilePreparation/GetPatientDataList/Model";
import { getPatientDataList } from "../../../../../redux/FilePreparation/GetPatientDataList/API";
import { clearImageUploadAPIRes } from "../../../../../redux/ImageUpload/API";

interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  docTypeName?: string;
  itemId?: number | any;
  type?: string | any;
  selectedItem?: any;
  selectedItemUpdateState: any;
}

export default function AddEditPatientData({
  dialogEnable,
  dialogUpdateState,
  docTypeName,
  type,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
    setHealthProblem("");
    setHealthProblemError("");
    setPatientDataImgId("");
    setStartDate(new Date());
    setEndDate(new Date());
    dispatch(updateAddPatientDataAPIResMsg());
    dispatch(clearAddPatientDataAPIRes());
    dispatch(clearImageUploadAPIRes());
    selectedItemUpdateState({} as any);
  };
  const [healthProblem, setHealthProblem] = useState("");
  const [patientDataImgId, setPatientDataImgId] = useState<String | undefined>("");

  const updateGovtDataStateFromChild = async (passedVal: any) => {
    if (passedVal !== undefined) {
      setPatientDataImgId(String(passedVal));
    } else {
      setPatientDataImgId(undefined);
    }
  };
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const updateStartDateFromChild = async (startDateValue: any) => {
    await setStartDate(startDateValue);
  };
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const updateEndDateFromChild = async (endDateValue: any) => {
    await setEndDate(endDateValue);
  };
  const [fieldDisabled, setFieldDisabled] = useState(false);
  // enable dialog
  useEffect(() => {
    dialogEnable !== undefined ? setDialogOpen(dialogEnable) : setDialogOpen(false);
  }, [dialogEnable]);
  // Set Selected item in the corresponding filed which are passed from the table
  useEffect(() => {
    "id" in selectedItem ? setFieldDisabled(true) : setFieldDisabled(false);
    selectedItem !== undefined
      ? selectedItem.description !== ""
        ? setHealthProblem(selectedItem.description)
        : setHealthProblem("")
      : setHealthProblem("");
    selectedItem !== undefined
      ? selectedItem.startDate !== ""
        ? setStartDate(selectedItem.startDate)
        : setStartDate(null)
      : setStartDate(null);
    selectedItem !== undefined
      ? selectedItem.endDate !== ""
        ? setEndDate(selectedItem.endDate)
        : setEndDate(null)
      : setEndDate(null);
  }, [selectedItem]);
  const [healthProblemError, setHealthProblemError] = useState("");
  // const delImgAction = (passeVal: boolean) => {
  //   if (passeVal) {
  //     console.log("passeVal", passeVal);
  //   }
  // };
  const [delImgAction, setDelImgAction] = useState(false);
  const addPatientDataBody = {
    patientId: patientid,
    caseId: caseid,
    secondOpinionUiSection: type,
    description: healthProblem,
    startDate: startDate,
    endDate: endDate,
  } as addPatientDataBody;
  const submitPatientData = () => {
    if (delImgAction) {
      setDelImgAction(false);
    }
    if (healthProblem === "") {
      setHealthProblemError("Please add description");
    } else {
      setHealthProblemError("");
      if (patientDataImgId === undefined) {
        setHealthProblemError("");
        // When user does not upload image on patient creation
        dispatch(addPatientData(addPatientDataBody));
      } else {
        setHealthProblemError("");
        // When user uploads image on patient creation
        dispatch(
          addPatientData(
            Object.assign(addPatientDataBody, {
              patientDocumentId: Number(patientDataImgId),
            }),
          ),
        );
      }
    }
  };
  const addPatientDataListValRes = useSelector((state: AppState) => state.addPatientDataListRes);

  const [addPatientDataMsgType, setDeletePatientDataMsgType] = useState<AlertColor>("success");
  const [addPatientDataErrorMsg, setDeletePatientDataErrorMsg] = useState("");

  const getPatientDataDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetPatientDataDetailsBody;
  useEffect(() => {
    if (addPatientDataListValRes?.successMsg !== "") {
      dispatch(getPatientDataList(getPatientDataDetails));
      handleClose();
    } else if (addPatientDataListValRes?.errorMsg !== "") {
      setDeletePatientDataErrorMsg(addPatientDataListValRes?.errorMsg);
      setDeletePatientDataMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDataListValRes]);
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    console.log("passedVal", passedVal);
    setCheckImgUpload(passedVal);
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="lg">
      {addPatientDataListValRes?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            {!fieldDisabled ? "Add" : "View"} Data for {docTypeName} Details
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>Mention your health problem</Typography>
            <MWTextField
              placeholder={`Please enter ${docTypeName!} description`}
              value={healthProblem}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setHealthProblem(event.target.value)
              }
              disabled={fieldDisabled}
              multiline
              rows={8}
              fullWidth
            />
            {healthProblemError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>{healthProblemError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>Upload Supporting Document</Typography>
            <Box
              style={{
                textAlign: "center",
                border: "1px #6574c4 solid",
                borderRadius: "3px",
              }}
              p={1}
            >
              <MWUploader
                documentType="patient-image"
                criteria="Document size should be maximum 10MB."
                updatedDataState={updateGovtDataStateFromChild}
                title={`Upload ${docTypeName} data`}
                visibility={fieldDisabled}
                imageUrl={selectedItem?.docUrl}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <MWDatePicker
              label="Start Date"
              selectedDateValue={startDate}
              dateState={updateStartDateFromChild}
              disabledValue={fieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <MWDatePicker
              label="End Date"
              selectedDateValue={endDate}
              dateState={updateEndDateFromChild}
              disabledValue={fieldDisabled}
            />
          </Grid>
        </Grid>
        {addPatientDataErrorMsg !== "" ? (
          <Box py={2}>
            <Alert
              severity={addPatientDataMsgType}
              onClose={() => dispatch(updateAddPatientDataAPIResMsg())}
            >
              {addPatientDataErrorMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={fieldDisabled ? "contained" : "outlined"}
          onClick={() => handleClose()}
          sx={{ textTransform: "none" }}
        >
          {fieldDisabled ? "Close" : "Cancel"}
        </Button>
        {!fieldDisabled ? (
          <Button
            variant="contained"
            disabled={checkImgUpload}
            onClick={() => submitPatientData()}
            sx={{ textTransform: "none" }}
          >
            Submit
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
