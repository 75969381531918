import { UpdatePatientCaseStatusRes } from "./Model";
export const UPDATE_PATIENT_CASE_STATUS_LOADING = "UPDATE_PATIENT_CASE_STATUS_LOADING";
export const UPDATE_PATIENT_CASE_STATUS_SUCCESS = "UPDATE_PATIENT_CASE_STATUS_SUCCESS";
export const UPDATE_PATIENT_CASE_STATUS_FAIL = "UPDATE_PATIENT_CASE_STATUS_FAIL";
export const UPDATE_PATIENT_CASE_STATUS_DATA = "UPDATE_PATIENT_CASE_STATUS_DATA";
export const UPDATE_PATIENT_CASE_STATUS_UPDATE_API_MSG =
  "UPDATE_PATIENT_CASE_STATUS_UPDATE_API_MSG";
export const UPDATE_PATIENT_CASE_STATUS_UPDATE_API_RES =
  "UPDATE_PATIENT_CASE_STATUS_UPDATE_API_RES";

export interface UpdatePatientCaseStatusState {
  updatePatientCaseStatusRes: UpdatePatientCaseStatusRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientCaseStatusLoading {
  type: typeof UPDATE_PATIENT_CASE_STATUS_LOADING;
  loading: boolean;
}
export interface UpdatePatientCaseStatusSuccess {
  type: typeof UPDATE_PATIENT_CASE_STATUS_SUCCESS;
  payload: UpdatePatientCaseStatusRes;
  successMsg: string;
}
export interface UpdatePatientCaseStatusFail {
  type: typeof UPDATE_PATIENT_CASE_STATUS_FAIL;
  payload: UpdatePatientCaseStatusRes;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientCaseStatusUpdateAPIMsg {
  type: typeof UPDATE_PATIENT_CASE_STATUS_UPDATE_API_MSG;
  payload: UpdatePatientCaseStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientCaseStatusUpdateAPIRes {
  type: typeof UPDATE_PATIENT_CASE_STATUS_UPDATE_API_RES;
  payload: UpdatePatientCaseStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface UpdatePatientCaseStatusAction {
  type: typeof UPDATE_PATIENT_CASE_STATUS_DATA;
  payload: UpdatePatientCaseStatusRes;
}

export type UpdatePatientCaseStatusActionTypes =
  | UpdatePatientCaseStatusAction
  | UpdatePatientCaseStatusLoading
  | UpdatePatientCaseStatusSuccess
  | UpdatePatientCaseStatusFail
  | UpdatePatientCaseStatusUpdateAPIMsg
  | UpdatePatientCaseStatusUpdateAPIRes;
