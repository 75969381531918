import * as React from "react";
import { Card, Grid, Typography, Avatar, CardHeader, CardContent, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import { getProviderOrgDetails } from "../../../redux/ProviderOrganizationDetails/API";
import { GetProviderOrgDetailsBody } from "../../../redux/ProviderOrganizationDetails/Model";

function ProviderOrgDetailsComponent() {
  const dispatch = useDispatch();
  const administratorDetails = useSelector((state: AppState) => state.getProviderOrgDetailsValue);
  const detailsValueRes =
    administratorDetails?.getProviderOrgDetailsRes?.providerOrganizationDetails;

  const [detailsValue, setDetailsValue] = React.useState({} as any);
  console.log("detailsValue", detailsValue);
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  const apiBody = {
    providerOrganizationId: organizationid,
  } as GetProviderOrgDetailsBody;
  React.useEffect(() => {
    dispatch(getProviderOrgDetails(apiBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  React.useEffect(() => {
    if (detailsValueRes !== undefined) {
      setDetailsValue(detailsValueRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [administratorDetails]);

  return (
    <Box m={4}>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}></Typography>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              {detailsValue?.logoImage?.document ? (
                <img
                  src={detailsValue?.logoImage?.document}
                  srcSet={detailsValue?.logoImage?.document}
                  alt=""
                  loading="lazy"
                  style={{
                    maxWidth: "180px",
                    maxHeight: "180px",
                    flex: 1,
                  }}
                />
              ) : (
                <Avatar sx={{ width: 120, height: 120 }} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" mt={1}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h6">Name:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
                        {detailsValue.displayName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h6">Tagline:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
                        {detailsValue.tagLine}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h6">Description:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
                        {detailsValue.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ProviderOrgDetailsComponent;
