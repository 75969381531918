import {
  AdminGetPatientTeamListActionTypes,
  ALL_PATIENT_TEAM_FAIL,
  ALL_PATIENT_TEAM_LOADING,
  ALL_PATIENT_TEAM_SUCCESS,
  GetAllPatientListStateType,
} from "./ActionTypes";
import { AdminPatientTeamList } from "./Model";

const initialStateGetAllPatientlist: GetAllPatientListStateType = {
  loading: false,
  allPatientTeamListValue: {} as AdminPatientTeamList,
  patientListPageCount: Object.assign({}),
  patientTeamCurrentPageCount: Object.assign({}),
};
export const getpatientTeamListReducer = (
  state = initialStateGetAllPatientlist,
  action: AdminGetPatientTeamListActionTypes,
): GetAllPatientListStateType => {
  switch (action.type) {
    case ALL_PATIENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        allPatientTeamListValue: action.payload,
        patientListPageCount: action.patientListPageCount,
        patientTeamCurrentPageCount: action.patientTeamCurrentPageCount,
      };
    case ALL_PATIENT_TEAM_LOADING:
      return {
        ...state,
        loading: true,
        allPatientTeamListValue: {} as AdminPatientTeamList,
      };
    case ALL_PATIENT_TEAM_FAIL:
      return {
        ...state,
        allPatientTeamListValue: {} as AdminPatientTeamList,
        loading: false,
      };
    default:
      return state;
  }
};
