import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../../../../redux/store/Store";
import { updateAddExistingPtAPIResMsg } from "../../../../../../redux/addExistingHealthcampPatient/API";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../component/MWExceptionList";
import MWPageTitle from "../../../../../../component/MWPageTitle";
import MWTextField from "../../../../../../component/MWTextField";
import Snackbar from "@mui/material/Snackbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { Portal } from "@mui/base";
import { executiveSearchApi } from "redux/ExecutiveSearchApi/API";
import { ExecutiveSearchBody, StaffList } from "redux/ExecutiveSearchApi/Model";
import { addStaffOfHealthcampApiCall } from "redux/AddHealthcampStaff/API";
import { AddStaffOfHealthcampBody } from "redux/AddHealthcampStaff/Model";
import { GetHealthcampStaffActiveListBody } from "redux/HealthcampStaffList/Model";
import { getGetHealthcampStaffActiveList } from "redux/HealthcampStaffList/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import PageLayout from "pages/Layout/PageLayout";

type Props = {
  healthcampid?: string | any;
  addExistingPatientDialogEnable?: boolean | any;
  addExistingPatientDialogUpdateState?: boolean | any;
  title?: string | any;
  addPt?: boolean | any;
  showPtDetail?: boolean | any;
  showPtDoc?: boolean | any;
};

export default function SearchExecutiveForAddStaff({ healthcampid, title }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();

  const [urlPath, setUrlPath] = useState(history.location.pathname);
  console.log("urlPath", urlPath);
  useEffect(() => {
    setUrlPath(history.location.pathname);
  }, [history]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(updateAddExistingPtAPIResMsg());
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const addHealthcampStaffSubmit = (element: string) => {
    let addBody = {
      healthcampId: healthcampid,
      userId: element,
    } as AddStaffOfHealthcampBody;
    dispatch(addStaffOfHealthcampApiCall(addBody));
  };

  const [inputValue, setInputValue] = React.useState("" as any);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("0");

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const executiveSearchListValueRes = useSelector((state: AppState) => state.executiveSearchValue);
  const searchExecutiveList = executiveSearchListValueRes?.executiveSearchRes.StaffList;

  const addExistingPatientValueRes = useSelector((state: AppState) => state.addHealhcampStaffValue);
  const [addExistingPt, setAddExistingPt] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    // setIsLoading(addExistingPatientValueRes?.loading);
    if (addExistingPatientValueRes?.AddStaffOfOrgRes?.message !== undefined) {
      setAddExistingPt(addExistingPatientValueRes?.AddStaffOfOrgRes?.message);
      setSnackBarType("success");
      handleClick();
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
        isActiveOrAdmin: 1,
      } as GetHealthcampStaffActiveListBody;
      dispatch(getGetHealthcampStaffActiveList(viewHealthcampStaffListBody, 1));
    }
    if (addExistingPatientValueRes?.errorMsg !== "") {
      setAddExistingPt(addExistingPatientValueRes?.errorMsg);
      setSnackBarType("error");
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExistingPatientValueRes]);

  const executiveSearchBody = {
    searchKey: inputValue,
  } as ExecutiveSearchBody;

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(executiveSearchApi(executiveSearchBody, value));
  };

  useEffect(() => {
    urlPath === "/searchpatient" &&
      dispatch(toggleAppBarLoading(executiveSearchListValueRes?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveSearchListValueRes]);

  useEffect(() => {
    executiveSearchListValueRes?.executiveSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(executiveSearchListValueRes?.executiveSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (executiveSearchListValueRes?.executiveSearchRes?.count !== undefined) {
      setListCount(String(executiveSearchListValueRes?.executiveSearchRes?.count));
    }
    if (searchExecutiveList !== undefined) {
      let dataPointArr = searchExecutiveList.map((element: StaffList) => ({
        id: element.id,
        first_name: element.first_name,
        last_name: element.last_name,
        email: element.email,
        phone: element.phoneNumber,
        userId: element.userId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(executiveSearchListValueRes?.executiveSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveSearchListValueRes]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },

    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 250,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 250,
      align: "left",
      flex: 1,
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      align: "left",
      flex: 1,
    },

    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 130,
      align: "left",
    },
    {
      field: "userId",
      headerName: "Action",
      minWidth: 110,
      align: "left",
      hide: urlPath !== "/searchpatient" ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          disabled={executiveSearchListValueRes?.loading || addExistingPatientValueRes?.loading}
          variant="outlined"
          onClick={() => {
            addHealthcampStaffSubmit(params.row.userId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add Staff
          </span>
        </Button>
      ),
    },
  ];

  const ExecutiveSearchListData = (
    <Card
      sx={{
        height:
          !fullScreen && urlPath !== "/searchpatient"
            ? "calc(100vh - 18vh)"
            : !fullScreen && urlPath === "/searchpatient"
            ? "calc(100vh - 55vh)"
            : fullScreen && urlPath !== "/searchpatient"
            ? "calc(100vh - 20vh)"
            : "calc(100vh - 85vh)",
      }}
    >
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
              <MWPageTitle title="Executive Search List" enableCount={true} count={listCount} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg="auto" xl="auto">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {searchExecutiveList && searchExecutiveList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const pageBody = (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <MWPageTitle
            backButton={true}
            title={urlPath !== "/searchpatient" ? title : "Search Patient"}
          />
        ) : null}
        <MWTextField
          label="Executive Search In MedicalWISDOM Platform"
          value={inputValue}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              dispatch(executiveSearchApi(executiveSearchBody, page));
              ev.preventDefault();
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
          placeholder="Please enter value"
          id="searchValue"
          fullWidth
          size="small"
        />
        <Box>{ExecutiveSearchListData}</Box>
      </Stack>
      <Portal>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} action={action}>
          <Alert severity={snackBarType!}>{addExistingPt}</Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
  return (
    <>
      {urlPath === "/searchpatient" ? <PageLayout>{pageBody}</PageLayout> : <Box>{pageBody}</Box>}
    </>
  );
}
