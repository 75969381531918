import {
  PROVIDER_ORG_PATIENT_SEARCH_DATA,
  PROVIDER_ORG_PATIENT_SEARCH_FAIL,
  PROVIDER_ORG_PATIENT_SEARCH_LOADING,
  PROVIDER_ORG_PATIENT_SEARCH_SUCCESS,
  ProviderOrgPatientSearchActionTypes,
} from "./ActionTypes";
import { PageCount, ProviderOrgPatientSearchDetails } from "./Model";

export const providerOrgPatientSearchAction = (
  ProviderOrgPatientSearchDetails: ProviderOrgPatientSearchDetails,
  pageCount: PageCount,
): ProviderOrgPatientSearchActionTypes => {
  return {
    type: PROVIDER_ORG_PATIENT_SEARCH_DATA,
    payload: ProviderOrgPatientSearchDetails,
    pageCount: pageCount,
  };
};

export const providerOrgPatientSearchLoadingAction = (loading: boolean): ProviderOrgPatientSearchActionTypes => {
  return {
    type: PROVIDER_ORG_PATIENT_SEARCH_LOADING,
    loading: loading,
  };
};

export const providerOrgPatientSearchSuccessAction = (
  providerOrgPatientSearchResponse: ProviderOrgPatientSearchDetails,
): ProviderOrgPatientSearchActionTypes => {
  return {
    type: PROVIDER_ORG_PATIENT_SEARCH_SUCCESS,
    payload: providerOrgPatientSearchResponse,
  };
};

export const providerOrgPatientSearchErrorAction = (
  providerOrgPatientSearchResponse: ProviderOrgPatientSearchDetails,
  errMsg: string,
  status: number,
): ProviderOrgPatientSearchActionTypes => {
  return {
    type: PROVIDER_ORG_PATIENT_SEARCH_FAIL,
    payload: providerOrgPatientSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const providerOrgPatientSearchUpdateAPIMsgAction = (
  providerOrgPatientSearchResponse: ProviderOrgPatientSearchDetails,
  errMsg: string,
  status: number,
): ProviderOrgPatientSearchActionTypes => {
  return {
    type: PROVIDER_ORG_PATIENT_SEARCH_FAIL,
    payload: providerOrgPatientSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const providerOrgPatientSearchAPIResClearAction = (
  providerOrgPatientSearchResponse: ProviderOrgPatientSearchDetails,
  errMsg: string,
  status: number,
): ProviderOrgPatientSearchActionTypes => {
  return {
    type: PROVIDER_ORG_PATIENT_SEARCH_FAIL,
    payload: providerOrgPatientSearchResponse,
    message: errMsg,
    status: status,
  };
};
