import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { PatientSearchByInstanceIdActionTypes } from "./ActionTypes";
import { PatientSearchByInstanceIdBody, PatientSearchByInstanceIdDetails } from "./Model";
import {
  PatientSearchByInstanceIdAPIResClearAction,
  PatientSearchByInstanceIdErrorAction,
  PatientSearchByInstanceIdLoadingAction,
  PatientSearchByInstanceIdSuccessAction,
  PatientSearchByInstanceIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as PatientSearchByInstanceIdDetails;
export const patientSearchByInstanceIdApi = (
  searchKey: PatientSearchByInstanceIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<PatientSearchByInstanceIdActionTypes>) {
    dispatch(PatientSearchByInstanceIdLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/searchapp/api/searchPatientInHealthcampSession/searchPatientListInHealthCampSession?page=${Number(
            pageNo,
          )}&size=12`,
        searchKey,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(PatientSearchByInstanceIdLoadingAction(false));
        dispatch(PatientSearchByInstanceIdSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(PatientSearchByInstanceIdLoadingAction(false));
        dispatch(
          PatientSearchByInstanceIdErrorAction(
            {} as PatientSearchByInstanceIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSearchAppointmentAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientSearchByInstanceIdActionTypes>) {
    dispatch(
      PatientSearchByInstanceIdAPIMsgAction(apiRes as PatientSearchByInstanceIdDetails, "", 0),
    );
  };
};

export const clearSearchAppointmentAPIRes = () => {
  return function (dispatch: Dispatch<PatientSearchByInstanceIdActionTypes>) {
    dispatch(
      PatientSearchByInstanceIdAPIResClearAction({} as PatientSearchByInstanceIdDetails, "", 0),
    );
  };
};
