import {
  SECOND_OPINION_TYPE_API_MSG,
    SECOND_OPINION_TYPE_API_RES,
    SECOND_OPINION_TYPE_DATA,
    SECOND_OPINION_TYPE_FAIL,
    SECOND_OPINION_TYPE_LOADING,
    SECOND_OPINION_TYPE_SUCCESS,
    SecondOpinionTypeActionTypes,
  } from "./ActionTypes";
  import { SecondOpinionResponse } from "./Model";
  
  export const secondOpinionTypeAction = (
    secondOpinionTypeRes: SecondOpinionResponse
  ): SecondOpinionTypeActionTypes => {
    return {
      type: SECOND_OPINION_TYPE_DATA,
      payload: secondOpinionTypeRes,
    };
  };
  
  export const secondOpinionTypeLoadingAction = (
    loading: boolean
  ): SecondOpinionTypeActionTypes => {
    return {
      type: SECOND_OPINION_TYPE_LOADING,
      loading: loading,
    };
  };
  
  export const secondOpinionTypeSuccessAction = (
    secondOpinionTypeRes: SecondOpinionResponse,
    successMsg: string,
    status: number    
  ): SecondOpinionTypeActionTypes => {
    return {
      type: SECOND_OPINION_TYPE_SUCCESS,
      payload: secondOpinionTypeRes,
      successMsg: successMsg,
      status: status      
    };
  };
  
  export const secondOpinionTypeErrorAction = (
    secondOpinionTypeRes: SecondOpinionResponse,
    errMsg: string,
    status: number
  ): SecondOpinionTypeActionTypes => {
    return {
      type: SECOND_OPINION_TYPE_FAIL,
      payload: secondOpinionTypeRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const secondOpinionTypeUpdateAPIMsgAction = (
    secondOpinionTypeRes: SecondOpinionResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): SecondOpinionTypeActionTypes => {
    return {
      type: SECOND_OPINION_TYPE_API_MSG,
      payload: secondOpinionTypeRes,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const secondOpinionTypeAPIResClearAction = (
    secondOpinionTypeRes: SecondOpinionResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): SecondOpinionTypeActionTypes => {
    return {
      type: SECOND_OPINION_TYPE_API_RES,
      payload: secondOpinionTypeRes,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  