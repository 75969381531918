import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { PatientDetailsTypeListActionTypes } from "./ActionTypes";
import { PatientDetailsTypeRes } from "./Model";
import {
  patientDetailsTypeListAPIResClearAction,
  patientDetailsTypeListErrorAction,
  patientDetailsTypeListLoadingAction,
  patientDetailsTypeListSuccessAction,
  patientDetailsTypeListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as PatientDetailsTypeRes;
export const patientDetailsTypeList = () => {
  return function (dispatch: Dispatch<PatientDetailsTypeListActionTypes>) {
    dispatch(patientDetailsTypeListLoadingAction(true));
    axios
      .get(
        Url.baseUrl + `/patientapp/api/patientMedicalData/patientDetailsType_dropdown_list`,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(patientDetailsTypeListLoadingAction(false));
        dispatch(
          patientDetailsTypeListSuccessAction(
            res.data,
            " work schedule details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(patientDetailsTypeListLoadingAction(false));
        dispatch(
          patientDetailsTypeListErrorAction(
            {} as PatientDetailsTypeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updatePatientDetailsTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientDetailsTypeListActionTypes>) {
    dispatch(
      patientDetailsTypeListUpdateAPIMsgAction(apiRes as PatientDetailsTypeRes, "", "", 0)
    );
  };
};

export const clearPatientDetailsTypeListAPIRes = () => {
  return function (dispatch: Dispatch<PatientDetailsTypeListActionTypes>) {
    dispatch(
      patientDetailsTypeListAPIResClearAction({} as PatientDetailsTypeRes, "", "", 0)
    );
  };
};
