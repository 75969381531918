import { GetPatientDetailsListByDuplicateIdRes } from "./Model";
export const PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_LOADING = "PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_LOADING";
export const PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_SUCCESS = "PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_SUCCESS";
export const PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_FAIL = "PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_FAIL";
export const PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_MSG = "PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_MSG";
export const PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_RES = "PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_RES";

export interface GetPatientDetailsListByDuplicateIdState {
  getPatientDetailsListByDuplicateIdRes: GetPatientDetailsListByDuplicateIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListByDuplicateIdLoading {
  type: typeof PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_LOADING;
  loading: boolean;
}
export interface GetPatientDetailsListByDuplicateIdSuccess {
  type: typeof PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_SUCCESS;
  payload: GetPatientDetailsListByDuplicateIdRes;
  successMsg: string;
}
export interface GetPatientDetailsListByDuplicateIdFail {
  type: typeof PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_FAIL;
  payload: GetPatientDetailsListByDuplicateIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListByDuplicateIdUpdateAPIMsg {
  type: typeof PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_MSG;
  payload: GetPatientDetailsListByDuplicateIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsListByDuplicateIdUpdateAPIRes {
  type: typeof PATIENT_DETAILS_LIST_BY_DUPLICATE_ID_UPDATE_API_RES;
  payload: GetPatientDetailsListByDuplicateIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientDetailsListByDuplicateIdActionTypes =
  | GetPatientDetailsListByDuplicateIdLoading
  | GetPatientDetailsListByDuplicateIdSuccess
  | GetPatientDetailsListByDuplicateIdFail
  | GetPatientDetailsListByDuplicateIdUpdateAPIMsg
  | GetPatientDetailsListByDuplicateIdUpdateAPIRes;
