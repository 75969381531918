import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetLastModifiedPatientListActionTypes } from "./ActionTypes";
import { GetLastModifiedPatientListRes } from "./Model";
import {
  getLastModifiedPatientListAPIResClearAction,
  getLastModifiedPatientListErrorAction,
  getLastModifiedPatientListLoadingAction,
  getLastModifiedPatientListSuccessAction,
  getLastModifiedPatientListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetLastModifiedPatientListRes;
export const getLastModifiedPatientListApi = () => {
  return function (dispatch: Dispatch<GetLastModifiedPatientListActionTypes>) {
    dispatch(getLastModifiedPatientListLoadingAction(true));
    axios
      .get(Url.baseUrl + `/adminapp/api/adminActions/admin_self_last_modified_patient_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getLastModifiedPatientListLoadingAction(false));
        dispatch(getLastModifiedPatientListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getLastModifiedPatientListLoadingAction(false));
        dispatch(
          getLastModifiedPatientListErrorAction(
            {} as GetLastModifiedPatientListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getLastModifiedPatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetLastModifiedPatientListActionTypes>) {
    dispatch(
      getLastModifiedPatientListAPIMsgAction(apiRes as GetLastModifiedPatientListRes, "", 0),
    );
  };
};

export const clearLastModifiedPatientListAPIRes = () => {
  return function (dispatch: Dispatch<GetLastModifiedPatientListActionTypes>) {
    dispatch(
      getLastModifiedPatientListAPIResClearAction({} as GetLastModifiedPatientListRes, "", 0),
    );
  };
};
