import { DuplicateOrganizationListByPatientIdDetails } from "./Model";

// Patient Demographics Loading State
export const DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_LOADING = "DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_LOADING";
export const DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS = "DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS";
export const DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_FAIL = "DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_FAIL";
export const DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG = "DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG";
export const DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_RES = "DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_RES";

export interface DuplicateOrganizationListByPatientIdDetailsState {
  duplicateOrganizationListByPatientIdRes: DuplicateOrganizationListByPatientIdDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface DuplicateOrganizationListByPatientIdLoading {
  type: typeof DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface DuplicateOrganizationListByPatientIdSuccess {
  type: typeof DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_SUCCESS;
  payload: DuplicateOrganizationListByPatientIdDetails;
  successMsg: string;
}
export interface DuplicateOrganizationListByPatientIdFail {
  type: typeof DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_FAIL;
  payload: DuplicateOrganizationListByPatientIdDetails;
  errorMsg: string;
  status: number;
}
export interface DuplicateOrganizationListByPatientIdAPIMsg {
  type: typeof DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_MSG;
  payload: DuplicateOrganizationListByPatientIdDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof DUPLICATE_ORGANIZATION_LIST_BY_PATIENT_ID_API_RES;
  payload: DuplicateOrganizationListByPatientIdDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type DuplicateOrganizationListByPatientIdActionTypes =
  | DuplicateOrganizationListByPatientIdLoading
  | DuplicateOrganizationListByPatientIdSuccess
  | DuplicateOrganizationListByPatientIdFail
  | DuplicateOrganizationListByPatientIdAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
