import {
  STAFF_LIST_BY_COMPETITION_ID_DATA,
  STAFF_LIST_BY_COMPETITION_ID_FAIL,
  STAFF_LIST_BY_COMPETITION_ID_LOADING,
  STAFF_LIST_BY_COMPETITION_ID_SUCCESS,
  GetStaffListByCompetitionActionTypes,
} from "./ActionTypes";
import { PageCount, GetStaffListByCompetitionDetails } from "./Model";

export const staffCompitionIdAction = (
  getStaffListByCompetitionDetails: GetStaffListByCompetitionDetails,
  pageCount: PageCount,
): GetStaffListByCompetitionActionTypes => {
  return {
    type: STAFF_LIST_BY_COMPETITION_ID_DATA,
    payload: getStaffListByCompetitionDetails,
    pageCount: pageCount,
  };
};

export const getStaffListByCompetitionLoadingAction = (
  loading: boolean,
): GetStaffListByCompetitionActionTypes => {
  return {
    type: STAFF_LIST_BY_COMPETITION_ID_LOADING,
    loading: loading,
  };
};

export const getStaffListByCompetitionSuccessAction = (
  getStaffListByCompetitionResponse: GetStaffListByCompetitionDetails,
): GetStaffListByCompetitionActionTypes => {
  return {
    type: STAFF_LIST_BY_COMPETITION_ID_SUCCESS,
    payload: getStaffListByCompetitionResponse,
  };
};

export const getStaffListByCompetitionErrorAction = (
  getStaffListByCompetitionResponse: GetStaffListByCompetitionDetails,
  errMsg: string,
  status: number,
): GetStaffListByCompetitionActionTypes => {
  return {
    type: STAFF_LIST_BY_COMPETITION_ID_FAIL,
    payload: getStaffListByCompetitionResponse,
    message: errMsg,
    status: status,
  };
};
export const getStaffListByCompetitionAPIMsgAction = (
  getStaffListByCompetitionResponse: GetStaffListByCompetitionDetails,
  errMsg: string,
  status: number,
): GetStaffListByCompetitionActionTypes => {
  return {
    type: STAFF_LIST_BY_COMPETITION_ID_FAIL,
    payload: getStaffListByCompetitionResponse,
    message: errMsg,
    status: status,
  };
};

export const getStaffListByCompetitionAPIResClearAction = (
  getStaffListByCompetitionResponse: GetStaffListByCompetitionDetails,
  errMsg: string,
  status: number,
): GetStaffListByCompetitionActionTypes => {
  return {
    type: STAFF_LIST_BY_COMPETITION_ID_FAIL,
    payload: getStaffListByCompetitionResponse,
    message: errMsg,
    status: status,
  };
};
