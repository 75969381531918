import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { getAdministratorDetails } from "./Actions";
import {
  AdministratorDetailsActionTypes,
  ADMINISTRATOR_DETAILS_FAIL,
  ADMINISTRATOR_DETAILS_LOADING,
  ADMINISTRATOR_DETAILS_SUCCESS,
} from "./ActionTypes";
import { headers } from "../../component/Utility";

export const getAdministratorDetailsById = (id: string) => {
  return function (dispatch: Dispatch<AdministratorDetailsActionTypes>) {
    dispatch({
      type: ADMINISTRATOR_DETAILS_LOADING,
    });

    axios
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/administrator/getProviderOrganizationAdministratorDetailsByOwner`,
        { id: id },
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data;
        dispatch(getAdministratorDetails(resData));
        dispatch({
          type: ADMINISTRATOR_DETAILS_SUCCESS,
          payload: resData,
        });
      })
      .catch((_error) => {
        dispatch({
          type: ADMINISTRATOR_DETAILS_FAIL,
        });
      });
  };
};
