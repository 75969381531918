import { ImportDataFromRecentCallRes } from "./Model";
export const IMPORT_DATA_FROM_RECENT_CALL_LOADING = "IMPORT_DATA_FROM_RECENT_CALL_LOADING";
export const IMPORT_DATA_FROM_RECENT_CALL_SUCCESS = "IMPORT_DATA_FROM_RECENT_CALL_SUCCESS";
export const IMPORT_DATA_FROM_RECENT_CALL_FAIL = "IMPORT_DATA_FROM_RECENT_CALL_FAIL";
export const IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG =
  "IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG";
export const IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES =
  "IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES";

export interface ImportDataFromRecentCallState {
  importDataFromRecentCallRes: ImportDataFromRecentCallRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ImportDataFromRecentCallLoading {
  type: typeof IMPORT_DATA_FROM_RECENT_CALL_LOADING;
  loading: boolean;
}
export interface ImportDataFromRecentCallSuccess {
  type: typeof IMPORT_DATA_FROM_RECENT_CALL_SUCCESS;
  payload: ImportDataFromRecentCallRes;
  successMsg: string;
}
export interface ImportDataFromRecentCallFail {
  type: typeof IMPORT_DATA_FROM_RECENT_CALL_FAIL;
  payload: ImportDataFromRecentCallRes;
  errorMsg: string;
  status: number;
}
export interface ImportDataFromRecentCallUpdateAPIMsg {
  type: typeof IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_MSG;
  payload: ImportDataFromRecentCallRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ImportDataFromRecentCallUpdateAPIRes {
  type: typeof IMPORT_DATA_FROM_RECENT_CALL_UPDATE_API_RES;
  payload: ImportDataFromRecentCallRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ImportDataFromRecentCallActionTypes =
  | ImportDataFromRecentCallLoading
  | ImportDataFromRecentCallSuccess
  | ImportDataFromRecentCallFail
  | ImportDataFromRecentCallUpdateAPIMsg
  | ImportDataFromRecentCallUpdateAPIRes;
