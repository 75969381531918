import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Card,
  Typography,
  Tabs,
  Tab,
  Chip,
  CardContent,
  tabsClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { AppState } from "redux/store/Store";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory, useLocation } from "react-router-dom";
import PatientDetailsOfDuplicatePatient from "./PatientDetailsOfDuplicatePatient";
import { getPatientDetailsListByDuplicateIdApi } from "redux/patientDataDetails/PatientDetailsListByDuplicateId/API";
import { GetPatientDetailsListByDuplicateIdBody } from "redux/patientDataDetails/PatientDetailsListByDuplicateId/Model";
import DuplicateObservationDetails from "./DuplicateObservationDetails";
import DuplicateDemographicsDetails from "./DuplicateDemographicsDetails";
import { duplicateObservationListApi } from "redux/DuplicatePatientObservationList/API";
import { DuplicateObservationListBody } from "redux/DuplicatePatientObservationList/Model";
import OrganizationListByPatient from "./OrganizationListByPatient";
import HealthcampListByPatient from "./HealthcampListByPatient";
import CompetitionListByPatient from "./CompetitionListByPatient";
import InstanceListByPatient from "./InstanceListByPatient";
import SessionListByPatient from "./SessionListByPatient";
import { duplicateOrganizationListByPatientIdApi } from "redux/DuplicatePatientDetails/PatientOrganizationListById/API";
import { DuplicateOrganizationListByPatientIdBody } from "redux/DuplicatePatientDetails/PatientOrganizationListById/Model";
import { duplicateHealthcampListByPatientIdApi } from "redux/DuplicatePatientDetails/PatientHealthcampListById/API";
import { DuplicateHealthcampListByPatientIdBody } from "redux/DuplicatePatientDetails/PatientHealthcampListById/Model";
import { competitionListByDuplicatePatientIdApi } from "redux/DuplicatePatientDetails/PatientCompetitionListById/API";
import { CompetitionListByDuplicatePatientIdBody } from "redux/DuplicatePatientDetails/PatientCompetitionListById/Model";
import { instanceListByDuplicatePatientIdApi } from "redux/DuplicatePatientDetails/PatientInstanceListById/API";
import { InstanceListByDuplicatePatientIdBody } from "redux/DuplicatePatientDetails/PatientInstanceListById/Model";
import { sessionListByDuplicatePatientIdApi } from "redux/DuplicatePatientDetails/PatientSessionListById/API";
import { SessionListByDuplicatePatientIdBody } from "redux/DuplicatePatientDetails/PatientSessionListById/Model";

type Props = {
  patientduplicateid?: any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OriginalPatientDetailsView({ patientduplicateid }: Props) {
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (patientduplicateid) {
      dispatch(getPatientDetailsListByDuplicateIdApi(patientduplicateid, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const getDuplicatePatientDetailsList = useSelector(
    (state: AppState) => state.getPatientDetailsByDuplicateIdValues,
  );
  const getExtractObservationValue = useSelector(
    (state: AppState) => state.getExtractObservationValue,
  );
  const organizationList = useSelector(
    (state: AppState) => state.duplicateOrgListByPatientIdResult,
  );
  const healthcampListByDuplicatePatientId = useSelector(
    (state: AppState) => state.duplicatePatientHealthcampListResult,
  );
  const competitionList = useSelector(
    (state: AppState) => state.competitionListByDuplicatePatientResult,
  );
  const instanceList = useSelector((state: AppState) => state.instanceListByDuplicatePatientResult);
  const sessionListValues = useSelector((state: AppState) => state.sessionListByDuplicatePatientResult);
  const [listCount, setLiseCount] = React.useState("");
  const [duplicateObsListCount, setDuplicateObsLiseCount] = React.useState("");
  const [duplicateOrgListCount, setDuplicateOrgLiseCount] = React.useState("");
  const [duplicateHealthcampListCount, setDuplicateHealthcampLiseCount] = React.useState("");
  const [duplicateCompetitionListCount, setDuplicateOmpetitionLiseCount] = React.useState("");
  const [duplicateInstanceCountList, setDuplicateInstanceCountList] = React.useState("");
  const [duplicateSessionCountList, setDuplicateSessionCountList] = React.useState("");

  useEffect(() => {
    if (
      getDuplicatePatientDetailsList?.getPatientDetailsListByDuplicateIdRes?.count !== undefined
    ) {
      setLiseCount(
        String(getDuplicatePatientDetailsList?.getPatientDetailsListByDuplicateIdRes?.count),
      );
    } else {
      setLiseCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDuplicatePatientDetailsList]);

  useEffect(() => {
    if (organizationList?.duplicateOrganizationListByPatientIdRes?.count !== undefined) {
      setDuplicateOrgLiseCount(
        String(organizationList?.duplicateOrganizationListByPatientIdRes?.count),
      );
    } else {
      setDuplicateOrgLiseCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);

  useEffect(() => {
    if (
      healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.count !== undefined
    ) {
      setDuplicateHealthcampLiseCount(
        String(healthcampListByDuplicatePatientId?.duplicateHealthcampListByPatientIdRes?.count),
      );
    } else {
      setDuplicateHealthcampLiseCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampListByDuplicatePatientId]);

  useEffect(() => {
    if (competitionList?.competitionListByDuplicatePatientIdRes?.count !== undefined) {
      setDuplicateOmpetitionLiseCount(
        String(competitionList?.competitionListByDuplicatePatientIdRes?.count),
      );
    } else {
      setDuplicateOmpetitionLiseCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionList]);

  useEffect(() => {
    if (instanceList?.instanceListByDuplicatePatientIdRes?.count !== undefined) {
      setDuplicateInstanceCountList(
        String(instanceList?.instanceListByDuplicatePatientIdRes?.count),
      );
    } else {
      setDuplicateInstanceCountList("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceList]);

  useEffect(() => {
    if (sessionListValues?.sessionListByDuplicatePatientIdRes?.count !== undefined) {
      setDuplicateSessionCountList(
        String(sessionListValues?.sessionListByDuplicatePatientIdRes?.count),
      );
    } else {
      setDuplicateSessionCountList("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListValues]);

  useEffect(() => {
    getExtractObservationValue?.getExtractObservationRes?.count !== undefined
      ? setDuplicateObsLiseCount(
          String(getExtractObservationValue?.getExtractObservationRes?.count),
        )
      : setDuplicateObsLiseCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExtractObservationValue]);

  const [tabValue, setTabValue] = useState(0);
  const [selected, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=duplicate-patient-details-List"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=duplicate-observation-list"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=duplicate-patient-organization-list"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=duplicate-patient-healthcamp-list"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=duplicate-patient-competition-list"}`);
    } else if (newValue === 5) {
      history.push(`${window.location.pathname + "?section=duplicate-patient-instance-list"}`);
    } else if (newValue === 6) {
      history.push(`${window.location.pathname + "?section=duplicate-patient-session-list"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "duplicate-patient-details-List") {
      setTabValue(0);
    } else if (tabId === "duplicate-observation-list") {
      setTabValue(1);
    } else if (tabId === "duplicate-patient-organization-list") {
      setTabValue(2);
    } else if (tabId === "duplicate-patient-healthcamp-list") {
      setTabValue(3);
    } else if (tabId === "duplicate-patient-competition-list") {
      setTabValue(4);
    } else if (tabId === "duplicate-patient-instance-list") {
      setTabValue(5);
    } else if (tabId === "duplicate-patient-session-list") {
      setTabValue(6);
    }
    // eslint-disable-next-line
  }, [tabId]);

  const duplicatePatientDetailsApi = () => {
    dispatch(
      getPatientDetailsListByDuplicateIdApi(
        { patientId: patientduplicateid } as GetPatientDetailsListByDuplicateIdBody,
        1,
      ),
    );
  };

  const observationList = () => {
    dispatch(
      duplicateObservationListApi(
        { patientId: patientduplicateid } as DuplicateObservationListBody,
        1,
      ),
    );
  };

  const duplicatePatientOrgList = () => {
    dispatch(
      duplicateOrganizationListByPatientIdApi(
        {
          patientId: patientduplicateid,
        } as DuplicateOrganizationListByPatientIdBody,
        1,
      ),
    );
  };
  const duplicatePatientHealthcampList = () => {
    dispatch(
      duplicateHealthcampListByPatientIdApi(
        { patientId: patientduplicateid } as DuplicateHealthcampListByPatientIdBody,
        1,
      ),
    );
  };
  const duplicatePatientCompetitionList = () => {
    dispatch(
      competitionListByDuplicatePatientIdApi(
        {
          patientId: patientduplicateid,
        } as CompetitionListByDuplicatePatientIdBody,
        1,
      ),
    );
  };
  const duplicatePatientInstanceList = () => {
    dispatch(
      instanceListByDuplicatePatientIdApi(
        {
          patientId: patientduplicateid,
        } as InstanceListByDuplicatePatientIdBody,
        1,
      ),
    );
  };

  const duplicatePatientSessionList = () => {
    dispatch(
      sessionListByDuplicatePatientIdApi(
        {
          patientId: patientduplicateid,
        } as SessionListByDuplicatePatientIdBody,
        1,
      ),
    );
  };
  const duplicatePatientDetails = (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Duplicate Patient Details List</Typography>
              </Grid>
              <Grid item>
                <Chip label={listCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                duplicatePatientDetailsApi();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Observation List</Typography>
              </Grid>
              <Grid item>
                <Chip label={duplicateObsListCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                observationList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(1)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Organization List</Typography>
              </Grid>
              <Grid item>
                <Chip label={duplicateOrgListCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                duplicatePatientOrgList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(2)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Healthcamp List</Typography>
              </Grid>
              <Grid item>
                <Chip label={duplicateHealthcampListCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                duplicatePatientHealthcampList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(3)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Competition List</Typography>
              </Grid>
              <Grid item>
                <Chip label={duplicateCompetitionListCount} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                duplicatePatientCompetitionList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(4)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Instance List</Typography>
              </Grid>
              <Grid item>
                <Chip label={duplicateInstanceCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                duplicatePatientInstanceList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(5)}
        />
        <Tab
          label={
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography>Patient Session List</Typography>
              </Grid>
              <Grid item>
                <Chip label={duplicateSessionCountList} variant="outlined" size="small" />
              </Grid>
            </Grid>
          }
          icon={
            <RefreshIcon
              onClick={() => {
                duplicatePatientSessionList();
              }}
            />
          }
          iconPosition="end"
          {...a11yProps(6)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <PatientDetailsOfDuplicatePatient patientduplicateid={patientduplicateid} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DuplicateObservationDetails patientduplicateid={patientduplicateid} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <OrganizationListByPatient duplicatePatientId={patientduplicateid} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <HealthcampListByPatient patientDuplicateId={patientduplicateid} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <CompetitionListByPatient patientDuplicateId={patientduplicateid} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <InstanceListByPatient patientDuplicateId={patientduplicateid} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <SessionListByPatient patientDuplicateId={patientduplicateid} />
      </TabPanel>
    </Box>
  );

  const patientListTable = (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DuplicateDemographicsDetails patientduplicateid={patientduplicateid} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {duplicatePatientDetails}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {patientListTable}
        </Stack>
      </Container>
    </Box>
  );
}
