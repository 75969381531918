import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddCaseBody, AddCaseResponse } from "./Model";
import {
  addCaseAPIResClearAction,
  addCaseErrorAction,
  addCaseLoadingAction,
  addCaseSuccessAction,
  addCaseUpdateAPIMsgAction,
} from "./Actions";
import { AddCaseActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as AddCaseResponse;
export const AddCaseApi = (body: AddCaseBody) => {
  return function (dispatch: Dispatch<AddCaseActionTypes>) {
    dispatch(addCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/patientapp/api/patient/add_patient_case`,
        body,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addCaseLoadingAction(false));
        dispatch(
          addCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient case add successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(addCaseLoadingAction(false));
        dispatch(
          addCaseErrorAction(
            {} as AddCaseResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const addCaseAPIResMsg = () => {
  return function (dispatch: Dispatch<AddCaseActionTypes>) {
    dispatch(addCaseUpdateAPIMsgAction(apiRes as AddCaseResponse, "", "", 0));
  };
};

export const clearaddCaseAPIRes = () => {
  return function (dispatch: Dispatch<AddCaseActionTypes>) {
    dispatch(addCaseAPIResClearAction({} as AddCaseResponse, "", "", 0));
  };
};
