import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetCompetitionListActionTypes } from "./ActionTypes";
import { GetCompetitionListRes } from "./Model";
import {
  getCompetitionListAPIResClearAction,
  getCompetitionListErrorAction,
  getCompetitionListLoadingAction,
  getCompetitionListSuccessAction,
  getCompetitionListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as GetCompetitionListRes;
export const getCompetitionList = (pageNo: any) => {
  return function (dispatch: Dispatch<GetCompetitionListActionTypes>) {
    dispatch(getCompetitionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/competitionapp/api/competitionListAsStaff/competition_list_by_staff?page=${Number(
            pageNo,
          )}&size=10`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getCompetitionListLoadingAction(false));
        dispatch(getCompetitionListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getCompetitionListLoadingAction(false));
        dispatch(
          getCompetitionListErrorAction(
            {} as GetCompetitionListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getCompetitionListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetCompetitionListActionTypes>) {
    dispatch(
      getCompetitionListAPIMsgAction(apiRes as GetCompetitionListRes, "", 0),
    );
  };
};

export const clearCompetitionListAPIRes = () => {
  return function (dispatch: Dispatch<GetCompetitionListActionTypes>) {
    dispatch(
      getCompetitionListAPIResClearAction({} as GetCompetitionListRes, "", 0),
    );
  };
};

