import {
  MOVE_PATIENT_TO_ORGANIZATION_DATA,
  MOVE_PATIENT_TO_ORGANIZATION_FAIL,
  MOVE_PATIENT_TO_ORGANIZATION_LOADING,
  MOVE_PATIENT_TO_ORGANIZATION_SUCCESS,
  MOVE_PATIENT_TO_ORGANIZATION_API_MSG,
  MOVE_PATIENT_TO_ORGANIZATION_API_RES,
  MovePatientToOrganizationActionTypes,
} from "./ActionTypes";
import { PageCount, MovePatientToOrganizationDetails } from "./Model";

export const MovePatientToOrganizationAction = (
  MovePatientToOrganizationDetails: MovePatientToOrganizationDetails,
  pageCount: PageCount,
): MovePatientToOrganizationActionTypes => {
  return {
    type: MOVE_PATIENT_TO_ORGANIZATION_DATA,
    payload: MovePatientToOrganizationDetails,
    pageCount: pageCount,
  };
};

export const movePatientToOrganizationLoadingAction = (
  loading: boolean,
): MovePatientToOrganizationActionTypes => {
  return {
    type: MOVE_PATIENT_TO_ORGANIZATION_LOADING,
    loading: loading,
  };
};

export const movePatientToOrganizationSuccessAction = (
  movePatientToOrganizationResponse: MovePatientToOrganizationDetails,
  successMsg: string,
): MovePatientToOrganizationActionTypes => {
  return {
    type: MOVE_PATIENT_TO_ORGANIZATION_SUCCESS,
    payload: movePatientToOrganizationResponse,
    successMsg: successMsg,
  };
};

export const movePatientToOrganizationErrorAction = (
  movePatientToOrganizationResponse: MovePatientToOrganizationDetails,
  errMsg: string,
  status: number,
): MovePatientToOrganizationActionTypes => {
  return {
    type: MOVE_PATIENT_TO_ORGANIZATION_FAIL,
    payload: movePatientToOrganizationResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const movePatientToOrganizationAPIMsgAction = (
  movePatientToOrganizationResponse: MovePatientToOrganizationDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): MovePatientToOrganizationActionTypes => {
  return {
    type: MOVE_PATIENT_TO_ORGANIZATION_API_MSG,
    payload: movePatientToOrganizationResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const movePatientToOrganizationAPIResClearAction = (
  movePatientToOrganizationResponse: MovePatientToOrganizationDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): MovePatientToOrganizationActionTypes => {
  return {
    type: MOVE_PATIENT_TO_ORGANIZATION_API_RES,
    payload: movePatientToOrganizationResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
