import {
  PatientDataRangeBySessionIdTypeActionTypes,
  PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL,
  PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING,
  PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS,
  PatientDataRangeBySessionIdTypeDetailsState,
  PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG,
  PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES,
} from "./ActionTypes";
import { PatientDataRangeBySessionIdTypeDetails } from "./Model";

const initialStateGetPosts: PatientDataRangeBySessionIdTypeDetailsState = {
  loading: false,
  patientDataRangeBySessionIdTypeRes: {} as PatientDataRangeBySessionIdTypeDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const patientDataRangeBySessionIdTypeReducer = (
  state = initialStateGetPosts,
  action: PatientDataRangeBySessionIdTypeActionTypes,
): PatientDataRangeBySessionIdTypeDetailsState => {
  switch (action.type) {
    case PATIENT_DATA_RANGE_BY_SESSION_ID_LOADING:
      return {
        ...state,
        loading: true,
        patientDataRangeBySessionIdTypeRes: {} as PatientDataRangeBySessionIdTypeDetails,
      };
    case PATIENT_DATA_RANGE_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDataRangeBySessionIdTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case PATIENT_DATA_RANGE_BY_SESSION_ID_FAIL:
      return {
        ...state,
        loading: false,
        patientDataRangeBySessionIdTypeRes: {} as PatientDataRangeBySessionIdTypeDetails,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DATA_RANGE_BY_SESSION_ID_API_MSG:
      return {
        ...state,
        loading: false,
        patientDataRangeBySessionIdTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DATA_RANGE_BY_SESSION_ID_API_RES:
      return {
        ...state,
        loading: false,
        patientDataRangeBySessionIdTypeRes: {} as PatientDataRangeBySessionIdTypeDetails,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
