import { DeleteAccountRequestListByTypeDetails } from "./Model";
export const DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING = "DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING";
export const DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS = "DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS";
export const DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL = "DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL";
export const DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG = "DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG";
export const DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES = "DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES";

export interface DeleteAccountRequestListByTypeState {
  deleteAccountRequestListByTypeRes: DeleteAccountRequestListByTypeDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteAccountRequestListByTypeLoading {
  type: typeof DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING;
  loading: boolean;
}
export interface DeleteAccountRequestListByTypeSuccess {
  type: typeof DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS;
  payload: DeleteAccountRequestListByTypeDetails;
  successMsg: string;
}
export interface DeleteAccountRequestListByTypeFail {
  type: typeof DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL;
  payload: DeleteAccountRequestListByTypeDetails;
  errorMsg: string;
  status: number;
}
export interface DeleteAccountRequestListByTypeAPIMsg {
  type: typeof DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG;
  payload: DeleteAccountRequestListByTypeDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteAccountRequestListByTypeAPIRes {
  type: typeof DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES;
  payload: DeleteAccountRequestListByTypeDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type DeleteAccountRequestListByTypeActionTypes =
  | DeleteAccountRequestListByTypeLoading
  | DeleteAccountRequestListByTypeSuccess
  | DeleteAccountRequestListByTypeFail
  | DeleteAccountRequestListByTypeAPIMsg
  | DeleteAccountRequestListByTypeAPIRes;
