import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import {
  clearInstanceListByHealthCampIdAPIRes,
  getInstanceListByHealthCampId,
} from "redux/InstanceListOfHealthcamp /API";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import dayjs from "dayjs";
import React from "react";
import AddHealthcampInstanceDialog from "./AddHealthcampInstanceDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
  healthcampInstanceListLoader?: boolean | any;
};

export default function InstanceList({ healthcampInstanceListLoader }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  // call Store
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const addResponse = useSelector((state: AppState) => state.addInstanceResValue);
  const editInstanceResponse = useSelector((state: AppState) => state.editInstanceValue);
  const [showFilterDialog, setShowFilterDialog] = React.useState(false);
  const [healthcampInstanceId, setHealthcampInstanceId] = React.useState("");
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowFilterDialog(passedVal);
  };

  useEffect(() => {
    dispatch(clearInstanceListByHealthCampIdAPIRes());
  }, [dispatch]);

  useEffect(() => {
    const viewHealthcampStaffListBody = {
      healthcampId: healthcampid,
    } as GetInstanceListBody;
    dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  // const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  useEffect(() => {
    healthcampInstanceListLoader(instanceListResponseValue?.loading);
    if (instanceListResponseValue?.getInstanceList?.healthcampInstanceList !== undefined) {
      let dataPointArr = instanceListResponseValue?.getInstanceList?.healthcampInstanceList.map(
        (element: HealthcampInstanceList) => ({
          id: element.id,
          healthcampInstanceId: element.id,
          instanceId: element.id,
          displayName: element.displayName,
          createDate:
            element.dateCreated !== null
              ? dayjs(element.dateCreated).format("ddd, MMM DD, YYYY")
              : "",
          instanceStartDate:
            element.instanceStartDate !== null
              ? dayjs(element.instanceStartDate).format("ddd, MMM DD, YYYY")
              : "",
          instanceEndDate:
            element.instanceEndDate !== null
              ? dayjs(element.instanceEndDate).format("ddd, MMM DD, YYYY")
              : "",
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceListResponseValue]);

  useEffect(() => {
    if (addResponse?.successMsg !== undefined) {
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  useEffect(() => {
    if (editInstanceResponse?.editInstanceRes?.message !== undefined) {
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editInstanceResponse]);

  function gotoDetails(instanceId: number) {
    history.push(`/healthcampinstance/${healthcampid}/${instanceId}`);
  }
  const showAddDialog = () => {
    setShowFilterDialog(true);
    setHealthcampInstanceId("");
  };
  const editInstance = (healthcampInstanceId: string) => {
    setHealthcampInstanceId(healthcampInstanceId);
    setShowFilterDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },

    {
      field: "displayName",
      headerName: "Instance Name",
      editable: true,
      minWidth: 300,
      align: "left",
      flex: 1,
    },
    {
      field: "createDate",
      headerName: "Created Date",
      editable: true,
      minWidth: 139,
      align: "left",
    },
    {
      field: "instanceStartDate",
      headerName: "Instance Start Date",
      editable: true,
      minWidth: 136,
      align: "left",
    },
    {
      field: "instanceEndDate",
      headerName: "Instance End Date",
      editable: true,
      minWidth: 138,
      align: "left",
    },
    {
      field: "healthcampInstanceId",
      headerName: "Action",
      editable: true,
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => {
            editInstance(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const StaffListOfHealthCamp = (
    <Card>
      {instanceListResponseValue?.getInstanceList?.healthcampInstanceList !== undefined ? (
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={() => showAddDialog()}
                >
                  Add Healthcamp Instance
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
      <CardContent>
        <Box>
          {instanceListResponseValue?.getInstanceList?.healthcampInstanceList &&
          instanceListResponseValue?.getInstanceList?.healthcampInstanceList.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {StaffListOfHealthCamp}
      {showFilterDialog ? (
        <AddHealthcampInstanceDialog
          filterDialogEnable={showFilterDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          healthcampid={healthcampid}
          healthcampInstanceId={healthcampInstanceId}
        />
      ) : null}
    </Box>
  );
}
