import React from "react";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchPatientComponent from "../SearchPatientComponent";
import { useDispatch } from "react-redux";
import { clearAddExistingPatientByProviderOrgIdAPIRes } from "redux/AddExistingPatientByProviderOrgId/API";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  title: string | any;
  providerorgId: string | any;
};

export default function AddPatientForProviderOrg({
  filterDialogEnable,
  filterDialogUpdateState,
  title,
  providerorgId,
}: Props) {
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearAddExistingPatientByProviderOrgIdAPIRes());
  };

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                Search Patient in medicalWISDOM Platform for {title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <SearchPatientComponent title={title} organizationid={providerorgId} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
