import { OrganizationAddRegistrationRequestDetails } from "./Model";

// Patient Demographics Loading State
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_LOADING = "ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_LOADING";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_FAIL = "ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_FAIL";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_MSG = "ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_MSG";
export const ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_RES = "ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_RES";

export interface OrganizationAddRegistrationRequestDetailsState {
  organizationAddRegistrationRequestDetailsRes: OrganizationAddRegistrationRequestDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OrganizationAddRegistrationRequestDetailsLoading {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_LOADING;
  loading: boolean;
}
export interface OrganizationAddRegistrationRequestDetailsSuccess {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_SUCCESS;
  payload: OrganizationAddRegistrationRequestDetails;
  successMsg: string;
}
export interface OrganizationAddRegistrationRequestDetailsFail {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_FAIL;
  payload: OrganizationAddRegistrationRequestDetails;
  errorMsg: string;
  status: number;
}
export interface OrganizationAddRegistrationRequestDetailsAPIMsg {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_MSG;
  payload: OrganizationAddRegistrationRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OrganizationAddRegistrationRequestDetailsAPIRes {
  type: typeof ORGANIZATION_ADD_REGISTRATION_REQUEST_DETAILS_API_RES;
  payload: OrganizationAddRegistrationRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OrganizationAddRegistrationRequestDetailsActionTypes =
  | OrganizationAddRegistrationRequestDetailsLoading
  | OrganizationAddRegistrationRequestDetailsSuccess
  | OrganizationAddRegistrationRequestDetailsFail
  | OrganizationAddRegistrationRequestDetailsAPIMsg
  | OrganizationAddRegistrationRequestDetailsAPIRes;
