import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { TabContext, TabPanel } from "@mui/lab";
import ProviderOrgListPage from "./ProviderOrgListPage";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { getProviderOrganizationListValue } from "../../redux/ProviderOrganizationList/API";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";

export default function ProviderOrganizationList() {
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useState(false);
  const [selected, setSelected] = React.useState("1");
  const [listCount, setListCount] = React.useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const organizationList = useSelector((state: AppState) => state.getProviderOrganizationListValue);

  useEffect(() => {
    dispatch(getProviderOrganizationListValue(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function organizationListApi() {
    dispatch(getProviderOrganizationListValue(1));
  }

  useEffect(() => {
    organizationList?.getProviderOrganizationRes?.count !== undefined
      ? setListCount(String(organizationList?.getProviderOrganizationRes?.count))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList, selected]);

  const addProviderOrganization = () => {
    history.push("/addproviderorganization");
  };

  const providerOrgListPage = (
    <Box>
      <Box sx={{ width: fullScreen ? "calc(100vw - 10vw)" : "100%" }}>
        <Tabs
          value={selected}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Provider Organization List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={listCount} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  organizationListApi();
                }}
              />
            }
            iconPosition="end"
            value="1"
          />
        </Tabs>
      </Box>
      <Divider />
      {fullScreen ? (
        <Box pt={2}>{selected === "1" ? <ProviderOrgListPage /> : null}</Box>
      ) : (
        <TabContext value={String(selected)}>
          <TabPanel value="1">
            <ProviderOrgListPage />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <MWPageTitle title={"Provider Organization"} />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Button variant="contained" onClick={() => addProviderOrganization()}>
                Add Provider Organization
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {providerOrgListPage}
    </PageLayout>
  );
}
