import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import MWSnackbar from "component/MWSnackbar";
import AddCompetitionStaffDialog from "../AddCompetitionStaffDialog";
import { getDeactiveStaffListByCompetitionId } from "redux/StaffListByCompetition/CompetitionDeactiveStaffList/API";
import {
  StaffList,
  GetCompetitionDeactiveStaffListBody,
} from "redux/StaffListByCompetition/CompetitionDeactiveStaffList/Model";
import { DiactiveStaffOfCompetitionBody } from "redux/StaffListByCompetition/DiactiveStaffOfCompetition/Model";
import { clearCompetitionDiactiveStaffOfAPIRes, diactiveStaffOfCompetitionApiCall } from "redux/StaffListByCompetition/DiactiveStaffOfCompetition/API";

type Props = {
  judgmentListOfCompetitonLoader?: boolean | any;
};

export default function DeactiveStaffList({ judgmentListOfCompetitonLoader }: Props) {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const [page, setPage] = useState(1);

  const [pageListCount, setPageListCount] = React.useState("");
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const [addOrganizationStaffDialog, setAddOrganizationStaffDialog] = React.useState(false);
  const updateAddOrganizationStaffDialogValFromChild = (passedVal: boolean) => {
    setAddOrganizationStaffDialog(passedVal);
  };

  useEffect(() => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetCompetitionDeactiveStaffListBody;
    dispatch(getDeactiveStaffListByCompetitionId(patientListBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const deactiveStaffListOfOrg = useSelector(
    (state: AppState) => state.competitionDeactiveStaffValues,
  );
  const diactiveStaffResponseValue = useSelector((state: AppState) => state.toggleCompetitionStaffValues);
  const addStaffOrgValue = useSelector((state: AppState) => state.addStaffOfOrgValue);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const deactiveStaff = (staffId: string) => {
    let diactiveBody = {
      competitionId: competitionid,
      staffId: staffId,
    } as DiactiveStaffOfCompetitionBody;
    dispatch(diactiveStaffOfCompetitionApiCall(diactiveBody));
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  useEffect(() => {
    deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.numberOfPages !== undefined
      ? setPageListCount(
          String(deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.numberOfPages),
        )
      : setPageListCount("0");
    if (deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.StaffList !== undefined) {
      let dataPointArr =
        deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.StaffList.map(
          (element: StaffList) => ({
            id: element.id,
            staffName: element.email,
            firstName: element.first_name,
            lastName: element.last_name,
            userId: element.userId,
          }),
        );
      setDataGridArray(dataPointArr);
    }
    if (deactiveStaffListOfOrg?.errorMsg !== "") {
      setNotAdministratorMsg(deactiveStaffListOfOrg?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactiveStaffListOfOrg]);

  useEffect(() => {
    if (diactiveStaffResponseValue?.DiactiveStaffOfCompetitionRes?.message !== undefined) {
      setDiactiveMsg(diactiveStaffResponseValue?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const patientListBody = {
        competitionId: competitionid,
      } as GetCompetitionDeactiveStaffListBody;
      dispatch(getDeactiveStaffListByCompetitionId(patientListBody, page));
      dispatch(clearCompetitionDiactiveStaffOfAPIRes());
    }
    if (diactiveStaffResponseValue?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(diactiveStaffResponseValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diactiveStaffResponseValue]);

  useEffect(() => {
    if (addStaffOrgValue?.AddStaffOfOrgRes?.message !== undefined) {
      setDiactiveMsg(addStaffOrgValue?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const patientListBody = {
        competitionId: competitionid,
      } as GetCompetitionDeactiveStaffListBody;
      dispatch(getDeactiveStaffListByCompetitionId(patientListBody, page));
    }
    if (addStaffOrgValue?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(addStaffOrgValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStaffOrgValue]);

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "staffName",
      headerName: "User Name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Action",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deactiveStaff(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Active Staff
        </Button>
      ),
    },
  ];

  const StaffListOfOrganization = (
    <Card>
      {deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.StaffList !== undefined ? (
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item></Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography>Page: {page}</Typography>
                  </Grid>
                  <Grid item>
                    <Pagination
                      color="primary"
                      count={Number(pageListCount) || 0}
                      page={page}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      ) : null}

      <CardContent>
        {notAdministratorMsg !== "" ? (
          <Alert severity="error">{notAdministratorMsg}</Alert>
        ) : (
          <Box>
            {deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.StaffList &&
            deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.StaffList.length ? (
              <DataGrid
                rows={dataGridArray}
                columns={columns}
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {deactiveStaffListOfOrg?.loading === true
        ? judgmentListOfCompetitonLoader(true)
        : judgmentListOfCompetitonLoader(false)}
      {StaffListOfOrganization}
      {addOrganizationStaffDialog ? (
        <AddCompetitionStaffDialog
          itemId={competitionid}
          addOrganizationStaffDialogEnable={addOrganizationStaffDialog}
          addOrganizationStaffDialogUpdateState={updateAddOrganizationStaffDialogValFromChild}
        />
      ) : null}
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
