import { GetPatientCaseListByPatientIdRes } from "./Model";
export const PATIENT_CASE_LIST_BY_PATIENT_ID_LOADING = "PATIENT_CASE_LIST_BY_PATIENT_ID_LOADING";
export const PATIENT_CASE_LIST_BY_PATIENT_ID_SUCCESS = "PATIENT_CASE_LIST_BY_PATIENT_ID_SUCCESS";
export const PATIENT_CASE_LIST_BY_PATIENT_ID_FAIL = "PATIENT_CASE_LIST_BY_PATIENT_ID_FAIL";
export const PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_MSG = "PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_MSG";
export const PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_RES = "PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_RES";

export interface GetPatientCaseListByPatientIdState {
  getPatientCaseListByPatientIdRes: GetPatientCaseListByPatientIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseListByPatientIdLoading {
  type: typeof PATIENT_CASE_LIST_BY_PATIENT_ID_LOADING;
  loading: boolean;
}
export interface GetPatientCaseListByPatientIdSuccess {
  type: typeof PATIENT_CASE_LIST_BY_PATIENT_ID_SUCCESS;
  payload: GetPatientCaseListByPatientIdRes;
  successMsg: string;
}
export interface GetPatientCaseListByPatientIdFail {
  type: typeof PATIENT_CASE_LIST_BY_PATIENT_ID_FAIL;
  payload: GetPatientCaseListByPatientIdRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseListByPatientIdUpdateAPIMsg {
  type: typeof PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_MSG;
  payload: GetPatientCaseListByPatientIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseListByPatientIdUpdateAPIRes {
  type: typeof PATIENT_CASE_LIST_BY_PATIENT_ID_UPDATE_API_RES;
  payload: GetPatientCaseListByPatientIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientCaseListByPatientIdActionTypes =
  | GetPatientCaseListByPatientIdLoading
  | GetPatientCaseListByPatientIdSuccess
  | GetPatientCaseListByPatientIdFail
  | GetPatientCaseListByPatientIdUpdateAPIMsg
  | GetPatientCaseListByPatientIdUpdateAPIRes;
