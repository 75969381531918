import { GetDoctorListInHealthCampRes } from "./Model";
export const DOCTOR_LIST_IN_HEALTH_CAMP_LOADING = "DOCTOR_LIST_IN_HEALTH_CAMP_LOADING";
export const DOCTOR_LIST_IN_HEALTH_CAMP_SUCCESS = "DOCTOR_LIST_IN_HEALTH_CAMP_SUCCESS";
export const DOCTOR_LIST_IN_HEALTH_CAMP_FAIL = "DOCTOR_LIST_IN_HEALTH_CAMP_FAIL";
export const DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_MSG =
  "DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_MSG";
export const DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_RES =
  "DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_RES";

export interface GetDoctorListInHealthCampState {
  getDoctorListInHealthCampList: GetDoctorListInHealthCampRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListInHealthCampLoading {
  type: typeof DOCTOR_LIST_IN_HEALTH_CAMP_LOADING;
  loading: boolean;
}
export interface GetDoctorListInHealthCampSuccess {
  type: typeof DOCTOR_LIST_IN_HEALTH_CAMP_SUCCESS;
  payload: GetDoctorListInHealthCampRes;
  successMsg: string;
}
export interface GetDoctorListInHealthCampFail {
  type: typeof DOCTOR_LIST_IN_HEALTH_CAMP_FAIL;
  payload: GetDoctorListInHealthCampRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListInHealthCampUpdateAPIMsg {
  type: typeof DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_MSG;
  payload: GetDoctorListInHealthCampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListInHealthCampUpdateAPIRes {
  type: typeof DOCTOR_LIST_IN_HEALTH_CAMP_UPDATE_API_RES;
  payload: GetDoctorListInHealthCampRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorListInHealthCampActionTypes =
  | GetDoctorListInHealthCampLoading
  | GetDoctorListInHealthCampSuccess
  | GetDoctorListInHealthCampFail
  | GetDoctorListInHealthCampUpdateAPIMsg
  | GetDoctorListInHealthCampUpdateAPIRes;
