import {
  ADD_DATA_CURATION_PROBLEM_FAIL,
  ADD_DATA_CURATION_PROBLEM_LOADING,
  ADD_DATA_CURATION_PROBLEM_SUCCESS,
  ADD_DATA_CURATION_PROBLEM_API_MSG,
  ADD_DATA_CURATION_PROBLEM_API_RES,
  AddDataCurationProblemActionTypes,
} from "./ActionTypes";
import { AddDataCurationProblemDetails } from "./Model";

export const addDataCurationProblemLoadingAction = (
  loading: boolean,
): AddDataCurationProblemActionTypes => {
  return {
    type: ADD_DATA_CURATION_PROBLEM_LOADING,
    loading: loading,
  };
};

export const addDataCurationProblemSuccessAction = (
  addDataCurationProblemResponse: AddDataCurationProblemDetails,
  successMsg: string,
): AddDataCurationProblemActionTypes => {
  return {
    type: ADD_DATA_CURATION_PROBLEM_SUCCESS,
    payload: addDataCurationProblemResponse,
    successMsg: successMsg,
  };
};

export const addDataCurationProblemErrorAction = (
  addDataCurationProblemResponse: AddDataCurationProblemDetails,
  errMsg: string,
  status: number,
): AddDataCurationProblemActionTypes => {
  return {
    type: ADD_DATA_CURATION_PROBLEM_FAIL,
    payload: addDataCurationProblemResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDataCurationProblemAPIMsgAction = (
  addDataCurationProblemResponse: AddDataCurationProblemDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDataCurationProblemActionTypes => {
  return {
    type: ADD_DATA_CURATION_PROBLEM_API_MSG,
    payload: addDataCurationProblemResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDataCurationProblemAPIResClearAction = (
  addDataCurationProblemResponse: AddDataCurationProblemDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDataCurationProblemActionTypes => {
  return {
    type: ADD_DATA_CURATION_PROBLEM_API_RES,
    payload: addDataCurationProblemResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
