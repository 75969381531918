import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ExtractObsTypeBySessionIdActionTypes } from "./ActionTypes";
import { ExtractObsTypeBySessionIdBody, ExtractObsTypeBySessionIdRes } from "./Model";
import {
  extractObsTypeBySessionIdAPIResClearAction,
  extractObsTypeBySessionIdErrorAction,
  extractObsTypeBySessionIdLoadingAction,
  extractObsTypeBySessionIdSuccessAction,
  extractObsTypeBySessionIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as ExtractObsTypeBySessionIdRes;
export const extractObsTypeBySessionId = (payload: ExtractObsTypeBySessionIdBody, pageNo: any) => {
  return function (dispatch: Dispatch<ExtractObsTypeBySessionIdActionTypes>) {
    dispatch(extractObsTypeBySessionIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsessionpatientdetails/extractObservationsFromPatientDetailsOfSession?page=${Number(pageNo)}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(extractObsTypeBySessionIdLoadingAction(false));
        dispatch(
          extractObsTypeBySessionIdSuccessAction(
            res.data !== undefined ? res.data : ({} as ExtractObsTypeBySessionIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Extract Observation fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(extractObsTypeBySessionIdLoadingAction(false));
        dispatch(
          extractObsTypeBySessionIdErrorAction(
            {} as ExtractObsTypeBySessionIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateExtractObsTypeOfSessionIdAPIResMsg = () => {
  return function (dispatch: Dispatch<ExtractObsTypeBySessionIdActionTypes>) {
    dispatch(extractObsTypeBySessionIdUpdateAPIMsgAction(apiRes as ExtractObsTypeBySessionIdRes, "", "", 0));
  };
};

export const clearExtractObsTypeOfSessionIdAPIRes = () => {
  return function (dispatch: Dispatch<ExtractObsTypeBySessionIdActionTypes>) {
    dispatch(extractObsTypeBySessionIdAPIResClearAction({} as ExtractObsTypeBySessionIdRes, "", "", 0));
  };
};
