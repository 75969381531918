import { DeleteAccountRequestDetails } from "./Model";
export const DELETE_ACCOUNT_REQUEST_LOADING = "DELETE_ACCOUNT_REQUEST_LOADING";
export const DELETE_ACCOUNT_REQUEST_SUCCESS = "DELETE_ACCOUNT_REQUEST_SUCCESS";
export const DELETE_ACCOUNT_REQUEST_FAIL = "DELETE_ACCOUNT_REQUEST_FAIL";
export const DELETE_ACCOUNT_REQUEST_API_MSG = "DELETE_ACCOUNT_REQUEST_API_MSG";
export const DELETE_ACCOUNT_REQUEST_API_RES = "DELETE_ACCOUNT_REQUEST_API_RES";

export interface DeleteAccountRequestState {
  deleteAccountRequestRes: DeleteAccountRequestDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteAccountRequestLoading {
  type: typeof DELETE_ACCOUNT_REQUEST_LOADING;
  loading: boolean;
}
export interface DeleteAccountRequestSuccess {
  type: typeof DELETE_ACCOUNT_REQUEST_SUCCESS;
  payload: DeleteAccountRequestDetails;
  successMsg: string;
}
export interface DeleteAccountRequestFail {
  type: typeof DELETE_ACCOUNT_REQUEST_FAIL;
  payload: DeleteAccountRequestDetails;
  errorMsg: string;
  status: number;
}
export interface DeleteAccountRequestAPIMsg {
  type: typeof DELETE_ACCOUNT_REQUEST_API_MSG;
  payload: DeleteAccountRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteAccountRequestAPIRes {
  type: typeof DELETE_ACCOUNT_REQUEST_API_RES;
  payload: DeleteAccountRequestDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export type DeleteAccountRequestActionTypes =
  | DeleteAccountRequestLoading
  | DeleteAccountRequestSuccess
  | DeleteAccountRequestFail
  | DeleteAccountRequestAPIMsg
  | DeleteAccountRequestAPIRes;
