import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Grid,
  TextField,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import { getObservationTypeDetails } from "redux/GetObservationTypeList/API";
import { PatientDetailsFromDashboardBody } from "redux/patientDataDetails/patientDetailsById/Model";
import { PatientDetailsTypeList } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/Model";
import { patientDetailsTypeList } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/API";
import { AppState } from "redux/store/Store";
import {
  addPatientDataDetails,
  addPatientDataDetailsUpdateAPIResMsg,
  clearPatientDataPointClearAPIRes,
} from "redux/patientDataDetails/addPatientDetails/API";
import {
  clearPatientDataPointEditAPIRes,
  updatePatientDataDetailsApi,
  updatePatientDataDetailsEditAPIResMsg,
} from "redux/patientDataDetails/UpdatePatientDataPoint/API";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import { AddPatientDataDetailsBody } from "redux/patientDataDetails/addPatientDetails/Model";
import {
  ModifiedObservationTypeList,
  ModifiedPatientDetailsTypeList,
} from "./Model";
import { getPatientDataDetailsById } from "redux/patientDataDetails/patientDetailsById/API";
import { UpdatePatientDataDetailsBody } from "redux/patientDataDetails/UpdatePatientDataPoint/Model";
import AdminLoader from "pages/AdminLoader";
import MWUploader from "component/MWUploader";
import { getUnprocessedPatientDetailsList } from "redux/UnprocessedPatientDetails/API";

type Props = {
  itemId?: string | any;
  addDataPointDialogEnable?: boolean | any;
  addDataPointDialogUpdateState?: boolean | any;
  value?: any;
  sessionId?: any;
  patientId?: string | any;
};

export default function UpdateUnproccessedPatientDetails({
  itemId,
  addDataPointDialogEnable,
  addDataPointDialogUpdateState,
  value,
  patientId,
}: Props) {
  const dispatch = useDispatch();
  const [imageId, setImageId] = React.useState("");
  const [dataPoint, setDataPoint] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState<Date | any>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const [selectedItem, setSelectedItem] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);

  const handleDialogClose = () => {
    setDialogState(false);
    addDataPointDialogUpdateState(false);
    setDataPoint("");
    setSelectedDate(new Date());
    dispatch(clearPatientDataPointClearAPIRes());
    dispatch(clearPatientDataPointEditAPIRes());
    dispatch(clearImageUploadAPIRes());
    dispatch(addPatientDataDetailsUpdateAPIResMsg());
    dispatch(updatePatientDataDetailsEditAPIResMsg());
  };

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };
  const [patientDetailsTypeListValue, setPatientDetailsTypeListValue] = React.useState([] as any[]);
  const [selectedPatientDetailsType, setSelectedPatientDetailsType] =
    React.useState<ModifiedPatientDetailsTypeList | null>(null);
  const handlePatientDetailsTypeChange = (value: ModifiedPatientDetailsTypeList | null) => {
    setSelectedPatientDetailsType(value);
  };
  
  const dataPointBody = {
    patientId: patientId!,
    patientDocumentId: imageId!,
    startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
    description: dataPoint!,
    patientDetailsType:
      (selectedPatientDetailsType !== undefined && selectedPatientDetailsType?.value),
  } as AddPatientDataDetailsBody;
  const [editImageId, setEditImageId] = useState("");

  useEffect(() => {
    setSelectedItem(itemId);
    setDialogState(addDataPointDialogEnable);
    if (addDataPointDialogEnable !== undefined) {
      setDataPoint("");
      setSelectedDate(new Date());
      dispatch(clearPatientDataPointClearAPIRes());
      dispatch(clearPatientDataPointEditAPIRes());
      dispatch(clearImageUploadAPIRes());
      dispatch(getObservationTypeDetails());
      dispatch(patientDetailsTypeList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, addDataPointDialogEnable]);

  const addDataDetailsStoreVal = useSelector((state: AppState) => state.addPointDataDetailsRes);
  useEffect(() => {
    if (value !== undefined) {
      if (value.description !== undefined) {
        setDataPoint(value.description);
      } else setDataPoint("");
    } else {
      setDataPoint("");
      setSelectedDate(new Date());
      setEditImageId("");
    }
  }, [value]);
  const patientDetailsTypeListRes = useSelector((state: AppState) => state.patientDetailsType);
  useEffect(() => {
    if (
      patientDetailsTypeListRes?.patientDetailsTypeListRes?.patientDetailsTypeList !== undefined
    ) {
      let patientDetailsTypeDropdownValue =
        patientDetailsTypeListRes?.patientDetailsTypeListRes?.patientDetailsTypeList.map(
          (element: PatientDetailsTypeList) => ({
            value: element.id,
            label: element.displayValue !== "" ? element.displayValue : "",
          }),
        );
      setPatientDetailsTypeListValue(patientDetailsTypeDropdownValue);
    } else setPatientDetailsTypeListValue([] as ModifiedPatientDetailsTypeList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsTypeListRes]);
  const updateDataDetailsVal = useSelector((state: AppState) => state.updatePatientDataDetailsRes);
  React.useEffect(() => {
    if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
      handleDialogClose();
      dispatch(getUnprocessedPatientDetailsList(1));
      if (selectedItem !== undefined) {
        let patientDetailsBody = {
          patientDetailsId: selectedItem,
        } as PatientDetailsFromDashboardBody;
        dispatch(getPatientDataDetailsById(patientDetailsBody));
        // dispatch(getPatientDataDetailsById(selectedItem));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataDetailsVal]);
  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  let editImgId = imageId !== undefined ? imageId! : editImageId;
  const editDataPointBody = {
    patientDetailsId: String(value?.id!),
    description: dataPoint!,
    startDate: dayjs(String(selectedDate)).format("YYYY-MM-DDTHH:mm:ss"),
    patientDocumentId: String(editImgId),
    patientDetailsType:
      (selectedPatientDetailsType !== undefined && selectedPatientDetailsType?.value),
  } as UpdatePatientDataDetailsBody;

  const submitPatientData = () => {
    if (delImgAction) {
      setDelImgAction(false);
    }
    value?.id === undefined
      ? dispatch(addPatientDataDetails(dataPointBody))
      : dispatch(updatePatientDataDetailsApi(editDataPointBody));
  };
  const [delImgAction, setDelImgAction] = useState(false);
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };

  const [observationList, setObservationList] = useState([] as ModifiedObservationTypeList[]);
  const [selectedObservation, setSelectedObservation] = useState<
    ModifiedObservationTypeList | null | any
  >(null);
  const changeSelectedObservation = (passedVal: ModifiedObservationTypeList | null) => {
    setSelectedObservation(passedVal);
    if (passedVal !== null) {
      setDataPoint(`${passedVal.name}: `);
    }
  };
  const getObservationTypeListRes = useSelector((state: AppState) => state.getObservationTypeValue);
  useEffect(() => {
    if (getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      let observationTypeListItem =
        getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList.map(
          (element: ObservationTypeList) => ({
            value: element.id,
            label: element.displayValue !== "" ? element.displayValue : "",
            name: element.name,
          }),
        );
      setObservationList(observationTypeListItem);
    } else setObservationList([] as ModifiedObservationTypeList[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObservationTypeListRes]);

  useEffect(() => {
    if (dataPoint.length > 0 && observationList.length > 0) {
      if (
        observationList!.find(
          (element: ModifiedObservationTypeList) => element?.name === dataPoint.split(":")[0],
        ) !== undefined
      ) {
        setSelectedObservation(
          observationList.find(
            (element: ModifiedObservationTypeList) => element?.name === dataPoint.split(":")[0],
          ),
        );
      }
    }
  }, [dataPoint, observationList]);
  return (
    <Box>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <Box>
          {updateDataDetailsVal?.loading || addDataDetailsStoreVal?.loading ? (
            <AdminLoader />
          ) : null}
        </Box>
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4">
                {value?.id !== undefined ? "Update " : "Add "}Patient Details
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => handleDialogClose()}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "grey",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    disabled={addDataDetailsStoreVal?.loading || updateDataDetailsVal?.loading}
                    value={selectedObservation}
                    options={observationList}
                    onChange={(_event: any, newValue: any) => {
                      changeSelectedObservation(newValue);
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} label="Observation Type" variant="outlined" />
                    )}
                    getOptionLabel={(option: any) => `${option.label}`}
                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    autoFocus
                    id="data"
                    label="Data"
                    variant="outlined"
                    value={dataPoint}
                    onChange={(e) => {
                      setDataPoint(e.target.value);
                    }}
                    size="small"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={selectedDate}
                      onChange={(date) => handleDateChange(date)}
                      views={["year", "month", "day"]}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          inputProps={{ ...params.inputProps, readOnly: true }}
                          size="small"
                        />
                      )}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="patient-dtails-type"
                    disableClearable
                    value={selectedPatientDetailsType}
                    options={patientDetailsTypeListValue}
                    onChange={(_event: any, newValue: any) => {
                      handlePatientDetailsTypeChange(newValue);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select All Select Patient Details Type"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option: any, value) => option.value === value.value}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 25 mb."
                updatedDataState={updateDataStateFromChild}
                title={"Upload Patient Observation"}
                imageUrl={value?.patientDocument?.document}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={submitPatientData}
            variant="contained"
            disabled={
              imageUploadRes?.loading ||
              addDataDetailsStoreVal?.loading ||
              updateDataDetailsVal?.loading ||
              checkImgUpload
            }
          >
            {value?.id !== undefined ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
