import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { Box } from "@mui/material";
import { HearRateData } from "../Model";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";

type Props = {
  data?: HearRateData[] | any;
  unit?: string | any;
};

export default function WeightTrends({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as HearRateData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);

  useEffect(() => {
    var root = am5.Root.new("weightchart");
    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }),
    );

    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("forceHidden", true);
    cursor.lineY.set("forceHidden", true);

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: unitt,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    xAxis.children.unshift(
      am5.Label.new(root, {
        rotation: 0,
        text: "Months",
        fontSize: 16,
        fontWeight: "bold",
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.Color.fromString("#ffffff"),
      }),
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        fontSize: 16,
        fontWeight: "bold",
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.Color.fromString("#ffffff"),
      }),
    );

    // Add series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "right",
          labelText: `[bold]Weight: {valueY}[/]\n[bold]Date: {valueX.formatDate()}[/]`,
        }),
        fill: am5.Color.fromString("#67b7dc"),
        stroke: am5.Color.fromString("#34a8eb"),
      }),
    );
    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    // Add scrollbar
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    // Set data
    series.data.setAll(chartData);
    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unitt]);

  return <Box id="weightchart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
