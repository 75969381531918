import {
    GetSingleEncounterListActionTypes,
    GET_SINGLE_ENCOUNTER_LIST_FAIL,
    GET_SINGLE_ENCOUNTER_LIST_LOADING,
    GET_SINGLE_ENCOUNTER_LIST_SUCCESS,
    GetSingleEncounterListState,
    GET_SINGLE_ENCOUNTER_LIST_API_MSG,
    GET_SINGLE_ENCOUNTER_LIST_API_RES,
  } from "./ActionTypes";
  import { GetSingleEncounterListRes } from "./Model";
  
  const initialStateUpdatePosts: GetSingleEncounterListState = {
    loading: false,
    getSingleEncounterListRes: {} as GetSingleEncounterListRes,
    errorMsg: "",
    status: 0,
  };
  export const getSingleEncounterListReducer = (
    state = initialStateUpdatePosts,
    action: GetSingleEncounterListActionTypes,
  ): GetSingleEncounterListState => {
    switch (action.type) {
      case GET_SINGLE_ENCOUNTER_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case GET_SINGLE_ENCOUNTER_LIST_SUCCESS:
        return {
          ...state,
          getSingleEncounterListRes: action.payload,
        };
      case GET_SINGLE_ENCOUNTER_LIST_FAIL:
        return {
          ...state,
          getSingleEncounterListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case GET_SINGLE_ENCOUNTER_LIST_API_MSG:
        return {
          ...state,
          getSingleEncounterListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case GET_SINGLE_ENCOUNTER_LIST_API_RES:
        return {
          ...state,
          getSingleEncounterListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  