import {
  Avatar,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory, useLocation } from "react-router-dom";

type ArrayType<T> = T[];
type Props = {
  icon?: boolean | any;
  showSubtitle?: boolean | any;
  showReason?: boolean | any;
  caseId?: number | any;
  patientId?: number | any;
  showNavigation?: boolean | any;
  data: ArrayType<any> | any;
  path?: string | any;
};

export default function ModifiedList({
  icon,
  showSubtitle,
  showReason,
  showNavigation,
  data,
  path,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <List
      disablePadding
      dense={location.pathname.includes !== "/patientcaselist"}
      sx={{
        width: "100%",
        maxWidth: "100%",
        bgcolor: location.pathname.includes("/patientcaselist") ? "background.paper" : "#303030",
      }}
    >
      {data.length > 0 &&
        data.map((item: any) => {
          return (
            <ListItem
              key={item?.id}
              disablePadding
              sx={{
                marginBottom: location.pathname.includes("/patientcaselist") ? "16px" : "5px",
                bgcolor: location.pathname.includes("/patientcaselist")
                  ? "#303030"
                  : "background.paper",
                borderRadius: 3,
              }}
            >
              <ListItemButton
                onClick={() =>
                  showNavigation && history.push(`/${path}/${item.patientId}/${item.caseId}`)
                }
              >
                <ListItemIcon>
                  {icon ? <VisibilityOutlinedIcon /> : <Avatar src="/broken-image.jpg" />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography fontWeight="bold">
                      {item?.whyWant2ndOpinion !== undefined
                        ? item?.whyWant2ndOpinion !== ""
                          ? item?.whyWant2ndOpinion
                          : null
                        : null}
                    </Typography>
                  }
                  secondary={
                    <Box>
                      <Typography variant="subtitle2">
                        {showSubtitle
                          ? item?.secondOpinionReasons !== undefined
                            ? item?.secondOpinionReasons !== ""
                              ? item?.secondOpinionReasons
                              : null
                            : null
                          : null}
                      </Typography>
                      {showReason ? (
                        <Chip
                          size="small"
                          color="primary"
                          label={
                            item?.consultationType !== undefined
                              ? item?.consultationType !== ""
                                ? item?.consultationType
                                : null
                              : null
                          }
                        />
                      ) : null}
                    </Box>
                  }
                />
                {showNavigation && (
                  <IconButton edge="end" aria-label="delete" disabled>
                    <ChevronRightIcon />
                  </IconButton>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}
