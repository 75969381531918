import { PageCount, AddSessionDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_SESSION_LOADING = "ADD_SESSION_LOADING";
export const ADD_SESSION_SUCCESS = "Add_STAFF_OF_ORG_SUCCESS";
export const ADD_SESSION_FAIL = "ADD_SESSION_FAIL";
export const ADD_SESSION_DATA = "ADD_SESSION_DATA";
export const ADD_SESSION_API_MSG = "ADD_SESSION_API_MSG";
export const ADD_SESSION_API_RES = "ADD_SESSION_API_RES";

export interface AddSessionState {
  addSessionRes: AddSessionDetails;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddSessionLoading {
  type: typeof ADD_SESSION_LOADING;
  loading: boolean;
}
export interface AddSessionSuccess {
  type: typeof ADD_SESSION_SUCCESS;
  payload: AddSessionDetails;
  successMsg: string;
}
export interface AddSessionFail {
  type: typeof ADD_SESSION_FAIL;
  payload: AddSessionDetails;
  errorMsg: string;
  status: number;
}
export interface AddSessionAPIMsg {
  type: typeof ADD_SESSION_API_MSG;
  payload: AddSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddSessionAPIRes {
  type: typeof ADD_SESSION_API_RES;
  payload: AddSessionDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
interface AddSessionDataAction {
  type: typeof ADD_SESSION_DATA;
  payload: AddSessionDetails;
  pageCount: PageCount;
}

export type AddSessionActionTypes =
  | AddSessionDataAction
  | AddSessionLoading
  | AddSessionSuccess
  | AddSessionFail
  | AddSessionAPIMsg
  | AddSessionAPIRes;
