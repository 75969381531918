import {
  GetCompetitionListActionTypes,
  GET_COMPETITION_LIST_FAIL,
  GET_COMPETITION_LIST_LOADING,
  GET_COMPETITION_LIST_SUCCESS,
  GetCompetitionListState,
  GET_COMPETITION_LIST_API_MSG,
  GET_COMPETITION_LIST_API_RES,
} from "./ActionTypes";
import { GetCompetitionListRes } from "./Model";

const initialStateUpdatePosts: GetCompetitionListState = {
  loading: false,
  getCompetitionListRes: {} as GetCompetitionListRes,
  errorMsg: "",
  status: 0,
};
export const getCompetitionListReducer = (
  state = initialStateUpdatePosts,
  action: GetCompetitionListActionTypes,
): GetCompetitionListState => {
  switch (action.type) {
    case GET_COMPETITION_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_COMPETITION_LIST_SUCCESS:
      return {
        ...state,
        getCompetitionListRes: action.payload,
      };
    case GET_COMPETITION_LIST_FAIL:
      return {
        ...state,
        getCompetitionListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_COMPETITION_LIST_API_MSG:
      return {
        ...state,
        getCompetitionListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_COMPETITION_LIST_API_RES:
      return {
        ...state,
        getCompetitionListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
