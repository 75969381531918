import { ProviderOrgPatientDetailsRes } from "./Model";
export const PROV_ORG_PT_DETAILS_LOADING = "PROV_ORG_PT_DETAILS_LOADING";
export const PROV_ORG_PT_DETAILS_SUCCESS = "PROV_ORG_PT_DETAILS_SUCCESS";
export const PROV_ORG_PT_DETAILS_FAIL = "PROV_ORG_PT_DETAILS_FAIL";
export const PROV_ORG_PT_DETAILS_DATA = "PROV_ORG_PT_DETAILS_DATA";
export const PROV_ORG_PT_DETAILS_API_MSG = "PROV_ORG_PT_DETAILS_API_MSG";
export const PROV_ORG_PT_DETAILS_API_RES = "PROV_ORG_PT_DETAILS_API_RES";

export interface ProviderOrgPtDetailsState {
  providerOrgPtDtRes: ProviderOrgPatientDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface ProviderOrgPtDetailsLoading {
  type: typeof PROV_ORG_PT_DETAILS_LOADING;
  loading: boolean;
}
export interface ProviderOrgPtDetailsSuccess {
  type: typeof PROV_ORG_PT_DETAILS_SUCCESS;
  payload: ProviderOrgPatientDetailsRes;
}
export interface ProviderOrgPtDetailsFail {
  type: typeof PROV_ORG_PT_DETAILS_FAIL;
  payload: ProviderOrgPatientDetailsRes;
  errorMsg: string;
  status: number;
}
export interface ProviderOrgPtDetailsAPIMsg {
  type: typeof PROV_ORG_PT_DETAILS_API_MSG;
  payload: ProviderOrgPatientDetailsRes;
  errorMsg: string;
  status: number;
}
export interface ProviderOrgPtDetailsAPIRes {
  type: typeof PROV_ORG_PT_DETAILS_API_RES;
  payload: ProviderOrgPatientDetailsRes;
  errorMsg: string;
  status: number;
}

export type ProviderOrgPtDetailsActionTypes =
  | ProviderOrgPtDetailsLoading
  | ProviderOrgPtDetailsSuccess
  | ProviderOrgPtDetailsFail
  | ProviderOrgPtDetailsAPIMsg
  | ProviderOrgPtDetailsAPIRes;
