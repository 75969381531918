import {
  GetPatientListOfCompetitionActionTypes,
  PATIENT_LIST_OF_COMPETITION_ID_FAIL,
  PATIENT_LIST_OF_COMPETITION_ID_LOADING,
  PATIENT_LIST_OF_COMPETITION_ID_SUCCESS,
  PatientListOfCompetitionDetailsState,
  PATIENT_LIST_OF_COMPETITION_API_MSG,
  PATIENT_LIST_OF_COMPETITION_API_RES,
} from "./ActionTypes";
import { GetPatientListOfCompetitionDetails } from "./Model";

const initialStateGetPosts: PatientListOfCompetitionDetailsState = {
  loading: false,
  PatientListOfCompetitionDetailsRes: {} as GetPatientListOfCompetitionDetails,
  message: "",
  status: 0,
};
export const getPatientListOfCompetitionReducer = (
  state = initialStateGetPosts,
  action: GetPatientListOfCompetitionActionTypes,
): PatientListOfCompetitionDetailsState => {
  switch (action.type) {
    case PATIENT_LIST_OF_COMPETITION_ID_LOADING:
      return {
        ...state,
        loading: true,
        PatientListOfCompetitionDetailsRes: {} as GetPatientListOfCompetitionDetails,
      };
    case PATIENT_LIST_OF_COMPETITION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionDetailsRes: action.payload,
      };
    case PATIENT_LIST_OF_COMPETITION_ID_FAIL:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionDetailsRes: {} as GetPatientListOfCompetitionDetails,
        message: action.message,
        status: action.status,
      };
    case PATIENT_LIST_OF_COMPETITION_API_MSG:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionDetailsRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_LIST_OF_COMPETITION_API_RES:
      return {
        ...state,
        loading: false,
        PatientListOfCompetitionDetailsRes: {} as GetPatientListOfCompetitionDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
