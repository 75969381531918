import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  GetHealthcampRegistrationListActionTypes } from "./ActionTypes";
import { GetHealthcampRequestListBody, GetHealthcampRequestListRes } from "./Model";
import {
   getHealthcampRegistrationListAPIResClearAction,
   getHealthcampRegistrationListErrorAction,
   getHealthcampRegistrationListLoadingAction,
   getHealthcampRegistrationListSuccessAction,
   getHealthcampRegistrationListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as GetHealthcampRequestListRes;
export const  getHealthCampRegistrationRequestList = (payload: GetHealthcampRequestListBody) => {
  return function (dispatch: Dispatch< GetHealthcampRegistrationListActionTypes>) {
    dispatch( getHealthcampRegistrationListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampRegistrationRequest/registration_request_list_healthcamp`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( getHealthcampRegistrationListLoadingAction(false));
        dispatch(
           getHealthcampRegistrationListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetHealthcampRequestListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Registration list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch( getHealthcampRegistrationListLoadingAction(false));
        dispatch(
           getHealthcampRegistrationListErrorAction(
            {} as GetHealthcampRequestListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateHealthCampRegistrationRequestAPIResMsg = () => {
  return function (dispatch: Dispatch< GetHealthcampRegistrationListActionTypes>) {
    dispatch(
       getHealthcampRegistrationListUpdateAPIMsgAction(apiRes as GetHealthcampRequestListRes, "", "", 0),
    );
  };
};

export const clearHealthCampRegistrationRequestAPIRes = () => {
  return function (dispatch: Dispatch< GetHealthcampRegistrationListActionTypes>) {
    dispatch( getHealthcampRegistrationListAPIResClearAction({} as GetHealthcampRequestListRes, "", "", 0));
  };
};
