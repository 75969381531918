import {
  DeleteOpinionActionTypes,
  DELETE_OPINION_LIST,
  OpinionActionTypes,
  OPINION_LIST,
} from "./ActionTypes";
import { DeleteResponse, OpinionList } from "./Model";

export const getOpinionAction = (steps: OpinionList[]): OpinionActionTypes => {
  return {
    type: OPINION_LIST,
    payload: steps,
  };
};

export const deleteOpinionAction = (steps: DeleteResponse): DeleteOpinionActionTypes => {
  return {
    type: DELETE_OPINION_LIST,
    payload: steps,
  };
};
