import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPatientDetailsListByDuplicateIdActionTypes } from "./ActionTypes";
import { GetPatientDetailsListByDuplicateIdBody, GetPatientDetailsListByDuplicateIdRes } from "./Model";
import {
  getPatientDetailsListByDuplicateIdAPIResClearAction,
  getPatientDetailsListByDuplicateIdErrorAction,
  getPatientDetailsListByDuplicateIdLoadingAction,
  getPatientDetailsListByDuplicateIdSuccessAction,
  getPatientDetailsListByDuplicateIdUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetPatientDetailsListByDuplicateIdRes;
export const getPatientDetailsListByDuplicateIdApi = (
  payload: GetPatientDetailsListByDuplicateIdBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetPatientDetailsListByDuplicateIdActionTypes>) {
    dispatch(getPatientDetailsListByDuplicateIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientDetails/get_patientDetailsList_from_dashboard?page=${Number(
            pageNo,
          )}&size=50`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDetailsListByDuplicateIdLoadingAction(false));
        dispatch(
          getPatientDetailsListByDuplicateIdSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientDetailsListByDuplicateIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientDetailsListByDuplicateIdLoadingAction(false));
        dispatch(
          getPatientDetailsListByDuplicateIdErrorAction(
            {} as GetPatientDetailsListByDuplicateIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientDetailsListByDuplicateIdAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsListByDuplicateIdActionTypes>) {
    dispatch(
      getPatientDetailsListByDuplicateIdUpdateAPIMsgAction(
        apiRes as GetPatientDetailsListByDuplicateIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetPatientDetailsListByDuplicateIdAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsListByDuplicateIdActionTypes>) {
    dispatch(
      getPatientDetailsListByDuplicateIdAPIResClearAction({} as GetPatientDetailsListByDuplicateIdRes, "", "", 0),
    );
  };
};
