import {
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG,
    ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES,
    AddPatientDetailsTypeToSessionListActionTypes,
  } from "./ActionTypes";
  import { AddPatientDetailsTypeToSessionRes } from "./Model";
  
  export const addPatientDetailsTypeToSessionLoadingAction = (
    loading: boolean,
  ): AddPatientDetailsTypeToSessionListActionTypes => {
    return {
      type: ADD_PATIENT_DETAILS_TYPE_TO_SESSION_LOADING,
      loading: loading,
    };
  };
  
  export const addPatientDetailsTypeToSessionSuccessAction = (
    addPatientDetailsTypeToSessionResponse: AddPatientDetailsTypeToSessionRes,
    successMsg: string,
  ): AddPatientDetailsTypeToSessionListActionTypes => {
    return {
      type: ADD_PATIENT_DETAILS_TYPE_TO_SESSION_SUCCESS,
      payload: addPatientDetailsTypeToSessionResponse,
      successMsg: successMsg,
    };
  };
  
  export const addPatientDetailsTypeToSessionFailAction = (
    addPatientDetailsTypeToSessionResponse: AddPatientDetailsTypeToSessionRes,
    errMsg: string,
    status: number,
  ): AddPatientDetailsTypeToSessionListActionTypes => {
    return {
      type: ADD_PATIENT_DETAILS_TYPE_TO_SESSION_FAIL,
      payload: addPatientDetailsTypeToSessionResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const addPatientDetailsTypeToSessionAPIMsgAction = (
    addPatientDetailsTypeToSessionResponse: AddPatientDetailsTypeToSessionRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): AddPatientDetailsTypeToSessionListActionTypes => {
    return {
      type: ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_MSG,
      payload: addPatientDetailsTypeToSessionResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const addPatientDetailsTypeToSessionAPIResClearAction = (
    addPatientDetailsTypeToSessionResponse: AddPatientDetailsTypeToSessionRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): AddPatientDetailsTypeToSessionListActionTypes => {
    return {
      type: ADD_PATIENT_DETAILS_TYPE_TO_SESSION_API_RES,
      payload: addPatientDetailsTypeToSessionResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  