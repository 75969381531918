import { SessionPatientAvailableByObservationTypeDetails } from "./Model";

// Patient Demographics Loading State
export const SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_LOADING = "SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_LOADING";
export const SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_SUCCESS = "SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_SUCCESS";
export const SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_FAIL = "SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_FAIL";
export const SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_DATA = "SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_DATA";
export const SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_MSG = "SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_MSG";
export const SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_RES = "SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_RES";

export interface SessionPatientAvailableByObservationTypeDetailsState {
  sessionPatientAvailableByObservationTypeRes: SessionPatientAvailableByObservationTypeDetails;
  successMsg: string;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface SessionPatientAvailableByObservationTypeLoading {
  type: typeof SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface SessionPatientAvailableByObservationTypeSuccess {
  type: typeof SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_SUCCESS;
  payload: SessionPatientAvailableByObservationTypeDetails;
  successMsg: string;
}
export interface SessionPatientAvailableByObservationTypeFail {
  type: typeof SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_FAIL;
  payload: SessionPatientAvailableByObservationTypeDetails;
  errorMsg: string;
  status: number;
}
export interface SessionPatientAvailableByObservationTypeAPIMsg {
  type: typeof SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_MSG;
  payload: SessionPatientAvailableByObservationTypeDetails;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface HealthcampPatientSearchObservationTypeRes {
  type: typeof SESSION_PATIENT_AVAILABLE_BY_OBSERVATION_TYPE_API_RES;
  payload: SessionPatientAvailableByObservationTypeDetails;
  errorMsg: string;
  status: number;
  successMsg: string;
}

export type SessionPatientAvailableByObservationTypeActionTypes =
  | SessionPatientAvailableByObservationTypeLoading
  | SessionPatientAvailableByObservationTypeSuccess
  | SessionPatientAvailableByObservationTypeFail
  | SessionPatientAvailableByObservationTypeAPIMsg
  | HealthcampPatientSearchObservationTypeRes;
