import { GetHealthcampStaffActiveListRes } from "./Model";
export const HEALTHCAMP_STAFF_ACTIVE_LIST_LOADING = "HEALTHCAMP_STAFF_ACTIVE_LIST_LOADING";
export const HEALTHCAMP_STAFF_ACTIVE_LIST_SUCCESS = "HEALTHCAMP_STAFF_ACTIVE_LIST_SUCCESS";
export const HEALTHCAMP_STAFF_ACTIVE_LIST_FAIL = "HEALTHCAMP_STAFF_ACTIVE_LIST_FAIL";
export const HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_MSG = "HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_MSG";
export const HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_RES = "HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_RES";

export interface GetHealthcampStaffActiveListState {
  getHealthcampStaffActiveList: GetHealthcampStaffActiveListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthcampStaffActiveListLoading {
  type: typeof HEALTHCAMP_STAFF_ACTIVE_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthcampStaffActiveListSuccess {
  type: typeof HEALTHCAMP_STAFF_ACTIVE_LIST_SUCCESS;
  payload: GetHealthcampStaffActiveListRes;
  successMsg: string;
  status: number;
}
export interface GetHealthcampStaffActiveListFail {
  type: typeof HEALTHCAMP_STAFF_ACTIVE_LIST_FAIL;
  payload: GetHealthcampStaffActiveListRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthcampStaffActiveListUpdateAPIMsg {
  type: typeof HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_MSG;
  payload: GetHealthcampStaffActiveListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthcampStaffActiveListUpdateAPIRes {
  type: typeof HEALTHCAMP_STAFF_ACTIVE_LIST_UPDATE_API_RES;
  payload: GetHealthcampStaffActiveListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthcampStaffActiveListActionTypes =
  | GetHealthcampStaffActiveListLoading
  | GetHealthcampStaffActiveListSuccess
  | GetHealthcampStaffActiveListFail
  | GetHealthcampStaffActiveListUpdateAPIMsg
  | GetHealthcampStaffActiveListUpdateAPIRes;
