import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPatientCaseNoteListActionTypes } from "./ActionTypes";
import { GetPatientCaseNoteListBody, GetPatientCaseNoteListRes } from "./Model";
import {
  getPatientCaseNoteListAPIResClearAction,
  getPatientCaseNoteListErrorAction,
  getPatientCaseNoteListLoadingAction,
  getPatientCaseNoteListSuccessAction,
  getPatientCaseNoteListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetPatientCaseNoteListRes;
export const getPatientCaseNoteList = (payload: GetPatientCaseNoteListBody) => {
  return function (dispatch: Dispatch<GetPatientCaseNoteListActionTypes>) {
    dispatch(getPatientCaseNoteListLoadingAction(true));
    axios
      .post(Url.baseUrl + `/erpapp/api/adminNote/get_admin_notes_by_patient`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientCaseNoteListLoadingAction(false));
        dispatch(getPatientCaseNoteListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getPatientCaseNoteListLoadingAction(false));
        dispatch(
          getPatientCaseNoteListErrorAction(
            {} as GetPatientCaseNoteListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateAdminNoteListByPatientEditAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientCaseNoteListActionTypes>) {
    dispatch(getPatientCaseNoteListUpdateAPIMsgAction(apiRes as GetPatientCaseNoteListRes, "", 0));
  };
};

export const clearAdminNoteListByPatientEditAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientCaseNoteListActionTypes>) {
    dispatch(getPatientCaseNoteListAPIResClearAction({} as GetPatientCaseNoteListRes, "", 0));
  };
};
