import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { SessionListByDuplicatePatientIdActionTypes } from "./ActionTypes";
import { SessionListByDuplicatePatientIdBody, SessionListByDuplicatePatientIdDetails } from "./Model";
import {
  SessionListByDuplicatePatientIdAPIResClearAction,
  SessionListByDuplicatePatientIdErrorAction,
  SessionListByDuplicatePatientIdLoadingAction,
  SessionListByDuplicatePatientIdSuccessAction,
  SessionListByDuplicatePatientIdAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as SessionListByDuplicatePatientIdDetails;
export const sessionListByDuplicatePatientIdApi = (patientid: SessionListByDuplicatePatientIdBody, pageNo: any) => {
  return function (dispatch: Dispatch<SessionListByDuplicatePatientIdActionTypes>) {
    dispatch(SessionListByDuplicatePatientIdLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampsession/session_list_by_patient?page=${Number(
            pageNo,
          )}&size=50`,
        patientid,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(SessionListByDuplicatePatientIdLoadingAction(false));
        dispatch(
          SessionListByDuplicatePatientIdSuccessAction(
            res.data !== undefined ? res.data : ({} as SessionListByDuplicatePatientIdDetails),
            res.data.message !== undefined
              ? res.data.message
              : "Duplicate Patient session list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(SessionListByDuplicatePatientIdLoadingAction(false));
        dispatch(
          SessionListByDuplicatePatientIdErrorAction(
            {} as SessionListByDuplicatePatientIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSessionListByDuplicatePatientIdAPIResMsg = () => {
  return function (dispatch: Dispatch<SessionListByDuplicatePatientIdActionTypes>) {
    dispatch(
      SessionListByDuplicatePatientIdAPIMsgAction(apiRes as SessionListByDuplicatePatientIdDetails, "", "", 0),
    );
  };
};

export const clearSessionListByDuplicatePatientIdAPIRes = () => {
  return function (dispatch: Dispatch<SessionListByDuplicatePatientIdActionTypes>) {
    dispatch(
      SessionListByDuplicatePatientIdAPIResClearAction({} as SessionListByDuplicatePatientIdDetails, "", "", 0),
    );
  };
};
