import {
  GET_DATA_CURATION_PROBLEM_LIST_FAIL,
  GET_DATA_CURATION_PROBLEM_LIST_LOADING,
  GET_DATA_CURATION_PROBLEM_LIST_SUCCESS,
  GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_MSG,
  GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_RES,
  GetDataCurationProblemListActionTypes,
} from "./ActionTypes";
import { GetDataCurationProblemListRes } from "./Model";

export const getDataCurationProblemListLoadingAction = (
  loading: boolean,
): GetDataCurationProblemListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_LIST_LOADING,
    loading: loading,
  };
};

export const getDataCurationProblemListSuccessAction = (
  getDataCurationProblemListResponse: GetDataCurationProblemListRes,
  successMsg: string,
): GetDataCurationProblemListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_LIST_SUCCESS,
    payload: getDataCurationProblemListResponse,
    successMsg: successMsg,
  };
};

export const getDataCurationProblemListErrorAction = (
  getDataCurationProblemListResponse: GetDataCurationProblemListRes,
  errMsg: string,
  status: number,
): GetDataCurationProblemListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_LIST_FAIL,
    payload: getDataCurationProblemListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDataCurationProblemListUpdateAPIMsgAction = (
  getDataCurationProblemListResponse: GetDataCurationProblemListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDataCurationProblemListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_MSG,
    payload: getDataCurationProblemListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDataCurationProblemListAPIResClearAction = (
  getDataCurationProblemListResponse: GetDataCurationProblemListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDataCurationProblemListActionTypes => {
  return {
    type: GET_DATA_CURATION_PROBLEM_LIST_UPDATE_API_RES,
    payload: getDataCurationProblemListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
