import {
  INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
  INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
  INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
  INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
  INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
  InstanceListByDuplicatePatientIdActionTypes,
} from "./ActionTypes";
import { InstanceListByDuplicatePatientIdDetails } from "./Model";

export const InstanceListByDuplicatePatientIdLoadingAction = (
  loading: boolean,
): InstanceListByDuplicatePatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_LOADING,
    loading: loading,
  };
};

export const InstanceListByDuplicatePatientIdSuccessAction = (
  instanceListByDuplicatePatientIdResponse: InstanceListByDuplicatePatientIdDetails,
  successMsg: string,
): InstanceListByDuplicatePatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_SUCCESS,
    payload: instanceListByDuplicatePatientIdResponse,
    successMsg: successMsg,
  };
};

export const InstanceListByDuplicatePatientIdErrorAction = (
  instanceListByDuplicatePatientIdResponse: InstanceListByDuplicatePatientIdDetails,
  errMsg: string,
  status: number,
): InstanceListByDuplicatePatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_FAIL,
    payload: instanceListByDuplicatePatientIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const InstanceListByDuplicatePatientIdAPIMsgAction = (
  instanceListByDuplicatePatientIdResponse: InstanceListByDuplicatePatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): InstanceListByDuplicatePatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_MSG,
    payload: instanceListByDuplicatePatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const InstanceListByDuplicatePatientIdAPIResClearAction = (
  instanceListByDuplicatePatientIdResponse: InstanceListByDuplicatePatientIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): InstanceListByDuplicatePatientIdActionTypes => {
  return {
    type: INSTANCE_LIST_BY_DUPLICATE_PATIENT_ID_API_RES,
    payload: instanceListByDuplicatePatientIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
