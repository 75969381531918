import { GetOrganizationListRes } from "./Model";
export const GET_ORGANIZATION_LIST_LOADING = "GET_ORGANIZATION_LIST_LOADING";
export const GET_ORGANIZATION_LIST_SUCCESS = "GET_ORGANIZATION_LIST_SUCCESS";
export const GET_ORGANIZATION_LIST_FAIL = "GET_ORGANIZATION_LIST_FAIL";
export const GET_EXTRACT_OBSERVATION_DATA = "GET_EXTRACT_OBSERVATION_DATA";
export const GET_ORGANIZATION_LIST_API_MSG = "GET_ORGANIZATION_LIST_API_MSG";
export const GET_ORGANIZATION_LIST_API_RES = "GET_ORGANIZATION_LIST_API_RES";

export interface GetExtractObservationState {
  getOrganizationRes: GetOrganizationListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetExtractObservationLoading {
  type: typeof GET_ORGANIZATION_LIST_LOADING;
  loading: boolean;
}
export interface GetExtractObservationSuccess {
  type: typeof GET_ORGANIZATION_LIST_SUCCESS;
  payload: GetOrganizationListRes;
}
export interface GetExtractObservationFail {
  type: typeof GET_ORGANIZATION_LIST_FAIL;
  payload: GetOrganizationListRes;
  errorMsg: string;
  status: number;
}
export interface GetExtractObservationAPIMsg {
  type: typeof GET_ORGANIZATION_LIST_API_MSG;
  payload: GetOrganizationListRes;
  errorMsg: string;
  status: number;
}
export interface GetExtractObservationAPIRes {
  type: typeof GET_ORGANIZATION_LIST_API_RES;
  payload: GetOrganizationListRes;
  errorMsg: string;
  status: number;
}

export type GetOriganizationListActionTypes =
  | GetExtractObservationLoading
  | GetExtractObservationSuccess
  | GetExtractObservationFail
  | GetExtractObservationAPIMsg
  | GetExtractObservationAPIRes;
