import {
  
  GET_ACTIVE_LIST_BY_ORG_ID_FAIL,
  GET_ACTIVE_LIST_BY_ORG_ID_LOADING,
  GET_ACTIVE_LIST_BY_ORG_ID_SUCCESS,
  GET_ACTIVE_LIST_BY_ORG_ID_API_MSG,
  GET_ACTIVE_LIST_BY_ORG_ID_API_RES,
  GetActiveListByOrgIdActionTypes,
} from "./ActionTypes";
import { GetActiveListByOrgIdDetails } from "./Model";

export const GetActiveListByOrgIdLoadingAction = (
  loading: boolean,
): GetActiveListByOrgIdActionTypes => {
  return {
    type: GET_ACTIVE_LIST_BY_ORG_ID_LOADING,
    loading: loading,
  };
};

export const GetActiveListByOrgIdSuccessAction = (
  viewOrgStaffListResponse: GetActiveListByOrgIdDetails,
  successMsg: string,
): GetActiveListByOrgIdActionTypes => {
  return {
    type: GET_ACTIVE_LIST_BY_ORG_ID_SUCCESS,
    payload: viewOrgStaffListResponse,
    successMsg: successMsg,
  };
};

export const GetActiveListByOrgIdErrorAction = (
  viewOrgStaffListResponse: GetActiveListByOrgIdDetails,
  errMsg: string,
  status: number,
): GetActiveListByOrgIdActionTypes => {
  return {
    type: GET_ACTIVE_LIST_BY_ORG_ID_FAIL,
    payload: viewOrgStaffListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const GetActiveListByOrgIdAPIMsgAction = (
  viewOrgStaffListResponse: GetActiveListByOrgIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): GetActiveListByOrgIdActionTypes => {
  return {
    type: GET_ACTIVE_LIST_BY_ORG_ID_API_MSG,
    payload: viewOrgStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const GetActiveListByOrgIdAPIResClearAction = (
  viewOrgStaffListResponse: GetActiveListByOrgIdDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): GetActiveListByOrgIdActionTypes => {
  return {
    type: GET_ACTIVE_LIST_BY_ORG_ID_API_RES,
    payload: viewOrgStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
