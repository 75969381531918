import {
  RESPONSE_TIME_TYPE_API_MSG,
  RESPONSE_TIME_TYPE_API_RES,
  RESPONSE_TIME_TYPE_DATA,
  RESPONSE_TIME_TYPE_FAIL,
  RESPONSE_TIME_TYPE_LOADING,
  RESPONSE_TIME_TYPE_SUCCESS,
  ResponseTimeTypeActionTypes,
} from "./ActionTypes";
import { ResponseTimeTypeResponse } from "./Model";

export const responseTimeTypeAction = (
  responseTimeTypeRes: ResponseTimeTypeResponse
): ResponseTimeTypeActionTypes => {
  return {
    type: RESPONSE_TIME_TYPE_DATA,
    payload: responseTimeTypeRes,
  };
};

export const responseTimeTypeLoadingAction = (
  loading: boolean
): ResponseTimeTypeActionTypes => {
  return {
    type: RESPONSE_TIME_TYPE_LOADING,
    loading: loading,
  };
};

export const responseTimeTypeSuccessAction = (
  responseTimeTypeRes: ResponseTimeTypeResponse,
  successMsg: string,
  status: number
): ResponseTimeTypeActionTypes => {
  return {
    type: RESPONSE_TIME_TYPE_SUCCESS,
    payload: responseTimeTypeRes,
    successMsg: successMsg,
    status: status,
  };
};

export const responseTimeTypeErrorAction = (
  responseTimeTypeRes: ResponseTimeTypeResponse,
  errMsg: string,
  status: number
): ResponseTimeTypeActionTypes => {
  return {
    type: RESPONSE_TIME_TYPE_FAIL,
    payload: responseTimeTypeRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const responseTimeTypeUpdateAPIMsgAction = (
  responseTimeTypeRes: ResponseTimeTypeResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ResponseTimeTypeActionTypes => {
  return {
    type: RESPONSE_TIME_TYPE_API_MSG,
    payload: responseTimeTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const responseTimeTypeAPIResClearAction = (
  responseTimeTypeRes: ResponseTimeTypeResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ResponseTimeTypeActionTypes => {
  return {
    type: RESPONSE_TIME_TYPE_API_RES,
    payload: responseTimeTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
