import { ExecutiveListRes } from "./Model";
export const EXECUTIVE_LIST_LOADING = "EXECUTIVE_LIST_LOADING";
export const EXECUTIVE_LIST_SUCCESS = "EXECUTIVE_LIST_SUCCESS";
export const EXECUTIVE_LIST_FAIL = "EXECUTIVE_LIST_FAIL";
export const EXECUTIVE_LIST_UPDATE_API_MSG = "EXECUTIVE_LIST_UPDATE_API_MSG";
export const EXECUTIVE_LIST_UPDATE_API_RES = "EXECUTIVE_LIST_UPDATE_API_RES";

export interface ExecutiveListState {
  executiveListRes: ExecutiveListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ExecutiveListLoading {
  type: typeof EXECUTIVE_LIST_LOADING;
  loading: boolean;
}
export interface ExecutiveListSuccess {
  type: typeof EXECUTIVE_LIST_SUCCESS;
  payload: ExecutiveListRes;
  successMsg: string;
}
export interface ExecutiveListFail {
  type: typeof EXECUTIVE_LIST_FAIL;
  payload: ExecutiveListRes;
  errorMsg: string;
  status: number;
}
export interface ExecutiveListUpdateAPIMsg {
  type: typeof EXECUTIVE_LIST_UPDATE_API_MSG;
  payload: ExecutiveListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ExecutiveListUpdateAPIRes {
  type: typeof EXECUTIVE_LIST_UPDATE_API_RES;
  payload: ExecutiveListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ExecutiveListActionTypes =
  | ExecutiveListLoading
  | ExecutiveListSuccess
  | ExecutiveListFail
  | ExecutiveListUpdateAPIMsg
  | ExecutiveListUpdateAPIRes;
