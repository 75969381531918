import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import ActiveList from "./ActiveList";
import DeactiveList from "./DeactiveList";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AdminList from "./AdminList";
import { GetHealthcampAdminListBody } from "redux/HealthcampStaffList/AdminListByHealthcampId/Model";
import { getHealthcampAdminListByHealthCampId } from "redux/HealthcampStaffList/AdminListByHealthcampId/API";
import { GetHealthcampStaffInactiveListBody } from "redux/HealthcampStaffList/InactiveStaffList/Model";
import { getInactiveListByHealthCampId } from "redux/HealthcampStaffList/InactiveStaffList/API";
import { GetHealthcampStaffActiveListBody } from "redux/HealthcampStaffList/Model";
import { getGetHealthcampStaffActiveList } from "redux/HealthcampStaffList/API";

type Props = {
  healthCampStaffListLoader?: boolean | any;
};

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function HealthCampStaffList({ healthCampStaffListLoader }: Props) {
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  let query = UseQuery();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tabId = query.get("section");
  const history = useHistory();
  const [listCount, setListCount] = React.useState("");
  const [inactiveListCount, setInactiveListCount] = React.useState("");
  // const [tabValue, setTabValue] = React.useState(0);
  const [selected, setSelected] = React.useState("0");
  const [adminListCount, setAdminListCount] = React.useState("");
  const changeAdminCount = (value: string) => {
    setAdminListCount(value);
  };
  const changeInactiveCount = (value: string) => {
    setInactiveListCount(value);
  };
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === "0") {
      history.push(`${window.location.pathname + "?section=admin-list"}`);
    } else if (newValue === "1") {
      history.push(`${window.location.pathname + "?section=active-list"}`);
    } else if (newValue === "2") {
      history.push(`${window.location.pathname + "?section=inactive-list"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "admin-list") {
      setSelected("0");
    } else if (tabId === "active-list") {
      setSelected("1");
    } else if (tabId === "inactive-list") {
      setSelected("2");
    }
    // eslint-disable-next-line
  }, [tabId]);
  const AdminHealthCampPatientList = useSelector(
    (state: AppState) => state.getViewHealthcampStaffListValue,
  );

  useEffect(() => {
    if (AdminHealthCampPatientList?.getHealthcampStaffActiveList?.count !== undefined) {
      setListCount(String(AdminHealthCampPatientList?.getHealthcampStaffActiveList?.count));
    } else setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminHealthCampPatientList]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ width: fullScreen ? "calc(100vw - 20vw)" : "100%" }}>
          <Tabs
            value={selected}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Admin List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={adminListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    const viewHealthcampAdminListBody = {
                      healthcampId: healthcampid,
                      isActiveOrAdmin: 2,
                    } as GetHealthcampAdminListBody;
                    dispatch(getHealthcampAdminListByHealthCampId(viewHealthcampAdminListBody, 1));
                  }}
                />
              }
              iconPosition="end"
              value="0"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Active List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={listCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    const viewHealthcampStaffActiveListBody = {
                      healthcampId: healthcampid,
                      isActiveOrAdmin: 1,
                    } as GetHealthcampStaffActiveListBody;
                    dispatch(getGetHealthcampStaffActiveList(viewHealthcampStaffActiveListBody, 1));
                  }}
                />
              }
              iconPosition="end"
              value="1"
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Inactive List</Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={inactiveListCount} variant="outlined" size="small" />
                  </Grid>
                </Grid>
              }
              icon={
                <RefreshIcon
                  onClick={() => {
                    const viewHealthcampStaffInactiveListBody = {
                      healthcampId: healthcampid,
                      isActiveOrAdmin: 0,
                    } as GetHealthcampStaffInactiveListBody;
                    dispatch(getInactiveListByHealthCampId(viewHealthcampStaffInactiveListBody, 1));
                  }}
                />
              }
              iconPosition="end"
              value="2"
            />
          </Tabs>
        </Box>
        <Divider />
        {fullScreen ? (
          <Box pt={2}>
            {selected === "0" ? (
              <AdminList
                viewHealthcampStaffListLoader={healthCampStaffListLoader}
                adminListCount={changeAdminCount}
              />
            ) : selected === "1" ? (
              <ActiveList viewHealthcampStaffListLoader={healthCampStaffListLoader} />
            ) : (
              <DeactiveList
                viewHealthcampStaffListLoader={healthCampStaffListLoader}
                inactiveListCount={changeInactiveCount}
              />
            )}
          </Box>
        ) : (
          <TabContext value={String(selected)}>
            <TabPanel value="0">
              <AdminList
                viewHealthcampStaffListLoader={healthCampStaffListLoader}
                adminListCount={changeAdminCount}
              />
            </TabPanel>
            <TabPanel value="1">
              <ActiveList viewHealthcampStaffListLoader={healthCampStaffListLoader} />
            </TabPanel>
            <TabPanel value="2">
              <DeactiveList
                viewHealthcampStaffListLoader={healthCampStaffListLoader}
                inactiveListCount={changeInactiveCount}
              />
            </TabPanel>
          </TabContext>
        )}
      </CardContent>
    </Card>
  );
}
