import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  AlertColor,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { AppState } from "redux/store/Store";
import { PatientSearchBody } from "redux/PatientSearchApi/Model";
import { patientSearchApi } from "redux/PatientSearchApi/API";
import MWPageTitle from "component/MWPageTitle";
import MWExceptionList from "component/MWExceptionList";
import AdminLoader from "pages/AdminLoader";
import { AddExistingPatientByProviderOrgIdBody } from "redux/AddExistingPatientByProviderOrgId/Model";
import {
  addExistingPatientByProviderOrgIdApiCall,
  clearAddExistingPatientByProviderOrgIdAPIRes,
} from "redux/AddExistingPatientByProviderOrgId/API";
import { GetProviderOrganizationPatientListBody } from "redux/PatientListByProviderOrganizationId/Model";
import { getPatientListByProviderOrganization } from "redux/PatientListByProviderOrganizationId/API";
import MWSnackbar from "component/MWSnackbar";
import { providerOrgPatientSearchApi } from "redux/ProviderOrganizationDetails/PatientSearchApi/API";
import { PatientList, ProviderOrgPatientSearchBody } from "redux/ProviderOrganizationDetails/PatientSearchApi/Model";

type Props = {
  organizationid?: string | any;
  title?: string | any;
};

export default function SearchPatientComponent({ organizationid, title }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [urlPath, setUrlPath] = useState(history.location.pathname);
  useEffect(() => {
    setUrlPath(history.location.pathname);
  }, [history]);

  const [isLoading, setIsLoading] = useState(false);

  const addExistingPatientSubmit = (element: string) => {
    const addExistingPatientBody = {
      providerorgId: organizationid!,
      patientId: element,
    } as AddExistingPatientByProviderOrgIdBody;
    dispatch(addExistingPatientByProviderOrgIdApiCall(addExistingPatientBody));
  };

  const addPatientValueRes = useSelector(
    (state: AppState) => state.addExistingPatientByProviderOrgValues,
  );

  const [inputValue, setInputValue] = React.useState("" as any);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("0");
  const [addExistingPatientMsg, setAddExistingPatientMsg] = useState("");
  const [addExistingPatientMsgType, setAddExistingPatientMsgType] = useState<AlertColor>("success");

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const patientSearchListValueRes = useSelector((state: AppState) => state.providerOrgPatientSearchValue);
  const searchPatientList = patientSearchListValueRes?.providerOrgPatientSearchRes?.patientList;
  const patientSearchBody = {
    searchKey: inputValue,
  } as PatientSearchBody;

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(patientSearchApi(patientSearchBody, value));
  };

  useEffect(() => {
    setIsLoading(patientSearchListValueRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  useEffect(() => {
    patientSearchListValueRes?.providerOrgPatientSearchRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientSearchListValueRes?.providerOrgPatientSearchRes?.numberOfPages))
      : setPageListCount("0");
    if (patientSearchListValueRes?.providerOrgPatientSearchRes?.count !== undefined) {
      setListCount(String(patientSearchListValueRes?.providerOrgPatientSearchRes?.count));
    }
    if (searchPatientList !== undefined) {
      let dataPointArr = searchPatientList.map((element: PatientList) => ({
        id: element.ipp,
        patientId: element.ipp,
        givenName: element.prenom,
        familyName: element.nom,
        pincode: element.cp,
        //createDate: dayjs(element.createDate).format("ddd, MMM D, YYYY"),
        dob: dayjs(element.ddn).format("ddd, MMM D, YYYY"),
        phone: element.tel,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(patientSearchListValueRes?.providerOrgPatientSearchRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchListValueRes]);

  const columns: GridColDef[] = [
    {
      field: "ptDetails",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "ptDocDetails",
      headerName: "View Pt. Doc.",
      width: 100,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View Document
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      width: 60,
      align: "left",
      flex: 1,
    },
    {
      field: "givenName",
      headerName: "First Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "familyName",
      headerName: "Last Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pin Code",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 50,
      align: "left",
      flex: 1,
    },
    {
      field: "patientId",
      headerName: "Action",
      width: 50,
      align: "left",
      flex: 1,
      hide: urlPath !== "/searchpatient" ? false : true,
      renderCell: (params: GridCellParams) => (
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => {
            addExistingPatientSubmit(params.row.patientId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add Existing Patient
          </span>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (addPatientValueRes?.successMsg !== "") {
      setAddExistingPatientMsgType("success");
      setAddExistingPatientMsg(addPatientValueRes?.successMsg);
      dispatch(
        getPatientListByProviderOrganization(
          { providerorgId: organizationid } as GetProviderOrganizationPatientListBody,
          page,
        ),
      );
    }
    if (addPatientValueRes?.errorMsg !== "") {
      setAddExistingPatientMsgType("error");
      setAddExistingPatientMsg(addPatientValueRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddExistingPatientByProviderOrgIdAPIRes());
      setAddExistingPatientMsg("");
    }
  };

  const PatientSearchListData = (
    <Card sx={{ height: "85vh" }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <MWPageTitle title="Patient Search List" enableCount={true} count={listCount} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                pt={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {searchPatientList && searchPatientList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const pageBody = (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <MWPageTitle
            backButton={true}
            title={urlPath !== "/searchpatient" ? title : "Search Patient"}
          />
        ) : null}
        <TextField
          label="Patient Search In MedicalWISDOM Platform"
          value={inputValue}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              dispatch(
                providerOrgPatientSearchApi(
                  { searchKey: inputValue, pageNo: 1 } as ProviderOrgPatientSearchBody,
                ),
              );
              ev.preventDefault();
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
          placeholder="Please enter value"
          id="searchValue"
          fullWidth
          size="small"
        />
        <Box>{PatientSearchListData}</Box>
      </Stack>
    </Box>
  );
  return (
    <Box>
      {isLoading && urlPath === "/searchpatient" ? <AdminLoader /> : null}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {urlPath === "/searchpatient" ? (
          <Container maxWidth="xl">{pageBody}</Container>
        ) : (
          <Box>{pageBody}</Box>
        )}
      </Stack>
      {addExistingPatientMsg !== "" ? (
        <MWSnackbar
          msg={addExistingPatientMsg}
          type={addExistingPatientMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
