import { AppointmentListResponse } from "./Model";
export const APPOINTMENT_LIST_LOADING = "APPOINTMENT_LIST_LOADING";
export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS";
export const APPOINTMENT_LIST_FAIL = "APPOINTMENT_LIST_FAIL";
export const APPOINTMENT_LIST_DATA = "APPOINTMENT_LIST_DATA";
export const APPOINTMENT_LIST_UPDATE_API_MSG = "APPOINTMENT_LIST_UPDATE_API_MSG";
export const APPOINTMENT_LIST_UPDATE_API_RES = "APPOINTMENT_LIST_UPDATE_API_RES";

export interface AppointmentListState {
  appointmentListRes: AppointmentListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AppointmentListLoading {
  type: typeof APPOINTMENT_LIST_LOADING;
  loading: boolean;
}
export interface AppointmentListSuccess {
  type: typeof APPOINTMENT_LIST_SUCCESS;
  payload: AppointmentListResponse;
  successMsg: string;
  status: number;
}
export interface AppointmentListFail {
  type: typeof APPOINTMENT_LIST_FAIL;
  payload: AppointmentListResponse;
  errorMsg: string;
  status: number;
}
export interface AppointmentListUpdateAPIMsg {
  type: typeof APPOINTMENT_LIST_UPDATE_API_MSG;
  payload: AppointmentListResponse;
  errorMsg: string;
  status: number;
}
export interface AppointmentListUpdateAPIRes {
  type: typeof APPOINTMENT_LIST_UPDATE_API_RES;
  payload: AppointmentListResponse;
  errorMsg: string;
  status: number;
}
interface AppointmentListAction {
  type: typeof APPOINTMENT_LIST_DATA;
  payload: AppointmentListResponse;
}

export type AppointmentListActionTypes =
  | AppointmentListAction
  | AppointmentListLoading
  | AppointmentListSuccess
  | AppointmentListFail
  | AppointmentListUpdateAPIMsg
  | AppointmentListUpdateAPIRes;
