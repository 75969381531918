import { GetHealthCampSessionReportRes } from "./Model";
export const HEALTH_CAMP_SESSION_REPORT_LIST_LOADING = "HEALTH_CAMP_SESSION_REPORT_LIST_LOADING";
export const HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS = "HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS";
export const HEALTH_CAMP_SESSION_REPORT_LIST_FAIL = "HEALTH_CAMP_SESSION_REPORT_LIST_FAIL";
export const HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG =
  "HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG";
export const HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES =
  "HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES";

export interface GetHealthCampSessionReportState {
  getHealthCampSessionReport: GetHealthCampSessionReportRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampSessionReportLoading {
  type: typeof HEALTH_CAMP_SESSION_REPORT_LIST_LOADING;
  loading: boolean;
}
export interface GetHealthCampSessionReportSuccess {
  type: typeof HEALTH_CAMP_SESSION_REPORT_LIST_SUCCESS;
  payload: GetHealthCampSessionReportRes;
  successMsg: string;
}
export interface GetHealthCampSessionReportFail {
  type: typeof HEALTH_CAMP_SESSION_REPORT_LIST_FAIL;
  payload: GetHealthCampSessionReportRes;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampSessionReportUpdateAPIMsg {
  type: typeof HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_MSG;
  payload: GetHealthCampSessionReportRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetHealthCampSessionReportUpdateAPIRes {
  type: typeof HEALTH_CAMP_SESSION_REPORT_LIST_UPDATE_API_RES;
  payload: GetHealthCampSessionReportRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetHealthCampSessionReportActionTypes =
  | GetHealthCampSessionReportLoading
  | GetHealthCampSessionReportSuccess
  | GetHealthCampSessionReportFail
  | GetHealthCampSessionReportUpdateAPIMsg
  | GetHealthCampSessionReportUpdateAPIRes;
