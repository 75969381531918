import { GetSessionListRes } from "./Model";
export const SESSION_LIST_LOADING = "SESSION_LIST_LOADING";
export const SESSION_LIST_SUCCESS = "SESSION_LIST_SUCCESS";
export const SESSION_LIST_FAIL = "SESSION_LIST_FAIL";
export const SESSION_LIST_UPDATE_API_MSG = "SESSION_LIST_UPDATE_API_MSG";
export const SESSION_LIST_UPDATE_API_RES = "SESSION_LIST_UPDATE_API_RES";

export interface GetSessionListState {
  getSessionList: GetSessionListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSessionListLoading {
  type: typeof SESSION_LIST_LOADING;
  loading: boolean;
}
export interface GetSessionListSuccess {
  type: typeof SESSION_LIST_SUCCESS;
  payload: GetSessionListRes;
  successMsg: string;
}
export interface GetSessionListFail {
  type: typeof SESSION_LIST_FAIL;
  payload: GetSessionListRes;
  errorMsg: string;
  status: number;
}
export interface GetSessionListUpdateAPIMsg {
  type: typeof SESSION_LIST_UPDATE_API_MSG;
  payload: GetSessionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSessionListUpdateAPIRes {
  type: typeof SESSION_LIST_UPDATE_API_RES;
  payload: GetSessionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetSessionListActionTypes =
  | GetSessionListLoading
  | GetSessionListSuccess
  | GetSessionListFail
  | GetSessionListUpdateAPIMsg
  | GetSessionListUpdateAPIRes;
