import {
  FILTER_PATIENT_DETAILS_BY_OBSERVATION_FAIL,
  FILTER_PATIENT_DETAILS_BY_OBSERVATION_LOADING,
  FILTER_PATIENT_DETAILS_BY_OBSERVATION_SUCCESS,
  FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_MSG,
  FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_RES,
  FilterPatientDetailsByObservationActionTypes,
} from "./ActionTypes";
import { FilterPatientDetailsByObservationListRes } from "./Model";

export const FilterPatientDetailsByObservationLoadingAction = (
  loading: boolean,
): FilterPatientDetailsByObservationActionTypes => {
  return {
    type: FILTER_PATIENT_DETAILS_BY_OBSERVATION_LOADING,
    loading: loading,
  };
};

export const FilterPatientDetailsByObservationSuccessAction = (
  filterPatientDetailsObservationRes: FilterPatientDetailsByObservationListRes,
  successMsg: string,
): FilterPatientDetailsByObservationActionTypes => {
  return {
    type: FILTER_PATIENT_DETAILS_BY_OBSERVATION_SUCCESS,
    payload: filterPatientDetailsObservationRes,
    successMsg: successMsg,
  };
};

export const FilterPatientDetailsByObservationErrorAction = (
  filterPatientDetailsObservationRes: FilterPatientDetailsByObservationListRes,
  errMsg: string,
  status: number,
): FilterPatientDetailsByObservationActionTypes => {
  return {
    type: FILTER_PATIENT_DETAILS_BY_OBSERVATION_FAIL,
    payload: filterPatientDetailsObservationRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const FilterPatientDetailsByObservationAPIMsgAction = (
  filterPatientDetailsObservationRes: FilterPatientDetailsByObservationListRes,
  successMsg: string,
  errorMsg: string,
  status: number,
): FilterPatientDetailsByObservationActionTypes => {
  return {
    type: FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_MSG,
    payload: filterPatientDetailsObservationRes,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const FilterPatientDetailsByObservationAPIResClearAction = (
  filterPatientDetailsObservationRes: FilterPatientDetailsByObservationListRes,
  successMsg: string,
  errorMsg: string,
  status: number,
): FilterPatientDetailsByObservationActionTypes => {
  return {
    type: FILTER_PATIENT_DETAILS_BY_OBSERVATION_API_RES,
    payload: filterPatientDetailsObservationRes,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
