import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddPatientDetailsTypeToSessionListActionTypes } from "./ActionTypes";
import { AddPatientDetailsTypeToSessionBody, AddPatientDetailsTypeToSessionRes } from "./Model";
import {
  addPatientDetailsTypeToSessionAPIResClearAction,
  addPatientDetailsTypeToSessionFailAction,
  addPatientDetailsTypeToSessionLoadingAction,
  addPatientDetailsTypeToSessionSuccessAction,
  addPatientDetailsTypeToSessionAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";
import { setupInterceptorsTo } from "redux/Authentication/Interceptors";

let apiRes = {} as AddPatientDetailsTypeToSessionRes;
export const addPatientDetailsTypeToSession = (payload: AddPatientDetailsTypeToSessionBody) => {
  return function (dispatch: Dispatch<AddPatientDetailsTypeToSessionListActionTypes>) {
    dispatch(addPatientDetailsTypeToSessionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/healthcampapp/api/healthCampSessionPatientDetailsType/add_patient_detailsType_to_session`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientDetailsTypeToSessionLoadingAction(false));
        dispatch(
          addPatientDetailsTypeToSessionSuccessAction(
            res.data !== undefined ? res.data : ({} as AddPatientDetailsTypeToSessionRes),
            res.data.message !== undefined
              ? res.data.message
              : "patientDetailsType Mapped with HealthCampSession",
          ),
        );
      })
      .catch((error) => {
        dispatch(addPatientDetailsTypeToSessionLoadingAction(false));
        dispatch(
          addPatientDetailsTypeToSessionFailAction(
            {} as AddPatientDetailsTypeToSessionRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAddPatientDetailsTypeToSessionListAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientDetailsTypeToSessionListActionTypes>) {
    dispatch(addPatientDetailsTypeToSessionAPIMsgAction(apiRes as AddPatientDetailsTypeToSessionRes, "", "", 0));
  };
};

export const clearAddPatientDetailsTypeToSessionListAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientDetailsTypeToSessionListActionTypes>) {
    dispatch(addPatientDetailsTypeToSessionAPIResClearAction({} as AddPatientDetailsTypeToSessionRes, "", "", 0));
  };
};
