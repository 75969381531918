import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ActivatePatientActionTypes } from "./ActionTypes";
import { ActivatePatientBody, ActivatePatientRes } from "./Model";
import {
  activatePatientAPIResClearAction,
  activatePatientErrorAction,
  activatePatientLoadingAction,
  activatePatientSuccessAction,
  activatePatientUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as ActivatePatientRes;
export const activatePatient = (payload: ActivatePatientBody) => {
  return function (dispatch: Dispatch<ActivatePatientActionTypes>) {
    dispatch(activatePatientLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/adminActions/change_patient_status`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(activatePatientLoadingAction(false));
        dispatch(
          activatePatientSuccessAction(
            res.data,
            res?.data?.message !== undefined
              ? res?.data?.message !== ""
                ? res.data.message
                : "Patient has been activated successfully"
              : "Patient has been activated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(activatePatientLoadingAction(false));
        dispatch(
          activatePatientErrorAction(
            {} as ActivatePatientRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateActivatePatientAPIResMsg = () => {
  return function (dispatch: Dispatch<ActivatePatientActionTypes>) {
    dispatch(activatePatientUpdateAPIMsgAction(apiRes as ActivatePatientRes, "", "", 0));
  };
};

export const clearActivatePatientAPIRes = () => {
  return function (dispatch: Dispatch<ActivatePatientActionTypes>) {
    dispatch(activatePatientAPIResClearAction({} as ActivatePatientRes, "", "", 0));
  };
};
