import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ExtractObservationDetailsActionTypes } from "./ActionTypes";
import { ExtractObservationDetailsBody, ExtractObservationDetailsRes } from "./Model";
import {
  extractObservationDetailsAPIResClearAction,
  extractObservationDetailsErrorAction,
  extractObservationDetailsLoadingAction,
  extractObservationDetailsSuccessAction,
  extractObservationDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as ExtractObservationDetailsRes;
export const extractObservationDetailsById = (payload: ExtractObservationDetailsBody) => {
  return function (dispatch: Dispatch<ExtractObservationDetailsActionTypes>) {
    dispatch(extractObservationDetailsLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/adminapp/api/patientDetails/extractObservationsFromPatientDetails`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(extractObservationDetailsLoadingAction(false));
        dispatch(extractObservationDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(extractObservationDetailsLoadingAction(false));
        dispatch(
          extractObservationDetailsErrorAction(
            {} as ExtractObservationDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const extractObservationDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<ExtractObservationDetailsActionTypes>) {
    dispatch(extractObservationDetailsAPIMsgAction(apiRes as ExtractObservationDetailsRes, "", 0));
  };
};

export const clearExtractObservationDetailsAPIRes = () => {
  return function (dispatch: Dispatch<ExtractObservationDetailsActionTypes>) {
    dispatch(extractObservationDetailsAPIResClearAction({} as ExtractObservationDetailsRes, "", 0));
  };
};
