import { AddPatientTeamByAdminRes } from "./Model";
export const ADD_PATIENT_TEAM_BY_ADMIN_LOADING =
  "ADD_PATIENT_TEAM_BY_ADMIN_LOADING";
export const ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS =
  "ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS";
export const ADD_PATIENT_TEAM_BY_ADMIN_FAIL =
  "ADD_PATIENT_TEAM_BY_ADMIN_FAIL";
export const ADD_PATIENT_TEAM_BY_ADMIN_API_MSG =
  "ADD_PATIENT_TEAM_BY_ADMIN_API_MSG";
export const ADD_PATIENT_TEAM_BY_ADMIN_API_RES =
  "ADD_PATIENT_TEAM_BY_ADMIN_API_RES";

export interface AddPatientTeamByAdminState {
  addPatientTeamByAdminRes: AddPatientTeamByAdminRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientTeamByAdminLoading {
  type: typeof ADD_PATIENT_TEAM_BY_ADMIN_LOADING;
  loading: boolean;
}
export interface AddPatientTeamByAdminSuccess {
  type: typeof ADD_PATIENT_TEAM_BY_ADMIN_SUCCESS;
  payload: AddPatientTeamByAdminRes;
  successMsg: string;
}
export interface AddPatientTeamByAdminFail {
  type: typeof ADD_PATIENT_TEAM_BY_ADMIN_FAIL;
  payload: AddPatientTeamByAdminRes;
  errorMsg: string;
  status: number;
}
export interface AddPatientTeamByAdminAPIMsg {
  type: typeof ADD_PATIENT_TEAM_BY_ADMIN_API_MSG;
  payload: AddPatientTeamByAdminRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientTeamByAdminAPIRes {
  type: typeof ADD_PATIENT_TEAM_BY_ADMIN_API_RES;
  payload: AddPatientTeamByAdminRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddPatientTeamByAdminActionTypes =
  | AddPatientTeamByAdminLoading
  | AddPatientTeamByAdminSuccess
  | AddPatientTeamByAdminFail
  | AddPatientTeamByAdminAPIMsg
  | AddPatientTeamByAdminAPIRes;
