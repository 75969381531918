import {
  PATIENT_SEARCH_BY_HEALTHCAMP_DATA,
  PATIENT_SEARCH_BY_HEALTHCAMP_FAIL,
  PATIENT_SEARCH_BY_HEALTHCAMP_LOADING,
  PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS,
  PatientSearchByHealthCampIdActionTypes,
} from "./ActionTypes";
import { PageCount, PatientSearchByHealthCampIdDetails } from "./Model";

export const patientSearchHealthCampIdAction = (
  PatientSearchByHealthCampIdDetails: PatientSearchByHealthCampIdDetails,
  pageCount: PageCount,
): PatientSearchByHealthCampIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_HEALTHCAMP_DATA,
    payload: PatientSearchByHealthCampIdDetails,
    pageCount: pageCount,
  };
};

export const PatientSearchByHealthCampIdLoadingAction = (
  loading: boolean,
): PatientSearchByHealthCampIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_HEALTHCAMP_LOADING,
    loading: loading,
  };
};

export const PatientSearchByHealthCampIdSuccessAction = (
  patientSearchResponse: PatientSearchByHealthCampIdDetails,
): PatientSearchByHealthCampIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS,
    payload: patientSearchResponse,
  };
};

export const PatientSearchByHealthCampIdErrorAction = (
  patientSearchResponse: PatientSearchByHealthCampIdDetails,
  errMsg: string,
  status: number,
): PatientSearchByHealthCampIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_HEALTHCAMP_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const PatientSearchByHealthCampIdAPIMsgAction = (
  patientSearchResponse: PatientSearchByHealthCampIdDetails,
  errMsg: string,
  status: number,
): PatientSearchByHealthCampIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_HEALTHCAMP_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const PatientSearchByHealthCampIdAPIResClearAction = (
  patientSearchResponse: PatientSearchByHealthCampIdDetails,
  errMsg: string,
  status: number,
): PatientSearchByHealthCampIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_HEALTHCAMP_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
