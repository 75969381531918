import {
  EXTRACT_OBS_BY_SESSION_ID_FAIL,
  EXTRACT_OBS_BY_SESSION_ID_LOADING,
  EXTRACT_OBS_BY_SESSION_ID_SUCCESS,
  EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG,
  EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES,
  ExtractObsTypeBySessionIdActionTypes,
} from "./ActionTypes";
import { ExtractObsTypeBySessionIdRes } from "./Model";

export const extractObsTypeBySessionIdLoadingAction = (
  loading: boolean,
): ExtractObsTypeBySessionIdActionTypes => {
  return {
    type: EXTRACT_OBS_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const extractObsTypeBySessionIdSuccessAction = (
  extractObsTypeBySessionIdResponse: ExtractObsTypeBySessionIdRes,
  successMsg: string,
): ExtractObsTypeBySessionIdActionTypes => {
  return {
    type: EXTRACT_OBS_BY_SESSION_ID_SUCCESS,
    payload: extractObsTypeBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const extractObsTypeBySessionIdErrorAction = (
  extractObsTypeBySessionIdResponse: ExtractObsTypeBySessionIdRes,
  errMsg: string,
  status: number,
): ExtractObsTypeBySessionIdActionTypes => {
  return {
    type: EXTRACT_OBS_BY_SESSION_ID_FAIL,
    payload: extractObsTypeBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const extractObsTypeBySessionIdUpdateAPIMsgAction = (
  extractObsTypeBySessionIdResponse: ExtractObsTypeBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ExtractObsTypeBySessionIdActionTypes => {
  return {
    type: EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG,
    payload: extractObsTypeBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const extractObsTypeBySessionIdAPIResClearAction = (
  extractObsTypeBySessionIdResponse: ExtractObsTypeBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): ExtractObsTypeBySessionIdActionTypes => {
  return {
    type: EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES,
    payload: extractObsTypeBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
