import {
  GET_VITAL_COLLECTION_STATUS_FAIL,
  GET_VITAL_COLLECTION_STATUS_LOADING,
  GET_VITAL_COLLECTION_STATUS_SUCCESS,
  GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG,
  GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES,
  GetVitalCollectionStatusActionTypes,
} from "./ActionTypes";
import { GetVitalCollectionStatusRes } from "./Model";

export const getVitalCollectionStatusLoadingAction = (
  loading: boolean,
): GetVitalCollectionStatusActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_STATUS_LOADING,
    loading: loading,
  };
};

export const getVitalCollectionStatusSuccessAction = (
  getVitalCollectionStatusResponse: GetVitalCollectionStatusRes,
  successMsg: string,
): GetVitalCollectionStatusActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_STATUS_SUCCESS,
    payload: getVitalCollectionStatusResponse,
    successMsg: successMsg,
  };
};

export const getVitalCollectionStatusErrorAction = (
  getVitalCollectionStatusResponse: GetVitalCollectionStatusRes,
  errMsg: string,
  status: number,
): GetVitalCollectionStatusActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_STATUS_FAIL,
    payload: getVitalCollectionStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getVitalCollectionStatusUpdateAPIMsgAction = (
  getVitalCollectionStatusResponse: GetVitalCollectionStatusRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetVitalCollectionStatusActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_STATUS_UPDATE_API_MSG,
    payload: getVitalCollectionStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getVitalCollectionStatusAPIResClearAction = (
  getVitalCollectionStatusResponse: GetVitalCollectionStatusRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetVitalCollectionStatusActionTypes => {
  return {
    type: GET_VITAL_COLLECTION_STATUS_UPDATE_API_RES,
    payload: getVitalCollectionStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
