import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorOnboardingRejectList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function DoctorsOnboardingRequest() {
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [listCount, setListCount] = React.useState("");
  // api call from store....
  const docotorList = useSelector((state: AppState) => state.doctorRejectValue);
  const doctorRejectList = docotorList.doctorRejectValue;
  useEffect(() => {
    dispatch(toggleAppBarLoading(docotorList?.loading));
    doctorRejectList?.length !== undefined
      ? setListCount(String(doctorRejectList?.length))
      : setListCount("0");
    if (doctorRejectList !== undefined) {
      let dataPointArr = doctorRejectList.map((element: any) => ({
        id: element.id,
        doctorId: element.id,
        name: element.fullName,
        qualification: element.qualification,
        specialty: element.specialty,
        yearsOfExperience: element.yearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docotorList]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      editable: true,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "qualification",
      headerName: "Qualification",
      editable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "specialty",
      headerName: "Speciality",
      type: "string",
      minWidth: 250,
      align: "left",
      flex: 1,
    },
    {
      field: "yearsOfExperience",
      headerName: "Years of Experience",
      sortable: false,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
  ];

  useEffect(() => {
    dispatch(getDoctorOnboardingRejectList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const doctorOnboardingRejectListApi = () => {
    dispatch(getDoctorOnboardingRejectList());
  };

  const DoctorListTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                title="Doctor's On-Boarding Reject List"
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={doctorOnboardingRejectListApi}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {doctorRejectList && doctorRejectList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return <Box>{DoctorListTable}</Box>;
}
