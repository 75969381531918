import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { Box, Card, CardContent, Chip, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActiveStaffList from "./ActiveStaffList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GetOrganizationDeactiveStaffListBody } from "redux/ViewOrganizationStaffList/OrganizationDeactiveStaffList/Model";
import { getDeactiveStaffListByOrganizationId } from "redux/ViewOrganizationStaffList/OrganizationDeactiveStaffList/API";
import DeactiveStaffList from "./DeactiveStaffList";
import { GetActiveListByOrgIdBody } from "redux/ViewOrganizationStaffList/ActiveListByOrganizationId/Model";
import { getActiveListByOrgId } from "redux/ViewOrganizationStaffList/ActiveListByOrganizationId/API";

type Props = {
  judgmentListOfCompetitonLoader?: boolean | any;
};

export default function ViewCompetitionStaffList({ judgmentListOfCompetitonLoader }: Props) {
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
  // call Store
  const viewOrgStaffList = useSelector((state: AppState) => state.viewStaffListOfCompetitionValue);
  const deactiveStaffListOfOrg = useSelector((state: AppState) => state.competitionDeactiveStaffValues);
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [activeListCount, setActiveListCount] = React.useState("");
  const [deactiveListCount, setDeactiveListCount] = React.useState("");
  useEffect(() => {
    if (viewOrgStaffList?.viewCompetitionStaffListRes?.count !== undefined) {
      setActiveListCount(String(viewOrgStaffList?.viewCompetitionStaffListRes?.count));
    } else setActiveListCount("0");
  }, [viewOrgStaffList]);

  useEffect(() => {
    if (deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.count !== undefined) {
      setDeactiveListCount(String(deactiveStaffListOfOrg?.getCompetitionDeactiveStaffList?.count));
    } else setDeactiveListCount("0");
  }, [deactiveStaffListOfOrg]);
  const reloadActiveStaffListApi = () => {
    const activeListBody = {
      organizationId: organizationid,
      isActiveOrAdmin: 1,
    } as GetActiveListByOrgIdBody;
    dispatch(getActiveListByOrgId(activeListBody, 1));
  }

  const reloadDeactiveStaffListApi = () => {
    const patientListBody = {
      organizationId: organizationid,
    } as GetOrganizationDeactiveStaffListBody;
    dispatch(getDeactiveStaffListByOrganizationId(patientListBody, 1));
  }

  const StaffListOfOrganization = (
    <Card>
      <CardContent>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Active List</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={activeListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadActiveStaffListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>Deactive List</Typography>
                      </Grid>
                      <Grid item>
                        <Chip label={deactiveListCount} variant="outlined" size="small" />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadDeactiveStaffListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ActiveStaffList judgmentListOfCompetitonLoader={judgmentListOfCompetitonLoader} />
            </TabPanel>
            <TabPanel value="2">
              <DeactiveStaffList judgmentListOfCompetitonLoader={judgmentListOfCompetitonLoader} />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );

  return <Box>{StaffListOfOrganization}</Box>;
}
