import {
  DeleteAccountRequestListByTypeActionTypes,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS,
  DeleteAccountRequestListByTypeState,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG,
  DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES,
} from "./ActionTypes";
import { DeleteAccountRequestListByTypeDetails } from "./Model";

const initialStateGetPosts: DeleteAccountRequestListByTypeState = {
  loading: false,
  deleteAccountRequestListByTypeRes: {} as DeleteAccountRequestListByTypeDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const deleteAccountRequestListByTypeReducer = (
  state = initialStateGetPosts,
  action: DeleteAccountRequestListByTypeActionTypes,
): DeleteAccountRequestListByTypeState => {
  switch (action.type) {
    case DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_SUCCESS:
      return {
        ...state,
        deleteAccountRequestListByTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_FAIL:
      return {
        ...state,
        deleteAccountRequestListByTypeRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_MSG:
      return {
        ...state,
        deleteAccountRequestListByTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_ACCOUNT_REQUEST_LIST_BY_TYPE_API_RES:
      return {
        ...state,
        deleteAccountRequestListByTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
