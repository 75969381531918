import {
  GET_ADMIN_LOGIN_DETAILS_FAIL,
  GET_ADMIN_LOGIN_DETAILS_LOADING,
  GET_ADMIN_LOGIN_DETAILS_SUCCESS,
  GET_ADMIN_LOGIN_DETAILS_API_MSG,
  GET_ADMIN_LOGIN_DETAILS_API_RES,
  GetAdminDetailsActionTypes,
} from "./ActionTypes";
import { GetAdminDetailsRes } from "./Model";

export const getAdminDetailsLoadingAction = (loading: boolean): GetAdminDetailsActionTypes => {
  return {
    type: GET_ADMIN_LOGIN_DETAILS_LOADING,
    loading: loading,
  };
};

export const getAdminDetailsSuccessAction = (
  getPatientLoginDetailsResponse: GetAdminDetailsRes,
  successMsg: string,
): GetAdminDetailsActionTypes => {
  return {
    type: GET_ADMIN_LOGIN_DETAILS_SUCCESS,
    payload: getPatientLoginDetailsResponse,
    successMsg: successMsg,
  };
};

export const getAdminDetailsErrorAction = (
  getPatientLoginDetailsResponse: GetAdminDetailsRes,
  errMsg: string,
  status: number,
): GetAdminDetailsActionTypes => {
  return {
    type: GET_ADMIN_LOGIN_DETAILS_FAIL,
    payload: getPatientLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getAdminDetailsAPIMsgAction = (
  getPatientLoginDetailsResponse: GetAdminDetailsRes,
  errMsg: string,
  status: number,
): GetAdminDetailsActionTypes => {
  return {
    type: GET_ADMIN_LOGIN_DETAILS_API_MSG,
    payload: getPatientLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getAdminDetailsAPIResClearAction = (
  getPatientLoginDetailsResponse: GetAdminDetailsRes,
  errMsg: string,
  status: number,
): GetAdminDetailsActionTypes => {
  return {
    type: GET_ADMIN_LOGIN_DETAILS_API_RES,
    payload: getPatientLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
