import { AdminPaymentResponse } from "./Model";
export const ADMIN_PAYMENT_LOADING = "ADMIN_PAYMENT_LOADING";
export const ADMIN_PAYMENT_SUCCESS = "ADMIN_PAYMENT_SUCCESS";
export const ADMIN_PAYMENT_FAIL = "ADMIN_PAYMENT_FAIL";
export const ADMIN_PAYMENT_DATA = "ADMIN_PAYMENT_DATA";
export const ADMIN_PAYMENT_UPDATE_API_MSG = "ADMIN_PAYMENT_UPDATE_API_MSG";
export const ADMIN_PAYMENT_UPDATE_API_RES = "ADMIN_PAYMENT_UPDATE_API_RES";

export interface AdminPaymentState {
  adminPaymentRes: AdminPaymentResponse;
  loading: boolean;
  message: string;
  status: number;
}
export interface AdminPaymentLoading {
  type: typeof ADMIN_PAYMENT_LOADING;
  loading: boolean;
}
export interface AdminPaymentSuccess {
  type: typeof ADMIN_PAYMENT_SUCCESS;
  payload: AdminPaymentResponse;
}
export interface AdminPaymentFail {
  type: typeof ADMIN_PAYMENT_FAIL;
  payload: AdminPaymentResponse;
  message: string;
  status: number;
}
export interface AdminPaymentUpdateAPIMsg {
  type: typeof ADMIN_PAYMENT_UPDATE_API_MSG;
  payload: AdminPaymentResponse;
  message: string;
  status: number;
}
export interface AdminPaymentUpdateAPIRes {
  type: typeof ADMIN_PAYMENT_UPDATE_API_RES;
  payload: AdminPaymentResponse;
  message: string;
  status: number;
}
interface AdminPaymentAction {
  type: typeof ADMIN_PAYMENT_DATA;
  payload: AdminPaymentResponse;
}

export type AdminPaymentActionTypes =
  | AdminPaymentAction
  | AdminPaymentLoading
  | AdminPaymentSuccess
  | AdminPaymentFail
  | AdminPaymentUpdateAPIMsg
  | AdminPaymentUpdateAPIRes;
