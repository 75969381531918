import {
  PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL,
  PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING,
  PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS,
  PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG,
  PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES,
  GetPatientDetailsListBySessionIdActionTypes,
} from "./ActionTypes";
import { GetPatientDetailsListBySessionIdRes } from "./Model";

export const getPatientDetailsListBySessionIdLoadingAction = (
  loading: boolean,
): GetPatientDetailsListBySessionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_SESSION_ID_LOADING,
    loading: loading,
  };
};

export const getPatientDetailsListBySessionIdSuccessAction = (
  getPatientDetailsListBySessionIdResponse: GetPatientDetailsListBySessionIdRes,
  successMsg: string,
): GetPatientDetailsListBySessionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_SESSION_ID_SUCCESS,
    payload: getPatientDetailsListBySessionIdResponse,
    successMsg: successMsg,
  };
};

export const getPatientDetailsListBySessionIdErrorAction = (
  getPatientDetailsListBySessionIdResponse: GetPatientDetailsListBySessionIdRes,
  errMsg: string,
  status: number,
): GetPatientDetailsListBySessionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_SESSION_ID_FAIL,
    payload: getPatientDetailsListBySessionIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientDetailsListBySessionIdUpdateAPIMsgAction = (
  getPatientDetailsListBySessionIdResponse: GetPatientDetailsListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientDetailsListBySessionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_MSG,
    payload: getPatientDetailsListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientDetailsListBySessionIdAPIResClearAction = (
  getPatientDetailsListBySessionIdResponse: GetPatientDetailsListBySessionIdRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetPatientDetailsListBySessionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_SESSION_ID_UPDATE_API_RES,
    payload: getPatientDetailsListBySessionIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
