import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  // Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import MWExceptionList from "component/MWExceptionList";
import MWPageTitle from "component/MWPageTitle";
import dayjs from "dayjs";
import { AppState } from "redux/store/Store";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  clearDeleteAccountRequestAPIRes,
  deleteAccountRequestApiCall,
  updateDeleteAccountRequestAPIResMsg,
} from "redux/DeleteAccountRequest/API";
import { DeleteAccountRequestBody } from "redux/DeleteAccountRequest/Model";
import MWSnackbar from "component/MWSnackbar";
import { DeleteAccountRequestListByTypeBody } from "redux/DeleteAccountRequestListByType/Model";
import { deleteAccountRequestListByTypeApiCall } from "redux/DeleteAccountRequestListByType/API";

export default function DeleteAccountRequestList() {
  const dispatch = useDispatch();
  const executiveDeleteAccountList = useSelector(
    (state: AppState) => state.deleteAccountRequestListValues,
  );
  const accountDeleteReqRes = useSelector((state: AppState) => state.deleteAccoutRequestValue);

  useEffect(() => {
    dispatch(
      deleteAccountRequestListByTypeApiCall({
        type: "incomplete",
      } as DeleteAccountRequestListByTypeBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [listCount, setListCount] = useState("");
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  // const [page, setPage] = React.useState(1);
  const [deleteRequestId, setDeleteRequestId] = React.useState("");
  const [deleteReason, setDeleteReason] = React.useState("");
  const [deleteAccountRequestApiMsg, setDeleteAccountRequestApiMsg] = React.useState("");
  const [deleteAccountRequestColorMsg, setDeleteAccountRequestColorMsg] =
    React.useState<AlertColor>("success");

  const [modalActive, setModalActive] = useState(false);
  const handleToggle = () => {
    setModalActive(false);
    setDeleteRequestId("");
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(executiveDeleteAccountList?.loading));
    if (
      executiveDeleteAccountList?.deleteAccountRequestListByTypeRes
        ?.executiveAccountDeleteRequestList !== undefined
    ) {
      setListCount(
        String(
          executiveDeleteAccountList?.deleteAccountRequestListByTypeRes
            ?.executiveAccountDeleteRequestList.length,
        ),
      );
      let dataPointArr =
        executiveDeleteAccountList?.deleteAccountRequestListByTypeRes?.executiveAccountDeleteRequestList.map(
          (element: any) => ({
            id: element.id,
            status: element.status !== undefined ? element.status : "",
            reason: element.reason !== null ? element.reason : "Not Set",
            createdDate: dayjs(element.createDate).format("ddd MMM DD, YYYY hh:mm A"),
            name: element.firstName + " " + element.lastName,
            userName: element.userName,
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveDeleteAccountList]);
  // const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  //   dispatch(getDuplicatePatientList(value));
  // };

  useEffect(() => {
    dispatch(toggleAppBarLoading(accountDeleteReqRes?.loading));
    if (accountDeleteReqRes?.successMsg !== "") {
      setDeleteAccountRequestApiMsg(accountDeleteReqRes?.successMsg);
      setDeleteAccountRequestColorMsg("success");
      dispatch(updateDeleteAccountRequestAPIResMsg());
      dispatch(clearDeleteAccountRequestAPIRes());
      setModalActive(false);
      dispatch(
        deleteAccountRequestListByTypeApiCall({
          type: "incomplete",
        } as DeleteAccountRequestListByTypeBody),
      );
    }
    if (accountDeleteReqRes?.errorMsg !== "") {
      setDeleteAccountRequestApiMsg(accountDeleteReqRes?.errorMsg);
      setDeleteAccountRequestColorMsg("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDeleteReqRes]);

  const openDialog = (id: string) => {
    setDeleteRequestId(id);
    setModalActive(true);
  };

  const deleteListReload = () => {
    //api call here
  };

  const deleteAccountSubmit = () => {
    //delete api call here
  };

  const deleteAccountRequestDialog = () => {
    setModalActive(true);
  };

  const closeDeleteAccountMsg = () => {
    setDeleteAccountRequestApiMsg("");
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      align: "left",
    },

    {
      field: "userName",
      headerName: "User Name",
      minWidth: 250,
      align: "left",
    },

    {
      field: "reason",
      headerName: "Reason",
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      disableColumnMenu: true,
      align: "left",
    },

    {
      field: "createdDate",
      headerName: "Original Create Date",
      type: "string",
      minWidth: 250,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "Action",
      editable: true,
      minWidth: 200,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<DeleteOutlineIcon />}
          onClick={() => {
            openDialog(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Delete Account
        </Button>
      ),
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => deleteAccountRequestDialog()}
                startIcon={<DeleteIcon />}
              >
                Delete Account Request
              </Button>
            </Grid>
            {/* <Grid item>
              <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            </Grid>
            <Grid item>
              <Pagination
                color="primary"
                count={Number(pageListCount)}
                page={page}
                onChange={handleChange}
              />
            </Grid> */}
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  const deleteAccount = (
    <Dialog open={modalActive} onClose={handleToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Delete Account</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h6">Are you sure, you want to Delete this Account?</Typography>
          </Grid>
          <Grid item>
            <TextField
              id="outlined-textarea"
              label="Reason"
              placeholder="Enter reason for delete account"
              multiline
              fullWidth
              onChange={(e) => {
                setDeleteReason(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        {deleteRequestId !== "" ? (
          <Button variant="contained" onClick={() => deleteAccountSubmit()}>
            Yes
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                deleteAccountRequestApiCall({ reason: deleteReason } as DeleteAccountRequestBody),
              )
            }
          >
            Yes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              title="Delete Account Request List"
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={deleteListReload}
            />
          </Grid>
        </Grid>
        {patientPointDataTable}
        {deleteAccount}
        {deleteAccountRequestApiMsg !== "" ? (
          <MWSnackbar
            msg={deleteAccountRequestApiMsg}
            type={deleteAccountRequestColorMsg}
            alertClose={closeDeleteAccountMsg}
          />
        ) : null}
      </Stack>
    </Container>
  );
}
