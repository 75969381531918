import {
  DuplicatePatientDemographicsDetailsActionTypes,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS,
  GetDuplicateDemographicsDetailsState,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG,
  DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES,
} from "./ActionTypes";
import { DuplicatePatientDemographicsDetailsRes } from "./Model";

const initialStateGetPosts: GetDuplicateDemographicsDetailsState = {
  loading: false,
  getDuplicateDemographicsByPatientIdRes: {} as DuplicatePatientDemographicsDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getGetDuplicateDemographicsDetailsByPatientIdReducer = (
  state = initialStateGetPosts,
  action: DuplicatePatientDemographicsDetailsActionTypes,
): GetDuplicateDemographicsDetailsState => {
  switch (action.type) {
    case DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_SUCCESS:
      return {
        ...state,
        getDuplicateDemographicsByPatientIdRes: action.payload,
        successMsg: action.successMsg,
      };
    case DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_FAIL:
      return {
        ...state,
        getDuplicateDemographicsByPatientIdRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_MSG:
      return {
        ...state,
        getDuplicateDemographicsByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DUPLICATE_PATIENT_DEMOGRAPHICS_DETAILS_UPDATE_API_RES:
      return {
        ...state,
        getDuplicateDemographicsByPatientIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
