import {
  GetJudgingDetailsListActionTypes,
  GET_JUDGING_LIST_ID_FAIL,
  GET_JUDGING_LIST_ID_LOADING,
  GET_JUDGING_LIST_ID_SUCCESS,
  GetJudgingDetailsListState,
  GET_JUDGING_LIST_ID_API_MSG,
  GET_JUDGING_LIST_ID_API_RES,
} from "./ActionTypes";
import { GetJudgingDetailsListDetails } from "./Model";

const initialStateGetPosts: GetJudgingDetailsListState = {
  loading: false,
  getJudgingDetailsListRes: {} as GetJudgingDetailsListDetails,
  message: "",
  status: 0,
};
export const getJudgingDetailsListReducer = (
  state = initialStateGetPosts,
  action: GetJudgingDetailsListActionTypes,
): GetJudgingDetailsListState => {
  switch (action.type) {
    case GET_JUDGING_LIST_ID_LOADING:
      return {
        ...state,
        loading: true,
        getJudgingDetailsListRes: {} as GetJudgingDetailsListDetails,
      };
    case GET_JUDGING_LIST_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getJudgingDetailsListRes: action.payload,
      };
    case GET_JUDGING_LIST_ID_FAIL:
      return {
        ...state,
        loading: false,
        getJudgingDetailsListRes: {} as GetJudgingDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    case GET_JUDGING_LIST_ID_API_MSG:
      return {
        ...state,
        loading: false,
        getJudgingDetailsListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case GET_JUDGING_LIST_ID_API_RES:
      return {
        ...state,
        loading: false,
        getJudgingDetailsListRes: {} as GetJudgingDetailsListDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
