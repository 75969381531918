import {
  EDIT_EXECUTIVE_FAIL,
  EDIT_EXECUTIVE_LOADING,
  EDIT_EXECUTIVE_SUCCESS,
  EDIT_EXECUTIVE_UPDATE_API_MSG,
  EDIT_EXECUTIVE_UPDATE_API_RES,
  EditExecutiveActionTypes,
} from "./ActionTypes";
import { EditExecutiveRes } from "./Model";

export const editExecutiveLoadingAction = (loading: boolean): EditExecutiveActionTypes => {
  return {
    type: EDIT_EXECUTIVE_LOADING,
    loading: loading,
  };
};

export const editExecutiveSuccessAction = (
  editExecutiveRes: EditExecutiveRes,
  successMsg: string,
): EditExecutiveActionTypes => {
  return {
    type: EDIT_EXECUTIVE_SUCCESS,
    payload: editExecutiveRes,
    successMsg: successMsg,
  };
};

export const editExecutiveErrorAction = (
  editExecutiveRes: EditExecutiveRes,
  errMsg: string,
  status: number,
): EditExecutiveActionTypes => {
  return {
    type: EDIT_EXECUTIVE_FAIL,
    payload: editExecutiveRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const editExecutiveUpdateAPIMsgAction = (
  editExecutiveRes: EditExecutiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): EditExecutiveActionTypes => {
  return {
    type: EDIT_EXECUTIVE_UPDATE_API_MSG,
    payload: editExecutiveRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editExecutiveAPIResClearAction = (
  editExecutiveRes: EditExecutiveRes,
  successMsg: string,
  errMsg: string,
  status: number,
): EditExecutiveActionTypes => {
  return {
    type: EDIT_EXECUTIVE_UPDATE_API_RES,
    payload: editExecutiveRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
