import {
  AddDataCurationProblemActionTypes,
  ADD_DATA_CURATION_PROBLEM_FAIL,
  ADD_DATA_CURATION_PROBLEM_LOADING,
  ADD_DATA_CURATION_PROBLEM_SUCCESS,
  AddDataCurationProblemState,
  ADD_DATA_CURATION_PROBLEM_API_MSG,
  ADD_DATA_CURATION_PROBLEM_API_RES,
} from "./ActionTypes";
import { AddDataCurationProblemDetails } from "./Model";

const initialStateGetPosts: AddDataCurationProblemState = {
  loading: false,
  addDataCurationProblemRes: {} as AddDataCurationProblemDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDataCurationProblemReducer = (
  state = initialStateGetPosts,
  action: AddDataCurationProblemActionTypes,
): AddDataCurationProblemState => {
  switch (action.type) {
    case ADD_DATA_CURATION_PROBLEM_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DATA_CURATION_PROBLEM_SUCCESS:
      return {
        ...state,
        addDataCurationProblemRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DATA_CURATION_PROBLEM_FAIL:
      return {
        ...state,
        addDataCurationProblemRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DATA_CURATION_PROBLEM_API_MSG:
      return {
        ...state,
        addDataCurationProblemRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DATA_CURATION_PROBLEM_API_RES:
      return {
        ...state,
        addDataCurationProblemRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
