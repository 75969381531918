import React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchPatientForOriginalId from "./SearchPatientForOriginalId";

type Props = {
  addOriginalDialogEnable?: boolean | any;
  addOriginalDialogUpdateState?: boolean | any;
  originalValue?: any;
};

export default function AddOriginalPatientDialog({
  addOriginalDialogEnable,
  addOriginalDialogUpdateState,
  originalValue,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addOriginalDialogUpdateState(false);
  };

  React.useEffect(() => {
    setDialogState(addOriginalDialogEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOriginalDialogEnable]);

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Search Patient</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <SearchPatientForOriginalId
            originalValue={originalValue}
            dialogClose={setDialogState}
            dialogUpdate={addOriginalDialogUpdateState}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
