import {
  VIEW_COMPETITION_STAFF_LIST_DATA,
  VIEW_COMPETITION_STAFF_LIST_FAIL,
  VIEW_COMPETITION_STAFF_LIST_LOADING,
  VIEW_COMPETITION_STAFF_LIST_SUCCESS,
  VIEW_COMPETITION_STAFF_LIST_API_MSG,
  VIEW_COMPETITION_STAFF_LIST_API_RES,
  GetViewCompetitionStaffListActionTypes,
} from "./ActionTypes";
import { PageCount, GetViewCompetitionStaffListDetails } from "./Model";

export const competitionIdAction = (
  GetViewCompetitionStaffListDetails: GetViewCompetitionStaffListDetails,
  pageCount: PageCount,
): GetViewCompetitionStaffListActionTypes => {
  return {
    type: VIEW_COMPETITION_STAFF_LIST_DATA,
    payload: GetViewCompetitionStaffListDetails,
    pageCount: pageCount,
  };
};

export const getViewCompetitionStaffListLoadingAction = (
  loading: boolean,
): GetViewCompetitionStaffListActionTypes => {
  return {
    type: VIEW_COMPETITION_STAFF_LIST_LOADING,
    loading: loading,
  };
};

export const getViewCompetitionStaffListSuccessAction = (
  viewCompetitionStaffListResponse: GetViewCompetitionStaffListDetails,
  successMsg: string,
): GetViewCompetitionStaffListActionTypes => {
  return {
    type: VIEW_COMPETITION_STAFF_LIST_SUCCESS,
    payload: viewCompetitionStaffListResponse,
    successMsg: successMsg,
  };
};

export const getViewCompetitionStaffListErrorAction = (
  viewCompetitionStaffListResponse: GetViewCompetitionStaffListDetails,
  errMsg: string,
  status: number,
): GetViewCompetitionStaffListActionTypes => {
  return {
    type: VIEW_COMPETITION_STAFF_LIST_FAIL,
    payload: viewCompetitionStaffListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getViewCompetitionStaffListAPIMsgAction = (
  viewCompetitionStaffListResponse: GetViewCompetitionStaffListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): GetViewCompetitionStaffListActionTypes => {
  return {
    type: VIEW_COMPETITION_STAFF_LIST_API_MSG,
    payload: viewCompetitionStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getViewCompetitionStaffListAPIResClearAction = (
  viewCompetitionStaffListResponse: GetViewCompetitionStaffListDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): GetViewCompetitionStaffListActionTypes => {
  return {
    type: VIEW_COMPETITION_STAFF_LIST_API_RES,
    payload: viewCompetitionStaffListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
