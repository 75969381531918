import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  Chip,
  tabsClasses,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { AppState } from "redux/store/Store";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { organizationListByPatientIdApi } from "redux/PatientOrganizationListById/API";
import { OrganizationListByPatientIdBody } from "redux/PatientOrganizationListById/Model";
import { healthcampListByPatientIdApi } from "redux/PatientHealthcampListById/API";
import { HealthcampListByPatientIdBody } from "redux/PatientHealthcampListById/Model";
import { competitionListByPatientIdApi } from "redux/PatientCompetitionListById/API";
import { CompetitionListByPatientIdBody } from "redux/PatientCompetitionListById/Model";
import { instanceListByPatientIdApi } from "redux/PatientInstanceListById/API";
import { InstanceListByPatientIdBody } from "redux/PatientInstanceListById/Model";
import { sessionListByPatientIdApi } from "redux/PatientSessionListById/API";
import { SessionListByPatientIdBody } from "redux/PatientSessionListById/Model";
import PatientTeamListDetails from "../PatientTeamListDetails";
import OrganizationListByPatient from "../OrganizationListByPatient";
import HealthcampListByPatient from "../HealthcampListByPatient";
import InstanceListByPatient from "../InstanceListByPatient";
import SessionListByPatient from "../SessionListByPatient";
import CompetitionListByPatient from "../CompetitionListByPatient";
import MWPageTitle from "component/MWPageTitle";
import PatientTeamListDetailsByProfileId from "../PatientTeamListDetailsByProfileId";
import SubscriptionListByPatient from "../SubscriptionListByPatient";
import EncounterListByPatient from "./EncounterListByPatient";
import { getSubscriptionListById } from "redux/PatientSubscriptionListById/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientTeamDetails() {
  const { patientDetailsID } = useParams() as {
    patientDetailsID: string;
  };
  let query = UseQuery();
  const tabId = query.get("section");
  const dispatch = useDispatch();
  const history = useHistory();
  // call Store
  const organizationList = useSelector((state: AppState) => state.organizationListByPatientValues);
  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.healthcampListByPatientValues,
  );
  const competitionList = useSelector((state: AppState) => state.competitionListByPatientIdValues);
  const instanceList = useSelector((state: AppState) => state.instanceListBuPatientIdValues);
  const sessionListValues = useSelector((state: AppState) => state.sessionListByPatientIdValues);
  const subscriptionList = useSelector(
    (state: AppState) => state.getPatientSubscriptionListbyIdValues,
  );
  const [organizationCountList, setOrganizationCountList] = React.useState("");
  const [healthcampCountList, setHealthcampCountList] = React.useState("");
  const [competitionCountList, setCompetitionCountList] = React.useState("");
  const [subscriptionCountList, setSubscriptionCountList] = React.useState("");
  const [instanceCountList, setInstanceCountList] = React.useState("");
  const [sessionCountList, setSessionCountList] = React.useState("");
  const [patientListByOrgLoader, setPatientListByOrgLoader] = React.useState(false);
  const [patientListByHealthcampLoader, setPatientListByHealthcampLoader] = React.useState(false);
  const [healthcampInstanceLoader, setHealthcampInstanceLoader] = React.useState(false);
  const [healthcampSessionLoader, setHealthcampSessionLoader] = React.useState(false);
  const [competitionListLoader, setCompetitionListLoader] = React.useState(false);
  const [subscriptionListLoader, setSubscriptionLoader] = React.useState(false);
  const [encounterListLoader, setEncounterListLoader] = React.useState(false);
  useEffect(() => {
    organizationList?.organizationListByPatientIdRes?.count !== undefined
      ? setOrganizationCountList(String(organizationList?.organizationListByPatientIdRes?.count))
      : setOrganizationCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);
  useEffect(() => {
    AdminAllHealthcampList?.healthcampListByPatientIdRes?.count !== undefined
      ? setHealthcampCountList(String(AdminAllHealthcampList?.healthcampListByPatientIdRes?.count))
      : setHealthcampCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);
  useEffect(() => {
    competitionList?.competitionListByPatientIdRes?.count !== undefined
      ? setCompetitionCountList(String(competitionList?.competitionListByPatientIdRes?.count))
      : setCompetitionCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionList]);
  useEffect(() => {
    subscriptionList?.GetSubscriptionListByIdResponse?.medicalWisdomSubscriptionList !== undefined
      ? setSubscriptionCountList(
          String(
            subscriptionList?.GetSubscriptionListByIdResponse?.medicalWisdomSubscriptionList
              ?.length,
          ),
        )
      : setSubscriptionCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionList]);
  useEffect(() => {
    instanceList?.instanceListByPatientIdRes?.count !== undefined
      ? setInstanceCountList(String(instanceList?.instanceListByPatientIdRes?.count))
      : setInstanceCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceList]);
  useEffect(() => {
    sessionListValues?.sessionListByPatientIdRes?.count !== undefined
      ? setSessionCountList(String(sessionListValues?.sessionListByPatientIdRes?.count))
      : setSessionCountList("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListValues]);
  const [tabValue, setTabValue] = useState(0);
  const [selected, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=patient-team-list-details"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=patient-organization-list"}`);
    } else if (newValue === 2) {
      history.push(`${window.location.pathname + "?section=patient-healthcamp-list"}`);
    } else if (newValue === 3) {
      history.push(`${window.location.pathname + "?section=healthcamp-instance-list"}`);
    } else if (newValue === 4) {
      history.push(`${window.location.pathname + "?section=healthcamp-session-list"}`);
    } else if (newValue === 5) {
      history.push(`${window.location.pathname + "?section=patient-competition-list"}`);
    } else if (newValue === 6) {
      history.push(`${window.location.pathname + "?section=patient-subscription-list"}`);
    } else if (newValue === 7) {
      history.push(`${window.location.pathname + "?section=patient-encounter-list"}`);
    }
  };
  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "patient-team-list-details") {
      setTabValue(0);
    } else if (tabId === "patient-organization-list") {
      setTabValue(1);
    } else if (tabId === "patient-healthcamp-list") {
      setTabValue(2);
    } else if (tabId === "healthcamp-instance-list") {
      setTabValue(3);
    } else if (tabId === "healthcamp-session-list") {
      setTabValue(4);
    } else if (tabId === "patient-competition-list") {
      setTabValue(5);
    } else if (tabId === "patient-subscription-list") {
      setTabValue(6);
    } else if (tabId === "patient-encounter-list") {
      setTabValue(7);
    }
    // eslint-disable-next-line
  }, [tabId]);

  const patientOrgList = () => {
    dispatch(
      organizationListByPatientIdApi(
        {
          patientId: patientDetailsID,
        } as OrganizationListByPatientIdBody,
        1,
      ),
    );
  };

  const patientHealthcampList = () => {
    dispatch(
      healthcampListByPatientIdApi(
        { patientId: patientDetailsID } as HealthcampListByPatientIdBody,
        1,
      ),
    );
  };
  const patientInstanceList = () => {
    dispatch(
      instanceListByPatientIdApi(
        {
          patientId: patientDetailsID,
        } as InstanceListByPatientIdBody,
        1,
      ),
    );
  };
  const patientSessionList = () => {
    dispatch(
      sessionListByPatientIdApi(
        {
          patientId: patientDetailsID,
        } as SessionListByPatientIdBody,
        1,
      ),
    );
  };

  const patientCompetitionList = () => {
    dispatch(
      competitionListByPatientIdApi(
        {
          patientId: patientDetailsID,
        } as CompetitionListByPatientIdBody,
        1,
      ),
    );
  };

  const patientSubscriptionList = () => {
    dispatch(getSubscriptionListById());
  };

  const membershipPage = (
    <Box>
      {patientDetailsID !== undefined ? (
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Patient Team List Details</Typography>
                </Grid>
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Patient Organization</Typography>
                </Grid>
                <Grid item>
                  <Chip label={organizationCountList} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  patientOrgList();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Healthcamps</Typography>
                </Grid>
                <Grid item>
                  <Chip label={healthcampCountList} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  patientHealthcampList();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Healthcamp Instance List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={instanceCountList} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  patientInstanceList();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(3)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Healthcamp Session List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={sessionCountList} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  patientSessionList();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(4)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Competition List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={competitionCountList} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  patientCompetitionList();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(5)}
          />
          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Subscription List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={subscriptionCountList} variant="outlined" size="small" />
                </Grid>
              </Grid>
            }
            icon={
              <RefreshIcon
                onClick={() => {
                  patientSubscriptionList();
                }}
              />
            }
            iconPosition="end"
            {...a11yProps(6)}
          />

          <Tab
            label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Encounter List</Typography>
                </Grid>
              </Grid>
            }
            {...a11yProps(7)}
          />
        </Tabs>
      ) : (
        <PatientTeamListDetailsByProfileId />
      )}

      <Divider sx={{ mx: 4 }} />
      <TabPanel value={tabValue} index={0}>
        <PatientTeamListDetails />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <OrganizationListByPatient
          patientoriginalid={patientDetailsID}
          patientListByOrgLoader={setPatientListByOrgLoader}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <HealthcampListByPatient
          patientoriginalid={patientDetailsID}
          patientListByHealthcampLoader={setPatientListByHealthcampLoader}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <InstanceListByPatient
          patientoriginalid={patientDetailsID}
          healthcampInstanceLoader={setHealthcampInstanceLoader}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <SessionListByPatient
          patientoriginalid={patientDetailsID}
          healthcampSessionLoader={setHealthcampSessionLoader}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <CompetitionListByPatient
          patientoriginalid={patientDetailsID}
          competitionListLoader={setCompetitionListLoader}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <SubscriptionListByPatient subscriptionListLoader={setSubscriptionLoader} />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <EncounterListByPatient encounterListLoader={setEncounterListLoader} />
      </TabPanel>
    </Box>
  );

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        patientListByOrgLoader ||
          patientListByHealthcampLoader ||
          healthcampInstanceLoader ||
          healthcampSessionLoader ||
          competitionListLoader ||
          subscriptionListLoader ||
          encounterListLoader,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientListByOrgLoader,
    patientListByHealthcampLoader,
    healthcampInstanceLoader,
    healthcampSessionLoader,
    competitionListLoader,
    subscriptionListLoader,
    encounterListLoader,
  ]);

  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle backButton={true} title="Membership" />
          </Grid>
        </Grid>
        {membershipPage}
      </Stack>
    </Container>
  );
}
