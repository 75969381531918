import * as React from "react";
import { Drawer, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import View from "../../pages/Preference/PreferenceView";
import AdminDetails from "pages/AdminDetails";

type Props = {
  selection: string;
  enable: boolean;
  disable: (value: boolean) => void;
};

export default function PreferenceDrawer({ selection, enable, disable }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    disable(newOpen);
  };
  React.useEffect(() => {
    setOpen(enable);
  }, [enable]);

  const disableDrawer = (value: boolean) => {
    disable(value);
  };
  return (
    <div>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        variant="temporary"
        anchor={"right"}
        sx={{
          width: fullScreen ? "80vw" : 350,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: fullScreen ? "80vw" : 350,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        {selection === "0" ? (
          <View disable={disableDrawer} />
        ) : selection === "1" ? (
          <AdminDetails />
        ) : null}
      </Drawer>
    </div>
  );
}
