import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { CreateHealthCampByOrganizationListActionTypes } from "./ActionTypes";
import {
  CreateHealthCampByOrganizationBody,
  CreateHealthCampByOrganizationRes,
} from "./Model";
import {
  createHealthCampByOrganizationAPIResClearAction,
  createHealthCampByOrganizationListErrorAction,
  createHealthCampByOrganizationLoadingAction,
  createHealthCampByOrganizationListSuccessAction,
  createHealthCampByOrganizationListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as CreateHealthCampByOrganizationRes;
export const createHealthCampByOrganizationList = (
  payload: CreateHealthCampByOrganizationBody
) => {
  return function (
    dispatch: Dispatch<CreateHealthCampByOrganizationListActionTypes>
  ) {
    dispatch(createHealthCampByOrganizationLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/patientorganization/createPatientOrganizationHealthcampMapping`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(createHealthCampByOrganizationLoadingAction(false));
        dispatch(createHealthCampByOrganizationListSuccessAction(res.data, ""));
      })
      .catch((error) => {
        dispatch(createHealthCampByOrganizationLoadingAction(false));
        dispatch(
          createHealthCampByOrganizationListErrorAction(
            {} as CreateHealthCampByOrganizationRes,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : 0
          )
        );
      });
  };
};

export const updateCreateHealthCampByOrganizationListAPIResMsg = () => {
  return function (
    dispatch: Dispatch<CreateHealthCampByOrganizationListActionTypes>
  ) {
    dispatch(
      createHealthCampByOrganizationListUpdateAPIMsgAction(
        apiRes as CreateHealthCampByOrganizationRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearCreateHealthCampByOrganizationListAPIRes = () => {
  return function (
    dispatch: Dispatch<CreateHealthCampByOrganizationListActionTypes>
  ) {
    dispatch(
      createHealthCampByOrganizationAPIResClearAction(
        {} as CreateHealthCampByOrganizationRes,
        "",
        "",
        0
      )
    );
  };
};
