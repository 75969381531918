import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPatientDataListActionTypes } from "./ActionTypes";
import { GetPatientDataDetailsBody, GetPatientDataListRes } from "./Model";
import {
  getPatientDataListAPIResClearAction,
  getPatientDataListErrorAction,
  getPatientDataListLoadingAction,
  getPatientDataListSuccessAction,
  getPatientDataListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetPatientDataListRes;
export const getPatientDataList = (payload: GetPatientDataDetailsBody) => {
  return function (dispatch: Dispatch<GetPatientDataListActionTypes>) {
    dispatch(getPatientDataListLoadingAction(true));
    axios
      .post(Url.baseUrl + `/patientapp/api/patientMedicalData/get_patientDetailsList`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDataListLoadingAction(false));
        dispatch(
          getPatientDataListSuccessAction(
            res.data,
            res?.data !== undefined
              ? res?.data?.message
                ? res.data.message
                : "Patient questionnaire list has been fetched successfully"
              : "Patient questionnaire list has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getPatientDataListLoadingAction(false));
        dispatch(
          getPatientDataListErrorAction(
            {} as GetPatientDataListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientDataListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDataListActionTypes>) {
    dispatch(getPatientDataListUpdateAPIMsgAction(apiRes as GetPatientDataListRes, "", "", 0));
  };
};

export const clearPatientDataListAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDataListActionTypes>) {
    dispatch(getPatientDataListAPIResClearAction({} as GetPatientDataListRes, "", "", 0));
  };
};
