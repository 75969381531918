import {
  GET_JUDGING_FINAL_ROUND_LIST_ID_DATA,
  GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL,
  GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING,
  GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS,
  GetJudgingFinalRoundDetailsListActionTypes,
} from "./ActionTypes";
import { PageCount, GetJudgingFinalRoundDetailsListDetails } from "./Model";

export const finalRankAction = (
  GetJudgingFinalRoundDetailsListDetails: GetJudgingFinalRoundDetailsListDetails,
  pageCount: PageCount,
): GetJudgingFinalRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_FINAL_ROUND_LIST_ID_DATA,
    payload: GetJudgingFinalRoundDetailsListDetails,
    pageCount: pageCount,
  };
};

export const getJudgingFinalRoundDetailsListLoadingAction = (
  loading: boolean,
): GetJudgingFinalRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_FINAL_ROUND_LIST_ID_LOADING,
    loading: loading,
  };
};

export const getJudgingFinalRoundDetailsListSuccessAction = (
  getJudgingDetailsListResponse: GetJudgingFinalRoundDetailsListDetails,
): GetJudgingFinalRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_FINAL_ROUND_LIST_ID_SUCCESS,
    payload: getJudgingDetailsListResponse,
  };
};

export const getJudgingFinalRoundDetailsListErrorAction = (
  getJudgingDetailsListResponse: GetJudgingFinalRoundDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingFinalRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
export const getJudgingFinalRoundDetailsListAPIMsgAction = (
  getJudgingDetailsListResponse: GetJudgingFinalRoundDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingFinalRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};

export const getJudgingFinalRoundDetailsListAPIResClearAction = (
  getJudgingDetailsListResponse: GetJudgingFinalRoundDetailsListDetails,
  errMsg: string,
  status: number,
): GetJudgingFinalRoundDetailsListActionTypes => {
  return {
    type: GET_JUDGING_FINAL_ROUND_LIST_ID_FAIL,
    payload: getJudgingDetailsListResponse,
    message: errMsg,
    status: status,
  };
};
