import { FindUserRes } from "./Model";
export const FIND_USER_LOADING = "FIND_USER_LOADING";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const FIND_USER_FAIL = "FIND_USER_FAIL";
export const FIND_USER_UPDATE_API_MSG =
  "FIND_USER_UPDATE_API_MSG";
export const FIND_USER_UPDATE_API_RES =
  "FIND_USER_UPDATE_API_RES";

export interface FindUserState {
  findUserRes: FindUserRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface FindUserLoading {
  type: typeof FIND_USER_LOADING;
  loading: boolean;
}
export interface FindUserSuccess {
  type: typeof FIND_USER_SUCCESS;
  payload: FindUserRes;
  successMsg: string;
}
export interface FindUserFail {
  type: typeof FIND_USER_FAIL;
  payload: FindUserRes;
  errorMsg: string;
  status: number;
}
export interface FindUserUpdateAPIMsg {
  type: typeof FIND_USER_UPDATE_API_MSG;
  payload: FindUserRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface FindUserUpdateAPIRes {
  type: typeof FIND_USER_UPDATE_API_RES;
  payload: FindUserRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type FindUserActionTypes =
  | FindUserLoading
  | FindUserSuccess
  | FindUserFail
  | FindUserUpdateAPIMsg
  | FindUserUpdateAPIRes;
