import { PageCount, GetPatientDetailsListByCompetitionIdDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_LOADING = "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_LOADING";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_SUCCESS = "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_SUCCESS";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_FAIL = "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_FAIL";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_DATA = "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_DATA";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG = "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG";
export const PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES = "PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES";

export interface PatientDetailsListOfCompetitionIdState {
  patientDetailsListOfCompetitionIdRes: GetPatientDetailsListByCompetitionIdDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientDetailsListOfCompetitionIdLoading {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_LOADING;
  loading: boolean;
}
export interface PatientDetailsListOfCompetitionIdSuccess {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_SUCCESS;
  payload: GetPatientDetailsListByCompetitionIdDetails;
}
export interface PatientDetailsListOfCompetitionIdFail {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_FAIL;
  payload: GetPatientDetailsListByCompetitionIdDetails;
  message: string;
  status: number;
}
export interface PatientDetailsListOfCompetitionIdAPIMsg {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG;
  payload: GetPatientDetailsListByCompetitionIdDetails;
  message: string;
  status: number;
}
export interface PatientDetailsListOfCompetitionIdRes {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES;
  payload: GetPatientDetailsListByCompetitionIdDetails;
  message: string;
  status: number;
}
interface PatientDetailsListOfCompetitionIdDataAction {
  type: typeof PATIENT_DETAILS_LIST_BY_COMPETITION_ID_DATA;
  payload: GetPatientDetailsListByCompetitionIdDetails;
  pageCount: PageCount;
}

export type GetPatientDetailsListByCompetitionIdActionTypes =
  | PatientDetailsListOfCompetitionIdDataAction
  | PatientDetailsListOfCompetitionIdLoading
  | PatientDetailsListOfCompetitionIdSuccess
  | PatientDetailsListOfCompetitionIdFail
  | PatientDetailsListOfCompetitionIdAPIMsg
  | PatientDetailsListOfCompetitionIdRes;
