import {
  ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL,
  ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING,
  ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS,
  AddPatientDetailsListInCompetitionIdActionTypes,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG,
  PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES,
} from "./ActionTypes";
import { AddPatientDetailsListInCompetitionIdDetails } from "./Model";

export const addPatientDetailsListInCompetitionIdLoadingAction = (
  loading: boolean,
): AddPatientDetailsListInCompetitionIdActionTypes => {
  return {
    type: ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_LOADING,
    loading: loading,
  };
};

export const addPatientDetailsListInCompetitionIdSuccessAction = (
  patientDetailsListByCompetitionIdResponse: AddPatientDetailsListInCompetitionIdDetails,
  successMsg: string,
): AddPatientDetailsListInCompetitionIdActionTypes => {
  return {
    type: ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_SUCCESS,
    payload: patientDetailsListByCompetitionIdResponse,
    successMsg: successMsg,
  };
};

export const addPatientDetailsListInCompetitionIdErrorAction = (
  patientDetailsListByCompetitionIdResponse: AddPatientDetailsListInCompetitionIdDetails,
  errorMsg: string,
  status: number,
): AddPatientDetailsListInCompetitionIdActionTypes => {
  return {
    type: ADD_PATIENT_DETAILSLIST_IN_COMPETITION_ID_FAIL,
    payload: patientDetailsListByCompetitionIdResponse,
    errorMsg: errorMsg,
    status: status,
  };
};
export const addPatientDetailsListInCompetitionIdAPIMsgAction = (
  patientDetailsListByCompetitionIdResponse: AddPatientDetailsListInCompetitionIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): AddPatientDetailsListInCompetitionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_MSG,
    payload: patientDetailsListByCompetitionIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};

export const addPatientDetailsListInCompetitionIdClearAction = (
  patientDetailsListByCompetitionIdResponse: AddPatientDetailsListInCompetitionIdDetails,
  successMsg: string,
  errorMsg: string,
  status: number,
): AddPatientDetailsListInCompetitionIdActionTypes => {
  return {
    type: PATIENT_DETAILS_LIST_BY_COMPETITION_ID_API_RES,
    payload: patientDetailsListByCompetitionIdResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
