import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Alert, Box, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWUploader from "../../../component/MWUploader";
import AdminLoader from "../../AdminLoader";
import MWPageTitle from "../../../component/MWPageTitle";
import { addProviderOrganizationApi } from "../../../redux/AddProviderOrganization/API";
import { AddProviderOrganizationBody } from "../../../redux/AddProviderOrganization/Model";

export default function AddProviderOrganization() {
  const dispatch = useDispatch();
  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };
  const closeSummaryBanner = () => {
    setAddProviderOrganizationSuccess("");
  };
  const [addProviderOrganizationSuccess, setAddProviderOrganizationSuccess] = useState("");
  const addHealthCampPatientSuccessDiv = addProviderOrganizationSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      {addProviderOrganizationSuccess}
    </Alert>
  ) : (
    ""
  );
  const [fullOrganizationName, setFullOrganizationName] = useState("");
  const [fNErrorMsg, setFNErrorMsg] = useState("");
  const [diaplayName, setDisplayName] = useState("");
  const [displayErrorMsg, setDisplayErrorMsg] = useState("");
  const [orgTagline, setOrgTagline] = useState("");
  const [email, setEmail] = useState("");
  const [orgTagErrorMsg, setOrgTagErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const [desErrorMsg, setDesErrorMsg] = useState("");
  const [imageId, setImageId] = useState("");

  const addProviderOrgBody = {
    displayname: diaplayName,
    fullName: fullOrganizationName,
    orgTagLine: orgTagline,
    description: orgDescription,
    email: email,
    logoImageId: imageId,
  } as AddProviderOrganizationBody;

  const fullNameHandleChange = (e: any) => {
    setFullOrganizationName(e.target.value);
    setFNErrorMsg("");
  };

  const displayNameHandleChange = (e: any) => {
    setDisplayName(e.target.value);
    setDisplayErrorMsg("");
  };

  const taglineHandleChange = (e: any) => {
    setOrgTagline(e.target.value);
    setOrgTagErrorMsg("");
  };

  const emailHandleChange = (e: any) => {
    setEmail(e.target.value);
    setEmailErrorMsg("");
  };

  const desHandleChange = (e: any) => {
    setOrgDescription(e.target.value);
    setDesErrorMsg("");
  };
  const addProviderOrganizationSubmit = () => {
    if (delImgAction) {
      setDelImgAction(false);
    }
    if (fullOrganizationName === "") {
      setFNErrorMsg("Please enter full organization name");
    } else if (diaplayName === "") {
      setDisplayErrorMsg("Please enter display name");
    } else if (orgTagline === "") {
      setOrgTagErrorMsg("Please enter organization tagline");
    } else if (email === "") {
      setEmailErrorMsg("Please enter organization email");
    } else if (orgDescription === "") {
      setDesErrorMsg("Please enter organization decription");
    } else {
      dispatch(addProviderOrganizationApi(addProviderOrgBody));
    }
  };

  const addProviderOrgValueRes = useSelector(
    (state: AppState) => state.addProviderOrganizationValue,
  );

  useEffect(() => {
    if (addProviderOrgValueRes?.AddProviderOrganizationRes?.message !== undefined) {
      setAddProviderOrganizationSuccess(
        addProviderOrgValueRes?.AddProviderOrganizationRes?.message,
      );
      setFullOrganizationName("");
      setDisplayName("");
      setOrgTagline("");
      setOrgDescription("");
      setImageId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProviderOrgValueRes]);

  // const delImgAction = (_passeVal: boolean) => {};
  const [delImgAction, setDelImgAction] = useState(false);
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };
  const addProviderOrganizationPage = (
    <Box>
      <Paper sx={{ my: 3, mx: 1 }} data-testid="add-organization">
        <Grid container spacing={2} maxWidth="xl" p={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              value={fullOrganizationName}
              id="fname"
              data-testid="fullName"
              label="Full Organization Name"
              variant="outlined"
              onChange={fullNameHandleChange}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              size="small"
            />
            <Box sx={{ color: "red", my: 1 }}>{fNErrorMsg}</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              value={diaplayName}
              id="dname"
              data-testid="displayName"
              label="Display Name"
              variant="outlined"
              onChange={displayNameHandleChange}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              size="small"
            />
            <Box sx={{ color: "red", my: 1 }}>{displayErrorMsg}</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              value={orgTagline}
              id="tagline"
              data-testid="tagLine"
              label="Organization Tagline"
              variant="outlined"
              onChange={taglineHandleChange}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              size="small"
            />
            <Box sx={{ color: "red", my: 1 }}>{orgTagErrorMsg}</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              value={email}
              id="email"
              data-testid="email"
              label="Organization Email"
              variant="outlined"
              onChange={emailHandleChange}
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              size="small"
            />
            <Box sx={{ color: "red", my: 1 }}>{emailErrorMsg}</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={orgDescription}
              id="description"
              data-testid="description"
              label="Organization Description"
              variant="outlined"
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              size="small"
              onChange={desHandleChange}
              multiline
              maxRows={2}
              minRows={3}
            />
            <Typography sx={{ color: "red", my: 1 }}>{desErrorMsg}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MWUploader
              documentType="patient-image"
              criteria="Upload a file less than 50 mb."
              updatedDataState={updateDataStateFromChild}
              title={"Organization Logo Upload"}
              deleteImgAction={delImgAction}
              previewImageData={viewPreviewImageData}
            />
          </Grid>
        </Grid>
        <Box px={2}>{addHealthCampPatientSuccessDiv}</Box>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item p={3}>
              <Button
                onClick={addProviderOrganizationSubmit}
                variant="contained"
                color="primary"
                style={{ fontSize: "12px", fontWeight: "bold" }}
                disabled={checkImgUpload}
                data-testid="Submit-target-btn"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
  return (
    <Box>
      <Box>{addProviderOrgValueRes?.loading ? <AdminLoader /> : null}</Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle backButton={true} title="Add Provider Organization" />
            </Grid>
          </Grid>
        </Stack>
        {addProviderOrganizationPage}
      </Container>
    </Box>
  );
}
