import {
  ADD_SESSION_FAIL,
  ADD_SESSION_LOADING,
  ADD_SESSION_SUCCESS,
  ADD_SESSION_API_MSG,
  ADD_SESSION_API_RES,
  AddSessionActionTypes,
} from "./ActionTypes";
import { AddSessionDetails } from "./Model";

export const addSessionLoadingAction = (
  loading: boolean,
): AddSessionActionTypes => {
  return {
    type: ADD_SESSION_LOADING,
    loading: loading,
  };
};

export const addSessionSuccessAction = (
  addSessionResponse: AddSessionDetails,
  successMsg: string,
): AddSessionActionTypes => {
  return {
    type: ADD_SESSION_SUCCESS,
    payload: addSessionResponse,
    successMsg: successMsg,
  };
};

export const addSessionErrorAction = (
  addSessionResponse: AddSessionDetails,
  errMsg: string,
  status: number,
): AddSessionActionTypes => {
  return {
    type: ADD_SESSION_FAIL,
    payload: addSessionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addSessionAPIMsgAction = (
  addSessionResponse: AddSessionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddSessionActionTypes => {
  return {
    type: ADD_SESSION_API_MSG,
    payload: addSessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addSessionAPIResClearAction = (
  addSessionResponse: AddSessionDetails,
  successMsg: string,
  errMsg: string,
  status: number,
): AddSessionActionTypes => {
  return {
    type: ADD_SESSION_API_RES,
    payload: addSessionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
