import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetProviderOriganizationListActionTypes } from "./ActionTypes";
import { GetProviderOrganizationAdministratorListRes } from "./Model";
import {
  getProviderOrgAdministratorListAPIResClearAction,
  getProviderOrgAdministratorListErrorAction,
  getProviderOrgAdministratorListLoadingAction,
  getProviderOrgAdministratorListSuccessAction,
  getProviderOrgAdministratorListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetProviderOrganizationAdministratorListRes;
export const getProviderOrganizationAdministratorListApi = (pageNo: any) => {
  return function (dispatch: Dispatch<GetProviderOriganizationListActionTypes>) {
    dispatch(getProviderOrgAdministratorListLoadingAction(true));
    axios
      .get(
        Url.baseUrl +
          `/providerorganizationapp/api/administrator/providerOrganizationAdministratorListByOwner?page=${Number(
            pageNo,
          )}&size=5`,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getProviderOrgAdministratorListLoadingAction(false));
        dispatch(getProviderOrgAdministratorListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getProviderOrgAdministratorListLoadingAction(false));
        dispatch(
          getProviderOrgAdministratorListErrorAction(
            {} as GetProviderOrganizationAdministratorListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const getProviderOrganizationListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderOriganizationListActionTypes>) {
    dispatch(
      getProviderOrgAdministratorListAPIMsgAction(
        apiRes as GetProviderOrganizationAdministratorListRes,
        "",
        0,
      ),
    );
  };
};

export const clearProviderOrganizationListAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderOriganizationListActionTypes>) {
    dispatch(
      getProviderOrgAdministratorListAPIResClearAction(
        {} as GetProviderOrganizationAdministratorListRes,
        "",
        0,
      ),
    );
  };
};
