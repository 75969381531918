import {
  ExtractObsTypeBySessionIdActionTypes,
  EXTRACT_OBS_BY_SESSION_ID_FAIL,
  EXTRACT_OBS_BY_SESSION_ID_LOADING,
  EXTRACT_OBS_BY_SESSION_ID_SUCCESS,
  ExtractObsTypeBySessionIdState,
  EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG,
  EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES,
} from "./ActionTypes";
import { ExtractObsTypeBySessionIdRes } from "./Model";

const initialStateGetPosts: ExtractObsTypeBySessionIdState = {
  loading: false,
  extractObsTypeBySessionId: {} as ExtractObsTypeBySessionIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const extractObsBySessionIdReducer = (
  state = initialStateGetPosts,
  action: ExtractObsTypeBySessionIdActionTypes,
): ExtractObsTypeBySessionIdState => {
  switch (action.type) {
    case EXTRACT_OBS_BY_SESSION_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EXTRACT_OBS_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        extractObsTypeBySessionId: action.payload,
        successMsg: action.successMsg,
      };
    case EXTRACT_OBS_BY_SESSION_ID_FAIL:
      return {
        ...state,
        extractObsTypeBySessionId: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_MSG:
      return {
        ...state,
        extractObsTypeBySessionId: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXTRACT_OBS_BY_SESSION_ID_UPDATE_API_RES:
      return {
        ...state,
        extractObsTypeBySessionId: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
