import * as React from "react";
import { Card, Grid, Typography, Avatar, CardHeader, CardContent, Stack, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import { getAdministratorDetailsById } from "../../../redux/ProviderOrganizationAdministratorDetails/API";
function ProviderOrganizationAdministratorDetails() {
  const dispatch = useDispatch();
  const administratorDetails = useSelector((state: AppState) => state.getAdministratorDetailsValue);
  const detailsValueRes =
    administratorDetails?.GetAdministratorDetailsById?.providerOrganizationAdministratorDetails;

  const [detailsValue, setDetailsValue] = React.useState([] as any[]);
  const { administratorid } = useParams() as {
    administratorid: string;
  };
  const apiBody = {
    id: administratorid,
  };
  React.useEffect(() => {
    dispatch(getAdministratorDetailsById(apiBody.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  React.useEffect(() => {
    if (detailsValueRes !== undefined) {
      setDetailsValue(detailsValueRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [administratorDetails]);

  return (
    <Box m={4}>
      {detailsValue.map((organizationItem: any) => (
        <Card style={{ borderRadius: 20 }}>
          <CardHeader
            title={
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Provider Organization Administrator Details
                  </Typography>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={6} md={4} lg={4}>
                {organizationItem?.organization?.logoImage?.document ? (
                  <img
                    src={organizationItem?.organization?.logoImage?.document}
                    srcSet={organizationItem?.organization?.logoImage?.document}
                    alt=""
                    loading="lazy"
                    style={{
                      maxWidth: "180px",
                      maxHeight: "180px",
                      flex: 1,
                    }}
                  />
                ) : (
                  <Avatar sx={{ width: 120, height: 120 }} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={8}>
                <Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography>Name:</Typography>
                    <Typography>Tagline:</Typography>
                    <Typography>Description:</Typography>
                  </Stack>

                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
                      {organizationItem.organization.displayName}
                    </Typography>
                    <Typography variant="body1" color="primary" style={{ fontWeight: "bold" }}>
                      {organizationItem.organization.tagLine}
                    </Typography>
                    <Typography variant="body2" color="primary" style={{ fontWeight: "bold" }}>
                      {organizationItem.organization.description}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}

export default ProviderOrganizationAdministratorDetails;
