import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import {  ToggleActiveRegistrationRequestDetailsActionTypes } from "./ActionTypes";
import { ToggleActiveRegReqDetailsBody, ToggleActiveRegReqDetailsRes } from "./Model";
import {
   toggleActiveRegistrationRequestDetailsAPIResClearAction,
   toggleActiveRegistrationRequestDetailsErrorAction,
   toggleActiveRegistrationRequestDetailsLoadingAction,
   toggleActiveRegistrationRequestDetailsSuccessAction,
   toggleActiveRegistrationRequestDetailsUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

let apiRes = {} as ToggleActiveRegReqDetailsRes;
export const  toggleActiveRegistrationRequestDetails = (payload: ToggleActiveRegReqDetailsBody) => {
  return function (dispatch: Dispatch< ToggleActiveRegistrationRequestDetailsActionTypes>) {
    dispatch( toggleActiveRegistrationRequestDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/healthcampapp/api/healthcampRegistrationDetail/toggle_active_registration_request_detail`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( toggleActiveRegistrationRequestDetailsLoadingAction(false));
        dispatch(
           toggleActiveRegistrationRequestDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as ToggleActiveRegReqDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "healthcampRegistrationRequest status of is now False",
          ),
        );
      })
      .catch((error) => {
        dispatch( toggleActiveRegistrationRequestDetailsLoadingAction(false));
        dispatch(
           toggleActiveRegistrationRequestDetailsErrorAction(
            {} as ToggleActiveRegReqDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateToggleActiveRegistrationRequestDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch< ToggleActiveRegistrationRequestDetailsActionTypes>) {
    dispatch(
       toggleActiveRegistrationRequestDetailsUpdateAPIMsgAction(apiRes as ToggleActiveRegReqDetailsRes, "", "", 0),
    );
  };
};

export const clearToggleActiveRegistrationRequestDetailsAPIRes = () => {
  return function (dispatch: Dispatch< ToggleActiveRegistrationRequestDetailsActionTypes>) {
    dispatch( toggleActiveRegistrationRequestDetailsAPIResClearAction({} as ToggleActiveRegReqDetailsRes, "", "", 0));
  };
};
