import { SetPreferenceRes } from "./Model";
export const SET_PREFERENCE_LOADING = "SET_PREFERENCE_LOADING";
export const SET_PREFERENCE_SUCCESS = "SET_PREFERENCE_SUCCESS";
export const SET_PREFERENCE_FAIL = "SET_PREFERENCE_FAIL";
export const SET_PREFERENCE_UPDATE_API_MSG = "SET_PREFERENCE_UPDATE_API_MSG";
export const SET_PREFERENCE_UPDATE_API_RES = "SET_PREFERENCE_UPDATE_API_RES";

export interface SetPreferenceState {
  addScheduleRes: SetPreferenceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SetPreferenceLoading {
  type: typeof SET_PREFERENCE_LOADING;
  loading: boolean;
}
export interface SetPreferenceSuccess {
  type: typeof SET_PREFERENCE_SUCCESS;
  payload: SetPreferenceRes;
  successMsg: string;
}
export interface SetPreferenceFail {
  type: typeof SET_PREFERENCE_FAIL;
  payload: SetPreferenceRes;
  errorMsg: string;
  status: number;
}
export interface SetPreferenceUpdateAPIMsg {
  type: typeof SET_PREFERENCE_UPDATE_API_MSG;
  payload: SetPreferenceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SetPreferenceUpdateAPIRes {
  type: typeof SET_PREFERENCE_UPDATE_API_RES;
  payload: SetPreferenceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SetPreferenceActionTypes =
  | SetPreferenceLoading
  | SetPreferenceSuccess
  | SetPreferenceFail
  | SetPreferenceUpdateAPIMsg
  | SetPreferenceUpdateAPIRes;
