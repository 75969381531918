import {
  APPOINTMENT_DEACTIVATE_DATA,
  APPOINTMENT_DEACTIVATE_FAIL,
  APPOINTMENT_DEACTIVATE_LOADING,
  APPOINTMENT_DEACTIVATE_SUCCESS,
  AppointmentDeactivateActionTypes,
} from "./ActionTypes";
import { AppointmentDeactivateResponse } from "./Model";

export const appointmentDeactivateAction = (
  appointmentDeactivateRes: AppointmentDeactivateResponse,
): AppointmentDeactivateActionTypes => {
  return {
    type: APPOINTMENT_DEACTIVATE_DATA,
    payload: appointmentDeactivateRes,
  };
};

export const appointmentDeactivateLoadingAction = (
  loading: boolean,
): AppointmentDeactivateActionTypes => {
  return {
    type: APPOINTMENT_DEACTIVATE_LOADING,
    loading: loading,
  };
};

export const appointmentDeactivateSuccessAction = (
  appointmentDeactivateRes: AppointmentDeactivateResponse,
  successMsg: string,
  status: number,
): AppointmentDeactivateActionTypes => {
  return {
    type: APPOINTMENT_DEACTIVATE_SUCCESS,
    payload: appointmentDeactivateRes,
    successMsg: successMsg,
    status: status,
  };
};

export const appointmentDeactivateErrorAction = (
  appointmentDeactivateRes: AppointmentDeactivateResponse,
  errMsg: string,
  status: number,
): AppointmentDeactivateActionTypes => {
  return {
    type: APPOINTMENT_DEACTIVATE_FAIL,
    payload: appointmentDeactivateRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const appointmentDeactivateUpdateAPIMsgAction = (
  appointmentDeactivateRes: AppointmentDeactivateResponse,
  errMsg: string,
  status: number,
): AppointmentDeactivateActionTypes => {
  return {
    type: APPOINTMENT_DEACTIVATE_FAIL,
    payload: appointmentDeactivateRes,
    errorMsg: errMsg,
    status: status,
  };
};

export const appointmentDeactivateAPIResClearAction = (
  appointmentDeactivateRes: AppointmentDeactivateResponse,
  errMsg: string,
  status: number,
): AppointmentDeactivateActionTypes => {
  return {
    type: APPOINTMENT_DEACTIVATE_FAIL,
    payload: appointmentDeactivateRes,
    errorMsg: errMsg,
    status: status,
  };
};
